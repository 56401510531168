import { DataError } from './DataError';
import { Loading } from './Loading';

interface Props {
  error: any | undefined;
  loading: string;
}
export const ApiserverNoContent = ({ error, loading }: Props) => {
  if (error) {
    // Div wrapper is encase parent container uses flex or something
    return (
      <div>
        <DataError error={error} />
      </div>
    );
  }

  return <Loading message={loading} />;
};
