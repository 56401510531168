import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace ZeroTrustCardStyles {
  export const Grid = styled.div<{ single?: boolean }>(({ single }) => {
    return css`
      display: grid;
      justify-content: center;
      gap: 20px;
      grid-template-columns: ${single ? '0.5fr' : 'repeat(2, 1fr)'};
      min-height: 167px; // Min height added so it will usually be the same height as the FIPS card
      margin: 15px 0;
    `;
  });

  export const InsightBox = styled.div(({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      padding: 10px;
      background: ${theme.dropBlue};
      border: 1px solid ${theme.oceanBlue};
      border-radius: 4px;
      ${svgBasics({ width: '100%' }, theme.oceanBlue)}
    `;
  });
  export const InsightBoxCount = styled.div(({ theme }) => {
    return css`
      font-size: 24px;
      padding: 10px 0;
      text-align: center;
      color: ${theme.neptuneBlue};
    `;
  });
  export const InsightBoxDesc = styled.div(({ theme }) => {
    return css`
      flex: 1;
      font-size: 12px;
      text-align: center;
    `;
  });
}
