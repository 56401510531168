import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { DataErrorStringList } from 'Components/Common/DataError';
import { SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloGridTableToggle, { isTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { DefaultTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { Svg } from 'Components/Common/Svg';
import { graphValidTimeIntervalList, useGetGraphCache } from 'Components/Features/Graph/General/graph-cache';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { TrafficEgressLandingBody } from './TrafficEgressLandingBody';

const { useListEgressSources } = overviewApi;

export const TrafficEgressLanding = () => {
  di(useListEgressSources, usePermissionGateRedirect);
  usePermissionGateRedirect(Permission.ViewEgress);
  const { cache, updateCache } = useGetGraphCache();
  //
  // Paginate data
  const { pagingData, useSetPagingTotal, apiPaginationObject } = useSoloPaging(DefaultTablePageSize);
  //
  // Table/grid
  const [isTable, setIsTable] = useState(isTableViewPreferred());
  //
  // Filters
  const [nameFilter, setNameFilter] = useState('');
  const [window, setWindow] = useState<number>(cache.timeInterval);
  const filtersOn = !!nameFilter.length;
  //
  // Fetch data
  const { data: egressGWs, error: egressGWsError } = useListEgressSources(nameFilter, apiPaginationObject, window);
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(egressGWs);
  useSetPagingTotal(egressGWs?.filteredTotalEgressSources);
  const isLoading = egressGWs === undefined;
  const errorsList = egressGWs?.apiserverErrors;

  useEffect(() => {
    updateCache({ timeInterval: window });
  }, [window]);

  //
  // Render
  //
  return (
    <OverviewStyles.Container data-testid='traffic-egress-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.EgressIcon />}
          Egress
        </OverviewStyles.Header.Title>
        {!!egressGWs?.registryOnly && (
          <FlexLayout flexGrow={1} justifyContent='flex-start' ml={4}>
            <SecondaryInfo
              items={[
                {
                  key: 'registry-only',
                  data: (
                    <>
                      <Svg height={15} asset={<Asset.EgressRegistryOnlyIcon />} color={theme => theme.seaBlue} />
                      Registry Only
                    </>
                  )
                }
              ]}
            />
          </FlexLayout>
        )}
        {!egressGWsError && (
          <OverviewStyles.Header.FiltersWrapper>
            <OverviewStyles.Header.Filters filters={2} hasTableToggle={true}>
              <SoloInput
                searchIcon
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label={'Search by name'}
                onChange={evt => setNameFilter(evt.target.value)}
              />
              <SoloDropdown
                options={graphValidTimeIntervalList}
                value={window}
                onChange={setWindow}
                aria-label={'Lookback time'}
              />
              <SoloGridTableToggle isTable={isTable} onChange={newIsTable => setIsTable(newIsTable)} />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <div>
        {!!errorsList?.length && <DataErrorStringList errorsList={errorsList} />}
        <TrafficEgressLandingBody
          filtersOn={filtersOn}
          isLoading={isLoading}
          itemsError={egressGWsError}
          isTable={isTable}
          items={egressGWs?.egressSourceSummaries ?? []}
          pagingData={pagingData}
          data-testid='traffic-egress-landing-body-empty'
          icon={<Asset.EgressIcon />}
          docsLink={false}
          resourceNamePlural='Egress'
        />
      </div>
    </OverviewStyles.Container>
  );
};
