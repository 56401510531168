/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/config/core/v3/grpc_service.proto" (package "envoy.config.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../../../../google/protobuf/any_pb";
import { Empty } from "../../../../google/protobuf/empty_pb";
import { DataSource } from "./base_pb";
import { UInt32Value } from "../../../../google/protobuf/wrappers_pb";
import { Struct } from "../../../../google/protobuf/struct_pb";
import { HeaderValue } from "./base_pb";
import { Duration } from "../../../../google/protobuf/duration_pb";
// [#protodoc-title: gRPC services]

/**
 * gRPC service configuration. This is used by :ref:`ApiConfigSource
 * <envoy_api_msg_config.core.v3.ApiConfigSource>` and filter configurations.
 * [#next-free-field: 6]
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService
 */
export interface GrpcService {
    /**
     * @generated from protobuf oneof: target_specifier
     */
    targetSpecifier: {
        oneofKind: "envoyGrpc";
        /**
         * Envoy's in-built gRPC client.
         * See the :ref:`gRPC services overview <arch_overview_grpc_services>`
         * documentation for discussion on gRPC client selection.
         *
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.EnvoyGrpc envoy_grpc = 1;
         */
        envoyGrpc: GrpcService_EnvoyGrpc;
    } | {
        oneofKind: "googleGrpc";
        /**
         * `Google C++ gRPC client <https://github.com/grpc/grpc>`_
         * See the :ref:`gRPC services overview <arch_overview_grpc_services>`
         * documentation for discussion on gRPC client selection.
         *
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc google_grpc = 2;
         */
        googleGrpc: GrpcService_GoogleGrpc;
    } | {
        oneofKind: undefined;
    };
    /**
     * The timeout for the gRPC request. This is the timeout for a specific
     * request.
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 3;
     */
    timeout?: Duration;
    /**
     * Additional metadata to include in streams initiated to the GrpcService. This can be used for
     * scenarios in which additional ad hoc authorization headers (e.g. ``x-foo-bar: baz-key``) are to
     * be injected. For more information, including details on header value syntax, see the
     * documentation on :ref:`custom request headers
     * <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.config.core.v3.HeaderValue initial_metadata = 5;
     */
    initialMetadata: HeaderValue[];
}
/**
 * @generated from protobuf message envoy.config.core.v3.GrpcService.EnvoyGrpc
 */
export interface GrpcService_EnvoyGrpc {
    /**
     * The name of the upstream gRPC cluster. SSL credentials will be supplied
     * in the :ref:`Cluster <envoy_api_msg_config.cluster.v3.Cluster>` :ref:`transport_socket
     * <envoy_api_field_config.cluster.v3.Cluster.transport_socket>`.
     *
     * @generated from protobuf field: string cluster_name = 1;
     */
    clusterName: string;
    /**
     * The `:authority` header in the grpc request. If this field is not set, the authority header value will be `cluster_name`.
     * Note that this authority does not override the SNI. The SNI is provided by the transport socket of the cluster.
     *
     * @generated from protobuf field: string authority = 2;
     */
    authority: string;
}
/**
 * [#next-free-field: 9]
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc
 */
export interface GrpcService_GoogleGrpc {
    /**
     * The target URI when using the `Google C++ gRPC client
     * <https://github.com/grpc/grpc>`_. SSL credentials will be supplied in
     * :ref:`channel_credentials <envoy_api_field_config.core.v3.GrpcService.GoogleGrpc.channel_credentials>`.
     *
     * @generated from protobuf field: string target_uri = 1;
     */
    targetUri: string;
    /**
     * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials channel_credentials = 2;
     */
    channelCredentials?: GrpcService_GoogleGrpc_ChannelCredentials;
    /**
     * A set of call credentials that can be composed with `channel credentials
     * <https://grpc.io/docs/guides/auth.html#credential-types>`_.
     *
     * @generated from protobuf field: repeated envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials call_credentials = 3;
     */
    callCredentials: GrpcService_GoogleGrpc_CallCredentials[];
    /**
     * The human readable prefix to use when emitting statistics for the gRPC
     * service.
     *
     * .. csv-table::
     *    :header: Name, Type, Description
     *    :widths: 1, 1, 2
     *
     *    streams_total, Counter, Total number of streams opened
     *    streams_closed_<gRPC status code>, Counter, Total streams closed with <gRPC status code>
     *
     * @generated from protobuf field: string stat_prefix = 4;
     */
    statPrefix: string;
    /**
     * The name of the Google gRPC credentials factory to use. This must have been registered with
     * Envoy. If this is empty, a default credentials factory will be used that sets up channel
     * credentials based on other configuration parameters.
     *
     * @generated from protobuf field: string credentials_factory_name = 5;
     */
    credentialsFactoryName: string;
    /**
     * Additional configuration for site-specific customizations of the Google
     * gRPC library.
     *
     * @generated from protobuf field: google.protobuf.Struct config = 6;
     */
    config?: Struct;
    /**
     * How many bytes each stream can buffer internally.
     * If not set an implementation defined default is applied (1MiB).
     *
     * @generated from protobuf field: google.protobuf.UInt32Value per_stream_buffer_limit_bytes = 7;
     */
    perStreamBufferLimitBytes?: UInt32Value;
    /**
     * Custom channels args.
     *
     * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs channel_args = 8;
     */
    channelArgs?: GrpcService_GoogleGrpc_ChannelArgs;
}
/**
 * See https://grpc.io/grpc/cpp/structgrpc_1_1_ssl_credentials_options.html.
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials
 */
export interface GrpcService_GoogleGrpc_SslCredentials {
    /**
     * PEM encoded server root certificates.
     *
     * @generated from protobuf field: envoy.config.core.v3.DataSource root_certs = 1;
     */
    rootCerts?: DataSource;
    /**
     * PEM encoded client private key.
     *
     * @generated from protobuf field: envoy.config.core.v3.DataSource private_key = 2;
     */
    privateKey?: DataSource;
    /**
     * PEM encoded client certificate chain.
     *
     * @generated from protobuf field: envoy.config.core.v3.DataSource cert_chain = 3;
     */
    certChain?: DataSource;
}
/**
 * Local channel credentials. Only UDS is supported for now.
 * See https://github.com/grpc/grpc/pull/15909.
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials
 */
export interface GrpcService_GoogleGrpc_GoogleLocalCredentials {
}
/**
 * See https://grpc.io/docs/guides/auth.html#credential-types to understand Channel and Call
 * credential types.
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
 */
export interface GrpcService_GoogleGrpc_ChannelCredentials {
    /**
     * @generated from protobuf oneof: credential_specifier
     */
    credentialSpecifier: {
        oneofKind: "sslCredentials";
        /**
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials ssl_credentials = 1;
         */
        sslCredentials: GrpcService_GoogleGrpc_SslCredentials;
    } | {
        oneofKind: "googleDefault";
        /**
         * https://grpc.io/grpc/cpp/namespacegrpc.html#a6beb3ac70ff94bd2ebbd89b8f21d1f61
         *
         * @generated from protobuf field: google.protobuf.Empty google_default = 2;
         */
        googleDefault: Empty;
    } | {
        oneofKind: "localCredentials";
        /**
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials local_credentials = 3;
         */
        localCredentials: GrpcService_GoogleGrpc_GoogleLocalCredentials;
    } | {
        oneofKind: undefined;
    };
}
/**
 * [#next-free-field: 8]
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
 */
export interface GrpcService_GoogleGrpc_CallCredentials {
    /**
     * @generated from protobuf oneof: credential_specifier
     */
    credentialSpecifier: {
        oneofKind: "accessToken";
        /**
         * Access token credentials.
         * https://grpc.io/grpc/cpp/namespacegrpc.html#ad3a80da696ffdaea943f0f858d7a360d.
         *
         * @generated from protobuf field: string access_token = 1;
         */
        accessToken: string;
    } | {
        oneofKind: "googleComputeEngine";
        /**
         * Google Compute Engine credentials.
         * https://grpc.io/grpc/cpp/namespacegrpc.html#a6beb3ac70ff94bd2ebbd89b8f21d1f61
         *
         * @generated from protobuf field: google.protobuf.Empty google_compute_engine = 2;
         */
        googleComputeEngine: Empty;
    } | {
        oneofKind: "googleRefreshToken";
        /**
         * Google refresh token credentials.
         * https://grpc.io/grpc/cpp/namespacegrpc.html#a96901c997b91bc6513b08491e0dca37c.
         *
         * @generated from protobuf field: string google_refresh_token = 3;
         */
        googleRefreshToken: string;
    } | {
        oneofKind: "serviceAccountJwtAccess";
        /**
         * Service Account JWT Access credentials.
         * https://grpc.io/grpc/cpp/namespacegrpc.html#a92a9f959d6102461f66ee973d8e9d3aa.
         *
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials service_account_jwt_access = 4;
         */
        serviceAccountJwtAccess: GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials;
    } | {
        oneofKind: "googleIam";
        /**
         * Google IAM credentials.
         * https://grpc.io/grpc/cpp/namespacegrpc.html#a9fc1fc101b41e680d47028166e76f9d0.
         *
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials google_iam = 5;
         */
        googleIam: GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials;
    } | {
        oneofKind: "fromPlugin";
        /**
         * Custom authenticator credentials.
         * https://grpc.io/grpc/cpp/namespacegrpc.html#a823c6a4b19ffc71fb33e90154ee2ad07.
         * https://grpc.io/docs/guides/auth.html#extending-grpc-to-support-other-authentication-mechanisms.
         *
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin from_plugin = 6;
         */
        fromPlugin: GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin;
    } | {
        oneofKind: "stsService";
        /**
         * Custom security token service which implements OAuth 2.0 token exchange.
         * https://tools.ietf.org/html/draft-ietf-oauth-token-exchange-16
         * See https://github.com/grpc/grpc/pull/19587.
         *
         * @generated from protobuf field: envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService sts_service = 7;
         */
        stsService: GrpcService_GoogleGrpc_CallCredentials_StsService;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials
 */
export interface GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials {
    /**
     * @generated from protobuf field: string json_key = 1;
     */
    jsonKey: string;
    /**
     * @generated from protobuf field: uint64 token_lifetime_seconds = 2;
     */
    tokenLifetimeSeconds: number;
}
/**
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials
 */
export interface GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials {
    /**
     * @generated from protobuf field: string authorization_token = 1;
     */
    authorizationToken: string;
    /**
     * @generated from protobuf field: string authority_selector = 2;
     */
    authoritySelector: string;
}
/**
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin
 */
export interface GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf oneof: config_type
     */
    configType: {
        oneofKind: "typedConfig";
        /**
         * @generated from protobuf field: google.protobuf.Any typed_config = 3;
         */
        typedConfig: Any;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Security token service configuration that allows Google gRPC to
 * fetch security token from an OAuth 2.0 authorization server.
 * See https://tools.ietf.org/html/draft-ietf-oauth-token-exchange-16 and
 * https://github.com/grpc/grpc/pull/19587.
 * [#next-free-field: 10]
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService
 */
export interface GrpcService_GoogleGrpc_CallCredentials_StsService {
    /**
     * URI of the token exchange service that handles token exchange requests.
     * [#comment:TODO(asraa): Add URI validation when implemented. Tracked by
     * https://github.com/envoyproxy/protoc-gen-validate/issues/303]
     *
     * @generated from protobuf field: string token_exchange_service_uri = 1;
     */
    tokenExchangeServiceUri: string;
    /**
     * Location of the target service or resource where the client
     * intends to use the requested security token.
     *
     * @generated from protobuf field: string resource = 2;
     */
    resource: string;
    /**
     * Logical name of the target service where the client intends to
     * use the requested security token.
     *
     * @generated from protobuf field: string audience = 3;
     */
    audience: string;
    /**
     * The desired scope of the requested security token in the
     * context of the service or resource where the token will be used.
     *
     * @generated from protobuf field: string scope = 4;
     */
    scope: string;
    /**
     * Type of the requested security token.
     *
     * @generated from protobuf field: string requested_token_type = 5;
     */
    requestedTokenType: string;
    /**
     * The path of subject token, a security token that represents the
     * identity of the party on behalf of whom the request is being made.
     *
     * @generated from protobuf field: string subject_token_path = 6;
     */
    subjectTokenPath: string;
    /**
     * Type of the subject token.
     *
     * @generated from protobuf field: string subject_token_type = 7;
     */
    subjectTokenType: string;
    /**
     * The path of actor token, a security token that represents the identity
     * of the acting party. The acting party is authorized to use the
     * requested security token and act on behalf of the subject.
     *
     * @generated from protobuf field: string actor_token_path = 8;
     */
    actorTokenPath: string;
    /**
     * Type of the actor token.
     *
     * @generated from protobuf field: string actor_token_type = 9;
     */
    actorTokenType: string;
}
/**
 * Channel arguments.
 *
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs
 */
export interface GrpcService_GoogleGrpc_ChannelArgs {
    /**
     * See grpc_types.h GRPC_ARG #defines for keys that work here.
     *
     * @generated from protobuf field: map<string, envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value> args = 1;
     */
    args: {
        [key: string]: GrpcService_GoogleGrpc_ChannelArgs_Value;
    };
}
/**
 * @generated from protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
 */
export interface GrpcService_GoogleGrpc_ChannelArgs_Value {
    /**
     * @generated from protobuf oneof: value_specifier
     */
    valueSpecifier: {
        oneofKind: "stringValue";
        /**
         * @generated from protobuf field: string string_value = 1;
         */
        stringValue: string;
    } | {
        oneofKind: "intValue";
        /**
         * @generated from protobuf field: int64 int_value = 2;
         */
        intValue: number;
    } | {
        oneofKind: undefined;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService$Type extends MessageType<GrpcService> {
    constructor() {
        super("envoy.config.core.v3.GrpcService", [
            { no: 1, name: "envoy_grpc", kind: "message", oneof: "targetSpecifier", T: () => GrpcService_EnvoyGrpc },
            { no: 2, name: "google_grpc", kind: "message", oneof: "targetSpecifier", T: () => GrpcService_GoogleGrpc },
            { no: 3, name: "timeout", kind: "message", T: () => Duration },
            { no: 5, name: "initial_metadata", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValue }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService
 */
export const GrpcService = new GrpcService$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_EnvoyGrpc$Type extends MessageType<GrpcService_EnvoyGrpc> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.EnvoyGrpc", [
            { no: 1, name: "cluster_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "authority", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "0", maxBytes: "16384", wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.EnvoyGrpc" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.EnvoyGrpc
 */
export const GrpcService_EnvoyGrpc = new GrpcService_EnvoyGrpc$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc$Type extends MessageType<GrpcService_GoogleGrpc> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc", [
            { no: 1, name: "target_uri", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "channel_credentials", kind: "message", T: () => GrpcService_GoogleGrpc_ChannelCredentials },
            { no: 3, name: "call_credentials", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GrpcService_GoogleGrpc_CallCredentials },
            { no: 4, name: "stat_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 5, name: "credentials_factory_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "config", kind: "message", T: () => Struct },
            { no: 7, name: "per_stream_buffer_limit_bytes", kind: "message", T: () => UInt32Value },
            { no: 8, name: "channel_args", kind: "message", T: () => GrpcService_GoogleGrpc_ChannelArgs }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc
 */
export const GrpcService_GoogleGrpc = new GrpcService_GoogleGrpc$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_SslCredentials$Type extends MessageType<GrpcService_GoogleGrpc_SslCredentials> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials", [
            { no: 1, name: "root_certs", kind: "message", T: () => DataSource },
            { no: 2, name: "private_key", kind: "message", T: () => DataSource, options: { "udpa.annotations.sensitive": true } },
            { no: 3, name: "cert_chain", kind: "message", T: () => DataSource }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.SslCredentials" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials
 */
export const GrpcService_GoogleGrpc_SslCredentials = new GrpcService_GoogleGrpc_SslCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_GoogleLocalCredentials$Type extends MessageType<GrpcService_GoogleGrpc_GoogleLocalCredentials> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials", [], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.GoogleLocalCredentials" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials
 */
export const GrpcService_GoogleGrpc_GoogleLocalCredentials = new GrpcService_GoogleGrpc_GoogleLocalCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_ChannelCredentials$Type extends MessageType<GrpcService_GoogleGrpc_ChannelCredentials> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials", [
            { no: 1, name: "ssl_credentials", kind: "message", oneof: "credentialSpecifier", T: () => GrpcService_GoogleGrpc_SslCredentials },
            { no: 2, name: "google_default", kind: "message", oneof: "credentialSpecifier", T: () => Empty },
            { no: 3, name: "local_credentials", kind: "message", oneof: "credentialSpecifier", T: () => GrpcService_GoogleGrpc_GoogleLocalCredentials }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.ChannelCredentials" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
 */
export const GrpcService_GoogleGrpc_ChannelCredentials = new GrpcService_GoogleGrpc_ChannelCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_CallCredentials$Type extends MessageType<GrpcService_GoogleGrpc_CallCredentials> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials", [
            { no: 1, name: "access_token", kind: "scalar", oneof: "credentialSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "google_compute_engine", kind: "message", oneof: "credentialSpecifier", T: () => Empty },
            { no: 3, name: "google_refresh_token", kind: "scalar", oneof: "credentialSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "service_account_jwt_access", kind: "message", oneof: "credentialSpecifier", T: () => GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials },
            { no: 5, name: "google_iam", kind: "message", oneof: "credentialSpecifier", T: () => GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials },
            { no: 6, name: "from_plugin", kind: "message", oneof: "credentialSpecifier", T: () => GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin },
            { no: 7, name: "sts_service", kind: "message", oneof: "credentialSpecifier", T: () => GrpcService_GoogleGrpc_CallCredentials_StsService }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.CallCredentials" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
 */
export const GrpcService_GoogleGrpc_CallCredentials = new GrpcService_GoogleGrpc_CallCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials$Type extends MessageType<GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials", [
            { no: 1, name: "json_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_lifetime_seconds", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials
 */
export const GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials = new GrpcService_GoogleGrpc_CallCredentials_ServiceAccountJWTAccessCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials$Type extends MessageType<GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials", [
            { no: 1, name: "authorization_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "authority_selector", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials
 */
export const GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials = new GrpcService_GoogleGrpc_CallCredentials_GoogleIAMCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin$Type extends MessageType<GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "typed_config", kind: "message", oneof: "configType", T: () => Any }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin
 */
export const GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin = new GrpcService_GoogleGrpc_CallCredentials_MetadataCredentialsFromPlugin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_CallCredentials_StsService$Type extends MessageType<GrpcService_GoogleGrpc_CallCredentials_StsService> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService", [
            { no: 1, name: "token_exchange_service_uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "resource", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "audience", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "scope", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "requested_token_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "subject_token_path", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 7, name: "subject_token_type", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 8, name: "actor_token_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "actor_token_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.GrpcService.GoogleGrpc.CallCredentials.StsService" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService
 */
export const GrpcService_GoogleGrpc_CallCredentials_StsService = new GrpcService_GoogleGrpc_CallCredentials_StsService$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_ChannelArgs$Type extends MessageType<GrpcService_GoogleGrpc_ChannelArgs> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs", [
            { no: 1, name: "args", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GrpcService_GoogleGrpc_ChannelArgs_Value } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs
 */
export const GrpcService_GoogleGrpc_ChannelArgs = new GrpcService_GoogleGrpc_ChannelArgs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcService_GoogleGrpc_ChannelArgs_Value$Type extends MessageType<GrpcService_GoogleGrpc_ChannelArgs_Value> {
    constructor() {
        super("envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value", [
            { no: 1, name: "string_value", kind: "scalar", oneof: "valueSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "int_value", kind: "scalar", oneof: "valueSpecifier", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
 */
export const GrpcService_GoogleGrpc_ChannelArgs_Value = new GrpcService_GoogleGrpc_ChannelArgs_Value$Type();
