import { graphApi } from 'Api/graphs';
import debounce from 'lodash.debounce';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { DependencyList, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppUtilsContext } from './context/AppUtilsContext';

export function useClientRect() {
  const [rect, setRect] = useState(null);

  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}
export function useClientRef() {
  const [nodeRef, setNodeRef] = useState(null);

  const ref = useCallback(node => {
    if (node !== null) {
      setNodeRef(node);
    }
  }, []);
  return [nodeRef, ref];
}

let useIdCounter = 0;
// A polyfill for the React 18 hook
export function useSoloId(id?: string) {
  return useMemo(() => id ?? `:${useIdCounter++}:`, [id]);
}

/* Ref below is gotten from React.useRef in related component */
export function useClickedOutside(ref: React.MutableRefObject<any>, onClickEvent: () => any) {
  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickEvent();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export function useLock(duration = 250): [() => boolean, (lock: boolean, onLockEnd?: () => void) => void] {
  const lockRef = React.useRef<boolean>();
  const timeoutRef = React.useRef<number>();

  // Clean up
  React.useEffect(
    () => () => {
      window.clearTimeout(timeoutRef.current);
    },

    []
  );

  function setLock(
    locked: boolean,
    onLockEnd?: () => void //onLockEnd can be used to simulate debouncing
  ) {
    lockRef.current = locked;

    window.clearTimeout(timeoutRef.current);
    if (locked) {
      timeoutRef.current = window.setTimeout(() => {
        lockRef.current = undefined;

        if (onLockEnd) {
          onLockEnd();
        }
      }, duration);
    }
  }

  return [() => !!lockRef.current, setLock];
}

export function useInterval(callback: () => any, delay: number) {
  const savedCallback = useRef<() => any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      } else {
        // eslint-disable-next-line no-console
        console.error('empty interval being called');
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useNamespaceClusterToWorkspaceMap() {
  const { data, error } = graphApi.useGetFilters();
  const [findWorkspaceMapping, setFindWorkspaceMapping] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!!data?.workspaceMap) {
      // This code reverses the lookup table to make a map with namespace.cluster key with a workspace value
      const newMap: typeof findWorkspaceMapping = {};
      Object.entries(data?.workspaceMap).forEach(([workspace, { workspaceClusters }]) => {
        Object.entries(workspaceClusters).forEach(([cluster, { clusterNamespaces }]) => {
          clusterNamespaces.forEach(namespace => {
            newMap[`${namespace}.${cluster}`] = workspace;
          });
        });
      });
      setFindWorkspaceMapping(newMap);
    }
  }, [data?.workspaceMap]);

  // `useCallback` required so the function can be used an a hook dependency
  const lookupWorkspaceName = useCallback(
    (ref?: ClusterObjectRef) => {
      if (ref) {
        return findWorkspaceMapping[`${ref.namespace}.${ref.clusterName}`];
      } else {
        return undefined;
      }
    },
    [findWorkspaceMapping]
  );

  return {
    map: findWorkspaceMapping,
    lookupWorkspaceName,
    error
  };
}

export function useDebouncedRefreshIndicator<T>(data: T, debounceTime?: number) {
  const initialLoad = useRef<boolean>(true);
  const [dataCached, setDataCached] = useState(data);
  const [showRefreshIndicator, setShowRefreshIndicator] = useState<boolean>(false);

  // refresh spinner should only dissapear after no loading for 300ms so we debounce it to prevent flickering
  const debouncedStopRefresh = useMemo(
    () =>
      debounce(() => {
        setShowRefreshIndicator(false);
      }, debounceTime ?? 300),
    []
  );

  // initialLoad is a reference for if data has been loaded before
  // determines if we render a refresh spinner or a full loading spinner
  // if data is being refreshed, instantly show the refresh indicator
  // if data has laoded, debounce hiding the spinner so it doesn't flicker
  useEffect(() => {
    if (initialLoad.current && !!data) {
      initialLoad.current = false;
    } else if (!initialLoad.current && !data) {
      setShowRefreshIndicator(true);
    } else {
      debouncedStopRefresh();
    }

    // update the data state if new data is defined
    // components can use this ref to prevent flickering when data comes back as undefined during loading
    if (data) {
      setDataCached(data);
    }
  }, [data]);

  // stop any debounced calls when the component unmounts
  useEffect(() => {
    return () => {
      debouncedStopRefresh.cancel();
    };
  }, []);

  return {
    initialLoad: initialLoad.current,
    showRefreshIndicator,
    debouncedStopRefresh,
    setShowRefreshIndicator,
    data: dataCached
  };
}

/**
 * This adds an event listener with `window.addEventListener` in a `useEffect` hook, and removes it in the callback.
 * @param skip Allows listener to be turned off
 */
export function useEventListener<Map extends WindowEventMap, K extends keyof Map>(
  element: Window,
  eventName: K,
  listener: (this: Window, ev: Map[K]) => any,
  dependencies?: DependencyList,
  skip?: boolean
): void;

/**
 * This adds an event listener with `document.addEventListener` in a `useEffect` hook, and removes it in the callback.
 * @param skip Allows listener to be turned off
 */
export function useEventListener<Map extends DocumentEventMap, K extends keyof Map>(
  element: Document,
  eventName: K,
  listener: (this: Document, ev: Map[K]) => any,
  dependencies?: DependencyList,
  skip?: boolean
): void;

/**
 * This adds an event listener with `element.addEventListener` in a `useEffect` hook, and removes it in the callback.
 * @param skip Allows listener to be turned off
 */
export function useEventListener<Map extends HTMLElementEventMap, K extends keyof Map>(
  element: HTMLElement | null,
  eventName: K,
  listener: (this: HTMLElement, ev: Map[K]) => any,
  dependencies?: DependencyList,
  skip?: boolean
): void;

// Actual hook for above function definitions
export function useEventListener<Elem extends Window | Document | HTMLElement>(
  element: Elem,
  eventName: string,
  listener: EventListenerOrEventListenerObject,
  dependencies: DependencyList = [],
  skip = false
) {
  useEffect(() => {
    // If element doesn't currently exist or hook isn't active then don't add a listener
    if (!element || !element.addEventListener || skip) return;

    element.addEventListener(eventName, listener);
    return () => {
      element.removeEventListener(eventName, listener);
    };
  }, [element, skip, ...dependencies]);
}

/**
 * Can be used to debounce a value, returning the new assigned value after `options.debounceMs`.
 * @param latestValue Usually the latest rendered value. This is returned after a period of time (`options.debounceMs`) with no changes. This is also the initial value.
 * @param options
 * @returns The value after a period of time (`options.debounceMs`) with no changes.
 */
export function useDebounce<T>(
  latestValue: T,
  options: { skip?: boolean; debounceMs?: number; extraDependencies?: React.DependencyList }
) {
  const [value, setValue] = useState<T>(latestValue);
  const debounceMs = options.debounceMs === undefined ? 200 : options.debounceMs;
  useEffect(() => {
    if (!!options.skip) return;
    // `changeTimeout` keeps track of the input delay.
    // Sets the real `value` after `debounceMs`
    const changeTimeout = setTimeout(() => setValue(latestValue), debounceMs);
    return () => {
      // Clears the timeout if the useEffect dependencies change.
      // (in which case a new timeout will be set)
      clearTimeout(changeTimeout);
    };
  }, [latestValue, setValue, options]);
  return value;
}

/**
 * This calls `callback` after the `dependencies` haven't changed in `timeoutMs` milliseconds.
 * The timeout object is returned.
 */
export const useTimeout = (callback: () => void, timeoutMs: number, dependencies: DependencyList = []) => {
  const theTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const newTimeout = setTimeout(callback, timeoutMs);
    theTimeout.current = newTimeout;
    return () => {
      clearTimeout(newTimeout);
      theTimeout.current = null;
    };
  }, dependencies);
  return theTimeout.current;
};

// This uses a context to get the window inner width and height so
// that we can re-use the same window resize event listener.
export const useWindowInnerWidth = () => {
  const { windowInnerWidth } = useContext(AppUtilsContext);
  return windowInnerWidth;
};
export const useWindowInnerHeight = () => {
  const { windowInnerHeight } = useContext(AppUtilsContext);
  return windowInnerHeight;
};

export const useDocumentTitle = (title: string | undefined) => {
  useEffect(() => {
    document.title = title ? `${title} - Gloo Platform` : 'Gloo Platform';
  }, [title]);
};
