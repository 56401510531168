import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloInputStyles } from 'Components/Common/Input/SoloInput.style';
import { hexAddAlpha } from 'Styles/colors';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export const MiniCardPageSizeOptions = [9, 12, 15, 30];

export namespace WorkspaceTabStyles {
  export const CardHeaderRightTools = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
    > div {
      flex: 1;
      min-width: 200px;
    }
    > div:first-of-type {
      flex: 0;
      min-width: 40px;
    }
    ${SoloInputStyles.InputContainer} {
      display: flex;
      align-items: center;
    }
    input,
    .ant-select-selection-placeholder {
      font-size: 16px;
    }
  `;

  export const ImportStatus = styled.span`
    display: flex;
    align-items: center;
    gap: 10px;
  `;

  export const CellIconText = styled.span(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      gap: 10px;

      ${svgBasics(21, theme.seaBlue)}
    `
  );

  export const MiniCardAddIcon = styled.div(
    ({ theme }) => css`
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 15px;
      width: 15px;
      top: 15px;
      right: 15px;
      border-radius: 8px;
      border: 2px solid ${theme.neptuneBlue};

      ${svgBasics(17)}
      svg {
        min-width: 17px;
        display: none; /* turned on by control in MiniCard */
      }
    `
  );

  export const MiniCardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
  `;
  interface MiniCardProps {
    height: number;
    maxHeight: number;
  }
  export const MiniCard = styled.div<MiniCardProps>(
    ({ theme, height, maxHeight }) => css`
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: ${height}px;
      max-height: ${maxHeight}px;
      padding: 16px;
      border-radius: 3px;
      border: 1px solid ${hexAddAlpha(theme.augustGrey, 0.33)};

      :hover {
        box-shadow: 0 0 5px ${hexAddAlpha(theme.augustGrey, 0.33)};

        ${MiniCardAddIcon} {
          border: none;
          svg {
            display: initial;
          }
        }
      }
    `
  );
  export const MiniCardTitle = styled.div`
    display: flex;
    font-size: 125%;
    margin: 0.25em 20px 1em 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  export const MiniCardDescription = styled.div`
    flex: 1;
    margin: 0 0 12px;
    p {
      /* This code allows for an ellipsis when text goes past a certain number of lines */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;
  export const MiniCardDate = styled.div(
    ({ theme }) => css`
      font-size: 80%;
      color: ${theme.juneGrey};
      margin: 0 0 12px;
    `
  );
  export const MiniCardIcons = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      gap: 4px;

      ${svgBasics({ width: 'auto', height: 20 }, theme.seaBlue)}
    `
  );
}
