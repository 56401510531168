import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetHttpBufferPolicyDetails } = policiesApi;

const policyType = PolicyType.HTTP_BUFFER;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const HttpBufferPolicyDetails = ({ clusterObjRef }: Props) => {
  di(useGetHttpBufferPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetHttpBufferPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <>
      <CardStyles.CardList>
        <DetailsHeaderCard
          icon={getPolicyIcon(policyType)}
          status={policyData?.status}
          objRef={clusterObjRef}
          workspaceRef={policyData?.ownerWorkspace?.ref}
          resourceType={getPolicyResourceType(policyType)}
        />
        <CardStyles.Card>
          <CardStyles.CardHeader>Overview</CardStyles.CardHeader>
          <div style={{ marginBottom: '20px' }}>
            <StatMiniCardList
              items={[
                {
                  icon: <Asset.MaxRequestBytes />,
                  title: 'Max Request Bytes',
                  data: <div>{policyData?.maxRequestBytes?.value}</div>
                }
              ]}
            />
          </div>
        </CardStyles.Card>

        <CardStyles.Card>
          <CardStyles.CardHeader>
            Routes Applied to
            <CardStyles.CardHeaderRightIcon>
              <Asset.RouteGroupIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>

          <RouteTablesListDisplay
            routeTables={policyData.routes?.routeTables}
            paging={routesPaging.pagingData}
            parentRef={clusterObjRef}
            policyType={policyType}
          />
        </CardStyles.Card>
      </CardStyles.CardList>
    </>
  );
};
