import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn } from 'Components/Common/SoloTableCells';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';

interface TableFields {
  key: string;
  name?: string;
  namespace?: string;
  clusterName?: string;
}
const columns: SoloColumnsType<TableFields> = [
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' })
];

export const PortalPublishedApisTable = ({ apis }: { apis: ClusterObjectRef[] }) => {
  const tableData = useMemo(() => apis.map(ref => ({ key: buildIdFromRef(ref), ...ref })), [apis]);
  return <SoloTable columns={columns} dataSource={tableData} />;
};
