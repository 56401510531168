import { Col, Row } from 'antd';
import { ErrorBoundaryCard } from 'Components/Common/ErrorBoundary';
import { di } from 'react-magnetic-di';
import {
  useDashboardInsightsLimitPassedNotification,
  useInsightEnablementChecks
} from 'utils/dashboard/dashboard-hooks';
import AnalysisAndInsightsCard from './Cards/AnalysisAndInsights/AnalysisAndInsightsCard';
import CertificatesExpiryCard from './Cards/CertificatesExpiry/CertificatesExpiryCard';
import ClusterServicesCard from './Cards/ClusterServices/ClusterServicesCard';
import FIPSCard from './Cards/FIPS/FIPSCard';
import DashboardHealthCard from './Cards/Health/DashboardHealthCard';
import ZeroTrustCard from './Cards/ZeroTrust/ZeroTrustCard';
import { DashboardStyles } from './DashboardLanding.style';

//
//
// Dashboard Sections
//
//

const AnalysisAndInsightsSection = () => (
  <DashboardStyles.LandingCardContainer>
    <ErrorBoundaryCard fallback='There was an error showing analysis and insights information...'>
      <AnalysisAndInsightsCard />
    </ErrorBoundaryCard>
  </DashboardStyles.LandingCardContainer>
);

const DashboardHealthSection = () => (
  <DashboardStyles.LandingCardContainer>
    <ErrorBoundaryCard fallback='There was an error showing Istio and Cilium health...'>
      <DashboardHealthCard />
    </ErrorBoundaryCard>
  </DashboardStyles.LandingCardContainer>
);

const ClusterServicesSection = () => (
  <DashboardStyles.LandingCardContainer>
    <ErrorBoundaryCard fallback='There was an error showing cluster services information...'>
      <ClusterServicesCard />
    </ErrorBoundaryCard>
  </DashboardStyles.LandingCardContainer>
);

const CertificatesExpirySection = () => (
  <DashboardStyles.LandingCardContainer>
    <ErrorBoundaryCard fallback='There was an error showing certificates expiration information...'>
      <CertificatesExpiryCard />
    </ErrorBoundaryCard>
  </DashboardStyles.LandingCardContainer>
);

const FIPSSection = () => (
  <DashboardStyles.LandingCardContainer>
    <ErrorBoundaryCard fallback='There was an error showing FIPS information...'>
      <FIPSCard />
    </ErrorBoundaryCard>
  </DashboardStyles.LandingCardContainer>
);

const ZeroTrustSection = () => (
  <DashboardStyles.LandingCardContainer>
    <ErrorBoundaryCard fallback='There was an error showing zero trust information...'>
      <ZeroTrustCard />
    </ErrorBoundaryCard>
  </DashboardStyles.LandingCardContainer>
);

//
//
// Dashboard
//
//

export const DashboardLandingWithData = () => {
  di(useInsightEnablementChecks);
  const { isIstioEnabledAndLicenced } = useInsightEnablementChecks();
  useDashboardInsightsLimitPassedNotification();

  if (!isIstioEnabledAndLicenced) {
    return (
      <DashboardStyles.LandingContainer data-testid='dashboard-landing'>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24}>
            <AnalysisAndInsightsSection />
          </Col>
          <Col xs={24} xl={12}>
            <DashboardHealthSection />
          </Col>
          <Col xs={24} xl={12}>
            <ClusterServicesSection />
          </Col>
        </Row>
      </DashboardStyles.LandingContainer>
    );
  }

  return (
    <DashboardStyles.LandingContainer data-testid='dashboard-landing'>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={24}>
          <AnalysisAndInsightsSection />
        </Col>
        <Col xs={24} xl={12}>
          <DashboardHealthSection />
        </Col>
        <Col xs={24} xl={12}>
          <CertificatesExpirySection />
        </Col>
        <Col xs={24} lg={24} xl={10}>
          <ClusterServicesSection />
        </Col>
        <Col xs={24} lg={12} xl={7}>
          <FIPSSection />
        </Col>
        <Col xs={24} lg={12} xl={7}>
          <ZeroTrustSection />
        </Col>
      </Row>
    </DashboardStyles.LandingContainer>
  );
};
