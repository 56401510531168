/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { ApiType } from "./common_pb";
import { PolicyType } from "./common_pb";
import { ImportStatus } from "./common_pb";
import { DestinationKind } from "../../../../api/gloo.solo.io/common/v2/selectors_pb";
import { Pagination } from "./common_pb";
import { Status } from "./common_pb";
import { WorkspaceStatus } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { ObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWorkspaceDetailsRequest
 */
export interface GetWorkspaceDetailsRequest {
    /**
     * @generated from protobuf field: string workspace_name = 1;
     */
    workspaceName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWorkspaceDetailsResponse
 */
export interface GetWorkspaceDetailsResponse {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef workspace_settings_ref = 2;
     */
    workspaceSettingsRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.WorkspaceStatus imported_workspaces = 3;
     */
    importedWorkspaces: WorkspaceStatus[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.WorkspaceStatus workspaces_exported_to = 4;
     */
    workspacesExportedTo: WorkspaceStatus[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.WorkspaceStatus workspaces_available_to_import = 5;
     */
    workspacesAvailableToImport: WorkspaceStatus[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetWorkspaceDetailsResponse.ClusterNamespaces cluster_namespaces = 6;
     */
    clusterNamespaces: GetWorkspaceDetailsResponse_ClusterNamespaces[];
    /**
     * @generated from protobuf field: bool federation_enabled = 7;
     */
    federationEnabled: boolean;
    /**
     * @generated from protobuf field: bool service_isolation_enabled = 8;
     */
    serviceIsolationEnabled: boolean;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 9;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWorkspaceDetailsResponse.ClusterNamespaces
 */
export interface GetWorkspaceDetailsResponse_ClusterNamespaces {
    /**
     * @generated from protobuf field: string cluster = 1;
     */
    cluster: string;
    /**
     * @generated from protobuf field: repeated string namespaces = 2;
     */
    namespaces: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsRequest
 */
export interface ListWorkspaceDestinationsRequest {
    /**
     * @generated from protobuf field: string workspace_name = 1;
     */
    workspaceName: string;
    /**
     * The list of destinations in the workspace should be paginated separately from the available destinations list
     *
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination workspace_destination_pagination = 2;
     */
    workspaceDestinationPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination available_destination_pagination = 3;
     */
    availableDestinationPagination?: Pagination;
    /**
     * Filters - Workspace
     *
     * @generated from protobuf field: string workspace_name_filter = 4;
     */
    workspaceNameFilter: string;
    /**
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationKind workspace_type_filter = 5;
     */
    workspaceTypeFilter: DestinationKind[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ImportStatus workspace_import_status_filter = 6;
     */
    workspaceImportStatusFilter: ImportStatus[];
    /**
     * Filters - Available
     *
     * @generated from protobuf field: string available_name_filter = 7;
     */
    availableNameFilter: string;
    /**
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationKind available_type_filter = 8;
     */
    availableTypeFilter: DestinationKind[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsResponse
 */
export interface ListWorkspaceDestinationsResponse {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * The destinations currently in the workspace natively or by import
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.WorkspaceDestination workspace_destinations = 2;
     */
    workspaceDestinations: ListWorkspaceDestinationsResponse_WorkspaceDestination[];
    /**
     * @generated from protobuf field: int32 total_workspace_destinations = 3;
     */
    totalWorkspaceDestinations: number;
    /**
     * The destinations that could be imported by the workspace
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.AvailableDestination available_destinations = 4;
     */
    availableDestinations: ListWorkspaceDestinationsResponse_AvailableDestination[];
    /**
     * @generated from protobuf field: int32 total_available_destinations = 5;
     */
    totalAvailableDestinations: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.WorkspaceDestination
 */
export interface ListWorkspaceDestinationsResponse_WorkspaceDestination {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 3;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: bool federated = 4;
     */
    federated: boolean;
    /**
     * @generated from protobuf field: repeated string hosts = 5;
     */
    hosts: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.AvailableDestination
 */
export interface ListWorkspaceDestinationsResponse_AvailableDestination {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
    /**
     * @generated from protobuf field: repeated string hosts = 3;
     */
    hosts: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesRequest
 */
export interface ListWorkspaceRoutesRequest {
    /**
     * @generated from protobuf field: string workspace_name = 1;
     */
    workspaceName: string;
    /**
     * The list of routes in the workspace should be paginated separately from the available routes list
     *
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination workspace_routes_pagination = 2;
     */
    workspaceRoutesPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination available_routes_pagination = 3;
     */
    availableRoutesPagination?: Pagination;
    /**
     * Filters - Workspace
     *
     * @generated from protobuf field: string workspace_name_filter = 4;
     */
    workspaceNameFilter: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.RouteType workspace_type_filter = 5;
     */
    workspaceTypeFilter: RouteType[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ImportStatus workspace_import_status_filter = 6;
     */
    workspaceImportStatusFilter: ImportStatus[];
    /**
     * Filters - Available
     *
     * @generated from protobuf field: string available_name_filter = 7;
     */
    availableNameFilter: string; // Only RouteTable are return for available routes currently, so no need for a type filter
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesResponse
 */
export interface ListWorkspaceRoutesResponse {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * The routes currently in the workspace natively or by import
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspaceRoutesResponse.WorkspaceRoute workspace_routes = 2;
     */
    workspaceRoutes: ListWorkspaceRoutesResponse_WorkspaceRoute[];
    /**
     * @generated from protobuf field: int32 total_workspace_routes = 3;
     */
    totalWorkspaceRoutes: number;
    /**
     * The routes that could be imported by the workspace
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspaceRoutesResponse.AvailableRoute available_routes = 4;
     */
    availableRoutes: ListWorkspaceRoutesResponse_AvailableRoute[];
    /**
     * @generated from protobuf field: int32 total_available_routes = 5;
     */
    totalAvailableRoutes: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesResponse.WorkspaceRoute
 */
export interface ListWorkspaceRoutesResponse_WorkspaceRoute {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_ref = 1;
     */
    routeRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.RouteType type = 2;
     */
    type: RouteType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 3;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * @generated from protobuf field: repeated string hosts = 5;
     */
    hosts: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesResponse.AvailableRoute
 */
export interface ListWorkspaceRoutesResponse_AvailableRoute {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef routeRef = 1;
     */
    routeRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.RouteType type = 2;
     */
    type: RouteType;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspacePoliciesRequest
 */
export interface ListWorkspacePoliciesRequest {
    /**
     * @generated from protobuf field: string workspace_name = 1;
     */
    workspaceName: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination workspace_policy_pagination = 2;
     */
    workspacePolicyPagination?: Pagination;
    /**
     * Filters - Workspace
     *
     * @generated from protobuf field: string workspace_name_filter = 3;
     */
    workspaceNameFilter: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.PolicyType workspace_type_filter = 4;
     */
    workspaceTypeFilter: PolicyType[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspacePoliciesResponse
 */
export interface ListWorkspacePoliciesResponse {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * The policies currently in the workspace
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspacePoliciesResponse.WorkspacePolicy workspace_policies = 2;
     */
    workspacePolicies: ListWorkspacePoliciesResponse_WorkspacePolicy[];
    /**
     * @generated from protobuf field: int32 total_workspace_policies = 3;
     */
    totalWorkspacePolicies: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspacePoliciesResponse.WorkspacePolicy
 */
export interface ListWorkspacePoliciesResponse_WorkspacePolicy {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyType type = 2;
     */
    type: PolicyType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceApisRequest
 */
export interface ListWorkspaceApisRequest {
    /**
     * @generated from protobuf field: string workspace_name = 1;
     */
    workspaceName: string;
    /**
     * There are two separate lists to paginate.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination workspace_apis_pagination = 2;
     */
    workspaceApisPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination available_apis_pagination = 3;
     */
    availableApisPagination?: Pagination;
    /**
     * Filters - Workspace
     *
     * @generated from protobuf field: string workspace_name_filter = 4;
     */
    workspaceNameFilter: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ApiType workspace_type_filter = 5;
     */
    workspaceTypeFilter: ApiType[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ImportStatus workspace_import_status_filter = 6;
     */
    workspaceImportStatusFilter: ImportStatus[];
    /**
     * Filters - Available
     *
     * @generated from protobuf field: string available_name_filter = 7;
     */
    availableNameFilter: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ApiType available_type_filter = 8;
     */
    availableTypeFilter: ApiType[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceApisResponse
 */
export interface ListWorkspaceApisResponse {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * The apis currently in the workspace natively or by import
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspaceApisResponse.WorkspaceApi workspace_apis = 2;
     */
    workspaceApis: ListWorkspaceApisResponse_WorkspaceApi[];
    /**
     * @generated from protobuf field: int32 total_workspace_apis = 3;
     */
    totalWorkspaceApis: number;
    /**
     * The apis that could be imported by the workspace
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspaceApisResponse.AvailableApi available_apis = 4;
     */
    availableApis: ListWorkspaceApisResponse_AvailableApi[];
    /**
     * @generated from protobuf field: int32 total_available_apis = 5;
     */
    totalAvailableApis: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceApisResponse.WorkspaceApi
 */
export interface ListWorkspaceApisResponse_WorkspaceApi {
    /**
     * This is the istio_route_name for GraphQL APIs (specifying the route that they are on).
     *
     * @generated from protobuf field: string route_name = 1;
     */
    routeName: string;
    /**
     * the route table that contains this API
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 2;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ApiType api_type = 3;
     */
    apiType: ApiType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 4;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspaceApisResponse.AvailableApi
 */
export interface ListWorkspaceApisResponse_AvailableApi {
    /**
     * This is the istio_route_name for GraphQL APIs (specifying the route that they are on).
     *
     * @generated from protobuf field: string route_name = 1;
     */
    routeName: string;
    /**
     * the route table that contains this API
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 2;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ApiType api_type = 3;
     */
    apiType: ApiType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.RouteType
 */
export enum RouteType {
    /**
     * @generated from protobuf enum value: UNKNOWN_ROUTE_TYPE = 0;
     */
    UNKNOWN_ROUTE_TYPE = 0,
    /**
     * @generated from protobuf enum value: VIRTUAL_GATEWAY = 1;
     */
    VIRTUAL_GATEWAY = 1,
    /**
     * @generated from protobuf enum value: ROUTE_TABLE = 2;
     */
    ROUTE_TABLE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GetWorkspaceDetailsRequest$Type extends MessageType<GetWorkspaceDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetWorkspaceDetailsRequest", [
            { no: 1, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWorkspaceDetailsRequest
 */
export const GetWorkspaceDetailsRequest = new GetWorkspaceDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWorkspaceDetailsResponse$Type extends MessageType<GetWorkspaceDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetWorkspaceDetailsResponse", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "workspace_settings_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "imported_workspaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkspaceStatus },
            { no: 4, name: "workspaces_exported_to", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkspaceStatus },
            { no: 5, name: "workspaces_available_to_import", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkspaceStatus },
            { no: 6, name: "cluster_namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetWorkspaceDetailsResponse_ClusterNamespaces },
            { no: 7, name: "federation_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "service_isolation_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWorkspaceDetailsResponse
 */
export const GetWorkspaceDetailsResponse = new GetWorkspaceDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWorkspaceDetailsResponse_ClusterNamespaces$Type extends MessageType<GetWorkspaceDetailsResponse_ClusterNamespaces> {
    constructor() {
        super("rpc.gloo.solo.io.GetWorkspaceDetailsResponse.ClusterNamespaces", [
            { no: 1, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWorkspaceDetailsResponse.ClusterNamespaces
 */
export const GetWorkspaceDetailsResponse_ClusterNamespaces = new GetWorkspaceDetailsResponse_ClusterNamespaces$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceDestinationsRequest$Type extends MessageType<ListWorkspaceDestinationsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceDestinationsRequest", [
            { no: 1, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "workspace_destination_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "available_destination_pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "workspace_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workspace_type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 6, name: "workspace_import_status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 7, name: "available_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "available_type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsRequest
 */
export const ListWorkspaceDestinationsRequest = new ListWorkspaceDestinationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceDestinationsResponse$Type extends MessageType<ListWorkspaceDestinationsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceDestinationsResponse", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "workspace_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspaceDestinationsResponse_WorkspaceDestination },
            { no: 3, name: "total_workspace_destinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "available_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspaceDestinationsResponse_AvailableDestination },
            { no: 5, name: "total_available_destinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsResponse
 */
export const ListWorkspaceDestinationsResponse = new ListWorkspaceDestinationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceDestinationsResponse_WorkspaceDestination$Type extends MessageType<ListWorkspaceDestinationsResponse_WorkspaceDestination> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.WorkspaceDestination", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 4, name: "federated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.WorkspaceDestination
 */
export const ListWorkspaceDestinationsResponse_WorkspaceDestination = new ListWorkspaceDestinationsResponse_WorkspaceDestination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceDestinationsResponse_AvailableDestination$Type extends MessageType<ListWorkspaceDestinationsResponse_AvailableDestination> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.AvailableDestination", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceDestinationsResponse.AvailableDestination
 */
export const ListWorkspaceDestinationsResponse_AvailableDestination = new ListWorkspaceDestinationsResponse_AvailableDestination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceRoutesRequest$Type extends MessageType<ListWorkspaceRoutesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceRoutesRequest", [
            { no: 1, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "workspace_routes_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "available_routes_pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "workspace_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workspace_type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.RouteType", RouteType] },
            { no: 6, name: "workspace_import_status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 7, name: "available_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesRequest
 */
export const ListWorkspaceRoutesRequest = new ListWorkspaceRoutesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceRoutesResponse$Type extends MessageType<ListWorkspaceRoutesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceRoutesResponse", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "workspace_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspaceRoutesResponse_WorkspaceRoute },
            { no: 3, name: "total_workspace_routes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "available_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspaceRoutesResponse_AvailableRoute },
            { no: 5, name: "total_available_routes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesResponse
 */
export const ListWorkspaceRoutesResponse = new ListWorkspaceRoutesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceRoutesResponse_WorkspaceRoute$Type extends MessageType<ListWorkspaceRoutesResponse_WorkspaceRoute> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceRoutesResponse.WorkspaceRoute", [
            { no: 1, name: "route_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.RouteType", RouteType] },
            { no: 3, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesResponse.WorkspaceRoute
 */
export const ListWorkspaceRoutesResponse_WorkspaceRoute = new ListWorkspaceRoutesResponse_WorkspaceRoute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceRoutesResponse_AvailableRoute$Type extends MessageType<ListWorkspaceRoutesResponse_AvailableRoute> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceRoutesResponse.AvailableRoute", [
            { no: 1, name: "routeRef", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.RouteType", RouteType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceRoutesResponse.AvailableRoute
 */
export const ListWorkspaceRoutesResponse_AvailableRoute = new ListWorkspaceRoutesResponse_AvailableRoute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspacePoliciesRequest$Type extends MessageType<ListWorkspacePoliciesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspacePoliciesRequest", [
            { no: 1, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "workspace_policy_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "workspace_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "workspace_type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.PolicyType", PolicyType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspacePoliciesRequest
 */
export const ListWorkspacePoliciesRequest = new ListWorkspacePoliciesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspacePoliciesResponse$Type extends MessageType<ListWorkspacePoliciesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspacePoliciesResponse", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "workspace_policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspacePoliciesResponse_WorkspacePolicy },
            { no: 3, name: "total_workspace_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspacePoliciesResponse
 */
export const ListWorkspacePoliciesResponse = new ListWorkspacePoliciesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspacePoliciesResponse_WorkspacePolicy$Type extends MessageType<ListWorkspacePoliciesResponse_WorkspacePolicy> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspacePoliciesResponse.WorkspacePolicy", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.PolicyType", PolicyType] },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspacePoliciesResponse.WorkspacePolicy
 */
export const ListWorkspacePoliciesResponse_WorkspacePolicy = new ListWorkspacePoliciesResponse_WorkspacePolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceApisRequest$Type extends MessageType<ListWorkspaceApisRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceApisRequest", [
            { no: 1, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "workspace_apis_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "available_apis_pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "workspace_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workspace_type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.ApiType", ApiType] },
            { no: 6, name: "workspace_import_status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 7, name: "available_name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "available_type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.ApiType", ApiType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceApisRequest
 */
export const ListWorkspaceApisRequest = new ListWorkspaceApisRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceApisResponse$Type extends MessageType<ListWorkspaceApisResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceApisResponse", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "workspace_apis", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspaceApisResponse_WorkspaceApi },
            { no: 3, name: "total_workspace_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "available_apis", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspaceApisResponse_AvailableApi },
            { no: 5, name: "total_available_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceApisResponse
 */
export const ListWorkspaceApisResponse = new ListWorkspaceApisResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceApisResponse_WorkspaceApi$Type extends MessageType<ListWorkspaceApisResponse_WorkspaceApi> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceApisResponse.WorkspaceApi", [
            { no: 1, name: "route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "api_type", kind: "enum", T: () => ["rpc.gloo.solo.io.ApiType", ApiType] },
            { no: 4, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceApisResponse.WorkspaceApi
 */
export const ListWorkspaceApisResponse_WorkspaceApi = new ListWorkspaceApisResponse_WorkspaceApi$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspaceApisResponse_AvailableApi$Type extends MessageType<ListWorkspaceApisResponse_AvailableApi> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspaceApisResponse.AvailableApi", [
            { no: 1, name: "route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "api_type", kind: "enum", T: () => ["rpc.gloo.solo.io.ApiType", ApiType] },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspaceApisResponse.AvailableApi
 */
export const ListWorkspaceApisResponse_AvailableApi = new ListWorkspaceApisResponse_AvailableApi$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.WorkspacesApi
 */
export const WorkspacesApi = new ServiceType("rpc.gloo.solo.io.WorkspacesApi", [
    { name: "GetWorkspaceDetails", options: {}, I: GetWorkspaceDetailsRequest, O: GetWorkspaceDetailsResponse },
    { name: "ListWorkspaceDestinations", options: {}, I: ListWorkspaceDestinationsRequest, O: ListWorkspaceDestinationsResponse },
    { name: "ListWorkspaceRoutes", options: {}, I: ListWorkspaceRoutesRequest, O: ListWorkspaceRoutesResponse },
    { name: "ListWorkspacePolicies", options: {}, I: ListWorkspacePoliciesRequest, O: ListWorkspacePoliciesResponse },
    { name: "ListWorkspaceApis", options: {}, I: ListWorkspaceApisRequest, O: ListWorkspaceApisResponse }
]);
