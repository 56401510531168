import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloLogViewerStyles } from 'Components/Common/SoloLogViewer/SoloLogViewer.style';
import { graphZIndex } from 'Styles/graph';
import { usageInfoTabWidth } from '../General/UsageInfoTab/UsageInfoTab.style';

export namespace GraphFiltersStyles {
  type ContainerProps = {
    leftSpacingNeeded: boolean;
  };
  export const Container = styled.div<ContainerProps>(
    ({ theme, leftSpacingNeeded }) => css`
      background: ${theme.januaryGrey};
      border-top: 1px solid ${theme.aprilGrey};

      transition: padding 0.3s ease-out;
      padding-left: ${leftSpacingNeeded ? usageInfoTabWidth : '0'}px;
    `
  );

  export const HeaderToolsFilterByText = styled.span(
    ({ theme }) => css`
      color: ${theme.septemberGrey};
      padding: 0 5px 0 10px;
      font-weight: 500;
    `
  );

  export const HeaderTools = styled.div`
    display: flex;
    align-items: center;
    padding-left: 5px;
    min-height: 59px;

    > span {
      padding: 12px 5px;
    }

    label {
      margin: 0;
      cursor: pointer;
    }

    .ant-select,
    .ant-select *,
    .ant-select .ant-select-selector::after {
      line-height: 1 !important;
    }

    .ant-select-arrow {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  `;

  export const DropdownContainer = styled.span`
    position: relative;
  `;

  export const EmptyPromptBox = styled(SoloLogViewerStyles.EmptyPromptBox)`
    top: 80%;
    left: -50%;
    right: -50%;
    z-index: ${graphZIndex.emptyPromptBox};
  `;

  export const RightSideTools = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 12px 20px;

    svg {
      height: 20px;
      vertical-align: middle;
    }
  `;

  export const TagsListHolder = styled.div(
    ({ theme }) => css`
      min-height: 53px;
      background: ${theme.januaryGrey};
    `
  );
}
