/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../../../../../../../../google/protobuf/duration_pb";
import { Empty } from "../../../../../../../../google/protobuf/empty_pb";
import { HTTPRequestMatcher } from "../../../../api/gloo.solo.io/common/v2/http_matchers_pb";
import { StringValue } from "../../../../../../../../google/protobuf/wrappers_pb";
import { Value } from "../../../../../../../../google/protobuf/struct_pb";
import { DestinationReference } from "../../../../api/gloo.solo.io/common/v2/references_pb";
import { WorkspaceStatus } from "./common_pb";
import { WorkspaceDependencies } from "./common_pb";
import { Pagination } from "./common_pb";
import { DestinationKind } from "../../../../api/gloo.solo.io/common/v2/selectors_pb";
import { ImportStatus } from "./common_pb";
import { Status } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphqlApiSummary
 */
export interface GraphqlApiSummary {
    /**
     * @generated from protobuf field: string istio_route_name = 1;
     */
    istioRouteName: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GraphqlApiType type = 2;
     */
    type: GraphqlApiType;
    /**
     * @generated from protobuf field: int32 num_subgraphs = 3;
     */
    numSubgraphs: number;
    /**
     * the RouteTable that contains this graphql api
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 4;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * Gateway refs
     *
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef gateway_refs = 5;
     */
    gatewayRefs: ClusterObjectRef[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 7;
     */
    importStatus: ImportStatus;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphqlResolver
 */
export interface GraphqlResolver {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 3;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphqlResolvers
 */
export interface GraphqlResolvers {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GraphqlResolver resolver = 1;
     */
    resolver: GraphqlResolver[];
    /**
     * @generated from protobuf field: int32 total_resolvers = 2;
     */
    totalResolvers: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsRequest
 */
export interface GetGraphqlApiExplorerSettingsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse
 */
export interface GetGraphqlApiExplorerSettingsResponse {
    /**
     * Returns the options for what gateways to select.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse.GatewayOption gateway_options = 1;
     */
    gatewayOptions: GetGraphqlApiExplorerSettingsResponse_GatewayOption[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse.GatewayOption
 */
export interface GetGraphqlApiExplorerSettingsResponse_GatewayOption {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef gateway_ref = 1;
     */
    gatewayRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
    /**
     * Optional error message while getting this gateway's ingress status
     * (e.g. no load balancer, or any ingress port status errors).
     *
     * @generated from protobuf field: string error = 3;
     */
    error: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphqlApiRequest
 */
export interface GetGraphqlApiRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string istio_route_name = 2;
     */
    istioRouteName: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination resolver_pagination = 4;
     */
    resolverPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphqlApiResponse
 */
export interface GetGraphqlApiResponse {
    /**
     * @generated from protobuf field: string schema_definition = 1;
     */
    schemaDefinition: string;
    /**
     * @generated from protobuf oneof: graphql
     */
    graphql: {
        oneofKind: "stitchedSchema";
        /**
         * GraphQLStitchedSchema resource.
         *
         * @generated from protobuf field: rpc.gloo.solo.io.StitchedSchema stitched_schema = 2;
         */
        stitchedSchema: StitchedSchema;
    } | {
        oneofKind: "executableSchema";
        /**
         * Mirror of the user-facing api proto, but with all the object refs resolved.
         *
         * @generated from protobuf field: rpc.gloo.solo.io.ExecutableSchema executable_schema = 3;
         */
        executableSchema: ExecutableSchema;
    } | {
        oneofKind: undefined;
    };
    /**
     * Gateway refs
     *
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef gateway_refs = 4;
     */
    gatewayRefs: ClusterObjectRef[];
    /**
     * the RouteTable that contains this graphql api
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 5;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * the GraphqlSchema that contains this graphql api
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef graphql_schema_ref = 6;
     */
    graphqlSchemaRef?: ClusterObjectRef;
    /**
     * the GraphqlStitchedSchema that contains this graphql api
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef graphql_stitched_schema_ref = 7;
     */
    graphqlStitchedSchemaRef?: ClusterObjectRef;
    /**
     * The ApiDoc this schema refers to
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef api_doc_ref = 8;
     */
    apiDocRef?: ClusterObjectRef;
    /**
     * The GraphqlResolverMaps that the schema refers to
     *
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef graphql_resolver_map_refs = 9;
     */
    graphqlResolverMapRefs: ClusterObjectRef[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceDependencies dependencies = 10;
     */
    dependencies?: WorkspaceDependencies;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 11;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 12;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.StitchedSchema
 */
export interface StitchedSchema {
    /**
     * List of GraphQL Subschemas that compose this GraphQL stitched schema.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.StitchedSchema.Subschema subschemas = 1;
     */
    subschemas: StitchedSchema_Subschema[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.StitchedSchema.Settings settings = 2;
     */
    settings?: StitchedSchema_Settings;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.StitchedSchema.Settings
 */
export interface StitchedSchema_Settings {
    /**
     * @generated from protobuf field: bool enable_introspection = 1;
     */
    enableIntrospection: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.StitchedSchema.Subschema
 */
export interface StitchedSchema_Subschema {
    /**
     * The RouteTable that contains the subschema graphql api.
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * The istio route name that the subschema graphql api is on.
     *
     * @generated from protobuf field: string istio_route_name = 2;
     */
    istioRouteName: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GraphqlApiType type = 3;
     */
    type: GraphqlApiType;
    /**
     *
     * Type merge configuration for this subschema. Let's say this subschema is a Users service schema
     * and provides the User type (with a query to fetch a user given the username)
     * ```gql
     * type Query {
     * GetUser(username: String): User
     * }
     * type User {
     * username: String
     * firstName: String
     * lastName: String
     * }
     * ```
     * and another subschema, e.g. Reviews schema, may have a partial User type:
     * ```gql
     * type Review {
     * author: User
     * }
     * type User {
     * username: String
     * }
     * ```
     * We want to provide the relevant information from this Users service schema,
     * so that another API that can give us a partial User type (with the username) will then
     * be able to have access to the full user type. With the correct type merging config under the Users subschema, e.g.:
     * ```yaml
     * type_merge:
     * User:
     * selection_set: '{ username }'
     * query_name: 'GetUser'
     * args:
     * username: username
     * ```
     * the stitched schema will now be able to provide the full user type to all types that require it. In this case,
     * we can now get the first name of an author from the Review.author field even though the Reviews schema doesn't
     * provide the full User type.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.StitchedSchema.Subschema.TypeMergeConfig> type_merge = 4;
     */
    typeMerge: {
        [key: string]: StitchedSchema_Subschema_TypeMergeConfig;
    };
    /**
     * the GraphqlSchema that contains this graphql api
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef graphql_schema_ref = 5;
     */
    graphqlSchemaRef?: ClusterObjectRef;
    /**
     * the GraphqlStitchedSchema that contains this graphql api
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef graphql_stitched_schema_ref = 6;
     */
    graphqlStitchedSchemaRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string schema_definition = 7;
     */
    schemaDefinition: string;
    /**
     * @generated from protobuf field: string schema_definition_error = 8;
     */
    schemaDefinitionError: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.StitchedSchema.Subschema.TypeMergeConfig
 */
export interface StitchedSchema_Subschema_TypeMergeConfig {
    /**
     * This specifies one or more key fields required from other services to perform this query.
     * Query planning will automatically resolve these fields from other subschemas in dependency order.
     * This is a graphql selection set specified as a string
     * e.g. '{ username }'
     *
     * @generated from protobuf field: string selection_set = 1;
     */
    selectionSet: string;
    /**
     * specifies the root field from this subschema used to request the resolved type
     *
     * @generated from protobuf field: string query_name = 2;
     */
    queryName: string; //       this provides the schema stitching engine the format to turn the initial object representation
    //       to query arguments
    //       so if the GetUser query was defined as
    // 
    //      ```gql
    //      input UserSearch {
    //        username: String
    //      }
    // 
    //      type Query {
    //        GetUser(user_search: UserSearch): User
    //      }
    //      ```
    //      we would want to set the user query argument with the correct username from an object.
    //      we can do that by setting the args as:
    //      ```yaml
    //      args:
    //        user_search.username: username
    //      ```
    //      where `user_search.username` is the "setter" path that we are setting the argument input value at and
    //      `username` is the "extraction" path that we are extracting from an object, such as `{"username": "wpatel"}`.
    /**
     * @generated from protobuf field: map<string, string> args = 3;
     */
    args: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ExecutableSchema
 */
export interface ExecutableSchema {
    /**
     * @generated from protobuf oneof: executable_schema
     */
    executableSchema: {
        oneofKind: "proxied";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.GraphQLProxied proxied = 2;
         */
        proxied: GraphQLProxied;
    } | {
        oneofKind: "resolved";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.GraphQLResolved resolved = 3;
         */
        resolved: GraphQLResolved;
    } | {
        oneofKind: undefined;
    };
    /**
     * Applied destinations
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.DestinationWithImportStatus applied_destinations = 5;
     */
    appliedDestinations: DestinationWithImportStatus[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DestinationWithImportStatus
 */
export interface DestinationWithImportStatus {
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationReference destination_ref = 1;
     */
    destinationRef?: DestinationReference;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 2;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * List of destinations that GraphQL requests can be proxied to. The external GraphQL server must
 * be compliant with the [June 2018 GraphQL specification](https://spec.graphql.org/June2018).
 * Though this is a list, only the first referenced destination will be used until fail-over and load balancing
 * is supported between multiple GraphQL destinations.
 *
 * @generated from protobuf message rpc.gloo.solo.io.GraphQLProxied
 */
export interface GraphQLProxied {
    /**
     * Declare variables that are used in variable transformations in the fields below for the GraphQL request to a remote server.
     * The keys to this map are the name of the variables. See the variable transformations specifically
     * for how to reference these variables.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.GraphQLProxied.RequestExtraction> variables = 2;
     */
    variables: {
        [key: string]: GraphQLProxied_RequestExtraction;
    };
    /**
     * map of header name to a transformation on extracted variables which are declared
     * in the `variables` field.
     * The transformation must result in a string value, or an error will be sent back to the client.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.VariableTransformation> headers = 3;
     */
    headers: {
        [key: string]: VariableTransformation;
    };
    /**
     * map of query parameter name to a transformation on extracted variables which are declared
     * in the `variables` field.
     * The transformation must result in a string value, or an error will be sent back to the client.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.VariableTransformation> query_params = 4;
     */
    queryParams: {
        [key: string]: VariableTransformation;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GraphQLProxied.Settings settings = 5;
     */
    settings?: GraphQLProxied_Settings;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphQLProxied.Settings
 */
export interface GraphQLProxied_Settings {
    /**
     * @generated from protobuf field: bool enable_introspection = 1;
     */
    enableIntrospection: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphQLProxied.RequestExtraction
 */
export interface GraphQLProxied_RequestExtraction {
    /**
     * @generated from protobuf oneof: extraction
     */
    extraction: {
        oneofKind: "dynamicMetadata";
        /**
         * Extract the value from envoy dynamic metadata. The extracted value is an object.
         *
         * @generated from protobuf field: rpc.gloo.solo.io.DynamicMetadataExtraction dynamic_metadata = 1;
         */
        dynamicMetadata: DynamicMetadataExtraction;
    } | {
        oneofKind: "requestHeader";
        /**
         * Extract the value from the GraphQL HTTP request header. The extracted value is a string.
         * If the header doesn't exist, an empty string will be used.
         *
         * @generated from protobuf field: string request_header = 2;
         */
        requestHeader: string;
    } | {
        oneofKind: "jsonValue";
        /**
         * Assigns the variable to a JSON value specified here.
         *
         * +kubebuilder:validation:Type=object
         *
         * @generated from protobuf field: google.protobuf.Value json_value = 3;
         */
        jsonValue: Value;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.VariableTransformation
 */
export interface VariableTransformation {
    /**
     * @generated from protobuf oneof: transformation
     */
    transformation: {
        oneofKind: "variable";
        /**
         * The name of a variable declared in the `variables` field. The value of the variable
         * is returned with no transformation.
         *
         * @generated from protobuf field: string variable = 1;
         */
        variable: string;
    } | {
        oneofKind: "jq";
        /**
         * The [jq](https://stedolan.github.io/jq/manual/) filter which is used to provide a value.
         * variables defined in `variables` can be used in the jq filter via regular jq variable syntax.
         * For example, a variable named "userIdHeader" can be used in a jq filter as `$userIdHeader`
         * Jq filters must only result in one value, or an error will be sent back to the client.
         * For example, for the input `[1,2,3]`, the jq filter `.[]` is not a valid jq filter as it results
         * in multiple jq results. However, the jq filter '. | join(",")' is valid as it results in one result: "1,2,3".
         * Refer to the [jq manual](https://stedolan.github.io/jq/manual/) for jq syntax and tips.
         * --- Using variables ---
         * Variables that are declared in the `variables` field are available at the top level of the input JSON object to the
         * jq filter.
         * For example, if the following variables have been declared:
         * ```yaml
         * variables:
         *   userIdHeader:
         *     request_header: x-user-id
         *   resolverResultVar:
         *     resolver_result: {}
         * ```
         * then the input object to the `jq` filter would be
         * ```json
         * {
         *  "userIdHeader": <x-user-id header value from GraphQL request>,
         *  "resolverResultVar": { .. the resolver result .. }
         * }
         * ```
         * --- Using a jq filter ---
         * Given the following input object to the `jq` transformation, we can use a filter to transform the values.
         * ```json
         * {
         *  "userIdHeader": "john_doe123",
         *  "resolverResultVar": { "data": {"name": "John Doe"} }
         * }
         * ```
         * If the `jq` filter is defined as
         * ```
         * "User: " + .userIdHeader + ", Name: " + .resolverResultVar.data.name
         * ```
         * the result of the jq transformation would be
         * ```
         * "User john_doe123, Name: John Doe"
         * ```
         *
         * @generated from protobuf field: string jq = 2;
         */
        jq: string;
    } | {
        oneofKind: "value";
        /**
         * Static JSON value.
         *
         * +kubebuilder:validation:Type=object
         *
         * @generated from protobuf field: google.protobuf.Value value = 3;
         */
        value: Value;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphQLResolved
 */
export interface GraphQLResolved {
    /**
     * Map of types -> fields -> resolver
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.Resolution> types = 1;
     */
    types: {
        [key: string]: Resolution;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GraphQLResolved.Settings settings = 2;
     */
    settings?: GraphQLResolved_Settings;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphQLResolved.Settings
 */
export interface GraphQLResolved_Settings {
    /**
     * don't show if this is 0
     *
     * @generated from protobuf field: int32 max_depth = 1;
     */
    maxDepth: number;
    /**
     * @generated from protobuf field: bool enable_introspection = 2;
     */
    enableIntrospection: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.Resolution
 */
export interface Resolution {
    /**
     * Map of GraphQL fields for the type to resolutions.
     * The key must match the GraphQL field name and is case-sensitive.
     * Fields that don't have resolvers will use the GraphQL default resolver,
     * which returns the field in the parent object with the same name as the GraphQL field.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.Resolution.Resolvers> fields = 1;
     */
    fields: {
        [key: string]: Resolution_Resolvers;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.Resolution.Resolvers
 */
export interface Resolution_Resolvers {
    /**
     * Resolver for this field
     *
     * @generated from protobuf field: rpc.gloo.solo.io.Resolution.Resolvers.Resolver resolver = 1;
     */
    resolver?: Resolution_Resolvers_Resolver;
    /**
     * Variables that can be used as a part of transformations for this field's resolution.
     * The key is the name of the variable, which is directly used in the transformation.
     * The value defines where the variable value is extracted from on the GraphQL request.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.Extraction> variables = 2;
     */
    variables: {
        [key: string]: Extraction;
    };
    /**
     * The stats prefix which will be used for this resolver.
     * If empty, will generate a stats prefix ${RESOLVER_NAME}
     *
     * @generated from protobuf field: google.protobuf.StringValue stat_prefix = 4;
     */
    statPrefix?: StringValue;
}
/**
 * Not yet supported by the data plane
 * This is included as a place holder
 * But should not be used as it serves no function yet.
 *
 * @generated from protobuf message rpc.gloo.solo.io.Resolution.Resolvers.Resolver
 */
export interface Resolution_Resolvers_Resolver {
    /**
     * @generated from protobuf oneof: optional_matcher
     */
    optionalMatcher: {
        oneofKind: "httpMatcher";
        /**
         * @generated from protobuf field: common.gloo.solo.io.HTTPRequestMatcher http_matcher = 1;
         */
        httpMatcher: HTTPRequestMatcher;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf oneof: resolver
     */
    resolver: {
        oneofKind: "restResolver";
        /**
         * Resolves values by making a HTTP/2 request to a HTTP/REST destination.
         *
         * @generated from protobuf field: rpc.gloo.solo.io.RestResolver rest_resolver = 3;
         */
        restResolver: RestResolver;
    } | {
        oneofKind: "grpcResolver";
        /**
         * Resolves fields with a gRPC destination.
         *
         * @generated from protobuf field: rpc.gloo.solo.io.GrpcResolver grpc_resolver = 2;
         */
        grpcResolver: GrpcResolver;
    } | {
        oneofKind: undefined;
    };
    /**
     * Use a transformation on extracted variables to transform the upstream REST response.
     * By default, the transformation is empty and the destination response is left as is before
     * being passed into the GraphQL execution engine.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.VariableTransformation resolver_result_transform = 10;
     */
    resolverResultTransform?: VariableTransformation;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.Extraction
 */
export interface Extraction {
    /**
     * @generated from protobuf oneof: extraction_type
     */
    extractionType: {
        oneofKind: "requestHeader";
        /**
         * Extract the value from the GraphQL HTTP request header. The extracted value is a string.
         * If the header doesn't exist, an empty string will be used.
         *
         * @generated from protobuf field: string request_header = 1;
         */
        requestHeader: string;
    } | {
        oneofKind: "dynamicMetadata";
        /**
         * Extract the value from envoy dynamic metadata. The extracted value is an object.
         *
         * @generated from protobuf field: rpc.gloo.solo.io.DynamicMetadataExtraction dynamic_metadata = 2;
         */
        dynamicMetadata: DynamicMetadataExtraction;
    } | {
        oneofKind: "graphqlParent";
        /**
         * Assigns the variable to the parent object
         *
         * @generated from protobuf field: google.protobuf.Empty graphql_parent = 3;
         */
        graphqlParent: Empty;
    } | {
        oneofKind: "graphqlArg";
        /**
         * Assigns the variable to the GraphQL Argument with this name.
         * If this name doesn't exist, the variable is `null`
         *
         * @generated from protobuf field: string graphql_arg = 4;
         */
        graphqlArg: string;
    } | {
        oneofKind: "resolverResult";
        /**
         * Assigns the variable to the GraphQL resolver result.
         * If this Extraction is used in a place where the resolver result is not available, an error
         * will be thrown during configuration time.
         *
         * @generated from protobuf field: google.protobuf.Empty resolver_result = 5;
         */
        resolverResult: Empty;
    } | {
        oneofKind: "jsonValue";
        /**
         * Assigns the variable to a JSON value specified here
         *
         * +kubebuilder:validation:Type=object
         *
         * @generated from protobuf field: google.protobuf.Value json_value = 6;
         */
        jsonValue: Value;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GrpcResolver
 */
export interface GrpcResolver {
    /**
     * This is the first destination in this resolver's (gRPC) destinations list.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.DestinationWithImportStatus destination = 1;
     */
    destination?: DestinationWithImportStatus;
    /**
     * Set the timeout of the request to the gRPC service.
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 2;
     */
    timeout?: Duration;
    /**
     * The gRPC service to call.
     *
     * @generated from protobuf field: string request_service = 3;
     */
    requestService: string;
    /**
     * The gRPC method to call.
     *
     * @generated from protobuf field: string request_method = 4;
     */
    requestMethod: string;
    /**
     * The request message template can transform the outgoing message using a `VariableTransformation`.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.VariableTransformation request_outgoing_message_template = 5;
     */
    requestOutgoingMessageTemplate?: VariableTransformation;
    /**
     * Static metadata to be added to the gRPC request.
     * See https://grpc.io/docs/what-is-grpc/core-concepts/#metadata for more information.
     *
     * @generated from protobuf field: map<string, string> request_metadata = 6;
     */
    requestMetadata: {
        [key: string]: string;
    };
    /**
     * The :authority header to set on the gRPC request.
     * If empty, the outgoing :authority header will be set to "envoy".
     * This does not need to be set in most cases.
     *
     * @generated from protobuf field: string request_authority = 7;
     */
    requestAuthority: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.RestResolver
 */
export interface RestResolver {
    /**
     * This is the first destination in this resolver's (REST) destinations list.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.DestinationWithImportStatus destination = 1;
     */
    destination?: DestinationWithImportStatus;
    /**
     * Configuration to template a HTTP request to fetch JSON from a destination REST service.
     * This includes configuration such as setting headers dynamically on the HTTP request, configuring query_params,
     * and setting the body for the request to the destination.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.RestResolver.RequestTemplate request = 2;
     */
    request?: RestResolver_RequestTemplate;
    /**
     * Variables that can be used in this field's response transform.
     * The key is the name of the variable, which is directly used in the transformation.
     * The value defines where the variable value is extracted from on the GraphQL REST response.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.RestResolver.RESTVariable> variables = 3;
     */
    variables: {
        [key: string]: RestResolver_RESTVariable;
    };
    /**
     * Set the timeout of the HTTP request to the REST service (default 5s)
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 5;
     */
    timeout?: Duration;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.RestResolver.RequestTemplate
 */
export interface RestResolver_RequestTemplate {
    /**
     * Sets the headers on the request to the REST destination.
     * This includes setting HTTP :path header.
     * This is a map of HTTP Header name to a transformation which determines the Header value.
     * The transformation must result in a string value, or an error will be sent back to the client.
     * By default, the :method header is set to GET. If `body` is set, then the :method is POST. This can be overridden
     * by setting the :method header manually below.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.VariableTransformation> headers = 1;
     */
    headers: {
        [key: string]: VariableTransformation;
    };
    /**
     * Sets the query parameters on the request to the REST destination.
     * The transformation must result in a string value, or an error will be sent back to the client.
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.VariableTransformation> query_params = 2;
     */
    queryParams: {
        [key: string]: VariableTransformation;
    };
    /**
     * Sets the outgoing body to the upstream REST destination.
     * By default, the body is empty.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.VariableTransformation body = 3;
     */
    body?: VariableTransformation;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.RestResolver.RESTVariable
 */
export interface RestResolver_RESTVariable {
    /**
     * Only available on resolver result transformations
     * Extract the value from the GraphQL HTTP response header. The extracted value is a string.
     * If this Extraction is used in a place where response headers are not available, an error
     * will be thrown during configuration time.
     *
     * @generated from protobuf field: string response_header = 1;
     */
    responseHeader: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DynamicMetadataExtraction
 */
export interface DynamicMetadataExtraction {
    /**
     * The envoy dynamic metadata namespace that the data lives in. If the namespace doesn't exist in the
     * metadata, the extraction yields null
     *
     * @generated from protobuf field: string metadata_namespace = 1;
     */
    metadataNamespace: string;
    /**
     * The key under the envoy dynamic metadata namespace that the data lives under.
     * If the key doesn't exist in the namespace, the extraction yields null
     *
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GraphqlApiType
 */
export enum GraphqlApiType {
    /**
     * @generated from protobuf enum value: RESOLVED = 0;
     */
    RESOLVED = 0,
    /**
     * @generated from protobuf enum value: STITCHED = 1;
     */
    STITCHED = 1,
    /**
     * @generated from protobuf enum value: PROXIED = 2;
     */
    PROXIED = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GraphqlApiSummary$Type extends MessageType<GraphqlApiSummary> {
    constructor() {
        super("rpc.gloo.solo.io.GraphqlApiSummary", [
            { no: 1, name: "istio_route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.GraphqlApiType", GraphqlApiType] },
            { no: 3, name: "num_subgraphs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 5, name: "gateway_refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 6, name: "status", kind: "message", T: () => Status },
            { no: 7, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphqlApiSummary
 */
export const GraphqlApiSummary = new GraphqlApiSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphqlResolver$Type extends MessageType<GraphqlResolver> {
    constructor() {
        super("rpc.gloo.solo.io.GraphqlResolver", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphqlResolver
 */
export const GraphqlResolver = new GraphqlResolver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphqlResolvers$Type extends MessageType<GraphqlResolvers> {
    constructor() {
        super("rpc.gloo.solo.io.GraphqlResolvers", [
            { no: 1, name: "resolver", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GraphqlResolver },
            { no: 2, name: "total_resolvers", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphqlResolvers
 */
export const GraphqlResolvers = new GraphqlResolvers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphqlApiExplorerSettingsRequest$Type extends MessageType<GetGraphqlApiExplorerSettingsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsRequest", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsRequest
 */
export const GetGraphqlApiExplorerSettingsRequest = new GetGraphqlApiExplorerSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphqlApiExplorerSettingsResponse$Type extends MessageType<GetGraphqlApiExplorerSettingsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse", [
            { no: 1, name: "gateway_options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetGraphqlApiExplorerSettingsResponse_GatewayOption }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse
 */
export const GetGraphqlApiExplorerSettingsResponse = new GetGraphqlApiExplorerSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphqlApiExplorerSettingsResponse_GatewayOption$Type extends MessageType<GetGraphqlApiExplorerSettingsResponse_GatewayOption> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse.GatewayOption", [
            { no: 1, name: "gateway_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse.GatewayOption
 */
export const GetGraphqlApiExplorerSettingsResponse_GatewayOption = new GetGraphqlApiExplorerSettingsResponse_GatewayOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphqlApiRequest$Type extends MessageType<GetGraphqlApiRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphqlApiRequest", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "istio_route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "resolver_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphqlApiRequest
 */
export const GetGraphqlApiRequest = new GetGraphqlApiRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphqlApiResponse$Type extends MessageType<GetGraphqlApiResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphqlApiResponse", [
            { no: 1, name: "schema_definition", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stitched_schema", kind: "message", oneof: "graphql", T: () => StitchedSchema },
            { no: 3, name: "executable_schema", kind: "message", oneof: "graphql", T: () => ExecutableSchema },
            { no: 4, name: "gateway_refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 5, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 6, name: "graphql_schema_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 7, name: "graphql_stitched_schema_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 8, name: "api_doc_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 9, name: "graphql_resolver_map_refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 10, name: "dependencies", kind: "message", T: () => WorkspaceDependencies },
            { no: 11, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 12, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphqlApiResponse
 */
export const GetGraphqlApiResponse = new GetGraphqlApiResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StitchedSchema$Type extends MessageType<StitchedSchema> {
    constructor() {
        super("rpc.gloo.solo.io.StitchedSchema", [
            { no: 1, name: "subschemas", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StitchedSchema_Subschema },
            { no: 2, name: "settings", kind: "message", T: () => StitchedSchema_Settings }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.StitchedSchema
 */
export const StitchedSchema = new StitchedSchema$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StitchedSchema_Settings$Type extends MessageType<StitchedSchema_Settings> {
    constructor() {
        super("rpc.gloo.solo.io.StitchedSchema.Settings", [
            { no: 1, name: "enable_introspection", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.StitchedSchema.Settings
 */
export const StitchedSchema_Settings = new StitchedSchema_Settings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StitchedSchema_Subschema$Type extends MessageType<StitchedSchema_Subschema> {
    constructor() {
        super("rpc.gloo.solo.io.StitchedSchema.Subschema", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "istio_route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.GraphqlApiType", GraphqlApiType] },
            { no: 4, name: "type_merge", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => StitchedSchema_Subschema_TypeMergeConfig } },
            { no: 5, name: "graphql_schema_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 6, name: "graphql_stitched_schema_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 7, name: "schema_definition", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "schema_definition_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.StitchedSchema.Subschema
 */
export const StitchedSchema_Subschema = new StitchedSchema_Subschema$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StitchedSchema_Subschema_TypeMergeConfig$Type extends MessageType<StitchedSchema_Subschema_TypeMergeConfig> {
    constructor() {
        super("rpc.gloo.solo.io.StitchedSchema.Subschema.TypeMergeConfig", [
            { no: 1, name: "selection_set", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "query_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "args", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.StitchedSchema.Subschema.TypeMergeConfig
 */
export const StitchedSchema_Subschema_TypeMergeConfig = new StitchedSchema_Subschema_TypeMergeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutableSchema$Type extends MessageType<ExecutableSchema> {
    constructor() {
        super("rpc.gloo.solo.io.ExecutableSchema", [
            { no: 2, name: "proxied", kind: "message", oneof: "executableSchema", T: () => GraphQLProxied },
            { no: 3, name: "resolved", kind: "message", oneof: "executableSchema", T: () => GraphQLResolved },
            { no: 5, name: "applied_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationWithImportStatus }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ExecutableSchema
 */
export const ExecutableSchema = new ExecutableSchema$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestinationWithImportStatus$Type extends MessageType<DestinationWithImportStatus> {
    constructor() {
        super("rpc.gloo.solo.io.DestinationWithImportStatus", [
            { no: 1, name: "destination_ref", kind: "message", T: () => DestinationReference },
            { no: 2, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DestinationWithImportStatus
 */
export const DestinationWithImportStatus = new DestinationWithImportStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphQLProxied$Type extends MessageType<GraphQLProxied> {
    constructor() {
        super("rpc.gloo.solo.io.GraphQLProxied", [
            { no: 2, name: "variables", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GraphQLProxied_RequestExtraction } },
            { no: 3, name: "headers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => VariableTransformation } },
            { no: 4, name: "query_params", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => VariableTransformation } },
            { no: 5, name: "settings", kind: "message", T: () => GraphQLProxied_Settings }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphQLProxied
 */
export const GraphQLProxied = new GraphQLProxied$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphQLProxied_Settings$Type extends MessageType<GraphQLProxied_Settings> {
    constructor() {
        super("rpc.gloo.solo.io.GraphQLProxied.Settings", [
            { no: 1, name: "enable_introspection", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphQLProxied.Settings
 */
export const GraphQLProxied_Settings = new GraphQLProxied_Settings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphQLProxied_RequestExtraction$Type extends MessageType<GraphQLProxied_RequestExtraction> {
    constructor() {
        super("rpc.gloo.solo.io.GraphQLProxied.RequestExtraction", [
            { no: 1, name: "dynamic_metadata", kind: "message", oneof: "extraction", T: () => DynamicMetadataExtraction },
            { no: 2, name: "request_header", kind: "scalar", oneof: "extraction", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "json_value", kind: "message", oneof: "extraction", T: () => Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphQLProxied.RequestExtraction
 */
export const GraphQLProxied_RequestExtraction = new GraphQLProxied_RequestExtraction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VariableTransformation$Type extends MessageType<VariableTransformation> {
    constructor() {
        super("rpc.gloo.solo.io.VariableTransformation", [
            { no: 1, name: "variable", kind: "scalar", oneof: "transformation", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "jq", kind: "scalar", oneof: "transformation", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "message", oneof: "transformation", T: () => Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.VariableTransformation
 */
export const VariableTransformation = new VariableTransformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphQLResolved$Type extends MessageType<GraphQLResolved> {
    constructor() {
        super("rpc.gloo.solo.io.GraphQLResolved", [
            { no: 1, name: "types", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Resolution } },
            { no: 2, name: "settings", kind: "message", T: () => GraphQLResolved_Settings }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphQLResolved
 */
export const GraphQLResolved = new GraphQLResolved$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphQLResolved_Settings$Type extends MessageType<GraphQLResolved_Settings> {
    constructor() {
        super("rpc.gloo.solo.io.GraphQLResolved.Settings", [
            { no: 1, name: "max_depth", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "enable_introspection", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphQLResolved.Settings
 */
export const GraphQLResolved_Settings = new GraphQLResolved_Settings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Resolution$Type extends MessageType<Resolution> {
    constructor() {
        super("rpc.gloo.solo.io.Resolution", [
            { no: 1, name: "fields", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Resolution_Resolvers } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Resolution
 */
export const Resolution = new Resolution$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Resolution_Resolvers$Type extends MessageType<Resolution_Resolvers> {
    constructor() {
        super("rpc.gloo.solo.io.Resolution.Resolvers", [
            { no: 1, name: "resolver", kind: "message", T: () => Resolution_Resolvers_Resolver },
            { no: 2, name: "variables", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Extraction } },
            { no: 4, name: "stat_prefix", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Resolution.Resolvers
 */
export const Resolution_Resolvers = new Resolution_Resolvers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Resolution_Resolvers_Resolver$Type extends MessageType<Resolution_Resolvers_Resolver> {
    constructor() {
        super("rpc.gloo.solo.io.Resolution.Resolvers.Resolver", [
            { no: 1, name: "http_matcher", kind: "message", oneof: "optionalMatcher", T: () => HTTPRequestMatcher },
            { no: 3, name: "rest_resolver", kind: "message", oneof: "resolver", T: () => RestResolver },
            { no: 2, name: "grpc_resolver", kind: "message", oneof: "resolver", T: () => GrpcResolver },
            { no: 10, name: "resolver_result_transform", kind: "message", T: () => VariableTransformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Resolution.Resolvers.Resolver
 */
export const Resolution_Resolvers_Resolver = new Resolution_Resolvers_Resolver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Extraction$Type extends MessageType<Extraction> {
    constructor() {
        super("rpc.gloo.solo.io.Extraction", [
            { no: 1, name: "request_header", kind: "scalar", oneof: "extractionType", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "dynamic_metadata", kind: "message", oneof: "extractionType", T: () => DynamicMetadataExtraction },
            { no: 3, name: "graphql_parent", kind: "message", oneof: "extractionType", T: () => Empty },
            { no: 4, name: "graphql_arg", kind: "scalar", oneof: "extractionType", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "resolver_result", kind: "message", oneof: "extractionType", T: () => Empty },
            { no: 6, name: "json_value", kind: "message", oneof: "extractionType", T: () => Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Extraction
 */
export const Extraction = new Extraction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrpcResolver$Type extends MessageType<GrpcResolver> {
    constructor() {
        super("rpc.gloo.solo.io.GrpcResolver", [
            { no: 1, name: "destination", kind: "message", T: () => DestinationWithImportStatus },
            { no: 2, name: "timeout", kind: "message", T: () => Duration },
            { no: 3, name: "request_service", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "request_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "request_outgoing_message_template", kind: "message", T: () => VariableTransformation },
            { no: 6, name: "request_metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 7, name: "request_authority", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GrpcResolver
 */
export const GrpcResolver = new GrpcResolver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestResolver$Type extends MessageType<RestResolver> {
    constructor() {
        super("rpc.gloo.solo.io.RestResolver", [
            { no: 1, name: "destination", kind: "message", T: () => DestinationWithImportStatus },
            { no: 2, name: "request", kind: "message", T: () => RestResolver_RequestTemplate },
            { no: 3, name: "variables", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => RestResolver_RESTVariable } },
            { no: 5, name: "timeout", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.RestResolver
 */
export const RestResolver = new RestResolver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestResolver_RequestTemplate$Type extends MessageType<RestResolver_RequestTemplate> {
    constructor() {
        super("rpc.gloo.solo.io.RestResolver.RequestTemplate", [
            { no: 1, name: "headers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => VariableTransformation } },
            { no: 2, name: "query_params", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => VariableTransformation } },
            { no: 3, name: "body", kind: "message", T: () => VariableTransformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.RestResolver.RequestTemplate
 */
export const RestResolver_RequestTemplate = new RestResolver_RequestTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestResolver_RESTVariable$Type extends MessageType<RestResolver_RESTVariable> {
    constructor() {
        super("rpc.gloo.solo.io.RestResolver.RESTVariable", [
            { no: 1, name: "response_header", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.RestResolver.RESTVariable
 */
export const RestResolver_RESTVariable = new RestResolver_RESTVariable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DynamicMetadataExtraction$Type extends MessageType<DynamicMetadataExtraction> {
    constructor() {
        super("rpc.gloo.solo.io.DynamicMetadataExtraction", [
            { no: 1, name: "metadata_namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DynamicMetadataExtraction
 */
export const DynamicMetadataExtraction = new DynamicMetadataExtraction$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.GraphqlApi
 */
export const GraphqlApi = new ServiceType("rpc.gloo.solo.io.GraphqlApi", [
    { name: "GetGraphqlApi", options: {}, I: GetGraphqlApiRequest, O: GetGraphqlApiResponse },
    { name: "GetGraphqlApiExplorerSettings", options: {}, I: GetGraphqlApiExplorerSettingsRequest, O: GetGraphqlApiExplorerSettingsResponse }
]);
