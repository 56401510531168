import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { isNonNullableAndNonFalse } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType, statusCodeMatcherComparatorMap } from 'utils/types';
import { BasicWorkloadsTableCard } from './BasicWorkloadsTable';

const { useGetAccessLogPolicyDetails } = policiesApi;

const policyType = PolicyType.ACCESS_LOG;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const AccessLogDetails = ({ clusterObjRef }: Props) => {
  di(useGetAccessLogPolicyDetails);
  const workloadsPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetAccessLogPolicyDetails(
    clusterObjRef,
    workloadsPaging.apiPaginationObject
  );

  workloadsPaging.useSetPagingTotal(policyData?.workloads?.totalWorkloads);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  const statusCodeMatcherFilters = policyData.filters
    .map(f => (f.type.oneofKind === 'statusCodeMatcher' ? f.type.statusCodeMatcher : null))
    .filter(isNonNullableAndNonFalse);
  const headerMatcherFilters = policyData.filters
    .map(f => (f.type.oneofKind === 'headerMatcher' ? f.type.headerMatcher : null))
    .filter(isNonNullableAndNonFalse);

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Filters
          <CardStyles.CardHeaderRightIcon>
            <Asset.FilterIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.AbortHttpStatusIcon />,
              title: 'Status Code',
              data: !!statusCodeMatcherFilters.length ? (
                <SecondaryInfo
                  small
                  items={statusCodeMatcherFilters.map(matcher => ({
                    data: statusCodeMatcherComparatorMap[matcher.comparator].name + matcher.value
                  }))}
                />
              ) : (
                'No status code filters configured'
              )
            },
            {
              icon: <Asset.HeaderManipulationPolicyIcon />,
              title: 'Headers',
              data: !!headerMatcherFilters.length ? (
                <SecondaryInfo
                  small
                  items={headerMatcherFilters.map(matcher => ({
                    // label: matcher.name,
                    data:
                      matcher.value + (matcher.regex ? ' (Regex)' : '') + (matcher.invertMatch ? ' (Invert Match)' : '')
                  }))}
                />
              ) : (
                'No header filters configured'
              )
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>Logged Attributes</CardStyles.CardHeader>

        <SoloListCardStyles.HorizontalListCardsContainer>
          <SoloListCard title='Request Headers' dataSource={policyData.requestHeaders} />
          <SoloListCard title='Response Headers' dataSource={policyData.responseHeaders} />
          <SoloListCard title='State Filter Objects' dataSource={policyData.filterStateObjects} />
          <SoloListCard title='Response Trailers' dataSource={policyData.responseTrailers} />
        </SoloListCardStyles.HorizontalListCardsContainer>
      </CardStyles.Card>

      <BasicWorkloadsTableCard workloads={policyData.workloads?.workloads} paging={workloadsPaging.pagingData} />
    </CardStyles.CardList>
  );
};
