/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { GraphApi } from "./graph_pb";
import type { GetServiceDetailsResponse } from "./graph_pb";
import type { GetServiceDetailsRequest } from "./graph_pb";
import type { GetEdgeMetricsResponse } from "./graph_pb";
import type { GetEdgeMetricsRequest } from "./graph_pb";
import type { GetWorkloadMetricsResponse } from "./graph_pb";
import type { GetWorkloadMetricsRequest } from "./graph_pb";
import type { GetFiltersResponse } from "./graph_pb";
import type { GetFiltersRequest } from "./graph_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetGraphResponse } from "./graph_pb";
import type { GetGraphRequest } from "./graph_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rpc.gloo.solo.io.GraphApi
 */
export interface IGraphApiClient {
    /**
     * @generated from protobuf rpc: GetGraph(rpc.gloo.solo.io.GetGraphRequest) returns (rpc.gloo.solo.io.GetGraphResponse);
     */
    getGraph(input: GetGraphRequest, options?: RpcOptions): UnaryCall<GetGraphRequest, GetGraphResponse>;
    /**
     * @generated from protobuf rpc: GetFilters(rpc.gloo.solo.io.GetFiltersRequest) returns (rpc.gloo.solo.io.GetFiltersResponse);
     */
    getFilters(input: GetFiltersRequest, options?: RpcOptions): UnaryCall<GetFiltersRequest, GetFiltersResponse>;
    /**
     * @generated from protobuf rpc: GetWorkloadMetrics(rpc.gloo.solo.io.GetWorkloadMetricsRequest) returns (rpc.gloo.solo.io.GetWorkloadMetricsResponse);
     */
    getWorkloadMetrics(input: GetWorkloadMetricsRequest, options?: RpcOptions): UnaryCall<GetWorkloadMetricsRequest, GetWorkloadMetricsResponse>;
    /**
     * @generated from protobuf rpc: GetEdgeMetrics(rpc.gloo.solo.io.GetEdgeMetricsRequest) returns (rpc.gloo.solo.io.GetEdgeMetricsResponse);
     */
    getEdgeMetrics(input: GetEdgeMetricsRequest, options?: RpcOptions): UnaryCall<GetEdgeMetricsRequest, GetEdgeMetricsResponse>;
    /**
     * @generated from protobuf rpc: GetServiceDetails(rpc.gloo.solo.io.GetServiceDetailsRequest) returns (rpc.gloo.solo.io.GetServiceDetailsResponse);
     */
    getServiceDetails(input: GetServiceDetailsRequest, options?: RpcOptions): UnaryCall<GetServiceDetailsRequest, GetServiceDetailsResponse>;
}
/**
 * @generated from protobuf service rpc.gloo.solo.io.GraphApi
 */
export class GraphApiClient implements IGraphApiClient, ServiceInfo {
    typeName = GraphApi.typeName;
    methods = GraphApi.methods;
    options = GraphApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetGraph(rpc.gloo.solo.io.GetGraphRequest) returns (rpc.gloo.solo.io.GetGraphResponse);
     */
    getGraph(input: GetGraphRequest, options?: RpcOptions): UnaryCall<GetGraphRequest, GetGraphResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphRequest, GetGraphResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFilters(rpc.gloo.solo.io.GetFiltersRequest) returns (rpc.gloo.solo.io.GetFiltersResponse);
     */
    getFilters(input: GetFiltersRequest, options?: RpcOptions): UnaryCall<GetFiltersRequest, GetFiltersResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFiltersRequest, GetFiltersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWorkloadMetrics(rpc.gloo.solo.io.GetWorkloadMetricsRequest) returns (rpc.gloo.solo.io.GetWorkloadMetricsResponse);
     */
    getWorkloadMetrics(input: GetWorkloadMetricsRequest, options?: RpcOptions): UnaryCall<GetWorkloadMetricsRequest, GetWorkloadMetricsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWorkloadMetricsRequest, GetWorkloadMetricsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEdgeMetrics(rpc.gloo.solo.io.GetEdgeMetricsRequest) returns (rpc.gloo.solo.io.GetEdgeMetricsResponse);
     */
    getEdgeMetrics(input: GetEdgeMetricsRequest, options?: RpcOptions): UnaryCall<GetEdgeMetricsRequest, GetEdgeMetricsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEdgeMetricsRequest, GetEdgeMetricsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetServiceDetails(rpc.gloo.solo.io.GetServiceDetailsRequest) returns (rpc.gloo.solo.io.GetServiceDetailsResponse);
     */
    getServiceDetails(input: GetServiceDetailsRequest, options?: RpcOptions): UnaryCall<GetServiceDetailsRequest, GetServiceDetailsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetServiceDetailsRequest, GetServiceDetailsResponse>("unary", this._transport, method, opt, input);
    }
}
