import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SecondaryInfoStyles } from 'Components/Common/SecondaryInfo.style';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace RoutesListDisplayStyles {
  const BasicCard = styled.div(
    ({ theme }) => css`
      padding: 15px;
      border: 1px solid ${theme.splashBlue};
      border-radius: 3px;
      margin-top: 20px;
      &:first-of-type {
        margin-top: 0;
      }
    `
  );

  export const RouteContainer = styled(BasicCard)`
    display: grid;
    grid-template:
      'left   clause1 top   ' auto
      'left   clause2 bottom' 40px
      'labels labels  bottom' auto
      / minmax(80px, 20%) 75px 1fr;

    gap: 20px 40px;
  `;
  export const LeftSide = styled.div`
    grid-area: left;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px; // add a little more room for absolute positioned LabelHeader to be moved up
  `;
  export const RouteName = styled.div`
    display: flex;
    gap: 10px;
    font-weight: bold;

    & + & {
      margin-top: 4px;
    }
  `;
  export const LabelContainer = styled.div`
    grid-area: labels;
    position: relative;
  `;
  export const LabelHeader = styled.div`
    position: absolute;
    top: -25px;
    font-weight: 500;
  `;
  export const ClauseContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: bold;
    white-space: nowrap;
  `;
  export const ClauseIcon = styled.span(
    ({ theme }) => css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      background: ${theme.oceanBlue};
      border-radius: 50%;
      margin-right: 7px;

      svg {
        width: 25px;
      }
    `
  );
  export const ClauseLine = styled.div(
    ({ theme }) => css`
      width: 13.5px;
      height: calc(100% + 15px);
      position: relative;
      top: 2.5px;
      border-right: 2px solid ${theme.juneGrey};
    `
  );
  const RouteContent = styled(BasicCard)`
    display: flex;
    margin-top: 0;
    font-size: 14px;
    > div {
      flex: 1;
    }
  `;
  export const RouteContentTop = styled(RouteContent)`
    grid-area: top;
  `;
  export const RouteContentBottom = styled(RouteContent)`
    grid-area: bottom;
  `;
  export const RouteContentHeading = styled.div`
    font-size: 16px;
    margin-bottom: 14px;
  `;
  export const RouteContentLabel = styled.div`
    font-size: 14px;
    font-weight: bold;
  `;
  export const MatchersGrid = styled.div`
    // Grid is inline so that the width of the grid scales down to only be as wide as it's content.
    // This is important so the separation arrows on delegated route matchers properly center when not a lot of matcher info.
    display: inline-grid;
    grid-template-columns: auto 1fr;
    gap: 16px 40px;

    > strong {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
  `;
  // Used in cases where matchers don't have a uri or such we want in a separate column
  export const MatchersList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;
  export const MatchersItem = styled.div`
    display: flex;
    align-items: baseline;
    gap: 10px;
  `;
  export const MatcherArrowRow = styled.div`
    grid-column: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  export const RouteHorizontalList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2px 20px;
  `;
  export const RouteItemsNone = styled.div(
    ({ theme }) => css`
      color: ${theme.augustGrey};
    `
  );

  export const IconText = styled.span`
    display: flex;
    align-items: center;
    gap: 10px;

    ${svgBasics({ height: 18 }, 'currentColor')}
  `;

  export const ActionLabel = styled.div`
    padding-bottom: 10px;
    font-weight: 500;
  `;

  export const ForwardsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: flex-start;
    gap: 20px 20px;
  `;
  export const ForwardWeight = styled.div(
    ({ theme }) => css`
      ${SecondaryInfoStyles.Item} {
        color: ${theme.white};
        border-color: ${theme.oceanBlue};
        background: ${theme.oceanBlue};
      }
    `
  );
}
