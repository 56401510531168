import { graphApi } from 'Api/graphs';
import { SoloCheckboxListDropdown } from 'Components/Common/Input/SoloCheckboxList';
//import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import { HealthCountBoxStyles } from 'Components/Common/Overview/HealthCountBox.style';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { Insight_Severity } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { di } from 'react-magnetic-di';
import { InsightCodeGroup, insightCodeGroupMap } from 'utils/dashboard/dashboard-types';
import { getEnumValues } from 'utils/helpers';
import { InsightsLandingFiltersObject } from '../InsightsLanding';
const { useGetFilters } = graphApi;

export const InsightsLandingFilters = ({
  filters,
  onFiltersChange,
  healthCounts,
  isSecurity
}: {
  filters: InsightsLandingFiltersObject;
  onFiltersChange(newFilters: InsightsLandingFiltersObject, pushToHistory: boolean): void;
  healthCounts: { info?: number; warning?: number; error?: number };
  isSecurity: boolean;
}) => {
  di(useGetFilters);

  // The graphFilters are used here to get the available clusters list.
  const { data: graphFilters, error: graphFiltersError } = useGetFilters();
  const showClusterFilter = (!!graphFilters && graphFilters.clusters.length > 0) || !!graphFiltersError;

  const toggleSeverity = (sev: Insight_Severity) => {
    onFiltersChange({ ...filters, severities: filters.severities.includes(sev) ? [] : [sev] }, true);
  };

  //
  // Render
  //
  return (
    <OverviewStyles.Header.FiltersWrapper>
      <HealthCountBoxStyles.CountBoxButton
        isSelected={filters.severities.includes(Insight_Severity.INFO)}
        isSelectable
        onClick={() => toggleSeverity(Insight_Severity.INFO)}
        data-testid='health-count-box-healthy'
        health='normal'>
        <HealthCountBoxStyles.CountDisplay>{healthCounts.info ?? 0}</HealthCountBoxStyles.CountDisplay>
        Info
      </HealthCountBoxStyles.CountBoxButton>
      <HealthCountBoxStyles.CountBoxButton
        isSelected={filters.severities.includes(Insight_Severity.WARNING)}
        isSelectable
        onClick={() => toggleSeverity(Insight_Severity.WARNING)}
        data-testid='health-count-box-warning'
        health='warning'>
        <HealthCountBoxStyles.CountDisplay>{healthCounts.warning ?? 0}</HealthCountBoxStyles.CountDisplay>
        Warning
      </HealthCountBoxStyles.CountBoxButton>
      <HealthCountBoxStyles.CountBoxButton
        isSelected={filters.severities.includes(Insight_Severity.ERROR)}
        isSelectable
        onClick={() => toggleSeverity(Insight_Severity.ERROR)}
        data-testid='health-count-box-error'
        health='error'>
        <HealthCountBoxStyles.CountDisplay>{healthCounts.error ?? 0}</HealthCountBoxStyles.CountDisplay>
        Error
      </HealthCountBoxStyles.CountBoxButton>
      <OverviewStyles.Header.Filters filters={Number(!isSecurity) + Number(showClusterFilter)} hasTableToggle={false}>
        {!isSecurity && (
          <SoloCheckboxListDropdown
            selectAll
            noSearch
            title={'Filter by type...'}
            values={filters.codeGroups}
            options={getEnumValues(InsightCodeGroup)
              .filter(g => g !== InsightCodeGroup.SYS)
              .map(g => ({
                key: g,
                value: g,
                label: `${g} - ${insightCodeGroupMap[g]}`
              }))}
            onChange={newCodeGroups => onFiltersChange({ ...filters, codeGroups: newCodeGroups }, true)}
          />
        )}
        {showClusterFilter &&
          (!graphFilters ? (
            <OverviewStyles.Header.RefreshIndicatorHolder>
              <Loading small floating />
            </OverviewStyles.Header.RefreshIndicatorHolder>
          ) : (
            <SoloCheckboxListDropdown
              selectAll
              options={graphFilters.clusters.map(c => ({ label: c, value: c }))}
              values={filters.clusters}
              onChange={newClusters => onFiltersChange({ ...filters, clusters: newClusters }, true)}
              title='Search by cluster...'
            />
          ))}
        {/*<SoloInput
          searchIcon
          placeholder='Search...'
          value={filters.searchText}
          onChangeValue={value => onFiltersChange({ ...filters, searchText: value }, true)}
        /> */}
      </OverviewStyles.Header.Filters>
    </OverviewStyles.Header.FiltersWrapper>
  );
};
