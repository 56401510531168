import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { UsageInfoTopicEnum } from '../General/UsageInfoTab/UsageInfoTab.style';

export enum LayoutTypesEnum {
  Preset = 1,
  Dot = 2
}
export enum GraphTypesEnum {
  Destination = 1,
  Workload = 2
}

export interface GraphUXSettingsContextType {
  layoutType: LayoutTypesEnum;
  setLayoutType: Dispatch<SetStateAction<LayoutTypesEnum>>;
  graphType: GraphTypesEnum;
  setGraphType: Dispatch<SetStateAction<GraphTypesEnum>>;

  findName: string;
  setFindName: Dispatch<SetStateAction<string>>;
  hideName: string;
  setHideName: Dispatch<SetStateAction<string>>;
  onlyErrorsShown: boolean;
  setOnlyErrorsShown: Dispatch<SetStateAction<boolean>>;

  usageInfoTopic: UsageInfoTopicEnum | undefined;
  setUsageInfoTopic: Dispatch<SetStateAction<UsageInfoTopicEnum | undefined>>;
  toggleLegend: () => void;
  toggleLayoutSettings: () => void;
}

export const GraphUXSettingsContext = createContext<GraphUXSettingsContextType | null>(null);

interface ProviderProps {
  children: ReactNode;
}
export const GraphUXSettingsContextProvider = ({ children }: ProviderProps) => {
  const [layoutType, setLayoutType] = useState<LayoutTypesEnum>(LayoutTypesEnum.Preset);
  const [graphType, setGraphType] = useState<GraphTypesEnum>(GraphTypesEnum.Workload);

  const [findName, setFindName] = useState<string>('');
  const [hideName, setHideName] = useState<string>('');
  const [onlyErrorsShown, setOnlyErrorsShown] = useState(false);

  const [usageInfoTopic, setUsageInfoTopic] = useState<UsageInfoTopicEnum | undefined>(undefined);

  const toggleLegend = () => {
    if (usageInfoTopic === UsageInfoTopicEnum.Legend) {
      setUsageInfoTopic(undefined);
    } else {
      setUsageInfoTopic(UsageInfoTopicEnum.Legend);
    }
  };
  const toggleLayoutSettings = () => {
    if (usageInfoTopic === UsageInfoTopicEnum.Settings) {
      setUsageInfoTopic(undefined);
    } else {
      setUsageInfoTopic(UsageInfoTopicEnum.Settings);
    }
  };

  return (
    <GraphUXSettingsContext.Provider
      value={{
        layoutType,
        setLayoutType,
        graphType,
        setGraphType,

        findName,
        setFindName,
        hideName,
        setHideName,
        onlyErrorsShown,
        setOnlyErrorsShown,

        usageInfoTopic,
        setUsageInfoTopic,
        toggleLegend,
        toggleLayoutSettings
      }}>
      {children}
    </GraphUXSettingsContext.Provider>
  );
};

export const useGraphUXSettingsContext = () => {
  const graphUXSettingsContext = useContext(GraphUXSettingsContext);

  if (!graphUXSettingsContext) {
    throw new Error('useGraphUXSettingsContext has to be used within <GraphUXSettingsContext.Provider>');
  }

  return graphUXSettingsContext;
};
