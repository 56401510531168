/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/skv2/api/core/v1/core.proto" (package "core.skv2.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../../../../google/protobuf/timestamp_pb";
import { StringValue } from "../../../../../../google/protobuf/wrappers_pb";
/**
 * Resource reference for an object
 *
 * @generated from protobuf message core.skv2.solo.io.ObjectRef
 */
export interface ObjectRef {
    /**
     * name of the resource being referenced
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * namespace of the resource being referenced
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
}
/**
 * Object providing a list of object refs.
 * Used to store lists of refs inside a map.
 *
 * @generated from protobuf message core.skv2.solo.io.ObjectRefList
 */
export interface ObjectRefList {
    /**
     * @generated from protobuf field: repeated core.skv2.solo.io.ObjectRef refs = 1;
     */
    refs: ObjectRef[];
}
/**
 * Resource reference for a cross-cluster-scoped object
 *
 * @generated from protobuf message core.skv2.solo.io.ClusterObjectRef
 */
export interface ClusterObjectRef {
    /**
     * name of the resource being referenced
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * namespace of the resource being referenced
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
    /**
     * name of the cluster in which the resource exists
     *
     * @generated from protobuf field: string cluster_name = 3;
     */
    clusterName: string;
}
/**
 * Resource reference for a typed object
 *
 * @generated from protobuf message core.skv2.solo.io.TypedObjectRef
 */
export interface TypedObjectRef {
    /**
     * API group of the resource being referenced
     *
     * @generated from protobuf field: google.protobuf.StringValue api_group = 1;
     */
    apiGroup?: StringValue;
    /**
     * Kind of the resource being referenced
     *
     * @generated from protobuf field: google.protobuf.StringValue kind = 2;
     */
    kind?: StringValue;
    /**
     * name of the resource being referenced
     *
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * namespace of the resource being referenced
     *
     * @generated from protobuf field: string namespace = 4;
     */
    namespace: string;
}
/**
 * Resource reference for a typed, cross-cluster-scoped object
 *
 * @generated from protobuf message core.skv2.solo.io.TypedClusterObjectRef
 */
export interface TypedClusterObjectRef {
    /**
     * API group of the resource being referenced
     *
     * @generated from protobuf field: google.protobuf.StringValue api_group = 1;
     */
    apiGroup?: StringValue;
    /**
     * Kind of the resource being referenced
     *
     * @generated from protobuf field: google.protobuf.StringValue kind = 2;
     */
    kind?: StringValue;
    /**
     * name of the resource being referenced
     *
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * namespace of the resource being referenced
     *
     * @generated from protobuf field: string namespace = 4;
     */
    namespace: string;
    /**
     * name of the cluster in which the resource exists
     *
     * @generated from protobuf field: string cluster_name = 5;
     */
    clusterName: string;
}
/**
 * A generic status
 *
 * @generated from protobuf message core.skv2.solo.io.Status
 */
export interface Status {
    /**
     * The current state of the resource
     *
     * @generated from protobuf field: core.skv2.solo.io.Status.State state = 1;
     */
    state: Status_State;
    /**
     * A human readable message about the current state of the object
     *
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * The most recently observed generation of the resource. This value corresponds to the `metadata.generation` of
     * a kubernetes resource
     *
     * @generated from protobuf field: int64 observed_generation = 3;
     */
    observedGeneration: number;
    /**
     * The time at which this status was recorded
     *
     * @generated from protobuf field: google.protobuf.Timestamp processing_time = 4;
     */
    processingTime?: Timestamp;
    /**
     * (optional) The owner of the status, this value can be used to identify the entity which wrote this status.
     * This is useful in situations where a given resource may have multiple owners.
     *
     * @generated from protobuf field: google.protobuf.StringValue owner = 5;
     */
    owner?: StringValue;
}
/**
 * The State of a reconciled object
 *
 * @generated from protobuf enum core.skv2.solo.io.Status.State
 */
export enum Status_State {
    /**
     * Waiting to be processed.
     *
     * @generated from protobuf enum value: PENDING = 0;
     */
    PENDING = 0,
    /**
     * Currently processing.
     *
     * @generated from protobuf enum value: PROCESSING = 1;
     */
    PROCESSING = 1,
    /**
     * Invalid parameters supplied, will not continue.
     *
     * @generated from protobuf enum value: INVALID = 2;
     */
    INVALID = 2,
    /**
     * Failed during processing.
     *
     * @generated from protobuf enum value: FAILED = 3;
     */
    FAILED = 3,
    /**
     * Finished processing successfully.
     *
     * @generated from protobuf enum value: ACCEPTED = 4;
     */
    ACCEPTED = 4
}
/**
 * Select K8s Objects by namespace, labels, or both.
 *
 * @generated from protobuf message core.skv2.solo.io.ObjectSelector
 */
export interface ObjectSelector {
    /**
     * Select Objects in these namespaces. If omitted, Gloo Mesh will only select Objects in the same namespace
     * as the parent resource (e.g. VirtualGateway) that owns this selector.
     * The reserved value "*" can be used to select objects in all namespaces watched by Gloo Mesh.
     *
     * @generated from protobuf field: repeated string namespaces = 1;
     */
    namespaces: string[];
    /**
     * Select objects whose labels match the ones specified here.
     *
     * @generated from protobuf field: map<string, string> labels = 2;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * Expressions allow for more flexible object label matching, such as equality-based requirements, set-based requirements, or a combination of both.
     * https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#equality-based-requirement
     *
     * @generated from protobuf field: repeated core.skv2.solo.io.ObjectSelector.Expression expressions = 3;
     */
    expressions: ObjectSelector_Expression[];
}
/**
 * @generated from protobuf message core.skv2.solo.io.ObjectSelector.Expression
 */
export interface ObjectSelector_Expression {
    /**
     * Kubernetes label key, must conform to Kubernetes syntax requirements
     * https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * The operator can only be in, notin, =, ==, !=, exists, ! (DoesNotExist), gt (GreaterThan), lt (LessThan).
     *
     * @generated from protobuf field: core.skv2.solo.io.ObjectSelector.Expression.Operator operator = 2;
     */
    operator: ObjectSelector_Expression_Operator;
    /**
     * @generated from protobuf field: repeated string values = 3;
     */
    values: string[];
}
/**
 * Object Selector expression operator, while the set-based syntax differs from Kubernetes (kubernetes: `key: !mylabel`, gloo: `key: mylabel, operator: "!"` | kubernetes: `key: mylabel`, gloo: `key: mylabel, operator: exists`), the functionality remains the same.
 *
 * @generated from protobuf enum core.skv2.solo.io.ObjectSelector.Expression.Operator
 */
export enum ObjectSelector_Expression_Operator {
    /**
     * =
     *
     * @generated from protobuf enum value: Equals = 0;
     */
    Equals = 0,
    /**
     * ==
     *
     * @generated from protobuf enum value: DoubleEquals = 1;
     */
    DoubleEquals = 1,
    /**
     * !=
     *
     * @generated from protobuf enum value: NotEquals = 2;
     */
    NotEquals = 2,
    /**
     * in
     *
     * @generated from protobuf enum value: In = 3;
     */
    In = 3,
    /**
     * notin
     *
     * @generated from protobuf enum value: NotIn = 4;
     */
    NotIn = 4,
    /**
     * exists
     *
     * @generated from protobuf enum value: Exists = 5;
     */
    Exists = 5,
    /**
     * !
     *
     * @generated from protobuf enum value: DoesNotExist = 6;
     */
    DoesNotExist = 6,
    /**
     * gt
     *
     * @generated from protobuf enum value: GreaterThan = 7;
     */
    GreaterThan = 7,
    /**
     * lt
     *
     * @generated from protobuf enum value: LessThan = 8;
     */
    LessThan = 8
}
/**
 * PolicyTargetReference identifies Gateway API objects to directly apply policy to.
 * This is a copy of the upstream K8s Gateway API `targetRef` API.
 * See the following for more information:
 * * https://gateway-api.sigs.k8s.io/geps/gep-713/
 * * https://gateway-api.sigs.k8s.io/reference/spec/#gateway.networking.k8s.io%2fv1alpha2.PolicyTargetReference
 * * https://github.com/kubernetes-sigs/gateway-api/blob/b4f0307cc9269e73187300e72979e7e111ab74ab/apis/v1alpha2/policy_types.go#L34-L56
 *
 * @generated from protobuf message core.skv2.solo.io.PolicyTargetReference
 */
export interface PolicyTargetReference {
    /**
     * @generated from protobuf field: string group = 1;
     */
    group: string;
    /**
     * @generated from protobuf field: string kind = 2;
     */
    kind: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * Optional, if unspecified, the local namespace of the policy is inferred.
     *
     * @generated from protobuf field: google.protobuf.StringValue namespace = 4;
     */
    namespace?: StringValue;
}
/**
 * PolicyTargetReferenceWithSectionName identifies Gateway API objects, and optionally a specific section of those objects, to directly apply policy to.
 * This is a copy of the upstream K8s Gateway API `targetRef` API.
 * See the following for more information:
 * * https://gateway-api.sigs.k8s.io/geps/gep-713/
 * * https://gateway-api.sigs.k8s.io/reference/spec/#gateway.networking.k8s.io%2fv1alpha2.PolicyTargetReferenceWithSectionName
 * * https://github.com/kubernetes-sigs/gateway-api/blob/b4f0307cc9269e73187300e72979e7e111ab74ab/apis/v1alpha2/policy_types.go#L58-L83
 *
 * @generated from protobuf message core.skv2.solo.io.PolicyTargetReferenceWithSectionName
 */
export interface PolicyTargetReferenceWithSectionName {
    /**
     * @generated from protobuf field: string group = 1;
     */
    group: string;
    /**
     * @generated from protobuf field: string kind = 2;
     */
    kind: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * Optional, if unspecified, the local namespace of the policy is inferred.
     *
     * @generated from protobuf field: google.protobuf.StringValue namespace = 4;
     */
    namespace?: StringValue;
    /**
     * Name of the section within the targeted resource to attach to. For `Gateway` resources, this refers to a `Listener` name.
     * Optional, if unspecified, the entire object referenced is selected.
     *
     * @generated from protobuf field: google.protobuf.StringValue section_name = 5;
     */
    sectionName?: StringValue;
}
// @generated message type with reflection information, may provide speed optimized methods
class ObjectRef$Type extends MessageType<ObjectRef> {
    constructor() {
        super("core.skv2.solo.io.ObjectRef", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.ObjectRef
 */
export const ObjectRef = new ObjectRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObjectRefList$Type extends MessageType<ObjectRefList> {
    constructor() {
        super("core.skv2.solo.io.ObjectRefList", [
            { no: 1, name: "refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.ObjectRefList
 */
export const ObjectRefList = new ObjectRefList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClusterObjectRef$Type extends MessageType<ClusterObjectRef> {
    constructor() {
        super("core.skv2.solo.io.ClusterObjectRef", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cluster_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.ClusterObjectRef
 */
export const ClusterObjectRef = new ClusterObjectRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypedObjectRef$Type extends MessageType<TypedObjectRef> {
    constructor() {
        super("core.skv2.solo.io.TypedObjectRef", [
            { no: 1, name: "api_group", kind: "message", T: () => StringValue },
            { no: 2, name: "kind", kind: "message", T: () => StringValue },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.TypedObjectRef
 */
export const TypedObjectRef = new TypedObjectRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypedClusterObjectRef$Type extends MessageType<TypedClusterObjectRef> {
    constructor() {
        super("core.skv2.solo.io.TypedClusterObjectRef", [
            { no: 1, name: "api_group", kind: "message", T: () => StringValue },
            { no: 2, name: "kind", kind: "message", T: () => StringValue },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "cluster_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.TypedClusterObjectRef
 */
export const TypedClusterObjectRef = new TypedClusterObjectRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Status$Type extends MessageType<Status> {
    constructor() {
        super("core.skv2.solo.io.Status", [
            { no: 1, name: "state", kind: "enum", T: () => ["core.skv2.solo.io.Status.State", Status_State] },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "observed_generation", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "processing_time", kind: "message", T: () => Timestamp },
            { no: 5, name: "owner", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.Status
 */
export const Status = new Status$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObjectSelector$Type extends MessageType<ObjectSelector> {
    constructor() {
        super("core.skv2.solo.io.ObjectSelector", [
            { no: 1, name: "namespaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "expressions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ObjectSelector_Expression }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.ObjectSelector
 */
export const ObjectSelector = new ObjectSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObjectSelector_Expression$Type extends MessageType<ObjectSelector_Expression> {
    constructor() {
        super("core.skv2.solo.io.ObjectSelector.Expression", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "operator", kind: "enum", T: () => ["core.skv2.solo.io.ObjectSelector.Expression.Operator", ObjectSelector_Expression_Operator] },
            { no: 3, name: "values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.ObjectSelector.Expression
 */
export const ObjectSelector_Expression = new ObjectSelector_Expression$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyTargetReference$Type extends MessageType<PolicyTargetReference> {
    constructor() {
        super("core.skv2.solo.io.PolicyTargetReference", [
            { no: 1, name: "group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.PolicyTargetReference
 */
export const PolicyTargetReference = new PolicyTargetReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyTargetReferenceWithSectionName$Type extends MessageType<PolicyTargetReferenceWithSectionName> {
    constructor() {
        super("core.skv2.solo.io.PolicyTargetReferenceWithSectionName", [
            { no: 1, name: "group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "message", T: () => StringValue },
            { no: 5, name: "section_name", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message core.skv2.solo.io.PolicyTargetReferenceWithSectionName
 */
export const PolicyTargetReferenceWithSectionName = new PolicyTargetReferenceWithSectionName$Type();
