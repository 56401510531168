import { workspacesApi } from 'Api/workspaces';
import { ApiserverNoContent } from 'Components/Common/ApiserverContent';
import { CardStyles } from 'Components/Common/Card';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { SoloMultiselect } from 'Components/Common/Input/SoloMultiselect';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import {
  ApiType,
  ImportStatus
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayoutSpacer } from 'Styles/CommonEmotions/flexLayout';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { apiTypeMap, importStatusMap } from 'utils/types';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';
import { AvailableApisToImportTable } from './AvailableApisToImportTable';
import { WorkspaceApisTable } from './WorkspaceApisTable';
const { useListApis } = workspacesApi;

const apiTypeOptions = [
  ApiType.GRAPHQL_RESOLVED,
  ApiType.GRAPHQL_STITCHED,
  ApiType.GRAPHQL_PROXIED,
  ApiType.PORTAL_OPENAPI
].map(kind => ({
  value: kind,
  title: apiTypeMap[kind].name
}));

export const ApisTab = ({ workspaceName }: { workspaceName: string }) => {
  di(useListApis);
  const workspaceApisPaging = useSoloPaging();
  const availableApisPaging = useSoloPaging();

  const [workspaceNameFilter, setWorkspaceNameFilter] = useState<string>('');
  const [workspaceTypeFilter, setWorkspaceTypeFilter] = useState<ApiType[]>([]);
  const [workspaceImportStatusFilter, setWorkspaceImportStatusFilter] = useState<ImportStatus[]>([]);

  const [availableNameFilter, setAvailableNameFilter] = useState<string>('');
  const [availableTypeFilter, setAvailableTypeFilter] = useState<ApiType[]>([]);

  const { data: apisDataUnbounced, error: apisError } = useListApis(
    workspaceName,
    workspaceNameFilter,
    workspaceTypeFilter,
    workspaceImportStatusFilter,
    workspaceApisPaging.apiPaginationObject,
    availableNameFilter,
    availableTypeFilter,
    availableApisPaging.apiPaginationObject
  );

  const { data: apisData, initialLoad, showRefreshIndicator } = useDebouncedRefreshIndicator(apisDataUnbounced);

  workspaceApisPaging.useSetPagingTotal(apisData?.totalWorkspaceApis);
  availableApisPaging.useSetPagingTotal(apisData?.totalAvailableApis);

  // --------------------- //
  // RENDER
  // --------------------- //
  return (
    <CardStyles.Card data-testid='apis-tab'>
      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Workspace APIs</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={workspaceNameFilter}
            placeholder={'Search by name...'}
            onChangeValue={setWorkspaceNameFilter}
          />
          <SoloMultiselect
            values={workspaceImportStatusFilter}
            options={[ImportStatus.IMPORTED, ImportStatus.EXPORTED, ImportStatus.PRIVATE].map(type => ({
              value: type,
              title: importStatusMap[type].name
            }))}
            placeholder={'Filter by Import Status'}
            onChange={setWorkspaceImportStatusFilter}
            multiple
          />
          <SoloMultiselect
            values={workspaceTypeFilter}
            options={apiTypeOptions}
            placeholder={'Filter by Type'}
            onChange={setWorkspaceTypeFilter}
            multiple
          />
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!apisError || initialLoad ? (
        <ApiserverNoContent error={apisError} loading={`Retrieving ${workspaceName} api list...`} />
      ) : (
        <WorkspaceApisTable workspaceApis={apisData?.workspaceApis ?? []} pagingData={workspaceApisPaging.pagingData} />
      )}

      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Available APIs to Add</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={availableNameFilter}
            placeholder={'Search by name...'}
            onChangeValue={setAvailableNameFilter}
          />
          <SoloMultiselect
            values={availableTypeFilter}
            options={apiTypeOptions}
            placeholder={'Filter by Type'}
            onChange={setAvailableTypeFilter}
            multiple
          />
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!apisError || initialLoad ? (
        <ApiserverNoContent error={apisError} loading={`Retrieving ${workspaceName} api list...`} />
      ) : (
        <AvailableApisToImportTable
          availableApis={apisData?.availableApis ?? []}
          pagingData={availableApisPaging.pagingData}
        />
      )}
    </CardStyles.Card>
  );
};
