import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import React, { useEffect, useState } from 'react';
import { HealthCountBoxStyles } from './HealthCountBox.style';

export const statusFiltersAreEqual = (filter1: State[], filter2: State[]) =>
  filter1.every(status => filter2.includes(status)) && filter1.length === filter2.length;

export const toggleStatusFilter = (
  statusFilter: State[],
  setStatusFilter: React.Dispatch<React.SetStateAction<State[]>>,
  newFilter: State[]
) => {
  if (statusFiltersAreEqual(statusFilter, newFilter)) {
    // If this was the last filter applied, clear the status filter.
    setStatusFilter([]);
  } else {
    // Otherwise set it to this filter.
    setStatusFilter(newFilter);
  }
};

export const defaultHealthyStatusFilter = [State.ACCEPTED];
export const defaultUnHealthyStatusFilter = [State.FAILED, State.INVALID, State.PENDING, State.WARNING];

const HealthCountBox: React.FC<{
  totalCount: number | undefined;
  errorCount: number | undefined;
  onHealthyClick?: () => void;
  onUnhealthyClick?: () => void;
  isHealthySelected?: boolean;
  isUnhealthySelected?: boolean;
}> = ({ totalCount, errorCount, onHealthyClick, onUnhealthyClick, isHealthySelected, isUnhealthySelected }) => {
  // Stores the values to prevent flickering when new filters applied, causing current data to be undefined
  const [totalCached, setTotalCached] = useState(totalCount ?? 0);
  const [errorCached, setErrorCached] = useState(errorCount ?? 0);

  useEffect(() => {
    if (totalCount !== undefined) setTotalCached(totalCount);
  }, [totalCount]);

  useEffect(() => {
    if (errorCount !== undefined) setErrorCached(errorCount);
  }, [errorCount]);

  // This will hide if no data, unless this needs to be selectable for status filter handlers.
  if (!totalCached && !onHealthyClick && !onUnhealthyClick) return null;
  return (
    <>
      <HealthCountBoxStyles.CountBoxButton
        isSelected={!!isHealthySelected}
        isSelectable={!!onHealthyClick}
        onClick={onHealthyClick}
        disabled={onHealthyClick === undefined}
        data-testid='health-count-box-healthy'
        health={'normal'}>
        <HealthCountBoxStyles.CountDisplay>{totalCached - (errorCached ?? 0)}</HealthCountBoxStyles.CountDisplay>
        Healthy
      </HealthCountBoxStyles.CountBoxButton>
      {
        // This will hide if no errors, unless this needs to be selectable for status filter handlers.
        (!!errorCached || !!onUnhealthyClick) && (
          <HealthCountBoxStyles.CountBoxButton
            isSelected={!!isUnhealthySelected}
            isSelectable={!!onUnhealthyClick}
            onClick={onUnhealthyClick}
            disabled={onUnhealthyClick === undefined}
            data-testid='health-count-box-error'
            health={errorCached === 0 ? 'idle' : 'error'}>
            <HealthCountBoxStyles.CountDisplay>{errorCached}</HealthCountBoxStyles.CountDisplay>
            Unhealthy
          </HealthCountBoxStyles.CountBoxButton>
        )
      }
    </>
  );
};

export default HealthCountBox;
