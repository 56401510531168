import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { cssProp } from 'utils/helpers';

export const Centered = styled.div<{
  horizontal?: boolean;
  vertical?: boolean;
}>(({ horizontal, vertical }) => {
  return css`
    display: flex;
    width: 100%;
    height: 100%;
    ${cssProp('text-align', !!horizontal ? 'center' : 'auto')}
    ${cssProp('justify-content', !!horizontal ? 'center' : 'auto')}
    ${cssProp('align-items', !!vertical ? 'center' : 'auto')}
  `;
});
