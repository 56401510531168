/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Insight } from "../../../../api/gloo.solo.io/internal/insights/v2alpha1/insights_pb";
import { TypedClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { Insight_Severity } from "../../../../api/gloo.solo.io/internal/insights/v2alpha1/insights_pb";
import { BoolValue } from "../../../../../../../../google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.Code
 */
export interface Code {
    /**
     * @generated from protobuf field: string group = 1;
     */
    group: string;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListInsightsRequest
 */
export interface ListInsightsRequest {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter filter = 1;
     */
    filter?: ListInsightsRequest_InsightsFilter;
    /**
     * cursor holds the current position for paginated results.
     * If empty, results will start from beginning.
     *
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * The maximum number of results to return. default = 25
     *
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter
 */
export interface ListInsightsRequest_InsightsFilter {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.Code codes = 1;
     */
    codes: Code[];
    /**
     * @generated from protobuf field: google.protobuf.BoolValue system = 2;
     */
    system?: BoolValue;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter.Target target = 3;
     */
    target?: ListInsightsRequest_InsightsFilter_Target;
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight.Severity severities = 4;
     */
    severities: Insight_Severity[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter.Target
 */
export interface ListInsightsRequest_InsightsFilter_Target {
    /**
     * @generated from protobuf field: repeated string clusters = 1;
     */
    clusters: string[];
    /**
     * @generated from protobuf field: google.protobuf.BoolValue global = 2;
     */
    global?: BoolValue;
    /**
     * @generated from protobuf field: core.skv2.solo.io.TypedClusterObjectRef resources = 3;
     */
    resources?: TypedClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListInsightsResponse
 */
export interface ListInsightsResponse {
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight insights = 1;
     */
    insights: Insight[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 3;
     */
    apiserverErrors: string[];
    /**
     * total_insights is the total number of insights that match the filter.
     *
     * @generated from protobuf field: int32 total_insights = 4;
     */
    totalInsights: number;
    /**
     * error_insights is the total number of insights that match the filter and have an error severity.
     *
     * @generated from protobuf field: int32 error_insights = 5;
     */
    errorInsights: number;
    /**
     * warning_insights is the total number of insights that match the filter and have a warning severity.
     *
     * @generated from protobuf field: int32 warning_insights = 6;
     */
    warningInsights: number;
    /**
     * info_insights is the total number of insights that match the filter and have an info severity.
     *
     * @generated from protobuf field: int32 info_insights = 7;
     */
    infoInsights: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetInsightResolutionStepsRequest
 */
export interface GetInsightResolutionStepsRequest {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Code code = 1;
     */
    code?: Code;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetInsightResolutionStepsResponse
 */
export interface GetInsightResolutionStepsResponse {
    /**
     * @generated from protobuf field: string resolution_steps = 1;
     */
    resolutionSteps: string;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 2;
     */
    apiserverErrors: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Code$Type extends MessageType<Code> {
    constructor() {
        super("rpc.gloo.solo.io.Code", [
            { no: 1, name: "group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Code
 */
export const Code = new Code$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInsightsRequest$Type extends MessageType<ListInsightsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListInsightsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => ListInsightsRequest_InsightsFilter },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListInsightsRequest
 */
export const ListInsightsRequest = new ListInsightsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInsightsRequest_InsightsFilter$Type extends MessageType<ListInsightsRequest_InsightsFilter> {
    constructor() {
        super("rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter", [
            { no: 1, name: "codes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Code },
            { no: 2, name: "system", kind: "message", T: () => BoolValue },
            { no: 3, name: "target", kind: "message", T: () => ListInsightsRequest_InsightsFilter_Target },
            { no: 4, name: "severities", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["insights.internal.gloo.solo.io.Insight.Severity", Insight_Severity] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter
 */
export const ListInsightsRequest_InsightsFilter = new ListInsightsRequest_InsightsFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInsightsRequest_InsightsFilter_Target$Type extends MessageType<ListInsightsRequest_InsightsFilter_Target> {
    constructor() {
        super("rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter.Target", [
            { no: 1, name: "clusters", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "global", kind: "message", T: () => BoolValue },
            { no: 3, name: "resources", kind: "message", T: () => TypedClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListInsightsRequest.InsightsFilter.Target
 */
export const ListInsightsRequest_InsightsFilter_Target = new ListInsightsRequest_InsightsFilter_Target$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInsightsResponse$Type extends MessageType<ListInsightsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListInsightsResponse", [
            { no: 1, name: "insights", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Insight },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "total_insights", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "error_insights", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "warning_insights", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "info_insights", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListInsightsResponse
 */
export const ListInsightsResponse = new ListInsightsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInsightResolutionStepsRequest$Type extends MessageType<GetInsightResolutionStepsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetInsightResolutionStepsRequest", [
            { no: 1, name: "code", kind: "message", T: () => Code }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetInsightResolutionStepsRequest
 */
export const GetInsightResolutionStepsRequest = new GetInsightResolutionStepsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInsightResolutionStepsResponse$Type extends MessageType<GetInsightResolutionStepsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetInsightResolutionStepsResponse", [
            { no: 1, name: "resolution_steps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetInsightResolutionStepsResponse
 */
export const GetInsightResolutionStepsResponse = new GetInsightResolutionStepsResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.InsightsApi
 */
export const InsightsApi = new ServiceType("rpc.gloo.solo.io.InsightsApi", [
    { name: "ListInsights", options: {}, I: ListInsightsRequest, O: ListInsightsResponse },
    { name: "GetInsightResolutionSteps", options: {}, I: GetInsightResolutionStepsRequest, O: GetInsightResolutionStepsResponse }
]);
