import { css } from '@emotion/react';
import styled from '@emotion/styled';

export namespace GqlExplorerTopCardStyles {
  export const Card = styled.div(
    ({ theme }) => css`
      position: relative;
      border-radius: 5px;
      border: 1px solid ${theme.splashBlue};
      background-color: ${theme.white};
      box-shadow: 0 0 10px ${theme.aprilGrey};
      padding: 5px;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 1em;
      display: flex;
      flex-wrap: wrap;
    `
  );

  export const Header = {
    Container: styled.div`
      padding: 15px;
      gap: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `,
    TextContainer: styled.div`
      justify-content: flex-start;
    `,
    Title: styled.div(
      ({ theme }) => css`
        font-size: 2em;
        color: ${theme.neptuneBlue};
        margin-bottom: 0.5em;
      `
    ),
    SubTitle: styled.div(
      ({ theme }) => css`
        font-size: 1.25em;
        color: ${theme.augustGrey};
      `
    )
  };

  export const Controls = {
    Container: styled.div(
      ({ theme }) => css`
        padding: 10px;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: ${theme.neptuneBlue};
        input {
          color: ${theme.septemberGrey};
        }
      `
    ),
    Grid: styled.div<{ numRows: number }>(
      ({ numRows }) => css`
        display: grid;
        grid-template-rows: repeat(${numRows}, auto);
        text-align: right;
        gap: 5px;
      `
    ),
    Row: styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
      input {
        min-width: 300px;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    `
  };
}
