import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { VariableTransformation } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { stringifyVariableTransformation } from 'utils/graphql-resolver-helpers';
import { GqlResolverModalStyles } from './GqlResolverModal.style';

const VariableTransformationLabel = ({
  title,
  variableTransformation
}: {
  title?: string;
  variableTransformation: VariableTransformation | undefined;
}) => {
  //
  // Render
  //
  if (!variableTransformation) return null;
  return (
    <SoloListCardStyles.Container>
      {title !== undefined && <SoloListCardStyles.Title>{title}</SoloListCardStyles.Title>}
      <GqlResolverModalStyles.FancyCodeDisplayerContainer>
        <GqlResolverModalStyles.FancyCodeDisplayerContainer>
          <FancyCodeDisplayer
            hideLineNumbers
            includeBorderBackground={false}
            contentString={stringifyVariableTransformation(variableTransformation)}
            type={'yaml'}
          />
        </GqlResolverModalStyles.FancyCodeDisplayerContainer>
      </GqlResolverModalStyles.FancyCodeDisplayerContainer>
    </SoloListCardStyles.Container>
  );
};

export default VariableTransformationLabel;
