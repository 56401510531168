import styled from '@emotion/styled';
import { css } from '@emotion/react';

export namespace DashboardLandingEmptyModalStyles {
  export const Title = styled.div(() => {
    return css`
      font-size: 22px;
      line-height: 26px;
    `;
  });

  export const RegisteringIconHolder = styled.div(({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 133px;
      height: 133px;
      border-radius: 133px;
      color: ${theme.splashBlue};
      border: 1px solid currentColor;
    `;
  });

  export const RegisteringTitle = styled.div`
    font-size: 24px;
    text-align: center;
  `;
  export const RegisteringBody = styled.div`
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  `;

  export const InstructionsArea = styled.div(({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: ${theme.dropBlue};
      border: 1px solid ${theme.seaBlue};
      border-radius: 8px;
      padding: 20px;
    `;
  });

  export const InstructionsTitle = styled.div`
    font-size: 24px;
  `;
}
