import React from 'react';
import { GqlSchemaTabStyles } from './GqlSchemaTabStyles.style';

const GqlTabItemBreadcrumb = ({
  tabHeader,
  focusedPath,
  onTypeClick
}: {
  tabHeader: string;
  focusedPath: string[];
  onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
}) => {
  return (
    <GqlSchemaTabStyles.TabDetailsBreadcrumbs data-testid={`gql-tab-breadcrumbs-${tabHeader.toLowerCase()}`}>
      <GqlSchemaTabStyles.TabDetailsBreadcrumbsButton onClick={() => onTypeClick(tabHeader, false)}>
        {tabHeader}
      </GqlSchemaTabStyles.TabDetailsBreadcrumbsButton>
      {focusedPath.map((itemName, idx) => (
        <React.Fragment key={idx}>
          <span>/</span>
          {idx < focusedPath.length - 1 ? (
            <GqlSchemaTabStyles.TabDetailsBreadcrumbsButton
              onClick={() => onTypeClick(tabHeader + '/' + focusedPath.slice(0, idx + 1).join('/'), false)}>
              {itemName}
            </GqlSchemaTabStyles.TabDetailsBreadcrumbsButton>
          ) : (
            <span>{itemName}</span>
          )}
        </React.Fragment>
      ))}
    </GqlSchemaTabStyles.TabDetailsBreadcrumbs>
  );
};

export default GqlTabItemBreadcrumb;
