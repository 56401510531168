import React from 'react';
import { DataError } from 'Components/Common/DataError';
import { loginApi, TempRoles } from 'Api/login';
import { FlagsLandingStyles as Styles } from './FlagsLanding.style';
import { Loading } from 'Components/Common/Loading';
import { Asset } from 'assets';
import { SoloToggleSwitch } from 'Components/Common/Input/SoloToggleSwitch';
import { checkLicenseEnabled, Permission, usePermissionGateRedirect, usePermissions } from 'utils/permissions';
import { SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { getEnumEntries } from 'utils/helpers';
import { dashboardApi } from 'Api/dashboard';
import { SoloButton } from 'Styles/CommonEmotions/button';
import {
  isUIOnlyFeatureFlagOn,
  setUIOnlyFeatureFlagOverride,
  UIFEATUREFLAG_NEWLAYOUTALGORITHM,
  UIFEATUREFLAG_SEARCHHIGHLIGHTING
} from './UIOnlyFlags';

export const FlagsLanding = () => {
  usePermissionGateRedirect(Permission.CanViewFlags);
  const { loading: permissionsLoading, errors: permissionErrors } = usePermissions();

  const { data: loggedInRole, mutate: mutateLoggedInRole } = loginApi.useGetLoggedInRole();
  const { data: featureFlags, mutate: mutateFeatureFlags } = dashboardApi.useGetFeatureFlags();

  const updateLicense = (licenseKey: dashboardApi.LicenseKey, on: boolean | 'unset') => {
    dashboardApi.setFeatureFlagOverride(licenseKey, on);
    mutateFeatureFlags(data =>
      on === 'unset' || !data
        ? data
        : {
            ...data,
            licenses: {
              ...data?.licenses,
              [licenseKey]: dashboardApi.createFakeLicense(on)
            }
          }
    );
  };

  const updateHelmFlag = (helmKey: dashboardApi.HelmFlagKey, on: boolean | 'unset') => {
    dashboardApi.setHelmFlagOverride(helmKey, on);
    mutateFeatureFlags(data => (on === 'unset' || !data ? data : { ...data, [helmKey]: on }));
  };

  const updateRole = (newRole: TempRoles) => {
    loginApi.setRole(newRole);
    mutateLoggedInRole({ role: newRole });
  };

  if (loggedInRole === undefined || permissionsLoading) {
    if (permissionErrors.length > 0) {
      return <DataError error={permissionErrors[0]} />;
    } else {
      return <Loading />;
    }
  }

  return (
    <Styles.Container>
      <Styles.TitleLine>
        <Styles.Title>
          <Styles.TitleIconHolder>
            <Asset.KeysIcon />
          </Styles.TitleIconHolder>
          User Role
        </Styles.Title>
      </Styles.TitleLine>

      <Styles.Grid>
        <div>
          <Asset.UserIcon />
        </div>
        <div>
          <SoloDropdown
            value={loggedInRole.role}
            options={getEnumEntries(TempRoles).map(([roleKey, value]) => {
              return {
                key: roleKey,
                value,
                displayValue: roleKey
              };
            })}
            onChange={updateRole}
          />
        </div>
      </Styles.Grid>

      <Styles.TitleLine>
        <Styles.Title>
          <Styles.TitleIconHolder>
            <Asset.KeysIcon />
          </Styles.TitleIconHolder>
          Feature Flags (Licenses)
        </Styles.Title>
      </Styles.TitleLine>

      <Styles.Grid>
        <div>
          <Asset.ApiIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>Gateway (GraphQL/Portal)</Styles.ToggleLabel>}
            labelOnRight
            checked={checkLicenseEnabled(featureFlags?.licenses?.glooGateway)}
            onChange={on => updateLicense('glooGateway', on)}
          />
        </div>
        <div>
          {localStorage.getItem(dashboardApi.localStorageLicenceFlagKeys['glooGateway']) !== null && (
            <SoloButton onClick={() => updateLicense('glooGateway', 'unset')}>Clear Overwritten Value</SoloButton>
          )}
        </div>

        <div>
          <Asset.CiliumIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>Network (Cilium)</Styles.ToggleLabel>}
            labelOnRight
            checked={checkLicenseEnabled(featureFlags?.licenses?.glooNetwork)}
            onChange={on => updateLicense('glooNetwork', on)}
          />
        </div>
        <div>
          {localStorage.getItem(dashboardApi.localStorageLicenceFlagKeys['glooNetwork']) !== null && (
            <SoloButton onClick={() => updateLicense('glooNetwork', 'unset')}>Clear Overwritten Value</SoloButton>
          )}
        </div>

        <div>
          <Asset.GraphIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>Graph Search Highlighting</Styles.ToggleLabel>}
            labelOnRight
            checked={isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING)}
            onChange={on => setUIOnlyFeatureFlagOverride(UIFEATUREFLAG_SEARCHHIGHLIGHTING, on)}
          />
        </div>
        <div>
          {localStorage.getItem(UIFEATUREFLAG_SEARCHHIGHLIGHTING) !== null && (
            <SoloButton onClick={() => setUIOnlyFeatureFlagOverride(UIFEATUREFLAG_SEARCHHIGHLIGHTING, 'unset')}>
              Clear Overwritten Value
            </SoloButton>
          )}
        </div>

        <div>
          <Asset.GraphIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>New Graph Layout Algorithm</Styles.ToggleLabel>}
            labelOnRight
            checked={isUIOnlyFeatureFlagOn(UIFEATUREFLAG_NEWLAYOUTALGORITHM)}
            onChange={on => setUIOnlyFeatureFlagOverride(UIFEATUREFLAG_NEWLAYOUTALGORITHM, on)}
          />
        </div>
        <div>
          {localStorage.getItem(UIFEATUREFLAG_NEWLAYOUTALGORITHM) !== null && (
            <SoloButton onClick={() => setUIOnlyFeatureFlagOverride(UIFEATUREFLAG_NEWLAYOUTALGORITHM, 'unset')}>
              Clear Overwritten Value
            </SoloButton>
          )}
        </div>
      </Styles.Grid>

      <Styles.TitleLine>
        <Styles.Title>
          <Styles.TitleIconHolder>
            <Asset.CodeCloseTag />
          </Styles.TitleIconHolder>
          Helm Flags
        </Styles.Title>
      </Styles.TitleLine>
      <Styles.Grid>
        <div>
          <Asset.PortalIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>Portal</Styles.ToggleLabel>}
            labelOnRight
            checked={featureFlags?.portalEnabled ?? false}
            onChange={on => updateHelmFlag('portalEnabled', on)}
          />
        </div>
        <div>
          {localStorage.getItem(dashboardApi.localStorageHelmFlagKeys['portalEnabled']) !== null && (
            <SoloButton onClick={() => updateHelmFlag('portalEnabled', 'unset')}>Clear Overwritten Value</SoloButton>
          )}
        </div>

        <div>
          <Asset.GraphIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>Graph</Styles.ToggleLabel>}
            labelOnRight
            checked={featureFlags?.graphEnabled ?? false}
            onChange={on => updateHelmFlag('graphEnabled', on)}
          />
        </div>
        <div>
          {localStorage.getItem(dashboardApi.localStorageHelmFlagKeys['graphEnabled']) !== null && (
            <SoloButton onClick={() => updateHelmFlag('graphEnabled', 'unset')}>Clear Overwritten Value</SoloButton>
          )}
        </div>

        <div>
          <Asset.InsightAnalysisIcon />
        </div>
        <div>
          <SoloToggleSwitch
            label={<Styles.ToggleLabel>Insights</Styles.ToggleLabel>}
            labelOnRight
            checked={featureFlags?.inightsEnabled ?? false}
            onChange={on => updateHelmFlag('inightsEnabled', on)}
          />
        </div>
        <div>
          {localStorage.getItem(dashboardApi.localStorageHelmFlagKeys['inightsEnabled']) !== null && (
            <SoloButton onClick={() => updateHelmFlag('inightsEnabled', 'unset')}>Clear Overwritten Value</SoloButton>
          )}
        </div>
      </Styles.Grid>
    </Styles.Container>
  );
};
