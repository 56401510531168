import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { healthColors } from 'Styles/colors';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getStatusColors } from 'utils/health-status';

export namespace OverviewCardStyles {
  type OverviewHealthProps = {
    health?: State;
  };
  type BodyProps = { collapsed: boolean };

  export const Container = styled(CardStyles.ShadowlessCard)<OverviewHealthProps>(
    ({ theme, health, onClick }) => css`
      padding: 0;
      border: 1px solid ${health === State.ACCEPTED ? theme.aprilGrey : getStatusColors(health).border};
      margin-bottom: 20px;
      cursor: ${!!onClick ? 'pointer' : 'initial'};
    `
  );

  export const Header = styled.div<OverviewHealthProps>(
    ({ health, theme }) => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${health === State.ACCEPTED ? theme.marchGrey : getStatusColors(health).background};
      padding: 8px 10px;
      border-radius: 4px 4px 0 0;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
      }
    `
  );
  export const Title = styled.div<OverviewHealthProps>(
    ({ health, theme }) => css`
      color: ${health === State.ACCEPTED ? theme.oceanBlue : getStatusColors(health).text};
    `
  );

  export const HealthHolder = styled.div`
    display: flex;
    align-items: center;

    ${svgBasics(16)}
  `;

  export const Body = styled.div`
    padding: 8px 14px;
  `;
  export const BodyDetails = styled.div`
    padding: 10px 0;
  `;
  interface BodyDetailsRowProps {
    itemCount: number;
  }
  export const BodyDetailsRow = styled.div<BodyDetailsRowProps>(
    ({ itemCount }) => css`
      display: grid;
      grid-template-columns: repeat(4, auto);
      align-items: center;
      gap: 20px;

      ${itemCount >= 4
        ? css`
            @media screen and (max-width: 1700px) {
              display: grid;
              grid-template-columns: repeat(3, auto);
            }
          `
        : ''}

      ${itemCount >= 3
        ? css`
            @media screen and (max-width: 1450px) {
              display: grid;
              grid-template-columns: repeat(2, auto);
            }
          `
        : ''}
    `
  );

  type HealthColorType = {
    health?: keyof typeof healthColors;
  };
  export const BodyDetailsInstance = styled.div<HealthColorType>(({ health, theme }) => {
    const svgColor = health !== undefined ? healthColors[health].dark : theme.seaBlue;
    return css`
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1;
      min-width: 0; /* Needed for truncated text to work */

      ${health !== undefined
        ? css`
            color: ${svgColor};
          `
        : ''}

      ${svgBasics(21, svgColor)}
      svg {
        margin-right: 6px;
      }
    `;
  });
  export const BodyDetailsInstanceTitle = styled.span`
    font-weight: 600;
    margin-right: 5px;
  `;
  export const ExtraDetailsHolder = styled.div`
    min-height: 100px;
  `;
  export const TooltipErrorText = styled.div(
    ({ theme }) =>
      css`
        color: ${theme.peachOrange};
      `
  );

  export const FurtherDetails = styled.div<BodyProps>(
    ({ collapsed, theme }) => css`
      ${collapsed
        ? css`
            border-top: 1px solid transparent;
            margin-bottom: 0px;
          `
        : css`
            border-top: 1px solid ${theme.aprilGrey};
            margin-bottom: 10px;
          `}

      transition:
      margin .3s,
      border-color .2s;
    `
  );
}
