import { HealthIndicatorIcon } from 'Components/Common/HealthIndicator';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useContext } from 'react';
import { graphqlApiTypeMap } from 'utils/types';
import { externalLinks } from 'utils/url-external-links-map';
import { GqlDetailsStyles } from '../Details/GqlDetails.style';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlExplorerTopCardStyles } from './GqlExplorerTopCard.style';

const GqlExplorerTopCard = ({ gatewayRef }: { gatewayRef: ClusterObjectRef | undefined }) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api, apiType, istioRouteName } = gqlCtx;

  //
  // Render
  //
  return (
    <GqlExplorerTopCardStyles.Card>
      {/*
       * Left Card Section
       */}
      <GqlExplorerTopCardStyles.Header.Container>
        <GqlDetailsStyles.ApiIconCircleContainer>
          <HealthIndicatorIcon
            status={api.status}
            icon={graphqlApiTypeMap[apiType].icon}
          />
        </GqlDetailsStyles.ApiIconCircleContainer>
        <GqlExplorerTopCardStyles.Header.TextContainer>
          <GqlExplorerTopCardStyles.Header.Title>{istioRouteName}</GqlExplorerTopCardStyles.Header.Title>
          {gatewayRef && (
            <GqlExplorerTopCardStyles.Header.SubTitle>{gatewayRef.name}</GqlExplorerTopCardStyles.Header.SubTitle>
          )}
        </GqlExplorerTopCardStyles.Header.TextContainer>
      </GqlExplorerTopCardStyles.Header.Container>

      {/*
       * Right Card Section
       */}
      <GqlExplorerTopCardStyles.Controls.Container>
        <GqlExplorerTopCardStyles.Controls.Grid numRows={1}>
          <GqlExplorerTopCardStyles.Controls.Row>
            <ExternalSoloLink href={externalLinks.graphql_org.learn_queries} newTab withArrow>
              GraphQL Docs
            </ExternalSoloLink>
          </GqlExplorerTopCardStyles.Controls.Row>
        </GqlExplorerTopCardStyles.Controls.Grid>
      </GqlExplorerTopCardStyles.Controls.Container>
    </GqlExplorerTopCardStyles.Card>
  );
};

export default GqlExplorerTopCard;
