import { EmptyAsterisk } from 'Components/Common/EmptyAsterisk';
import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn, createStatusColumn } from 'Components/Common/SoloTableCells';
import SoloTableTextEllipsesCell from 'Components/Common/SoloTableTextEllipsesCell';
import { StringListWithTooltip } from 'Components/Common/StringListWithTooltip';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListOverviewGatewaysResponse_GatewaySummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildIdFromRef } from 'utils/helpers';
import { buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { GatewaysLandingStyles } from './GatewaysLanding.style';

export const GatewaysLandingBody = (
  props: CustomLandingBodyProps<ListOverviewGatewaysResponse_GatewaySummary>
) => {
  return <LandingBody {...props} GridComponent={GatewaysLandingBodyGrid} TableComponent={GatewaysLandingBodyTable} />;
};

//
// Grid variant
//
const GatewaysLandingBodyGrid = (props: BaseLandingBodyProps<ListOverviewGatewaysResponse_GatewaySummary>) => {
  const { items: gateways, pagingData } = props;

  const getGatewayDetailsUrl = (gateway: ListOverviewGatewaysResponse_GatewaySummary) => {
    if (!!gateway.gatewayRef && !!gateway.ownerWorkspace?.ref) {
      return buildWorkspaceRouteDetailsUrl(
        gateway.ownerWorkspace?.ref?.name,
        RouteType.VIRTUAL_GATEWAY,
        gateway.gatewayRef
      );
    }
    return undefined;
  };

  return (
    <>
      <OverviewGridStyles.Grid data-testid='gateways-landing-body'>
        {gateways.map(gateway => {
          let metaInfo: {
            label: string;
            data: NonNullable<ReactNode>;
          }[] = [];
          if (!!gateway.ownerWorkspace?.ref?.name) {
            metaInfo.push({
              label: 'Workspace',
              data: gateway.ownerWorkspace.ref.name
            });
          }
          if (!!gateway.gatewayRef) {
            metaInfo.push({
              label: 'Cluster',
              data: gateway.gatewayRef.clusterName
            });
            metaInfo.push({
              label: 'Namespace',
              data: gateway.gatewayRef.namespace
            });
          }
          return (
            <li key={buildIdFromRef(gateway.gatewayRef) + buildIdFromRef(gateway.ownerWorkspace?.ref)}>
              <a
                href={getGatewayDetailsUrl(gateway)}
                data-testid={`${gateway.gatewayRef?.name}-gateway-card`}
                aria-label={`Gateway Card: ${gateway.gatewayRef?.name ?? 'Gateway Unnamed'}`}>
                <OverviewGridStyles.Card
                  title={
                    !gateway.gatewayRef || !gateway.ownerWorkspace ? 'Missing data to visit details page.' : undefined
                  }>
                  <OverviewGridStyles.CardHeader>
                    {gateway.gatewayRef?.name ?? 'Gateway Unnamed'}
                    <HealthIndicator status={gateway.status} size={15} />
                  </OverviewGridStyles.CardHeader>
                  <OverviewGridStyles.CardContent>
                    <SecondaryInfo items={metaInfo} small={true} />
                  </OverviewGridStyles.CardContent>
                  <OverviewGridStyles.CardFooter>
                    <GatewaysLandingStyles.Hosts>
                      {gateway.hosts.length === 1 && gateway.hosts[0] === '*' ? (
                        <EmptyAsterisk />
                      ) : (
                        <StringListWithTooltip items={gateway.hosts} />
                      )}
                    </GatewaysLandingStyles.Hosts>
                  </OverviewGridStyles.CardFooter>
                </OverviewGridStyles.Card>
              </a>
            </li>
          );
        })}
      </OverviewGridStyles.Grid>
      {!!pagingData.total && <SoloPagination {...pagingData} pageSizeOptions={[3, 6, 12, 24]} />}
    </>
  );
};

//
// Table variant
//
interface TableFields {
  key: string;
  status: Status | undefined;
  name: string;
  namespace: string;
  clusterName: string;
  workspaceName: string;
  hostsList: string[];
  gateway: ListOverviewGatewaysResponse_GatewaySummary;
}
const GatewaysLandingBodyTable = (
  props: BaseLandingBodyProps<ListOverviewGatewaysResponse_GatewaySummary>
) => {
  const { items: gateways, pagingData } = props;
  const navigate = useNavigate();
  const columns: SoloColumnsType<TableFields> = [
    createStatusColumn({ dataIndex: 'status' }),
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: v => <SoloTableTextEllipsesCell data-testid='name-cell' value={v} />
    },
    createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
    createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
    createEllipseTextColumn({ title: 'Workspace', dataIndex: 'workspaceName' }),
    {
      title: 'Hosts',
      dataIndex: 'hostsList',
      render: hostsList =>
        hostsList.length === 1 && hostsList[0] === '*' ? <EmptyAsterisk /> : <StringListWithTooltip items={hostsList} />
    }
  ];

  const tableData = useMemo<TableFields[]>(
    () =>
      gateways.map(g => ({
        key: buildIdFromRef(g.gatewayRef) + buildIdFromRef(g.ownerWorkspace?.ref),
        status: g.status,
        name: g.gatewayRef?.name ?? '',
        namespace: g.gatewayRef?.namespace ?? '',
        clusterName: g.gatewayRef?.clusterName ?? '',
        workspaceName: g.ownerWorkspace?.ref?.name ?? '',
        hostsList: g.hosts ?? [],
        gateway: g
      })),
    [gateways]
  );

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable
        data-testid='gateways-landing-body'
        dataSource={tableData}
        columns={columns}
        paging={pagingData}
        onRow={(record, _rowIndex) => ({
          onClick:
            !!record.gateway && !!record.workspaceName
              ? () =>
                navigate(
                  buildWorkspaceRouteDetailsUrl(
                    record.workspaceName,
                    RouteType.VIRTUAL_GATEWAY,
                    record.gateway.gatewayRef
                  )
                )
              : undefined
        })}
      />
    </Spacer>
  );
};
