import { gqlApi } from 'Api/graphql';
import { HealthNotificationBox } from 'Components/Common/HealthNotificationBox';
import { SoloCheckbox } from 'Components/Common/Input/SoloCheckbox';
import { SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { SoloTextarea } from 'Components/Common/Input/SoloTextarea';
import { Loading } from 'Components/Common/Loading';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GetGraphqlApiExplorerSettingsResponse_GatewayOption } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useContext, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { graphqlApiTypeMap } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlExplorerSettingsModalStyles as Styles } from './GqlExplorerSettingsModal.style';
import { GqlExplorerStyles } from './ThemedGraphiql/GqlExplorer.style';

const urlTooltip = 'This is the URL endpoint where the explorer will direct requests.';

export const GqlExplorerCorsTooltipContent = ({ url, urlError }: { url: string; urlError: string | undefined }) => {
  const errorOrigin = useMemo(() => {
    try {
      return new URL(url).origin;
    } catch {
      return '';
    }
  }, [url]);
  return (
    <Spacer padding={2}>
      There was an error fetching the URL:
      <Spacer pt={2}>
        <GqlExplorerStyles.Toolbar.TooltipNestedInfo>{urlError}</GqlExplorerStyles.Toolbar.TooltipNestedInfo>
      </Spacer>
      {!url ||
        (window.location.origin !== errorOrigin && (
          <Spacer pt={2}>
            A CORS Policy is required for the Explorer UI to invoke the GraphQL API.{' '}
            <ExternalSoloLink inline newTab href={docLinks.gateway.graphql_ui_HASH.try_out_graphql_apis}>
              VIEW DOCS &gt;
            </ExternalSoloLink>
          </Spacer>
        ))}
    </Spacer>
  );
};

const { useGetGraphqlExplorerSettings } = gqlApi;

interface GqlExplorerSettingsModalProps {
  gatewayRef: ClusterObjectRef | undefined;
  selectedGatewayOptions: GetGraphqlApiExplorerSettingsResponse_GatewayOption | undefined;
  onSelectedGatewayOptionsChange: (gwOption: GetGraphqlApiExplorerSettingsResponse_GatewayOption | undefined) => void;
  customUrlValue: string;
  url: string;
  onUrlChange: (url: string) => void;
  schemaText: string;
  onSchemaTextChange: (schemaText: string) => void;
  isSchemaTextOverwritten: boolean;
  rememberSchemaChange: boolean;
  setRememberSchemaChange: (schemaText: boolean) => void;
  isFetching: boolean;
  urlError: string | undefined;
  onClose?: () => void;
}
export const GqlExplorerSettingsModal = ({
  gatewayRef,
  selectedGatewayOptions,
  onSelectedGatewayOptionsChange,
  customUrlValue,
  url,
  onUrlChange,
  schemaText,
  onSchemaTextChange,
  isSchemaTextOverwritten,
  rememberSchemaChange,
  setRememberSchemaChange,
  isFetching,
  urlError,
  onClose
}: GqlExplorerSettingsModalProps) => {
  di(useGetGraphqlExplorerSettings);
  const { api, apiType, routeTableRef } = useContext(GqlLandingContext);

  const { data: expSettings } = useGetGraphqlExplorerSettings(routeTableRef);
  const gwOptions = expSettings?.gatewayOptions ?? [];
  const selectedGwOptionKey = useMemo(() => (!!gatewayRef?.name ? buildIdFromRef(gatewayRef) : 'custom'), [gatewayRef]);

  // We could uncomment or change this depending if we want to keep
  // the schema text input enabled or disabled.
  // const schemaInputEnabled = process.env.NODE_ENV !== 'production';
  const schemaInputEnabled = true;

  const status = { ...api.status };
  if (status.state !== State.ACCEPTED) status.state = State.WARNING;

  return (
    <SoloModal width={1000} onClose={onClose} withPadding data-testid='graphql-explorer-settings-modal'>
      <SoloModalHeader
        title='Explorer Settings'
        subtitle={['API Explorer', 'Settings']}
        icon={graphqlApiTypeMap[apiType].icon}
        status={api.status}
      />

      <Styles.Column>
        {gwOptions.length > 0 && (
          <div>
            <SoloDropdown
              label='Gateways'
              value={selectedGwOptionKey}
              onChange={val =>
                // Propagates a change to the selected gateway option through the URL search parameters.
                val === 'custom'
                  ? onUrlChange(customUrlValue)
                  : onSelectedGatewayOptionsChange(gwOptions.find(o => buildIdFromRef(o.gatewayRef) === val))
              }
              options={[
                ...gwOptions.map(o => ({
                  key: buildIdFromRef(o.gatewayRef),
                  displayValue: o.gatewayRef?.name ?? 'No name found.'
                })),
                { key: 'custom', displayValue: '-- user defined --' }
              ]}
            />
            {selectedGatewayOptions?.error && (
              <Spacer mt={5}>
                <HealthNotificationBox
                  status={{ state: State.FAILED, validationErrors: [selectedGatewayOptions.error] }}
                />
              </Spacer>
            )}
          </div>
        )}
        <div>
          <Styles.LabelRow>
            <Styles.Label htmlFor='endpointUrl'>Endpoint URL</Styles.Label>

            <Spacer mt='-3px'>
              {!!isFetching ? (
                //
                // Loading
                //
                <Tooltip placement='right' title={urlTooltip} trigger='hover'>
                  <span>
                    <Loading tiny={true} />
                  </span>
                </Tooltip>
              ) : !!urlError ? (
                //
                // Error
                //
                <GqlExplorerStyles.Toolbar.CorsTooltip>
                  <Tooltip
                    placement='right'
                    title={<GqlExplorerCorsTooltipContent url={url} urlError={urlError} />}
                    trigger='hover'>
                    <Asset.InfoIcon />
                  </Tooltip>
                </GqlExplorerStyles.Toolbar.CorsTooltip>
              ) : (
                //
                // Success
                //
                <Tooltip placement='right' title={urlTooltip} trigger='hover'>
                  <Asset.SmallLightGreenCheck width={18} height={18} />
                </Tooltip>
              )}
            </Spacer>
          </Styles.LabelRow>

          <SoloInput
            id='endpointUrl'
            placeholder='Endpoint URL'
            aria-label='Endpoint URL'
            value={url}
            onChange={e => onUrlChange(e.currentTarget.value)}
          />
        </div>

        {schemaInputEnabled && (
          <div>
            <Styles.LabelRow>
              <Styles.Label htmlFor='schemaText'>Schema Text</Styles.Label>

              <Spacer mt='1px'>
                <Tooltip
                  placement='left'
                  title='Paste in your GraphQL schema to override the default schema. Add a matching schema and endpoint to make GraphQL requests.'
                  trigger='hover'>
                  <Asset.InfoIcon width={18} height={18} />
                </Tooltip>
              </Spacer>

              <Tooltip title='This will store this custom schema in local storage' trigger='hover'>
                <Spacer ml='25px'>
                  <SoloCheckbox
                    title='Remember'
                    label
                    checked={rememberSchemaChange}
                    onChange={e => setRememberSchemaChange(e.target.checked)}
                  />
                </Spacer>
              </Tooltip>

              <Styles.RightSideFlexor>
                <SoloButton
                  small
                  onClick={() => {
                    onSchemaTextChange(api.schemaDefinition);
                    setRememberSchemaChange(false);
                  }}
                  disabled={!isSchemaTextOverwritten}>
                  Reset
                </SoloButton>
              </Styles.RightSideFlexor>
            </Styles.LabelRow>

            <SoloTextarea
              id='schemaText'
              placeholder='Schema Text'
              aria-label='Schema Text'
              value={schemaText}
              rows={20}
              resize='vertical'
              onChangeValue={onSchemaTextChange}
            />
          </div>
        )}
      </Styles.Column>
    </SoloModal>
  );
};
