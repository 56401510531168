import { isPolicyTypeDetailsPageSupported } from 'Components/Features/Policies/Details/PolicyDetailsLanding';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ResourceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { getPolicyTypeFromResourceType, getRouteTypeFromResourceType } from 'utils/types';
import {
  buildPortalDetailsUrl,
  buildServiceDetailsUrl,
  buildWorkspaceDestinationDetailsUrl,
  buildWorkspacePolicyDetailsUrl,
  buildWorkspaceRouteDetailsUrl
} from 'utils/url-builders';

export function getResourceDetailsUrlBasedOnSummaryType({
  type,
  ref,
  workspaceName
}: ResourceSummary): string | undefined {
  if (!ref || type === ResourceType.UNKNOWN_RESOURCE_TYPE) return undefined;

  // First check simple cases with only 1 type

  if (type === ResourceType.SERVICE) {
    return buildServiceDetailsUrl(ref);
  }

  if (type === ResourceType.PORTAL) {
    return buildPortalDetailsUrl(ref);
  }

  // Next check cases where there are multiple types. Helper function used to convert the type to know if
  // the current resource type is actually valid for the specific page type.

  const policyType = getPolicyTypeFromResourceType(type);
  if (policyType !== undefined && !!workspaceName && isPolicyTypeDetailsPageSupported(policyType)) {
    return buildWorkspacePolicyDetailsUrl(workspaceName, policyType, ref);
  }

  const routeType = getRouteTypeFromResourceType(type);
  if (routeType !== undefined && !!workspaceName) {
    return buildWorkspaceRouteDetailsUrl(workspaceName, routeType, ref);
  }

  // Somewhat special case as k8 is a service, and while this currently appears after the service check,
  // it seemed safer to only check the ones that won't cause issues if the order was ever changed.

  const destinationKind =
    type === ResourceType.VIRTUAL_DESTINATION
      ? DestinationKind.VIRTUAL_DESTINATION
      : type === ResourceType.EXTERNAL_SERVICE
      ? DestinationKind.EXTERNAL_SERVICE
      : undefined;
  if (destinationKind !== undefined && !!workspaceName) {
    return buildWorkspaceDestinationDetailsUrl(workspaceName, destinationKind, ref);
  }

  return undefined;
}
