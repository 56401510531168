import { dashboardApi } from 'Api/dashboard';
import { CardStyles } from 'Components/Common/Card';
import { useGetGraphCache } from 'Components/Features/Graph/General/graph-cache';
import TracingLandingEmpty from 'Components/Features/Tracing/TracingLandingEmpty';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { FullPageIframe } from 'Styles/CommonEmotions/iframe';
const { useGetTracingBasePath } = dashboardApi;

export const ServiceDetailsTracing = ({ serviceRef }: { serviceRef: ClusterObjectRef }) => {
  di(useGetTracingBasePath);
  const { data } = useGetTracingBasePath();
  const { cache } = useGetGraphCache();

  // The Jaeger tracing search page seems to identify services in the format: '{name}.{namespace}'.
  const serviceOption = `${serviceRef.name}.${serviceRef.namespace}`;

  // This is to make the Jaeger UI work when developing the UI locally.
  const tracingPageOrigin =
    window.location.origin === 'http://localhost:3000' ? 'http://localhost:8090' : window.location.origin;
  const tracingPageUrl = !!data?.tracingBasePath ? tracingPageOrigin + data.tracingBasePath : undefined;

  const tracingLookbackTime = useMemo(
    () =>
      cache.timeInterval < 60 * 5
        ? '5m'
        : cache.timeInterval < 60 * 60
        ? `${cache.timeInterval / 60}m`
        : `${cache.timeInterval / (60 * 60)}h`,
    [cache.timeInterval]
  );

  return (
    <CardStyles.Card style={{ height: '100%', minHeight: '650px' }}>
      {!!data?.tracingBasePath ? (
        <FullPageIframe
          title='Tracing page'
          src={`${tracingPageUrl}/search?limit=20&lookback=${tracingLookbackTime}&service=${serviceOption}`}
          containerPadding='18px'
        />
      ) : (
        <div data-testid='service-tracing-container'>
          <TracingLandingEmpty />
        </div>
      )}
    </CardStyles.Card>
  );
};
