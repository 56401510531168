import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createStatusColumn } from 'Components/Common/SoloTableCells';
import { Svg, SvgWithTextContainer } from 'Components/Common/Svg';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Tooltip } from 'antd';
import {
  ApiType,
  Status
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListOverviewApisResponse_ApiSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildIdFromRef } from 'utils/helpers';
import { apiTypeMap } from 'utils/types';
import { buildApiDetailsUrl } from 'utils/url-builders';
import { ApisLandingStyles } from './ApisLanding.style';
import ApisLandingApiExplorerButton from './ApisLandingApiExplorerButton';

function isApiTypeDetailsPageSupported(type: ApiType) {
  return type !== ApiType.UNKNOWN;
}

const HasDetailsWrapper = ({ type, children }: { type: ApiType; children: React.ReactNode }) => {
  return isApiTypeDetailsPageSupported(type) ? (
    <>{children}</>
  ) : (
    <Tooltip
      title={`${apiTypeMap[type].name} api details pages are not currently supported by Gloo Platform UI`}
      trigger='hover'>
      {children}
    </Tooltip>
  );
};

export const ApisLandingBody = (props: CustomLandingBodyProps<ListOverviewApisResponse_ApiSummary>) => (
  <LandingBody {...props} GridComponent={ApisLandingBodyGrid} TableComponent={ApisLandingBodyTable} />
);

//
// Grid variant
//
const ApisLandingBodyGrid = (props: BaseLandingBodyProps<ListOverviewApisResponse_ApiSummary>) => {
  const { items: apis, pagingData } = props;
  return (
    <>
      <OverviewGridStyles.Grid data-testid='apis-landing-body'>
        {apis.map(api => (
          <li key={buildIdFromRef(api.routeTableRef) + '-' + api.apiType}>
            <HasDetailsWrapper type={api.apiType}>
              <a
                href={
                  isApiTypeDetailsPageSupported(api.apiType)
                    ? buildApiDetailsUrl({
                        routeTableRef: api.routeTableRef,
                        istioRouteName: api.routeName,
                        apiType: api.apiType
                      })
                    : undefined
                }
                aria-label={`Api Card: ${api.routeName ?? 'Api Unnamed'}`}>
                <OverviewGridStyles.Card
                  title={
                    !api.routeTableRef?.name || !api.routeTableRef?.namespace || !api.routeTableRef?.clusterName
                      ? 'Missing data to visit details page.'
                      : undefined
                  }
                  data-testid={`${api.routeName}-api-card`}>
                  <OverviewGridStyles.CardHeader>
                    {api.routeName ?? 'Api Unnamed'}
                    <HealthIndicator status={api.status} />
                  </OverviewGridStyles.CardHeader>
                  <OverviewGridStyles.CardContent>
                    <SecondaryInfo
                      items={[
                        {
                          label: 'Route Table',
                          data: api.routeTableRef?.name ?? ''
                        },
                        {
                          label: 'Namespace',
                          data: api.routeTableRef?.namespace ?? ''
                        },
                        {
                          label: 'Cluster',
                          data: api.routeTableRef?.clusterName ?? ''
                        }
                      ]}
                      small
                    />
                  </OverviewGridStyles.CardContent>
                  <OverviewGridStyles.CardFooter>
                    <SvgWithTextContainer>
                      <Svg asset={apiTypeMap[api.apiType].icon} size={20} color={thm => thm.oceanBlue} />
                      {apiTypeMap[api.apiType].name}
                    </SvgWithTextContainer>
                    <ApisLandingStyles.CardFooterRight>
                      <ApisLandingApiExplorerButton api={api} />
                    </ApisLandingStyles.CardFooterRight>
                  </OverviewGridStyles.CardFooter>
                </OverviewGridStyles.Card>
              </a>
            </HasDetailsWrapper>
          </li>
        ))}
      </OverviewGridStyles.Grid>
      {!!pagingData.total && <SoloPagination {...pagingData} pageSizeOptions={[3, 6, 12, 24]} />}
    </>
  );
};

//
// Table variant
//
interface TableFields {
  key: string;
  status: Status | undefined;
  routeName: string;
  rtName: string;
  rtNamespace: string;
  rtClusterName: string;
  rtRef: ClusterObjectRef | undefined;
  apiType: ApiType;
  api: ListOverviewApisResponse_ApiSummary;
}
const ApisLandingBodyTable = (props: BaseLandingBodyProps<ListOverviewApisResponse_ApiSummary>) => {
  const { items: apis, pagingData } = props;
  const navigate = useNavigate();
  const columns: SoloColumnsType<TableFields> = [
    createStatusColumn({ dataIndex: 'status' }),
    { title: 'Route Name', dataIndex: 'routeName', render: v => <div data-testid='name-cell'>{v}</div> },
    { title: 'Route Table', dataIndex: 'rtName' },
    { title: 'Namespace', dataIndex: 'rtNamespace' },
    { title: 'Cluster', dataIndex: 'rtClusterName' },
    {
      title: 'Type',
      dataIndex: 'apiType',
      render: apiType => (
        <FlexLayout vertical gap={2}>
          <Svg mt='2px' size={20} color={thm => thm.oceanBlue} asset={apiTypeMap[apiType].icon} />
          <Spacer ml='5px'>{apiTypeMap[apiType].name}</Spacer>
        </FlexLayout>
      )
    },
    {
      title: 'API Explorer',
      dataIndex: 'api',
      render: api => (
        <Spacer pt='20px' ml='20px' position='relative'>
          <ApisLandingApiExplorerButton api={api} />
        </Spacer>
      )
    }
  ];

  const tableData = useMemo<TableFields[]>(
    () =>
      apis.map(a => ({
        key: `${buildIdFromRef(a.routeTableRef)}-${a.apiType}-${a.routeName}`,
        routeName: a.routeName ?? '',
        rtName: a.routeTableRef?.name ?? '',
        rtNamespace: a.routeTableRef?.namespace ?? '',
        rtClusterName: a.routeTableRef?.clusterName ?? '',
        rtRef: a.routeTableRef,
        status: a.status,
        apiType: a.apiType,
        api: a
      })),
    [apis]
  );

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable
        data-testid='apis-landing-body'
        dataSource={tableData}
        columns={columns}
        paging={pagingData}
        onRow={(record, _rowIndex) => ({
          onClick: isApiTypeDetailsPageSupported(record.apiType)
            ? () =>
                navigate(
                  buildApiDetailsUrl({
                    apiType: record.apiType,
                    istioRouteName: record.routeName,
                    routeTableRef: record.rtRef
                  })
                )
            : undefined
        })}
      />
    </Spacer>
  );
};
