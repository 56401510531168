import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from '../Card';

export namespace OverviewGridStyles {
  export const Grid = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;

    > li > a,
    > li > button {
      height: 100%;
    }
  `;

  // If a card is a button/link, it should be wrapped with it; the card handles the styling in this use case
  export const Card = styled(CardStyles.ShadowlessCard)(({ theme }) => {
    return css`
      position: relative;
      height: 100%;
      color: ${theme.neptuneBlue};

      svg {
        color: ${theme.novemberGrey};
      }

      a[href] &:hover,
      button:not([disabled]) &:hover {
        box-shadow: 0px 2px 8px ${theme.boxShadow};

        ${CardHeader} {
          text-decoration: underline;
          color: ${theme.seaBlue};

          svg {
            color: ${theme.seaBlue};
          }
        }
      }
    `;
  });

  export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    > div {
      display: flex;
      align-items: center;
    }
  `;
  export const CardContent = styled.div`
    display: flex;
    align-items: center;
  `;
  export const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;

    button {
      font-size: 16px;
    }
  `;
}
