import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { BasicDestinationsTable } from 'Components/Features/Destinations/BasicDestinationsTable';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetAdaptiveRequestConcurrencyPolicyDetails } = policiesApi;

const policyType = PolicyType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const AdaptiveRequestConcurrencyDetails = ({ clusterObjRef }: Props) => {
  di(useGetAdaptiveRequestConcurrencyPolicyDetails);
  const destPagingData = useSoloPaging();

  const { data: policyData, error: policyError } = useGetAdaptiveRequestConcurrencyPolicyDetails(
    clusterObjRef,
    destPagingData.apiPaginationObject
  );

  destPagingData.useSetPagingTotal(policyData?.destinations?.destinations?.length);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  const reqConcurrencySettings = policyData.requestConcurrencySettings;
  const minRttCalcSettings = policyData.minRttCalcSettings;

  return (
    <>
      <CardStyles.CardList>
        <DetailsHeaderCard
          icon={getPolicyIcon(policyType)}
          status={policyData?.status}
          objRef={clusterObjRef}
          workspaceRef={policyData?.ownerWorkspace?.ref}
          resourceType={getPolicyResourceType(policyType)}
        />
        <CardStyles.Card>
          <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

          <FlexLayout gap={3}>
            <FlexLayout flexGrow={1}>
              <SoloListCard
                title='Request Concurrency Settings'
                dataSource={
                  reqConcurrencySettings
                    ? [
                        {
                          key: 'update-interval',
                          data: 'Update Interval',
                          right: `${reqConcurrencySettings.concurrencyUpdateIntervalMillis}ms`
                        },
                        {
                          key: 'sample-aggregate-percentile',
                          data: 'Sample Aggregate Percentile',
                          right: reqConcurrencySettings.sampleAggregatePercentile?.value
                            ? `${reqConcurrencySettings.sampleAggregatePercentile?.value}%`
                            : ''
                        },
                        {
                          key: 'max-limit',
                          data: 'Maximum Limit',
                          right: reqConcurrencySettings.maxConcurrencyLimit?.value ?? ''
                        },
                        {
                          key: 'limit-exceeded-status',
                          data: 'Limit Exceeded Status',
                          right: reqConcurrencySettings.concurrencyLimitExceededStatus
                        }
                      ]
                    : []
                }
              />
            </FlexLayout>
            <FlexLayout flexGrow={1}>
              <SoloListCard
                title='Minimum Roundtrip Time Calculation Settings'
                dataSource={
                  minRttCalcSettings
                    ? [
                        {
                          key: 'interval',
                          data: 'Interval',
                          right: `${minRttCalcSettings.intervalMillis}ms`
                        },
                        {
                          key: 'req-count',
                          data: 'Request Count',
                          right: minRttCalcSettings.requestCount?.value ?? '50'
                        },
                        {
                          key: 'min-concurrency',
                          data: 'Minimum Concurrency',
                          right: minRttCalcSettings.minConcurrency?.value ?? '3'
                        },
                        {
                          key: 'jitter-percentile',
                          data: 'Jitter Percentile',
                          right: `${minRttCalcSettings.jitterPercentile?.value ?? '15'}%`
                        },
                        {
                          key: 'buffer-percentile',
                          data: 'Buffer Percentile',
                          right: `${minRttCalcSettings.bufferPercentile?.value ?? '25'}%`
                        }
                      ]
                    : []
                }
              />
            </FlexLayout>
          </FlexLayout>
        </CardStyles.Card>
        <CardStyles.Card>
          <CardStyles.CardHeader>
            Applied to Destinations
            <CardStyles.CardHeaderRightIcon>
              <Asset.ServiceIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          <BasicDestinationsTable
            destinations={policyData?.destinations?.destinations}
            paging={destPagingData.pagingData}
          />
        </CardStyles.Card>
      </CardStyles.CardList>
    </>
  );
};
