import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Permission, usePermissions } from 'utils/permissions';
import { GvkType } from 'utils/types';
import { buildResourcesUrl } from 'utils/url-builders';

export const ResourcesRootLanding = () => {
  const navigate = useNavigate();
  const { hasPerm } = usePermissions();

  useEffect(() => {
    let url: string | undefined = undefined;
    if (hasPerm(Permission.SoloResources)) {
      url = buildResourcesUrl(GvkType.GLOO);
    } else if (hasPerm(Permission.IstioEnabled)) {
      url = buildResourcesUrl(GvkType.ISTIO);
    } else if (hasPerm(Permission.CiliumEnabled)) {
      url = buildResourcesUrl(GvkType.CILIUM);
    } else if (hasPerm(Permission.Kubernetes)) {
      url = buildResourcesUrl(GvkType.KUBE_NON_GATEWAY);
    } else if (hasPerm(Permission.GatewayApi)) {
      url = buildResourcesUrl(GvkType.KUBE_GATEWAY_API);
    }
    if (url) {
      navigate(url, { replace: true });
    } else {
      navigate('/');
    }
  }, []);

  return <></>;
};
