import { GetGraphResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';

export function generateDot(graphData: GetGraphResponse | undefined) {
  let dot = 'digraph {\n';

  // define graph options
  dot += '\tfontname="Proxima Nova, Open Sans, Helvetica, Arial, sans-serif"\n';
  dot += '\tcenter="true"\n';
  dot += '\trankdir="LR"\n';
  dot += '\tsplines="false"\n';
  dot += '\tnodesep=2\n';
  dot += '\tranksep="2.5"\n';

  // define default node attributes
  dot += `\tnode [fixedsize=true width=0.6]\n\n`;

  // define "clusters"
  const clusters: {
    [key: string]: {
      label: string;
      namespaces: {
        [key: string]: {
          label: string;
          nodes: { id: string; displayName: string }[];
        };
      };
    };
  } = {};

  graphData?.nodeMetrics.forEach(node => {
    if (!node.workload?.cluster || !node.workload?.namespace) {
      return;
    }

    const { cluster, namespace, id, displayName } = node.workload;

    if (!clusters[cluster]) {
      clusters[cluster] = {
        label: cluster,
        namespaces: {}
      };
    }

    if (clusters[cluster] && !clusters[cluster].namespaces[namespace]) {
      clusters[cluster].namespaces[namespace] = {
        label: namespace,
        nodes: []
      };
    }

    if (clusters[cluster] && clusters[cluster].namespaces[namespace]) {
      clusters[cluster].namespaces[namespace].nodes.push({ id, displayName });
    }
  });

  let clusterCount = 0;
  Object.keys(clusters).forEach(cluster => {
    // define the outermost cluster container
    dot += `\tsubgraph cluster_${clusterCount} {\n`;
    dot += '\t\tclass="cluster"\n';
    dot += '\t\tmargin=50\n';
    dot += `\t\tid="${clusters[cluster].label}"\n`;
    clusterCount += 1;

    Object.keys(clusters[cluster].namespaces).forEach(namespace => {
      // define inner namespace containers
      dot += `\n\t\tsubgraph cluster_${clusterCount} {\n`;
      dot += '\t\tclass="namespace"\n';
      dot += '\t\tmargin=25\n';
      // unique id that also stores namespace label
      dot += `\t\t\tid="${clusters[cluster].namespaces[namespace].label}"\n`;
      clusterCount += 1;

      // define nodes belonging to each namespace
      clusters[cluster].namespaces[namespace].nodes.forEach(node => {
        dot += `\t\t\t"${node.id}" [label="" id="${node.displayName}"]\n`;
      });

      dot += '\t\t}\n';
    });
    dot += '\t}\n';
  });

  // define edges
  const seenEdges: { [key: string]: string[] } = {};
  dot += '\n';
  graphData?.edgeMetrics.forEach(edge => {
    const source = edge.sourceWorkload?.id;
    const target = edge.targetWorkload?.id;
    if (!source || !target) return;

    if (!seenEdges[source]) {
      seenEdges[source] = [];
    }

    if (seenEdges[source].indexOf(target) === -1) {
      seenEdges[source].push(target);
      dot += `\t"${edge.sourceWorkload?.id}"->"${edge.targetWorkload?.id}" [class="healthy"]\n`;
    }
  });

  dot += '}';
  return dot;
}
