import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace RouteTablesDisplayStyles {
  const BasicCard = styled.div(
    ({ theme }) => css`
      padding: 15px;
      border: 1px solid ${theme.splashBlue};
      border-radius: 3px;
      margin-top: 20px;
      &:first-of-type {
        margin-top: 0;
      }
    `
  );

  export const RouteTableContainer = styled(BasicCard)`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `;
  export const RouteTableHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `;
  export const RouteTableTitle = styled.div`
    font-size: 22px;
  `;
  interface RouteTableHeaderArrowProps {
    opened: boolean;
  }
  export const RouteTableHeaderArrow = styled.div<RouteTableHeaderArrowProps>(
    ({ opened, theme }) => css`
      display: flex;
      align-items: center;
      margin-left: auto;
      ${!opened
        ? css`
            transform: rotate(-180deg);
          `
        : ''}

      ${svgBasics({ height: 20 }, theme.seaBlue)}
    `
  );
}
