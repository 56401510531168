import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { getGlooAgentLogsLinks, getGlooManagementPlaneLogsLinks } from 'utils/log-viewer-helpers';
import { DashboardHealthCheckRow } from '../DashboardHealthCheckRow';

export const GlooHealthCheckSectionContent = ({
  glooManagementPlaneInsights,
  glooManagementPlaneStatus,
  glooAgentInsights,
  glooAgentStatus
}: // telemetryStatus
  {
    glooManagementPlaneInsights: Insight[] | undefined;
    glooAgentInsights: Insight[] | undefined;
    // False is passed when there are no insights / status for the thing
    glooManagementPlaneStatus: Status | undefined | 'loading';
    glooAgentStatus: Status | undefined | 'loading';
    // telemetryStatus: Status | undefined | 'loading';
  }) => {
  return (
    <div>
      <GridLayout templateColumns='20px 1fr auto' gap='20px 6px' data-testid='gloo-health-section-content'>
        <DashboardHealthCheckRow
          label='Management Plane'
          status={glooManagementPlaneStatus}
          logsLinks={getGlooManagementPlaneLogsLinks(glooManagementPlaneInsights)}
        />
        <DashboardHealthCheckRow
          label='Agent'
          status={glooAgentStatus}
          logsLinks={getGlooAgentLogsLinks(glooAgentInsights)}
        />
        {/* TODO: update link to actual page */}
        {/* <DashboardHealthCheckRow label='Telemetry' status={telemetryStatus} /> */}
      </GridLayout>
    </div>
  );
};
