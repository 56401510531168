import { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Svg } from 'Components/Common/Svg';
import { AddClusterModalBody } from 'Components/Features/Overview/AddClusterModalBody';
import { Asset } from 'assets';
import { SoloButton, SoloButtonSolid } from 'Styles/CommonEmotions/button';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { docLinks } from 'utils/url-external-links-map';

namespace Styles {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 48px;
    height: 100%;
  `;
  export const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const RegisteringIconHolder = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 133px;
      height: 133px;
      border-radius: 133px;
      border: 1px solid ${theme.splashBlue};
      margin-bottom: 30px;
    `
  );
  export const RegisteringTitle = styled.div`
    font-size: 28px;
    text-align: center;
  `;
  export const RegisteringBody = styled.div`
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding: 25px 0;
  `;
  export const RegisterButtons = styled.div`
    display: flex;
    > * {
      margin-right: 20px;
    }
    > :last-child {
      margin-right: 0;
    }
  `;
}

export const NoValidGraphContent = () => {
  const [addClusterModalOpen, setAddClusterModalOpen] = useState(false);
  return (
    <Styles.Container>
      <Styles.Top>
        <Styles.RegisteringIconHolder>
          <Svg asset={<Asset.ClusterIcon />} size={80} color={thm => thm.splashBlue} />
        </Styles.RegisteringIconHolder>

        <Styles.RegisteringTitle>No Clusters/Namespaces Found</Styles.RegisteringTitle>
        <Styles.RegisteringBody>
          To add workload clusters, create KubernetesCluster resources in your Gloo Platform management cluster. Then,
          you can use Gloo Platform custom resources to manage Istio and control service mesh traffic.
        </Styles.RegisteringBody>
      </Styles.Top>

      <Styles.RegisterButtons>
        <SoloButtonSolid onClick={() => setAddClusterModalOpen(true)}>REGISTER CLUSTER</SoloButtonSolid>
        <ExternalSoloLink href={docLinks.enterprise.meshctl_cluster} newTab>
          <SoloButton>VIEW DOCS</SoloButton>
        </ExternalSoloLink>
      </Styles.RegisterButtons>
      {addClusterModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddClusterModalOpen(false)}>
          <AddClusterModalBody />
        </SoloModal>
      )}
    </Styles.Container>
  );
};
