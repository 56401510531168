import {
  ApiType,
  Pagination,
  State
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetClusterDetailsCardsRequest,
  GetDashboardCardsRequest,
  GetOverviewClusterRequest,
  GetOverviewWorkspaceRequest,
  ListClustersRequest,
  ListClustersRequest_ClusterSortOrder,
  ListEgressSourcesRequest,
  ListIngressGatewaysRequest,
  ListNodesRequest,
  ListOverviewApisRequest,
  ListOverviewClustersRequest,
  ListOverviewClustersRequest_ClusterSortOrder,
  ListOverviewGatewaysRequest,
  ListOverviewPortalsRequest,
  ListOverviewWorkspacesRequest,
  ListOverviewWorkspacesRequest_WorkspaceSortOrder,
  ListServicesRequest,
  ListWorkspacesRequest,
  ListWorkspacesRequest_WorkspaceSortOrder
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { OverviewApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb.client';
import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { Duration } from 'proto/google/protobuf/duration_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import { useApiserverErrorsNotification } from 'utils/notificationsystem';
import { grpcWebFetchTransport, soloGrpcCall, useRequest, useRequestSkipRef } from './helpers';

export namespace overviewApi {
  const client = new OverviewApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.OverviewApi/' + capitalizeFirstLetters(fn.name);

  //
  // Get Workspace
  //
  export async function getWorkspace(workspaceRef?: ObjectRef) {
    const request: GetOverviewWorkspaceRequest = { workspaceName: workspaceRef?.name ?? '' };
    return soloGrpcCall(client.getOverviewWorkspace(request));
  }
  export function useGetWorkspace(...args: Parameters<typeof getWorkspace>) {
    return useRequestSkipRef(getWorkspace, args, getMethodDescriptorName(client.getOverviewWorkspace));
  }

  //
  // List Workspaces
  //
  export async function listOverviewWorkspaces(
    nameFilter = '',
    pagination?: Pagination,
    statusFilter: State[] = [],
    sortOrder?: ListOverviewWorkspacesRequest_WorkspaceSortOrder,
    reverseSortOrder?: boolean
  ) {
    const request: ListOverviewWorkspacesRequest = {
      nameFilter,
      pagination,
      statusFilter,
      sortOrder: sortOrder ?? ListOverviewWorkspacesRequest_WorkspaceSortOrder.NAME,
      reverseSortOrder: !!reverseSortOrder
    };
    return soloGrpcCall(client.listOverviewWorkspaces(request));
  }
  export function useListOverviewWorkspaces(...args: Parameters<typeof listOverviewWorkspaces>) {
    return useRequest(listOverviewWorkspaces, args, {
      methodDescriptorName: getMethodDescriptorName(client.listOverviewWorkspaces)
    });
  }

  //
  // List Workspaces
  //
  export async function listWorkspaces(
    nameFilter = '',
    pagination?: Pagination,
    statusFilter: State[] = [],
    sortOrder?: ListWorkspacesRequest_WorkspaceSortOrder,
    reverseSortOrder?: boolean
  ) {
    const request: ListWorkspacesRequest = {
      nameFilter,
      pagination,
      statusFilter,
      sortOrder: sortOrder ?? ListWorkspacesRequest_WorkspaceSortOrder.NAME,
      reverseSortOrder: !!reverseSortOrder
    };
    return soloGrpcCall(client.listWorkspaces(request));
  }
  export function useListWorkspaces(...args: Parameters<typeof listWorkspaces>) {
    return useRequest(listWorkspaces, args, {
      methodDescriptorName: getMethodDescriptorName(client.listWorkspaces)
    });
  }

  //
  // Get Cluster Details Cards
  //
  export async function getClusterDetailsCards(clusterName?: string) {
    const request: GetClusterDetailsCardsRequest = { clusterName: clusterName ?? '' };
    return soloGrpcCall(client.getClusterDetailsCards(request));
  }
  export function useGetClusterDetailsCards(...args: Parameters<typeof getClusterDetailsCards>) {
    return useRequest(getClusterDetailsCards, args, {
      skip: !args[0], // Skip if clusterName is nullable
      methodDescriptorName: getMethodDescriptorName(client.getClusterDetailsCards)
    });
  }

  //
  // Get Cluster
  //
  export async function getCluster(clusterRef?: ObjectRef) {
    const request: GetOverviewClusterRequest = { clusterRef };
    return soloGrpcCall(client.getOverviewCluster(request));
  }
  export function useGetCluster(...args: Parameters<typeof getCluster>) {
    return useRequestSkipRef(getCluster, args, getMethodDescriptorName(client.getOverviewCluster));
  }

  //
  // List Clusters
  //
  export async function listOverviewClusters(
    nameFilter = '',
    pagination?: Pagination,
    statusFilter: State[] = [],
    sortOrder?: ListOverviewClustersRequest_ClusterSortOrder,
    reverseSortOrder?: boolean
  ) {
    const request: ListOverviewClustersRequest = {
      nameFilter,
      pagination,
      statusFilter,
      sortOrder: sortOrder ?? ListOverviewClustersRequest_ClusterSortOrder.NAME,
      reverseSortOrder: !!reverseSortOrder
    };
    return soloGrpcCall(client.listOverviewClusters(request));
  }
  export function useListOverviewClusters(...args: Parameters<typeof listOverviewClusters>) {
    return useRequest(listOverviewClusters, args, {
      methodDescriptorName: getMethodDescriptorName(client.listOverviewClusters)
    });
  }

  export async function listClusters(
    nameFilter = '',
    pagination?: Pagination,
    statusFilter: State[] = [],
    sortOrder = ListClustersRequest_ClusterSortOrder.NAME,
    reverseSortOrder = false
  ) {
    const request: ListClustersRequest = { nameFilter, pagination, statusFilter, sortOrder, reverseSortOrder };
    return soloGrpcCall(client.listClusters(request));
  }
  export function useListClusters(...args: Parameters<typeof listClusters>) {
    return useRequest(listClusters, args, {
      methodDescriptorName: getMethodDescriptorName(client.listClusters)
    });
  }

  //
  // List Egress Sources
  //
  export async function listEgressSources(nameFilter = '', pagination?: Pagination, windowSeconds?: number) {
    const request: ListEgressSourcesRequest = {
      nameFilter,
      pagination,
      window: windowSeconds === undefined ? undefined : { seconds: windowSeconds, nanos: 0 }
    };
    return soloGrpcCall(client.listEgressSources(request));
  }
  export function useListEgressSources(...args: Parameters<typeof listEgressSources>) {
    return useRequest(listEgressSources, args, {
      methodDescriptorName: getMethodDescriptorName(client.listEgressSources)
    });
  }

  //
  // List Ingress Gateways
  //
  export async function listIngressGateways(nameFilter = '', pagination?: Pagination) {
    const request: ListIngressGatewaysRequest = { nameFilter, pagination };
    return soloGrpcCall(client.listIngressGateways(request));
  }
  export function useListIngressGateways(...args: Parameters<typeof listIngressGateways>) {
    return useRequest(listIngressGateways, args, {
      methodDescriptorName: getMethodDescriptorName(client.listIngressGateways)
    });
  }

  //
  // List Gateways
  //
  export async function listGateways(nameFilter?: string, pagination?: Pagination, statusFilter?: State[]) {
    const request: ListOverviewGatewaysRequest = {
      nameFilter: nameFilter ?? '',
      pagination,
      statusFilter: statusFilter ?? []
    };
    return soloGrpcCall(client.listOverviewGateways(request));
  }
  export function useListGateways(...args: Parameters<typeof listGateways>) {
    return useRequest(listGateways, args, {
      methodDescriptorName: getMethodDescriptorName(client.listOverviewGateways)
    });
  }

  //
  // List APIs
  //
  export async function listApis(
    pagination?: Pagination,
    nameFilter = '',
    typeFilter: ApiType[] = [],
    statusFilter: State[] = []
  ) {
    const request: ListOverviewApisRequest = { pagination, nameFilter, typeFilter, statusFilter };
    return soloGrpcCall(client.listOverviewApis(request));
  }
  export function useListApis(...args: Parameters<typeof listApis>) {
    return useRequest(listApis, args, {
      methodDescriptorName: getMethodDescriptorName(client.listOverviewApis)
    });
  }

  //
  // List Portals
  //
  export async function listPortals(pagination?: Pagination, nameFilter = '', statusFilter: State[] = []) {
    const request: ListOverviewPortalsRequest = { pagination, nameFilter, statusFilter };
    return soloGrpcCall(client.listOverviewPortals(request));
  }
  export function useListPortals(...args: Parameters<typeof listPortals>) {
    return useRequest(listPortals, args, {
      methodDescriptorName: getMethodDescriptorName(client.listOverviewPortals)
    });
  }

  //
  // List Services
  //
  export async function listServices(
    pagination?: Pagination,
    statusFilter: State[] = [],
    textFilter = '',
    typeFilter = '',
    labelKeyFilter = '',
    labelValueFilter = '',
    window?: Duration
  ) {
    const request: ListServicesRequest = {
      pagination,
      statusFilter,
      textFilter,
      typeFilter,
      labelKeyFilter,
      labelValueFilter,
      window
    };
    return soloGrpcCall(client.listServices(request));
  }
  export function useListServices(...args: Parameters<typeof listServices>) {
    return useRequest(listServices, args, {
      methodDescriptorName: getMethodDescriptorName(client.listServices)
    });
  }

  //
  // List Services
  //
  export async function listNodes(pagination?: Pagination, nameFilter = '', statusFilter: State[] = []) {
    const request: ListNodesRequest = { pagination, nameFilter, statusFilter };
    return soloGrpcCall(client.listNodes(request));
  }
  export function useListNodes(...args: Parameters<typeof listNodes>) {
    return useRequest(listNodes, args, {
      methodDescriptorName: getMethodDescriptorName(client.listNodes)
    });
  }

  //
  // Get Overview Cards
  //
  export async function getDashboardCards(windowSeconds?: number) {
    const request: GetDashboardCardsRequest = { window: { seconds: windowSeconds ?? 900, nanos: 0 } };
    return soloGrpcCall(client.getDashboardCards(request));
  }
  export function useGetDashboardCards(...args: Parameters<typeof getDashboardCards>) {
    const swrResponse = useRequest(getDashboardCards, args, {
      methodDescriptorName: getMethodDescriptorName(client.getDashboardCards)
    });
    const { data, error } = swrResponse;
    // If there were apiserver errors, notify the user.
    // also return actual errors to display if there are any
    useApiserverErrorsNotification('dashboard', {
      ...(data ?? { apiserverErrors: error?.message ? [error?.message] : [] })
    });
    return swrResponse;
  }
}
