import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';

export namespace SoloToggleSwitchStyles {
  export const Container = styled.div`
    display: flex;
    align-items: center;
  `;

  type ToggleButtonProps = {
    isDisabled?: boolean;
    isChecked: boolean;
    isDark?: boolean;
  };
  export const ToggleButton = styled(UnstyledButton)<ToggleButtonProps>(
    ({ theme, isDisabled, isChecked, isDark }) => css`
      position: relative;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 21px;
      width: 45px;
      border: 2px solid transparent;
      border-radius: 20px;
      cursor: pointer;
      transition: colors 0.3s;

      background: ${isChecked ? theme.seaBlue : isDark ? theme.juneGrey : theme.marchGrey};
      ${isDisabled &&
      css`
        cursor: default;
        opacity: 0.6;
      `}
    `
  );

  export const ToggleBall = styled.div<ToggleButtonProps>(
    ({ isChecked }) => css`
      pointer-events: none;
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background: white;
      transition: transform 0.3s;

      ${isChecked
        ? css`
            transform: translateX(25px);
          `
        : css`
            transform: translateX(1px);
          `};
    `
  );

  type ToggleLabelProps = {
    labelOnRight?: boolean;
  };
  export const Label = styled.label<ToggleLabelProps>(
    ({ theme, labelOnRight }) => css`
      color: ${theme.novemberGrey};
      ${labelOnRight
        ? css`
            margin-left: 7px;
          `
        : css`
            margin-right: 7px;
          `};
    `
  );
}
