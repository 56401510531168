import { Asset } from 'assets';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { GetDashboardCardsResponse_ZeroTrustOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { ClusterServicesCodesDataTypeMap, ClusterServicesKey } from '../ClusterServices/insight-codes';
import { ZeroTrustCardStyles as Styles } from './ZeroTrustCard.style';

type clusterServicesSummaryDataType = ClusterServicesCodesDataTypeMap[ClusterServicesKey.clusterServicesSummary];

function compactNumber(num: number) {
  return Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(
    num
  );
}

const ZeroTrustCardContent = ({
  zeroTrustOverview,
  timeInterval,
  clusterServicesSummary
}: {
  zeroTrustOverview: GetDashboardCardsResponse_ZeroTrustOverview | undefined;
  timeInterval: number;
  clusterServicesSummary?: clusterServicesSummaryDataType;
}) => {
  const window = timeInterval === 60 ? '1 minute' : `${timeInterval / 60} minutes`;

  // If ambient workloads exist, hide external services card as they can be misleading - https://github.com/solo-io/gloo-mesh-enterprise/issues/13119
  const hideExternalServices = !!clusterServicesSummary && clusterServicesSummary.ambientServices > 0;

  //
  // Render
  //
  if (!zeroTrustOverview) {
    return (
      <FlexLayout horizontal vertical mt={3}>
        <SoloEmptySimple description='No Data' icon={<Asset.ZeroTrustIcon />} iconSize={75} greyscale />
      </FlexLayout>
    );
  }
  return (
    <Styles.Grid single={hideExternalServices}>
      {zeroTrustOverview.totalWorkloads === 0 ? (
        <Styles.InsightBox>
          <Styles.InsightBoxDesc>No mesh traffic was observed in the last {window}</Styles.InsightBoxDesc>
          <Asset.EncryptedTrafficGraphic />
        </Styles.InsightBox>
      ) : (
        <Styles.InsightBox>
          <Styles.InsightBoxCount>
            {compactNumber(zeroTrustOverview.mtlsEncryptedWorkloads)}/{compactNumber(zeroTrustOverview.totalWorkloads)}
          </Styles.InsightBoxCount>
          <Styles.InsightBoxDesc>workloads receiving only mTLS encrypted mesh traffic</Styles.InsightBoxDesc>
          <Asset.EncryptedTrafficGraphic />
        </Styles.InsightBox>
      )}
      {!hideExternalServices && (
        <>
          {zeroTrustOverview.externalServicesAccessed === 0 ? (
            <Styles.InsightBox>
              <Styles.InsightBoxDesc>
                No external services were accessed from the mesh in the last {window}
              </Styles.InsightBoxDesc>
              <Asset.ExternalServicesGraphic />
            </Styles.InsightBox>
          ) : (
            <Styles.InsightBox>
              <Styles.InsightBoxCount>{zeroTrustOverview.externalServicesAccessed}</Styles.InsightBoxCount>
              <Styles.InsightBoxDesc>
                external service{zeroTrustOverview.externalServicesAccessed === 1 ? ' is' : 's are'} being accessed
              </Styles.InsightBoxDesc>
              <Asset.ExternalServicesGraphic />
            </Styles.InsightBox>
          )}
        </>
      )}
    </Styles.Grid>
  );
};

export default ZeroTrustCardContent;
