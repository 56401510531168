import { CardStyles } from 'Components/Common/Card';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { useParams } from 'react-router-dom';
import { makeClusterObjectRef } from 'utils/helpers';
import { getDestinationKindFromId } from 'utils/types';
import { ExternalServiceDetails } from './Details/ExternalServiceDetails';
import { K8ServiceDetails } from './Details/K8ServiceDetails';
import { VirtualDestinationDetails } from './Details/VirtualDestinationDetails';

const detailsMap: Record<DestinationKind, typeof K8ServiceDetails> = {
  [DestinationKind.SERVICE]: K8ServiceDetails,
  [DestinationKind.VIRTUAL_DESTINATION]: VirtualDestinationDetails,
  [DestinationKind.EXTERNAL_SERVICE]: ExternalServiceDetails
};

export function DestinationDetailsLanding() {
  const { destinationType: destinationTypeId, destinationName, destinationNamespace, destinationCluster } = useParams();
  const clusterObjRef = makeClusterObjectRef(destinationName, destinationNamespace, destinationCluster);

  if (!clusterObjRef) {
    return (
      <CardStyles.Card>
        <CardStyles.CardHeader>Unknown Destination Reference</CardStyles.CardHeader>
        Some or all of the provided cluster reference is unknown:
        {[destinationName, destinationNamespace, destinationCluster].map(s => s ?? 'unknown').join('.')}
      </CardStyles.Card>
    );
  }

  const destinationKind = getDestinationKindFromId(destinationTypeId);
  const DetailsComponent = detailsMap[destinationKind!];
  if (DetailsComponent) {
    return <DetailsComponent clusterObjRef={clusterObjRef} />;
  }
  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>Unknown Destination Type</CardStyles.CardHeader>
      Destination type '{destinationTypeId ?? 'unknown'}' is not currently supported by Gloo Platform UI.
    </CardStyles.Card>
  );
}
