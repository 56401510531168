import { Insight_SYS0011Data } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { insightCodeMapToArray } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeMap, InsightCodeWithDataProp } from 'utils/dashboard/dashboard-types';

export enum ClusterServicesKey {
  clusterServicesSummary = 'clusterServicesSummary'
}

/**
 * This type map is how we can typecast,
 * when fetching data using the InsightCodeMap.
 */
export interface ClusterServicesCodesDataTypeMap extends Record<ClusterServicesKey, any> {
  [ClusterServicesKey.clusterServicesSummary]: Insight_SYS0011Data;
}

interface ClusterServicesCodesMap extends InsightCodeMap, Record<ClusterServicesKey, Code> {
  [ClusterServicesKey.clusterServicesSummary]: InsightCodeWithDataProp<'sYS0011'>;
}

/**
 * This maps the group/key values from the insight code
 * to be more readable.
 */
export const clusterServicesCodesMap: ClusterServicesCodesMap = {
  [ClusterServicesKey.clusterServicesSummary]: {
    // Cluster Services Summary
    // Data: &insights_v2alpha1.Insight_SYS0011{
    // 	SYS0011: &insights_v2alpha1.Insight_SYS0011Data{
    // 		InMeshServices:    50,
    // 		OutOfMeshServices: 15,
    // 		SidecarServices:   30,
    // 		AmbientServices:   5,
    // 	},
    // },
    group: 'SYS',
    key: '0011',
    dataProp: 'sYS0011'
  }
};

export const clusterServicesCodesArray = insightCodeMapToArray(clusterServicesCodesMap);
