import { SoloLink } from 'Components/Common/SoloLink';
import { HTTPRoute } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { getPolicyIcon } from 'utils/types';
import { buildWorkspacePolicyDetailsUrl } from 'utils/url-builders';
import { isPolicyTypeDetailsPageSupported } from '../../../Policies/Details/PolicyDetailsLanding';
import { RoutesListDisplayStyles as Styles } from '../RoutesListDisplay.style';
import { BaseRouteItem } from './BaseRouteItem';
import { HttpRequestMatcherGridRow } from './Components/HttpRequestMatcherGridRow';
import { HttpRouteActionSection } from './Components/HttpRouteActionSection';

export const HttpRouteItem = ({
  route,
  routeTableRef,
  parentRef
}: {
  route: HTTPRoute;
  routeTableRef: ClusterObjectRef | undefined;
  parentRef: ClusterObjectRef;
}) => {
  const { name, status, matchers, appliedPolicies, labels } = route;
  di(useNamespaceClusterToWorkspaceMap);
  const { lookupWorkspaceName } = useNamespaceClusterToWorkspaceMap();

  return (
    <BaseRouteItem
      data-testid='http-route-item'
      routeType='HTTP'
      names={[name]}
      status={status}
      labels={labels}
      ifContent={
        <>
          <Styles.RouteContentHeading>Matchers</Styles.RouteContentHeading>
          <Styles.MatchersGrid>
            {matchers.map((matcher, idx) => (
              <HttpRequestMatcherGridRow matcher={matcher} key={[idx, matcher.method, matcher.name].join('-')} />
            ))}
          </Styles.MatchersGrid>
        </>
      }
      thenContentColumns={[
        <HttpRouteActionSection key='1' action={route.action} routeTableRef={routeTableRef} parentRef={parentRef} />,
        <>
          <Styles.RouteContentHeading>Applied Policies</Styles.RouteContentHeading>
          <Styles.RouteHorizontalList>
            {appliedPolicies?.length ? (
              appliedPolicies.map(policy =>
                isPolicyTypeDetailsPageSupported(policy.type) ? (
                  <SoloLink
                    key={buildIdFromRef(policy.ref)}
                    link={buildWorkspacePolicyDetailsUrl(lookupWorkspaceName(policy.ref), policy.type, policy.ref)}>
                    <Styles.IconText>
                      {getPolicyIcon(policy.type)}
                      {policy.ref?.name}
                    </Styles.IconText>
                  </SoloLink>
                ) : (
                  <Styles.IconText key={buildIdFromRef(policy.ref)}>
                    {getPolicyIcon(policy.type)}
                    {policy.ref?.name}
                  </Styles.IconText>
                )
              )
            ) : (
              <Styles.RouteItemsNone>None</Styles.RouteItemsNone>
            )}
          </Styles.RouteHorizontalList>
        </>
      ]}
    />
  );
};
