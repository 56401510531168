import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Asset } from 'assets';
import ReactMarkdown from 'react-markdown';
import { GqlSchemaTabStyles } from './Tabs/GqlSchemaTabStyles.style';

export const GqlDescriptionInfoTooltip = ({
  description,
  placement
}: {
  description: string;
  placement?: TooltipPlacement;
}) => {
  return (
    <GqlSchemaTabStyles.InfoTooltipContainer hidden={!description}>
      <Tooltip
        placement={placement}
        title={
          <Spacer px={5} py={2}>
            {/* eslint-disable-next-line */}
            <ReactMarkdown children={description} />
          </Spacer>
        }
        trigger='hover'>
        <GqlSchemaTabStyles.InfoIconContainer>
          <Asset.InfoIcon />
        </GqlSchemaTabStyles.InfoIconContainer>
      </Tooltip>
    </GqlSchemaTabStyles.InfoTooltipContainer>
  );
};
