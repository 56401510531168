// Code generated by skv2. DO NOT EDIT.


import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';


export interface GroupKind {
  group: string;
  kind: string;
}

export function groupKindtoString(groupKind: GroupKind): string {
  return `${groupKind.group}-${groupKind.kind}`;
}

export const resourceTypeGroupKindMap: Record<ResourceType, GroupKind> = {
  [ResourceType.UNKNOWN_RESOURCE_TYPE]: {
    group: "",
    kind: "",
  },
  [ResourceType.WORKSPACE]: {
    group: "admin.gloo.solo.io",
    kind: "Workspace",
  },
  [ResourceType.WORKSPACE_SETTINGS]: {
    group: "admin.gloo.solo.io",
    kind: "WorkspaceSettings",
  },
  [ResourceType.KUBERNETES_CLUSTER]: {
    group: "admin.gloo.solo.io",
    kind: "KubernetesCluster",
  },
  [ResourceType.ROOT_TRUST_POLICY]: {
    group: "admin.gloo.solo.io",
    kind: "RootTrustPolicy",
  },
  [ResourceType.EXT_AUTH_SERVER]: {
    group: "admin.gloo.solo.io",
    kind: "ExtAuthServer",
  },
  [ResourceType.RATE_LIMIT_SERVER_SETTINGS]: {
    group: "admin.gloo.solo.io",
    kind: "RateLimitServerSettings",
  },
  [ResourceType.RATE_LIMIT_SERVER_CONFIG]: {
    group: "admin.gloo.solo.io",
    kind: "RateLimitServerConfig",
  },
  [ResourceType.DASHBOARD]: {
    group: "admin.gloo.solo.io",
    kind: "Dashboard",
  },
  [ResourceType.ISTIO_LIFECYCLE_MANAGER]: {
    group: "admin.gloo.solo.io",
    kind: "IstioLifecycleManager",
  },
  [ResourceType.GATEWAY_LIFECYCLE_MANAGER]: {
    group: "admin.gloo.solo.io",
    kind: "GatewayLifecycleManager",
  },
  [ResourceType.CLOUD_PROVIDER]: {
    group: "infrastructure.gloo.solo.io",
    kind: "CloudProvider",
  },
  [ResourceType.CLOUD_RESOURCES]: {
    group: "infrastructure.gloo.solo.io",
    kind: "CloudResources",
  },
  [ResourceType.WAYPOINT_LIFECYCLE_MANAGER]: {
    group: "admin.gloo.solo.io",
    kind: "WaypointLifecycleManager",
  },
  [ResourceType.INSIGHTS_CONFIG]: {
    group: "admin.gloo.solo.io",
    kind: "InsightsConfig",
  },
  [ResourceType.EXTERNAL_SERVICE]: {
    group: "networking.gloo.solo.io",
    kind: "ExternalService",
  },
  [ResourceType.EXTERNAL_ENDPOINT]: {
    group: "networking.gloo.solo.io",
    kind: "ExternalEndpoint",
  },
  [ResourceType.ROUTE_TABLE]: {
    group: "networking.gloo.solo.io",
    kind: "RouteTable",
  },
  [ResourceType.VIRTUAL_DESTINATION]: {
    group: "networking.gloo.solo.io",
    kind: "VirtualDestination",
  },
  [ResourceType.VIRTUAL_GATEWAY]: {
    group: "networking.gloo.solo.io",
    kind: "VirtualGateway",
  },
  [ResourceType.EXTERNAL_WORKLOAD]: {
    group: "networking.gloo.solo.io",
    kind: "ExternalWorkload",
  },
  [ResourceType.WASM_DEPLOYMENT_POLICY]: {
    group: "extensions.policy.gloo.solo.io",
    kind: "WasmDeploymentPolicy",
  },
  [ResourceType.ACCESS_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "AccessPolicy",
  },
  [ResourceType.CORS_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "CORSPolicy",
  },
  [ResourceType.CSRF_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "CSRFPolicy",
  },
  [ResourceType.EXT_AUTH_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "ExtAuthPolicy",
  },
  [ResourceType.WAF_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "WAFPolicy",
  },
  [ResourceType.JWT_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "JWTPolicy",
  },
  [ResourceType.CLIENT_TLS_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "ClientTLSPolicy",
  },
  [ResourceType.GRAPH_QL_ALLOWED_QUERY_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "GraphQLAllowedQueryPolicy",
  },
  [ResourceType.DLP_POLICY]: {
    group: "security.policy.gloo.solo.io",
    kind: "DLPPolicy",
  },
  [ResourceType.ACCESS_LOG_POLICY]: {
    group: "observability.policy.gloo.solo.io",
    kind: "AccessLogPolicy",
  },
  [ResourceType.GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "GraphQLPersistedQueryCachePolicy",
  },
  [ResourceType.FAILOVER_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "FailoverPolicy",
  },
  [ResourceType.OUTLIER_DETECTION_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "OutlierDetectionPolicy",
  },
  [ResourceType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "AdaptiveRequestConcurrencyPolicy",
  },
  [ResourceType.FAULT_INJECTION_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "FaultInjectionPolicy",
  },
  [ResourceType.RETRY_TIMEOUT_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "RetryTimeoutPolicy",
  },
  [ResourceType.CONNECTION_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "ConnectionPolicy",
  },
  [ResourceType.TRIM_PROXY_CONFIG_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "TrimProxyConfigPolicy",
  },
  [ResourceType.ACTIVE_HEALTH_CHECK_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "ActiveHealthCheckPolicy",
  },
  [ResourceType.LISTENER_CONNECTION_POLICY]: {
    group: "resilience.policy.gloo.solo.io",
    kind: "ListenerConnectionPolicy",
  },
  [ResourceType.MIRROR_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "MirrorPolicy",
  },
  [ResourceType.RATE_LIMIT_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "RateLimitPolicy",
  },
  [ResourceType.RATE_LIMIT_CLIENT_CONFIG]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "RateLimitClientConfig",
  },
  [ResourceType.HEADER_MANIPULATION_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "HeaderManipulationPolicy",
  },
  [ResourceType.TRANSFORMATION_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "TransformationPolicy",
  },
  [ResourceType.LOAD_BALANCER_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "LoadBalancerPolicy",
  },
  [ResourceType.PROXY_PROTOCOL_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "ProxyProtocolPolicy",
  },
  [ResourceType.HTTP_BUFFER_POLICY]: {
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "HTTPBufferPolicy",
  },
  [ResourceType.GRAPH_QL_STITCHED_SCHEMA]: {
    group: "apimanagement.gloo.solo.io",
    kind: "GraphQLStitchedSchema",
  },
  [ResourceType.GRAPH_QL_RESOLVER_MAP]: {
    group: "apimanagement.gloo.solo.io",
    kind: "GraphQLResolverMap",
  },
  [ResourceType.GRAPH_QL_SCHEMA]: {
    group: "apimanagement.gloo.solo.io",
    kind: "GraphQLSchema",
  },
  [ResourceType.API_DOC]: {
    group: "apimanagement.gloo.solo.io",
    kind: "ApiDoc",
  },
  [ResourceType.PORTAL]: {
    group: "apimanagement.gloo.solo.io",
    kind: "Portal",
  },
  [ResourceType.API_PRODUCT]: {
    group: "apimanagement.gloo.solo.io",
    kind: "ApiProduct",
  },
  [ResourceType.PORTAL_GROUP]: {
    group: "apimanagement.gloo.solo.io",
    kind: "PortalGroup",
  },
  [ResourceType.API_SCHEMA_DISCOVERY]: {
    group: "apimanagement.gloo.solo.io",
    kind: "ApiSchemaDiscovery",
  },
  [ResourceType.DISCOVERED_GATEWAY]: {
    group: "internal.gloo.solo.io",
    kind: "DiscoveredGateway",
  },
  [ResourceType.MESH]: {
    group: "internal.gloo.solo.io",
    kind: "Mesh",
  },
  [ResourceType.DISCOVERED_CNI]: {
    group: "internal.gloo.solo.io",
    kind: "DiscoveredCNI",
  },
  [ResourceType.PORTAL_CONFIG]: {
    group: "internal.gloo.solo.io",
    kind: "PortalConfig",
  },
  [ResourceType.SPIRE_REGISTRATION_ENTRY]: {
    group: "internal.gloo.solo.io",
    kind: "SpireRegistrationEntry",
  },
  [ResourceType.AUTH_CONFIG]: {
    group: "extauth.solo.io",
    kind: "AuthConfig",
  },
  [ResourceType.CILIUM_NETWORK_POLICY]: {
    group: "cilium.io",
    kind: "CiliumNetworkPolicy",
  },
  [ResourceType.DESTINATION_RULE]: {
    group: "networking.istio.io",
    kind: "DestinationRule",
  },
  [ResourceType.GATEWAY]: {
    group: "networking.istio.io",
    kind: "Gateway",
  },
  [ResourceType.SERVICE_ENTRY]: {
    group: "networking.istio.io",
    kind: "ServiceEntry",
  },
  [ResourceType.WORKLOAD_ENTRY]: {
    group: "networking.istio.io",
    kind: "WorkloadEntry",
  },
  [ResourceType.VIRTUAL_SERVICE]: {
    group: "networking.istio.io",
    kind: "VirtualService",
  },
  [ResourceType.SIDECAR]: {
    group: "networking.istio.io",
    kind: "Sidecar",
  },
  [ResourceType.ENVOY_FILTER]: {
    group: "networking.istio.io",
    kind: "EnvoyFilter",
  },
  [ResourceType.AUTHORIZATION_POLICY]: {
    group: "security.istio.io",
    kind: "AuthorizationPolicy",
  },
  [ResourceType.PEER_AUTHENTICATION]: {
    group: "security.istio.io",
    kind: "PeerAuthentication",
  },
  [ResourceType.ISTIO_OPERATOR]: {
    group: "install.istio.io",
    kind: "IstioOperator",
  },
  [ResourceType.TELEMETRY]: {
    group: "telemetry.istio.io",
    kind: "Telemetry",
  },
  [ResourceType.SERVICE_ACCOUNT]: {
    group: "",
    kind: "ServiceAccount",
  },
  [ResourceType.SERVICE]: {
    group: "",
    kind: "Service",
  },
  [ResourceType.NAMESPACE]: {
    group: "",
    kind: "Namespace",
  },
  [ResourceType.NODE]: {
    group: "",
    kind: "Node",
  },
  [ResourceType.DEPLOYMENT]: {
    group: "apps",
    kind: "Deployment",
  },
  [ResourceType.DAEMON_SET]: {
    group: "apps",
    kind: "DaemonSet",
  },
  [ResourceType.STATEFUL_SET]: {
    group: "apps",
    kind: "StatefulSet",
  },
  [ResourceType.ROLE]: {
    group: "rbac.authorization.k8s.io",
    kind: "Role",
  },
  [ResourceType.ROLE_BINDING]: {
    group: "rbac.authorization.k8s.io",
    kind: "RoleBinding",
  },
  [ResourceType.CLUSTER_ROLE]: {
    group: "rbac.authorization.k8s.io",
    kind: "ClusterRole",
  },
  [ResourceType.CLUSTER_ROLE_BINDING]: {
    group: "rbac.authorization.k8s.io",
    kind: "ClusterRoleBinding",
  },
  [ResourceType.NETWORK_POLICY]: {
    group: "networking.k8s.io",
    kind: "NetworkPolicy",
  },
  [ResourceType.CUSTOM_RESOURCE_DEFINITION]: {
    group: "apiextensions.k8s.io",
    kind: "CustomResourceDefinition",
  },
  [ResourceType.K8S_GATEWAY]: {
    group: "gateway.networking.k8s.io",
    kind: "Gateway",
  },
  [ResourceType.GATEWAY_CLASS]: {
    group: "gateway.networking.k8s.io",
    kind: "GatewayClass",
  },
  [ResourceType.HTTP_ROUTE]: {
    group: "gateway.networking.k8s.io",
    kind: "HTTPRoute",
  },
  [ResourceType.RATE_LIMIT_CONFIG]: {
    group: "ratelimit.solo.io",
    kind: "RateLimitConfig",
  },
}

export const groupKindResourceTypeMap: Record<string, ResourceType> = {
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "Workspace"
    })]: ResourceType.WORKSPACE,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "WorkspaceSettings"
    })]: ResourceType.WORKSPACE_SETTINGS,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "KubernetesCluster"
    })]: ResourceType.KUBERNETES_CLUSTER,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "RootTrustPolicy"
    })]: ResourceType.ROOT_TRUST_POLICY,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "ExtAuthServer"
    })]: ResourceType.EXT_AUTH_SERVER,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "RateLimitServerSettings"
    })]: ResourceType.RATE_LIMIT_SERVER_SETTINGS,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "RateLimitServerConfig"
    })]: ResourceType.RATE_LIMIT_SERVER_CONFIG,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "Dashboard"
    })]: ResourceType.DASHBOARD,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "IstioLifecycleManager"
    })]: ResourceType.ISTIO_LIFECYCLE_MANAGER,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "GatewayLifecycleManager"
    })]: ResourceType.GATEWAY_LIFECYCLE_MANAGER,
  [groupKindtoString({
    group: "infrastructure.gloo.solo.io",
    kind: "CloudProvider"
    })]: ResourceType.CLOUD_PROVIDER,
  [groupKindtoString({
    group: "infrastructure.gloo.solo.io",
    kind: "CloudResources"
    })]: ResourceType.CLOUD_RESOURCES,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "WaypointLifecycleManager"
    })]: ResourceType.WAYPOINT_LIFECYCLE_MANAGER,
  [groupKindtoString({
    group: "admin.gloo.solo.io",
    kind: "InsightsConfig"
    })]: ResourceType.INSIGHTS_CONFIG,
  [groupKindtoString({
    group: "networking.gloo.solo.io",
    kind: "ExternalService"
    })]: ResourceType.EXTERNAL_SERVICE,
  [groupKindtoString({
    group: "networking.gloo.solo.io",
    kind: "ExternalEndpoint"
    })]: ResourceType.EXTERNAL_ENDPOINT,
  [groupKindtoString({
    group: "networking.gloo.solo.io",
    kind: "RouteTable"
    })]: ResourceType.ROUTE_TABLE,
  [groupKindtoString({
    group: "networking.gloo.solo.io",
    kind: "VirtualDestination"
    })]: ResourceType.VIRTUAL_DESTINATION,
  [groupKindtoString({
    group: "networking.gloo.solo.io",
    kind: "VirtualGateway"
    })]: ResourceType.VIRTUAL_GATEWAY,
  [groupKindtoString({
    group: "networking.gloo.solo.io",
    kind: "ExternalWorkload"
    })]: ResourceType.EXTERNAL_WORKLOAD,
  [groupKindtoString({
    group: "extensions.policy.gloo.solo.io",
    kind: "WasmDeploymentPolicy"
    })]: ResourceType.WASM_DEPLOYMENT_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "AccessPolicy"
    })]: ResourceType.ACCESS_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "CORSPolicy"
    })]: ResourceType.CORS_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "CSRFPolicy"
    })]: ResourceType.CSRF_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "ExtAuthPolicy"
    })]: ResourceType.EXT_AUTH_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "WAFPolicy"
    })]: ResourceType.WAF_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "JWTPolicy"
    })]: ResourceType.JWT_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "ClientTLSPolicy"
    })]: ResourceType.CLIENT_TLS_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "GraphQLAllowedQueryPolicy"
    })]: ResourceType.GRAPH_QL_ALLOWED_QUERY_POLICY,
  [groupKindtoString({
    group: "security.policy.gloo.solo.io",
    kind: "DLPPolicy"
    })]: ResourceType.DLP_POLICY,
  [groupKindtoString({
    group: "observability.policy.gloo.solo.io",
    kind: "AccessLogPolicy"
    })]: ResourceType.ACCESS_LOG_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "GraphQLPersistedQueryCachePolicy"
    })]: ResourceType.GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "FailoverPolicy"
    })]: ResourceType.FAILOVER_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "OutlierDetectionPolicy"
    })]: ResourceType.OUTLIER_DETECTION_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "AdaptiveRequestConcurrencyPolicy"
    })]: ResourceType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "FaultInjectionPolicy"
    })]: ResourceType.FAULT_INJECTION_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "RetryTimeoutPolicy"
    })]: ResourceType.RETRY_TIMEOUT_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "ConnectionPolicy"
    })]: ResourceType.CONNECTION_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "TrimProxyConfigPolicy"
    })]: ResourceType.TRIM_PROXY_CONFIG_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "ActiveHealthCheckPolicy"
    })]: ResourceType.ACTIVE_HEALTH_CHECK_POLICY,
  [groupKindtoString({
    group: "resilience.policy.gloo.solo.io",
    kind: "ListenerConnectionPolicy"
    })]: ResourceType.LISTENER_CONNECTION_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "MirrorPolicy"
    })]: ResourceType.MIRROR_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "RateLimitPolicy"
    })]: ResourceType.RATE_LIMIT_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "RateLimitClientConfig"
    })]: ResourceType.RATE_LIMIT_CLIENT_CONFIG,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "HeaderManipulationPolicy"
    })]: ResourceType.HEADER_MANIPULATION_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "TransformationPolicy"
    })]: ResourceType.TRANSFORMATION_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "LoadBalancerPolicy"
    })]: ResourceType.LOAD_BALANCER_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "ProxyProtocolPolicy"
    })]: ResourceType.PROXY_PROTOCOL_POLICY,
  [groupKindtoString({
    group: "trafficcontrol.policy.gloo.solo.io",
    kind: "HTTPBufferPolicy"
    })]: ResourceType.HTTP_BUFFER_POLICY,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "GraphQLStitchedSchema"
    })]: ResourceType.GRAPH_QL_STITCHED_SCHEMA,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "GraphQLResolverMap"
    })]: ResourceType.GRAPH_QL_RESOLVER_MAP,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "GraphQLSchema"
    })]: ResourceType.GRAPH_QL_SCHEMA,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "ApiDoc"
    })]: ResourceType.API_DOC,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "Portal"
    })]: ResourceType.PORTAL,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "ApiProduct"
    })]: ResourceType.API_PRODUCT,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "PortalGroup"
    })]: ResourceType.PORTAL_GROUP,
  [groupKindtoString({
    group: "apimanagement.gloo.solo.io",
    kind: "ApiSchemaDiscovery"
    })]: ResourceType.API_SCHEMA_DISCOVERY,
  [groupKindtoString({
    group: "internal.gloo.solo.io",
    kind: "DiscoveredGateway"
    })]: ResourceType.DISCOVERED_GATEWAY,
  [groupKindtoString({
    group: "internal.gloo.solo.io",
    kind: "Mesh"
    })]: ResourceType.MESH,
  [groupKindtoString({
    group: "internal.gloo.solo.io",
    kind: "DiscoveredCNI"
    })]: ResourceType.DISCOVERED_CNI,
  [groupKindtoString({
    group: "internal.gloo.solo.io",
    kind: "PortalConfig"
    })]: ResourceType.PORTAL_CONFIG,
  [groupKindtoString({
    group: "internal.gloo.solo.io",
    kind: "SpireRegistrationEntry"
    })]: ResourceType.SPIRE_REGISTRATION_ENTRY,
  [groupKindtoString({
    group: "extauth.solo.io",
    kind: "AuthConfig"
    })]: ResourceType.AUTH_CONFIG,
  [groupKindtoString({
    group: "cilium.io",
    kind: "CiliumNetworkPolicy"
    })]: ResourceType.CILIUM_NETWORK_POLICY,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "DestinationRule"
    })]: ResourceType.DESTINATION_RULE,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "Gateway"
    })]: ResourceType.GATEWAY,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "ServiceEntry"
    })]: ResourceType.SERVICE_ENTRY,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "WorkloadEntry"
    })]: ResourceType.WORKLOAD_ENTRY,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "VirtualService"
    })]: ResourceType.VIRTUAL_SERVICE,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "Sidecar"
    })]: ResourceType.SIDECAR,
  [groupKindtoString({
    group: "networking.istio.io",
    kind: "EnvoyFilter"
    })]: ResourceType.ENVOY_FILTER,
  [groupKindtoString({
    group: "security.istio.io",
    kind: "AuthorizationPolicy"
    })]: ResourceType.AUTHORIZATION_POLICY,
  [groupKindtoString({
    group: "security.istio.io",
    kind: "PeerAuthentication"
    })]: ResourceType.PEER_AUTHENTICATION,
  [groupKindtoString({
    group: "install.istio.io",
    kind: "IstioOperator"
    })]: ResourceType.ISTIO_OPERATOR,
  [groupKindtoString({
    group: "telemetry.istio.io",
    kind: "Telemetry"
    })]: ResourceType.TELEMETRY,
  [groupKindtoString({
    group: "",
    kind: "ServiceAccount"
    })]: ResourceType.SERVICE_ACCOUNT,
  [groupKindtoString({
    group: "",
    kind: "Service"
    })]: ResourceType.SERVICE,
  [groupKindtoString({
    group: "",
    kind: "Namespace"
    })]: ResourceType.NAMESPACE,
  [groupKindtoString({
    group: "",
    kind: "Node"
    })]: ResourceType.NODE,
  [groupKindtoString({
    group: "apps",
    kind: "Deployment"
    })]: ResourceType.DEPLOYMENT,
  [groupKindtoString({
    group: "apps",
    kind: "DaemonSet"
    })]: ResourceType.DAEMON_SET,
  [groupKindtoString({
    group: "apps",
    kind: "StatefulSet"
    })]: ResourceType.STATEFUL_SET,
  [groupKindtoString({
    group: "rbac.authorization.k8s.io",
    kind: "Role"
    })]: ResourceType.ROLE,
  [groupKindtoString({
    group: "rbac.authorization.k8s.io",
    kind: "RoleBinding"
    })]: ResourceType.ROLE_BINDING,
  [groupKindtoString({
    group: "rbac.authorization.k8s.io",
    kind: "ClusterRole"
    })]: ResourceType.CLUSTER_ROLE,
  [groupKindtoString({
    group: "rbac.authorization.k8s.io",
    kind: "ClusterRoleBinding"
    })]: ResourceType.CLUSTER_ROLE_BINDING,
  [groupKindtoString({
    group: "networking.k8s.io",
    kind: "NetworkPolicy"
    })]: ResourceType.NETWORK_POLICY,
  [groupKindtoString({
    group: "apiextensions.k8s.io",
    kind: "CustomResourceDefinition"
    })]: ResourceType.CUSTOM_RESOURCE_DEFINITION,
  [groupKindtoString({
    group: "gateway.networking.k8s.io",
    kind: "Gateway"
    })]: ResourceType.K8S_GATEWAY,
  [groupKindtoString({
    group: "gateway.networking.k8s.io",
    kind: "GatewayClass"
    })]: ResourceType.GATEWAY_CLASS,
  [groupKindtoString({
    group: "gateway.networking.k8s.io",
    kind: "HTTPRoute"
    })]: ResourceType.HTTP_ROUTE,
  [groupKindtoString({
    group: "ratelimit.solo.io",
    kind: "RateLimitConfig"
    })]: ResourceType.RATE_LIMIT_CONFIG,
}
