import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { Centered } from 'Styles/CommonEmotions/centered';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { FieldDefinitionNode, InputValueDefinitionNode } from 'graphql';
import { Resolution_Resolvers } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext, useMemo, useState } from 'react';
import { GqlSchemaTabItemProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import GqlResolverModal from '../../../Modals/GqlResolverModal';
import { GqlLandingContext } from '../../../context/GqlLandingContext';
import FieldTypeValue from '../FieldTypeValue';
import NameAndReturnType from '../NameAndReturnType';
import GqlResolverButton from '../buttons/GqlResolverButton';
import { GqlSchemaTabStyles } from './GqlSchemaTabStyles.style';
import GqlTabItemBreadcrumb from './GqlTabItemBreadcrumb';
import GqlTabItemDescription from './GqlTabItemDescription';
import GqlTabItemDirectivesList from './GqlTabItemDirectivesList';

type TableFields = InputValueDefinitionNode & {
  key: string;
  descriptionMarkdown: string;
};

/**
 * The arguments table is hidden if no fields have arguments.
 * If `showArgumentsTableIfNoArgs===true`, the arguments column
 * will be shown regardless, even if arguments exist.
 */
const GqlSchemaFieldDefinitionItem = ({
  hideResolversAlways = false,
  objectName,
  onTypeClick,
  focusedItem,
  focusedPath,
  tabHeader
}: GqlSchemaTabItemProps<FieldDefinitionNode> & {
  hideResolversAlways?: boolean;
  objectName?: string;
}) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { apiTypeCanHaveResolvers } = gqlCtx;

  // Show the resolvers if the API can have resolvers and the hideResolversProp is falsy.
  const showResolvers = apiTypeCanHaveResolvers && !hideResolversAlways;

  // Selected Resolver State
  const [selectedResolver, setSelectedResolver] = useState<Resolution_Resolvers | null>(null);

  const columns: SoloColumnsType<TableFields> = useMemo(
    () => [
      {
        title: 'Argument',
        dataIndex: 'name',
        render: (_, argument) => (
          <Spacer py={2}>
            <NameAndReturnType node={argument} onTypeClick={t => onTypeClick(t, true)} />
          </Spacer>
        )
      },
      {
        title: 'Description',
        dataIndex: 'descriptionMarkdown',
        render: renderReactMarkdown
      }
    ],
    [onTypeClick]
  );
  const tableData: readonly TableFields[] = useMemo(
    () =>
      focusedItem.arguments?.map(a => ({
        ...a,
        key: a.name.value,
        descriptionMarkdown: getDescriptionColumnValue(a)
      })) ?? [],
    [focusedItem.arguments]
  );

  //
  // Render
  //
  const showArgsTable = !!focusedItem.arguments?.length;
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectivesList node={focusedItem} onTypeClick={onTypeClick} />
      <GqlTabItemDescription node={focusedItem} />

      <Spacer mb={showArgsTable ? 0 : -4} display='block'>
        {showResolvers && (
          <Spacer pb={showArgsTable ? 5 : 3} pr={3} display='inline-block'>
            <Centered vertical>
              <GqlSchemaTabStyles.TabDetailsInlineHeader>Resolver:</GqlSchemaTabStyles.TabDetailsInlineHeader>
              <GqlResolverButton
                objectName={objectName ?? ''}
                fieldNode={focusedItem}
                onResolverSelected={setSelectedResolver}
              />
              <GqlResolverModal
                objectName={objectName ?? ''}
                fieldName={focusedItem.name.value}
                resolver={selectedResolver}
                onClose={() => setSelectedResolver(null)}
              />
            </Centered>
          </Spacer>
        )}
        <Spacer pb={showArgsTable ? 5 : 3} display='inline-block'>
          <GqlSchemaTabStyles.TabDetailsInlineHeader>Return Type:</GqlSchemaTabStyles.TabDetailsInlineHeader>
          <FieldTypeValue onTypeClick={onTypeClick} field={focusedItem.type} />
        </Spacer>
      </Spacer>

      {showArgsTable && (
        <SoloTable
          removeHorizontalPadding
          columns={columns}
          dataSource={tableData}
          data-testid='gql-field-arguments-table'
        />
      )}
    </>
  );
};

export default GqlSchemaFieldDefinitionItem;
