import { CardStyles } from 'Components/Common/Card';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloLink, SoloLinkStylesOnly } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { SoloModalStyles } from 'Components/Common/SoloModal.style';
import { SoloTable } from 'Components/Common/SoloTable';
import { SoloTableStyles } from 'Components/Common/SoloTable.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { StitchedGraphqlIcon } from 'assets/assets';
import { parse, print } from 'graphql';
import { StitchedSchema_Subschema } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext, useMemo, useState } from 'react';
import { getStitchedSchema } from 'utils/graphql-helpers';
import { graphqlApiTypeMap } from 'utils/types';
import { buildApiDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlDetailsStyles, GqlDetailsStyles as Styles } from './GqlDetails.style';
import { GqlDescriptionInfoTooltip } from './Schema/GqlDescriptionInfoTooltip';
import { StyledSdlModalContent } from './Schema/GqlShowSdlButton';

const GqlSubgraphsCard = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api } = gqlCtx;
  const subschemas = getStitchedSchema(api)?.subschemas ?? [];

  //
  // Subgraph SDL Modal Content
  //
  const [subschemaForModal, setSubschemaForModal] = useState<StitchedSchema_Subschema>();
  const formattedSdlForModal = useMemo(() => {
    if (!subschemaForModal?.schemaDefinition) {
      return '';
    }
    try {
      return print(parse(subschemaForModal.schemaDefinition));
    } catch {
      return subschemaForModal.schemaDefinition;
    }
  }, [subschemaForModal]);

  //
  // Render
  //
  return (
    <>
      <CardStyles.Card data-testid='gql-subgraphs-card'>
        <Styles.StyledCardHeader>
          <Spacer mr={5}>Subgraphs</Spacer>
          <CardStyles.CardHeaderRightIcon>
            <StitchedGraphqlIcon />
          </CardStyles.CardHeaderRightIcon>
        </Styles.StyledCardHeader>

        <SoloTable
          removeHorizontalPadding
          columns={[
            {
              title: 'Name',
              dataIndex: 'nameAndLink',
              render: value =>
                !!value.link ? (
                  <SoloLink inline link={value.link}>
                    {value.name}
                  </SoloLink>
                ) : (
                  <GqlDetailsStyles.StyledSubDetailValue>
                    {value.name}
                    &nbsp;
                    <GqlDescriptionInfoTooltip
                      placement='right'
                      description={`\`${value.name}\` is not exposed on a route in a route table.`}
                    />
                  </GqlDetailsStyles.StyledSubDetailValue>
                )
            },
            {
              title: 'Namespace',
              dataIndex: 'namespace',
              render: value => <GqlDetailsStyles.StyledSubDetailValue>{value}</GqlDetailsStyles.StyledSubDetailValue>
            },
            {
              title: 'Cluster',
              dataIndex: 'clusterName',
              render: value => <GqlDetailsStyles.StyledSubDetailValue>{value}</GqlDetailsStyles.StyledSubDetailValue>
            },
            {
              title: 'Type',
              dataIndex: 'type',
              render: value => (
                <SoloTableStyles.CellIconText>
                  {graphqlApiTypeMap[value].icon}
                  {graphqlApiTypeMap[value].name}
                </SoloTableStyles.CellIconText>
              )
            },
            {
              title: 'View SDL',
              dataIndex: 'subschema',
              render: value => (
                <SoloLinkStylesOnly onClick={() => setSubschemaForModal(value)} withArrow>
                  VIEW SDL
                </SoloLinkStylesOnly>
              )
            }
          ]}
          dataSource={subschemas.map((s, idx) => ({
            key: idx,
            nameAndLink: {
              name: s.graphqlSchemaRef?.name ?? s.graphqlStitchedSchemaRef?.name ?? '',
              link: !!s.istioRouteName ? buildApiDetailsUrl(s) : undefined
            },
            namespace: s.graphqlSchemaRef?.namespace ?? s.graphqlStitchedSchemaRef?.namespace ?? '',
            clusterName: s.graphqlSchemaRef?.clusterName ?? s.graphqlStitchedSchemaRef?.clusterName ?? '',
            subschema: s,
            type: s.type
          }))}
          data-testid='gql-subgraphs-table'
          renderEmpty={
            <SoloEmptyWithDocs
              icon={<Asset.SettingsGear />}
              title='There are no settings to display'
              description=''
              href={docLinks.gateway.graphql}
            />
          }
        />
      </CardStyles.Card>

      <SoloModal width={1000} visible={!!subschemaForModal} onClose={() => setSubschemaForModal(undefined)}>
        <SoloModalStyles.SimpleTitle>Schema</SoloModalStyles.SimpleTitle>
        <StyledSdlModalContent>
          <FancyCodeDisplayer contentString={formattedSdlForModal} type={'graphql'} />
        </StyledSdlModalContent>
      </SoloModal>
    </>
  );
};

export default GqlSubgraphsCard;
