import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { GetUIFeatureFlagsResponse_License_LicenseState } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb';

const menuHeight = 55;

export namespace TopBarStyles {
  export const Container = styled.div(
    ({ theme }) => css`
      grid-area: topbar;

      line-height: 36px;
      background: ${theme.marchGrey};
      border-bottom: 1px solid ${theme.aprilGrey};
      color: black;
      padding: 0 20px;
    `
  );
  export const InnerContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  `;

  export const Flexor = styled.h1`
    display: flex;
    align-items: center;
  `;

  export const RightFlexor = styled.nav(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      margin-left: 50px;
      height: 100%;
    `
  );

  export const GearIconHolder = styled.div`
    display: flex;
    align-items: center;

    svg {
      width: 28px;
    }
  `;

  export const DropdownDivider = styled.div(
    ({ theme }) => css`
      width: 1px;
      height: 35px;
      background: ${theme.aprilGrey};
      margin: 0 9px;
    `
  );
  const dropdownTransTime = '.3s';
  type DropdownProps = {
    isActive: boolean;
  };
  export const DropdownMenuHolder = styled(UnstyledButton)`
    position: relative;
  `;
  export const DropdownMenuIconHolder = styled.div<
    DropdownProps & { licenseState?: GetUIFeatureFlagsResponse_License_LicenseState }
  >(
    ({ isActive, theme, licenseState }) => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 33px;
      border-radius: 32px;
      cursor: pointer;
      color: ${theme.septemberGrey};

      ${svgBasics(27, 'currentColor')}

      ${isActive
        ? css`
            background: ${theme.pondBlue};
            color: ${theme.januaryGrey};
          `
        : css`
            background: transparent;
            transition: background ${dropdownTransTime};

            :hover {
              filter: drop-shadow(0 0 3px ${theme.juneGrey});
            }
          `}

      ${licenseState === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRED ||
      licenseState === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRES_SOON
        ? css`
            &:after {
              content: '';
              box-sizing: border-box;
              position: absolute;
              top: 3px;
              right: 2px;
              width: 8px;
              height: 8px;
              border-radius: 8px;
              z-index: 20;
              background: ${licenseState === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRED
                ? theme.healthColors.error.dark
                : theme.healthColors.warning.dark};
              ${isActive && 'border: 1px solid white'};
            }
          `
        : ''}
    `
  );
  export const DropdownMenu = styled.div<DropdownProps>(
    ({ isActive, theme }) => css`
      position: absolute;
      top: ${menuHeight - 15}px;
      right: 0;
      border-radius: 2px;
      background: white;
      border: 1px solid ${theme.splashBlue};
      box-shadow: 0px 3px 6px ${theme.boxShadow};
      z-index: 1000;

      overflow: auto;
      ${isActive
        ? css`
            max-height: 90vh;
            opacity: 1;
            transition: max-height ${dropdownTransTime}, opacity ${dropdownTransTime};
          `
        : css`
            max-height: 0px;
            opacity: 0;
            transition: max-height 0;
            display: none; // Required to hide it on screen readers; won't show close animation however
          `}

      > div:first-of-type, > ul {
        margin-top: 10px;
      }

      a {
        /* cuts the right flexor's a rule */
        margin: 0 !important;
      }
    `
  );

  export const DropdownMenuItem = styled.div<{ flipIcon?: boolean }>(
    ({ theme, flipIcon }) => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 0 9px;
      white-space: nowrap;
      color: ${theme.novemberGrey} !important;

      ${svgBasics({ height: 18 }, 'currentColor')}
      svg {
        max-width: 18px;
        margin-left: 24px;

        ${flipIcon
          ? css`
              transform: rotate(-90deg);
              height: 10px;
            `
          : ''}
      }

      &:hover {
        color: ${theme.seaBlue} !important;
        background: ${theme.splashBlue};
      }
    `
  );
}
