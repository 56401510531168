import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { getStatusColors } from 'utils/health-status';

export namespace SoloLinkStyles {
  type SoloWrapperProps = {
    displayInline?: boolean;
  };

  type SoloLinkLooksProps = {
    stylingOverrides?: SerializedStyles; // aka using css``
    darkText?: boolean;
    health?: State;
    withArrow?: boolean;
    small?: boolean;
    withoutHover?: boolean;
  } & SoloWrapperProps;
  export const SoloLinkLooks = styled.div<SoloLinkLooksProps>(
    ({ theme, displayInline, stylingOverrides, darkText, health, small, withArrow, withoutHover }) => css`
      display: ${displayInline ? 'inline-flex' : 'flex'};
      color: ${health !== undefined ? getStatusColors(health).text : darkText ? theme.neptuneBlue : theme.seaBlue};
      cursor: pointer;

      ${!withoutHover
        ? css`
            &:hover {
              color: ${darkText ? theme.seaBlue : theme.lakeBlue};
              text-decoration: underline;
              &:active {
                color: ${darkText ? theme.lakeBlue : theme.pondBlue};
              }
            }
          `
        : ''}
      ${withArrow
        ? css`
            text-transform: uppercase;
            &:after {
              content: '';
              display: inline-block;
              position: relative;
              align-self: center;
              margin-left: 0.4em;
              top: -0.1ex;
              width: 0.9ch;
              height: 0.9ch;
              border: solid currentColor;
              border-width: 0 2px 2px 0;
              transform: rotate(-45deg);
            }
          `
        : ''}
      ${small
        ? css`
            font-size: 80%;
          `
        : ''}
    ${stylingOverrides?.styles}
    `
  );

  export const SoloLinkLooksButton = SoloLinkLooks.withComponent(UnstyledButton);

  export const SoloLinkWrapper = styled.div<SoloWrapperProps>(
    ({ displayInline }) => css`
      display: ${displayInline ? 'inline' : 'block'};
      a {
        text-decoration: none;
        display: inherit;
      }
      ${SoloLinkLooks} {
        // SoloLinkLooks shouldn't override the display of the SoloLinkWrapper.
        display: unset;
      }
    `
  );
}
