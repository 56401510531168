import { policiesApi } from 'Api/policies';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon } from 'utils/types';

const { useGetGraphQLPersistedQueryCachePolicyDetails } = policiesApi;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const GraphqlPersistedQueryCacheDetails = ({ clusterObjRef }: Props) => {
  di(useGetGraphQLPersistedQueryCachePolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetGraphQLPersistedQueryCachePolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded
          icon={getPolicyIcon(PolicyType.GRAPHQL_PERSISTED_QUERY_CACHE)}
          objRef={clusterObjRef}
        />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  //
  // Render
  //
  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(PolicyType.GRAPHQL_PERSISTED_QUERY_CACHE)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={ResourceType.GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.GraphqlIcon />,
              title: 'Number of Persisted Queries',
              data: policyData.cacheSize
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
