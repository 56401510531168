import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { insightCodeMapToArray } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeMap, InsightCodeWithDataProp } from 'utils/dashboard/dashboard-types';

export enum FIPSHealthKey {
  fipsControlPlaneComplianceSummary = 'fipsControlPlaneComplianceSummary',
  fipsDataPlaneComplianceSummary = 'fipsDataPlaneComplianceSummary'
}

interface FIPSCodesMap extends InsightCodeMap, Record<FIPSHealthKey, Code> {
  [FIPSHealthKey.fipsControlPlaneComplianceSummary]: InsightCodeWithDataProp<'sYS0009'>;
  [FIPSHealthKey.fipsDataPlaneComplianceSummary]: InsightCodeWithDataProp<'sYS0010'>;
}

export const fipsCodesMap: FIPSCodesMap = {
  [FIPSHealthKey.fipsControlPlaneComplianceSummary]: {
    // FIPs Control Plane Compliance Summary
    // Data: &insights_v2alpha1.Insight_SYS0009{
    // 	SYS0009: &insights_v2alpha1.Insight_SYS0009Data{
    // 		FipsCompliantIstioWorkloads: 5,
    // 		TotalIstioWorkloads:         5,
    // 		UniqueFipsVersions:          []string{"1.18.3-solo-fips"},
    // 	},
    // },
    group: 'SYS',
    key: '0009',
    dataProp: 'sYS0009'
  },
  [FIPSHealthKey.fipsDataPlaneComplianceSummary]: {
    // FIPs Data Plane Compliance Summary
    // Data: &insights_v2alpha1.Insight_SYS0010{
    // 	SYS0010: &insights_v2alpha1.Insight_SYS0010Data{
    // 		FipsCompliantIstioWorkloads: 60,
    // 		TotalIstioWorkloads:         60,
    // 		UniqueFipsVersions:          []string{"1.18.3-solo-fips"},
    // 	}},
    group: 'SYS',
    key: '0010',
    dataProp: 'sYS0010'
  }
};

export const fipsCodesArray = insightCodeMapToArray(fipsCodesMap);
