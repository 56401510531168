import { EnumTypeDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlEnumTabItem from './GqlEnumTabItem';
import GqlEnumTabTable from './GqlEnumTabTable';

const GqlEnumTab = ({
  definitions,
  onTypeClick,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<EnumTypeDefinitionNode>) => {
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={5}>
        <GqlDetailsStyles.LightText>
          Use a restricted scalar to represent a predefined list of possible values.
        </GqlDetailsStyles.LightText>
      </Spacer>

      {!!focusedItem && (
        <GqlEnumTabItem
          focusedPath={focusedPath}
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlEnumTabTable
        tabHeader={tabHeader}
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
      />
    </>
  );
};

export default GqlEnumTab;
