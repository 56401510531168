import { VariableTransformation } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { convertFromPBValue } from 'utils/graphql-helpers';

export const getVariableTransformationValue = (vt: VariableTransformation | undefined) => {
  if (!vt) return null;
  if (vt.transformation.oneofKind === 'jq') return vt.transformation.jq;
  if (vt.transformation.oneofKind === 'variable') return vt.transformation.variable;
  if (vt.transformation.oneofKind === 'value') return convertFromPBValue(vt.transformation.value);
  throw new Error('Invalid `VariableTransformation` value.');
};

export const getVariableTransformationType = (vt: VariableTransformation | undefined) => {
  return vt?.transformation.oneofKind ?? '';
};

export const stringifyVariableTransformationValue = (
  vt: VariableTransformation | undefined,
  jsonStringifyStrings = true
) => {
  if (!vt) return '';
  const vtObj = getVariableTransformationValue(vt);
  if (!jsonStringifyStrings && typeof vtObj === 'string') return vtObj;
  let vtString = JSON.stringify(vtObj);
  return vtString;
};

export const stringifyVariableTransformation = (value: undefined | VariableTransformation) => {
  if (value === undefined) return '';
  const vtType = getVariableTransformationType(value);
  // This doesn't stringify certain transformation types, so they display as-intended.
  const variableTypesToNotSerialize = ['jq', 'variable'];
  return vtType + ': ' + stringifyVariableTransformationValue(value, !variableTypesToNotSerialize.includes(vtType));
};

export const stringifyVariableTransformationMap = (theMap: undefined | [string, VariableTransformation][]) => {
  if (theMap === undefined) return '';
  return theMap.map(([variable, value]) => variable + ':\n  ' + stringifyVariableTransformation(value)).join('\n');
};
