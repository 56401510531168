// all url strings, for all path pieces, for all pages
// E.g. `/${urlStrings.services}/`
export const urlStrings = {
  overview: 'overview',
  clusters: 'clusters',
  insights: 'insights',
  securityInsights: 'security-insights',
  certificates: 'certificates',
  graph: 'graph',
  gateways: 'gateways',
  apis: 'apis',
  workspaces: 'workspaces',
  policies: 'policies',
  resources: 'resources',
  tracing: 'tracing',
  portals: 'portals',
  services: 'services',
  ingress: 'ingress',
  egress: 'egress',
  logs: 'logs',

  // Indirect pages
  welcome: 'welcome',
  login: 'login',
  // logout url is dynamically returned by api server

  // Internal pages
  flags: 'flags'
} as const;

// These are the options for the workspaces second URL component.
// E.g. `/${urlStrings.workspaces}/${workspaceUrlStrings.policy}/`
export const workspaceUrlStrings = {
  destinations: 'destinations',
  policies: 'policies',
  routing: 'routing',
  apis: 'apis', // not really used, but needed for code consistency with workspace page tabs
  graph: 'graph'
} as const;
