import { Asset } from 'assets';
import { StatusCodeMatcher_Comparator } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/http_matchers_pb';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import {
  ApiType,
  ImportStatus,
  PolicyType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GraphqlApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import {
  PolicyWorkload_WorkloadKind,
  StringMatchType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import React from 'react';
import { getEnumValues } from './helpers';

const UNKNOWN_TYPE_ICON = <Asset.HelpIcon />;
const ICON_NEEDED = UNKNOWN_TYPE_ICON;

interface TypeData {
  readonly id: string; // used in urls
  readonly name: string;
  readonly icon: React.ReactNode;
}

interface ResourceProp {
  resourceType: ResourceType;
}

///////////////////////
// ImportStatus
///////////////////////
export const importStatusMap: Record<ImportStatus, TypeData> = {
  [ImportStatus.UNKNOWN_IMPORT_STATUS]: {
    id: 'unknown',
    name: 'Unknown status!',
    icon: UNKNOWN_TYPE_ICON
  },
  [ImportStatus.IMPORTED]: {
    id: 'imported',
    name: 'Imported',
    icon: <Asset.ImportIcon />
  },
  [ImportStatus.EXPORTED]: {
    id: 'exported',
    name: 'Exported',
    icon: <Asset.ExportIcon />
  },
  [ImportStatus.PRIVATE]: {
    id: 'private',
    name: 'Private',
    icon: <Asset.PrivateIcon />
  }
};
export function getImportStatusReadableName(status?: ImportStatus): string {
  if (!status) {
    return 'No status!';
  }

  return importStatusMap[status]?.name ?? 'Unknown status!';
}

export function getImportStatusIcon(status?: ImportStatus): React.ReactNode {
  if (!status) {
    return UNKNOWN_TYPE_ICON;
  }

  return importStatusMap[status]?.icon ?? UNKNOWN_TYPE_ICON;
}

///////////////////////
// DestinationKind
///////////////////////
export const destinationKindMap: Record<DestinationKind, TypeData & ResourceProp> = {
  [DestinationKind.SERVICE]: {
    id: 'kube-service',
    name: 'Kubernetes Service',
    icon: <Asset.KubernetesServiceIdle />,
    resourceType: ResourceType.SERVICE
  },
  [DestinationKind.VIRTUAL_DESTINATION]: {
    id: 'virtual-destination',
    name: 'Virtual Destination',
    icon: <Asset.VirtualMachineIconBlack />,
    resourceType: ResourceType.VIRTUAL_DESTINATION
  },
  [DestinationKind.EXTERNAL_SERVICE]: {
    id: 'external-service',
    name: 'External Service',
    icon: <Asset.ExternalServiceIconGray />,
    resourceType: ResourceType.EXTERNAL_SERVICE
  }
};
export function getDestinationKindReadableName(kind?: DestinationKind) {
  if (kind === undefined) return 'No kind!';
  return destinationKindMap[kind]?.name ?? 'Unknown kind!';
}

export function getDestinationKindIcon(kind?: DestinationKind): React.ReactNode {
  if (kind === undefined) {
    return UNKNOWN_TYPE_ICON;
  }

  return destinationKindMap[kind]?.icon ?? UNKNOWN_TYPE_ICON;
}

export const getDestinationKindFromId = (id?: string): DestinationKind | undefined => {
  return getEnumValues(DestinationKind).find(type => destinationKindMap[type]?.id === id);
};

///////////////////////
// RouteType
///////////////////////
export const routeTypeMap: Record<RouteType, TypeData & ResourceProp> = {
  [RouteType.UNKNOWN_ROUTE_TYPE]: {
    id: 'unknown',
    name: 'Unknown route type!',
    icon: UNKNOWN_TYPE_ICON,
    resourceType: ResourceType.UNKNOWN_RESOURCE_TYPE
  },
  [RouteType.VIRTUAL_GATEWAY]: {
    id: 'virtual-gateway',
    name: 'Virtual Gateway',
    icon: <Asset.GatewayIconBlack />,
    resourceType: ResourceType.VIRTUAL_GATEWAY
  },
  [RouteType.ROUTE_TABLE]: {
    id: 'table',
    name: 'Route Table',
    icon: <Asset.RouteGroupIcon />,
    resourceType: ResourceType.ROUTE_TABLE
  }
};
export function getRouteTypeReadableName(type?: RouteType): string {
  if (!type) {
    return 'No route type!';
  }

  return routeTypeMap[type]?.name ?? 'Unknown route type!';
}

export function getRouteTypeIcon(type?: RouteType): React.ReactNode {
  if (!type) {
    return UNKNOWN_TYPE_ICON;
  }

  return routeTypeMap[type]?.icon ?? UNKNOWN_TYPE_ICON;
}

export const getRouteTypeFromId = (id?: string): RouteType => {
  return getEnumValues(RouteType).find(type => routeTypeMap[type]?.id === id) ?? RouteType.UNKNOWN_ROUTE_TYPE;
};

export const getRouteTypeFromResourceType = (rt?: ResourceType): RouteType | undefined => {
  return getEnumValues(RouteType).find(type => routeTypeMap[type].resourceType === rt);
};

///////////////////////
// PolicyType
///////////////////////
export const WASM_DISABLED = true; // WASM is disabled (but not removed) as part of https://github.com/solo-io/gloo-mesh-enterprise/issues/18579
export const policyTypeMap: Record<PolicyType, TypeData & ResourceProp> = {
  [PolicyType.UNKNOWN_POLICY_TYPE]: {
    id: 'unknown',
    name: 'Unknown Type!',
    icon: UNKNOWN_TYPE_ICON,
    resourceType: ResourceType.UNKNOWN_RESOURCE_TYPE
  },
  [PolicyType.ACCESS]: {
    id: 'access',
    name: 'Access',
    icon: <Asset.AccessPolicyIcon />,
    resourceType: ResourceType.ACCESS_POLICY
  },
  [PolicyType.ACCESS_LOG]: {
    id: 'access-log',
    name: 'Access Log',
    icon: <Asset.AccessLogPolicyIcon />,
    resourceType: ResourceType.ACCESS_LOG_POLICY
  },
  [PolicyType.ACTIVE_HEALTH_CHECK]: {
    id: 'active-health-check',
    name: 'Active Health Check',
    icon: <Asset.ActiveHealthCheckPolicyIcon />,
    resourceType: ResourceType.ACTIVE_HEALTH_CHECK_POLICY
  },
  [PolicyType.CLIENT_TLS]: {
    id: 'client-tls',
    name: 'Client TLS',
    icon: <Asset.MtlsSecure />,
    resourceType: ResourceType.CLIENT_TLS_POLICY
  },
  [PolicyType.CONNECTION]: {
    id: 'connection',
    name: 'Connection',
    icon: <Asset.ConnectionPolicyIcon />,
    resourceType: ResourceType.CONNECTION_POLICY
  },
  [PolicyType.CORS]: {
    id: 'cors',
    name: 'CORS',
    icon: <Asset.CorsPolicyIcon />,
    resourceType: ResourceType.CORS_POLICY
  },
  [PolicyType.CSRF]: {
    id: 'csrf',
    name: 'CSRF',
    icon: <Asset.CsrfIcon />,
    resourceType: ResourceType.CSRF_POLICY
  },
  [PolicyType.DLP]: {
    id: 'dlp',
    name: 'Data Loss Prevention',
    icon: <Asset.DlpPolicyIcon />,
    resourceType: ResourceType.DLP_POLICY
  },
  [PolicyType.EXT_AUTH]: {
    id: 'ext-auth',
    name: 'Ext Auth',
    icon: <Asset.ExtAuthIcon />,
    resourceType: ResourceType.EXT_AUTH_POLICY
  },
  [PolicyType.FAILOVER]: {
    id: 'failover',
    name: 'Failover',
    icon: <Asset.FailoverIcon />,
    resourceType: ResourceType.FAILOVER_POLICY
  },
  [PolicyType.FAULT_INJECTION]: {
    id: 'fault-injection',
    name: 'Fault Injection',
    icon: <Asset.FaultInjectionIcon />,
    resourceType: ResourceType.FAULT_INJECTION_POLICY
  },
  [PolicyType.HEADER_MANIPULATION]: {
    id: 'header-manipulation',
    name: 'Header Manipulation',
    icon: <Asset.HeaderManipulationPolicyIcon />,
    resourceType: ResourceType.HEADER_MANIPULATION_POLICY
  },
  [PolicyType.HTTP_BUFFER]: {
    id: 'http-buffer',
    name: 'HTTP Buffer',
    icon: <Asset.HttpPolicyIcon />,
    resourceType: ResourceType.HTTP_BUFFER_POLICY
  },
  [PolicyType.JWT]: {
    id: 'jwt',
    name: 'JWT',
    icon: <Asset.JwtIcon />,
    resourceType: ResourceType.JWT_POLICY
  },
  [PolicyType.LISTENER_CONNECTION]: {
    id: 'listener-connection-policy',
    name: 'Listener Connection',
    icon: <Asset.ListenerIcon />,
    resourceType: ResourceType.LISTENER_CONNECTION_POLICY
  },
  [PolicyType.LOAD_BALANCER]: {
    id: 'load-balancer-policy',
    name: 'Load Balancer',
    icon: <Asset.LoadBalancerIcon />,
    resourceType: ResourceType.LOAD_BALANCER_POLICY
  },
  [PolicyType.MIRROR]: {
    id: 'mirror',
    name: 'Mirror',
    icon: <Asset.MirrorPolicyIcon />,
    resourceType: ResourceType.MIRROR_POLICY
  },
  [PolicyType.OUTLIER_DETECTION]: {
    id: 'outlier-detection',
    name: 'Outlier Detection',
    icon: <Asset.OutlierDetectionPolicyIcon />,
    resourceType: ResourceType.OUTLIER_DETECTION_POLICY
  },
  [PolicyType.PROXY_PROTOCOL]: {
    id: 'proxy-protocol',
    name: 'Proxy Protocol',
    icon: <Asset.ProxyProtocolPolicyIcon />,
    resourceType: ResourceType.PROXY_PROTOCOL_POLICY
  },
  [PolicyType.RATE_LIMIT]: {
    id: 'rate-limit',
    name: 'Rate Limit',
    icon: <Asset.RateLimitIcon />,
    resourceType: ResourceType.RATE_LIMIT_POLICY
  },
  [PolicyType.RETRY_TIMEOUT]: {
    id: 'retry',
    name: 'Retry Timeout',
    icon: <Asset.RetryPolicyIcon />,
    resourceType: ResourceType.RETRY_TIMEOUT_POLICY
  },
  [PolicyType.TRANSFORMATION]: {
    id: 'transformation',
    name: 'Transformation',
    icon: <Asset.TransformationPolicyIcon />,
    resourceType: ResourceType.TRANSFORMATION_POLICY
  },
  [PolicyType.TRIM_PROXY_CONFIG]: {
    id: 'trim-proxy-config',
    name: 'Trim Proxy Config',
    icon: <Asset.TrimProxyConfigIcon />,
    resourceType: ResourceType.TRIM_PROXY_CONFIG_POLICY
  },
  [PolicyType.WAF]: {
    id: 'waf',
    name: 'WAF',
    icon: <Asset.WebAccessFirewallIcon />,
    resourceType: ResourceType.WAF_POLICY
  },
  [PolicyType.WASM_DEPLOYMENT]: {
    id: 'wasm',
    name: 'WASM Deployment',
    icon: <Asset.WebAssemblyIconOutline />,
    resourceType: ResourceType.WASM_DEPLOYMENT_POLICY
  },
  [PolicyType.GRAPHQL_PERSISTED_QUERY_CACHE]: {
    id: 'graphql-persisted-query-cache',
    name: 'GraphQL Persisted Query Cache',
    // TODO: icon
    icon: <Asset.GraphqlIcon />,
    resourceType: ResourceType.GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY
  },
  [PolicyType.GRAPHQL_ALLOWED_QUERY_POLICY]: {
    id: 'graphql-allowed-query',
    name: 'GraphQL Allowed Query',
    // TODO: icon
    icon: <Asset.GraphqlIcon />,
    resourceType: ResourceType.GRAPH_QL_ALLOWED_QUERY_POLICY
  },
  [PolicyType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY]: {
    id: 'adaptive-request-concurrency',
    name: 'Adaptive Request Concurrency',
    icon: <Asset.AdaptiveRequestConcurrencyPolicyIcon />,
    resourceType: ResourceType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY
  }
};

export function getPolicyTypeReadableName(type: PolicyType | undefined): string {
  if (!type) {
    return 'No type!';
  }
  return policyTypeMap[type].name ?? 'Unknown type!';
}

export function getPolicyTypeId(type: PolicyType | undefined): string {
  if (!type) {
    return 'No type!';
  }
  return policyTypeMap[type].id ?? 'Unknown type!';
}

export const getPolicyIcon = (policyType: PolicyType | undefined): React.ReactNode => {
  if (!policyType) {
    return UNKNOWN_TYPE_ICON;
  }
  return policyTypeMap[policyType].icon ?? UNKNOWN_TYPE_ICON;
};

export const getPolicyResourceType = (policyType: PolicyType | undefined): ResourceType =>
  policyTypeMap[policyType ?? PolicyType.UNKNOWN_POLICY_TYPE]?.resourceType ?? ResourceType.UNKNOWN_RESOURCE_TYPE;

export const getPolicyTypeFromId = (id?: string): PolicyType => {
  return getEnumValues(PolicyType).find(type => policyTypeMap[type].id === id) ?? PolicyType.UNKNOWN_POLICY_TYPE;
};
export const getPolicyTypeFromResourceType = (rt?: ResourceType): PolicyType | undefined => {
  return getEnumValues(PolicyType).find(type => policyTypeMap[type].resourceType === rt);
};

///////////////////////
// StringMatchType
///////////////////////
export const stringMatchTypeMap: Record<StringMatchType, { name: string }> = {
  [StringMatchType.UNKNOWN_MATCH_TYPE]: { name: 'Unknown match type!' },
  [StringMatchType.EXACT]: { name: 'exact' },
  [StringMatchType.PREFIX]: { name: 'prefix' },
  [StringMatchType.REGEX]: { name: 'regex' },
  [StringMatchType.SUFFIX]: { name: 'suffix' }
};

///////////////////////
// StatusCodeMatcher.Comparator
///////////////////////
export const statusCodeMatcherComparatorMap: Record<StatusCodeMatcher_Comparator, { name: string }> = {
  [StatusCodeMatcher_Comparator.EQ]: { name: '=' },
  [StatusCodeMatcher_Comparator.GE]: { name: '>' },
  [StatusCodeMatcher_Comparator.LE]: { name: '<' }
};

///////////////////////
// StatusCodeMatcher.Comparator
///////////////////////
export const workloadKindMap: Record<PolicyWorkload_WorkloadKind, TypeData> = {
  [PolicyWorkload_WorkloadKind.KUBE]: {
    id: 'kube',
    name: 'Kubernetes',
    icon: <Asset.KubernetesServiceIdle />
  },
  [PolicyWorkload_WorkloadKind.VM]: {
    id: 'vm',
    name: 'Virtual Machine',
    icon: <Asset.VirtualMachineIconBlack />
  }
};

///////////////////////
// ApiType
///////////////////////
export const apiTypeMap: Record<ApiType, TypeData> = {
  [ApiType.UNKNOWN]: { id: 'unknown', name: '<Unknown>', icon: UNKNOWN_TYPE_ICON },
  [ApiType.GRAPHQL_STITCHED]: {
    id: 'stitched',
    name: 'GraphQL - Stitched',
    icon: <Asset.StitchedGraphqlIcon />
  },
  [ApiType.GRAPHQL_RESOLVED]: {
    id: 'resolved',
    name: 'GraphQL - Resolved',
    icon: <Asset.ResolvedGraphqlIcon />
  },
  [ApiType.GRAPHQL_PROXIED]: {
    id: 'proxied',
    name: 'GraphQL - Proxied',
    icon: <Asset.ProxiedGraphqlIcon />
  },
  [ApiType.PORTAL_OPENAPI]: { id: 'openapi', name: 'OpenAPI (REST)', icon: <Asset.OpenApiIcon /> }
};

export const getApiTypeIcon = (type: ApiType | undefined): React.ReactNode => {
  if (!type) {
    return UNKNOWN_TYPE_ICON;
  }
  return apiTypeMap[type].icon ?? UNKNOWN_TYPE_ICON;
};

export const getApiTypeFromId = (id?: string): ApiType => {
  return getEnumValues(ApiType).find(type => apiTypeMap[type].id === id) ?? ApiType.UNKNOWN;
};

///////////////////////
// GraphqlApiType
///////////////////////
export const graphqlApiTypeMap: Record<GraphqlApiType, TypeData> = {
  [GraphqlApiType.STITCHED]: { id: 'stitched', name: 'Stitched', icon: <Asset.StitchedGraphqlIcon /> },
  [GraphqlApiType.RESOLVED]: {
    id: 'resolved',
    name: 'Resolved',
    icon: <Asset.ResolvedGraphqlIcon />
  },
  [GraphqlApiType.PROXIED]: { id: 'proxied', name: 'Proxied', icon: <Asset.ProxiedGraphqlIcon /> }
};

///////////////////////
// GvkType
///////////////////////
export enum GvkType {
  /**
   * Group: /.solo.io$/
   */
  GLOO,
  /**
   * Group: /.solo.io$/
   */
  GLOO_INTERNAL,
  /**
   * Group: /.istio.io$/
   */
  ISTIO,
  /**
   * Group: "" & Version: v1  OR  Group: apps & Version: v1  OR  Group: /.k8s.io$/
   * Excludes group: gateway.networking.k8s.io.
   */
  KUBE_NON_GATEWAY,
  /**
   * Group: gateway.networking.k8s.io
   */
  KUBE_GATEWAY_API,
  /**
   * Group: cilium.io
   */
  CILIUM
}
export const gvkTypeMap: Record<GvkType, Omit<TypeData, 'icon'>> = {
  [GvkType.ISTIO]: { id: 'istio', name: 'Istio' },
  [GvkType.CILIUM]: { id: 'cilium', name: 'Cilium' },
  [GvkType.KUBE_NON_GATEWAY]: { id: 'kubernetes', name: 'Kubernetes' },
  [GvkType.GLOO]: { id: 'solo', name: 'Solo Resources' },
  [GvkType.GLOO_INTERNAL]: { id: 'solo-internal', name: 'Solo Internal Resources' },
  [GvkType.KUBE_GATEWAY_API]: { id: 'kubernetes-gateway-api', name: 'Kubernetes Gateway API' }
};

export const getGvkTypeFromId = (id?: string): GvkType | undefined => {
  return getEnumValues(GvkType).find(type => gvkTypeMap[type].id === id);
};

export function getGvkTypeReadableName(type?: GvkType): string | undefined {
  if (type === undefined) return undefined;
  return gvkTypeMap[type]?.name;
}

///////////////////////
// ResourceType
///////////////////////
export const getResourceTypeFromId = (id?: string): ResourceType =>
  getEnumValues(ResourceType).find(type => resourceTypesMap[type].id === id) ?? ResourceType.UNKNOWN_RESOURCE_TYPE;

/*
  If you're seeing "Property '#' is missing in type...",
  then it needs to be manually added to this list and an issue made for getting the icon.
  If you are getting further errors or missing references, reach out for help from the UI team.

  Please keep list alphabetized and keep ids as lowercase dash-separated values (for urls)
*/
export const resourceTypesMap: Record<ResourceType, TypeData & { gvkType: GvkType }> = {
  //
  // CILIUM Resources
  //
  [ResourceType.CILIUM_NETWORK_POLICY]: {
    id: 'cilium-network-policy',
    name: 'Cilium Network Policy',
    icon: <Asset.CiliumIcon />,
    gvkType: GvkType.CILIUM
  },

  //
  // KUBE_NON_GATEWAY Resources
  //
  [ResourceType.CLUSTER_ROLE]: {
    id: 'cluster-role',
    name: 'Cluster Role',
    icon: <Asset.ClusterIcon />,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.CLUSTER_ROLE_BINDING]: {
    id: 'cluster-role-binding',
    name: 'Cluster Role Binding',
    icon: <Asset.ClusterIcon />,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.CUSTOM_RESOURCE_DEFINITION]: {
    id: 'custom-resource-definition',
    name: 'Custom Resource Definition',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.DAEMON_SET]: {
    id: 'daemon-set',
    name: 'Daemon Set',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.DEPLOYMENT]: {
    id: 'deployment',
    name: 'Deployment',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.NAMESPACE]: {
    id: 'namespace',
    name: 'Namespace',
    icon: <Asset.NamespaceIcon />,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.NETWORK_POLICY]: {
    id: 'network-policy',
    name: 'Network Policy',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.NODE]: {
    id: 'node',
    name: 'Node',
    icon: <Asset.NodeBiDirection />,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.ROLE]: {
    id: 'role',
    name: 'Role',
    icon: <Asset.RoleIcon />,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.ROLE_BINDING]: {
    id: 'role-binding',
    name: 'Role Binding',
    icon: <Asset.RoleIcon />,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.SERVICE]: {
    id: 'service',
    name: 'Service',
    icon: getDestinationKindIcon(DestinationKind.SERVICE),
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.SERVICE_ACCOUNT]: {
    id: 'service-account',
    name: 'Service Account',
    icon: getDestinationKindIcon(DestinationKind.SERVICE),
    gvkType: GvkType.KUBE_NON_GATEWAY
  },
  [ResourceType.STATEFUL_SET]: {
    id: 'stateful-set',
    name: 'Stateful Set',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_NON_GATEWAY
  },

  //
  // KUBE_GATEWAY_API Resources
  //
  [ResourceType.GATEWAY_CLASS]: {
    id: 'gateway-class',
    name: 'Gateway Class',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_GATEWAY_API
  },
  [ResourceType.HTTP_ROUTE]: {
    id: 'http-route',
    name: 'HTTP Route',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_GATEWAY_API
  },
  [ResourceType.K8S_GATEWAY]: {
    id: 'k8s-gateway',
    name: 'Kubernetes Gateway',
    icon: ICON_NEEDED,
    gvkType: GvkType.KUBE_GATEWAY_API
  },

  //
  // GLOO Resources
  //
  [ResourceType.UNKNOWN_RESOURCE_TYPE]: {
    id: 'unknown',
    name: 'Unknown status!',
    icon: UNKNOWN_TYPE_ICON,
    gvkType: GvkType.GLOO
  },
  [ResourceType.ACCESS_LOG_POLICY]: {
    id: 'access-log-policy',
    name: 'Access Log Policy',
    icon: getPolicyIcon(PolicyType.ACCESS_LOG),
    gvkType: GvkType.GLOO
  },
  [ResourceType.ACCESS_POLICY]: {
    id: 'access-policy',
    name: 'Access Policy',
    icon: getPolicyIcon(PolicyType.ACCESS),
    gvkType: GvkType.GLOO
  },
  [ResourceType.ACTIVE_HEALTH_CHECK_POLICY]: {
    id: 'active-health-check-policy',
    name: 'Active Health Check Policy',
    icon: getPolicyIcon(PolicyType.ACTIVE_HEALTH_CHECK),
    gvkType: GvkType.GLOO
  },
  [ResourceType.API_DOC]: {
    id: 'api-doc',
    name: 'API Doc',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.API_SCHEMA_DISCOVERY]: {
    id: 'api-schema-discovery',
    name: 'API Schema Discovery',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.AUTH_CONFIG]: {
    id: 'auth-config',
    name: 'Auth Config',
    icon: <Asset.AuthCloudIcon />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.CLIENT_TLS_POLICY]: {
    id: 'client-tls-policy',
    name: 'Client TLS Policy',
    icon: getPolicyIcon(PolicyType.CLIENT_TLS),
    gvkType: GvkType.GLOO
  },
  [ResourceType.CLOUD_PROVIDER]: {
    id: 'cloud-provider',
    name: 'Cloud Provider',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.CLOUD_RESOURCES]: {
    id: 'cloud-resources',
    name: 'Cloud Functions',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.CONNECTION_POLICY]: {
    id: 'connection-policy',
    name: 'Connection Policy',
    icon: getPolicyIcon(PolicyType.CONNECTION),
    gvkType: GvkType.GLOO
  },
  [ResourceType.CORS_POLICY]: {
    id: 'cors-policy',
    name: 'CORS Policy',
    icon: getPolicyIcon(PolicyType.CORS),
    gvkType: GvkType.GLOO
  },
  [ResourceType.CSRF_POLICY]: {
    id: 'csrf-policy',
    name: 'CSRF Policy',
    icon: getPolicyIcon(PolicyType.CSRF),
    gvkType: GvkType.GLOO
  },
  [ResourceType.DASHBOARD]: {
    id: 'dashboard',
    name: 'Dashboard',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.DISCOVERED_CNI]: {
    id: 'discovered-cni',
    name: 'Discovered CNI',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO_INTERNAL
  },
  [ResourceType.DISCOVERED_GATEWAY]: {
    id: 'discovered-gateway',
    name: 'Discovered Gateway',
    icon: <Asset.GatewayIcon />,
    gvkType: GvkType.GLOO_INTERNAL
  },
  [ResourceType.DLP_POLICY]: {
    id: 'dlp-policy',
    name: 'Data Loss Prevention Policy',
    icon: getPolicyIcon(PolicyType.DLP),
    gvkType: GvkType.GLOO
  },
  [ResourceType.EXT_AUTH_POLICY]: {
    id: 'ext-auth-policy',
    name: 'Ext Auth Policy',
    icon: getPolicyIcon(PolicyType.EXT_AUTH),
    gvkType: GvkType.GLOO
  },
  [ResourceType.EXT_AUTH_SERVER]: {
    id: 'ext-auth-server',
    name: 'Ext Auth Server',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.EXTERNAL_ENDPOINT]: {
    id: 'external-endpoint',
    name: 'External Endpoint',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.EXTERNAL_SERVICE]: {
    id: 'external-service',
    name: 'External Service',
    icon: getDestinationKindIcon(DestinationKind.EXTERNAL_SERVICE),
    gvkType: GvkType.GLOO
  },
  [ResourceType.EXTERNAL_WORKLOAD]: {
    id: 'external-workload',
    name: 'External Workload',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.FAILOVER_POLICY]: {
    id: 'failover-policy',
    name: 'Failover Policy',
    icon: getPolicyIcon(PolicyType.FAILOVER),
    gvkType: GvkType.GLOO
  },
  [ResourceType.FAULT_INJECTION_POLICY]: {
    id: 'fault-injection-policy',
    name: 'Fault Injection Policy',
    icon: getPolicyIcon(PolicyType.FAULT_INJECTION),
    gvkType: GvkType.GLOO
  },
  // This is just to get it to pass.
  [ResourceType.GATEWAY_LIFECYCLE_MANAGER]: {
    id: 'gateway-lifecycle-manager',
    name: 'Gateway Lifecycle Manager',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.GRAPH_QL_ALLOWED_QUERY_POLICY]: {
    id: 'graphql-allowed-query-policy',
    name: 'GraphQL Allowed Query Policy',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY]: {
    id: 'graphql-persisted-query-cache-policy',
    name: 'GraphQL Persisted Query Cache Policy',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.GRAPH_QL_RESOLVER_MAP]: {
    id: 'graphql-resolver',
    name: 'GraphQL Resolver',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.GRAPH_QL_SCHEMA]: {
    id: 'graphql-schema',
    name: 'GraphQL Schema',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.GRAPH_QL_STITCHED_SCHEMA]: {
    id: 'graphql-stitched',
    name: 'GraphQL Stitched',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.HEADER_MANIPULATION_POLICY]: {
    id: 'header-manipulation-policy',
    name: 'Header Manipulation Policy',
    icon: getPolicyIcon(PolicyType.HEADER_MANIPULATION),
    gvkType: GvkType.GLOO
  },
  [ResourceType.HTTP_BUFFER_POLICY]: {
    id: 'http-buffer-policy',
    name: 'HTTP Buffer Policy',
    icon: getPolicyIcon(PolicyType.HTTP_BUFFER),
    gvkType: GvkType.GLOO
  },
  [ResourceType.ISTIO_LIFECYCLE_MANAGER]: {
    id: 'istio-lifecycle-manager',
    name: 'Istio Lifecycle Manager',
    icon: <Asset.IstioLogo />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.JWT_POLICY]: {
    id: 'jwt-policy',
    name: 'JWT Policy',
    icon: getPolicyIcon(PolicyType.JWT),
    gvkType: GvkType.GLOO
  },
  [ResourceType.KUBERNETES_CLUSTER]: {
    id: 'kubernetes-cluster',
    name: 'Kubernetes Cluster',
    icon: <Asset.ClusterIcon />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.LISTENER_CONNECTION_POLICY]: {
    id: 'listener-connection-policy',
    name: 'Listener Connection Policy',
    icon: getPolicyIcon(PolicyType.LISTENER_CONNECTION),
    gvkType: GvkType.GLOO
  },
  [ResourceType.LOAD_BALANCER_POLICY]: {
    id: 'load-balancer-policy',
    name: 'Load Balancer Policy',
    icon: getPolicyIcon(PolicyType.LOAD_BALANCER),
    gvkType: GvkType.GLOO
  },
  [ResourceType.MESH]: {
    id: 'mesh',
    name: 'Mesh',
    icon: <Asset.MeshIcon />,
    gvkType: GvkType.GLOO_INTERNAL
  },
  [ResourceType.MIRROR_POLICY]: {
    id: 'mirror-policy',
    name: 'Mirror Policy',
    icon: getPolicyIcon(PolicyType.MIRROR),
    gvkType: GvkType.GLOO
  },
  [ResourceType.OUTLIER_DETECTION_POLICY]: {
    id: 'outlier-detection-policy',
    name: 'Outlier Detection Policy',
    icon: getPolicyIcon(PolicyType.OUTLIER_DETECTION),
    gvkType: GvkType.GLOO
  },
  [ResourceType.PORTAL]: {
    id: 'portal',
    name: 'Portal',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.API_PRODUCT]: {
    id: 'api-product',
    name: 'API Product',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.PORTAL_CONFIG]: {
    id: 'portal-config',
    name: 'Portal Config',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO_INTERNAL
  },
  [ResourceType.PORTAL_GROUP]: {
    id: 'portal-group',
    name: 'Portal Group',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.PROXY_PROTOCOL_POLICY]: {
    id: 'proxy-protocol-policy',
    name: 'Proxy Protocol Policy',
    icon: getPolicyIcon(PolicyType.PROXY_PROTOCOL),
    gvkType: GvkType.GLOO
  },
  [ResourceType.RATE_LIMIT_CLIENT_CONFIG]: {
    id: 'rate-limit-client-config',
    name: 'Rate Limit Client Config',
    icon: <Asset.RateLimitIcon />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.RATE_LIMIT_CONFIG]: {
    id: 'rate-limit-config',
    name: 'Rate Limit Config',
    icon: <Asset.RateLimitIcon />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.RATE_LIMIT_POLICY]: {
    id: 'rate-limit-policy',
    name: 'Rate Limit Policy',
    icon: getPolicyIcon(PolicyType.RATE_LIMIT),
    gvkType: GvkType.GLOO
  },
  [ResourceType.RATE_LIMIT_SERVER_CONFIG]: {
    id: 'rate-limit-server-config',
    name: 'Rate Limit Server Config',
    icon: <Asset.RateLimitIcon />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.RATE_LIMIT_SERVER_SETTINGS]: {
    id: 'rate-limit-server-settings',
    name: 'Rate Limit Server Settings',
    icon: <Asset.RateLimitIcon />,
    gvkType: GvkType.GLOO
  },
  [ResourceType.RETRY_TIMEOUT_POLICY]: {
    id: 'retry-timeout-policy',
    name: 'Retry Timeout Policy',
    icon: getPolicyIcon(PolicyType.RETRY_TIMEOUT),
    gvkType: GvkType.GLOO
  },
  [ResourceType.ROOT_TRUST_POLICY]: {
    id: 'root-trust-policy',
    name: 'Root Trust Policy',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.ROUTE_TABLE]: {
    id: 'route-table',
    name: 'Route Table',
    icon: getRouteTypeIcon(RouteType.ROUTE_TABLE),
    gvkType: GvkType.GLOO
  },
  [ResourceType.SPIRE_REGISTRATION_ENTRY]: {
    id: 'spire-registration-entry',
    name: 'Spire Registration Entry',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.TRANSFORMATION_POLICY]: {
    id: 'transformation-policy',
    name: 'Transformation Policy',
    icon: getPolicyIcon(PolicyType.TRANSFORMATION),
    gvkType: GvkType.GLOO
  },
  [ResourceType.TRIM_PROXY_CONFIG_POLICY]: {
    id: 'trim-proxy-config-policy',
    name: 'Trim Proxy Config Policy',
    icon: getPolicyIcon(PolicyType.TRIM_PROXY_CONFIG),
    gvkType: GvkType.GLOO
  },
  [ResourceType.VIRTUAL_DESTINATION]: {
    id: 'virtual-destination',
    name: 'Virtual Destination',
    icon: getDestinationKindIcon(DestinationKind.VIRTUAL_DESTINATION),
    gvkType: GvkType.GLOO
  },
  [ResourceType.VIRTUAL_GATEWAY]: {
    id: 'virtual-gateway',
    name: 'Virtual Gateway',
    icon: getRouteTypeIcon(RouteType.VIRTUAL_GATEWAY),
    gvkType: GvkType.GLOO
  },
  [ResourceType.WAF_POLICY]: {
    id: 'waf-policy',
    name: 'WAF Policy',
    icon: getPolicyIcon(PolicyType.WAF),
    gvkType: GvkType.GLOO
  },
  [ResourceType.WASM_DEPLOYMENT_POLICY]: {
    id: 'wasm-deployment-policy',
    name: 'Wasm Deployment Policy',
    icon: getPolicyIcon(PolicyType.WASM_DEPLOYMENT),
    gvkType: GvkType.GLOO
  },
  [ResourceType.WAYPOINT_LIFECYCLE_MANAGER]: {
    id: 'waypoint-lifecycle-manager',
    name: 'Waypoint Lifecycle Manager',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.WORKSPACE]: {
    id: 'workspace',
    name: 'Workspace',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.WORKSPACE_SETTINGS]: {
    id: 'workspace-settings',
    name: 'Workspace Settings',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },

  //
  // ISTIO Resources
  //
  [ResourceType.AUTHORIZATION_POLICY]: {
    id: 'authorization',
    name: 'Authorization Policy',
    icon: <Asset.AuthCloudIcon />,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.DESTINATION_RULE]: {
    id: 'destination-rule',
    name: 'Destination Rule',
    icon: ICON_NEEDED,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.ENVOY_FILTER]: {
    id: 'envoy-filter',
    name: 'Envoy Filter',
    icon: ICON_NEEDED,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.GATEWAY]: {
    id: 'gateway',
    name: 'Gateway',
    icon: <Asset.GatewayIcon />,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.PEER_AUTHENTICATION]: {
    id: 'peer-authentication',
    name: 'Peer Authentication',
    icon: <Asset.PeerAuthenticationIcon />,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.SERVICE_ENTRY]: {
    id: 'service-entry',
    name: 'Service Entry',
    icon: <Asset.ServiceIcon />,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.SIDECAR]: {
    id: 'sidecar',
    name: 'Sidecar',
    icon: ICON_NEEDED,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.VIRTUAL_SERVICE]: {
    id: 'virtual-service',
    name: 'Virtual Service',
    icon: ICON_NEEDED,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.WORKLOAD_ENTRY]: {
    id: 'workload-entry',
    name: 'Workload Entry',
    icon: <Asset.WorkloadIcon />,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.ISTIO_OPERATOR]: {
    id: 'istioOperator',
    name: 'Istio Operator',
    icon: ICON_NEEDED,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.TELEMETRY]: {
    id: 'telemetry',
    name: 'Telemetry',
    icon: ICON_NEEDED,
    gvkType: GvkType.ISTIO
  },
  [ResourceType.INSIGHTS_CONFIG]: {
    id: 'insights-config-policy',
    name: 'Insights Config Policy',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  },
  [ResourceType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY]: {
    id: 'adaptive-request-concurrency-policy',
    name: 'Adaptive Request Concurrency Policy',
    icon: ICON_NEEDED,
    gvkType: GvkType.GLOO
  }
};

type _Combine<T, K extends PropertyKey = T extends unknown ? keyof T : never> = T extends unknown
  ? T & Partial<Record<Exclude<K, keyof T>, never>>
  : never;

// This collapses the types of a union into a type that can be then narrowed down.
// From: https://stackoverflow.com/a/77841929
export type Combine<T> = { [K in keyof _Combine<T>]: _Combine<T>[K] };
