import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { Centered } from 'Styles/CommonEmotions/centered';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { FieldDefinitionNode, InputValueDefinitionNode, TypeNode } from 'graphql';
import { Resolution_Resolvers } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext, useMemo, useState } from 'react';
import { GqlSchemaTabTableProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import GqlResolverModal from '../../../Modals/GqlResolverModal';
import { GqlLandingContext } from '../../../context/GqlLandingContext';
import { RenderDefinitionNameLinkProps, renderDefinitionNameLink } from '../../GqlSoloTableCells';
import FieldTypeValue from '../FieldTypeValue';
import { GqlDescriptionInfoTooltip } from '../GqlDescriptionInfoTooltip';
import NameAndReturnType from '../NameAndReturnType';
import GqlResolverButton from '../buttons/GqlResolverButton';
import { GqlSchemaTabStyles } from './GqlSchemaTabStyles.style';

type TableFields = {
  key: string;
  isFieldIncludedByExtension: boolean;
  nameLink: RenderDefinitionNameLinkProps;
  type: TypeNode;
  arguments?: readonly InputValueDefinitionNode[] | undefined;
  description: string;
  fieldNode: FieldDefinitionNode;
};

/**
 * The arguments column is hidden if no fields have arguments.
 * If `showArgumentsColumnIfNoArgs===true`, the arguments column
 * will be shown regardless, even if no fields have arguments.
 */
const GqlSchemaFieldDefinitionTable = ({
  hideResolversAlways = false,
  objectName,
  definitions,
  extendedDefinitions,
  showArgumentsColumnIfNoArgs,
  onTypeClick,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<FieldDefinitionNode> & {
  hideResolversAlways?: boolean;
  objectName?: string;
  tabHeader: string;
}) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { apiTypeCanHaveResolvers } = gqlCtx;

  // Show the resolvers if the API can have resolvers and the hideResolversProp is falsy.
  const showResolvers = apiTypeCanHaveResolvers && !hideResolversAlways;

  // Selected Resolver State
  const [selectedResolver, setSelectedResolver] = useState<Resolution_Resolvers | null>(null);
  const [selectedResolverObjectName, setSelectedResolverObjectName] = useState('');
  const [selectedResolverFieldName, setSelectedResolverFieldName] = useState('');

  const columns: SoloColumnsType<TableFields> = useMemo(() => {
    const cols: SoloColumnsType<TableFields> = [
      {
        title: 'Name',
        dataIndex: 'nameLink',
        render: (props, record) => {
          return (
            <FlexLayout>
              {record.isFieldIncludedByExtension && (
                <Spacer mt={1}>
                  <GqlDescriptionInfoTooltip description='This type was extended to include this field.' />
                </Spacer>
              )}
              {renderDefinitionNameLink(props)}
            </FlexLayout>
          );
        }
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => (
          <GqlSchemaTabStyles.MonospaceItem>
            <FieldTypeValue field={type} onTypeClick={itemName => onTypeClick(itemName, true)} />
          </GqlSchemaTabStyles.MonospaceItem>
        )
      }
    ];
    const anyFieldsHaveArgs = definitions.some(d => !!d.arguments?.length);
    if (showArgumentsColumnIfNoArgs || anyFieldsHaveArgs) {
      cols.push({
        title: 'Arguments',
        dataIndex: 'arguments',
        render: args => (
          <Spacer py={2}>
            {args?.map(argument => (
              <NameAndReturnType key={argument.name.value} node={argument} onTypeClick={t => onTypeClick(t, true)} />
            ))}
          </Spacer>
        )
      });
    }
    cols.push({
      title: 'Description',
      dataIndex: 'description',
      render: renderReactMarkdown
    });
    if (showResolvers) {
      cols.push({
        title: () => <Centered horizontal>Resolver</Centered>,
        dataIndex: 'fieldNode',
        render: fieldNode => {
          return (
            <GqlSchemaTabStyles.CenteredTableCell>
              <GqlResolverButton
                objectName={objectName ?? ''}
                fieldNode={fieldNode}
                onResolverSelected={resolver => {
                  setSelectedResolver(resolver);
                  setSelectedResolverObjectName(objectName ?? '');
                  setSelectedResolverFieldName(fieldNode.name.value);
                }}
              />
            </GqlSchemaTabStyles.CenteredTableCell>
          );
        }
      });
    }
    return cols;
  }, [onTypeClick, showResolvers]);

  //
  // Pagination, Table Data
  //
  const combinedDefinitions = useMemo(
    () =>
      [...definitions, ...(extendedDefinitions?.map(d => ({ ...d, isFieldIncludedByExtension: true })) ?? [])] as
        | (FieldDefinitionNode & { isFieldIncludedByExtension?: boolean })[],
    [definitions, extendedDefinitions]
  );
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(combinedDefinitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(f => ({
      key: f.name.value,
      isFieldIncludedByExtension: !!f.isFieldIncludedByExtension,
      nameLink: { name: f.name.value, tabHeader, onTypeClick },
      type: f.type,
      description: getDescriptionColumnValue(f),
      arguments: f.arguments ?? [],
      fieldNode: f
    }));
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  // Render
  //
  if (hidden) return null;
  return (
    <>
      <SoloTable
        removeHorizontalPadding
        columns={columns}
        dataSource={tableData}
        paging={pagingData}
        data-testid='gql-fields-table'
      />
      <GqlResolverModal
        objectName={selectedResolverObjectName}
        fieldName={selectedResolverFieldName}
        resolver={selectedResolver}
        onClose={() => {
          setSelectedResolver(null);
          setSelectedResolverObjectName('');
          setSelectedResolverFieldName('');
        }}
      />
    </>
  );
};

export default GqlSchemaFieldDefinitionTable;
