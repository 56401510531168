import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { EnumTypeDefinitionNode, EnumValueDefinitionNode, Kind } from 'graphql';
import { useContext, useMemo } from 'react';
import { GqlSchemaTabTableProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import { RenderDefinitionNameLinkProps, renderDefinitionNameLink } from '../../../GqlSoloTableCells';
import { GqlDescriptionInfoTooltip } from '../../GqlDescriptionInfoTooltip';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';

type TableFields = {
  key: string;
  nameLink: RenderDefinitionNameLinkProps;
  values: readonly (EnumValueDefinitionNode & { isFieldIncludedByExtension?: boolean })[];
  description: string;
};
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Name',
    dataIndex: 'nameLink',
    render: renderDefinitionNameLink
  },
  {
    title: 'Values',
    dataIndex: 'values',
    render: values => (
      <Spacer py={2}>
        {values.map(v => {
          const descriptionValue = v.description?.value ?? '';
          return (
            <FlexLayout key={v.name.value}>
              {!!v.isFieldIncludedByExtension && (
                <GqlDescriptionInfoTooltip description={'This enum was extended to include this value.'} />
              )}

              <GqlSchemaTabStyles.MonospaceItem>
                {v.name.value}
                <GqlDescriptionInfoTooltip description={descriptionValue} />
              </GqlSchemaTabStyles.MonospaceItem>
            </FlexLayout>
          );
        })}
      </Spacer>
    )
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: renderReactMarkdown
  }
];

const GqlEnumTabTable = ({
  definitions,
  onTypeClick,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<EnumTypeDefinitionNode>) => {
  const gqlctx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlctx;

  //
  // Pagination, Table Data
  //
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(definitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(d => {
      //
      // Get the extensions info, and mark it as such.
      const extensionValues =
        getExtensionInfo(Kind.ENUM_TYPE_EXTENSION, d.name.value)?.values?.map(v => ({
          ...v,
          isFieldIncludedByExtension: true
        })) ?? [];
      //
      // Return the table data
      return {
        key: d.name.value,
        nameLink: { name: d.name.value, tabHeader, onTypeClick },
        values: [...(d.values ?? []), ...extensionValues],
        description: getDescriptionColumnValue(d)
      };
    });
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  // Render
  //
  if (hidden) return null;
  return (
    <SoloTable
      removeHorizontalPadding
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='gql-enums-table'
    />
  );
};

export default GqlEnumTabTable;
