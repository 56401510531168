import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { Color, colors, healthColors } from 'Styles/colors';

const { normal: normalColors, warning: warningColors, error: errorColors } = healthColors;

export interface HealthStateProps {
  state?: State;
}

//////////////////////////
// State Conversion - helps converts unsupported states into supported ones
//////////////////////////

type SupportedState = State.ACCEPTED | State.WARNING | State.FAILED;
const stateConversionMap: Record<State, SupportedState> = {
  [State.ACCEPTED]: State.ACCEPTED,

  [State.WARNING]: State.WARNING,
  [State.PENDING]: State.WARNING, // TODO: Treat as own state?

  [State.FAILED]: State.FAILED,
  [State.INVALID]: State.FAILED
};
export function convertHealthState(state: State | undefined): SupportedState {
  // If state isn't known yet, then show as healthy until it is known (this avoids incorrectly showing content as unhealty)
  return stateConversionMap[state ?? State.ACCEPTED];
}

//////////////////////////
// General Health Color
// Used by notifications and alerts
//////////////////////////
interface StatusColors {
  light: Color;
  dark: Color;

  text: Color;
  icon: Color;
  border: Color;
  background: Color;
}
const statusColors: Record<SupportedState, StatusColors> = {
  [State.ACCEPTED]: {
    ...normalColors,
    text: normalColors.dark,
    icon: colors.seaBlue, // Icon uses a slightly lighter blue color
    border: normalColors.dark,
    background: normalColors.light
  },
  [State.WARNING]: {
    ...warningColors,
    text: warningColors.text, // `dark` isn't dark enough for text to be readable
    icon: warningColors.dark,
    border: warningColors.dark,
    background: warningColors.light
  },
  [State.FAILED]: {
    ...errorColors,
    text: errorColors.dark,
    icon: errorColors.dark,
    border: errorColors.dark,
    background: errorColors.light
  }
};
export function getStatusColors(state: State | undefined) {
  return statusColors[convertHealthState(state)];
}
