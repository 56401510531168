import ReactMarkdown from 'react-markdown';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabStyles } from './GqlSchemaTabStyles.style';

const GqlTabItemDescription = ({ node }: { node: undefined | { description?: { value: string } } }) => {
  //
  // Render
  //
  if (!node?.description?.value) return <Spacer pt={2} />;
  return (
    <Spacer pt={1} pb={4} display='block'>
      <GqlSchemaTabStyles.TabDetailsDescription>
        {/* eslint-disable-next-line */}
        <ReactMarkdown children={node.description.value.trim()} />
      </GqlSchemaTabStyles.TabDetailsDescription>
    </Spacer>
  );
};

export default GqlTabItemDescription;
