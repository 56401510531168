import { Asset } from 'assets';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { docLinks } from 'utils/url-external-links-map';
import { AddClusterModalBodyStyles as Styles } from './ClustersLanding.style';

export const AddClusterModalBody = () => (
  <Styles.Container data-testid='add-overview-resource-modal'>
    <Styles.Title>
      Registering a Kubernetes Cluster <Asset.ClusterIcon />
    </Styles.Title>
    <Styles.Hint>
      Full instructions can be found on the{' '}
      <ExternalSoloLink href={docLinks.enterprise.enterprise_installation_HASH.helm_register} newTab inline>
        Registering clusters with Gloo Platform
      </ExternalSoloLink>{' '}
      page.
    </Styles.Hint>

    <Styles.InstructionsArea>
      <Styles.InstructionHint>
        <Styles.InstructionNumber>1</Styles.InstructionNumber>
        Complete the{' '}
        <ExternalSoloLink inline newTab href={docLinks.enterprise.gs_multi_HASH.before_you_begin}>
          prerequisites
        </ExternalSoloLink>
        .
      </Styles.InstructionHint>

      <Styles.InstructionHint>
        <Styles.InstructionNumber>2</Styles.InstructionNumber>
        Install Gloo in the management cluster with{' '}
        <ExternalSoloLink inline newTab href={docLinks.enterprise.gs_multi_HASH.gloo_server}>
          meshctl
        </ExternalSoloLink>
        .
      </Styles.InstructionHint>

      <Styles.InstructionHint>
        <Styles.InstructionNumber>3</Styles.InstructionNumber>
        Register your{' '}
        <ExternalSoloLink inline newTab href={docLinks.enterprise.gs_multi_HASH.gloo_agents}>
          workload clusters
        </ExternalSoloLink>
        .
      </Styles.InstructionHint>

      <Styles.InstructionHint>
        <Styles.InstructionNumber>4</Styles.InstructionNumber>
        Explore{' '}
        <ExternalSoloLink inline newTab href={docLinks.enterprise.supported_policies}>
          policies
        </ExternalSoloLink>
        .
      </Styles.InstructionHint>
    </Styles.InstructionsArea>
  </Styles.Container>
);
