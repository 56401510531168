import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { SoloModalStyles } from 'Components/Common/SoloModal.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { svgColorReplace } from 'Styles/CommonEmotions/utils';
import { Properties } from 'csstype';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { Link } from 'react-router-dom';
import { getStatusColors } from 'utils/health-status';

export namespace DashboardStyles {
  export const LandingContainer = styled.div``;
  export const LandingContainerEmptyWrapper = styled.div`
    position: relative;
    ${SoloModalStyles.ModalWindow} {
      position: absolute;
      z-index: 0;
    }
  `;
  export const LandingContainerEmpty = styled.div`
    pointer-events: none;
    filter: blur(1.5px);
  `;

  export const LandingCardContainer = styled.div(
    ({ theme }) => css`
      width: 100%;
      height: 100%;
      ${CardStyles.Card} {
        box-shadow: 0 0 10px ${theme.splashBlue};
        border-color: ${theme.splashBlue};
        height: 100%;
        padding: 1em 1.5em;
      }
    `
  );

  export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    font-size: 1.25em;
    padding-bottom: 0.25em;
  `;

  export const SubHeader = styled.div`
    font-size: 1.1em;
    padding-bottom: 0.5em;
  `;

  export const CardColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `;

  export const CardBottomLink = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: end;
    font-weight: bold;
  `;

  export const ChartLegendRow = styled.div(({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 5px 0px;
      font-size: 12px;
    `;
  });

  export const ChartLegendRowAsLink = styled(ChartLegendRow)(({ theme }) => {
    return css`
      color: ${theme.neptuneBlue};
    `;
  }).withComponent(Link);

  export const ChartLegendCountText = styled.div<{ datasetColor: Properties['backgroundColor'] }>(
    ({ datasetColor }) => css`
      color: ${datasetColor};
      font-weight: bold;
    `
  );

  export const ChartLegendColorSample = styled.div<{ datasetColor: Properties['backgroundColor'] }>(
    ({ datasetColor }) => css`
      border-radius: 3px;
      width: 15px;
      height: 15px;
      background-color: ${datasetColor};
    `
  );

  /** Should be used inside flex containers that have flex-direction: row. */
  export const VerticalLine = styled(Spacer)<{
    width?: Properties['width'];
    height?: Properties['height'];
  }>(
    ({ theme, width = '1px', height }) => css`
      flex-basis: 0px;
      flex-grow: 1;
      flex-shrink: 1;
      width: ${width};
      max-width: ${width};
      ${height === undefined
        ? css`
            height: 100%; // Note: 'height' used over 'min-height' as the later causes an issue on safari
          `
        : css`
            height: ${height};
          `};
      background-color: ${theme.splashBlue};
    `
  );

  export const VersionsCountCircleAndTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.35em;
  `;
  export const VersionsCountCircle = styled.span(({ theme }) => {
    return css`
      display: inline-flex;
      height: 22px;
      min-width: 22px;
      padding: 2px 4px 0;
      align-items: center;
      border: 2px solid ${theme.oceanBlue};
      border-radius: 11px;
    `;
  });

  export const FullHeightTabsWrapper = styled.div`
    height: 100%;
    [data-reach-tabs] {
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
    }
    [data-reach-tab-panel] {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  `;

  export const InsightBox = styled.div<{ state?: State }>(({ theme, state }) => {
    const statusColors = getStatusColors(state);
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100%;
      padding: 10px;
      background: ${state === State.ACCEPTED ? theme.dropBlue : statusColors.background};
      border: 1px solid ${state ? statusColors.border : theme.oceanBlue};
      border-radius: 4px;
      ${svgColorReplace(state ? statusColors.text : theme.oceanBlue)}
      cursor: default;
    `;
  });

  export const InsightBoxStatusColor = styled.span<{ state?: State }>(({ theme, state }) => {
    const statusColors = getStatusColors(state);
    return css`
      color: ${state ? statusColors.text : theme.oceanBlue};
    `;
  });

  export const InsightBoxTitle = styled.div`
    font-size: 18px;
  `;
}
