import { overviewApi } from 'Api/overview';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { DashboardHealthCardStyles } from 'Components/Features/Dashboard/Cards/Health/DashboardHealthCard.style';
import { useGlooVersionListCardEntries } from 'Components/Features/Dashboard/Cards/Health/hooks';
import { di } from 'react-magnetic-di';
const { useGetClusterDetailsCards } = overviewApi;

export const ClusterGlooVersionsListCard = ({ cluster }: { cluster: string }) => {
  di(useGetClusterDetailsCards);
  const { data: overviewCardsData } = useGetClusterDetailsCards(cluster);
  const versionListCardEntries = useGlooVersionListCardEntries(overviewCardsData?.healthOverview?.glooVersions);

  return (
    <DashboardHealthCardStyles.NoMarginEmptyContainer>
      <SoloListCard
        data-testid='gloo-versions-card'
        title='Version'
        maxHeight='167px'
        dataSource={versionListCardEntries}
        hideDottedLine
      />
    </DashboardHealthCardStyles.NoMarginEmptyContainer>
  );
};
