import styled from '@emotion/styled';

export namespace ClusterServicesStyles {
  const CardContentContainer_cssContainerName = 'dashboard-cluster-services-container';
  const ChartContainer_cssContainerName = 'dashboard-cluster-chart-container';

  export const CardContentContainer = styled.div`
    container: ${CardContentContainer_cssContainerName} / inline-size;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
  `;

  export const CardContent = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 600px;
    flex-wrap: wrap;
  `;

  export const ChartTextContainer = styled.div`
    // TODO: using pointerEvents:none will enable the tooltip on the chart.
    // For now it isn't themed yet, so it's disabled.
    // pointerEvents: 'none',
    position: absolute;
    top: 5px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #325476;
    font-weight: 400;
    gap: 10px;
  `;

  export const ChartBigText = styled.div`
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const ChartSmallText = styled.div`
    font-size: 14px;
    line-height: 1em;
    text-align: center;
  `;

  export const ChartContainer = styled.div`
    container: ${ChartContainer_cssContainerName} / size;

    position: relative;
    width: 160px;
    height: 160px; /* height must match instead of being auto, as otherwise it doesn't scale up correctly when resizing window */

    /* Controls how text behaves inside the chart circle at smaller sizes */

    @container ${ChartContainer_cssContainerName} (max-width: 139px) {
      ${ChartSmallText} {
        font-size: 12px !important;
      }
    }
    @container ${ChartContainer_cssContainerName} (max-width: 119px) {
      ${ChartTextContainer} {
        display: none;
      }
    }
  `;

  // Made it's own component as `&` doesn't work inside `@container`, and moving the logic into `CardContentContainer`
  // forces `ChartContainer` + other things to the top of the file
  export const ChartSizer = styled.div`
    /* shrinks ChartContainer to fit within container at smaller sizes */
    @container ${CardContentContainer_cssContainerName} (max-width: 475px) {
      ${ChartContainer} {
        width: 140px;
        height: 140px;
      }
    }
    @container ${CardContentContainer_cssContainerName} (max-width: 420px) {
      ${ChartContainer} {
        width: 120px;
        height: 120px;
      }
    }
    @container ${CardContentContainer_cssContainerName} (max-width: 400px) {
      ${ChartContainer} {
        width: 100px;
        height: 100px;
      }
    }
    @container ${CardContentContainer_cssContainerName} (max-width: 375px) {
      ${ChartContainer} {
        width: 80px;
        height: 80px;
      }
    }
    // This makes it fit up to the point where the card becomes a single column
    @container ${CardContentContainer_cssContainerName} (max-width: 355px) {
      ${ChartContainer} {
        width: 50px;
        height: 50px;
      }
    }
  `;
}
