/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PoliciesApi } from "./policies_pb";
import type { GetPolicyRoutesResponse } from "./policies_pb";
import type { GetPolicyRoutesRequest } from "./policies_pb";
import type { GetRateLimitClientConfigResponse } from "./policies_pb";
import type { GetRateLimitClientConfigRequest } from "./policies_pb";
import type { GetAdaptiveRequestConcurrencyPolicyDetailsResponse } from "./policies_pb";
import type { GetGraphQLAllowedQueryPolicyDetailsResponse } from "./policies_pb";
import type { GetGraphQLAllowedQueryPolicyDetailsRequest } from "./policies_pb";
import type { GetGraphQLPersistedQueryCachePolicyDetailsResponse } from "./policies_pb";
import type { GetListenerConnectionPolicyDetailsResponse } from "./policies_pb";
import type { GetLoadBalancerPolicyDetailsResponse } from "./policies_pb";
import type { GetTrimProxyConfigPolicyDetailsResponse } from "./policies_pb";
import type { GetActiveHealthCheckPolicyDetailsResponse } from "./policies_pb";
import type { GetActiveHealthCheckPolicyDetailsRequest } from "./policies_pb";
import type { GetClientTlsPolicyDetailsResponse } from "./policies_pb";
import type { GetHttpBufferPolicyDetailsResponse } from "./policies_pb";
import type { GetProxyProtocolPolicyDetailsResponse } from "./policies_pb";
import type { GetConnectionPolicyDetailsResponse } from "./policies_pb";
import type { GetWasmDeploymentPolicyDetailsResponse } from "./policies_pb";
import type { GetTransformationPolicyDetailsResponse } from "./policies_pb";
import type { GetAccessLogPolicyDetailsResponse } from "./policies_pb";
import type { GetJwtPolicyDetailsResponse } from "./policies_pb";
import type { GetHeaderManipulationPolicyDetailsResponse } from "./policies_pb";
import type { GetDlpPolicyDetailsResponse } from "./policies_pb";
import type { GetWafPolicyDetailsResponse } from "./policies_pb";
import type { GetRateLimitPolicyDetailsResponse } from "./policies_pb";
import type { GetRateLimitPolicyDetailsRequest } from "./policies_pb";
import type { GetExtAuthPolicyDetailsResponse } from "./policies_pb";
import type { GetExtAuthPolicyDetailsRequest } from "./policies_pb";
import type { GetMirrorPolicyDetailsResponse } from "./policies_pb";
import type { GetCsrfPolicyDetailsResponse } from "./policies_pb";
import type { GetCorsPolicyDetailsResponse } from "./policies_pb";
import type { GetOutlierDetectionPolicyDetailsResponse } from "./policies_pb";
import type { GetFailoverPolicyDetailsResponse } from "./policies_pb";
import type { GetRetryPolicyDetailsResponse } from "./policies_pb";
import type { GetFaultInjectionPolicyDetailsResponse } from "./policies_pb";
import type { GetPolicyDetailsRequest } from "./policies_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetAccessPolicyDetailsResponse } from "./policies_pb";
import type { GetAccessPolicyDetailsRequest } from "./policies_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Apis for the Gloo Mesh UI Workspace Policies pages
 *
 * @generated from protobuf service rpc.gloo.solo.io.PoliciesApi
 */
export interface IPoliciesApiClient {
    /**
     * @generated from protobuf rpc: GetAccessPolicyDetails(rpc.gloo.solo.io.GetAccessPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetAccessPolicyDetailsResponse);
     */
    getAccessPolicyDetails(input: GetAccessPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetAccessPolicyDetailsRequest, GetAccessPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetFaultInjectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetFaultInjectionPolicyDetailsResponse);
     */
    getFaultInjectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetFaultInjectionPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetRetryPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetRetryPolicyDetailsResponse);
     */
    getRetryPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetRetryPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetFailoverPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetFailoverPolicyDetailsResponse);
     */
    getFailoverPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetFailoverPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetOutlierDetectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetOutlierDetectionPolicyDetailsResponse);
     */
    getOutlierDetectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetOutlierDetectionPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetCorsPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetCorsPolicyDetailsResponse);
     */
    getCorsPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetCorsPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetCsrfPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse);
     */
    getCsrfPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetCsrfPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetMirrorPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetMirrorPolicyDetailsResponse);
     */
    getMirrorPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetMirrorPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetExtAuthPolicyDetails(rpc.gloo.solo.io.GetExtAuthPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse);
     */
    getExtAuthPolicyDetails(input: GetExtAuthPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetExtAuthPolicyDetailsRequest, GetExtAuthPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetRateLimitPolicyDetails(rpc.gloo.solo.io.GetRateLimitPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetRateLimitPolicyDetailsResponse);
     */
    getRateLimitPolicyDetails(input: GetRateLimitPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetRateLimitPolicyDetailsRequest, GetRateLimitPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetWafPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetWafPolicyDetailsResponse);
     */
    getWafPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetWafPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetDlpPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetDlpPolicyDetailsResponse);
     */
    getDlpPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetDlpPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetHeaderManipulationPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetHeaderManipulationPolicyDetailsResponse);
     */
    getHeaderManipulationPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetHeaderManipulationPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetJwtPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetJwtPolicyDetailsResponse);
     */
    getJwtPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetJwtPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetAccessLogPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetAccessLogPolicyDetailsResponse);
     */
    getAccessLogPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetAccessLogPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetTransformationPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse);
     */
    getTransformationPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetTransformationPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetWasmDeploymentPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse);
     */
    getWasmDeploymentPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetWasmDeploymentPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetConnectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse);
     */
    getConnectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetConnectionPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetProxyProtocolPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetProxyProtocolPolicyDetailsResponse);
     */
    getProxyProtocolPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetProxyProtocolPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetHttpBufferPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetHttpBufferPolicyDetailsResponse);
     */
    getHttpBufferPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetHttpBufferPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetClientTlsPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse);
     */
    getClientTlsPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetClientTlsPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetActiveHealthCheckPolicyDetails(rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsResponse);
     */
    getActiveHealthCheckPolicyDetails(input: GetActiveHealthCheckPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetActiveHealthCheckPolicyDetailsRequest, GetActiveHealthCheckPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetTrimProxyConfigPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetTrimProxyConfigPolicyDetailsResponse);
     */
    getTrimProxyConfigPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetTrimProxyConfigPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetLoadBalancerPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse);
     */
    getLoadBalancerPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetLoadBalancerPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetListenerConnectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetListenerConnectionPolicyDetailsResponse);
     */
    getListenerConnectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetListenerConnectionPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetGraphQLPersistedQueryCachePolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetGraphQLPersistedQueryCachePolicyDetailsResponse);
     */
    getGraphQLPersistedQueryCachePolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetGraphQLPersistedQueryCachePolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetGraphQLAllowedQueryPolicyDetails(rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsResponse);
     */
    getGraphQLAllowedQueryPolicyDetails(input: GetGraphQLAllowedQueryPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetGraphQLAllowedQueryPolicyDetailsRequest, GetGraphQLAllowedQueryPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetAdaptiveRequestConcurrencyPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse);
     */
    getAdaptiveRequestConcurrencyPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetAdaptiveRequestConcurrencyPolicyDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetRateLimitClientConfig(rpc.gloo.solo.io.GetRateLimitClientConfigRequest) returns (rpc.gloo.solo.io.GetRateLimitClientConfigResponse);
     */
    getRateLimitClientConfig(input: GetRateLimitClientConfigRequest, options?: RpcOptions): UnaryCall<GetRateLimitClientConfigRequest, GetRateLimitClientConfigResponse>;
    /**
     * @generated from protobuf rpc: GetPolicyRoutes(rpc.gloo.solo.io.GetPolicyRoutesRequest) returns (rpc.gloo.solo.io.GetPolicyRoutesResponse);
     */
    getPolicyRoutes(input: GetPolicyRoutesRequest, options?: RpcOptions): UnaryCall<GetPolicyRoutesRequest, GetPolicyRoutesResponse>;
}
/**
 * Apis for the Gloo Mesh UI Workspace Policies pages
 *
 * @generated from protobuf service rpc.gloo.solo.io.PoliciesApi
 */
export class PoliciesApiClient implements IPoliciesApiClient, ServiceInfo {
    typeName = PoliciesApi.typeName;
    methods = PoliciesApi.methods;
    options = PoliciesApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetAccessPolicyDetails(rpc.gloo.solo.io.GetAccessPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetAccessPolicyDetailsResponse);
     */
    getAccessPolicyDetails(input: GetAccessPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetAccessPolicyDetailsRequest, GetAccessPolicyDetailsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccessPolicyDetailsRequest, GetAccessPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFaultInjectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetFaultInjectionPolicyDetailsResponse);
     */
    getFaultInjectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetFaultInjectionPolicyDetailsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetFaultInjectionPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRetryPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetRetryPolicyDetailsResponse);
     */
    getRetryPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetRetryPolicyDetailsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetRetryPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFailoverPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetFailoverPolicyDetailsResponse);
     */
    getFailoverPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetFailoverPolicyDetailsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetFailoverPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOutlierDetectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetOutlierDetectionPolicyDetailsResponse);
     */
    getOutlierDetectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetOutlierDetectionPolicyDetailsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetOutlierDetectionPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCorsPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetCorsPolicyDetailsResponse);
     */
    getCorsPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetCorsPolicyDetailsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetCorsPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCsrfPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse);
     */
    getCsrfPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetCsrfPolicyDetailsResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetCsrfPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMirrorPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetMirrorPolicyDetailsResponse);
     */
    getMirrorPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetMirrorPolicyDetailsResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetMirrorPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetExtAuthPolicyDetails(rpc.gloo.solo.io.GetExtAuthPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse);
     */
    getExtAuthPolicyDetails(input: GetExtAuthPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetExtAuthPolicyDetailsRequest, GetExtAuthPolicyDetailsResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetExtAuthPolicyDetailsRequest, GetExtAuthPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRateLimitPolicyDetails(rpc.gloo.solo.io.GetRateLimitPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetRateLimitPolicyDetailsResponse);
     */
    getRateLimitPolicyDetails(input: GetRateLimitPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetRateLimitPolicyDetailsRequest, GetRateLimitPolicyDetailsResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRateLimitPolicyDetailsRequest, GetRateLimitPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWafPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetWafPolicyDetailsResponse);
     */
    getWafPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetWafPolicyDetailsResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetWafPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDlpPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetDlpPolicyDetailsResponse);
     */
    getDlpPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetDlpPolicyDetailsResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetDlpPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetHeaderManipulationPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetHeaderManipulationPolicyDetailsResponse);
     */
    getHeaderManipulationPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetHeaderManipulationPolicyDetailsResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetHeaderManipulationPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJwtPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetJwtPolicyDetailsResponse);
     */
    getJwtPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetJwtPolicyDetailsResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetJwtPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccessLogPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetAccessLogPolicyDetailsResponse);
     */
    getAccessLogPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetAccessLogPolicyDetailsResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetAccessLogPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransformationPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse);
     */
    getTransformationPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetTransformationPolicyDetailsResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetTransformationPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWasmDeploymentPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse);
     */
    getWasmDeploymentPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetWasmDeploymentPolicyDetailsResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetWasmDeploymentPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConnectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse);
     */
    getConnectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetConnectionPolicyDetailsResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetConnectionPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProxyProtocolPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetProxyProtocolPolicyDetailsResponse);
     */
    getProxyProtocolPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetProxyProtocolPolicyDetailsResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetProxyProtocolPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetHttpBufferPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetHttpBufferPolicyDetailsResponse);
     */
    getHttpBufferPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetHttpBufferPolicyDetailsResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetHttpBufferPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetClientTlsPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse);
     */
    getClientTlsPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetClientTlsPolicyDetailsResponse> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetClientTlsPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetActiveHealthCheckPolicyDetails(rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsResponse);
     */
    getActiveHealthCheckPolicyDetails(input: GetActiveHealthCheckPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetActiveHealthCheckPolicyDetailsRequest, GetActiveHealthCheckPolicyDetailsResponse> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetActiveHealthCheckPolicyDetailsRequest, GetActiveHealthCheckPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTrimProxyConfigPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetTrimProxyConfigPolicyDetailsResponse);
     */
    getTrimProxyConfigPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetTrimProxyConfigPolicyDetailsResponse> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetTrimProxyConfigPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLoadBalancerPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse);
     */
    getLoadBalancerPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetLoadBalancerPolicyDetailsResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetLoadBalancerPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetListenerConnectionPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetListenerConnectionPolicyDetailsResponse);
     */
    getListenerConnectionPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetListenerConnectionPolicyDetailsResponse> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetListenerConnectionPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGraphQLPersistedQueryCachePolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetGraphQLPersistedQueryCachePolicyDetailsResponse);
     */
    getGraphQLPersistedQueryCachePolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetGraphQLPersistedQueryCachePolicyDetailsResponse> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetGraphQLPersistedQueryCachePolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGraphQLAllowedQueryPolicyDetails(rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsResponse);
     */
    getGraphQLAllowedQueryPolicyDetails(input: GetGraphQLAllowedQueryPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetGraphQLAllowedQueryPolicyDetailsRequest, GetGraphQLAllowedQueryPolicyDetailsResponse> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphQLAllowedQueryPolicyDetailsRequest, GetGraphQLAllowedQueryPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAdaptiveRequestConcurrencyPolicyDetails(rpc.gloo.solo.io.GetPolicyDetailsRequest) returns (rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse);
     */
    getAdaptiveRequestConcurrencyPolicyDetails(input: GetPolicyDetailsRequest, options?: RpcOptions): UnaryCall<GetPolicyDetailsRequest, GetAdaptiveRequestConcurrencyPolicyDetailsResponse> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyDetailsRequest, GetAdaptiveRequestConcurrencyPolicyDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRateLimitClientConfig(rpc.gloo.solo.io.GetRateLimitClientConfigRequest) returns (rpc.gloo.solo.io.GetRateLimitClientConfigResponse);
     */
    getRateLimitClientConfig(input: GetRateLimitClientConfigRequest, options?: RpcOptions): UnaryCall<GetRateLimitClientConfigRequest, GetRateLimitClientConfigResponse> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRateLimitClientConfigRequest, GetRateLimitClientConfigResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPolicyRoutes(rpc.gloo.solo.io.GetPolicyRoutesRequest) returns (rpc.gloo.solo.io.GetPolicyRoutesResponse);
     */
    getPolicyRoutes(input: GetPolicyRoutesRequest, options?: RpcOptions): UnaryCall<GetPolicyRoutesRequest, GetPolicyRoutesResponse> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPolicyRoutesRequest, GetPolicyRoutesResponse>("unary", this._transport, method, opt, input);
    }
}
