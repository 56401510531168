import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { IconNextToText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import { IstioOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { OverviewDetailsStyles as Styles } from './OverviewDetails.style';

export const OverviewClusterIstioDetails = ({
  overview,
  clusterName
}: {
  overview: IstioOverview;
  clusterName: string;
}) => {
  const actionState = overview.status?.state;
  const healthState = overview.healthState;
  return (
    <Styles.SubDetail data-testid='overview-cluster-istio-details' state={actionState}>
      <Styles.SubDetailsTitle state={actionState}>
        <div>
          <IconNextToText>
            <HealthIndicator status={overview.status} size={14} />
            Istio Overview
            <SecondaryInfo
              items={[
                { label: 'Version', data: overview.version },
                !!overview.revision && { label: 'Revision', data: overview.revision }
              ]}
              small
            />
          </IconNextToText>
        </div>
        <Asset.IstioLogo />
      </Styles.SubDetailsTitle>
      <Styles.SubDetailsBody>
        <div data-testid={`${clusterName}-root-namespace`}>
          <div>Root Namespace</div>
          <Styles.DottedLined />
          <Styles.Number>{overview.rootNamespace}</Styles.Number>
        </div>
        <div data-testid={`${clusterName}-trust-domain`}>
          <div>Trust Domain</div>
          <Styles.DottedLined />
          <Styles.Number>{overview.trustDomain}</Styles.Number>
        </div>
        <div data-testid={`${clusterName}-managed-by-gloo-mesh`}>
          <div>Managed By Gloo</div>
          <Styles.DottedLined />
          <Styles.Number>{overview.managedByGlooMesh ? 'Yes' : 'No'}</Styles.Number>
        </div>
        <div data-testid={`${clusterName}-health`}>
          <div>Health State</div>
          <Styles.DottedLined />
          <Styles.Number>{healthState}</Styles.Number>
        </div>
      </Styles.SubDetailsBody>
    </Styles.SubDetail>
  );
};
