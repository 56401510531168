import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace LoadingStyles {
  interface ContainerProps {
    small?: boolean;
    tiny?: boolean;
    center?: boolean;
    floating?: boolean;
  }
  export const Container = styled.div<ContainerProps>(
    ({ small, tiny, center, floating }) => css`
      ${floating
        ? css`
            position: absolute;
          `
        : ''}
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      ${!small && !tiny
        ? css`
            padding: 20px 10px 35px;
          `
        : ''}

      ${center
        ? css`
            display: flex;
            height: 100%;
            justify-content: center;
            align-content: center;
          `
        : ''}
    `
  );

  interface RotaterProps {
    degrees: number;
    size: number;
  }
  export const Rotater = styled.div<RotaterProps>(
    ({ degrees, size }) => css`
      transform: rotate(${degrees}deg);

      width: ${size}px;
      height: ${size}px;

      ${svgBasics(size)}
    `
  );

  export const Message = styled.div`
    padding-top: 16px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  `;
}
