import { css } from '@emotion/react';
import styled from '@emotion/styled';

type ErrorMessageProps = { loading?: boolean };
export const ErrorMessage = styled.div<ErrorMessageProps>(
  ({ theme, loading }) => css`
    background-color: ${theme.tangerineOrange};
    border: 1px solid ${theme.grapefruitOrange};
    color: ${theme.pumpkinOrange};
    border-radius: 8px;
    padding: 10px 24px 13px;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    opacity: ${loading ? `.4;` : '1'};

    span {
      font-weight: bold;
    }
  `
);

export const SmallErrorMessage = styled.div<ErrorMessageProps>(
  ({ theme, loading }) => css`
    background-color: ${theme.tangerineOrange};
    border: 1px solid ${theme.grapefruitOrange};
    color: ${theme.pumpkinOrange};
    border-radius: 8px;
    padding: 10px 24px 13px;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    opacity: ${loading ? `.4` : '1'};
    padding: 7px 15px;

    span {
      font-weight: bold;
    }
  `
);
