import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { FieldDefinitionNode, ObjectTypeDefinitionNode } from 'graphql';
import { useContext, useMemo } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlSchemaFieldDefinitionItem from '../GqlSchemaFieldDefinitionItem';
import GqlSchemaFieldDefinitionTable from '../GqlSchemaFieldDefinitionTable';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';
import GqlTabItemInterfaceImplementations from '../GqlTabItemInterfaceImplementations';

const GqlMutationTab = ({
  definitions,
  onTypeClick,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<FieldDefinitionNode>) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { schema } = gqlCtx;
  // This gets the "Mutation" object definition.
  const mutationObjectDefinition = useMemo(
    () =>
      schema.definitions.find(d => 'name' in d && d.name?.value.toLowerCase() === 'mutation') as
        | ObjectTypeDefinitionNode
        | undefined,
    [schema.definitions]
  );

  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={3}>
        <GqlDetailsStyles.LightText>
          Define the top-level entry point for write operation queries that clients execute against your server.
        </GqlDetailsStyles.LightText>
      </Spacer>
      {!focusedItem ? (
        <>
          <GqlTabItemInterfaceImplementations node={mutationObjectDefinition} onTypeClick={onTypeClick} />
          <GqlTabItemDirectiveList node={mutationObjectDefinition} onTypeClick={onTypeClick} />
          <GqlTabItemDescription node={mutationObjectDefinition} />
        </>
      ) : (
        <GqlSchemaFieldDefinitionItem
          objectName='mutation'
          focusedPath={focusedPath}
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlSchemaFieldDefinitionTable
        objectName='mutation'
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
        tabHeader={tabHeader}
        showArgumentsColumnIfNoArgs
      />
    </>
  );
};

export default GqlMutationTab;
