import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { SoloTableStyles } from 'Components/Common/SoloTable.style';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { Asset } from 'assets';
import {
  PolicyType,
  Status
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useEffect, useState } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { getPolicyIcon, getPolicyTypeReadableName } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';

type TableFields = {
  key: string;
  name: StateAndNameType;
  clusterName?: string;
  namespace?: string;
  type?: PolicyType;
};
const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render(type) {
      return (
        <SoloTableStyles.CellIconText>
          {getPolicyIcon(type)} {getPolicyTypeReadableName(type)}
        </SoloTableStyles.CellIconText>
      );
    }
  }
];

interface BasicPoliciesTableProps {
  policies:
    | {
        policyRef?: ClusterObjectRef;
        status?: Status;
        type: PolicyType;
      }[]
    | undefined;
  paging: PagingProps;
}
export const BasicPoliciesTable = ({ policies, paging }: BasicPoliciesTableProps) => {
  const [tableData, setTableData] = useState<TableFields[]>([]);

  useEffect(() => {
    if (!!policies) {
      setTableData(
        policies.map(data => ({
          ...data,
          ...data.policyRef,
          key: buildIdFromRef(data.policyRef),
          name: { name: data.policyRef?.name ?? '', status: data.status }
        }))
      );
    } else {
      setTableData([]);
    }
  }, [policies]);

  return (
    <SoloTable
      data-testid='basic-policies-table'
      columns={columns}
      dataSource={tableData}
      paging={paging}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.PolicyRuleIcon />}
          title='There are no Policies to display'
          description=''
          href={docLinks.enterprise.supported_policies}
        />
      }
    />
  );
};
