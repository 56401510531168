import { HealthIndicatorIcon } from 'Components/Common/HealthIndicator';
import { HealthNotificationBox } from 'Components/Common/HealthNotificationBox';
import { GetYamlParams, MultiYamlModal, YamlModal } from 'Components/Common/YamlModal';
import { State, Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef, ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useState } from 'react';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { DetailsHeaderCardStyles as Styles } from './DetailsHeaderCard.style';
import { SecondaryInfo, SecondaryInfoItemProps } from './SecondaryInfo';

type AltYamlProps = GetYamlParams & {
  status?: Status;
  icon?: React.ReactNode;
};

type YamlUnionType =
  | { title?: React.ReactNode; yamlList: AltYamlProps[] }
  | {
      title?: React.ReactNode;
      resourceType: ResourceType;
      clusterObjectRef: ClusterObjectRef;
      icon?: React.ReactNode;
      status?: Status;
    };

interface PropsWithoutDefaults {
  title: React.ReactNode;
  icon: React.ReactNode;
  status: Status | undefined;
  secondaryInfo?: (SecondaryInfoItemProps | false)[];
  buttons?: React.ReactNode;
  // Allows different types of yaml behavior, such as a multi yaml or manually passing in a yaml object
  yaml?: YamlUnionType;
  'data-testid'?: string;
}
export function DetailsHeaderCardWithoutDefaults({
  title,
  icon,
  status,
  buttons,
  secondaryInfo,
  yaml,
  'data-testid': testId
}: PropsWithoutDefaults) {
  const [showYamlModal, setShowYamlModal] = useState(false);
  return (
    <>
      <Styles.HeaderInfo state={status?.state ?? State.ACCEPTED} data-testid={testId}>
        <HealthIndicatorIcon status={status} size={60} icon={icon} />

        <Styles.NameContainer>
          <Styles.Name>{title}</Styles.Name>
          {!!secondaryInfo?.length && <SecondaryInfo highlightByDefault items={secondaryInfo} />}
        </Styles.NameContainer>
        <Styles.RightTools>
          {buttons}
          {!!yaml && <SoloButton onClick={() => setShowYamlModal(true)}>VIEW YAML</SoloButton>}
        </Styles.RightTools>
      </Styles.HeaderInfo>

      <HealthNotificationBox status={status} />

      {!!showYamlModal &&
        !!yaml &&
        ('resourceType' in yaml ? (
          <YamlModal
            usedTitle={yaml.title ?? title}
            yamlResource={{
              resourceType: yaml.resourceType,
              clusterObjectRef: yaml.clusterObjectRef,
              status: yaml.status ?? status,
              icon: yaml.icon ?? icon
            }}
            onClose={() => setShowYamlModal(false)}
          />
        ) : (
          <MultiYamlModal
            title={yaml.title ?? title}
            icon={yaml.yamlList[0].icon ?? icon}
            status={yaml.yamlList[0].status ?? status}
            resources={yaml.yamlList}
            onClose={() => setShowYamlModal(false)}
          />
        ))}
    </>
  );
}

interface Props {
  objRef: ClusterObjectRef;
  icon: React.ReactNode;
  workspaceRef: ObjectRef | undefined;
  status: Status | undefined;
  buttons?: React.ReactNode;
  resourceType?: ResourceType;
  secondaryInfo?: (SecondaryInfoItemProps | false)[];
  usedName?: string;
  contentAfterName?: React.ReactNode;
  // Allows custom yaml behavior, such as a multi yaml or manually passing in a yaml object
  yaml?: YamlUnionType;
}
export function DetailsHeaderCard({
  icon,
  status,
  objRef,
  workspaceRef,
  buttons,
  resourceType,
  secondaryInfo,
  usedName,
  contentAfterName,
  yaml
}: Props) {
  // If a yaml object isn't passed in, attempt to make one if we have the necessary data
  yaml ??= !!resourceType && !!objRef ? { resourceType, clusterObjectRef: objRef } : undefined;

  return (
    <DetailsHeaderCardWithoutDefaults
      {...{ icon, status, buttons, yaml }}
      data-testid={!!workspaceRef ? 'detailspage-header-with-loaded-workspace' : undefined}
      title={
        <>
          {usedName ?? objRef?.name ?? 'unknown'} {contentAfterName}
        </>
      }
      secondaryInfo={[
        !!workspaceRef && { label: 'workspace', data: workspaceRef.name },
        !!objRef?.clusterName && { label: 'cluster', data: objRef.clusterName },
        { label: 'namespace', data: objRef.namespace ?? 'unknown' },
        ...(secondaryInfo ?? [])
      ]}
    />
  );
}

interface DetailsHeaderCardPreLoadedProps {
  objRef: ClusterObjectRef | undefined;
  icon: React.ReactNode;
  usedName?: string;
}
export function DetailsHeaderCardPreLoaded({ objRef, icon, usedName }: DetailsHeaderCardPreLoadedProps) {
  return (
    <DetailsHeaderCardWithoutDefaults
      title={usedName ?? objRef?.name ?? 'unknown'}
      icon={icon}
      status={{ state: State.ACCEPTED, validationErrors: [] }}
      secondaryInfo={[
        !!objRef?.clusterName && {
          label: 'cluster',
          data: objRef.clusterName
        },
        objRef !== undefined && { label: 'namespace', data: objRef.namespace ?? 'unknown' }
      ]}
    />
  );
}
