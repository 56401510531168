/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/api/v2/core/http_uri.proto" (package "envoy.api.v2.core", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../../../../google/protobuf/duration_pb";
// [#protodoc-title: HTTP Service URI ]

/**
 * Envoy external URI descriptor
 *
 * @generated from protobuf message envoy.api.v2.core.HttpUri
 */
export interface HttpUri {
    /**
     * The HTTP server URI. It should be a full FQDN with protocol, host and path.
     *
     * Example:
     *
     * .. code-block:: yaml
     *
     *    uri: https://www.googleapis.com/oauth2/v1/certs
     *
     *
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf oneof: http_upstream_type
     */
    httpUpstreamType: {
        oneofKind: "cluster";
        /**
         * A cluster is created in the Envoy "cluster_manager" config
         * section. This field specifies the cluster name.
         *
         * Example:
         *
         * .. code-block:: yaml
         *
         *    cluster: jwks_cluster
         *
         *
         * @generated from protobuf field: string cluster = 2;
         */
        cluster: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * Sets the maximum duration in milliseconds that a response can take to arrive upon request.
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 3;
     */
    timeout?: Duration;
}
// @generated message type with reflection information, may provide speed optimized methods
class HttpUri$Type extends MessageType<HttpUri> {
    constructor() {
        super("envoy.api.v2.core.HttpUri", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "cluster", kind: "scalar", oneof: "httpUpstreamType", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 3, name: "timeout", kind: "message", T: () => Duration, options: { "validate.rules": { duration: { required: true, gte: {} } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.HttpUri
 */
export const HttpUri = new HttpUri$Type();
