import { ScalarTypeDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlScalarTabItem from './GqlScalarTabItem';
import GqlScalarTabTable from './GqlScalarTabTable';

const GqlScalarTab = ({
  definitions,
  onTypeClick,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<ScalarTypeDefinitionNode>) => {
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={5}>
        <GqlDetailsStyles.LightText>Use a custom scalar to represent non-standard types.</GqlDetailsStyles.LightText>
      </Spacer>
      {!!focusedItem && (
        <GqlScalarTabItem
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          focusedPath={focusedPath}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlScalarTabTable
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
        tabHeader={tabHeader}
        showArgumentsColumnIfNoArgs
      />
    </>
  );
};

export default GqlScalarTab;
