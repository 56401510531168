import Pagination from 'antd/lib/pagination';
import debounce from 'lodash.debounce';
import { Pagination as SoloPaginationClass } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useAddAriaPropsToAntdDropdown } from './Input/SoloDropdown';
import { SoloPaginationStyles as Styles } from './SoloPagination.style';

export const DefaultTablePageSizeOptions = [5, 10, 20, 50];

export const DefaultTablePageSize = DefaultTablePageSizeOptions[1];
export const OverviewTablePageSize = DefaultTablePageSizeOptions[2];

export interface PagingProps {
  onChange: (page: number, pageSize?: number) => void;
  currentPage: number; // The pagination assumes straight numbers, not array indexing. So, starts at 1,2,3, not 0,1,2
  total: number;
  pageSize: number;
}

export function useSoloPaging(defaultPageSize?: number, debounceTime?: number) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize ?? DefaultTablePageSize);
  const [total, setTotal] = useState<number>();

  const updatePaging = (newPage: number, newPageSize?: number) => {
    setCurrentPage(newPage);
    if (newPageSize !== undefined) {
      setPageSize(newPageSize);
    }
  };

  const [pagingData, setPagingData] = useState<PagingProps>({
    currentPage,
    total: total ?? 0,
    pageSize,
    onChange: updatePaging
  });

  // Force current page to stay within bounds of current table data
  useEffect(() => {
    if (total !== undefined && currentPage > Math.ceil(total / pageSize)) {
      setCurrentPage(total === 0 ? 1 : Math.ceil(total / pageSize));
    }
  }, [total, pageSize]);

  useEffect(() => {
    // debounce updating paging data to prevent flickering during short loads
    const debouncedFunc = debounce(() => {
      setPagingData({
        currentPage,
        total: total ?? 0,
        pageSize,
        onChange: updatePaging
      });
    }, debounceTime ?? 300);
    debouncedFunc();
    // if total is defined, then data is loaded and we can activate function right away
    if (total !== undefined) {
      debouncedFunc.flush();
    }

    // cancel any active debounces on cleanup
    return () => {
      debouncedFunc.cancel();
    };
  }, [currentPage, total, pageSize]);

  const apiPaginationObject: SoloPaginationClass = {
    offset: (currentPage - 1) * pageSize,
    limit: pageSize
  };

  return {
    pagingData,
    apiPaginationObject,
    setCurrentPage,
    setPageSize,
    setPagingTotal: setTotal,
    // Returns a hook for updating pagination total
    useSetPagingTotal: (total: number | undefined) => {
      useEffect(() => {
        setTotal(total);
      }, [total]);
    }
  };
}

/**
 * This function is to be used when paginating on a complete data set (instead of filtering being done on api side)
 */
export function useSoloPagingWithDataFiltering<T>(data?: T[] | readonly T[], defaultPageSize?: number) {
  const paging = useSoloPaging(defaultPageSize);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);

  useEffect(() => {
    if (data === undefined) return;
    paging.setPagingTotal(data.length);
    const { pageSize, currentPage } = paging.pagingData;
    const offset = (currentPage - 1) * pageSize;
    setPaginatedData(data.filter((_, idx) => idx >= offset && idx < offset + pageSize));
  }, [data, paging.pagingData]);

  return { ...paging, paginatedData };
}

interface SoloPaginationProps extends PagingProps {
  pageSizeOptions: number[];
  hideExtraTotalDisplay?: boolean;
  small?: boolean;
}
export const SoloPagination = ({
  currentPage,
  onChange,
  total,
  pageSize,
  pageSizeOptions,
  hideExtraTotalDisplay,
  small
}: SoloPaginationProps) => {
  const containerRef = useRef<ElementRef<'div'>>(null);
  useAddAriaPropsToAntdDropdown(containerRef, false);

  return (
    <Styles.PaginationRow data-testid='pagination-row' small={small} ref={containerRef}>
      <Pagination
        current={currentPage}
        onChange={onChange}
        total={total}
        pageSize={pageSize ?? DefaultTablePageSize}
        showTotal={total =>
          hideExtraTotalDisplay ? null : (
            <>
              Total {total} item{total === 1 ? '' : 's'}
            </>
          )
        }
        showSizeChanger={pageSize !== undefined}
        pageSizeOptions={pageSizeOptions.map(String)}
      />
    </Styles.PaginationRow>
  );
};

interface SoloPaginationJustArrowsProps extends Pick<SoloPaginationProps, 'small'> {
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick(): void;
  onNextClick(): void;
  total?: number;
}

/**
 * This is a pagination control that can be used for highly customizable
 * left/right controls. Mostly useful when passing a cursor object and not
 * relying on pages.
 */
export const SoloPaginationJustArrows = ({
  prevDisabled,
  nextDisabled,
  onNextClick,
  onPrevClick,
  total,
  small
}: SoloPaginationJustArrowsProps) => {
  return (
    <Styles.PaginationRow data-testid='pagination-row' small={small} hidePages>
      {total !== undefined && (
        <ul className='ant-pagination'>
          <li className='ant-pagination-total-text'>
            Total {total} item{total === 1 ? '' : 's'}
          </li>
        </ul>
      )}
      <Pagination
        current={prevDisabled ? 1 : 2}
        // eslint-disable-next-line
        onChange={() => { }}
        total={nextDisabled && prevDisabled ? 1 : nextDisabled ? 2 : 3}
        pageSize={1}
        itemRender={(_page, type, _element) => {
          if (type === 'page') {
            return null;
          } else {
            return (
              <button
                className='ant-pagination-item-link'
                disabled={(type === 'prev' && prevDisabled) || (type === 'next' && nextDisabled)}
                onClick={() => {
                  if (type === 'prev') {
                    onPrevClick();
                  } else if (type === 'next') {
                    onNextClick();
                  }
                }}>
                {type === 'next' && (
                  <span role='img' aria-label='right' className='anticon anticon-right'>
                    <svg
                      viewBox='64 64 896 896'
                      focusable='false'
                      data-icon='right'
                      width='1em'
                      height='1em'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path d='M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z'></path>
                    </svg>
                  </span>
                )}
                {type === 'prev' && (
                  <span role='img' aria-label='left' className='anticon anticon-left'>
                    <svg
                      viewBox='64 64 896 896'
                      focusable='false'
                      data-icon='left'
                      width='1em'
                      height='1em'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z'></path>
                    </svg>
                  </span>
                )}
              </button>
            );
          }
        }}
      />
    </Styles.PaginationRow>
  );
};
