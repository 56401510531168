/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/destinations.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { ImportStatus } from "./common_pb";
import { DestinationKind } from "../../../../api/gloo.solo.io/common/v2/selectors_pb";
import { WorkspaceDependencies } from "./common_pb";
import { WorkspaceStatus } from "./common_pb";
import { Pagination } from "./common_pb";
import { Status } from "./common_pb";
import { PolicyType } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.DestinationPolicy
 */
export interface DestinationPolicy {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyType type = 2;
     */
    type: PolicyType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DestinationPolicies
 */
export interface DestinationPolicies {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.DestinationPolicy policies = 1;
     */
    policies: DestinationPolicy[];
    /**
     * @generated from protobuf field: int32 total_policies = 2;
     */
    totalPolicies: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetKubernetesServiceDetailsRequest
 */
export interface GetKubernetesServiceDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination policies_pagination = 2;
     */
    policiesPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetVirtualDestinationDetailsRequest
 */
export interface GetVirtualDestinationDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination destination_services_pagination = 2;
     */
    destinationServicesPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination policies_pagination = 3;
     */
    policiesPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetExternalServiceDetailsRequest
 */
export interface GetExternalServiceDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination external_endpoints_pagination = 2;
     */
    externalEndpointsPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination policies_pagination = 3;
     */
    policiesPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetKubernetesServiceDetailsResponse
 */
export interface GetKubernetesServiceDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceDependencies dependencies = 2;
     */
    dependencies?: WorkspaceDependencies;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.DestinationPolicies policies = 3;
     */
    policies?: DestinationPolicies;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * If non-nil, the service has a backing schema that can be viewed
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef api_doc_ref = 5;
     */
    apiDocRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DestinationService
 */
export interface DestinationService {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef service_ref = 1;
     */
    serviceRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 3;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetVirtualDestinationDetailsResponse
 */
export interface GetVirtualDestinationDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceDependencies dependencies = 2;
     */
    dependencies?: WorkspaceDependencies;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.DestinationService destinations = 3;
     */
    destinations: DestinationService[];
    /**
     * @generated from protobuf field: int32 total_destinations = 4;
     */
    totalDestinations: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.DestinationPolicies policies = 5;
     */
    policies?: DestinationPolicies;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetExternalServiceDetailsResponse
 */
export interface GetExternalServiceDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceDependencies dependencies = 2;
     */
    dependencies?: WorkspaceDependencies;
    /**
     * @generated from protobuf field: repeated string hosts = 3;
     */
    hosts: string[];
    /**
     * @generated from protobuf field: repeated string subject_alt_names = 4;
     */
    subjectAltNames: string[];
    /**
     * @generated from protobuf field: repeated uint32 ports = 5;
     */
    ports: number[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ExternalEndpoint external_endpoints = 6;
     */
    externalEndpoints: ExternalEndpoint[];
    /**
     * @generated from protobuf field: int32 total_external_endpoints = 7;
     */
    totalExternalEndpoints: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.DestinationPolicies policies = 8;
     */
    policies?: DestinationPolicies;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 9;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ExternalEndpoint
 */
export interface ExternalEndpoint {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ExternalEndpoint.Port ports = 2;
     */
    ports: ExternalEndpoint_Port[];
    /**
     * @generated from protobuf field: string locality = 3;
     */
    locality: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ExternalEndpoint.Port
 */
export interface ExternalEndpoint_Port {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: uint32 number = 2;
     */
    number: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetSchemaForKubernetesServiceRequest
 */
export interface GetSchemaForKubernetesServiceRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef service_ref = 1;
     */
    serviceRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetSchemaForKubernetesServiceResponse
 */
export interface GetSchemaForKubernetesServiceResponse {
    /**
     * @generated from protobuf field: string schema = 1;
     */
    schema: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class DestinationPolicy$Type extends MessageType<DestinationPolicy> {
    constructor() {
        super("rpc.gloo.solo.io.DestinationPolicy", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.PolicyType", PolicyType] },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DestinationPolicy
 */
export const DestinationPolicy = new DestinationPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestinationPolicies$Type extends MessageType<DestinationPolicies> {
    constructor() {
        super("rpc.gloo.solo.io.DestinationPolicies", [
            { no: 1, name: "policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationPolicy },
            { no: 2, name: "total_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DestinationPolicies
 */
export const DestinationPolicies = new DestinationPolicies$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetKubernetesServiceDetailsRequest$Type extends MessageType<GetKubernetesServiceDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetKubernetesServiceDetailsRequest", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "policies_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetKubernetesServiceDetailsRequest
 */
export const GetKubernetesServiceDetailsRequest = new GetKubernetesServiceDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVirtualDestinationDetailsRequest$Type extends MessageType<GetVirtualDestinationDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetVirtualDestinationDetailsRequest", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "destination_services_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "policies_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetVirtualDestinationDetailsRequest
 */
export const GetVirtualDestinationDetailsRequest = new GetVirtualDestinationDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetExternalServiceDetailsRequest$Type extends MessageType<GetExternalServiceDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetExternalServiceDetailsRequest", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "external_endpoints_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "policies_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetExternalServiceDetailsRequest
 */
export const GetExternalServiceDetailsRequest = new GetExternalServiceDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetKubernetesServiceDetailsResponse$Type extends MessageType<GetKubernetesServiceDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetKubernetesServiceDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "dependencies", kind: "message", T: () => WorkspaceDependencies },
            { no: 3, name: "policies", kind: "message", T: () => DestinationPolicies },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "api_doc_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetKubernetesServiceDetailsResponse
 */
export const GetKubernetesServiceDetailsResponse = new GetKubernetesServiceDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestinationService$Type extends MessageType<DestinationService> {
    constructor() {
        super("rpc.gloo.solo.io.DestinationService", [
            { no: 1, name: "service_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DestinationService
 */
export const DestinationService = new DestinationService$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVirtualDestinationDetailsResponse$Type extends MessageType<GetVirtualDestinationDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetVirtualDestinationDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "dependencies", kind: "message", T: () => WorkspaceDependencies },
            { no: 3, name: "destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationService },
            { no: 4, name: "total_destinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "policies", kind: "message", T: () => DestinationPolicies },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetVirtualDestinationDetailsResponse
 */
export const GetVirtualDestinationDetailsResponse = new GetVirtualDestinationDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetExternalServiceDetailsResponse$Type extends MessageType<GetExternalServiceDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetExternalServiceDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "dependencies", kind: "message", T: () => WorkspaceDependencies },
            { no: 3, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subject_alt_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ports", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "external_endpoints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ExternalEndpoint },
            { no: 7, name: "total_external_endpoints", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "policies", kind: "message", T: () => DestinationPolicies },
            { no: 9, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetExternalServiceDetailsResponse
 */
export const GetExternalServiceDetailsResponse = new GetExternalServiceDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExternalEndpoint$Type extends MessageType<ExternalEndpoint> {
    constructor() {
        super("rpc.gloo.solo.io.ExternalEndpoint", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ExternalEndpoint_Port },
            { no: 3, name: "locality", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ExternalEndpoint
 */
export const ExternalEndpoint = new ExternalEndpoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExternalEndpoint_Port$Type extends MessageType<ExternalEndpoint_Port> {
    constructor() {
        super("rpc.gloo.solo.io.ExternalEndpoint.Port", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ExternalEndpoint.Port
 */
export const ExternalEndpoint_Port = new ExternalEndpoint_Port$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchemaForKubernetesServiceRequest$Type extends MessageType<GetSchemaForKubernetesServiceRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetSchemaForKubernetesServiceRequest", [
            { no: 1, name: "service_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetSchemaForKubernetesServiceRequest
 */
export const GetSchemaForKubernetesServiceRequest = new GetSchemaForKubernetesServiceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchemaForKubernetesServiceResponse$Type extends MessageType<GetSchemaForKubernetesServiceResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetSchemaForKubernetesServiceResponse", [
            { no: 1, name: "schema", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetSchemaForKubernetesServiceResponse
 */
export const GetSchemaForKubernetesServiceResponse = new GetSchemaForKubernetesServiceResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.DestinationsApi
 */
export const DestinationsApi = new ServiceType("rpc.gloo.solo.io.DestinationsApi", [
    { name: "GetKubernetesServiceDetails", options: {}, I: GetKubernetesServiceDetailsRequest, O: GetKubernetesServiceDetailsResponse },
    { name: "GetVirtualDestinationDetails", options: {}, I: GetVirtualDestinationDetailsRequest, O: GetVirtualDestinationDetailsResponse },
    { name: "GetExternalServiceDetails", options: {}, I: GetExternalServiceDetailsRequest, O: GetExternalServiceDetailsResponse },
    { name: "GetSchemaForKubernetesService", options: {}, I: GetSchemaForKubernetesServiceRequest, O: GetSchemaForKubernetesServiceResponse }
]);
