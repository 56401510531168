import { Pagination } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetRouteTableDetailsRequest,
  GetRouteTableSchemaRequest,
  GetVirtualGatewayDetailsRequest
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routing_pb';
import { RoutingApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routing_pb.client';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import { grpcWebFetchTransport, soloGrpcCall, useRequestSkipRef } from './helpers';

export namespace routingApi {
  const client = new RoutingApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.RoutingApi/' + capitalizeFirstLetters(fn.name);

  //
  // Get Virtual Gateway Details
  //
  export async function getVirtualGatewayDetails(gatewayRef?: ClusterObjectRef) {
    let request: GetVirtualGatewayDetailsRequest = { gatewayRef };
    return soloGrpcCall(client.getVirtualGatewayDetails(request));
  }
  export function useGetVirtualGatewayDetails(...args: Parameters<typeof getVirtualGatewayDetails>) {
    return useRequestSkipRef(
      getVirtualGatewayDetails,
      args,
      getMethodDescriptorName(client.getVirtualGatewayDetails)
    );
  }

  //
  // Get Route Table Details
  //
  export async function getRouteTableDetails(routeTableRef?: ClusterObjectRef, routesPagination?: Pagination) {
    let request: GetRouteTableDetailsRequest = { routeTableRef, routesPagination };
    return soloGrpcCall(client.getRouteTableDetails(request));
  }
  export function useGetRouteTableDetails(...args: Parameters<typeof getRouteTableDetails>) {
    return useRequestSkipRef(
      getRouteTableDetails,
      args,
      getMethodDescriptorName(client.getRouteTableDetails)
    );
  }

  //
  // Get Route Table Schema
  //
  export async function getRouteTableSchema(routeTableRef?: ClusterObjectRef) {
    let request: GetRouteTableSchemaRequest = { routeTableRef };
    return soloGrpcCall(client.getRouteTableSchema(request));
  }
  export function useGetRouteTableSchema(...args: Parameters<typeof getRouteTableSchema>) {
    return useRequestSkipRef(getRouteTableSchema, args, getMethodDescriptorName(client.getRouteTableSchema));
  }
}
