/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { InsightsApi } from "./insight_pb";
import type { GetInsightResolutionStepsResponse } from "./insight_pb";
import type { GetInsightResolutionStepsRequest } from "./insight_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListInsightsResponse } from "./insight_pb";
import type { ListInsightsRequest } from "./insight_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * InsightsApi is the interface for the InsightsApi
 *
 * @generated from protobuf service rpc.gloo.solo.io.InsightsApi
 */
export interface IInsightsApiClient {
    /**
     * @generated from protobuf rpc: ListInsights(rpc.gloo.solo.io.ListInsightsRequest) returns (rpc.gloo.solo.io.ListInsightsResponse);
     */
    listInsights(input: ListInsightsRequest, options?: RpcOptions): UnaryCall<ListInsightsRequest, ListInsightsResponse>;
    /**
     * @generated from protobuf rpc: GetInsightResolutionSteps(rpc.gloo.solo.io.GetInsightResolutionStepsRequest) returns (rpc.gloo.solo.io.GetInsightResolutionStepsResponse);
     */
    getInsightResolutionSteps(input: GetInsightResolutionStepsRequest, options?: RpcOptions): UnaryCall<GetInsightResolutionStepsRequest, GetInsightResolutionStepsResponse>;
}
/**
 * InsightsApi is the interface for the InsightsApi
 *
 * @generated from protobuf service rpc.gloo.solo.io.InsightsApi
 */
export class InsightsApiClient implements IInsightsApiClient, ServiceInfo {
    typeName = InsightsApi.typeName;
    methods = InsightsApi.methods;
    options = InsightsApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListInsights(rpc.gloo.solo.io.ListInsightsRequest) returns (rpc.gloo.solo.io.ListInsightsResponse);
     */
    listInsights(input: ListInsightsRequest, options?: RpcOptions): UnaryCall<ListInsightsRequest, ListInsightsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListInsightsRequest, ListInsightsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInsightResolutionSteps(rpc.gloo.solo.io.GetInsightResolutionStepsRequest) returns (rpc.gloo.solo.io.GetInsightResolutionStepsResponse);
     */
    getInsightResolutionSteps(input: GetInsightResolutionStepsRequest, options?: RpcOptions): UnaryCall<GetInsightResolutionStepsRequest, GetInsightResolutionStepsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInsightResolutionStepsRequest, GetInsightResolutionStepsResponse>("unary", this._transport, method, opt, input);
    }
}
