import cytoscape from 'cytoscape';
import { arrowScale } from 'Styles/graph';
import { EdgeTypeAnimationProps } from '../General/graph-selection-utils';
import { CyCanvasData, drawShape } from './canvas-utils';

interface EdgePrivateRscratch {
  allpts: number[];
  tgtArrowAngle: number;
  srcArrowAngle: number;
  edgeType: 'straight' | 'segments' | 'haystack' | 'bezier' | 'self' | 'compound' | 'multibezier';
}

const rpsBucketValues = [2, 4, 8, 16];

// Points for the cytoscape `triangle` arrow type - https://github.com/cytoscape/cytoscape.js/blob/e6541de603f5714f572238573ea06ef17503282b/src/extensions/renderer/base/arrow-shapes.js#L131
// I'm not exactly sure how cytoscape converts thier shape code to thier default size,
// but 29x scale seem to match it exactly, so that value is hardcoded
const arrowPoints = [-0.15, -0.3, 0, 0, 0.15, -0.3].map(n => n * 29 * arrowScale);

export function renderEdgeAnimation(dt: number, cy: cytoscape.Core, { layer, ctx }: CyCanvasData) {
  layer.resetTransform(ctx);
  layer.clear(ctx);
  layer.setTransform(ctx);

  // [ALL_EDGES] Save default values so we don't overwrite them
  ctx.save();

  ctx.lineWidth = 3; // how wide (not long) the dots are
  ctx.lineCap = 'butt';

  cy.edges().forEach(edge => {
    if (
      !edge.hasClass('animated') ||
      edge.effectiveOpacity() === 0 ||
      (!!edge.targets()[0] && edge.targets()[0].data('idleHidden') !== 'show')
    ) {
      return;
    }

    const start = edge.sourceEndpoint(),
      end = edge.targetEndpoint();
    const { latency = false, rpsBucket = 0 } = edge.data('httpEdgeAnimation') as EdgeTypeAnimationProps;

    // save styles set before this specific edge
    ctx.save();

    let lineDashOffset = 0,
      gap = 3;

    // Animate moving pulse if latency exists / latency isn't super slow
    // rps bucket of 0 means there's 0 rps
    if (latency !== false && latency < 3000 && rpsBucket > 0) {
      // Move the pulse
      const prevDist = edge.data('anim-ant-distance') ?? 0;
      // percentage-based speed using latency between 0ms to 1000ms
      const MAX_SPEED = 7,
        MIN_SPEED = 3;
      const speed = -(MAX_SPEED - Math.min(latency / 1000, 1) * (MAX_SPEED - MIN_SPEED));
      const newDist = prevDist + dt * speed;
      edge.data('anim-ant-distance', newDist);

      lineDashOffset = newDist;
      gap = 1 + rpsBucketValues[rpsBucket - 1];
    }

    // To avoid re-calculating cytoscape value for no reason, we're accessing
    // it's private values for the edge
    // https://github.com/cytoscape/cytoscape.js/blob/054750d9326e9e17ea73d42651c8461325458887/src/extensions/renderer/canvas/drawing-edges.js
    const {
      allpts: pts,
      edgeType,
      ...rscratch
      // @ts-ignore
    } = edge._private.rscratch as EdgePrivateRscratch;

    // Some times rscratch is baren due to a node being messed up in the hierarchy.
    if (!!pts) {
      ctx.beginPath();
      ctx.lineDashOffset = lineDashOffset;
      ctx.strokeStyle = edge.style('line-color');
      ctx.setLineDash([6, gap]); // length, gap
      ctx.globalAlpha = edge.effectiveOpacity();
      // We use `pts` taken from cytoscape code here to only draw the line up to the middle of the arrow
      // to avoid drawing the line past the tip of the arrow
      ctx.moveTo(pts[0], pts[1]);
      // Draw logic taken from: https://github.com/cytoscape/cytoscape.js/blob/054750d9326e9e17ea73d42651c8461325458887/src/extensions/renderer/canvas/drawing-edges.js#L198
      switch (edgeType) {
        case 'bezier':
        case 'self':
        case 'compound':
        case 'multibezier':
          for (let i = 2; i + 3 < pts.length; i += 4) {
            ctx.quadraticCurveTo(pts[i], pts[i + 1], pts[i + 2], pts[i + 3]);
          }
          break;

        case 'straight':
        case 'segments':
        case 'haystack':
          for (let i = 2; i + 1 < pts.length; i += 2) {
            ctx.lineTo(pts[i], pts[i + 1]);
          }
          break;
      }
      ctx.stroke();
      ctx.closePath();

      // Draw arrows
      const { tgtArrowAngle, srcArrowAngle } = rscratch;
      ctx.fillStyle = edge.style('target-arrow-color');
      drawEdgeArrowhead(ctx, edge, 'target', end.x, end.y, tgtArrowAngle);
      ctx.fillStyle = edge.style('source-arrow-color');
      drawEdgeArrowhead(ctx, edge, 'source', start.x, start.y, srcArrowAngle);
    }

    ctx.restore(); // restore ctx to previous `save()`
  });

  // [/ALL_EDGES] restore ctx to previous `save()`
  ctx.restore();
}

////////////////////////////////
// Canvas Helper Functions
////////////////////////////////

function drawEdgeArrowhead(
  ctx: CanvasRenderingContext2D,
  edge: cytoscape.EdgeSingular,
  prefix: 'target' | 'source',
  x: number,
  y: number,
  angle: number
) {
  if (isNaN(angle) || angle == null) {
    return;
  }
  if (edge.style(prefix + '-arrow-shape') === 'none') {
    return;
  }

  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(angle);
  drawShape(ctx, arrowPoints);
  ctx.fill();
  ctx.restore();
}
