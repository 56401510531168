import { overviewApi } from 'Api/overview';
import { GetDashboardCardsResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import React, { createContext } from 'react';
import { di } from 'react-magnetic-di';
import { useGetGraphCache } from '../Graph/General/graph-cache';
const { useGetDashboardCards } = overviewApi;

//
// TYPES
//
interface IDashboardContext {
  dashboardCardsData: GetDashboardCardsResponse | undefined;
  dashboardCardsError: any | undefined;
}

//
// CONTEXT
//
export const DashboardContext = createContext({} as IDashboardContext);

//
// PROVIDER
//
export const DashboardProvider = ({ children }: { children: React.ReactNode }) => {
  di(useGetDashboardCards, useGetGraphCache);
  const { cache } = useGetGraphCache();
  const { data: dashboardCardsData, error: dashboardCardsError } = useGetDashboardCards(cache.timeInterval);
  return (
    <DashboardContext.Provider
      value={{
        dashboardCardsData:
          dashboardCardsData ??
          // If api returns an actual error, treat it like an empty response on the page
          (dashboardCardsError ? { apiserverErrors: [] } : undefined),
        dashboardCardsError
      }}>
      {children}
    </DashboardContext.Provider>
  );
};
