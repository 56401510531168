import { DashboardLanding } from 'Components/Features/Dashboard/DashboardLanding';
import { OverviewLanding } from 'Components/Features/Overview/OverviewLanding';
import { di } from 'react-magnetic-di';
import { Permission, usePermissions } from 'utils/permissions';

export const RootLanding = () => {
  di(usePermissions);
  const { hasPerm } = usePermissions();
  return (hasPerm(Permission.PlatformLicense) && !hasPerm(Permission.CoreLicense)) ||
    !hasPerm(Permission.InsightsEnabled) ? (
    <OverviewLanding />
  ) : (
    <DashboardLanding />
  );
};
