import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { CertExpiryStyles } from '../CertificatesExpiryCard.style';

const CertExpiryVizualizationEmpty = ({ title, type }: { title: string; type: string }) => {
  //
  // Render
  //
  return (
    <CertExpiryStyles.CertExpirySubSection data-testid={`${type}-certs-vizualization-empty`}>
      <CertExpiryStyles.CertExpiryTitleText>{title}</CertExpiryStyles.CertExpiryTitleText>
      <FlexLayout justifyContent='space-between'>
        <CertExpiryStyles.RootCertLineInfoText>No {type} certificates found.</CertExpiryStyles.RootCertLineInfoText>
      </FlexLayout>
      <CertExpiryStyles.RootCertExpiryVisualizationLine percentFull={0} />
    </CertExpiryStyles.CertExpirySubSection>
  );
};

export default CertExpiryVizualizationEmpty;
