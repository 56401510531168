import { graphApi } from 'Api/graphs';
import { Asset } from 'assets';
import { ErrorBoundaryBasic } from 'Components/Common/ErrorBoundary';
import { Loading } from 'Components/Common/Loading';
import { SoloModal } from 'Components/Common/SoloModal';
import {
  HttpMetrics,
  NodeMetrics,
  NodeType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useGraphFetchSettingsContext } from '../../Context/GraphFetchSettingsContext';
import {
  findWorstHealth,
  getEdgeMetricsType,
  getEdgeStatus,
  getNodeMetricsType,
  getNodeStatus,
  isCiliumMetricsDefined,
  isHttpMetricsDefined,
  isTcpMetricsDefined
} from '../get-graph-items-data';
import { GraphMetricsType, GraphStatus } from '../graph-selection-utils';
import { CiliumSection } from './CiliumSection';
import {
  EnforcedByInfoListBlock,
  getMetricsListLineData,
  getNodeIcon,
  httpMetricsListToCounts,
  isTimestampsListOverHourLong
} from './DetailsTab';
import { DetailsTabStyles } from './DetailsTab.style';
import { LatencySection } from './LatencySection';
import { NodeDetailsStyles } from './NodeDetails.style';
import { TcpSection } from './TcpSection';
import { TimelineGraph } from './TimelineGraph';

const { useGetWorkloadMetrics } = graphApi;

enum EdgeDir {
  In = 0,
  Both = 1,
  Out = 2
}
interface ConnectedNodeData {
  key: string;
  id: string;
  displayName: string;
  nodeType?: NodeType;
  edgeHealth: GraphStatus;
  edgeMetricsType: GraphMetricsType;
  dir: EdgeDir;
}

function parseHttpTimelineGraphData(metricsIn: HttpMetrics[], metricsOut: HttpMetrics[]) {
  const multipleHours =
    metricsIn.some(metric => isTimestampsListOverHourLong(metric.timestamps)) ||
    metricsOut.some(metric => isTimestampsListOverHourLong(metric.timestamps));

  const lineDataIn = getMetricsListLineData(metricsIn, [{ id: 'in', key: 'requestCount' }], multipleHours);
  const lineDataOut = getMetricsListLineData(metricsOut, [{ id: 'out', key: 'requestCount' }], multipleHours);

  const dataset = [...lineDataIn.dataset, ...lineDataOut.dataset];
  const graphDataExists = !!dataset.length && Math.max(lineDataIn.maxY, lineDataOut.maxY) > 0;
  return { dataset, graphDataExists };
}

export function prettierNumbersBySpace(originalNumber: number): string {
  if (originalNumber >= 10000000) {
    return Math.round(originalNumber / 1000000) + 'M';
  } else if (originalNumber >= 100000) {
    return Math.round(originalNumber / 1000) + 'k';
  } else if (originalNumber > 1000) {
    return originalNumber.toFixed(0);
  } else {
    return originalNumber.toFixed(2);
  }
}

export type NodeDetailsProps = {
  details: NodeMetrics;
};
export const NodeDetails = ({ details }: NodeDetailsProps) => {
  di(useGetWorkloadMetrics);
  const [workloadBeingExamined, setWorkloadBeingExamined] = useState<string>();
  const [connectedNodes, setConnectedNodes] = useState<ConnectedNodeData[]>();

  const { fetchSettings } = useGraphFetchSettingsContext();

  const { data: workloadMetrics } = useGetWorkloadMetrics(
    details?.workload?.id,
    fetchSettings.endTime,
    fetchSettings.window,
    fetchSettings.step,
    fetchSettings.istioMetrics,
    fetchSettings.ciliumMetrics,
    fetchSettings.tcpMetrics
  );

  useEffect(() => {
    if (workloadMetrics) {
      const nodes = [
        ...workloadMetrics.outgoingEdgeMetrics.map(edge => ({
          workload: edge.targetWorkload,
          dir: EdgeDir.Out,
          edge
        })),
        ...workloadMetrics.incomingEdgeMetrics.map(edge => ({
          workload: edge.sourceWorkload,
          dir: EdgeDir.In,
          edge
        }))
      ].reduce<Record<string, ConnectedNodeData>>((dict, { workload, edge, dir }) => {
        if (workload) {
          if (dict[workload.id]) {
            if (dict[workload?.id].dir !== dir) dict[workload.id].dir = EdgeDir.Both;
          } else {
            const edgeMetricsType = getEdgeMetricsType(edge);
            dict[workload.id] = {
              key: workload?.id,
              dir: edgeMetricsType === GraphMetricsType.Tcp ? EdgeDir.Both : dir,
              id: workload?.id ?? '',
              displayName: workload?.displayName ?? '',
              nodeType: workload?.nodeType,
              edgeHealth: getEdgeStatus(edge),
              edgeMetricsType
            };
          }
        }
        return dict;
      }, {});
      setConnectedNodes(
        Object.values(nodes)
          .sort((e1, e2) => e1.id.localeCompare(e2.id))
          .sort((e1, e2) => e2.dir - e1.dir)
      );
    } else {
      setConnectedNodes(undefined);
    }
  }, [workloadMetrics]);

  const metricsType = getNodeMetricsType(details);

  const incomingEdgeMetrics = workloadMetrics?.incomingEdgeMetrics,
    outgoingEdgeMetrics = workloadMetrics?.outgoingEdgeMetrics;

  const incomingMetrics = incomingEdgeMetrics?.map(n => n.httpMetrics).filter(isHttpMetricsDefined) ?? [];
  const outgoingMetrics = outgoingEdgeMetrics?.map(n => n.httpMetrics).filter(isHttpMetricsDefined) ?? [];
  const httpMetricsExist = incomingMetrics.length + outgoingMetrics.length > 0;

  const ciliumMetricsIn = incomingEdgeMetrics?.map(n => n.ciliumMetrics).filter(isCiliumMetricsDefined) ?? [];
  const ciliumMetricsOut = outgoingEdgeMetrics?.map(n => n.ciliumMetrics).filter(isCiliumMetricsDefined) ?? [];
  const ciliumMetricsExist = ciliumMetricsIn.length + ciliumMetricsOut.length > 0;

  const inCounts = httpMetricsListToCounts(incomingMetrics);
  const outCounts = httpMetricsListToCounts(outgoingMetrics);

  const startingTime = Math.min(inCounts.startingTime, outCounts.startingTime);
  const endingTime = Math.max(inCounts.endingTime, outCounts.endingTime);
  const totalTime = endingTime - startingTime || 1;

  // Default to showing status with incoming edges unless there are none, in which case fall back to outgoing
  let healthEdges = outgoingEdgeMetrics ?? [];
  if (healthEdges.length === 0) {
    healthEdges = incomingEdgeMetrics ?? [];
  }
  // if truly no edges connected to this node, then just use this node's health
  const status = findWorstHealth(healthEdges.length > 0 ? healthEdges.map(getEdgeStatus) : [getNodeStatus(details)]);

  const { dataset, graphDataExists } = parseHttpTimelineGraphData(incomingMetrics, outgoingMetrics);

  if (!workloadMetrics) {
    return <Loading message='Loading node...' />;
  }

  const nodeTitle = details?.workload?.displayName ?? '';

  return (
    <>
      <NodeDetailsStyles.TitleRow data-testid={`${nodeTitle}-title-row`}>
        <NodeDetailsStyles.NodeTitle
          data-testid='node-title'
          onClick={() => {
            // domNavigate(`/workspace/${details?.workload?.id.split('.')[0]}/${details?.workload?.id.split('.')[1]}/`);
          }}
          title={nodeTitle}>
          <NodeDetailsStyles.NodeTitleIconHolder>
            {getNodeIcon(details?.workload?.nodeType)}
          </NodeDetailsStyles.NodeTitleIconHolder>
          <NodeDetailsStyles.TruncateText>
            {nodeTitle}
            {/* <ModalPopIconStyled /> */}
          </NodeDetailsStyles.TruncateText>
        </NodeDetailsStyles.NodeTitle>
        <NodeDetailsStyles.NodeTitleStatusHolder data-testid='node-status'>
          {status.toLowerCase()}
          <DetailsTabStyles.GraphStatusIndicator metricsType={metricsType} health={status} />
        </NodeDetailsStyles.NodeTitleStatusHolder>

        {connectedNodes?.map(node => (
          <React.Fragment key={node.id}>
            {node.dir === EdgeDir.Both ? (
              <NodeDetailsStyles.EdgeArrowHolder status={node.edgeHealth} metricsType={node.edgeMetricsType}>
                <Asset.NodeBiDirection data-testid='node-twoway-direction' />
              </NodeDetailsStyles.EdgeArrowHolder>
            ) : (
              <NodeDetailsStyles.EdgeArrowHolder
                flip={node.dir === EdgeDir.Out}
                status={node.edgeHealth}
                metricsType={node.edgeMetricsType}>
                <Asset.NodeSingleDirection data-testid='node-single-direction' />
              </NodeDetailsStyles.EdgeArrowHolder>
            )}
            <NodeDetailsStyles.NodeTitle
              title={node.displayName}
              onClick={() => {
                // setWorkloadBeingExamined(node.id)
              }}
              data-testid='connected-node-title'>
              <NodeDetailsStyles.NodeTitleIconHolder>
                {getNodeIcon(node.nodeType)}
              </NodeDetailsStyles.NodeTitleIconHolder>
              <NodeDetailsStyles.TruncateText>{node.displayName}</NodeDetailsStyles.TruncateText>
            </NodeDetailsStyles.NodeTitle>
            <NodeDetailsStyles.NodeTitleStatusHolder></NodeDetailsStyles.NodeTitleStatusHolder>
          </React.Fragment>
        ))}
      </NodeDetailsStyles.TitleRow>

      {!!details.workload &&
      (details.workload.vpc.length ||
        details.workload.cluster.length ||
        details.workload.subnet.length ||
        details.workload.namespace.length ||
        details.workload.instance.length ||
        details.workload.platform.length) ? (
        <DetailsTabStyles.InfoListBlock>
          {details.workload.instance.length ? (
            <>
              <div>Instance:</div>
              <div data-testid='node-instance-title'>{details.workload.instance}</div>
            </>
          ) : null}
          {details.workload.platform.length ? (
            <>
              <div>Platform:</div>
              <div data-testid='node-platform-title'>{details.workload.platform}</div>
            </>
          ) : null}
          {details.workload.vpc.length ? (
            <>
              <div>VPC:</div>
              <div data-testid='node-vpc-title'>{details.workload.vpc}</div>
            </>
          ) : null}
          {details.workload.subnet.length ? (
            <>
              <div>Subnet:</div>
              <div data-testid='node-subnet-title'>{details.workload.subnet}</div>
            </>
          ) : null}
          {details.workload.cluster.length ? (
            <>
              <div>Cluster:</div>
              <div data-testid='node-cluster-title'>{details.workload.cluster}</div>
            </>
          ) : null}
          {details.workload.namespace.length ? (
            <>
              <div>Namespace:</div>
              <div data-testid='node-namespace-title'>{details.workload.namespace}</div>
            </>
          ) : null}
        </DetailsTabStyles.InfoListBlock>
      ) : null}

      <EnforcedByInfoListBlock metricsType={metricsType} />

      {httpMetricsExist && (
        <>
          <DetailsTabStyles.SectionTitle>Traffic</DetailsTabStyles.SectionTitle>
          <DetailsTabStyles.SubsectionTitle>HTTP (requests per second)</DetailsTabStyles.SubsectionTitle>
          <DetailsTabStyles.GridTable columns={['18%', '24%', '1fr', '27%']} data-testid='node-details-traffic-table'>
            {/*Top row */}
            <DetailsTabStyles.GridTH></DetailsTabStyles.GridTH>
            <DetailsTabStyles.GridTH>RPS</DetailsTabStyles.GridTH>
            <DetailsTabStyles.GridTH>% Success</DetailsTabStyles.GridTH>
            <DetailsTabStyles.GridTH>% Error</DetailsTabStyles.GridTH>
            {/* INs */}
            <DetailsTabStyles.GridLabel>In</DetailsTabStyles.GridLabel>
            <div title={inCounts.requestCount + ''}>{prettierNumbersBySpace(inCounts.requestCount)}</div>
            <div>
              {!!inCounts.successCount
                ? prettierNumbersBySpace(Math.round((inCounts.successCount / inCounts.requestCount) * 100))
                : '--'}
            </div>
            <div>
              {!!inCounts.failureCount
                ? prettierNumbersBySpace(Math.round((inCounts.failureCount / inCounts.requestCount) * 100))
                : '--'}
            </div>
            {/* OUTs */}
            <DetailsTabStyles.GridLabel>Out</DetailsTabStyles.GridLabel>
            <div title={outCounts.requestCount + ''}>{prettierNumbersBySpace(outCounts.requestCount)}</div>
            <div>
              {!!outCounts.successCount
                ? prettierNumbersBySpace(Math.round((outCounts.successCount / outCounts.requestCount) * 100))
                : '--'}
            </div>
            <div>
              {!!outCounts.failureCount
                ? prettierNumbersBySpace(Math.round((outCounts.failureCount / outCounts.requestCount) * 100))
                : '--'}
            </div>
          </DetailsTabStyles.GridTable>

          {graphDataExists && (
            <ErrorBoundaryBasic thereWasAnErrorWith='HTTP request traffic section'>
              <DetailsTabStyles.SectionHorizontalRule />
              <DetailsTabStyles.SubsectionTitle data-testid='node-details-traffic-graph'>
                HTTP - Request Traffic ({(totalTime / 60).toFixed(0)}min)
              </DetailsTabStyles.SubsectionTitle>
              <DetailsTabStyles.SubsectionDetails>
                RPS: {prettierNumbersBySpace(outCounts.rps + inCounts.rps)}, % Error:{' '}
                {(
                  (outCounts.failureCount + inCounts.failureCount) /
                  (outCounts.requestCount + inCounts.requestCount)
                ).toFixed(2)}
              </DetailsTabStyles.SubsectionDetails>
              <TimelineGraph
                dataset={dataset}
                fixedHeight={'175px'}
                legendData={[
                  { name: 'Req(in)', symbol: { type: 'dash' } },
                  { name: 'Req(out)', symbol: { type: 'dash' } }
                ]}
                yValueMeaning='Reqs'
              />
            </ErrorBoundaryBasic>
          )}

          {!!details?.incomingMetrics?.requestLatencies?.p99.length && (
            <ErrorBoundaryBasic thereWasAnErrorWith='latency section'>
              <LatencySection requestLatencies={details.incomingMetrics.requestLatencies} />
            </ErrorBoundaryBasic>
          )}
        </>
      )}

      {ciliumMetricsExist && (
        <>
          <DetailsTabStyles.SectionHorizontalRule />
          <ErrorBoundaryBasic thereWasAnErrorWith='cilium section'>
            <CiliumSection metricsIn={ciliumMetricsIn} metricsOut={ciliumMetricsOut} />
          </ErrorBoundaryBasic>
        </>
      )}

      {isTcpMetricsDefined(details.tcpMetrics) && (
        <>
          <DetailsTabStyles.SectionHorizontalRule />
          <ErrorBoundaryBasic thereWasAnErrorWith='TCP section'>
            <TcpSection metric={details.tcpMetrics} />
          </ErrorBoundaryBasic>
        </>
      )}

      {!!workloadBeingExamined && (
        <SoloModal visible={true} width={1110} onClose={() => setWorkloadBeingExamined(undefined)}>
          {'TODO' /*<WorkloadDetails workloadRef={{ name: '', namespace: '' }} />*/}
        </SoloModal>
      )}
    </>
  );
};
