export const UIFEATUREFLAG_SEARCHHIGHLIGHTING = 'SearchHighlightingFlag';
export const UIFEATUREFLAG_NEWLAYOUTALGORITHM = 'NewLayoutAlgorithmFlag';

export type UIOnlyFeatureFlags = typeof UIFEATUREFLAG_SEARCHHIGHLIGHTING | typeof UIFEATUREFLAG_NEWLAYOUTALGORITHM;

export async function setUIOnlyFeatureFlagOverride(flag: UIOnlyFeatureFlags, state: boolean | 'unset') {
  if (state === 'unset') {
    window.localStorage.removeItem(flag);
  } else {
    window.localStorage.setItem(flag, state ? 'true' : 'false');
  }
}

export function isUIOnlyFeatureFlagOn(flag: UIOnlyFeatureFlags) {
  return window.localStorage.getItem(flag) === 'true';
}
