import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { ConstDirectiveNode } from 'graphql';
import { GqlDescriptionInfoTooltip } from '../GqlDescriptionInfoTooltip';
import NameAndReturnType from '../NameAndReturnType';

const GqlTabItemDirectiveList = ({
  node,
  extendedDirectives,
  onTypeClick
}: {
  node: undefined | { directives?: ReadonlyArray<ConstDirectiveNode> };
  extendedDirectives?: ReadonlyArray<ConstDirectiveNode>;
  onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
}) => {
  //
  // Render
  //
  return (
    <>
      {!!node?.directives?.length && (
        <Spacer pt={1} pb={2} display='block'>
          {node.directives?.map((d: any, i: number) => (
            <Spacer key={d.name?.value ?? i} py={0.5}>
              <NameAndReturnType node={d} onTypeClick={t => onTypeClick(t, true)} />
            </Spacer>
          ))}
        </Spacer>
      )}
      {!!extendedDirectives?.length && (
        <Spacer pt={1} pb={2} display='block'>
          {extendedDirectives.map((d: any, i: number) => (
            <Spacer key={d.name?.value ?? i} py={0.5}>
              <FlexLayout gap={1}>
                <GqlDescriptionInfoTooltip description={'This type was extended to have this directive.'} />
                <NameAndReturnType node={d} onTypeClick={t => onTypeClick(t, true)} />
              </FlexLayout>
            </Spacer>
          ))}
        </Spacer>
      )}
    </>
  );
};

export default GqlTabItemDirectiveList;
