import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { CardStyles } from 'Components/Common/Card';
import { Svg } from 'Components/Common/Svg';
import { CardFolderTab, CardFolderTabListWithBottomBorder } from 'Components/Common/Tabs';
import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { GetClusterDetailsCardsResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useInsightEnablementChecks } from 'utils/dashboard/dashboard-hooks';
import ClusterCiliumSummaryTabContent from './Cilium/ClusterCiliumSummaryTabContent';
import { ClusterGlooSummaryTabContent } from './Gloo/ClusterGlooSummaryTabContent';
import { ClusterIstioSummaryTabContent } from './Istio/ClusterIstioSummaryTabContent';

type TabDataItem = {
  key: string;
  title: JSX.Element;
  Component: (props: { data: GetClusterDetailsCardsResponse; cluster: string }) => JSX.Element;
};

export const ClusterIstioAndCiliumSummaryCard = ({
  data,
  cluster
}: {
  data: GetClusterDetailsCardsResponse;
  cluster: string;
}) => {
  di(useInsightEnablementChecks);
  const [tabIndex, setTabIndex] = useState(0);
  const { isCiliumEnabled, isIstioEnabledAndLicenced } = useInsightEnablementChecks({ clusters: [cluster] });

  const tabData = useMemo(() => {
    const istioTab = {
      key: 'istio-summary',
      title: (
        <Spacer my={-1}>
          <DashboardStyles.Header>
            <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.IstioLogo />} />
            Istio
          </DashboardStyles.Header>
        </Spacer>
      ),
      Component: ClusterIstioSummaryTabContent
    };
    const ciliumTab = {
      key: 'cilium-summary',
      title: (
        <Spacer my={-1}>
          <DashboardStyles.Header>
            <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.CiliumIcon />} />
            Cilium
          </DashboardStyles.Header>
        </Spacer>
      ),
      Component: ClusterCiliumSummaryTabContent
    };
    const glooTab = {
      key: 'gloo-summary',
      title: (
        <Spacer my={-1}>
          <DashboardStyles.Header>
            <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.GlooOutlineIcon />} />
            Gloo
          </DashboardStyles.Header>
        </Spacer>
      ),
      Component: ClusterGlooSummaryTabContent
    };
    const tabs: TabDataItem[] = [];
    if (isIstioEnabledAndLicenced) {
      tabs.push(istioTab);
    }
    if (isCiliumEnabled) {
      tabs.push(ciliumTab);
    }
    // Always show gloo tab
    tabs.push(glooTab);
    return tabs;
  }, [isCiliumEnabled, isIstioEnabledAndLicenced]);

  return (
    <CardStyles.Card data-testid='istio-cilium-summary-card'>
      <Tabs id='multiYamlTabs' index={tabIndex} onChange={setTabIndex}>
        <CardFolderTabListWithBottomBorder margin='0px'>
          {tabData.map(({ title, key }) => (
            <CardFolderTab key={key} width='auto' data-testid={`tab-${key}`}>
              {title}
            </CardFolderTab>
          ))}
        </CardFolderTabListWithBottomBorder>
        <TabPanels>
          {tabData.map(({ Component, key }) => (
            <TabPanel key={key}>
              <Component data={data} cluster={cluster} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </CardStyles.Card>
  );
};
