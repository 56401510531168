import { Loading } from 'Components/Common/Loading';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from './Cards/Health/insight-codes';
import { DashboardProvider } from './DashboardContext';
import { DashboardStyles } from './DashboardLanding.style';
import { DashboardLandingEmpty } from './DashboardLandingEmpty';
import { DashboardLandingWithData } from './DashboardLandingWithData';
import { DashboardUtilsKey, dashboardUtilsCodesMap } from './insight-codes';

export const DashboardLandingInner = () => {
  di(useFilteredDashboardInsights);
  //
  // Check for gloo agent insights to see if there is any data.
  //
  const glooAgentInsights = useFilteredDashboardInsights(dashboardHealthCodesMap, DashboardHealthKey.glooAgent);
  const insightsEngineRunningInsights = useFilteredDashboardInsights(
    dashboardUtilsCodesMap,
    DashboardUtilsKey.insightsEngineIsRunning
  );

  const isInsightsEngineRunning = !!insightsEngineRunningInsights?.length;
  const isLoading = glooAgentInsights === undefined || insightsEngineRunningInsights === undefined;
  let hasNoData = !glooAgentInsights?.length || !isInsightsEngineRunning;

  //
  // Render
  //
  if (isLoading) {
    return (
      <DashboardStyles.LandingContainer data-testid='dashboard-landing'>
        <Loading center />
      </DashboardStyles.LandingContainer>
    );
  } else if (hasNoData) {
    return <DashboardLandingEmpty isInsightsEngineRunning={isInsightsEngineRunning} />;
  } else {
    return <DashboardLandingWithData />;
  }
};

export const DashboardLanding = () => {
  return (
    <DashboardProvider>
      <DashboardLandingInner />
    </DashboardProvider>
  );
};
