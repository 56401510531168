import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { OptionType, SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { DefaultTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListWorkspacesRequest_WorkspaceSortOrder } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useNavigate } from 'react-router-dom';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { buildWorkspaceDetailsUrl } from 'utils/url-builders';
import { WorkspacesLandingStyles as Styles } from './WorkspacesLanding.style';
import { WorkspacesLandingBody } from './WorkspacesLandingBody';
import { WorkspacesLandingEmpty } from './WorkspacesLandingEmpty';

const { useListWorkspaces } = overviewApi;

const sortOrderOptions: OptionType<ListWorkspacesRequest_WorkspaceSortOrder>[] = [
  {
    value: ListWorkspacesRequest_WorkspaceSortOrder.NAME,
    displayValue: 'Name'
  },
  {
    value: ListWorkspacesRequest_WorkspaceSortOrder.CLUSTERS,
    displayValue: 'Cluster'
  },
  {
    value: ListWorkspacesRequest_WorkspaceSortOrder.NAMESPACES,
    displayValue: 'Namespace'
  },
  {
    value: ListWorkspacesRequest_WorkspaceSortOrder.SERVICES,
    displayValue: 'Destinations'
  }
];

/** Returns true after one useEffect render. */
const useCheckIfDataHasBeenSeenAtLeastOnce = (data: any, count: number | undefined) => {
  const [dataHasBeenSeenAtLeastOnce, setDataHasBeenSeenAtLeastOnce] = useState(false);
  useEffect(() => {
    if (!!count) {
      setDataHasBeenSeenAtLeastOnce(true);
    }
  }, [data]);
  return dataHasBeenSeenAtLeastOnce;
};

export const WorkspacesLanding = () => {
  di(useListWorkspaces);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const [sortOrder, setSortOrder] = useState(ListWorkspacesRequest_WorkspaceSortOrder.NAME);
  const [reversed, setReversed] = useState(false);

  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging(DefaultTablePageSize);

  const { data: workspacesData, error: workspacesError } = useListWorkspaces(
    nameFilter,
    apiPaginationObject,
    statusFilter,
    sortOrder,
    reversed
  );

  useEffect(() => {
    if (!dataHasBeenSeenAtLeastOnce && workspacesData?.total === 1 && workspacesData.workspaces.length > 0) {
      navigate(buildWorkspaceDetailsUrl(workspacesData.workspaces[0].workspaceRef?.name));
    }
  }, [workspacesData]);

  useSetPagingTotal(workspacesData?.filteredTotal);
  const { initialLoad, showRefreshIndicator, data: workspaces } = useDebouncedRefreshIndicator(workspacesData);
  const dataHasBeenSeenAtLeastOnce = useCheckIfDataHasBeenSeenAtLeastOnce(workspaces, workspaces?.workspaces.length);

  // Different behavior if it has been seen once before
  if (!!workspaces && !workspaces.workspaces.length && !dataHasBeenSeenAtLeastOnce) {
    return <WorkspacesLandingEmpty />;
  }

  return (
    <OverviewStyles.Container data-testid='workspaces-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.WorkspaceIcon />}
          Workspaces
        </OverviewStyles.Header.Title>
        {!workspacesError && (
          <OverviewStyles.Header.FiltersWrapper>
            {(workspaces?.workspaces.length !== 1 || statusFilter.length > 0) && (
              <HealthCountBox
                onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
                onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
                isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
                isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
                totalCount={workspaces?.total}
                errorCount={workspaces?.errors}
              />
            )}
            <OverviewStyles.Header.Filters filters={2} hasTableToggle={false}>
              <Styles.SortContainer>
                <Styles.OrderDirectionToggle reversed={reversed} onClick={() => setReversed(rev => !rev)}>
                  <Asset.SortArrows />
                </Styles.OrderDirectionToggle>
                <SoloDropdown
                  value={sortOrder}
                  displayValue={`Sort by ${sortOrderOptions.find(o => o.value === sortOrder)?.displayValue}`}
                  options={sortOrderOptions}
                  placeholder={'Sort by...'}
                  aria-label='Sort by'
                  onChange={setSortOrder}
                />
              </Styles.SortContainer>
              <SoloInput
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label='Search by name'
                onChange={evt => setNameFilter(evt.target.value)}
              />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <WorkspacesLandingBody
        isLoading={initialLoad}
        itemsError={workspacesError}
        items={workspaces?.workspaces ?? []}
        pagingData={pagingData}
      />
    </OverviewStyles.Container>
  );
};
