/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/tls_matchers.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Specify TLS request matchers. Must provide an SNI host to match with an incoming request.
 *
 * @generated from protobuf message common.gloo.solo.io.TLSRequestMatcher
 */
export interface TLSRequestMatcher {
    /**
     * Required: SNI (server name indicator) to match on. Wildcard prefixes can be used in the SNI value.
     * For example, '*.com' matches 'foo.example.com' as well as 'example.com'.
     * The SNI value must be a subset of the corresponding virtual service’s hosts.
     *
     * @generated from protobuf field: repeated string sni_hosts = 1;
     */
    sniHosts: string[];
    /**
     * Optional: Port on the host to match against.
     *
     * +kubebuilder:validation:Minimum=0
     * +kubebuilder:validation:Maximum=65535
     *
     * @generated from protobuf field: uint32 port = 2;
     */
    port: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class TLSRequestMatcher$Type extends MessageType<TLSRequestMatcher> {
    constructor() {
        super("common.gloo.solo.io.TLSRequestMatcher", [
            { no: 1, name: "sni_hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.TLSRequestMatcher
 */
export const TLSRequestMatcher = new TLSRequestMatcher$Type();
