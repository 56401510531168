import { SoloCheckboxListDropdown } from 'Components/Common/Input/SoloCheckboxList';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { OverviewTablePageSize, useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { Svg } from 'Components/Common/Svg';
import {
  CertificateExpiryKey,
  certificatesExpiryCodesMap
} from 'Components/Features/Dashboard/Cards/CertificatesExpiry/insight-codes';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Asset } from 'assets';
import { useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { transformCertDataToListData } from 'utils/certificate-helpers';
import { getInsightData } from 'utils/dashboard/dashboard-helpers';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { CertificatesLandingBody, SharedCertData } from './CertificatesLandingBody';

//
// Insight data types
//
//
// Root cert
const rootExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.rootCAExpiry].dataProp;
const intermediateExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.intermediateCAExpiry].dataProp;
const istioGatewayExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.istioGatewayCAExpiry].dataProp;
const k8GatewayExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.k8GatewayCAExpiry].dataProp;

//
// Component
//
export const CertificatesLanding = () => {
  di(useFilteredDashboardInsights);
  const [nameFilter, setNameFilter] = useState('');
  //
  // This is currently only a table view.
  // const [isTable, setIsTable] = useState(isTableViewPreferred());
  const isTable = true;
  //
  const typeFilterOptions = ['Root', 'Intermediate', 'Istio Gateway', 'Kubernetes Gateway'] as const;
  const [typeFilter, setTypeFilter] = useState([...typeFilterOptions]);
  const expiryFilterOptions = ['expired', 'valid'];
  const [expiryFilter, setExpiryFilter] = useState(expiryFilterOptions);
  const filtersOn = !!nameFilter.length || !!typeFilter.length || !!expiryFilter.length;

  //
  // Get cert insights data
  //
  const rootCertInsights = useFilteredDashboardInsights(certificatesExpiryCodesMap, CertificateExpiryKey.rootCAExpiry);
  const intermediateCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.intermediateCAExpiry
  );
  const istioGatewayCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.istioGatewayCAExpiry
  );
  const k8GatewayCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.k8GatewayCAExpiry
  );
  const isLoading =
    rootCertInsights === undefined ||
    intermediateCertInsights === undefined ||
    istioGatewayCertInsights === undefined ||
    k8GatewayCertInsights === undefined;
  //
  // This maps the data from each cert into a shared data type,
  // then passes it as an array down to the table/grid components.
  const sharedCertData = useMemo<SharedCertData[]>(() => {
    const newFilteredData: SharedCertData[] = [];
    if (typeFilter.includes('Root')) {
      newFilteredData.push(
        ...(rootCertInsights?.map(insight => {
          const data = getInsightData(insight, rootExpiryDataProp);
          return transformCertDataToListData('root', insight, data);
        }) ?? [])
      );
    }

    if (typeFilter.includes('Intermediate')) {
      newFilteredData.push(
        ...(intermediateCertInsights?.map(insight => {
          const data = getInsightData(insight, intermediateExpiryDataProp);
          return transformCertDataToListData('intermediate', insight, data);
        }) ?? [])
      );
    }
    if (typeFilter.includes('Istio Gateway')) {
      newFilteredData.push(
        ...(istioGatewayCertInsights?.flatMap(insight => {
          const data = getInsightData(insight, istioGatewayExpiryDataProp);
          return (
            data?.certificates.map(cert => transformCertDataToListData('istio gateway', insight, cert.certificate)) ??
            []
          );
        }) ?? [])
      );
    }
    if (typeFilter.includes('Kubernetes Gateway')) {
      newFilteredData.push(
        ...(k8GatewayCertInsights?.flatMap(insight => {
          const data = getInsightData(insight, k8GatewayExpiryDataProp);
          return (
            data?.certificates.map(cert =>
              transformCertDataToListData('kubernetes gateway', insight, cert.certificate)
            ) ?? []
          );
        }) ?? [])
      );
    }
    const lowerNameFilter = nameFilter.toLocaleLowerCase();
    const includesExpiredCerts = expiryFilter.includes('expired');
    const includesValidCerts = expiryFilter.includes('valid');
    return newFilteredData
      .filter(
        d =>
          // Name filter
          d.hostname.toLocaleLowerCase().includes(lowerNameFilter) ||
          d.issuedToCn.toLocaleLowerCase().includes(lowerNameFilter) ||
          d.certType.toLocaleLowerCase().includes(lowerNameFilter) ||
          d.cluster.toLocaleLowerCase().includes(lowerNameFilter)
      )
      .filter(
        d =>
          // Expiration filter
          (includesExpiredCerts && d.expiryDays < 0) || (includesValidCerts && d.expiryDays >= 0)
      );
  }, [
    rootCertInsights,
    intermediateCertInsights,
    istioGatewayCertInsights,
    k8GatewayCertInsights,
    nameFilter,
    typeFilter,
    expiryFilter
  ]);

  // This does client-side pagination.
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(sharedCertData, OverviewTablePageSize);

  //
  // Render
  //
  return (
    <OverviewStyles.Container data-testid='certificates-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          <Svg width={30} asset={<Asset.CertExpiryIcon />} color={theme => theme.seaBlue} /> Certificates
        </OverviewStyles.Header.Title>
        <FlexLayout gap={3} flexGrow={1} justifyContent='flex-end'>
          <SoloCheckboxListDropdown
            noSearch
            title={'Filter by expiration...'}
            values={expiryFilter}
            options={expiryFilterOptions.map(t => ({
              key: t,
              value: t,
              label: t
            }))}
            onChange={setExpiryFilter}
          />
          <SoloCheckboxListDropdown
            noSearch
            title={'Filter by type...'}
            values={typeFilter}
            options={typeFilterOptions.map(t => ({
              key: t,
              value: t,
              label: t
            }))}
            onChange={setTypeFilter}
          />
          <SoloInput
            value={nameFilter}
            placeholder={'Search...'}
            aria-label={'Search'}
            onChange={evt => setNameFilter(evt.target.value)}
          />
        </FlexLayout>
      </OverviewStyles.Header.Header>
      <CertificatesLandingBody
        filtersOn={filtersOn}
        items={paginatedData}
        isLoading={isLoading}
        pagingData={pagingData}
        itemsError={undefined}
        isTable={isTable}
        data-testid='certificates-landing-body-empty'
        icon={<Asset.CertIcon />}
        // TODO: add in docs.
        docsLink={false}
        resourceNamePlural='Certificates'
      />
    </OverviewStyles.Container>
  );
};

export default CertificatesLanding;
