/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/envoy-gloo/api/envoy/config/filter/http/transformation/v2/transformation_filter.proto" (package "envoy.api.v2.filter.http", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "../../../../../../../../../../google/protobuf/wrappers_pb";
import { Empty } from "../../../../../../../../../../google/protobuf/empty_pb";
import { BoolValue } from "../../../../../../../../../../google/protobuf/wrappers_pb";
import { TypedExtensionConfig } from "../../../../../../../../../../envoy/config/core/v3/extension_pb";
import { StringMatcher } from "../../../../../../../../../../envoy/type/matcher/v3/string_pb";
import { HeaderMatcher } from "../../../../../../../../../../envoy/config/route/v3/route_components_pb";
import { RouteMatch } from "../../../../../../../../../../envoy/config/route/v3/route_components_pb";
import { Matcher } from "../../../../../../../../../../xds/type/matcher/v3/matcher_pb";
/**
 * @generated from protobuf message envoy.api.v2.filter.http.FilterTransformations
 */
export interface FilterTransformations {
    /**
     * Specifies transformations based on the route matches. The first matched
     * transformation will be applied. If there are overlapped match conditions,
     * please put the most specific match first.
     *
     * @generated from protobuf field: repeated envoy.api.v2.filter.http.TransformationRule transformations = 1;
     */
    transformations: TransformationRule[];
    /**
     * If provided, transformations fields here are ignored. The transformation will be the action from this matcher.
     *
     * @generated from protobuf field: xds.type.matcher.v3.Matcher matcher = 4;
     */
    matcher?: Matcher;
    /**
     * Only RouteTransformations.RouteTransformation with matching stage will be
     * used with this filter.
     *
     * @generated from protobuf field: uint32 stage = 2;
     */
    stage: number;
    /**
     * Logs request/response sensitive information
     * By default, this is false so no request or response sensitive information is logged.
     * If set to true, the filter will log the request/response body and headers before and
     * after any transformation is applied.
     *
     * @generated from protobuf field: bool log_request_response_info = 3;
     */
    logRequestResponseInfo: boolean;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.TransformationRule
 */
export interface TransformationRule {
    /**
     * The route matching parameter. Only when the match is satisfied, the
     * "requires" field will apply.
     *
     * For example: following match will match all requests.
     *
     * .. code-block:: yaml
     *
     *    match:
     *      prefix: /
     *
     *
     * @generated from protobuf field: envoy.config.route.v3.RouteMatch match = 1;
     */
    match?: RouteMatch;
    /**
     * transformation to perform
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.TransformationRule.Transformations route_transformations = 2;
     */
    routeTransformations?: TransformationRule_Transformations;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.TransformationRule.Transformations
 */
export interface TransformationRule_Transformations {
    /**
     * Apply a transformation to requests.
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation request_transformation = 1;
     */
    requestTransformation?: Transformation;
    /**
     * Clear the route cache if the request transformation was applied.
     *
     * @generated from protobuf field: bool clear_route_cache = 3;
     */
    clearRouteCache: boolean;
    /**
     * Apply a transformation to responses.
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation response_transformation = 2;
     */
    responseTransformation?: Transformation;
    /**
     * Apply a transformation in the onStreamComplete callback
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation on_stream_completion_transformation = 4;
     */
    onStreamCompletionTransformation?: Transformation;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.RouteTransformations
 */
export interface RouteTransformations {
    /**
     * deprecated. Use transformations[].request_match.request_transformation
     * instead.
     *
     * @deprecated
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation request_transformation = 1 [deprecated = true];
     */
    requestTransformation?: Transformation;
    /**
     * deprecated. Use transformations[].request_match.response_transformation
     * instead.
     *
     * @deprecated
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation response_transformation = 2 [deprecated = true];
     */
    responseTransformation?: Transformation;
    /**
     * deprecated. Use transformations[].request_match.clear_route_cache instead.
     *
     * @deprecated
     * @generated from protobuf field: bool clear_route_cache = 3 [deprecated = true];
     */
    clearRouteCache: boolean;
    /**
     * @generated from protobuf field: repeated envoy.api.v2.filter.http.RouteTransformations.RouteTransformation transformations = 4;
     */
    transformations: RouteTransformations_RouteTransformation[];
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.RouteTransformations.RouteTransformation
 */
export interface RouteTransformations_RouteTransformation {
    /**
     * Stage number. This transformation will only be processed by filters with
     * the same stage number.
     *
     * @generated from protobuf field: uint32 stage = 1;
     */
    stage: number;
    /**
     * @generated from protobuf oneof: match
     */
    match: {
        oneofKind: "requestMatch";
        /**
         * @generated from protobuf field: envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.RequestMatch request_match = 2;
         */
        requestMatch: RouteTransformations_RouteTransformation_RequestMatch;
    } | {
        oneofKind: "responseMatch";
        /**
         * @generated from protobuf field: envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.ResponseMatch response_match = 3;
         */
        responseMatch: RouteTransformations_RouteTransformation_ResponseMatch;
    } | {
        oneofKind: undefined;
    };
    /**
     * If provided, transformations with request_match in transformations field are ignored.
     * The transformation will be the action from this matcher.
     *
     * @generated from protobuf field: xds.type.matcher.v3.Matcher matcher = 5;
     */
    matcher?: Matcher;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.RequestMatch
 */
export interface RouteTransformations_RouteTransformation_RequestMatch {
    /**
     * if no match is specified, will match all
     *
     * @generated from protobuf field: envoy.config.route.v3.RouteMatch match = 1;
     */
    match?: RouteMatch;
    /**
     * transformation to perform
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation request_transformation = 2;
     */
    requestTransformation?: Transformation;
    /**
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation response_transformation = 3;
     */
    responseTransformation?: Transformation;
    /**
     * clear the route cache if the request transformation was applied
     *
     * @generated from protobuf field: bool clear_route_cache = 4;
     */
    clearRouteCache: boolean;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.ResponseMatch
 */
export interface RouteTransformations_RouteTransformation_ResponseMatch {
    /**
     * @generated from protobuf field: envoy.api.v2.filter.http.ResponseMatcher match = 1;
     */
    match?: ResponseMatcher;
    /**
     * transformation to perform
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation response_transformation = 2;
     */
    responseTransformation?: Transformation;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.ResponseMatcher
 */
export interface ResponseMatcher {
    /**
     * Specifies a set of headers that the route should match on. The router will
     * check the response headers against all the specified headers in the route
     * config. A match will happen if all the headers in the route are present in
     * the request with the same values (or based on presence if the value field
     * is not in the config).
     *
     * @generated from protobuf field: repeated envoy.config.route.v3.HeaderMatcher headers = 1;
     */
    headers: HeaderMatcher[];
    /**
     * Only match responses with non empty response code details (this usually
     * implies a local reply).
     *
     * @generated from protobuf field: envoy.type.matcher.v3.StringMatcher response_code_details = 2;
     */
    responseCodeDetails?: StringMatcher;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.ResponseTransformationRule
 */
export interface ResponseTransformationRule {
    /**
     * @generated from protobuf field: envoy.api.v2.filter.http.ResponseMatcher match = 1;
     */
    match?: ResponseMatcher;
    /**
     * transformation to perform
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Transformation response_transformation = 2;
     */
    responseTransformation?: Transformation;
}
/**
 * This message defines a transformation.
 *
 * @generated from protobuf message envoy.api.v2.filter.http.Transformation
 */
export interface Transformation {
    /**
     * @generated from protobuf oneof: transformation_type
     */
    transformationType: {
        oneofKind: "transformationTemplate";
        /**
         * Apply transformation templates.
         *
         * @generated from protobuf field: envoy.api.v2.filter.http.TransformationTemplate transformation_template = 1;
         */
        transformationTemplate: TransformationTemplate;
    } | {
        oneofKind: "headerBodyTransform";
        /**
         * This type of transformation will make all the headers available in the
         * response body. The resulting JSON body will consist of two attributes:
         * 'headers', containing the headers, and 'body', containing the original
         * body.
         *
         * @generated from protobuf field: envoy.api.v2.filter.http.HeaderBodyTransform header_body_transform = 2;
         */
        headerBodyTransform: HeaderBodyTransform;
    } | {
        oneofKind: "transformerConfig";
        /**
         * Configuration for an externally implemented transformer.
         *
         * @generated from protobuf field: envoy.config.core.v3.TypedExtensionConfig transformer_config = 3;
         */
        transformerConfig: TypedExtensionConfig;
    } | {
        oneofKind: undefined;
    };
    /**
     * Logs request/response sensitive information
     * By default, this is false so no request or response sensitive information is logged.
     * If set to true, the filter will log the request/response body and headers before and
     * after this transformation is applied.
     *
     * @generated from protobuf field: google.protobuf.BoolValue log_request_response_info = 4;
     */
    logRequestResponseInfo?: BoolValue;
}
/**
 * Extractions can be used to extract information from the request/response.
 * The extracted information can then be referenced in template fields.
 *
 * @generated from protobuf message envoy.api.v2.filter.http.Extraction
 */
export interface Extraction {
    /**
     * @generated from protobuf oneof: source
     */
    source: {
        oneofKind: "header";
        /**
         * Extract information from headers
         *
         * @generated from protobuf field: string header = 1;
         */
        header: string;
    } | {
        oneofKind: "body";
        /**
         * Extract information from the request/response body
         *
         * @generated from protobuf field: google.protobuf.Empty body = 4;
         */
        body: Empty;
    } | {
        oneofKind: undefined;
    };
    /**
     * The regex field specifies the regular expression used for matching against the source content. This field is required.
     * - In EXTRACT mode, the entire source must match the regex. The subgroup-th capturing group,
     *   if specified, determines which part of the match is extracted. if the regex does not match the source
     *   the result of the extraction will be an empty value.
     * - In SINGLE_REPLACE mode, the regex also needs to match the entire source. The subgroup-th capturing group
     *   is targeted for replacement with the replacement_text. if the regex does not match the source
     *   the result of the extraction will be the source itself.
     * - In REPLACE_ALL mode, the regex is applied repeatedly to find all occurrences within the source that match.
     *   Each matching occurrence is replaced with the replacement_text, and the subgroup field is not used. if the
     *   regex does not match the source the result of the extraction will be the source itself.
     *
     * @generated from protobuf field: string regex = 2;
     */
    regex: string;
    /**
     * If your regex contains capturing groups, use this field to determine which
     * group should be selected.
     * For EXTRACT and SINGLE_REPLACE, refers to the portion of the text
     * to extract/replace.
     * Config will be rejected if this is specified in REPLACE_ALL mode.
     *
     * @generated from protobuf field: uint32 subgroup = 3;
     */
    subgroup: number;
    /**
     * Used in SINGLE_REPLACE and REPLACE_ALL modes.
     * `replacement_text` is used to format the substitution for matched sequences in the input string
     * - In SINGLE_REPLACE mode, the content in the subgroup-th capturing group is replaced with the `replacement_text`.
     * - In REPLACE_ALL mode, each sequence matching the specified regex in the in the input is replaced with the `replacement_text`.
     *     The replacement_text may contain special syntax, such as $1, $2, etc., to refer to captured groups within the regular expression.
     *     The value contained within `replacement_text` is treated as a string, and is passed to std::regex_replace as the replacement string.
     *     see https://en.cppreference.com/w/cpp/regex/regex_replace for more details.
     *
     * @generated from protobuf field: google.protobuf.StringValue replacement_text = 5;
     */
    replacementText?: StringValue;
    /**
     * The mode of operation for the extraction.
     * Defaults to EXTRACT.
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.Extraction.Mode mode = 6;
     */
    mode: Extraction_Mode;
}
/**
 * The mode of operation for the extraction.
 *
 * @generated from protobuf enum envoy.api.v2.filter.http.Extraction.Mode
 */
export enum Extraction_Mode {
    /**
     * Default mode. Extract the value of the subgroup-th capturing group.
     *
     * @generated from protobuf enum value: EXTRACT = 0;
     */
    EXTRACT = 0,
    /**
     * Replace the value of the subgroup-th capturing group with the replacement_text.
     * Note: replacement_text must be set for this mode.
     *
     * @generated from protobuf enum value: SINGLE_REPLACE = 1;
     */
    SINGLE_REPLACE = 1,
    /**
     * Replace all matches of the regex in the source with the replacement_text.
     * Note: replacement_text must be set for this mode.
     * Note: subgroup is ignored for this mode. configuration will fail if subgroup is set.
     * Note: restrictions on the regex are different for this mode. See the regex field for more details.
     *
     * @generated from protobuf enum value: REPLACE_ALL = 2;
     */
    REPLACE_ALL = 2
}
/**
 * Defines a transformation template.
 *
 * @generated from protobuf message envoy.api.v2.filter.http.TransformationTemplate
 */
export interface TransformationTemplate {
    /**
     * If set to true, use JSON pointer notation (e.g. "time/start") instead of
     * dot notation (e.g. "time.start") to access JSON elements. Defaults to
     * false.
     *
     * Please note that, if set to 'true', you will need to use the `extraction`
     * function to access extractors in the template (e.g. '{{
     * extraction("my_extractor") }}'); if the default value of 'false' is used,
     * extractors will simply be available by their name (e.g. '{{ my_extractor
     * }}').
     *
     * @generated from protobuf field: bool advanced_templates = 1;
     */
    advancedTemplates: boolean;
    /**
     * Use this attribute to extract information from the request. It consists of
     * a map of strings to extractors. The extractor will defines which
     * information will be extracted, while the string key will provide the
     * extractor with a name. You can reference extractors by their name in
     * templates, e.g. "{{ my-extractor }}" will render to the value of the
     * "my-extractor" extractor.
     *
     * @generated from protobuf field: map<string, envoy.api.v2.filter.http.Extraction> extractors = 2;
     */
    extractors: {
        [key: string]: Extraction;
    };
    /**
     * Use this attribute to transform request/response headers. It consists of a
     * map of strings to templates. The string key determines the name of the
     * resulting header, the rendered template will determine the value. Any existing
     * headers with the same header name will be replaced by the transformed header.
     * If a header name is included in `headers` and `headers_to_append`, it will first
     * be replaced the template in `headers`, then additional header values will be appended
     * by the templates defined in `headers_to_append`.
     *
     * @generated from protobuf field: map<string, envoy.api.v2.filter.http.InjaTemplate> headers = 3;
     */
    headers: {
        [key: string]: InjaTemplate;
    };
    /**
     * Use this attribute to transform request/response headers. It consists of
     * an array of string/template objects. Use this attribute to define multiple
     * templates for a single header. Header template(s) defined here will be appended to any
     * existing headers with the same header name, not replace existing ones.
     *
     * @generated from protobuf field: repeated envoy.api.v2.filter.http.TransformationTemplate.HeaderToAppend headers_to_append = 10;
     */
    headersToAppend: TransformationTemplate_HeaderToAppend[];
    /**
     * @generated from protobuf field: repeated string headers_to_remove = 11;
     */
    headersToRemove: string[];
    /**
     * @generated from protobuf oneof: body_transformation
     */
    bodyTransformation: {
        oneofKind: "body";
        /**
         * Apply a template to the body
         *
         * @generated from protobuf field: envoy.api.v2.filter.http.InjaTemplate body = 4;
         */
        body: InjaTemplate;
    } | {
        oneofKind: "passthrough";
        /**
         * This will cause the transformation filter not to buffer the body.
         * Use this setting if the response body is large and you don't need to
         * transform nor extract information from it.
         *
         * @generated from protobuf field: envoy.api.v2.filter.http.Passthrough passthrough = 5;
         */
        passthrough: Passthrough;
    } | {
        oneofKind: "mergeExtractorsToBody";
        /**
         * Merge all defined extractors to the request/response body.
         * If you want to nest elements inside the body, use dot separator in the
         * extractor name.
         *
         * @generated from protobuf field: envoy.api.v2.filter.http.MergeExtractorsToBody merge_extractors_to_body = 6;
         */
        mergeExtractorsToBody: MergeExtractorsToBody;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: envoy.api.v2.filter.http.TransformationTemplate.RequestBodyParse parse_body_behavior = 7;
     */
    parseBodyBehavior: TransformationTemplate_RequestBodyParse;
    /**
     * If set to true, Envoy will not throw an exception in case the body parsing
     * fails.
     *
     * @generated from protobuf field: bool ignore_error_on_parse = 8;
     */
    ignoreErrorOnParse: boolean;
    /**
     * Use this field to set Dynamic Metadata.
     *
     * @generated from protobuf field: repeated envoy.api.v2.filter.http.TransformationTemplate.DynamicMetadataValue dynamic_metadata_values = 9;
     */
    dynamicMetadataValues: TransformationTemplate_DynamicMetadataValue[];
    /**
     * Use to escape the output of a body transformation. This will cause
     * rendered string values to be escaped in order to make valid JSON/YAML strings
     *
     * @generated from protobuf field: bool escape_characters = 12;
     */
    escapeCharacters: boolean;
}
/**
 * Defines a header-template pair to be used in `headers_to_append`
 *
 * @generated from protobuf message envoy.api.v2.filter.http.TransformationTemplate.HeaderToAppend
 */
export interface TransformationTemplate_HeaderToAppend {
    /**
     * Header name
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * Apply a template to the header value
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.InjaTemplate value = 2;
     */
    value?: InjaTemplate;
}
/**
 * Defines an [Envoy Dynamic
 * Metadata](https://www.envoyproxy.io/docs/envoy/latest/configuration/advanced/well_known_dynamic_metadata)
 * entry.
 *
 * @generated from protobuf message envoy.api.v2.filter.http.TransformationTemplate.DynamicMetadataValue
 */
export interface TransformationTemplate_DynamicMetadataValue {
    /**
     * The metadata namespace. Defaults to the filter namespace.
     *
     * @generated from protobuf field: string metadata_namespace = 1;
     */
    metadataNamespace: string;
    /**
     * The metadata key.
     *
     * @generated from protobuf field: string key = 2;
     */
    key: string;
    /**
     * A template that determines the metadata value.
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.InjaTemplate value = 3;
     */
    value?: InjaTemplate;
}
/**
 * Determines how the body will be parsed.
 *
 * @generated from protobuf enum envoy.api.v2.filter.http.TransformationTemplate.RequestBodyParse
 */
export enum TransformationTemplate_RequestBodyParse {
    /**
     * Will attempt to parse the request/response body as JSON
     *
     * @generated from protobuf enum value: ParseAsJson = 0;
     */
    ParseAsJson = 0,
    /**
     * The request/response body will be treated as plain text
     *
     * @generated from protobuf enum value: DontParse = 1;
     */
    DontParse = 1
}
/**
 * Defines an [Inja template](https://github.com/pantor/inja) that will be
 * rendered by Gloo. In addition to the core template functions, the Gloo
 * transformation filter defines the following custom functions:
 * - header(header_name): returns the value of the header with the given name
 * - extraction(extractor_name): returns the value of the extractor with the
 * given name
 * - env(env_var_name): returns the value of the environment variable with the
 * given name
 * - body(): returns the request/response body
 * - context(): returns the base JSON context (allowing for example to range on
 * a JSON body that is an array)
 *
 * @generated from protobuf message envoy.api.v2.filter.http.InjaTemplate
 */
export interface InjaTemplate {
    /**
     * @generated from protobuf field: string text = 1;
     */
    text: string;
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.Passthrough
 */
export interface Passthrough {
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.MergeExtractorsToBody
 */
export interface MergeExtractorsToBody {
}
/**
 * @generated from protobuf message envoy.api.v2.filter.http.HeaderBodyTransform
 */
export interface HeaderBodyTransform {
    /**
     * When transforming a request, setting this to true will additionally add
     * "queryString", "queryStringParameters", "multiValueQueryStringParameters",
     * "multiValueHeaders", "httpMethod" and "path" to the body.
     * NOTE: To match the AWS API Gateway spec, multiValueQueryStringParameters
     * and multiValueHeaders only contain query params and headers with multiple
     * values. Query params and Headers with a single value will not be present
     * in the multiValue fields.
     *
     * @generated from protobuf field: bool add_request_metadata = 1;
     */
    addRequestMetadata: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class FilterTransformations$Type extends MessageType<FilterTransformations> {
    constructor() {
        super("envoy.api.v2.filter.http.FilterTransformations", [
            { no: 1, name: "transformations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransformationRule },
            { no: 4, name: "matcher", kind: "message", T: () => Matcher },
            { no: 2, name: "stage", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { lte: 10 } } } },
            { no: 3, name: "log_request_response_info", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.FilterTransformations
 */
export const FilterTransformations = new FilterTransformations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationRule$Type extends MessageType<TransformationRule> {
    constructor() {
        super("envoy.api.v2.filter.http.TransformationRule", [
            { no: 1, name: "match", kind: "message", T: () => RouteMatch, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "route_transformations", kind: "message", T: () => TransformationRule_Transformations }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.TransformationRule
 */
export const TransformationRule = new TransformationRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationRule_Transformations$Type extends MessageType<TransformationRule_Transformations> {
    constructor() {
        super("envoy.api.v2.filter.http.TransformationRule.Transformations", [
            { no: 1, name: "request_transformation", kind: "message", T: () => Transformation },
            { no: 3, name: "clear_route_cache", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "response_transformation", kind: "message", T: () => Transformation },
            { no: 4, name: "on_stream_completion_transformation", kind: "message", T: () => Transformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.TransformationRule.Transformations
 */
export const TransformationRule_Transformations = new TransformationRule_Transformations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteTransformations$Type extends MessageType<RouteTransformations> {
    constructor() {
        super("envoy.api.v2.filter.http.RouteTransformations", [
            { no: 1, name: "request_transformation", kind: "message", T: () => Transformation },
            { no: 2, name: "response_transformation", kind: "message", T: () => Transformation },
            { no: 3, name: "clear_route_cache", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "transformations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteTransformations_RouteTransformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.RouteTransformations
 */
export const RouteTransformations = new RouteTransformations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteTransformations_RouteTransformation$Type extends MessageType<RouteTransformations_RouteTransformation> {
    constructor() {
        super("envoy.api.v2.filter.http.RouteTransformations.RouteTransformation", [
            { no: 1, name: "stage", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { lte: 10 } } } },
            { no: 2, name: "request_match", kind: "message", oneof: "match", T: () => RouteTransformations_RouteTransformation_RequestMatch },
            { no: 3, name: "response_match", kind: "message", oneof: "match", T: () => RouteTransformations_RouteTransformation_ResponseMatch },
            { no: 5, name: "matcher", kind: "message", T: () => Matcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.RouteTransformations.RouteTransformation
 */
export const RouteTransformations_RouteTransformation = new RouteTransformations_RouteTransformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteTransformations_RouteTransformation_RequestMatch$Type extends MessageType<RouteTransformations_RouteTransformation_RequestMatch> {
    constructor() {
        super("envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.RequestMatch", [
            { no: 1, name: "match", kind: "message", T: () => RouteMatch },
            { no: 2, name: "request_transformation", kind: "message", T: () => Transformation },
            { no: 3, name: "response_transformation", kind: "message", T: () => Transformation },
            { no: 4, name: "clear_route_cache", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.RequestMatch
 */
export const RouteTransformations_RouteTransformation_RequestMatch = new RouteTransformations_RouteTransformation_RequestMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteTransformations_RouteTransformation_ResponseMatch$Type extends MessageType<RouteTransformations_RouteTransformation_ResponseMatch> {
    constructor() {
        super("envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.ResponseMatch", [
            { no: 1, name: "match", kind: "message", T: () => ResponseMatcher },
            { no: 2, name: "response_transformation", kind: "message", T: () => Transformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.RouteTransformations.RouteTransformation.ResponseMatch
 */
export const RouteTransformations_RouteTransformation_ResponseMatch = new RouteTransformations_RouteTransformation_ResponseMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseMatcher$Type extends MessageType<ResponseMatcher> {
    constructor() {
        super("envoy.api.v2.filter.http.ResponseMatcher", [
            { no: 1, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher },
            { no: 2, name: "response_code_details", kind: "message", T: () => StringMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.ResponseMatcher
 */
export const ResponseMatcher = new ResponseMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseTransformationRule$Type extends MessageType<ResponseTransformationRule> {
    constructor() {
        super("envoy.api.v2.filter.http.ResponseTransformationRule", [
            { no: 1, name: "match", kind: "message", T: () => ResponseMatcher },
            { no: 2, name: "response_transformation", kind: "message", T: () => Transformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.ResponseTransformationRule
 */
export const ResponseTransformationRule = new ResponseTransformationRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transformation$Type extends MessageType<Transformation> {
    constructor() {
        super("envoy.api.v2.filter.http.Transformation", [
            { no: 1, name: "transformation_template", kind: "message", oneof: "transformationType", T: () => TransformationTemplate },
            { no: 2, name: "header_body_transform", kind: "message", oneof: "transformationType", T: () => HeaderBodyTransform },
            { no: 3, name: "transformer_config", kind: "message", oneof: "transformationType", T: () => TypedExtensionConfig },
            { no: 4, name: "log_request_response_info", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.Transformation
 */
export const Transformation = new Transformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Extraction$Type extends MessageType<Extraction> {
    constructor() {
        super("envoy.api.v2.filter.http.Extraction", [
            { no: 1, name: "header", kind: "scalar", oneof: "source", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "body", kind: "message", oneof: "source", T: () => Empty },
            { no: 2, name: "regex", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subgroup", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "replacement_text", kind: "message", T: () => StringValue },
            { no: 6, name: "mode", kind: "enum", T: () => ["envoy.api.v2.filter.http.Extraction.Mode", Extraction_Mode] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.Extraction
 */
export const Extraction = new Extraction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationTemplate$Type extends MessageType<TransformationTemplate> {
    constructor() {
        super("envoy.api.v2.filter.http.TransformationTemplate", [
            { no: 1, name: "advanced_templates", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "extractors", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Extraction } },
            { no: 3, name: "headers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InjaTemplate } },
            { no: 10, name: "headers_to_append", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransformationTemplate_HeaderToAppend },
            { no: 11, name: "headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "body", kind: "message", oneof: "bodyTransformation", T: () => InjaTemplate },
            { no: 5, name: "passthrough", kind: "message", oneof: "bodyTransformation", T: () => Passthrough },
            { no: 6, name: "merge_extractors_to_body", kind: "message", oneof: "bodyTransformation", T: () => MergeExtractorsToBody },
            { no: 7, name: "parse_body_behavior", kind: "enum", T: () => ["envoy.api.v2.filter.http.TransformationTemplate.RequestBodyParse", TransformationTemplate_RequestBodyParse] },
            { no: 8, name: "ignore_error_on_parse", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "dynamic_metadata_values", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransformationTemplate_DynamicMetadataValue },
            { no: 12, name: "escape_characters", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.TransformationTemplate
 */
export const TransformationTemplate = new TransformationTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationTemplate_HeaderToAppend$Type extends MessageType<TransformationTemplate_HeaderToAppend> {
    constructor() {
        super("envoy.api.v2.filter.http.TransformationTemplate.HeaderToAppend", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "message", T: () => InjaTemplate }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.TransformationTemplate.HeaderToAppend
 */
export const TransformationTemplate_HeaderToAppend = new TransformationTemplate_HeaderToAppend$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationTemplate_DynamicMetadataValue$Type extends MessageType<TransformationTemplate_DynamicMetadataValue> {
    constructor() {
        super("envoy.api.v2.filter.http.TransformationTemplate.DynamicMetadataValue", [
            { no: 1, name: "metadata_namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 3, name: "value", kind: "message", T: () => InjaTemplate }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.TransformationTemplate.DynamicMetadataValue
 */
export const TransformationTemplate_DynamicMetadataValue = new TransformationTemplate_DynamicMetadataValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InjaTemplate$Type extends MessageType<InjaTemplate> {
    constructor() {
        super("envoy.api.v2.filter.http.InjaTemplate", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.InjaTemplate
 */
export const InjaTemplate = new InjaTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Passthrough$Type extends MessageType<Passthrough> {
    constructor() {
        super("envoy.api.v2.filter.http.Passthrough", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.Passthrough
 */
export const Passthrough = new Passthrough$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MergeExtractorsToBody$Type extends MessageType<MergeExtractorsToBody> {
    constructor() {
        super("envoy.api.v2.filter.http.MergeExtractorsToBody", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.MergeExtractorsToBody
 */
export const MergeExtractorsToBody = new MergeExtractorsToBody$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeaderBodyTransform$Type extends MessageType<HeaderBodyTransform> {
    constructor() {
        super("envoy.api.v2.filter.http.HeaderBodyTransform", [
            { no: 1, name: "add_request_metadata", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.filter.http.HeaderBodyTransform
 */
export const HeaderBodyTransform = new HeaderBodyTransform$Type();
