import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Kind, NamedTypeNode, UnionTypeDefinitionNode } from 'graphql';
import { useContext, useMemo } from 'react';
import { GqlSchemaTabItemProps } from 'utils/graphql-schema-search-helpers';
import FieldTypeValue from '../../FieldTypeValue';
import { GqlDescriptionInfoTooltip } from '../../GqlDescriptionInfoTooltip';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';

type TableFields = {
  key: string;
  isFieldIncludedByExtension: boolean;
  unionedNode: NamedTypeNode;
};

const GqlUnionTabItem = ({
  onTypeClick,
  focusedItem,
  tabHeader,
  focusedPath
}: GqlSchemaTabItemProps<UnionTypeDefinitionNode>) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlCtx;
  const focusedItemExtensions = getExtensionInfo(Kind.UNION_TYPE_EXTENSION, focusedItem.name.value);

  const combinedTypes = useMemo(
    () =>
      [
        ...(focusedItem.types ?? []),
        ...(focusedItemExtensions?.types?.map(d => ({ ...d, isFieldIncludedByExtension: true })) ?? [])
      ] as (NamedTypeNode & { isFieldIncludedByExtension?: boolean })[],
    [focusedItem.types, focusedItemExtensions]
  );

  const tableData = useMemo<readonly TableFields[]>(
    () =>
      combinedTypes.map(t => ({
        key: t.name.value,
        isFieldIncludedByExtension: !!t.isFieldIncludedByExtension,
        unionedNode: t
      })) ?? [],
    [combinedTypes]
  );

  const columns: SoloColumnsType<TableFields> = useMemo(
    () => [
      {
        title: 'Values',
        dataIndex: 'unionedNode',
        render: (node, record) => (
          <FlexLayout>
            {!!record.isFieldIncludedByExtension && (
              <GqlDescriptionInfoTooltip description={'This union was extended to include this type.'} />
            )}
            <GqlSchemaTabStyles.MonospaceItem>
              <FieldTypeValue field={node} onTypeClick={itemName => onTypeClick(itemName, true)} />
            </GqlSchemaTabStyles.MonospaceItem>
          </FlexLayout>
        )
      }
    ],
    [tableData, onTypeClick]
  );

  //
  // Render
  //
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectiveList
        node={focusedItem}
        extendedDirectives={focusedItemExtensions?.directives}
        onTypeClick={onTypeClick}
      />
      <GqlTabItemDescription node={focusedItem} />
      <SoloTable
        removeHorizontalPadding
        columns={columns}
        dataSource={tableData}
        data-testid='gql-union-values-table'
      />
    </>
  );
};

export default GqlUnionTabItem;
