import { DashboardContext } from 'Components/Features/Dashboard/DashboardContext';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { useContext } from 'react';
import { DashboardStyles } from '../../../DashboardLanding.style';
import { GlooHealthCheckSection } from './GlooHealthCheckSection';
import { GlooHealthEnvironmentCheckSection } from './GlooHealthEnvironmentCheckSection';

export const GlooHealthTabContent = () => {
  const { dashboardCardsData } = useContext(DashboardContext);

  return (
    <GridLayout templateColumns='1fr auto 1fr' gap={5} pt={5}>
      <GlooHealthCheckSection />
      <DashboardStyles.VerticalLine />
      <GlooHealthEnvironmentCheckSection healthOverview={dashboardCardsData?.healthOverview} />
    </GridLayout>
  );
};
