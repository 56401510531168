import { SoloListCard } from 'Components/Common/SoloListCard';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { DashboardStyles } from '../../../DashboardLanding.style';
import { DashboardHealthCardStyles } from '../DashboardHealthCard.style';
import { useCiliumVersionInsightsToVersionDict, useVersionListCardEntries } from '../hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from '../insight-codes';

export const CiliumHealthEnvironmentCheckSection = () => {
  di(useFilteredDashboardInsights);
  const ciliumEnvironment = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.ciliumEnvironmentCheck
  );
  const versionsDict = useCiliumVersionInsightsToVersionDict(ciliumEnvironment);
  const versionListCardEntries = useVersionListCardEntries(versionsDict);

  return (
    <GridLayout templateRows='auto 1fr auto' data-testid='cilium-environment-check-section'>
      <DashboardStyles.SubHeader>Environment Check</DashboardStyles.SubHeader>

      <DashboardHealthCardStyles.NoMarginEmptyContainer>
        <SoloListCard title='Version' maxHeight='140px' dataSource={versionListCardEntries} hideDottedLine />
      </DashboardHealthCardStyles.NoMarginEmptyContainer>

      <FlexLayout pt={3} justifyContent='space-between'>
        <DashboardStyles.VersionsCountCircleAndTextContainer>
          Cilium Versions{' '}
          <DashboardStyles.VersionsCountCircle data-testid='cilium-versions-count'>
            {Object.keys(versionsDict).length}
          </DashboardStyles.VersionsCountCircle>
        </DashboardStyles.VersionsCountCircleAndTextContainer>
        {/* <FlexLayout vertical>
          <DashboardStyles.CardBottomLink>
            <SoloLink small link='/TODO' withArrow>
              DETAILS
            </SoloLink>
          </DashboardStyles.CardBottomLink>
        </FlexLayout> */}
      </FlexLayout>
    </GridLayout>
  );
};
