import { destinationsApi } from 'Api/destinations';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getDestinationKindIcon } from 'utils/types';
import { BasicPoliciesTable } from '../../Policies/BasicPoliciesTable';
import { WorkspaceDependenciesCard } from '../../Workspaces/WorkspaceDependenciesCard';
import { BasicDestinationsTable } from '../BasicDestinationsTable';

const { useGetVirtualDestinationDetails } = destinationsApi;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const VirtualDestinationDetails = ({ clusterObjRef }: Props) => {
  di(useGetVirtualDestinationDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();
  const servicesPaging = useSoloPaging();

  const { data: destinationData, error: destinationError } = useGetVirtualDestinationDetails(
    clusterObjRef,
    servicesPaging.apiPaginationObject,
    apiPaginationObject
  );

  useSetPagingTotal(destinationData?.policies?.totalPolicies);
  servicesPaging.useSetPagingTotal(destinationData?.totalDestinations);

  if (!!destinationError || !destinationData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded
          icon={getDestinationKindIcon(DestinationKind.VIRTUAL_DESTINATION)}
          objRef={clusterObjRef}
        />
        {!!destinationError ? (
          <DataError error={destinationError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} destination details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getDestinationKindIcon(DestinationKind.VIRTUAL_DESTINATION)}
        status={destinationData?.status}
        objRef={clusterObjRef}
        workspaceRef={destinationData?.ownerWorkspace?.ref}
        resourceType={ResourceType.VIRTUAL_DESTINATION}
      />

      <WorkspaceDependenciesCard
        dependencies={destinationData.dependencies}
        importingTypeName='destination'
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Destinations</CardStyles.CardHeader>

        <BasicDestinationsTable destinations={destinationData.destinations} paging={servicesPaging.pagingData} />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>Workspace Policies</CardStyles.CardHeader>

        <BasicPoliciesTable policies={destinationData.policies?.policies} paging={pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
