import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ToolbarToggleGroup as PatternflyToolbarToggleGroup } from '@patternfly/react-core';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Asset } from 'assets';
import { Properties } from 'csstype';

export namespace SoloLogViewerStyles {
  //
  // This is needed so that there is a background-color
  // when the LogViewer goes fullscreen.
  export const LogViewerContainer = styled.div<{ isFullscreen: boolean; isWaitingForSelection: boolean | undefined }>(
    ({ theme, isFullscreen, isWaitingForSelection }) => css`
      ${isFullscreen
        ? css`
            padding: 0.5em;
          `
        : ''}
      ${isWaitingForSelection
        ? css`
            .pf-v5-c-log-viewer {
              height: unset;
            }
            .pf-v5-c-log-viewer__main {
              display: none;
            }
          `
        : ''}
      background-color: ${theme.white};
      .pf-v5-c-log-viewer__main {
        background-color: ${theme.dropBlue};
        border: 1px solid ${theme.splashBlue};
        border-radius: 3px;
      }
    `
  );

  export const ToolbarRow = styled(FlexLayout)(
    ({ theme }) => css`
      align-items: center;
      width: 100%;
      background-color: ${theme.white};
      justify-content: flex-end;
    `
  );

  export const ToolbarToggleGroup = styled(PatternflyToolbarToggleGroup)`
    gap: 1rem;
  `;

  export const ToolbarTitle = styled.div`
    font-size: 22px;
  `;

  export const ToolbarButton = styled(SoloButton)(
    () => css`
      height: 30px;
      padding: 5px 10px;
      min-width: fit-content;
    `
  );

  export const SearchContainer = styled.div(
    ({ theme }) => css`
      width: 200px;
      max-width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      position: relative;
      height: 30px;

      .pf-v5-c-text-input-group {
        height: 30px;
        button,
        .pf-v5-c-text-input-group__group {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .pf-v5-c-text-input-group__text {
        height: 100%;
        &:before {
          border-radius: 3px;
          border: 1px solid ${theme.augustGrey};
        }
        &:after {
          border: none;
        }
      }

      input {
        height: 100%;
        border-radius: 3px;
        padding: 3px 22px 3px 8px;
        min-width: unset;
      }

      .pf-v5-c-text-input-group__icon {
        display: none;
      }
    `
  );

  export const SoloDropdownContainer = styled.div(
    ({ theme }) => css`
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.5em;
      label {
        color: ${theme.augustGrey};
      }
    `
  );

  export const EmptyPromptBoxContent = styled.div(
    ({ theme }) => css`
      position: relative;
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 3px;
      border: 1px solid ${theme.seaBlue};
      color: ${theme.seaBlue};
      background-color: ${theme.lightSeaBlue};
    `
  );
  export const EmptyPromptBox = styled.div(
    ({ theme }) => css`
      position: absolute;
      display: flex;
      align-items: center;
      left: -15%;
      right: -35%;
      top: 100%;
      margin-top: 12px;
      z-index: 1;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: ${theme.seaBlue};
        transform: rotate(45deg);
        position: absolute;
        left: 50%;
        top: -5px;
      }
    `
  );

  export const EmptyLogsContainer = styled.div<{ height: Properties['height'] }>(
    ({ height, theme }) => css`
      margin-top: -75px;
      display: flex;
      height: ${height};
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      border: 1px solid ${theme.splashBlue};
      margin-top: 5px;
      background-color: ${theme.dropBlue};
    `
  );
  export const EmptyLogsTitle = styled.div(
    css`
      font-size: 28px;
      margin-top: 20px;
    `
  );
  export const EmptyLogsText = styled.div(css`
    font-size: 16px;
    width: 400px;
    text-align: center;
    line-height: 28px;
  `);

  // This can be used to experiment with toggling text-wrapping.
  export const LineWrapIcon = styled(Asset.RequestHeader)`
    transform: scale(-1, -1) rotate(90deg);
  `;
}
