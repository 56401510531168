/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/string_match.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Describes how to match a given string in HTTP headers. Match is case-sensitive.
 *
 * @generated from protobuf message common.gloo.solo.io.StringMatch
 */
export interface StringMatch {
    /**
     * @generated from protobuf oneof: match_type
     */
    matchType: {
        oneofKind: "exact";
        /**
         * Exact string match.
         *
         * @generated from protobuf field: string exact = 1;
         */
        exact: string;
    } | {
        oneofKind: "prefix";
        /**
         * Prefix-based match.
         *
         * @generated from protobuf field: string prefix = 2;
         */
        prefix: string;
    } | {
        oneofKind: "regex";
        /**
         * [Re2 style regex-based match](https://github.com/google/re2/wiki/).
         *
         * @generated from protobuf field: string regex = 3;
         */
        regex: string;
    } | {
        oneofKind: "suffix";
        /**
         * Warning! Suffix-based matching is not currently supported in Gloo Platform.
         *
         * @generated from protobuf field: string suffix = 4;
         */
        suffix: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * If true, indicates the exact/prefix/suffix matching should be case insensitive. This has no effect for the regex match.
     *
     * @generated from protobuf field: bool ignore_case = 5;
     */
    ignoreCase: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class StringMatch$Type extends MessageType<StringMatch> {
    constructor() {
        super("common.gloo.solo.io.StringMatch", [
            { no: 1, name: "exact", kind: "scalar", oneof: "matchType", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prefix", kind: "scalar", oneof: "matchType", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "regex", kind: "scalar", oneof: "matchType", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "suffix", kind: "scalar", oneof: "matchType", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ignore_case", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.StringMatch
 */
export const StringMatch = new StringMatch$Type();
