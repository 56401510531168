import { CardStyles } from 'Components/Common/Card';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { Asset } from 'assets';
import {
  GetGraphqlApiResponse,
  Resolution_Resolvers
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { convertFromPBValue } from 'utils/graphql-helpers';
import { getRefFromDestinationRef } from 'utils/helpers';
import { stringifyVariableTransformation } from '../../../../../../utils/graphql-resolver-helpers';
import GqlInfoFromHeadersMap from '../GqlInfoFromHeadersMap';
import { GqlResolverModalStyles as Styles } from '../GqlResolverModal.style';
import VariableTransformationLabel from '../VariableTransformationLabel';
import GqlRestResolverRequestTabs from './GqlRestResolverRequestTabs';

export const GqlRestResolverModal = ({
  api,
  objectName,
  fieldName,
  resolver,
  onClose
}: {
  api: GetGraphqlApiResponse;
  objectName: string;
  fieldName: string;
  resolver: Resolution_Resolvers;
  onClose?: () => void;
}) => {
  const restResolver =
    resolver?.resolver?.resolver.oneofKind === 'restResolver' ? resolver.resolver.resolver.restResolver : undefined;
  const destName = getRefFromDestinationRef(restResolver?.destination?.destinationRef)?.name ?? 'unknown';

  // Gets resolver properties.
  const requestBody = stringifyVariableTransformation(restResolver?.request?.body);
  const resolverResultTransform = resolver.resolver?.resolverResultTransform;
  const variablesMap = resolver.variables ?? {};

  // Checks what the default HTTP method should be.
  const defaultHttpMethod = !!requestBody ? 'POST' : 'GET';

  //
  // Render
  //
  return (
    <SoloModal visible width={1000} onClose={onClose} withPadding data-testid='rest-resolver-modal'>
      <SoloModalHeader
        title='REST Resolver'
        subtitle={['Schema', objectName, fieldName]}
        icon={<Asset.RestIcon />}
        status={api.status}
        rightContent={
          <Styles.HeaderRight>
            <div>
              <SecondaryInfoItem label='Destination' data={destName} />
            </div>
          </Styles.HeaderRight>
        }
      />
      <CardStyles.CardList>
        {/* 

          // 
          // Variables
          // 
          */}
        {!!Object.keys(variablesMap).length && (
          <SoloListCardStyles.Container>
            <SoloListCardStyles.Title>Variables</SoloListCardStyles.Title>
            <Styles.FancyCodeDisplayerContainer>
              <FancyCodeDisplayer
                padLineNumbersWithZeros={2}
                includeBorderBackground={false}
                contentString={Object.entries(variablesMap)
                  .map(
                    ([variable, value]) =>
                      `${variable}` +
                      (value.extractionType.oneofKind === undefined ? ' {}' : '') +
                      (value.extractionType.oneofKind === 'dynamicMetadata'
                        ? `\n  dynamicMetadata:` +
                        `\n    key: ${value.extractionType.dynamicMetadata.key}` +
                        `\n    metadataNamespace: ${value.extractionType.dynamicMetadata.metadataNamespace}`
                        : '') +
                      (value.extractionType.oneofKind === 'graphqlArg'
                        ? `\n  graphqlArg: ${value.extractionType.graphqlArg}`
                        : '') +
                      (value.extractionType.oneofKind === 'jsonValue'
                        ? `\n  json: ${JSON.stringify(convertFromPBValue(value.extractionType.jsonValue))}`
                        : '') +
                      (value.extractionType.oneofKind === 'requestHeader'
                        ? `\n  requestHeader: ${value.extractionType.requestHeader}`
                        : '')
                  )
                  .join('\n')}
                type={'yaml'}
              />
            </Styles.FancyCodeDisplayerContainer>
          </SoloListCardStyles.Container>
        )}
        {/* 

          // 
          // Request
          // 
          */}
        <CardStyles.Card>
          <CardStyles.CardHeader>
            Request
            <GqlInfoFromHeadersMap
              headersMap={Object.entries(restResolver?.request?.headers ?? {})}
              defaultHttpMethod={defaultHttpMethod}
              httpMethodTooltipText='The default method is "POST" if there is a request body, otherwise it is "GET". This can be changed by adding a ":method" header with a value for the new HTTP method.'
            />
            <CardStyles.CardHeaderRightIcon>
              <Asset.RequestIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          <GqlRestResolverRequestTabs resolver={resolver} />
        </CardStyles.Card>
        {/* 

          // 
          // Response
          // 
          */}
        <CardStyles.Card>
          <CardStyles.CardHeader>
            Response
            <CardStyles.CardHeaderRightIcon>
              <Asset.ResponseIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          {resolverResultTransform ? (
            <VariableTransformationLabel title='Result Transform' variableTransformation={resolverResultTransform} />
          ) : (
            <i>No Transformation</i>
          )}
        </CardStyles.Card>
      </CardStyles.CardList>
    </SoloModal>
  );
};
