import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { FieldDefinitionNode, InputValueDefinitionNode, TypeNode } from 'graphql';
import { useContext } from 'react';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { getFieldReturnType } from 'utils/graphql-helpers';
import { GqlLandingContext } from '../../context/GqlLandingContext';
import { GqlDetailsStyles } from '../GqlDetails.style';

/**
 * Renders only the GraphQL type of the `field` prop.
 * If the base type of the `field` exists in the `schema`, this renders a link
 * (along with any prefix + suffix). e.g. `[[Product]!]` would show
 * `Product` as a link, surrounded by `[[`, and `]!]`.
 */
const FieldTypeValue = ({
  field,
  onTypeClick
}: {
  field: FieldDefinitionNode | TypeNode | InputValueDefinitionNode;
  onTypeClick(type: string, searchForTheTab: boolean): void;
}) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { schema } = gqlCtx;
  const returnType = getFieldReturnType(field);
  return (
    <GqlDetailsStyles.MonospaceCodeStyle>
      {returnType.parts.prefix}
      {schema.definitions.find(d => d.name?.value === returnType.parts.base) ? (
        <UnstyledButton onClick={() => onTypeClick(returnType.parts.base, true)} aria-label='Select type'>
          <SoloLinkStyles.SoloLinkLooks displayInline>{returnType.parts.base}</SoloLinkStyles.SoloLinkLooks>
        </UnstyledButton>
      ) : (
        <>{returnType.parts.base}</>
      )}
      {returnType.parts.suffix}
    </GqlDetailsStyles.MonospaceCodeStyle>
  );
};

export default FieldTypeValue;
