import { FieldDefinitionNode, InputValueDefinitionNode } from 'graphql';
import { useMemo } from 'react';
import { generateUUID } from 'utils/helpers';
import { SupportedDefinitionNode } from './graphql-helpers';

/**
 * Returns a scaled font size based on the provided
 * text length (more characters === smaller).
 * @param textLength
 * @returns
 */
export function getFontSize(textLength: number, baseTextLength = 9, smallFontSize = '1.25em', largeFontSize = '1.5em') {
  const diff = textLength - baseTextLength;
  if (diff >= 1) return smallFontSize;
  else return largeFontSize;
}

/**
 * Generates mock data for the `GqlSchemaSearchBox` `allSearchBoxItems` prop.
 * @param numItems
 * @returns
 */
export const generateMockSearchBoxItems = (numItems: number) => {
  // Mock data
  let items = [] as IGqlSchemaSearchBoxItem[];
  for (let i = 0; i < numItems; i++) {
    items.push({
      id: i,
      title: `Mock item: ${generateUUID()}`,
      description: `Mock description ${i} for item: ${generateUUID()}.`,
      path: generateUUID()
    });
  }
  return items;
};

export interface IGqlSchemaSearchBoxItem {
  id: number;
  title: string;
  description?: string;
  path: string;
}

export type GqlSchemaTabProps<T extends SupportedDefinitionNode | FieldDefinitionNode> = {
  tabHeader: string;
  definitions: T[];
  focusedPath: string[];
  onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
};

export type GqlSchemaTabItemProps<T extends SupportedDefinitionNode | FieldDefinitionNode | InputValueDefinitionNode> =
  {
    tabHeader: string;
    focusedItem: T;
    focusedPath: string[];
    onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
  };

export type GqlSchemaTabTableProps<T extends SupportedDefinitionNode | FieldDefinitionNode | InputValueDefinitionNode> =
  {
    tabHeader: string;
    definitions: T[] | readonly T[];
    extendedDefinitions?: T[] | readonly T[];
    showArgumentsColumnIfNoArgs?: boolean;
    /** This just hides the table from the page (and doesn't remove it
     * or clear the state), so clicking back to the table from a different
     * view takes the user back to the page they were on.
     **/
    hidden?: boolean;
    onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
  };
export interface IGqlSchemaTypeInfo {
  tabHeader: string;
  testId: string;
  definitions: (SupportedDefinitionNode | FieldDefinitionNode)[];
  Component: (props: GqlSchemaTabProps<SupportedDefinitionNode | FieldDefinitionNode>) => JSX.Element;
}
export interface IGqlSchemaTypeInfoIndexed extends IGqlSchemaTypeInfo {
  index: number;
}

/**
 * This gets the `focusedItem` (the graphql node definition) using the
 * `focusedPath` (the breadcrumbs), and a list of `definitions`.
 */
export const useGetFocusedItem = <T extends { name: { value: string } } | { name: { value: string } }>(
  definitions: T[] | readonly T[] | undefined,
  focusedPath: string[],
  pathIndex = 0
) =>
  useMemo(() => {
    if (focusedPath.length <= pathIndex || !definitions) return undefined;
    return definitions.find(d => 'name' in d && d.name?.value === focusedPath[pathIndex]) as T;
  }, [definitions, focusedPath, pathIndex]);

/**
 * This is used for rendering the description value in a table, or preparing the data for tables.
 * It uses a common indicator for "no description found".
 */
export const getDescriptionColumnValue = <T extends { description?: { value: string } }>(node: T) =>
  node.description?.value.trim() ?? '\\-';
