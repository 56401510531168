import { useMemo } from 'react';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { OpenAPIV3 } from 'openapi-types';
import { HttpMethodBubbleList } from 'Components/Common/HttpMethodBubble';
import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { getEnumValues } from 'utils/helpers';
import { MonospaceText } from 'Styles/CommonEmotions/text';

type TableFields = {
  key: string;
  path: string;
  operations: OpenAPIV3.HttpMethods[];
};

const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Path',
    dataIndex: 'path',
    render(path) {
      return <MonospaceText>{path}</MonospaceText>;
    }
  },
  {
    title: 'Operations',
    dataIndex: 'operations',
    render(operations) {
      return <HttpMethodBubbleList methods={operations} />;
    }
  }
];

export const PathsTab = ({ paths, filter }: { paths: OpenAPIV3.PathsObject; filter: string }) => {
  const tableData = useMemo<TableFields[]>(
    () =>
      Object.entries(paths)
        .filter(([path]) => path.toLowerCase().includes(filter.toLowerCase()))
        .map(([path, itemMap]) => {
          const itemKeys = Object.keys(itemMap ?? {});
          // itemKeys object can contain both HTTP methods and other properties - filter out any key that isn't a method
          const methods = getEnumValues(OpenAPIV3.HttpMethods).filter(m => itemKeys.includes(m));
          return { key: path, path, operations: methods };
        }),
    [paths, filter]
  );
  const { paginatedData, pagingData } = useSoloPagingWithDataFiltering(tableData, 5);

  return (
    <Spacer padding={5}>
      <SoloTable columns={columns} dataSource={paginatedData} paging={pagingData} />
    </Spacer>
  );
};
