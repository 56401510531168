import { css } from '@emotion/react';
import styled from '@emotion/styled';

export namespace SoloPaginationStyles {
  interface PaginationRowProps {
    small?: boolean;
    hidePages?: boolean;
  }
  export const PaginationRow = styled.div<PaginationRowProps>(
    ({ theme, small, hidePages }) => css`
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      margin-right: 10px;
      padding-bottom: 10px;

      ${hidePages
        ? css`
            .ant-pagination-item {
              display: none;
            }
          `
        : ''}

      /* First rule so it works outside of ant-pagination, second so it overrides values with specificity */
      .ant-pagination-total-text, .ant-pagination .ant-pagination-total-text {
        white-space: nowrap;
        flex: 1;
        color: ${theme.augustGrey};
        margin-right: 3px;
        display: flex;
        align-items: center;
      }

      .ant-pagination {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link,
        .ant-pagination-item,
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: ${small ? 4 : 6}px;
        }

        ${small
          ? css`
              .ant-pagination-total-text {
                height: 24px;
              }
              .ant-pagination-item {
                min-width: 24px;
                height: 24px;
                line-height: 22px;
                font-size: 12px;
              }
              .ant-pagination-prev,
              .ant-pagination-next,
              .ant-pagination-jump-prev,
              .ant-pagination-jump-next {
                min-width: 24px;
                height: 24px;
                line-height: 22px;
              }
              .ant-pagination-prev,
              .ant-pagination-jump-prev,
              .ant-pagination-jump-next,
              .ant-pagination-item {
                margin-right: 5px;
              }

              .ant-pagination-options {
                margin-left: 5px;
              }
              .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                height: 24px;
                padding: 0 8px;
                font-size: 12px;

                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                  line-height: 22px;
                }
              }
            `
          : ``}
      }

      .ant-table-pagination {
        &.ant-pagination {
          margin: 0;
        }

        &[unselectable='unselectable'] {
          opacity: 0.25;
        }

        .ant-pagination-prev,
        .ant-pagination-next {
        }

        a,
        a.ant-pagination-item-link,
        .ant-pagination-item-active {
          background: none;
          border: none;
          color: ${theme.septemberGrey};
          line-height: 30px;
          height: 30px;
        }
      }
    `
  );
}
