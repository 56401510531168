import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';

export const DISABLE_REFRESH = 999999999;

export function convertToStringArray(values: string | null): string[] {
  if (!values?.length) {
    return [];
  }
  const stringArray: string[] = [];
  values?.split(';').forEach(val => {
    if (val !== null) {
      stringArray.push(val);
    }
  });
  return stringArray;
}

export function convertToObjectRefArray(values: string | null): ObjectRef[] {
  if (!values?.length) {
    return [];
  }
  const objRefArray: ObjectRef[] = [];
  values?.split(';').forEach(val => {
    if (val !== null) {
      const [namespace, name] = val.split('.');
      if (!!namespace && !!name) {
        objRefArray.push({ namespace, name });
      }
    }
  });
  return objRefArray;
}

interface filterOptionType {
  disabled?: boolean;
  value: string;
}
/**
 * Sorts by:
 * 1. Disabled status,
 * 2. Alphabetically by string value (with numbers stripped),
 * 3. By ascending number order (if numbers exist in the string value).
 * @param a
 * @param b
 * @returns `-1`, `0`, or `1`
 */
export function filterOptionsSort(a: filterOptionType, b: filterOptionType) {
  if (a.disabled !== b.disabled) return a.disabled ? 1 : -1;
  // First check the cluster names with numbers stripped.
  const aNoNums = a.value.replace(/\d/g, '');
  const bNoNums = a.value.replace(/\d/g, '');
  const noNumsResult = aNoNums.localeCompare(bNoNums);
  if (noNumsResult !== 0) return noNumsResult;
  // Break ties with number comparisons.
  const aNums = Number.parseInt(a.value.replace(/^\d/g, ''));
  const bNums = Number.parseInt(b.value.replace(/^\d/g, ''));
  if (aNums === bNums) return 0;
  return aNums < bNums ? -1 : 1;
}
