import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { healthColors } from 'Styles/colors';

export namespace HealthCountBoxStyles {
  export const CountBoxButton = styled.button<{
    health: 'normal' | 'error' | 'idle' | 'warning';
    isSelected: boolean;
    isSelectable: boolean;
  }>(
    ({ health, isSelected, isSelectable }) => css`
      border: unset;
      border-radius: 3px;
      background: unset;
      display: flex;
      align-items: center;
      margin-left: 8px;
      font-size: 14px;
      line-height: 26px;
      height: 26px;
      padding: 0px;
      margin: 0 10px;
      color: ${healthColors[health].dark};
      ${isSelectable
        ? css`
            &:hover {
              text-decoration: underline;
              &:active {
                opacity: 0.75;
              }
            }
          `
        : css`
            cursor: unset;
          `}
      ${isSelected
        ? css`
            box-shadow: 0 0 0 5px white, 0 0 1px 6px ${healthColors[health].dark};
          `
        : ''}
    `
  );
  export const CountDisplay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    padding: 0 4px;
    border-radius: 24px;
    border: 2px solid currentColor;
    font-weight: 600;
    font-size: 16px;
    margin-right: 5px;
  `;
}
