/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/v3/range.proto" (package "envoy.type.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Range]

/**
 * Specifies the int64 start and end of the range using half-open interval semantics [start,
 * end).
 *
 * @generated from protobuf message envoy.type.v3.Int64Range
 */
export interface Int64Range {
    /**
     * start of the range (inclusive)
     *
     * @generated from protobuf field: int64 start = 1;
     */
    start: number;
    /**
     * end of the range (exclusive)
     *
     * @generated from protobuf field: int64 end = 2;
     */
    end: number;
}
/**
 * Specifies the int32 start and end of the range using half-open interval semantics [start,
 * end).
 *
 * @generated from protobuf message envoy.type.v3.Int32Range
 */
export interface Int32Range {
    /**
     * start of the range (inclusive)
     *
     * @generated from protobuf field: int32 start = 1;
     */
    start: number;
    /**
     * end of the range (exclusive)
     *
     * @generated from protobuf field: int32 end = 2;
     */
    end: number;
}
/**
 * Specifies the double start and end of the range using half-open interval semantics [start,
 * end).
 *
 * @generated from protobuf message envoy.type.v3.DoubleRange
 */
export interface DoubleRange {
    /**
     * start of the range (inclusive)
     *
     * @generated from protobuf field: double start = 1;
     */
    start: number;
    /**
     * end of the range (exclusive)
     *
     * @generated from protobuf field: double end = 2;
     */
    end: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Int64Range$Type extends MessageType<Int64Range> {
    constructor() {
        super("envoy.type.v3.Int64Range", [
            { no: 1, name: "start", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "end", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.Int64Range" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.v3.Int64Range
 */
export const Int64Range = new Int64Range$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Int32Range$Type extends MessageType<Int32Range> {
    constructor() {
        super("envoy.type.v3.Int32Range", [
            { no: 1, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "end", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.Int32Range" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.v3.Int32Range
 */
export const Int32Range = new Int32Range$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoubleRange$Type extends MessageType<DoubleRange> {
    constructor() {
        super("envoy.type.v3.DoubleRange", [
            { no: 1, name: "start", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "end", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.DoubleRange" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.v3.DoubleRange
 */
export const DoubleRange = new DoubleRange$Type();
