import { CollapseToggle } from 'Components/Common/CollapseToggle';
import { Svg } from 'Components/Common/Svg';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Link, Location, useLocation } from 'react-router-dom';
import { isNonNullableAndNonFalse } from 'utils/helpers';
import { useSidebarContext } from './SidebarContext';
import { SidebarSectionStyles as Styles } from './SidebarSection.style';

function isLinkActive(location: Location, linkToCheck: string | undefined, matcher?: 'exact' | LinkMatcherFunc) {
  if (typeof linkToCheck === 'undefined') {
    return false;
  }
  if (typeof matcher === 'function') {
    return matcher(location);
  }
  // Remove first and last "/" if it exists.
  // ("/services/test/" => "services/test")
  linkToCheck = linkToCheck.replace(/\/$/, '').replace(/^\//, '');
  const curPathname = location.pathname.replace(/\/$/, '').replace(/^\//, '');
  if (matcher === 'exact') {
    // If the matcher is exact, compare the paths.
    // The URL search parameters and hash are not part of this check,
    // since a link being active depends on the pathname only.
    return curPathname === linkToCheck;
  }
  // If we aren't matching the exact path (if matcher === undefined), check that the URL path
  // components in the linkToCheck are a subset of the current URL path components.
  const curPathPieces = curPathname.split('/');
  const linkToCheckPieces = linkToCheck.split('/');
  if (curPathPieces.length < linkToCheckPieces.length) {
    return false;
  }
  // If we get here, curPathPieces.length >= linkToCheckPieces.length.
  for (let i = 0; i < linkToCheckPieces.length; i++) {
    if (curPathPieces[i] !== linkToCheckPieces[i]) {
      return false;
    }
  }
  return true;
}

type LinkMatcherFunc = (location: Location) => boolean;
type LinkMatcher = 'exact' | LinkMatcherFunc;
const useIsLinkActive = (linkToCheck: string | undefined, matcher?: 'exact' | LinkMatcherFunc) => {
  const routerLocation = useLocation();
  return useMemo(
    () => isLinkActive(routerLocation, linkToCheck, matcher),
    [routerLocation.pathname, linkToCheck, matcher]
  );
};

interface IsChildActiveData {
  url?: string;
  urlMatcher?: LinkMatcher;
  items?: Array<false | IsChildActiveData>;
}
function isAChildActive(location: Location, items?: Array<false | IsChildActiveData>): boolean {
  return (
    items
      ?.filter(isNonNullableAndNonFalse)
      .filter(itm => itm.url)
      .some(
        itm =>
          isLinkActive(location, itm.url!, itm.urlMatcher) ||
          (!!itm.items?.length && isAChildActive(location, itm.items))
      ) ?? false
  );
}
const useIsAChildActive = (items?: Array<false | IsChildActiveData> | undefined) => {
  const routerLocation = useLocation();
  return useMemo(
    () => isAChildActive(routerLocation, items),
    [routerLocation.pathname, routerLocation.search, routerLocation.hash, items?.length ?? 0]
  );
};

type OptionalTooltipProps = { flag: boolean; children: React.ReactNode } & React.ComponentProps<typeof Tooltip>;
const OptionalTooltip = ({ flag, children, ...props }: OptionalTooltipProps) => {
  return <>{flag ? <Tooltip {...props}>{children}</Tooltip> : children}</>;
};

interface SubItemProps {
  label: string;
  url?: string;
  urlMatcher?: LinkMatcher;
  items?: Array<false | SubItemPropsForList>;
  level: number;
  tooltipDesign: boolean;
  'data-testid'?: string;
}
type SubItemPropsForList = Omit<SubItemProps, 'level' | 'tooltipDesign'>;

const SubItem = ({
  label,
  url,
  urlMatcher,
  items: itemsIn,
  level,
  tooltipDesign,
  'data-testid': testId
}: SubItemProps) => {
  const active = useIsLinkActive(url, urlMatcher);
  const [collapsed, setCollapsed] = useState(true);
  const items = itemsIn?.filter(isNonNullableAndNonFalse);
  const childActive = useIsAChildActive(items);
  const toggleCollapse = !!items?.length ? () => setCollapsed(b => !b) : undefined;
  const controlsId = `navbar-${label.toLowerCase().replaceAll(' ', '_')}-submenu-lv${level}`;

  // If a child link is currently active (visited) then open all parent menus to be able to see it
  useEffect(() => {
    if (childActive && collapsed) {
      setCollapsed(false);
    }
  }, [childActive]); // do NOT add 'collapsed' to deps; it will force the menu open if child active - we only want to open it on active child change

  return (
    <Styles.SubItemWrapper>
      <Styles.SubItem active={active} level={level} compactPadding={tooltipDesign}>
        {!!url ? (
          <Link to={url} className='action' aria-label={label} data-testid={testId}>
            {label}
          </Link>
        ) : (
          <UnstyledButton
            onClick={toggleCollapse}
            aria-expanded={!collapsed}
            aria-controls={controlsId}
            className='action'
            aria-label={label}
            data-testid={testId}>
            {label}
          </UnstyledButton>
        )}
        {!!items?.length && (
          <CollapseToggle
            collapsed={collapsed}
            onClick={toggleCollapse}
            aria-expanded={!collapsed}
            aria-controls={controlsId}
            small
            aria-label={`${label} sub menu collapse toggle`}
          />
        )}
      </Styles.SubItem>
      <AnimateHeight height={!collapsed ? 'auto' : 0}>
        <SubItemList items={items} level={level + 1} tooltipDesign={tooltipDesign} id={controlsId} />
      </AnimateHeight>
    </Styles.SubItemWrapper>
  );
};

interface SubItemListProps {
  items: Array<SubItemPropsForList> | undefined;
  level?: number;
  tooltipDesign?: boolean;
  id?: string;
}
const SubItemList = ({ items, level = 0, tooltipDesign, id }: SubItemListProps) => {
  if (!items?.length) return <></>;
  return (
    <Styles.SubItemList tooltipDesign={!!tooltipDesign && level === 0} id={id}>
      {items?.map(data => (
        <SubItem key={data.label} {...data} level={level} tooltipDesign={!!tooltipDesign} />
      ))}
    </Styles.SubItemList>
  );
};

const SidebarCollapsableText = ({ visible, text }: { visible: boolean; text: string }) => {
  //
  // This checks when the sidebar is initially closed.
  const [closedAndNeverBeenOpened, setClosedAndNeverBeenOpened] = useState(!visible);
  useEffect(() => {
    if (closedAndNeverBeenOpened && visible) {
      setClosedAndNeverBeenOpened(false);
    }
  }, [visible]);

  return (
    <Styles.CollapsableText
      // Don't fade if the sidebar is closed and never been opened.
      fadingIn={visible || closedAndNeverBeenOpened}
      animationPlaying={!closedAndNeverBeenOpened}>
      {text}
    </Styles.CollapsableText>
  );
};

const TopLevelItemInner = ({
  sidebarCollapsed,
  label,
  icon
}: {
  sidebarCollapsed: boolean;
  label: string;
  icon: React.ReactNode;
}) => (
  <>
    <OptionalTooltip
      flag={sidebarCollapsed}
      title={label}
      placement='right'
      trigger='hover'
      overlayStyle={{ pointerEvents: 'none' }}>
      <Svg asset={icon} size={26} color='currentColor' />
    </OptionalTooltip>
    <SidebarCollapsableText visible={!sidebarCollapsed} text={label} />
  </>
);

export interface TopLevelItemProps {
  icon: React.ReactNode;
  label: string;
  url?: string;
  urlMatcher?: LinkMatcher;
  items?: Array<false | SubItemPropsForList>;
  'data-testid'?: string;
}
const TopLevelItem = ({ icon, label, url, urlMatcher, items: itemsIn, 'data-testid': testId }: TopLevelItemProps) => {
  const { collapsed: sidebarCollapsed } = useSidebarContext();
  const active = useIsLinkActive(url, urlMatcher);
  const [collapsed, setCollapsed] = useState(true);
  const items = itemsIn?.filter(isNonNullableAndNonFalse);
  const childActive = useIsAChildActive(items);
  const toggleCollapse = !!items?.length && !sidebarCollapsed ? () => setCollapsed(b => !b) : undefined;

  // If a child link is currently active (visited) then open all parent menus to be able to see it
  useEffect(() => {
    if (childActive && collapsed) {
      setCollapsed(false);
    }
  }, [childActive]); // do NOT add 'collapsed' to deps; it will force the menu open if child active - we only want to open it on active child change

  // If no children AND not a link, hide
  if (!items?.length && !url) {
    return <></>;
  }

  const controlsId = `navbar-${label.toLowerCase().replaceAll(' ', '_')}-submenu`;
  return (
    <Styles.TopLevelItemWrapper sidebarCollapsed={sidebarCollapsed} submenuOpen={!collapsed}>
      <Styles.TopLevelItem active={active} submenuOpen={!collapsed}>
        {!!url ? (
          <Link to={url} className='action' aria-label={label} data-testid={testId}>
            <TopLevelItemInner sidebarCollapsed={sidebarCollapsed} label={label} icon={icon} />
          </Link>
        ) : (
          <UnstyledButton
            onClick={toggleCollapse}
            aria-expanded={!collapsed}
            aria-controls={controlsId}
            className='action'
            aria-label={label}
            data-testid={testId}>
            <TopLevelItemInner sidebarCollapsed={sidebarCollapsed} label={label} icon={icon} />
          </UnstyledButton>
        )}
        {!!items?.length && (
          <CollapseToggle
            collapsed={collapsed}
            onClick={toggleCollapse}
            aria-expanded={!collapsed}
            aria-controls={controlsId}
            small
            aria-label={`${label} sub menu collapse toggle`}
          />
        )}
      </Styles.TopLevelItem>
      <AnimateHeight height={!collapsed && !sidebarCollapsed ? 'auto' : 0}>
        <SubItemList items={items} id={controlsId} />
      </AnimateHeight>
      {sidebarCollapsed && (
        // Use separate duplicate list for hover to avoid animation issues when sidebar is in-between states
        <Styles.TopLevelHoverContent>
          <SubItemList items={items} tooltipDesign id={controlsId} />
        </Styles.TopLevelHoverContent>
      )}
    </Styles.TopLevelItemWrapper>
  );
};

interface SidebarSectionProps {
  heading?: string;
  items: Array<false | TopLevelItemProps>;
}
export const SidebarSection = ({ heading, items: itemsIn }: SidebarSectionProps) => {
  const { collapsed: sidebarCollapsed } = useSidebarContext();
  const items = itemsIn.filter(isNonNullableAndNonFalse);
  return (
    <Styles.Container sidebarCollapsed={sidebarCollapsed}>
      {!!heading && <Styles.Heading>{heading}</Styles.Heading>}
      {!!items.length && (
        <Styles.TopLevelItemList>
          {items.map(itemProps => (
            <TopLevelItem key={itemProps.label} {...itemProps} />
          ))}
        </Styles.TopLevelItemList>
      )}
    </Styles.Container>
  );
};
