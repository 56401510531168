import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { DashboardStyles } from '../../../DashboardLanding.style';
import { useIstioVersionInsightsToVersionDict } from '../hooks';
import { IstioVersionsSoloListCard } from './IstioVersionsSoloListCard';

export const IstioHealthEnvironmentCheckSection = ({
  istioEnvironment
}: {
  istioEnvironment: Insight[] | undefined;
}) => {
  const versionsDict = useIstioVersionInsightsToVersionDict(istioEnvironment);

  return (
    <GridLayout templateRows='auto 1fr auto' data-testid='istio-environment-check-section'>
      <DashboardStyles.SubHeader>Environment Check</DashboardStyles.SubHeader>
      <ErrorBoundary fallback={'Problems finding Istio version information...'}>
        <IstioVersionsSoloListCard versionsDict={versionsDict} />
      </ErrorBoundary>
      <FlexLayout pt={3} justifyContent='space-between'>
        <DashboardStyles.VersionsCountCircleAndTextContainer>
          Istio Versions{' '}
          <DashboardStyles.VersionsCountCircle data-testid='istio-versions-count'>
            {Object.keys(versionsDict).length}
          </DashboardStyles.VersionsCountCircle>
        </DashboardStyles.VersionsCountCircleAndTextContainer>
        {/* <FlexLayout vertical>
          <DashboardStyles.CardBottomLink>
            <SoloLink small link='/TODO' withArrow>
              DETAILS
            </SoloLink>
          </DashboardStyles.CardBottomLink>
        </FlexLayout> */}
      </FlexLayout>
    </GridLayout>
  );
};
