import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { BasicDestinationsTable } from '../../Destinations/BasicDestinationsTable';

const { useGetOutlierDetectionPolicyDetails } = policiesApi;

const policyType = PolicyType.OUTLIER_DETECTION;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const OutlierDetectionDetails = ({ clusterObjRef }: Props) => {
  di(useGetOutlierDetectionPolicyDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();

  const { data: policyData, error: policyError } = useGetOutlierDetectionPolicyDetails(
    clusterObjRef,
    apiPaginationObject
  );

  useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            !!policyData.consecutiveErrors && {
              icon: undefined, // TODO
              title: 'Consecutive Errors',
              data: policyData.consecutiveErrors?.value
            },
            !!policyData.consecutiveGatewayErrors && {
              icon: undefined, // TODO
              title: 'Consecutive Gateway Errors',
              data: policyData.consecutiveGatewayErrors?.value
            },
            {
              icon: <Asset.TimeoutPolicyIcon />,
              title: 'Interval',
              data: policyData.interval ? policyData.interval.seconds + 's' : 'No interval configured'
            },
            {
              icon: <Asset.TimeoutPolicyIcon />,
              title: 'Base Ejection Time',
              data: policyData.baseEjectionTime
                ? policyData.baseEjectionTime.seconds + 's'
                : 'No base ejection time configured'
            },
            {
              icon: undefined, // TODO
              title: 'Max Ejection Percent',
              data:
                policyData.maxEjectionPercent !== undefined
                  ? policyData.maxEjectionPercent + '%'
                  : 'No max ejection configured'
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable destinations={policyData.destinations?.destinations} paging={pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
