import { Asset } from 'assets';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { useState } from 'react';
import { SoloButton, SoloButtonSolid } from 'Styles/CommonEmotions/button';
import { AddClusterModalBody } from './AddClusterModalBody';
import { EmptyOverviewStyles } from './EmptyOverview.style';
import { AddWorkspaceModalBody } from './AddWorkspaceModalBody';
import { docLinks } from 'utils/url-external-links-map';

export const EmptyOverview = () => {
  return (
    <EmptyOverviewStyles.Container data-testid='empty-overview'>
      <EmptyOverviewStyles.WelcomeBox>
        <EmptyOverviewStyles.WelcomeTitleRow>
          <div>Welcome to Gloo Platform! With Gloo Platform, you can:</div>
          <Asset.HealthIcon />
        </EmptyOverviewStyles.WelcomeTitleRow>
        <EmptyOverviewStyles.WelcomeItems>
          <EmptyOverviewStyles.WelcomeItemBox>
            <EmptyOverviewStyles.WelcomeItemCheckbox>
              <Asset.CheckmarkBlue />
            </EmptyOverviewStyles.WelcomeItemCheckbox>
            <div>
              Manage your teams' services across Kubernetes namespaces and clusters with multitenant Gloo Platform
              workspaces.
            </div>
          </EmptyOverviewStyles.WelcomeItemBox>
          <EmptyOverviewStyles.WelcomeItemBox>
            <EmptyOverviewStyles.WelcomeItemCheckbox>
              <Asset.CheckmarkBlue />
            </EmptyOverviewStyles.WelcomeItemCheckbox>
            <div>
              Set up federation and isolation for workspaces in a zero-trust architecture that complies with regulations
              such as FIPS.
            </div>
          </EmptyOverviewStyles.WelcomeItemBox>
          <EmptyOverviewStyles.WelcomeItemBox>
            <EmptyOverviewStyles.WelcomeItemCheckbox>
              <Asset.CheckmarkBlue />
            </EmptyOverviewStyles.WelcomeItemCheckbox>
            <div>
              Visualize key metrics and logs for your service mesh traffic in the interactive Gloo Platform Graph UI.
            </div>
          </EmptyOverviewStyles.WelcomeItemBox>
          <EmptyOverviewStyles.WelcomeItemBox>
            <EmptyOverviewStyles.WelcomeItemCheckbox>
              <Asset.CheckmarkBlue />
            </EmptyOverviewStyles.WelcomeItemCheckbox>
            <div>
              Define policies to secure traffic to your services, such as retries, timeouts, external auth, or rate
              limiting.
            </div>
          </EmptyOverviewStyles.WelcomeItemBox>
          <EmptyOverviewStyles.WelcomeItemBox>
            <EmptyOverviewStyles.WelcomeItemCheckbox>
              <Asset.CheckmarkBlue />
            </EmptyOverviewStyles.WelcomeItemCheckbox>
            <div>Automatically discover Istio service mesh resources across one or more Kubernetes clusters.</div>
          </EmptyOverviewStyles.WelcomeItemBox>
          <EmptyOverviewStyles.WelcomeItemBox>
            <EmptyOverviewStyles.WelcomeItemCheckbox>
              <Asset.CheckmarkBlue />
            </EmptyOverviewStyles.WelcomeItemCheckbox>
            <div>
              Deploy secure, reliable, Istio-based service meshes with security patch support for Istio n-4 versions.
            </div>
          </EmptyOverviewStyles.WelcomeItemBox>
        </EmptyOverviewStyles.WelcomeItems>
      </EmptyOverviewStyles.WelcomeBox>
      <EmptyOverviewStyles.WorkspacesBox>
        <EmptyWorkspaces />
      </EmptyOverviewStyles.WorkspacesBox>
      <EmptyOverviewStyles.ClustersBox>
        <EmptyClusters />
      </EmptyOverviewStyles.ClustersBox>
    </EmptyOverviewStyles.Container>
  );
};

export const EmptyWorkspaces = () => {
  const [addWorkspaceModalOpen, setAddWorkspaceModalOpen] = useState(false);

  return (
    <EmptyOverviewStyles.WorkspaceContent>
      <EmptyOverviewStyles.Top>
        <EmptyOverviewStyles.RegisteringIconHolder>
          <Asset.WorkspaceIcon />
        </EmptyOverviewStyles.RegisteringIconHolder>

        <EmptyOverviewStyles.RegisteringTitle>No Workspaces Found</EmptyOverviewStyles.RegisteringTitle>
        <EmptyOverviewStyles.RegisteringBody>
          Group the Kubernetes namespaces and clusters for each of your teams into Gloo Platform workspaces. Gloo
          Platform automatically discovers and shares service mesh resources with the workspace. You can even share
          certain resources with other workspaces by defining import and export settings for the workspaces
        </EmptyOverviewStyles.RegisteringBody>
      </EmptyOverviewStyles.Top>

      <EmptyOverviewStyles.RegisterButtons>
        <SoloButtonSolid onClick={() => setAddWorkspaceModalOpen(true)}>ADD WORKSPACE</SoloButtonSolid>
        <ExternalSoloLink href={docLinks.enterprise.multi_tenancy} newTab>
          <SoloButton>VIEW DOCS</SoloButton>
        </ExternalSoloLink>
      </EmptyOverviewStyles.RegisterButtons>
      {addWorkspaceModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddWorkspaceModalOpen(false)}>
          <AddWorkspaceModalBody />
        </SoloModal>
      )}
    </EmptyOverviewStyles.WorkspaceContent>
  );
};

export const EmptyClusters = () => {
  const [addClusterModalOpen, setAddClusterModalOpen] = useState(false);

  return (
    <EmptyOverviewStyles.ClusterContent>
      <EmptyOverviewStyles.Top>
        <EmptyOverviewStyles.RegisteringIconHolder>
          <Asset.ClusterIcon />
        </EmptyOverviewStyles.RegisteringIconHolder>

        <EmptyOverviewStyles.RegisteringTitle>No Clusters Found</EmptyOverviewStyles.RegisteringTitle>
        <EmptyOverviewStyles.RegisteringBody>
          To add workload clusters, create KubernetesCluster resources in your Gloo Platform management cluster. Then,
          you can use Gloo Platform custom resources to manage Istio and control service mesh traffic.
        </EmptyOverviewStyles.RegisteringBody>
      </EmptyOverviewStyles.Top>

      <EmptyOverviewStyles.RegisterButtons>
        <SoloButtonSolid onClick={() => setAddClusterModalOpen(true)}>REGISTER CLUSTER</SoloButtonSolid>
        <ExternalSoloLink href={docLinks.enterprise.meshctl_cluster} newTab>
          <SoloButton>VIEW DOCS</SoloButton>
        </ExternalSoloLink>
      </EmptyOverviewStyles.RegisterButtons>
      {addClusterModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddClusterModalOpen(false)}>
          <AddClusterModalBody />
        </SoloModal>
      )}
    </EmptyOverviewStyles.ClusterContent>
  );
};
