import { Global } from '@emotion/react';
import { gqlApi } from 'Api/graphql';
import { clusterRefsAreEqual } from 'Api/helpers';
import { buildSchema } from 'graphql';
import { GetGraphqlApiExplorerSettingsResponse_GatewayOption } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useContext, useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'utils/hooks';
import { GqlLandingContext } from '../context/GqlLandingContext';
import GqlExplorerGlobalStyles from './GqlExplorer.global.style';
import { GqlExplorerSettingsModal } from './GqlExplorerSettingsModal';
import GqlExplorerTopCard from './GqlExplorerTopCard';
import GqlExplorer from './ThemedGraphiql/GqlExplorer';
import { GqlExplorerStyles } from './ThemedGraphiql/GqlExplorer.style';

const { useGetGraphqlExplorerSettings } = gqlApi;

export function getGraphqlStoragePrefix(routeTableRef: ClusterObjectRef | undefined, istioRouteName: string) {
  return `${routeTableRef?.clusterName}/${routeTableRef?.namespace}/${routeTableRef?.name}/${istioRouteName}/`;
}

const GqlExplorerContainer = () => {
  di(useGetGraphqlExplorerSettings, getGraphqlStoragePrefix);
  const gqlCtx = useContext(GqlLandingContext);
  const { api, istioRouteName, routeTableRef } = gqlCtx;
  const [settingsOpen, setSettingsOpen] = useState(false);

  //
  // Graphiql can use a custom `Storage` object.
  // This makes it so the explorer state won't be carried-over or overwritten between API instances.
  //
  const localStoragePrefixed: Storage = useMemo(() => {
    const localStoragePrefix = getGraphqlStoragePrefix(routeTableRef, istioRouteName);
    return {
      getItem: key => localStorage.getItem(`${localStoragePrefix}${key}`),
      setItem: (key, value) => localStorage.setItem(`${localStoragePrefix}${key}`, value),
      removeItem: key => localStorage.removeItem(`${localStoragePrefix}${key}`),
      clear: localStorage.clear,
      length: localStorage.length,
      key: localStorage.key
    };
  }, [routeTableRef, istioRouteName]);

  //
  // Schema URL
  //
  const [urlToDisplay, setUrlToDisplay] = useState<string>();
  const url = useDebounce(urlToDisplay, { skip: urlToDisplay === undefined, debounceMs: 500 });
  useEffect(() => {
    // wait for initial setup.
    // Only save this if no gateway is selected (if this is a custom user-input).
    if (url === undefined || selectedGwOptions !== undefined) return;
    localStoragePrefixed.setItem('url', url);
  }, [url]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [urlError, setUrlError] = useState<string>();
  // This checks if the URL is valid, separate from the graphiql fetcher
  // so we can control when it's called.
  useEffect(() => {
    if (!url) {
      setIsFetching(false);
      setUrlError('No URL endpoint was entered.');
      return;
    }
    (async () => {
      setIsFetching(true);
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'same-origin'
        });
        if (!res.ok) throw new Error(res.statusText);
        setUrlError('');
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('Error when fetching URL:\n', error);
        setUrlError(error.message);
      }
      setIsFetching(false);
    })();
  }, [url, setUrlError, setIsFetching]);

  //
  // Schema
  //
  const [rememberSchemaChange, setRememberSchemaChange] = useState(
    !!localStoragePrefixed.getItem('schemaText')?.trim()
  );
  const [schemaText, setSchemaText] = useState(() => {
    let initialSchemaText = localStoragePrefixed.getItem('schemaText');
    if (!initialSchemaText?.trim()) initialSchemaText = api.schemaDefinition;
    return initialSchemaText;
  });
  const [isSchemaTextOverwritten, setIsSchemaTextOverwritten] = useState(schemaText !== api.schemaDefinition);
  useEffect(() => {
    const schemaTextIsOverwrriten = schemaText !== api.schemaDefinition;
    if (rememberSchemaChange && schemaTextIsOverwrriten) {
      localStoragePrefixed.setItem('schemaText', schemaText);
    } else {
      // We don't want to store schema text if nothing was changed, to avoid confusion when api response changes it
      localStoragePrefixed.removeItem('schemaText');
    }
    setIsSchemaTextOverwritten(schemaTextIsOverwrriten);
  }, [schemaText, rememberSchemaChange]);

  const parsedSchema = useMemo(() => {
    try {
      const schemaTextToUse = schemaText.trim() === '' ? api.schemaDefinition : schemaText;
      return buildSchema(schemaTextToUse, { assumeValidSDL: true });
    } catch {
      return undefined;
    }
  }, [schemaText]);

  //
  // Pre-configures explorer settings with any selected Gateway info.
  //
  // There is a lot going on here, but to summarize:
  //   - The selected gateway is configured through URL search parameters.
  //   - When a gateway is selected, we update the search parameters.
  //     The `gwRef` picks up this change through the `useMemo` dependency, and the URL is updated.
  //   - When NO gateway is selected, we use a "custom", user-defined URL, stored in localstorage.
  //   - When the URL changes (through user-input), we try to find gateway with a matching URL.
  //      If none exist, we use the "custom" URL.
  //
  const customUrlValue = localStoragePrefixed.getItem('url')?.trim() ?? 'http://localhost:8080/graphql';
  let [searchParams, setSearchParams] = useSearchParams();
  const { data: expSettings } = useGetGraphqlExplorerSettings(routeTableRef);
  const gwOptionsList = expSettings?.gatewayOptions ?? [];
  const gwRef: ClusterObjectRef = useMemo(
    () => ({
      name: searchParams.get('gwName') ?? '',
      namespace: searchParams.get('gwNamespace') ?? '',
      clusterName: searchParams.get('gwCluster') ?? ''
    }),
    [searchParams]
  );
  const selectedGwOptions = useMemo(() => {
    // Sets the selectedGwOptions using the specified gateway from the URL search parameters.
    return gwOptionsList?.find(o => clusterRefsAreEqual(o.gatewayRef, gwRef));
  }, [gwRef, gwOptionsList]);
  useEffect(() => {
    if (url === undefined || urlToDisplay === undefined) return;
    if (!!selectedGwOptions) {
      // If there is a selected gateway, we use its URL.
      setUrlToDisplay(selectedGwOptions.url ?? '');
    } else {
      // If there is NOT a selected gateway, we use the custom URL.
      setUrlToDisplay(customUrlValue);
    }
  }, [selectedGwOptions]);
  /**
   * This updates the search parameters with the `gwOption` data, which propagates the change.
   * When it gets picked up by the React dependencies, the URL is udpdated.
   **/
  const onSelectedGwOptionsChange = (gwOption: GetGraphqlApiExplorerSettingsResponse_GatewayOption | undefined) => {
    const newGwRef = gwOption?.gatewayRef;
    if (!newGwRef) {
      setSearchParams({}, { replace: true });
      return;
    }
    setSearchParams(
      {
        gwName: newGwRef.name,
        gwNamespace: newGwRef.namespace,
        gwCluster: newGwRef.clusterName
      },
      { replace: true }
    );
  };
  useEffect(() => {
    if (expSettings === undefined || url === undefined) return;
    // When the URL changes.
    // This finds the gateways with matching URLs.
    const foundOptionsList = gwOptionsList.filter(o => o.url === url || (o.url === undefined && url === ''));
    if (foundOptionsList.length === 0) {
      // If no gateway matches, this is a custom URL. So we update localStorage.
      localStoragePrefixed.setItem('url', url.trim());
    }
    // This checks if we already selected one of the gateways with matching URLs.
    const isSelectedOptionsInList = foundOptionsList.some(o =>
      clusterRefsAreEqual(o.gatewayRef, selectedGwOptions?.gatewayRef)
    );
    // If we did, we can stop here.
    if (isSelectedOptionsInList) return;
    // Else, we can update the selected gateway option.
    onSelectedGwOptionsChange(foundOptionsList.length > 0 ? foundOptionsList[0] : undefined);
  }, [url, expSettings]);

  //
  // Initial URL render.
  //
  useEffect(() => {
    if (expSettings === undefined || urlToDisplay !== undefined) return;
    let initialUrl: string;
    if (!!selectedGwOptions) {
      // If there is a selected gateway, set the URL to that.
      initialUrl = selectedGwOptions.url;
    } else {
      // Else, set the URL to the "custom" value.
      initialUrl = customUrlValue;
    }
    setUrlToDisplay(initialUrl);
  }, [urlToDisplay, setUrlToDisplay, selectedGwOptions, expSettings]);

  //
  // Render
  //
  return (
    <GqlExplorerStyles.GqlExplorerPage>
      {/* <GqlExplorerStyles.GqlExplorerPageBackground /> */}
      <Global styles={GqlExplorerGlobalStyles} />
      <GqlExplorerTopCard gatewayRef={gwRef} />
      <GqlExplorer
        urlError={urlError}
        url={url?.trim() ?? ''}
        parsedSchema={parsedSchema}
        isSchemaTextOverwritten={isSchemaTextOverwritten}
        resetSchemaText={() => {
          setSchemaText(api.schemaDefinition);
          setRememberSchemaChange(false);
        }}
        storage={localStoragePrefixed}
        openSettings={() => setSettingsOpen(true)}
      />
      {settingsOpen && (
        <GqlExplorerSettingsModal
          gatewayRef={gwRef}
          selectedGatewayOptions={selectedGwOptions}
          onSelectedGatewayOptionsChange={onSelectedGwOptionsChange}
          customUrlValue={customUrlValue}
          url={urlToDisplay ?? ''}
          onUrlChange={setUrlToDisplay}
          schemaText={schemaText}
          onSchemaTextChange={setSchemaText}
          isSchemaTextOverwritten={isSchemaTextOverwritten}
          rememberSchemaChange={rememberSchemaChange}
          setRememberSchemaChange={setRememberSchemaChange}
          urlError={urlError}
          isFetching={isFetching}
          onClose={() => setSettingsOpen(false)}
        />
      )}
    </GqlExplorerStyles.GqlExplorerPage>
  );
};

export default GqlExplorerContainer;
