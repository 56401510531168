/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/matcher/v3/string.proto" (package "envoy.type.matcher.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { RegexMatcher } from "./regex_pb";
// [#protodoc-title: String matcher]

/**
 * Specifies the way to match a string.
 * [#next-free-field: 8]
 *
 * @generated from protobuf message envoy.type.matcher.v3.StringMatcher
 */
export interface StringMatcher {
    /**
     * @generated from protobuf oneof: match_pattern
     */
    matchPattern: {
        oneofKind: "exact";
        /**
         * The input string must match exactly the string specified here.
         *
         * Examples:
         *
         * * *abc* only matches the value *abc*.
         *
         * @generated from protobuf field: string exact = 1;
         */
        exact: string;
    } | {
        oneofKind: "prefix";
        /**
         * The input string must have the prefix specified here.
         * Note: empty prefix is not allowed, please use regex instead.
         *
         * Examples:
         *
         * * *abc* matches the value *abc.xyz*
         *
         * @generated from protobuf field: string prefix = 2;
         */
        prefix: string;
    } | {
        oneofKind: "suffix";
        /**
         * The input string must have the suffix specified here.
         * Note: empty prefix is not allowed, please use regex instead.
         *
         * Examples:
         *
         * * *abc* matches the value *xyz.abc*
         *
         * @generated from protobuf field: string suffix = 3;
         */
        suffix: string;
    } | {
        oneofKind: "safeRegex";
        /**
         * The input string must match the regular expression specified here.
         *
         * @generated from protobuf field: envoy.type.matcher.v3.RegexMatcher safe_regex = 5;
         */
        safeRegex: RegexMatcher;
    } | {
        oneofKind: "contains";
        /**
         * The input string must have the substring specified here.
         * Note: empty contains match is not allowed, please use regex instead.
         *
         * Examples:
         *
         * * *abc* matches the value *xyz.abc.def*
         *
         * @generated from protobuf field: string contains = 7;
         */
        contains: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * If true, indicates the exact/prefix/suffix matching should be case insensitive. This has no
     * effect for the safe_regex match.
     * For example, the matcher *data* will match both input string *Data* and *data* if set to true.
     *
     * @generated from protobuf field: bool ignore_case = 6;
     */
    ignoreCase: boolean;
}
/**
 * Specifies a list of ways to match a string.
 *
 * @generated from protobuf message envoy.type.matcher.v3.ListStringMatcher
 */
export interface ListStringMatcher {
    /**
     * @generated from protobuf field: repeated envoy.type.matcher.v3.StringMatcher patterns = 1;
     */
    patterns: StringMatcher[];
}
// @generated message type with reflection information, may provide speed optimized methods
class StringMatcher$Type extends MessageType<StringMatcher> {
    constructor() {
        super("envoy.type.matcher.v3.StringMatcher", [
            { no: 1, name: "exact", kind: "scalar", oneof: "matchPattern", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prefix", kind: "scalar", oneof: "matchPattern", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 3, name: "suffix", kind: "scalar", oneof: "matchPattern", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 5, name: "safe_regex", kind: "message", oneof: "matchPattern", T: () => RegexMatcher, options: { "validate.rules": { message: { required: true } } } },
            { no: 7, name: "contains", kind: "scalar", oneof: "matchPattern", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 6, name: "ignore_case", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.matcher.StringMatcher" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.matcher.v3.StringMatcher
 */
export const StringMatcher = new StringMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStringMatcher$Type extends MessageType<ListStringMatcher> {
    constructor() {
        super("envoy.type.matcher.v3.ListStringMatcher", [
            { no: 1, name: "patterns", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StringMatcher, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.matcher.ListStringMatcher" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.matcher.v3.ListStringMatcher
 */
export const ListStringMatcher = new ListStringMatcher$Type();
