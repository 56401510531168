import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CertExpiryStyles } from 'Components/Features/Dashboard/Cards/CertificatesExpiry/CertificatesExpiryCard.style';
import { healthColors } from 'Styles/colors';
import { Card } from 'antd';

export namespace CertModalDetailsStyles {
  export const CertModalCard = styled(Card)(
    () => css`
      border-radius: 5px;
      .ant-card-body {
        padding: 10px 10px 15px 10px;
      }
    `
  );

  export const SubSectionTitle = styled.h3(
    () => css`
      font-size: 1.4em;
      margin-bottom: 10px;
    `
  );

  export const BoldText = styled.div(
    () => css`
      font-weight: 500;
    `
  );

  export const GreyText = styled.div(
    ({ theme }) => css`
      color: ${theme.augustGrey};
    `
  );

  export const RedText = styled.div(
    () => css`
      color: ${healthColors.error.dark};
    `
  );

  export const CertExpiryVisualizationLine = styled(CertExpiryStyles.CertExpiryVizualizationLine)<{
    percentFull: number;
    isExpired?: boolean;
  }>(
    ({ theme, percentFull, isExpired }) => css`
      margin: 8px 0px;
      height: 20px;
      ${isExpired
        ? css`
            width: 100%;
            background-color: ${healthColors.error.dark};
          `
        : css`
            background: linear-gradient(90deg, ${theme.oceanBlue} ${percentFull}%, ${theme.splashBlue} ${percentFull}%);
          `}
    `
  );
}
