import { CardStyles } from 'Components/Common/Card';
import { SoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import { Insight_SYS0011Data } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { buildServicesUrl } from 'utils/url-builders';
import { DashboardStyles } from '../../DashboardLanding.style';
import ClusterServicesCardContent from './ClusterServicesCardContent';

const summaryData: Insight_SYS0011Data = {
  outOfMeshServices: 30,
  inMeshServices: 70,
  ambientServices: 20,
  sidecarServices: 50,
  gatewayServices: 10,
  totalServices: 100
};

export const ClusterServicesCardFakeData = () => {
  return (
    <CardStyles.Card data-testid='cluster-services-card'>
      <DashboardStyles.CardColumn>
        <DashboardStyles.Header>
          <Svg asset={<Asset.ClusterServicesIcon />} color={theme => theme.neptuneBlue} />
          Cluster Services
        </DashboardStyles.Header>
        <ClusterServicesCardContent data={summaryData} />
        <DashboardStyles.CardBottomLink>
          <SoloLink small link={buildServicesUrl()} withArrow>
            DETAILS
          </SoloLink>
        </DashboardStyles.CardBottomLink>
      </DashboardStyles.CardColumn>
    </CardStyles.Card>
  );
};
