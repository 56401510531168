import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { debugApi } from 'Api/debug';
import { DataError } from 'Components/Common/DataError';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { Loading } from 'Components/Common/Loading';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { buildIdFromRef } from 'utils/helpers';
import { resourceTypesMap } from 'utils/types';
import { ErrorBoundary } from './ErrorBoundary';
import { CardFolderTab, CardFolderTabList } from './Tabs';
import { YamlModalStyles as Styles } from './YamlModal.style';

const { useGetYaml } = debugApi;

export type GetYamlParams = {
  resourceType: ResourceType;
  clusterObjectRef: ClusterObjectRef;
};

export type YamlResource = GetYamlParams & {
  status?: Status;
  icon: React.ReactNode;
};

export const YamlModalBody = ({ resourceType, clusterObjectRef }: GetYamlParams) => {
  //
  // Get the YAML
  di(useGetYaml);
  const { data: yamlString, error: yamlError } = useGetYaml(resourceType, clusterObjectRef);
  //
  // Render
  if (!!yamlError) return <DataError error={yamlError} />;
  else if (yamlString === undefined) return <Loading message={`Retrieving ${clusterObjectRef.name} YAML...`} />;
  return <FancyCodeDisplayer contentString={yamlString} type='yaml' maxHeight='none' />;
};

/**
 * Renders the YAML for a resource in a modal,
 * with an `<ErrorBoundary/>` around the modal body.
 */
export const YamlModal = (props: { yamlResource: YamlResource; onClose: () => void; usedTitle?: React.ReactNode }) => {
  const { yamlResource, onClose, usedTitle } = props;
  return (
    <SoloModal width={1000} onClose={onClose}>
      <Styles.Body>
        <SoloModalHeader
          title={usedTitle ?? yamlResource.clusterObjectRef.name}
          subtitle='YAML Configuration'
          icon={yamlResource.icon}
          status={yamlResource.status}
        />

        <Styles.Content>
          <ErrorBoundary fallback='Could not get YAML...'>
            <YamlModalBody {...yamlResource} />
          </ErrorBoundary>
        </Styles.Content>
      </Styles.Body>
    </SoloModal>
  );
};

interface MultiYamlModalProps {
  title: React.ReactNode;
  icon: React.ReactNode;
  status?: Status;
  resources: GetYamlParams[];
  onClose: () => void;
}
/**
 * Renders the YAML for a resource in a modal,
 * with an `<ErrorBoundary/>` around the modal body.
 */
export const MultiYamlModal = ({ title, icon, status, resources, onClose }: MultiYamlModalProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  const typeCountMap: Partial<Record<ResourceType, number>> = {};

  const tabData = useMemo(() => {
    return resources.map(resource => {
      const { resourceType: type, clusterObjectRef: ref } = resource;
      // Make sure tabs with same type have a unique name
      typeCountMap[type] ??= 0;
      typeCountMap[type]!++;

      return {
        key: `${type}--${buildIdFromRef(ref)}`,
        tabText: resourceTypesMap[type].name + (typeCountMap[type] === 1 ? '' : ` (${typeCountMap[type]})`),
        resource
      };
    });
  }, [resources]);

  return (
    <SoloModal width={1000} onClose={onClose}>
      <Styles.Body>
        <SoloModalHeader title={title} subtitle='YAML Configurations' icon={icon} status={status} />

        <Styles.Content>
          <Tabs id='multiYamlTabs' index={tabIndex} onChange={setTabIndex}>
            <CardFolderTabList>
              {tabData.map(({ key, tabText }) => (
                <CardFolderTab key={key} width='auto'>
                  {tabText}
                </CardFolderTab>
              ))}
            </CardFolderTabList>
            <TabPanels>
              {tabData.map(({ key, resource }) => (
                <TabPanel key={key}>
                  <ErrorBoundary fallback='Could not get YAML...'>
                    <YamlModalBody {...resource} />
                  </ErrorBoundary>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Styles.Content>
      </Styles.Body>
    </SoloModal>
  );
};

/**
 * `YamlModalButton` can be used for all YAML modal logic if yamlResource is supplied.
 * */
export const YamlModalButton = ({ yamlResource }: { yamlResource: YamlResource | undefined }) => {
  const [yamlResourceToShow, setYamlResourceToShow] = useState<YamlResource>();
  return (
    <>
      {yamlResource === undefined ? (
        <SoloButton disabled>VIEW YAML</SoloButton>
      ) : (
        <SoloButton onClick={() => setYamlResourceToShow(yamlResource)}>VIEW YAML</SoloButton>
      )}
      {!!yamlResourceToShow && (
        <YamlModal yamlResource={yamlResourceToShow} onClose={() => setYamlResourceToShow(undefined)} />
      )}
    </>
  );
};
