import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { OptionType, SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { DefaultTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListClustersRequest_ClusterSortOrder } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useNavigate } from 'react-router-dom';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { buildClusterDetailsUrl } from 'utils/url-builders';
import { ClustersLandingStyles as Styles } from './ClustersLanding.style';
import { ClustersLandingBody } from './ClustersLandingBody';
import { ClustersLandingEmpty } from './ClustersLandingEmpty';

const { useListClusters } = overviewApi;

const sortOrderOptions: OptionType<ListClustersRequest_ClusterSortOrder>[] = [
  {
    value: ListClustersRequest_ClusterSortOrder.NAME,
    displayValue: 'Name'
  },
  {
    value: ListClustersRequest_ClusterSortOrder.INSIGHTS,
    displayValue: 'Insights'
  }
];

/** Returns true after one useEffect render. */
const useCheckIfDataHasBeenSeenAtLeastOnce = (data: any, count: number | undefined) => {
  const [dataHasBeenSeenAtLeastOnce, setDataHasBeenSeenAtLeastOnce] = useState(false);
  useEffect(() => {
    if (!!count) {
      setDataHasBeenSeenAtLeastOnce(true);
    }
  }, [data]);
  return dataHasBeenSeenAtLeastOnce;
};

export const ClustersLanding = () => {
  di(useListClusters);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const [sortOrder, setSortOrder] = useState(ListClustersRequest_ClusterSortOrder.NAME);
  const [reversed, setReversed] = useState(false);

  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging(DefaultTablePageSize);

  const { data: clustersData, error: clustersError } = useListClusters(
    nameFilter,
    apiPaginationObject,
    statusFilter,
    sortOrder,
    reversed
  );

  useEffect(() => {
    if (!dataHasBeenSeenAtLeastOnce && clustersData?.total === 1 && clustersData.clusters.length > 0) {
      navigate(buildClusterDetailsUrl(clustersData.clusters[0].clusterRef?.name));
    }
  }, [clustersData]);

  useSetPagingTotal(clustersData?.filteredTotal);
  const { initialLoad, showRefreshIndicator, data: clusters } = useDebouncedRefreshIndicator(clustersData);
  const dataHasBeenSeenAtLeastOnce = useCheckIfDataHasBeenSeenAtLeastOnce(clusters, clusters?.clusters.length);

  // Different behavior if it has been seen once before
  if (!!clusters && !clusters.clusters.length && !dataHasBeenSeenAtLeastOnce) {
    return <ClustersLandingEmpty />;
  }

  return (
    <OverviewStyles.Container data-testid='clusters-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.ClusterIcon />}
          Clusters
        </OverviewStyles.Header.Title>
        {!clustersError && (
          <OverviewStyles.Header.FiltersWrapper>
            {clusters?.clusters.length !== 1 && (
              <HealthCountBox
                onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
                onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
                isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
                isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
                totalCount={clusters?.total}
                errorCount={clusters?.errors}
              />
            )}
            <OverviewStyles.Header.Filters filters={2} hasTableToggle={false}>
              <Styles.SortContainer>
                <Styles.OrderDirectionToggle reversed={reversed} onClick={() => setReversed(rev => !rev)}>
                  <Asset.SortArrows />
                </Styles.OrderDirectionToggle>
                <SoloDropdown
                  value={sortOrder}
                  displayValue={`Sort by ${sortOrderOptions.find(o => o.value === sortOrder)?.displayValue}`}
                  options={sortOrderOptions}
                  placeholder={'Sort by...'}
                  aria-label='Sort by'
                  onChange={setSortOrder}
                />
              </Styles.SortContainer>
              <SoloInput
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label='Search by name'
                onChange={evt => setNameFilter(evt.target.value)}
              />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <ClustersLandingBody
        isLoading={initialLoad}
        itemsError={clustersError}
        items={clusters?.clusters ?? []}
        pagingData={pagingData}
      />
    </OverviewStyles.Container>
  );
};
