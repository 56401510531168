const GqlExplorerDefaultQuery = `query Example {
}

# Welcome to GraphiQL, an in-browser tool for
# writing, validating, and testing GraphQL queries.
#
# Type queries into this side of the screen, and you
# will see intelligent typeaheads aware of the current
# GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character.
# Lines that start with a # are ignored.
# The name of the query on the first line of each tab
# is the title of that tab.
#
# An example GraphQL query might look like:
#     query Example {
#       field(arg: "value") {
#         subField
#       }
#     }
#
# Keyboard shortcuts:
#     Prettify Query:    Shift-Ctrl-P
#     Merge Query:     Shift-Ctrl-M
#     Run Query:        Ctrl-Enter
#     Auto Complete:  Ctrl-Space
`;
export default GqlExplorerDefaultQuery;
