import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { DashboardStyles } from '../../../DashboardLanding.style';
import { IstioHealthCheckSectionFakeData } from './IstioHealthCheckSectionFakeData';
import { IstioHealthEnvironmentCheckSection } from './IstioHealthEnvironmentCheckSection';

const IstioHealthTabContentFakeData = () => {
  return (
    <GridLayout templateColumns='1fr auto 1fr' gap={5} pt={5}>
      <IstioHealthCheckSectionFakeData />
      <DashboardStyles.VerticalLine />
      <IstioHealthEnvironmentCheckSection istioEnvironment={[]} />
    </GridLayout>
  );
};

export default IstioHealthTabContentFakeData;
