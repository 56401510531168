/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/phase.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Int32Value } from "../../../../../../../../google/protobuf/wrappers_pb";
/**
 * Prioritized phase is the phase at which a given policy should be applied.
 * The 2 pre-defined phases are "pre" and "post" AuthZ. In addition to the phase, you can
 * specify a priority to indicate at what point this policy should be applied relative to other policies
 * defined in the same phase.
 * For Example, if you have the following policies defined with phases and priorities:
 * ```
 * 1.
 * phase:
 *   preAuthz:
 *     priority: 1
 * 2.
 * phase:
 *   preAuthz:
 *     priority: -1
 * 3.
 * phase:
 *   preAuthz: {}
 * ```
 * The policies will be run in the following order: `(2, 3, 1)`.
 *
 * Note: The default phase is defined per policy type which you can find in the policy reference documentation.
 * The following policies currently implement prioritized staging:
 * - RateLimit
 * - JwtAuth
 * - Transformation
 *
 * @generated from protobuf message common.gloo.solo.io.PrioritizedPhase
 */
export interface PrioritizedPhase {
    /**
     * @generated from protobuf oneof: phase
     */
    phase: {
        oneofKind: "preAuthz";
        /**
         * Apply this policy before Authorization policies are applied.
         *
         * @generated from protobuf field: common.gloo.solo.io.PrioritizedPhase.Phase pre_authz = 1;
         */
        preAuthz: PrioritizedPhase_Phase;
    } | {
        oneofKind: "postAuthz";
        /**
         * Apply this policy after Authorization policies are applied.
         *
         * @generated from protobuf field: common.gloo.solo.io.PrioritizedPhase.Phase post_authz = 2;
         */
        postAuthz: PrioritizedPhase_Phase;
    } | {
        oneofKind: undefined;
    };
}
/**
 * A message that describes each individual phase.
 *
 * @generated from protobuf message common.gloo.solo.io.PrioritizedPhase.Phase
 */
export interface PrioritizedPhase_Phase {
    /**
     * The priority at which this policy should be applied relative to other policies defined in the same phase.
     * The lowest priority will be run first and the default priority value is 0.
     * For information about the value format, see the [Google protocol buffer documentation](https://protobuf.dev/reference/protobuf/google.protobuf/#int32-value).
     *
     * @generated from protobuf field: google.protobuf.Int32Value priority = 1;
     */
    priority?: Int32Value;
}
// @generated message type with reflection information, may provide speed optimized methods
class PrioritizedPhase$Type extends MessageType<PrioritizedPhase> {
    constructor() {
        super("common.gloo.solo.io.PrioritizedPhase", [
            { no: 1, name: "pre_authz", kind: "message", oneof: "phase", T: () => PrioritizedPhase_Phase },
            { no: 2, name: "post_authz", kind: "message", oneof: "phase", T: () => PrioritizedPhase_Phase }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.PrioritizedPhase
 */
export const PrioritizedPhase = new PrioritizedPhase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrioritizedPhase_Phase$Type extends MessageType<PrioritizedPhase_Phase> {
    constructor() {
        super("common.gloo.solo.io.PrioritizedPhase.Phase", [
            { no: 1, name: "priority", kind: "message", T: () => Int32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.PrioritizedPhase.Phase
 */
export const PrioritizedPhase_Phase = new PrioritizedPhase_Phase$Type();
