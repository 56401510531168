import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { Asset } from 'assets';
import React, { useEffect, useState } from 'react';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { docLinks } from 'utils/url-external-links-map';
import { DataErrorStyles } from './DataError.style';
import { MarkdownRenderer } from './MarkdownRenderer';
import { ExternalSoloLink } from './SoloLink';

interface ErrorProps {
  error: Partial<RpcError>;
  center?: boolean;
  skipGracefulStart?: boolean;
  icon?: React.ReactNode;
  isCalm?: boolean;
  iconWidth?: number;
  errorTitle?: string;
}

export const DataError = ({
  center = true,
  error,
  skipGracefulStart,
  icon,
  isCalm,
  iconWidth,
  errorTitle
}: ErrorProps) => {
  const [gracefulStartComplete, setGracefulStateComplete] = useState(false);
  const [{ errorId, errorMessage }, setErrorMessageData] = useState<{
    errorId?: string;
    errorMessage?: string;
  }>({});

  useEffect(() => {
    if (skipGracefulStart) {
      setGracefulStateComplete(true);
    } else {
      const timer = setTimeout(() => {
        setGracefulStateComplete(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  // This detects if the error message contains an error ID. Format:
  // {ERROR_ID} Normal error message
  useEffect(() => {
    const result = /^{([A-Z_]+)}\s*(.*)/gs.exec(error.message!);
    if (result) {
      setErrorMessageData({ errorId: result[1], errorMessage: result[2] });
    } else {
      setErrorMessageData({ errorMessage: error.message });
    }
  }, [error.message]);

  return (
    <DataErrorStyles.Container center={center} loadingComplete={gracefulStartComplete}>
      {error.code === GrpcStatusCode[GrpcStatusCode.INTERNAL] && errorId === 'NO_GRAPH_DATA' ? (
        <>
          <DataErrorStyles.ImageHolder data-testid='graph-no-data' calm iconWidth={iconWidth ?? 133}>
            {icon ?? <Asset.NoGraphPlaceholder />}
          </DataErrorStyles.ImageHolder>
          <DataErrorStyles.Title>No Data Found</DataErrorStyles.Title>
          <DataErrorStyles.CalmerMessageBox>{errorMessage}</DataErrorStyles.CalmerMessageBox>
        </>
      ) : error.code === GrpcStatusCode[GrpcStatusCode.INTERNAL] &&
        (errorId === 'REDIS_CONN_ERROR' || errorId === 'PROMETHEUS_CONN_ERROR') ? (
        <>
          <DataErrorStyles.ImageHolder calm iconWidth={iconWidth ?? 435}>
            {icon ?? <Asset.ConfigError />}
          </DataErrorStyles.ImageHolder>
          <DataErrorStyles.Title>Error Retrieving Data</DataErrorStyles.Title>
          <DataErrorStyles.CalmerMessageBox>
            There was a problem retrieving the data.
            <br />
            {errorId === 'REDIS_CONN_ERROR' ? (
              <>
                Unable to connect to the redis storage cache. Please check the <code>--redis-address</code> flag in your{' '}
                <b>gloo-mesh-ui</b> deployment and the status of the redis deployment.
              </>
            ) : (
              <>
                Unable to connect to the prometheus server. Please check the <code>--prometheus-url</code> flag in your{' '}
                <b>gloo-mesh-ui</b> deployment and the status of the prometheus-server deployment.
              </>
            )}
            <div>
              <ExternalSoloLink newTab href={docLinks.core.troubleshooting_ui}>
                VIEW DOCS &gt;
              </ExternalSoloLink>
            </div>
          </DataErrorStyles.CalmerMessageBox>
        </>
      ) : error.code === GrpcStatusCode[GrpcStatusCode.INTERNAL] ||
        error.code === GrpcStatusCode[GrpcStatusCode.UNAVAILABLE] ||
        error.code === GrpcStatusCode[GrpcStatusCode.DATA_LOSS] ? (
        <>
          <DataErrorStyles.ImageHolder calm iconWidth={iconWidth}>
            {icon ?? <Asset.ConnectionErrorGraphic />}
          </DataErrorStyles.ImageHolder>
          <DataErrorStyles.Title>Connection Error</DataErrorStyles.Title>
          <DataErrorStyles.CalmerMessageBox>{errorMessage}</DataErrorStyles.CalmerMessageBox>
        </>
      ) : error.code ===
          GrpcStatusCode[GrpcStatusCode.UNAUTHENTICATED] /* temporary until api can pass a code 16 back */ ||
        error.code === GrpcStatusCode[GrpcStatusCode.UNKNOWN] ? (
        <>
          <DataErrorStyles.ImageHolder calm iconWidth={iconWidth}></DataErrorStyles.ImageHolder>
          <DataErrorStyles.Title>Not Authenticated</DataErrorStyles.Title>
          <DataErrorStyles.CalmerMessageBox>{errorMessage}</DataErrorStyles.CalmerMessageBox>
        </>
      ) : (
        <>
          <DataErrorStyles.ImageHolder calm={isCalm ?? false} iconWidth={iconWidth}>
            {icon ?? <Asset.BigUnsuccessfulX />}
          </DataErrorStyles.ImageHolder>
          {errorTitle && <DataErrorStyles.Title>{errorTitle}</DataErrorStyles.Title>}
          {isCalm ? (
            <DataErrorStyles.CalmerMessageBox>
              <MarkdownRenderer text={errorMessage} />
            </DataErrorStyles.CalmerMessageBox>
          ) : (
            <DataErrorStyles.MessageBox>
              <MarkdownRenderer text={errorMessage} />
            </DataErrorStyles.MessageBox>
          )}
        </>
      )}

      {error.code !== undefined && error.code !== GrpcStatusCode[GrpcStatusCode.OK] && (
        <DataErrorStyles.CodeBox>Code: {error.code}</DataErrorStyles.CodeBox>
      )}
    </DataErrorStyles.Container>
  );
};

export const DataErrorStringList = ({ errorsList }: { errorsList: string[] }) => {
  return (
    <div>
      {errorsList.map(err => (
        <DataError key={err} error={{ message: err }} />
      ))}
    </div>
  );
};

export const DataErrorStringListCompact = ({ errorsList }: { errorsList: string[] | undefined }) => {
  return (
    <FlexLayout flexDirection='column' gap={1}>
      {errorsList?.map(err => (
        <FlexLayout key={err} gap={2} alignItems='center'>
          <DataErrorStyles.ImageHolder calm={false} iconWidth={15}>
            <Asset.BigUnsuccessfulX />
          </DataErrorStyles.ImageHolder>
          <DataErrorStyles.MessageBox compact>{err}</DataErrorStyles.MessageBox>
        </FlexLayout>
      ))}
    </FlexLayout>
  );
};
