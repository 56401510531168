import * as React from 'react';
import { SoloToggleSwitchStyles as Styles } from './SoloToggleSwitch.style';
import { InfoTooltip } from '../InfoTooltip';
import { useSoloId } from 'utils/hooks';

export interface SoloToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  checkedLabel?: React.ReactNode;
  uncheckedLabel?: React.ReactNode;
  labelOnRight?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  id?: string;
  infoTooltip?: React.ReactNode;
  dark?: boolean;
  'data-testid'?: string;
  'aria-label'?: string;
}

export const SoloToggleSwitch = ({
  checked,
  checkedLabel,
  uncheckedLabel,
  onChange,
  labelOnRight,
  disabled,
  label,
  infoTooltip,
  dark,
  id,
  'aria-label': ariaLabel,
  ...otherProps // Includes `data-` and `aria-` attributes
}: SoloToggleSwitchProps) => {
  const inputId = useSoloId(id);
  const onClick = (): void => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  const labelText = !!label
    ? label
    : !!checkedLabel && checked
    ? checkedLabel
    : !!uncheckedLabel && !checked
    ? uncheckedLabel
    : null;

  const labelSection = !!labelText && (
    <Styles.Label labelOnRight={labelOnRight} htmlFor={inputId}>
      {labelText} {!!infoTooltip && <InfoTooltip tooltip={infoTooltip} />}
    </Styles.Label>
  );

  return (
    <Styles.Container data-testid='solo-toggle-switch-wrapper'>
      {!labelOnRight && labelSection}
      <Styles.ToggleButton
        id={inputId}
        onClick={onClick}
        role='radio'
        aria-checked={checked}
        aria-label={ariaLabel ?? label?.toString() ?? (checked ? checkedLabel?.toString() : uncheckedLabel?.toString())}
        isDisabled={disabled}
        isChecked={checked}
        isDark={dark}
        {...otherProps}>
        <Styles.ToggleBall aria-hidden='true' isChecked={checked} />
      </Styles.ToggleButton>
      {!!labelOnRight && labelSection}
    </Styles.Container>
  );
};
