import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { BasicWorkloadsTableCard } from './BasicWorkloadsTable';

const { useGetTrimProxyConfigPolicyDetails } = policiesApi;

const policyType = PolicyType.TRIM_PROXY_CONFIG;
interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const TrimProxyConfigDetails = ({ clusterObjRef }: Props) => {
  di(useGetTrimProxyConfigPolicyDetails);
  const workloadsPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetTrimProxyConfigPolicyDetails(
    clusterObjRef,
    workloadsPaging.apiPaginationObject
  );

  workloadsPaging.useSetPagingTotal(policyData?.workloads?.totalWorkloads);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <BasicWorkloadsTableCard workloads={policyData.workloads?.workloads} paging={workloadsPaging.pagingData} />
    </CardStyles.CardList>
  );
};
