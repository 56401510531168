import { Asset } from 'assets';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { NoticeUrgencyLevel, Notification, useNotifications } from 'utils/notificationsystem';
import { TopLevelNotificationDisplayStyles as Styles } from './TopLevelNotificationDisplay.style';

interface NotificationProps {
  notification: Notification;
  icon?: React.ReactNode;
  onDismiss: (id: string) => void;
}
const NotificationBox = ({ notification, icon, onDismiss }: NotificationProps) => {
  const { id, label, message, dismissable, 'data-testid': testId } = notification;
  const onClose = () => onDismiss(id);
  return (
    <Styles.IndividualNotification data-testid={testId ?? `${id}-notification`}>
      {!!icon && <Styles.StatusIcon>{icon}</Styles.StatusIcon>}
      {!!label && <Styles.Label>{label}:</Styles.Label>}
      <div>
        {!Array.isArray(message) ? (
          message
        ) : (
          <Styles.MessageList>
            {message.map((msg, i) => (
              <li key={i + (typeof msg === 'string' ? msg : '')}>{msg}</li>
            ))}
          </Styles.MessageList>
        )}
      </div>
      {dismissable && (
        <Styles.DismissalX onClick={onClose}>
          <Asset.CloseX />
        </Styles.DismissalX>
      )}
    </Styles.IndividualNotification>
  );
};

export const TopLevelNotificationDisplay = () => {
  di(useNotifications);
  const { notifications, dismissNotification } = useNotifications();

  const errors = useMemo(() => notifications.filter(n => n.level === NoticeUrgencyLevel.Error), [notifications]);
  const warnings = useMemo(() => notifications.filter(n => n.level === NoticeUrgencyLevel.Warning), [notifications]);
  const infos = useMemo(() => notifications.filter(n => n.level === NoticeUrgencyLevel.Info), [notifications]);

  return (
    <>
      {!!errors.length && (
        <Styles.Container data-testid='top-level-notification-error' health='error'>
          {errors.map(n => (
            <NotificationBox
              key={n.id}
              notification={n}
              icon={<Asset.ErrorTriangle />}
              onDismiss={dismissNotification}
            />
          ))}
        </Styles.Container>
      )}
      {!!warnings.length && (
        <Styles.Container data-testid='top-level-notification-warning' health='warning'>
          {warnings.map(n => (
            <NotificationBox
              key={n.id}
              notification={n}
              icon={<Asset.WarningCircleIconDarker />}
              onDismiss={dismissNotification}
            />
          ))}
        </Styles.Container>
      )}
      {!!infos.length && (
        <Styles.Container data-testid='top-level-notification-info' health='idle'>
          {infos.map(n => (
            <NotificationBox key={n.id} notification={n} onDismiss={dismissNotification} />
          ))}
        </Styles.Container>
      )}
    </>
  );
};
