/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/logs.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { LogsApi } from "./logs_pb";
import type { AttributesResponse } from "./logs_pb";
import type { Empty } from "../../../../../../../../google/protobuf/empty_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetContainerLogsStreamResponse } from "./logs_pb";
import type { GetContainerLogsStreamRequest } from "./logs_pb";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rpc.gloo.solo.io.LogsApi
 */
export interface ILogsApiClient {
    /**
     * @generated from protobuf rpc: GetContainerLogsStream(rpc.gloo.solo.io.GetContainerLogsStreamRequest) returns (stream rpc.gloo.solo.io.GetContainerLogsStreamResponse);
     */
    getContainerLogsStream(input: GetContainerLogsStreamRequest, options?: RpcOptions): ServerStreamingCall<GetContainerLogsStreamRequest, GetContainerLogsStreamResponse>;
    /**
     * @generated from protobuf rpc: GetAvailableComponentInfo(google.protobuf.Empty) returns (rpc.gloo.solo.io.AttributesResponse);
     */
    getAvailableComponentInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, AttributesResponse>;
}
/**
 * @generated from protobuf service rpc.gloo.solo.io.LogsApi
 */
export class LogsApiClient implements ILogsApiClient, ServiceInfo {
    typeName = LogsApi.typeName;
    methods = LogsApi.methods;
    options = LogsApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetContainerLogsStream(rpc.gloo.solo.io.GetContainerLogsStreamRequest) returns (stream rpc.gloo.solo.io.GetContainerLogsStreamResponse);
     */
    getContainerLogsStream(input: GetContainerLogsStreamRequest, options?: RpcOptions): ServerStreamingCall<GetContainerLogsStreamRequest, GetContainerLogsStreamResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetContainerLogsStreamRequest, GetContainerLogsStreamResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAvailableComponentInfo(google.protobuf.Empty) returns (rpc.gloo.solo.io.AttributesResponse);
     */
    getAvailableComponentInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, AttributesResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, AttributesResponse>("unary", this._transport, method, opt, input);
    }
}
