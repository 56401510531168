import { insightsApi } from 'Api/insights';
import { certificatesExpiryCodesArray } from 'Components/Features/Dashboard/Cards/CertificatesExpiry/insight-codes';
import { clusterServicesCodesArray } from 'Components/Features/Dashboard/Cards/ClusterServices/insight-codes';
import { fipsCodesArray } from 'Components/Features/Dashboard/Cards/FIPS/insight-codes';
import {
  dashboardHealthCodesMap,
  DashboardHealthKey,
  istioAndCiliumHealthCodesArray
} from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { dashboardUtilsCodesArray } from 'Components/Features/Dashboard/insight-codes';
import {
  Insight,
  Insight_Data
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import {
  Code,
  ListInsightsRequest_InsightsFilter,
  ListInsightsRequest_InsightsFilter_Target
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { isNonNullableAndNonFalse } from 'utils/helpers';
import { DisplayNotificationProps, NoticeUrgencyLevel, useDisplayNotification } from 'utils/notificationsystem';
import { Permission, usePermissions } from 'utils/permissions';
import { getInsightData } from './dashboard-helpers';
import { InsightCodeMap } from './dashboard-types';

//
// List Dashboard Insights
//
type CodeType = 'normal' | 'istioDataPlane' | 'certs';
const insightCodeTypesMap: Record<CodeType, { codes: Code[]; limit: number }> = {
  normal: {
    codes: [
      ...dashboardUtilsCodesArray,
      ...clusterServicesCodesArray,
      ...fipsCodesArray,
      ...istioAndCiliumHealthCodesArray.filter(
        c => c.key !== dashboardHealthCodesMap[DashboardHealthKey.istioDataPlane].key
      )
    ],
    limit: 250 // We should never hit this, but we need to define some upper limit
  },
  // Some environments could have a large amount of certs, so split them off
  istioDataPlane: {
    codes: [{ ...dashboardHealthCodesMap[DashboardHealthKey.istioDataPlane] }],
    limit: 250 // We should never hit this, but we need to define some upper limit
  },
  certs: {
    codes: [...certificatesExpiryCodesArray],
    limit: 100 // Past 100 wouldn't be very useful anyways
  }
};

export function useListDashboardInsights(codeType: CodeType, target?: ListInsightsRequest_InsightsFilter_Target) {
  const filter: ListInsightsRequest_InsightsFilter = {
    // Because there is a limit of how many insights can be fetched at once, we decouple certs from the main insights call
    codes: insightCodeTypesMap[codeType].codes,
    target: target,
    severities: [],
    system: {
      value: true
    }
  };
  return insightsApi.useListInsights(filter, undefined, insightCodeTypesMap[codeType].limit);
}

// Detect if it's a code for a cert or if we should fetch all the other dashboard SYS insights
function detectCodeType(code: Code): CodeType {
  const codeMatches = (c: Code) => c.group === code.group && c.key === code.key;
  return (
    (Object.entries(insightCodeTypesMap).find(([, obj]) => !!obj.codes.find(codeMatches))?.[0] as CodeType) ?? 'normal'
  );
}

/**
 * Fetches dashboard insights.
 * Then uses the provided map to filter down the insights.
 */
export function useFilteredDashboardInsights<T extends InsightCodeMap>(
  codeMap: T,
  mapId: keyof T,
  target?: ListInsightsRequest_InsightsFilter_Target
) {
  const code = codeMap[mapId];
  const { data } = useListDashboardInsights(detectCodeType(code), target);
  return useMemo<Insight[] | undefined>(() => {
    if (!data) return undefined;

    const matchingInsightValues = data.insights.filter(d => d.code?.group === code.group && d.code?.key === code.key);
    return matchingInsightValues;
  }, [data]);
}

// Detect if we've passed by the allowed insights limit for dashboard.
// Anything past this won't be rendered, so a warning should be given.
export function useCheckIfDashboardInsightsPassedLimit(
  mode: CodeType,
  target?: ListInsightsRequest_InsightsFilter_Target
) {
  const { data } = useListDashboardInsights(mode, target);
  return useMemo(() => (!!data ? { limitPassed: !!data.cursor, count: data.insights.length } : undefined), [data]);
}

/**
 * This adds a notification to the UI if `useCheckIfDashboardInsightsPassedLimit` is true
 */
export function useDashboardInsightsLimitPassedNotification(target?: ListInsightsRequest_InsightsFilter_Target) {
  di(useCheckIfDashboardInsightsPassedLimit);
  const [notification, setNotification] = useState<DisplayNotificationProps>();
  useDisplayNotification(notification);

  const limitPassedData = useCheckIfDashboardInsightsPassedLimit('normal', target);
  const limitPassedDataDataPlane = useCheckIfDashboardInsightsPassedLimit('istioDataPlane', target);
  useEffect(() => {
    let notification: DisplayNotificationProps | undefined = undefined;
    if (limitPassedData?.limitPassed || limitPassedDataDataPlane?.limitPassed) {
      notification = {
        level: NoticeUrgencyLevel.Warning,
        message: 'Some insights could not be rendered.',
        'data-testid': `dashboard-insight-limit-warning-${!!target ? target.clusters.join('---') : 'all'}`
      };
    }
    setNotification(notification);
  }, [limitPassedData, limitPassedDataDataPlane]);
}

/**
 * This assumes the insights list has already been filtered to only contain insights with specified dataProp
 */
export const useDataPropForInsights = <T extends Insight_Data['data']['oneofKind']>(
  dataProp: T,
  insights: Insight[] | undefined
) => {
  return useMemo(
    () => insights?.map(insight => getInsightData(insight, dataProp)).filter(isNonNullableAndNonFalse),
    [dataProp, insights]
  );
};

/**
 * This assumes the insights list has already been filtered, and simply returns the
 * data for that prop of the first entry if it exists
 */
export const useDataPropOfFirstInsight = <T extends Insight_Data['data']['oneofKind']>(
  dataProp: T,
  insights: Insight[] | undefined
) => {
  const datas = useDataPropForInsights(dataProp, insights);
  return !datas?.length ? undefined : datas[0];
};

/**
 * A more streamlined function that doesn't load a bunch of codes at once (useful when just checking if an insight exists)
 */
const useListInsightsSimple = (
  code: Code | Code[],
  target?: ListInsightsRequest_InsightsFilter_Target,
  limit?: number
) => {
  const filter: ListInsightsRequest_InsightsFilter = {
    codes: Array.isArray(code) ? code : [code],
    severities: [],
    target,
    system: { value: true }
  };
  return insightsApi.useListInsights(filter, undefined, limit);
};

const ciliumCode = dashboardHealthCodesMap[DashboardHealthKey.ciliumAgent];
export const useIsCiliumEnabled = (target?: ListInsightsRequest_InsightsFilter_Target) => {
  di(useListInsightsSimple);
  const { data } = useListInsightsSimple(ciliumCode, target, 1);
  return !!data?.totalInsights;
};

const istioCodes = [
  DashboardHealthKey.istioControlPlane,
  DashboardHealthKey.istioDataPlane,
  DashboardHealthKey.istioEnvironmentCheck
].map(key => dashboardHealthCodesMap[key]);
export const useIsIstioAndGlooEnabled = (target?: ListInsightsRequest_InsightsFilter_Target) => {
  di(useListInsightsSimple);
  // While we check for more than one code, we only care if at least one is returned for this check
  const { data } = useListInsightsSimple(istioCodes, target, 1);
  return !!data?.totalInsights;
};

export const useIsOnlyCiliumEnabled = (target?: ListInsightsRequest_InsightsFilter_Target) => {
  di(useIsCiliumEnabled, useIsIstioAndGlooEnabled);
  const isCiliumEnabled = useIsCiliumEnabled(target);
  const isIstioAndGlooEnabled = useIsIstioAndGlooEnabled(target);
  return isCiliumEnabled && !isIstioAndGlooEnabled;
};

export const useInsightEnablementChecks = (target?: ListInsightsRequest_InsightsFilter_Target) => {
  di(useIsCiliumEnabled, useIsIstioAndGlooEnabled, usePermissions);
  const isCiliumEnabled = useIsCiliumEnabled(target);
  const isIstioAndGlooEnabled = useIsIstioAndGlooEnabled(target);
  const { hasPerm } = usePermissions();
  const isIstioEnabledAndLicenced = isIstioAndGlooEnabled && hasPerm(Permission.IstioEnabled);
  return {
    isCiliumEnabled,
    isIstioEnabledAndLicenced: isIstioEnabledAndLicenced,
    isOnlyCiliumEnabled: isCiliumEnabled && !isIstioAndGlooEnabled
  };
};
