import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HorizontalRule } from 'Styles/CommonEmotions/dividers';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getGraphItemColors, graphZIndex } from 'Styles/graph';
import { GraphMetricsType, GraphStatus } from '../graph-selection-utils';

export namespace DetailsTabStyles {
  const detailsWidth = 275;
  const tabWidth = 44;

  type GraphDetailsTabContainerProps = {
    hideAway: boolean;
    canBeDisplayed: boolean;
    splitView: boolean;
  };
  export const GraphDetailsTabContainer = styled.div<GraphDetailsTabContainerProps>(
    ({ theme, hideAway, canBeDisplayed, splitView }) => {
      const width = splitView ? detailsWidth * 2 : detailsWidth;
      return css`
        position: absolute;
        top: 0;
        bottom: 0;
        width: ${width}px;
        max-height: 100%;
        background: white;
        z-index: ${graphZIndex.detailsTab};
        ${canBeDisplayed
          ? hideAway
            ? css`
                right: -${width}px;
              `
            : css`
                right: 0;
                box-shadow: -1px 0 5px 1px ${theme.aprilGrey};
              `
          : css`
              right: -${width + tabWidth}px;
            `}

        transition: right ${splitView ? 0.6 : 0.3}s;
      `;
    }
  );

  export const Slider = styled.div`
    position: relative;
    max-height: 100%;
    height: 100%;
  `;

  export const TabTray = styled.div`
    position: absolute;
    top: 0;
    left: -44px;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 2;
  `;

  interface CollapseTabProps {
    open: boolean;
  }
  export const CollapseTab = styled.div<CollapseTabProps>(
    ({ theme, open }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${tabWidth}px;
      padding: 10px 0;
      margin-top: 20px;
      margin-bottom: 10px;
      background: ${theme.januaryGrey};

      border-radius: 8px 0 0 8px;
      cursor: pointer;
      border-width: 1px 0 1px 1px;
      border-color: ${theme.aprilGrey};
      border-style: solid;

      ${svgBasics({ width: 22 }, theme.seaBlue)}
      svg {
        transform: rotate(${open ? -90 : 90}deg);
        transition: transform 0.3s;
      }
    `
  );

  export const TabsHolder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  `;

  type TabProps = {
    isActive: boolean;
  };
  export const Tab = styled.div<TabProps>(
    ({ isActive, theme }) => css`
      writing-mode: vertical-rl;

      padding: 0 10px;
      font-size: 18px;
      line-height: 22px;
      border-radius: 10px 0 0 10px;
      border: 1px solid ${theme.aprilGrey};
      height: 100px;
      text-align: center;
      cursor: default;

      ${isActive
        ? css`
            color: ${theme.seaBlue};
            background: white;
            border-right: 1px solid white;
          `
        : css`
            color: ${theme.septemberGrey};
            background: ${theme.marchGrey};
            cursor: pointer;
          `}

      > span {
        display: block;
        transform: rotate(180deg);
      }
    `
  );

  export const DetailsContainer = styled.div(
    ({ theme }) => css`
      padding: 15px;
      width: ${detailsWidth}px;
      overflow: auto;
      max-height: 100%;

      & + & {
        border-left: 1px solid ${theme.marchGrey};
      }
    `
  );

  export const SplitDetailsContainer = styled.div`
    display: flex;
    overflow: auto;
    max-height: 100%;
    height: 100%;
  `;

  interface GraphStatusIndicatorProps {
    metricsType: GraphMetricsType;
    health: GraphStatus;
  }
  export const GraphStatusIndicator = styled.span<GraphStatusIndicatorProps>(
    ({ metricsType, health }) => css`
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-left: 4px;

      background: ${getGraphItemColors(metricsType, health).node};
    `
  );

  interface GridTableProps {
    columns: string[];
  }
  export const GridTable = styled.div<GridTableProps>(
    ({ theme, columns }) => css`
      display: grid;
      grid-gap: 2px;
      grid-template-columns: ${columns.join(' ')};
      margin: 8px 0 15px;
      background: white;
      border-radius: 8px;
      border: 1px solid ${theme.marchGrey};

      > div {
        padding: 8px 5px;
        cursor: default;

        &:nth-of-type(1) {
          border-top-left-radius: 8px;
        }
        &:nth-of-type(${columns.length}) {
          border-top-right-radius: 8px;
        }

        &:nth-of-type(n + ${columns.length + 1}) {
          border-bottom: 1px solid ${theme.marchGrey};
        }
        &:nth-last-of-type(-n + ${columns.length}) {
          border-bottom: none;
        }
      }
    `
  );

  export const GridTH = styled.div(
    ({ theme }) => css`
      font-weight: 500;
      font-size: 12px;
      background: ${theme.marchGrey};
    `
  );

  export const GridLabel = styled.div`
    font-weight: 500;
    font-size: 12px;
  `;

  export const Unit = styled.span(
    ({ theme }) => css`
      color: ${theme.septemberGrey};
      font-size: 85%;
    `
  );

  export const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 4px;
  `;

  export const SubsectionTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 4px;
  `;

  export const SubsectionDetails = styled.div`
    font-size: 12px;
  `;

  export const SectionHorizontalRule = styled(HorizontalRule)(
    ({ theme, color }) => css`
      margin: 20px 0;
      background: ${color ?? theme.marchGrey};

      // Hide any that don't appear below a section title (can happen due to optional sections)
      display: none;
      ${SectionTitle} ~ & {
        display: block;
      }
    `
  );

  export const InfoListBlock = styled.div(
    ({ theme }) => css`
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 8px;
      align-items: center;

      background: ${theme.januaryGrey};
      border: 1px solid ${theme.aprilGrey};
      border-radius: 7px;
      padding: 10px 7px 8px;
      margin: 10px 0 0;
      font-size: 15px;
      line-height: 1;
      cursor: default;

      :last-of-type {
        margin-bottom: 18px;
      }

      ${svgBasics(16)}
    `
  );
}
