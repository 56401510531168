import { Asset } from 'assets';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { Permission, usePermissions } from 'utils/permissions';
import { GvkType } from 'utils/types';
import {
  buildApisUrl,
  buildCertificatesUrl,
  buildClustersUrl,
  buildEgressUrl,
  buildGatewaysUrl,
  buildGraphUrl,
  buildIngressUrl,
  buildInsightsUrl,
  buildLogsUrl,
  buildPortalsUrl,
  buildResourcesUrl,
  buildSecurityInsightsUrl,
  buildServicesUrl,
  buildTracingUrl,
  buildWorkspacesUrl
} from 'utils/url-builders';
import { SidebarStyles } from './Sidebar.style';
import { SidebarContext, SidebarContextProvider } from './SidebarContext';
import { SidebarSection, TopLevelItemProps } from './SidebarSection';

const appLogoMap = {
  loading: { icon: <Asset.GlooOnlyLogoTextOnly title='Gloo Logo' />, a11y: 'Gloo Logo' },
  platform: { icon: <Asset.GlooPlatformLogoTextOnly title='Gloo Platform Logo' />, a11y: 'Gloo Platform Logo' },
  core: { icon: <Asset.GlooCoreLogoTextOnly title='Gloo Core Logo' />, a11y: 'Gloo Core Logo' }
};

export const Sidebar = () => {
  di(usePermissions);
  const { hasPerm, loading } = usePermissions();

  // Top level checks
  // Unlike dashboard we only check for licenses here to both avoid loading insights on every page, as well as
  // the fact that the sidebar is a more broad display, where dashboard is a more specific one (ex: for cluster
  // dashboard we need to know if specific clusters have insights, not the product as a whole)
  const isPlat = hasPerm(Permission.PlatformLicense),
    isCore = hasPerm(Permission.CoreLicense),
    isCoreIstio = isCore && hasPerm(Permission.IstioEnabled),
    isCilium = hasPerm(Permission.NetworkForCiliumLicense);

  const logoData = appLogoMap[loading ? 'loading' : isCore ? 'core' : 'platform'];

  const sidebarSectionItems = useMemo<TopLevelItemProps[]>(() => {
    // Home
    const homeItems: TopLevelItemProps['items'] = [
      { label: 'Dashboard', url: '/', urlMatcher: 'exact' },
      isCore &&
        hasPerm(Permission.InsightsEnabled) && {
          label: 'Insights',
          url: buildInsightsUrl(),
          'data-testid': 'navbar-insights'
        }
    ];

    // Inventory
    const inventoryItems: TopLevelItemProps['items'] = [];
    // Page is only useful when you have platform license, as well as core license that then hides the platform dashboard
    if (isPlat && isCore) {
      inventoryItems.push({ label: 'Workspaces', url: buildWorkspacesUrl(), 'data-testid': 'navbar-workspaces' });
    }
    if (isCore) {
      inventoryItems.push({ label: 'Clusters', url: buildClustersUrl(), 'data-testid': 'navbar-clusters' });
    }
    if (isCoreIstio) {
      inventoryItems.push({ label: 'Services', url: buildServicesUrl(), 'data-testid': 'navbar-services' });
    }

    // Traffic
    const trafficItems: TopLevelItemProps['items'] = [];
    if (isPlat) {
      trafficItems.push({
        label: 'Gateways',
        url: buildGatewaysUrl(),
        'data-testid': 'navbar-gateways'
      });
    }
    if (isCoreIstio) {
      trafficItems.push(
        hasPerm(Permission.ViewIngress) && {
          label: 'Ingress',
          url: buildIngressUrl(),
          'data-testid': 'navbar-ingress'
        },
        hasPerm(Permission.ViewEgress) && { label: 'Egress', url: buildEgressUrl(), 'data-testid': 'navbar-egress' }
      );
    }

    // APIs
    const apiItems: TopLevelItemProps['items'] = [];
    if (isPlat) {
      apiItems.push(
        hasPerm(Permission.PortalEnabled) && {
          label: 'Portals',
          url: buildPortalsUrl(),
          'data-testid': 'navbar-portals'
        },
        hasPerm(Permission.CanViewApisPage) && {
          label: 'API Registry',
          url: buildApisUrl(),
          'data-testid': 'navbar-apis'
        }
      );
    }

    // Security
    const securityItems: TopLevelItemProps['items'] = [];
    if (isCoreIstio) {
      securityItems.push(
        hasPerm(Permission.InsightsEnabled) && {
          label: 'Security Insights',
          url: buildSecurityInsightsUrl(),
          'data-testid': 'navbar-security-insights'
        },
        { label: 'Certificates', url: buildCertificatesUrl(), 'data-testid': 'navbar-certificates' }
      );
    }

    // Resources
    const resourceItems: TopLevelItemProps['items'] = [];
    if (hasPerm(Permission.SoloResources)) {
      resourceItems.push({
        label: 'Solo',
        url: buildResourcesUrl(GvkType.GLOO),
        'data-testid': 'navbar-resources-solo'
      });
    }
    if (hasPerm(Permission.IstioEnabled) && (isPlat || isCoreIstio)) {
      resourceItems.push({
        label: 'Istio',
        url: buildResourcesUrl(GvkType.ISTIO),
        'data-testid': 'navbar-resources-istio'
      });
    }
    if (hasPerm(Permission.CiliumEnabled) && (isPlat || isCilium)) {
      resourceItems.push({
        label: 'Cilium',
        url: buildResourcesUrl(GvkType.CILIUM),
        'data-testid': 'navbar-resources-cilium'
      });
    }
    if (hasPerm(Permission.GatewayApi) && (isPlat || isCoreIstio)) {
      resourceItems.push({
        label: 'Gateway API',
        url: buildResourcesUrl(GvkType.KUBE_GATEWAY_API),
        'data-testid': 'navbar-resources-kubernetes-gateway-api'
      });
    }
    if (hasPerm(Permission.Kubernetes)) {
      resourceItems.push({
        label: 'Kubernetes',
        url: buildResourcesUrl(GvkType.KUBE_NON_GATEWAY),
        'data-testid': 'navbar-resources-kubernetes'
      });
    }

    // Observability
    const observabilityItems: TopLevelItemProps['items'] = [];
    if (hasPerm(Permission.GraphEnabled) && (isPlat || isCoreIstio)) {
      observabilityItems.push({
        label: 'Graph',
        url: buildGraphUrl(),
        'data-testid': 'navbar-graph'
      });
    }
    if (isPlat || isCoreIstio) {
      observabilityItems.push({
        label: 'Tracing',
        url: buildTracingUrl(),
        'data-testid': 'navbar-tracing'
      });
    }
    if (hasPerm(Permission.LogsEnabled)) {
      observabilityItems.push({
        label: 'Logs',
        url: buildLogsUrl(),
        'data-testid': 'navbar-logs'
      });
    }

    return [
      {
        icon: <Asset.HomeIcon />,
        label: 'Home',
        // url: '/',
        // urlMatcher: 'exact',
        'data-testid': 'navbar-overview',
        items: homeItems
      },
      {
        icon: <Asset.InventoryIcon />,
        label: 'Inventory',
        items: inventoryItems
      },
      {
        icon: <Asset.TrafficPolicyIcon />,
        label: 'Traffic',
        items: trafficItems
      },
      {
        icon: <Asset.ApiIcon />,
        label: 'APIs',
        items: apiItems
      },
      {
        icon: <Asset.SecureLockIcon />,
        label: 'Security',
        items: securityItems
      },
      {
        icon: <Asset.ResourcesIcon />,
        label: 'Resources',
        'data-testid': 'navbar-resources',
        items: resourceItems
      },
      {
        icon: <Asset.ObservabilityIcon />,
        label: 'Observability',
        items: observabilityItems
      }
    ];
  }, [isPlat, isCore, isCoreIstio, isCilium]);

  return (
    <SidebarContextProvider>
      <SidebarContext.Consumer>
        {({ collapsed, toggleCollapse }) => (
          <SidebarStyles.Container data-testid='sidebar' open={!collapsed}>
            <SidebarStyles.AppTitle.AppTitleBox role='link' to='/' open={!collapsed} aria-label='Site Root'>
              {/* Why a random UL? Aria expects nav links to be <li>s and outside of <ul>s, they add an ugly, unremovable :marker */}
              {/* <ul>
          <li> */}
              <SidebarStyles.AppTitle.CollapsingItemWrapper hideOverflow={true}>
                <SidebarStyles.AppTitle.GlooPlatformIconHolder textVisible={!collapsed}>
                  <Asset.GlooPlatformLogoNoText title={logoData.a11y} />
                  {logoData.icon}
                </SidebarStyles.AppTitle.GlooPlatformIconHolder>
              </SidebarStyles.AppTitle.CollapsingItemWrapper>
              {/* </li>
        </ul> */}
            </SidebarStyles.AppTitle.AppTitleBox>
            <SidebarStyles.InnerContainer>
              <div>
                <SidebarSection items={sidebarSectionItems} />
              </div>

              <SidebarStyles.CollapseButton onClick={toggleCollapse} open={!collapsed}>
                <Asset.SidebarCollapseIcon /> <span>Collapse</span>
              </SidebarStyles.CollapseButton>
            </SidebarStyles.InnerContainer>
          </SidebarStyles.Container>
        )}
      </SidebarContext.Consumer>
    </SidebarContextProvider>
  );
};
