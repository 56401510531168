/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/resilience/adaptive_request_concurrency_policy.proto" (package "resilience.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { DestinationReference } from "../../../common/v2/references_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { UInt32Value } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { DoubleValue } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { DestinationSelector } from "../../../common/v2/selectors_pb";
/**
 * Specifications for the policy.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec
 */
export interface AdaptiveRequestConcurrencyPolicySpec {
    /**
     * Destinations to apply the concurrency limit to.
     * Note that external services are not supported as destinations with this policy.
     * If empty, the policy applies to all destinations in the workspace.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationSelector apply_to_destinations = 1;
     */
    applyToDestinations: DestinationSelector[];
    /**
     * Details of the policy to apply to the selected destinations.
     *
     * @generated from protobuf field: resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config config = 2;
     */
    config?: AdaptiveRequestConcurrencyPolicySpec_Config;
}
/**
 * Configure how the gradient controller calculates the adaptive concurrency limit.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config
 */
export interface AdaptiveRequestConcurrencyPolicySpec_Config {
    /**
     * The percent of sampled requests to use when summarizing aggregated samples in the minRTT
     * calculation. If unset, defaults to 50%.
     *
     * @generated from protobuf field: google.protobuf.DoubleValue sample_aggregate_percentile = 2;
     */
    sampleAggregatePercentile?: DoubleValue;
    /**
     * The allowed upper-bound on the calculated concurrency limit. For example, you can cap the concurrency
     * limit to a maximum of 800 connections, in the case that the calculated concurrency limit exceeds this value.
     * If unset, defaults to 1000.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value max_concurrency_limit = 3;
     */
    maxConcurrencyLimit?: UInt32Value;
    /**
     * The period of time during which request latency samples are taken to recalculate the concurrency limit.
     * This field is required.
     *
     * @generated from protobuf field: uint32 concurrency_update_interval_millis = 4;
     */
    concurrencyUpdateIntervalMillis: number;
    /**
     * Configure how the gradient controller calculates the minimum round-trip time (minRTT) for the destination.
     * For more information about the minRTT formula and the following fields, see the
     * [Envoy adaptive concurrency docs](https://www.envoyproxy.io/docs/envoy/latest/configuration/http/http_filters/adaptive_concurrency_filter).
     * This field is required.
     *
     * @generated from protobuf field: resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config.MinRoundtripTimeCalculationParams min_rtt_calc_params = 5;
     */
    minRttCalcParams?: AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams;
    /**
     * Return a custom HTTP response status code to the downstream client when the concurrency limit is exceeded.
     * If this field is empty, omitted, or set to a non-error response of < 400, the response code defaults to 503 (Service Unavailable).
     *
     * @generated from protobuf field: uint32 concurrency_limit_exceeded_status = 6;
     */
    concurrencyLimitExceededStatus: number;
}
/**
 * Configure how the gradient controller calculates the minimum round-trip time (minRTT) for the destination.
 * For more information about the minRTT formula and the following fields, see the
 * [Envoy adaptive concurrency docs](https://www.envoyproxy.io/docs/envoy/latest/configuration/http/http_filters/adaptive_concurrency_filter).
 * This field is required.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config.MinRoundtripTimeCalculationParams
 */
export interface AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams {
    /**
     * The amount of time between each minRTT remeasurement.
     * This field is required.
     *
     * @generated from protobuf field: uint32 interval_millis = 1;
     */
    intervalMillis: number;
    /**
     * The number of requests to sample during the `concurrencyUpdateIntervalMillis` timeframe.
     * If unset, defaults to 50.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value request_count = 2;
     */
    requestCount?: UInt32Value;
    /**
     * Temporarily set the concurrency limit until the latest minRTT measurement is complete.
     * If unset, defaults to 3.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value min_concurrency = 3;
     */
    minConcurrency?: UInt32Value;
    /**
     * Add a random delay to the start of each minRTT measurement, represented as a percentage of the interval between each
     * remeasurement (`intervalMillis`). For example, if the interval is 1000ms and the jitter is 15%, the next minRTT measurement
     * begins in the range of 1000ms - 1150ms, because a delay between 0ms - 150ms is added to the 1000ms interval.
     * If unset, defaults to 15%.
     *
     * @generated from protobuf field: google.protobuf.DoubleValue jitter_percentile = 4;
     */
    jitterPercentile?: DoubleValue;
    /**
     * Add a buffer to the measured minRTT to stabilize natural variability in latency. This is represented as a percentage
     * of the measured value, and can be adjusted to allow more or less tolerance to the sampled latency values.
     * If unset, defaults to 25%.
     *
     * @generated from protobuf field: google.protobuf.DoubleValue buffer_percentile = 5;
     */
    bufferPercentile?: DoubleValue;
}
/**
 * The status of the policy after it is applied to your Gloo environment.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicyStatus
 */
export interface AdaptiveRequestConcurrencyPolicyStatus {
    /**
     * The state and workspace conditions of the applied resource.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of destinations selected by this policy.
     *
     * @generated from protobuf field: uint32 num_selected_destinations = 2;
     */
    numSelectedDestinations: number;
}
/**
 * @generated from protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicyReport
 */
export interface AdaptiveRequestConcurrencyPolicyReport {
    /**
     * A list of workspaces in which the policy can apply to workloads.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of destination selected by this policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationReference selected_destinations = 2;
     */
    selectedDestinations: DestinationReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveRequestConcurrencyPolicySpec$Type extends MessageType<AdaptiveRequestConcurrencyPolicySpec> {
    constructor() {
        super("resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec", [
            { no: 1, name: "apply_to_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationSelector },
            { no: 2, name: "config", kind: "message", T: () => AdaptiveRequestConcurrencyPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec
 */
export const AdaptiveRequestConcurrencyPolicySpec = new AdaptiveRequestConcurrencyPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveRequestConcurrencyPolicySpec_Config$Type extends MessageType<AdaptiveRequestConcurrencyPolicySpec_Config> {
    constructor() {
        super("resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config", [
            { no: 2, name: "sample_aggregate_percentile", kind: "message", T: () => DoubleValue },
            { no: 3, name: "max_concurrency_limit", kind: "message", T: () => UInt32Value },
            { no: 4, name: "concurrency_update_interval_millis", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "min_rtt_calc_params", kind: "message", T: () => AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams },
            { no: 6, name: "concurrency_limit_exceeded_status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config
 */
export const AdaptiveRequestConcurrencyPolicySpec_Config = new AdaptiveRequestConcurrencyPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams$Type extends MessageType<AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams> {
    constructor() {
        super("resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config.MinRoundtripTimeCalculationParams", [
            { no: 1, name: "interval_millis", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "request_count", kind: "message", T: () => UInt32Value },
            { no: 3, name: "min_concurrency", kind: "message", T: () => UInt32Value },
            { no: 4, name: "jitter_percentile", kind: "message", T: () => DoubleValue },
            { no: 5, name: "buffer_percentile", kind: "message", T: () => DoubleValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config.MinRoundtripTimeCalculationParams
 */
export const AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams = new AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveRequestConcurrencyPolicyStatus$Type extends MessageType<AdaptiveRequestConcurrencyPolicyStatus> {
    constructor() {
        super("resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 2, name: "num_selected_destinations", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicyStatus
 */
export const AdaptiveRequestConcurrencyPolicyStatus = new AdaptiveRequestConcurrencyPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveRequestConcurrencyPolicyReport$Type extends MessageType<AdaptiveRequestConcurrencyPolicyReport> {
    constructor() {
        super("resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicyReport
 */
export const AdaptiveRequestConcurrencyPolicyReport = new AdaptiveRequestConcurrencyPolicyReport$Type();
