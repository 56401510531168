import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InfoTooltip } from '../InfoTooltip';

export namespace SoloLabelStyles {
  export const Container = styled.div`
    display: flex;
    gap: 4px;
    margin-bottom: 10px;
  `;
  export const Label = styled.label(({ theme }) => {
    return css`
      color: ${theme.novemberGrey};
      font-size: 16px;
      font-weight: 500;
    `;
  });
}

export interface SoloLabelProps {
  children: React.ReactNode;
  infoTooltip?: React.ReactNode;
  extras?: React.ReactNode;
  htmlFor?: string;
}
export const SoloLabel = ({ children, infoTooltip, extras, htmlFor }: SoloLabelProps) => {
  return (
    <SoloLabelStyles.Container>
      <SoloLabelStyles.Label htmlFor={htmlFor}>{children}</SoloLabelStyles.Label>
      {/* Interactive elements shouldn't be inside the label */}
      {!!infoTooltip && <InfoTooltip tooltip={infoTooltip} />}
      {extras}
    </SoloLabelStyles.Container>
  );
};
