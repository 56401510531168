import { CardStyles } from 'Components/Common/Card';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { GetGraphqlApiResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { convertFromPBValue, getExecutableSchema, getProxied } from 'utils/graphql-helpers';
import GqlInfoFromHeadersMap from './GqlInfoFromHeadersMap';
import GqlProxiedRemoteConfigTabs from './GqlProxiedRemoteConfigTabs';
import { GqlResolverModalStyles as Styles } from './GqlResolverModal.style';

const GqlProxiedRemoteConfigModal = ({
  show,
  onClose,
  api,
  routeName
}: {
  show: boolean;
  onClose(): void;
  api: GetGraphqlApiResponse;
  routeName: string;
}) => {
  const proxied = getProxied(getExecutableSchema(api));

  //
  // Render
  //
  if (!proxied || !show) return null;
  return (
    <SoloModal width={1000} onClose={onClose} withPadding data-testid='gql-remote-config-modal'>
      <SoloModalHeader
        title={routeName}
        subtitle='Remote Configuration'
        icon={<Asset.ProxiedGraphqlIcon />}
        status={api.status}
        rightContent={
          <GqlInfoFromHeadersMap
            headersMap={Object.entries(proxied.headers)}
            defaultHttpMethod={'POST'}
            httpMethodTooltipText='The default method is "POST". This can be changed by adding a ":method" header with a value for the new HTTP method.'
          />
        }></SoloModalHeader>

      <CardStyles.CardList>
        <CardStyles.Card>
          <CardStyles.CardHeader>
            Remote Configuration
            <CardStyles.CardHeaderRightIcon>
              <Styles.IconContainer>
                <Asset.ConfigIcon />
              </Styles.IconContainer>
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          {/* 

            // 
            // Variables
            // 
            */}
          <SoloListCardStyles.Container>
            <SoloListCardStyles.Title>Variables</SoloListCardStyles.Title>
            <Styles.FancyCodeDisplayerContainer>
              <FancyCodeDisplayer
                padLineNumbersWithZeros={2}
                includeBorderBackground={false}
                contentString={Object.entries(proxied.variables)
                  .map(
                    ([variable, value]) =>
                      `${variable}` +
                      (value.extraction.oneofKind === undefined ? ' {}' : '') +
                      (value.extraction.oneofKind === 'dynamicMetadata'
                        ? `\n  dynamicMetadata:` +
                        `\n    key: ${value.extraction.dynamicMetadata.key}` +
                        `\n    metadataNamespace: ${value.extraction.dynamicMetadata.metadataNamespace}`
                        : '') +
                      (value.extraction.oneofKind === 'jsonValue'
                        ? `\n  json: ${JSON.stringify(convertFromPBValue(value.extraction.jsonValue))}`
                        : '') +
                      (value.extraction.oneofKind === 'requestHeader'
                        ? `\n  requestHeader: ${value.extraction.requestHeader}`
                        : '')
                  )
                  .join('\n')}
                type={'yaml'}
              />
            </Styles.FancyCodeDisplayerContainer>
          </SoloListCardStyles.Container>
          {/* 

            // 
            // Headers + Query Parameters
            // 
            */}
          <Spacer pt={3}>
            <GqlProxiedRemoteConfigTabs headers={proxied.headers} queryParams={proxied.queryParams} />
          </Spacer>
        </CardStyles.Card>
      </CardStyles.CardList>
    </SoloModal>
  );
};

export default GqlProxiedRemoteConfigModal;
