import {
  Insight,
  Insight_Data,
  Insight_Severity
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { State, Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { Combine } from 'utils/types';
import { InsightCodeMap } from './dashboard-types';

/**
 * Flattens the InsightCodeMap into an array.
 */
export const insightCodeMapToArray = (codeMap: InsightCodeMap) =>
  Object.values(codeMap).map<Code>(({ group, key }) => ({
    group,
    key
  }));

/**
 * This changes the width/height of the canvas to make it render in higher reolution.
 * From: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas#scaling_for_high_resolution_displays
 */
export const setupCanvasScreenResolutionScaling = (ctx: CanvasRenderingContext2D) => {
  const { canvas } = ctx;
  // Get the DPR and size of the canvas
  const dpr = window.devicePixelRatio;
  const rect = canvas.getBoundingClientRect();
  //
  // Set the "actual" size of the canvas
  canvas.width = rect.width * dpr;
  canvas.height = rect.height * dpr;
  //
  // Scale the context to ensure correct drawing operations
  ctx.scale(dpr, dpr);
  //
  // Set the "drawn" size of the canvas
  canvas.style.width = `${rect.width}px`;
  canvas.style.height = `${rect.height}px`;
};

export const degreesToRadians = (degrees: number) => {
  return degrees * (Math.PI / 180);
};

const severityStateMap: Record<Insight_Severity, State> = {
  [Insight_Severity.SEVERITY_UNSPECIFIED]: State.WARNING,
  [Insight_Severity.INFO]: State.ACCEPTED,
  [Insight_Severity.WARNING]: State.WARNING,
  [Insight_Severity.ERROR]: State.FAILED
};

export function convertInsightSeverityToState(severity: Insight_Severity) {
  return severityStateMap[severity];
}

/**
 * This returns the worst severity `State` in the passed-in insights array,
 * and a `validationErrors` of the non-INFO insight's summaries.
 *
 * **If the insights array is empty, `undefined` is returned.**
 */
export function createCombinedInsightStatus(insights: Insight[]): Status | undefined {
  if (!insights.length) return undefined;
  // Sort from worst to best
  insights = insights.sort((a, b) => b.severity - a.severity);
  return {
    state: convertInsightSeverityToState(insights[0].severity), // Show worst severity
    validationErrors: insights.filter(ins => ins.severity > Insight_Severity.INFO).map(ins => ins.summary)
  };
}

export const insightSeverityMap = {
  [Insight_Severity.SEVERITY_UNSPECIFIED]: {
    displayValue: 'Unspecified'
  },
  [Insight_Severity.INFO]: { displayValue: 'Info' },
  [Insight_Severity.WARNING]: { displayValue: 'Warning' },
  [Insight_Severity.ERROR]: { displayValue: 'Error' }
} as const;

export function getInsightData<T extends Combine<Insight_Data['data']>, K extends Insight_Data['data']['oneofKind']>(
  insight: Insight,
  code: K
) {
  const data = insight.data?.data;
  if (!code || !data || data.oneofKind === undefined) {
    return;
  }
  const combinedData = data as T;
  return combinedData[code];
}
