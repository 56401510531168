import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { SoloTableStyles } from 'Components/Common/SoloTable.style';
import { createEllipseLinkColumn, createEllipseTextColumn } from 'Components/Common/SoloTableCells';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ApiDestination } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/portal_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { getDestinationKindIcon, getDestinationKindReadableName } from 'utils/types';
import { buildWorkspaceDestinationDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';

interface TableFields {
  key: string;
  name: { text: string; url: string };
  clusterName?: string;
  namespace?: string;
  type?: DestinationKind;
}
const columns: SoloColumnsType<TableFields> = [
  createEllipseLinkColumn({ title: 'Name', dataIndex: 'name' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render(type) {
      return (
        <SoloTableStyles.CellIconText>
          {getDestinationKindIcon(type)} {getDestinationKindReadableName(type)}
        </SoloTableStyles.CellIconText>
      );
    }
  },
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' })
];

export const ApiDestinationTable = ({ destinations }: { destinations: ApiDestination[] }) => {
  const { lookupWorkspaceName } = useNamespaceClusterToWorkspaceMap();

  const tableData = useMemo(
    () =>
      destinations.map(({ ref, type }) => ({
        key: buildIdFromRef(ref),
        ...ref,
        name: {
          text: ref?.name ?? '',
          url: buildWorkspaceDestinationDetailsUrl(lookupWorkspaceName(ref), type, ref)
        },
        type: type
      })),
    [destinations, lookupWorkspaceName]
  );

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.ServiceIcon />}
          title='There are no Destinations to display'
          description=''
          href={docLinks.enterprise.workspace_export}
        />
      }
    />
  );
};
