import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { svgBasics } from './utils';

interface SoloButtonBaseComponentProps {
  small?: boolean;
}
const SoloButtonBaseComponent = styled.button<SoloButtonBaseComponentProps>(
  ({ small }) => css`
    position: relative;
    display: inline-flex;
    padding: ${small ? '4px 10px' : '8px 15px'};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    border-radius: 3px;
    outline: none;
    ${small ? '' : 'min-width: 80px;'}
    height: auto;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    background: white;
    color: black;
    border: 1px solid black;

    a {
      color: black;

      transition: none;
    }

    transition: min-width 0.3s;

    &:disabled,
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    }

    > span {
      position: relative;
      z-index: 2;
    }
  `
);

export const SoloButton = styled(SoloButtonBaseComponent)(
  ({ theme }) => css`
    background: white;
    color: ${theme.seaBlue};
    border: 1px solid ${theme.seaBlue};

    a {
      color: ${theme.seaBlue};
    }

    &:hover,
    &:focus {
      color: white;
      background: ${theme.lakeBlue};
      border-color: ${theme.lakeBlue};

      &:not(:active) {
        ${svgBasics(undefined, theme.white)}
      }

      a {
        color: white;
      }
    }

    &:active {
      color: ${theme.seaBlue};
      background: white;
      border-color: ${theme.seaBlue};

      a {
        color: ${theme.seaBlue};
      }
    }

    &:disabled,
    &.disabled {
      background: ${theme.aprilGrey};
      color: ${theme.septemberGrey};
      border-color: ${theme.septemberGrey};

      a {
        color: ${theme.septemberGrey};
      }
    }
  `
);

export const SoloButtonSolid = styled(SoloButtonBaseComponent)<{ isNegative?: boolean }>(
  ({ theme, isNegative }) => css`
    background: ${isNegative ? theme.grapefruitOrange : theme.seaBlue};
    color: white;
    border: 1px solid ${isNegative ? theme.grapefruitOrange : theme.seaBlue}; /* This makes it so white buttons will have the same sizing */
    a {
      color: white;
    }

    &:hover,
    &:focus {
      color: white;
      background: ${isNegative ? theme.peachOrange : theme.lakeBlue};

      a {
        color: white;
      }
    }

    &:active {
      background: ${isNegative ? theme.grapefruitOrange : theme.seaBlue};
    }

    &:disabled,
    &.disabled {
      background: ${isNegative ? theme.tangerineOrange : theme.splashBlue};
      color: white;

      a {
        color: white;
      }
    }
  `
);

/*
export const SoloCancelButton = styled.button`
  ${SoloButtonCSS};
  background: ${theme.juneGrey};

  &:hover,
  &:focus {
    background: ${theme.mayGrey};
  }

  &:active {
    background: ${theme.juneGrey};
  }

  &:disabled {
    background: ${theme.juneGrey};
  }
`;*/

// Having a link inside a button causes a11y warnings
export const SoloButtonLink = SoloButton.withComponent(Link);
export const SoloButtonLinkExternal = SoloButton.withComponent('a');
export const SoloButtonSolidLink = SoloButtonSolid.withComponent(Link);
export const SoloButtonSolidLinkExternal = SoloButtonSolid.withComponent('a');

export const IconButton = styled.button`
  display: inline-flex;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
`;
