import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { InputObjectTypeDefinitionNode } from 'graphql';
import { useMemo } from 'react';
import { Centered } from 'Styles/CommonEmotions/centered';
import { getDescriptionColumnValue, GqlSchemaTabTableProps } from 'utils/graphql-schema-search-helpers';
import {
  renderCenteredTableCell,
  renderDefinitionNameLink,
  RenderDefinitionNameLinkProps
} from '../../../GqlSoloTableCells';

type TableFields = {
  key: string;
  nameLink: RenderDefinitionNameLinkProps;
  description: string;
  fields?: number;
};
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Name',
    dataIndex: 'nameLink',
    render: renderDefinitionNameLink
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: renderReactMarkdown
  },
  {
    title: () => <Centered horizontal>Fields</Centered>,
    dataIndex: 'fields',
    render: renderCenteredTableCell
  }
];

const GqlInputTabTable = ({
  definitions,
  onTypeClick,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<InputObjectTypeDefinitionNode>) => {
  //
  // Pagination, Table Data
  //
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(definitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(d => ({
      key: d.name.value,
      nameLink: { name: d.name.value, tabHeader, onTypeClick },
      description: getDescriptionColumnValue(d),
      fields: d.fields?.length ?? 0
    }));
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  // Render
  //
  if (hidden) return null;
  return (
    <SoloTable
      removeHorizontalPadding
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='gql-inputs-table'
    />
  );
};

export default GqlInputTabTable;
