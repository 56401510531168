import { Tooltip } from 'antd';
import { GqlResolverInfoIcon } from 'assets/assets';
import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { useContext } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { GqlLandingContext } from '../../../context/GqlLandingContext';
import { GqlSchemaTabStyles } from '../Tabs/GqlSchemaTabStyles.style';

const GqlTypeMergeButton = ({ objectName, onViewDetails }: { objectName: string; onViewDetails: () => void }) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { fullTypeMergeMap } = gqlCtx;
  const typeMergeAndSubschemas = fullTypeMergeMap[objectName];

  //
  // Render
  //
  if (!typeMergeAndSubschemas?.length)
    return (
      <Spacer display='inline-block'>
        <Tooltip title={<Spacer padding={2}>No type merge defined</Spacer>} trigger='hover'>
          <GqlSchemaTabStyles.GqlNoCheckedIcon />
        </Tooltip>
      </Spacer>
    );
  return (
    <Spacer display='inline-block'>
      <GqlSchemaTabStyles.GqlCheckedIconContainer data-testid='gql-type-merge-icon'>
        <Tooltip
          placement='topLeft'
          trigger='hover'
          title={
            <Spacer px={5} py={2}>
              <GqlSchemaTabStyles.TableCellTooltip>
                <Spacer mb={3}>Merged From</Spacer>
                {typeMergeAndSubschemas.map(({ subschema }, idx) => (
                  <Spacer mx={-2} mb={2} key={idx}>
                    <SecondaryInfoItem
                      data={subschema.graphqlSchemaRef?.name ?? subschema.graphqlStitchedSchemaRef?.name ?? ''}
                    />
                  </Spacer>
                ))}
                <Spacer display='inline-block' pt={2}>
                  <UnstyledButton onClick={onViewDetails}>
                    <SoloLinkStyles.SoloLinkWrapper>
                      <SoloLinkStyles.SoloLinkLooks withArrow data-testid='gql-type-merge-details-link'>
                        VIEW DETAILS
                      </SoloLinkStyles.SoloLinkLooks>
                    </SoloLinkStyles.SoloLinkWrapper>
                  </UnstyledButton>
                </Spacer>
              </GqlSchemaTabStyles.TableCellTooltip>
            </Spacer>
          }>
          <GqlResolverInfoIcon />
        </Tooltip>
      </GqlSchemaTabStyles.GqlCheckedIconContainer>
    </Spacer>
  );
};

export default GqlTypeMergeButton;
