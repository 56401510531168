/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/api/v2/core/base.proto" (package "envoy.api.v2.core", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { FractionalPercent } from "../../../type/percent_pb";
import { Any } from "../../../../google/protobuf/any_pb";
import { HttpUri } from "./http_uri_pb";
import { UInt32Value } from "../../../../google/protobuf/wrappers_pb";
import { BackoffStrategy } from "./backoff_pb";
import { BoolValue } from "../../../../google/protobuf/wrappers_pb";
import { Address } from "./address_pb";
import { Struct } from "../../../../google/protobuf/struct_pb";
import { SemanticVersion } from "../../../type/semantic_version_pb";
/**
 * Identifies location of where either Envoy runs or where upstream hosts run.
 *
 * @generated from protobuf message envoy.api.v2.core.Locality
 */
export interface Locality {
    /**
     * Region this :ref:`zone <envoy_api_field_core.Locality.zone>` belongs to.
     *
     * @generated from protobuf field: string region = 1;
     */
    region: string;
    /**
     * Defines the local service zone where Envoy is running. Though optional, it
     * should be set if discovery service routing is used and the discovery
     * service exposes :ref:`zone data <envoy_api_field_endpoint.LocalityLbEndpoints.locality>`,
     * either in this message or via :option:`--service-zone`. The meaning of zone
     * is context dependent, e.g. `Availability Zone (AZ)
     * <https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html>`_
     * on AWS, `Zone <https://cloud.google.com/compute/docs/regions-zones/>`_ on
     * GCP, etc.
     *
     * @generated from protobuf field: string zone = 2;
     */
    zone: string;
    /**
     * When used for locality of upstream hosts, this field further splits zone
     * into smaller chunks of sub-zones so they can be load balanced
     * independently.
     *
     * @generated from protobuf field: string sub_zone = 3;
     */
    subZone: string;
}
/**
 * BuildVersion combines SemVer version of extension with free-form build information
 * (i.e. 'alpha', 'private-build') as a set of strings.
 *
 * @generated from protobuf message envoy.api.v2.core.BuildVersion
 */
export interface BuildVersion {
    /**
     * SemVer version of extension.
     *
     * @generated from protobuf field: envoy.type.SemanticVersion version = 1;
     */
    version?: SemanticVersion;
    /**
     * Free-form build information.
     * Envoy defines several well known keys in the source/common/version/version.h file
     *
     * @generated from protobuf field: google.protobuf.Struct metadata = 2;
     */
    metadata?: Struct;
}
/**
 * Version and identification for an Envoy extension.
 * [#next-free-field: 6]
 *
 * @generated from protobuf message envoy.api.v2.core.Extension
 */
export interface Extension {
    /**
     * This is the name of the Envoy filter as specified in the Envoy
     * configuration, e.g. envoy.filters.http.router, com.acme.widget.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Category of the extension.
     * Extension category names use reverse DNS notation. For instance "envoy.filters.listener"
     * for Envoy's built-in listener filters or "com.acme.filters.http" for HTTP filters from
     * acme.com vendor.
     * [#comment:TODO(yanavlasov): Link to the doc with existing envoy category names.]
     *
     * @generated from protobuf field: string category = 2;
     */
    category: string;
    /**
     * [#not-implemented-hide:] Type descriptor of extension configuration proto.
     * [#comment:TODO(yanavlasov): Link to the doc with existing configuration protos.]
     * [#comment:TODO(yanavlasov): Add tests when PR #9391 lands.]
     *
     * @generated from protobuf field: string type_descriptor = 3;
     */
    typeDescriptor: string;
    /**
     * The version is a property of the extension and maintained independently
     * of other extensions and the Envoy API.
     * This field is not set when extension did not provide version information.
     *
     * @generated from protobuf field: envoy.api.v2.core.BuildVersion version = 4;
     */
    version?: BuildVersion;
    /**
     * Indicates that the extension is present but was disabled via dynamic configuration.
     *
     * @generated from protobuf field: bool disabled = 5;
     */
    disabled: boolean;
}
/**
 * Identifies a specific Envoy instance. The node identifier is presented to the
 * management server, which may use this identifier to distinguish per Envoy
 * configuration for serving.
 * [#next-free-field: 12]
 *
 * @generated from protobuf message envoy.api.v2.core.Node
 */
export interface Node {
    /**
     * An opaque node identifier for the Envoy node. This also provides the local
     * service node name. It should be set if any of the following features are
     * used: :ref:`statsd <arch_overview_statistics>`, :ref:`CDS
     * <config_cluster_manager_cds>`, and :ref:`HTTP tracing
     * <arch_overview_tracing>`, either in this message or via
     * :option:`--service-node`.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Defines the local service cluster name where Envoy is running. Though
     * optional, it should be set if any of the following features are used:
     * :ref:`statsd <arch_overview_statistics>`, :ref:`health check cluster
     * verification
     * <envoy_api_field_core.HealthCheck.HttpHealthCheck.service_name_matcher>`,
     * :ref:`runtime override directory <envoy_api_msg_config.bootstrap.v2.Runtime>`,
     * :ref:`user agent addition
     * <envoy_api_field_config.filter.network.http_connection_manager.v2.HttpConnectionManager.add_user_agent>`,
     * :ref:`HTTP global rate limiting <config_http_filters_rate_limit>`,
     * :ref:`CDS <config_cluster_manager_cds>`, and :ref:`HTTP tracing
     * <arch_overview_tracing>`, either in this message or via
     * :option:`--service-cluster`.
     *
     * @generated from protobuf field: string cluster = 2;
     */
    cluster: string;
    /**
     * Opaque metadata extending the node identifier. Envoy will pass this
     * directly to the management server.
     *
     * @generated from protobuf field: google.protobuf.Struct metadata = 3;
     */
    metadata?: Struct;
    /**
     * Locality specifying where the Envoy instance is running.
     *
     * @generated from protobuf field: envoy.api.v2.core.Locality locality = 4;
     */
    locality?: Locality;
    /**
     * This is motivated by informing a management server during canary which
     * version of Envoy is being tested in a heterogeneous fleet. This will be set
     * by Envoy in management server RPCs.
     * This field is deprecated in favor of the user_agent_name and user_agent_version values.
     *
     * @deprecated
     * @generated from protobuf field: string build_version = 5 [deprecated = true];
     */
    buildVersion: string;
    /**
     * Free-form string that identifies the entity requesting config.
     * E.g. "envoy" or "grpc"
     *
     * @generated from protobuf field: string user_agent_name = 6;
     */
    userAgentName: string;
    /**
     * @generated from protobuf oneof: user_agent_version_type
     */
    userAgentVersionType: {
        oneofKind: "userAgentVersion";
        /**
         * Free-form string that identifies the version of the entity requesting config.
         * E.g. "1.12.2" or "abcd1234", or "SpecialEnvoyBuild"
         *
         * @generated from protobuf field: string user_agent_version = 7;
         */
        userAgentVersion: string;
    } | {
        oneofKind: "userAgentBuildVersion";
        /**
         * Structured version of the entity requesting config.
         *
         * @generated from protobuf field: envoy.api.v2.core.BuildVersion user_agent_build_version = 8;
         */
        userAgentBuildVersion: BuildVersion;
    } | {
        oneofKind: undefined;
    };
    /**
     * List of extensions and their versions supported by the node.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.Extension extensions = 9;
     */
    extensions: Extension[];
    /**
     * Client feature support list. These are well known features described
     * in the Envoy API repository for a given major version of an API. Client features
     * use reverse DNS naming scheme, for example `com.acme.feature`.
     * See :ref:`the list of features <client_features>` that xDS client may
     * support.
     *
     * @generated from protobuf field: repeated string client_features = 10;
     */
    clientFeatures: string[];
    /**
     * Known listening ports on the node as a generic hint to the management server
     * for filtering :ref:`listeners <config_listeners>` to be returned. For example,
     * if there is a listener bound to port 80, the list can optionally contain the
     * SocketAddress `(0.0.0.0,80)`. The field is optional and just a hint.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.Address listening_addresses = 11;
     */
    listeningAddresses: Address[];
}
/**
 * Metadata provides additional inputs to filters based on matched listeners,
 * filter chains, routes and endpoints. It is structured as a map, usually from
 * filter name (in reverse DNS format) to metadata specific to the filter. Metadata
 * key-values for a filter are merged as connection and request handling occurs,
 * with later values for the same key overriding earlier values.
 *
 * An example use of metadata is providing additional values to
 * http_connection_manager in the envoy.http_connection_manager.access_log
 * namespace.
 *
 * Another example use of metadata is to per service config info in cluster metadata, which may get
 * consumed by multiple filters.
 *
 * For load balancing, Metadata provides a means to subset cluster endpoints.
 * Endpoints have a Metadata object associated and routes contain a Metadata
 * object to match against. There are some well defined metadata used today for
 * this purpose:
 *
 * * ``{"envoy.lb": {"canary": <bool> }}`` This indicates the canary status of an
 *   endpoint and is also used during header processing
 *   (x-envoy-upstream-canary) and for stats purposes.
 * [#next-major-version: move to type/metadata/v2]
 *
 * @generated from protobuf message envoy.api.v2.core.Metadata
 */
export interface Metadata {
    /**
     * Key is the reverse DNS filter name, e.g. com.acme.widget. The envoy.*
     * namespace is reserved for Envoy's built-in filters.
     *
     * @generated from protobuf field: map<string, google.protobuf.Struct> filter_metadata = 1;
     */
    filterMetadata: {
        [key: string]: Struct;
    };
}
/**
 * Runtime derived uint32 with a default when not specified.
 *
 * @generated from protobuf message envoy.api.v2.core.RuntimeUInt32
 */
export interface RuntimeUInt32 {
    /**
     * Default value if runtime value is not available.
     *
     * @generated from protobuf field: uint32 default_value = 2;
     */
    defaultValue: number;
    /**
     * Runtime key to get value for comparison. This value is used if defined.
     *
     * @generated from protobuf field: string runtime_key = 3;
     */
    runtimeKey: string;
}
/**
 * Runtime derived double with a default when not specified.
 *
 * @generated from protobuf message envoy.api.v2.core.RuntimeDouble
 */
export interface RuntimeDouble {
    /**
     * Default value if runtime value is not available.
     *
     * @generated from protobuf field: double default_value = 1;
     */
    defaultValue: number;
    /**
     * Runtime key to get value for comparison. This value is used if defined.
     *
     * @generated from protobuf field: string runtime_key = 2;
     */
    runtimeKey: string;
}
/**
 * Runtime derived bool with a default when not specified.
 *
 * @generated from protobuf message envoy.api.v2.core.RuntimeFeatureFlag
 */
export interface RuntimeFeatureFlag {
    /**
     * Default value if runtime value is not available.
     *
     * @generated from protobuf field: google.protobuf.BoolValue default_value = 1;
     */
    defaultValue?: BoolValue;
    /**
     * Runtime key to get value for comparison. This value is used if defined. The boolean value must
     * be represented via its
     * `canonical JSON encoding <https://developers.google.com/protocol-buffers/docs/proto3#json>`_.
     *
     * @generated from protobuf field: string runtime_key = 2;
     */
    runtimeKey: string;
}
/**
 * Header name/value pair.
 *
 * @generated from protobuf message envoy.api.v2.core.HeaderValue
 */
export interface HeaderValue {
    /**
     * Header name.
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * Header value.
     *
     * The same :ref:`format specifier <config_access_log_format>` as used for
     * :ref:`HTTP access logging <config_access_log>` applies here, however
     * unknown header values are replaced with the empty string instead of `-`.
     *
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * Header name/value pair plus option to control append behavior.
 *
 * @generated from protobuf message envoy.api.v2.core.HeaderValueOption
 */
export interface HeaderValueOption {
    /**
     * Header name/value pair that this option applies to.
     *
     * @generated from protobuf field: envoy.api.v2.core.HeaderValue header = 1;
     */
    header?: HeaderValue;
    /**
     * Should the value be appended? If true (default), the value is appended to
     * existing values.
     *
     * @generated from protobuf field: google.protobuf.BoolValue append = 2;
     */
    append?: BoolValue;
}
/**
 * Wrapper for a set of headers.
 *
 * @generated from protobuf message envoy.api.v2.core.HeaderMap
 */
export interface HeaderMap {
    /**
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValue headers = 1;
     */
    headers: HeaderValue[];
}
/**
 * Data source consisting of either a file or an inline value.
 *
 * @generated from protobuf message envoy.api.v2.core.DataSource
 */
export interface DataSource {
    /**
     * @generated from protobuf oneof: specifier
     */
    specifier: {
        oneofKind: "filename";
        /**
         * Local filesystem data source.
         *
         * @generated from protobuf field: string filename = 1;
         */
        filename: string;
    } | {
        oneofKind: "inlineBytes";
        /**
         * Bytes inlined in the configuration.
         *
         * @generated from protobuf field: bytes inline_bytes = 2;
         */
        inlineBytes: Uint8Array;
    } | {
        oneofKind: "inlineString";
        /**
         * String inlined in the configuration.
         *
         * @generated from protobuf field: string inline_string = 3;
         */
        inlineString: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * The message specifies the retry policy of remote data source when fetching fails.
 *
 * @generated from protobuf message envoy.api.v2.core.RetryPolicy
 */
export interface RetryPolicy {
    /**
     * Specifies parameters that control :ref:`retry backoff strategy <envoy_api_msg_core.BackoffStrategy>`.
     * This parameter is optional, in which case the default base interval is 1000 milliseconds. The
     * default maximum interval is 10 times the base interval.
     *
     * @generated from protobuf field: envoy.api.v2.core.BackoffStrategy retry_back_off = 1;
     */
    retryBackOff?: BackoffStrategy;
    /**
     * Specifies the allowed number of retries. This parameter is optional and
     * defaults to 1.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value num_retries = 2;
     */
    numRetries?: UInt32Value;
}
/**
 * The message specifies how to fetch data from remote and how to verify it.
 *
 * @generated from protobuf message envoy.api.v2.core.RemoteDataSource
 */
export interface RemoteDataSource {
    /**
     * The HTTP URI to fetch the remote data.
     *
     * @generated from protobuf field: envoy.api.v2.core.HttpUri http_uri = 1;
     */
    httpUri?: HttpUri;
    /**
     * SHA256 string for verifying data.
     *
     * @generated from protobuf field: string sha256 = 2;
     */
    sha256: string;
    /**
     * Retry policy for fetching remote data.
     *
     * @generated from protobuf field: envoy.api.v2.core.RetryPolicy retry_policy = 3;
     */
    retryPolicy?: RetryPolicy;
}
/**
 * Async data source which support async data fetch.
 *
 * @generated from protobuf message envoy.api.v2.core.AsyncDataSource
 */
export interface AsyncDataSource {
    /**
     * @generated from protobuf oneof: specifier
     */
    specifier: {
        oneofKind: "local";
        /**
         * Local async data source.
         *
         * @generated from protobuf field: envoy.api.v2.core.DataSource local = 1;
         */
        local: DataSource;
    } | {
        oneofKind: "remote";
        /**
         * Remote async data source.
         *
         * @generated from protobuf field: envoy.api.v2.core.RemoteDataSource remote = 2;
         */
        remote: RemoteDataSource;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Configuration for transport socket in :ref:`listeners <config_listeners>` and
 * :ref:`clusters <envoy_api_msg_Cluster>`. If the configuration is
 * empty, a default transport socket implementation and configuration will be
 * chosen based on the platform and existence of tls_context.
 *
 * @generated from protobuf message envoy.api.v2.core.TransportSocket
 */
export interface TransportSocket {
    /**
     * The name of the transport socket to instantiate. The name must match a supported transport
     * socket implementation.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf oneof: config_type
     */
    configType: {
        oneofKind: "config";
        /**
         * @deprecated
         * @generated from protobuf field: google.protobuf.Struct config = 2 [deprecated = true];
         */
        config: Struct;
    } | {
        oneofKind: "typedConfig";
        /**
         * @generated from protobuf field: google.protobuf.Any typed_config = 3;
         */
        typedConfig: Any;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Runtime derived FractionalPercent with defaults for when the numerator or denominator is not
 * specified via a runtime key.
 *
 * .. note::
 *
 *   Parsing of the runtime key's data is implemented such that it may be represented as a
 *   :ref:`FractionalPercent <envoy_api_msg_type.FractionalPercent>` proto represented as JSON/YAML
 *   and may also be represented as an integer with the assumption that the value is an integral
 *   percentage out of 100. For instance, a runtime key lookup returning the value "42" would parse
 *   as a `FractionalPercent` whose numerator is 42 and denominator is HUNDRED.
 *
 * @generated from protobuf message envoy.api.v2.core.RuntimeFractionalPercent
 */
export interface RuntimeFractionalPercent {
    /**
     * Default value if the runtime value's for the numerator/denominator keys are not available.
     *
     * @generated from protobuf field: envoy.type.FractionalPercent default_value = 1;
     */
    defaultValue?: FractionalPercent;
    /**
     * Runtime key for a YAML representation of a FractionalPercent.
     *
     * @generated from protobuf field: string runtime_key = 2;
     */
    runtimeKey: string;
}
/**
 * Identifies a specific ControlPlane instance that Envoy is connected to.
 *
 * @generated from protobuf message envoy.api.v2.core.ControlPlane
 */
export interface ControlPlane {
    /**
     * An opaque control plane identifier that uniquely identifies an instance
     * of control plane. This can be used to identify which control plane instance,
     * the Envoy is connected to.
     *
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
}
// [#protodoc-title: Common types]

/**
 * Envoy supports :ref:`upstream priority routing
 * <arch_overview_http_routing_priority>` both at the route and the virtual
 * cluster level. The current priority implementation uses different connection
 * pool and circuit breaking settings for each priority level. This means that
 * even for HTTP/2 requests, two physical connections will be used to an
 * upstream host. In the future Envoy will likely support true HTTP/2 priority
 * over a single upstream connection.
 *
 * @generated from protobuf enum envoy.api.v2.core.RoutingPriority
 */
export enum RoutingPriority {
    /**
     * @generated from protobuf enum value: DEFAULT = 0;
     */
    DEFAULT = 0,
    /**
     * @generated from protobuf enum value: HIGH = 1;
     */
    HIGH = 1
}
/**
 * HTTP request method.
 *
 * @generated from protobuf enum envoy.api.v2.core.RequestMethod
 */
export enum RequestMethod {
    /**
     * @generated from protobuf enum value: METHOD_UNSPECIFIED = 0;
     */
    METHOD_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: GET = 1;
     */
    GET = 1,
    /**
     * @generated from protobuf enum value: HEAD = 2;
     */
    HEAD = 2,
    /**
     * @generated from protobuf enum value: POST = 3;
     */
    POST = 3,
    /**
     * @generated from protobuf enum value: PUT = 4;
     */
    PUT = 4,
    /**
     * @generated from protobuf enum value: DELETE = 5;
     */
    DELETE = 5,
    /**
     * @generated from protobuf enum value: CONNECT = 6;
     */
    CONNECT = 6,
    /**
     * @generated from protobuf enum value: OPTIONS = 7;
     */
    OPTIONS = 7,
    /**
     * @generated from protobuf enum value: TRACE = 8;
     */
    TRACE = 8,
    /**
     * @generated from protobuf enum value: PATCH = 9;
     */
    PATCH = 9
}
/**
 * Identifies the direction of the traffic relative to the local Envoy.
 *
 * @generated from protobuf enum envoy.api.v2.core.TrafficDirection
 */
export enum TrafficDirection {
    /**
     * Default option is unspecified.
     *
     * @generated from protobuf enum value: UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The transport is used for incoming traffic.
     *
     * @generated from protobuf enum value: INBOUND = 1;
     */
    INBOUND = 1,
    /**
     * The transport is used for outgoing traffic.
     *
     * @generated from protobuf enum value: OUTBOUND = 2;
     */
    OUTBOUND = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Locality$Type extends MessageType<Locality> {
    constructor() {
        super("envoy.api.v2.core.Locality", [
            { no: 1, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sub_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.Locality
 */
export const Locality = new Locality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildVersion$Type extends MessageType<BuildVersion> {
    constructor() {
        super("envoy.api.v2.core.BuildVersion", [
            { no: 1, name: "version", kind: "message", T: () => SemanticVersion },
            { no: 2, name: "metadata", kind: "message", T: () => Struct }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.BuildVersion
 */
export const BuildVersion = new BuildVersion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Extension$Type extends MessageType<Extension> {
    constructor() {
        super("envoy.api.v2.core.Extension", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type_descriptor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "version", kind: "message", T: () => BuildVersion },
            { no: 5, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.Extension
 */
export const Extension = new Extension$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Node$Type extends MessageType<Node> {
    constructor() {
        super("envoy.api.v2.core.Node", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "metadata", kind: "message", T: () => Struct },
            { no: 4, name: "locality", kind: "message", T: () => Locality },
            { no: 5, name: "build_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "user_agent_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "user_agent_version", kind: "scalar", oneof: "userAgentVersionType", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "user_agent_build_version", kind: "message", oneof: "userAgentVersionType", T: () => BuildVersion },
            { no: 9, name: "extensions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Extension },
            { no: 10, name: "client_features", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "listening_addresses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Address }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.Node
 */
export const Node = new Node$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Metadata$Type extends MessageType<Metadata> {
    constructor() {
        super("envoy.api.v2.core.Metadata", [
            { no: 1, name: "filter_metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Struct } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.Metadata
 */
export const Metadata = new Metadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuntimeUInt32$Type extends MessageType<RuntimeUInt32> {
    constructor() {
        super("envoy.api.v2.core.RuntimeUInt32", [
            { no: 2, name: "default_value", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "runtime_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.RuntimeUInt32
 */
export const RuntimeUInt32 = new RuntimeUInt32$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuntimeDouble$Type extends MessageType<RuntimeDouble> {
    constructor() {
        super("envoy.api.v2.core.RuntimeDouble", [
            { no: 1, name: "default_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "runtime_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.RuntimeDouble
 */
export const RuntimeDouble = new RuntimeDouble$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuntimeFeatureFlag$Type extends MessageType<RuntimeFeatureFlag> {
    constructor() {
        super("envoy.api.v2.core.RuntimeFeatureFlag", [
            { no: 1, name: "default_value", kind: "message", T: () => BoolValue, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "runtime_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.RuntimeFeatureFlag
 */
export const RuntimeFeatureFlag = new RuntimeFeatureFlag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeaderValue$Type extends MessageType<HeaderValue> {
    constructor() {
        super("envoy.api.v2.core.HeaderValue", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1", maxBytes: "16384", wellKnownRegex: "HTTP_HEADER_NAME", strict: false } } } },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "16384", wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.HeaderValue
 */
export const HeaderValue = new HeaderValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeaderValueOption$Type extends MessageType<HeaderValueOption> {
    constructor() {
        super("envoy.api.v2.core.HeaderValueOption", [
            { no: 1, name: "header", kind: "message", T: () => HeaderValue, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "append", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.HeaderValueOption
 */
export const HeaderValueOption = new HeaderValueOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeaderMap$Type extends MessageType<HeaderMap> {
    constructor() {
        super("envoy.api.v2.core.HeaderMap", [
            { no: 1, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.HeaderMap
 */
export const HeaderMap = new HeaderMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataSource$Type extends MessageType<DataSource> {
    constructor() {
        super("envoy.api.v2.core.DataSource", [
            { no: 1, name: "filename", kind: "scalar", oneof: "specifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "inline_bytes", kind: "scalar", oneof: "specifier", T: 12 /*ScalarType.BYTES*/, options: { "validate.rules": { bytes: { minLen: "1" } } } },
            { no: 3, name: "inline_string", kind: "scalar", oneof: "specifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.DataSource
 */
export const DataSource = new DataSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetryPolicy$Type extends MessageType<RetryPolicy> {
    constructor() {
        super("envoy.api.v2.core.RetryPolicy", [
            { no: 1, name: "retry_back_off", kind: "message", T: () => BackoffStrategy },
            { no: 2, name: "num_retries", kind: "message", T: () => UInt32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.RetryPolicy
 */
export const RetryPolicy = new RetryPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoteDataSource$Type extends MessageType<RemoteDataSource> {
    constructor() {
        super("envoy.api.v2.core.RemoteDataSource", [
            { no: 1, name: "http_uri", kind: "message", T: () => HttpUri, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "sha256", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 3, name: "retry_policy", kind: "message", T: () => RetryPolicy }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.RemoteDataSource
 */
export const RemoteDataSource = new RemoteDataSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AsyncDataSource$Type extends MessageType<AsyncDataSource> {
    constructor() {
        super("envoy.api.v2.core.AsyncDataSource", [
            { no: 1, name: "local", kind: "message", oneof: "specifier", T: () => DataSource },
            { no: 2, name: "remote", kind: "message", oneof: "specifier", T: () => RemoteDataSource }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.AsyncDataSource
 */
export const AsyncDataSource = new AsyncDataSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransportSocket$Type extends MessageType<TransportSocket> {
    constructor() {
        super("envoy.api.v2.core.TransportSocket", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "config", kind: "message", oneof: "configType", T: () => Struct },
            { no: 3, name: "typed_config", kind: "message", oneof: "configType", T: () => Any }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.TransportSocket
 */
export const TransportSocket = new TransportSocket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuntimeFractionalPercent$Type extends MessageType<RuntimeFractionalPercent> {
    constructor() {
        super("envoy.api.v2.core.RuntimeFractionalPercent", [
            { no: 1, name: "default_value", kind: "message", T: () => FractionalPercent, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "runtime_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.RuntimeFractionalPercent
 */
export const RuntimeFractionalPercent = new RuntimeFractionalPercent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ControlPlane$Type extends MessageType<ControlPlane> {
    constructor() {
        super("envoy.api.v2.core.ControlPlane", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.ControlPlane
 */
export const ControlPlane = new ControlPlane$Type();
