import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { insightCodeMapToArray } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeMap, InsightCodeWithDataProp } from 'utils/dashboard/dashboard-types';

export enum DashboardHealthKey {
  glooManagementPlane = 'glooManagementPlane',
  glooAgent = 'glooAgent',

  istioControlPlane = 'istioControlPlane',
  istioDataPlane = 'istioDataPlane',
  istioEnvironmentCheck = 'istioEnvironmentCheck',

  ciliumOperator = 'ciliumOperator',
  ciliumAgent = 'ciliumAgent',
  ciliumHubble = 'ciliumHubble',
  ciliumEnvironmentCheck = 'ciliumEnvironmentCheck'
}

interface DashboardHealthCodesMap extends InsightCodeMap, Record<DashboardHealthKey, Code> {
  // The istioEnvironmentChecks has a dataProp since it has associated data
  // (included in the sys0006 property of the response).
  [DashboardHealthKey.istioEnvironmentCheck]: InsightCodeWithDataProp<'sYS0006'>;
  [DashboardHealthKey.ciliumEnvironmentCheck]: InsightCodeWithDataProp<'sYS0019'>;
  [DashboardHealthKey.glooAgent]: InsightCodeWithDataProp<'sYS0003'>;
  // The istioDataPlane and istioControlPlane responses don't have associated data in the response,
  // so they use the Code, which we've specified in the "extends ..." here.
}

export const dashboardHealthCodesMap: DashboardHealthCodesMap = {
  [DashboardHealthKey.glooManagementPlane]: {
    // severity/location info about gloo management plane
    group: 'SYS',
    key: '0002'
  },
  [DashboardHealthKey.glooAgent]: {
    // severity/location info about gloo agent
    group: 'SYS',
    key: '0003',
    dataProp: 'sYS0003'
  },
  [DashboardHealthKey.istioControlPlane]: {
    // severity/location info about istio control plane
    group: 'SYS',
    key: '0004'
  },
  [DashboardHealthKey.istioDataPlane]: {
    // severity/location info about istio data plane
    group: 'SYS',
    key: '0005'
  },
  [DashboardHealthKey.istioEnvironmentCheck]: {
    // istio environment check
    // SYS0006: &insights_v2alpha1.Insight_SYS0006Data{
    // 	IstioVersion:  "1.18.3"
    // },
    group: 'SYS',
    key: '0006',
    // This specifies  to get the data from the sys0006 property of the response.
    dataProp: 'sYS0006'
  },

  [DashboardHealthKey.ciliumOperator]: {
    // severity/location info about Cilium Operator
    group: 'SYS',
    key: '0016'
  },
  [DashboardHealthKey.ciliumAgent]: {
    // severity/location info about Cilium Agent
    group: 'SYS',
    key: '0017'
  },
  [DashboardHealthKey.ciliumHubble]: {
    // severity/location info about Cilium Hubble
    group: 'SYS',
    key: '0018'
  },
  [DashboardHealthKey.ciliumEnvironmentCheck]: {
    // Cilium environment check
    // SYS0019: &insights_v2alpha1.Insight_SYS0019Data{
    // 	CiliumVersion:  "1.14.2",
    // },
    group: 'SYS',
    key: '0019',
    // This specifies  to get the data from the sys0006 property of the response.
    //   SYS0019: &insights_v2alpha1.Insight_SYS0006Data{
    //     CiliumVersion:  "1.14.2",
    //   },
    dataProp: 'sYS0019'
  }
};

export const istioAndCiliumHealthCodesArray = insightCodeMapToArray(dashboardHealthCodesMap);
