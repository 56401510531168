import { Tooltip } from 'antd';
import { getDayApiRefreshTime } from 'Api/helpers';
import { Asset } from 'assets';
import { HealthIndicatorRingWithPermanentIcon, HealthIndicatorWithName } from 'Components/Common/HealthIndicator';
import { InfoTooltip } from 'Components/Common/InfoTooltip';
import { DataType } from 'Components/Common/SoloListCard';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { State, Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GlooVersionSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useMemo } from 'react';
import useSWR from 'swr';
import { createCombinedInsightStatus, getInsightData } from 'utils/dashboard/dashboard-helpers';
import { compareVersion, createCombinedStatus } from 'utils/helpers';
import { externalLinks } from 'utils/url-external-links-map';
import { dashboardHealthCodesMap, DashboardHealthKey } from './insight-codes';

export const useCombinedInsightStatusOrLoading = (insights: Insight[] | undefined) => {
  return useMemo(() => (insights === undefined ? 'loading' : createCombinedInsightStatus(insights)), [insights]);
};

const xmlFetcher = (url: string) =>
  fetch(url)
    .then(response => response.text())
    .then(str => new window.DOMParser().parseFromString(str, 'text/xml'));

const useFetchIstioNewsXml = () => {
  return useSWR(externalLinks.istio_io.news_xml, xmlFetcher, {
    refreshInterval: getDayApiRefreshTime(),
    errorRetryCount: 2 // not a super important feature, just retry a couple and move on if it fails
  });
};

interface IstioNewsItem {
  title?: string;
  category?: string;
  description?: string;
  link?: string;
}

function useGetIstioNewsList() {
  const { data, error } = useFetchIstioNewsXml();

  const parsedData = useMemo(() => {
    if (!data) return undefined;
    const newsItems = Array.from(data.querySelectorAll('channel > item'));
    return newsItems.map<IstioNewsItem>(el => ({
      title: el.getElementsByTagName('title')[0]?.textContent ?? undefined,
      description: el.getElementsByTagName('description')[0]?.textContent ?? undefined,
      category: el.getElementsByTagName('category')[0]?.textContent ?? undefined,
      link: el.getElementsByTagName('link')[0]?.textContent ?? undefined
    }));
  }, [data]);

  return { list: parsedData, error };
}

export function useGetIstioCveList() {
  const { list, error } = useGetIstioNewsList();

  const parsedAndFilteredData = useMemo(() => {
    if (!list) return undefined;
    return list.filter(
      (item): item is { title: string; description: string; link?: string } =>
        item.category === 'CVE' && !!item.title && !!item.description
    );
  }, [list]);

  return { data: parsedAndFilteredData, error };
}

export function useGetIstioVersionAnnouncementsList() {
  const { list, error } = useGetIstioNewsList();

  const parsedAndFilteredData = useMemo(() => {
    if (!list) return undefined;
    return list.filter(
      (item): item is { title: string; description?: string; link?: string } =>
        item.category !== 'CVE' && !!item.title && item.title.includes('Announcing')
    );
  }, [list]);

  return { data: parsedAndFilteredData, error };
}

export const useIstioVersionInsightsToVersionDict = (insights: Insight[] | undefined) => {
  return useMemo(() => {
    return (
      insights?.reduce<Record<string, Insight[]>>((dict, ins) => {
        const dataProp = dashboardHealthCodesMap[DashboardHealthKey.istioEnvironmentCheck].dataProp;
        const data = getInsightData(ins, dataProp);
        if (data?.istioVersion) {
          dict[data.istioVersion] ??= [];
          dict[data.istioVersion].push(ins);
        }
        return dict;
      }, {}) ?? {}
    );
  }, [insights]);
};

export const useCiliumVersionInsightsToVersionDict = (insights: Insight[] | undefined) => {
  return useMemo(() => {
    return (
      insights?.reduce<Record<string, Insight[]>>((dict, ins) => {
        const dataProp = dashboardHealthCodesMap[DashboardHealthKey.ciliumEnvironmentCheck].dataProp;
        const data = getInsightData(ins, dataProp);
        if (data?.ciliumVersion) {
          dict[data.ciliumVersion] ??= [];
          dict[data.ciliumVersion].push(ins);
        }
        return dict;
      }, {}) ?? {}
    );
  }, [insights]);
};

export const useGlooVersionListCardEntries = (versionsIn: GlooVersionSummary[] | undefined) => {
  return useMemo(() => {
    const cleanVersion = (v?: string) => v?.replace('-fips', '');
    const versions = [...(versionsIn ?? [])];
    // Add UI version to the list if it doesn't already exist in api server list
    if (!versions.find(({ version }) => cleanVersion(version) === cleanVersion(process.env.UI_VERSION))) {
      versions.unshift({
        version: process.env.UI_VERSION ?? '',
        status: { state: State.ACCEPTED, validationErrors: [] },
        deploymentRef: {
          name: 'gloo-mesh-ui',
          namespace: '',
          clusterName: ''
        }
      });
    }
    const versionMap = versions.reduce<Record<string, GlooVersionSummary[]>>((dict, vsum) => {
      if (vsum.version) {
        dict[vsum.version] ??= [];
        dict[vsum.version].push(vsum);
      }
      return dict;
    }, {});
    return Object.values(versionMap).map(summaries => {
      const version = summaries[0].version;
      const status = createCombinedStatus(summaries.map(s => s.status));

      const isSameVersionAsDashboard = cleanVersion(version) === cleanVersion(process.env.UI_VERSION);
      return {
        key: version,
        data: (
          <HealthIndicatorWithName
            status={status}
            name={version}
            tooltipTitleOverride={summaries.map(({ deploymentRef }) => (
              <div key={deploymentRef?.name}>
                <b>{deploymentRef?.name ?? 'unknown'}</b>:
                <br />
                {version}
              </div>
            ))}
          />
        ),
        right: isSameVersionAsDashboard ? (
          <InfoTooltip
            tooltip={summaries.map(({ deploymentRef }) => (
              <div key={deploymentRef?.name}>
                {!!deploymentRef?.name
                  ? `This is the ${deploymentRef.name} deployment.`
                  : 'This is an unknown deployment.'}
              </div>
            ))}
          />
        ) : (
          <Tooltip
            placement='left'
            trigger='hover'
            title={summaries.map(({ deploymentRef }) => (
              <div key={deploymentRef?.name}>
                There is a mismatch between this version of the {deploymentRef?.name ?? 'unknown'} and the current
                dashboard version of {process.env.UI_VERSION}
              </div>
            ))}>
            <span>
              <HealthIndicatorRingWithPermanentIcon state={State.WARNING} icon={<Asset.WarningCircleIcon />} />
            </span>
          </Tooltip>
        )
      };
    });
  }, [versionsIn]);
};

export const useVersionListCardEntries = (versionsDict: Record<string, Insight[] | Status>) =>
  useMemo<DataType[]>(() => {
    return Object.entries(versionsDict)
      .sort(([a], [b]) => -compareVersion(a, b))
      .map(([version, insights]) => {
        return {
          key: version,
          data: (
            <HealthIndicatorWithName
              status={Array.isArray(insights) ? createCombinedInsightStatus(insights) : insights}
              name={version}
            />
          )
        };
      });
  }, [versionsDict]);
