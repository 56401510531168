import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { SoloDropdownStyles } from 'Components/Common/Input/SoloDropdown.style';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloPaginationStyles } from 'Components/Common/SoloPagination.style';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace OverviewAreasStyles {
  export const Container = styled(CardStyles.Card)`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
  `;

  export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;

    > div {
      display: flex;
      align-items: center;
    }
  `;
  export const TitleIconHolder = styled.div(({ theme }) => {
    return css`
      margin-right: 10px;

      ${svgBasics(24, theme.seaBlue)}
    `;
  });

  export const FilterLine = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 18px 0;
  `;

  export const RefreshIndicatorHolder = styled.div`
    position: absolute;
    right: 25px;
    top: -4px;
  `;

  export const ContentBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  export const ContentBodyFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    > ${SoloPaginationStyles.PaginationRow} {
      margin-top: 3px;
      padding-bottom: 0;
    }
  `;
  export const FooterCount = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      color: ${theme.augustGrey};
      height: 100%;
      font-size: 14px;
    `
  );
  export const AddItemPrompt = styled(UnstyledButton)(
    ({ theme }) => css`
      ${SoloLinkStyles.SoloLinkLooks} {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        height: 100%;
      }

      ${svgBasics(19)}
      svg {
        margin-left: 10px;
      }
    `
  );

  export const SortContainer = styled.div`
    display: flex;

    ${SoloDropdownStyles.SoloDropdownBlock} {
      &.ant-select .ant-select-selector {
        border-radius: 0 3px 3px 0;
      }
    }
  `;

  interface OrderDirectionToggleProps {
    reversed: boolean;
  }
  export const OrderDirectionToggle = styled.div<OrderDirectionToggleProps>(
    ({ theme, reversed }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      border: 1px solid ${theme.aprilGrey};
      border-right: 0;
      border-radius: 3px 0 0 3px;

      ${svgBasics(16)}
      svg {
        .sort-arrows--up {
          fill: ${reversed ? theme.splashBlue : theme.oceanBlue};
        }
        .sort-arrows--down {
          fill: ${reversed ? theme.oceanBlue : theme.splashBlue};
        }
      }
    `
  );
}
