import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { VariableTransformation } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useMemo } from 'react';
import { getVariableTransformationType, stringifyVariableTransformationValue } from 'utils/graphql-resolver-helpers';
import { GqlResolverModalStyles } from './GqlResolverModal.style';

type TableFields = {
  key: string;
  name: string;
  type: string;
  value: string;
};
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Key',
    dataIndex: 'name',
    render: value => <GqlResolverModalStyles.MinWidthCell>{value}</GqlResolverModalStyles.MinWidthCell>
  },
  {
    title: 'Type',
    dataIndex: 'type'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    render: value => <GqlResolverModalStyles.MinWidthCell>{value}</GqlResolverModalStyles.MinWidthCell>
  }
];

const VariableTransformationMapTable = ({
  variableTransformations
}: {
  variableTransformations: Record<string, VariableTransformation> | undefined;
}) => {
  const tableData = useMemo<TableFields[]>(() => {
    if (!variableTransformations) return [];
    return Object.entries(variableTransformations).map(([key, varTransformation]) => {
      const vtType = getVariableTransformationType(varTransformation);
      const variableTypesToNotSerialize = ['jq', 'variable'];
      const vtValue = stringifyVariableTransformationValue(
        varTransformation,
        !variableTypesToNotSerialize.includes(vtType)
      );
      return {
        key,
        name: key,
        type: vtType,
        value: vtValue
      };
    });
  }, [variableTransformations]);

  //
  // Render
  //
  if (!variableTransformations) return null;
  return (
    <GqlResolverModalStyles.TableWrapper>
      <SoloTable removeHorizontalPadding columns={columns} dataSource={tableData} />
    </GqlResolverModalStyles.TableWrapper>
  );
};

export default VariableTransformationMapTable;
