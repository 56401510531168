import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace LandingBodyStyles {
  //
  // Empty page styles
  //
  export const Header = styled.h1`
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  `;

  export const EmptyContainer = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 5%;
    margin-bottom: 10px;
  `;

  export const EmptyIconContainer = styled.div<{ width?: number }>(
    ({ theme, width }) => css`
      border-radius: 50%;
      margin-top: 10%;
      padding: 30px;
      padding-top: 35px;
      padding-bottom: 35px;
      border: 2px solid ${theme.splashBlue};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      ${svgBasics({ width: width ?? 70, height: 'auto' }, theme.splashBlue)}
    `
  );
}
