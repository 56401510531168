import styled from '@emotion/styled';
import { Tooltip, TooltipProps } from 'antd';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { isContentOverflowing } from 'utils/helpers';
import { useEventListener } from 'utils/hooks';

const TextEllipsesCellContent = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  pointer-events: all;
  z-index: 1;
`;

/**
 * Handling ellipses with antd table cells is tricky.
 * Right click and translate this GitHub issue to English in chrome for more context:
 * https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
 * 
 * However, the following method works:
 * - Set a column width and the ellipses property in the column definition,
 * - Then use the <SoloTableTextEllipsesCell> component, like in this example from InsightsLandingBody.
 * 
 const columns = [
  ...
  {
    title: 'Summary',
    dataIndex: 'summary',
    width: '50%',
    ellipsis: true,
    render: value => <SoloTableTextEllipsesCell value={value} />
  },
 ];
 */
const SoloTableTextEllipsesCell = (props: {
  value: string;
  tooltipTitleOverride?: TooltipProps['title'];
  'data-testid'?: string;
}) => {
  // Check if we should show the tooltip when the window resizes.
  // (since we don't support cell resizing this should be all that's needed for now)
  const containerRef = useRef<ElementRef<'div'>>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  useEventListener(
    window,
    'resize',
    () => {
      setShowTooltip(isContentOverflowing(containerRef.current));
    },
    [containerRef.current]
  );

  // Sets the tooltip to the correct value when the containerRef.current first loads.
  useEffect(() => {
    setShowTooltip(isContentOverflowing(containerRef.current));
  }, [containerRef.current]);

  return (
    <Tooltip
      title={props?.tooltipTitleOverride ?? props.value}
      open={
        // `undefined` is for normal tooltip behavior, `false` is for forcefully hiding it.
        showTooltip ? undefined : false
      }>
      <TextEllipsesCellContent ref={containerRef} data-testid={props['data-testid']}>
        {props.value}
      </TextEllipsesCellContent>
    </Tooltip>
  );
};

export default SoloTableTextEllipsesCell;
