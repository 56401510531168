import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloLogsContainer from './SoloLogsContainer';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';

const LogsLanding = () => {
  usePermissionGateRedirect(Permission.LogsEnabled);

  return (
    <OverviewStyles.Container>
      <ErrorBoundary fallback='There was an error while fetching and displaying logs...'>
        <SoloLogsContainer />
      </ErrorBoundary>
    </OverviewStyles.Container>
  );
};

export default LogsLanding;
