/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/http_matchers.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { StringMatch } from "./string_match_pb";
/**
 * Specify HTTP request level match criteria. All specified conditions must be satisfied for a match to occur.
 *
 * @generated from protobuf message common.gloo.solo.io.HTTPRequestMatcher
 */
export interface HTTPRequestMatcher {
    /**
     * UNSUPPORTED: Name matching is currently unsupported.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Optional: Specify match criteria against the targeted path.
     *
     * @generated from protobuf field: common.gloo.solo.io.StringMatch uri = 2;
     */
    uri?: StringMatch;
    /**
     * Optional: Specify a set of headers which requests must match in entirety (all headers must match).
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.HeaderMatcher headers = 5;
     */
    headers: HeaderMatcher[];
    /**
     * Optional: Specify a set of URL query parameters which requests must match in entirety (all query params must match).
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.HTTPRequestMatcher.QueryParameterMatcher query_parameters = 6;
     */
    queryParameters: HTTPRequestMatcher_QueryParameterMatcher[];
    /**
     * Optional: Specify an HTTP method to match against.
     *
     * @generated from protobuf field: string method = 7;
     */
    method: string;
    /**
     * Optional: Specifies ports on the host to match against.
     *
     * +kubebuilder:validation:Minimum=0
     * +kubebuilder:validation:Maximum=65535
     *
     * @generated from protobuf field: uint32 port = 8;
     */
    port: number;
}
/**
 * Specify match criteria against the target URL's query parameters.
 *
 * @generated from protobuf message common.gloo.solo.io.HTTPRequestMatcher.QueryParameterMatcher
 */
export interface HTTPRequestMatcher_QueryParameterMatcher {
    /**
     * Specify the name of a key that must be present in the requested path's query string.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Specify the value of the query parameter keyed on `name`.
     *
     * @generated from protobuf field: string value = 2;
     */
    value: string;
    /**
     * If true, treat `value` as a regular expression.
     *
     * @generated from protobuf field: bool regex = 3;
     */
    regex: boolean;
}
/**
 * Describes a matcher against HTTP request headers.
 *
 * @generated from protobuf message common.gloo.solo.io.HeaderMatcher
 */
export interface HeaderMatcher {
    /**
     * Specify the name of the header in the request.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Specify the value of the header. If the value is absent a request that
     * has the name header will match, regardless of the header’s value.
     *
     * @generated from protobuf field: string value = 2;
     */
    value: string;
    /**
     * Specify whether the header value should be treated as regex.
     *
     * @generated from protobuf field: bool regex = 3;
     */
    regex: boolean;
    /**
     *
     * If set to true, the result of the match will be inverted. Defaults to false.
     *
     * Examples:
     *
     * - name=foo, invert_match=true: matches if no header named `foo` is present
     * - name=foo, value=bar, invert_match=true: matches if no header named `foo` with value `bar` is present
     * - name=foo, value=``\d{3}``, regex=true, invert_match=true: matches if no header named `foo` with a value consisting of three integers is present.
     *
     * @generated from protobuf field: bool invert_match = 4;
     */
    invertMatch: boolean;
}
/**
 * Describes a matcher against HTTP response status codes.
 *
 * @generated from protobuf message common.gloo.solo.io.StatusCodeMatcher
 */
export interface StatusCodeMatcher {
    /**
     * The status code value to match against.
     *
     * @generated from protobuf field: uint32 value = 1;
     */
    value: number;
    /**
     * The comparison type used for matching.
     *
     * @generated from protobuf field: common.gloo.solo.io.StatusCodeMatcher.Comparator comparator = 2;
     */
    comparator: StatusCodeMatcher_Comparator;
}
/**
 * @generated from protobuf enum common.gloo.solo.io.StatusCodeMatcher.Comparator
 */
export enum StatusCodeMatcher_Comparator {
    /**
     * Strict equality.
     *
     * @generated from protobuf enum value: EQ = 0;
     */
    EQ = 0,
    /**
     * Greater than or equal to.
     *
     * @generated from protobuf enum value: GE = 1;
     */
    GE = 1,
    /**
     * Less than or equal to.
     *
     * @generated from protobuf enum value: LE = 2;
     */
    LE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class HTTPRequestMatcher$Type extends MessageType<HTTPRequestMatcher> {
    constructor() {
        super("common.gloo.solo.io.HTTPRequestMatcher", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uri", kind: "message", T: () => StringMatch },
            { no: 5, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher },
            { no: 6, name: "query_parameters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HTTPRequestMatcher_QueryParameterMatcher },
            { no: 7, name: "method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.HTTPRequestMatcher
 */
export const HTTPRequestMatcher = new HTTPRequestMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTPRequestMatcher_QueryParameterMatcher$Type extends MessageType<HTTPRequestMatcher_QueryParameterMatcher> {
    constructor() {
        super("common.gloo.solo.io.HTTPRequestMatcher.QueryParameterMatcher", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "regex", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.HTTPRequestMatcher.QueryParameterMatcher
 */
export const HTTPRequestMatcher_QueryParameterMatcher = new HTTPRequestMatcher_QueryParameterMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeaderMatcher$Type extends MessageType<HeaderMatcher> {
    constructor() {
        super("common.gloo.solo.io.HeaderMatcher", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "regex", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "invert_match", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.HeaderMatcher
 */
export const HeaderMatcher = new HeaderMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusCodeMatcher$Type extends MessageType<StatusCodeMatcher> {
    constructor() {
        super("common.gloo.solo.io.StatusCodeMatcher", [
            { no: 1, name: "value", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "comparator", kind: "enum", T: () => ["common.gloo.solo.io.StatusCodeMatcher.Comparator", StatusCodeMatcher_Comparator] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.StatusCodeMatcher
 */
export const StatusCodeMatcher = new StatusCodeMatcher$Type();
