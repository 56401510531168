import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';

export namespace GraphLandingStyles {
  export const LandingContainer = styled(CardStyles.Card)`
    position: relative;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    ${CardStyles.TitleContainer} {
      padding: 18px;
    }
  `;

  export const GraphContainer = styled.div`
    position: relative;
    overflow: hidden;

    .secondaryInformationSection {
      height: auto;
      outline: none;
      padding: 0;
    }
    .ant-select-selector {
      height: auto !important;
      border: none !important;
      box-shadow: none !important;
    }
  `;

  export const TitleWord = styled.div`
    display: flex;
    align-items: center;
    margin-right: 16px;
  `;
}
