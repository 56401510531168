import { Tooltip, TooltipProps } from 'antd';
import { ElementRef, useRef } from 'react';

const SoloTooltip = (
  props: TooltipProps & {
    /** Rendering the dropdown popup inside the container (with `includePopupInContainerElement={true}`)
     * is necessary for them to work if the container element is in fullscreen mode. */
    includePopupInContainerElement?: boolean;
  }
) => {
  const containerRef = useRef<ElementRef<'div'>>(null);
  return (
    // Rendering the popup inside the container is necessary for it to work
    // if the container element is in fullscreen mode.
    <Tooltip
      getPopupContainer={
        !!props.includePopupInContainerElement ? () => containerRef.current ?? document.body : undefined
      }
      {...props}>
      <div ref={containerRef} />
      {props.children}
    </Tooltip>
  );
};

export default SoloTooltip;
