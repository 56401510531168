import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { StatMiniCardStyles } from 'Components/Common/StatMiniCard.style';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetWafPolicyDetailsResponse_AuditLogAction,
  GetWafPolicyDetailsResponse_AuditLogLocation
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetWafPolicyDetails } = policiesApi;

const policyType = PolicyType.WAF;

const auditLogActionMap: Record<GetWafPolicyDetailsResponse_AuditLogAction, string> = {
  [GetWafPolicyDetailsResponse_AuditLogAction.NEVER]: 'Never',
  [GetWafPolicyDetailsResponse_AuditLogAction.RELEVANT_ONLY]: 'Relevant Only',
  [GetWafPolicyDetailsResponse_AuditLogAction.ALWAYS]: 'Always'
};

const auditLogLocationMap: Record<GetWafPolicyDetailsResponse_AuditLogLocation, string> = {
  [GetWafPolicyDetailsResponse_AuditLogLocation.FILTER_STATE]: 'Filter State',
  [GetWafPolicyDetailsResponse_AuditLogLocation.DYNAMIC_METADATA]: 'Dynamic Metadata'
};

// This makes the first list items resized to minimum widths to fit content, while letting last item take up all remaining space.
const CustomListSizer = styled.div`
  ${StatMiniCardStyles.Item} {
    flex: none;
    :last-of-type {
      flex: 1;
    }
  }
`;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const WafDetails = ({ clusterObjRef }: Props) => {
  di(useGetWafPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetWafPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Overview
          <CardStyles.CardHeaderSecondaryInfoHolder>
            <SecondaryInfo
              items={[
                {
                  label: 'Request',
                  data: policyData.requestHeadersOnly ? 'Headers Only' : 'Body and Headers'
                },
                {
                  label: 'Response',
                  data: policyData.responseHeadersOnly ? 'Headers Only' : 'Body and Headers'
                }
              ]}
            />
          </CardStyles.CardHeaderSecondaryInfoHolder>
        </CardStyles.CardHeader>

        <CustomListSizer>
          <StatMiniCardList
            items={[
              {
                icon: <Asset.CoreSetIcon />,
                title: 'Core Rule Sets',
                data: policyData.enableCoreRuleSet ? 'Included' : 'Not Included'
              },
              {
                icon: <Asset.AuditLoggingActionIcon />,
                title: 'Audit Logging Action',
                data: auditLogActionMap[policyData.action]
              },
              {
                icon: <Asset.AuditLoggingLocationIcon />,
                title: 'Audit Logging Location',
                data: auditLogLocationMap[policyData.location]
              },
              {
                icon: <Asset.MessageIconGray />,
                title: 'Custom Intervention Message',
                data: policyData.message
              }
            ]}
          />
        </CustomListSizer>
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
