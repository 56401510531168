import { BaseLandingBodyProps } from 'Components/Common/Overview/LandingBody';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { SoloTableStyles } from 'Components/Common/SoloTable.style';
import { LabelsCell, createEllipseTextColumn, createStatusColumn } from 'Components/Common/SoloTableCells';
import { YamlModal, YamlResource } from 'Components/Common/YamlModal';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo, useState } from 'react';
import { getResourceDetailsUrlBasedOnSummaryType } from 'utils/features/resources/resource-helpers';
import { buildIdFromRef } from 'utils/helpers';
import { resourceTypesMap } from 'utils/types';

function createYamlResource(resourceType: ResourceType, ref: ClusterObjectRef | undefined) {
  return {
    resourceType,
    clusterObjectRef: ref!,
    icon: resourceTypesMap[resourceType].icon
  };
}

interface TableFields {
  key: string;
  status: Status | undefined;
  name: string;
  namespace: string;
  clusterName: string;
  type: ResourceType;
  labels: Record<string, string>;
  actions: { yamlOnClick?: () => void; link?: string };
}

const columns: SoloColumnsType<TableFields> = [
  createStatusColumn({ dataIndex: 'status' }),
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name', 'data-testid': 'name-cell' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render: type => {
      const usefulResourceTypeInfo = resourceTypesMap[type];
      return (
        <SoloTableStyles.CellIconText data-testid='resources-landing-resource-table-type'>
          {usefulResourceTypeInfo.name}
        </SoloTableStyles.CellIconText>
      );
    }
  },
  {
    title: 'Labels',
    dataIndex: 'labels',
    render: labels => <LabelsCell labelsMap={Object.entries(labels)} />
  },
  {
    title: 'Details',
    dataIndex: 'actions',
    render({ yamlOnClick, link }) {
      return !!yamlOnClick ? (
        <SoloLinkStyles.SoloLinkLooksButton onClick={yamlOnClick} withArrow>
          View YAML
        </SoloLinkStyles.SoloLinkLooksButton>
      ) : !!link ? (
        <SoloLink link={link} withArrow>
          View Details
        </SoloLink>
      ) : undefined;
    }
  }
];

export const ResourcesLandingBodyTable = ({ items: resources, pagingData }: BaseLandingBodyProps<ResourceSummary>) => {
  const [yamlResource, setYamlResource] = useState<YamlResource>();

  const tableData = useMemo<TableFields[]>(() => {
    return resources.map(r => {
      const url = getResourceDetailsUrlBasedOnSummaryType(r);
      return {
        ...r,
        key: buildIdFromRef(r.ref) + r.type,
        show: Boolean(r.ref),
        status: r.status,
        name: r.ref?.name ?? '',
        namespace: r.ref?.namespace ?? '',
        clusterName: r.ref?.clusterName ?? '',
        labels: r.labels,
        actions: !url
          ? { yamlOnClick: !!r.ref ? () => setYamlResource(createYamlResource(r.type, r.ref)) : undefined }
          : { link: url }
      };
    });
  }, [resources]);

  return (
    <Spacer mt={5}>
      <SoloTable
        data-testid='resources-landing-resource-table'
        dataSource={tableData}
        columns={columns}
        paging={pagingData}
      />
      {!!yamlResource && <YamlModal yamlResource={yamlResource} onClose={() => setYamlResource(undefined)} />}
    </Spacer>
  );
};
