import { Asset } from 'assets';
import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { recordToSecondaryInfoItemList, SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { Svg } from 'Components/Common/Svg';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import React from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { RoutesListDisplayStyles as Styles } from '../RoutesListDisplay.style';

interface BaseRouteItemProps {
  routeType: 'HTTP' | 'TCP' | 'TLS';
  names: string[] | undefined;
  status: Status | undefined;
  labels: Record<string, string>;
  'data-testid': string;
  ifContent: React.ReactNode;
  thenContentColumns: React.ReactNode[];
}
export const BaseRouteItem = (props: BaseRouteItemProps) => {
  const { routeType, names, status, labels, ifContent, thenContentColumns, 'data-testid': testId } = props;
  return (
    <Styles.RouteContainer data-testid={testId}>
      <Styles.LeftSide>
        {(names ?? ['']).map((name, i) => (
          <Styles.RouteName key={(name ?? '') + i}>
            {i === 0 ? (
              <HealthIndicator status={status} />
            ) : (
              <Svg asset={<Asset.BreadcrumbSeperator />} size={15} />
            )}
            <span>{name}</span>
          </Styles.RouteName>
        ))}
        <Spacer mt='8px'>
          <SecondaryInfoItem highlighted data={`${routeType} Route`} />
        </Spacer>
      </Styles.LeftSide>
      <Styles.ClauseContainer>
        <div>
          <Styles.ClauseIcon>
            <Asset.RouteIfIcon />
          </Styles.ClauseIcon>
          IF
        </div>
        <div style={{ flex: 1 }}>
          <Styles.ClauseLine />
        </div>
      </Styles.ClauseContainer>

      <Styles.RouteContentTop>
        <div>{ifContent}</div>
      </Styles.RouteContentTop>

      <Styles.ClauseContainer>
        <div>
          <Styles.ClauseIcon>
            <Asset.RouteThenIcon />
          </Styles.ClauseIcon>
          THEN
        </div>
      </Styles.ClauseContainer>

      <Styles.RouteContentBottom>
        {thenContentColumns.map((c, i) => (
          <div key={i}>{c}</div>
        ))}
      </Styles.RouteContentBottom>

      <Styles.LabelContainer>
        {!!Object.keys(labels).length && (
          <>
            <Styles.LabelHeader>Labels</Styles.LabelHeader>
            <SecondaryInfo items={recordToSecondaryInfoItemList(labels)} small overflowAfter={3} />
          </>
        )}
      </Styles.LabelContainer>
    </Styles.RouteContainer>
  );
};
