/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/config/core/v3/extension.proto" (package "envoy.config.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { ConfigSource } from "./config_source_pb";
import { Any } from "../../../../google/protobuf/any_pb";
// [#protodoc-title: Extension configuration]

/**
 * Message type for extension configuration.
 * [#next-major-version: revisit all existing typed_config that doesn't use this wrapper.].
 *
 * @generated from protobuf message envoy.config.core.v3.TypedExtensionConfig
 */
export interface TypedExtensionConfig {
    /**
     * The name of an extension. This is not used to select the extension, instead
     * it serves the role of an opaque identifier.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The typed config for the extension. The type URL will be used to identify
     * the extension. In the case that the type URL is *udpa.type.v1.TypedStruct*,
     * the inner type URL of *TypedStruct* will be utilized. See the
     * :ref:`extension configuration overview
     * <config_overview_extension_configuration>` for further details.
     *
     * @generated from protobuf field: google.protobuf.Any typed_config = 2;
     */
    typedConfig?: Any;
}
/**
 * Configuration source specifier for a late-bound extension configuration. The
 * parent resource is warmed until all the initial extension configurations are
 * received, unless the flag to apply the default configuration is set.
 * Subsequent extension updates are atomic on a per-worker basis. Once an
 * extension configuration is applied to a request or a connection, it remains
 * constant for the duration of processing. If the initial delivery of the
 * extension configuration fails, due to a timeout for example, the optional
 * default configuration is applied. Without a default configuration, the
 * extension is disabled, until an extension configuration is received. The
 * behavior of a disabled extension depends on the context. For example, a
 * filter chain with a disabled extension filter rejects all incoming streams.
 *
 * @generated from protobuf message envoy.config.core.v3.ExtensionConfigSource
 */
export interface ExtensionConfigSource {
    /**
     * @generated from protobuf field: envoy.config.core.v3.ConfigSource config_source = 1;
     */
    configSource?: ConfigSource;
    /**
     * Optional default configuration to use as the initial configuration if
     * there is a failure to receive the initial extension configuration or if
     * `apply_default_config_without_warming` flag is set.
     *
     * @generated from protobuf field: google.protobuf.Any default_config = 2;
     */
    defaultConfig?: Any;
    /**
     * Use the default config as the initial configuration without warming and
     * waiting for the first discovery response. Requires the default configuration
     * to be supplied.
     *
     * @generated from protobuf field: bool apply_default_config_without_warming = 3;
     */
    applyDefaultConfigWithoutWarming: boolean;
    /**
     * A set of permitted extension type URLs. Extension configuration updates are rejected
     * if they do not match any type URL in the set.
     *
     * @generated from protobuf field: repeated string type_urls = 4;
     */
    typeUrls: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class TypedExtensionConfig$Type extends MessageType<TypedExtensionConfig> {
    constructor() {
        super("envoy.config.core.v3.TypedExtensionConfig", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "typed_config", kind: "message", T: () => Any, options: { "validate.rules": { any: { required: true } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.TypedExtensionConfig
 */
export const TypedExtensionConfig = new TypedExtensionConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtensionConfigSource$Type extends MessageType<ExtensionConfigSource> {
    constructor() {
        super("envoy.config.core.v3.ExtensionConfigSource", [
            { no: 1, name: "config_source", kind: "message", T: () => ConfigSource, options: { "validate.rules": { any: { required: true } } } },
            { no: 2, name: "default_config", kind: "message", T: () => Any },
            { no: 3, name: "apply_default_config_without_warming", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "type_urls", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.ExtensionConfigSource
 */
export const ExtensionConfigSource = new ExtensionConfigSource$Type();
