/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Status } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { Duration } from "../../../../../../../../google/protobuf/duration_pb";
import { Timestamp } from "../../../../../../../../google/protobuf/timestamp_pb";
/**
 * Get workload metrics graph.
 *
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphRequest
 */
export interface GetGraphRequest {
    /**
     * Namespaces to filter by. If omitted, will retrieve metrics for workloads in all namespaces.
     *
     * @generated from protobuf field: repeated string namespaces = 1;
     */
    namespaces: string[];
    /**
     * Clusters to filter by. If omitted, will retrieve metrics for workloads in all clusters.
     *
     * @generated from protobuf field: repeated string clusters = 2;
     */
    clusters: string[];
    /**
     * Workspaces to filter by. If omitted, will retrieve metrics for workloads in all workspaces.
     *
     * @generated from protobuf field: repeated string workspaces = 3;
     */
    workspaces: string[];
    /**
     * Workload name to filter by.
     *
     * @generated from protobuf field: string workload_name = 10;
     */
    workloadName: string;
    /**
     * The time that the metrics go up to. If omitted, will default to the current time.
     *
     * @generated from protobuf field: google.protobuf.Timestamp end_time = 4;
     */
    endTime?: Timestamp;
    /**
     * Duration of metrics to be returned. If omitted, will default to 5 minutes.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 5;
     */
    window?: Duration;
    /**
     * Duration of each metrics sample. If omitted, will default to the `window` value, which will return a single sample.
     *
     * @generated from protobuf field: google.protobuf.Duration step = 6;
     */
    step?: Duration;
    /**
     * Show Istio metrics, false by default
     *
     * @generated from protobuf field: bool istio_metrics = 7;
     */
    istioMetrics: boolean;
    /**
     * Show Cilium metrics, false by default
     *
     * @generated from protobuf field: bool cilium_metrics = 8;
     */
    ciliumMetrics: boolean;
    /**
     * Show TCP metrics, false by default
     *
     * @generated from protobuf field: bool tcp_metrics = 9;
     */
    tcpMetrics: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphResponse
 */
export interface GetGraphResponse {
    /**
     * Node metrics.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.NodeMetrics node_metrics = 1;
     */
    nodeMetrics: NodeMetrics[];
    /**
     * Edge metrics.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics edge_metrics = 2;
     */
    edgeMetrics: EdgeMetrics[];
}
/**
 * Get all workspaces, namespaces, and clusters that can be used for filtering.
 *
 * @generated from protobuf message rpc.gloo.solo.io.GetFiltersRequest
 */
export interface GetFiltersRequest {
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetFiltersResponse
 */
export interface GetFiltersResponse {
    /**
     * @generated from protobuf field: repeated string namespaces = 1;
     */
    namespaces: string[];
    /**
     * @generated from protobuf field: repeated string clusters = 2;
     */
    clusters: string[];
    /**
     * @generated from protobuf field: repeated string workspaces = 3;
     */
    workspaces: string[];
    /**
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.GetFiltersResponse.WorkspaceClusters> workspace_map = 4;
     */
    workspaceMap: {
        [key: string]: GetFiltersResponse_WorkspaceClusters;
    };
    /**
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.GetFiltersResponse.ClusterNamespaces> cluster_namespace_map = 5;
     */
    clusterNamespaceMap: {
        [key: string]: GetFiltersResponse_ClusterNamespaces;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetFiltersResponse.WorkspaceClusters
 */
export interface GetFiltersResponse_WorkspaceClusters {
    /**
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.GetFiltersResponse.ClusterNamespaces> workspace_clusters = 1;
     */
    workspaceClusters: {
        [key: string]: GetFiltersResponse_ClusterNamespaces;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetFiltersResponse.ClusterNamespaces
 */
export interface GetFiltersResponse_ClusterNamespaces {
    /**
     * @generated from protobuf field: repeated string cluster_namespaces = 1;
     */
    clusterNamespaces: string[];
}
/**
 * Get incoming and outgoing metrics for the specified workload.
 *
 * @generated from protobuf message rpc.gloo.solo.io.GetWorkloadMetricsRequest
 */
export interface GetWorkloadMetricsRequest {
    /**
     * Specific Graph node IDs to get metrics for, currently comprised of the workload_id + network_id
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The time that the metrics go up to. If omitted, will default to the current time.
     *
     * @generated from protobuf field: google.protobuf.Timestamp end_time = 2;
     */
    endTime?: Timestamp;
    /**
     * Duration of metrics to be returned. If omitted, will default to 5 minutes.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 3;
     */
    window?: Duration;
    /**
     * Duration of each metrics sample. If omitted, will default to the `window` value, which will return a single sample.
     *
     * @generated from protobuf field: google.protobuf.Duration step = 4;
     */
    step?: Duration;
    /**
     * Show Istio metrics, false by default
     *
     * @generated from protobuf field: bool istio_metrics = 5;
     */
    istioMetrics: boolean;
    /**
     * Show Cilium metrics, false by default
     *
     * @generated from protobuf field: bool cilium_metrics = 6;
     */
    ciliumMetrics: boolean;
    /**
     * Show TCP metrics, false by default
     *
     * @generated from protobuf field: bool tcp_metrics = 7;
     */
    tcpMetrics: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWorkloadMetricsResponse
 */
export interface GetWorkloadMetricsResponse {
    /**
     * The metrics for edges from all workloads to the specified workload.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics incoming_edge_metrics = 1;
     */
    incomingEdgeMetrics: EdgeMetrics[];
    /**
     * The metrics for edges from the specified workload to all workloads.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics outgoing_edge_metrics = 2;
     */
    outgoingEdgeMetrics: EdgeMetrics[];
}
/**
 * Get incoming and outgoing metrics for the specified edge.
 *
 * @generated from protobuf message rpc.gloo.solo.io.GetEdgeMetricsRequest
 */
export interface GetEdgeMetricsRequest {
    /**
     * Specific IDs to get metrics for, where an ID is currently comprised of the workload_id + network_id
     *
     * @generated from protobuf field: string source_id = 1;
     */
    sourceId: string;
    /**
     * @generated from protobuf field: string target_id = 2;
     */
    targetId: string;
    /**
     * The time that the metrics go up to. If omitted, will default to the current time.
     *
     * @generated from protobuf field: google.protobuf.Timestamp end_time = 3;
     */
    endTime?: Timestamp;
    /**
     * Duration of metrics to be returned. If omitted, will default to 5 minutes.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 4;
     */
    window?: Duration;
    /**
     * Duration of each metrics sample. If omitted, will default to the `window` value, which will return a single sample.
     *
     * @generated from protobuf field: google.protobuf.Duration step = 5;
     */
    step?: Duration;
    /**
     * Show Istio metrics, false by default
     *
     * @generated from protobuf field: bool istio_metrics = 6;
     */
    istioMetrics: boolean;
    /**
     * Show Cilium metrics, false by default
     *
     * @generated from protobuf field: bool cilium_metrics = 7;
     */
    ciliumMetrics: boolean;
    /**
     * Show TCP metrics, false by default
     *
     * @generated from protobuf field: bool tcp_metrics = 8;
     */
    tcpMetrics: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetEdgeMetricsResponse
 */
export interface GetEdgeMetricsResponse {
    /**
     * The metrics for going from the target workload to the source workload.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics incoming_edge_metrics = 1;
     */
    incomingEdgeMetrics: EdgeMetrics[];
    /**
     * The metrics for going from the source workload to the target workload.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics outgoing_edge_metrics = 2;
     */
    outgoingEdgeMetrics: EdgeMetrics[];
}
/**
 * Parameters for metrics aggregated for a network node over time.
 *
 * @generated from protobuf message rpc.gloo.solo.io.NodeMetricsParams
 */
export interface NodeMetricsParams {
    /**
     * A list of workloads whose metrics should be retrieved.
     * Leave empty to retrieve metrics for all workloads.
     *
     * @generated from protobuf field: repeated string workload_ids = 1;
     */
    workloadIds: string[];
    /**
     * @generated from protobuf field: string network_instance = 2;
     */
    networkInstance: string;
    /**
     * @generated from protobuf field: string network_subnet = 3;
     */
    networkSubnet: string;
    /**
     * @generated from protobuf field: string network_vpc = 4;
     */
    networkVpc: string;
    /**
     * The time which the metrics represent. Defaults to the present time if not specified.
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/timestamp).
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 5;
     */
    time?: Timestamp;
    /**
     * The duration over which the metrics are computed, floored to the minute.
     * I.e. metrics are returned for the the time window defined by [`time - window`, `time`].
     * Defaults to 5 minutes.
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/duration).
     *
     * @generated from protobuf field: google.protobuf.Duration window = 6;
     */
    window?: Duration;
    /**
     * The duration for each sample in the range, floored to the minute.
     * I.e. metrics are returned for the the time window defined by [`time - window`, `time`].
     * If omitted, defaults to the value of `window`, which represents a request for a single sample.
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/duration).
     *
     * @generated from protobuf field: google.protobuf.Duration step = 7;
     */
    step?: Duration;
    /**
     * Return Istio metrics, false by default
     *
     * @generated from protobuf field: bool istio_metrics = 8;
     */
    istioMetrics: boolean;
    /**
     * Return Cilium metrics, false by default
     *
     * @generated from protobuf field: bool cilium_metrics = 9;
     */
    ciliumMetrics: boolean;
    /**
     * Return TCP metrics, false by default
     *
     * @generated from protobuf field: bool tcp_metrics = 10;
     */
    tcpMetrics: boolean;
}
/**
 * Parameters for metrics aggregated for one or more network edges over time.
 *
 * @generated from protobuf message rpc.gloo.solo.io.EdgeMetricsParams
 */
export interface EdgeMetricsParams {
    /**
     * Select the edges (i.e. (source, target) workload pairs) whose metrics should be retrieved.
     * Leave empty to retrieve metrics for all edges.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetricsParams.EdgeSelector edge_selectors = 1;
     */
    edgeSelectors: EdgeMetricsParams_EdgeSelector[];
    /**
     * The time which the metrics represent. Defaults to the present time if not specified.
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/timestamp).
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 2;
     */
    time?: Timestamp;
    /**
     * The duration over which the metrics are computed, floored to the minute.
     * I.e. metrics are returned for the the time window defined by [`time - window`, `time`].
     * Defaults to 5 minutes.
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/duration).
     *
     * @generated from protobuf field: google.protobuf.Duration window = 3;
     */
    window?: Duration;
    /**
     * The duration for each sample in the range, floored to the minute.
     * I.e. metrics are returned for the the time window defined by [`time - window`, `time`].
     * Defaults to 2 minutes.
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/duration).
     *
     * @generated from protobuf field: google.protobuf.Duration step = 4;
     */
    step?: Duration;
    /**
     * Return Istio metrics, false by default
     *
     * @generated from protobuf field: bool istio_metrics = 5;
     */
    istioMetrics: boolean;
    /**
     * Return Cilium metrics, false by default
     *
     * @generated from protobuf field: bool cilium_metrics = 6;
     */
    ciliumMetrics: boolean;
    /**
     * Return TCP metrics, false by default
     *
     * @generated from protobuf field: bool tcp_metrics = 7;
     */
    tcpMetrics: boolean;
}
/**
 * Select the workloads whose metrics should be retrieved.
 * Leave empty to retrieve metrics for all workloads.
 *
 * @generated from protobuf message rpc.gloo.solo.io.EdgeMetricsParams.EdgeSelector
 */
export interface EdgeMetricsParams_EdgeSelector {
    /**
     * Specify the source workload. If omitted, retrieve edges targeting this workload and originating from any workload.
     *
     * @generated from protobuf field: string source_workload_id = 1;
     */
    sourceWorkloadId: string;
    /**
     * @generated from protobuf field: string source_instance = 2;
     */
    sourceInstance: string;
    /**
     * @generated from protobuf field: string source_subnet = 3;
     */
    sourceSubnet: string;
    /**
     * @generated from protobuf field: string source_vpc = 4;
     */
    sourceVpc: string;
    /**
     * Specify the target workload. If omitted, retrieve edges originating from this workload and targeting any workload.
     *
     * @generated from protobuf field: string target_workload_id = 5;
     */
    targetWorkloadId: string;
    /**
     * @generated from protobuf field: string target_instance = 6;
     */
    targetInstance: string;
    /**
     * @generated from protobuf field: string target_subnet = 7;
     */
    targetSubnet: string;
    /**
     * @generated from protobuf field: string target_vpc = 8;
     */
    targetVpc: string;
}
/**
 * Metrics for single node.
 *
 * @generated from protobuf message rpc.gloo.solo.io.NodeMetrics
 */
export interface NodeMetrics {
    /**
     * Reference to the workload associated with the metrics.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.WorkloadNode workload = 1;
     */
    workload?: WorkloadNode;
    /**
     * HTTP Metrics over time for all requests originating from the given workload.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.HttpMetrics outgoing_metrics = 2;
     */
    outgoingMetrics?: HttpMetrics;
    /**
     * HTTP Metrics over time for all requests targeting the given workload.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.HttpMetrics incoming_metrics = 3;
     */
    incomingMetrics?: HttpMetrics;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.TcpMetrics tcp_metrics = 4;
     */
    tcpMetrics?: TcpMetrics;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.CiliumMetrics cilium_metrics = 5;
     */
    ciliumMetrics?: CiliumMetrics;
    /**
     * Counts of active ejections for outgoing requests originating from this service.
     * Sidecars must be annotated to record outlier detection in order to populate this data.
     * The key of each k/v pair is a combination of the service/namespace/owner that the ejections are tied to.
     * Due to system limitations, we cannot differentiate between workloads of the ejected service.
     * For example, we could say that the reviews service has ejections, but we wouldn't know if it's
     * caused by reviews-v1, reviews-v2, or both.
     * This is not included in the metrics struct because it is not temporal - active ejections either exist,
     * or they aren't active anymore. Also this data is node-only.
     *
     * @generated from protobuf field: map<string, int32> active_ejection_counts = 6;
     */
    activeEjectionCounts: {
        [key: string]: number;
    };
}
/**
 * Metrics over time for a single network edge.
 *
 * @generated from protobuf message rpc.gloo.solo.io.EdgeMetrics
 */
export interface EdgeMetrics {
    /**
     * Reference to the source workload.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.WorkloadNode source_workload = 1;
     */
    sourceWorkload?: WorkloadNode;
    /**
     * Reference to the target workload.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.WorkloadNode target_workload = 2;
     */
    targetWorkload?: WorkloadNode;
    /**
     * HTTP Metrics over time for a network edge with a single source and target.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.HttpMetrics http_metrics = 3;
     */
    httpMetrics?: HttpMetrics;
    /**
     * TCP Metrics over time for a network edge with a single source and target
     *
     * @generated from protobuf field: rpc.gloo.solo.io.TcpMetrics tcp_metrics = 4;
     */
    tcpMetrics?: TcpMetrics;
    /**
     * Cilium Metrics over time for a network edge with a single source and target
     *
     * @generated from protobuf field: rpc.gloo.solo.io.CiliumMetrics cilium_metrics = 5;
     */
    ciliumMetrics?: CiliumMetrics;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.WorkloadNode
 */
export interface WorkloadNode {
    /**
     * Random but unique graph node ID, currently comprised of the workload_id + network_id
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Display name of the node
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * Information used to render boundary boxes for the workload node
     * This information is optional. An empty value or a value of "unknown" will
     * result in this node not being rendered inside of a box.
     *
     * @generated from protobuf field: string namespace = 3;
     */
    namespace: string;
    /**
     * @generated from protobuf field: string cluster = 4;
     */
    cluster: string;
    /**
     * @generated from protobuf field: string workspace = 5;
     */
    workspace: string;
    /**
     * @generated from protobuf field: string vpc = 6;
     */
    vpc: string;
    /**
     * @generated from protobuf field: string subnet = 7;
     */
    subnet: string;
    /**
     * @generated from protobuf field: string instance = 8;
     */
    instance: string;
    /**
     * @generated from protobuf field: string platform = 9;
     */
    platform: string; // AWS or GCP
    /**
     * An enum (apiserver specific) that denotes what kind of node should be displayed.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.NodeType node_type = 10;
     */
    nodeType: NodeType;
}
/**
 * HTTP Metrics sampled over time.
 *
 * @generated from protobuf message rpc.gloo.solo.io.HttpMetrics
 */
export interface HttpMetrics {
    /**
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/timestamp).
     *
     * @generated from protobuf field: repeated google.protobuf.Timestamp timestamps = 1;
     */
    timestamps: Timestamp[];
    /**
     * The number of requests.
     *
     * @generated from protobuf field: repeated float request_count = 2;
     */
    requestCount: number[];
    /**
     * The number of successful requests.
     *
     * @generated from protobuf field: repeated float success_count = 3;
     */
    successCount: number[];
    /**
     * The number of failed requests.
     *
     * @generated from protobuf field: repeated float failure_count = 4;
     */
    failureCount: number[];
    /**
     * Percentiles for request latencies, measured in milliseconds.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.HttpMetrics.RequestLatencies request_latencies = 5;
     */
    requestLatencies?: HttpMetrics_RequestLatencies;
    /**
     * Describes the security policy, if any, applied to this network edge.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.EdgeSecurity security = 6;
     */
    security?: EdgeSecurity;
}
/**
 * Percentiles for request latencies, measured in milliseconds.
 *
 * @generated from protobuf message rpc.gloo.solo.io.HttpMetrics.RequestLatencies
 */
export interface HttpMetrics_RequestLatencies {
    /**
     * 99th percentile request latency.
     *
     * @generated from protobuf field: repeated float p99 = 1;
     */
    p99: number[];
    /**
     * 90th percentile request latency.
     *
     * @generated from protobuf field: repeated float p90 = 2;
     */
    p90: number[];
    /**
     * 50th percentile request latency.
     *
     * @generated from protobuf field: repeated float p50 = 3;
     */
    p50: number[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.TcpMetrics
 */
export interface TcpMetrics {
    /**
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/timestamp).
     *
     * @generated from protobuf field: repeated google.protobuf.Timestamp timestamps = 1;
     */
    timestamps: Timestamp[];
    /**
     * @generated from protobuf field: repeated float bytes_sent = 2;
     */
    bytesSent: number[];
    /**
     * @generated from protobuf field: repeated float bytes_received = 3;
     */
    bytesReceived: number[];
    /**
     * Describes the security policy, if any, applied to this network edge.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.EdgeSecurity security = 4;
     */
    security?: EdgeSecurity;
}
/**
 * Describes the security policy, if any, applied to this network edge.
 *
 * @generated from protobuf message rpc.gloo.solo.io.EdgeSecurity
 */
export interface EdgeSecurity {
    /**
     * Describes the security policy of the connection.
     *
     * @generated from protobuf field: string security_policy = 1;
     */
    securityPolicy: string;
    /**
     * The identity of the source workload, only populated if mTLS is enabled.
     *
     * @generated from protobuf field: string source_principal = 2;
     */
    sourcePrincipal: string;
    /**
     * The identity of the destination, only populated if mTLS is enabled.
     *
     * @generated from protobuf field: string destination_principal = 3;
     */
    destinationPrincipal: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.CiliumMetrics
 */
export interface CiliumMetrics {
    /**
     * For information about the value format, see the [Google protocol buffer documentation](https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/timestamp).
     *
     * @generated from protobuf field: repeated google.protobuf.Timestamp timestamps = 1;
     */
    timestamps: Timestamp[];
    /**
     * @generated from protobuf field: repeated float forwarded_sent = 2;
     */
    forwardedSent: number[];
    /**
     * @generated from protobuf field: repeated float forwarded_received = 3;
     */
    forwardedReceived: number[];
    /**
     * @generated from protobuf field: repeated float policy_drops = 6;
     */
    policyDrops: number[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetServiceDetailsRequest
 */
export interface GetServiceDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef service_ref = 1;
     */
    serviceRef?: ClusterObjectRef;
    /**
     * The time that the metrics go up to. If omitted, will default to the current time.
     *
     * @generated from protobuf field: google.protobuf.Timestamp end_time = 4;
     */
    endTime?: Timestamp;
    /**
     * Duration of metrics to be returned. If omitted, will default to 5 minutes.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 5;
     */
    window?: Duration;
    /**
     * Duration of each metrics sample. If omitted, will default to the `window` value, which will return a single sample.
     *
     * @generated from protobuf field: google.protobuf.Duration step = 6;
     */
    step?: Duration;
    /**
     * Show Istio metrics, false by default
     *
     * @generated from protobuf field: bool istio_metrics = 7;
     */
    istioMetrics: boolean;
    /**
     * Show Cilium metrics, false by default
     *
     * @generated from protobuf field: bool cilium_metrics = 8;
     */
    ciliumMetrics: boolean;
    /**
     * Show TCP metrics, false by default
     *
     * @generated from protobuf field: bool tcp_metrics = 9;
     */
    tcpMetrics: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetServiceDetailsResponse
 */
export interface GetServiceDetailsResponse {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef service_ref = 1;
     */
    serviceRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string workload_id = 2;
     */
    workloadId: string;
    /**
     * Node metrics.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.NodeMetrics node_metrics = 3;
     */
    nodeMetrics: NodeMetrics[];
    /**
     * The metrics for edges from all workloads to the specified workload.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics incoming_edge_metrics = 4;
     */
    incomingEdgeMetrics: EdgeMetrics[];
    /**
     * The metrics for edges from the specified workload to all workloads.
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.EdgeMetrics outgoing_edge_metrics = 5;
     */
    outgoingEdgeMetrics: EdgeMetrics[];
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 6;
     */
    apiserverErrors: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.NodeType
 */
export enum NodeType {
    /**
     * An unknown node type should never be sent to the UI
     *
     * @generated from protobuf enum value: UNKNOWN_NODE_TYPE = 0;
     */
    UNKNOWN_NODE_TYPE = 0,
    /**
     * This is a workload node specifically designed to handle north-south traffic
     *
     * @generated from protobuf enum value: GATEWAY = 1;
     */
    GATEWAY = 1,
    /**
     * This is a workload located outside of the Istio mesh, with no sidecar attached to it
     *
     * @generated from protobuf enum value: KUBERNETES_WORKLOAD = 2;
     */
    KUBERNETES_WORKLOAD = 2,
    /**
     * This is a workload that belongs to an Istio mesh, with a sidecar
     *
     * @generated from protobuf enum value: MESH_WORKLOAD = 3;
     */
    MESH_WORKLOAD = 3,
    /**
     * This is a workload that belongs to an Ambient Istio mesh, with no sidecar and no Waypoint Proxy
     *
     * @generated from protobuf enum value: AMBIENT_WORKLOAD = 4;
     */
    AMBIENT_WORKLOAD = 4,
    /**
     * This is a workload that belongs to an Ambient Istio mesh, with a Waypoint Proxy deployed next to it
     *
     * @generated from protobuf enum value: WAYPOINT_PROXY_WORKLOAD = 5;
     */
    WAYPOINT_PROXY_WORKLOAD = 5,
    /**
     * This is a workload that can not be in a Kubernetes environment
     *
     * @generated from protobuf enum value: EXTERNAL_WORKLOAD = 6;
     */
    EXTERNAL_WORKLOAD = 6,
    /**
     * A lambda workload is a special case of an external workload that we know to be a Lambda Function in AWS
     *
     * @generated from protobuf enum value: LAMBDA_WORKLOAD = 7;
     */
    LAMBDA_WORKLOAD = 7,
    /**
     * A VM workload is a special case of an external workload that we know to belong to a bare-metal VM
     *
     * @generated from protobuf enum value: VM_WORKLOAD = 8;
     */
    VM_WORKLOAD = 8
}
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphRequest$Type extends MessageType<GetGraphRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphRequest", [
            { no: 1, name: "namespaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "clusters", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "workspaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "workload_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "end_time", kind: "message", T: () => Timestamp },
            { no: 5, name: "window", kind: "message", T: () => Duration },
            { no: 6, name: "step", kind: "message", T: () => Duration },
            { no: 7, name: "istio_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "cilium_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "tcp_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphRequest
 */
export const GetGraphRequest = new GetGraphRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphResponse$Type extends MessageType<GetGraphResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphResponse", [
            { no: 1, name: "node_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NodeMetrics },
            { no: 2, name: "edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphResponse
 */
export const GetGraphResponse = new GetGraphResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFiltersRequest$Type extends MessageType<GetFiltersRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetFiltersRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetFiltersRequest
 */
export const GetFiltersRequest = new GetFiltersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFiltersResponse$Type extends MessageType<GetFiltersResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetFiltersResponse", [
            { no: 1, name: "namespaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "clusters", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "workspaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "workspace_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetFiltersResponse_WorkspaceClusters } },
            { no: 5, name: "cluster_namespace_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetFiltersResponse_ClusterNamespaces } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetFiltersResponse
 */
export const GetFiltersResponse = new GetFiltersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFiltersResponse_WorkspaceClusters$Type extends MessageType<GetFiltersResponse_WorkspaceClusters> {
    constructor() {
        super("rpc.gloo.solo.io.GetFiltersResponse.WorkspaceClusters", [
            { no: 1, name: "workspace_clusters", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetFiltersResponse_ClusterNamespaces } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetFiltersResponse.WorkspaceClusters
 */
export const GetFiltersResponse_WorkspaceClusters = new GetFiltersResponse_WorkspaceClusters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFiltersResponse_ClusterNamespaces$Type extends MessageType<GetFiltersResponse_ClusterNamespaces> {
    constructor() {
        super("rpc.gloo.solo.io.GetFiltersResponse.ClusterNamespaces", [
            { no: 1, name: "cluster_namespaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetFiltersResponse.ClusterNamespaces
 */
export const GetFiltersResponse_ClusterNamespaces = new GetFiltersResponse_ClusterNamespaces$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWorkloadMetricsRequest$Type extends MessageType<GetWorkloadMetricsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetWorkloadMetricsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "end_time", kind: "message", T: () => Timestamp },
            { no: 3, name: "window", kind: "message", T: () => Duration },
            { no: 4, name: "step", kind: "message", T: () => Duration },
            { no: 5, name: "istio_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "cilium_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "tcp_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWorkloadMetricsRequest
 */
export const GetWorkloadMetricsRequest = new GetWorkloadMetricsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWorkloadMetricsResponse$Type extends MessageType<GetWorkloadMetricsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetWorkloadMetricsResponse", [
            { no: 1, name: "incoming_edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics },
            { no: 2, name: "outgoing_edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWorkloadMetricsResponse
 */
export const GetWorkloadMetricsResponse = new GetWorkloadMetricsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEdgeMetricsRequest$Type extends MessageType<GetEdgeMetricsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetEdgeMetricsRequest", [
            { no: 1, name: "source_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "end_time", kind: "message", T: () => Timestamp },
            { no: 4, name: "window", kind: "message", T: () => Duration },
            { no: 5, name: "step", kind: "message", T: () => Duration },
            { no: 6, name: "istio_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "cilium_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "tcp_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetEdgeMetricsRequest
 */
export const GetEdgeMetricsRequest = new GetEdgeMetricsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEdgeMetricsResponse$Type extends MessageType<GetEdgeMetricsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetEdgeMetricsResponse", [
            { no: 1, name: "incoming_edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics },
            { no: 2, name: "outgoing_edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetEdgeMetricsResponse
 */
export const GetEdgeMetricsResponse = new GetEdgeMetricsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NodeMetricsParams$Type extends MessageType<NodeMetricsParams> {
    constructor() {
        super("rpc.gloo.solo.io.NodeMetricsParams", [
            { no: 1, name: "workload_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "network_instance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "network_subnet", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "network_vpc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "time", kind: "message", T: () => Timestamp },
            { no: 6, name: "window", kind: "message", T: () => Duration },
            { no: 7, name: "step", kind: "message", T: () => Duration },
            { no: 8, name: "istio_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "cilium_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "tcp_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.NodeMetricsParams
 */
export const NodeMetricsParams = new NodeMetricsParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EdgeMetricsParams$Type extends MessageType<EdgeMetricsParams> {
    constructor() {
        super("rpc.gloo.solo.io.EdgeMetricsParams", [
            { no: 1, name: "edge_selectors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetricsParams_EdgeSelector },
            { no: 2, name: "time", kind: "message", T: () => Timestamp },
            { no: 3, name: "window", kind: "message", T: () => Duration },
            { no: 4, name: "step", kind: "message", T: () => Duration },
            { no: 5, name: "istio_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "cilium_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "tcp_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.EdgeMetricsParams
 */
export const EdgeMetricsParams = new EdgeMetricsParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EdgeMetricsParams_EdgeSelector$Type extends MessageType<EdgeMetricsParams_EdgeSelector> {
    constructor() {
        super("rpc.gloo.solo.io.EdgeMetricsParams.EdgeSelector", [
            { no: 1, name: "source_workload_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_instance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "source_subnet", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "source_vpc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "target_workload_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "target_instance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "target_subnet", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "target_vpc", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.EdgeMetricsParams.EdgeSelector
 */
export const EdgeMetricsParams_EdgeSelector = new EdgeMetricsParams_EdgeSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NodeMetrics$Type extends MessageType<NodeMetrics> {
    constructor() {
        super("rpc.gloo.solo.io.NodeMetrics", [
            { no: 1, name: "workload", kind: "message", T: () => WorkloadNode },
            { no: 2, name: "outgoing_metrics", kind: "message", T: () => HttpMetrics },
            { no: 3, name: "incoming_metrics", kind: "message", T: () => HttpMetrics },
            { no: 4, name: "tcp_metrics", kind: "message", T: () => TcpMetrics },
            { no: 5, name: "cilium_metrics", kind: "message", T: () => CiliumMetrics },
            { no: 6, name: "active_ejection_counts", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.NodeMetrics
 */
export const NodeMetrics = new NodeMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EdgeMetrics$Type extends MessageType<EdgeMetrics> {
    constructor() {
        super("rpc.gloo.solo.io.EdgeMetrics", [
            { no: 1, name: "source_workload", kind: "message", T: () => WorkloadNode },
            { no: 2, name: "target_workload", kind: "message", T: () => WorkloadNode },
            { no: 3, name: "http_metrics", kind: "message", T: () => HttpMetrics },
            { no: 4, name: "tcp_metrics", kind: "message", T: () => TcpMetrics },
            { no: 5, name: "cilium_metrics", kind: "message", T: () => CiliumMetrics }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.EdgeMetrics
 */
export const EdgeMetrics = new EdgeMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkloadNode$Type extends MessageType<WorkloadNode> {
    constructor() {
        super("rpc.gloo.solo.io.WorkloadNode", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workspace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "vpc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "subnet", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "instance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "platform", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "node_type", kind: "enum", T: () => ["rpc.gloo.solo.io.NodeType", NodeType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.WorkloadNode
 */
export const WorkloadNode = new WorkloadNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HttpMetrics$Type extends MessageType<HttpMetrics> {
    constructor() {
        super("rpc.gloo.solo.io.HttpMetrics", [
            { no: 1, name: "timestamps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Timestamp },
            { no: 2, name: "request_count", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "success_count", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "failure_count", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "request_latencies", kind: "message", T: () => HttpMetrics_RequestLatencies },
            { no: 6, name: "security", kind: "message", T: () => EdgeSecurity }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.HttpMetrics
 */
export const HttpMetrics = new HttpMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HttpMetrics_RequestLatencies$Type extends MessageType<HttpMetrics_RequestLatencies> {
    constructor() {
        super("rpc.gloo.solo.io.HttpMetrics.RequestLatencies", [
            { no: 1, name: "p99", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 2, name: "p90", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "p50", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.HttpMetrics.RequestLatencies
 */
export const HttpMetrics_RequestLatencies = new HttpMetrics_RequestLatencies$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TcpMetrics$Type extends MessageType<TcpMetrics> {
    constructor() {
        super("rpc.gloo.solo.io.TcpMetrics", [
            { no: 1, name: "timestamps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Timestamp },
            { no: 2, name: "bytes_sent", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "bytes_received", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "security", kind: "message", T: () => EdgeSecurity }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.TcpMetrics
 */
export const TcpMetrics = new TcpMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EdgeSecurity$Type extends MessageType<EdgeSecurity> {
    constructor() {
        super("rpc.gloo.solo.io.EdgeSecurity", [
            { no: 1, name: "security_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_principal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "destination_principal", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.EdgeSecurity
 */
export const EdgeSecurity = new EdgeSecurity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CiliumMetrics$Type extends MessageType<CiliumMetrics> {
    constructor() {
        super("rpc.gloo.solo.io.CiliumMetrics", [
            { no: 1, name: "timestamps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Timestamp },
            { no: 2, name: "forwarded_sent", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "forwarded_received", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "policy_drops", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.CiliumMetrics
 */
export const CiliumMetrics = new CiliumMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetServiceDetailsRequest$Type extends MessageType<GetServiceDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetServiceDetailsRequest", [
            { no: 1, name: "service_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 4, name: "end_time", kind: "message", T: () => Timestamp },
            { no: 5, name: "window", kind: "message", T: () => Duration },
            { no: 6, name: "step", kind: "message", T: () => Duration },
            { no: 7, name: "istio_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "cilium_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "tcp_metrics", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetServiceDetailsRequest
 */
export const GetServiceDetailsRequest = new GetServiceDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetServiceDetailsResponse$Type extends MessageType<GetServiceDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetServiceDetailsResponse", [
            { no: 1, name: "service_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "workload_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "node_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NodeMetrics },
            { no: 4, name: "incoming_edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics },
            { no: 5, name: "outgoing_edge_metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EdgeMetrics },
            { no: 6, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetServiceDetailsResponse
 */
export const GetServiceDetailsResponse = new GetServiceDetailsResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.GraphApi
 */
export const GraphApi = new ServiceType("rpc.gloo.solo.io.GraphApi", [
    { name: "GetGraph", options: {}, I: GetGraphRequest, O: GetGraphResponse },
    { name: "GetFilters", options: {}, I: GetFiltersRequest, O: GetFiltersResponse },
    { name: "GetWorkloadMetrics", options: {}, I: GetWorkloadMetricsRequest, O: GetWorkloadMetricsResponse },
    { name: "GetEdgeMetrics", options: {}, I: GetEdgeMetricsRequest, O: GetEdgeMetricsResponse },
    { name: "GetServiceDetails", options: {}, I: GetServiceDetailsRequest, O: GetServiceDetailsResponse }
]);
