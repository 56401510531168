import { Spacer } from 'Styles/CommonEmotions/spacer';
import { InterfaceTypeDefinitionNode, NamedTypeNode, ObjectTypeDefinitionNode } from 'graphql';
import FieldTypeValue from '../FieldTypeValue';
import { GqlDescriptionInfoTooltip } from '../GqlDescriptionInfoTooltip';

/**
 * For an ObjectTypeDefinitionNode, renders links to any interfaces that it implements.
 */
const GqlTabItemInterfaceImplementations = ({
  node,
  extendedInterfaces,
  onTypeClick
}: {
  node: ObjectTypeDefinitionNode | InterfaceTypeDefinitionNode | undefined;
  extendedInterfaces?: readonly NamedTypeNode[];
  onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
}) => {
  //
  // Render
  //
  return (
    <>
      {!!node?.interfaces?.length && (
        <Spacer pt={1} pb={2} display='block'>
          <span>Implements:&nbsp;</span>
          {node.interfaces.map((f, i) => (
            <span key={f.name.value}>
              {i > 0 && ', '}
              <FieldTypeValue field={f} onTypeClick={onTypeClick} />
            </span>
          ))}
        </Spacer>
      )}
      {!!extendedInterfaces?.length && (
        <Spacer pt={1} pb={2} display='block'>
          <GqlDescriptionInfoTooltip description={'This type was extended to implement this interface.'} />
          <span>Implements:&nbsp;</span>
          {extendedInterfaces.map((f, i) => (
            <span key={f.name.value}>
              {i > 0 && ', '}
              <FieldTypeValue field={f} onTypeClick={onTypeClick} />
            </span>
          ))}
        </Spacer>
      )}
    </>
  );
};

export default GqlTabItemInterfaceImplementations;
