import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { BasicDestinationsTable } from 'Components/Features/Destinations/BasicDestinationsTable';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GetClientTlsPolicyDetailsResponse_ClientTlsMode } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetClientTlsPolicyDetails } = policiesApi;

const policyType = PolicyType.CLIENT_TLS;

const SecondaryLabelWithIcon = styled.span`
  display: inline-flex;
  align-items: baseline;

  > svg {
    align-self: center;
    margin-right: 2px;
    width: 15px;
    height: 15px;
  }
`;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ClientTlsDetails = ({ clusterObjRef }: Props) => {
  di(useGetClientTlsPolicyDetails);
  const destPagingData = useSoloPaging();

  const { data: policyData, error: policyError } = useGetClientTlsPolicyDetails(
    clusterObjRef,
    destPagingData.apiPaginationObject
  );

  destPagingData.useSetPagingTotal(policyData?.destinations?.destinations?.length);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <>
      <CardStyles.CardList>
        <DetailsHeaderCard
          icon={getPolicyIcon(policyType)}
          status={policyData?.status}
          objRef={clusterObjRef}
          workspaceRef={policyData?.ownerWorkspace?.ref}
          resourceType={getPolicyResourceType(policyType)}
        />
        <CardStyles.Card>
          <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

          <div style={{ marginBottom: '20px' }}>
            {policyData?.mode === GetClientTlsPolicyDetailsResponse_ClientTlsMode.DISABLE && (
              <>
                <StatMiniCardList
                  items={[
                    {
                      icon: <Asset.TlsPolicyDisabled />,
                      title: 'Disabled',
                      data: null
                    }
                  ]}
                />
              </>
            )}
            {policyData?.mode === GetClientTlsPolicyDetailsResponse_ClientTlsMode.SIMPLE && (
              <>
                <StatMiniCardList
                  items={[
                    {
                      icon: <Asset.TlsPolicyIcon />,
                      title: 'Simple',
                      data: (
                        <SecondaryInfo
                          items={[
                            {
                              label: 'sni',
                              data: <div>{policyData?.tlsConfig?.sni?.value || 'No SNI configured'}</div>
                            },
                            {
                              label: (
                                <SecondaryLabelWithIcon>
                                  <Asset.KeyIcon />
                                  credential secret name
                                </SecondaryLabelWithIcon>
                              ),
                              data: (
                                <div>
                                  {policyData?.tlsConfig?.credentialName || 'No credential secret name configured'}
                                </div>
                              )
                            }
                          ]}
                        />
                      )
                    }
                  ]}
                />
              </>
            )}
            {policyData?.mode === GetClientTlsPolicyDetailsResponse_ClientTlsMode.MUTUAL && (
              <>
                <StatMiniCardList
                  items={[
                    {
                      icon: <Asset.TlsPolicyIcon />,
                      title: 'Mutual',
                      data: (
                        <SecondaryInfo
                          items={[
                            {
                              label: 'sni',
                              data: <div>{policyData?.tlsConfig?.sni?.value || 'No SNI configured'}</div>
                            },
                            {
                              label: (
                                <SecondaryLabelWithIcon>
                                  <Asset.SecureLockIcon />
                                  credential secret name
                                </SecondaryLabelWithIcon>
                              ),
                              data: (
                                <div>
                                  {policyData?.tlsConfig?.credentialName || 'No credential secret name configured'}
                                </div>
                              )
                            }
                          ]}
                        />
                      )
                    }
                  ]}
                />
              </>
            )}
            {policyData?.mode === GetClientTlsPolicyDetailsResponse_ClientTlsMode.ISTIO_MUTUAL && (
              <>
                <StatMiniCardList
                  items={[
                    {
                      icon: <Asset.TlsIstioMutual />,
                      title: 'Istio Mutual',
                      data: null
                    }
                  ]}
                />
              </>
            )}
          </div>
        </CardStyles.Card>

        <CardStyles.Card>
          <CardStyles.CardHeader>
            Applied to Destinations
            <CardStyles.CardHeaderRightIcon>
              <Asset.ServiceIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          <BasicDestinationsTable
            destinations={policyData?.destinations?.destinations}
            paging={destPagingData.pagingData}
          />
        </CardStyles.Card>
      </CardStyles.CardList>
    </>
  );
};
