import { dashboardApi } from 'Api/dashboard';
import { ExternalSoloLink, SoloLink } from 'Components/Common/SoloLink';
import { Breadcrumb } from 'Components/Structure/TopBar/Breadcrumb';
import { Asset } from 'assets';
import { GetUIFeatureFlagsResponse_License_LicenseState } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb';
import { useRef, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useClickedOutside } from 'utils/hooks';
import { Permission, useLicenses, usePermissions } from 'utils/permissions';
import { buildFlagsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { SettingsDropdown } from './SettingsDropdown';
import { TopBarStyles } from './TopBar.style';

const { useGetLogoutPath } = dashboardApi;

type DropdownType = 'settings' | 'user' | 'help';

function getDocsLink(isCore: boolean) {
  return isCore ? docLinks.core.root : docLinks.enterprise.root;
}

export const TopBar = () => {
  di(useGetLogoutPath, usePermissions, useLicenses);
  const { data: logoutData } = useGetLogoutPath();
  const { hasPerm } = usePermissions();
  const { userViewableLicenses } = useLicenses();

  const [activeDropdown, setActiveDropdown] = useState<DropdownType>();

  let worstLicenseState = GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_GRANTED;
  Object.values(userViewableLicenses).forEach(license => {
    if (worstLicenseState !== GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRED && !!license) {
      if (license.state === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRED) {
        worstLicenseState = GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRED;
      } else if (license.state === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRES_SOON) {
        worstLicenseState = GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRES_SOON;
      }
    }
  });

  const toggleDropdown = (type: DropdownType) => {
    setActiveDropdown(prev => (prev !== type ? type : undefined));
  };

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setActiveDropdown(undefined);
  });

  return (
    <TopBarStyles.Container data-testid='topbar-container' ref={wrapperRef} role='banner'>
      <TopBarStyles.InnerContainer>
        <TopBarStyles.Flexor>
          <Breadcrumb />
        </TopBarStyles.Flexor>
        <TopBarStyles.RightFlexor aria-label='Site top level menus'>
          <TopBarStyles.DropdownMenuHolder
            data-testid='topbar-licenses-toggle'
            aria-label='Licenses'
            onClick={() => toggleDropdown('settings')}
            aria-expanded={activeDropdown === 'settings'}>
            <TopBarStyles.DropdownMenuIconHolder
              isActive={activeDropdown === 'settings'}
              licenseState={worstLicenseState}>
              {/* {activeDropdown === 'settings' ? <Asset.SettingsGearHover /> : <Asset.SettingsGear />} */}
              <Asset.SettingsGear />
            </TopBarStyles.DropdownMenuIconHolder>
            <TopBarStyles.DropdownMenu isActive={activeDropdown === 'settings'}>
              <SettingsDropdown />
            </TopBarStyles.DropdownMenu>
          </TopBarStyles.DropdownMenuHolder>
          {!!logoutData?.logoutPath && (
            <>
              <TopBarStyles.DropdownDivider />
              <TopBarStyles.DropdownMenuHolder
                aria-label='User Options'
                onClick={() => toggleDropdown('user')}
                aria-expanded={activeDropdown === 'user'}>
                <TopBarStyles.DropdownMenuIconHolder isActive={activeDropdown === 'user'}>
                  <Asset.ProfileIcon />
                </TopBarStyles.DropdownMenuIconHolder>
                <TopBarStyles.DropdownMenu isActive={activeDropdown === 'user'}>
                  <ul>
                    <li>
                      <ExternalSoloLink href={logoutData.logoutPath}>
                        <TopBarStyles.DropdownMenuItem flipIcon={true}>
                          Logout <Asset.ArrowToggle />
                        </TopBarStyles.DropdownMenuItem>
                      </ExternalSoloLink>
                    </li>
                  </ul>
                </TopBarStyles.DropdownMenu>
              </TopBarStyles.DropdownMenuHolder>
            </>
          )}
          <TopBarStyles.DropdownDivider />
          <TopBarStyles.DropdownMenuHolder
            aria-label='Help'
            onClick={() => toggleDropdown('help')}
            aria-expanded={activeDropdown === 'help'}>
            <TopBarStyles.DropdownMenuIconHolder isActive={activeDropdown === 'help'}>
              <Asset.HelpIcon />
            </TopBarStyles.DropdownMenuIconHolder>
            <TopBarStyles.DropdownMenu isActive={activeDropdown === 'help'}>
              <ul>
                <li>
                  <ExternalSoloLink href={getDocsLink(hasPerm(Permission.CoreLicense))} newTab>
                    <TopBarStyles.DropdownMenuItem>
                      View Documentation <Asset.IconDocumentation />
                    </TopBarStyles.DropdownMenuItem>
                  </ExternalSoloLink>
                </li>
                <li>
                  <ExternalSoloLink href='https://solo-io.slack.com/archives/C4RFYH51D' newTab>
                    <TopBarStyles.DropdownMenuItem>
                      Get Help <Asset.IconSlack />
                    </TopBarStyles.DropdownMenuItem>
                  </ExternalSoloLink>
                </li>
                {hasPerm(Permission.CanViewFlags) && (
                  <li>
                    <SoloLink link={buildFlagsUrl()}>
                      <TopBarStyles.DropdownMenuItem>
                        Feature Flags <Asset.KeysIcon />
                      </TopBarStyles.DropdownMenuItem>
                    </SoloLink>
                  </li>
                )}
              </ul>
            </TopBarStyles.DropdownMenu>
          </TopBarStyles.DropdownMenuHolder>
        </TopBarStyles.RightFlexor>
      </TopBarStyles.InnerContainer>
    </TopBarStyles.Container>
  );
};
