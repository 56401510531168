import { css } from '@emotion/react';
import styled from '@emotion/styled';

export namespace OverviewLandingStyles {
  type ContainerProps = {
    singleArea: boolean;
  };
  export const Container = styled.div<ContainerProps>(
    ({ singleArea }) => css`
      display: grid;
      grid-template-columns: ${
        // 50%-15px used here instead of 1fr to prevent overflowing text from
        // resizing column instead of being truncated
        singleArea ? `1fr` : `repeat(2, calc(50% - 15px))`
      };
      grid-gap: 30px;
      height: 100%;
    `
  );
}
