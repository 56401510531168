import { LogViewer } from '@patternfly/react-log-viewer';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { Properties } from 'csstype';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useWindowInnerHeight } from 'utils/hooks';
import { DataErrorStringListCompact } from '../DataError';
import { Svg } from '../Svg';
import { SoloLogViewerStyles } from './SoloLogViewer.style';
import SoloLogViewerFooterButton from './SoloLogViewerFooterButton';
import {
  SoloLogViewerClusterComponentPodSelectionProps,
  SoloLogViewerToolbarProps,
  SoloLogViewerToolbarWithServiceSelection
} from './SoloLogViewerToolbar';

//
// Types
//
interface SoloLogViewerDataSource {
  isStreaming: boolean;
  label: string;
  data: string[];
}

export interface SoloLogViewerDataSources {
  initialDataSource: SoloLogViewerDataSource;
  [id: string]: SoloLogViewerDataSource;
}

interface SoloLogViewerProps extends SoloLogViewerClusterComponentPodSelectionProps {
  dataSources?: SoloLogViewerDataSources;
  ToolbarComponent?: (props: SoloLogViewerToolbarProps) => JSX.Element | null;
  errorsList?: string[];
  isWaitingForSelection?: boolean;
}

//
// Component
//
const SoloLogViewer = (props: SoloLogViewerProps) => {
  const {
    dataSources = {
      // The initial data source shows an empty log if it is undefined.
      initialDataSource: {
        isStreaming: false,
        label: 'No Data',
        data: []
      }
    },
    ToolbarComponent = SoloLogViewerToolbarWithServiceSelection
  } = props;

  const [searchText, setSearchText] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [lineNumbersEnabled, setLineNumbersEnabled] = useState(true);
  // This could be useful to toggle for non-streaming data, but for streaming
  // data, the line wrap causes the lines to flicker.
  const [lineWrapEnabled, setLineWrapEnabled] = useState(false);
  const logViewerRef = useRef<ElementRef<'div'>>(null);
  const logViewerContainerRef = useRef<ElementRef<'div'>>(null);
  const windowInnerHeight = useWindowInnerHeight();

  //
  // Data source selection
  //
  const [selectedDataSourceKey, setSelectedDataSourceKey] =
    useState<keyof SoloLogViewerDataSources>('initialDataSource');
  const selectedDataSource = dataSources[selectedDataSourceKey];
  const selectedData = selectedDataSource.data;
  const dataSourceIsStreaming = selectedDataSource.isStreaming;

  const [prevDataSourceLabel, setPrevDataSourceLabel] = useState<string>();
  useEffect(() => {
    if (prevDataSourceLabel !== selectedDataSource.label) {
      return () => {
        setPrevDataSourceLabel(selectedDataSource.label);
      };
    }
  }, [selectedDataSource]);

  //
  // Streaming state
  //
  const [isPaused, setIsPaused] = useState(true);
  //
  // This is the latest data that will been rendered.
  // If the user pauses the stream, it stops being appended to.
  const [renderData, setRenderData] = useState(selectedDataSource.data.join('\n'));
  //
  // The number of lines to render to the screen.
  // If the user pauses the stream, it stops increasing.
  const [linesToRender, setLinesToRender] = useState(selectedDataSource.data.length);
  //
  // This the number of lines that has been loaded.
  // If the user pauses the stream, it continues increasing.
  const [linesLoaded, setLinesLoaded] = useState(selectedDataSource.data.length);
  //
  // The number of lines loaded that haven't yet been rendered.
  const linesBehind = linesLoaded - linesToRender;

  //
  // Updates state when switching between data sources.
  //
  useEffect(() => {
    if (prevDataSourceLabel === selectedDataSource.label) {
      return;
    }
    setSearchText('');
    setRenderData(selectedDataSource.data.join('\n'));
    setLinesToRender(selectedDataSource.data.length);
    setLinesLoaded(selectedDataSource.data.length);
    if (dataSourceIsStreaming) {
      setIsPaused(false);
    } else {
      setIsPaused(true);
    }
  }, [dataSources, selectedDataSource, dataSourceIsStreaming]);

  //
  // Updates the buffer with the latest data, as if it's streaming.
  //
  useEffect(() => {
    const updateBufferTimout = window.setTimeout(() => {
      const isAllDataLoaded = linesLoaded === selectedData.length;
      if (!isAllDataLoaded) {
        setLinesLoaded(Math.max(linesLoaded + 1, selectedData.length));
      }
      // Setting a timeout here prevents the browser from being overloaded
      // with requests if they happen very quickly.
    }, 100);
    return () => {
      window.clearTimeout(updateBufferTimout);
    };
  }, [linesLoaded, selectedData]);

  //
  // Updates the rendered data as the lineNumberRendered increases.
  //
  useEffect(() => {
    if (isPaused) {
      return;
    }
    setLinesToRender(linesLoaded);
    setRenderData(selectedData.slice(0, linesLoaded).join('\n'));
    if (!!logViewerRef.current?.scrollToBottom) {
      logViewerRef.current.scrollToBottom();
    }
  }, [isPaused, linesLoaded, selectedData]);

  //
  // Forced resize on line wrap enabled to fix a bug that only renders one log
  //
  useEffect(()=>{
    window.dispatchEvent(new Event('resize'));
  }, [lineWrapEnabled]);

  //
  // Render
  //
  const logContainerHeight: Properties['height'] = isFullscreen
    ? '100%'
    : Math.max(300, windowInnerHeight - 300) + 'px';
  return (
    <SoloLogViewerStyles.LogViewerContainer
      ref={logViewerContainerRef}
      data-testid='log-viewer'
      isFullscreen={isFullscreen}
      isWaitingForSelection={props.isWaitingForSelection}>
      <LogViewer
        data={renderData}
        scrollToRow={linesToRender}
        innerRef={logViewerRef}
        height={!!props.isWaitingForSelection ? 0 : logContainerHeight}
        hasLineNumbers={lineNumbersEnabled}
        isTextWrapped={lineWrapEnabled}
        toolbar={
          <>
            <ToolbarComponent
              {...props}
              isFullscreen={isFullscreen}
              onIsFullscreenChanged={setIsFullscreen}
              dataSources={dataSources}
              selectedDataSourceKey={selectedDataSourceKey}
              logViewerContainerRef={logViewerContainerRef}
              onIsPausedChanged={setIsPaused}
              onSelectedDataSourceKeyChanged={setSelectedDataSourceKey}
              isPaused={isPaused}
              searchText={searchText}
              onSearchTextChanged={setSearchText}
              lineNumbersEnabled={lineNumbersEnabled}
              onLineNumbersEnabledChanged={setLineNumbersEnabled}
              lineWrapEnabled={lineWrapEnabled}
              onLineWrapEnabledChanged={setLineWrapEnabled}
            />
            {props.errorsList && (
              <Spacer mt={2}>
                <DataErrorStringListCompact errorsList={props.errorsList} />
              </Spacer>
            )}
          </>
        }
        overScanCount={10}
        footer={
          <SoloLogViewerFooterButton
            isVisible={isPaused && dataSourceIsStreaming}
            linesBehind={linesBehind}
            onResume={() => setIsPaused(false)}
          />
        }
        onScroll={({ scrollOffsetToBottom, scrollDirection, scrollUpdateWasRequested }) => {
          if (!!scrollUpdateWasRequested) {
            return;
          }
          setIsPaused(scrollOffsetToBottom > 0);
        }}
      />
      {!!props.isWaitingForSelection && (
        <SoloLogViewerStyles.EmptyLogsContainer height={logContainerHeight}>
          <Svg asset={<Asset.NoLogPlaceholder />} height={133} ml={'10px'} />
          <SoloLogViewerStyles.EmptyLogsTitle>Log Empty</SoloLogViewerStyles.EmptyLogsTitle>
          <SoloLogViewerStyles.EmptyLogsText>
            Please select a cluster, component, pod, and container using the filters to view the log.
          </SoloLogViewerStyles.EmptyLogsText>
        </SoloLogViewerStyles.EmptyLogsContainer>
      )}
    </SoloLogViewerStyles.LogViewerContainer>
  );
};

export default SoloLogViewer;
