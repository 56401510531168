import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FancyCodeDisplayerStyles } from 'Components/Common/FancyCodeDisplayer.style';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { svgColorReplace } from 'Styles/CommonEmotions/utils';
import { getStatusColors } from 'utils/health-status';

export namespace GqlResolverModalStyles {
  export const HeaderRight = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
  `;

  export const IconContainer = styled.div<{ healthStatus?: State }>(
    ({ theme, healthStatus }) => css`
      ${svgColorReplace(healthStatus === undefined ? theme.oceanBlue : getStatusColors(healthStatus).icon)}
    `
  );

  export const Code = styled.code``;

  export const VarHighlight = styled.span(
    ({ theme }) => css`
      color: ${theme.seaBlue};
    `
  );

  export const FancyCodeDisplayerContainer = styled.div`
    overflow: auto;
    width: 100%;
    margin-left: -0.5em;
    line-height: normal;

    ${FancyCodeDisplayerStyles.FancyCodeContainer} {
      margin: 0;
    }
  `;

  export const TableWrapper = styled.div`
    max-width: 100%;
    overflow-x: auto;
  `;

  export const MinWidthCell = styled.div`
    min-width: 120px;
    white-space: nowrap;
  `;
}
