import { css } from '@emotion/react';
import styled from '@emotion/styled';

type VerticalRuleProps = {
  height?: string;
  color?: string;
  margin?: string;
};

export const VerticalRule = styled.div<VerticalRuleProps>(
  ({ theme, height, color, margin }) => css`
    border-left: 1px solid ${color ?? theme.septemberGrey};
    margin: ${margin ?? '0 10px'};
    height: ${height ?? '1em'};
  `
);

type HorizontalRuleProps = {
  color?: string;
};

export const HorizontalRule = styled.div<HorizontalRuleProps>(
  ({ theme, color }) => css`
    height: 1px;
    width: 100%;
    background: ${color ?? theme.septemberGrey};
    margin: 10px 0;
  `
);
