import { Asset } from 'assets';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import React, { CSSProperties, useEffect } from 'react';
import { useEventListener } from 'utils/hooks';
import { HealthIndicatorIcon } from './HealthIndicator';
import { SoloModalStyles as Styles } from './SoloModal.style';

export interface ModalProps {
  visible?: boolean;
  width: CSSProperties['width'];
  minHeight?: CSSProperties['minHeight'];
  children: React.ReactNode;
  'data-testid'?: string;
  onClose?: () => any;
  withPadding?: boolean;
  noScrollMaxHeight?: boolean;
}

export const SoloModal = (props: ModalProps) => {
  const {
    visible = true,
    width,
    minHeight,
    children,
    onClose,
    withPadding,
    'data-testid': testId,
    noScrollMaxHeight
  } = props;

  //
  // Keyboard Listeners
  //
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && !!onClose) onClose();
  };
  useEventListener(document, 'keydown', onKeydown, [onClose], !visible);

  //
  // Scroll Behavior
  //
  useEffect(() => {
    // Update the scroll behavior when the visibility changes.
    if (visible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [visible]);
  useEffect(() => {
    // Remove the scroll behavior when this component is unmounted.
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  //
  // Render
  //
  if (!visible) return null;
  return (
    <Styles.ModalWindow noScrollMaxHeight={noScrollMaxHeight} data-testid={testId ?? 'solo-modal'} onClick={onClose}>
      <Styles.BlockHolder onClick={(evt: React.SyntheticEvent) => evt.stopPropagation()}>
        <Styles.ModalBlock role='dialog' aria-modal='true' width={width} minHeight={minHeight}>
          {!!onClose && (
            <Styles.CloseXContainer onClick={onClose} aria-label='Close Modal'>
              <Asset.CloseX />
            </Styles.CloseXContainer>
          )}
          {withPadding ? <Styles.Body>{children}</Styles.Body> : children}
        </Styles.ModalBlock>
      </Styles.BlockHolder>
    </Styles.ModalWindow>
  );
};

export interface SoloModalHeaderProps {
  title: React.ReactNode;
  subtitle: React.ReactNode | React.ReactNode[];
  icon: React.ReactNode;
  status?: Status;
  rightContent?: React.ReactNode;
}
export const SoloModalHeader = ({ title, subtitle, icon, status, rightContent }: SoloModalHeaderProps) => {
  return (
    <Styles.Header.Container data-testid='modal-header'>
      <HealthIndicatorIcon size={60} status={status} icon={icon} />
      <Styles.Header.TextContainer>
        <Styles.Header.Title>{title}</Styles.Header.Title>
        <Styles.Header.Subtitle>{Array.isArray(subtitle) ? subtitle.join(' / ') : subtitle}</Styles.Header.Subtitle>
      </Styles.Header.TextContainer>
      {!!rightContent && <Styles.Header.RightContent>{rightContent}</Styles.Header.RightContent>}
    </Styles.Header.Container>
  );
};
