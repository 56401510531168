import { CardStyles } from 'Components/Common/Card';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloListCardDynamic } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import SoloTableTextEllipsesCell from 'Components/Common/SoloTableTextEllipsesCell';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { TruncateText } from 'Styles/CommonEmotions/text';
import { Tooltip } from 'antd';
import {
  InjaTemplate,
  TransformationTemplate,
  TransformationTemplate_RequestBodyParse
} from 'proto/github.com/solo-io/envoy-gloo/api/envoy/config/filter/http/transformation/v2/transformation_filter_pb';
import React, { useMemo } from 'react';
import { docLinks } from 'utils/url-external-links-map';
import { TransformationCodeModalBodyProps } from '../TransformationDetails';
import { TransformationDetailsStyles as Styles } from '../TransformationDetails.style';

const requestBodyParseMap: Record<TransformationTemplate_RequestBodyParse, string> = {
  [TransformationTemplate_RequestBodyParse.ParseAsJson]: 'JSON',
  [TransformationTemplate_RequestBodyParse.DontParse]: 'Not Parsed'
};

function boolToText(bool: boolean) {
  return bool ? 'True' : 'False';
}

function getTransformationTypeText(tt: TransformationTemplate) {
  // These three are all part of the same `body_transformation` oneof in proto
  return tt.bodyTransformation.oneofKind === 'body'
    ? 'Inja'
    : tt.bodyTransformation.oneofKind === 'passthrough'
    ? 'Passthrough'
    : 'Merge Extractors';
}

type ExtractorsTableFields = {
  key: string;
  name: string;
  extractFrom: string;
  header: string | undefined;
  regex: string;
  subgroup: number | undefined;
};

const extractorsColumns: SoloColumnsType<ExtractorsTableFields> = [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true,
    render(name) {
      return (
        <strong>
          <SoloTableTextEllipsesCell value={name} />
        </strong>
      );
    }
  },
  {
    title: 'Extract From',
    dataIndex: 'extractFrom'
  },
  {
    title: 'Header Name',
    dataIndex: 'header'
  },
  {
    title: 'Regex',
    dataIndex: 'regex'
  },
  {
    title: 'Subgroup',
    dataIndex: 'subgroup'
  }
];

const SimpleCode = ({ injaTemplate }: { injaTemplate: InjaTemplate }) => {
  return (
    <Styles.NumberlessCodeContainer>
      <FancyCodeDisplayer
        padLineNumbersWithZeros={2}
        includeBorderBackground={false}
        copyable={false}
        type='jinja2'
        contentString={injaTemplate.text}
      />
    </Styles.NumberlessCodeContainer>
  );
};

const CodeTextOrButton = ({
  code,
  onButtonClick
}: {
  code: InjaTemplate;
  onButtonClick: (code: InjaTemplate) => void;
}) => {
  return code.text.length < 100 ? (
    <SimpleCode injaTemplate={code} />
  ) : (
    <SoloLinkStyles.SoloLinkLooks onClick={() => onButtonClick(code)}>
      <strong>VIEW CODE &gt;</strong>
    </SoloLinkStyles.SoloLinkLooks>
  );
};

interface InjaTemplateCardProps {
  inja: TransformationTemplate;
  header: string;
  headerIcon: React.ReactNode;
  setCodeModalData: (props: TransformationCodeModalBodyProps) => void;
  recalculateRoutingDestination?: boolean;
}
export const InjaTemplateCard = ({
  inja,
  header,
  headerIcon,
  setCodeModalData,
  recalculateRoutingDestination
}: InjaTemplateCardProps) => {
  const extractorsTableData = useMemo<ExtractorsTableFields[]>(() => {
    return (
      Object.entries(inja.extractors).map(([key, ext]) => ({
        key,
        regex: ext.regex,
        subgroup: ext.subgroup,
        body: ext.source.oneofKind === 'body' ? ext.source.body : undefined,
        header: ext.source.oneofKind === 'header' ? ext.source.header : undefined,
        name: key,
        extractFrom: ext.source.oneofKind === 'body' ? 'Body' : 'Header'
      })) ?? []
    );
  }, [inja.extractors]);

  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>
        {header}
        <CardStyles.CardHeaderSecondaryInfoHolder>
          <SecondaryInfo
            items={[
              { label: 'Transformation Type', data: getTransformationTypeText(inja) },
              recalculateRoutingDestination !== undefined && {
                label: 'Recalcuate Routing Destination',
                data: boolToText(recalculateRoutingDestination)
              },
              {
                label: 'Advanced Templates',
                data: (
                  <>
                    {boolToText(inja?.advancedTemplates)}
                    <Tooltip
                      trigger='hover'
                      placement='bottom'
                      title={
                        <>
                          If true, uses a JSON pointer notation (e.g. "time/start") instead of dot notation (e.g.
                          "time.start") to access JSON elements.
                          <br />
                          <Styles.WhiteLink
                            href={docLinks.enterprise.transformation_policy_api_HASH.requesttransformation}>
                            VIEW DOCS &gt;
                          </Styles.WhiteLink>
                        </>
                      }>
                      <Styles.InfoIconSI />
                    </Tooltip>
                  </>
                )
              }
            ]}
          />
        </CardStyles.CardHeaderSecondaryInfoHolder>
        <CardStyles.CardHeaderRightIcon>{headerIcon}</CardStyles.CardHeaderRightIcon>
      </CardStyles.CardHeader>

      <Styles.CardSubheader>Extractors</Styles.CardSubheader>
      <SoloTable columns={extractorsColumns} dataSource={extractorsTableData} />

      <Spacer mt='20px'>
        <SoloListCardStyles.GridListCardsContainer columns={2}>
          <SoloListCardDynamic title='Override Headers'>
            {!!Object.entries(inja.headers).length && (
              <Styles.ColonListGrid>
                {Object.entries(inja.headers).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <TruncateText title={key}>
                      <strong>{key}:</strong>
                    </TruncateText>
                    <span>
                      <CodeTextOrButton
                        code={value}
                        onButtonClick={code =>
                          setCodeModalData({
                            code,
                            title: key,
                            subtitle: 'Request Transformation / Headers / Override'
                          })
                        }
                      />
                    </span>
                  </React.Fragment>
                ))}
              </Styles.ColonListGrid>
            )}
          </SoloListCardDynamic>
          <SoloListCardDynamic title='Append Headers'>
            {!!inja.headersToAppend.length && (
              <Styles.ColonListGrid>
                {inja.headersToAppend.map(({ key, value }) => (
                  <React.Fragment key={key}>
                    <TruncateText title={key}>
                      <strong>{key}:</strong>
                    </TruncateText>
                    <span>
                      {value && (
                        <CodeTextOrButton
                          code={value}
                          onButtonClick={code =>
                            setCodeModalData({
                              code,
                              title: key,
                              subtitle: 'Request Transformation / Headers / Append'
                            })
                          }
                        />
                      )}
                    </span>
                  </React.Fragment>
                ))}
              </Styles.ColonListGrid>
            )}
          </SoloListCardDynamic>
          <SoloListCardDynamic title='Body'>
            <Spacer mb='20px'>
              <SecondaryInfo
                items={[
                  { label: 'Buffer body', data: boolToText(inja.bodyTransformation.oneofKind !== 'passthrough') }, // Buffer Body is true if no passthrough
                  {
                    label: 'Body parsing behavior',
                    data: requestBodyParseMap[inja.parseBodyBehavior]
                  },
                  {
                    label: 'Ignore parsing errors',
                    data: boolToText(inja.ignoreErrorOnParse)
                  },
                  { label: 'Transformation type', data: 'Template' }, // Type is currently only possible to be this
                  inja.bodyTransformation.oneofKind === 'mergeExtractorsToBody' && {
                    label: 'Merge Extractors to Body',
                    data: 'True'
                  }
                ]}
              />
            </Spacer>
            {inja.bodyTransformation.oneofKind === 'body' &&
              (!!inja.bodyTransformation.body.text ? (
                <Styles.ColonListGrid labelWidth={60}>
                  <strong>Template:</strong>
                  <span>
                    <SimpleCode injaTemplate={inja.bodyTransformation.body} />
                  </span>
                </Styles.ColonListGrid>
              ) : (
                <SoloEmptySimple description='Body has an empty template' />
              ))}
          </SoloListCardDynamic>
          <SoloListCardDynamic title='Dynamic Metadata'>
            {inja?.dynamicMetadataValues.map(({ key, metadataNamespace, value }, i) => (
              <React.Fragment key={`${metadataNamespace}-${key}`}>
                {i !== 0 && <Styles.HorizontalRuleLight />}
                <Spacer mb='10px'>
                  <strong>{metadataNamespace}</strong> <SecondaryInfoItem label='Key' data={key} small />
                </Spacer>
                <Styles.ColonListGrid labelWidth={40}>
                  <strong>Value:</strong>
                  <span>{value && <SimpleCode injaTemplate={value} />}</span>
                </Styles.ColonListGrid>
              </React.Fragment>
            ))}
          </SoloListCardDynamic>
        </SoloListCardStyles.GridListCardsContainer>
      </Spacer>
    </CardStyles.Card>
  );
};
