/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/cloud_provider_options.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message common.gloo.solo.io.LambdaOptions
 */
export interface LambdaOptions {
    /**
     * @generated from protobuf field: common.gloo.solo.io.LambdaOptions.RequestTransformation request_transformation = 1;
     */
    requestTransformation: LambdaOptions_RequestTransformation;
    /**
     * @generated from protobuf field: common.gloo.solo.io.LambdaOptions.ResponseTransformation response_transformation = 2;
     */
    responseTransformation: LambdaOptions_ResponseTransformation;
    /**
     * Can be either Sync or Async. See [AWS Invoke](https://docs.aws.amazon.com/lambda/latest/dg/API_Invoke.html)
     * for more details.
     *
     * @generated from protobuf field: common.gloo.solo.io.LambdaOptions.InvocationStyle invocation_style = 3;
     */
    invocationStyle: LambdaOptions_InvocationStyle;
}
/**
 * @generated from protobuf enum common.gloo.solo.io.LambdaOptions.RequestTransformation
 */
export enum LambdaOptions_RequestTransformation {
    /**
     * Default value. include headers, querystring, request path, and request method in the event payload sent to aws lambda.
     *
     * @generated from protobuf enum value: REQUEST_DEFAULT = 0;
     */
    REQUEST_DEFAULT = 0,
    /**
     * Disable all transformations for the request to AWS Lambda.
     *
     * @generated from protobuf enum value: REQUEST_DISABLE = 1;
     */
    REQUEST_DISABLE = 1
}
/**
 * @generated from protobuf enum common.gloo.solo.io.LambdaOptions.ResponseTransformation
 */
export enum LambdaOptions_ResponseTransformation {
    /**
     * Default value. Unwrap the response as if the proxy was an AWS API Gateway. de-jsonify response bodies returned
     * from aws lambda, sets response status code, and sets response headers from the JSON payload.
     *
     * @generated from protobuf enum value: RESPONSE_DEFAULT = 0;
     */
    RESPONSE_DEFAULT = 0,
    /**
     * Disable all transformations for the response from AWS Lambda.
     *
     * @generated from protobuf enum value: RESPONSE_DISABLE = 1;
     */
    RESPONSE_DISABLE = 1
}
/**
 * @generated from protobuf enum common.gloo.solo.io.LambdaOptions.InvocationStyle
 */
export enum LambdaOptions_InvocationStyle {
    /**
     * @generated from protobuf enum value: SYNC = 0;
     */
    SYNC = 0,
    /**
     * @generated from protobuf enum value: ASYNC = 1;
     */
    ASYNC = 1
}
/**
 * @generated from protobuf message common.gloo.solo.io.CloudProviderFilterOptions
 */
export interface CloudProviderFilterOptions {
    /**
     * @generated from protobuf oneof: provider_options
     */
    providerOptions: {
        oneofKind: "aws";
        /**
         * Optional: filter out route tables that use AWS functionality, if provided. Each evaluated route must match at
         * at least one of every repeated field provided, if not empty.
         *
         * @generated from protobuf field: common.gloo.solo.io.AWSFilterOptions aws = 1;
         */
        aws: AWSFilterOptions;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message common.gloo.solo.io.AWSFilterOptions
 */
export interface AWSFilterOptions {
    /**
     * Optional: filter out route tables that use an AWS account ID which does not match the selector, if provided.
     *
     * @generated from protobuf field: repeated string account_ids = 1;
     */
    accountIds: string[];
    /**
     * Optional: filter out route tables that use IAM invoke roles which do not match the selector, if provided.
     * Regex supported.
     *
     * @generated from protobuf field: repeated string iam_roles = 2;
     */
    iamRoles: string[];
    /**
     * Optional: filter out route tables that use regions which do not match the selector, if provided.
     *
     * @generated from protobuf field: repeated string regions = 3;
     */
    regions: string[];
    /**
     * Optional: filter out route tables that use backend Lambda functions that do no match the selector,
     * if provided. Regex supported.
     *
     * @generated from protobuf field: repeated string lambda_functions = 4;
     */
    lambdaFunctions: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class LambdaOptions$Type extends MessageType<LambdaOptions> {
    constructor() {
        super("common.gloo.solo.io.LambdaOptions", [
            { no: 1, name: "request_transformation", kind: "enum", T: () => ["common.gloo.solo.io.LambdaOptions.RequestTransformation", LambdaOptions_RequestTransformation] },
            { no: 2, name: "response_transformation", kind: "enum", T: () => ["common.gloo.solo.io.LambdaOptions.ResponseTransformation", LambdaOptions_ResponseTransformation] },
            { no: 3, name: "invocation_style", kind: "enum", T: () => ["common.gloo.solo.io.LambdaOptions.InvocationStyle", LambdaOptions_InvocationStyle] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.LambdaOptions
 */
export const LambdaOptions = new LambdaOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloudProviderFilterOptions$Type extends MessageType<CloudProviderFilterOptions> {
    constructor() {
        super("common.gloo.solo.io.CloudProviderFilterOptions", [
            { no: 1, name: "aws", kind: "message", oneof: "providerOptions", T: () => AWSFilterOptions }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.CloudProviderFilterOptions
 */
export const CloudProviderFilterOptions = new CloudProviderFilterOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AWSFilterOptions$Type extends MessageType<AWSFilterOptions> {
    constructor() {
        super("common.gloo.solo.io.AWSFilterOptions", [
            { no: 1, name: "account_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "iam_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "regions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "lambda_functions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AWSFilterOptions
 */
export const AWSFilterOptions = new AWSFilterOptions$Type();
