const yaml = `apiVersion: admin.gloo.solo.io/v2
kind: WorkspaceSettings
metadata:
  name: anything
  namespace: bookinfo
spec:
  options:
    federation:
      enabled: true
      hostSuffix: global
      serviceSelector:
      - {}`;
export default yaml;
