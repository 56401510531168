export type ImageSource = HTMLImageElement['src'];

const imageCache: Record<ImageSource, HTMLImageElement> = {};

async function loadImage(source: ImageSource): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>(resolve => {
    if (imageCache[source]) {
      return resolve(imageCache[source]);
    }
    const img = document.createElement('img');

    img.onload = () => {
      imageCache[source] = img;
      resolve(img);
    };
    img.src = source;
  });
}

/**
 * Draws the image source provided.
 * Image drawn may be blank for a frame the first time it's used as
 * it is "loaded" into an img element suitable for drawing with.
 */
export function drawImage(
  ctx: CanvasRenderingContext2D,
  imageSource: ImageSource,
  x: number,
  y: number,
  width?: number,
  height?: number
) {
  const image = imageCache[imageSource];
  if (image) {
    return drawLoadedImage(ctx, image, x, y, width, height);
  } else {
    loadImage(imageSource).then(image => {
      drawLoadedImage(ctx, image, x, y, width, height);
    });
    return { height: width ?? 0, width: height ?? 0 };
  }
}
function drawLoadedImage(
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  x: number,
  y: number,
  width?: number,
  height?: number
) {
  width ??= image?.width as number;
  height ??= image?.height as number;
  ctx.drawImage(image, x, y, width, height);
  return { height, width };
}

/* Don't love that this is here, but it's the most
   relevant util and I don't feel this deserves its own util file */
export function cleanNumberDisplay(numberForDisplay: number, isPercentile?: boolean, units?: string): string {
  if (numberForDisplay === -1) {
    return '-';
  } else if (numberForDisplay === 0) {
    return '0' + (units ?? '');
  } else if (numberForDisplay < 0.1) {
    return '<0.1' + (units ?? '');
  }

  const fullValue = !!isPercentile ? numberForDisplay * 100 : numberForDisplay;

  return fullValue.toFixed(isPercentile || fullValue < 100 ? 1 : 0) + (units ?? '');
}
