import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { Svg } from 'Components/Common/Svg';
import { SoloButtonLinkExternal, SoloButtonSolidLinkExternal } from 'Styles/CommonEmotions/button';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { Asset } from 'assets';
import { DashboardLandingEmptyModalStyles as Styles } from './DashboardLandingEmptyModal.style';
import { docLinks } from 'utils/url-external-links-map';

const RegisterClusterModalBody = () => {
  return (
    <GridLayout templateColumns='40% 1fr' gap='20px' height='380px'>
      <FlexLayout vertical horizontal flexDirection='column' gap='20px'>
        <Styles.RegisteringIconHolder>
          <Svg asset={<Asset.ClusterIcon />} size={80} color='currentColor' />
        </Styles.RegisteringIconHolder>
        <Styles.RegisteringTitle>No Clusters Registered</Styles.RegisteringTitle>
        <Styles.RegisteringBody>
          To access your custom dashboard, you must register a cluster. You can choose between the options to the right.
        </Styles.RegisteringBody>
        <div>
          <SoloButtonLinkExternal href={docLinks.core.quickstart} target='_blank'>
            View Docs
          </SoloButtonLinkExternal>
        </div>
      </FlexLayout>
      <GridLayout templateRows='min-content auto auto' gap='20px'>
        <Styles.Title>Registering a Kubernetes Cluster</Styles.Title>
        <Styles.InstructionsArea>
          <Styles.InstructionsTitle>Option 1</Styles.InstructionsTitle>
          <p>Register a cluster that runs an existing Istio service mesh</p>
          <div>
            <SoloButtonSolidLinkExternal href={docLinks.core.quickstart_multi_HASH.data_plane} target='_blank'>
              ADD CLUSTER
            </SoloButtonSolidLinkExternal>
          </div>
        </Styles.InstructionsArea>
        <Styles.InstructionsArea>
          <Styles.InstructionsTitle>Option 2</Styles.InstructionsTitle>
          <p>
            Register a cluster and use the Istio lifecycle manager resource to deploy a managed Istio service mesh to
            the cluster
          </p>
          <div>
            <SoloButtonSolidLinkExternal href={docLinks.core.istio_mesh_ilm} target='_blank'>
              ADD CLUSTER
            </SoloButtonSolidLinkExternal>
          </div>
        </Styles.InstructionsArea>
      </GridLayout>
    </GridLayout>
  );
};

const NoInsightsModalBody = () => {
  return (
    <GridLayout height='380px'>
      <FlexLayout vertical horizontal flexDirection='column' gap='20px'>
        <Styles.RegisteringIconHolder>
          <Svg asset={<Asset.InsightAnalysisIcon />} size={80} color='currentColor' />
        </Styles.RegisteringIconHolder>
        <Styles.RegisteringTitle>Insights Engine Stopped</Styles.RegisteringTitle>
        <Styles.RegisteringBody>
          The insights engine is not running.
          <br />
          Dashboard disabled without Insights.{' '}
          <ExternalSoloLink href={docLinks.core.quickstart} withArrow>
            VIEW DOCS
          </ExternalSoloLink>
        </Styles.RegisteringBody>
        {/* 
        // TODO: Add docs links in
        <div>
          <SoloButtonLinkExternal href='/' target='_blank'>
            View Docs
          </SoloButtonLinkExternal>
        </div>
          */}
      </FlexLayout>
    </GridLayout>
  );
};

export const DashboardLandingEmptyModal = ({ isInsightsEngineRunning }: { isInsightsEngineRunning: boolean }) => {
  return (
    <SoloModal width={900} withPadding>
      {!isInsightsEngineRunning ? <NoInsightsModalBody /> : <RegisterClusterModalBody />}
    </SoloModal>
  );
};
