import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import { Asset } from 'assets';
import React, { useEffect, useMemo, useState } from 'react';
import { SoloCheckboxListStyles as Styles } from './SoloCheckboxList.style';
import { SoloInput } from './SoloInput';

function optionMatchesSearch<T extends ValueType>(opt: OptionType<T>, search: string) {
  const { value, label } = opt;
  search = search.toLowerCase();
  return (
    value.toString().toLowerCase().includes(search) ||
    (typeof label === 'string' && label.toLowerCase().includes(search))
  );
}

type ValueType = string | number;

export interface OptionType<T> {
  hidden?: boolean;
  disabled?: boolean;
  value: T;
  label: React.ReactNode;
}
interface SoloCheckboxListDropdownProps<T> {
  title: string;
  options: OptionType<T>[];
  values: T[];
  onChange: (newValues: T[]) => any;
  selectAll?: boolean;
  searchPlaceholder?: string;
  searchDisabled?: boolean;
  noSearch?: boolean;
}
export const SoloCheckboxListDropdown = <T extends ValueType>(props: SoloCheckboxListDropdownProps<T>) => {
  const { title, searchPlaceholder, searchDisabled, options, values, onChange, selectAll, noSearch } = props;

  const allValues = useMemo(() => options.map(v => v.value), [options]);
  const sortedOptions = useMemo(() => {
    return options.sort((a, b) => (a.label + '').localeCompare(b.label + ''));
  }, [options]);

  //
  // This component uses the updated antd API for dropdown menu items.
  // See docs here for antd v4.x: https://4x.ant.design/components/dropdown/
  //
  const [searchString, setSearchString] = useState('');
  const dropdownMenuItems = useMemo(() => {
    const newDropdownMenuItems: MenuProps['items'] = [];
    const checkedAll = options.length === values.length;
    const someButNotAllChecked = !!values.length && values.length < options.length;
    if (!noSearch) {
      newDropdownMenuItems.push({
        key: 'dropdown-search',
        label: (
          <Styles.SearchWrap>
            <SoloInput
              autoComplete='off'
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              placeholder={searchPlaceholder ?? 'Search...'}
              disabled={searchDisabled}
            />
            <Asset.MagnifyingGlass />
          </Styles.SearchWrap>
        )
      });
    }
    // We hide "Select All" during a search to avoid confusion on expected behavior and to avoid bugs
    const shouldSelectAll = selectAll && !searchString;
    if (shouldSelectAll) {
      newDropdownMenuItems.push({
        key: 'select-all',
        onClick: info => {
          info.domEvent.preventDefault();
          onChange(checkedAll ? [] : allValues);
        },
        label: (
          <Checkbox tabIndex={0} indeterminate={someButNotAllChecked} checked={checkedAll}>
            {checkedAll ? 'Des' : 'S'}elect All
          </Checkbox>
        )
      });
    }
    // This adds the sorted options to the dropdown,
    // skipping ones that don't match the search.
    for (let i = 0; i < sortedOptions.length; i++) {
      const o = sortedOptions[i];
      if (!!searchString && !optionMatchesSearch(o, searchString)) {
        continue;
      }
      const valueIsChecked = values.find(v => v === o.value) !== undefined;
      newDropdownMenuItems.push({
        key: o.value,
        onClick: info => {
          info.domEvent.preventDefault();
          if (valueIsChecked) {
            onChange(values.filter(v => v !== o.value));
          } else {
            onChange([...values, o.value]);
          }
        },
        label: (
          <Checkbox value={o.value} checked={valueIsChecked} aria-checked={valueIsChecked}>
            {o.label}
          </Checkbox>
        )
      });
    }
    return newDropdownMenuItems;
  }, [noSearch, values, allValues, sortedOptions, searchString]);

  //
  // The nested input elements within the dropdown menu don't get focused normally with keyboard input.
  // Instead, antd makes the <li> elements receive focus, which is very unhelpful for screen readers,
  // since the <li> doesn't have the checked/unchecked information on it.
  //
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownContainerEl, setDropdownContainerEl] = useState<HTMLSpanElement | null>(null);
  useEffect(() => {
    const dropdownEl = dropdownContainerEl?.querySelector('ul');
    const liEls = dropdownEl?.querySelectorAll('li.ant-dropdown-menu-item') as HTMLLIElement[] | undefined;
    if (!liEls) {
      return;
    }
    // This focuses the first input element when the dropdown is initially focused.
    const dropdownFocusFirstInput = () => dropdownEl?.querySelector('input')?.focus();
    dropdownEl?.addEventListener('focus', dropdownFocusFirstInput);

    // The code below modifies the behavior of antd's dropdown so if a <li> element is focused,
    // the nested input element is attempted to be focused instead, which gives a better experience.
    const makeFocusInputListener = (liEl: HTMLLIElement) => {
      return () => liEl?.querySelector('input')?.focus();
    };
    const listeners: ReturnType<typeof makeFocusInputListener>[] = [];
    for (let i = 0; i < liEls.length; i++) {
      const listener = makeFocusInputListener(liEls[i]);
      listeners.push(listener);
      liEls[i].addEventListener('focus', listener);
    }
    return () => {
      dropdownEl?.removeEventListener('focus', dropdownFocusFirstInput);
      for (let i = 0; i < liEls.length && i < listeners.length; i++) {
        liEls[i].removeEventListener('focus', listeners[i]);
      }
    };
  }, [dropdownContainerEl, isDropdownOpen, noSearch]);

  return (
    <Styles.SelectWrap
      data-testid={`${title.toLowerCase()}-dropdown`}
      ref={
        // Setting the element here using state causes the component to re-render,
        // which is needed for the dropdown to use it in `getPopupContainer` below.
        ref => setDropdownContainerEl(ref)
      }>
      {dropdownContainerEl && (
        <Dropdown
          placement='bottom'
          getPopupContainer={() => dropdownContainerEl}
          trigger={['click']}
          menu={{ items: dropdownMenuItems }}
          open={isDropdownOpen}
          onOpenChange={newIsOpen => {
            setIsDropdownOpen(newIsOpen);
          }}
          dropdownRender={originalDropdown => (
            <div data-testid={`${title.toLowerCase()}-dropdown-content`}>
              <Styles.OptionsList data-testid={'checkbox-options-list'}>{originalDropdown}</Styles.OptionsList>
            </div>
          )}>
          <Styles.DropdownTrigger>
            {title} <DownOutlined aria-hidden={true} />
          </Styles.DropdownTrigger>
        </Dropdown>
      )}
    </Styles.SelectWrap>
  );
};
