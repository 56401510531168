import { grpcWebFetchTransport, soloGrpcCall, useRequestSkipRef } from 'Api/helpers';
import {
  GetPortalApiDetailsRequest,
  GetPortalDetailsRequest,
  GetSchemaRequest
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/portal_pb';
import { PortalApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/portal_pb.client';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { capitalizeFirstLetters } from 'utils/helpers';

export namespace portalApi {
  const client = new PortalApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.PortalApi/' + capitalizeFirstLetters(fn.name);

  ////////////////////////////////
  // Get Portal API Details
  ////////////////////////////////
  export function getPortalApiDetails(routeTableRef: ClusterObjectRef | undefined) {
    const request: GetPortalApiDetailsRequest = { routeTableRef };
    return soloGrpcCall(client.getPortalApiDetails(request));
  }
  export function useGetPortalApiDetails(...args: Parameters<typeof getPortalApiDetails>) {
    return useRequestSkipRef(getPortalApiDetails, args, getMethodDescriptorName(client.getPortalApiDetails));
  }

  ////////////////////////////////
  // Get Schema
  ////////////////////////////////
  export function getSchema(routeTableRef: ClusterObjectRef | undefined) {
    const request: GetSchemaRequest = { routeTableRef };
    return soloGrpcCall(client.getSchema(request));
  }
  export function useGetSchema(...args: Parameters<typeof getSchema>) {
    return useRequestSkipRef(getSchema, args, getMethodDescriptorName(client.getSchema));
  }

  ////////////////////////////////
  // Get Schema
  ////////////////////////////////
  export function getPortalDetails(portalRef: ClusterObjectRef | undefined) {
    const request: GetPortalDetailsRequest = { portalRef };
    return soloGrpcCall(client.getPortalDetails(request));
  }
  export function useGetPortalDetails(...args: Parameters<typeof getPortalDetails>) {
    return useRequestSkipRef(getPortalDetails, args, getMethodDescriptorName(client.getPortalDetails));
  }
}
