import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { State, Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { convertHealthState, getStatusColors } from 'utils/health-status';
import { MarkdownRenderer } from './MarkdownRenderer';

namespace SmallErrorXStyles {
  export const ErrorDetails = styled.div<{ state: State }>(({ state }) => {
    const statusColors = getStatusColors(state);
    return css`
      border: 1px solid ${statusColors.border};
      border-radius: 4px;
      background: ${statusColors.background};
      padding: 6px;

      color: ${statusColors.text};
    `;
  });

  export const DeclarationRow = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;

    ${svgBasics(14)}
    svg {
      margin-right: 6px;
    }
  `;

  export const IssuesList = styled.ul`
    > div {
      position: relative;
      padding-left: 34px;
      line-height: 19px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 22px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: currentColor;
      }
    }
  `;

  export const Trigger = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    ${svgBasics(14)}
  `;
}

const TooltipContent = ({ status }: { status: Status }) => {
  const { state: originalState, validationErrors: errors } = status;
  const state = convertHealthState(originalState);
  const inPendingStateWithNoMessage = originalState === State.PENDING && status.validationErrors.length === 0;

  return (
    <SmallErrorXStyles.ErrorDetails state={state}>
      <SmallErrorXStyles.DeclarationRow>
        {state === State.WARNING ? <Asset.WarningCircleIconDarker /> : <Asset.ErrorCircleIcon />}
        {inPendingStateWithNoMessage ? (
          'No Status Detected'
        ) : (
          <>
            {errors.length} {state === State.WARNING ? 'Warning' : 'Error'}
            {errors.length === 1 ? '' : 's'} Detected
          </>
        )}
      </SmallErrorXStyles.DeclarationRow>
      <SmallErrorXStyles.IssuesList>
        {errors.map((valErr, ind) => (
          <li key={valErr + ind}>
            <MarkdownRenderer text={valErr} inline />
          </li>
        ))}
      </SmallErrorXStyles.IssuesList>
    </SmallErrorXStyles.ErrorDetails>
  );
};

interface ErrorProps {
  status?: Status;
}

export const SmallErrorXList = ({ status }: ErrorProps) => {
  const state = convertHealthState(status?.state);
  if (!status || state === State.ACCEPTED) {
    return null;
  }

  return (
    <Tooltip
      title={<TooltipContent status={status} />}
      trigger='hover'
      placement='left'
      overlayClassName='soloTooltip errorColors'>
      <SmallErrorXStyles.Trigger data-testid='tooltip-exit'>
        {state === State.WARNING ? <Asset.WarningCircleIconDarker /> : <Asset.ErrorCircleIcon />}
      </SmallErrorXStyles.Trigger>
    </Tooltip>
  );
};
