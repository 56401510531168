/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/config/core/v3/config_source.proto" (package "envoy.config.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Authority } from "../../../../xds/core/v3/authority_pb";
import { DoubleValue } from "../../../../google/protobuf/wrappers_pb";
import { UInt32Value } from "../../../../google/protobuf/wrappers_pb";
import { Duration } from "../../../../google/protobuf/duration_pb";
import { GrpcService } from "./grpc_service_pb";
/**
 * API configuration source. This identifies the API type and cluster that Envoy
 * will use to fetch an xDS API.
 * [#next-free-field: 9]
 *
 * @generated from protobuf message envoy.config.core.v3.ApiConfigSource
 */
export interface ApiConfigSource {
    /**
     * API type (gRPC, REST, delta gRPC)
     *
     * @generated from protobuf field: envoy.config.core.v3.ApiConfigSource.ApiType api_type = 1;
     */
    apiType: ApiConfigSource_ApiType;
    /**
     * API version for xDS transport protocol. This describes the xDS gRPC/REST
     * endpoint and version of [Delta]DiscoveryRequest/Response used on the wire.
     *
     * @generated from protobuf field: envoy.config.core.v3.ApiVersion transport_api_version = 8;
     */
    transportApiVersion: ApiVersion;
    /**
     * Cluster names should be used only with REST. If > 1
     * cluster is defined, clusters will be cycled through if any kind of failure
     * occurs.
     *
     * .. note::
     *
     *  The cluster with name ``cluster_name`` must be statically defined and its
     *  type must not be ``EDS``.
     *
     * @generated from protobuf field: repeated string cluster_names = 2;
     */
    clusterNames: string[];
    /**
     * Multiple gRPC services be provided for GRPC. If > 1 cluster is defined,
     * services will be cycled through if any kind of failure occurs.
     *
     * @generated from protobuf field: repeated envoy.config.core.v3.GrpcService grpc_services = 4;
     */
    grpcServices: GrpcService[];
    /**
     * For REST APIs, the delay between successive polls.
     *
     * @generated from protobuf field: google.protobuf.Duration refresh_delay = 3;
     */
    refreshDelay?: Duration;
    /**
     * For REST APIs, the request timeout. If not set, a default value of 1s will be used.
     *
     * @generated from protobuf field: google.protobuf.Duration request_timeout = 5;
     */
    requestTimeout?: Duration;
    /**
     * For GRPC APIs, the rate limit settings. If present, discovery requests made by Envoy will be
     * rate limited.
     *
     * @generated from protobuf field: envoy.config.core.v3.RateLimitSettings rate_limit_settings = 6;
     */
    rateLimitSettings?: RateLimitSettings;
    /**
     * Skip the node identifier in subsequent discovery requests for streaming gRPC config types.
     *
     * @generated from protobuf field: bool set_node_on_first_message_only = 7;
     */
    setNodeOnFirstMessageOnly: boolean;
}
/**
 * APIs may be fetched via either REST or gRPC.
 *
 * @generated from protobuf enum envoy.config.core.v3.ApiConfigSource.ApiType
 */
export enum ApiConfigSource_ApiType {
    /**
     * Ideally this would be 'reserved 0' but one can't reserve the default
     * value. Instead we throw an exception if this is ever used.
     *
     * @deprecated
     * @generated from protobuf enum value: DEPRECATED_AND_UNAVAILABLE_DO_NOT_USE = 0 [deprecated = true];
     */
    DEPRECATED_AND_UNAVAILABLE_DO_NOT_USE = 0,
    /**
     * REST-JSON v2 API. The `canonical JSON encoding
     * <https://developers.google.com/protocol-buffers/docs/proto3#json>`_ for
     * the v2 protos is used.
     *
     * @generated from protobuf enum value: REST = 1;
     */
    REST = 1,
    /**
     * SotW gRPC service.
     *
     * @generated from protobuf enum value: GRPC = 2;
     */
    GRPC = 2,
    /**
     * Using the delta xDS gRPC service, i.e. DeltaDiscovery{Request,Response}
     * rather than Discovery{Request,Response}. Rather than sending Envoy the entire state
     * with every update, the xDS server only sends what has changed since the last update.
     *
     * @generated from protobuf enum value: DELTA_GRPC = 3;
     */
    DELTA_GRPC = 3,
    /**
     * SotW xDS gRPC with ADS. All resources which resolve to this configuration source will be
     * multiplexed on a single connection to an ADS endpoint.
     * [#not-implemented-hide:]
     *
     * @generated from protobuf enum value: AGGREGATED_GRPC = 5;
     */
    AGGREGATED_GRPC = 5,
    /**
     * Delta xDS gRPC with ADS. All resources which resolve to this configuration source will be
     * multiplexed on a single connection to an ADS endpoint.
     * [#not-implemented-hide:]
     *
     * @generated from protobuf enum value: AGGREGATED_DELTA_GRPC = 6;
     */
    AGGREGATED_DELTA_GRPC = 6
}
/**
 * Aggregated Discovery Service (ADS) options. This is currently empty, but when
 * set in :ref:`ConfigSource <envoy_api_msg_config.core.v3.ConfigSource>` can be used to
 * specify that ADS is to be used.
 *
 * @generated from protobuf message envoy.config.core.v3.AggregatedConfigSource
 */
export interface AggregatedConfigSource {
}
/**
 * [#not-implemented-hide:]
 * Self-referencing config source options. This is currently empty, but when
 * set in :ref:`ConfigSource <envoy_api_msg_config.core.v3.ConfigSource>` can be used to
 * specify that other data can be obtained from the same server.
 *
 * @generated from protobuf message envoy.config.core.v3.SelfConfigSource
 */
export interface SelfConfigSource {
    /**
     * API version for xDS transport protocol. This describes the xDS gRPC/REST
     * endpoint and version of [Delta]DiscoveryRequest/Response used on the wire.
     *
     * @generated from protobuf field: envoy.config.core.v3.ApiVersion transport_api_version = 1;
     */
    transportApiVersion: ApiVersion;
}
/**
 * Rate Limit settings to be applied for discovery requests made by Envoy.
 *
 * @generated from protobuf message envoy.config.core.v3.RateLimitSettings
 */
export interface RateLimitSettings {
    /**
     * Maximum number of tokens to be used for rate limiting discovery request calls. If not set, a
     * default value of 100 will be used.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value max_tokens = 1;
     */
    maxTokens?: UInt32Value;
    /**
     * Rate at which tokens will be filled per second. If not set, a default fill rate of 10 tokens
     * per second will be used.
     *
     * @generated from protobuf field: google.protobuf.DoubleValue fill_rate = 2;
     */
    fillRate?: DoubleValue;
}
/**
 * Configuration for :ref:`listeners <config_listeners>`, :ref:`clusters
 * <config_cluster_manager>`, :ref:`routes
 * <envoy_api_msg_config.route.v3.RouteConfiguration>`, :ref:`endpoints
 * <arch_overview_service_discovery>` etc. may either be sourced from the
 * filesystem or from an xDS API source. Filesystem configs are watched with
 * inotify for updates.
 * [#next-free-field: 8]
 *
 * @generated from protobuf message envoy.config.core.v3.ConfigSource
 */
export interface ConfigSource {
    /**
     * Authorities that this config source may be used for. An authority specified in a xdstp:// URL
     * is resolved to a *ConfigSource* prior to configuration fetch. This field provides the
     * association between authority name and configuration source.
     * [#not-implemented-hide:]
     *
     * @generated from protobuf field: repeated xds.core.v3.Authority authorities = 7;
     */
    authorities: Authority[];
    /**
     * @generated from protobuf oneof: config_source_specifier
     */
    configSourceSpecifier: {
        oneofKind: "path";
        /**
         * Path on the filesystem to source and watch for configuration updates.
         * When sourcing configuration for :ref:`secret <envoy_api_msg_extensions.transport_sockets.tls.v3.Secret>`,
         * the certificate and key files are also watched for updates.
         *
         * .. note::
         *
         *  The path to the source must exist at config load time.
         *
         * .. note::
         *
         *   Envoy will only watch the file path for *moves.* This is because in general only moves
         *   are atomic. The same method of swapping files as is demonstrated in the
         *   :ref:`runtime documentation <config_runtime_symbolic_link_swap>` can be used here also.
         *
         * @generated from protobuf field: string path = 1;
         */
        path: string;
    } | {
        oneofKind: "apiConfigSource";
        /**
         * API configuration source.
         *
         * @generated from protobuf field: envoy.config.core.v3.ApiConfigSource api_config_source = 2;
         */
        apiConfigSource: ApiConfigSource;
    } | {
        oneofKind: "ads";
        /**
         * When set, ADS will be used to fetch resources. The ADS API configuration
         * source in the bootstrap configuration is used.
         *
         * @generated from protobuf field: envoy.config.core.v3.AggregatedConfigSource ads = 3;
         */
        ads: AggregatedConfigSource;
    } | {
        oneofKind: "self";
        /**
         * [#not-implemented-hide:]
         * When set, the client will access the resources from the same server it got the
         * ConfigSource from, although not necessarily from the same stream. This is similar to the
         * :ref:`ads<envoy_api_field.ConfigSource.ads>` field, except that the client may use a
         * different stream to the same server. As a result, this field can be used for things
         * like LRS that cannot be sent on an ADS stream. It can also be used to link from (e.g.)
         * LDS to RDS on the same server without requiring the management server to know its name
         * or required credentials.
         * [#next-major-version: In xDS v3, consider replacing the ads field with this one, since
         * this field can implicitly mean to use the same stream in the case where the ConfigSource
         * is provided via ADS and the specified data can also be obtained via ADS.]
         *
         * @generated from protobuf field: envoy.config.core.v3.SelfConfigSource self = 5;
         */
        self: SelfConfigSource;
    } | {
        oneofKind: undefined;
    };
    /**
     * When this timeout is specified, Envoy will wait no longer than the specified time for first
     * config response on this xDS subscription during the :ref:`initialization process
     * <arch_overview_initialization>`. After reaching the timeout, Envoy will move to the next
     * initialization phase, even if the first config is not delivered yet. The timer is activated
     * when the xDS API subscription starts, and is disarmed on first config update or on error. 0
     * means no timeout - Envoy will wait indefinitely for the first xDS config (unless another
     * timeout applies). The default is 15s.
     *
     * @generated from protobuf field: google.protobuf.Duration initial_fetch_timeout = 4;
     */
    initialFetchTimeout?: Duration;
    /**
     * API version for xDS resources. This implies the type URLs that the client
     * will request for resources and the resource type that the client will in
     * turn expect to be delivered.
     *
     * @generated from protobuf field: envoy.config.core.v3.ApiVersion resource_api_version = 6;
     */
    resourceApiVersion: ApiVersion;
}
// [#protodoc-title: Configuration sources]

/**
 * xDS API and non-xDS services version. This is used to describe both resource and transport
 * protocol versions (in distinct configuration fields).
 *
 * @generated from protobuf enum envoy.config.core.v3.ApiVersion
 */
export enum ApiVersion {
    /**
     * When not specified, we assume v2, to ease migration to Envoy's stable API
     * versioning. If a client does not support v2 (e.g. due to deprecation), this
     * is an invalid value.
     *
     * @deprecated
     * @generated from protobuf enum value: AUTO = 0 [deprecated = true];
     */
    AUTO = 0,
    /**
     * Use xDS v2 API.
     *
     * @deprecated
     * @generated from protobuf enum value: V2 = 1 [deprecated = true];
     */
    V2 = 1,
    /**
     * Use xDS v3 API.
     *
     * @generated from protobuf enum value: V3 = 2;
     */
    V3 = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class ApiConfigSource$Type extends MessageType<ApiConfigSource> {
    constructor() {
        super("envoy.config.core.v3.ApiConfigSource", [
            { no: 1, name: "api_type", kind: "enum", T: () => ["envoy.config.core.v3.ApiConfigSource.ApiType", ApiConfigSource_ApiType], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 8, name: "transport_api_version", kind: "enum", T: () => ["envoy.config.core.v3.ApiVersion", ApiVersion], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 2, name: "cluster_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "grpc_services", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GrpcService },
            { no: 3, name: "refresh_delay", kind: "message", T: () => Duration },
            { no: 5, name: "request_timeout", kind: "message", T: () => Duration, options: { "validate.rules": { duration: { gt: {} } } } },
            { no: 6, name: "rate_limit_settings", kind: "message", T: () => RateLimitSettings },
            { no: 7, name: "set_node_on_first_message_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.ApiConfigSource" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.ApiConfigSource
 */
export const ApiConfigSource = new ApiConfigSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AggregatedConfigSource$Type extends MessageType<AggregatedConfigSource> {
    constructor() {
        super("envoy.config.core.v3.AggregatedConfigSource", [], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.AggregatedConfigSource" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.AggregatedConfigSource
 */
export const AggregatedConfigSource = new AggregatedConfigSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SelfConfigSource$Type extends MessageType<SelfConfigSource> {
    constructor() {
        super("envoy.config.core.v3.SelfConfigSource", [
            { no: 1, name: "transport_api_version", kind: "enum", T: () => ["envoy.config.core.v3.ApiVersion", ApiVersion], options: { "validate.rules": { enum: { definedOnly: true } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.SelfConfigSource" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.SelfConfigSource
 */
export const SelfConfigSource = new SelfConfigSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimitSettings$Type extends MessageType<RateLimitSettings> {
    constructor() {
        super("envoy.config.core.v3.RateLimitSettings", [
            { no: 1, name: "max_tokens", kind: "message", T: () => UInt32Value },
            { no: 2, name: "fill_rate", kind: "message", T: () => DoubleValue, options: { "validate.rules": { double: { gt: 0 } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.RateLimitSettings" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.RateLimitSettings
 */
export const RateLimitSettings = new RateLimitSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfigSource$Type extends MessageType<ConfigSource> {
    constructor() {
        super("envoy.config.core.v3.ConfigSource", [
            { no: 7, name: "authorities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Authority },
            { no: 1, name: "path", kind: "scalar", oneof: "configSourceSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "api_config_source", kind: "message", oneof: "configSourceSpecifier", T: () => ApiConfigSource },
            { no: 3, name: "ads", kind: "message", oneof: "configSourceSpecifier", T: () => AggregatedConfigSource },
            { no: 5, name: "self", kind: "message", oneof: "configSourceSpecifier", T: () => SelfConfigSource },
            { no: 4, name: "initial_fetch_timeout", kind: "message", T: () => Duration },
            { no: 6, name: "resource_api_version", kind: "enum", T: () => ["envoy.config.core.v3.ApiVersion", ApiVersion], options: { "validate.rules": { enum: { definedOnly: true } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.ConfigSource" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.ConfigSource
 */
export const ConfigSource = new ConfigSource$Type();
