import { css } from '@emotion/react';
import styled from '@emotion/styled';

export namespace GatewaysLandingStyles {
  export const Hosts = styled.div(
    ({ theme }) => css`
      color: ${theme.augustGrey};
    `
  );
}
