import React from 'react';
import { StatMiniCardStyles as Styles } from './StatMiniCard.style';

interface StatMiniCardProps {
  icon?: React.ReactNode;
  title: string;
  data: React.ReactNode;
  right?: React.ReactNode;
}
export const StatMiniCard = ({ icon, title, data, right }: StatMiniCardProps) => {
  return (
    <Styles.Item data-testid='stat-mini-card' hasRight={!!right}>
      <Styles.Icon>{icon}</Styles.Icon>
      <Styles.Title>{title}</Styles.Title>
      {!!right && (
        <Styles.RightData>
          <div>{right}</div>
        </Styles.RightData>
      )}
      <div>{data}</div>
    </Styles.Item>
  );
}

interface StatMiniCardListProps {
  items: (StatMiniCardProps | false)[];
}
export const StatMiniCardList = ({ items }: StatMiniCardListProps) => {
  return (
    <Styles.List data-testid='stat-mini-card-list'>
      {items
        .filter((i): i is StatMiniCardProps => !!i)
        .map(item => (
          <StatMiniCard key={item.title} {...item} />
        ))}
    </Styles.List>
  );
}
