import { CardStyles } from 'Components/Common/Card';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloTable } from 'Components/Common/SoloTable';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { useContext } from 'react';
import { getExecutableSchema, getProxied, getResolved, getStitchedSchema } from 'utils/graphql-helpers';
import { docLinks } from 'utils/url-external-links-map';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlDetailsStyles, GqlDetailsStyles as Styles } from './GqlDetails.style';

const noSettingsPlaceholder = (
  <SoloEmptyWithDocs
    icon={<Asset.SettingsGear />}
    title='There are no settings to display'
    description=''
    href={docLinks.gateway.graphql}
  />
);

const GqlSettingsCard = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api } = gqlCtx;
  const stitchedSchema = getStitchedSchema(api);
  const stitchedSettings = stitchedSchema?.settings;

  const executableSchema = getExecutableSchema(api);
  const resolvedSettings = getResolved(executableSchema)?.settings;
  const proxiedSettings = getProxied(executableSchema)?.settings;

  //
  // Render
  //
  if (!resolvedSettings && !stitchedSettings && !proxiedSettings) return null;
  return (
    <CardStyles.Card data-testid='gql-settings-card'>
      <Styles.StyledCardHeader>
        <Spacer mr={5}>Settings</Spacer>
        <CardStyles.CardHeaderRightIcon>
          <Asset.SettingsGear />
        </CardStyles.CardHeaderRightIcon>
      </Styles.StyledCardHeader>

      {!!resolvedSettings && (
        //
        // Resolved Settings
        //
        <SoloTable
          removeHorizontalPadding
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              render: value => <GqlDetailsStyles.StyledSubDetailValue>{value}</GqlDetailsStyles.StyledSubDetailValue>
            },
            { title: 'Value', dataIndex: 'value' }
          ]}
          dataSource={[
            {
              key: '1',
              name: 'Introspection Enabled',
              value: resolvedSettings.enableIntrospection ? 'True' : 'False'
            },
            { key: '2', name: 'Max Query Depth', value: resolvedSettings.maxDepth }
          ]}
          data-testid='gql-resolved-settings-table'
          renderEmpty={noSettingsPlaceholder}
        />
      )}

      {!!proxiedSettings && (
        //
        // Proxied Settings
        //
        <SoloTable
          removeHorizontalPadding
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              render: value => <GqlDetailsStyles.StyledSubDetailValue>{value}</GqlDetailsStyles.StyledSubDetailValue>
            },
            { title: 'Value', dataIndex: 'value' }
          ]}
          dataSource={[
            {
              key: '1',
              name: 'Introspection Enabled',
              value: proxiedSettings.enableIntrospection ? 'True' : 'False'
            }
          ]}
          data-testid='gql-proxied-settings-table'
          renderEmpty={noSettingsPlaceholder}
        />
      )}

      {!!stitchedSettings && (
        //
        // Stitched Settings
        //
        <SoloTable
          removeHorizontalPadding
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              render: value => <GqlDetailsStyles.StyledSubDetailValue>{value}</GqlDetailsStyles.StyledSubDetailValue>
            },
            { title: 'Value', dataIndex: 'value' }
          ]}
          dataSource={[
            {
              key: '0',
              name: 'Introspection Enabled',
              value: stitchedSettings.enableIntrospection ? 'True' : 'False'
            }
          ]}
          data-testid='gql-stitched-settings-table'
          renderEmpty={noSettingsPlaceholder}
        />
      )}
    </CardStyles.Card>
  );
};

export default GqlSettingsCard;
