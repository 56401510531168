import { CardStyles } from 'Components/Common/Card';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { makeClusterObjectRef } from 'utils/helpers';
import { getRouteTypeFromId } from 'utils/types';
import { RouteTableDetails } from './Details/RouteTableDetails';
import { VirtualGatewayDetails } from './Details/VirtualGatewayDetails';

const detailsMap: Record<RouteType, typeof VirtualGatewayDetails | undefined> = {
  [RouteType.UNKNOWN_ROUTE_TYPE]: undefined,
  [RouteType.VIRTUAL_GATEWAY]: VirtualGatewayDetails,
  [RouteType.ROUTE_TABLE]: RouteTableDetails
};

export function RouteDetailsLanding() {
  di(useParams);
  const { routeType: routeTypeId, routeName, routeNamespace, routeCluster } = useParams();
  const clusterObjRef = makeClusterObjectRef(routeName, routeNamespace, routeCluster);

  if (!clusterObjRef) {
    return (
      <CardStyles.Card>
        <CardStyles.CardHeader>Unknown Route Reference</CardStyles.CardHeader>
        Some or all of the provided cluster reference is unknown:
        {[routeName, routeNamespace, routeCluster].map(s => s ?? 'unknown').join('.')}
      </CardStyles.Card>
    );
  }

  const routeType = getRouteTypeFromId(routeTypeId);
  const DetailsComponent = detailsMap[routeType!];
  if (DetailsComponent) {
    return <DetailsComponent clusterObjRef={clusterObjRef} />;
  }
  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>Unknown Route Type</CardStyles.CardHeader>
      Route type '{routeTypeId ?? 'unknown'}' is not currently supported by Gloo Platform UI.
    </CardStyles.Card>
  );
}
