import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from '../insight-codes';
import { CiliumHealthCheckSectionContent } from './CiliumHealthCheckSectionContent';

export const CiliumHealthCheckSection = () => {
  di(useFilteredDashboardInsights);
  const ciliumAgent = useFilteredDashboardInsights(dashboardHealthCodesMap, DashboardHealthKey.ciliumAgent);
  const ciliumOperator = useFilteredDashboardInsights(dashboardHealthCodesMap, DashboardHealthKey.ciliumOperator);
  const ciliumHubble = useFilteredDashboardInsights(dashboardHealthCodesMap, DashboardHealthKey.ciliumHubble);

  return (
    <CiliumHealthCheckSectionContent
      ciliumAgentInsights={ciliumAgent}
      ciliumOperatorInsights={ciliumOperator}
      ciliumHubbleInsights={ciliumHubble}
    />
  );
};
