import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { DataErrorStringList } from 'Components/Common/DataError';
import { SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import SoloLabelInput from 'Components/Common/Input/SoloLabelInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { isTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { OverviewTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { graphValidTimeIntervalList, useGetGraphCache } from 'Components/Features/Graph/General/graph-cache';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useLocation } from 'react-router-dom';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { parseServicesSearchParams } from 'utils/url-builders';
import { ServicesLandingBody } from './ServicesLandingBody';
const { useListServices } = overviewApi;

type TypeFilterApiType = 'in-mesh' | 'out-of-mesh' | 'all';

export const ServicesLanding = () => {
  di(useListServices);
  const routerLocation = useLocation();
  const { cache, updateCache } = useGetGraphCache();
  const [isTable] = useState(isTableViewPreferred());
  const [textFilter, setTextFilter] = useState('');
  const [labelKeyFilter, setLabelKeyFilter] = useState('');
  const [labelValueFilter, setLabelValueFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const [typeFilter, setTypeFilter] = useState<TypeFilterApiType>('all');
  const [window, setWindow] = useState<number>(cache.timeInterval);
  const filtersOn =
    !!textFilter.length || !!typeFilter || !!statusFilter.length || !!labelKeyFilter || !!labelValueFilter;

  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging(OverviewTablePageSize);

  const { data: services, error: servicesError } = useListServices(
    apiPaginationObject,
    statusFilter,
    textFilter,
    typeFilter,
    labelKeyFilter,
    labelValueFilter,
    { seconds: window, nanos: 0 }
  );
  const isLoading = services === undefined;

  useSetPagingTotal(services?.filteredTotalServices);
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(services);

  useEffect(() => {
    const params = parseServicesSearchParams(routerLocation.search);
    if (params.type) {
      setTypeFilter(params.type);
    }
  }, [routerLocation.search]);

  useEffect(() => {
    updateCache({ timeInterval: window });
  }, [window]);

  return (
    <OverviewStyles.Container data-testid='services-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.ServiceSvcIcon />}
          Services
        </OverviewStyles.Header.Title>
        {!servicesError && (
          <OverviewStyles.Header.FiltersWrapper>
            <HealthCountBox
              onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
              onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
              isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
              isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
              totalCount={services?.totalServices}
              errorCount={services?.serviceErrors}
            />
            <OverviewStyles.Header.Filters
              filters={4}
              // filterColumnOverride='150px 220px 150px 135px'
              hasTableToggle={false}>
              <SoloLabelInput
                labelKey={labelKeyFilter}
                onLabelKeyChange={setLabelKeyFilter}
                labelValue={labelValueFilter}
                onLabelValueChange={setLabelValueFilter}
              />
              <SoloInput value={textFilter} placeholder='Search...' aria-label='Search' onChangeValue={setTextFilter} />
              <SoloDropdown
                options={[
                  { displayValue: 'All Mesh Status', value: 'all' },
                  { displayValue: 'In Mesh', value: 'in-mesh' },
                  { displayValue: 'Out of Mesh', value: 'out-of-mesh' }
                ]}
                value={typeFilter}
                onChange={setTypeFilter}
                placeholder='Filter by Mesh Status'
                aria-label={'Mesh status filter'}
              />
              <SoloDropdown
                options={graphValidTimeIntervalList}
                value={window}
                onChange={setWindow}
                aria-label={'Lookback time'}
              />
              {/* <SoloGridTableToggle isTable={isTable} onChange={newIsTable => setIsTable(newIsTable)} /> -- cuurently removed as grid view is currently removed */}
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <div>
        {!servicesError && !!services?.apiserverErrors?.length && (
          <DataErrorStringList errorsList={services.apiserverErrors} />
        )}
        <ServicesLandingBody
          filtersOn={filtersOn}
          isLoading={isLoading}
          itemsError={servicesError}
          isTable={isTable}
          items={services?.services ?? []}
          pagingData={pagingData}
          data-testid='services-landing-body-empty'
          icon={<Asset.ServiceSvcIcon />}
          docsLink={false}
          resourceNamePlural='Services'
        />
      </div>
    </OverviewStyles.Container>
  );
};
