import { NameValue } from 'Components/Features/Certificates/Modals/CertificateDetailsModal';
import { SharedCertData } from 'Components/Features/Certificates/Overview/CertificatesLandingBody';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import { SECONDS_IN_DAY } from './helpers';

interface ValidCertData {
  expiresOn?: Timestamp;
  issuedOn?: Timestamp;
  issuedToCn?: string;
  issuedToOrganization?: string[];
  issuedToOrganizationUnit?: string[];
  issuedFromCn?: string;
  issuedFromOrganization?: string[];
  issuedFromOrganizationUnit?: string[];
  hostname?: string;
  fingerprints?: NameValue[];
}

const getCertString = (s: string | undefined) => (!!s ? s : '<Not Part of Certificate>');
const getStringOrArrayNameValues = (name: string, s: string | string[] | undefined): NameValue[] => {
  if (s === undefined || typeof s === 'string') {
    return [{ name, value: getCertString(s) }];
  } else {
    // s is an array
    return s.map(value => ({ name, value: getCertString(value) }));
  }
};

export const transformCertDataToListData = <T extends ValidCertData>(
  certType: string,
  insight: Insight,
  data: T | undefined
) => {
  //
  // Get data we need
  //
  const curSeconds = Math.floor(Date.now().valueOf() / 1000);
  const expiresAtSeconds = data?.expiresOn?.seconds ?? 0;
  const expirationDate = new Date(expiresAtSeconds * 1000);
  const secondsLeft = expiresAtSeconds - curSeconds;
  const daysLeft = Math.floor(secondsLeft / SECONDS_IN_DAY);
  const hostname = getCertString(data?.hostname);
  const issuedToCn = data?.issuedToCn ?? '-';

  //
  // Build and return cert data
  //
  const certData: SharedCertData = {
    key: certType + hostname,
    hostname,
    issuedToCn,
    certType,
    expiration: expirationDate.toString(),
    cluster: insight.target?.target.oneofKind === 'resource' ? insight.target?.target.resource.clusterName : '',
    expiryDays: daysLeft,
    detailsModalProps: transformCertDataToModalProps(certType, data)
  };
  return certData;
};

export function transformCertDataToModalProps<T extends ValidCertData>(certType: string, data: T | undefined) {
  const expiresAtSeconds = data?.expiresOn?.seconds ?? 0;
  const issuedAtSeconds = data?.issuedOn?.seconds ?? 0;
  const hostname = getCertString(data?.hostname);

  const issuedToCn = data?.issuedToCn ?? '-';
  const issuedToOrganizationNameValues = getStringOrArrayNameValues(
    'Organization (O)',
    data?.issuedToOrganization ?? data?.issuedToOrganization
  );
  const issuedToOrganizationUnitNameValues = getStringOrArrayNameValues(
    'Organization Unit (OU)',
    data?.issuedToOrganizationUnit ?? data?.issuedToOrganizationUnit
  );
  const issuedByCn = data?.issuedFromCn ?? '-';
  const issuedByOrganizationNameValues = getStringOrArrayNameValues(
    'Organization (O)',
    data?.issuedFromOrganization ?? data?.issuedFromOrganization
  );
  const issuedByOrganizationUnitNameValues = getStringOrArrayNameValues(
    'Organization Unit (OU)',
    data?.issuedFromOrganizationUnit ?? data?.issuedFromOrganizationUnit
  );

  return {
    certType,
    expiresAtSeconds,
    issuedAtSeconds,
    fingerprints: data?.fingerprints ?? [],
    hostname,
    issuedTo: [
      { name: 'Common Name (CN)', value: issuedToCn },
      ...issuedToOrganizationNameValues,
      ...issuedToOrganizationUnitNameValues
    ],
    issuedBy: [
      { name: 'Common Name (CN)', value: issuedByCn },
      ...issuedByOrganizationNameValues,
      ...issuedByOrganizationUnitNameValues
    ]
  };
}
