import { CardStyles } from 'Components/Common/Card';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { Asset } from 'assets';
import {
  GetGraphqlApiResponse,
  Resolution_Resolvers
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { convertFromPBValue } from 'utils/graphql-helpers';
import { getRefFromDestinationRef } from 'utils/helpers';
import { GqlResolverModalStyles as Styles } from '../GqlResolverModal.style';
import VariableTransformationLabel from '../VariableTransformationLabel';

export const GqlGrpcResolverModal = ({
  api,
  objectName,
  fieldName,
  resolver,
  onClose
}: {
  api: GetGraphqlApiResponse;
  objectName: string;
  fieldName: string;
  resolver: Resolution_Resolvers;
  onClose?: () => void;
}) => {
  // Gets data from resolver
  const grpcResolver =
    resolver?.resolver?.resolver?.oneofKind === 'grpcResolver' ? resolver.resolver.resolver.grpcResolver : undefined;
  const resolverResultTransform = resolver.resolver?.resolverResultTransform;
  const variablesMap = resolver.variables;
  const destName = getRefFromDestinationRef(grpcResolver?.destination?.destinationRef)?.name ?? 'unkown';
  const requestMethod = grpcResolver?.requestMethod;
  const requestService = grpcResolver?.requestService;
  const requestOutgoingMessageTemplate = grpcResolver?.requestOutgoingMessageTemplate;

  //
  // Render
  //
  return (
    <SoloModal width={1000} onClose={onClose} minHeight='unset' withPadding data-testid='grpc-resolver-modal'>
      <SoloModalHeader
        title='Grpc Resolver'
        subtitle={['Schema', objectName, fieldName]}
        icon={<Asset.GrpcIcon />}
        status={api.status}
        rightContent={
          <Styles.HeaderRight>
            <div>
              <SecondaryInfoItem label='Destination' data={destName} />
            </div>
          </Styles.HeaderRight>
        }
      />
      <CardStyles.CardList>
        {/* 

          // 
          // Variables
          // 
          */}
        {!!variablesMap?.length && (
          <SoloListCardStyles.Container>
            <SoloListCardStyles.Title>Variables</SoloListCardStyles.Title>
            <Styles.FancyCodeDisplayerContainer>
              <FancyCodeDisplayer
                padLineNumbersWithZeros={2}
                includeBorderBackground={false}
                contentString={Object.entries(variablesMap)
                  .map(
                    ([variable, value]) =>
                      `${variable}` +
                      (value.extractionType.oneofKind === 'dynamicMetadata'
                        ? `\n  dynamicMetadata:` +
                          `\n    ${value.extractionType.dynamicMetadata.key}: ${value.extractionType.dynamicMetadata.metadataNamespace}`
                        : // Could also be displayed like:
                          // `\n    key: ${value.dynamicMetadata.key}` +
                          // `\n    metadataNamespace: ${value.dynamicMetadata.metadataNamespace}`
                          '') +
                      (value.extractionType.oneofKind === 'graphqlArg'
                        ? `\n  graphqlArg: ${value.extractionType.graphqlArg}`
                        : '') +
                      (value.extractionType.oneofKind === 'jsonValue'
                        ? `\n  jsonValue: ${JSON.stringify(convertFromPBValue(value.extractionType.jsonValue))}`
                        : '') +
                      (value.extractionType.oneofKind === 'requestHeader'
                        ? `\n  requestHeader: ${value.extractionType.requestHeader}`
                        : '')
                  )
                  .join('\n')}
                type={'yaml'}
              />
            </Styles.FancyCodeDisplayerContainer>
          </SoloListCardStyles.Container>
        )}
        {/* 

          // 
          // Request
          // 
          */}
        <CardStyles.Card>
          <CardStyles.CardHeader>
            Request
            <CardStyles.CardHeaderSecondaryInfoHolder>
              <SecondaryInfo
                items={[
                  {
                    label: 'gRPC Service',
                    data: requestService ?? 'No service defined.',
                    tooltipText: 'The gRPC service that will be used.'
                  },
                  {
                    label: 'gRPC Method',
                    data: requestMethod ?? 'No method defined.',
                    tooltipText: 'The gRPC method that will be called.'
                  }
                ]}
              />
            </CardStyles.CardHeaderSecondaryInfoHolder>
            <CardStyles.CardHeaderRightIcon>
              <Asset.RequestIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          {requestOutgoingMessageTemplate ? (
            <VariableTransformationLabel
              title='Request Transform'
              variableTransformation={requestOutgoingMessageTemplate}
            />
          ) : (
            <i>No Transformation</i>
          )}
        </CardStyles.Card>
        {/* 

          // 
          // Response
          // 
          */}
        {!!resolverResultTransform && (
          <CardStyles.Card>
            <CardStyles.CardHeader>
              Response
              <CardStyles.CardHeaderRightIcon>
                <Asset.ResponseIcon />
              </CardStyles.CardHeaderRightIcon>
            </CardStyles.CardHeader>
            <VariableTransformationLabel title='Result Transform' variableTransformation={resolverResultTransform} />
          </CardStyles.Card>
        )}
      </CardStyles.CardList>
    </SoloModal>
  );
};
