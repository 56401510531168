import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { useIstioVersionInsightsToVersionDict } from 'Components/Features/Dashboard/Cards/Health/hooks';
import { dashboardHealthCodesMap, DashboardHealthKey } from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { IstioVersionsSoloListCard } from 'Components/Features/Dashboard/Cards/Health/Istio/IstioVersionsSoloListCard';
import { ListInsightsRequest_InsightsFilter_Target } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { compareVersion } from 'utils/helpers';

const healthCodesMap = dashboardHealthCodesMap;

export const ClusterIstioVersionsListCard = ({
  cluster,
  selectVersion,
  selectedVersion
}: {
  cluster: string;
  selectVersion: (version: string) => any;
  selectedVersion: string | undefined;
}) => {
  di(useFilteredDashboardInsights);
  const target: ListInsightsRequest_InsightsFilter_Target = { clusters: [cluster] };
  const istioEnvironment = useFilteredDashboardInsights(
    healthCodesMap,
    DashboardHealthKey.istioEnvironmentCheck,
    target
  );
  const versionsDict = useIstioVersionInsightsToVersionDict(istioEnvironment);

  useEffect(() => {
    if (!!selectedVersion) {
      return;
    }
    const versionsEntries = Object.entries(versionsDict);
    if (!versionsEntries.length) {
      return;
    }
    const initialList = versionsEntries.sort(([a], [b]) => -compareVersion(a, b));
    const [firstVersion] = initialList[0];

    selectVersion(firstVersion);
  }, [versionsDict]);

  return (
    <ErrorBoundary fallback={'Problems finding Istio version information...'}>
      <IstioVersionsSoloListCard
        versionsDict={versionsDict}
        maxHeight='167px'
        selectVersion={!!Object.entries(versionsDict).length ? selectVersion : undefined}
        selectedVersion={selectedVersion}
      />
    </ErrorBoundary>
  );
};
