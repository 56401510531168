/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/destinations.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { DestinationsApi } from "./destinations_pb";
import type { GetSchemaForKubernetesServiceResponse } from "./destinations_pb";
import type { GetSchemaForKubernetesServiceRequest } from "./destinations_pb";
import type { GetExternalServiceDetailsResponse } from "./destinations_pb";
import type { GetExternalServiceDetailsRequest } from "./destinations_pb";
import type { GetVirtualDestinationDetailsResponse } from "./destinations_pb";
import type { GetVirtualDestinationDetailsRequest } from "./destinations_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetKubernetesServiceDetailsResponse } from "./destinations_pb";
import type { GetKubernetesServiceDetailsRequest } from "./destinations_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Apis for the Gloo Mesh UI Workspace Destinations pages
 *
 * @generated from protobuf service rpc.gloo.solo.io.DestinationsApi
 */
export interface IDestinationsApiClient {
    /**
     * @generated from protobuf rpc: GetKubernetesServiceDetails(rpc.gloo.solo.io.GetKubernetesServiceDetailsRequest) returns (rpc.gloo.solo.io.GetKubernetesServiceDetailsResponse);
     */
    getKubernetesServiceDetails(input: GetKubernetesServiceDetailsRequest, options?: RpcOptions): UnaryCall<GetKubernetesServiceDetailsRequest, GetKubernetesServiceDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetVirtualDestinationDetails(rpc.gloo.solo.io.GetVirtualDestinationDetailsRequest) returns (rpc.gloo.solo.io.GetVirtualDestinationDetailsResponse);
     */
    getVirtualDestinationDetails(input: GetVirtualDestinationDetailsRequest, options?: RpcOptions): UnaryCall<GetVirtualDestinationDetailsRequest, GetVirtualDestinationDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetExternalServiceDetails(rpc.gloo.solo.io.GetExternalServiceDetailsRequest) returns (rpc.gloo.solo.io.GetExternalServiceDetailsResponse);
     */
    getExternalServiceDetails(input: GetExternalServiceDetailsRequest, options?: RpcOptions): UnaryCall<GetExternalServiceDetailsRequest, GetExternalServiceDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetSchemaForKubernetesService(rpc.gloo.solo.io.GetSchemaForKubernetesServiceRequest) returns (rpc.gloo.solo.io.GetSchemaForKubernetesServiceResponse);
     */
    getSchemaForKubernetesService(input: GetSchemaForKubernetesServiceRequest, options?: RpcOptions): UnaryCall<GetSchemaForKubernetesServiceRequest, GetSchemaForKubernetesServiceResponse>;
}
/**
 * Apis for the Gloo Mesh UI Workspace Destinations pages
 *
 * @generated from protobuf service rpc.gloo.solo.io.DestinationsApi
 */
export class DestinationsApiClient implements IDestinationsApiClient, ServiceInfo {
    typeName = DestinationsApi.typeName;
    methods = DestinationsApi.methods;
    options = DestinationsApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetKubernetesServiceDetails(rpc.gloo.solo.io.GetKubernetesServiceDetailsRequest) returns (rpc.gloo.solo.io.GetKubernetesServiceDetailsResponse);
     */
    getKubernetesServiceDetails(input: GetKubernetesServiceDetailsRequest, options?: RpcOptions): UnaryCall<GetKubernetesServiceDetailsRequest, GetKubernetesServiceDetailsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetKubernetesServiceDetailsRequest, GetKubernetesServiceDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVirtualDestinationDetails(rpc.gloo.solo.io.GetVirtualDestinationDetailsRequest) returns (rpc.gloo.solo.io.GetVirtualDestinationDetailsResponse);
     */
    getVirtualDestinationDetails(input: GetVirtualDestinationDetailsRequest, options?: RpcOptions): UnaryCall<GetVirtualDestinationDetailsRequest, GetVirtualDestinationDetailsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetVirtualDestinationDetailsRequest, GetVirtualDestinationDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetExternalServiceDetails(rpc.gloo.solo.io.GetExternalServiceDetailsRequest) returns (rpc.gloo.solo.io.GetExternalServiceDetailsResponse);
     */
    getExternalServiceDetails(input: GetExternalServiceDetailsRequest, options?: RpcOptions): UnaryCall<GetExternalServiceDetailsRequest, GetExternalServiceDetailsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetExternalServiceDetailsRequest, GetExternalServiceDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSchemaForKubernetesService(rpc.gloo.solo.io.GetSchemaForKubernetesServiceRequest) returns (rpc.gloo.solo.io.GetSchemaForKubernetesServiceResponse);
     */
    getSchemaForKubernetesService(input: GetSchemaForKubernetesServiceRequest, options?: RpcOptions): UnaryCall<GetSchemaForKubernetesServiceRequest, GetSchemaForKubernetesServiceResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSchemaForKubernetesServiceRequest, GetSchemaForKubernetesServiceResponse>("unary", this._transport, method, opt, input);
    }
}
