import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { portalApi } from 'Api/portal';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { FancyCodeDisplayerModal, FancyCodeDisplayerModalContent } from 'Components/Common/FancyCodeDisplayerModal';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import { CardFolderTab, CardFolderTabList } from 'Components/Common/Tabs';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { ErrorMessage } from 'Styles/CommonEmotions/errors';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { ApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { getApiTypeIcon } from 'utils/types';
import { GqlDetailsStyles as Styles } from '../../Graphql/Details/GqlDetails.style';
import { useParseOpenApiSchemaString } from '../openapi-helpers';
import { PathsTab } from './Tabs/PathsTab';
import { TypesTab } from './Tabs/TypesTab';

const { useGetSchema } = portalApi;

const HASH_SEPARATOR = encodeURI('>');

/**
 * Extracts schema routing info from the URL hash.
 * The hash follows the format: "schema_<tabIndex>".
 */
function getUrlHashRouteInfo(location: Location): number {
  // Return the default tab index and path if it is invalid.
  const hashStart = `#schema${HASH_SEPARATOR}`;
  if (location.hash?.substring(0, hashStart.length) !== hashStart) return 0;

  let [, tabIndexStr] = location.hash.split(HASH_SEPARATOR);
  const tabIndex = Number.parseInt(tabIndexStr);
  if (isNaN(tabIndex)) return 0;
  return tabIndex;
}

export const OpenApiSchemaCard = ({ routeTableRef }: { routeTableRef: ClusterObjectRef }) => {
  di(useGetSchema);
  const location = useLocation();
  const navigate = useNavigate();
  const [schemaModalVisible, setSchemaModalVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [nameFilter, setNameFilter] = useState('');

  const { data: schemaStringResponse, error: schemaStringResponseError } = useGetSchema(routeTableRef);
  const schemaString = schemaStringResponse?.schema;
  const { data: parseData, error: parseError } = useParseOpenApiSchemaString(schemaString);

  // When the page loads and/or URL hash changes, check for updates to the tabIndex and focusedItem.
  useEffect(() => {
    const newTabIndex = getUrlHashRouteInfo(location);
    // If the hash doesn't exist yet, set it to the default.
    // window.replace makes it so this doesn't add anything to the history stack.
    if (!location.hash) window.location.replace(window.location.href + `#schema${HASH_SEPARATOR}0`);
    setTabIndex(newTabIndex);
  }, [location.hash]);

  // This finds the tab index, then updates the URL hash to focus it.
  const onTabChange = (newTabIndex: number) => {
    // Update the URL hash.
    let newHash = `#schema${HASH_SEPARATOR}${newTabIndex}`;
    if (location.hash !== newHash) navigate(newHash);
  };

  if (!!schemaStringResponseError || !!parseError) {
    return <DataError error={schemaStringResponseError || parseError} />;
  } else if (!schemaStringResponse || !parseData) {
    return <Loading message={`Retrieving schema details...`} />;
  }

  const { schema } = parseData;

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='openapi-schema-card'>
      <ErrorBoundary fallback={<ErrorMessage>There was an error parsing your OpenAPI schema...</ErrorMessage>}>
        <Styles.SchemaSection>
          <Styles.StyledCardHeader>
            <div>
              <Spacer mr={5} display='inline-block'>
                API Schema
              </Spacer>
              <SoloButton small onClick={() => setSchemaModalVisible(true)}>
                View OpenAPI
              </SoloButton>
              {schemaModalVisible && (
                <FancyCodeDisplayerModal
                  title={routeTableRef.name}
                  subtitle='OpenAPI Schema'
                  icon={getApiTypeIcon(ApiType.PORTAL_OPENAPI)}
                  onClose={() => setSchemaModalVisible(false)}>
                  <FancyCodeDisplayerModalContent contentString={schemaString} type='json' />
                </FancyCodeDisplayerModal>
              )}
            </div>
            <SoloInput
              searchIcon
              value={nameFilter}
              placeholder='Search by name...'
              aria-label='Search by name'
              onChange={evt => setNameFilter(evt.target.value)}
            />
          </Styles.StyledCardHeader>

          <Tabs id='tabs' index={tabIndex} onChange={onTabChange}>
            <CardFolderTabList>
              <CardFolderTab>Paths</CardFolderTab>
              <CardFolderTab>Types</CardFolderTab>
            </CardFolderTabList>

            <TabPanels>
              <TabPanel>
                <PathsTab paths={schema.paths} filter={nameFilter} />
              </TabPanel>

              <TabPanel>
                <TypesTab types={schema.components?.schemas} filter={nameFilter} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Styles.SchemaSection>
      </ErrorBoundary>
    </CardStyles.Card>
  );
};
