const yaml = `apiVersion: admin.gloo.solo.io/v2
kind: Workspace
metadata:
  annotations:
    cluster.solo.io/cluster: ""
  name: anything
  namespace: gloo-mesh
spec:
  workloadClusters:
  - name: '*'
    namespaces:
    - name: '*'`;
export default yaml;
