/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/matcher/regex.proto" (package "envoy.type.matcher", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { UInt32Value } from "../../../google/protobuf/wrappers_pb";
// [#protodoc-title: Regex matcher]

/**
 * A regex matcher designed for safety when used with untrusted input.
 *
 * @generated from protobuf message envoy.type.matcher.RegexMatcher
 */
export interface RegexMatcher {
    /**
     * @generated from protobuf oneof: engine_type
     */
    engineType: {
        oneofKind: "googleRe2";
        /**
         * Google's RE2 regex engine.
         *
         * @generated from protobuf field: envoy.type.matcher.RegexMatcher.GoogleRE2 google_re2 = 1;
         */
        googleRe2: RegexMatcher_GoogleRE2;
    } | {
        oneofKind: undefined;
    };
    /**
     * The regex match string. The string must be supported by the configured engine.
     *
     * @generated from protobuf field: string regex = 2;
     */
    regex: string;
}
/**
 * Google's `RE2 <https://github.com/google/re2>`_ regex engine. The regex string must adhere to
 * the documented `syntax <https://github.com/google/re2/wiki/Syntax>`_. The engine is designed
 * to complete execution in linear time as well as limit the amount of memory used.
 *
 * Envoy supports program size checking via runtime. The runtime keys `re2.max_program_size.error_level`
 * and `re2.max_program_size.warn_level` can be set to integers as the maximum program size or
 * complexity that a compiled regex can have before an exception is thrown or a warning is
 * logged, respectively. `re2.max_program_size.error_level` defaults to 100, and
 * `re2.max_program_size.warn_level` has no default if unset (will not check/log a warning).
 *
 * Envoy emits two stats for tracking the program size of regexes: the histogram `re2.program_size`,
 * which records the program size, and the counter `re2.exceeded_warn_level`, which is incremented
 * each time the program size exceeds the warn level threshold.
 *
 * @generated from protobuf message envoy.type.matcher.RegexMatcher.GoogleRE2
 */
export interface RegexMatcher_GoogleRE2 {
    /**
     * This field controls the RE2 "program size" which is a rough estimate of how complex a
     * compiled regex is to evaluate. A regex that has a program size greater than the configured
     * value will fail to compile. In this case, the configured max program size can be increased
     * or the regex can be simplified. If not specified, the default is 100.
     *
     * This field is deprecated; regexp validation should be performed on the management server
     * instead of being done by each individual client.
     *
     * @deprecated
     * @generated from protobuf field: google.protobuf.UInt32Value max_program_size = 1 [deprecated = true];
     */
    maxProgramSize?: UInt32Value;
}
/**
 * Describes how to match a string and then produce a new string using a regular
 * expression and a substitution string.
 *
 * @generated from protobuf message envoy.type.matcher.RegexMatchAndSubstitute
 */
export interface RegexMatchAndSubstitute {
    /**
     * The regular expression used to find portions of a string (hereafter called
     * the "subject string") that should be replaced. When a new string is
     * produced during the substitution operation, the new string is initially
     * the same as the subject string, but then all matches in the subject string
     * are replaced by the substitution string. If replacing all matches isn't
     * desired, regular expression anchors can be used to ensure a single match,
     * so as to replace just one occurrence of a pattern. Capture groups can be
     * used in the pattern to extract portions of the subject string, and then
     * referenced in the substitution string.
     *
     * @generated from protobuf field: envoy.type.matcher.RegexMatcher pattern = 1;
     */
    pattern?: RegexMatcher;
    /**
     * The string that should be substituted into matching portions of the
     * subject string during a substitution operation to produce a new string.
     * Capture groups in the pattern can be referenced in the substitution
     * string. Note, however, that the syntax for referring to capture groups is
     * defined by the chosen regular expression engine. Google's `RE2
     * <https://github.com/google/re2>`_ regular expression engine uses a
     * backslash followed by the capture group number to denote a numbered
     * capture group. E.g., ``\1`` refers to capture group 1, and ``\2`` refers
     * to capture group 2.
     *
     * @generated from protobuf field: string substitution = 2;
     */
    substitution: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class RegexMatcher$Type extends MessageType<RegexMatcher> {
    constructor() {
        super("envoy.type.matcher.RegexMatcher", [
            { no: 1, name: "google_re2", kind: "message", oneof: "engineType", T: () => RegexMatcher_GoogleRE2, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "regex", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.matcher.RegexMatcher
 */
export const RegexMatcher = new RegexMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegexMatcher_GoogleRE2$Type extends MessageType<RegexMatcher_GoogleRE2> {
    constructor() {
        super("envoy.type.matcher.RegexMatcher.GoogleRE2", [
            { no: 1, name: "max_program_size", kind: "message", T: () => UInt32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.matcher.RegexMatcher.GoogleRE2
 */
export const RegexMatcher_GoogleRE2 = new RegexMatcher_GoogleRE2$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegexMatchAndSubstitute$Type extends MessageType<RegexMatchAndSubstitute> {
    constructor() {
        super("envoy.type.matcher.RegexMatchAndSubstitute", [
            { no: 1, name: "pattern", kind: "message", T: () => RegexMatcher },
            { no: 2, name: "substitution", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.matcher.RegexMatchAndSubstitute
 */
export const RegexMatchAndSubstitute = new RegexMatchAndSubstitute$Type();
