import React from 'react';
import * as ReactDOM from 'react-dom';
import GlooMeshApp from './GlooMeshApp';
import * as serviceWorker from './serviceWorker';
import { SWRConfig } from 'swr';

ReactDOM.render(
  <SWRConfig
    value={{
      provider: () => new Map(),
      shouldRetryOnError: false,
      dedupingInterval: 2000
    }}>
    <GlooMeshApp />
  </SWRConfig>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./GlooMeshApp', () => {
    const NextApp = require('./GlooMeshApp').default;
    ReactDOM.render(
      <SWRConfig
        value={{
          provider: () => new Map(),
          shouldRetryOnError: false,
          dedupingInterval: 2000
        }}>
        <NextApp />
      </SWRConfig>,
      document.getElementById('root')
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
