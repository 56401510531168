import { CollapseToggle } from 'Components/Common/CollapseToggle';
import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { SmallErrorXList } from 'Components/Common/SmallErrorXList';
import { SoloLink } from 'Components/Common/SoloLink';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { TruncateText } from 'Styles/CommonEmotions/text';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { buildWorkspaceDetailsUrl } from 'utils/url-builders';
import { OverviewCardStyles } from './OverviewCards.style';
import { OverviewClusterDetails } from './OverviewClusterDetails';
import { OverviewWorkspaceDetails } from './OverviewWorkspaceDetails';

type OverviewCardProps = {
  simpleInfo: React.ReactNode;
  children: React.ReactNode;
  status?: Status;
  objRef?: ObjectRef;
  startOpen?: boolean;
  detailsLink?: string;
};
const OverviewGeneralizedCard = ({
  status,
  objRef,
  startOpen,
  simpleInfo,
  detailsLink,
  children
}: OverviewCardProps) => {
  const [collapsed, setCollapsed] = useState(!startOpen);

  const toggleCollapse = () => {
    setCollapsed(coll => !coll);
  };

  const title = objRef?.name ?? 'Untitled';

  return (
    <OverviewCardStyles.Container
      health={status?.state}
      data-testid={`${title.toLowerCase()}-container`}
      onClick={collapsed ? toggleCollapse : undefined}>
      <OverviewCardStyles.Header health={status?.state} onClick={collapsed ? undefined : toggleCollapse}>
        <div>
          <Spacer mr='8px'>
            <HealthIndicator status={status} />
          </Spacer>
          <OverviewCardStyles.Title health={status?.state}>
            {!detailsLink ? (
              title
            ) : (
              <SoloLink link={detailsLink} health={status?.state}>
                {title}
              </SoloLink>
            )}
          </OverviewCardStyles.Title>
        </div>
        <OverviewCardStyles.HealthHolder>
          <SmallErrorXList status={status} />
          <CollapseToggle health={status?.state} collapsed={collapsed} />
        </OverviewCardStyles.HealthHolder>
      </OverviewCardStyles.Header>
      <OverviewCardStyles.Body>
        <OverviewCardStyles.BodyDetails data-testid={`${title.toLowerCase()}-body-details`}>
          {simpleInfo}
        </OverviewCardStyles.BodyDetails>
        <OverviewCardStyles.FurtherDetails collapsed={collapsed}>
          <AnimateHeight height={collapsed ? 0 : 'auto'} duration={300} animateOpacity>
            {children}
          </AnimateHeight>
        </OverviewCardStyles.FurtherDetails>
      </OverviewCardStyles.Body>
    </OverviewCardStyles.Container>
  );
};

type WorkspaceCardProps = {
  clustersCount: number;
  namespacesCount: number;
  servicesCount: number;
};
export const OverviewWorkspaceCard = ({
  clustersCount,
  namespacesCount,
  servicesCount,
  objRef,
  ...rest
}: WorkspaceCardProps & Omit<OverviewCardProps, 'simpleInfo' | 'children'>) => {
  const itemCount = 3;
  return (
    <OverviewGeneralizedCard
      objRef={objRef}
      detailsLink={buildWorkspaceDetailsUrl(objRef?.name)}
      {...rest}
      simpleInfo={
        <OverviewCardStyles.BodyDetailsRow itemCount={itemCount}>
          <OverviewCardStyles.BodyDetailsInstance>
            <Asset.ClusterIcon />
            <OverviewCardStyles.BodyDetailsInstanceTitle>{clustersCount}</OverviewCardStyles.BodyDetailsInstanceTitle>
            Clusters
          </OverviewCardStyles.BodyDetailsInstance>
          <OverviewCardStyles.BodyDetailsInstance>
            <Asset.NamespaceIcon />
            <OverviewCardStyles.BodyDetailsInstanceTitle>{namespacesCount}</OverviewCardStyles.BodyDetailsInstanceTitle>
            Namespaces
          </OverviewCardStyles.BodyDetailsInstance>
          <OverviewCardStyles.BodyDetailsInstance>
            <Asset.GatewayIcon />
            <OverviewCardStyles.BodyDetailsInstanceTitle>{servicesCount}</OverviewCardStyles.BodyDetailsInstanceTitle>
            Destinations
          </OverviewCardStyles.BodyDetailsInstance>
        </OverviewCardStyles.BodyDetailsRow>
      }>
      <OverviewCardStyles.ExtraDetailsHolder>
        <OverviewWorkspaceDetails workspaceRef={objRef} />
      </OverviewCardStyles.ExtraDetailsHolder>
    </OverviewGeneralizedCard>
  );
};

type ClusterCardProps = {
  region: string;
  glooMeshAgentVersion?: string;
  k8Version: string;
  istioVersions: string[];
  ciliumVersion?: string;
  calicoVersion?: string;
};
export const OverviewClusterCard = ({
  region,
  glooMeshAgentVersion,
  k8Version,
  istioVersions,
  ciliumVersion,
  calicoVersion,
  objRef,
  ...rest
}: ClusterCardProps & Omit<OverviewCardProps, 'simpleInfo' | 'children'>) => {
  // 2 represents region and k8Version which we always want shown
  const itemCount = 2 + /*istioVersions.length*/ +Number(!!ciliumVersion) + Number(!!glooMeshAgentVersion);
  const cleanVersion = (v?: string) => v?.replace('-fips', '');
  const agentVersionMismatch = cleanVersion(glooMeshAgentVersion) !== cleanVersion(process.env.UI_VERSION);
  return (
    <OverviewGeneralizedCard
      objRef={objRef}
      {...rest}
      simpleInfo={
        <OverviewCardStyles.BodyDetailsRow itemCount={itemCount}>
          {!!region && (
            <OverviewCardStyles.BodyDetailsInstance>
              <Asset.RegionIcon />
              <TruncateText title={`Region: ${region}`}>
                <OverviewCardStyles.BodyDetailsInstanceTitle>Region:</OverviewCardStyles.BodyDetailsInstanceTitle>
                {region}
              </TruncateText>
            </OverviewCardStyles.BodyDetailsInstance>
          )}
          {glooMeshAgentVersion && (
            <Tooltip
              title={
                <>
                  Agent: {glooMeshAgentVersion}
                  {agentVersionMismatch && (
                    <OverviewCardStyles.TooltipErrorText>
                      There is a mismatch between this cluster's agent version and the current dashboard version of{' '}
                      {process.env.UI_VERSION}
                    </OverviewCardStyles.TooltipErrorText>
                  )}
                </>
              }>
              <OverviewCardStyles.BodyDetailsInstance health={agentVersionMismatch ? 'error' : undefined}>
                <Asset.GlooMeshGloo />
                <TruncateText title={undefined}>
                  <OverviewCardStyles.BodyDetailsInstanceTitle>Agent:</OverviewCardStyles.BodyDetailsInstanceTitle>
                  {glooMeshAgentVersion}
                </TruncateText>
              </OverviewCardStyles.BodyDetailsInstance>
            </Tooltip>
          )}
          {!!k8Version && (
            <OverviewCardStyles.BodyDetailsInstance>
              <Asset.KubernetesTypeIcon />
              <TruncateText title={`K8s: ${k8Version}`}>
                <OverviewCardStyles.BodyDetailsInstanceTitle>K8s:</OverviewCardStyles.BodyDetailsInstanceTitle>
                {k8Version}
              </TruncateText>
            </OverviewCardStyles.BodyDetailsInstance>
          )}
          {!!istioVersions?.length &&
            (istioVersions.length === 1 ? (
              <OverviewCardStyles.BodyDetailsInstance>
                <Asset.IstioLogo />
                <TruncateText title={`Istio: ${istioVersions[0]}`}>
                  <OverviewCardStyles.BodyDetailsInstanceTitle>Istio:</OverviewCardStyles.BodyDetailsInstanceTitle>
                  {istioVersions[0]}
                </TruncateText>
              </OverviewCardStyles.BodyDetailsInstance>
            ) : (
              <OverviewCardStyles.BodyDetailsInstance>
                <Asset.IstioLogo />
                <TruncateText
                  title={
                    <>
                      <strong>Istio Versions:</strong>
                      <ul>
                        {istioVersions.map(v => (
                          <li key={v}>{v}</li>
                        ))}
                      </ul>
                    </>
                  }>
                  <OverviewCardStyles.BodyDetailsInstanceTitle>Istio:</OverviewCardStyles.BodyDetailsInstanceTitle>
                  Multiple Versions
                </TruncateText>
              </OverviewCardStyles.BodyDetailsInstance>
            ))}
          {ciliumVersion && (
            <OverviewCardStyles.BodyDetailsInstance>
              <Asset.CiliumIcon />
              <TruncateText title={`Cilium: ${ciliumVersion}`}>
                <OverviewCardStyles.BodyDetailsInstanceTitle>Cilium:</OverviewCardStyles.BodyDetailsInstanceTitle>
                {ciliumVersion}
              </TruncateText>
            </OverviewCardStyles.BodyDetailsInstance>
          )}
          {calicoVersion && (
            <OverviewCardStyles.BodyDetailsInstance>
              <Asset.CalicoIcon />
              <TruncateText title={`Calico: ${calicoVersion}`}>
                <OverviewCardStyles.BodyDetailsInstanceTitle>Calico:</OverviewCardStyles.BodyDetailsInstanceTitle>
                {calicoVersion}
              </TruncateText>
            </OverviewCardStyles.BodyDetailsInstance>
          )}
        </OverviewCardStyles.BodyDetailsRow>
      }>
      <OverviewCardStyles.ExtraDetailsHolder>
        <OverviewClusterDetails clusterRef={objRef} />
      </OverviewCardStyles.ExtraDetailsHolder>
    </OverviewGeneralizedCard>
  );
};
