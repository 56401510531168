import { SoloListCard } from 'Components/Common/SoloListCard';
import { DashboardHealthCardStyles } from 'Components/Features/Dashboard/Cards/Health/DashboardHealthCard.style';
import {
  useCiliumVersionInsightsToVersionDict,
  useVersionListCardEntries
} from 'Components/Features/Dashboard/Cards/Health/hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { ListInsightsRequest_InsightsFilter_Target } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';

const healthCodesMap = dashboardHealthCodesMap;

export const ClusterCiliumVersionsListCard = ({ cluster }: { cluster: string }) => {
  di(useFilteredDashboardInsights);
  const target: ListInsightsRequest_InsightsFilter_Target = { clusters: [cluster] };
  const ciliumEnvironment = useFilteredDashboardInsights(
    healthCodesMap,
    DashboardHealthKey.ciliumEnvironmentCheck,
    target
  );
  const versionsDict = useCiliumVersionInsightsToVersionDict(ciliumEnvironment);
  const versionListCardEntries = useVersionListCardEntries(versionsDict);

  return (
    <DashboardHealthCardStyles.NoMarginEmptyContainer>
      <SoloListCard
        data-testid='cilium-versions-card'
        title='Version'
        maxHeight='167px'
        dataSource={versionListCardEntries}
        hideDottedLine
      />
    </DashboardHealthCardStyles.NoMarginEmptyContainer>
  );
};
