import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn, createStatusColumn } from 'Components/Common/SoloTableCells';
import { StringListWithTooltip } from 'Components/Common/StringListWithTooltip';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListOverviewPortalsResponse_PortalSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildIdFromRef } from 'utils/helpers';
import { convertRefToUrl } from 'utils/url-builder-helpers';
import { buildPortalDetailsUrl } from 'utils/url-builders';

export const PortalsLandingBody = (props: CustomLandingBodyProps<ListOverviewPortalsResponse_PortalSummary>) => (
  <LandingBody {...props} GridComponent={PortalsLandingBodyGrid} TableComponent={PortalsLandingBodyTable} />
);

//
// Grid variant
//
const PortalsLandingBodyGrid = (props: BaseLandingBodyProps<ListOverviewPortalsResponse_PortalSummary>) => {
  const { items: portals, pagingData } = props;
  return (
    <>
      <OverviewGridStyles.Grid data-testid='portals-landing-body'>
        {portals.map(portal => (
          <li key={buildIdFromRef(portal.portalRef)}>
            <a
              href={buildPortalDetailsUrl(portal.portalRef)}
              aria-label={`Portal Card: ${portal.portalRef?.name ?? ''}`}>
              <OverviewGridStyles.Card
                title={
                  !portal.portalRef?.name || !portal.portalRef?.namespace || !portal.portalRef?.clusterName
                    ? 'Missing data to visit details page.'
                    : undefined
                }
                data-testid={`${portal.portalRef?.name}-portal-card`}>
                <OverviewGridStyles.CardHeader>
                  {portal.portalRef?.name ?? ''}
                  <HealthIndicator status={portal.status} />
                </OverviewGridStyles.CardHeader>
                <OverviewGridStyles.CardContent>
                  <SecondaryInfo
                    items={[
                      {
                        label: 'Workspace',
                        data: portal.ownerWorkspace?.ref?.name ?? ''
                      },
                      {
                        label: 'Cluster',
                        data: portal.portalRef?.clusterName ?? ''
                      },
                      {
                        label: 'Namespace',
                        data: portal.portalRef?.namespace ?? ''
                      }
                    ]}
                    small={true}
                  />
                </OverviewGridStyles.CardContent>
                <OverviewGridStyles.CardFooter>
                  {portal.domains.length === 0 && <div>No Domains</div>}
                  {portal.domains.length > 0 && (
                    <FlexLayout>
                      <Spacer display='inline' mr={1}>
                        Domain{portal.domains.length > 1 && 's'}:
                      </Spacer>
                      <StringListWithTooltip items={portal.domains} />
                    </FlexLayout>
                  )}
                </OverviewGridStyles.CardFooter>
              </OverviewGridStyles.Card>
            </a>
          </li>
        ))}
      </OverviewGridStyles.Grid>
      <SoloPagination {...pagingData} pageSizeOptions={[3, 6, 12, 24]} />
    </>
  );
};

//
// Table variant
//
interface TableFields {
  key: string;
  portalRef: ClusterObjectRef | undefined;
  status: Status | undefined;
  name: string;
  namespace: string;
  clusterName: string;
  workspaceName: string;
  domains: string[];
}
const PortalsLandingBodyTable = (props: BaseLandingBodyProps<ListOverviewPortalsResponse_PortalSummary>) => {
  const { items: portals, pagingData } = props;
  const navigate = useNavigate();
  const columns: SoloColumnsType<TableFields> = [
    createStatusColumn({ dataIndex: 'status' }),
    createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
    createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
    createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
    createEllipseTextColumn({ title: 'Workspace', dataIndex: 'workspaceName' }),
    {
      title: 'Domains',
      dataIndex: 'domains',
      render: domains => <StringListWithTooltip items={domains} />
    }
  ];

  const tableData = useMemo<TableFields[]>(
    () =>
      portals.map(p => ({
        key: buildIdFromRef(p.portalRef),
        portalRef: p.portalRef,
        status: p.status,
        name: p.portalRef?.name ?? '',
        namespace: p.portalRef?.namespace ?? '',
        clusterName: p.portalRef?.clusterName ?? '',
        workspaceName: p.ownerWorkspace?.ref?.name ?? '',
        domains: p.domains
      })),
    [portals]
  );

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable
        data-testid='portals-landing-body'
        dataSource={tableData}
        columns={columns}
        paging={pagingData}
        onRow={(record, _rowIndex) => ({
          onClick: () => navigate(convertRefToUrl(record.portalRef))
        })}
      />
    </Spacer>
  );
};
