// https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html

import { Theme } from '@emotion/react';

// HEX isn't made more specific as after a point it becomes "to complex" for the IDE to handle
type HEX = `#${string}`;
export type Color = HEX;
export type ColorOrAllowed = Color | 'currentColor' | 'transparent' | 'white' | 'black';
export type ColorThemeFunc = (theme: Theme) => ColorOrAllowed;

const colorsList = {
  kumquatOrange: '#FFF2EB',
  tangerineOrange: '#FCCCB3', // DESIGN: Light Orange
  peachOrange: '#E98D5E',
  grapefruitOrange: '#D75B1D', // DESIGN: Orange-Red / Dark Orange
  butternutOrange: '#B7460E',
  pumpkinOrange: '#8B2E00',

  flashlightGold: '#FFF6E5', // DESIGN: Light Yellow
  lampGold: '#FFE6A5',
  sunGold: '#FFB831', // DESIGN: Yellow
  darkSunGold: '#C98709',
  novaGold: '#e6a426',

  copseGreen: '#ebfff9',
  groveGreen: '#39f0b8',
  forestGreen: '#0dc98e',
  brightGreen: '#0dce93',

  dropBlue: '#F8FAFB', // DESIGN: Cloud Blue
  splashBlue: '#DBE4EC', // Gray Blue
  drizzleBlue: '#E5F4FA',
  lightSeaBlue: '#E8F4FA',
  puddleBlue: '#B1E7FF',
  deepPuddleBlue: '#C6E1F0',
  pondBlue: '#6AC7F0',
  lakeBlue: '#54B7E3',
  seaBlue: '#158BC2', // DESIGN: Logo Dark Blue
  oceanBlue: '#45698A', // DESIGN: Night Blue
  neptuneBlue: '#253E58', // DESIGN: Black Blue / Blacker Blue

  white: '#FFFFFF', // DESIGN: Logo White
  januaryGrey: '#F8FAFB', // DESIGN: Cloud Blue
  marchGrey: '#E9EEF3',
  aprilGrey: '#D4D8DE', // DESIGN: Light Gray
  juneGrey: '#ADB3BC',
  augustGrey: '#8693A5', // DESIGN: Med Grey
  septemberGrey: '#6E7477',
  novemberGrey: '#35393B', // DESIGN: Dark Gray

  scrollbarBorderGrey: '#E5E5E5',
  scrollbarBackgroundGrey: '#fbfbfb',

  boxShadow: hexAddAlpha('#263f5a', 0.07)
} as const; // "as const" forces all the color values to be an `as const` value instead of `string`
export const colors = { ...colorsList };

/**
 * Adds hex to the end of a hex color
 * @param hex
 * @param alpha 0-1
 */
export function hexAddAlpha(hex: HEX, alpha: number): HEX {
  const hexAlpha = (Math.floor(255 * alpha) % 256).toString(16).padStart(2, '0');
  return (hex + hexAlpha) as HEX;
}

export const healthColors = {
  normal: {
    light: colors.marchGrey,
    dark: colors.oceanBlue
  },
  warning: {
    light: colors.flashlightGold,
    dark: colors.sunGold,
    text: colors.darkSunGold
  },
  error: {
    light: colors.kumquatOrange,
    dark: colors.grapefruitOrange
  },
  darkError: {
    light: colors.tangerineOrange,
    dark: colors.butternutOrange
  },
  idle: {
    light: colors.marchGrey,
    dark: colors.septemberGrey
  }
};

export function handleColorOrColorThemeFunc(
  color: ColorOrAllowed | ColorThemeFunc | undefined,
  tm: Theme
): ColorOrAllowed | undefined {
  return typeof color === 'function' ? color(tm) : color;
}
