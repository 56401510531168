import { Col, Row } from 'antd';
import { overviewApi } from 'Api/overview';
import { DataError, DataErrorStringList } from 'Components/Common/DataError';
import { ErrorBoundaryCard } from 'Components/Common/ErrorBoundary';
import { Loading } from 'Components/Common/Loading';
import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { GetClusterDetailsCardsResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import {
  useDashboardInsightsLimitPassedNotification,
  useInsightEnablementChecks
} from 'utils/dashboard/dashboard-hooks';
import { ClusterCertificatesExpiryCard } from './Cards/CertificatesExpiry/ClusterCertificatesExpiryCard';
import { ClusterConfigurationCard } from './Cards/Configuration/ClusterConfigurationCard';
import { ClusterInsightsCard } from './Cards/Insights/ClusterInsightsCard';
import { ClusterIstioAndCiliumSummaryCard } from './Cards/IstioAndCiliumSummary/ClusterIstioAndCiliumSummaryCard';
import { ClusterResourcesCard } from './Cards/Resources/ClusterResourcesCard';
import { ClusterDetailsLandingHeader, ClusterDetailsLandingHeaderPreLoaded } from './ClusterDetailsLandingHeader';
const { useGetClusterDetailsCards } = overviewApi;

const ClusterIstioAndCiliumSummarySection = (props: Parameters<typeof ClusterIstioAndCiliumSummaryCard>[0]) => {
  return (
    <DashboardStyles.LandingCardContainer>
      <ErrorBoundaryCard fallback='There was an error showing analysis and insights information...'>
        <ClusterIstioAndCiliumSummaryCard {...props} />
      </ErrorBoundaryCard>
    </DashboardStyles.LandingCardContainer>
  );
};

const ClusterInsightsSection = (props: Parameters<typeof ClusterInsightsCard>[0]) => {
  return (
    <DashboardStyles.LandingCardContainer>
      <ErrorBoundaryCard fallback='There was an error showing insights information...'>
        <ClusterInsightsCard {...props} />
      </ErrorBoundaryCard>
    </DashboardStyles.LandingCardContainer>
  );
};

const ClusterCertificatesExpirySection = (props: Parameters<typeof ClusterCertificatesExpiryCard>[0]) => {
  return (
    <DashboardStyles.LandingCardContainer>
      <ErrorBoundaryCard fallback='There was an error showing certificates expiry information...'>
        <ClusterCertificatesExpiryCard {...props} />
      </ErrorBoundaryCard>
    </DashboardStyles.LandingCardContainer>
  );
};

const ClusterConfigurationSection = (props: Parameters<typeof ClusterConfigurationCard>[0]) => {
  return (
    <DashboardStyles.LandingCardContainer>
      <ErrorBoundaryCard fallback='There was an error showing configuration information...'>
        <ClusterConfigurationCard {...props} />
      </ErrorBoundaryCard>
    </DashboardStyles.LandingCardContainer>
  );
};

const ClusterResourcesSection = (props: Parameters<typeof ClusterResourcesCard>[0]) => {
  return (
    <DashboardStyles.LandingCardContainer>
      <ErrorBoundaryCard fallback='There was an error showing resource information...'>
        <ClusterResourcesCard {...props} />
      </ErrorBoundaryCard>
    </DashboardStyles.LandingCardContainer>
  );
};

export type ClusterDetailsLandingParams = {
  clusterName: string;
};

const ClusterDetailsLandingInner = ({
  clusterData,
  cluster
}: {
  clusterData: GetClusterDetailsCardsResponse;
  cluster: string;
}) => {
  di(useInsightEnablementChecks);
  const { isIstioEnabledAndLicenced } = useInsightEnablementChecks({ clusters: [cluster] });
  if (!isIstioEnabledAndLicenced) {
    return (
      <>
        <Col xs={24} xl={24}>
          <ClusterIstioAndCiliumSummarySection data={clusterData} cluster={cluster} />
        </Col>
        <Col xs={24} xl={24}>
          <ClusterInsightsSection insightsOverview={clusterData.insightsOverview} cluster={cluster} />
        </Col>
        <Col xs={24} xl={12}>
          <ClusterConfigurationSection configurationSummary={clusterData.configurationSummary} />
        </Col>
        <Col xs={24} xl={12}>
          <ClusterResourcesSection resourceSummary={clusterData.resourceSummary} cluster={cluster} />
        </Col>
      </>
    );
  }
  return (
    <>
      <Col xs={24} xl={24}>
        <ClusterIstioAndCiliumSummarySection data={clusterData} cluster={cluster} />
      </Col>
      <Col xs={24} xl={24}>
        <ClusterInsightsSection insightsOverview={clusterData.insightsOverview} cluster={cluster} />
      </Col>
      <Col xs={24} xl={8}>
        <ClusterCertificatesExpirySection cluster={cluster} />
      </Col>
      <Col xs={24} xl={8}>
        <ClusterConfigurationSection configurationSummary={clusterData.configurationSummary} />
      </Col>
      <Col xs={24} xl={8}>
        <ClusterResourcesSection resourceSummary={clusterData.resourceSummary} cluster={cluster} />
      </Col>
    </>
  );
};

export const ClusterDetailsLanding = () => {
  di(useGetClusterDetailsCards, useParams);
  const { clusterName } = useParams<ClusterDetailsLandingParams>();
  const { data: clusterData, error: clusterError } = useGetClusterDetailsCards(clusterName);
  useDashboardInsightsLimitPassedNotification({ clusters: [clusterName ?? ''] });

  if (!!clusterError || !clusterData) {
    return (
      <>
        <ClusterDetailsLandingHeaderPreLoaded clusterName={clusterName} />
        {!!clusterError ? (
          <DataError error={clusterError} />
        ) : (
          <Loading message={`Retrieving ${clusterName} cluster details...`} />
        )}
      </>
    );
  }

  return (
    <DashboardStyles.LandingContainer data-testid='cluster-details-landing'>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={24}>
          <ClusterDetailsLandingHeader clusterName={clusterName} clusterVersions={clusterData.clusterVersions} />
          {!!clusterData?.apiserverErrors?.length && <DataErrorStringList errorsList={clusterData.apiserverErrors} />}
        </Col>
        <ClusterDetailsLandingInner clusterData={clusterData} cluster={clusterName ?? ''} />
      </Row>
    </DashboardStyles.LandingContainer>
  );
};
