import Chart from 'chart.js/auto';
import { ElementRef, useEffect, useRef } from 'react';
import { formatLargeNumberNicely } from 'utils/helpers';
import { ClusterServicesStyles } from './ClusterServices.style';
import { ClusterServicesCodesDataTypeMap, ClusterServicesKey } from './insight-codes';

type clusterServicesSummaryDataType = ClusterServicesCodesDataTypeMap[ClusterServicesKey.clusterServicesSummary];

export enum clusterServicesChartColors {
  inMeshServicesColor = '#138bc2',
  outOfMeshServicesColor = '#45698a',
  ambientServicesColor = '#09ce93',
  sidecarServicesColor = '#263e58'
}

const ClusterServicesChart = ({ data }: { data: clusterServicesSummaryDataType }) => {
  // This fixes the flickering so that it only animates once when the chart appears.
  const chartRenderedOnceRef = useRef(false);

  const totalServices = data.inMeshServices + data.outOfMeshServices;
  //
  // Initializes Chart with options
  //
  const chartCanvas = useRef<ElementRef<'canvas'>>(null);
  useEffect(() => {
    if (!chartCanvas.current) {
      return;
    }
    const canvas = chartCanvas.current;

    // Set up the data we will use for the chart.
    const ambientAndSidecarServices = data.ambientServices + data.sidecarServices;
    const labels = ['In Mesh Services', 'Out of Mesh Services', 'Ambient Services', 'Sidecar Services'];
    const backgroundColor = [
      clusterServicesChartColors.inMeshServicesColor,
      clusterServicesChartColors.outOfMeshServicesColor,
      clusterServicesChartColors.ambientServicesColor,
      clusterServicesChartColors.sidecarServicesColor
    ];

    // Create the chart instance.
    const chart = new Chart(canvas, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [
          {
            // Outer Donut
            data: [0, 0, data.ambientServices, data.sidecarServices],
            backgroundColor,
            borderWidth: 0,
            circumference: 360 * (ambientAndSidecarServices / totalServices),
            // These props are in the chart.js spec, but not in the types.
            // @ts-ignore
            cutout: '85%'
          },
          {
            // Inner Donut
            data: [data.inMeshServices, data.outOfMeshServices, 0, 0],
            backgroundColor,
            borderWidth: 0,
            // These props are in the chart.js spec, but not in the types.
            // @ts-ignore
            cutout: '60%',
            radius: '110%'
          }
        ]
      },
      options: {
        plugins: { legend: { display: false } },
        animation: chartRenderedOnceRef.current ? false : undefined
      }
    });
    chartRenderedOnceRef.current = true;
    return () => {
      chart.destroy();
    };
  }, [chartCanvas.current, data.inMeshServices, data.outOfMeshServices, data.ambientServices, data.sidecarServices]);

  const totalServicesFormatted = formatLargeNumberNicely(totalServices);

  //
  // Render
  //
  return (
    <ClusterServicesStyles.ChartContainer>
      <canvas id='cluster-services-summary' ref={chartCanvas} />
      <ClusterServicesStyles.ChartSizer />
      <ClusterServicesStyles.ChartTextContainer>
        <ClusterServicesStyles.ChartBigText data-testid='total-services-count'>
          {totalServicesFormatted}
        </ClusterServicesStyles.ChartBigText>
        <ClusterServicesStyles.ChartSmallText>
          Total
          <br />
          Services
        </ClusterServicesStyles.ChartSmallText>
      </ClusterServicesStyles.ChartTextContainer>
    </ClusterServicesStyles.ChartContainer>
  );
};

export default ClusterServicesChart;
