import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { HttpMethodBubble, HttpMethodBubbleContainer } from 'Components/Common/HttpMethodBubble';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getTimeAsSecondsString } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType, stringMatchTypeMap } from 'utils/types';

const { useGetCorsPolicyDetails } = policiesApi;

const policyType = PolicyType.CORS;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const CorsDetails = ({ clusterObjRef }: Props) => {
  di(useGetCorsPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetCorsPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <div style={{ marginBottom: '20px' }}>
          <StatMiniCardList
            items={[
              {
                icon: <Asset.OriginIcon />,
                title: 'Origins Allowed',
                data: !!policyData.allowedOrigins.length ? (
                  <SecondaryInfo
                    items={policyData.allowedOrigins.map(o => ({
                      label: stringMatchTypeMap[o.type].name,
                      data: o.matcher
                    }))}
                  />
                ) : (
                  'No origins configured'
                )
              },
              {
                icon: <Asset.HttpMethodIcon />,
                title: 'Allowed HTTP Methods',
                data: !!policyData.allowedMethods?.length ? (
                  <HttpMethodBubbleContainer>
                    {policyData.allowedMethods.map((method: string) => (
                      <HttpMethodBubble key={method} method={method} />
                    ))}
                  </HttpMethodBubbleContainer>
                ) : (
                  'No HTTP methods configured'
                )
              }
            ]}
          />
        </div>
        <StatMiniCardList
          items={[
            {
              icon: <Asset.HeaderManipulationPolicyIcon />,
              title: 'Allowed Headers',
              data: policyData.allowedHeaders ? (
                <ul>
                  {policyData.allowedHeaders.map((v, i, a) => (
                    <li key={v}>{v}</li>
                  ))}
                </ul>
              ) : (
                'Allowed headers not configured'
              )
            },
            {
              icon: <Asset.MaxAgeIcon />,
              title: 'Max Age',
              data: policyData.maxAge ? getTimeAsSecondsString(policyData.maxAge) : 'Max age not configured'
            },
            {
              icon: <Asset.KeysIcon />,
              title: 'Allow Credentials',
              data:
                policyData.allowCredentials !== undefined
                  ? policyData.allowCredentials
                    ? 'True'
                    : 'False'
                  : 'Credentials not configured'
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
