import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { SoloInput } from './SoloInput';
import { SoloInputStyles } from './SoloInput.style';

const SoloLabelInputContainer = styled(FlexLayout)(
  ({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.aprilGrey};
    ${SoloInputStyles.InputContainer} {
      ${SoloInputStyles.Input} {
        border: none;
        width: 100%;
      }
      flex-grow: 1;
      &:first-of-type {
        flex-basis: 85px;
      }
      &:nth-of-type(2) {
        flex-basis: 60px;
      }
    }
  `
);

const SoloLabelInput = ({
  labelKey,
  onLabelKeyChange,
  labelValue,
  onLabelValueChange
}: {
  labelKey: string;
  onLabelKeyChange: (newKey: string) => void;
  labelValue: string;
  onLabelValueChange: (newKey: string) => void;
}) => {
  return (
    <SoloLabelInputContainer gap='3px' alignItems='center'>
      <SoloInput
        value={labelKey}
        placeholder='Label key'
        aria-label='Filter by label key'
        onChangeValue={onLabelKeyChange}
      />
      :
      <SoloInput
        value={labelValue}
        placeholder='value'
        aria-label='Filter by label value'
        onChangeValue={onLabelValueChange}
      />
    </SoloLabelInputContainer>
  );
};

export default SoloLabelInput;
