import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { ResourceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug_pb';
import { buildIdFromRef } from 'utils/helpers';
import { ResourcesLandingBodyCard } from './ResourcesLandingBodyCard';
import { ResourcesLandingBodyTable } from './ResourcesLandingBodyTable';

export const ResourcesLandingBody = (props: CustomLandingBodyProps<ResourceSummary>) => {
  return <LandingBody {...props} GridComponent={ResourcesLandingBodyGrid} TableComponent={ResourcesLandingBodyTable} />;
};

//
// Grid variant
//
const ResourcesLandingBodyGrid = (props: BaseLandingBodyProps<ResourceSummary>) => {
  const { items: resourceList, pagingData } = props;
  return (
    <>
      <OverviewGridStyles.Grid>
        {resourceList.map(resource => (
          <li key={buildIdFromRef(resource.ref) + resource.type}>
            <ResourcesLandingBodyCard resource={resource} />
          </li>
        ))}
      </OverviewGridStyles.Grid>
      {!!pagingData.total && <SoloPagination {...pagingData} pageSizeOptions={[4, 8, 16, 32]} />}
    </>
  );
};
