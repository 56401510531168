import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { SoloModalStyles } from 'Components/Common/SoloModal.style';
import { RouteAction } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useState } from 'react';
import { di } from 'react-magnetic-di';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { buildIdFromRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { Permission, usePermissions } from 'utils/permissions';
import { buildApiDetailsUrl, buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { RoutesListDisplayStyles as Styles } from '../../RoutesListDisplay.style';
import { RouteForwardToDestinationList } from './RouteForwardToDestinationList';

const GraphQLLink = ({ link, children }: { link: string; children: React.ReactNode }) => {
  di(usePermissions);
  const { hasPerm } = usePermissions();
  return hasPerm(Permission.GraphQLEnabled) ? <SoloLink link={link}>{children}</SoloLink> : <div>{children}</div>;
};

export const HttpRouteActionSection = ({
  action,
  routeTableRef,
  parentRef
}: {
  action: RouteAction | undefined;
  routeTableRef: ClusterObjectRef | undefined;
  parentRef: ClusterObjectRef;
}) => {
  di(useNamespaceClusterToWorkspaceMap);
  const [directResponseBody, setDirectResponseBody] = useState<string>();
  const { lookupWorkspaceName } = useNamespaceClusterToWorkspaceMap();

  const actionType = action?.actionType;
  return (
    <div>
      <Styles.RouteContentHeading>Action</Styles.RouteContentHeading>
      {actionType?.oneofKind === 'forwardTo' && (
        <>
          <Styles.ActionLabel>Forward to Destination</Styles.ActionLabel>
          <RouteForwardToDestinationList destinationsList={actionType.forwardTo.destinations} parentRef={parentRef} />
        </>
      )}
      {actionType?.oneofKind === 'delegate' && (
        <>
          <Styles.ActionLabel>Delegate</Styles.ActionLabel>
          <Styles.ForwardsGrid>
            {actionType.delegate.routeTables.map(rt => (
              <SoloLink
                key={buildIdFromRef(rt.ref)}
                link={buildWorkspaceRouteDetailsUrl(lookupWorkspaceName(rt.ref), RouteType.ROUTE_TABLE, rt.ref)}>
                <Styles.IconText>
                  <HealthIndicator status={rt.status} size={8} />
                  {rt.ref?.name}
                </Styles.IconText>
              </SoloLink>
            ))}
          </Styles.ForwardsGrid>
        </>
      )}
      {actionType?.oneofKind === 'directResponse' && (
        <>
          <Styles.ActionLabel>Direct Response</Styles.ActionLabel>
          <Styles.ForwardsGrid>
            <SecondaryInfoItem label='Status Code' data={actionType.directResponse.statusCode} />
            <SoloButton small onClick={() => setDirectResponseBody(actionType.directResponse.body)}>
              View
            </SoloButton>
          </Styles.ForwardsGrid>
          {directResponseBody !== undefined && (
            <SoloModal visible width={960} onClose={() => setDirectResponseBody(undefined)}>
              <SoloModalStyles.SimpleTitle>
                Response Body for {actionType.directResponse.statusCode}
              </SoloModalStyles.SimpleTitle>
              <CardStyles.Card>
                <FancyCodeDisplayer type='tsx' contentString={directResponseBody} />
              </CardStyles.Card>
            </SoloModal>
          )}
        </>
      )}
      {actionType?.oneofKind === 'redirect' && (
        <>
          <Styles.ActionLabel>Redirect to</Styles.ActionLabel>
          <SecondaryInfo
            items={[
              { label: 'Status Code', data: actionType.redirect.statusCode },
              {
                label: 'Host',
                data: actionType.redirect.hostRedirect || <Styles.RouteItemsNone>{'<none>'}</Styles.RouteItemsNone>
              },
              {
                label: 'Path',
                data: actionType.redirect.pathRedirect || <Styles.RouteItemsNone>{'<none>'}</Styles.RouteItemsNone>
              }
            ]}
          />
        </>
      )}
      {actionType?.oneofKind === 'graphql' && (
        <>
          <Styles.ActionLabel>GraphQL</Styles.ActionLabel>
          <Styles.ForwardsGrid>
            <React.Fragment key={actionType.graphql.istioRouteName}>
              <GraphQLLink
                link={buildApiDetailsUrl({ routeTableRef, istioRouteName: actionType.graphql.istioRouteName })}>
                <Styles.IconText>
                  <Asset.GraphqlIcon /> {actionType.graphql.istioRouteName}
                </Styles.IconText>
              </GraphQLLink>
            </React.Fragment>
          </Styles.ForwardsGrid>
        </>
      )}
    </div>
  );
};
