import { policiesApi } from 'Api/policies';
import { routingApi } from 'Api/routing';
import { Asset } from 'assets';
import { DataError } from 'Components/Common/DataError';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { SoloLink } from 'Components/Common/SoloLink';
import { HorizontalListCardsContainer, SoloListCard } from 'Components/Common/SoloListCard';
import { PagingProps, SoloPagination, useSoloPaging } from 'Components/Common/SoloPagination';
import { Svg } from 'Components/Common/Svg';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { RouteTableDetails } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { RoutesListDisplay } from './RoutesListDisplay';
import { RouteTablesDisplayStyles as Styles } from './RouteTablesListDisplay.style';

const { useGetRouteTableDetails } = routingApi;
const { useGetPolicyRoutes } = policiesApi;

interface RouteTableDetailsItemBodyProps {
  details: RouteTableDetails;
  routeTableRef: ClusterObjectRef | undefined;
  pagingData: PagingProps;
  parentRef: ClusterObjectRef;
}
const RouteTableDetailsItemBody = ({ details, ...props }: RouteTableDetailsItemBodyProps) => {
  di(useNamespaceClusterToWorkspaceMap);
  const { lookupWorkspaceName, error: workspaceMapError } = useNamespaceClusterToWorkspaceMap();

  if (!!workspaceMapError) {
    return <DataError error={workspaceMapError} />;
  }
  return (
    <>
      <HorizontalListCardsContainer>
        <SoloListCard title='Host(s)' dataSource={details.hosts ?? []} />
        <SoloListCard
          title='Gateways'
          dataSource={
            details.virtualGateways.map(g => ({
              key: buildIdFromRef(g),
              data: (
                <SoloLink link={buildWorkspaceRouteDetailsUrl(lookupWorkspaceName(g), RouteType.VIRTUAL_GATEWAY, g)}>
                  {g.name}
                </SoloLink>
              )
            })) ?? []
          }
        />
        {!!details.workloads?.length && (
          <SoloListCard title='Workloads' dataSource={details.workloads.map(w => w.name)} />
        )}
      </HorizontalListCardsContainer>
      <div>
        <RoutesListDisplay {...props} routes={details.routes} />
      </div>
    </>
  );
};

interface RouteTableProps {
  ref?: ClusterObjectRef;
  ownerWorkspaceName?: string;
}

export const RouteTableDetailsItem = ({
  clusterObjRef,
  parentRef
}: {
  clusterObjRef?: ClusterObjectRef;
  parentRef: ClusterObjectRef;
}) => {
  di(useSoloPaging, useGetRouteTableDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();

  const { data: rtData, error: rtError } = useGetRouteTableDetails(clusterObjRef, apiPaginationObject);
  const routeTableDetails = rtData?.routeTableDetails;

  useSetPagingTotal(routeTableDetails?.totalRoutes);

  if (!!rtError) {
    return <DataError error={rtError} />;
  } else if (!routeTableDetails) {
    return <Loading message={`Retrieving ${clusterObjRef?.name} route table details...`} />;
  }

  return (
    <RouteTableDetailsItemBody
      details={routeTableDetails}
      routeTableRef={clusterObjRef}
      pagingData={pagingData}
      parentRef={parentRef}
    />
  );
};

export const PolicyRouteTableDetailsItem = ({
  clusterObjRef,
  policyRef,
  policyType
}: {
  clusterObjRef?: ClusterObjectRef;
  policyRef: ClusterObjectRef;
  policyType: PolicyType;
}) => {
  di(useSoloPaging, useGetPolicyRoutes);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();

  const { data: rtData, error: rtError } = useGetPolicyRoutes(
    policyRef,
    policyType,
    clusterObjRef,
    apiPaginationObject
  );
  const routeTableDetails = rtData?.routeTableDetails;

  useSetPagingTotal(routeTableDetails?.totalRoutes);

  if (!!rtError) {
    return <DataError error={rtError} />;
  } else if (!routeTableDetails) {
    return <Loading message={`Retrieving ${clusterObjRef?.name} route table details...`} />;
  }

  return (
    <RouteTableDetailsItemBody
      details={routeTableDetails}
      routeTableRef={clusterObjRef}
      pagingData={pagingData}
      parentRef={policyRef}
    />
  );
};

interface RouteTableItemProps {
  routeTable: RouteTableProps;
  parentRef: ClusterObjectRef;
  policyType?: PolicyType;
  startOpened?: boolean;
}
export const RouteTableItem = ({ routeTable, parentRef, policyType, startOpened = false }: RouteTableItemProps) => {
  const [opened, setOpened] = useState(startOpened);
  return (
    <Styles.RouteTableContainer data-testid='route-table-item'>
      <Styles.RouteTableHeader onClick={() => setOpened(open => !open)}>
        <Styles.RouteTableTitle>{routeTable.ref?.name}</Styles.RouteTableTitle>
        <SecondaryInfo
          items={[
            {
              label: 'workspace',
              data: routeTable.ownerWorkspaceName ?? 'unknown'
            },
            {
              label: 'cluster',
              data: routeTable.ref?.clusterName ?? 'unknown'
            },
            { label: 'namespace', data: routeTable.ref?.namespace ?? 'unknown' }
          ]}
        />
        <Styles.RouteTableHeaderArrow opened={opened}>
          <Asset.ArrowToggle />
        </Styles.RouteTableHeaderArrow>
      </Styles.RouteTableHeader>
      {opened &&
        (policyType ? (
          <PolicyRouteTableDetailsItem clusterObjRef={routeTable.ref} policyRef={parentRef} policyType={policyType} />
        ) : (
          <RouteTableDetailsItem clusterObjRef={routeTable.ref} parentRef={parentRef} />
        ))}
    </Styles.RouteTableContainer>
  );
};

interface RouteTablesListDisplayProps {
  routeTables: RouteTableProps[] | undefined;
  paging?: PagingProps;
  parentRef: ClusterObjectRef | 'routetable';
  policyType?: PolicyType;
}
export const RouteTablesListDisplay = ({ routeTables, paging, parentRef, ...props }: RouteTablesListDisplayProps) => {
  return (
    <div>
      {!!routeTables?.length ? (
        <>
          {routeTables.map(routeTable => (
            <RouteTableItem
              key={buildIdFromRef(routeTable.ref)}
              routeTable={routeTable}
              parentRef={parentRef === 'routetable' ? routeTable.ref! : parentRef}
              {...props}
            />
          ))}
          {!!paging && <SoloPagination {...paging} pageSizeOptions={[5, 10, 20, 50]} />}
        </>
      ) : (
        <SoloEmptySimple
          description='No route tables attached'
          icon={<Svg color={thm => thm.aprilGrey} asset={<Asset.RouteGroupIcon />} />}
        />
      )}
    </div>
  );
};
