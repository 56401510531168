import { Issue, NotificationBox, NotificationType } from 'Components/Common/NotificationBox';
import { Kind } from 'graphql';
import { useContext, useMemo } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlLandingContext } from '../context/GqlLandingContext';

const GqlSchemaCardWarningMessages = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { schema, includedSubscriptions } = gqlCtx;

  //
  // Creates Warning Messages
  //
  const supportedTypesWarningMessages = useMemo(() => {
    const messages = [] as Issue[];
    if (schema.definitions.some(d => d.kind === Kind.SCALAR_TYPE_DEFINITION))
      messages.push({ message: 'Custom scalar types are not supported' });
    if (includedSubscriptions.length > 0) messages.push({ message: 'Subscription types are not supported' });
    if (messages.length === 1)
      messages[0] = { message: 'This schema includes unsupported type definitions. ' + messages[0].message };
    return messages;
  }, [schema, includedSubscriptions]);

  //
  // Render
  //
  if (supportedTypesWarningMessages.length === 0) return null;
  return (
    <Spacer mb={2}>
      <NotificationBox
        type={NotificationType.WARNING}
        issues={supportedTypesWarningMessages}
        multipleIssuesMessage='This schema includes unsupported type definitions.'
      />
    </Spacer>
  );
};

export default GqlSchemaCardWarningMessages;
