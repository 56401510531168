import styled from '@emotion/styled';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';

export namespace AnalysisAndInsightsStyles {
  export const CardContentContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-bottom: -5px;

    > :first-of-type {
      margin-left: 0px;
    }
    > :last-of-type {
      margin-right: 0;
    }
  `;

  // Shared across insights and resources-scanned charts.
  export const ChartAndLegendOuterContainer = styled.div`
    height: fit-content;
    margin: 5px 10px;
  `;

  // For the insights chart.
  export const InsightsChartContainer = styled.div`
    position: relative;
    width: 140px;
    height: auto;
    margin-top: -5px;
  `;

  // Shared across insights and resources-scanned charts.
  // This expands to fit the canvas container's height/width.
  export const ChartTextContainer = styled.div`
    // TODO: using pointerEvents:none will enable the tooltip on the chart.
    // For now it isn't themed yet, so it's disabled.
    // pointerEvents: 'none',
    position: absolute;
    top: 5px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #325476;
    font-weight: 400;
    gap: 10px;
  `;

  export const ChartBigText = styled.div`
    font-size: 30px;
  `;

  export const ChartMediumText = styled(ChartBigText)`
    font-size: 16px;
  `;

  export const ChartSmallText = styled(ChartMediumText)`
    font-size: 14px;
    line-height: 1em;
    text-align: center;
  `;

  // This is the container for the ResourcesScanned radar chart canvas,
  // but it also contains the resources scanned total count text, so
  // position:relative and display:flex are needed here so that
  // the text will be centered and cover the canvas.
  export const ResourcesScannedContainer = styled.div`
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
  `;

  export const ResourcesScannedLabel = styled(ChartMediumText)`
    text-align: center;
    // Based on the browser order of wrapping the parent flex container will wrap before this text will try to; so sadly we have to force the issue
    @media screen and (max-width: 1292px) {
      /* SoloLinkLooks and !important both needed for the link arrow to behave properly on multiple lines when span is a block */
      ${SoloLinkStyles.SoloLinkLooks}, span {
        display: block !important;
      }
    }
  `;
}
