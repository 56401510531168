import cytoscape from 'cytoscape';
import { NodeType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { colors } from 'Styles';
import { getGraphItemColors } from 'Styles/graph';
import { GraphMetricsType, GraphStatus } from '../General/graph-selection-utils';
import { drawImage, ImageSource } from './canvas-image-draw-util';
import { CyCanvasData, getNodeOpacity } from './canvas-utils';

// Note: For info about Vite SVG imports, see https://vitejs.dev/guide/assets#importing-asset-as-url
import externalServiceHealthyNode from 'assets/graphing-images/workload-icons/external-service-icon-blue.svg';
import externalServiceIdleNode from 'assets/graphing-images/workload-icons/external-service-icon-gray.svg';
import externalServiceDangerNode from 'assets/graphing-images/workload-icons/external-service-icon-red.svg';
import externalServiceWarnNode from 'assets/graphing-images/workload-icons/external-service-icon-yellow.svg';
import gatewayHealthyNode from 'assets/graphing-images/workload-icons/gateway-icon-blue.svg';
import gatewayIdleNode from 'assets/graphing-images/workload-icons/gateway-icon-gray.svg';
import gatewayDangerNode from 'assets/graphing-images/workload-icons/gateway-icon-red.svg';
import gatewayWarnNode from 'assets/graphing-images/workload-icons/gateway-icon-yellow.svg';
import workloadDangerNode from 'assets/graphing-images/workload-icons/kubernetes-service-danger.svg';
import workloadHealthyNode from 'assets/graphing-images/workload-icons/kubernetes-service-healthy.svg';
import workloadIdleNode from 'assets/graphing-images/workload-icons/kubernetes-service-idle.svg';
import workloadWarnNode from 'assets/graphing-images/workload-icons/kubernetes-service-warn.svg';
import nonMeshServiceHealthyNode from 'assets/graphing-images/workload-icons/virtual-machine-icon-blue.svg';
import nonMeshServiceIdleNode from 'assets/graphing-images/workload-icons/virtual-machine-icon-gray.svg';
import nonMeshServiceDangerNode from 'assets/graphing-images/workload-icons/virtual-machine-icon-red.svg';
import nonMeshServiceWarnNode from 'assets/graphing-images/workload-icons/virtual-machine-icon-yellow.svg';
import unknownNode from 'assets/help-icon.svg';

const nodeImageMap: Record<number, Record<GraphStatus, ImageSource>> = {
  [NodeType.KUBERNETES_WORKLOAD]: {
    [GraphStatus.Healthy]: workloadHealthyNode,
    [GraphStatus.Idle]: workloadIdleNode,
    [GraphStatus.Warning]: workloadWarnNode,
    [GraphStatus.Error]: workloadDangerNode
  },
  [NodeType.MESH_WORKLOAD]: {
    [GraphStatus.Healthy]: workloadHealthyNode,
    [GraphStatus.Idle]: workloadIdleNode,
    [GraphStatus.Warning]: workloadWarnNode,
    [GraphStatus.Error]: workloadDangerNode
  },
  [NodeType.GATEWAY]: {
    [GraphStatus.Healthy]: gatewayHealthyNode,
    [GraphStatus.Idle]: gatewayIdleNode,
    [GraphStatus.Warning]: gatewayWarnNode,
    [GraphStatus.Error]: gatewayDangerNode
  },
  [NodeType.AMBIENT_WORKLOAD]: {
    [GraphStatus.Healthy]: workloadHealthyNode,
    [GraphStatus.Idle]: workloadIdleNode,
    [GraphStatus.Warning]: workloadWarnNode,
    [GraphStatus.Error]: workloadDangerNode
  },
  [NodeType.EXTERNAL_WORKLOAD]: {
    [GraphStatus.Healthy]: externalServiceHealthyNode,
    [GraphStatus.Idle]: externalServiceIdleNode,
    [GraphStatus.Warning]: externalServiceWarnNode,
    [GraphStatus.Error]: externalServiceDangerNode
  },
  [NodeType.LAMBDA_WORKLOAD]: {
    [GraphStatus.Healthy]: externalServiceHealthyNode,
    [GraphStatus.Idle]: externalServiceIdleNode,
    [GraphStatus.Warning]: externalServiceWarnNode,
    [GraphStatus.Error]: externalServiceDangerNode
  },
  [NodeType.VM_WORKLOAD]: {
    [GraphStatus.Healthy]: nonMeshServiceHealthyNode,
    [GraphStatus.Idle]: nonMeshServiceIdleNode,
    [GraphStatus.Warning]: nonMeshServiceWarnNode,
    [GraphStatus.Error]: nonMeshServiceDangerNode
  },
  // The following should never happen, but we don't want to crash if it does
  [NodeType.UNKNOWN_NODE_TYPE]: {
    [GraphStatus.Healthy]: unknownNode,
    [GraphStatus.Idle]: unknownNode,
    [GraphStatus.Warning]: unknownNode,
    [GraphStatus.Error]: unknownNode
  }
};

export function getNodeIcon(metricsType: GraphMetricsType, nodeType: NodeType, health: GraphStatus): ImageSource {
  switch (metricsType) {
    case GraphMetricsType.Http:
    case GraphMetricsType.Cilium:
      return nodeImageMap[nodeType][health];
    case GraphMetricsType.Tcp:
      // We show idle node icons like normal, but otherwise always show blue
      return health === GraphStatus.Idle ? nodeImageMap[nodeType][health] : nodeImageMap[nodeType][GraphStatus.Healthy];
  }
}

export function renderNodes(cy: cytoscape.Core, { layer, ctx }: CyCanvasData) {
  layer.resetTransform(ctx);
  layer.clear(ctx);
  layer.setTransform(ctx);

  // [ALL_NODES] Save default values so we don't overwrite them
  ctx.save();
  ctx.lineWidth = 2.5;

  cy.nodes()
    .filter(n => !n.data('containerType'))
    // Force hovered nodes on top
    .sort(n => (n.hasClass('hovered') ? 1 : -1))
    .forEach(node => {
      if (
        node.data('idleHidden') === 'hide' ||
        node.data('nameHidden') === 'hide' ||
        node.data('typeHidden') === 'hide'
      ) {
        // In this case, rather than even just 'hiding' the node, we need to not draw it at all. The equivalent
        // of 'display none'.
      } else {
        // Start drawing
        ctx.save();

        // Opacity determination
        ctx.globalAlpha = getNodeOpacity(node);

        // Position and sizing
        const { x, y } = node.position();
        const nodeHeight = node.height();
        let outlineRadius = nodeHeight / 2;

        let nodeType = node.data('nodeType') as NodeType;
        const health = node.data('health') as GraphStatus;
        const metricsType = node.data('metricsType') as GraphMetricsType;

        if (!nodeImageMap[nodeType]) {
          nodeType = NodeType.KUBERNETES_WORKLOAD;
        }

        ctx.fillStyle = node.data('externalFromSearch') ? colors.marchGrey : 'transparent';

        if (node.hasClass('hovered')) {
          // Whole circle should increase in size slightly when hovered
          outlineRadius += 1.5;

          ctx.save();

          ctx.globalAlpha *= 0.8;
          ctx.lineWidth = 5;
          ctx.strokeStyle = getGraphItemColors(metricsType, health)?.nodeHoverOutline ?? 'white';
          drawCircleOutline(ctx, x, y, outlineRadius + 2.5);

          ctx.restore();
        }

        // Outline circle + icon
        ctx.strokeStyle = node.style('border-color');
        // Outline
        drawCircleOutline(ctx, x, y, outlineRadius);

        // Icon
        const nodeIconSize = 30;
        drawImage(
          ctx,
          getNodeIcon(metricsType, nodeType, health),
          x - nodeIconSize * 0.5,
          y - nodeIconSize * 0.5,
          nodeIconSize,
          nodeIconSize
        );

        ctx.restore();
      }

      // end drawing
    });

  // [/ALL_NODES] restore ctx to previous `save()`
  ctx.restore();
}

////////////////////////////////
// Canvas Helper Functions
////////////////////////////////

function drawCircleOutline(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number) {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.fill();
}
