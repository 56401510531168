import { CardStyles } from 'Components/Common/Card';
import { Loading } from 'Components/Common/Loading';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import { useContext } from 'react';
import { DashboardContext } from '../../DashboardContext';
import { DashboardStyles } from '../../DashboardLanding.style';
import AnalysisAndInsightsCardContent from './AnalysisAndInsightsCardContent';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';

const AnalysisAndInsightsCard = () => {
  //
  // Gets Analysis and Insight Card data
  //
  const { dashboardCardsData } = useContext(DashboardContext);
  const insightsOverview = dashboardCardsData?.insightsOverview;

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='analysis-and-insights-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.InsightAnalysisIcon />} color={theme => theme.neptuneBlue} />
        Analysis and Insights
      </DashboardStyles.Header>
      {!dashboardCardsData ? (
        <Loading />
      ) : !insightsOverview ? (
        <SoloEmptySimple description='No Data' icon={<Asset.InsightAnalysisIcon />} iconSize={75} greyscale />
      ) : (
        <AnalysisAndInsightsCardContent data={insightsOverview} />
      )}
    </CardStyles.Card>
  );
};

export default AnalysisAndInsightsCard;
