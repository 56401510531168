import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { CardStyles } from 'Components/Common/Card';
import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { CardFolderTab, CardFolderTabList } from 'Components/Common/Tabs';
import {
  DefinitionNode,
  DirectiveDefinitionNode,
  EnumTypeDefinitionNode,
  InputObjectTypeDefinitionNode,
  InterfaceTypeDefinitionNode,
  Kind,
  ObjectTypeDefinitionNode,
  ScalarTypeDefinitionNode,
  UnionTypeDefinitionNode
} from 'graphql';
import type { Location } from 'history';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorMessage } from 'Styles/CommonEmotions/errors';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { IGqlSchemaTypeInfo, IGqlSchemaTypeInfoIndexed } from 'utils/graphql-schema-search-helpers';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlDetailsStyles as Styles } from './GqlDetails.style';
import GqlSchemaCardWarningMessages from './GqlSchemaCardWarningMessages';
import GqlShowSchemaButton from './Schema/GqlShowSdlButton';
import GqlSchemaSearchInput from './Schema/Search/GqlSchemaSearchInput';
import GqlDirectiveTab from './Schema/Tabs/DirectiveTab/GqlDirectiveTab';
import GqlEnumTab from './Schema/Tabs/EnumTab/GqlEnumTab';
import GqlInputTab from './Schema/Tabs/InputTab/GqlInputTab';
import GqlInterfaceTab from './Schema/Tabs/InterfaceTab/GqlInterfaceTab';
import GqlMutationTab from './Schema/Tabs/MutationTab/GqlMutationTab';
import GqlObjectTab from './Schema/Tabs/ObjectTab/GqlObjectTab';
import GqlQueryTab from './Schema/Tabs/QueryTab/GqlQueryTab';
import GqlScalarTab from './Schema/Tabs/ScalarTab/GqlScalarTab';
import GqlUnionTab from './Schema/Tabs/UnionTab/GqlUnionTab';

const HASH_SEPARATOR = encodeURI('>');

interface SchemaRouteInfo {
  tabIndex: number;
  path: string[];
}

/**
 * Extracts schema routing info from the URL hash.
 * The hash follows the format: "schema_<tabIndex>_<itemName>_<restOfPath>".
 * @returns
 */
function getUrlHashRouteInfo(location: Location): SchemaRouteInfo {
  const defaultInfo = { tabIndex: 0, path: [] };
  //
  // Return the default tab index and path if it is invalid.
  const hashStart = `#schema${HASH_SEPARATOR}`;
  if (location.hash?.substring(0, hashStart.length) !== hashStart) return defaultInfo;
  const hashParts = location.hash.split(HASH_SEPARATOR);
  if (hashParts.length < 2) return defaultInfo;
  //
  // Return the tab index and rest of path from the hash.
  let tabIndex = Number.parseInt(hashParts[1]);
  if (isNaN(tabIndex)) tabIndex = 0;
  if (hashParts.length < 3) return { tabIndex, path: [] };
  return { tabIndex, path: hashParts.slice(2) };
}

const GqlSchemaCard = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { schema } = gqlCtx;
  //
  // Schema Type Definitions
  //
  const typeDefinitions = useMemo(() => {
    const getDefsOfType = <T extends DefinitionNode>(filterFn: (value: DefinitionNode) => boolean) =>
      schema.definitions.filter(filterFn) as T[];
    return [
      {
        tabHeader: 'Query',
        testId: 'query-tab-button',
        definitions:
          getDefsOfType<ObjectTypeDefinitionNode>(
            d => d.kind === Kind.OBJECT_TYPE_DEFINITION && d.name.value.toLowerCase() === 'query'
          )[0]?.fields ?? [],
        Component: GqlQueryTab
      },
      {
        tabHeader: 'Mutation',
        testId: 'mutation-tab-button',
        definitions:
          getDefsOfType<ObjectTypeDefinitionNode>(
            d => d.kind === Kind.OBJECT_TYPE_DEFINITION && d.name.value.toLowerCase() === 'mutation'
          )[0]?.fields ?? [],
        Component: GqlMutationTab
      },
      {
        tabHeader: 'Object',
        testId: 'object-tab-button',
        definitions: getDefsOfType<ObjectTypeDefinitionNode>(
          d => d.kind === Kind.OBJECT_TYPE_DEFINITION && !['query', 'mutation'].includes(d.name.value.toLowerCase())
        ),
        Component: GqlObjectTab
      },
      {
        tabHeader: 'Enum',
        testId: 'enum-tab-button',
        definitions: getDefsOfType<EnumTypeDefinitionNode>(d => d.kind === Kind.ENUM_TYPE_DEFINITION),
        Component: GqlEnumTab
      },
      {
        tabHeader: 'Input',
        testId: 'input-tab-button',
        definitions: getDefsOfType<InputObjectTypeDefinitionNode>(d => d.kind === Kind.INPUT_OBJECT_TYPE_DEFINITION),
        Component: GqlInputTab
      },
      {
        tabHeader: 'Interface',
        testId: 'interface-tab-button',
        definitions: getDefsOfType<InterfaceTypeDefinitionNode>(d => d.kind === Kind.INTERFACE_TYPE_DEFINITION),
        Component: GqlInterfaceTab
      },
      {
        tabHeader: 'Directive',
        testId: 'directive-tab-button',
        definitions: getDefsOfType<DirectiveDefinitionNode>(d => d.kind === Kind.DIRECTIVE_DEFINITION),
        Component: GqlDirectiveTab
      },
      {
        tabHeader: 'Union',
        testId: 'union-tab-button',
        definitions: getDefsOfType<UnionTypeDefinitionNode>(d => d.kind === Kind.UNION_TYPE_DEFINITION),
        Component: GqlUnionTab
      },
      {
        tabHeader: 'Scalar',
        testId: 'scalar-tab-button',
        definitions: getDefsOfType<ScalarTypeDefinitionNode>(d => d.kind === Kind.SCALAR_TYPE_DEFINITION),
        Component: GqlScalarTab
      }
    ] as IGqlSchemaTypeInfo[];
  }, [schema.definitions]);

  // Adds the original index for each type, and filters out the
  // types with no definitions.
  const indexedTypeDefinitions: IGqlSchemaTypeInfoIndexed[] = useMemo(
    () => typeDefinitions.filter(td => td.definitions.length > 0).map((td, i) => ({ ...td, index: i })),
    [typeDefinitions]
  );

  //
  // Routing
  //
  const location = useLocation();
  const navigate = useNavigate();
  const [routeInfo, setRouteInfo] = useState<SchemaRouteInfo | undefined>();

  // 1. When the page loads and/or URL hash changes, check for updates to the tabIndex and focusedItem.
  useEffect(() => {
    const newRouteInfo = getUrlHashRouteInfo(location);
    // If the hash doesn't exist yet, set it to the default.
    // window.replace makes it so this doesn't add anything to the history stack.
    if (!location.hash) window.location.replace(window.location.href + `#schema${HASH_SEPARATOR}0`);
    setRouteInfo(newRouteInfo);
  }, [location.hash]);

  /**
   * This finds the tab index and the schema node, then updates the URL hash to focus it.
   * If `searchForTheTab===false`, the first item in the `itemPath` (formatted like `"item1/item2/item3..."`)
   * is the tab header.
   */
  const onTypeClick = (itemPath: string, searchForTheTab: boolean) => {
    //
    // `pathToSearch` is a queue of type names to search for.
    // Items are dequeued (`pathToSearch.shift()`) when they are found.
    // If `searchForTheTab`===false, the first item is the tab header.
    // Some examples:
    //   ['Object','User','id'], searchForTheTab===false -> "Object" is the tab header.
    //   ['Product','id'], searchForTheTab===true --------> The tab header is found in the schema.
    const pathToSearch = itemPath.split('/').map(s => s.trim());
    if (pathToSearch.length === 0) return;
    //
    // Find the IGqlSchemaTypeInfoIndexed object, which has the tab index and definitions.
    let foundTabType: IGqlSchemaTypeInfoIndexed | undefined = undefined;
    let restOfHash = '';
    if (searchForTheTab) {
      foundTabType = indexedTypeDefinitions.find(td => td.definitions.some(d => d.name.value === pathToSearch[0]));
    } else {
      foundTabType = indexedTypeDefinitions.find(td => td.tabHeader === pathToSearch[0]);
      pathToSearch.shift();
    }
    if (!foundTabType) return;
    //
    // Search through the rest of the path.
    if (pathToSearch.length > 0) {
      //
      // Find the definition
      const theDefinition = foundTabType.definitions?.find(td => td.name.value === pathToSearch[0]);
      if (!theDefinition) return;
      restOfHash = `${HASH_SEPARATOR}${theDefinition.name.value}`;
      pathToSearch.shift();
      //
      // Find the field
      if (pathToSearch.length > 0) {
        const theField = (theDefinition as ObjectTypeDefinitionNode).fields?.find(
          td => td.name.value === pathToSearch[0]
        );
        if (!theField) return;
        restOfHash += `${HASH_SEPARATOR}${theField.name.value}`;
      }
    }
    // Update the URL hash.
    let newHash = `#schema${HASH_SEPARATOR}${foundTabType.index}${restOfHash}`;
    if (location.hash !== newHash) navigate(newHash);
  };

  //
  // Render
  //
  if (routeInfo === undefined) return null;
  return (
    <CardStyles.Card data-testid='gql-schema-card'>
      <ErrorBoundary fallback={<ErrorMessage>There was an error parsing your GraphQL schema...</ErrorMessage>}>
        <Styles.SchemaSection>
          <Styles.StyledCardHeader>
            <div>
              <Spacer mr={5} display='inline-block'>
                Schema
              </Spacer>
              <GqlShowSchemaButton />
            </div>
            {schema.definitions.length > 0 && <GqlSchemaSearchInput onTypeClick={onTypeClick} />}
          </Styles.StyledCardHeader>

          <GqlSchemaCardWarningMessages />

          <Tabs
            id='tabs'
            index={routeInfo.tabIndex}
            onChange={newIndex => setRouteInfo({ ...routeInfo, tabIndex: newIndex })}>
            {/* --- Tab Headers --- */}
            <CardFolderTabList>
              {indexedTypeDefinitions.map(({ index, testId, tabHeader }) => (
                <CardFolderTab
                  key={index}
                  index={index}
                  data-testid={testId}
                  onClick={() => onTypeClick(tabHeader, false)}>
                  {tabHeader}
                </CardFolderTab>
              ))}
            </CardFolderTabList>

            {/* --- Tab Content --- */}
            <TabPanels>
              {indexedTypeDefinitions.map(({ index, tabHeader, Component, definitions }) => (
                <TabPanel key={index} index={index}>
                  <Spacer padding={5}>
                    <Component
                      tabHeader={tabHeader}
                      definitions={definitions}
                      focusedPath={routeInfo.tabIndex === index ? routeInfo.path : []}
                      onTypeClick={onTypeClick}
                    />
                  </Spacer>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Styles.SchemaSection>
      </ErrorBoundary>
    </CardStyles.Card>
  );
};

export default GqlSchemaCard;
