import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { getStatusColors } from 'utils/health-status';

export namespace DetailsHeaderCardStyles {
  interface HeaderInfoProps {
    state?: State;
  }
  export const HeaderInfo = styled(CardStyles.Card)<HeaderInfoProps>(({ state }) => {
    const { icon: color } = getStatusColors(state);
    return css`
      display: flex;
      gap: 15px;

      ${state !== State.ACCEPTED &&
      css`
        border-color: ${color};
        box-shadow: 0 0 5px ${color};
      `}
    `;
  });
  export const RightTools = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
  `;
  export const NameContainer = styled.div`
    margin-right: 20px;
    align-self: center;
  `;
  export const Name = styled.div`
    align-self: flex-end;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 8px;
    min-width: 150px;
  `;
}
