import { Pagination } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetExternalServiceDetailsRequest,
  GetKubernetesServiceDetailsRequest,
  GetSchemaForKubernetesServiceRequest,
  GetVirtualDestinationDetailsRequest
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/destinations_pb';
import { DestinationsApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/destinations_pb.client';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import { grpcWebFetchTransport, soloGrpcCall, useRequestSkipRef } from './helpers';

export namespace destinationsApi {
  const client = new DestinationsApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.DestinationsApi/' + capitalizeFirstLetters(fn.name);

  //
  // Get Kubernetes Service Details
  //
  export async function getKubernetesServiceDetails(
    destinationRef?: ClusterObjectRef,
    policiesPagination?: Pagination
  ) {
    let request: GetKubernetesServiceDetailsRequest = { destinationRef, policiesPagination };
    return soloGrpcCall(client.getKubernetesServiceDetails(request));
  }
  export function useGetKubernetesServiceDetails(...args: Parameters<typeof getKubernetesServiceDetails>) {
    return useRequestSkipRef(
      getKubernetesServiceDetails,
      args,
      getMethodDescriptorName(client.getKubernetesServiceDetails)
    );
  }

  //
  // Get External Service Details
  //
  export async function getExternalServiceDetails(
    destinationRef?: ClusterObjectRef,
    externalEndpointsPagination?: Pagination,
    policiesPagination?: Pagination
  ) {
    let request: GetExternalServiceDetailsRequest = { destinationRef, externalEndpointsPagination, policiesPagination };
    return soloGrpcCall(client.getExternalServiceDetails(request));
  }
  export function useGetExternalServiceDetails(...args: Parameters<typeof getExternalServiceDetails>) {
    return useRequestSkipRef(
      getExternalServiceDetails,
      args,
      getMethodDescriptorName(client.getExternalServiceDetails)
    );
  }

  //
  // Get Virtual Destination Details
  //
  export async function getVirtualDestinationDetails(
    destinationRef?: ClusterObjectRef,
    destinationServicesPagination?: Pagination,
    policiesPagination?: Pagination
  ) {
    const request: GetVirtualDestinationDetailsRequest = {
      destinationRef,
      destinationServicesPagination,
      policiesPagination
    };
    return soloGrpcCall(client.getVirtualDestinationDetails(request));
  }
  export function useGetVirtualDestinationDetails(...args: Parameters<typeof getVirtualDestinationDetails>) {
    return useRequestSkipRef(
      getVirtualDestinationDetails,
      args,
      getMethodDescriptorName(client.getVirtualDestinationDetails)
    );
  }

  //
  // Get Schema for Kube Service
  //
  export async function getSchemaForKubernetesService(serviceRef?: ClusterObjectRef) {
    let request: GetSchemaForKubernetesServiceRequest = { serviceRef };
    return soloGrpcCall(client.getSchemaForKubernetesService(request));
  }
  export function useGetSchemaForKubernetesService(...args: Parameters<typeof getSchemaForKubernetesService>) {
    return useRequestSkipRef(
      getSchemaForKubernetesService,
      args,
      getMethodDescriptorName(client.getSchemaForKubernetesService)
    );
  }
}
