import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TabList, TabPanel, Tabs } from '@reach/tabs';
import { Properties } from 'csstype';

export namespace TabsStyles {
  type SelectableTabProps = {
    isSelected?: boolean;
  };

  export const FolderTabHolder = styled.div<SelectableTabProps>(
    ({ theme, isSelected }) => css`
      button {
        margin-right: 5px;
        padding: 10px 16px 6px;
        border-radius: 10px 10px 0 0;
        border-bottom-color: ${theme.marchGrey} !important;
        border: 1px solid ${theme.marchGrey};
        background: ${theme.januaryGrey};
        color: ${theme.septemberGrey};
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        cursor: pointer;

        ${!!isSelected &&
        css`
          border-bottom-color: white !important;
          border-bottom: 1px solid white;
          cursor: default;
          z-index: 10;
          background: white;
          font-weight: 500;
        `};

        &:focus {
          outline: none;
        }
      }
    `
  );

  export const FolderTabContent = styled.div(
    ({ theme }) => css`
      padding: 23px 20px 25px;
      border-radius: 10px;
      border: 1px solid ${theme.marchGrey};
      margin-top: -1px;
    `
  );

  export const FolderTabList = styled(TabList)`
    display: flex;
    margin-left: 30px;
    background: transparent;
  `;

  export const StyledTabPanel = styled(TabPanel)`
    &:focus {
      outline: none;
    }
  `;

  export const SubpageTabHolder = styled.div<SelectableTabProps>(
    ({ theme, isSelected }) => css`
      button {
        margin-bottom: 16px;
        padding: 10px 16px;
        width: 185px;
        border-radius: 10px;
        border: 1px solid ${theme.juneGrey};
        background: white;
        color: ${theme.septemberGrey};
        font-size: 16px;
        text-align: left;
        cursor: pointer;

        ${isSelected &&
        css`
          cursor: default;
          background: ${theme.dropBlue};
          color: ${theme.seaBlue};
          border: 1px solid ${theme.seaBlue};
        `};

        &:focus {
          outline: none;
        }
      }
    `
  );

  export const SubpageTabs = styled(Tabs)`
    display: grid !important;
    grid-template-columns: 190px 1fr;

    > [data-reach-tab-list] {
      background: transparent;
    }
  `;

  export const CardFolderTabHolder = styled.div<SelectableTabProps & { width?: string }>(
    ({ theme, isSelected, hidden, width = '120px' }) => css`
      display: ${!!hidden ? 'none' : 'block'};
      button {
        padding: 10px 16px;
        width: ${width};
        border-bottom: 3px solid transparent;
        color: ${theme.juneGrey};
        font-size: 16px;
        text-align: center;
        box-sizing: content-box;
        cursor: pointer;

        &:hover {
          color: ${theme.septemberGrey};
        }

        ${isSelected &&
        css`
          cursor: default;
          color: ${theme.novemberGrey};
          border-bottom-color: ${theme.seaBlue} !important;
        `};

        &:focus {
          outline: none;
          background: none;
        }
      }
    `
  );

  export const CardFolderTabList = styled(TabList)<{ margin?: Properties['margin'] }>(
    ({ margin = '3px 6px 0' }) => css`
      display: flex;
      flex-wrap: wrap;
      margin: ${margin};
      background: transparent;
    `
  );

  export const CardFolderTabListWithBottomBorder = styled(CardFolderTabList)(
    ({ theme }) => css`
      border-bottom: 1px solid ${theme.splashBlue};
    `
  );
}
