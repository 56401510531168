/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/percent.proto" (package "envoy.type", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Percent]

/**
 * Identifies a percentage, in the range [0.0, 100.0].
 *
 * @generated from protobuf message envoy.type.Percent
 */
export interface Percent {
    /**
     * @generated from protobuf field: double value = 1;
     */
    value: number;
}
/**
 * A fractional percentage is used in cases in which for performance reasons performing floating
 * point to integer conversions during randomness calculations is undesirable. The message includes
 * both a numerator and denominator that together determine the final fractional value.
 *
 * * **Example**: 1/100 = 1%.
 * * **Example**: 3/10000 = 0.03%.
 *
 * @generated from protobuf message envoy.type.FractionalPercent
 */
export interface FractionalPercent {
    /**
     * Specifies the numerator. Defaults to 0.
     *
     * @generated from protobuf field: uint32 numerator = 1;
     */
    numerator: number;
    /**
     * Specifies the denominator. If the denominator specified is less than the numerator, the final
     * fractional percentage is capped at 1 (100%).
     *
     * @generated from protobuf field: envoy.type.FractionalPercent.DenominatorType denominator = 2;
     */
    denominator: FractionalPercent_DenominatorType;
}
/**
 * Fraction percentages support several fixed denominator values.
 *
 * @generated from protobuf enum envoy.type.FractionalPercent.DenominatorType
 */
export enum FractionalPercent_DenominatorType {
    /**
     * 100.
     *
     * **Example**: 1/100 = 1%.
     *
     * @generated from protobuf enum value: HUNDRED = 0;
     */
    HUNDRED = 0,
    /**
     * 10,000.
     *
     * **Example**: 1/10000 = 0.01%.
     *
     * @generated from protobuf enum value: TEN_THOUSAND = 1;
     */
    TEN_THOUSAND = 1,
    /**
     * 1,000,000.
     *
     * **Example**: 1/1000000 = 0.0001%.
     *
     * @generated from protobuf enum value: MILLION = 2;
     */
    MILLION = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Percent$Type extends MessageType<Percent> {
    constructor() {
        super("envoy.type.Percent", [
            { no: 1, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/, options: { "validate.rules": { double: { lte: 100, gte: 0 } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.Percent
 */
export const Percent = new Percent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FractionalPercent$Type extends MessageType<FractionalPercent> {
    constructor() {
        super("envoy.type.FractionalPercent", [
            { no: 1, name: "numerator", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "denominator", kind: "enum", T: () => ["envoy.type.FractionalPercent.DenominatorType", FractionalPercent_DenominatorType], options: { "validate.rules": { enum: { definedOnly: true } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.FractionalPercent
 */
export const FractionalPercent = new FractionalPercent$Type();
