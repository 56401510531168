import { Asset } from 'assets';
import React from 'react';
import { GraphLegend } from './GraphLegend';
import { LayoutSettings } from './LayoutSettings';
import { UsageInfoTabStyles, UsageInfoTopicEnum } from './UsageInfoTab.style';

interface Props {
  topicShown?: UsageInfoTopicEnum;
  setTopicShown: (newTopic?: UsageInfoTopicEnum) => void;
  toggleGroupBy: (groupBy: 'workspace' | 'infra') => void;
}
export const UsageInfoTab = ({ topicShown, setTopicShown, toggleGroupBy }: Props) => {
  return (
    <UsageInfoTabStyles.Swivel className='usageInfoTabSwivel' topicShown={topicShown}>
      <UsageInfoTabStyles.Container>
        <UsageInfoTabStyles.CloseXContainer onClick={() => setTopicShown(undefined)}>
          <Asset.CloseX />
        </UsageInfoTabStyles.CloseXContainer>

        {topicShown === UsageInfoTopicEnum.Legend ? <GraphLegend /> : <LayoutSettings toggleGroupBy={toggleGroupBy} />}

        <UsageInfoTabStyles.TabsHolder className='tabsHolder' topicShown={topicShown}>
          <UsageInfoTabStyles.TabsSpinner>
            <UsageInfoTabStyles.Tab
              isActive={topicShown === UsageInfoTopicEnum.Legend}
              onClick={() => setTopicShown(UsageInfoTopicEnum.Legend)}>
              Legend
            </UsageInfoTabStyles.Tab>
            <UsageInfoTabStyles.Tab
              isActive={topicShown === UsageInfoTopicEnum.Settings}
              onClick={() => setTopicShown(UsageInfoTopicEnum.Settings)}>
              Layout Settings
            </UsageInfoTabStyles.Tab>
          </UsageInfoTabStyles.TabsSpinner>
        </UsageInfoTabStyles.TabsHolder>
      </UsageInfoTabStyles.Container>
    </UsageInfoTabStyles.Swivel>
  );
};
