import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { FieldDefinitionNode, ObjectTypeDefinitionNode } from 'graphql';
import { useContext, useMemo } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlSchemaFieldDefinitionItem from '../GqlSchemaFieldDefinitionItem';
import GqlSchemaFieldDefinitionTable from '../GqlSchemaFieldDefinitionTable';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';
import GqlTabItemInterfaceImplementations from '../GqlTabItemInterfaceImplementations';

const GqlQueryTab = ({ definitions, onTypeClick, focusedPath, tabHeader }: GqlSchemaTabProps<FieldDefinitionNode>) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { schema } = gqlCtx;
  // This gets the "Query" object definition.
  const queryObjectDefinition = useMemo(
    () =>
      schema.definitions.find(d => 'name' in d && d.name?.value.toLowerCase() === 'query') as
        | ObjectTypeDefinitionNode
        | undefined,
    [schema.definitions]
  );

  // This gets the "clicked-into" or "focused" item definition from the breadcrumbs.
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={3}>
        <GqlDetailsStyles.LightText>
          Define the top-level entry point for read operation queries that clients execute against your server.
        </GqlDetailsStyles.LightText>
      </Spacer>
      {!focusedItem ? (
        <>
          <GqlTabItemInterfaceImplementations node={queryObjectDefinition} onTypeClick={onTypeClick} />
          <GqlTabItemDirectiveList node={queryObjectDefinition} onTypeClick={onTypeClick} />
          <GqlTabItemDescription node={queryObjectDefinition} />
        </>
      ) : (
        <GqlSchemaFieldDefinitionItem
          objectName='query'
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          focusedPath={focusedPath}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlSchemaFieldDefinitionTable
        objectName='query'
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
        tabHeader={tabHeader}
        showArgumentsColumnIfNoArgs
      />
    </>
  );
};

export default GqlQueryTab;
