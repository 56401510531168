import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from '../Card';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { LoadingStyles } from '../Loading.style';

export namespace OverviewStyles {
  export const Container = styled(CardStyles.Card)`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
  `;

  export namespace Header {
    export const Header = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `;
    export const Title = styled.h1(({ theme }) => {
      return css`
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 28px;

        ${svgBasics(24, theme.seaBlue)}

        ${LoadingStyles.Rotater} {
          width: 24px;
          height: 24px;
          ${svgBasics(24)}
        }
      `;
    });

    export const FiltersWrapper = styled.div`
      display: flex;
      align-items: center;
    `;
    export const Filters = styled.div<{ filters: number; hasTableToggle?: boolean; filterColumnOverride?: string }>(
      ({ filters, filterColumnOverride, hasTableToggle = true }) => css`
        display: grid;
        // loading spinner / filters / table toggle
        grid-template-columns: ${filterColumnOverride ??
          `repeat(${filters}, minmax(100px, ${filters < 4 ? '200px' : '160px'}))`} ${hasTableToggle ? '30px' : ''};
        align-items: stretch;
        gap: 15px;
        align-items: center;
        margin-left: 15px;
      `
    );

    export const RefreshIndicatorHolder = styled.div`
      position: relative;
    `;
  }

  export const TagsListHolder = styled.div(
    ({ theme }) => css`
      background: ${theme.januaryGrey};
      padding-left: 0px;
      margin: 15px -18px 0;

      transition: padding 0.3s ease-out;
    `
  );
}
