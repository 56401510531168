import { css } from '@emotion/react';
import 'antd/dist/antd.css';
import '@patternfly/patternfly/patternfly.css';
import '@patternfly/react-core/dist/styles/base.css';
import './graphiql.min.css';
import './fontFace.css';
import { antdGlobalStyles } from './antd-overrides.style';
import { siteGlobalStyles } from './site.style';

export const globalStyles = css`
  ${antdGlobalStyles}
  ${siteGlobalStyles}
`;
