/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/logs.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Empty } from "../../../../../../../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetContainerLogsStreamRequest
 */
export interface GetContainerLogsStreamRequest {
    /**
     * @generated from protobuf field: string cluster_name = 1;
     */
    clusterName: string;
    /**
     * @generated from protobuf field: string component = 2;
     */
    component: string;
    /**
     * @generated from protobuf field: string pod_name = 3;
     */
    podName: string;
    /**
     * @generated from protobuf field: string container_name = 4;
     */
    containerName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetContainerLogsStreamResponse
 */
export interface GetContainerLogsStreamResponse {
    /**
     * @generated from protobuf field: repeated string logs = 1;
     */
    logs: string[];
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 2;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.AttributesResponse
 */
export interface AttributesResponse {
    /**
     * mapping is cluster name -> component -> pod name -> container name
     *
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.AttributesResponse.Components> attributes = 1;
     */
    attributes: {
        [key: string]: AttributesResponse_Components;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.AttributesResponse.Components
 */
export interface AttributesResponse_Components {
    /**
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.AttributesResponse.Components.Pods> components = 1;
     */
    components: {
        [key: string]: AttributesResponse_Components_Pods;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.AttributesResponse.Components.Pods
 */
export interface AttributesResponse_Components_Pods {
    /**
     * @generated from protobuf field: map<string, rpc.gloo.solo.io.AttributesResponse.Components.Pods.Containers> pods = 1;
     */
    pods: {
        [key: string]: AttributesResponse_Components_Pods_Containers;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.AttributesResponse.Components.Pods.Containers
 */
export interface AttributesResponse_Components_Pods_Containers {
    /**
     * @generated from protobuf field: repeated string containers = 1;
     */
    containers: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetContainerLogsStreamRequest$Type extends MessageType<GetContainerLogsStreamRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetContainerLogsStreamRequest", [
            { no: 1, name: "cluster_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "component", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pod_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "container_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetContainerLogsStreamRequest
 */
export const GetContainerLogsStreamRequest = new GetContainerLogsStreamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetContainerLogsStreamResponse$Type extends MessageType<GetContainerLogsStreamResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetContainerLogsStreamResponse", [
            { no: 1, name: "logs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetContainerLogsStreamResponse
 */
export const GetContainerLogsStreamResponse = new GetContainerLogsStreamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttributesResponse$Type extends MessageType<AttributesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.AttributesResponse", [
            { no: 1, name: "attributes", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AttributesResponse_Components } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.AttributesResponse
 */
export const AttributesResponse = new AttributesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttributesResponse_Components$Type extends MessageType<AttributesResponse_Components> {
    constructor() {
        super("rpc.gloo.solo.io.AttributesResponse.Components", [
            { no: 1, name: "components", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AttributesResponse_Components_Pods } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.AttributesResponse.Components
 */
export const AttributesResponse_Components = new AttributesResponse_Components$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttributesResponse_Components_Pods$Type extends MessageType<AttributesResponse_Components_Pods> {
    constructor() {
        super("rpc.gloo.solo.io.AttributesResponse.Components.Pods", [
            { no: 1, name: "pods", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AttributesResponse_Components_Pods_Containers } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.AttributesResponse.Components.Pods
 */
export const AttributesResponse_Components_Pods = new AttributesResponse_Components_Pods$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttributesResponse_Components_Pods_Containers$Type extends MessageType<AttributesResponse_Components_Pods_Containers> {
    constructor() {
        super("rpc.gloo.solo.io.AttributesResponse.Components.Pods.Containers", [
            { no: 1, name: "containers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.AttributesResponse.Components.Pods.Containers
 */
export const AttributesResponse_Components_Pods_Containers = new AttributesResponse_Components_Pods_Containers$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.LogsApi
 */
export const LogsApi = new ServiceType("rpc.gloo.solo.io.LogsApi", [
    { name: "GetContainerLogsStream", serverStreaming: true, options: {}, I: GetContainerLogsStreamRequest, O: GetContainerLogsStreamResponse },
    { name: "GetAvailableComponentInfo", options: {}, I: Empty, O: AttributesResponse }
]);
