import { css } from '@emotion/react';
import { colors } from 'Styles/colors';

export default css`
  // ========================== //
  // SCROLLBAR
  // This mostly matches the graphiql theme explorer design,
  // but might not be what we want for the whole site.
  // TODO: there aren't colors that exactly match the design.
  //
  // Width
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  // Track
  ::-webkit-scrollbar-track {
    background: ${colors.splashBlue};
    border-radius: 5px;
  }
  // Handle
  ::-webkit-scrollbar-thumb {
    background: ${colors.augustGrey};
    border-radius: 5px;
  }
  // Handle on hover
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.septemberGrey};
  }

  // ========================== //
  // STORYBOOK PLACEHOLDERS
  //
  // This removes extra padding from storybook and
  // inserts navbar and footer placeholders to test
  // the page layout.
  .sb-show-main.sb-main-padded {
    padding: 0px;
    padding-bottom: 60px;
    padding-top: 50px;
    &:before,
    &:after {
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      background-color: ${colors.marchGrey};
      color: ${colors.juneGrey};
    }
    &:before {
      content: '[Footer Placeholder]';
      bottom: 0px;
      left: 0px;
      height: 60px;
      border-top: 1px solid ${colors.aprilGrey};
    }
    &:after {
      content: '[Top Navbar Placeholder]';
      top: 0px;
      left: 0px;
      height: 50px;
      border-bottom: 1px solid ${colors.aprilGrey};
    }
  }
`;
