import { CardStyles } from 'Components/Common/Card';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { SoloTableStyles } from 'Components/Common/SoloTable.style';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { Asset } from 'assets';
import {
  PolicyWorkload,
  PolicyWorkload_WorkloadKind
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { useEffect, useState } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { workloadKindMap } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';

type TableFields = {
  key: string;
  name: StateAndNameType;
  kind: PolicyWorkload_WorkloadKind;
  namespace?: string;
  clusterName?: string;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  {
    title: 'Type',
    dataIndex: 'kind',
    render(kind) {
      return (
        <SoloTableStyles.CellIconText>
          {workloadKindMap[kind].icon} {workloadKindMap[kind].name}
        </SoloTableStyles.CellIconText>
      );
    }
  },
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' })
];

interface Props {
  workloads: PolicyWorkload[] | undefined;
  paging: PagingProps;
}
export const BasicWorkloadsTable = ({ workloads, paging }: Props) => {
  const [tableData, setTableData] = useState<TableFields[]>([]);

  useEffect(() => {
    if (!!workloads) {
      setTableData(
        workloads.map(data => ({
          ...data,
          ...data.ref,
          key: buildIdFromRef(data.ref),
          name: { name: data.ref?.name ?? '', status: data.status }
        }))
      );
    } else {
      setTableData([]);
    }
  }, [workloads]);

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={paging}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.WorkloadIcon />}
          title='There are no Workloads to display'
          description=''
          href={docLinks.enterprise.policies_overview_HASH.policy_resources}
        />
      }
    />
  );
};

export const BasicWorkloadsTableCard = (props: Props) => {
  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>
        Workloads
        <CardStyles.CardHeaderRightIcon>
          <Asset.WorkloadIcon />
        </CardStyles.CardHeaderRightIcon>
      </CardStyles.CardHeader>

      <BasicWorkloadsTable {...props} />
    </CardStyles.Card>
  );
};
