import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { TagList } from 'Components/Common/SoloTag';
import { insightSeverityMap } from 'utils/dashboard/dashboard-helpers';
import { insightCodeGroupMap } from 'utils/dashboard/dashboard-types';
import { InsightsLandingFiltersObject } from '../InsightsLanding';

const InsightsLandingTagsList = ({
  filters,
  onFiltersChange
}: {
  filters: InsightsLandingFiltersObject;
  onFiltersChange(newFilters: InsightsLandingFiltersObject): void;
}) => {
  //
  // Render
  //
  return (
    <OverviewStyles.TagsListHolder>
      <TagList
        includePadding
        tags={[
          {
            value: 'Clear all',
            color: thm => thm.butternutOrange,
            onClose: () =>
              onFiltersChange({
                clusters: [],
                codeGroups: [],
                searchText: '',
                severities: []
              })
          },
          ...(filters.searchText
            ? [
                {
                  label: 'Search',
                  value: filters.searchText,
                  onClose: () => {
                    onFiltersChange({
                      ...filters,
                      searchText: ''
                    });
                  }
                }
              ]
            : []),
          ...filters.codeGroups.map(group => ({
            label: 'Type',
            value: insightCodeGroupMap[group],
            onClose: () => {
              onFiltersChange({
                ...filters,
                codeGroups: filters.codeGroups.filter(g => g !== group)
              });
            }
          })),
          ...filters.severities.map(sev => ({
            label: 'Severity',
            value: insightSeverityMap[sev].displayValue,
            onClose: () => {
              onFiltersChange({
                ...filters,
                severities: filters.severities.filter(s => s !== sev)
              });
            }
          })),
          ...filters.clusters.map(cluster => ({
            label: 'Cluster',
            value: cluster,
            onClose: () => {
              onFiltersChange({
                ...filters,
                clusters: filters.clusters.filter(c => c !== cluster)
              });
            }
          }))
        ]}
      />
    </OverviewStyles.TagsListHolder>
  );
};

export default InsightsLandingTagsList;
