import React, { createContext, useContext } from 'react';
import { useSidebarStorage } from 'utils/local-storage';

interface SidebarContextProps {
  collapsed: boolean;
  toggleCollapse: () => void;
}

export const SidebarContext = createContext<SidebarContextProps>({} as any);

export const useSidebarContext = () => {
  return useContext(SidebarContext);
};

export const SidebarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ closed: collapsed }, setSidebarStorage] = useSidebarStorage();

  const toggleCollapse = () => {
    setSidebarStorage({ closed: !collapsed });
  };

  return <SidebarContext.Provider value={{ collapsed, toggleCollapse }}>{children}</SidebarContext.Provider>;
};
