import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { hexAddAlpha } from 'Styles/colors';
import { SoloPaginationStyles } from './SoloPagination.style';

export namespace SoloTableStyles {
  export const ClusterCell = styled.div(
    ({ theme }) => css`
      ${svgBasics(18, theme.seaBlue)}
      svg {
        margin-right: 5px;
        border-radius: 100%;
      }
    `
  );

  export const CellIconText = styled.span(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      gap: 10px;

      ${svgBasics(21, theme.oceanBlue)}
    `
  );

  export const TableHealthCircleHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;

    > div {
      width: 12px;
      height: 12px;
      margin-left: 12px;
    }
  `;

  export const TableActions = styled.div`
    display: grid;
    grid-template-columns: 18px 18px 18px;
    grid-gap: 5px;
  `;

  type TableActionCircleProps = {
    clickable: boolean;
  };
  export const TableActionCircle = styled.div<TableActionCircleProps>(
    ({ theme, clickable }) => css`
      width: 18px;
      height: 18px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      color: ${theme.novemberGrey};
      border-radius: 18px;
      ${clickable
        ? css`
            cursor: pointer;
          `
        : css`
            cursor: default;
            pointer-events: none;
          `}

      background: ${theme.marchGrey};

      &:hover,
      &:focus {
        background: ${theme.juneGrey};
      }

      &:active {
        background: ${theme.marchGrey};
      }

      ${svgBasics(10, theme.septemberGrey)}
    `
  );

  export const TableNotificationCircle = styled.div`
    width: 18px;
    height: 18px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    border-radius: 18px;
    cursor: pointer;
  `;

  export const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.03);
  `;

  export const CustomEmpty = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 47px;
    box-sizing: content-box;
    max-height: 160px; /* must match antd empty height set in TableContainer */
    height: 100%;
    padding: 16px; /* must match cell padding */
  `;

  type TableContainerHeadingProps = {
    flatTopped?: boolean;
    darkHeader?: boolean;
  };
  type TableContainerProps = {
    removeBorder?: boolean;
    rowHeight?: string;
    alignWithPagination?: boolean;
    isEmpty: boolean;
    hasBottomRowBorder: boolean;
    rowsAreClickable: boolean;
  };
  export const TableContainer = styled.div<TableContainerHeadingProps & TableContainerProps>(
    ({
      theme,
      flatTopped,
      removeBorder,
      rowHeight,
      darkHeader,
      alignWithPagination,
      isEmpty,
      hasBottomRowBorder,
      rowsAreClickable
    }) => css`
      position: relative;
      width: 100%;
      border-radius: 3px;
      padding: 0 10px;

      ${!!rowsAreClickable &&
      css`
        tbody.ant-table-tbody tr.ant-table-row {
          cursor: pointer;
        }
      `}

      .ant-table {
        border-radius: 3px;

        .ant-table-container {
          border-radius: 3px;
          ::before {
            display: none;
          }

          .ant-table-content {
            border-radius: 3px;
            ${!!alignWithPagination &&
            css`
              th.ant-table-cell {
                padding: 12px 5px;
              }
            `}
          }
        }
      }

      ${removeBorder ||
      css`
        border: 1px solid ${theme.splashBlue};
      `}

      table {
        background: ${hexAddAlpha(theme.marchGrey, 0.15)};
        width: 100%;

        border-radius: 3px;

        .ant-table-thead {
          ${!flatTopped &&
          css`
            border-radius: 3px 3px 0 0;
          `};

          tr {
            background: ${darkHeader ? theme.marchGrey : theme.januaryGrey};

            th {
              background: transparent;
              cursor: default;
              font-weight: 500;
              padding: 12px 20px;
              color: ${theme.novemberGrey};
              border-bottom: 1px solid ${theme.splashBlue};
              text-align: left;
              // While we do want text to wrap in header cells, we don't wants words to be split
              overflow-wrap: normal;

              &:before {
                display: none;
              }
            }
          }

          ${!flatTopped
            ? css`
                tr:first-of-type {
                  > th:first-of-type {
                    border-top-left-radius: 3px;
                  }
                  > th:last-of-type {
                    border-top-right-radius: 3px;
                  }
                }
              `
            : ''};
        }

        .ant-table-tbody {
          background: white;

          .ant-table-row {
            position: relative;

            :not(:last-of-type) {
              border-bottom: 1px solid ${theme.splashBlue};
            }

            ${hasBottomRowBorder === false
              ? css`
                  :last-of-type td {
                    border-bottom: none;
                  }
                `
              : ''}

            > td {
              position: relative;
              border-color: ${theme.splashBlue};
              cursor: ${!rowsAreClickable && 'default'};
              padding: ${!!alignWithPagination ? '0 20px 0 0' : '0 20px'};
              height: ${rowHeight ?? '46px'};
              color: ${theme.augustGrey};
              background: transparent;
              border-bottom: 1px solid ${theme.splashBlue};
              z-index: 2;
            }
            // This breaks the antd table ellipses cells.
            // So we disable it for that case.
            :not(:has(.ant-table-cell-ellipsis)) {
              &:hover {
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: -10px;
                  right: -10px;
                  bottom: 0;
                  background: ${hexAddAlpha(theme.marchGrey, 0.5)};
                }
              }
            }
          }

          tr:last-of-type {
            > td:first-of-type {
              border-bottom-left-radius: 3px;
            }
            > td:last-of-type {
              border-bottom-right-radius: 3px;
            }
          }

          ${!!isEmpty
            ? css`
                tr:last-child {
                  > td {
                    border-bottom: 0;
                  }
                }
              `
            : ''}
        }

        .ant-empty {
          visibility: hidden;
          height: 160px;
          margin: 0;

          * {
            display: none;
          }
        }
      }
      ${SoloPaginationStyles.PaginationRow} {
        margin-top: 12px;
        padding-bottom: 12px;
      }
    `
  );

  export const FakeTableHeaderBackground = styled.div<TableContainerHeadingProps & { height?: number }>(
    ({ theme, flatTopped, darkHeader, height = 47 }) => css`
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: ${height}px;
      background: ${darkHeader ? theme.marchGrey : theme.januaryGrey};
      border-bottom: 1px solid ${theme.splashBlue};

      ${!flatTopped &&
      css`
        border-radius: 3px 3px 0 0;
      `};
    `
  );
}
