import React, { ComponentProps } from 'react';
import { css } from '@emotion/react';
import { ColorOrAllowed, ColorThemeFunc, handleColorOrColorThemeFunc } from 'Styles/colors';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

interface IconProps {
  width?: number | string;
  height?: number | string;
  svgColor?: ColorOrAllowed | ColorThemeFunc; // Called `svgColor` to avoid `color` type weirdness & messing with `shouldForwardProp`
  inline?: boolean;
}
const SvgContainer = styled(Spacer)<IconProps>(
  ({ width, height, svgColor: color, inline, theme }) => css`
    display: ${inline ? 'inline-flex' : 'flex'};
    align-items: center;
    justify-items: center;

    ${svgBasics({ width, height }, handleColorOrColorThemeFunc(color, theme))}
  `
);

export const SvgWithTextContainer = styled(Spacer)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

type SpacerProps = Omit<ComponentProps<typeof Spacer>, 'color'>; // see `svgColor` above on why we do this
type SvgProps = SpacerProps & {
  asset: React.ReactNode;
  color?: ColorOrAllowed | ColorThemeFunc;
  width?: number | `${number}%`;
  height?: number | `${number}%`;
  size?: number | `${number}%`; // sets both width and height
  inline?: boolean;
};
export const Svg = ({ asset, color, size, width = size, height = size, ...props }: SvgProps) => {
  return (
    <SvgContainer svgColor={color} width={width} height={height} {...props}>
      {asset}
    </SvgContainer>
  );
};
