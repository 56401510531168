import { HttpMetrics_RequestLatencies } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { reduceListAverage } from '../get-graph-items-data';
import { DetailsTabStyles } from './DetailsTab.style';

const latencyCell = (plist: number[]) => {
  const reduced = reduceListAverage(plist);
  return !!plist && reduced > 0 ? (
    <>
      {reduceListAverage(plist).toFixed(2)}
      <DetailsTabStyles.Unit>ms</DetailsTabStyles.Unit>
    </>
  ) : (
    '--'
  );
};

interface Props {
  requestLatencies: HttpMetrics_RequestLatencies;
}
export const LatencySection = ({ requestLatencies }: Props) => {
  return (
    <>
      <DetailsTabStyles.SectionHorizontalRule />
      <DetailsTabStyles.SectionTitle>Incoming Latency</DetailsTabStyles.SectionTitle>

      <DetailsTabStyles.GridTable columns={['1fr', '1fr', '1fr']}>
        {/*Top row */}
        <DetailsTabStyles.GridTH>P50</DetailsTabStyles.GridTH>
        <DetailsTabStyles.GridTH>P90</DetailsTabStyles.GridTH>
        <DetailsTabStyles.GridTH>P99</DetailsTabStyles.GridTH>

        <div>{latencyCell(requestLatencies.p50)}</div>
        <div>{latencyCell(requestLatencies.p90)}</div>
        <div>{latencyCell(requestLatencies.p99)}</div>
      </DetailsTabStyles.GridTable>
    </>
  );
};
