import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { SoloCheckboxListDropdown } from 'Components/Common/Input/SoloCheckboxList';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloGridTableToggle, { isTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { OverviewTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import {
  ApiType,
  State
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { getEnumValues } from 'utils/helpers';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { apiTypeMap } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';
import { ApisLandingBody } from './ApisLandingBody';

const { useListApis } = overviewApi;

const apiTypeOptions = getEnumValues(ApiType)
  .filter(type => type !== ApiType.UNKNOWN)
  .map(type => {
    const { name: label, icon } = apiTypeMap[type];
    return { key: type, label, value: type, icon };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

export const ApisLanding = () => {
  //
  // State
  //
  di(useListApis);
  const { pagingData, useSetPagingTotal, apiPaginationObject } = useSoloPaging(OverviewTablePageSize);
  usePermissionGateRedirect(Permission.CanViewApisPage);
  const [isTable, setIsTable] = useState(isTableViewPreferred());
  const [nameFilter, setNameFilter] = useState('');
  const [typesFilter, setTypesFilter] = useState<ApiType[]>([]);
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const filtersOn = !!nameFilter.length || !!typesFilter.length || !!statusFilter.length;

  const { data: listApisResponse, error: listApisError } = useListApis(
    apiPaginationObject,
    nameFilter,
    typesFilter,
    statusFilter
  );
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(listApisResponse);

  useSetPagingTotal(listApisResponse?.filteredTotalApis);

  //
  // Render
  //
  const isLoading = listApisResponse === undefined;
  return (
    <OverviewStyles.Container data-testid='apis-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.ApiIcon />}
          API Registry
        </OverviewStyles.Header.Title>
        {!listApisError && (
          <OverviewStyles.Header.FiltersWrapper>
            <HealthCountBox
              onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
              onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
              isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
              isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
              totalCount={listApisResponse?.totalApis}
              errorCount={listApisResponse?.apiErrors}
            />
            <OverviewStyles.Header.Filters filters={2}>
              <SoloCheckboxListDropdown
                selectAll
                noSearch
                title={'Filter by...'}
                values={typesFilter}
                options={apiTypeOptions}
                onChange={setTypesFilter}
              />
              <SoloInput
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label={'Search by name'}
                onChange={evt => setNameFilter(evt.target.value)}
              />
              <SoloGridTableToggle isTable={isTable} onChange={newIsTable => setIsTable(newIsTable)} />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <ApisLandingBody
        filtersOn={filtersOn}
        items={listApisResponse?.apis ?? []}
        isLoading={isLoading}
        pagingData={pagingData}
        itemsError={listApisError}
        isTable={isTable}
        docsLink={docLinks.gateway.graphql}
        resourceNamePlural='APIs'
        icon={<Asset.ApiIcon />}
        data-testid={'apis-landing-body-empty'}
      />
    </OverviewStyles.Container>
  );
};
