import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { LandingBodyStyles } from 'Components/Common/Overview/LandingBody.style';

export namespace ServiceDetailsLandingStyles {
  export const Container = styled(CardStyles.CardList)(() => {
    return css`
      height: 100%;

      [data-reach-tabs] {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      [data-reach-tab-panels] {
        flex: 1;
      }
      [data-reach-tab-panel] {
        height: 100%;
      }
    `;
  });

  export const TracingWrapper = styled.div`
    position: relative;
    height: 100%;
    min-height: 650px;

    ${LandingBodyStyles.EmptyIconContainer} {
      margin-top: 0;
    }
    ${LandingBodyStyles.EmptyContainer} > div:last-of-type {
      padding-bottom: 0;
    }
  `;
}
