import styled from '@emotion/styled';
import { FancyCodeDisplayerStyles } from './FancyCodeDisplayer.style';
import { SoloModalStyles } from './SoloModal.style';

export namespace YamlModalStyles {
  export const Body = styled(SoloModalStyles.Body)`
    // modal top/bottom padding + body top/bottom padding forces Content to
    // fill remaining area and gain a fixed height
    max-height: calc(100vh - 100px - 50px - 18px - 18px);
    min-height: 400px;

    display: grid;
    grid-template-rows: auto 1fr;
  `;

  export const Content = styled.div`
    // Required to dynamically make child container's height match parent's allowed area to
    // force code block to be scrollable
    &,
    ${FancyCodeDisplayerStyles.RelativeHook}, [data-reach-tabs],
    [data-reach-tab-panels],
    [data-reach-tab-panel] {
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
    }
  `;
}
