import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { EmptyAsterisk } from 'Components/Common/EmptyAsterisk';
import ExpandableStringList from 'Components/Common/ExpandableStringList';
import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn } from 'Components/Common/SoloTableCells';
import { StringList } from 'Components/Common/StringList';
import { Svg, SvgWithTextContainer } from 'Components/Common/Svg';
import { YamlModal, YamlResource } from 'Components/Common/YamlModal';
import { CertificateDetailsModal } from 'Components/Features/Certificates/Modals/CertificateDetailsModal';
import { Insight_ReferencedCertificateSecret } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { ListIngressGatewaysResponse_IngressGatewaySummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { ReactNode, useMemo, useState } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { transformCertDataToModalProps } from 'utils/certificate-helpers';
import { buildIdFromRef } from 'utils/helpers';
import { resourceTypesMap } from 'utils/types';

type DetailsModalInfo = Parameters<typeof CertificateDetailsModal>[0];

const UncoloredLinkText = styled.span(({ theme }) => {
  return css`
    color: ${theme.augustGrey};
  `;
});

type ExtraPropsType = {
  onShowYaml: (resourceType: ResourceType, clusterObjectRef: ClusterObjectRef) => void;
};

export const TrafficIngressLandingBody = (
  props: CustomLandingBodyProps<ListIngressGatewaysResponse_IngressGatewaySummary>
) => {
  //
  // Yaml resource
  const [yamlResource, setYamlResource] = useState<YamlResource>();
  const showYaml = (resourceType: ResourceType, clusterObjectRef: ClusterObjectRef) => {
    setYamlResource({
      resourceType,
      clusterObjectRef,
      icon: resourceTypesMap[resourceType].icon
    });
  };

  //
  // Render
  //
  return (
    <>
      <LandingBody
        {...props}
        GridComponent={TrafficIngressLandingBodyGrid}
        TableComponent={TrafficIngressLandingBodyTable}
        extraProps={{ onShowYaml: showYaml }}
      />
      {!!yamlResource && <YamlModal yamlResource={yamlResource} onClose={() => setYamlResource(undefined)} />}
    </>
  );
};

interface CertDataProps {
  certificateName: string;
  certificatesList: Insight_ReferencedCertificateSecret[] | undefined;
  onClick: (data: DetailsModalInfo) => void;
}
const CertData = ({ certificateName, certificatesList, onClick }: CertDataProps) => {
  if (!certificatesList?.length) {
    return <>{certificateName}</>;
  }
  if (certificatesList.length === 1) {
    return (
      <SoloLinkStyles.SoloLinkLooksButton
        onClick={() => onClick(transformCertDataToModalProps('istio gateway', certificatesList[0].certificate))}>
        <SvgWithTextContainer>
          <UncoloredLinkText>{certificateName}</UncoloredLinkText>
          <Svg asset={<Asset.CertExpiryIcon />} size={20} color='currentColor' />
        </SvgWithTextContainer>
      </SoloLinkStyles.SoloLinkLooksButton>
    );
  }
  return (
    <Tooltip
      title={certificatesList.map(({ certificate }) => (
        <SoloLinkStyles.SoloLinkLooksButton
          key={'istio gateway' + certificate?.hostname}
          onClick={() => onClick(transformCertDataToModalProps('istio gateway', certificate))}>
          {certificate?.hostname ?? '<unknown>'}
        </SoloLinkStyles.SoloLinkLooksButton>
      ))}>
      <SoloLinkStyles.SoloLinkLooksButton>
        <SvgWithTextContainer>
          <UncoloredLinkText>{certificateName}</UncoloredLinkText>
          <Svg asset={<Asset.CertExpiryIcon />} size={20} color='currentColor' />
        </SvgWithTextContainer>
      </SoloLinkStyles.SoloLinkLooksButton>
    </Tooltip>
  );
};

//
// Grid variant
//
export const TrafficIngressLandingBodyGrid = ({
  items,
  pagingData,
  extraProps
}: BaseLandingBodyProps<ListIngressGatewaysResponse_IngressGatewaySummary, ExtraPropsType>) => {
  const onShowYaml = extraProps?.onShowYaml;
  const [detailsModalInfo, setDetailsModalInfo] = useState<DetailsModalInfo>();

  return (
    <>
      <OverviewGridStyles.Grid data-testid='traffic-ingress-landing-body'>
        {items.map(gw => {
          let metaInfo: { label: string; data: NonNullable<ReactNode> }[] = [];
          const missingRef = !gw.ingressGatewayRef;
          const ref = gw.ingressGatewayRef ??
            gw.ingressGatewayRef ?? {
              apiGroup: 'Unknown',
              kind: 'Unknown',
              name: 'Unknown',
              namespace: 'Unknown',
              clusterName: 'Unknown'
            };
          metaInfo.push(
            { label: 'Name', data: ref.name },
            // TODO: Update resource type when k8s gateways are returned.
            { label: 'Type', data: resourceTypesMap[ResourceType.GATEWAY].name },
            { label: 'Cluster', data: ref.clusterName },
            { label: 'Namespace', data: ref.namespace },
            { label: 'Port', data: gw.port },
            { label: 'Domain', data: gw.domain === '*' ? <EmptyAsterisk /> : gw.domain }
          );
          if (!!gw.certificateName) {
            metaInfo.push({
              label: 'Certificate',
              data: (
                <CertData
                  certificateName={gw.certificateName}
                  certificatesList={gw.certificates?.certificates}
                  onClick={setDetailsModalInfo}
                />
              )
            });
          }
          return (
            <li key={buildIdFromRef(ref)}>
              <div data-testid={`${ref.name}-gateway-card`} aria-label={`Gateway Card: ${ref.name}`}>
                <OverviewGridStyles.Card title={missingRef ? 'Missing data to visit gateway page.' : undefined}>
                  <OverviewGridStyles.CardHeader>{ref.name ?? 'Gateway Unnamed'}</OverviewGridStyles.CardHeader>
                  <OverviewGridStyles.CardContent>
                    <SecondaryInfo items={metaInfo} small={true} />
                  </OverviewGridStyles.CardContent>
                  <OverviewGridStyles.CardFooter>
                    <SecondaryInfoItem small label='Backend Services' data={<StringList list={gw.backingServices} />} />
                    {!!ref && (
                      <SoloLinkStyles.SoloLinkLooksButton
                        onClick={e => {
                          e.preventDefault();
                          if (!!gw.ingressGatewayRef) {
                            onShowYaml?.(ResourceType.GATEWAY, gw.ingressGatewayRef);
                          } else {
                            console.warn(`Resource ref not returned for gateway.`); // eslint-disable-line no-console
                          }
                        }}
                        withArrow>
                        View YAML
                      </SoloLinkStyles.SoloLinkLooksButton>
                    )}
                  </OverviewGridStyles.CardFooter>
                </OverviewGridStyles.Card>
              </div>
            </li>
          );
        })}
      </OverviewGridStyles.Grid>
      {!!pagingData.total && <SoloPagination {...pagingData} pageSizeOptions={[3, 6, 12, 24]} />}
      {detailsModalInfo && (
        <CertificateDetailsModal {...detailsModalInfo} onClose={() => setDetailsModalInfo(undefined)} />
      )}
    </>
  );
};

//
// Table variant
//
interface TableFields {
  key: string;
  name: string;
  resourceType: ResourceType;
  namespace: string;
  clusterName: string;
  port: string;
  domain: string;
  cert: CertDataProps;
  services: string[];
  yaml: { onClick?: () => void };
}
const columns: SoloColumnsType<TableFields> = [
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  {
    title: 'Type',
    dataIndex: 'resourceType',
    render: resourceType => <>{resourceTypesMap[resourceType].name}</>
  },
  { title: 'Port', dataIndex: 'port' },
  {
    title: 'Domain',
    dataIndex: 'domain',
    render: domain => (domain === '*' ? <EmptyAsterisk /> : <>{domain}</>)
  },
  {
    title: 'Certificate',
    dataIndex: 'cert',
    render: cert => <CertData {...cert} />
  },
  {
    title: 'Backend Services',
    dataIndex: 'services',
    render: services => <ExpandableStringList items={services} />
  },
  {
    title: 'Details',
    dataIndex: 'yaml',
    render({ onClick }) {
      return (
        !!onClick && (
          <SoloLinkStyles.SoloLinkLooksButton onClick={onClick} withArrow>
            VIEW YAML
          </SoloLinkStyles.SoloLinkLooksButton>
        )
      );
    }
  }
];

const TrafficIngressLandingBodyTable = ({
  items,
  pagingData,
  extraProps
}: BaseLandingBodyProps<ListIngressGatewaysResponse_IngressGatewaySummary, ExtraPropsType>) => {
  const onShowYaml = extraProps!.onShowYaml;
  const [detailsModalInfo, setDetailsModalInfo] = useState<DetailsModalInfo>();

  const tableData = useMemo<TableFields[]>(
    () =>
      items.map(gw => ({
        key: buildIdFromRef(gw.ingressGatewayRef) + gw.domain,
        name: gw.ingressGatewayRef?.name ?? 'Unnamed',
        // TODO: Update resource type when k8s gateways are returned.
        resourceType: ResourceType.GATEWAY,
        clusterName: gw.ingressGatewayRef?.clusterName ?? 'Unnamed',
        namespace: gw.ingressGatewayRef?.namespace ?? 'Unnamed',
        port: gw.port,
        domain: gw.domain,
        cert: {
          certificateName: gw.certificateName,
          certificatesList: gw.certificates?.certificates,
          onClick: setDetailsModalInfo
        },
        services: gw.backingServices,
        yaml: {
          onClick: () => {
            if (!!gw.ingressGatewayRef) {
              onShowYaml?.(ResourceType.GATEWAY, gw.ingressGatewayRef);
            } else {
              console.warn(`Resource ref not returned for gateway.`); // eslint-disable-line no-console
            }
          }
        }
      })),
    [items, onShowYaml]
  );

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable
        data-testid='traffic-ingress-landing-body'
        dataSource={tableData}
        columns={columns}
        paging={pagingData}
      />
      {detailsModalInfo && (
        <CertificateDetailsModal {...detailsModalInfo} onClose={() => setDetailsModalInfo(undefined)} />
      )}
    </Spacer>
  );
};
