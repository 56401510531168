import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { httpMetricsListToCounts } from 'Components/Features/Graph/General/DetailsTab/DetailsTab';
import { DetailsTabStyles } from 'Components/Features/Graph/General/DetailsTab/DetailsTab.style';
import { prettierNumbersBySpace } from 'Components/Features/Graph/General/DetailsTab/NodeDetails';
import { isHttpMetricsDefined, reduceListAverage } from 'Components/Features/Graph/General/get-graph-items-data';
import {
  EdgeMetrics,
  NodeMetrics
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { useMemo } from 'react';

function renderLatency(p?: number) {
  return !!p && p > 0 ? (
    <>
      {p.toFixed(2)}
      <DetailsTabStyles.Unit>ms</DetailsTabStyles.Unit>
    </>
  ) : (
    '--'
  );
}

interface TableFields {
  key: string;
  direction: string;
  rps: string;
  errorPerc: string;
  p50?: number;
  p90?: number;
  p99?: number;
}
const columns: SoloColumnsType<TableFields> = [
  { title: 'Direction', dataIndex: 'direction' },
  { title: 'RPS', dataIndex: 'rps' },
  { title: '%Error', dataIndex: 'errorPerc' },
  { title: 'P50 Latency', dataIndex: 'p50', render: renderLatency },
  { title: 'P90 Latency', dataIndex: 'p90', render: renderLatency },
  { title: 'P99 Latency', dataIndex: 'p99', render: renderLatency }
];

export type Props = {
  serviceName: string;
  serviceNamespace: string;
  serviceCluster: string;
};

export const ServiceDetailsSignalsTable = ({
  metrics,
  edgesIn,
  edgesOut
}: {
  metrics: NodeMetrics | undefined;
  edgesIn: EdgeMetrics[];
  edgesOut: EdgeMetrics[];
}) => {
  const incomingMetrics = edgesIn?.map(n => n.httpMetrics).filter(isHttpMetricsDefined) ?? [];
  const outgoingMetrics = edgesOut?.map(n => n.httpMetrics).filter(isHttpMetricsDefined) ?? [];
  const inCounts = httpMetricsListToCounts(incomingMetrics);
  const outCounts = httpMetricsListToCounts(outgoingMetrics);

  const tableData = useMemo<TableFields[]>(
    () => [
      {
        key: 'in',
        direction: 'In',
        rps: prettierNumbersBySpace(inCounts.rps),
        errorPerc: !!inCounts.failureCount
          ? prettierNumbersBySpace(Math.round((inCounts.failureCount / inCounts.requestCount) * 100)) + '%'
          : '--',
        p50: reduceListAverage(metrics?.incomingMetrics?.requestLatencies?.p50),
        p90: reduceListAverage(metrics?.incomingMetrics?.requestLatencies?.p90),
        p99: reduceListAverage(metrics?.incomingMetrics?.requestLatencies?.p99)
      },
      {
        key: 'out',
        direction: 'Out',
        rps: prettierNumbersBySpace(outCounts.rps),
        errorPerc: !!outCounts.failureCount
          ? prettierNumbersBySpace(Math.round((outCounts.failureCount / outCounts.requestCount) * 100)) + '%'
          : '--',
        p50: reduceListAverage(metrics?.outgoingMetrics?.requestLatencies?.p50),
        p90: reduceListAverage(metrics?.outgoingMetrics?.requestLatencies?.p90),
        p99: reduceListAverage(metrics?.outgoingMetrics?.requestLatencies?.p99)
      }
    ],
    [metrics]
  );

  return <SoloTable columns={columns} dataSource={tableData} />;
};
