import styled from '@emotion/styled';
import { SoloLabelStyles } from 'Components/Common/Input/SoloLabel';

export namespace GqlExplorerSettingsModalStyles {
  export const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
  `;

  export const LabelRow = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 6px;
  `;
  export const Label = styled(SoloLabelStyles.Label)`
    margin-bottom: 0;
  `;
  export const RightSideFlexor = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
  `;
}
