import styled from '@emotion/styled';
import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport';
import { DataError } from 'Components/Common/DataError';
import { Asset } from 'assets';
import { useGraphWorkspacesUsable } from './graph-filter-utils';

const EmptyGraphContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 390px);
`;

export const EmptyGraph = ({ hideEmptyGraphFiltersMessage }: { hideEmptyGraphFiltersMessage?: boolean }) => {
  const workspacesUsable = useGraphWorkspacesUsable();
  return (
    <EmptyGraphContainer data-testid='graph-no-data'>
      <DataError
        error={{
          code: GrpcStatusCode[GrpcStatusCode.OK],
          message: !!hideEmptyGraphFiltersMessage
            ? ''
            : `Please select ${workspacesUsable ? 'at least one workspace or ' : ''
            }at least one namespace and one cluster by using the filters.`
        }}
        errorTitle={'No Data'}
        icon={<Asset.NoGraphPlaceholder />}
        iconWidth={133}
        isCalm={true}
      />
    </EmptyGraphContainer>
  );
};
