import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { MonospaceText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import {
  LoadBalancerPolicySpec_Config_ConsistentHashLB,
  LoadBalancerPolicySpec_Config_SimpleLB
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/trafficcontrol/load_balancer_policy_pb';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getTimeAsSecondsString } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { BasicDestinationsTable } from '../../Destinations/BasicDestinationsTable';

const { useGetLoadBalancerPolicyDetails } = policiesApi;

const policyType = PolicyType.LOAD_BALANCER;

const simpleAlgorithmMap: Record<LoadBalancerPolicySpec_Config_SimpleLB, { name: string; desc: string }> = {
  [LoadBalancerPolicySpec_Config_SimpleLB.UNSPECIFIED]: {
    name: 'Unspecified',
    desc: 'No load balancing algorithm has been specified by the user. Istio will select an appropriate default.'
  },

  [LoadBalancerPolicySpec_Config_SimpleLB.RANDOM]: {
    name: 'Random',
    desc: 'The random load balancer selects a random healthy host. The random load balancer generally performs better than round robin if no health checking policy is configured.'
  },

  [LoadBalancerPolicySpec_Config_SimpleLB.PASSTHROUGH]: {
    name: 'Passthrough',
    desc: 'This option will forward the connection to the original IP address requested by the caller without doing any form of load balancing. This option must be used with care. It is meant for advanced use cases. Refer to Original Destination load balancer in Envoy for further details.'
  },

  [LoadBalancerPolicySpec_Config_SimpleLB.ROUND_ROBIN]: {
    name: 'Round Robin',
    desc: 'A basic round robin load balancing policy. This is generally unsafe for many scenarios (e.g. when endpoint weighting is used) as it can overburden endpoints. In general, we recommend using "Least Request" as a drop-in replacement for "Round Robin".'
  },

  [LoadBalancerPolicySpec_Config_SimpleLB.LEAST_REQUEST]: {
    name: 'Least Request',
    desc: 'The least request load balancer spreads load across endpoints, favoring endpoints with the least outstanding requests. This is generally safer and outperforms "Round Robin" in nearly all cases. Prefer to use "Least Request" as a drop-in replacement for "Round Robin".'
  }
};

const ErrorTriangleStyled = styled(Asset.ErrorTriangle)`
  .bg {
    fill: none;
  }
`;

const SimpleCard = ({ simple }: { simple: GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer }) => {
  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>
        Load Balancing
        <CardStyles.CardHeaderSecondaryInfoHolder>
          <SecondaryInfo
            items={[
              { label: 'type', data: 'Simple', highlighted: true },
              {
                label: 'algorithm',
                data: simpleAlgorithmMap[simple.type].name
              }
            ]}
          />
        </CardStyles.CardHeaderSecondaryInfoHolder>
      </CardStyles.CardHeader>
      <p>{simpleAlgorithmMap[simple.type].desc}</p>
    </CardStyles.Card>
  );
};

const ConsistentHashCard = ({
  consistentHash: { hashKey }
}: {
  consistentHash: LoadBalancerPolicySpec_Config_ConsistentHashLB;
}) => {
  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>
        Load Balancing
        <CardStyles.CardHeaderSecondaryInfoHolder>
          <SecondaryInfo
            items={[
              { label: 'type', data: 'Consistent Hash', highlighted: true },
              {
                label: 'key',
                data:
                  hashKey.oneofKind === 'httpHeaderName'
                    ? 'HTTP Header'
                    : hashKey.oneofKind === 'httpCookie'
                    ? 'HTTP Cookie'
                    : hashKey.oneofKind === 'httpQueryParameterName'
                    ? 'HTTP Query Parameter Name'
                    : 'Source IP'
              }
            ]}
          />
        </CardStyles.CardHeaderSecondaryInfoHolder>
      </CardStyles.CardHeader>
      {hashKey.oneofKind === 'httpHeaderName' ? (
        <StatMiniCardList items={[{ title: 'Name', data: hashKey.httpHeaderName }]} />
      ) : hashKey.oneofKind === 'httpQueryParameterName' ? (
        <StatMiniCardList items={[{ title: 'Name', data: hashKey.httpQueryParameterName }]} />
      ) : hashKey.oneofKind === 'httpCookie' ? (
        <StatMiniCardList
          items={[
            { title: 'Name', data: hashKey.httpCookie.name },
            { title: 'Path', data: <MonospaceText>{hashKey.httpCookie.path}</MonospaceText> },
            {
              title: 'Time To Live (TTL)',
              data: getTimeAsSecondsString(hashKey.httpCookie.ttl, 'No TTL configured')
            }
          ]}
        />
      ) : (
        '' // sSourceIp has no data section
      )}
    </CardStyles.Card>
  );
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const LoadBalancerDetails = ({ clusterObjRef }: Props) => {
  di(useGetLoadBalancerPolicyDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();

  const { data: policyData, error: policyError } = useGetLoadBalancerPolicyDetails(clusterObjRef, apiPaginationObject);

  useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.TimeoutPolicyIcon />,
              title: 'Warmup Duration',
              data: getTimeAsSecondsString(policyData.warmup, 'No warmup duration configured')
            },
            {
              icon: <ErrorTriangleStyled />,
              title: 'Healthy Panic Threshold',
              data: policyData.healthyPanicThreshold?.value ?? 'No threshold configured'
            },
            {
              icon: <Asset.MaxAgeIcon />,
              title: 'Update Merge Window',
              data: getTimeAsSecondsString(policyData.updateMergeWindow, 'No window configured')
            }
          ]}
        />
      </CardStyles.Card>

      {policyData.lbPolicyData.oneofKind === 'consistentHash' ? (
        <ConsistentHashCard consistentHash={policyData.lbPolicyData.consistentHash} />
      ) : policyData.lbPolicyData.oneofKind === 'simple' ? (
        <SimpleCard simple={policyData.lbPolicyData.simple!} />
      ) : (
        <></>
      )}

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable destinations={policyData.destinations?.destinations} paging={pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
