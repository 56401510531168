import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { CardFolderTab, CardFolderTabListWithBottomBorder } from 'Components/Common/Tabs';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { VariableTransformation } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useState } from 'react';
import VariableTransformationMapTable from './VariableTransformationMapTable';

const GqlProxiedRemoteConfigTabs = ({
  headers,
  queryParams
}: {
  headers: Record<string, VariableTransformation> | undefined;
  queryParams: Record<string, VariableTransformation> | undefined;
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  //
  // Render
  //
  if (headers === undefined || !queryParams === undefined) return null;
  return (
    <Tabs id='tabs' index={tabIndex} onChange={setTabIndex}>
      <CardFolderTabListWithBottomBorder>
        <CardFolderTab>Headers</CardFolderTab>
        <CardFolderTab width='200px' data-testid='query-parameters-button'>
          Query Parameters
        </CardFolderTab>
      </CardFolderTabListWithBottomBorder>
      <TabPanels>
        <TabPanel>
          <Spacer pt={4}>
            <VariableTransformationMapTable variableTransformations={headers} />
          </Spacer>
        </TabPanel>
        <TabPanel>
          <Spacer pt={4}>
            <VariableTransformationMapTable variableTransformations={queryParams} />
          </Spacer>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default GqlProxiedRemoteConfigTabs;
