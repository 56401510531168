import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { useState } from 'react';
import { svgBasics, svgColorReplace } from 'Styles/CommonEmotions/utils';

const TableButton = styled.button(({ theme }) => {
  return css`
    background: inherit;
    border: none;
    padding: 4px;
    margin: 0;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    ${svgBasics(22, theme.seaBlue)}
    border: 1px solid transparent;
    &:hover {
      background-color: ${theme.splashBlue};
    }
    &:active {
      background-color: ${theme.pondBlue};
      ${svgColorReplace(theme.oceanBlue)}
    }
    &:focus-visible {
      border: 1px solid ${theme.lakeBlue};
    }
  `;
});

const PREFER_TABLE_LOCAL_STORAGE_KEY = 'prefer-list-view';

export const isTableViewPreferred = () => localStorage.getItem(PREFER_TABLE_LOCAL_STORAGE_KEY) !== 'false';

export const useIsTableViewPreferred = () => {
  const [isTable, setIsTable] = useState(isTableViewPreferred());

  // useEffect(() => {
  //   localStorage.setItem(PREFER_TABLE_LOCAL_STORAGE_KEY, isTable ? 'true' : 'false');
  // }, [isTable]);

  return { isTable, setIsTable };
};

const SoloGridTableToggle = ({ isTable, onChange }: { isTable: boolean; onChange: (newIsTable: boolean) => void }) => {
  //
  // Render
  //
  return (
    <>
      {!isTable ? (
        <Tooltip title={'Show table view'} placement='bottom' trigger='hover'>
          <TableButton
            data-testid='view-table-button'
            aria-label='Show table view'
            onClick={() => {
              localStorage.setItem(PREFER_TABLE_LOCAL_STORAGE_KEY, 'true');
              onChange(true);
            }}>
            <Asset.ListViewIcon />
          </TableButton>
        </Tooltip>
      ) : (
        <Tooltip title={'Show grid view'} placement='bottom' trigger='hover'>
          <TableButton
            data-testid='view-grid-button'
            aria-label='Show grid view'
            onClick={() => {
              localStorage.setItem(PREFER_TABLE_LOCAL_STORAGE_KEY, 'false');
              onChange(false);
            }}>
            <Asset.TileViewIcon />
          </TableButton>
        </Tooltip>
      )}
    </>
  );
};

export default SoloGridTableToggle;
