/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/metadata/v2/metadata.proto" (package "envoy.type.metadata.v2", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Metadata]

/**
 * MetadataKey provides a general interface using `key` and `path` to retrieve value from
 * :ref:`Metadata <envoy_api_msg_core.Metadata>`.
 *
 * For example, for the following Metadata:
 *
 * .. code-block:: yaml
 *
 *    filter_metadata:
 *      envoy.xxx:
 *        prop:
 *          foo: bar
 *          xyz:
 *            hello: envoy
 *
 * The following MetadataKey will retrieve a string value "bar" from the Metadata.
 *
 * .. code-block:: yaml
 *
 *    key: envoy.xxx
 *    path:
 *    - key: prop
 *    - key: foo
 *
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKey
 */
export interface MetadataKey {
    /**
     * The key name of Metadata to retrieve the Struct from the metadata.
     * Typically, it represents a builtin subsystem or custom extension.
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * The path to retrieve the Value from the Struct. It can be a prefix or a full path,
     * e.g. ``[prop, xyz]`` for a struct or ``[prop, foo]`` for a string in the example,
     * which depends on the particular scenario.
     *
     * Note: Due to that only the key type segment is supported, the path can not specify a list
     * unless the list is the last segment.
     *
     * @generated from protobuf field: repeated envoy.type.metadata.v2.MetadataKey.PathSegment path = 2;
     */
    path: MetadataKey_PathSegment[];
}
/**
 * Specifies the segment in a path to retrieve value from Metadata.
 * Currently it is only supported to specify the key, i.e. field name, as one segment of a path.
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKey.PathSegment
 */
export interface MetadataKey_PathSegment {
    /**
     * @generated from protobuf oneof: segment
     */
    segment: {
        oneofKind: "key";
        /**
         * If specified, use the key to retrieve the value in a Struct.
         *
         * @generated from protobuf field: string key = 1;
         */
        key: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Describes what kind of metadata.
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKind
 */
export interface MetadataKind {
    /**
     * @generated from protobuf oneof: kind
     */
    kind: {
        oneofKind: "request";
        /**
         * Request kind of metadata.
         *
         * @generated from protobuf field: envoy.type.metadata.v2.MetadataKind.Request request = 1;
         */
        request: MetadataKind_Request;
    } | {
        oneofKind: "route";
        /**
         * Route kind of metadata.
         *
         * @generated from protobuf field: envoy.type.metadata.v2.MetadataKind.Route route = 2;
         */
        route: MetadataKind_Route;
    } | {
        oneofKind: "cluster";
        /**
         * Cluster kind of metadata.
         *
         * @generated from protobuf field: envoy.type.metadata.v2.MetadataKind.Cluster cluster = 3;
         */
        cluster: MetadataKind_Cluster;
    } | {
        oneofKind: "host";
        /**
         * Host kind of metadata.
         *
         * @generated from protobuf field: envoy.type.metadata.v2.MetadataKind.Host host = 4;
         */
        host: MetadataKind_Host;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Represents dynamic metadata associated with the request.
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKind.Request
 */
export interface MetadataKind_Request {
}
/**
 * Represents metadata from :ref:`the route<envoy_api_field_route.Route.metadata>`.
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKind.Route
 */
export interface MetadataKind_Route {
}
/**
 * Represents metadata from :ref:`the upstream cluster<envoy_api_field_Cluster.metadata>`.
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKind.Cluster
 */
export interface MetadataKind_Cluster {
}
/**
 * Represents metadata from :ref:`the upstream
 * host<envoy_api_field_endpoint.LbEndpoint.metadata>`.
 *
 * @generated from protobuf message envoy.type.metadata.v2.MetadataKind.Host
 */
export interface MetadataKind_Host {
}
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKey$Type extends MessageType<MetadataKey> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKey", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MetadataKey_PathSegment, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKey
 */
export const MetadataKey = new MetadataKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKey_PathSegment$Type extends MessageType<MetadataKey_PathSegment> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKey.PathSegment", [
            { no: 1, name: "key", kind: "scalar", oneof: "segment", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKey.PathSegment
 */
export const MetadataKey_PathSegment = new MetadataKey_PathSegment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKind$Type extends MessageType<MetadataKind> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKind", [
            { no: 1, name: "request", kind: "message", oneof: "kind", T: () => MetadataKind_Request },
            { no: 2, name: "route", kind: "message", oneof: "kind", T: () => MetadataKind_Route },
            { no: 3, name: "cluster", kind: "message", oneof: "kind", T: () => MetadataKind_Cluster },
            { no: 4, name: "host", kind: "message", oneof: "kind", T: () => MetadataKind_Host }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKind
 */
export const MetadataKind = new MetadataKind$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKind_Request$Type extends MessageType<MetadataKind_Request> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKind.Request", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKind.Request
 */
export const MetadataKind_Request = new MetadataKind_Request$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKind_Route$Type extends MessageType<MetadataKind_Route> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKind.Route", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKind.Route
 */
export const MetadataKind_Route = new MetadataKind_Route$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKind_Cluster$Type extends MessageType<MetadataKind_Cluster> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKind.Cluster", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKind.Cluster
 */
export const MetadataKind_Cluster = new MetadataKind_Cluster$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetadataKind_Host$Type extends MessageType<MetadataKind_Host> {
    constructor() {
        super("envoy.type.metadata.v2.MetadataKind.Host", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.metadata.v2.MetadataKind.Host
 */
export const MetadataKind_Host = new MetadataKind_Host$Type();
