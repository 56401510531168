import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { GqlApiExplorerIcon, GqlPrettifyIcon } from 'assets/assets';
import { Fetcher, GraphiQL } from 'graphiql';
import { GraphQLSchema } from 'graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { useEventListener } from 'utils/hooks';
import { GqlExplorerCorsTooltipContent } from '../GqlExplorerSettingsModal';
import { GqlExplorerStyles } from './GqlExplorer.style';
import GqlExplorerDefaultQuery from './GqlExplorerDefaultQuery';
import GqlExplorerExplorer from './GqlExplorerExplorer';
import GqlExplorerRunButton from './GqlExplorerRunButton';
import { NotificationBox } from 'Components/Common/NotificationBox';
import { NotificationType } from 'Components/Common/NotificationBox';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';

const GqlExplorer = ({
  urlError,
  parsedSchema,
  isSchemaTextOverwritten,
  resetSchemaText,
  url,
  storage = localStorage,
  openSettings
}: {
  urlError: string | undefined;
  parsedSchema: GraphQLSchema | undefined;
  isSchemaTextOverwritten: boolean;
  resetSchemaText: () => void;
  url: string;
  storage?: Storage;
  openSettings: () => void;
}) => {
  //
  // GraphiQL State
  //
  const graphiqlRef = useRef<null | GraphiQL>(null);
  const [query, setQuery] = useState<string>(() => {
    let initialQuery = storage.getItem('graphiql:query');
    if (initialQuery === null) initialQuery = '';
    return initialQuery;
  });
  const onQueryUpdate = (query?: string) => setQuery(query ?? '');
  const [variables, setVariables] = useState(() => {
    let initialVariables = storage.getItem('graphiql:variables');
    if (!initialVariables) initialVariables = '{}';
    return initialVariables;
  });
  const [headers, setHeaders] = useState(() => {
    let initialHeaders = storage.getItem('graphiql:headers');
    if (!initialHeaders) initialHeaders = '';
    return initialHeaders;
  });
  const [opName, setOpName] = useState(() => {
    let initialOpName = storage.getItem('graphiql:operationName');
    if (!initialOpName) initialOpName = 'Example';
    return initialOpName;
  });

  //
  // GraphQL Fetcher
  // Add any extra logic to process the request here.
  //
  let gqlFetcher = createGraphiQLFetcher({ url });

  //
  // Docs Panel Fixes
  //
  const [showDocs, setShowDocs] = useState(storage.getItem('graphiql:docExplorerOpen') === 'true');
  useEffect(() => {
    // GraphiQL calls these functions behind the scenes when the docs explorer is
    // toggled with its "Show/Hide Docs" buttons, but not when its `showDocs` prop changes.
    // Adding this allows the `showDocs` property to control the docs visibilty.
    if (showDocs) {
      graphiqlRef.current?.ref?.props.explorerContext?.show();
      graphiqlRef.current?.ref?.props.docResize.setHiddenElement(null);
    } else {
      graphiqlRef.current?.ref?.props.docResize.setHiddenElement('second');
      graphiqlRef.current?.ref?.props.explorerContext?.hide();
    }
  }, [showDocs]);

  //
  // Toolbar Actions
  //
  const prettifyQuery = () => {
    graphiqlRef.current?.ref?.props.prettify();
  };

  const runOperation = (op?: string) => {
    if (!op?.trim()) graphiqlRef.current?.ref?.props.executionContext.run();
    else {
      graphiqlRef.current?.ref?.props.editorContext.setOperationName(op as any);
      setTimeout(() => graphiqlRef.current?.ref?.props.executionContext.run(), 1);
    }
  };

  //
  // Resizes Height to Fit Window
  //
  const gqlMinHeightPx = 600;
  const gqlTopPadding = 60;
  const gqlBottomPadding = 30;
  const [heightPx, setHeightPx] = useState(gqlMinHeightPx);

  const onResize = useCallback(() => {
    const graphiqlEl = document.getElementById('graphiql');
    if (!graphiqlEl) return;

    const graphiqlY = graphiqlEl.getBoundingClientRect().y;
    // Compute the full height of graphiql element to the bottom
    // of the page. Subtract 50px for the top navbar height.
    const gqlFullHeightPx = window.innerHeight - graphiqlY - gqlTopPadding - 50;
    setHeightPx(Math.max(gqlMinHeightPx, gqlFullHeightPx));
    // Could include a max-height here like this:
    // setHeightPx(Math.min(Math.max(gqlMinHeightPx, gqlFullHeightPx), gqlMaxHeightPx));
  }, [setHeightPx]);

  useEffect(onResize, []);

  useEventListener(window, 'resize', onResize, [onResize]);

  //
  // Render
  //
  return (
    <GqlExplorerStyles.GqlExplorerCard heightPx={heightPx} bottomPadding={gqlBottomPadding} topPadding={gqlTopPadding}>
      <GqlExplorerStyles.Toolbar.Container>
        <GqlExplorerStyles.Toolbar.Title>
          <GqlApiExplorerIcon />
          API Explorer
        </GqlExplorerStyles.Toolbar.Title>

        {isSchemaTextOverwritten && (
          <NotificationBox
            type={NotificationType.INFO}
            issues={[
              {
                message: (
                  <>
                    Settings is overriding schema text.{' '}
                    <SoloLinkStyles.SoloLinkLooksButton
                      displayInline
                      onClick={() => {
                        resetSchemaText();
                        runOperation();
                      }}>
                      reset
                    </SoloLinkStyles.SoloLinkLooksButton>
                  </>
                )
              }
            ]}
            notificationTypeString=''
          />
        )}

        <GqlExplorerStyles.Toolbar.RightButtonsContainer>
          <Spacer margin={2} display='inline-block'>
            <GqlExplorerStyles.Toolbar.Button variant='primary-outline' onClick={openSettings}>
              {!!urlError && (
                <GqlExplorerStyles.Toolbar.CorsTooltip>
                  <Tooltip
                    placement='left'
                    title={<GqlExplorerCorsTooltipContent url={url} urlError={urlError} />}
                    trigger='hover'>
                    <Asset.InfoIcon />
                  </Tooltip>
                </GqlExplorerStyles.Toolbar.CorsTooltip>
              )}
              <Spacer mt='1px'>Endpoint URL and Schema Settings</Spacer>
            </GqlExplorerStyles.Toolbar.Button>
          </Spacer>
          <Spacer margin={2} display='inline-block'>
            <Tooltip title='Prettify Query (Shift-Ctrl-P)' trigger='hover'>
              <GqlExplorerStyles.Toolbar.Button variant='primary-outline' onClick={prettifyQuery}>
                <GqlPrettifyIcon />
                <Spacer mt='1px'>PRETTIFY</Spacer>
              </GqlExplorerStyles.Toolbar.Button>
            </Tooltip>
          </Spacer>
          <Spacer margin={2} mr={3} display='inline-block'>
            <GqlExplorerRunButton graphiqlRef={graphiqlRef} onRunOperation={runOperation} />
          </Spacer>
        </GqlExplorerStyles.Toolbar.RightButtonsContainer>
      </GqlExplorerStyles.Toolbar.Container>

      <GqlExplorerStyles.GraphiqlContainer
        heightPx={heightPx}
        topPadding={gqlTopPadding}
        id='graphiql'
        data-testid='gql-explorer'>
        <GqlExplorerExplorer
          executableSchema={parsedSchema}
          query={query}
          handleQueryUpdate={onQueryUpdate}
          onRunOperation={runOperation}
        />

        <GraphiQL
          ref={graphiqlRef}
          //
          // editorTheme={theme}
          //   - The editorTheme prop is a little buggy: When changing themes,
          //     it freezes whatever text was input on the background, and looks
          //     kind of glitchy. Also, it only affects the query editor section.
          //
          // keyMap='vim'
          //   - keyMap doesn't seem to work, even when the codemirror/keymap/vim.js
          //     file is imported.
          //
          schema={parsedSchema}
          fetcher={gqlFetcher as Fetcher}
          //
          defaultQuery={GqlExplorerDefaultQuery}
          query={query}
          onEditQuery={onQueryUpdate}
          //
          onEditVariables={setVariables}
          variables={variables}
          //
          headers={headers}
          onEditHeaders={setHeaders}
          shouldPersistHeaders={true}
          //
          onToggleDocs={setShowDocs}
          docExplorerOpen={showDocs}
          //
          operationName={opName}
          onEditOperationName={setOpName}
          //
          tabs={{
            onTabChange: tab => {
              if (tab.tabs.length < 1 || !!tab.tabs[tab.activeTabIndex].variables?.trim()) return;
              // The variables must be changed for the editor to update.
              // So we can add or remove one space here to trigger that.
              if (variables === '{}') setVariables('{} ');
              else setVariables('{}');
            }
          }}
          //
          storage={storage}
          //
          maxHistoryLength={1}
        />

        {!showDocs && (
          <Spacer ml='15px'>
            <GqlExplorerStyles.ShowExplorerButton.Container placement='right' onClick={() => setShowDocs(true)}>
              <GqlExplorerStyles.ShowExplorerButton.Content>
                <GqlExplorerStyles.Arrow direction='left' />
                Documentation Explorer
              </GqlExplorerStyles.ShowExplorerButton.Content>
            </GqlExplorerStyles.ShowExplorerButton.Container>
          </Spacer>
        )}
      </GqlExplorerStyles.GraphiqlContainer>
    </GqlExplorerStyles.GqlExplorerCard>
  );
};

export default GqlExplorer;
