import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { BasicDestinationsTable } from 'Components/Features/Destinations/BasicDestinationsTable';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetExtAuthPolicyDetailsResponse_AuthType,
  GetExtAuthPolicyDetailsResponse_AuthUsed
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetExtAuthPolicyDetails } = policiesApi;

const policyType = PolicyType.EXT_AUTH;

const authUsedMap: Record<GetExtAuthPolicyDetailsResponse_AuthUsed, string> = {
  [GetExtAuthPolicyDetailsResponse_AuthUsed.AUTH_DISABLED]: 'Disabled',
  [GetExtAuthPolicyDetailsResponse_AuthUsed.GLOO_AUTH]: 'Gloo Ext Auth',
  [GetExtAuthPolicyDetailsResponse_AuthUsed.CUSTOM_AUTH]: 'Custom Auth'
};

const authTypeMap: Record<GetExtAuthPolicyDetailsResponse_AuthType, string> = {
  [GetExtAuthPolicyDetailsResponse_AuthType.UNKNOWN_AUTH_METHOD]: 'Unknown Auth Type',
  [GetExtAuthPolicyDetailsResponse_AuthType.BASIC]: 'Basic Auth',
  [GetExtAuthPolicyDetailsResponse_AuthType.OAUTH]: 'OAuth',
  [GetExtAuthPolicyDetailsResponse_AuthType.OAUTH2]: 'OAuth 2.0',
  [GetExtAuthPolicyDetailsResponse_AuthType.API_KEY]: 'API Key',
  [GetExtAuthPolicyDetailsResponse_AuthType.PLUGIN]: 'Plugin',
  [GetExtAuthPolicyDetailsResponse_AuthType.OPA]: 'OPA',
  [GetExtAuthPolicyDetailsResponse_AuthType.LDAP]: 'LDAP',
  [GetExtAuthPolicyDetailsResponse_AuthType.JWT]: 'JWT',
  [GetExtAuthPolicyDetailsResponse_AuthType.PASS_THROUGH]: 'Pass Through',
  [GetExtAuthPolicyDetailsResponse_AuthType.HMAC]: 'HMAC',
  [GetExtAuthPolicyDetailsResponse_AuthType.OPA_SERVER]: 'OPA Server'
};

const StatMiniCardWrapper = styled.div`
  width: 100%;

  > div {
    height: 100%;
  }
`;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ExtAuthDetails = ({ clusterObjRef }: Props) => {
  di(useGetExtAuthPolicyDetails);
  const routesPaging = useSoloPaging();
  const destinationsPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetExtAuthPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject,
    destinationsPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);
  destinationsPaging.useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <SoloListCardStyles.HorizontalListCardsContainer>
          <SoloListCard
            title='Auth Config'
            icon={<SecondaryInfoItem label='Auth Used' data={authUsedMap[policyData.authUsed]} small />}
            dataSource={policyData.authChecks.map(a => ({
              key: `${a.name}-${a.type}`,
              data: (
                <SecondaryInfo
                  small
                  items={[
                    { label: 'Name', data: a.name },
                    { label: 'Auth Type', data: authTypeMap[a.type] }
                  ]}
                />
              )
            }))}
          />
          <StatMiniCardWrapper>
            <StatMiniCardList
              items={[
                {
                  icon: <Asset.ServerIcon />,
                  title: 'Auth Server',
                  data: !!policyData.serverRef ? (
                    <SecondaryInfo
                      small
                      items={[
                        { label: 'Name', data: policyData.serverRef.name },
                        {
                          label: 'Namespace',
                          data: policyData.serverRef.namespace
                        },
                        {
                          label: 'Cluster',
                          data: policyData.serverRef.clusterName
                        }
                      ]}
                    />
                  ) : (
                    'No auth server configured'
                  )
                }
              ]}
            />
          </StatMiniCardWrapper>
        </SoloListCardStyles.HorizontalListCardsContainer>
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable
          destinations={policyData.destinations?.destinations}
          paging={destinationsPaging.pagingData}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
