import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { InputObjectTypeDefinitionNode, Kind } from 'graphql';
import { useContext } from 'react';
import { GqlSchemaTabItemProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';
import GqlInputValueDefinitionItem from './GqlInputValueDefinitionItem';
import GqlInputTabItemTable from './GqlInputValueDefinitionTable';

const GqlInputTabItem = ({
  onTypeClick,
  focusedItem,
  tabHeader,
  focusedPath
}: GqlSchemaTabItemProps<InputObjectTypeDefinitionNode>) => {
  const gqlctx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlctx;
  const focusedItemExtensions = getExtensionInfo(Kind.INPUT_OBJECT_TYPE_EXTENSION, focusedItem.name.value);
  const focusedSubItem = useGetFocusedItem(focusedItem.fields, focusedPath, 1);

  //
  // Render
  //
  if (!!focusedSubItem)
    return (
      <GqlInputValueDefinitionItem
        focusedItem={focusedSubItem}
        focusedPath={focusedPath}
        onTypeClick={onTypeClick}
        tabHeader={tabHeader}
      />
    );
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectiveList
        node={focusedItem}
        extendedDirectives={focusedItemExtensions?.directives}
        onTypeClick={onTypeClick}
      />
      <GqlTabItemDescription node={focusedItem} />
      <GqlInputTabItemTable
        definitions={focusedItem.fields ?? []}
        extendedDefinitions={focusedItemExtensions?.fields}
        onTypeClick={onTypeClick}
        tabHeader={`${tabHeader}/${focusedItem.name.value}`}
      />
    </>
  );
};

export default GqlInputTabItem;
