/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/trafficcontrol/transformation_policy.proto" (package "trafficcontrol.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { RouteReference } from "../../../common/v2/status_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { TransformationTemplate } from "../../../../../../../envoy-gloo/api/envoy/config/filter/http/transformation/v2/transformation_filter_pb";
import { PrioritizedPhase } from "../../../common/v2/phase_pb";
import { RouteSelector } from "../../../common/v2/selectors_pb";
/**
 * TransformationPolicy is used to transform HTTP requests and responses matching selected routes.
 * TransformationPolicies are applied at the *Route* level.
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec
 */
export interface TransformationPolicySpec {
    /**
     * Routes to apply the policy to.
     * If empty, the policy applies to all workloads in the workspace.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.RouteSelector apply_to_routes = 1;
     */
    applyToRoutes: RouteSelector[];
    /**
     * The details of the transformation policy to apply to the selected routes.
     *
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config config = 2;
     */
    config?: TransformationPolicySpec_Config;
}
/**
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config
 */
export interface TransformationPolicySpec_Config {
    /**
     * Specify the phase to indicate where this policy should be applied relative to other policies.
     * If no phase is specified, the default will be post AuthZ.
     *
     * @generated from protobuf field: common.gloo.solo.io.PrioritizedPhase phase = 1;
     */
    phase?: PrioritizedPhase;
    /**
     * Transformation to be applied before the request is sent to the upstream service.
     *
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.RequestTransformation request = 2;
     */
    request?: TransformationPolicySpec_Config_RequestTransformation;
    /**
     * Transform the response received from the upstream service before returning it to the client.
     *
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.ResponseTransformation response = 3;
     */
    response?: TransformationPolicySpec_Config_ResponseTransformation;
}
/**
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.RequestTransformation
 */
export interface TransformationPolicySpec_Config_RequestTransformation {
    /**
     * If set to **true**, recalculate the routing destination and select a new route for transformed requests.
     * For example, if you have a request that is transformed to match a new route, the new route is selected
     * when calculating the routing destination. The default value is **false**.
     *
     * @generated from protobuf field: bool recalculate_routing_destination = 1;
     */
    recalculateRoutingDestination: boolean;
    /**
     * transform HTTP body and headers using Inja templates.
     * For more information, see the [Envoy `transformation_filter.proto`](https://github.com/solo-io/envoy-gloo/blob/master/api/envoy/config/filter/http/transformation/v2/transformation_filter.proto#L155).
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.TransformationTemplate inja_template = 2;
     */
    injaTemplate?: TransformationTemplate;
}
/**
 * Configure response transformations for a selected route
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.ResponseTransformation
 */
export interface TransformationPolicySpec_Config_ResponseTransformation {
    /**
     * transform response body and headers using Inja templates.
     * For more information, see the [Envoy `transformation_filter.proto`](https://github.com/solo-io/envoy-gloo/blob/master/api/envoy/config/filter/http/transformation/v2/transformation_filter.proto#L155).
     *
     * @generated from protobuf field: envoy.api.v2.filter.http.TransformationTemplate inja_template = 1;
     */
    injaTemplate?: TransformationTemplate;
}
/**
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicyStatus
 */
export interface TransformationPolicyStatus {
    /**
     * The state and workspace conditions of the applied resource.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of routes selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_routes = 2;
     */
    numSelectedRoutes: number;
}
/**
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicyReport
 */
export interface TransformationPolicyReport {
    /**
     * The status of the resource in each workspace that it exists in.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of references to all routes selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.RouteReference selected_routes = 2;
     */
    selectedRoutes: RouteReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class TransformationPolicySpec$Type extends MessageType<TransformationPolicySpec> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec", [
            { no: 1, name: "apply_to_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteSelector },
            { no: 2, name: "config", kind: "message", T: () => TransformationPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec
 */
export const TransformationPolicySpec = new TransformationPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationPolicySpec_Config$Type extends MessageType<TransformationPolicySpec_Config> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config", [
            { no: 1, name: "phase", kind: "message", T: () => PrioritizedPhase },
            { no: 2, name: "request", kind: "message", T: () => TransformationPolicySpec_Config_RequestTransformation },
            { no: 3, name: "response", kind: "message", T: () => TransformationPolicySpec_Config_ResponseTransformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config
 */
export const TransformationPolicySpec_Config = new TransformationPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationPolicySpec_Config_RequestTransformation$Type extends MessageType<TransformationPolicySpec_Config_RequestTransformation> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.RequestTransformation", [
            { no: 1, name: "recalculate_routing_destination", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "inja_template", kind: "message", T: () => TransformationTemplate }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.RequestTransformation
 */
export const TransformationPolicySpec_Config_RequestTransformation = new TransformationPolicySpec_Config_RequestTransformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationPolicySpec_Config_ResponseTransformation$Type extends MessageType<TransformationPolicySpec_Config_ResponseTransformation> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.ResponseTransformation", [
            { no: 1, name: "inja_template", kind: "message", T: () => TransformationTemplate }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.ResponseTransformation
 */
export const TransformationPolicySpec_Config_ResponseTransformation = new TransformationPolicySpec_Config_ResponseTransformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationPolicyStatus$Type extends MessageType<TransformationPolicyStatus> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.TransformationPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 2, name: "num_selected_routes", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicyStatus
 */
export const TransformationPolicyStatus = new TransformationPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationPolicyReport$Type extends MessageType<TransformationPolicyReport> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.TransformationPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.TransformationPolicyReport
 */
export const TransformationPolicyReport = new TransformationPolicyReport$Type();
