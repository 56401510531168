import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { CiliumHealthCheckSection } from './CiliumHealthCheckSection';
import { CiliumHealthEnvironmentCheckSection } from './CiliumHealthEnvironmentCheckSection';

const CiliumHealthTabContent = () => {
  return (
    <GridLayout templateColumns='1fr auto 1fr' gap={5} pt={5}>
      <CiliumHealthCheckSection />
      <DashboardStyles.VerticalLine />
      <CiliumHealthEnvironmentCheckSection />
    </GridLayout>
  );
};

export default CiliumHealthTabContent;
