import React from 'react';
import { SoloTextareaStyles } from './SoloTextarea.style';
import { SoloLabel } from './SoloLabel';
import { useSoloId } from 'utils/hooks';

export interface SoloTextareaProps {
  innerRef?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>['ref'];
  onChangeValue?: (value: string) => void;
  // Label
  label?: string;
  infoTooltip?: React.ReactNode;

  // Styled values - passed via ...rest
  resize?: React.CSSProperties['resize'];
  borderless?: boolean;
  error?: boolean;

  // Input default - most passed via ...rest
  id?: string;
  value: string;
  name?: string;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  onKeyPress?: (e: React.KeyboardEvent) => void;
}

export const SoloTextarea = (props: SoloTextareaProps) => {
  const {
    id,
    label,
    infoTooltip,
    innerRef,
    onChangeValue,
    onChange,
    ...otherProps // Includes data-testid and `aria-` attributes
  } = props;
  const inputId = useSoloId(id);

  return (
    <SoloTextareaStyles.Container data-testid='solo-textarea'>
      {label && (
        <SoloLabel htmlFor={inputId} infoTooltip={infoTooltip}>
          {label}
        </SoloLabel>
      )}
      <SoloTextareaStyles.Textarea
        id={inputId}
        title={label}
        ref={innerRef}
        {...otherProps}
        onChange={e => {
          onChange?.(e);
          onChangeValue?.(e.currentTarget.value);
        }}
      />
    </SoloTextareaStyles.Container>
  );
};
