import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { ApisLanding } from 'Components/Features/Apis/ApisLanding';
import { GqlLanding } from 'Components/Features/Apis/Graphql/GqlLanding';
import { OpenApiDetails } from 'Components/Features/Apis/OpenApi/OpenApiDetails';
import { CertificatesLanding } from 'Components/Features/Certificates/Overview/CertificatesLanding';
import { ClusterDetailsLanding } from 'Components/Features/Clusters/Details/ClusterDetailsLanding';
import { ClustersLanding } from 'Components/Features/Clusters/Overview/ClustersLanding';
import { FlagsLanding } from 'Components/Features/Flags/FlagsLanding';
import { GatewayLanding } from 'Components/Features/Gateways/General/GatewaysLanding';
import { GraphLanding } from 'Components/Features/Graph/MainGraph/GraphLanding';
import { InsightsLanding } from 'Components/Features/Insights/Overview/InsightsLanding';
import LogsLanding from 'Components/Features/Logs/LogsLanding';
import { OverviewLanding } from 'Components/Features/Overview/OverviewLanding';
import { PortalDetailsLanding } from 'Components/Features/Portals/Details/PortalDetailsLanding';
import PortalsLanding from 'Components/Features/Portals/PortalsLanding';
import { ResourcesLanding } from 'Components/Features/Resources/Overview/ResourcesLanding';
import { ResourcesRootLanding } from 'Components/Features/Resources/Overview/ResourcesRootLanding';
import { ServiceDetailsLanding } from 'Components/Features/Services/Details/ServiceDetailsLanding';
import { ServicesLanding } from 'Components/Features/Services/Landing/ServicesLanding';
import { TracingLanding } from 'Components/Features/Tracing/TracingLanding';
import { TrafficEgressLanding } from 'Components/Features/Traffic/Egress/TrafficEgressLanding';
import { TrafficIngressLanding } from 'Components/Features/Traffic/Ingress/TrafficIngressLanding';
import { WorkspacesLanding } from 'Components/Features/Workspaces/Overview/WorkspacesLanding';
import { useEffect } from 'react';
import { Navigate, Params, RouteObject, useLocation, useParams } from 'react-router-dom';
import { useDocumentTitle } from 'utils/hooks';
import {
  getDestinationKindFromId,
  getDestinationKindReadableName,
  getGvkTypeFromId,
  getGvkTypeReadableName,
  getPolicyTypeFromId,
  getPolicyTypeReadableName,
  getRouteTypeFromId,
  getRouteTypeReadableName
} from 'utils/types';
import { urlStrings, workspaceUrlStrings } from 'utils/url-string-constants';
import { DestinationDetailsLanding } from '../Features/Destinations/DestinationDetailsLanding';
import { WorkspaceGraphLanding } from '../Features/Graph/OverviewGraph/WorkspaceGraphLanding';
import { PolicyDetailsLanding } from '../Features/Policies/Details/PolicyDetailsLanding';
import { RouteDetailsLanding } from '../Features/Routing/RouteDetailsLanding';
import { WorkspaceDetailsLanding } from '../Features/Workspaces/Details/WorkspaceDetailsLanding';
import { RootLanding } from './RootLanding';
import { BreadcrumbsItem } from './TopBar/Breadcrumb';

type RouteObjectWithBreadcrumb = Omit<RouteObject, 'children'> & {
  children?: RouteObjectWithBreadcrumb[];
  breadcrumbName?: string | ((params: Readonly<Params<string>>) => string);
  breadcrumbDontLink?: boolean;
};

export const routeObjects: RouteObjectWithBreadcrumb[] = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
    element: (
      <ErrorBoundary fallback='There was an error with the dashboard page...'>
        <RootLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `/${urlStrings.overview}`,
    breadcrumbName: 'Overview',
    element: (
      <ErrorBoundary fallback='There was an error getting everything ready for your Overview...'>
        <OverviewLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `/${urlStrings.clusters}`,
    breadcrumbName: 'Clusters',
    element: (
      <ErrorBoundary fallback='There was an error with the Clusters area...'>
        <ClustersLanding />
      </ErrorBoundary>
    ),
    children: [
      {
        path: ':clusterName/*',
        breadcrumbName: params => params.clusterName ?? '',
        element: (
          <ErrorBoundary fallback='There was an error with the Cluster details...'>
            <ClusterDetailsLanding />
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: `/${urlStrings.insights}`,
    breadcrumbName: 'Insights',
    element: (
      <ErrorBoundary fallback='There was an error with the insights page...'>
        <InsightsLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `/${urlStrings.securityInsights}`,
    breadcrumbName: 'Security Insights',
    element: (
      <ErrorBoundary fallback='There was an error with the security insights page...'>
        <InsightsLanding isSecurity />
      </ErrorBoundary>
    )
  },
  {
    path: `/${urlStrings.certificates}`,
    breadcrumbName: 'Certificates',
    element: (
      <ErrorBoundary fallback='There was an error with the certificates page...'>
        <CertificatesLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.graph}/*`,
    breadcrumbName: 'Graph',
    element: (
      <ErrorBoundary fallback='There was an error with the graph...'>
        <GraphLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.gateways}/*`,
    breadcrumbName: 'Gateways',
    element: (
      <ErrorBoundary fallback='There was an error with the gateways page...'>
        <GatewayLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.apis}/*`,
    breadcrumbName: 'API Registry',
    element: (
      <ErrorBoundary fallback='There was an error with APIs page...'>
        <ApisLanding />
      </ErrorBoundary>
    ),
    children: [
      {
        path: ':routeTableName/:routeTableNamespace/:routeTableCluster/:istioRouteName/*',
        breadcrumbName: params => params.istioRouteName ?? 'Unknown API',
        element: (
          <ErrorBoundary fallback='There was an error with the API details...'>
            <GqlLanding display='details' />
          </ErrorBoundary>
        ),
        children: [
          {
            path: 'explorer',
            breadcrumbName: 'Explorer',
            element: (
              <ErrorBoundary fallback='There was an error with the API details...'>
                <GqlLanding display='explorer' />
              </ErrorBoundary>
            )
          }
        ]
      },
      {
        path: 'openapi/:routeTableName/:routeTableNamespace/:routeTableCluster/*',
        breadcrumbName: params => 'OpenAPI',
        element: (
          <ErrorBoundary fallback='There was an error with the API details...'>
            <OpenApiDetails />
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: `${urlStrings.workspaces}`,
    breadcrumbName: 'Workspaces',
    element: <WorkspacesLanding />,
    children: [
      {
        path: ':workspaceName/*',
        breadcrumbName: params => params.workspaceName ?? 'Unknown',
        element: (
          <ErrorBoundary fallback='There was an error with the workspace details...'>
            <WorkspaceDetailsLanding />
          </ErrorBoundary>
        ),
        children: [
          {
            path: workspaceUrlStrings.destinations,
            breadcrumbName: 'Destinations',
            // if link visited (from breadcrumb) then render workspace details without redirect (so it opens correct tab)
            element: (
              <ErrorBoundary fallback='There was an error with the workspace details...'>
                <WorkspaceDetailsLanding />
              </ErrorBoundary>
            ),
            children: [
              {
                path: ':destinationType/:destinationName/:destinationNamespace/:destinationCluster/*',
                breadcrumbName: params =>
                  getDestinationKindReadableName(getDestinationKindFromId(params.destinationType)) ??
                  'Unknown Destination Type',
                element: (
                  <ErrorBoundary fallback='There was an error with the destination details...'>
                    <DestinationDetailsLanding />
                  </ErrorBoundary>
                )
              }
            ]
          },
          {
            path: workspaceUrlStrings.policies,
            breadcrumbName: 'Policies',
            // if link visited (from breadcrumb) then render workspace details without redirect (so it opens correct tab)
            element: (
              <ErrorBoundary fallback='There was an error with the workspace details...'>
                <WorkspaceDetailsLanding />
              </ErrorBoundary>
            ),
            children: [
              {
                path: ':policyType/:policyName/:policyNamespace/:policyCluster/*',
                breadcrumbName: params =>
                  getPolicyTypeReadableName(getPolicyTypeFromId(params.policyType)) ?? 'Unknown Policy Type',
                element: (
                  <ErrorBoundary fallback='There was an error with the policy details...'>
                    <PolicyDetailsLanding />
                  </ErrorBoundary>
                )
              }
            ]
          },
          {
            path: workspaceUrlStrings.routing,
            breadcrumbName: 'Routes',
            // if link visited (from breadcrumb) then render workspace details without redirect (so it opens correct tab)
            element: (
              <ErrorBoundary fallback='There was an error with the workspace details...'>
                <WorkspaceDetailsLanding />
              </ErrorBoundary>
            ),
            children: [
              {
                path: ':routeType/:routeName/:routeNamespace/:routeCluster/*',
                breadcrumbName: params =>
                  getRouteTypeReadableName(getRouteTypeFromId(params.routeType)) ?? 'Unknown Route Type',
                element: (
                  <ErrorBoundary fallback='There was an error with the route details...'>
                    <RouteDetailsLanding />
                  </ErrorBoundary>
                )
              }
            ]
          },
          {
            path: workspaceUrlStrings.graph,
            breadcrumbName: 'Graph',
            element: (
              <ErrorBoundary fallback='There was an error getting observability data for the workspace...'>
                <WorkspaceGraphLanding />
              </ErrorBoundary>
            )
          }
        ]
      }
    ]
  },
  {
    path: `${urlStrings.resources}/*`,
    breadcrumbName: 'Resources',
    element: (
      <ErrorBoundary fallback='There was an error with the Resources area...'>
        <ResourcesRootLanding />
      </ErrorBoundary>
    ),
    children: [
      {
        path: ':category/*',
        breadcrumbName: params =>
          getGvkTypeReadableName(getGvkTypeFromId(params.category)) ?? 'Unknown Resource Category',
        element: (
          <ErrorBoundary fallback='There was an error getting the associated Resources area...'>
            <ResourcesLanding />
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: `${urlStrings.tracing}/*`,
    breadcrumbName: 'Tracing',
    element: (
      <ErrorBoundary fallback='There was an error with the Tracing area...'>
        <TracingLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.flags}/*`,
    breadcrumbName: 'Feature Flags',
    element: (
      <ErrorBoundary fallback='There was an error with the Flags area...'>
        <FlagsLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.portals}/*`,
    breadcrumbName: 'Portals',
    element: (
      <ErrorBoundary fallback='There was an error with the Portals area...'>
        <PortalsLanding />
      </ErrorBoundary>
    ),
    children: [
      {
        path: ':portalName/:portalNamespace/:portalCluster/*',
        breadcrumbName: params => params.portalName ?? '',
        element: (
          <ErrorBoundary fallback='There was an error with the portal details...'>
            <PortalDetailsLanding />
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: `${urlStrings.services}/*`,
    breadcrumbName: 'Services',
    element: (
      <ErrorBoundary fallback='There was an error with the Services area...'>
        <ServicesLanding />
      </ErrorBoundary>
    ),
    children: [
      {
        path: ':serviceName/:serviceNamespace/:serviceCluster/*',
        breadcrumbName: params => params.serviceName ?? '',
        element: (
          <ErrorBoundary fallback='There was an error with the service details...'>
            <ServiceDetailsLanding />
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: `${urlStrings.ingress}/*`,
    breadcrumbName: 'Ingress',
    element: (
      <ErrorBoundary fallback='There was an error with the Ingress area...'>
        <TrafficIngressLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.egress}/*`,
    breadcrumbName: 'Egress',
    element: (
      <ErrorBoundary fallback='There was an error with the Egress area...'>
        <TrafficEgressLanding />
      </ErrorBoundary>
    )
  },
  {
    path: `${urlStrings.logs}/*`,
    breadcrumbName: 'Logs',
    element: (
      <ErrorBoundary fallback='There was an error with the Logs area...'>
        <LogsLanding />
      </ErrorBoundary>
    )
  },
  { path: '*', element: <Navigate to='/' /> }
];

// We don't use the layout 'feature' of react-router subroutes,
// so if a parent has a component automatically move it to an index sub route
// to avoid unexpected behavior
function autoIndexRoutes(routes: RouteObjectWithBreadcrumb[]): RouteObject[] {
  return routes.map(({ element, children: childrenIn, breadcrumbName, breadcrumbDontLink, ...params }) => {
    // Manually add a scroll-to-top to each element in the route. This is done
    // instead of triggering on every route change as we only want routes defined
    // in this file to automatically scroll to the top of the page when url changes
    if (!!childrenIn) {
      const children = autoIndexRoutes(childrenIn);
      if (element) {
        children.unshift({
          index: true,
          element: (
            <>
              <ScrollToTop />
              <DocTitle title={breadcrumbName} />
              {element}
            </>
          )
        });
        // Don't scroll up on fallbacks - this allows us to modify url on child without
        // auto scroll (useful for modals and tabs)
        children.push({ path: '*', element });
        element = undefined;
      }

      return { ...params, element, children, index: false };
    } else {
      if (element) {
        element = (
          <>
            <ScrollToTop />
            <DocTitle title={breadcrumbName} />
            {element}
          </>
        );
      }
      return { ...params, element, index: true };
    }
  });
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.document.getElementById('siteScrollArea')?.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const DocTitle = ({ title }: { title: RouteObjectWithBreadcrumb['breadcrumbName'] }) => {
  const params = useParams();
  const titleText = typeof title === 'function' ? title(params) : title;
  useDocumentTitle(titleText);
  return null;
};

export function createBreadcrumbRoutesFromRoutes(routes: RouteObjectWithBreadcrumb[]): RouteObject[] {
  return routes.map(({ breadcrumbName, breadcrumbDontLink, children: childrenIn, element, ...params }) => {
    let children: RouteObject[] = [];
    if (childrenIn) children = createBreadcrumbRoutesFromRoutes(childrenIn);

    if (breadcrumbName) {
      return {
        ...params,
        element: <BreadcrumbsItem text={breadcrumbName} nolink={breadcrumbDontLink} />,
        children,
        index: false
      };
    } else {
      return { ...params, element: null, children, index: false };
    }
  });
}

export const pageRouteObjects = autoIndexRoutes(routeObjects);
