import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { Asset } from 'assets';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { SoloLinkStyles } from './SoloLink.style';

export namespace CollapseToggleStyles {
  export const Holder = styled(SoloLinkStyles.SoloLinkLooksButton)<Props>(
    ({ small, collapsed }) => css`
      display: flex;
      align-items: center;
      gap: 0.5ex;
      margin-left: 10px;
      user-select: none;

      ${svgBasics({ width: small ? 14 : 20 }, 'currentColor')} // coloring is taken from link settings
  
      svg {
        transform: rotate(${collapsed ? '0deg' : '180deg'});
        transition: transform 0.3s;
        cursor: pointer;
      }
    `
  );
}

interface Props {
  collapsed: boolean;
  health?: State;
  label?: string;
  small?: boolean;
  onClick?: () => void;
}
export const CollapseToggle = ({ collapsed, health, label, small, onClick, ...rest }: Props) => {
  return (
    <CollapseToggleStyles.Holder
      data-testid='collapse-toggle'
      health={health}
      aria-label={'Section collapse toggle'}
      aria-expanded={!collapsed}
      role='button'
      collapsed={collapsed}
      small={small}
      onClick={onClick}
      {...rest} // this supports aria/data attributes
    >
      {label}
      <Asset.ArrowToggle />
    </CollapseToggleStyles.Holder>
  );
};
