/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/api/v2/core/socket_option.proto" (package "envoy.api.v2.core", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Socket Option ]

/**
 * Generic socket option message. This would be used to set socket options that
 * might not exist in upstream kernels or precompiled Envoy binaries.
 * [#next-free-field: 7]
 *
 * @generated from protobuf message envoy.api.v2.core.SocketOption
 */
export interface SocketOption {
    /**
     * An optional name to give this socket option for debugging, etc.
     * Uniqueness is not required and no special meaning is assumed.
     *
     * @generated from protobuf field: string description = 1;
     */
    description: string;
    /**
     * Corresponding to the level value passed to setsockopt, such as IPPROTO_TCP
     *
     * @generated from protobuf field: int64 level = 2;
     */
    level: number;
    /**
     * The numeric name as passed to setsockopt
     *
     * @generated from protobuf field: int64 name = 3;
     */
    name: number;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "intValue";
        /**
         * Because many sockopts take an int value.
         *
         * @generated from protobuf field: int64 int_value = 4;
         */
        intValue: number;
    } | {
        oneofKind: "bufValue";
        /**
         * Otherwise it's a byte buffer.
         *
         * @generated from protobuf field: bytes buf_value = 5;
         */
        bufValue: Uint8Array;
    } | {
        oneofKind: undefined;
    };
    /**
     * The state in which the option will be applied. When used in BindConfig
     * STATE_PREBIND is currently the only valid value.
     *
     * @generated from protobuf field: envoy.api.v2.core.SocketOption.SocketState state = 6;
     */
    state: SocketOption_SocketState;
}
/**
 * @generated from protobuf enum envoy.api.v2.core.SocketOption.SocketState
 */
export enum SocketOption_SocketState {
    /**
     * Socket options are applied after socket creation but before binding the socket to a port
     *
     * @generated from protobuf enum value: STATE_PREBIND = 0;
     */
    STATE_PREBIND = 0,
    /**
     * Socket options are applied after binding the socket to a port but before calling listen()
     *
     * @generated from protobuf enum value: STATE_BOUND = 1;
     */
    STATE_BOUND = 1,
    /**
     * Socket options are applied after calling listen()
     *
     * @generated from protobuf enum value: STATE_LISTENING = 2;
     */
    STATE_LISTENING = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class SocketOption$Type extends MessageType<SocketOption> {
    constructor() {
        super("envoy.api.v2.core.SocketOption", [
            { no: 1, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "level", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "name", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "int_value", kind: "scalar", oneof: "value", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "buf_value", kind: "scalar", oneof: "value", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "state", kind: "enum", T: () => ["envoy.api.v2.core.SocketOption.SocketState", SocketOption_SocketState], options: { "validate.rules": { enum: { definedOnly: true } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.core.SocketOption
 */
export const SocketOption = new SocketOption$Type();
