import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { BasicDestinationsTable } from 'Components/Features/Destinations/BasicDestinationsTable';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getTimeAsSecondsString } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetConnectionPolicyDetails } = policiesApi;

const policyType = PolicyType.CONNECTION;

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ConnectionDetails = ({ clusterObjRef }: Props) => {
  di(useGetConnectionPolicyDetails);
  const destinationsPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetConnectionPolicyDetails(
    clusterObjRef,
    destinationsPaging.apiPaginationObject
  );

  destinationsPaging.useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          TCP keepalive Settings
          <CardStyles.CardHeaderRightIcon>
            <Asset.TcpIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.MaxConnectionsIcon />,
              title: 'Max Connections',
              data: policyData.tcpMaxConnections
            },
            {
              icon: <Asset.TimeoutPolicyIcon />,
              title: 'Timeout',
              data: policyData.timeout ? getTimeAsSecondsString(policyData.timeout) : 'No timeout configured'
            }
          ]}
        />

        <StatMiniCardList
          items={[
            {
              icon: <Asset.ProbeIcon />,
              title: 'Probes',
              data: policyData.keepalive?.probes ?? 'No probes configured'
            },
            {
              icon: <Asset.MaxAgeIcon />,
              title: 'Time',
              data:
                policyData.keepalive?.time !== undefined
                  ? getTimeAsSecondsString(policyData.keepalive.time)
                  : 'No time configured'
            },
            {
              icon: <Asset.IntervalIcon />,
              title: 'Interval',
              data:
                policyData.keepalive?.interval !== undefined
                  ? getTimeAsSecondsString(policyData.keepalive.interval)
                  : 'No interval configured'
            }
          ]}
        />
      </CardStyles.Card>

      {policyData.httpSettings && (
        <CardStyles.Card>
          <CardStyles.CardHeader>
            HTTP keepalive Settings
            <CardStyles.CardHeaderRightIcon>
              <Asset.HttpMethodIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          <StatMiniCardList
            items={[
              {
                icon: <Asset.RequestIcon />,
                title: 'Max Requests per Connection',
                data:
                  policyData.httpSettings.maxRequestsPerConnection === 0
                    ? 'unlimited'
                    : policyData.httpSettings.maxRequestsPerConnection
              },
              {
                icon: <Asset.RetryPolicyIcon />,
                title: 'Max Retries',
                data:
                  policyData.httpSettings.maxRetries === 0
                    ? // Although the istio docs don't indicate it, 0 is treated the same as not defining a value, which is treated as default
                      `${Math.pow(2, 32) - 1} (Default)`
                    : policyData.httpSettings.maxRetries
              },
              {
                icon: <Asset.TimeoutPolicyIcon />,
                title: 'Idle Timeout',
                data:
                  policyData.httpSettings.idleTimeout !== undefined
                    ? getTimeAsSecondsString(policyData.httpSettings.idleTimeout)
                    : 'No timeout configured (Default: 1 hour)'
              }
            ]}
          />
        </CardStyles.Card>
      )}

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable
          destinations={policyData.destinations?.destinations}
          paging={destinationsPaging.pagingData}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
