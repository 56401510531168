import { CardStyles } from 'Components/Common/Card';
import { HealthIndicatorIcon } from 'Components/Common/HealthIndicator';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { GetYamlParams, MultiYamlModal } from 'Components/Common/YamlModal';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlApiExplorerIcon } from 'assets/assets';
import { GraphqlApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { graphqlApiTypeMap } from 'utils/types';
import { buildGraphqlApiExplorerUrl } from 'utils/url-builders';
import GqlProxiedRemoteConfigModal from '../Modals/GqlProxiedRemoteConfigModal';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlDetailsStyles as Styles } from './GqlDetails.style';

const GqlTitleCard = () => {
  const navigate = useNavigate();
  const gqlCtx = useContext(GqlLandingContext);
  const { api, apiType, istioRouteName, routeTableRef } = gqlCtx;
  const [showProxiedRemoteConfigModal, setShowProxiedRemoteConfigModal] = useState(false);
  const [yamlResources, setYamlResources] = useState<GetYamlParams[]>();
  const apiWorkspace = api.ownerWorkspace?.ref?.name;

  if (routeTableRef === undefined) return null;
  const apiIcon = graphqlApiTypeMap[apiType].icon;

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='gql-title-card'>
      <Styles.TitleCardGrid>
        <Styles.ApiIconCircleContainer>
          <HealthIndicatorIcon status={api.status} icon={apiIcon} />
        </Styles.ApiIconCircleContainer>
        <Styles.TitleContainer>
          <Styles.Title>
            {istioRouteName}
            <Styles.TitleSecondary>({graphqlApiTypeMap[apiType].name})</Styles.TitleSecondary>
          </Styles.Title>
          <SecondaryInfo
            highlightByDefault
            items={[
              {
                label: 'route table',
                data: routeTableRef.name ?? 'unknown'
              },
              {
                label: 'workspace',
                data: apiWorkspace ?? 'unknown'
              },
              {
                label: 'cluster',
                data: routeTableRef.clusterName ?? 'unknown'
              },
              {
                label: 'namespace',
                data: routeTableRef.namespace ?? 'unknown'
              }
            ]}
          />
        </Styles.TitleContainer>
        <Styles.ViewYAMLContainer>
          {apiType === GraphqlApiType.PROXIED && (
            <Spacer mr={3}>
              <SoloButton
                onClick={() => {
                  setShowProxiedRemoteConfigModal(true);
                }}
                data-testid='gql-title-remote-config-button'>
                Remote Config
              </SoloButton>
              <GqlProxiedRemoteConfigModal
                routeName={istioRouteName}
                show={showProxiedRemoteConfigModal}
                onClose={() => setShowProxiedRemoteConfigModal(false)}
                api={api}
              />
            </Spacer>
          )}
          <Spacer mr={3}>
            <SoloButton
              onClick={() =>
                navigate(
                  buildGraphqlApiExplorerUrl({
                    routeTableRef,
                    istioRouteName
                  })
                )
              }
              data-testid='gql-title-explorer-button'>
              <GqlApiExplorerIcon />
              &nbsp;&nbsp;Explore API
            </SoloButton>
          </Spacer>
          <SoloButton
            onClick={() => {
              let resources: GetYamlParams[] = [];
              const push = (resourceType: ResourceType, clusterObjectRef: ClusterObjectRef | undefined) => {
                if (clusterObjectRef) resources.push({ resourceType, clusterObjectRef });
              };

              push(ResourceType.ROUTE_TABLE, routeTableRef);
              push(ResourceType.GRAPH_QL_SCHEMA, api.graphqlSchemaRef);
              push(ResourceType.GRAPH_QL_STITCHED_SCHEMA, api.graphqlStitchedSchemaRef);
              push(ResourceType.API_DOC, api.apiDocRef);
              api.graphqlResolverMapRefs.forEach(ref => push(ResourceType.GRAPH_QL_RESOLVER_MAP, ref));

              setYamlResources(resources);
            }}
            data-testid='gql-title-yaml-button'>
            View YAML
          </SoloButton>
          {!!yamlResources && (
            <MultiYamlModal
              title={routeTableRef.name}
              icon={apiIcon}
              status={api.status}
              resources={yamlResources}
              onClose={() => setYamlResources(undefined)}
            />
          )}
        </Styles.ViewYAMLContainer>
      </Styles.TitleCardGrid>
    </CardStyles.Card>
  );
};

export default GqlTitleCard;
