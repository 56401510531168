import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { SoloTableStyles } from 'Components/Common/SoloTable.style';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { Asset } from 'assets';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useEffect, useState } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { getDestinationKindIcon, getDestinationKindReadableName } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';

interface TableFields {
  key: string;
  name: StateAndNameType;
  clusterName?: string;
  namespace?: string;
  type?: DestinationKind;
}
const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render(type) {
      return (
        <SoloTableStyles.CellIconText>
          {getDestinationKindIcon(type)} {getDestinationKindReadableName(type)}
        </SoloTableStyles.CellIconText>
      );
    }
  },
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' })
];

interface BasicDestinationsTableProps {
  destinations:
    | {
        displayName?: string;
        destinationRef?: ClusterObjectRef;
        serviceRef?: ClusterObjectRef;
        status?: Status;
        type: DestinationKind;
      }[]
    | undefined;
  paging: PagingProps;
}
export const BasicDestinationsTable = ({ destinations, paging }: BasicDestinationsTableProps) => {
  const [tableData, setTableData] = useState<TableFields[]>([]);

  useEffect(() => {
    if (!!destinations) {
      setTableData(
        destinations.map(data => {
          let usedRef = data.destinationRef;
          if (data?.serviceRef) {
            usedRef = data.serviceRef;
          }

          return {
            ...data,
            ...usedRef,
            key: buildIdFromRef(usedRef),
            name: {
              name: data.displayName ?? usedRef?.name ?? '',
              status: data.status
            }
          };
        })
      );
    } else {
      setTableData([]);
    }
  }, [destinations]);

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={paging}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.ServiceIcon />}
          title='There are no Destinations to display'
          description=''
          href={docLinks.enterprise.workspace_export}
        />
      }
    />
  );
};
