import styled from '@emotion/styled';
import soloLogo from 'assets/meta/solo-logo.png';
import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { FooterStyles } from './Footer.style';
import { externalLinks } from 'utils/url-external-links-map';

// size is 557x166.  Ratio to 1/4 that size.
const StyledIcon = styled.img`
  width: auto;
  height: 40px;
  display: block;
`;

export const Footer = () => {
  return (
    <FooterStyles.Container data-testid='footer'>
      <FooterStyles.Copyright>
        <SecondaryInfoItem data={`Gloo Platform v${process.env.UI_VERSION}`} small />
        <span>© {new Date().getFullYear()} solo.io, Inc. All Rights Reserved.</span>
        <FooterStyles.PrivacyPolicyContainer href={externalLinks.solo_io.privacy_policy} target='_blank'>
          Privacy Policy
        </FooterStyles.PrivacyPolicyContainer>
      </FooterStyles.Copyright>
      <FooterStyles.IconContainer href={externalLinks.solo_io.root} target='_blank'>
        <FooterStyles.Tagline>Powered by</FooterStyles.Tagline>
        {/* If we switch to svg, here's the code: <StyledIcon width='86' height='40' viewBox='0 0 300 100' /> */}
        <StyledIcon src={soloLogo} alt='solo.io Logo' />
      </FooterStyles.IconContainer>
    </FooterStyles.Container>
  );
};
