import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { EnumTypeDefinitionNode, EnumValueDefinitionNode, Kind } from 'graphql';
import { useContext, useMemo } from 'react';
import { GqlSchemaTabItemProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import { GqlDescriptionInfoTooltip } from '../../GqlDescriptionInfoTooltip';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';

type TableFields = {
  key: string;
  isFieldIncludedByExtension: boolean;
  value: string;
  description: string;
};
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Value',
    dataIndex: 'value',
    render: (value, record) => (
      <FlexLayout>
        {!!record.isFieldIncludedByExtension && (
          <GqlDescriptionInfoTooltip description={'This enum was extended to include this value.'} />
        )}
        <GqlSchemaTabStyles.MonospaceItem>{value}</GqlSchemaTabStyles.MonospaceItem>
      </FlexLayout>
    )
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: renderReactMarkdown
  }
];

const GqlEnumTabItem = ({
  onTypeClick,
  focusedItem,
  tabHeader,
  focusedPath
}: GqlSchemaTabItemProps<EnumTypeDefinitionNode>) => {
  const gqlctx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlctx;
  const focusedItemExtensions = getExtensionInfo(Kind.ENUM_TYPE_EXTENSION, focusedItem.name.value);

  const combinedValues = useMemo(
    () =>
      [
        ...(focusedItem.values ?? []),
        ...(focusedItemExtensions?.values?.map(d => ({ ...d, isFieldIncludedByExtension: true })) ?? [])
      ] as (EnumValueDefinitionNode & { isFieldIncludedByExtension?: boolean })[],
    [focusedItem.values, focusedItemExtensions]
  );

  const tableData: readonly TableFields[] = useMemo(
    () =>
      combinedValues.map(v => ({
        key: v.name.value,
        isFieldIncludedByExtension: !!v.isFieldIncludedByExtension,
        value: v.name.value,
        description: getDescriptionColumnValue(v)
      })) ?? [],
    [focusedItem.values, focusedItemExtensions]
  );

  //
  // Render
  //
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectiveList
        node={focusedItem}
        extendedDirectives={focusedItemExtensions?.directives}
        onTypeClick={onTypeClick}
      />
      <GqlTabItemDescription node={focusedItem} />
      <SoloTable removeHorizontalPadding columns={columns} dataSource={tableData} data-testid='gql-enum-values-table' />
    </>
  );
};

export default GqlEnumTabItem;
