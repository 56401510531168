import styled from '@emotion/styled';
import { portalApi } from 'Api/portal';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { StringList } from 'Components/Common/StringList';
import { Asset } from 'assets';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { makeClusterObjectRef } from 'utils/helpers';
import { PortalPublishedApisTable } from './PortalPublishedApisTable';
import { UsagePlanTable } from './UsagePlanTable';

const { useGetPortalDetails } = portalApi;

const DomainsListContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 10px;
  gap: 10px;
`;

const DomainsList = ({ domains: [first, ...domains] }: { domains: string[] }) => {
  if (!first) return null;
  return (
    <DomainsListContainer>
      <ExternalSoloLink href={first} newTab>
        {first}
      </ExternalSoloLink>
      <StringList
        list={domains.map(d => (
          <ExternalSoloLink key={d} href={d} newTab>
            {d}
          </ExternalSoloLink>
        ))}
        tooltipPlacement='right'
      />
    </DomainsListContainer>
  );
};

export type PortalDetailsLandingParams = {
  portalName: string;
  portalNamespace: string;
  portalCluster: string;
};

export const PortalDetailsLanding = () => {
  di(useGetPortalDetails, useParams);
  const { portalName, portalNamespace, portalCluster } = useParams<PortalDetailsLandingParams>();
  const portalRef = makeClusterObjectRef(portalName, portalNamespace, portalCluster)!;
  const { data: portalData, error: portalError } = useGetPortalDetails(portalRef);

  if (!!portalError || !portalData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={<Asset.PortalIcon />} objRef={portalRef} />
        {!!portalError ? (
          <DataError error={portalError} />
        ) : (
          <Loading message={`Retrieving ${portalRef.name} OpenAPI details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={<Asset.PortalIcon />}
        status={portalData?.status}
        contentAfterName={<DomainsList domains={portalData.domains} />}
        objRef={portalRef}
        workspaceRef={portalData.ownerWorkspace?.ref}
        resourceType={ResourceType.PORTAL}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Published APIs</CardStyles.CardHeader>
        <PortalPublishedApisTable apis={portalData.publishedApis} />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>Usage Plans</CardStyles.CardHeader>
        <UsagePlanTable plans={portalData.usagePlans} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
