import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { GraphqlApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext } from 'react';
import { GqlLandingContext } from '../context/GqlLandingContext';
import GqlAppliedToDestinationsCard from './GqlAppliedToDestinationsCard';
import { GqlDetailsStyles as Styles } from './GqlDetails.style';
import GqlGatewaysCard from './GqlGatewaysCard';
import GqlSchemaCard from './GqlSchemaCard';
import GqlSettingsCard from './GqlSettingsCard';
import GqlSubgraphsCard from './GqlSubgraphsCard';
import GqlTitleCard from './GqlTitleCard';
import GqlWorkspaceDepsCard from './GqlWorkspaceDepsCard';

const GqlDetails = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { apiType, schemaError } = gqlCtx;

  //
  // Render
  //
  return (
    <Styles.Container data-testid='gql-landing-details'>
      <GqlTitleCard />
      {!!schemaError ? (
        <CardStyles.Card>
          <Styles.SchemaSection>
            <Styles.StyledCardHeader>Schema</Styles.StyledCardHeader>
            <DataError error={schemaError} />
          </Styles.SchemaSection>
        </CardStyles.Card>
      ) : (
        <GqlSchemaCard />
      )}
      {apiType === GraphqlApiType.STITCHED && <GqlSubgraphsCard />}
      <CardStyles.HorizontalLayout>
        <GqlSettingsCard />
        <GqlGatewaysCard />
      </CardStyles.HorizontalLayout>
      <GqlWorkspaceDepsCard />
      {apiType !== GraphqlApiType.STITCHED && <GqlAppliedToDestinationsCard />}
    </Styles.Container>
  );
};

export default GqlDetails;
