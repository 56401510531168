import { DashboardContext } from 'Components/Features/Dashboard/DashboardContext';
import { useContext } from 'react';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { useCombinedInsightStatusOrLoading } from '../hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from '../insight-codes';
import { IstioHealthCheckSectionContent } from './IstioHealthCheckSectionContent';

export const IstioHealthCheckSection = () => {
  di(useFilteredDashboardInsights);
  const { dashboardCardsData } = useContext(DashboardContext);
  const lifecycleManagementStatus =
    dashboardCardsData === undefined ? 'loading' : dashboardCardsData.healthOverview?.lifecycleManagementStatus;

  const istioControlPlaneInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.istioControlPlane
  );
  const istioControlPlaneStatus = useCombinedInsightStatusOrLoading(istioControlPlaneInsights);

  const istioDataPlaneInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.istioDataPlane
  );
  const istioDataPlaneStatus = useCombinedInsightStatusOrLoading(istioDataPlaneInsights);

  return (
    <IstioHealthCheckSectionContent
      istioControlPlaneInsights={istioControlPlaneInsights}
      lifecycleManagementStatus={lifecycleManagementStatus}
      istioControlPlaneStatus={istioControlPlaneStatus}
      istioDataPlaneStatus={istioDataPlaneStatus}
    />
  );
};
