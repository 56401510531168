import { Global, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { SWRConfig } from 'swr';
import { defaultTheme, globalStyles } from './Styles';

import { AuthLoginPage } from 'Components/Structure/AuthLoginPage';
import { TopBar } from 'Components/Structure/TopBar/TopBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppUtilsContextProvider } from 'utils/context/AppUtilsContext';
import { urlStrings } from 'utils/url-string-constants';
import { Content } from './Components/Structure/Content';
import { Sidebar } from './Components/Structure/Sidebar/Sidebar';
import { debugSWRMiddleware, useSWRDebug } from './utils/swr-debug';

const AppContainer = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: fit-content(235px) 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    'sidebar topbar'
    'sidebar content';
`;

const SwrDebugWrapper = ({ children }: { children: React.ReactNode }) => {
  const { debugProvider } = useSWRDebug();

  if (debugProvider) {
    return (
      <SWRConfig
        value={{
          provider: debugProvider,
          use: [debugSWRMiddleware],
          shouldRetryOnError: false,
          dedupingInterval: 2000
        }}>
        {children}
      </SWRConfig>
    );
  }

  return <>{children}</>;
};

const GlooMeshApp = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <SwrDebugWrapper>
          <AppUtilsContextProvider>
            <Global styles={globalStyles} />
            <Routes>
              <Route path={`/${urlStrings.welcome}/`} element={<AuthLoginPage />} />
              <Route
                path='*'
                element={
                  <AppContainer>
                    <Sidebar />

                    <TopBar />

                    <Content />
                  </AppContainer>
                }
              />
            </Routes>
          </AppUtilsContextProvider>
        </SwrDebugWrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default hot(GlooMeshApp);
