import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace SoloCheckboxListStyles {
  ////////////////////////////
  // Checkbox List
  ////////////////////////////

  export const OptionsList = styled.div(
    ({ theme }) => css`
      position: relative;
      max-height: 256px;
      overflow-y: auto;
      overflow-anchor: none;
      li {
        padding: 0 8px;
      }
      box-shadow: ${theme.juneGrey} 0px 2px 8px;
      border-radius: 6px;
      min-width: 250px;

      > div {
        display: flex;
        flex-direction: column;
      }

      .ant-checkbox-checked:after {
        border-radius: 4px;
      }

      .ant-checkbox-wrapper {
        display: flex;
        margin-right: 0;
        color: ${theme.septemberGrey};
        height: 23px;

        .ant-checkbox-inner {
          border-radius: 4px;
        }

        &.ant-checkbox-wrapper-checked {
          background: none;
          color: initial;
          // Using font-weight here causes a small (~1-2px) layout shift transition
          // when the dropdown is opened. Using min-width: 250px on the the OptionsList
          // makes it so the width is usually large enough that this doesn't happen.
          font-weight: 500;

          .ant-checkbox-inner {
            background-color: ${theme.seaBlue};
            border-color: ${theme.seaBlue};
          }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner {
          border-color: ${theme.seaBlue};

          &::after {
            background: ${theme.seaBlue};
            height: 2px;
            border-radius: 2px;
          }
        }

        &.ant-checkbox-wrapper-checked .ant-checkbox-inner,
        .ant-checkbox-indeterminate .ant-checkbox-inner {
          background: ${theme.puddleBlue};

          &:after {
            border-color: ${theme.seaBlue};
          }
        }

        &:hover .ant-checkbox-inner {
          border-color: ${theme.seaBlue} !important;
        }

        &:hover .ant-checkbox-disabled .ant-checkbox-inner {
          border: 1px solid ${theme.aprilGrey} !important;
        }
      }
    `
  );

  ////////////////////////////
  // Dropdown
  ////////////////////////////

  export const SelectWrap = styled.span(
    ({ theme }) => css`
      display: flex;

      span.anticon {
        margin-left: 5px;
        font-size: 14px !important; // Font size controls the size of the icon
      }
    `
  );

  export const InputContainerStyle = styled.div(({ theme }) => {
    return css`
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: white;
      border-radius: 3px;
      padding: 0 15px 0 11px;
      line-height: 28px;
      font-weight: 400;
      font-size: 16px;
      color: ${theme.septemberGrey};
      border: 1px solid ${theme.aprilGrey};
    `;
  });

  export const DropdownTrigger = InputContainerStyle.withComponent(UnstyledButton);

  export const SearchWrap = styled.div(
    ({ theme }) => css`
      position: relative;
      padding: 0 8px;
      margin-bottom: 5px;

      input {
        padding-right: 30px;
      }

      ${svgBasics({ height: 20 }, theme.juneGrey)}
      svg {
        position: absolute;
        top: 4px;
        right: 15px;
      }
    `
  );
}
