import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cache, Middleware, SWRHook, unstable_serialize as swrInternalSerialize, useSWRConfig } from 'swr';
import { doDownload, readFileAsText } from './helpers';

/*
  To dump app state, call `window.__DUMP_SWR_CACHE__(<<optional filename>>)` or use a JavaScript bookmarklet like:
  javascript: window.__DUMP_SWR_CACHE__?.()

  To upload app state, call `window.__UPLOAD_SWR_CACHE__()` or use a JavaScript bookmarklet like:
  javascript: window.__UPLOAD_SWR_CACHE__?.()
*/

declare global {
  interface Window {
    __SWR_CACHE__: Cache<any>;
    __DUMP_SWR_CACHE__: (arg0?: string) => void;
    __UPLOAD_SWR_CACHE__: () => void;
  }
}

async function promptFileSelect(accept?: string) {
  return new Promise<File>((resolve, reject) => {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.multiple = false;
    input.setAttribute('type', 'file');
    input.setAttribute('accept', accept ?? '.txt,application/json');
    input.addEventListener('change', () => {
      if (!input.files?.length) return reject();
      resolve(input.files[input.files.length - 1]);
    });
    input.click();
  });
}

export function useSWRDebug() {
  const navigate = useNavigate();
  const [uploadedCache, setUploadedCache] = useState<Map<string, any>>();
  const { cache } = useSWRConfig();

  window.__SWR_CACHE__ = cache;

  window.__DUMP_SWR_CACHE__ = filename => {
    const dump = JSON.stringify({
      currentPage: window.location.href,
      uiVersion: process.env.UI_VERSION,
      localStorage: window.localStorage,
      swrCache: Object.fromEntries(cache as any),
      time: new Date(),
      screenSize: {
        ...window.screen,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        clientWidth: document.body.clientWidth,
        clientHeight: document.body.clientHeight
      },
      browserInfo: navigator // This stuff is a mess and often browsers lie or respond with weird/surprising stuff. Grabbing the whole thing is our best bet for parsing it all
    });

    filename ??= (prompt('Dump filename (blank for default)') || 'gloo-platform-ui-cache') + '.json.txt';
    doDownload(dump, filename, 'text/json');
  };

  window.__UPLOAD_SWR_CACHE__ = async () => {
    const file = await promptFileSelect();
    const result = await readFileAsText(file);
    const parsedDump = JSON.parse(result);

    // may cause some issues if used on the same page that's being redirected to? since it may not re-read the storage
    Object.keys(parsedDump.localStorage).forEach(k => {
      window.localStorage.setItem(k, parsedDump.localStorage[k]);
    });

    const newCache = new Map(
      Object.entries(parsedDump.swrCache)
        // The notification system includes React components in it's storage, which can't be properly read from
        // this data. Removing it here prevents a crash.
        .filter(([k]) => !['notificationsystem'].includes(k))
    );
    if (uploadedCache) {
      // If dump already in use, remove old one, then delay for a bit to allow a render cycle before setting new data.
      // This is needed since `SWRConfig` does seem to update the provider once it's set; so we have to tell it to
      // stop rendering the SWRConfig and then render it again with the new data
      setUploadedCache(undefined);
      setTimeout(() => setUploadedCache(newCache), 100);
    } else {
      setUploadedCache(newCache);
    }

    console.log('--- CACHE SUCCESSFULLY LOADED FROM FILE'); // eslint-disable-line

    if (parsedDump.currentPage) {
      console.log('--- DETECTED URL; redirecting to:', parsedDump.currentPage); // eslint-disable-line
      const url = new URL(parsedDump.currentPage);
      navigate(url.href.replace(url.origin, ''));
    }
  };

  return { debugProvider: uploadedCache ? () => uploadedCache : undefined };
}

export const debugSWRMiddleware: Middleware = (useSWRNext: SWRHook) => (key: any, fetcher, config: any) => {
  const getFromCache = async () => {
    let response = config.cache.get(swrInternalSerialize(key));
    // There's some internal swr logic that when data isn't as expected (such as data being undefined), then data will
    // returned as a copy of the parent data (ex: `{ _k:"", isValidating:true, data:{ _k:"", isValidating:true } }`)
    // to work around this, we check for when this happens and force it back to correct data form
    return response?._k ? response.data : response;
  };
  return useSWRNext(key, getFromCache, config);
};
