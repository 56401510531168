import { CardStyles } from 'Components/Common/Card';
import { HealthIndicatorWithName } from 'Components/Common/HealthIndicator';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { OverviewDetailsStyles } from 'Components/Features/Overview/OverviewDetails.style';
import { ExportIcon, ImportIcon } from 'assets/assets';
import { useContext, useMemo } from 'react';
import { GqlLandingContext } from '../context/GqlLandingContext';

const GqlWorkspaceDepsCard = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api } = gqlCtx;
  const wsExportedTo = useMemo(
    () =>
      api.dependencies?.workspacesExportedTo.map(w => ({
        name: w.ref?.name ?? '',
        status: w.status
      })),
    [api.dependencies?.workspacesExportedTo]
  );
  const wsImportedFrom = useMemo(
    () =>
      api.dependencies?.workspacesImporting.map(w => ({
        name: w.ref?.name ?? '',
        status: w.status
      })),
    [api.dependencies?.workspacesImporting]
  );
  return (
    <CardStyles.Card data-testid='gql-workspace-deps-card'>
      <CardStyles.CardHeader>Workspace Dependencies</CardStyles.CardHeader>
      <OverviewDetailsStyles.SubDetails data-testid={'workspaces-exporting-details'}>
        <OverviewDetailsStyles.SubDetailsRow>
          <SoloListCard
            title='Workspaces exported to'
            icon={<ExportIcon />}
            dataSource={
              wsExportedTo?.map(data => ({
                key: data.name,
                data: <HealthIndicatorWithName {...data} status={data.status} />
              })) ?? []
            }
            renderEmpty={<SoloEmptySimple />}
          />

          <SoloListCard
            title='Workspaces importing this service'
            icon={<ImportIcon />}
            dataSource={
              wsImportedFrom?.map(data => ({
                key: data.name,
                data: <HealthIndicatorWithName {...data} status={data.status} />
              })) ?? []
            }
            renderEmpty={<SoloEmptySimple />}
          />
        </OverviewDetailsStyles.SubDetailsRow>
      </OverviewDetailsStyles.SubDetails>
    </CardStyles.Card>
  );
};

export default GqlWorkspaceDepsCard;
