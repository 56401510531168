import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { Asset } from 'assets';
import { useMemo, useState } from 'react';

const ExpandButton = styled(UnstyledButton)<{ isExpanded: boolean }>(
  ({ isExpanded, theme }) => css`
    ${SoloLinkStyles.SoloLinkLooks} {
      font-size: 14px;
      font-weight: 500;

      margin-top: 3px;
      border-radius: 5px;
      ${svgBasics({ width: 12 }, 'currentColor')}
      svg {
        margin-left: 3px;
        transform: translateY(-2px);
        transition: 0.3s rotate, 0.3s transform;
        rotate: 180deg;
        ${!isExpanded
          ? css`
              transform: translateY(2px);
              rotate: 0deg;
            `
          : ''}
      }
    }
  `
);

const ExpandableStringList = ({
  items,
  maxItemsToShowWhenCollapsed = 2
}: {
  items: string[];
  maxItemsToShowWhenCollapsed?: number;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const canExpand = maxItemsToShowWhenCollapsed < items.length;
  //
  // Get the actual number of items we will render.
  const numItemsToShowWhenCollapsed = Math.min(maxItemsToShowWhenCollapsed, items.length);
  const numHiddenIfExpanded = items.length - numItemsToShowWhenCollapsed;
  //
  // Get the copy of the items array that will actually render.
  const itemsToRender = useMemo(() => {
    if (isExpanded) {
      return items;
    } else {
      return items.slice(0, numItemsToShowWhenCollapsed);
    }
  }, [isExpanded]);

  //
  // Render
  //
  return (
    <Spacer my='5px' data-testid='expandable-string-list'>
      {itemsToRender.map((item, idx) => (
        <div key={idx}>{item}</div>
      ))}
      {canExpand && (
        <ExpandButton
          onClick={e => {
            // Needs to stopPropagation because when this is in a <SoloTable>, clicking on a row
            // navigates the user to that details page through the onRow onClick callback.
            // Adding stopPropagation() here prevents that callback from happening so that
            // this interaction can be used.
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          isExpanded={isExpanded}>
          {isExpanded ? (
            <SoloLinkStyles.SoloLinkLooks>
              Show {numHiddenIfExpanded} Less <Asset.ArrowToggle />
            </SoloLinkStyles.SoloLinkLooks>
          ) : (
            <SoloLinkStyles.SoloLinkLooks>
              Show {numHiddenIfExpanded} More <Asset.ArrowToggle />
            </SoloLinkStyles.SoloLinkLooks>
          )}
        </ExpandButton>
      )}
    </Spacer>
  );
};

export default ExpandableStringList;
