import styled from '@emotion/styled';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SoloModal } from 'Components/Common/SoloModal';
import { SoloModalStyles } from 'Components/Common/SoloModal.style';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { parse, print } from 'graphql';
import { useContext, useMemo, useState } from 'react';
import { GqlLandingContext } from '../../context/GqlLandingContext';

export const StyledSdlModalContent = styled.div`
  padding: 0.5rem 1rem 1rem 1rem;
  font-size: 80%;
`;

const GqlShowSchemaButton = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const {
    api: { schemaDefinition }
  } = gqlCtx;
  const [showingModal, setShowingModal] = useState(false);
  const formattedSdl = useMemo(() => {
    try {
      return print(parse(schemaDefinition));
    } catch {
      return schemaDefinition;
    }
  }, [schemaDefinition]);

  return (
    <>
      <SoloButton small onClick={() => setShowingModal(true)}>
        View SDL
      </SoloButton>
      <SoloModal width={1000} visible={showingModal} onClose={() => setShowingModal(false)}>
        <SoloModalStyles.SimpleTitle>Schema</SoloModalStyles.SimpleTitle>
        <StyledSdlModalContent>
          <FancyCodeDisplayer contentString={formattedSdl} type={'graphql'} />
        </StyledSdlModalContent>
      </SoloModal>
    </>
  );
};

export default GqlShowSchemaButton;
