/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { ObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.Pagination
 */
export interface Pagination {
    /**
     * @generated from protobuf field: int32 limit = 1;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 offset = 2;
     */
    offset: number;
}
/**
 * Status of a Mesh, Destination, or Workload as it should be displayed on the UI
 *
 * @generated from protobuf message rpc.gloo.solo.io.Status
 */
export interface Status {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.State state = 1;
     */
    state: State;
    /**
     * Validation errors, if any.
     *
     * @generated from protobuf field: repeated string validation_errors = 2;
     */
    validationErrors: string[];
}
/**
 * The export / import state of a resource
 *
 * @generated from protobuf message rpc.gloo.solo.io.WorkspaceDependencies
 */
export interface WorkspaceDependencies {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.WorkspaceStatus workspaces_exported_to = 1;
     */
    workspacesExportedTo: WorkspaceStatus[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.WorkspaceStatus workspaces_importing = 2;
     */
    workspacesImporting: WorkspaceStatus[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.WorkspaceStatus
 */
export interface WorkspaceStatus {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef ref = 1;
     */
    ref?: ObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 2;
     */
    status?: Status;
}
/**
 * Based on api-v2/api/gloo.solo.io/common/v2/approval_state.proto
 *
 * @generated from protobuf enum rpc.gloo.solo.io.State
 */
export enum State {
    /**
     * @generated from protobuf enum value: PENDING = 0;
     */
    PENDING = 0,
    /**
     * @generated from protobuf enum value: ACCEPTED = 1;
     */
    ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: INVALID = 2;
     */
    INVALID = 2,
    /**
     * @generated from protobuf enum value: WARNING = 3;
     */
    WARNING = 3,
    /**
     * @generated from protobuf enum value: FAILED = 4;
     */
    FAILED = 4
}
/**
 * ImportStatus is the state of a resource relative to a workspace that contains it.
 *
 * @generated from protobuf enum rpc.gloo.solo.io.ImportStatus
 */
export enum ImportStatus {
    /**
     * @generated from protobuf enum value: UNKNOWN_IMPORT_STATUS = 0;
     */
    UNKNOWN_IMPORT_STATUS = 0,
    /**
     * The resource is not exported.
     *
     * @generated from protobuf enum value: PRIVATE = 1;
     */
    PRIVATE = 1,
    /**
     * The resource is exported from the workspace in question.
     *
     * @generated from protobuf enum value: IMPORTED = 2;
     */
    IMPORTED = 2,
    /**
     * The resource is exported from another workspace.
     *
     * @generated from protobuf enum value: EXPORTED = 3;
     */
    EXPORTED = 3
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.PolicyType
 */
export enum PolicyType {
    /**
     * @generated from protobuf enum value: UNKNOWN_POLICY_TYPE = 0;
     */
    UNKNOWN_POLICY_TYPE = 0,
    /**
     * @generated from protobuf enum value: WASM_DEPLOYMENT = 1;
     */
    WASM_DEPLOYMENT = 1,
    /**
     * @generated from protobuf enum value: ACCESS_LOG = 2;
     */
    ACCESS_LOG = 2,
    /**
     * @generated from protobuf enum value: FAILOVER = 3;
     */
    FAILOVER = 3,
    /**
     * @generated from protobuf enum value: OUTLIER_DETECTION = 4;
     */
    OUTLIER_DETECTION = 4,
    /**
     * @generated from protobuf enum value: RETRY_TIMEOUT = 5;
     */
    RETRY_TIMEOUT = 5,
    /**
     * @generated from protobuf enum value: ACCESS = 6;
     */
    ACCESS = 6,
    /**
     * @generated from protobuf enum value: CORS = 7;
     */
    CORS = 7,
    /**
     * @generated from protobuf enum value: CSRF = 8;
     */
    CSRF = 8,
    /**
     * @generated from protobuf enum value: EXT_AUTH = 9;
     */
    EXT_AUTH = 9,
    /**
     * @generated from protobuf enum value: MIRROR = 10;
     */
    MIRROR = 10,
    /**
     * @generated from protobuf enum value: RATE_LIMIT = 11;
     */
    RATE_LIMIT = 11,
    /**
     * @generated from protobuf enum value: TRANSFORMATION = 12;
     */
    TRANSFORMATION = 12,
    /**
     * @generated from protobuf enum value: FAULT_INJECTION = 13;
     */
    FAULT_INJECTION = 13,
    /**
     * @generated from protobuf enum value: HEADER_MANIPULATION = 14;
     */
    HEADER_MANIPULATION = 14,
    /**
     * @generated from protobuf enum value: WAF = 15;
     */
    WAF = 15,
    /**
     * @generated from protobuf enum value: JWT = 16;
     */
    JWT = 16,
    /**
     * @generated from protobuf enum value: PROXY_PROTOCOL = 17;
     */
    PROXY_PROTOCOL = 17,
    /**
     * @generated from protobuf enum value: CONNECTION = 18;
     */
    CONNECTION = 18,
    /**
     * @generated from protobuf enum value: HTTP_BUFFER = 19;
     */
    HTTP_BUFFER = 19,
    /**
     * @generated from protobuf enum value: CLIENT_TLS = 20;
     */
    CLIENT_TLS = 20,
    /**
     * @generated from protobuf enum value: DLP = 21;
     */
    DLP = 21,
    /**
     * @generated from protobuf enum value: ACTIVE_HEALTH_CHECK = 22;
     */
    ACTIVE_HEALTH_CHECK = 22,
    /**
     * @generated from protobuf enum value: TRIM_PROXY_CONFIG = 23;
     */
    TRIM_PROXY_CONFIG = 23,
    /**
     * @generated from protobuf enum value: LOAD_BALANCER = 24;
     */
    LOAD_BALANCER = 24,
    /**
     * @generated from protobuf enum value: LISTENER_CONNECTION = 25;
     */
    LISTENER_CONNECTION = 25,
    /**
     * @generated from protobuf enum value: GRAPHQL_PERSISTED_QUERY_CACHE = 26;
     */
    GRAPHQL_PERSISTED_QUERY_CACHE = 26,
    /**
     * @generated from protobuf enum value: GRAPHQL_ALLOWED_QUERY_POLICY = 27;
     */
    GRAPHQL_ALLOWED_QUERY_POLICY = 27,
    /**
     * @generated from protobuf enum value: ADAPTIVE_REQUEST_CONCURRENCY_POLICY = 28;
     */
    ADAPTIVE_REQUEST_CONCURRENCY_POLICY = 28
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.ApiType
 */
export enum ApiType {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: GRAPHQL_RESOLVED = 1;
     */
    GRAPHQL_RESOLVED = 1,
    /**
     * @generated from protobuf enum value: GRAPHQL_STITCHED = 2;
     */
    GRAPHQL_STITCHED = 2,
    /**
     * @generated from protobuf enum value: GRAPHQL_PROXIED = 3;
     */
    GRAPHQL_PROXIED = 3,
    /**
     * @generated from protobuf enum value: PORTAL_OPENAPI = 4;
     */
    PORTAL_OPENAPI = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class Pagination$Type extends MessageType<Pagination> {
    constructor() {
        super("rpc.gloo.solo.io.Pagination", [
            { no: 1, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Pagination
 */
export const Pagination = new Pagination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Status$Type extends MessageType<Status> {
    constructor() {
        super("rpc.gloo.solo.io.Status", [
            { no: 1, name: "state", kind: "enum", T: () => ["rpc.gloo.solo.io.State", State] },
            { no: 2, name: "validation_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Status
 */
export const Status = new Status$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkspaceDependencies$Type extends MessageType<WorkspaceDependencies> {
    constructor() {
        super("rpc.gloo.solo.io.WorkspaceDependencies", [
            { no: 1, name: "workspaces_exported_to", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkspaceStatus },
            { no: 2, name: "workspaces_importing", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkspaceStatus }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.WorkspaceDependencies
 */
export const WorkspaceDependencies = new WorkspaceDependencies$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkspaceStatus$Type extends MessageType<WorkspaceStatus> {
    constructor() {
        super("rpc.gloo.solo.io.WorkspaceStatus", [
            { no: 1, name: "ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.WorkspaceStatus
 */
export const WorkspaceStatus = new WorkspaceStatus$Type();
