import { grpcWebFetchTransport, soloGrpcCall, useRequest } from 'Api/helpers';
import {
  Code,
  GetInsightResolutionStepsRequest,
  ListInsightsRequest,
  ListInsightsRequest_InsightsFilter
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { InsightsApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb.client';
import { capitalizeFirstLetters } from 'utils/helpers';
import { useApiserverErrorsNotification } from 'utils/notificationsystem';

/**
 * Insights functions and hooks.
 */
export namespace insightsApi {
  const client = new InsightsApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.InsightsApi/' + capitalizeFirstLetters(fn.name);

  //
  // List Insights
  //
  export function listInsights(filter?: ListInsightsRequest_InsightsFilter, cursor?: string, limit?: number) {
    const request: ListInsightsRequest = {
      filter,
      cursor: cursor ?? '',
      limit: limit ?? 0
    };
    return soloGrpcCall(client.listInsights(request));
  }

  export function useListInsights(...args: Parameters<typeof listInsights>) {
    const swrResponse = useRequest(insightsApi.listInsights, args, {
      // The args are in the key so that the insights are re-fetched if the filter changes.
      key: getMethodDescriptorName(client.listInsights) + JSON.stringify(args),
      methodDescriptorName: getMethodDescriptorName(client.listInsights)
    });
    const { data } = swrResponse;
    // If there were apiserver errors, notify the user.
    useApiserverErrorsNotification('list insights', data);
    return swrResponse;
  }

  //
  // List Insights Resolution Steps
  //
  export function getInsightResolutionSteps(code?: Code) {
    const request: GetInsightResolutionStepsRequest = {
      code
    };
    return soloGrpcCall(client.getInsightResolutionSteps(request));
  }
  export function useGetInsightResolutionSteps(...args: Parameters<typeof getInsightResolutionSteps>) {
    return useRequest(insightsApi.getInsightResolutionSteps, args, {
      // The args are in the key so that the insights are re-fetched if the filter changes.
      key: getMethodDescriptorName(client.getInsightResolutionSteps) + JSON.stringify(args),
      methodDescriptorName: getMethodDescriptorName(client.getInsightResolutionSteps)
    });
  }
}
