import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { PagingProps, useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { PolicyVirtualGatewayListener } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef, getTimeAsSecondsString } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetListenerConnectionPolicyDetails } = policiesApi;

const policyType = PolicyType.LISTENER_CONNECTION;

type TableFields = {
  key: string;
  name: StateAndNameType;
  namespace?: string;
  clusterName?: string;
  port: number;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  {
    title: 'Port',
    dataIndex: 'port'
  }
];

const VirtualGatewayListenersTable = ({
  listeners,
  paging
}: {
  listeners: PolicyVirtualGatewayListener[] | undefined;
  paging: PagingProps;
}) => {
  const tableData = useMemo<TableFields[]>(
    () =>
      listeners?.map(vg => ({
        ...vg.ref,
        key: buildIdFromRef(vg.ref),
        name: { name: vg.ref?.name ?? '', status: vg.status },
        port: vg.port
      })) ?? [],
    [listeners]
  );
  return <SoloTable columns={columns} dataSource={tableData} paging={paging} />;
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ListenerConnectionDetails = ({ clusterObjRef }: Props) => {
  di(useGetListenerConnectionPolicyDetails);
  const paging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetListenerConnectionPolicyDetails(
    clusterObjRef,
    paging.apiPaginationObject
  );

  paging.useSetPagingTotal(policyData?.virtualGatewayListeners?.totalVirtualGatewayListeners);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.MaxRequestBytes />,
              title: 'Per Connection Buffer Limit Bytes',
              data: policyData.perConnectionBufferLimitBytes?.value ?? 'No limit bytes configured'
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          TCP keepalive Settings
          <CardStyles.CardHeaderRightIcon>
            <Asset.TcpIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.ProbeIcon />,
              title: 'Probes',
              data: policyData.tcpKeepalive?.probes ?? 'No probes configured'
            },
            {
              icon: <Asset.MaxAgeIcon />,
              title: 'Time',
              data:
                policyData.tcpKeepalive?.time !== undefined
                  ? getTimeAsSecondsString(policyData.tcpKeepalive.time)
                  : 'No time configured'
            },
            {
              icon: <Asset.IntervalIcon />,
              title: 'Interval',
              data:
                policyData.tcpKeepalive?.interval !== undefined
                  ? getTimeAsSecondsString(policyData.tcpKeepalive.interval)
                  : 'No interval configured'
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Virtual Gateway Listeners
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <VirtualGatewayListenersTable
          listeners={policyData.virtualGatewayListeners?.virtualGatewayListeners}
          paging={paging.pagingData}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
