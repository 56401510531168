import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace CardStyles {
  export const Card = styled.div(
    ({ theme }) => css`
      position: relative;
      width: 100%;
      padding: 18px;
      background: white;
      border-radius: 4px;
      border: 1px solid ${theme.aprilGrey};
      box-shadow: 0px 2px 8px ${theme.boxShadow};
      box-sizing: border-box;
    `
  );

  export const CardList = styled.div`
    display: flex;
    flex-direction: column;

    > div + div {
      margin-top: 20px;
    }
  `;

  export const ShadowlessCard = styled(Card)`
    box-shadow: none;
  `;

  export const CardHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: 20px;
    padding: 10px 0px;
    margin-top: 10px;
    line-height: 38px;
    border-radius: 10px 10px 0 0;

    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  `;

  export const CardHeaderRightIcon = styled.span(({ theme }) => {
    return css`
      flex: 1;
      display: flex;
      justify-content: flex-end;

      ${svgBasics(30, theme.oceanBlue)}
    `;
  });

  export const CardHeaderSecondaryInfoHolder = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
  `;

  export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    gap: 8px;

    > div {
      display: flex;
      align-items: center;
    }
  `;

  export const TitleIconContainer = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${theme.marchGrey};
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: 12px;

      ${svgBasics({ height: 30 })}
    `
  );

  export const HorizontalLayout = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    > * {
      flex-basis: content;
      flex-grow: 1;
    }
  `;
}

export const CardSubsectionWrapper = styled.div(
  ({ theme }) => css`
    border-radius: 7px;
    padding: 18px;
    background: ${theme.januaryGrey};
  `
);

export const CardSubsectionContent = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 18px 20px;
  background: white;
  height: 100%;
`;

export const CardWhiteSubsection = styled(CardSubsectionContent)(
  ({ theme }) => css`
    background: white;
    border: 1px solid ${theme.marchGrey};
  `
);

type CardSubsectionDividerProps = {
  vertical?: boolean;
};
export const CardSubsectionDivider = styled.div<CardSubsectionDividerProps>(
  ({ theme, vertical }) => css`
    ${vertical
      ? css`
          border-right: 1px solid ${theme.marchGrey};
        `
      : css`
          border-top: 1px solid ${theme.marchGrey};
          margin-bottom: 20px;
        `}
  `
);

/* CARD HEADER BLOCKS */

export const CardFooter = styled.div(
  ({ theme }) => css`
    display: flex;
    border-radius: 0 0 10px 10px;
    background: ${theme.januaryGrey};
    color: ${theme.septemberGrey};
    font-size: 14px;
    line-height: 40px;
    padding-left: 11px;

    > div {
      margin-right: 8px;
    }
  `
);
