import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';

interface Props {
  text: string | undefined;
  inline?: boolean;
}
export const MarkdownRenderer = ({ text, inline }: Props) => {
  if (!text) return <></>;
  return (
    <ReactMarkdownStylesWrapper inline={inline}>
      {/* Having it inside the tags doesn't work for some reason, so we have to call `children` manually */}
      {/* eslint-disable-next-line react/no-children-prop */}
      <ReactMarkdown children={text} />
    </ReactMarkdownStylesWrapper>
  );
};

// taken from https://github.com/sindresorhus/github-markdown-css/blob/main/github-markdown-light.css
export const ReactMarkdownStylesWrapper = styled.div<{ inline?: boolean }>(({ theme, inline }) => {
  return css`
    display: ${inline ? 'inline' : 'block'};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'; */
    line-height: 1.5;
    word-wrap: break-word;

    .octicon {
      display: inline-block;
      fill: currentColor;
      vertical-align: text-bottom;
    }

    h1:hover .anchor .octicon-link:before,
    h2:hover .anchor .octicon-link:before,
    h3:hover .anchor .octicon-link:before,
    h4:hover .anchor .octicon-link:before,
    h5:hover .anchor .octicon-link:before,
    h6:hover .anchor .octicon-link:before {
      width: 16px;
      height: 16px;
      content: ' ';
      display: inline-block;
      background-color: currentColor;
      -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
      mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
    }

    details,
    figcaption,
    figure {
      display: block;
    }

    summary {
      display: list-item;
    }

    [hidden] {
      display: none !important;
    }

    a {
      color: ${theme.seaBlue};
      cursor: pointer;

      &:hover {
        color: ${theme.lakeBlue};
        text-decoration: underline;
        &:active {
          color: ${theme.pondBlue};
        }
      }
    }

    abbr[title] {
      border-bottom: none;
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted;
    }

    b,
    strong {
      font-weight: 600;
    }

    dfn {
      font-style: italic;
    }

    h1 {
      margin: 0.67em 0;
      font-weight: 600;
      padding-bottom: 0.3em;
      font-size: 2em;
      border-bottom: 1px solid hsla(210, 18%, 87%, 1);
    }

    mark {
      background-color: #fff8c5;
      color: #1f2328;
    }

    small {
      font-size: 90%;
    }

    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }

    img {
      border-style: none;
      max-width: 100%;
      box-sizing: content-box;
      background-color: #ffffff;
    }

    code,
    kbd,
    pre,
    samp {
      font-family: monospace;
      font-size: 1em;
    }

    figure {
      margin: 1em 40px;
    }

    hr {
      box-sizing: content-box;
      overflow: hidden;
      background: transparent;
      border-bottom: 1px solid hsla(210, 18%, 87%, 1);
      height: 0.25em;
      padding: 0;
      margin: 24px 0;
      background-color: #d0d7de;
      border: 0;
    }

    input {
      font: inherit;
      margin: 0;
      overflow: visible;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    /* [type='button'],
[type='reset'],
[type='submit'] {
-webkit-appearance: button;
} */

    [type='checkbox'],
    [type='radio'] {
      box-sizing: border-box;
      padding: 0;
    }

    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
      height: auto;
    }

    [type='search']::-webkit-search-cancel-button,
    [type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    ::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.54;
    }

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }

    a:hover {
      text-decoration: underline;
    }

    ::placeholder {
      color: #6e7781;
      opacity: 1;
    }

    hr::before {
      display: table;
      content: '';
    }

    hr::after {
      display: table;
      clear: both;
      content: '';
    }

    table {
      border-spacing: 0;
      border-collapse: collapse;
      display: block;
      width: max-content;
      max-width: 100%;
      overflow: auto;
    }

    td,
    th {
      padding: 0;
    }

    details summary {
      cursor: pointer;
    }

    details:not([open]) > *:not(summary) {
      display: none !important;
    }

    a:focus,
    [role='button']:focus,
    input[type='radio']:focus,
    input[type='checkbox']:focus {
      outline: 2px solid #0969da;
      outline-offset: -2px;
      box-shadow: none;
    }

    a:focus:not(:focus-visible),
    [role='button']:focus:not(:focus-visible),
    input[type='radio']:focus:not(:focus-visible),
    input[type='checkbox']:focus:not(:focus-visible) {
      outline: solid 1px transparent;
    }

    a:focus-visible,
    [role='button']:focus-visible,
    input[type='radio']:focus-visible,
    input[type='checkbox']:focus-visible {
      outline: 2px solid #0969da;
      outline-offset: -2px;
      box-shadow: none;
    }

    a:not([class]):focus,
    a:not([class]):focus-visible,
    input[type='radio']:focus,
    input[type='radio']:focus-visible,
    input[type='checkbox']:focus,
    input[type='checkbox']:focus-visible {
      outline-offset: 0;
    }

    kbd {
      display: inline-block;
      padding: 3px 5px;
      font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
      line-height: 10px;
      color: #1f2328;
      vertical-align: middle;
      background-color: #f6f8fa;
      border: solid 1px rgba(175, 184, 193, 0.2);
      border-bottom-color: rgba(175, 184, 193, 0.2);
      border-radius: 6px;
      box-shadow: inset 0 -1px 0 rgba(175, 184, 193, 0.2);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 600;
      line-height: 1.25;
    }

    h2 {
      font-weight: 600;
      padding-bottom: 0.3em;
      font-size: 1.5em;
      border-bottom: 1px solid hsla(210, 18%, 87%, 1);
    }

    h3 {
      font-weight: 600;
      font-size: 1.25em;
    }

    h4 {
      font-weight: 600;
      font-size: 1em;
    }

    h5 {
      font-weight: 600;
      font-size: 0.875em;
    }

    h6 {
      font-weight: 600;
      font-size: 0.85em;
      color: #656d76;
    }

    p {
      display: ${inline ? 'inline' : 'block'};
      margin-top: 0;
      margin-bottom: 10px;
    }

    blockquote {
      margin: 0;
      padding: 0 1em;
      color: #656d76;
      border-left: 0.25em solid #d0d7de;
    }

    ul {
      list-style: disc;
    }

    ul,
    ol {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 2em;
    }

    ol ol,
    ul ol {
      list-style-type: lower-roman;
    }

    ul ul ol,
    ul ol ol,
    ol ul ol,
    ol ol ol {
      list-style-type: lower-alpha;
    }

    dd {
      margin-left: 0;
    }

    tt,
    code,
    samp {
      font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
      font-size: 12px;
    }

    pre {
      margin-top: 0;
      margin-bottom: 0;
      font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
      font-size: 12px;
      word-wrap: normal;
    }

    .octicon {
      display: inline-block;
      overflow: visible !important;
      vertical-align: text-bottom;
      fill: currentColor;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
    }

    ${!inline &&
    css`
      ::before {
        display: table;
        content: '';
      }

      ::after {
        display: table;
        clear: both;
        content: '';
      }
    `}

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }

    a:not([href]) {
      color: inherit;
      text-decoration: none;
    }

    .absent {
      color: #d1242f;
    }

    .anchor {
      float: left;
      padding-right: 4px;
      margin-left: -20px;
      line-height: 1;
    }

    .anchor:focus {
      outline: none;
    }

    p,
    blockquote,
    ul,
    ol,
    dl,
    table,
    pre,
    details {
      margin-top: 0;
      margin-bottom: 16px;
    }

    blockquote > :first-child {
      margin-top: 0;
    }

    blockquote > :last-child {
      margin-bottom: 0;
    }

    h1 .octicon-link,
    h2 .octicon-link,
    h3 .octicon-link,
    h4 .octicon-link,
    h5 .octicon-link,
    h6 .octicon-link {
      color: #1f2328;
      vertical-align: middle;
      visibility: hidden;
    }

    h1:hover .anchor,
    h2:hover .anchor,
    h3:hover .anchor,
    h4:hover .anchor,
    h5:hover .anchor,
    h6:hover .anchor {
      text-decoration: none;
    }

    h1:hover .anchor .octicon-link,
    h2:hover .anchor .octicon-link,
    h3:hover .anchor .octicon-link,
    h4:hover .anchor .octicon-link,
    h5:hover .anchor .octicon-link,
    h6:hover .anchor .octicon-link {
      visibility: visible;
    }

    h1 tt,
    h1 code,
    h2 tt,
    h2 code,
    h3 tt,
    h3 code,
    h4 tt,
    h4 code,
    h5 tt,
    h5 code,
    h6 tt,
    h6 code {
      padding: 0 0.2em;
      font-size: inherit;
    }

    summary h1,
    summary h2,
    summary h3,
    summary h4,
    summary h5,
    summary h6 {
      display: inline-block;
    }

    summary h1 .anchor,
    summary h2 .anchor,
    summary h3 .anchor,
    summary h4 .anchor,
    summary h5 .anchor,
    summary h6 .anchor {
      margin-left: -40px;
    }

    summary h1,
    summary h2 {
      padding-bottom: 0;
      border-bottom: 0;
    }

    ul.no-list,
    ol.no-list {
      padding: 0;
      list-style-type: none;
    }

    ol[type='a s'] {
      list-style-type: lower-alpha;
    }

    ol[type='A s'] {
      list-style-type: upper-alpha;
    }

    ol[type='i s'] {
      list-style-type: lower-roman;
    }

    ol[type='I s'] {
      list-style-type: upper-roman;
    }

    ol[type='1'] {
      list-style-type: decimal;
    }

    div > ol:not([type]) {
      list-style-type: decimal;
    }

    ul ul,
    ul ol,
    ol ol,
    ol ul {
      margin-top: 0;
      margin-bottom: 0;
    }

    li > p {
      margin-top: 16px;
    }

    li + li {
      margin-top: 0.25em;
    }

    dl {
      padding: 0;
    }

    dl dt {
      padding: 0;
      margin-top: 16px;
      font-size: 1em;
      font-style: italic;
      font-weight: 600;
    }

    dl dd {
      padding: 0 16px;
      margin-bottom: 16px;
    }

    table th {
      font-weight: 600;
    }

    table th,
    table td {
      padding: 6px 13px;
      border: 1px solid #d0d7de;
    }

    table td > :last-child {
      margin-bottom: 0;
    }

    table tr {
      background-color: #ffffff;
      border-top: 1px solid hsla(210, 18%, 87%, 1);
    }

    table tr:nth-child(2n) {
      background-color: #f6f8fa;
    }

    table img {
      background-color: transparent;
    }

    img[align='right'] {
      padding-left: 20px;
    }

    img[align='left'] {
      padding-right: 20px;
    }

    .emoji {
      max-width: none;
      vertical-align: text-top;
      background-color: transparent;
    }

    span.frame {
      display: block;
      overflow: hidden;
    }

    span.frame > span {
      display: block;
      float: left;
      width: auto;
      padding: 7px;
      margin: 13px 0 0;
      overflow: hidden;
      border: 1px solid #d0d7de;
    }

    span.frame span img {
      display: block;
      float: left;
    }

    span.frame span span {
      display: block;
      padding: 5px 0 0;
      clear: both;
      color: #1f2328;
    }

    span.align-center {
      display: block;
      overflow: hidden;
      clear: both;
    }

    span.align-center > span {
      display: block;
      margin: 13px auto 0;
      overflow: hidden;
      text-align: center;
    }

    span.align-center span img {
      margin: 0 auto;
      text-align: center;
    }

    span.align-right {
      display: block;
      overflow: hidden;
      clear: both;
    }

    span.align-right > span {
      display: block;
      margin: 13px 0 0;
      overflow: hidden;
      text-align: right;
    }

    span.align-right span img {
      margin: 0;
      text-align: right;
    }

    span.float-left {
      display: block;
      float: left;
      margin-right: 13px;
      overflow: hidden;
    }

    span.float-left span {
      margin: 13px 0 0;
    }

    span.float-right {
      display: block;
      float: right;
      margin-left: 13px;
      overflow: hidden;
    }

    span.float-right > span {
      display: block;
      margin: 13px auto 0;
      overflow: hidden;
      text-align: right;
    }

    code,
    tt {
      padding: 0.2em 0.4em;
      margin: 0;
      font-size: 85%;
      white-space: break-spaces;
      background-color: rgba(175, 184, 193, 0.1);
      border-radius: 6px;
    }

    code br,
    tt br {
      display: none;
    }

    del code {
      text-decoration: inherit;
    }

    samp {
      font-size: 85%;
    }

    pre code {
      font-size: 100%;
    }

    pre > code {
      padding: 0;
      margin: 0;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0;
    }

    .highlight {
      margin-bottom: 16px;
    }

    .highlight pre {
      margin-bottom: 0;
      word-break: normal;
    }

    .highlight pre,
    pre {
      padding: 16px;
      overflow: auto;
      font-size: 85%;
      line-height: 1.45;
      color: #1f2328;
      background-color: #f6f8fa;
      border-radius: 6px;
    }

    pre code,
    pre tt {
      display: inline;
      max-width: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0;
    }

    .csv-data td,
    .csv-data th {
      padding: 5px;
      overflow: hidden;
      font-size: 12px;
      line-height: 1;
      text-align: left;
      white-space: nowrap;
    }

    .csv-data .blob-num {
      padding: 10px 8px 9px;
      text-align: right;
      background: #ffffff;
      border: 0;
    }

    .csv-data tr {
      border-top: 0;
    }

    .csv-data th {
      font-weight: 600;
      background: #f6f8fa;
      border-top: 0;
    }

    [data-footnote-ref]::before {
      content: '[';
    }

    [data-footnote-ref]::after {
      content: ']';
    }

    .footnotes {
      font-size: 12px;
      color: #656d76;
      border-top: 1px solid #d0d7de;
    }

    .footnotes ol {
      padding-left: 16px;
    }

    .footnotes ol ul {
      display: inline-block;
      padding-left: 16px;
      margin-top: 16px;
    }

    .footnotes li {
      position: relative;
    }

    .footnotes li:target::before {
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -24px;
      pointer-events: none;
      content: '';
      border: 2px solid #0969da;
      border-radius: 6px;
    }

    .footnotes li:target {
      color: #1f2328;
    }

    .footnotes .data-footnote-backref g-emoji {
      font-family: monospace;
    }

    .pl-c {
      color: #6e7781;
    }

    .pl-c1,
    .pl-s .pl-v {
      color: #0550ae;
    }

    .pl-e,
    .pl-en {
      color: #6639ba;
    }

    .pl-smi,
    .pl-s .pl-s1 {
      color: #24292f;
    }

    .pl-ent {
      color: #116329;
    }

    .pl-k {
      color: #cf222e;
    }

    .pl-s,
    .pl-pds,
    .pl-s .pl-pse .pl-s1,
    .pl-sr,
    .pl-sr .pl-cce,
    .pl-sr .pl-sre,
    .pl-sr .pl-sra {
      color: #0a3069;
    }

    .pl-v,
    .pl-smw {
      color: #953800;
    }

    .pl-bu {
      color: #82071e;
    }

    .pl-ii {
      color: #f6f8fa;
      background-color: #82071e;
    }

    .pl-c2 {
      color: #f6f8fa;
      background-color: #cf222e;
    }

    .pl-sr .pl-cce {
      font-weight: bold;
      color: #116329;
    }

    .pl-ml {
      color: #3b2300;
    }

    .pl-mh,
    .pl-mh .pl-en,
    .pl-ms {
      font-weight: bold;
      color: #0550ae;
    }

    .pl-mi {
      font-style: italic;
      color: #24292f;
    }

    .pl-mb {
      font-weight: bold;
      color: #24292f;
    }

    .pl-md {
      color: #82071e;
      background-color: #ffebe9;
    }

    .pl-mi1 {
      color: #116329;
      background-color: #dafbe1;
    }

    .pl-mc {
      color: #953800;
      background-color: #ffd8b5;
    }

    .pl-mi2 {
      color: #eaeef2;
      background-color: #0550ae;
    }

    .pl-mdr {
      font-weight: bold;
      color: #8250df;
    }

    .pl-ba {
      color: #57606a;
    }

    .pl-sg {
      color: #8c959f;
    }

    .pl-corl {
      text-decoration: underline;
      color: #0a3069;
    }

    g-emoji {
      display: inline-block;
      min-width: 1ch;
      font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      font-size: 1em;
      font-style: normal !important;
      font-weight: 400;
      line-height: 1;
      vertical-align: -0.075em;
    }

    g-emoji img {
      width: 1em;
      height: 1em;
    }

    .task-list-item {
      list-style-type: none;
    }

    .task-list-item label {
      font-weight: 400;
    }

    .task-list-item.enabled label {
      cursor: pointer;
    }

    .task-list-item + .task-list-item {
      margin-top: 4px;
    }

    .task-list-item .handle {
      display: none;
    }

    .task-list-item-checkbox {
      margin: 0 0.2em 0.25em -1.4em;
      vertical-align: middle;
    }

    .contains-task-list:dir(rtl) .task-list-item-checkbox {
      margin: 0 -1.6em 0.25em 0.2em;
    }

    .contains-task-list {
      position: relative;
    }

    .contains-task-list:hover .task-list-item-convert-container,
    .contains-task-list:focus-within .task-list-item-convert-container {
      display: block;
      width: auto;
      height: 24px;
      overflow: visible;
      clip: auto;
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(50%);
    }
  `;
});
