/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { CloudProviderFilterOptions } from "./cloud_provider_options_pb";
import { PortSelector } from "./port_pb";
/**
 * selects zero or more Kubernetes API resources by matching on labels, name, namespace, cluster, and workspace.
 *
 * @generated from protobuf message common.gloo.solo.io.ObjectSelector
 */
export interface ObjectSelector {
    /**
     * labels matching those of the object
     *
     * @generated from protobuf field: map<string, string> labels = 1;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * Only select objects with the matching name. If omitted, Gloo selects
     * matching objects with any name available in the parent object's workspace.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Only select objects in the matching namespace. If omitted, Gloo selects
     * matching objects across all namespaces available in the parent object's workspace.
     *
     * @generated from protobuf field: string namespace = 3;
     */
    namespace: string;
    /**
     * Only select objects in the matching cluster. If omitted, Gloo selects
     * matching objects across all clusters available in the parent object's workspace.
     *
     * @generated from protobuf field: string cluster = 4;
     */
    cluster: string;
    /**
     * Only select objects in the given workspace. If omitted, Gloo selects
     * matching objects across all workspaces available in the parent object's workspace.
     *
     * @generated from protobuf field: string workspace = 5;
     */
    workspace: string;
}
/**
 * DestinationSelector is a selector for matching routable destinations for routes.
 * DestinationSelectors can select a variety of object types. The behavior of the route action
 * will vary depending on the type of destination selected. Defaults to the kubernetes `v1/Service`.
 * Currently supported destination types:
 * - v1 Service
 * - networking.gloo.solo.io/v2 VirtualDestination (route traffic to a group of backing destinations)
 * - networking.gloo.solo.io/v2 ExternalService (route traffic to a static set of service endpoints external to the mesh)
 *
 * @generated from protobuf message common.gloo.solo.io.DestinationSelector
 */
export interface DestinationSelector {
    /**
     * The selector used to match destination objects by their metadata
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectSelector selector = 1;
     */
    selector?: ObjectSelector;
    /**
     * The kind of destination being selected. defaults to Kubernetes Service.
     *
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind kind = 2;
     */
    kind: DestinationKind;
    /**
     * The port on the Destination which receives traffic.
     * All ports on the Destination will be selected if left empty.
     *
     * @generated from protobuf field: common.gloo.solo.io.PortSelector port = 3;
     */
    port?: PortSelector;
}
/**
 * RouteDestinationSelector is a Selector specifically built for individual destinations on individual Routes inside of RouteTables.
 *
 * @generated from protobuf message common.gloo.solo.io.RouteDestinationSelector
 */
export interface RouteDestinationSelector {
    /**
     * select routes based on their labels.
     * these labels can be used to match route tables or individual routes within route tables.
     * If empty, configuration will apply to all specified destinations on all routes in the workspace.
     *
     * @generated from protobuf field: common.gloo.solo.io.RouteLabelSelector route = 1;
     */
    route?: RouteLabelSelector;
    /**
     * for the selected routes, select destinations on which to apply this policy
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationSelector on_destinations = 2;
     */
    onDestinations: DestinationSelector[];
}
/**
 * RouteLabelSelector is used to select the details of a route primarily by label. The selection may be further
 * restricted by namespace, cluster, and workspace.
 *
 * @generated from protobuf message common.gloo.solo.io.RouteLabelSelector
 */
export interface RouteLabelSelector {
    /**
     * Select routes including all specified labels on the route or route table.
     *
     * @generated from protobuf field: map<string, string> labels = 1;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * Only select routes attached to route tables matching the namespace.
     *
     * @generated from protobuf field: string namespace = 3;
     */
    namespace: string;
    /**
     * Only select routes attached to route tables matching the cluster.
     *
     * @generated from protobuf field: string cluster = 4;
     */
    cluster: string;
    /**
     * Only select routes attached to route tables matching the workspace.
     *
     * @generated from protobuf field: string workspace = 5;
     */
    workspace: string;
}
/**
 * RouteSelector is a Selector specifically built for individual Routes inside of RouteTables.
 *
 * This selector can be used to select individual routes or entire route tables using the same label selector.
 *
 * @generated from protobuf message common.gloo.solo.io.RouteSelector
 */
export interface RouteSelector {
    /**
     * @generated from protobuf oneof: selector_type
     */
    selectorType: {
        oneofKind: "route";
        /**
         * select routes based on their labels.
         * these labels can be used to match route tables or individual routes within route tables.
         *
         * @generated from protobuf field: common.gloo.solo.io.RouteLabelSelector route = 1;
         */
        route: RouteLabelSelector;
    } | {
        oneofKind: "withDestinations";
        /**
         * select routes with `forwardTo` actions based on their destinations.
         * this can be used to apply policy to routes regardless of
         * their labels
         *
         * @generated from protobuf field: common.gloo.solo.io.DestinationSelector with_destinations = 2;
         */
        withDestinations: DestinationSelector;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message common.gloo.solo.io.RouteFilter
 */
export interface RouteFilter {
    /**
     * Optional: filter out route tables that specify CloudProvider functionality, if provided.
     *
     * @generated from protobuf field: common.gloo.solo.io.CloudProviderFilterOptions cloud_provider = 3;
     */
    cloudProvider?: CloudProviderFilterOptions;
}
/**
 * ListenerSelector is a selector for a listener on a VirtualGateway.
 *
 * @generated from protobuf message common.gloo.solo.io.ListenerSelector
 */
export interface ListenerSelector {
    /**
     * The virtual gateway on which to select a listener.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectSelector virtual_gateway = 1;
     */
    virtualGateway?: ObjectSelector;
    /**
     * The port to select on the selected listener.
     *
     * @generated from protobuf field: common.gloo.solo.io.PortSelector port = 2;
     */
    port?: PortSelector;
}
/**
 * WorkloadSelector is a Selector specifically built for selecting individual workloads.
 * Workloads must have injected (sidecars) or be standalone proxies (gateways) to be selected by Gloo Mesh policies.
 *
 * This selector can be used to select pods or injected external endpoints (vms).
 *
 * @generated from protobuf message common.gloo.solo.io.WorkloadSelector
 */
export interface WorkloadSelector {
    /**
     * Selector used to match Workload objects by their metadata.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectSelector selector = 1;
     */
    selector?: ObjectSelector;
    /**
     * The kind of workload being selected. Defaults to Kube.
     *
     * @generated from protobuf field: common.gloo.solo.io.WorkloadSelector.WorkloadKind kind = 2;
     */
    kind: WorkloadSelector_WorkloadKind;
    /**
     * The port to select on the selected workloads.
     * Only applies to policies which select specific workload ports, such as the WasmDeploymentPolicy.
     *
     * @generated from protobuf field: common.gloo.solo.io.PortSelector port = 4;
     */
    port?: PortSelector;
}
/**
 * @generated from protobuf enum common.gloo.solo.io.WorkloadSelector.WorkloadKind
 */
export enum WorkloadSelector_WorkloadKind {
    /**
     * Select kubernetes workloads (deployment, statefulset, daemonset, etc.).
     *
     * @generated from protobuf enum value: KUBE = 0;
     */
    KUBE = 0,
    /**
     * Select vms which are external, non-kube workloads.
     *
     * @generated from protobuf enum value: VM = 1;
     */
    VM = 1
}
/**
 * IdentitySelector is a Selector specifically built for selecting client identities for security policies.
 *
 * @generated from protobuf message common.gloo.solo.io.IdentitySelector
 */
export interface IdentitySelector {
    /**
     * Select kubernetes service accounts as identities.
     * When selecting a service account that will be created in the future for use in a AuthorizationPolicy, ONLY provide the name, namespace and cluster where the service account will exist in the selector.
     * Providing labels and/or the workspace, or omitting the name, namespace or cluster for a service account that does not exist in the selector will not result in that service account being added to the resulting AuthorizationPolicy.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectSelector service_account_selector = 1;
     */
    serviceAccountSelector?: ObjectSelector;
    /**
     * Select external workloads.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectSelector external_workload_selector = 3;
     */
    externalWorkloadSelector?: ObjectSelector;
    /**
     * Select identities based on properties of the request. If multiple fields are set, they are ANDed together.
     * More information about the individual values can be found here: https://istio.io/latest/docs/reference/config/security/authorization-policy/#Source
     *
     * @generated from protobuf field: common.gloo.solo.io.IdentitySelector.RequestIdentityMatcher request_identity_matcher = 2;
     */
    requestIdentityMatcher?: IdentitySelector_RequestIdentityMatcher;
}
/**
 * @generated from protobuf message common.gloo.solo.io.IdentitySelector.RequestIdentityMatcher
 */
export interface IdentitySelector_RequestIdentityMatcher {
    /**
     *
     * Optional: A list of identities to match the request identity ("iss/sub" from the JWT).
     * If omitted all request identity values will be accepted.
     *
     * @generated from protobuf field: repeated string requestPrincipals = 3;
     */
    requestPrincipals: string[];
    /**
     *
     * Optional: A list of identities to negative match the request identity.
     *
     * @generated from protobuf field: repeated string notRequestPrincipals = 4;
     */
    notRequestPrincipals: string[];
}
/**
 * Select clusters and namespaces for a workspace
 *
 * @generated from protobuf message common.gloo.solo.io.ClusterSelector
 */
export interface ClusterSelector {
    /**
     * Optional: Name of the cluster to select.
     * Use * to match name patterns in multiple clusters name.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Optional: Include workload clusters in the workspace by using a selector.
     * The selector matches the selector values in the KubernetesCluster resource on the management cluster.
     * Selector is a map of {key,value} pairs. A single {key,value} in the selector
     * map is equivalent to an element of labelRequirements, whose key field is "key", the
     * operator is "In", and the values array contains only "value". The requirements are ANDed.
     *
     * @generated from protobuf field: map<string, string> selector = 2;
     */
    selector: {
        [key: string]: string;
    };
    /**
     * Select the namespaces in the cluster(s) to include in the workspace. If you do not select any namespaces, the workspace is empty and results in an error.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.ClusterSelector.NamespaceSelector namespaces = 4;
     */
    namespaces: ClusterSelector_NamespaceSelector[];
    /**
     * Optional: Read Gloo configuration from specific clusters and namespaces that are included
     * in a workspace. If you omit this field for any cluster in a workspace, it is set to `true`
     * by default, and Gloo configuration is read for all clusters and namespaces in that workspace.
     * If you explicitly set this field to either true or false on a specific cluster, you must also explicitly set
     * the field for every other cluster that is listed in that workspace. For example, if you have a workspace that spans
     * namespaces in three clusters, but you want to read Gloo configuration from the namespaces in only one of the
     * clusters, you must explicitly set `configEnabled: true` for the cluster to read from,
     * and explicitly set `configEnabled: false` for the other two clusters.
     *
     * @generated from protobuf field: bool config_enabled = 5;
     */
    configEnabled: boolean;
}
/**
 * Select namespaces for the selected cluster(s)
 *
 * @generated from protobuf message common.gloo.solo.io.ClusterSelector.NamespaceSelector
 */
export interface ClusterSelector_NamespaceSelector {
    /**
     * Name of the namespace to select.
     * Can use * to match name patterns in multiple namespaces.
     * Note: When this field is used in conjunction with "labels", both conditions are ANDed together.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Optional: Read Gloo configuration from this namespace. If you omit this field for any other namespace
     * in the cluster, it is set to `true` by default, and Gloo configuration is read for all namespaces
     * in that cluster. If you explicitly set this field to either true or false for one namespace, you must
     * also explicitly set the field for every other namespace that is listed in the workspace for that cluster.
     * For example, if you have a cluster in your workspace that has three namespaces, but you want to read Gloo
     * configuration from only one of the namespaces in the cluster, you must explicitly set `configEnabled: true`
     * for the namespace to read from, and explicitly set `configEnabled: false` for the other two namespaces.
     *
     * @generated from protobuf field: bool config_enabled = 2;
     */
    configEnabled: boolean;
    /**
     * Optional: Select groups of namespaces via labels.
     * Leaving this field empty will NOT select all namespaces, but rather be ignored.
     * Note: When this field is used in conjunction with "name", both conditions are ANDed together.
     *
     * @generated from protobuf field: map<string, string> labels = 3;
     */
    labels: {
        [key: string]: string;
    };
}
/**
 * MeshSelector is a Selector built for selecting instances of Mesh control planes. A control plane is understood to have a single domain and provide a single root of trust for the data plane proxies it is managing. Each revision of Istiod maps
 *
 * @generated from protobuf message common.gloo.solo.io.MeshSelector
 */
export interface MeshSelector {
    /**
     * @generated from protobuf oneof: mesh_type
     */
    meshType: {
        oneofKind: "istio";
        /**
         * options for selecting istio Mesh control planes.
         *
         * @generated from protobuf field: common.gloo.solo.io.MeshSelector.Istio istio = 1;
         */
        istio: MeshSelector_Istio;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message common.gloo.solo.io.MeshSelector.Istio
 */
export interface MeshSelector_Istio {
    /**
     * istio.io/revision of Istio to select. regex supported.
     * omit to select across all revisions.
     *
     * @generated from protobuf field: string revision = 1;
     */
    revision: string;
    /**
     * match the name of the namespace where istiod is deployed.
     * omit to select across all namespaces.
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
    /**
     * match the labels of the cluster where istiod is deployed.
     * omit to select across all clusters.
     *
     * @generated from protobuf field: map<string, string> cluster_selector = 3;
     */
    clusterSelector: {
        [key: string]: string;
    };
    /**
     * select via the labels of the istiod deployment.
     * omit to select across all labels.
     *
     * @generated from protobuf field: map<string, string> selector = 4;
     */
    selector: {
        [key: string]: string;
    };
}
/**
 * WorkspaceSelector is a selector for workspaces.
 *
 * @generated from protobuf message common.gloo.solo.io.WorkspaceSelector
 */
export interface WorkspaceSelector {
    /**
     * Optional: Name of the workspace to select.
     * Use * to match name patterns in multiple workspace names.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Optional: Select workspaces based on their labels,
     * such as setting `selector` to `team: backend`.
     *
     * @generated from protobuf field: map<string, string> selector = 2;
     */
    selector: {
        [key: string]: string;
    };
}
/**
 * Supported Kinds which can be selected by routes.
 *
 * @generated from protobuf enum common.gloo.solo.io.DestinationKind
 */
export enum DestinationKind {
    /**
     * Select a Kubernetes Service
     *
     * @generated from protobuf enum value: SERVICE = 0;
     */
    SERVICE = 0,
    /**
     * Select a Virtual Destination
     *
     * @generated from protobuf enum value: VIRTUAL_DESTINATION = 1;
     */
    VIRTUAL_DESTINATION = 1,
    /**
     * Select an External Service
     *
     * @generated from protobuf enum value: EXTERNAL_SERVICE = 2;
     */
    EXTERNAL_SERVICE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class ObjectSelector$Type extends MessageType<ObjectSelector> {
    constructor() {
        super("common.gloo.solo.io.ObjectSelector", [
            { no: 1, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workspace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ObjectSelector
 */
export const ObjectSelector = new ObjectSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestinationSelector$Type extends MessageType<DestinationSelector> {
    constructor() {
        super("common.gloo.solo.io.DestinationSelector", [
            { no: 1, name: "selector", kind: "message", T: () => ObjectSelector },
            { no: 2, name: "kind", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "port", kind: "message", T: () => PortSelector }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.DestinationSelector
 */
export const DestinationSelector = new DestinationSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteDestinationSelector$Type extends MessageType<RouteDestinationSelector> {
    constructor() {
        super("common.gloo.solo.io.RouteDestinationSelector", [
            { no: 1, name: "route", kind: "message", T: () => RouteLabelSelector },
            { no: 2, name: "on_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationSelector }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.RouteDestinationSelector
 */
export const RouteDestinationSelector = new RouteDestinationSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteLabelSelector$Type extends MessageType<RouteLabelSelector> {
    constructor() {
        super("common.gloo.solo.io.RouteLabelSelector", [
            { no: 1, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workspace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.RouteLabelSelector
 */
export const RouteLabelSelector = new RouteLabelSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteSelector$Type extends MessageType<RouteSelector> {
    constructor() {
        super("common.gloo.solo.io.RouteSelector", [
            { no: 1, name: "route", kind: "message", oneof: "selectorType", T: () => RouteLabelSelector },
            { no: 2, name: "with_destinations", kind: "message", oneof: "selectorType", T: () => DestinationSelector }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.RouteSelector
 */
export const RouteSelector = new RouteSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteFilter$Type extends MessageType<RouteFilter> {
    constructor() {
        super("common.gloo.solo.io.RouteFilter", [
            { no: 3, name: "cloud_provider", kind: "message", T: () => CloudProviderFilterOptions }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.RouteFilter
 */
export const RouteFilter = new RouteFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListenerSelector$Type extends MessageType<ListenerSelector> {
    constructor() {
        super("common.gloo.solo.io.ListenerSelector", [
            { no: 1, name: "virtual_gateway", kind: "message", T: () => ObjectSelector },
            { no: 2, name: "port", kind: "message", T: () => PortSelector }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ListenerSelector
 */
export const ListenerSelector = new ListenerSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkloadSelector$Type extends MessageType<WorkloadSelector> {
    constructor() {
        super("common.gloo.solo.io.WorkloadSelector", [
            { no: 1, name: "selector", kind: "message", T: () => ObjectSelector },
            { no: 2, name: "kind", kind: "enum", T: () => ["common.gloo.solo.io.WorkloadSelector.WorkloadKind", WorkloadSelector_WorkloadKind] },
            { no: 4, name: "port", kind: "message", T: () => PortSelector }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.WorkloadSelector
 */
export const WorkloadSelector = new WorkloadSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentitySelector$Type extends MessageType<IdentitySelector> {
    constructor() {
        super("common.gloo.solo.io.IdentitySelector", [
            { no: 1, name: "service_account_selector", kind: "message", T: () => ObjectSelector },
            { no: 3, name: "external_workload_selector", kind: "message", T: () => ObjectSelector },
            { no: 2, name: "request_identity_matcher", kind: "message", T: () => IdentitySelector_RequestIdentityMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.IdentitySelector
 */
export const IdentitySelector = new IdentitySelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentitySelector_RequestIdentityMatcher$Type extends MessageType<IdentitySelector_RequestIdentityMatcher> {
    constructor() {
        super("common.gloo.solo.io.IdentitySelector.RequestIdentityMatcher", [
            { no: 3, name: "requestPrincipals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notRequestPrincipals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.IdentitySelector.RequestIdentityMatcher
 */
export const IdentitySelector_RequestIdentityMatcher = new IdentitySelector_RequestIdentityMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClusterSelector$Type extends MessageType<ClusterSelector> {
    constructor() {
        super("common.gloo.solo.io.ClusterSelector", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "selector", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterSelector_NamespaceSelector },
            { no: 5, name: "config_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ClusterSelector
 */
export const ClusterSelector = new ClusterSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClusterSelector_NamespaceSelector$Type extends MessageType<ClusterSelector_NamespaceSelector> {
    constructor() {
        super("common.gloo.solo.io.ClusterSelector.NamespaceSelector", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "config_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ClusterSelector.NamespaceSelector
 */
export const ClusterSelector_NamespaceSelector = new ClusterSelector_NamespaceSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeshSelector$Type extends MessageType<MeshSelector> {
    constructor() {
        super("common.gloo.solo.io.MeshSelector", [
            { no: 1, name: "istio", kind: "message", oneof: "meshType", T: () => MeshSelector_Istio }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.MeshSelector
 */
export const MeshSelector = new MeshSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeshSelector_Istio$Type extends MessageType<MeshSelector_Istio> {
    constructor() {
        super("common.gloo.solo.io.MeshSelector.Istio", [
            { no: 1, name: "revision", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cluster_selector", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "selector", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.MeshSelector.Istio
 */
export const MeshSelector_Istio = new MeshSelector_Istio$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkspaceSelector$Type extends MessageType<WorkspaceSelector> {
    constructor() {
        super("common.gloo.solo.io.WorkspaceSelector", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "selector", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.WorkspaceSelector
 */
export const WorkspaceSelector = new WorkspaceSelector$Type();
