/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/security/dlp_policy.proto" (package "security.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { RouteReference } from "../../../common/v2/status_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { RegexAction } from "../../../../../../../envoy-gloo-ee/api/envoy/config/filter/http/transformation_ee/v2/transformation_ee_filter_pb";
import { FloatValue } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { StringValue } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { BoolValue } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { RouteSelector } from "../../../common/v2/selectors_pb";
/**
 * Use a DLPPolicy (data loss prevention policy) to prevent sensitive data from being exposed.
 * Gloo Gateway completes a series of regex replacements on the body or headers of responses that it processes.
 * DLPPolicies are applied at the *Route* level.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.DLPPolicySpec
 */
export interface DLPPolicySpec {
    /**
     * Select the routes that the policy applies to.
     * If empty, the policy applies to all routes in the workspace.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.RouteSelector apply_to_routes = 1;
     */
    applyToRoutes: RouteSelector[];
    /**
     * The details of the DLP policy to apply to the selected routes.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.DLPPolicySpec.Config config = 2;
     */
    config?: DLPPolicySpec_Config;
}
/**
 * Whether actions apply to responses, access logs, or both.
 * If empty, masking is enabled for responses bodies only.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.DLPPolicySpec.Config
 */
export interface DLPPolicySpec_Config {
    /**
     * List of data loss prevention actions to be applied.
     * These actions are applied in order, one at a time.
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.DlpAction actions = 1;
     */
    actions: DlpAction[];
    /**
     * Whether actions apply to responses, access logs, or both.
     * If empty, masking is enabled for responses bodies only.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.DLPPolicySpec.Config.Sanitize sanitize = 2;
     */
    sanitize: DLPPolicySpec_Config_Sanitize;
}
/**
 * @generated from protobuf enum security.policy.gloo.solo.io.DLPPolicySpec.Config.Sanitize
 */
export enum DLPPolicySpec_Config_Sanitize {
    /**
     * Enable DLP masking for both responses bodies and access logs. Default value.
     *
     * @generated from protobuf enum value: ALL = 0;
     */
    ALL = 0,
    /**
     * Enable DLP masking for access logs only.
     *
     * @generated from protobuf enum value: ACCESS_LOGS = 1;
     */
    ACCESS_LOGS = 1,
    /**
     * Enable DLP masking for response bodies only.
     *
     * @generated from protobuf enum value: RESPONSE_BODY = 2;
     */
    RESPONSE_BODY = 2
}
/**
 * A single DLP action to mask sensitive data.
 * You can apply preconfigured actions, create custom actions,
 * and write key-value (header) actions.
 * These actions can also be shadowed. A shadowed action is recorded
 * in the statistics and debug logs, but is not committed in the response body.
 *
 * To use a predefined action, set the `predefinedAction` to one of the predefined actions.
 *
 * ```yaml
 * - predefinedAction: VISA
 * ```
 *
 * To create a custom action, specify the configuration in the `customAction` field. The default enum value
 * is custom, so that can be left empty.
 *
 * ```yaml
 *
 * - customAction:
 *     name: test
 *     regexActions:
 *     - regex: "hello"
 *     - regex: "world"
 *     maskChar: Y
 *     percent: 60
 * ```
 *
 * To create a key-value action, specify the configuration in the `keyValueAction` field. The default enum value
 * is custom, so that can be left empty.
 *
 * ```yaml
 *
 * - keyValueAction:
 *     keysToMask:
 *     - x-my-header-01
 *     maskChar: _
 *     name: kv-action-1
 *     percent: 100
 * ```
 *
 * @generated from protobuf message security.policy.gloo.solo.io.DlpAction
 */
export interface DlpAction {
    /**
     * @generated from protobuf oneof: action
     */
    action: {
        oneofKind: "predefinedAction";
        /**
         * The predefined action to apply.
         *
         * @generated from protobuf field: security.policy.gloo.solo.io.DlpAction.PredefinedAction predefined_action = 1;
         */
        predefinedAction: DlpAction_PredefinedAction;
    } | {
        oneofKind: "customAction";
        /**
         * The custom action to apply.
         *
         * @generated from protobuf field: security.policy.gloo.solo.io.DlpCustomAction custom_action = 2;
         */
        customAction: DlpCustomAction;
    } | {
        oneofKind: "keyValueAction";
        /**
         * The key-value action to apply.
         * Key-values actions affect access logs and response headers, but not response bodies.
         *
         * @generated from protobuf field: security.policy.gloo.solo.io.DlpKeyValueAction key_value_action = 4;
         */
        keyValueAction: DlpKeyValueAction;
    } | {
        oneofKind: undefined;
    };
    /**
     * If enabled, the action is recorded in debug logs, but not applied to response bodies
     * or headers.
     *
     * @generated from protobuf field: google.protobuf.BoolValue shadow = 3;
     */
    shadow?: BoolValue;
}
/**
 *
 * The following pre-defined actions map to subgroup 1 of the listed regex patterns:
 *
 * SSN:
 * - '(?:^|\D)([0-9]{9})(?:\D|$)'
 * - '(?:^|\D)([0-9]{3}\-[0-9]{2}\-[0-9]{4})(?:\D|$)'
 * - '(?:^|\D)([0-9]{3}\ [0-9]{2}\ [0-9]{4})(?:\D|$)'
 *
 * MASTERCARD:
 * - '(?:^|\D)(5[1-5][0-9]{2}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4})(?:\D|$)'
 *
 * VISA:
 * - '(?:^|\D)(4[0-9]{3}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4})(?:\D|$)'
 *
 * AMEX:
 * - '(?:^|\D)((?:34|37)[0-9]{2}(?:\ |\-|)[0-9]{6}(?:\ |\-|)[0-9]{5})(?:\D|$)'
 *
 * DISCOVER:
 * - '(?:^|\D)(6011(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4})(?:\D|$)'
 *
 * JCB:
 * - '(?:^|\D)(3[0-9]{3}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4}(?:\ |\-|)[0-9]{4})(?:\D|$)'
 * - '(?:^|\D)((?:2131|1800)[0-9]{11})(?:\D|$)'
 *
 * DINERS_CLUB:
 * - '(?:^|\D)(30[0-5][0-9](?:\ |\-|)[0-9]{6}(?:\ |\-|)[0-9]{4})(?:\D|$)'
 * - '(?:^|\D)((?:36|38)[0-9]{2}(?:\ |\-|)[0-9]{6}(?:\ |\-|)[0-9]{4})(?:\D|$)'
 *
 * CREDIT_CARD_TRACKERS:
 * - '([1-9][0-9]{2}\-[0-9]{2}\-[0-9]{4}\^\d)'
 * - '(?:^|\D)(\%?[Bb]\d{13,19}\^[\-\/\.\w\s]{2,26}\^[0-9][0-9][01][0-9][0-9]{3})'
 * - '(?:^|\D)(\;\d{13,19}\=(?:\d{3}|)(?:\d{4}|\=))'
 *
 * ALL_CREDIT_CARDS:
 * - (All credit card related regexes from above)
 *
 * @generated from protobuf enum security.policy.gloo.solo.io.DlpAction.PredefinedAction
 */
export enum DlpAction_PredefinedAction {
    /**
     * @generated from protobuf enum value: ALL_CREDIT_CARDS = 0;
     */
    ALL_CREDIT_CARDS = 0,
    /**
     * @generated from protobuf enum value: SSN = 1;
     */
    SSN = 1,
    /**
     * @generated from protobuf enum value: MASTERCARD = 2;
     */
    MASTERCARD = 2,
    /**
     * @generated from protobuf enum value: VISA = 3;
     */
    VISA = 3,
    /**
     * @generated from protobuf enum value: AMEX = 4;
     */
    AMEX = 4,
    /**
     * @generated from protobuf enum value: DISCOVER = 5;
     */
    DISCOVER = 5,
    /**
     * @generated from protobuf enum value: JCB = 6;
     */
    JCB = 6,
    /**
     * @generated from protobuf enum value: DINERS_CLUB = 7;
     */
    DINERS_CLUB = 7,
    /**
     * @generated from protobuf enum value: CREDIT_CARD_TRACKERS = 8;
     */
    CREDIT_CARD_TRACKERS = 8
}
/**
 * A custom action that you define to apply to the response body.
 *
 * The list of regex strings are applied in order. For instance, if the response body contains `hello world`,
 * and you define the following custom action:
 * ``` yaml
 * customAction:
 *     name: test
 *     regexActions:
 *     - regex: "hello"
 *     - regex: "world"
 *     maskChar: Y
 *     percent: 60
 * ```
 *
 * The result is masked in this way:
 * `YYYlo YYYld`
 *
 * If `mask_char` and `percent` are not defined, the result is masked in this way:
 * `XXXXo XXXXd`
 *
 * @generated from protobuf message security.policy.gloo.solo.io.DlpCustomAction
 */
export interface DlpCustomAction {
    /**
     * The name of the custom action.
     * This name is used for logging and debugging purposes.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The masking character to replace the sensitive data.
     * Default: `X`
     *
     * @generated from protobuf field: google.protobuf.StringValue mask_char = 2;
     */
    maskChar?: StringValue;
    /**
     * The percent of the string to mask with the `mask_char`.
     * Rounds the ratio (percent/100) by using [std::round](http://www.cplusplus.com/reference/cmath/round/).
     * Default: 75%
     *
     * @generated from protobuf field: google.protobuf.FloatValue percent = 3;
     */
    percent?: FloatValue;
    /**
     * List of regexes to apply to the response body. Data that matches the regexes
     * is masked. Regexes are applied iteratively in the order that they are
     * specified.
     *
     * @generated from protobuf field: repeated envoy.config.filter.http.transformation_ee.v2.RegexAction regex_actions = 4;
     */
    regexActions: RegexAction[];
}
/**
 * A key-value action to apply to response headers.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.DlpKeyValueAction
 */
export interface DlpKeyValueAction {
    /**
     * The name of the key-value action.
     * This name is used for logging and debugging purposes.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The masking character to replace the sensitive data.
     * Default: `X`
     *
     * @generated from protobuf field: google.protobuf.StringValue mask_char = 2;
     */
    maskChar?: StringValue;
    /**
     * The percent of the string to mask with the `mask_char`.
     * Rounds the ratio (percent/100) by using [std::round](http://www.cplusplus.com/reference/cmath/round/).
     * Default: 75%
     *
     * @generated from protobuf field: google.protobuf.FloatValue percent = 3;
     */
    percent?: FloatValue;
    /**
     * Required: The keys for which corresponding header names or dynamic metadata values are masked.
     *
     * @generated from protobuf field: repeated string keys_to_mask = 4;
     */
    keysToMask: string[];
}
/**
 * The status of the DLPPolicy.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.DLPPolicyStatus
 */
export interface DLPPolicyStatus {
    /**
     * The state and workspace conditions of the applied resource.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * A count of references to all routes selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_routes = 3;
     */
    numSelectedRoutes: number;
}
/**
 * @generated from protobuf message security.policy.gloo.solo.io.DLPPolicyReport
 */
export interface DLPPolicyReport {
    /**
     * The status of the resource in each workspace that it exists in.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of references to all routes selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.RouteReference selected_routes = 2;
     */
    selectedRoutes: RouteReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class DLPPolicySpec$Type extends MessageType<DLPPolicySpec> {
    constructor() {
        super("security.policy.gloo.solo.io.DLPPolicySpec", [
            { no: 1, name: "apply_to_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteSelector },
            { no: 2, name: "config", kind: "message", T: () => DLPPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DLPPolicySpec
 */
export const DLPPolicySpec = new DLPPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DLPPolicySpec_Config$Type extends MessageType<DLPPolicySpec_Config> {
    constructor() {
        super("security.policy.gloo.solo.io.DLPPolicySpec.Config", [
            { no: 1, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DlpAction },
            { no: 2, name: "sanitize", kind: "enum", T: () => ["security.policy.gloo.solo.io.DLPPolicySpec.Config.Sanitize", DLPPolicySpec_Config_Sanitize] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DLPPolicySpec.Config
 */
export const DLPPolicySpec_Config = new DLPPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DlpAction$Type extends MessageType<DlpAction> {
    constructor() {
        super("security.policy.gloo.solo.io.DlpAction", [
            { no: 1, name: "predefined_action", kind: "enum", oneof: "action", T: () => ["security.policy.gloo.solo.io.DlpAction.PredefinedAction", DlpAction_PredefinedAction] },
            { no: 2, name: "custom_action", kind: "message", oneof: "action", T: () => DlpCustomAction },
            { no: 4, name: "key_value_action", kind: "message", oneof: "action", T: () => DlpKeyValueAction },
            { no: 3, name: "shadow", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DlpAction
 */
export const DlpAction = new DlpAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DlpCustomAction$Type extends MessageType<DlpCustomAction> {
    constructor() {
        super("security.policy.gloo.solo.io.DlpCustomAction", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "mask_char", kind: "message", T: () => StringValue },
            { no: 3, name: "percent", kind: "message", T: () => FloatValue },
            { no: 4, name: "regex_actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegexAction }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DlpCustomAction
 */
export const DlpCustomAction = new DlpCustomAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DlpKeyValueAction$Type extends MessageType<DlpKeyValueAction> {
    constructor() {
        super("security.policy.gloo.solo.io.DlpKeyValueAction", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "mask_char", kind: "message", T: () => StringValue },
            { no: 3, name: "percent", kind: "message", T: () => FloatValue },
            { no: 4, name: "keys_to_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DlpKeyValueAction
 */
export const DlpKeyValueAction = new DlpKeyValueAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DLPPolicyStatus$Type extends MessageType<DLPPolicyStatus> {
    constructor() {
        super("security.policy.gloo.solo.io.DLPPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 3, name: "num_selected_routes", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DLPPolicyStatus
 */
export const DLPPolicyStatus = new DLPPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DLPPolicyReport$Type extends MessageType<DLPPolicyReport> {
    constructor() {
        super("security.policy.gloo.solo.io.DLPPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.DLPPolicyReport
 */
export const DLPPolicyReport = new DLPPolicyReport$Type();
