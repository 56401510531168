import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';

export namespace VirtualGatewayDetailsStyles {
  export const ListenerCard = styled(CardStyles.Card)`
    padding: 0;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  `;
  export const ListenerCardHeader = styled(CardStyles.CardHeader)(
    ({ theme }) => css`
      padding: 0 20px;
      line-height: 44px;
      background: ${theme.januaryGrey};
      > strong {
        font-weight: 500;
      }
    `
  );
  export const ListenerCardHeaderDetails = styled.div`
    margin-left: auto;
    display: flex;
    gap: 60px;
    font-size: 16px;
  `;
  export const ListenerCardBody = styled.div`
    padding: 20px;
  `;
  export const ListenerCardSubheader = styled.div`
    font-weight: 500;
    margin-bottom: 16px;
  `;
}
