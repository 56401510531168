//import { grpc } from '@improbable-eng/grpc-web';
//import { host } from './helpers';

import { getPermissionsRefreshTime, useRequest } from './helpers';

/*const overviewApiClient = new OverviewApiClient(host, {
  transport: grpc.CrossBrowserHttpTransport({ withCredentials: false }),
  debug: true
});*/

const localStorageRoleKey = 'local-role';

export enum TempRoles {
  SuperUser = 1,
  WorkspaceAdmin,
  WorkspaceUser
}

const WorkspaceUserAreaPermissions: { [key: string]: string[] } = {
  workspaces: ['bookinfo', 'gateway'],
  clusters: ['cluster-1']
};

export namespace loginApi {
  export async function getLoggedInRole(): Promise<{
    role: TempRoles;
    specificPrivileges?: typeof WorkspaceUserAreaPermissions;
  }> {
    return new Promise((resolve, reject) => {
      /*const request = new GetOverviewWorkspaceRequest();
      request.setWorkspaceRef(toObjectRefClass(workspaceRef));
      overviewApiClient.getOverviewWorkspace(request, (error, data) => {
        if (error !== null) {
          console.error('Error:', error.message);
          console.error('Code:', error.code);
          console.error('Metadata:', error.metadata);
          reject(error);
        } else {
          resolve(data!.toObject());
        }
      });*/
      const testResolveValue = window.localStorage.getItem(localStorageRoleKey)
        ? Number.parseInt(window.localStorage.getItem(localStorageRoleKey)!)
        : TempRoles.SuperUser;
      resolve({
        role: testResolveValue,
        specificPrivileges: testResolveValue === TempRoles.WorkspaceUser ? WorkspaceUserAreaPermissions : undefined
      });
    });
  }
  export function useGetLoggedInRole() {
    return useRequest(
      getLoggedInRole,
      [],
      { key: 'LOGGEDINKEY', methodDescriptorName: 'getLoggedInRole' },
      { refreshInterval: getPermissionsRefreshTime() }
    );
  }

  export async function setRole(role: TempRoles) {
    window.localStorage.setItem(localStorageRoleKey, role.toString());
  }
}
