import React, { useState, useEffect } from 'react';
import { Asset } from 'assets';
import { SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { rangeType, SoloDateTimePicker } from 'Components/Common/Input/SoloDateTimePicker';
import moment from 'moment';
import { GraphFetchSettingsStyles as Styles } from './GraphFetchSettings.style';
import { graphValidRefreshList, graphValidTimeIntervalList, useGetGraphCache } from '../General/graph-cache';
import { useGraphFetchSettingsContext } from '../Context/GraphFetchSettingsContext';

const DISABLE_REFRESH = 999999999;

export const graphFetchTrafficOptions = [
  ...graphValidTimeIntervalList,
  {
    value: 'range' as const,
    displayValue: 'Custom range...'
  }
];

export const graphFetchRefreshOptions = graphValidRefreshList;

interface Props {
  isGraphRefreshing: boolean;
  hasEnoughFilters: boolean;
  doRefresh: () => any;
}
export const GraphFetchSettings = ({ isGraphRefreshing, hasEnoughFilters, doRefresh }: Props) => {
  const { cache } = useGetGraphCache();
  const [buttonRefreshInProgress, setButtonRefreshInProgress] = useState(false);

  const { setRange, setTimeInterval, setRefreshRate, setPullTime, range, timeInterval, refreshRate } =
    useGraphFetchSettingsContext();

  const setIntervalIntercept = (interval: number | 'range') => {
    if (interval === 'range') {
      setRange([undefined, undefined]);
    } else {
      setTimeInterval(interval);
    }
  };

  const setDates = (newValue: rangeType) => {
    setRange(newValue);
    if (!newValue) {
      if (refreshRate === DISABLE_REFRESH) {
        setRefreshRate(cache.refreshRate);
        setTimeInterval(cache.timeInterval);
      }
      setPullTime(new Date());
    } else {
      if (newValue[0] && newValue[1]) {
        setRefreshRate(DISABLE_REFRESH);
        setPullTime(new Date(newValue[1].valueOf()));
        setTimeInterval((newValue[1].valueOf() - newValue[0].valueOf()) / 1000);
      }
    }
  };

  useEffect(() => {
    if (!isGraphRefreshing && buttonRefreshInProgress) {
      setButtonRefreshInProgress(false);
    }
  }, [isGraphRefreshing]);

  const onRefreshClicked = () => {
    setButtonRefreshInProgress(true);
    doRefresh();
  };

  return (
    <Styles.FetchSettings data-testid='graph-fetch-settings'>
      <Styles.FetchLabelDropdownPair>
        <label htmlFor='trafficInput'>Traffic: </label>
        {!range ? (
          <span data-testid='graph-traffic-dropdown'>
            <SoloDropdown<number | 'range'>
              value={timeInterval}
              onChange={val => setIntervalIntercept(val)}
              options={graphFetchTrafficOptions}
              id='trafficInput'
            />
          </span>
        ) : (
          <span>
            <SoloDateTimePicker
              disabledDate={current => current && current.valueOf() > moment().endOf('day').valueOf()}
              disableFutureTime={true}
              value={range}
              setDates={setDates}
              placeholder={['Start time', 'End time']}
            />
          </span>
        )}
      </Styles.FetchLabelDropdownPair>

      {!range && (
        <Styles.FetchLabelDropdownPair>
          <label htmlFor='refreshInput'>Refresh: </label>
          <span>
            <SoloDropdown
              value={refreshRate}
              onChange={val => setRefreshRate(val)}
              options={graphFetchRefreshOptions}
              id='refreshInput'
            />
          </span>
        </Styles.FetchLabelDropdownPair>
      )}

      {hasEnoughFilters && !range && (
        <Styles.ManualRefreshButton data-testid='graph-refresh-button' onClick={onRefreshClicked}>
          {buttonRefreshInProgress ? (
            'Updating...'
          ) : (
            <>
              <Asset.RefreshCircle /> Refresh
            </>
          )}
        </Styles.ManualRefreshButton>
      )}
    </Styles.FetchSettings>
  );
};
