import styled from '@emotion/styled';
import { LandingBodyStyles } from 'Components/Common/Overview/LandingBody.style';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Card } from 'antd';
import { Asset } from 'assets';
import { AddOverviewResourceModalBodyStyles } from '../Overview/AddOverviewResourceModalBody.style';
import { Permission, usePermissions } from 'utils/permissions';
import { docLinks } from 'utils/url-external-links-map';

const TracingLandingEmptyContentWrapper = styled.div`
  max-width: 800px;
  margin-left: 30px;
  margin-right: 30px;
`;

const TracingLandingEmpty = () => {
  const { hasPerm } = usePermissions();
  const docsLink = hasPerm(Permission.CoreLicense) ? docLinks.core.tracing : docLinks.enterprise.tracing;
  return (
    <LandingBodyStyles.EmptyContainer data-testid={'tracing-landing-empty'}>
      <LandingBodyStyles.EmptyIconContainer>
        <Asset.TracingIcon />
      </LandingBodyStyles.EmptyIconContainer>
      <LandingBodyStyles.Header>Distributed Tracing</LandingBodyStyles.Header>
      <TracingLandingEmptyContentWrapper>
        <Card>
          <Spacer pb={4}>
            Distributed tracing helps you track requests across multiple services in your distributed service mesh.
            Sampling the requests in your mesh can give you insight into request latency, serialization, and
            parallelism. For more information, see the Istio tracing documentation.
          </Spacer>
          <div>
            To get started:
            <FlexLayout position='relative' my={4}>
              <AddOverviewResourceModalBodyStyles.InstructionNumber>
                1
              </AddOverviewResourceModalBodyStyles.InstructionNumber>
              <Spacer pl={'40px'}>
                Enable the default Envoy distributed tracing in your Istio installation to trace requests across your
                service mesh, analyze service dependencies and find bottlenecks or high latency services in your mesh.
              </Spacer>
            </FlexLayout>
            <FlexLayout position='relative'>
              <AddOverviewResourceModalBodyStyles.InstructionNumber>
                2
              </AddOverviewResourceModalBodyStyles.InstructionNumber>
              <Spacer pl={'40px'}>
                Then, use a tracing provider, such as the built-in Jaeger platform, to collect and visualize the sampled
                spans.
              </Spacer>
            </FlexLayout>
          </div>
        </Card>
      </TracingLandingEmptyContentWrapper>
      <Spacer pb={'100px'}>
        <ExternalSoloLink href={docsLink} newTab>
          <SoloButton>VIEW DOCS</SoloButton>
        </ExternalSoloLink>
      </Spacer>
    </LandingBodyStyles.EmptyContainer>
  );
};

export default TracingLandingEmpty;
