import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { MonospaceText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import { useContext } from 'react';
import { graphqlApiTypeMap } from 'utils/types';
import { GqlDetailsStyles } from '../../Details/GqlDetails.style';
import { GqlDescriptionInfoTooltip } from '../../Details/Schema/GqlDescriptionInfoTooltip';
import { GqlLandingContext } from '../../context/GqlLandingContext';
import { GqlResolverModalStyles as Styles } from '../GqlResolverModal.style';

export const GqlTypeMergeModal = ({ objectName, onClose }: { objectName: string; onClose?: () => void }) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api, fullTypeMergeMap } = gqlCtx;
  const crumbs = ['Schema', objectName];
  const typeMergeAndSubschemas = fullTypeMergeMap[objectName];

  //
  // Render
  //
  if (!objectName) return null;
  if (typeMergeAndSubschemas === undefined)
    return (
      <SoloModal width={1000} onClose={onClose} minHeight='unset' withPadding data-testid='type-merge-modal'>
        <SoloModalHeader
          title='Type Merge'
          subtitle={crumbs}
          icon={<Asset.StitchedGraphqlIcon />}
          status={api.status}
          rightContent={<Styles.HeaderRight></Styles.HeaderRight>}
        />
        <DataError error={{ message: `There is no type merge definition for ${objectName}.` }} />
      </SoloModal>
    );
  return (
    <SoloModal width={1000} onClose={onClose} minHeight='unset' withPadding data-testid='type-merge-modal'>
      <SoloModalHeader
        title='Type Merge'
        subtitle={crumbs}
        icon={<Asset.StitchedGraphqlIcon />}
        status={api.status}
        rightContent={<Styles.HeaderRight></Styles.HeaderRight>}
      />
      <CardStyles.CardList>
        {typeMergeAndSubschemas.map(({ subschema, typeMerge }, idx) => {
          const schemaName = subschema.graphqlSchemaRef?.name ?? subschema.graphqlStitchedSchemaRef?.name ?? '';
          return (
            <CardStyles.Card key={idx}>
              <CardStyles.CardHeader>
                {graphqlApiTypeMap[subschema.type].icon}
                &nbsp;&nbsp;
                {schemaName}
                <GqlDetailsStyles.TitleSecondary>
                  ({graphqlApiTypeMap[subschema.type].name})
                </GqlDetailsStyles.TitleSecondary>
              </CardStyles.CardHeader>
              <SoloListCardStyles.Body>
                <Spacer mt={2}>
                  <Spacer mb={2}>
                    <b>Query</b>
                    <GqlDescriptionInfoTooltip
                      placement='right'
                      description={`The query on ${schemaName} that is used for this type.`}
                    />
                  </Spacer>
                  <MonospaceText>{typeMerge.queryName}</MonospaceText>
                </Spacer>
                {!!Object.keys(typeMerge.args) && (
                  <Spacer mt={7}>
                    <Spacer>
                      <b>Arguments</b>
                      <GqlDescriptionInfoTooltip
                        placement='right'
                        description={`
The arguments provide the schema stitching engine the format to turn the initial object representation into query arguments.

For example, if the GetUser query on a subschema was defined as
\`\`\`gql
input UserSearch {
  username: String
}
type Query {
  GetUser(user_search: UserSearch): User
}
\`\`\`
we would want to set the user query argument with the correct username from an object.
we can do that by setting the args as:
\`\`\`yaml
args:
  user_search.username: username
\`\`\`
where \`user_search.username\` is the "setter" path that we are setting the argument input value at and
\`username\` is the "extraction" path that we are extracting from an object, such as \`{"username": "wpatel"}\`.
                          `}
                      />
                    </Spacer>
                    {Object.entries(typeMerge.args).map(([key, value], idx) => (
                      <Spacer key={idx} display='inline-block' mr={2} mt={2}>
                        <SecondaryInfoItem label={key} data={value} />
                      </Spacer>
                    ))}
                  </Spacer>
                )}
                {!!typeMerge.selectionSet && (
                  <Spacer mt={7}>
                    <Spacer mb={2}>
                      <b>Selection Set</b>
                      <GqlDescriptionInfoTooltip
                        placement='right'
                        description={`This is a graphql selection set that specifies one or more key fields required from other services to perform this query. Query planning will automatically resolve these fields from other subschemas in dependency order`}
                      />
                    </Spacer>
                    <FancyCodeDisplayer contentString={typeMerge.selectionSet} type='graphql' />
                  </Spacer>
                )}
              </SoloListCardStyles.Body>
            </CardStyles.Card>
          );
        })}
      </CardStyles.CardList>
    </SoloModal>
  );
};
