import { policiesApi } from 'Api/policies';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { MonospaceText } from 'Styles/CommonEmotions/text';
import { getPolicyIcon } from 'utils/types';

const { useGetGraphQLAllowedQueryPolicyDetails } = policiesApi;

type QueryHashColumnsType = { key: string; value: string };
const queryHashColumns: SoloColumnsType<QueryHashColumnsType> = [
  {
    title: 'Allowed Query Hashes',
    dataIndex: 'value',
    render: value => <MonospaceText>{value}</MonospaceText>
  }
];

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const GraphqlAllowedQueryDetails = ({ clusterObjRef }: Props) => {
  di(useGetGraphQLAllowedQueryPolicyDetails);
  const queryHashPaging = useSoloPaging();
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetGraphQLAllowedQueryPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject,
    queryHashPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);
  queryHashPaging.useSetPagingTotal(policyData?.totalAllowedQueryHashes);

  const queryHashDataSource = useMemo(
    () =>
      policyData?.allowedQueryHashes.map((value, idx) => ({
        key: `${idx}_${value}`,
        value
      })) ?? [],
    [policyData?.allowedQueryHashes]
  );

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded
          icon={getPolicyIcon(PolicyType.GRAPHQL_ALLOWED_QUERY_POLICY)}
          objRef={clusterObjRef}
        />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  //
  // Render
  //
  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(PolicyType.GRAPHQL_ALLOWED_QUERY_POLICY)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={ResourceType.GRAPH_QL_ALLOWED_QUERY_POLICY}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>
        <SoloTable
          removeHorizontalPadding
          columns={queryHashColumns}
          dataSource={queryHashDataSource}
          paging={queryHashPaging.pagingData}
          data-testid='gql-allowed-query-hashes-table'
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
