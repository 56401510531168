import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { healthColors } from 'Styles/colors';

export namespace DataErrorStyles {
  type ContainerProps = {
    center: boolean;
    loadingComplete: boolean;
  };
  export const Container = styled.div<ContainerProps>(
    ({ center, loadingComplete }) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px 10px 35px;
      opacity: 0;
      transition: opacity 0.3s;

      ${loadingComplete ? 'opacity: 1;' : ''}

      ${center
        ? css`
            height: 100%;
            justify-content: center;
          `
        : ''}
    `
  );

  type ImageHolderProps = {
    calm: boolean;
    iconWidth?: number;
  };
  export const ImageHolder = styled.div<ImageHolderProps>(
    ({ calm, iconWidth }) => css`
      display: flex;
      align-items: center;
      justify-items: center;

      ${calm
        ? css`
            svg {
              display: block;

              ${iconWidth
                ? css`
                    width: ${iconWidth}px;
                  `
                : css`
                    width: auto;
                    height: 55px;
                  `}
            }
          `
        : css`
            svg {
              width: ${iconWidth ?? 30}px;

              /* How did we decide this for all error svgs?? */
              line {
                stroke: white;
                stroke-width: 6px;
              }

              circle {
                stroke: none;
                fill: ${healthColors.error.dark};
              }
            }
          `};
    `
  );

  export const Title = styled.div`
    font-size: 28px;
    text-align: center;
    margin-top: 30px;
  `;

  export const MessageBox = styled.div<{ compact?: boolean }>(
    ({ compact = true }) => css`
      font-weight: 500;
      ${!compact
        ? css`
            margin-top: 12px;
            font-size: 18px;
          `
        : css`
            font-size: 16px;
          `}
      color: ${healthColors.error.dark};
      max-width: 100%;
      word-break: break-all;
    `
  );
  export const CalmerMessageBox = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
  `;
  export const CodeBox = styled(CalmerMessageBox)(
    ({ theme }) => css`
      font-style: italic;
      color: ${theme.augustGrey};
    `
  );
}
