import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CollapseToggleStyles } from 'Components/Common/CollapseToggle';
import { animations } from 'Styles/CommonEmotions/animations';
import { svgColorReplace } from 'Styles/CommonEmotions/utils';

export namespace SidebarSectionStyles {
  export const Container = styled.div<{ sidebarCollapsed: boolean }>(({ theme, sidebarCollapsed }) => {
    return css`
      border-top: 1px solid ${theme.marchGrey};
      padding: 10px 0;

      &:first-of-type {
        border-top: unset;
      }

      a,
      button {
        color: ${theme.neptuneBlue};
        :hover {
          color: ${theme.seaBlue};
        }
      }

      ${sidebarCollapsed &&
      css`
        ${Heading} {
          display: none;
        }
      `}
    `;
  });
  export const Heading = styled.div(({ theme }) => {
    return css`
      color: ${theme.augustGrey};
      font-size: 14px;
      text-transform: capitalize;
      padding: 10px 0 5px 22px;
    `;
  });

  export const SubItemList = styled.ul<{ tooltipDesign: boolean }>(({ tooltipDesign, theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: 1px;
      ${tooltipDesign
        ? css`
            > ${SubItemWrapper} > ${SubItem} {
              > :last-child {
                padding-right: 10px;
              }

              > ${CollapseToggleStyles.Holder} {
                padding-left: 14px;
              }
            }
          `
        : ''}
    `;
  });
  export const SubItemWrapper = styled.li``;
  interface SubItemProps {
    active: boolean;
    level: number;
    compactPadding: boolean;
  }
  export const SubItem = styled.div<SubItemProps>(({ active, level, compactPadding, theme }) => {
    return css`
      display: flex;
      justify-content: space-between;

      width: 100%;
      font-size: 16px;
      color: ${theme.neptuneBlue};
      border-right: 3px solid transparent;

      .action {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        padding: 10px 0 10px ${(compactPadding ? 17 : 22 + 26 + 10) + level * 8}px; // padding on the action to give it a bigger hitbox
        white-space: nowrap;
      }

      // We want there to be a right padding always on last item - but here instead of parent to make a bigger hitbox
      > :last-child {
        padding-right: 17px;
      }

      ${CollapseToggleStyles.Holder} {
        padding-left: 17px;
        margin: 0;
      }

      ${active &&
      css`
        * {
          color: ${theme.seaBlue} !important;
        }
        color: ${theme.seaBlue} !important;
        background: ${theme.drizzleBlue};
        border-right-color: ${theme.seaBlue};
      `}
    `;
  });

  export const CollapsableText = styled.span<{ fadingIn: boolean; animationPlaying: boolean }>(
    ({ fadingIn, animationPlaying }) => {
      return css`
        animation-name: ${fadingIn ? animations.fadeIn : animations.fadeOut};
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-timing-function: ease;
        animation-duration: 0.6s;
        animation-play-state: ${animationPlaying ? 'running' : 'paused'};
      `;
    }
  );

  export const TopLevelItemList = styled.ul`
    display: flex;
    flex-direction: column;
  `;
  interface TopLevelItemWrapperProps {
    sidebarCollapsed: boolean;
    submenuOpen: boolean;
  }
  export const TopLevelItemWrapper = styled.li<TopLevelItemWrapperProps>(({ sidebarCollapsed, submenuOpen, theme }) => {
    return css`
      ${submenuOpen &&
      css`
        background: ${theme.dropBlue};
      `}
      ${sidebarCollapsed &&
      css`
        ${TopLevelItem} > ${CollapseToggleStyles.Holder} {
          display: none;
        }
      `}
    `;
  });
  export const TopLevelItem = styled.div<{ active: boolean; submenuOpen: boolean }>(
    ({ active, submenuOpen, theme }) => {
      return css`
        display: flex;
        justify-content: space-between;

        width: 100%;
        font-size: 18px;
        color: ${theme.neptuneBlue};
        border-right: 3px solid transparent;

        .action {
          display: flex;
          align-items: center;
          gap: 10px;
          flex: 1;
          padding: 18px 0 18px 22px; // padding on the action to give it a bigger hitbox
          white-space: nowrap;
        }

        // We want there to be a right padding always on last item - but here instead of parent to make a bigger hitbox
        > :last-child {
          padding-right: 17px;
        }

        ${CollapseToggleStyles.Holder} {
          padding-left: 17px;
          margin: 0;
          border: 1px solid transparent;
          :hover {
            background: ${theme.dropBlue};
            border-color: ${theme.dropBlue};
            ${submenuOpen &&
            css`
              border-color: ${theme.marchGrey};
            `}
          }
        }

        ${submenuOpen &&
        css`
          * {
            font-weight: bold;
          }
          ${svgColorReplace(theme.seaBlue)}
        `};

        ${active &&
        css`
          a {
            cursor: default;
            pointer-events: none;
          }
          * {
            color: ${theme.seaBlue} !important;
          }
          color: ${theme.seaBlue} !important;
          background: ${theme.drizzleBlue};
          border-right-color: ${theme.seaBlue};
        `}
      `;
    }
  );
  export const TopLevelHoverContent = styled.div(({ theme }) => {
    return css`
      display: none;

      position: absolute;
      z-index: 99;
      left: 35px;
      margin-top: -10px;
      white-space: nowrap;
      width: min-content;

      padding: 5px 0;
      background: white;
      border: 1px solid ${theme.aprilGrey};
      box-shadow: 0px 2px 8px ${theme.boxShadow};

      ${TopLevelItemWrapper}:hover > & {
        display: block;
      }
    `;
  });
}
