import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';

export namespace DashboardHealthCardStyles {
  export const NoMarginEmptyContainer = styled.div`
    display: flex; // Makes sure the ListCard stretches to fit in cases where this container is also in a flexbox (basically fixes the default ListCard behavior)
    .ant-empty {
      margin: 0 !important;
    }

    ${SoloListCardStyles.CellContent} {
      max-width: calc(100% - 30px);
    }
  `;

  export const HealthCardRowStyle = styled(FlexLayout)<{ hasNoStatus: boolean }>(
    ({ hasNoStatus, theme }) => css`
      ${hasNoStatus
        ? css`
            color: ${theme.juneGrey};
          `
        : ''}
    `
  );
}
