import { ExportIcon, ImportIcon, WorkspaceIcon } from 'assets/assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { renderHealthIndicatorWithName, StateAndNameType } from 'Components/Common/SoloTableCells';
import { WorkspaceStatus } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { docLinks } from 'utils/url-external-links-map';

export enum WorkspaceListCardType {
  Imported,
  Exported,
  AvailableToImport,
  Importing
}

const workspaceListCardTypeMap = {
  [WorkspaceListCardType.Imported]: {
    title: 'Imported Workspaces',
    icon: <ImportIcon />,
    renderEmpty: (
      <SoloEmptyWithDocs
        icon={<WorkspaceIcon />}
        iconCircled
        title='No Workspaces Imported'
        description=''
        href={docLinks.enterprise.multi_tenancy}
      />
    )
  },
  [WorkspaceListCardType.Exported]: {
    title: 'Workspaces exported to',
    icon: <ExportIcon />,
    renderEmpty: (
      <SoloEmptyWithDocs
        icon={<WorkspaceIcon />}
        iconCircled
        title='Not Exported'
        description=''
        href={docLinks.enterprise.multi_tenancy}
      />
    )
  },
  [WorkspaceListCardType.AvailableToImport]: {
    title: 'Workspaces available to import',
    icon: <></>, //<AvailableAddIcon />,
    renderEmpty: (
      <SoloEmptyWithDocs
        icon={<WorkspaceIcon />}
        iconCircled
        title='No Importable Workspaces'
        description=''
        href={docLinks.enterprise.multi_tenancy}
      />
    )
  },
  [WorkspaceListCardType.Importing]: {
    title: 'Workspaces importing this', // importingTypeName
    icon: <ImportIcon />,
    renderEmpty: (
      <SoloEmptyWithDocs
        icon={<WorkspaceIcon />}
        iconCircled
        title='No Workspaces Importing'
        description=''
        href={docLinks.enterprise.multi_tenancy}
      />
    )
  }
};

interface WorkspaceTableFields {
  key: string;
  workspace: StateAndNameType;
}

export interface WorkspacesListCardProps {
  cardType: WorkspaceListCardType;
  workspaces: WorkspaceStatus[] | undefined;
  importingTypeName?: string;
}
export const WorkspacesListCard = ({ cardType, workspaces, importingTypeName }: WorkspacesListCardProps) => {
  const tableData = useMemo<WorkspaceTableFields[]>(
    () =>
      workspaces?.map(ws => ({
        key: buildIdFromRef(ws.ref),
        workspace: {
          name: ws.ref?.name ?? '<unknown>',
          status: ws.status
        }
      })) ?? [],
    [workspaces]
  );

  const { title, icon, renderEmpty } = workspaceListCardTypeMap[cardType];

  return (
    <SoloListCard
      title={cardType !== WorkspaceListCardType.Importing ? title : `${title} ${importingTypeName ?? 'service'}`}
      icon={icon}
      dataSource={tableData.map(({ key, workspace }) => ({
        key,
        data: renderHealthIndicatorWithName(workspace)
      }))}
      renderEmpty={renderEmpty}
    />
  );
};
