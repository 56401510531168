import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Kind, NamedTypeNode, UnionTypeDefinitionNode } from 'graphql';
import { useContext, useMemo } from 'react';
import { GqlSchemaTabTableProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import { RenderDefinitionNameLinkProps, renderDefinitionNameLink } from '../../../GqlSoloTableCells';
import FieldTypeValue from '../../FieldTypeValue';
import { GqlDescriptionInfoTooltip } from '../../GqlDescriptionInfoTooltip';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';

type TableFields = {
  key: string;
  nameLink: RenderDefinitionNameLinkProps;
  unionedTypes: readonly (NamedTypeNode & { isFieldIncludedByExtension?: boolean })[];
  description: string;
};

const GqlUnionTabTable = ({
  onTypeClick,
  definitions,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<UnionTypeDefinitionNode>) => {
  const columns: SoloColumnsType<TableFields> = [
    {
      title: 'Name',
      dataIndex: 'nameLink',
      render: renderDefinitionNameLink
    },
    {
      title: 'Value',
      dataIndex: 'unionedTypes',
      render: unionedTypes => (
        <Spacer py={2}>
          {unionedTypes.map((type, idx) => {
            return (
              <FlexLayout display='inline' key={type.name.value}>
                {idx > 0 && (
                  <Spacer display='inline' mr='2px'>
                    |
                  </Spacer>
                )}
                {!!type.isFieldIncludedByExtension && (
                  <GqlDescriptionInfoTooltip description={'This union was extended to include this value.'} />
                )}
                <GqlSchemaTabStyles.MonospaceItem display='inline-block' key={type.name.value}>
                  <FieldTypeValue field={type} onTypeClick={itemName => onTypeClick(itemName, true)} />
                  {idx < unionedTypes.length - 1 && <>&nbsp;</>}
                </GqlSchemaTabStyles.MonospaceItem>
              </FlexLayout>
            );
          })}
        </Spacer>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: renderReactMarkdown
    }
  ];

  //
  // Pagination, Table Data
  //
  const gqlctx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlctx;
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(definitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(d => {
      //
      // Get the extensions info, and mark it as such.
      const extensionValues =
        getExtensionInfo(Kind.UNION_TYPE_EXTENSION, d.name.value)?.types?.map(v => ({
          ...v,
          isFieldIncludedByExtension: true
        })) ?? [];
      //
      // Return the table data
      return {
        key: d.name.value,
        nameLink: { name: d.name.value, tabHeader, onTypeClick },
        unionedTypes: [...(d.types ?? []), ...extensionValues],
        description: getDescriptionColumnValue(d)
      };
    });
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  //  Render
  //
  if (hidden) return null;
  return (
    <SoloTable
      removeHorizontalPadding
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='gql-unions-table'
    />
  );
};

export default GqlUnionTabTable;
