import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';

const openPadding = '20px';
const closedPadding = '20px';
const closedPaddingAppIcon = '15px';

export namespace SidebarStyles {
  interface ContainerProps {
    open: boolean;
  }
  export const Container = styled.nav<ContainerProps>(
    ({ open, theme }) => css`
      grid-area: sidebar;

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      width: 235px;
      max-width: 235px;
      transition: max-width 0.6s;
      line-height: 1;
      background: white;
      color: ${theme.neptuneBlue};

      ${!open &&
      css`
        max-width: 70px;
      `}
    `
  );

  export namespace AppTitle {
    export const AppTitleBox = styled(Link)<ContainerProps>(
      ({ open, theme }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        height: 53px;
        text-decoration: none;
        font-weight: 500;
        color: ${theme.puddleBlue};
        font-size: 18px;
        border-bottom: 1px solid ${theme.marchGrey};
        border-right: 1px solid ${theme.marchGrey};
        padding-left: ${open ? openPadding : closedPaddingAppIcon};
        padding-right: 0;
        transition: padding-left 0.3s ease-out;

        svg {
          height: 30px;
        }
      `
    );

    export const CollapsingItemWrapper = styled.div<{ hideOverflow?: boolean }>(
      ({ hideOverflow }) => css`
        ${hideOverflow
          ? css`
              overflow: hidden;
            `
          : ''}
      `
    );

    export const GlooPlatformIconHolder = styled.div<{ textVisible: boolean }>(
      ({ textVisible }) => css`
        padding-left: 30px;
        margin-left: -30px;
        white-space: nowrap;
        svg:nth-of-type(2) {
          margin-left: -45px;
          transition: opacity 0.6s ease;
          // Using an animation fade didn't work here.
          ${textVisible
            ? css`
                opacity: 1;
              `
            : css`
                opacity: 0;
              `}
        }
      `
    );
  }

  export const InnerContainer = styled.div(
    ({ theme }) => css`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      border-right: 1px solid ${theme.marchGrey};
      overflow-y: auto;
      overflow-x: hidden;
    `
  );

  export const CollapseButton = styled(UnstyledButton)<ContainerProps>(
    ({ open, theme }) => css`
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 21px;
      color: ${theme.augustGrey};
      cursor: pointer;
      padding-left: ${open ? openPadding : closedPadding};
      transition: padding 0.3s ease-out;
      gap: 13px;

      ${svgBasics(26.25, theme.augustGrey)}
      svg {
        transform: scaleX(${open ? '100' : '-100'}%);
        transition: transform 0.3s ease-out;
      }

      span {
        opacity: ${open ? '1' : '0'};
        max-width: ${open ? '90px' : '0'};
        overflow: hidden;
        transition: opacity 0.3s ease-out, max-width 0.3s ease-out;
      }
    `
  );
}
