import { Empty } from 'antd';
import React, { useMemo } from 'react';
import { isNonNullableAndNonFalse } from 'utils/helpers';
import { SoloListCardStyles as Styles } from './SoloListCard.style';

export type DataType =
  | string
  | number
  | {
      key?: string;
      data: NonNullable<React.ReactNode>;
      right?: React.ReactNode;
      selectRow?: (key: string) => any;
      isSelected?: boolean;
    };

export const HorizontalListCardsContainer = Styles.HorizontalListCardsContainer;

export interface SoloListCardProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
  dataSource: readonly (DataType | false | undefined)[];
  renderEmpty?: React.ReactNode;
  maxHeight?: string;
  hideDottedLine?: boolean;
  ['data-testid']?: string;
}
export const SoloListCard = ({
  title,
  icon,
  dataSource,
  renderEmpty,
  maxHeight = '495px',
  hideDottedLine,
  'data-testid': testId
}: SoloListCardProps) => {
  const dataSourceFiltered = useMemo(() => dataSource.filter(isNonNullableAndNonFalse), [dataSource]);
  return (
    <Styles.Container data-testid={testId ?? 'solo-list-card'} maxHeight={maxHeight}>
      <Styles.Title isFirst={true} isLast={true}>
        <div>{title}</div>
        {icon}
      </Styles.Title>
      {!!dataSourceFiltered.length ? (
        dataSourceFiltered.map((row, idx) => {
          return typeof row !== 'object' ? (
            <Styles.Cell key={`${row}_${idx}`}>
              <Styles.CellContent>{row}</Styles.CellContent>
              <Styles.DottedLined visibility={hideDottedLine === true ? 'hidden' : 'visible'} />
            </Styles.Cell>
          ) : (
            <Styles.Cell
              key={`${row.key ?? row.data.toString()}_${idx}`}
              onClick={
                !!row.selectRow ? () => row.selectRow!(row.key ?? 'A key must be provided in this case!') : undefined
              }
              isSelectable={!!row.selectRow}
              isSelected={!!row.selectRow && row.isSelected}>
              <Styles.CellContent>{row.data}</Styles.CellContent>
              <Styles.DottedLined visibility={hideDottedLine === true ? 'hidden' : 'visible'} />
              {(!!row.right || typeof row.right === 'number') && <Styles.RightValue>{row.right}</Styles.RightValue>}
            </Styles.Cell>
          );
        })
      ) : (
        <Styles.Body>{renderEmpty ?? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</Styles.Body>
      )}
    </Styles.Container>
  );
};

export interface SoloListCardWithColumnsProps {
  columns: { title: string; width?: string }[];
  icon?: React.ReactNode;
  rows: {
    key: string;
    cells: { data: React.ReactNode; right?: React.ReactNode }[];
  }[];
  renderEmpty?: React.ReactNode;
  hideDottedLine?: boolean;
  ['data-testid']?: string;
}

export const SoloListCardWithColumns = ({
  columns,
  icon,
  rows,
  renderEmpty,
  hideDottedLine,
  'data-testid': testId
}: SoloListCardWithColumnsProps) => {
  return (
    <Styles.Container data-testid={testId ?? 'solo-list-card-with-columns'} columns={columns} maxHeight='495px'>
      {columns.map((c, i) => (
        <Styles.Title key={c.title} isFirst={i === 0} isLast={i === columns.length - 1}>
          <div>{c.title}</div>
          {i === columns.length - 1 && icon}
        </Styles.Title>
      ))}
      {!!rows.length ? (
        rows
          // auto generate cell keys using row key + column count
          .map(row => row.cells.map((cell, i) => ({ key: `${row.key}-${i}`, ...cell })))
          .flat()
          .map(({ key, data, right }) => {
            return (
              <Styles.Cell key={key}>
                <Styles.CellContent>{data}</Styles.CellContent>
                <Styles.DottedLined visibility={hideDottedLine === true ? 'hidden' : 'visible'} />
                {(!!right || typeof right === 'number') && <Styles.RightValue>{right}</Styles.RightValue>}
              </Styles.Cell>
            );
          })
      ) : (
        <Styles.Body colspan={columns.length}>
          {renderEmpty ?? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Styles.Body>
      )}
    </Styles.Container>
  );
};

export interface SoloListCardDynamicProps {
  title: string;
  icon?: React.ReactNode;
  renderEmpty?: React.ReactNode;
  ['data-testid']?: string;
  children: React.ReactNode;
}
export const SoloListCardDynamic = ({
  title,
  icon,
  renderEmpty,
  'data-testid': testId,
  children
}: SoloListCardDynamicProps) => {
  return (
    <Styles.Container data-testid={testId ?? 'solo-list-card-dynamic'}>
      <Styles.Title isFirst={true} isLast={true}>
        <div>{title}</div>
        {icon}
      </Styles.Title>
      <Styles.Body>
        {(Array.isArray(children) ? children.length > 0 : !!children)
          ? children
          : renderEmpty ?? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Styles.Body>
    </Styles.Container>
  );
};
