import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { workspacesApi } from 'Api/workspaces';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCardPreLoaded, DetailsHeaderCardWithoutDefaults } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { Svg, SvgWithTextContainer } from 'Components/Common/Svg';
import { CardFolderTab, CardFolderTabList } from 'Components/Common/Tabs';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useLocation, useParams } from 'react-router-dom';
import { SoloButtonLink } from 'Styles/CommonEmotions/button';
import { Permission, usePermissions } from 'utils/permissions';
import { buildWorkspaceGraphUrl } from 'utils/url-builders';
import { workspaceUrlStrings } from 'utils/url-string-constants';
import { WorkspaceListCardType, WorkspacesListCard } from '../WorkspacesListCard';
import { ClusterNamespacesSection } from './Components/ClusterNamespacesSection';
import { ApisTab } from './Tabs/Apis/ApisTab';
import { DestinationsTab } from './Tabs/Destinations/DestinationsTab';
import { VirtualDestinationsTab } from './Tabs/Destinations/VirtualDestinationsTab';
import { PoliciesTab } from './Tabs/Policies/PoliciesTab';
import { RoutingTab } from './Tabs/Routing/RoutingTab';
const { useGetWorkspaceDetails } = workspacesApi;

interface WorkspaceHeaderProps {
  workspaceName: string;
  workspaceSettingsRef: ClusterObjectRef | undefined;
  status: Status | undefined;
  federationEnabled: boolean;
  serviceIsolationEnabled: boolean;
}
const WorkspaceHeader = ({
  workspaceName,
  workspaceSettingsRef,
  status,
  federationEnabled,
  serviceIsolationEnabled
}: WorkspaceHeaderProps) => {
  const { hasPerm } = usePermissions();
  return (
    <DetailsHeaderCardWithoutDefaults
      title={workspaceName}
      icon={<Asset.WorkspaceIcon />}
      status={status}
      secondaryInfo={[
        {
          label: 'settings namespace',
          data: workspaceSettingsRef?.namespace ?? 'unknown'
        },

        {
          label: (
            <SvgWithTextContainer>
              <Svg asset={<Asset.FederationIcon />} size={20} />
              federation
            </SvgWithTextContainer>
          ),
          data: federationEnabled ? 'Enabled' : 'Disabled'
        },
        {
          label: (
            <SvgWithTextContainer>
              <Svg asset={<Asset.SelectorIcon />} size={20} />
              service isolation
            </SvgWithTextContainer>
          ),
          data: serviceIsolationEnabled ? 'Enabled' : 'Disabled'
        }
      ]}
      yaml={{
        clusterObjectRef: workspaceSettingsRef!,
        resourceType: ResourceType.WORKSPACE_SETTINGS
      }}
      buttons={[
        hasPerm(Permission.GraphEnabled) && (
          <SoloButtonLink to={buildWorkspaceGraphUrl(workspaceName)} data-testid='workspacedetails-linktograph'>
            View Graph
          </SoloButtonLink>
        )
      ]}
    />
  );
};

export const WorkspaceDetailsLanding = () => {
  di(useParams, useGetWorkspaceDetails, usePermissions);
  const { workspaceName = '' } = useParams();
  const routerLocation = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const { hasPerm } = usePermissions();

  const { data: workspaceData, error: workspaceError } = useGetWorkspaceDetails(workspaceName);

  // If the user visited a subopage that was redirected here (such as `/routing`, often from a breadcrumb) then open
  // tab corresponding to it, since they don't have their own pages.
  useEffect(() => {
    let tabIndex = -1;
    if (routerLocation.pathname.indexOf(`/${workspaceUrlStrings.destinations}`) > -1) {
      tabIndex = 0;
    } else if (routerLocation.pathname.indexOf(`/${workspaceUrlStrings.routing}`) > -1) {
      tabIndex = 2;
    } else if (routerLocation.pathname.indexOf(`/${workspaceUrlStrings.policies}`) > -1) {
      tabIndex = 3;
    } else if (routerLocation.pathname.indexOf(`/${workspaceUrlStrings.apis}`) > -1) {
      tabIndex = 4;
    }
    if (tabIndex > -1) {
      setTabIndex(tabIndex);
      window.document.getElementById('tabs')?.scrollIntoView();
    }
  }, []);

  if (!!workspaceError || !workspaceData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded usedName={workspaceName} icon={<Asset.WorkspaceIcon />} objRef={undefined} />
        {!!workspaceError ? (
          <DataError error={workspaceError} />
        ) : (
          <Loading message={`Retrieving ${workspaceName} Workspace details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList data-testid='workspace-details-landing'>
      <WorkspaceHeader
        workspaceName={workspaceName}
        workspaceSettingsRef={workspaceData.workspaceSettingsRef}
        status={workspaceData.status}
        federationEnabled={workspaceData.federationEnabled}
        serviceIsolationEnabled={workspaceData.serviceIsolationEnabled}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Cluster / Namespaces</CardStyles.CardHeader>
        <ClusterNamespacesSection
          clusterNamespaces={workspaceData.clusterNamespaces}
          workspaceSettingsRef={workspaceData.workspaceSettingsRef}
        />
      </CardStyles.Card>

      <Tabs id='tabs' index={tabIndex} onChange={setTabIndex}>
        <CardFolderTabList>
          <CardFolderTab>Destinations</CardFolderTab>
          <CardFolderTab data-testid='virtual-destination-tab-button' width='170px'>
            Virtual Destinations
          </CardFolderTab>
          <CardFolderTab data-testid='routing-tab-button'>Routing</CardFolderTab>
          <CardFolderTab data-testid='policy-tab-button'>Policies</CardFolderTab>
          <CardFolderTab hidden={!hasPerm(Permission.CanViewApisPage)} data-testid='apis-tab-button'>
            APIs
          </CardFolderTab>
        </CardFolderTabList>
        <TabPanels>
          <TabPanel>
            <DestinationsTab workspaceName={workspaceName} />
          </TabPanel>
          <TabPanel>
            <VirtualDestinationsTab workspaceName={workspaceName} />
          </TabPanel>
          <TabPanel>
            <RoutingTab workspaceName={workspaceName} />
          </TabPanel>
          <TabPanel>
            <PoliciesTab workspaceName={workspaceName} />
          </TabPanel>
          <TabPanel>
            <ApisTab workspaceName={workspaceName} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <CardStyles.Card>
        <CardStyles.CardHeader>Workspace Dependencies</CardStyles.CardHeader>
        <SoloListCardStyles.HorizontalListCardsContainer style={{ maxHeight: '250px' }}>
          <WorkspacesListCard cardType={WorkspaceListCardType.Imported} workspaces={workspaceData.importedWorkspaces} />
          <WorkspacesListCard
            cardType={WorkspaceListCardType.Exported}
            workspaces={workspaceData.workspacesExportedTo}
          />
          <WorkspacesListCard
            cardType={WorkspaceListCardType.AvailableToImport}
            workspaces={workspaceData.workspacesAvailableToImport}
          />
        </SoloListCardStyles.HorizontalListCardsContainer>
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
