import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Asset } from 'assets';
import { svgBasics, svgColorReplace } from 'Styles/CommonEmotions/utils';

export namespace SettingsDropdownStyles {
  export const Container = styled.div`
    min-width: 250px;
  `;

  export const LoadingHolder = styled(Container)`
    height: 75px;
  `;

  export const InnerTop = styled.div`
    padding: 0 10px 5px;
  `;

  export const InnerBottom = styled.div(
    ({ theme }) => css`
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      padding: 4px 8px;
      background: ${theme.dropBlue};
    `
  );

  export const Header = styled.div(
    ({ theme }) => css`
      font-size: 18px;
      color: ${theme.neptuneBlue};
    `
  );

  export const LicenseRow = styled.div(
    ({ theme }) => css`
      display: flex;
      justify-content: space-between;
      gap: 10px;

      margin-top: 5px;
      white-space: nowrap;

      & + & {
        padding-top: 5px;
        border-top: 1px solid ${theme.splashBlue};
      }
    `
  );
  export const ProductName = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 4px;
  `;
  export const Gloo = styled.span`
    font-size: 16px;
    font-weight: bolder;
    color: #003c6a;
  `;
  export const AfterGloo = styled.span`
    font-size: 16px;
    color: #003c6a;
  `;
  export const InfoIconHolder = styled.div(({ theme }) => {
    return css`
      ${svgBasics({ width: 12, height: 'auto' })}
      :hover {
        ${svgColorReplace(theme.lakeBlue)}
      }
    `;
  });
  export const InfoTooltipHeader = styled.div`
    font-size: 15px;
    font-weight: bold;
  `;

  export const LicenseStatusCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 4px;

    ${svgBasics({ width: 15, height: 'auto' })}
  `;
  export const LicenseStatusText = styled.span(
    ({ theme }) => css`
      font-size: 14px;
      color: ${theme.augustGrey};
    `
  );
  export const NoLicenseXSmall = styled(Asset.ErrorCircleIcon)`
    filter: grayscale(1);
  `;
}
