import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { getFontSize, IGqlSchemaSearchBoxItem } from 'utils/graphql-schema-search-helpers';
import { useEventListener } from 'utils/hooks';
import { GqlSchemaSearchStyles } from './GqlSchemaSearch.style';

const GqlSchemaSearchBox = ({
  show,
  allSearchBoxItems,
  searchText,
  numItemsToRender = 10,
  numItemsToLoad = 100,
  onSearchBoxItemClick
}: {
  show: boolean;
  allSearchBoxItems: IGqlSchemaSearchBoxItem[];
  searchText: string;
  numItemsToRender?: number;
  numItemsToLoad?: number;
  onSearchBoxItemClick?: (item: IGqlSchemaSearchBoxItem) => void;
}) => {
  //
  // State
  //
  const [hoveredItem, setHoveredItem] = useState<IGqlSchemaSearchBoxItem | undefined>(
    allSearchBoxItems.length > 0 ? allSearchBoxItems[0] : undefined
  );
  const itemHeightPx = 35;
  const boxHeightPx = numItemsToRender * itemHeightPx;
  const listElementRef = useRef<HTMLUListElement>(null);

  //
  // Update
  //
  // Updates the visible search box items when searched.
  const searchBoxItems = useMemo(() => {
    const formattedSearchText = searchText.toLowerCase().replaceAll(' ', '');
    const items = allSearchBoxItems.filter(item =>
      item.title.toLowerCase().replaceAll(' ', '').includes(formattedSearchText)
    );
    const itemsToLoad = items.filter((_, idx) => idx < numItemsToLoad);
    if (!itemsToLoad.some(item => item.id === hoveredItem?.id)) setHoveredItem(itemsToLoad[0]);
    return itemsToLoad;
  }, [allSearchBoxItems, searchText]);
  // Resets the hovered item when the search box is hidden.
  useEffect(() => {
    if (!show) {
      if (searchBoxItems.length > 0) setHoveredItem(searchBoxItems[0]);
      else setHoveredItem(undefined);
    }
  }, [show]);
  // This moves up/down or selects the item with the keyboard.
  useEventListener(
    window,
    'keydown',
    e => {
      if (!show || !searchBoxItems.length || !listElementRef.current) return;
      const listEl = listElementRef.current;
      let hoveredIdx = 0;
      if (!!hoveredItem) {
        hoveredIdx = searchBoxItems.findIndex(it => it.id === hoveredItem.id);
      }
      if (e.key === 'ArrowDown') {
        setHoveredItem(searchBoxItems[Math.min(searchBoxItems.length - 1, hoveredIdx + 1)]);
        listEl.scrollTop += itemHeightPx;
      }
      if (e.key === 'ArrowUp') {
        setHoveredItem(searchBoxItems[Math.max(0, hoveredIdx - 1)]);
        listEl.scrollTop -= itemHeightPx;
      }
      if (e.key === 'Enter' && !!onSearchBoxItemClick && !!hoveredItem) {
        onSearchBoxItemClick(hoveredItem);
      }
    },
    [show, searchBoxItems, hoveredItem, listElementRef.current, itemHeightPx]
  );

  //
  // Render
  //
  if (!show) return null;
  if (searchBoxItems.length === 0)
    return (
      <GqlSchemaSearchStyles.SearchBox heightPx={boxHeightPx} splitContent={false}>
        <Spacer padding={5}>
          <SoloEmptySimple />
        </Spacer>
      </GqlSchemaSearchStyles.SearchBox>
    );
  return (
    <GqlSchemaSearchStyles.SearchBox heightPx={boxHeightPx} splitContent={true}>
      <GqlSchemaSearchStyles.SearchBoxList ref={listElementRef}>
        {searchBoxItems.map(item => (
          <GqlSchemaSearchStyles.SearchBoxListItem
            key={item.id}
            menuItemHeightPx={itemHeightPx}
            hovered={item.title === hoveredItem?.title}
            className={hoveredItem?.id === item.id ? 'hovered-item' : ''}
            onMouseOver={() => setHoveredItem(item)}
            data-testid={`searchbox-list-item-${item.id}`}
            onClick={() => {
              if (!!onSearchBoxItemClick) onSearchBoxItemClick(item);
            }}>
            <span>{item.title}</span>
          </GqlSchemaSearchStyles.SearchBoxListItem>
        ))}
      </GqlSchemaSearchStyles.SearchBoxList>
      <GqlSchemaSearchStyles.SearchBoxSideContent>
        {!!hoveredItem && (
          <>
            <GqlSchemaSearchStyles.ContentH1 fontSize={getFontSize(hoveredItem.title.length)}>
              {hoveredItem.title}
            </GqlSchemaSearchStyles.ContentH1>
            {!!hoveredItem.description && (
              <>
                <GqlSchemaSearchStyles.ContentH2>Description</GqlSchemaSearchStyles.ContentH2>
                <GqlSchemaSearchStyles.ContentP>{hoveredItem.description}</GqlSchemaSearchStyles.ContentP>
              </>
            )}
          </>
        )}
      </GqlSchemaSearchStyles.SearchBoxSideContent>
    </GqlSchemaSearchStyles.SearchBox>
  );
};

export default GqlSchemaSearchBox;
