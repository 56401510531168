import { CiliumMetrics } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { reduceListAverage } from '../get-graph-items-data';
import { getMetricsListLineData, isTimestampsListOverHourLong } from './DetailsTab';
import { DetailsTabStyles } from './DetailsTab.style';
import { TimelineGraph } from './TimelineGraph';

const legendDataSingle = [
  { name: 'Forwarded', symbol: { type: 'dash' } },
  { name: 'Dropped', symbol: { type: 'dash' } }
];

const legendDataBoth = [
  { name: 'Fwd(I)', symbol: { type: 'dash' } },
  { name: 'Drp(I)', symbol: { type: 'dash' } },
  { name: 'Fwd(O)', symbol: { type: 'dash' } },
  { name: 'Drp(O)', symbol: { type: 'dash' } }
];

const lineDataInIds = [
  { id: 'sentIn', key: 'forwardedSent' as const },
  { id: 'dropsIn', key: 'policyDrops' as const }
];

const lineDataOutIds = [
  { id: 'sentOut', key: 'forwardedSent' as const },
  { id: 'dropsOut', key: 'policyDrops' as const }
];

function parseCiliumTimelineGraphData(metricsIn: CiliumMetrics[], metricsOut: CiliumMetrics[]) {
  const multipleHours =
    metricsIn.some(metric => isTimestampsListOverHourLong(metric.timestamps)) ||
    metricsOut.some(metric => isTimestampsListOverHourLong(metric.timestamps));

  const lineDataIn = getMetricsListLineData(metricsIn, lineDataInIds, multipleHours);
  const lineDataOut = getMetricsListLineData(metricsOut, lineDataOutIds, multipleHours);

  const dataset = [...lineDataIn.dataset, ...lineDataOut.dataset];
  let graphDataExists = !!dataset.length && Math.max(lineDataIn.maxY, lineDataOut.maxY) > 0;
  return { dataset, graphDataExists };
}
export function ciliumMetricsListToCounts(metricsList?: CiliumMetrics[]) {
  return (metricsList ?? []).reduce(
    (sums, metrics) => {
      sums.forwardedSentCount += reduceListAverage(metrics.forwardedSent);
      sums.policyDropsCount += reduceListAverage(metrics.policyDrops);

      sums.startingTime = Math.min(sums.startingTime, metrics.timestamps[0].seconds);
      sums.endingTime = Math.max(sums.endingTime, metrics.timestamps[metrics.timestamps.length - 1].seconds);
      return sums;
    },
    { forwardedSentCount: 0, policyDropsCount: 0, startingTime: Number.MAX_VALUE, endingTime: 0 }
  );
}

interface Props {
  metricsIn: CiliumMetrics[];
  metricsOut: CiliumMetrics[];
  isEdgeDetails?: boolean;
}
export const CiliumSection = ({ metricsIn, metricsOut, isEdgeDetails = false }: Props) => {
  const oneWayEdge = isEdgeDetails && !(metricsIn.length > 0 && metricsOut.length > 0);

  const inCounts = ciliumMetricsListToCounts(metricsIn);
  const outCounts = ciliumMetricsListToCounts(metricsOut);

  const sentCount = inCounts.forwardedSentCount + outCounts.forwardedSentCount;
  const dropsCount = inCounts.policyDropsCount + outCounts.policyDropsCount;

  const startingTime = Math.min(inCounts.startingTime, outCounts.startingTime);
  const endingTime = Math.max(inCounts.endingTime, outCounts.endingTime);
  const totalTime = endingTime - startingTime || 1;

  const { dataset, graphDataExists } = parseCiliumTimelineGraphData(metricsIn, metricsOut);

  return (
    <>
      {graphDataExists && (
        <>
          <DetailsTabStyles.SectionTitle>Packets</DetailsTabStyles.SectionTitle>

          <DetailsTabStyles.SubsectionTitle>
            Packet Requests ({(totalTime / 60).toFixed(0)}min)
          </DetailsTabStyles.SubsectionTitle>
          <DetailsTabStyles.SubsectionDetails>
            PPS: {(sentCount / totalTime).toFixed(2)}, % Dropped {(dropsCount / (sentCount + dropsCount)).toFixed(2)}
          </DetailsTabStyles.SubsectionDetails>

          <TimelineGraph
            fixedHeight={'200px'}
            fixedWidth={'375px'}
            dataset={dataset}
            legendData={oneWayEdge ? legendDataSingle : legendDataBoth}
          />
        </>
      )}
    </>
  );
};
