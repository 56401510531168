/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/security/jwt_policy.proto" (package "security.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { RouteReference } from "../../../common/v2/status_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { Duration } from "../../../../../../../../../google/protobuf/duration_pb";
import { DestinationReference } from "../../../common/v2/references_pb";
import { ObjectReference } from "../../../common/v2/references_pb";
import { UInt32Value } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { PrioritizedPhase } from "../../../common/v2/phase_pb";
import { DestinationSelector } from "../../../common/v2/selectors_pb";
import { RouteSelector } from "../../../common/v2/selectors_pb";
/**
 * Fill out the spec of the JWT policy to enable JWT authentication. You can apply JWT policies to routes and destinations.
 *
 * Multiple policies: If multiple JWT policies select the same route or destination, only the oldest policy applies.
 *
 * Multiple selectors: You can select routes and destinations together in the same policy.
 * Using both selectors might impact the other. For example, you might omit a route selector. If you also omit a destination selector, then the policy applies to all routes. If you use a destination selector, the policy applies only to those destinations and no longer to any routes.
 *
 * If different JWT policies having conflicting rules for the same routes and destinations, then access is blocked.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec
 */
export interface JWTPolicySpec {
    /**
     * Select the routes where the policy will be applied.
     * If empty (`{}`), the policy applies to all routes in the workspace.
     * If omitted and the policy also does not select any destinations, the policy applies to all routes in the workspace.
     * If omitted and the policy selects a destination, the policy does not apply to any routes.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.RouteSelector apply_to_routes = 1;
     */
    applyToRoutes: RouteSelector[];
    /**
     * Select the destinations where the policy will be applied. Only Kubernetes services are supported.
     * By default if omitted, the policy does not apply to any destinations.
     * If empty (`{}`), the policy applies to all destinations in the workspace.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationSelector apply_to_destinations = 3;
     */
    applyToDestinations: DestinationSelector[];
    /**
     * The details of the JWT policy to apply to the selected routes.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config config = 2;
     */
    config?: JWTPolicySpec_Config;
}
/**
 * Configure the details of the JWT policy, including the provider, phase, matching claims or scopes, and other settings.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config
 */
export interface JWTPolicySpec_Config {
    /**
     * Set up the provider for the JWT configuration.
     * You can have multiple providers in the same JWT policy, or create separate policies per provider.
     *
     * Successfully verified JWT payloads will be written to Envoy Dynamic Metadata in the format `{{ policy generated prefix }}.{{ provider }}`.
     * For more info, see the `payload_in_metadata` field
     * [here](https://www.envoyproxy.io/docs/envoy/latest/api-v3/extensions/filters/http/jwt_authn/v3/config.proto.html).
     *
     * Note: You can name the providers to help you map the provider when viewing logs to debug.
     * However, the provider name does not affect the policy's behavior and cannot be used by other resources to select the policy.
     *
     * @generated from protobuf field: map<string, security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider> providers = 1;
     */
    providers: {
        [key: string]: JWTPolicySpec_Config_Provider;
    };
    /**
     * Optional: Set when to apply the JWT filter in the request chain,
     * either before (`preAuthz`) or after (`postAuthz`) authorization to have access to the JWT token.
     * If no phase is specified, defaults to `preAuthz`.
     *
     * @generated from protobuf field: common.gloo.solo.io.PrioritizedPhase phase = 2;
     */
    phase?: PrioritizedPhase;
    /**
     * Deprecated: Set the `validationPolicy` field to `ALLOW_MISSING_OR_FAILED` instead of setting this field to "true".
     * Note that if `validationPolicy` is set to `ALLOW_MISSING_OR_FAILED` or `ALLOW_MISSING`, it takes precedence and this field is ignored.
     *
     * Allow requests to succeed even if JWT authentication is missing or fails.
     * For example, you might apply multiple policies to your routes so that requests can authenticate with either a
     * JWT or another method such as external auth. Set this value to "true"
     * to allow a failed JWT auth request to pass through to the other authentication
     * method. By default, this value is "false" so that requests that fail JWT
     * authentication then fail authorization immediately.
     *
     * @generated from protobuf field: bool allow_missing_or_failed = 3;
     */
    allowMissingOrFailed: boolean;
    /**
     * Optional: Decide whether to clear the route cache after the JWT filter. By clearing the route cache, the gateway recomputes route matching. This way, you can configure traffic rules after JWT auth, such as claim-based routing on cleared routes or direct response on cached routes.
     * Defaults to the `AUTO` option.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config.ClearRouteCache clear_route_cache = 4;
     */
    clearRouteCache: JWTPolicySpec_Config_ClearRouteCache;
    /**
     * Optional: A key-value list of claims to require for JWT authorization. The JWT must meet all of the claims to be allowed (logically AND'd together).
     * For each claim, you can specify values that must or must not be present.
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.JWTPolicySpec.Config.ClaimMatcher claims = 5;
     */
    claims: JWTPolicySpec_Config_ClaimMatcher[];
    /**
     * Optional: An unordered list of required JWT scopes. The JWT "scope" claim must have all of the listed scopes to be allowed (logically AND'd together).
     * Scopes typically come from an identity provider and are formatted similar to `"<product>:<permission>"` or `"is:<role>"`.
     * For more information, see the [IETF docs](https://datatracker.ietf.org/doc/html/rfc8693#name-scope-scopes-claim).
     *
     * For example, you might use this field to set `email` and `is:developer` as required scopes.
     * Then the scope claim in the JWT must have all of those required scopes, but could also have others.
     * JWTs with scopes such as `"scope":"email is:developer"` or `"scope":"email is:developer phone address"` would be allowed.
     * JWTs with only one of the required scopes, such as `"scope":"email address"` would not be allowed.
     *
     * If you want to set scopes that if present in the claim are not allowed (`notValues`),
     * or to allow a request to succeed with just one of many listed scopes (`email OR is:developer`),
     * use the claims field instead.
     * To skip scope validation, omit this value or leave the list empty.
     * Note that nested scopes (a scope with multiple sub scopes) are not supported at this time.
     *
     * @generated from protobuf field: repeated string required_scopes = 6;
     */
    requiredScopes: string[];
    /**
     * Optional: Configure how JWT validation works, with the flexibility to handle requests with missing or invalid JWTs.
     * By default, after applying JWT policy to a route, only requests that authenticate with a valid JWT succeed.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config.ValidationPolicy validation_policy = 7;
     */
    validationPolicy: JWTPolicySpec_Config_ValidationPolicy;
}
/**
 * Configure how to verify the details of a JWT, such as the issuer, source, audience, matching claims or scopes, and other settings.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider
 */
export interface JWTPolicySpec_Config_Provider {
    /**
     * Optional: The principal that issued the JWT, usually a URL or an email address.
     * If specified, the `iss` field in JWT token of the incoming request must match this field, or else the request is denied.
     * If omitted, the `iss` field in the JWT token is not checked.
     *
     * @generated from protobuf field: string issuer = 1;
     */
    issuer: string;
    /**
     * Optional: A list of intended audiences for this JWT token.
     * If specified, a JWT token containing any of these `aud` field values is accepted.
     * If omitted, the `aud` field in the JWT token is not checked.
     *
     * @generated from protobuf field: repeated string audiences = 2;
     */
    audiences: string[];
    /**
     * Optional: Where to extract the JWT from the HTTP request.
     * If no explicit token source location is specified, the following default locations are tried in order:
     *
     * First, the Authorization header using the `Bearer ` prefix, such as: `Authorization: Bearer <token>`.
     *
     * Second, the `access_token` query parameter, such as: `https://<url>/path?access_token=<token>`.
     *
     * Gloo can verify multiple JWTs for a request.
     * Each JWT is extracted from the location that is specified per provider, or from the default locations previously described.
     * Note that if a single request contains multiple sources,
     * such as both a header and query parameter, then all tokens found in the request must be valid for the request to be accepted.
     * Configured fields are case sensitive and are matched verbatim.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource token_source = 3;
     */
    tokenSource?: JWTPolicySpec_Config_Provider_TokenSource;
    /**
     * @generated from protobuf oneof: jwks_source
     */
    jwksSource: {
        oneofKind: "local";
        /**
         * Get the public keys to validate the JWT from a local source,
         * such as a Kubernetes secret or an inline, raw string JWKS.
         *
         * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS local = 4;
         */
        local: JWTPolicySpec_Config_Provider_LocalJWKS;
    } | {
        oneofKind: "remote";
        /**
         * Get the public keys from a remote JSON Web Key Set (JWKS) server.
         * This server must be accessible from your cluster.
         *
         * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.RemoteJWKS remote = 5;
         */
        remote: JWTPolicySpec_Config_Provider_RemoteJWKS;
    } | {
        oneofKind: undefined;
    };
    /**
     * Optional: Specify the claims from the JWT payload to copy to individual headers before forwarding the request to the upstream destination.
     * To copy the entire payload to a single header instead, use the OutputPayloadToHeader field.
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.ClaimsToHeader claims_to_headers = 6;
     */
    claimsToHeaders: JWTPolicySpec_Config_Provider_ClaimsToHeader[];
    /**
     * Optional: Keep the JWT in the request post verification.
     *
     * @generated from protobuf field: bool keep_token = 7;
     */
    keepToken: boolean;
    /**
     * Optional: Verify time constraints, such as `exp` and `npf`. Default is 60s.
     * For information about the value format, see the [Google protocol buffer documentation](https://protobuf.dev/reference/protobuf/google.protobuf/#u-int32-value).
     *
     * @generated from protobuf field: google.protobuf.UInt32Value clock_skew_seconds = 8;
     */
    clockSkewSeconds?: UInt32Value;
    /**
     * Optional: Copy the output of the JWT payload to a single header before forwarding the request to the upstream destination. The header is the name that you enter in this field.
     * The payload data is base64-encoded before forwarding.
     * If this field is omitted or empty, the payload is not forwarded with the request.
     * To send select claims in the payload in individual headers, use the ClaimsToHeaders field instead.
     *
     * @generated from protobuf field: string output_payload_to_header = 9;
     */
    outputPayloadToHeader: string;
    /**
     * Optional: Specify a custom delimiter to append claims. Defaults to a comma (`,`).
     *
     * @generated from protobuf field: string custom_delimiter = 10;
     */
    customDelimiter: string;
}
/**
 * Optional: Where to extract the JWT from the HTTP request.
 *
 * If omitted, defaults to the header `"Authorization: Bearer <token>"` or the query parameter `"access_token=<Token>"`.
 * Note that if a request has both the header and query parameter, both tokens must be valid for Gloo Gateway to accept the request.
 * Configured fields are case sensitive and are matched verbatim.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource
 */
export interface JWTPolicySpec_Config_Provider_TokenSource {
    /**
     * Try to retrieve the token from these headers.
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource.fromHeader headers = 1;
     */
    headers: JWTPolicySpec_Config_Provider_TokenSource_fromHeader[];
    /**
     * Try to retrieve the token from these query parameters.
     *
     * @generated from protobuf field: repeated string query_params = 2;
     */
    queryParams: string[];
}
/**
 * Describes how to retrieve the JWT from a header.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource.fromHeader
 */
export interface JWTPolicySpec_Config_Provider_TokenSource_fromHeader {
    /**
     * The name of header, such as `"Authorization"`.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The prefix before the token, such as `"Bearer "` (with a space before the token).
     *
     * @generated from protobuf field: string prefix = 2;
     */
    prefix: string;
}
/**
 * Get the public keys to validate the JWT from a local source,
 * such as a Kubernetes secret or inline configuration.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS
 */
export interface JWTPolicySpec_Config_Provider_LocalJWKS {
    /**
     * @generated from protobuf oneof: specifier
     */
    specifier: {
        oneofKind: "secretRef";
        /**
         * Refer to a secret that has the PEM-formatted public key.
         *
         * @generated from protobuf field: security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS.SecretRef secret_ref = 1;
         */
        secretRef: JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef;
    } | {
        oneofKind: "inline";
        /**
         * Provide an inline PEM-formatted public key in the configuration of this JWT policy.
         *
         * @generated from protobuf field: string inline = 2;
         */
        inline: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS.SecretRef
 */
export interface JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef {
    /**
     * Refer to the secret explicitly by the cluster and namespace where the secret is located.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference objectRef = 1;
     */
    objectRef?: ObjectReference;
    /**
     * Refer to the key of data that has the PEM-formatted public key within the specified secret.
     *
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
/**
 * Get the public keys from a remote JSON Web Key Set (JWKS) server.
 * This server must be accessible from your cluster.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.RemoteJWKS
 */
export interface JWTPolicySpec_Config_Provider_RemoteJWKS {
    /**
     * The URL to access the remote destination for JWKS server.
     * This value sets the host and path in the request.
     *
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * The destination that represents the JWKS server.
     * If the JWKS server runs in your cluster, the destination can be a Kubernetes Service or Gloo VirtualDestination.
     * If the JWKS server is a remote URL, the destination must be a Gloo ExternalService.
     *
     * @generated from protobuf field: common.gloo.solo.io.DestinationReference destination_ref = 2;
     */
    destinationRef?: DestinationReference;
    /**
     * Duration after which the cached JWKS expires.
     *
     * If omitted, defaults to 5 minutes.
     * For information about the value format, see the [Google protocol buffer documentation](https://protobuf.dev/reference/protobuf/google.protobuf/#duration).
     *
     * @generated from protobuf field: google.protobuf.Duration cache_duration = 3;
     */
    cacheDuration?: Duration;
    /**
     * Set the maximum duration in seconds that a response can take to arrive upon request.
     *
     * If omitted, defaults to 5 seconds.
     * For information about the value format, see the [Google protocol buffer documentation](https://protobuf.dev/reference/protobuf/google.protobuf/#duration).
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 4;
     */
    timeout?: Duration;
    /**
     * Fetch the JWKS asynchronously in the main thread before activating the listener.
     * Then, the fetched JWKS can be used by all worker threads.
     *
     * When set to false (default):
     * The JWKS is fetched on-demand when requests come. The first few requests are paused until the JWKS is fetched.
     * Because the JWKS cache is per worker thread, each worker thread fetches its own JWKS.
     * You might leave async fetching disabled in simple environments with minimal traffic or resources,
     * during testing to simplify debugging issues, or when you want more control over when and how the JWKS is fetched.
     *
     * When set to true:
     * The JWKS is fetched in the main thread before activating the listener.
     * Then, the JWKS can be used by all worker threads. Each worker thread doesn't need to fetch its own.
     * This way, the JWKS is ready when requests come.
     * You might enable async fetching to reduce latency, improve responsiveness, and have consistent JWKS across worker threads
     * such as in multi-threaded, concurrent, and large-scale environments.
     *
     * @generated from protobuf field: bool enable_async_fetch = 5;
     */
    enableAsyncFetch: boolean;
}
/**
 * Specify the claims from the JWT payload to copy to individual headers before forwarding the request to the upstream destination.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.ClaimsToHeader
 */
export interface JWTPolicySpec_Config_Provider_ClaimsToHeader {
    /**
     * Claim name, such as `"sub"`.
     *
     * @generated from protobuf field: string claim = 1;
     */
    claim: string;
    /**
     * The request header to copy the claim to, such as `"x-sub"`.
     *
     * @generated from protobuf field: string header = 2;
     */
    header: string;
    /**
     * If the header already exists, append this copied value to it (true), or overwrite the existing value (false).
     *
     * @generated from protobuf field: bool append = 3;
     */
    append: boolean;
}
/**
 * Optionally configure a list of key-value claims for JWT authorization rules. The JWT must meet all of the claims to be allowed (logically AND'd together).
 * For each claim, you can specify values that must or must not be present. If a claim has multiple supported values, any of these values is allowed (logically OR'd together).
 * You can also use wildcards, such as `"*"` to allow any value, or for example `"*@solo.io"` to allow any `@solo.io` email. Nested claims are not supported at this time.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.ClaimMatcher
 */
export interface JWTPolicySpec_Config_ClaimMatcher {
    /**
     * The name of the JWT claim's key.
     * [RFC 7519 spec](https://datatracker.ietf.org/doc/html/rfc7519#section-4.1) reserves seven claims, and the [IANA JSON Web Token Claims](https://www.iana.org/assignments/jwt/jwt.xhtml#claims) outline many more registered claims to encourage interoperability across providers. Further, your OIDC provider might have custom claims, such as described in the [Auth0 docs](https://auth0.com/docs/get-started/apis/scopes/sample-use-cases-scopes-and-claims).
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * Optional: A list of allowed values for the JWT claim. If a claim has multiple supported values, any of these values is allowed (logically OR'd together).
     * You can also use wildcards, such as `"*"` to allow any value or for example `"*@solo.io"` to allow any `@solo.io` email. Nested claims are not supported at this time.
     * Note: You must set at least one of "values" or "not_values".
     *
     * @generated from protobuf field: repeated string values = 2;
     */
    values: string[];
    /**
     * Optional: A list of values that are not allowed for the JWT claim. If a claim contains one of these values, the request is denied.
     * You can also use wildcards, such as `"*"` to deny any value, or for example `"*@solo.io"` to deny any `@solo.io` email.
     * Note: You must set at least one of "values" or "not_values".
     *
     * @generated from protobuf field: repeated string not_values = 3;
     */
    notValues: string[];
    /**
     * Optional: Specify a delimiter to enable nested claims, which are claims that are children of top-level claims. The delimiter is the character that separates the nested levels within the claims names of the JWT.
     * The delimiter is commonly set to a period ("."), which allows nested claim names of the form parent.child.grandchild. JWTs that match the value you set for the grandchild nested claim are allowed.
     *
     * @generated from protobuf field: string nested_claim_delimiter = 4;
     */
    nestedClaimDelimiter: string;
}
/**
 * Decide whether to clear the route cache after the JWT filter.
 *
 * @generated from protobuf enum security.policy.gloo.solo.io.JWTPolicySpec.Config.ClearRouteCache
 */
export enum JWTPolicySpec_Config_ClearRouteCache {
    /**
     * The default behavior, which clears the route cache when the auth response is successful or if the JWT policy adds a claim in the 'claimsToHeader' field. This way, the gateway recomputes route matching, such as to route traffic based on the claims you add in the JWT policy.
     *
     * @generated from protobuf enum value: AUTO = 0;
     */
    AUTO = 0,
    /**
     * Set to false so that the route cache is not cleared after the JWT filter. This way, you can configure traffic rules based on routing details from both before and after the JWT filter.
     * For example, you might want to reject with a direct response any requests that send a header that is also set in the JWT policy's 'claimsToHeader' field.
     *
     * @generated from protobuf enum value: FALSE = 1;
     */
    FALSE = 1,
    /**
     * Set to true so that the route cache is always cleared after the JWT filter. This way, the gateway recomputes route matching, such as to route traffic based on the claims you add in the JWT policy.
     *
     * @generated from protobuf enum value: TRUE = 2;
     */
    TRUE = 2
}
/**
 * @generated from protobuf enum security.policy.gloo.solo.io.JWTPolicySpec.Config.ValidationPolicy
 */
export enum JWTPolicySpec_Config_ValidationPolicy {
    /**
     * Default value. Allow only requests that authenticate with a valid JWT to succeed.
     * Note that the `allowMissingOrFailed=true` setting takes precedence. In such a case, even if you explicitly set `validationPolicy=REQUIRE_VALID`, this field is ignored.
     *
     * @generated from protobuf enum value: REQUIRE_VALID = 0;
     */
    REQUIRE_VALID = 0,
    /**
     * Allow requests to succeed even if JWT authentication is missing, but fail when an invalid JWT token is presented.
     * You might use this setting when later steps depend on input from the JWT.
     * For example, you might add claims from the JWT to request headers with the claimsToHeaders field.
     * As such, you may want to make sure that any provided JWT is valid. If not, the request fails,
     * which informs the requester that their JWT is not valid.
     * Requests without a JWT, however, still succeed and skip JWT validation.
     *
     * @generated from protobuf enum value: ALLOW_MISSING = 1;
     */
    ALLOW_MISSING = 1,
    /**
     * Allow requests to succeed even when a JWT is missing or JWT verification fails.
     * For example, you might apply multiple policies to your routes so that requests can authenticate with either a
     * JWT or another method such as external auth. Use this value
     * to allow a failed JWT auth request to pass through to the other authentication method.
     *
     * @generated from protobuf enum value: ALLOW_MISSING_OR_FAILED = 2;
     */
    ALLOW_MISSING_OR_FAILED = 2
}
/**
 * The status of the policy after it is applied to your Gloo environment.Status
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicyStatus
 */
export interface JWTPolicyStatus {
    /**
     * The state and workspace conditions of the applied resource.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of routes selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_routes = 2;
     */
    numSelectedRoutes: number;
    /**
     * The number of destination ports selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_destination_ports = 3;
     */
    numSelectedDestinationPorts: number;
}
/**
 * The report shows the resources that the policy selects after the policy is successfully applied.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.JWTPolicyReport
 */
export interface JWTPolicyReport {
    /**
     * The status of the resource in each workspace that it exists in.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of references to all routes selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.RouteReference selected_routes = 2;
     */
    selectedRoutes: RouteReference[];
    /**
     * A list of destination ports selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationReference selected_destination_ports = 3;
     */
    selectedDestinationPorts: DestinationReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec$Type extends MessageType<JWTPolicySpec> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec", [
            { no: 1, name: "apply_to_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteSelector },
            { no: 3, name: "apply_to_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationSelector },
            { no: 2, name: "config", kind: "message", T: () => JWTPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec
 */
export const JWTPolicySpec = new JWTPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config$Type extends MessageType<JWTPolicySpec_Config> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config", [
            { no: 1, name: "providers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => JWTPolicySpec_Config_Provider } },
            { no: 2, name: "phase", kind: "message", T: () => PrioritizedPhase },
            { no: 3, name: "allow_missing_or_failed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "clear_route_cache", kind: "enum", T: () => ["security.policy.gloo.solo.io.JWTPolicySpec.Config.ClearRouteCache", JWTPolicySpec_Config_ClearRouteCache] },
            { no: 5, name: "claims", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JWTPolicySpec_Config_ClaimMatcher },
            { no: 6, name: "required_scopes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "validation_policy", kind: "enum", T: () => ["security.policy.gloo.solo.io.JWTPolicySpec.Config.ValidationPolicy", JWTPolicySpec_Config_ValidationPolicy] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config
 */
export const JWTPolicySpec_Config = new JWTPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider$Type extends MessageType<JWTPolicySpec_Config_Provider> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider", [
            { no: 1, name: "issuer", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "audiences", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "token_source", kind: "message", T: () => JWTPolicySpec_Config_Provider_TokenSource },
            { no: 4, name: "local", kind: "message", oneof: "jwksSource", T: () => JWTPolicySpec_Config_Provider_LocalJWKS },
            { no: 5, name: "remote", kind: "message", oneof: "jwksSource", T: () => JWTPolicySpec_Config_Provider_RemoteJWKS },
            { no: 6, name: "claims_to_headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JWTPolicySpec_Config_Provider_ClaimsToHeader },
            { no: 7, name: "keep_token", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "clock_skew_seconds", kind: "message", T: () => UInt32Value },
            { no: 9, name: "output_payload_to_header", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "custom_delimiter", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider
 */
export const JWTPolicySpec_Config_Provider = new JWTPolicySpec_Config_Provider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider_TokenSource$Type extends MessageType<JWTPolicySpec_Config_Provider_TokenSource> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource", [
            { no: 1, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JWTPolicySpec_Config_Provider_TokenSource_fromHeader },
            { no: 2, name: "query_params", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource
 */
export const JWTPolicySpec_Config_Provider_TokenSource = new JWTPolicySpec_Config_Provider_TokenSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider_TokenSource_fromHeader$Type extends MessageType<JWTPolicySpec_Config_Provider_TokenSource_fromHeader> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource.fromHeader", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.TokenSource.fromHeader
 */
export const JWTPolicySpec_Config_Provider_TokenSource_fromHeader = new JWTPolicySpec_Config_Provider_TokenSource_fromHeader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider_LocalJWKS$Type extends MessageType<JWTPolicySpec_Config_Provider_LocalJWKS> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS", [
            { no: 1, name: "secret_ref", kind: "message", oneof: "specifier", T: () => JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef },
            { no: 2, name: "inline", kind: "scalar", oneof: "specifier", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS
 */
export const JWTPolicySpec_Config_Provider_LocalJWKS = new JWTPolicySpec_Config_Provider_LocalJWKS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef$Type extends MessageType<JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS.SecretRef", [
            { no: 1, name: "objectRef", kind: "message", T: () => ObjectReference },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.LocalJWKS.SecretRef
 */
export const JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef = new JWTPolicySpec_Config_Provider_LocalJWKS_SecretRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider_RemoteJWKS$Type extends MessageType<JWTPolicySpec_Config_Provider_RemoteJWKS> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.RemoteJWKS", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "destination_ref", kind: "message", T: () => DestinationReference },
            { no: 3, name: "cache_duration", kind: "message", T: () => Duration },
            { no: 4, name: "timeout", kind: "message", T: () => Duration },
            { no: 5, name: "enable_async_fetch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.RemoteJWKS
 */
export const JWTPolicySpec_Config_Provider_RemoteJWKS = new JWTPolicySpec_Config_Provider_RemoteJWKS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_Provider_ClaimsToHeader$Type extends MessageType<JWTPolicySpec_Config_Provider_ClaimsToHeader> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.ClaimsToHeader", [
            { no: 1, name: "claim", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "header", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "append", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.ClaimsToHeader
 */
export const JWTPolicySpec_Config_Provider_ClaimsToHeader = new JWTPolicySpec_Config_Provider_ClaimsToHeader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicySpec_Config_ClaimMatcher$Type extends MessageType<JWTPolicySpec_Config_ClaimMatcher> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicySpec.Config.ClaimMatcher", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "not_values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "nested_claim_delimiter", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicySpec.Config.ClaimMatcher
 */
export const JWTPolicySpec_Config_ClaimMatcher = new JWTPolicySpec_Config_ClaimMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicyStatus$Type extends MessageType<JWTPolicyStatus> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 2, name: "num_selected_routes", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "num_selected_destination_ports", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicyStatus
 */
export const JWTPolicyStatus = new JWTPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JWTPolicyReport$Type extends MessageType<JWTPolicyReport> {
    constructor() {
        super("security.policy.gloo.solo.io.JWTPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteReference },
            { no: 3, name: "selected_destination_ports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.JWTPolicyReport
 */
export const JWTPolicyReport = new JWTPolicyReport$Type();
