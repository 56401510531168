import { GetContainerLogsStreamRequest } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/logs_pb';
import { LogsApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/logs_pb.client';
import { Empty } from 'proto/google/protobuf/empty_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import { grpcWebFetchTransport, soloGrpcCall, useRequest } from './helpers';

export namespace logsApi {
  const client = new LogsApiClient(grpcWebFetchTransport);
  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.LogsApi/' + capitalizeFirstLetters(fn.name);
  //
  // Get Container Log Stream
  //
  export function getContainerLogsStream(
    clusterName: string,
    component: string,
    podName: string,
    containerName: string
  ) {
    const request: GetContainerLogsStreamRequest = { clusterName, component, podName, containerName };
    const responseStream = client.getContainerLogsStream(request);
    return responseStream.responses;
  }

  //
  // Get Available Component Info
  //
  export async function getAvailableComponentInfo() {
    const request: Empty = {};
    return soloGrpcCall(client.getAvailableComponentInfo(request));
  }
  export function useGetAvailableComponentInfo(...args: Parameters<typeof getAvailableComponentInfo>) {
    return useRequest(getAvailableComponentInfo, args, {
      methodDescriptorName: getMethodDescriptorName(client.getAvailableComponentInfo)
    });
  }
}
