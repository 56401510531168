import { Asset } from 'assets';
import { DataError } from 'Components/Common/DataError';
import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { SoloToggleSwitch } from 'Components/Common/Input/SoloToggleSwitch';
import { Loading } from 'Components/Common/Loading';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloModal } from 'Components/Common/SoloModal';
import { getTimeDisplayProps, TimeDisplay } from 'Components/Common/TimeDisplay';
import { isUIOnlyFeatureFlagOn, UIFEATUREFLAG_SEARCHHIGHLIGHTING } from 'Components/Features/Flags/UIOnlyFlags';
import * as Cy from 'cytoscape';
import cyCanvas from 'cytoscape-canvas';
import {
  EdgeMetrics,
  GetGraphResponse,
  NodeMetrics
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { Duration } from 'proto/google/protobuf/duration_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import React, { useEffect, useRef, useState } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { di } from 'react-magnetic-di';
import { useSearchParams } from 'react-router-dom';
import { graphStylesheet } from 'Styles/graph';
import { Permission, usePermissions } from 'utils/permissions';
import { NetworkGraphCanvas } from '../Canvas/NetworkGraphCanvas';
import { useGraphFetchSettingsContext } from '../Context/GraphFetchSettingsContext';
import { LayoutTypesEnum, useGraphUXSettingsContext } from '../Context/GraphUXSettingsContext';
import { DetailsTab } from '../General/DetailsTab/DetailsTab';
import { DetailsTabStyles } from '../General/DetailsTab/DetailsTab.style';
import { EdgeDetails } from '../General/DetailsTab/EdgeDetails';
import { NodeDetails } from '../General/DetailsTab/NodeDetails';
import { EmptyGraph } from '../General/EmptyGraph';
import { BoxTypeDisplay, EdgeLabelType, getUsedData } from '../General/get-graph-items-data';
import { setGraphCache, useGetGraphCache } from '../General/graph-cache';
import {
  CLUSTER_QUERY_PARAM,
  GraphFiltersType,
  hasEnoughFilters,
  NAMESPACE_QUERY_PARAM,
  useGraphWorkspacesUsable,
  WORKSPACE_QUERY_PARAM
} from '../General/graph-filter-utils';
import {
  getEdgeIdFromData,
  graphIsNodeHovered,
  graphOnMouseIn,
  graphOnMouseOut,
  isEdge,
  isGraphCore,
  isNode,
  resetGraph,
  selectElements,
  unselectAll
} from '../General/graph-selection-utils';
import {
  ContainerNodeType,
  EdgeType,
  GraphContainerType,
  GraphStatus,
  NodePositions,
  UINodeType
} from '../General/graph-types';
import { getUsedPositioning } from '../General/setup-graph-node-positions';
import { UsageInfoTab } from '../General/UsageInfoTab/UsageInfoTab';
import { UsageInfoTopicEnum } from '../General/UsageInfoTab/UsageInfoTab.style';
import { NetworkGraphContainerStyles } from './NetworkGraphContainer.style';

// @ts-ignore - rerunning cyCanvas (on file save) causes an error, so we only do it once
if (!Cy.default().cyCanvas) {
  cyCanvas(Cy.default); // Register extension
}

declare global {
  interface Window {
    cySetPan: (e: { x: number; y: number }) => void;
    cySetZoom: (e: number) => void;
  }
}

export interface FetchSettings {
  pullTime: Date;
  timeInterval: number;
  refreshRate: number;

  endTime: Timestamp;
  window: Duration;
  step: Duration;
  istioMetrics: boolean;
  ciliumMetrics: boolean;
  tcpMetrics: boolean;
}

enum RenderedOnceState {
  NotStarted = 0,
  Waiting,
  Finished
}

enum TabState {
  Open,
  Closed,
  LockedClosed // they manually closed it, leave it be
}

function getCyClassList(item: Cy.SingularElementArgument): string[] {
  return item.classes() as unknown as string[];
}

const getListOfNodesAndEdges = (graphData?: GetGraphResponse): string => {
  if (!graphData) {
    return 'NONE';
  }

  const nodeList = graphData.nodeMetrics.map(node => node.workload?.id).sort();
  const edgeList = graphData.edgeMetrics
    .map(eMet => getEdgeIdFromData(eMet.sourceWorkload?.id, eMet.targetWorkload?.id))
    .sort();

  return nodeList.join('') + edgeList.join('');
};

function isMetricsTypeSelectable(metricsType: any) {
  return true;
}

export const NetworkGraphContainer = ({
  graphData,
  filters,
  doPauseRefresh,
  graphDataError,
  isLoading
}: {
  graphData?: GetGraphResponse;
  filters: GraphFiltersType;
  doPauseRefresh: (on: boolean) => any;
  graphDataError?: any;
  isLoading: boolean;
}) => {
  di(useFullScreenHandle, getUsedData, hasEnoughFilters);

  const { hasPerm } = usePermissions();
  //Permissions can change the environment we provide
  useEffect(() => {
    if (!hasPerm(Permission.Workspace)) {
      toggleGroupBy('infra');
    }
  }, [hasPerm]);

  const [, setSearchParams] = useSearchParams();
  const workspacesUsable = useGraphWorkspacesUsable();
  const { cache, updateCache } = useGetGraphCache();
  const { animationsOn, mTLSOn, idlesOn, kubernetesServicesDisplayed, externalServicesDisplayed, gatewaysDisplayed } =
    cache;
  let { infraOn, workspacesOn } = cache;
  // This is a little different from the permission check above, because
  //   this isn't just about the permission, but also the workspaces available.
  // It is also not affecting the cache, but only temporarily during runtime.
  if (!workspacesUsable) {
    infraOn = true;
    workspacesOn = false;
  }

  const { pullTime, range } = useGraphFetchSettingsContext();
  const {
    layoutType,
    setLayoutType,
    onlyErrorsShown,
    setOnlyErrorsShown,
    usageInfoTopic,
    toggleLayoutSettings,
    toggleLegend,
    setUsageInfoTopic
  } = useGraphUXSettingsContext();

  const fullscreenHandle = useFullScreenHandle();
  const [detailsTabState, setDetailsTabState] = useState<TabState>(TabState.Closed);
  const [selectedNode, setSelectedNode] = useState<NodeMetrics>();
  const [selectedEdge, setSelectedEdge] = useState<EdgeMetrics>();
  const [selectedEdgeReversed, setSelectedEdgeReversed] = useState<EdgeMetrics>();
  const [detailsTabDoubleEdgeView, setDetailsTabDoubleEdgeView] = useState(false);

  const [graphApiErrorOpen, setGraphApiErrorOpen] = useState(false);

  const manuallyMovedNodePositions = useRef<Record<string, { x: number; y: number }>>(
    workspacesOn ? cache.manuallyMovedWorkspaceOrientedNodePositions : cache.manuallyMovedClusterOrientedNodePositions
  );
  const fitToScreenNeeded = useRef<boolean>(true);
  const lastNodeMousePosition = useRef<Cy.Position>();

  const [cyRef, setCyRef] = useState<Cy.Core>();
  const [graphedNodePositions, setGraphedNodePositions] = useState<NodePositions>({});
  const [graphedNodes, setGraphedNodes] = useState<(UINodeType | ContainerNodeType)[]>([]);
  const [graphedEdges, setGraphedEdges] = useState<EdgeType[]>([]);
  const [graphRenderedOnce, setGraphRenderedOnce] = useState<RenderedOnceState>(RenderedOnceState.NotStarted);

  // Initial graph data setup
  useEffect(() => {
    getUsedPositioning(
      manuallyMovedNodePositions.current,
      layoutType,
      workspacesOn,
      setGraphedNodePositions,
      graphData
    );

    const { nodesList, edgesList } = getUsedData(
      {
        namespaces: filters.namespaces ?? [],
        clusters: filters.clusters ?? [],
        workspaces: filters.workspaces,
        instances: [],
        subnets: [],
        vpcs: []
      },
      EdgeLabelType.errorRate,
      layoutType,
      workspacesOn ? BoxTypeDisplay.Workspaces : BoxTypeDisplay.ClusterWithNamespaces,
      animationsOn,
      idlesOn,
      kubernetesServicesDisplayed,
      externalServicesDisplayed,
      gatewaysDisplayed,
      filters.findName,
      filters.hideName,
      graphData
    );

    setGraphedNodes([
      ...nodesList.UINodes,
      ...nodesList.VPCNodes,
      ...nodesList.clusterNodes,
      ...nodesList.instanceNodes,
      ...nodesList.namespaceNodes,
      ...nodesList.subnetNodes,
      ...nodesList.workspaceNodes
    ]);
    setGraphedEdges(
      edgesList.filter(
        edge =>
          nodesList.UINodes.some(node => node.data.id === edge.data.source) &&
          nodesList.UINodes.some(node => node.data.id === edge.data.target)
      )
    );

    return () => {
      if (!!cyRef) {
        cyRef.destroy();
      }
    };
  }, []);

  // Clear selected nodes/edges when removed on update
  useEffect(() => {
    if (selectedNode && graphData?.nodeMetrics) {
      const nodeStillExists = graphData.nodeMetrics.some(gNode => selectedNode.workload?.id === gNode.workload?.id);

      if (!nodeStillExists) {
        setSelectedNode(undefined);
      }
    } else {
      setSelectedNode(undefined);
    }

    if (selectedEdge && graphData?.edgeMetrics) {
      const edgeStillExists = graphData.edgeMetrics.some(
        gEdge =>
          selectedEdge.sourceWorkload?.id === gEdge.sourceWorkload?.id &&
          selectedEdge.targetWorkload?.id === gEdge.targetWorkload?.id
      );

      if (!edgeStillExists) {
        setSelectedEdge(undefined);
        setSelectedEdgeReversed(undefined);
        setDetailsTabDoubleEdgeView(false);
      }
    } else {
      setSelectedEdge(undefined);
      setSelectedEdgeReversed(undefined);
      setDetailsTabDoubleEdgeView(false);
    }
  }, [graphData]);

  // Update automatic node positions
  useEffect(() => {
    const positioningNodes = getUsedPositioning(
      manuallyMovedNodePositions.current,
      layoutType,
      workspacesOn,
      setGraphedNodePositions,
      graphData
    );

    if (cyRef && Object.keys(graphedNodePositions).length === 0 && Object.keys(positioningNodes).length > 0) {
      centerView();
    }
  }, [getListOfNodesAndEdges(graphData), manuallyMovedNodePositions.current, layoutType]);

  // Auto close details tab if nothing is selected
  useEffect(() => {
    if (!!selectedNode && !!selectedEdge) {
      setDetailsTabState(TabState.Closed);
    }
  }, [selectedNode, selectedEdge]);

  // Update graph data
  useEffect(() => {
    const { edgesList, nodesList } = getUsedData(
      {
        namespaces: filters.namespaces ?? [],
        clusters: filters.clusters ?? [],
        workspaces: filters.workspaces,
        instances: [],
        subnets: [],
        vpcs: []
      },
      EdgeLabelType.errorRate,
      layoutType,
      workspacesOn ? BoxTypeDisplay.Workspaces : BoxTypeDisplay.ClusterWithNamespaces,
      animationsOn,
      idlesOn,
      kubernetesServicesDisplayed,
      externalServicesDisplayed,
      gatewaysDisplayed,
      filters.findName,
      filters.hideName,
      graphData
    );

    // Check length here to prevent cache values being overwritten before graph loads
    if (nodesList.UINodes.length > 0) {
      let stillExistingManuallyMovedNodePositions: typeof manuallyMovedNodePositions.current = {};
      Object.keys(manuallyMovedNodePositions.current).forEach(key => {
        if (nodesList.UINodes.some(node => node.data.id === key)) {
          stillExistingManuallyMovedNodePositions[key] = manuallyMovedNodePositions.current[key];
        }
      });
      manuallyMovedNodePositions.current = stillExistingManuallyMovedNodePositions;
    }

    setGraphedNodes([
      ...nodesList.UINodes,
      ...nodesList.VPCNodes,
      ...nodesList.clusterNodes,
      ...nodesList.instanceNodes,
      ...nodesList.namespaceNodes,
      ...nodesList.subnetNodes,
      ...nodesList.workspaceNodes
    ]);
    setGraphedEdges(
      edgesList.filter(
        edge =>
          nodesList.UINodes.some(node => node.data.id === edge.data.source) &&
          nodesList.UINodes.some(node => node.data.id === edge.data.target)
      )
    );
  }, [
    filters,
    graphData,
    infraOn,
    workspacesOn,
    animationsOn,
    idlesOn,
    kubernetesServicesDisplayed,
    externalServicesDisplayed,
    gatewaysDisplayed,
    layoutType
  ]);

  // REFRESH GRAPH ON RELEVANT CHANGES
  useEffect(() => {
    if (!!cyRef && Object.keys(graphedNodePositions).length === graphedNodes.length) {
      /* This forces the redraw of all elements. Otherwise, the CytoscapeComponent
    tries to be too clever and keep already known elements in their old
    positions. Via the maintainer of cytoscapejs, this is one of the preferred
    ways of doing this: https://stackoverflow.com/questions/35770055/replace-all-elements-and-redraw-graph-softly-in-cytoscape-js
    The .json() approach was causing an issue about a null renderer, which
    I believe was because there is a small competition between the CytoscapeComponent
    and cytoscapejs's .json in order of operations on updates.

    This is slightly slower than other alternatives I believe. But it seems to consistently work.

    Everything after ...g<Item> in the objects is just cytoscape cruft. Some may be removable, but some parts
      are more important than they may seem at a glance, so have a care.*/

      // Get previous selections
      const oldNodes = cyRef.nodes();
      const oldEdges = cyRef.edges();

      const eles: Cy.CollectionArgument = {
        //@ts-ignore
        edges: graphedEdges
          .filter(gEdge => gEdge.label !== 'false' || layoutType === LayoutTypesEnum.Preset)
          .map(gEdge => {
            const oldEdge = oldEdges.getElementById(gEdge.data.id);
            const oldClassList = !oldEdge.empty() ? getCyClassList(oldEdge) : [];

            // matches both 'selected' and 'unselected'
            const selectedClasses = oldClassList.filter(cls => cls.includes('selected'));

            let newClasses = gEdge.classes?.split(' ') ?? [];

            if (!mTLSOn) {
              // Strip out mention of mtls
              newClasses = newClasses.filter(
                className => !className.includes('mtlsEnabledEdge') && !className.includes('mtlsDisabledEdge')
              );
            } else {
              // A workaround to fix https://github.com/solo-io/gloo-mesh-enterprise/issues/4874
              // If the edge previously had a defined mTLS status and wasn't idle but then goes
              // idle and loses mTLS information, then we still want to show what is known about mTLS
              if (gEdge.data.health === GraphStatus.Idle && oldClassList.includes('mtlsEnabledEdge')) {
                newClasses = [...newClasses, 'mtlsEnabledEdge'];
              }
              if (gEdge.data.health === GraphStatus.Idle && oldClassList.includes('mtlsDisabledEdge')) {
                newClasses = [...newClasses, 'mtlsDisabledEdge'];
              }
            }

            const classes = [...newClasses, ...selectedClasses].filter(cls => !!cls || cls !== 'undefined');
            if (onlyErrorsShown) {
              classes.push('onlyErrorsHighlight');
            }

            // @ts-ignore - some data is stored to the  edge during runtime
            // this makes sure it is carried over to any new edge
            gEdge.data['anim-ant-distance'] = oldEdge.data('anim-ant-distance');

            return {
              ...gEdge,
              classes: classes.join(' '),
              group: 'edges',
              removed: false,
              selected: selectedClasses.includes(' selected'),
              selectable: true,
              grabbable: false,
              pannable: false,
              position: { x: 0, y: 0 }
            };
          }),
        //@ts-ignore
        nodes: graphedNodes
          .filter(
            gNode =>
              layoutType === LayoutTypesEnum.Preset ||
              layoutType === LayoutTypesEnum.Dot ||
              !(gNode as ContainerNodeType).data.containerType
          )
          .map(gNode => {
            const oldNode = oldNodes.getElementById(gNode.data.id);
            const oldClassList = !oldNode.empty() ? getCyClassList(oldNode) : [];

            // matches both 'selected' and 'unselected'
            const selectedClasses = oldClassList.filter(cls => cls.includes('selected'));

            const newClasses = gNode.classes?.split(' ') ?? [];
            const classes = [...newClasses, ...selectedClasses].filter(cls => !!cls || cls !== 'undefined');
            // Check if node is still being hovered before copying over the hover class.
            // This is needed in the situation where the node is move programmatically or UI opens on top
            // of it, both of which prevent `mouseout` from being fired
            if (
              oldClassList.includes('hovered') &&
              lastNodeMousePosition.current &&
              graphIsNodeHovered(oldNode, lastNodeMousePosition.current)
            ) {
              classes.push('hovered');
            }

            if (onlyErrorsShown) {
              classes.push('onlyErrorsHighlight');
            }

            let data: any = gNode.data;
            if (!!graphedNodePositions[gNode.data.id]) {
              data = {
                ...data,
                ...graphedNodePositions[gNode.data.id]
              };
            }

            return {
              ...gNode,
              data,
              position: graphedNodePositions[gNode.data.id].position,
              classes: classes.join(' '),
              group: 'nodes',
              removed: false,
              // selected: selectedClasses.includes('selected'), // seems to prevent moving the node
              selectable: true,
              locked: layoutType === LayoutTypesEnum.Dot // lock nodes when in dot mode for testing purposes
            };
          })
      };

      // Clear *everything* then full redraw, but keep selections from before.
      cyRef.elements().remove();
      cyRef.add(eles);

      runLayout();

      if (fitToScreenNeeded.current && graphedNodes.length > 0) {
        fitToScreenNeeded.current = false;
        centerView();
      }

      if (graphRenderedOnce === RenderedOnceState.NotStarted && graphedNodes.length > 0) {
        // if a state update happens before center actually moves anything, the move doesn't happen.
        // this solution may not be the best, but mostly works, and a better solution doesn't seem to be
        // available as there is no way to know when panBy has actually completed the dom update.

        // This tells us when the graph has finished its first render, so we can hide the 2ndary loader
        // and also does a quick adjust to bring everything into focus
        setGraphRenderedOnce(RenderedOnceState.Waiting);
        setTimeout(() => {
          centerView();
          // safety timeout to make sure graph centering/scale is properly rendered
          setTimeout(() => {
            setGraphRenderedOnce(RenderedOnceState.Finished);
          }, 75);
        }, 250);
      }
    }
  }, [graphedNodePositions, graphedNodes, graphedEdges, mTLSOn, onlyErrorsShown, layoutType]);

  // Setup graph event listeners
  useEffect(() => {
    if (!!cyRef) {
      cyRef.on('mouseover', (event: Cy.EventObject) => {
        const container = event.cy.container();
        if (container) {
          // Disable pointers for nodes that aren't selectable
          if (isNode(event.target) && !isMetricsTypeSelectable((event.target as Cy.NodeSingular).data('metricsType'))) {
            container.style.cursor = 'default';
          } else {
            container.style.cursor = 'pointer';
          }
        }
      });

      cyRef.on('mouseout', (event: Cy.EventObject) => {
        const container = event.cy.container();
        if (container) {
          container.style.cursor = 'default';
        }
      });
      /***
          HOVER OVER NODE LISTENERS
        ***/
      cyRef.on('mouseover', 'node', (evt: Cy.EventObject) => {
        graphOnMouseIn(evt.target);
      });

      cyRef.on('mouseout', 'node', (evt: Cy.EventObject) => {
        graphOnMouseOut(evt.target);
        lastNodeMousePosition.current = undefined;
      });
      cyRef.on('mousemove', 'node', (evt: Cy.EventObject) => {
        lastNodeMousePosition.current = evt.position;
      });

      const updateManuallyMovedNodePositionsRef = (nodes: Cy.NodeSingular[]) => {
        manuallyMovedNodePositions.current = {
          ...manuallyMovedNodePositions.current,
          ...Object.fromEntries(nodes.map(n => [n.id(), { ...n.position() }]))
        };
      };

      /***
          MOVING NODE LISTENERS
        ***/
      if (layoutType === LayoutTypesEnum.Preset) {
        if (isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING)) {
          cyRef.on('cxttap', 'node', evt => {
            const target = evt.target as Cy.NodeSingular;

            if (!!target.data('containerType') && !!target.hasClass('selected')) {
              if (target.data('containerType') === GraphContainerType.Workspace) {
                setSearchParams({
                  [WORKSPACE_QUERY_PARAM]: target.data('workspace')
                });
              } else if (target.data('containerType') === GraphContainerType.Namespace) {
                const clusterName = target.data('cluster');
                const namespace = target.data('namespace');
                setSearchParams({
                  [NAMESPACE_QUERY_PARAM]: namespace,
                  [CLUSTER_QUERY_PARAM]: clusterName
                });
              } else if (target.data('containerType') === GraphContainerType.Cluster) {
                setSearchParams({
                  [CLUSTER_QUERY_PARAM]: target.data('id')
                });
              }
            }
          });
        }

        cyRef.on('grabon', 'node', evt => {
          const target = evt.target as Cy.NodeSingular;

          target.predecessors().difference(target).ungrabify();
          target.successors().difference(target).ungrabify();
          target.ancestors().difference(target).ungrabify();

          doPauseRefresh(true);

          changeSelectedEdge('');
          unselectAll(cyRef);

          let selectedNodesIds: string[] = [],
            allSelectedItems: Cy.CollectionReturnValue;
          if (!target.data('containerType')) {
            const containers = cyRef.elements('[containerType]');
            const predecessors = target.predecessors().difference(target).difference(containers);

            // We don't currently support selecting certain types of nodes
            if (isMetricsTypeSelectable(target.data('metricsType'))) {
              // Here we are still using the clicked node and the predecessors sep.
              //  This is to make sure the front of the array is the clicked node, so we can treat it higher.
              //  The order returned otherwise does not seem to have a useful order.
              selectedNodesIds = [
                target.data('id'),
                ...predecessors
                  .difference(target)
                  .nodes()
                  .map(node => node.data('id'))
              ];
            }

            allSelectedItems = predecessors.add(target).add(target.ancestors().difference(target));
          } else {
            const children = target.difference(target).descendants();
            selectedNodesIds = children.nodes().map(node => node.data('id'));
            allSelectedItems = children.add(target).add(target.ancestors().difference(target));
          }

          changeSelectedNodes(selectedNodesIds);
          selectElements([allSelectedItems.nodes(), allSelectedItems.edges()]);
        });
        cyRef.on('drag', 'node', evt => {
          const target = evt.target as Cy.NodeSingular;

          if (!target.data('containerType')) {
            updateManuallyMovedNodePositionsRef([target]);
          } else {
            let movedNodes = target
              .descendants()
              .difference(target)
              .filter(node => !node.data('containerType'))
              .nodes();

            updateManuallyMovedNodePositionsRef(movedNodes.toArray());
          }
        });
        cyRef.on('freeon', 'node', evt => {
          const target = evt.target as Cy.NodeSingular;

          doPauseRefresh(false);

          target.predecessors().difference(target).grabify();
          target.successors().difference(target).grabify();
          target.ancestors().difference(target).grabify();
          if (!!target.data('containerType')) {
            updateManuallyMovedNodePositionsRef([target]);
          } else {
            let movedNodes = target
              .descendants()
              .difference(target)
              .filter(node => !node.data('containerType'))
              .nodes();

            updateManuallyMovedNodePositionsRef(movedNodes.toArray());
          }

          getUsedPositioning(
            manuallyMovedNodePositions.current,
            layoutType,
            workspacesOn,
            setGraphedNodePositions,
            graphData
          );
        });
      }

      /***
        CLICK LISTENER
      ***/
      cyRef.removeListener('tap');
      cyRef.on('tap', (evt: Cy.EventObject) => {
        if (isGraphCore(evt.target)) {
          resetGraph(cyRef);
          changeSelectedNodes([]);
          changeSelectedEdge('');
        } else if (isEdge(evt.target)) {
          const target = evt.target as Cy.EdgeSingular;
          unselectAll(cyRef);
          changeSelectedNodes([]);

          // We don't currently support clicking certain types of edges
          if (!isMetricsTypeSelectable(target.data('metricsType'))) {
            changeSelectedEdge('');
          } else {
            // @ts-ignore
            changeSelectedEdge(target.edges().data('id'));
          }

          selectElements([target.edges(), target.sources(), target.targets()]);
        } else if (isNode(evt.target)) {
          const target = evt.target as Cy.NodeSingular;
          changeSelectedEdge('');
          unselectAll(cyRef);

          if (target.data('containerType')) {
            const children = target.descendants().difference(target);
            const allItems = children.add(target).add(target.ancestors().difference(target));

            changeSelectedNodes(
              children
                .nodes()
                .filter(node => isMetricsTypeSelectable(node.data('metricsType')))
                .map(node => node.data('id'))
            );

            selectElements([allItems]);
          } else {
            const containers = cyRef.elements('[containerType]');

            const predecessors = target.predecessors().difference(containers);
            const allItems = predecessors.add(target).add(target.difference(target));

            // We don't currently support clicking certain types of nodes
            if (!isMetricsTypeSelectable(target.data('metricsType'))) {
              changeSelectedNodes([]);
            } else {
              // Here we are still using the clicked node and the predecessors sep.
              //  This is to make sure the front of the array is the clicked node, so we can treat it higher.
              //  The order returned otherwise does not seem to have a useful order.
              changeSelectedNodes([
                target.data('id'),
                ...predecessors
                  .difference(target)
                  .nodes()
                  .map(node => node.data('id'))
              ]);
            }

            selectElements([allItems.nodes(), allItems.edges()]);
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('nothing');
        }
      });
    }

    return () => {
      if (cyRef) {
        cyRef.removeListener('mouseover');
        cyRef.removeListener('mouseout');
        cyRef.removeListener('grabon');
        cyRef.removeListener('drag');
        cyRef.removeListener('freeon');
      }
    };
  }, [cyRef, graphedNodePositions, graphedNodes, graphedEdges, layoutType]);

  // update graph cache
  useEffect(() => {
    // use `setGraphCache` instead of `updateCache` to avoid re-render
    setGraphCache({
      manuallyMovedWorkspaceOrientedNodePositions: workspacesOn
        ? manuallyMovedNodePositions.current
        : cache.manuallyMovedWorkspaceOrientedNodePositions,
      manuallyMovedClusterOrientedNodePositions: infraOn
        ? manuallyMovedNodePositions.current
        : cache.manuallyMovedClusterOrientedNodePositions
    });
  }, [manuallyMovedNodePositions.current, layoutType]);

  const runLayout = () => {
    if (cyRef) {
      if (layoutType === LayoutTypesEnum.Preset) {
        cyRef.layout({ name: 'preset', fit: false }).run();
      }
    }
  };

  useEffect(() => {
    runLayout();
  }, [layoutType]);

  const changeSelectedNodes = (nodeIds: string[]) => {
    const nodes = graphData
      ? nodeIds.map(nodeId => graphData.nodeMetrics.find(node => node.workload?.id === nodeId)!).filter(n => !!n)
      : [];

    if (!!nodes.length) {
      setSelectedNode(nodes[0]);
      setDetailsTabState(TabState.Open);
    } else {
      setSelectedNode(undefined);
      setDetailsTabState(TabState.Closed);
    }
  };

  const changeSelectedEdge = (edgeId: string) => {
    const edge = graphData?.edgeMetrics.find(
      edge => getEdgeIdFromData(edge.sourceWorkload?.id, edge.targetWorkload?.id) === edgeId
    );
    const edgeReversed =
      edge &&
      graphData?.edgeMetrics.find(
        pEdge =>
          getEdgeIdFromData(pEdge.sourceWorkload?.id, pEdge.targetWorkload?.id) ===
          getEdgeIdFromData(edge.targetWorkload?.id, edge.sourceWorkload?.id)
      );

    setSelectedEdge(edge);
    setSelectedEdgeReversed(edgeReversed);
    setDetailsTabDoubleEdgeView(false);
    if (!!edge) {
      setDetailsTabState(TabState.Open);
    } else {
      setDetailsTabState(TabState.Closed);
    }
  };

  const forgetManualPlacements = () => {
    manuallyMovedNodePositions.current = {};
    updateCache(
      infraOn ? { manuallyMovedClusterOrientedNodePositions: {} } : { manuallyMovedWorkspaceOrientedNodePositions: {} }
    );

    getUsedPositioning(
      manuallyMovedNodePositions.current,
      layoutType,
      workspacesOn,
      setGraphedNodePositions,
      graphData
    );
  };

  const toggleFullscreen = () => {
    if (fullscreenHandle.active) {
      fullscreenHandle.exit();
    } else {
      fullscreenHandle.enter();
    }
  };
  const zoomIn = () => {
    if (cyRef) {
      cyRef.zoom(cyRef.zoom() + 0.075);
    }
  };
  const zoomOut = () => {
    if (cyRef) {
      cyRef.zoom(cyRef.zoom() - 0.075);
    }
  };
  const goUp = () => {
    if (cyRef) {
      cyRef.panBy({ x: 0, y: 30 });
    }
  };
  const goLeft = () => {
    if (cyRef) {
      cyRef.panBy({ x: 30, y: 0 });
    }
  };
  const goRight = () => {
    if (cyRef) {
      cyRef.panBy({ x: -30, y: 0 });
    }
  };
  const goDown = () => {
    if (cyRef) {
      cyRef.panBy({ x: 0, y: -30 });
    }
  };
  const centerView = () => {
    if (cyRef) {
      cyRef.fit(undefined, (graphData?.nodeMetrics.length ?? 0) < 3 ? 50 : 80);
      cyRef.center();
    }
  };
  useEffect(() => {
    window.cySetZoom = (e: number) => {
      if (cyRef) {
        cyRef.zoom(e);
      }
    };
    window.cySetPan = (e: { x: number; y: number }) => {
      if (cyRef) {
        cyRef.pan(e);
      }
    };
  }, []);

  const evaluateManuallyPlacedPositions = () => {
    // Check length here to prevent cache values being overwritten before graph loads
    if (graphedNodes.length) {
      let stillExistingManuallyMovedNodePositions: typeof manuallyMovedNodePositions.current = {};
      Object.keys(manuallyMovedNodePositions.current).forEach(key => {
        if (graphedNodes.some(node => node.data.id === key)) {
          stillExistingManuallyMovedNodePositions[key] = manuallyMovedNodePositions.current[key];
        }
      });
      manuallyMovedNodePositions.current = stillExistingManuallyMovedNodePositions;
    }
  };

  // switch node positions based on display type
  const toggleGroupBy = (groupBy: 'workspace' | 'infra') => {
    manuallyMovedNodePositions.current =
      groupBy === 'workspace'
        ? cache.manuallyMovedWorkspaceOrientedNodePositions
        : cache.manuallyMovedClusterOrientedNodePositions;
    evaluateManuallyPlacedPositions();

    fitToScreenNeeded.current = true;

    updateCache({ infraOn: groupBy === 'infra', workspacesOn: groupBy === 'workspace' });
  };

  // Auto close error modal if error is resolved
  useEffect(() => {
    if (graphApiErrorOpen && !graphDataError) {
      setGraphApiErrorOpen(false);
    }
  }, [graphApiErrorOpen, graphDataError]);

  // SWITCH LAYOUT USED
  const usePresetLayout = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    manuallyMovedNodePositions.current = {};
    setLayoutType(LayoutTypesEnum.Preset);
    fitToScreenNeeded.current = true;
  };

  const useDotLayout = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    setLayoutType(LayoutTypesEnum.Dot);
    fitToScreenNeeded.current = true;
  };

  const controlsEnabled = graphRenderedOnce === RenderedOnceState.Finished && hasEnoughFilters(filters);

  return (
    <NetworkGraphContainerStyles.ContainerWrapper>
      <NetworkGraphContainerStyles.InjectedHeaderActions>
        <SoloToggleSwitch checked={onlyErrorsShown} onChange={setOnlyErrorsShown} label={'Errors Only'} dark={true} />
      </NetworkGraphContainerStyles.InjectedHeaderActions>
      <FullScreen handle={fullscreenHandle}>
        <NetworkGraphContainerStyles.SizeLimiter className='sizeLimiter'>
          <NetworkGraphContainerStyles.InnerContainer className='innerContainer'>
            <NetworkGraphContainerStyles.GraphDataWrapper data-testid='graph-screenshot-container'>
              <div style={{ background: 'white', height: '100%' }}>
                <NetworkGraphContainerStyles.GraphHolder>
                  {
                    // @ts-ignore
                    <CytoscapeComponent
                      elements={CytoscapeComponent.normalizeElements({
                        nodes: graphedNodes,
                        edges: graphedEdges
                      })}
                      style={{
                        opacity: !!hasEnoughFilters(filters) ? '1' : '0',
                        // - 50px is to prevent bottom toolbar from overlapping canvas
                        // which can throw off canvas content being properly centered
                        ...(fullscreenHandle.active
                          ? { height: 'calc(100vh - 50px)' }
                          : {
                              width: '100%',
                              height: graphData ? 'calc(100vh - 330px - 50px)' : '0'
                            })
                      }}
                      stylesheet={graphStylesheet}
                      panningEnabled={true}
                      cy={cy => setCyRef(cy)}
                    />
                  }
                  {hasEnoughFilters(filters) &&
                    (graphData ? (
                      <>
                        {cyRef && <NetworkGraphCanvas cyRef={cyRef} animationOff={!animationsOn} />}

                        {graphRenderedOnce < RenderedOnceState.Finished && (
                          <NetworkGraphContainerStyles.GraphLoadingHolder
                            className='graph-loading-holder'
                            data-testid='graph-loading-holder'>
                            <Loading message={'Rendering data for first time...'} />
                          </NetworkGraphContainerStyles.GraphLoadingHolder>
                        )}

                        {graphDataError && (
                          <NetworkGraphContainerStyles.ErrorNotificationIconHolder
                            onClick={() => {
                              setGraphApiErrorOpen(true);
                            }}>
                            <Asset.ErrorCircleIcon />
                          </NetworkGraphContainerStyles.ErrorNotificationIconHolder>
                        )}
                      </>
                    ) : (
                      <NetworkGraphContainerStyles.GraphLoadingHolder
                        className='graph-loading-holder'
                        data-testid='graph-loading-holder'>
                        {graphDataError ? (
                          <>
                            <DataError error={graphDataError} />
                            {isLoading && (
                              <NetworkGraphContainerStyles.SmallCornerLoadingHolder>
                                <Loading small />
                              </NetworkGraphContainerStyles.SmallCornerLoadingHolder>
                            )}
                          </>
                        ) : (
                          <Loading message={'Retrieving network data...'} />
                        )}
                      </NetworkGraphContainerStyles.GraphLoadingHolder>
                    ))}
                </NetworkGraphContainerStyles.GraphHolder>
                {!hasEnoughFilters(filters) && (
                  <EmptyGraph hideEmptyGraphFiltersMessage={filters.hideEmptyGraphFiltersMessage} />
                )}
              </div>
              {hasEnoughFilters(filters) && (
                <NetworkGraphContainerStyles.TimeContainer movedForInfoTab={!!usageInfoTopic}>
                  <TimeDisplay
                    data-chromatic='ignore'
                    extraClass='chromatic-ignore'
                    {...getTimeDisplayProps(pullTime, range)}
                  />
                </NetworkGraphContainerStyles.TimeContainer>
              )}

              <DetailsTab
                tabHidden={detailsTabState !== TabState.Open}
                toggleIn={e => {
                  setDetailsTabState(n => (n === TabState.Open ? TabState.LockedClosed : TabState.Open));
                }}
                canBeDisplayed={!!selectedNode || !!selectedEdge}
                extraTabs={
                  !!selectedEdgeReversed && detailsTabState === TabState.Open ? (
                    <DetailsTabStyles.TabsHolder>
                      <DetailsTabStyles.Tab
                        isActive={!detailsTabDoubleEdgeView}
                        onClick={() => setDetailsTabDoubleEdgeView(false)}>
                        <span>Merged</span>
                      </DetailsTabStyles.Tab>
                      <DetailsTabStyles.Tab
                        isActive={detailsTabDoubleEdgeView}
                        onClick={() => setDetailsTabDoubleEdgeView(true)}>
                        <span>Split</span>
                      </DetailsTabStyles.Tab>
                    </DetailsTabStyles.TabsHolder>
                  ) : undefined
                }
                splitView={selectedEdgeReversed && detailsTabDoubleEdgeView}>
                {!!selectedNode && (
                  <DetailsTabStyles.DetailsContainer>
                    <ErrorBoundary fallback='Error displaying node details'>
                      <NodeDetails details={selectedNode} />
                    </ErrorBoundary>
                  </DetailsTabStyles.DetailsContainer>
                )}

                {!!selectedEdge &&
                  (selectedEdgeReversed && detailsTabDoubleEdgeView ? (
                    <DetailsTabStyles.SplitDetailsContainer>
                      <ErrorBoundary fallback='Error displaying edge details'>
                        <DetailsTabStyles.DetailsContainer>
                          <EdgeDetails details={selectedEdge} splitView />
                        </DetailsTabStyles.DetailsContainer>
                        <DetailsTabStyles.DetailsContainer>
                          <EdgeDetails details={selectedEdgeReversed} splitView />
                        </DetailsTabStyles.DetailsContainer>
                      </ErrorBoundary>
                    </DetailsTabStyles.SplitDetailsContainer>
                  ) : (
                    <DetailsTabStyles.DetailsContainer>
                      <ErrorBoundary fallback='Error displaying edge details'>
                        <EdgeDetails details={selectedEdge} />
                      </ErrorBoundary>
                    </DetailsTabStyles.DetailsContainer>
                  ))}
              </DetailsTab>
            </NetworkGraphContainerStyles.GraphDataWrapper>
            {controlsEnabled && (
              <NetworkGraphContainerStyles.Arrows>
                <NetworkGraphContainerStyles.DirectionArrow dir='up' onClick={goUp} data-testid='graph-up-button'>
                  <Asset.ArrowToggle />
                </NetworkGraphContainerStyles.DirectionArrow>
                <NetworkGraphContainerStyles.DirectionArrow dir='left' onClick={goLeft} data-testid='graph-left-button'>
                  <Asset.ArrowToggle />
                </NetworkGraphContainerStyles.DirectionArrow>
                <NetworkGraphContainerStyles.DirectionArrow
                  dir='right'
                  onClick={goRight}
                  data-testid='graph-right-button'>
                  <Asset.ArrowToggle />
                </NetworkGraphContainerStyles.DirectionArrow>
                <NetworkGraphContainerStyles.DirectionArrow dir='down' onClick={goDown} data-testid='graph-down-button'>
                  <Asset.ArrowToggle />
                </NetworkGraphContainerStyles.DirectionArrow>
              </NetworkGraphContainerStyles.Arrows>
            )}
            <NetworkGraphContainerStyles.GraphFooter>
              <NetworkGraphContainerStyles.ControlsBox enabled={controlsEnabled}>
                <NetworkGraphContainerStyles.ControlButton
                  onClick={toggleFullscreen}
                  title={'Fullscreen Toggle'}
                  data-testid='graph-fullscreen-button'>
                  {fullscreenHandle.active ? <Asset.LeaveFullscreen /> : <Asset.FitIconBlack />}
                </NetworkGraphContainerStyles.ControlButton>
                <NetworkGraphContainerStyles.ControlButton
                  onClick={zoomIn}
                  title={'Zoom In'}
                  data-testid='graph-zoom-in-button'>
                  <Asset.ZoomInIconBlack />
                </NetworkGraphContainerStyles.ControlButton>
                <NetworkGraphContainerStyles.ControlButton
                  onClick={zoomOut}
                  title={'Zoom Out'}
                  data-testid='graph-zoom-out-button'>
                  <Asset.ZoomOutIconBlack />
                </NetworkGraphContainerStyles.ControlButton>
                <NetworkGraphContainerStyles.ControlButton
                  data-testid='graph-forget-manual-placement-button'
                  onClick={forgetManualPlacements}
                  title={'Revert node placements to default'}>
                  <Asset.RequestIcon />
                </NetworkGraphContainerStyles.ControlButton>
                <NetworkGraphContainerStyles.ControlButton
                  data-testid='graph-center-button'
                  onClick={centerView}
                  title={'Bring nearer to center'}>
                  <Asset.FitToScreen />
                </NetworkGraphContainerStyles.ControlButton>

                {/* Debug button - useful when testing node positioning */}
                {/*<NetworkGraphContainerStyles.ControlButton
                        data-testid='graph-not-public-button'
                        onClick={clearNodePositioning}
                        title={'Reset the node positions'}>
                        CN
                      </NetworkGraphContainerStyles.ControlButton>*/}
              </NetworkGraphContainerStyles.ControlsBox>

              <NetworkGraphContainerStyles.ControlsVr className='controlsVr' />

              <NetworkGraphContainerStyles.LayoutControls enabled={controlsEnabled}>
                <NetworkGraphContainerStyles.ControlButtonActivateable
                  onClick={usePresetLayout}
                  active={layoutType === LayoutTypesEnum.Preset}>
                  <Asset.LayoutIcon /> <span>1</span>
                </NetworkGraphContainerStyles.ControlButtonActivateable>
                <NetworkGraphContainerStyles.ControlButtonActivateable
                  onClick={useDotLayout}
                  active={layoutType === LayoutTypesEnum.Dot}>
                  <Asset.LayoutIcon /> <span>2</span>
                </NetworkGraphContainerStyles.ControlButtonActivateable>
              </NetworkGraphContainerStyles.LayoutControls>

              <NetworkGraphContainerStyles.RightControls>
                <NetworkGraphContainerStyles.ControlButtonLink onClick={toggleLegend}>
                  <SoloLinkStyles.SoloLinkLooks>
                    <Asset.ViewIcon />
                    {usageInfoTopic === UsageInfoTopicEnum.Legend ? 'Hide' : 'View'} Legend
                  </SoloLinkStyles.SoloLinkLooks>
                </NetworkGraphContainerStyles.ControlButtonLink>

                <NetworkGraphContainerStyles.ControlsVr />

                <NetworkGraphContainerStyles.ControlButtonLink
                  data-testid='graph-layout-settings-button'
                  onClick={toggleLayoutSettings}>
                  <SoloLinkStyles.SoloLinkLooks>
                    <NetworkGraphContainerStyles.LayoutSettingsIconStyled />
                    Layout Settings
                  </SoloLinkStyles.SoloLinkLooks>
                </NetworkGraphContainerStyles.ControlButtonLink>
              </NetworkGraphContainerStyles.RightControls>
            </NetworkGraphContainerStyles.GraphFooter>
          </NetworkGraphContainerStyles.InnerContainer>
        </NetworkGraphContainerStyles.SizeLimiter>
        <UsageInfoTab topicShown={usageInfoTopic} setTopicShown={setUsageInfoTopic} toggleGroupBy={toggleGroupBy} />
      </FullScreen>
      <SoloModal
        visible={graphApiErrorOpen}
        width={1024}
        onClose={() => {
          setGraphApiErrorOpen(false);
        }}>
        <NetworkGraphContainerStyles.ErrorModalBodyContainer>
          <h2>Graph Error</h2>
          <p>Details on the graph will be stale until error is resolved.</p>
          <DataError skipGracefulStart error={graphDataError} />
        </NetworkGraphContainerStyles.ErrorModalBodyContainer>
      </SoloModal>
    </NetworkGraphContainerStyles.ContainerWrapper>
  );
};
