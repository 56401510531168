import { Tooltip } from 'antd';
import { Asset } from 'assets';
import React, { ComponentProps } from 'react';
import { ColorOrAllowed, ColorThemeFunc } from 'Styles/colors';
import { Svg } from './Svg';

type Props = { tooltip: React.ReactNode; size?: number; iconColor?: ColorOrAllowed | ColorThemeFunc } & Omit<
  ComponentProps<typeof Tooltip>,
  'title'
>;
export const InfoTooltip = ({ tooltip, size = 14, iconColor, ...tooltipProps }: Props) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <Svg asset={<Asset.InfoIcon />} color={iconColor ?? (thm => thm.augustGrey)} size={size} inline />
    </Tooltip>
  );
};
