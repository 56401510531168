import {
  Pagination,
  State
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetYamlRequest,
  ListResourcesRequest,
  ListResourcesRequest_ParentResource
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug_pb';
import { DebugApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug_pb.client';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import { grpcWebFetchTransport, soloGrpcCall, useRequest } from './helpers';

export namespace debugApi {
  const client = new DebugApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.DebugApi/' + capitalizeFirstLetters(fn.name);

  //
  // Get YAML
  //
  export async function getYaml(type: ResourceType, ref: ClusterObjectRef) {
    const request: GetYamlRequest = { ref, type };
    return (await soloGrpcCall(client.getYaml(request)))?.yaml;
  }
  export function useGetYaml(...args: Parameters<typeof getYaml>) {
    const ref = args[1];
    return useRequest(getYaml, args, {
      skip: !ref,
      methodDescriptorName: getMethodDescriptorName(client.getYaml)
    });
  }

  //
  // Get Output Resources List
  //
  export async function getResourcesList(
    searchTextFilter: string,
    typesFilter: ResourceType[],
    parentResource?: ListResourcesRequest_ParentResource,
    labelKey?: string,
    labelValue?: string,
    statusFilterList?: State[],
    pagination?: Pagination
  ) {
    const request: ListResourcesRequest = {
      searchFilter: searchTextFilter,
      typeFilter: typesFilter,
      parentResource: parentResource,
      labelKeyFilter: labelKey ?? '',
      labelValueFilter: labelValue ?? '',
      statusFilter: statusFilterList ?? [],
      pagination,
      clusterFilter: '',
      nameFilter: '',
      namespaceFilter: '',
      workspaceFilter: ''
    };
    return soloGrpcCall(client.listResources(request));
  }
  export function useGetResourcesList(...args: Parameters<typeof getResourcesList>) {
    return useRequest(getResourcesList, args, {
      methodDescriptorName: getMethodDescriptorName(client.listResources)
    });
  }
}
