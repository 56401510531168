import { InfoTooltip } from 'Components/Common/InfoTooltip';
import { CertificateDetailsModal } from 'Components/Features/Certificates/Modals/CertificateDetailsModal';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { useMemo, useState } from 'react';
import { transformCertDataToModalProps } from 'utils/certificate-helpers';
import { getInsightData } from 'utils/dashboard/dashboard-helpers';
import { SECONDS_IN_DAY, formatDateToMMDDYYYY } from 'utils/helpers';
import { CertExpiryStyles } from '../CertificatesExpiryCard.style';
import { CertificateExpiryKey, certificatesExpiryCodesMap } from '../insight-codes';
import { ExpiryVizualizationLine, ExpiryVizualizationTooltipContent } from './ExpiryVizualizationLine';
import { useGetExpiryLineTimeData } from './hooks';

const rootExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.rootCAExpiry].dataProp;

const SingleInsightVizualization = ({
  insight,
  onDetailsClicked
}: {
  insight: Insight;
  onDetailsClicked: () => void;
}) => {
  const data = getInsightData(insight, rootExpiryDataProp);
  if (!data) {
    return null;
  }
  const hasNoExpiration = data.expiresOn === undefined;
  const lineStartTime = data.issuedOn?.seconds ?? 0;
  const lineEndTime = data.expiresOn?.seconds ?? 1;
  //
  // Calculate the days left and percent complete.
  const totalSeconds = lineEndTime - lineStartTime;
  const curSeconds = Math.floor(Date.now().valueOf() / 1000);
  const secondsLeft = lineEndTime - curSeconds;
  const daysLeft = Math.floor(secondsLeft / SECONDS_IN_DAY);
  const percentComplete = (1 - secondsLeft / totalSeconds) * 100;

  return (
    <>
      <FlexLayout justifyContent='space-between'>
        <CertExpiryStyles.RootCertLineInfoText>
          {data.hostname}
          <InfoTooltip
            tooltip={<ExpiryVizualizationTooltipContent data={data} onDetailsClicked={onDetailsClicked} />}
          />
        </CertExpiryStyles.RootCertLineInfoText>
        <CertExpiryStyles.RootCertLineInfoText>
          {hasNoExpiration && <>No Expiration</>}
        </CertExpiryStyles.RootCertLineInfoText>
      </FlexLayout>
      <CertExpiryStyles.RootCertExpiryVisualizationLine
        addMarkerSpace={!hasNoExpiration && percentComplete <= 30}
        percentFull={percentComplete}>
        {/* <CertExpiryStyles.CertExpiryDataPoint leftPercent={percentComplete} inLineSection='normal' /> */}
        {!hasNoExpiration && (
          <CertExpiryStyles.TextMarker percent={percentComplete} textOnRight={percentComplete < 75} health='normal'>
            Expires{' '}
            {daysLeft === 0 ? (
              'within a day'
            ) : (
              <>
                in {daysLeft} day{daysLeft > 1 ? 's' : ''}
              </>
            )}
          </CertExpiryStyles.TextMarker>
        )}
      </CertExpiryStyles.RootCertExpiryVisualizationLine>
    </>
  );
};

//
// This renders a line for one root cert, specifically filled to show
// its expiration time.
//
const RootCertExpiryVizualization = ({ insights }: { insights: Insight[] }) => {
  const [insightForModal, setInsightForModal] = useState<Insight>();
  const detailsModalProps = useMemo(() => {
    if (!insightForModal) {
      return undefined;
    }
    const certData = getInsightData(insightForModal, rootExpiryDataProp);
    return transformCertDataToModalProps('root', certData);
  }, [insightForModal]);

  const { startTime, endTime } = useGetExpiryLineTimeData(insights, CertificateExpiryKey.rootCAExpiry);

  //
  // Format the start/end dates to display
  //
  const lineStartTimeFormattedDate = useMemo(() => formatDateToMMDDYYYY(new Date(startTime * 1000)), [startTime]);
  const lineEndTimeFormattedDate = useMemo(() => formatDateToMMDDYYYY(new Date(endTime * 1000)), [endTime]);

  //
  // Render
  //
  if (insights.length === 0) return null;
  return (
    <CertExpiryStyles.CertExpirySubSection data-testid='root-certs-vizualization'>
      <CertExpiryStyles.CertExpiryTitleText>Root Certificate</CertExpiryStyles.CertExpiryTitleText>
      {insights.length === 1 ? (
        <SingleInsightVizualization insight={insights[0]} onDetailsClicked={() => setInsightForModal(insights[0])} />
      ) : (
        <>
          <ExpiryVizualizationLine
            insights={insights}
            mapKey={CertificateExpiryKey.rootCAExpiry}
            onDetailsClicked={setInsightForModal}
          />
          <FlexLayout justifyContent='space-between'>
            <CertExpiryStyles.ExpiryDateText>{lineStartTimeFormattedDate}</CertExpiryStyles.ExpiryDateText>
            <CertExpiryStyles.ExpiryDateText>{lineEndTimeFormattedDate}</CertExpiryStyles.ExpiryDateText>
          </FlexLayout>
        </>
      )}
      {detailsModalProps && (
        <CertificateDetailsModal {...detailsModalProps} onClose={() => setInsightForModal(undefined)} />
      )}
    </CertExpiryStyles.CertExpirySubSection>
  );
};

export default RootCertExpiryVizualization;
