import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace FancyCodeDisplayerStyles {
  export const RelativeHook = styled.div`
    position: relative;
  `;

  interface ContainerProps {
    includeBorderBackground?: boolean;
    removeLineNumberPadding?: boolean;
    maxHeight?: number | 'none';
  }
  export const Container = styled.div<ContainerProps>(
    ({ theme, includeBorderBackground, removeLineNumberPadding = false, maxHeight = 600 }) => css`
      position: relative;
      padding: 0 5px 0 ${removeLineNumberPadding ? '18px' : 'calc(2em + 18px)'};
      max-height: ${typeof maxHeight === 'string' ? maxHeight : `${maxHeight}px`};
      overflow-y: auto;
      ${includeBorderBackground
        ? css`
            border: 1px solid ${theme.aprilGrey};
            border-radius: 8px;
            background: ${theme.januaryGrey};
            .prism-code {
              background: ${theme.januaryGrey} !important;
            }
          `
        : css`
            background: transparent;
            .prism-code {
              background: transparent !important;
            }
          `}

      > div {
        > textarea {
          outline: none !important;
          border: 1px solid ${theme.aprilGrey} !important;
        }
        > pre {
          overflow-x: auto;
          overflow-wrap: normal;
          // This was set to 1em, but rem fixes size variations in nested elements.
          font-size: 1rem;
        }
      }
    `
  );

  export const DescriptionContainer = styled.div(
    ({ theme }) => css`
      color: ${theme.septemberGrey};
      font-size: 14px;
      margin-left: -2em;
      margin-top: 18px;
    `
  );

  export const FancyCodeContainer = styled.div`
    // Adding this so one-line code displayers have some more spacing.
    // This also prevents the copy button from being cut-off.
    pre:has(div:only-child) {
      padding-top: calc(20px - 1ex);
      min-height: 40px;
    }
  `;

  export const Pre = styled.pre`
    text-align: left;
    & .token-line {
      line-height: 1;
      height: 1.3em;
    }
    > div {
      > span {
        font-family: monospace;
      }
    }
  `;

  export const LineNo = styled.span`
    position: absolute;
    left: 18px;
    display: inline-block;
    width: 2em;
    user-select: none;
    opacity: 0.3;
    pointer-events: none;
  `;

  type CopyButtonProps = {
    copySuccessful: boolean | 'inactive';
    shiftDown: boolean;
  };
  export const CopyButton = styled(UnstyledButton)<CopyButtonProps>(
    ({ theme, copySuccessful, shiftDown }) => css`
      position: absolute;
      top: ${shiftDown ? '12px' : '5px'};
      right: 20px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 8px;
      z-index: 10;
      transition: background 0.7s ease-out, opacity 0.5s;
      opacity: 0.25;
      &:hover,
      &:focus,
      &:focus-visible {
        opacity: 1;
      }

      ${(() => {
        if (copySuccessful === 'inactive') {
          return css`
            background: ${theme.seaBlue};
            &:active {
              background: ${theme.oceanBlue};
            }
          `;
        } else {
          return css`
            background: ${copySuccessful ? theme.forestGreen : theme.pumpkinOrange};
            transition: background 0.2s ease-in, opacity 0.5s;
            opacity: 1;
          `;
        }
      })()}

      ${svgBasics({ width: 14 }, 'white')}
    `
  );

  export const DownloadButton = styled(CopyButton)<CopyButtonProps>(
    () => css`
      right: 55px;
      ${svgBasics({ width: 14 }, 'white')}
    `
  );

  export const SimpleFancyCodeWrapper = styled.div`
    ${Container} {
      background: transparent;
      border: none;
      padding-left: 2em;

      .prism-code {
        background: transparent !important;
      }
    }
    ${FancyCodeContainer} {
      margin: 0;
    }
    ${LineNo} {
      width: 2em;
      left: 0;
    }
  `;
}
