import { SoloInput } from 'Components/Common/Input/SoloInput';
import { FieldDefinitionNode } from 'graphql';
import { useContext, useMemo, useRef, useState } from 'react';
import { IGqlSchemaSearchBoxItem } from 'utils/graphql-schema-search-helpers';
import { useEventListener } from 'utils/hooks';
import { GqlLandingContext } from '../../../context/GqlLandingContext';
import { GqlSchemaSearchStyles } from './GqlSchemaSearch.style';
import GqlSchemaSearchBox from './GqlSchemaSearchBox';

const GqlSchemaSearchInput = ({
  onTypeClick
}: {
  onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
}) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { schema } = gqlCtx;
  //
  // State
  //
  const inputElementRef = useRef<HTMLInputElement>(null);
  const [isSearchContainerFocused, setIsSearchContainerFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const allSearchBoxItems = useMemo(() => {
    const items = [] as IGqlSchemaSearchBoxItem[];
    schema.definitions.forEach(d => {
      const isSpecialCase = ['query', 'mutation'].includes(d.name.value.toLowerCase());
      // Filter out special cases
      if (!isSpecialCase)
        items.push({
          id: items.length,
          title: d.name.value,
          description: (d as any).description?.value ?? '',
          path: d.name.value
        });
      if ((d as any).fields?.length)
        (d as any).fields.forEach((f: FieldDefinitionNode) =>
          items.push({
            id: items.length,
            title: `${d.name.value} / ${f.name.value}`,
            description: f.description?.value,
            path: isSpecialCase ? f.name.value : `${d.name.value}/${f.name.value}`
          })
        );
    });
    return items;
  }, [schema.definitions]);

  //
  // Update
  //
  // This checks if the click target was inside the search box, and updates focus-state.
  useEventListener(window, 'click', e => {
    setIsSearchContainerFocused(!!(e.target as HTMLElement).closest('.search-box-container'));
  });
  // This checks if the user is tabbing outside the search box, and updates focus-state.
  useEventListener(window, 'keyup', e => {
    if (e.key !== 'Tab') return;
    setIsSearchContainerFocused(!!(e.target as HTMLElement).closest('.search-box-container'));
  });

  //
  // Render
  //
  return (
    <GqlSchemaSearchStyles.SearchBoxContainer className='search-box-container'>
      <SoloInput
        searchIcon
        value={searchText}
        placeholder='Search Schema...'
        aria-label='Search Schema...'
        data-testid='schema-search-input'
        onChange={s => setSearchText(s.target.value)}
        onFocus={() => setIsSearchContainerFocused(true)}
        innerRef={inputElementRef}
      />
      <GqlSchemaSearchBox
        show={isSearchContainerFocused}
        searchText={searchText}
        allSearchBoxItems={allSearchBoxItems}
        onSearchBoxItemClick={item => {
          onTypeClick(item.path, true);
          setIsSearchContainerFocused(false);
          inputElementRef.current?.blur();
        }}
      />
    </GqlSchemaSearchStyles.SearchBoxContainer>
  );
};

export default GqlSchemaSearchInput;
