import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { colors } from 'Styles';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetProxyProtocolPolicyDetails } = policiesApi;

const policyType = PolicyType.PROXY_PROTOCOL;

type TableFields = {
  key: string;
  name: StateAndNameType;
  namespace?: string;
  clusterName?: string;
  port: number;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  {
    title: 'Port',
    dataIndex: 'port'
  }
];

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ProxyProtocolDetails = ({ clusterObjRef }: Props) => {
  di(useGetProxyProtocolPolicyDetails);
  const listenersPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetProxyProtocolPolicyDetails(
    clusterObjRef,
    listenersPaging.apiPaginationObject
  );

  listenersPaging.useSetPagingTotal(policyData?.virtualGatewayListeners?.totalVirtualGatewayListeners);

  const tableData = useMemo<TableFields[]>(
    () =>
      policyData?.virtualGatewayListeners?.virtualGatewayListeners.map(data => ({
        ...data,
        ...data.ref,
        key: buildIdFromRef(data.ref),
        name: { name: data.ref?.name, status: data.status }
      })) ?? [],
    [policyData?.virtualGatewayListeners?.virtualGatewayListeners]
  );

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
        secondaryInfo={[
          policyData.enableProxyProtocol
            ? { label: 'enabled', data: 'true', highlighted: false, background: colors.forestGreen }
            : { label: 'enabled', data: 'false', highlighted: { primaryColor: colors.augustGrey } }
        ]}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Virtual Gateway Listeners
          <CardStyles.CardHeaderRightIcon>
            <Asset.ListenerIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>
        <SoloTable columns={columns} dataSource={tableData} paging={listenersPaging.pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
