import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { ForwardToDestination } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { getDestinationKindIcon } from 'utils/types';
import { buildWorkspaceDestinationDetailsUrl } from 'utils/url-builders';
import { RoutesListDisplayStyles as Styles } from '../../RoutesListDisplay.style';

/**
 * Returns a validated DestinationReference that has had fallback values set for the type of destination being used.
 */
function destinationToValidatedRef(dest: ForwardToDestination, parentRef: ClusterObjectRef) {
  const { destinationRef: ref } = dest;
  if (!ref) return ref;

  if (ref.namespace === '') {
    ref.namespace = parentRef.namespace;
  }
  if (ref.clusterName === '') {
    ref.clusterName = parentRef.clusterName;
  }
  return ref;
}

interface Props {
  destinationsList: ForwardToDestination[];
  parentRef: ClusterObjectRef;
}
export const RouteForwardToDestinationList = ({ destinationsList, parentRef }: Props) => {
  di(useNamespaceClusterToWorkspaceMap);
  const { lookupWorkspaceName } = useNamespaceClusterToWorkspaceMap();
  return (
    <Styles.ForwardsGrid>
      {destinationsList.map(dest => {
        const ref = destinationToValidatedRef(dest, parentRef);
        return (
          <React.Fragment key={buildIdFromRef(ref)}>
            <SoloLink link={buildWorkspaceDestinationDetailsUrl(lookupWorkspaceName(ref), dest.type, ref)}>
              <Styles.IconText>
                {getDestinationKindIcon(dest.type)} {ref?.name}
              </Styles.IconText>
            </SoloLink>
            <>
              <div>
                <SecondaryInfoItem label='Port' data={dest.port} small />
              </div>
              <Styles.ForwardWeight>
                <SecondaryInfoItem label='Weight' data={dest.weight} small />
              </Styles.ForwardWeight>
            </>
            {dest.hasOpenapiApi ? (
              <Tooltip title='Destination has an API schema available'>
                <Svg size={22} asset={<Asset.OpenApiIcon />} />
              </Tooltip>
            ) : (
              <div></div>
            )}
          </React.Fragment>
        );
      })}
    </Styles.ForwardsGrid>
  );
};
