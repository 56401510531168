import { UnionTypeDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlUnionTabItem from './GqlUnionTabItem';
import GqlUnionTabTable from './GqlUnionTabTable';

const GqlUnionTab = ({
  onTypeClick,
  definitions,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<UnionTypeDefinitionNode>) => {
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  //  Render
  //
  return (
    <>
      <Spacer mb={5}>
        <GqlDetailsStyles.LightText>
          Represent an object that could be one of a list of object types, but without guaranteed fields between those
          types.
        </GqlDetailsStyles.LightText>
      </Spacer>

      {!!focusedItem && (
        <GqlUnionTabItem
          focusedPath={focusedPath}
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlUnionTabTable
        tabHeader={tabHeader}
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
      />
    </>
  );
};

export default GqlUnionTab;
