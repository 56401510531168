/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { WorkspacesApi } from "./workspaces_pb";
import type { ListWorkspaceApisResponse } from "./workspaces_pb";
import type { ListWorkspaceApisRequest } from "./workspaces_pb";
import type { ListWorkspacePoliciesResponse } from "./workspaces_pb";
import type { ListWorkspacePoliciesRequest } from "./workspaces_pb";
import type { ListWorkspaceRoutesResponse } from "./workspaces_pb";
import type { ListWorkspaceRoutesRequest } from "./workspaces_pb";
import type { ListWorkspaceDestinationsResponse } from "./workspaces_pb";
import type { ListWorkspaceDestinationsRequest } from "./workspaces_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetWorkspaceDetailsResponse } from "./workspaces_pb";
import type { GetWorkspaceDetailsRequest } from "./workspaces_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Apis for the Gloo Mesh UI Workspace Details page
 *
 * @generated from protobuf service rpc.gloo.solo.io.WorkspacesApi
 */
export interface IWorkspacesApiClient {
    /**
     * @generated from protobuf rpc: GetWorkspaceDetails(rpc.gloo.solo.io.GetWorkspaceDetailsRequest) returns (rpc.gloo.solo.io.GetWorkspaceDetailsResponse);
     */
    getWorkspaceDetails(input: GetWorkspaceDetailsRequest, options?: RpcOptions): UnaryCall<GetWorkspaceDetailsRequest, GetWorkspaceDetailsResponse>;
    /**
     * @generated from protobuf rpc: ListWorkspaceDestinations(rpc.gloo.solo.io.ListWorkspaceDestinationsRequest) returns (rpc.gloo.solo.io.ListWorkspaceDestinationsResponse);
     */
    listWorkspaceDestinations(input: ListWorkspaceDestinationsRequest, options?: RpcOptions): UnaryCall<ListWorkspaceDestinationsRequest, ListWorkspaceDestinationsResponse>;
    /**
     * @generated from protobuf rpc: ListWorkspaceRoutes(rpc.gloo.solo.io.ListWorkspaceRoutesRequest) returns (rpc.gloo.solo.io.ListWorkspaceRoutesResponse);
     */
    listWorkspaceRoutes(input: ListWorkspaceRoutesRequest, options?: RpcOptions): UnaryCall<ListWorkspaceRoutesRequest, ListWorkspaceRoutesResponse>;
    /**
     * @generated from protobuf rpc: ListWorkspacePolicies(rpc.gloo.solo.io.ListWorkspacePoliciesRequest) returns (rpc.gloo.solo.io.ListWorkspacePoliciesResponse);
     */
    listWorkspacePolicies(input: ListWorkspacePoliciesRequest, options?: RpcOptions): UnaryCall<ListWorkspacePoliciesRequest, ListWorkspacePoliciesResponse>;
    /**
     * @generated from protobuf rpc: ListWorkspaceApis(rpc.gloo.solo.io.ListWorkspaceApisRequest) returns (rpc.gloo.solo.io.ListWorkspaceApisResponse);
     */
    listWorkspaceApis(input: ListWorkspaceApisRequest, options?: RpcOptions): UnaryCall<ListWorkspaceApisRequest, ListWorkspaceApisResponse>;
}
/**
 * Apis for the Gloo Mesh UI Workspace Details page
 *
 * @generated from protobuf service rpc.gloo.solo.io.WorkspacesApi
 */
export class WorkspacesApiClient implements IWorkspacesApiClient, ServiceInfo {
    typeName = WorkspacesApi.typeName;
    methods = WorkspacesApi.methods;
    options = WorkspacesApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetWorkspaceDetails(rpc.gloo.solo.io.GetWorkspaceDetailsRequest) returns (rpc.gloo.solo.io.GetWorkspaceDetailsResponse);
     */
    getWorkspaceDetails(input: GetWorkspaceDetailsRequest, options?: RpcOptions): UnaryCall<GetWorkspaceDetailsRequest, GetWorkspaceDetailsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWorkspaceDetailsRequest, GetWorkspaceDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListWorkspaceDestinations(rpc.gloo.solo.io.ListWorkspaceDestinationsRequest) returns (rpc.gloo.solo.io.ListWorkspaceDestinationsResponse);
     */
    listWorkspaceDestinations(input: ListWorkspaceDestinationsRequest, options?: RpcOptions): UnaryCall<ListWorkspaceDestinationsRequest, ListWorkspaceDestinationsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListWorkspaceDestinationsRequest, ListWorkspaceDestinationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListWorkspaceRoutes(rpc.gloo.solo.io.ListWorkspaceRoutesRequest) returns (rpc.gloo.solo.io.ListWorkspaceRoutesResponse);
     */
    listWorkspaceRoutes(input: ListWorkspaceRoutesRequest, options?: RpcOptions): UnaryCall<ListWorkspaceRoutesRequest, ListWorkspaceRoutesResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListWorkspaceRoutesRequest, ListWorkspaceRoutesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListWorkspacePolicies(rpc.gloo.solo.io.ListWorkspacePoliciesRequest) returns (rpc.gloo.solo.io.ListWorkspacePoliciesResponse);
     */
    listWorkspacePolicies(input: ListWorkspacePoliciesRequest, options?: RpcOptions): UnaryCall<ListWorkspacePoliciesRequest, ListWorkspacePoliciesResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListWorkspacePoliciesRequest, ListWorkspacePoliciesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListWorkspaceApis(rpc.gloo.solo.io.ListWorkspaceApisRequest) returns (rpc.gloo.solo.io.ListWorkspaceApisResponse);
     */
    listWorkspaceApis(input: ListWorkspaceApisRequest, options?: RpcOptions): UnaryCall<ListWorkspaceApisRequest, ListWorkspaceApisResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListWorkspaceApisRequest, ListWorkspaceApisResponse>("unary", this._transport, method, opt, input);
    }
}
