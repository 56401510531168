import {
  GetLogoutPathRequest,
  GetSafeModeStatusRequest,
  GetTracingBasePathRequest,
  GetUIFeatureFlagsRequest,
  GetUIFeatureFlagsResponse,
  GetUIFeatureFlagsResponse_License,
  GetUIFeatureFlagsResponse_Licenses,
  GetUIFeatureFlagsResponse_License_LicenseState
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb';
import { DashboardApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb.client';
import { capitalizeFirstLetters } from 'utils/helpers';
import {
  getDayApiRefreshTime,
  getVeryLongApiRefreshTime,
  grpcWebFetchTransport,
  soloGrpcCall,
  useRequest
} from './helpers';

export namespace dashboardApi {
  const client = new DashboardApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.DashboardApi/' + capitalizeFirstLetters(fn.name);

  ///////////////////////////////////////
  // Logout Path
  ///////////////////////////////////////
  export async function getLogoutPath(workspaceName?: string) {
    let request: GetLogoutPathRequest = { workspaceName };
    return soloGrpcCall(client.getLogoutPath(request));
  }
  export function useGetLogoutPath() {
    return useRequest(
      getLogoutPath,
      [],
      { methodDescriptorName: getMethodDescriptorName(client.getLogoutPath) },
      { refreshInterval: getDayApiRefreshTime() }
    );
  }

  ///////////////////////////////////////
  // Tracing Path
  ///////////////////////////////////////
  export async function getTracingBasePath() {
    let request: GetTracingBasePathRequest = {};
    return soloGrpcCall(client.getTracingBasePath(request));
  }
  export function useGetTracingBasePath() {
    // const { data } = useRequest(
    return useRequest(
      getTracingBasePath,
      [],
      { methodDescriptorName: getMethodDescriptorName(client.getTracingBasePath) },
      { refreshInterval: getDayApiRefreshTime() }
    );
  }

  ///////////////////////////////////////
  // Safe Mode Status
  ///////////////////////////////////////
  export async function getSafeModeStatus() {
    let request: GetSafeModeStatusRequest = {};
    return soloGrpcCall(client.getSafeModeStatus(request));
  }
  export function useGetSafeModeStatus() {
    return useRequest(
      getSafeModeStatus,
      [],
      { methodDescriptorName: getMethodDescriptorName(client.getSafeModeStatus) },
      { refreshInterval: getVeryLongApiRefreshTime() }
    );
  }

  ///////////////////////////////////////
  // Feature Flags
  ///////////////////////////////////////
  export type LicenseKey = keyof GetUIFeatureFlagsResponse_Licenses;
  export const localStorageLicenceFlagKeys: Record<LicenseKey, string> = {
    glooGateway: 'ff-gateway-enabled',
    glooMesh: 'ff-mesh-enabled',
    glooNetwork: 'ff-network-enabled',
    glooCore: 'ff-core-enabled',
    any: 'ff-any-license-enabled'
  };
  export type HelmFlagKey = keyof Omit<GetUIFeatureFlagsResponse, 'licenses'>;
  export const localStorageHelmFlagKeys: Record<HelmFlagKey, string> = {
    portalEnabled: 'ff-bool-portal',
    graphEnabled: 'ff-bool-graph',
    inightsEnabled: 'ff-bool-insights',
  };

  export async function getFeatureFlags(expDays?: number) {
    let request: GetUIFeatureFlagsRequest = { licenseExpirationThresholdDays: expDays ?? 30 };
    const resp = await soloGrpcCall(client.getUIFeatureFlags(request));
    Object.entries(localStorageLicenceFlagKeys).forEach(([licenseKey, lsKey]) => {
      if (window.localStorage.getItem(lsKey)) {
        resp.licenses ??= {};
        resp.licenses[licenseKey as LicenseKey] = createFakeLicense(window.localStorage.getItem(lsKey) === 'true');
      }
    });
    Object.entries(localStorageHelmFlagKeys).forEach(([flagName, lsKey]) => {
      if (window.localStorage.getItem(lsKey)) {
        resp[flagName as HelmFlagKey] = window.localStorage.getItem(lsKey) === 'true';
      }
    });
    return resp;
  }
  export function createFakeLicense(
    enabled: boolean | GetUIFeatureFlagsResponse_License_LicenseState
  ): GetUIFeatureFlagsResponse_License {
    return {
      state:
        typeof enabled === 'boolean'
          ? enabled
            ? GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_GRANTED
            : GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_DENIED
          : enabled,
      errors: []
    };
  }
  export function useGetFeatureFlags(...args: Parameters<typeof getFeatureFlags>) {
    return useRequest(getFeatureFlags, args, {
      methodDescriptorName: getMethodDescriptorName(client.getUIFeatureFlags)
    });
  }

  ///////////////////////////////////////
  // Update feature flags
  ///////////////////////////////////////
  export async function setFeatureFlagOverride(flag: LicenseKey, state: boolean | 'unset') {
    if (state === 'unset') {
      window.localStorage.removeItem(localStorageLicenceFlagKeys[flag]);
    } else {
      window.localStorage.setItem(localStorageLicenceFlagKeys[flag], state ? 'true' : 'false');
    }
  }
  export async function setHelmFlagOverride(flag: HelmFlagKey, state: boolean | 'unset') {
    if (state === 'unset') {
      window.localStorage.removeItem(localStorageHelmFlagKeys[flag]);
    } else {
      window.localStorage.setItem(localStorageHelmFlagKeys[flag], state ? 'true' : 'false');
    }
  }
}
