/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/status.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { DestinationKind } from "./selectors_pb";
import { ObjectReference } from "./references_pb";
import { ApprovalState } from "./approval_state_pb";
/**
 * @generated from protobuf message common.gloo.solo.io.Status
 */
export interface Status {
    /**
     * @generated from protobuf field: common.gloo.solo.io.State State = 1 [json_name = "State"];
     */
    state?: State;
    /**
     * A map of ApprovalState to the number of workspaces in this condition, e.g.,
     * "Accepted" -> 2
     * "Pending" -> 1
     *
     * @generated from protobuf field: map<string, uint32> workspace_conditions = 2;
     */
    workspaceConditions: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message common.gloo.solo.io.State
 */
export interface State {
    /**
     * The most recent generation observed in the object's metadata.
     * If the `observedGeneration` does not match `metadata.generation`, Gloo Mesh
     * has not processed the most recent version of this object.
     *
     * @generated from protobuf field: int64 observed_generation = 1;
     */
    observedGeneration: number;
    /**
     * Whether the resource has been accepted as valid and processed in the Gloo
     * Mesh config translation.
     *
     * @generated from protobuf field: common.gloo.solo.io.ApprovalState approval = 2;
     */
    approval: ApprovalState;
    /**
     * Additional information about the current state of the resource.
     *
     * @generated from protobuf field: string message = 3;
     */
    message: string;
}
/**
 * $hide_from_docs
 *
 * @generated from protobuf message common.gloo.solo.io.Report
 */
export interface Report {
    /**
     * The state of the resource in each cluster that receives its configuration.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.State> clusters = 1;
     */
    clusters: {
        [key: string]: State;
    };
}
/**
 * Indicates selected routes on status messages.
 *
 * @generated from protobuf message common.gloo.solo.io.RouteReference
 */
export interface RouteReference {
    /**
     * The name of the route
     *
     * @generated from protobuf field: string route_name = 1;
     */
    routeName: string;
    /**
     * The index of the route on the route table
     *
     * @generated from protobuf field: int32 route_index = 2;
     */
    routeIndex: number;
    /**
     * The route table containing the route
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference route_table = 3;
     */
    routeTable?: ObjectReference;
}
/**
 * @generated from protobuf message common.gloo.solo.io.AppliedDestinationPortPolicies
 */
export interface AppliedDestinationPortPolicies {
    /**
     * List of applied destination port policies
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.AppliedDestinationPortPolicies.DestinationPolicyReference policies = 1;
     */
    policies: AppliedDestinationPortPolicies_DestinationPolicyReference[];
}
/**
 * @generated from protobuf message common.gloo.solo.io.AppliedDestinationPortPolicies.DestinationPolicyReference
 */
export interface AppliedDestinationPortPolicies_DestinationPolicyReference {
    /**
     * the port on the destination object that the policy applies to
     *
     * @generated from protobuf field: int32 destination_port = 1;
     */
    destinationPort: number;
    /**
     * the kind of destination object that the policy applies to
     *
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind destination_kind = 2;
     */
    destinationKind: DestinationKind;
    /**
     * The reference to the policy
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference policy = 3;
     */
    policy?: ObjectReference;
}
/**
 * @generated from protobuf message common.gloo.solo.io.AppliedRoutePolicies
 */
export interface AppliedRoutePolicies {
    /**
     * List of applied route policies
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.AppliedRoutePolicies.RoutePolicyReference policies = 1;
     */
    policies: AppliedRoutePolicies_RoutePolicyReference[];
}
/**
 * @generated from protobuf message common.gloo.solo.io.AppliedRoutePolicies.RoutePolicyReference
 */
export interface AppliedRoutePolicies_RoutePolicyReference {
    /**
     * The name of the route that the policy is applied to
     *
     * @generated from protobuf field: string route_name = 1;
     */
    routeName: string;
    /**
     * The index of the route on the route table
     *
     * @generated from protobuf field: int32 route_index = 2;
     */
    routeIndex: number;
    /**
     * The reference to the policy
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference policy = 3;
     */
    policy?: ObjectReference;
    /**
     * The reference to the root route table.
     * Only relevant if this is a delegated route table.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference root_route_table = 4;
     */
    rootRouteTable?: ObjectReference;
}
/**
 * @generated from protobuf message common.gloo.solo.io.AppliedWorkloadPolicies
 */
export interface AppliedWorkloadPolicies {
    /**
     * Policies on the workload
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.ObjectReference policies = 1;
     */
    policies: ObjectReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Status$Type extends MessageType<Status> {
    constructor() {
        super("common.gloo.solo.io.Status", [
            { no: 1, name: "State", kind: "message", jsonName: "State", T: () => State },
            { no: 2, name: "workspace_conditions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 13 /*ScalarType.UINT32*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.Status
 */
export const Status = new Status$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
    constructor() {
        super("common.gloo.solo.io.State", [
            { no: 1, name: "observed_generation", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "approval", kind: "enum", T: () => ["common.gloo.solo.io.ApprovalState", ApprovalState] },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Report$Type extends MessageType<Report> {
    constructor() {
        super("common.gloo.solo.io.Report", [
            { no: 1, name: "clusters", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => State } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.Report
 */
export const Report = new Report$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteReference$Type extends MessageType<RouteReference> {
    constructor() {
        super("common.gloo.solo.io.RouteReference", [
            { no: 1, name: "route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "route_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "route_table", kind: "message", T: () => ObjectReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.RouteReference
 */
export const RouteReference = new RouteReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppliedDestinationPortPolicies$Type extends MessageType<AppliedDestinationPortPolicies> {
    constructor() {
        super("common.gloo.solo.io.AppliedDestinationPortPolicies", [
            { no: 1, name: "policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppliedDestinationPortPolicies_DestinationPolicyReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AppliedDestinationPortPolicies
 */
export const AppliedDestinationPortPolicies = new AppliedDestinationPortPolicies$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppliedDestinationPortPolicies_DestinationPolicyReference$Type extends MessageType<AppliedDestinationPortPolicies_DestinationPolicyReference> {
    constructor() {
        super("common.gloo.solo.io.AppliedDestinationPortPolicies.DestinationPolicyReference", [
            { no: 1, name: "destination_port", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "destination_kind", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "policy", kind: "message", T: () => ObjectReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AppliedDestinationPortPolicies.DestinationPolicyReference
 */
export const AppliedDestinationPortPolicies_DestinationPolicyReference = new AppliedDestinationPortPolicies_DestinationPolicyReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppliedRoutePolicies$Type extends MessageType<AppliedRoutePolicies> {
    constructor() {
        super("common.gloo.solo.io.AppliedRoutePolicies", [
            { no: 1, name: "policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppliedRoutePolicies_RoutePolicyReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AppliedRoutePolicies
 */
export const AppliedRoutePolicies = new AppliedRoutePolicies$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppliedRoutePolicies_RoutePolicyReference$Type extends MessageType<AppliedRoutePolicies_RoutePolicyReference> {
    constructor() {
        super("common.gloo.solo.io.AppliedRoutePolicies.RoutePolicyReference", [
            { no: 1, name: "route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "route_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "policy", kind: "message", T: () => ObjectReference },
            { no: 4, name: "root_route_table", kind: "message", T: () => ObjectReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AppliedRoutePolicies.RoutePolicyReference
 */
export const AppliedRoutePolicies_RoutePolicyReference = new AppliedRoutePolicies_RoutePolicyReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppliedWorkloadPolicies$Type extends MessageType<AppliedWorkloadPolicies> {
    constructor() {
        super("common.gloo.solo.io.AppliedWorkloadPolicies", [
            { no: 1, name: "policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ObjectReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AppliedWorkloadPolicies
 */
export const AppliedWorkloadPolicies = new AppliedWorkloadPolicies$Type();
