import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { InterfaceTypeDefinitionNode, Kind } from 'graphql';
import { useContext } from 'react';
import { GqlSchemaTabItemProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import GqlSchemaFieldDefinitionItem from '../GqlSchemaFieldDefinitionItem';
import GqlSchemaFieldDefinitionTable from '../GqlSchemaFieldDefinitionTable';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';
import GqlTabItemInterfaceImplementations from '../GqlTabItemInterfaceImplementations';

const GqlInterfaceTabItem = ({
  onTypeClick,
  focusedItem,
  tabHeader,
  focusedPath
}: GqlSchemaTabItemProps<InterfaceTypeDefinitionNode>) => {
  const gqlctx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlctx;
  const focusedItemExtensions = getExtensionInfo(Kind.INTERFACE_TYPE_EXTENSION, focusedItem.name.value);
  const focusedSubItem = useGetFocusedItem(focusedItem.fields, focusedPath, 1);

  //
  // Render
  //
  if (!!focusedSubItem)
    return (
      <GqlSchemaFieldDefinitionItem
        hideResolversAlways={true}
        focusedItem={focusedSubItem}
        focusedPath={focusedPath}
        onTypeClick={onTypeClick}
        tabHeader={tabHeader}
      />
    );
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectiveList
        node={focusedItem}
        extendedDirectives={focusedItemExtensions?.directives}
        onTypeClick={onTypeClick}
      />
      <GqlTabItemInterfaceImplementations
        node={focusedItem}
        extendedInterfaces={focusedItemExtensions?.interfaces}
        onTypeClick={onTypeClick}
      />
      <GqlTabItemDescription node={focusedItem} />
      <GqlSchemaFieldDefinitionTable
        hideResolversAlways={true}
        definitions={focusedItem?.fields ?? []}
        extendedDefinitions={focusedItemExtensions?.fields}
        onTypeClick={onTypeClick}
        tabHeader={`${tabHeader}/${focusedItem.name.value}`}
      />
    </>
  );
};

export default GqlInterfaceTabItem;
