import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { GetIstioControlPlaneLogsLinks } from 'utils/log-viewer-helpers';
import { buildInsightsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { DashboardHealthCheckRow } from '../DashboardHealthCheckRow';

export const IstioHealthCheckSectionContent = ({
  istioControlPlaneInsights,
  lifecycleManagementStatus,
  istioControlPlaneStatus,
  istioDataPlaneStatus
}: {
  istioControlPlaneInsights: Insight[] | undefined;
  // False is passed when there are no insights / status for the thing
  lifecycleManagementStatus: Status | undefined | 'loading';
  istioControlPlaneStatus: Status | undefined | 'loading';
  istioDataPlaneStatus: Status | undefined | 'loading';
}) => {
  return (
    <div>
      <GridLayout templateColumns='20px 1fr auto' gap='20px 6px' data-testid='istio-health-section-content'>
        {/* TODO: update link to actual page */}
        <DashboardHealthCheckRow
          label='Lifecycle Management'
          status={lifecycleManagementStatus}
          infoTooltip={
            <>
              ILM is not currently installed.{' '}
              <ExternalSoloLink href={docLinks.enterprise.istio_mesh_onboard} inline newTab withArrow>
                DOCS
              </ExternalSoloLink>
            </>
          }
        />
        <DashboardHealthCheckRow
          label='Control Plane'
          status={istioControlPlaneStatus}
          logsLinks={GetIstioControlPlaneLogsLinks(istioControlPlaneInsights)}
        />
        <DashboardHealthCheckRow
          label='Data Plane'
          status={istioDataPlaneStatus}
          insightsLinks={[{ label: 'Istio data plane', link: buildInsightsUrl({ codes: ['HLT0001'] }) }]}
        />
      </GridLayout>
    </div>
  );
};
