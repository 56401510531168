import { destinationsApi } from 'Api/destinations';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { FancyCodeDisplayerModal, FancyCodeDisplayerModalContent } from 'Components/Common/FancyCodeDisplayerModal';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { getDestinationKindIcon } from 'utils/types';
import { BasicPoliciesTable } from '../../Policies/BasicPoliciesTable';
import { WorkspaceDependenciesCard } from '../../Workspaces/WorkspaceDependenciesCard';

const { useGetKubernetesServiceDetails, useGetSchemaForKubernetesService } = destinationsApi;

const ApiSchemaModalContent = ({ serviceRef }: { serviceRef: ClusterObjectRef }) => {
  di(useGetSchemaForKubernetesService);
  const { data, error } = useGetSchemaForKubernetesService(serviceRef);
  return <FancyCodeDisplayerModalContent contentString={data?.schema} error={error} type='json' />;
};
interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const K8ServiceDetails = ({ clusterObjRef }: Props) => {
  di(useGetKubernetesServiceDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();
  const [apiSchemaOpen, setApiSchemaOpen] = useState(false);

  const { data: destinationData, error: destinationError } = useGetKubernetesServiceDetails(
    clusterObjRef,
    apiPaginationObject
  );

  useSetPagingTotal(destinationData?.policies?.totalPolicies);

  if (!!destinationError || !destinationData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getDestinationKindIcon(DestinationKind.SERVICE)} objRef={clusterObjRef} />
        {!!destinationError ? (
          <DataError error={destinationError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} destination details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getDestinationKindIcon(DestinationKind.SERVICE)}
        status={destinationData?.status}
        objRef={clusterObjRef}
        workspaceRef={destinationData?.ownerWorkspace?.ref}
        resourceType={ResourceType.SERVICE}
        buttons={
          !!destinationData.apiDocRef
            ? [
              <SoloButton key='api-schema-button' onClick={() => setApiSchemaOpen(true)}>
                API Schema
              </SoloButton>
            ]
            : undefined
        }
      />

      <WorkspaceDependenciesCard
        dependencies={destinationData.dependencies}
        importingTypeName='service'
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Workspace Policies</CardStyles.CardHeader>

        <BasicPoliciesTable policies={destinationData.policies?.policies} paging={pagingData} />
      </CardStyles.Card>

      {apiSchemaOpen && (
        <FancyCodeDisplayerModal
          title={clusterObjRef.name}
          subtitle='API Schema'
          icon={getDestinationKindIcon(DestinationKind.SERVICE)}
          onClose={() => setApiSchemaOpen(false)}>
          <ApiSchemaModalContent serviceRef={clusterObjRef} />
        </FancyCodeDisplayerModal>
      )}
    </CardStyles.CardList>
  );
};
