import { logsApi } from 'Api/logs';
import { Loading } from 'Components/Common/Loading';
import SoloLogViewer from 'Components/Common/SoloLogViewer/SoloLogViewer';
import { SoloLogViewerStyles } from 'Components/Common/SoloLogViewer/SoloLogViewer.style';
import { SoloLogViewerToolbarWithServiceSelection } from 'Components/Common/SoloLogViewer/SoloLogViewerToolbar';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import { GetContainerLogsStreamResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/logs_pb';
import { useEffect, useMemo, useRef, useState } from 'react';
const { useGetAvailableComponentInfo, getContainerLogsStream: createListLogsStream } = logsApi;

const MAX_LOG_LINES_TO_SHOW = 50000;

export const ServiceDetailsLogViewer = ({ cluster, component }: { cluster: string; component: string }) => {
  const { data: availableComponentInfoRaw } = useGetAvailableComponentInfo();
  const isLoading = availableComponentInfoRaw === undefined;

  //
  // State for the SoloLogViewer.
  //
  const [pod, setPod] = useState('');
  const [container, setContainer] = useState('');

  const readyToStream = !!pod && !!container;

  //
  // This gets the clusters/components/etc out of the
  // response and initializes state if needed.
  //
  // Repeated for each item.
  // It's not exactly the same though since each mapping
  // is nested and depends on the parent.
  //
  const availableClusterMap = useMemo(() => {
    if (!availableComponentInfoRaw) return {};
    return availableComponentInfoRaw.attributes;
  }, [availableComponentInfoRaw]);

  const availableComponentMap = useMemo(() => {
    if (!availableClusterMap[cluster ?? '']) return {};
    return availableClusterMap[cluster ?? ''].components;
  }, [availableClusterMap, cluster]);

  const [availablePodsMap, podOptions] = useMemo(() => {
    if (!availableComponentMap[component ?? '']) {
      setPod('');
      return [{}, []];
    }
    const newMap = availableComponentMap[component ?? ''].pods;
    const newMapKeys = Object.keys(newMap);
    if (!newMap[pod ?? '']) {
      setPod(newMapKeys.length === 1 ? newMapKeys[0] : '');
    }
    return [newMap, newMapKeys];
  }, [availableComponentMap, component]);

  const containerOptions = useMemo(() => {
    if (!availablePodsMap[pod ?? '']) {
      setContainer('');
      return [];
    }
    const newContainerOptions = availablePodsMap[pod ?? ''].containers;
    if (!newContainerOptions.includes(container ?? '')) {
      setContainer(newContainerOptions.length === 1 ? newContainerOptions[0] : '');
    }
    return newContainerOptions;
  }, [availablePodsMap, pod]);

  //
  // Set up the log stream based on the selections.
  //
  const logsRef = useRef<string[]>([]);
  const updateTimeoutRef = useRef<NodeJS.Timeout>();
  const [, setLogsLastUpdateTime] = useState<number>();

  const apiserverErrors = useRef<string[]>([]);
  useEffect(() => {
    if (!cluster || !component || !pod || !container) {
      return;
    }
    const newLogStream = createListLogsStream(cluster, component, pod, container);
    const dataCallback = (data: GetContainerLogsStreamResponse) => {
      const handleCallback = () => {
        // Adding randomness can simulate change in the data. Uncomment for testing.
        // let newLogs = [...logsRef.current, ...data.getLogsList().map(l => Math.random() + l)];
        let newLogs = [...logsRef.current, ...data.logs];
        //
        // This slices the logs so no more than MAX_LOG_LINES_TO_SHOW are kept.
        newLogs = newLogs.slice(Math.max(newLogs.length - MAX_LOG_LINES_TO_SHOW, 0));
        logsRef.current = newLogs;
        const newApiserverErrors = data.apiserverErrors;
        apiserverErrors.current = newApiserverErrors;
        if (!!newApiserverErrors.length) {
          // eslint-disable-next-line no-console
          console.error('logged errors', [...newApiserverErrors]);
        }
        //
        // We need to setLogsLastUpdateTime here to queue a re-render when logsRef.current changes.
        if (updateTimeoutRef.current) {
          clearTimeout(updateTimeoutRef.current);
        }
        updateTimeoutRef.current = setTimeout(() => {
          setLogsLastUpdateTime(Date.now);
        }, 500);
      };
      // Setting a timeout prevents this from being UI blocking.
      setTimeout(() => {
        handleCallback();
      }, 0);
      // This can be uncommented for stress-testing.
      // setInterval(() => {
      //   handleCallback();
      // }, 1000);
    };
    newLogStream.onMessage(dataCallback);
    const errorCallback = (err: Error) => {
      // These log stream errors come from the browser and don't necessarily affect the stream.
      // So we can log them here for reference, and not show them on the page.
      // eslint-disable-next-line no-console
      console.error('stream error event', err);
    };
    newLogStream.onError(errorCallback);
    return () => {
      logsRef.current = [];
    };
  }, [cluster, component, pod, container]);

  //
  // Render
  //
  if (isLoading) {
    return <Loading />;
  } else if (podOptions.length === 0 || (pod && containerOptions.length === 0)) {
    return (
      <SoloLogViewerStyles.EmptyLogsContainer height='auto' style={{ padding: '20px' }}>
        <Svg asset={<Asset.NoLogPlaceholder />} height={133} ml='10px' />
        <SoloLogViewerStyles.EmptyLogsTitle>Log Empty</SoloLogViewerStyles.EmptyLogsTitle>
        <SoloLogViewerStyles.EmptyLogsText>No logs are available for this service.</SoloLogViewerStyles.EmptyLogsText>
      </SoloLogViewerStyles.EmptyLogsContainer>
    );
  }
  return (
    <SoloLogViewer
      isWaitingForSelection={!container}
      errorsList={apiserverErrors.current}
      dataSources={{
        initialDataSource: {
          data: logsRef.current,
          isStreaming: readyToStream,
          label: `logs:${cluster}.${component}.${pod}.${container}`
        }
      }}
      ToolbarComponent={SoloLogViewerToolbarWithServiceSelection}
      cluster={cluster}
      clusterDropdownDisabled
      component={component}
      componentDropdownDisabled
      pod={pod}
      podOptions={podOptions}
      onPodSelected={setPod}
      container={container}
      containerOptions={containerOptions}
      onContainerSelected={setContainer}
    />
  );
};
