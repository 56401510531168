import { CardStyles } from 'Components/Common/Card';
import { SoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import { InsightsOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { buildInsightsUrl } from 'utils/url-builders';
import { DashboardStyles } from '../../DashboardLanding.style';
import AnalysisAndInsightsCardContent from './AnalysisAndInsightsCardContent';


const insightsOverview: InsightsOverview = {
  errorCount: 13,
  warningCount: 5,
  infoCount: 10,
  totalCount: 28,
  categoryCounts: {
    Health: 8,
    'Best Practice': 5,
    Security: 3,
    Routing: 1,
    Ingress: 4,
    Resiliency: 5,
    Configuration: 2
  },
  resourcesScanned: 300
};

export const AnalysisAndInsightsCardFakeData = () => {
  return (
    <CardStyles.Card data-testid='analysis-and-insights-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.InsightAnalysisIcon />} color={theme => theme.neptuneBlue} />
        Analysis and Insights
      </DashboardStyles.Header>
      <AnalysisAndInsightsCardContent data={insightsOverview} />
      <DashboardStyles.CardBottomLink>
        <SoloLink small link={buildInsightsUrl()} withArrow>
          DETAILS
        </SoloLink>
      </DashboardStyles.CardBottomLink>
    </CardStyles.Card>
  );
};
