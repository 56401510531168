import styled from '@emotion/styled';
import { Properties } from 'csstype';
import { cssProp } from 'utils/helpers';

const spacerScale = '.25rem';

/**
 * Use a number (e.g. `mx={3}`) for scaled default spacing,
 * or a string for exact values (e.g. `mx='5px'`).
 * - Note: For overrides, `mr`, `mb` and other
 * directional styles override `mx`, `my`.
 */
export const Spacer = styled.div<{
  mx?: string | number;
  my?: string | number;
  mr?: string | number;
  ml?: string | number;
  mt?: string | number;
  mb?: string | number;
  margin?: string | number;
  px?: string | number;
  py?: string | number;
  pr?: string | number;
  pl?: string | number;
  pt?: string | number;
  pb?: string | number;
  padding?: string | number;
  display?: Properties['display'];
  position?: Properties['position'];
}>(props => {
  //
  // Scale numeric spacing values with `spacerScale`.
  ['mx', 'my', 'mr', 'ml', 'mt', 'mb', 'margin', 'px', 'py', 'pl', 'pr', 'pt', 'pb', 'padding']
    .filter(k => (props as any)[k] !== undefined)
    .forEach(k => {
      const value = (props as any)[k] as string | number;
      if (typeof value === 'number') (props as any)[k] = `calc(${value} * ${spacerScale})`;
    });
  //
  // Check for overrides, return styles.
  const { mx, my, mr, ml, mt, mb, margin, px, py, pl, pr, pt, pb, padding } = props;
  const marginRight = mr ?? mx;
  const marginLeft = ml ?? mx;
  const marginTop = mt ?? my;
  const marginBottom = mb ?? my;
  const paddingRight = pr ?? px;
  const paddingLeft = pl ?? px;
  const paddingTop = pt ?? py;
  const paddingBottom = pb ?? py;
  return `
  ${cssProp('display', props.display)}
  ${cssProp('position', props.position)}
  ${cssProp('margin', margin)}
  ${cssProp('margin-right', marginRight)}
  ${cssProp('margin-left', marginLeft)}
  ${cssProp('margin-top', marginTop)}
  ${cssProp('margin-bottom', marginBottom)}
  ${cssProp('padding', padding)}
  ${cssProp('padding-right', paddingRight)}
  ${cssProp('padding-left', paddingLeft)}
  ${cssProp('padding-top', paddingTop)}
  ${cssProp('padding-bottom', paddingBottom)}
`;
});
