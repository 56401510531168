import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Properties } from 'csstype';
import { cssProp } from 'utils/helpers';
import { Spacer } from './spacer';

/**
 * Can be used to quickly create a horizontal, vertical, or centered (horizontal & vertical) layout.
 */
export const FlexLayout = styled(Spacer)<{
  horizontal?: boolean;
  vertical?: boolean;
  gap?: Properties['gap'] | number;
  display?: Properties['display'];
  flexWrap?: Properties['flexWrap'];
  flexGrow?: Properties['flexGrow'];
  justifyContent?: Properties['justifyContent'];
  alignItems?: Properties['alignItems'];
  flexDirection?: Properties['flexDirection'];
  expandChildren?: boolean;
}>(
  ({
    horizontal,
    vertical,
    gap,
    display,
    flexWrap,
    flexGrow,
    justifyContent,
    alignItems,
    flexDirection,
    expandChildren
  }) => {
    if (typeof gap === 'number') gap = gap * 0.25 + 'em';
    return css`
      display: flex;
      ${cssProp('justify-content', !!justifyContent ? justifyContent : !!horizontal ? 'center' : undefined)}
      ${cssProp('align-items', !!alignItems ? alignItems : !!vertical ? 'center' : undefined)}
      ${cssProp('flex-direction', flexDirection)}
      ${cssProp('gap', gap)}
      ${cssProp('display', display)}
      ${cssProp('flex-wrap', flexWrap ?? 'wrap')}
      ${cssProp('flex-grow', flexGrow)}
      max-width: 100%;
      max-height: 100%;
      ${expandChildren &&
      css`
        > * {
          flex-grow: 1;
          width: auto;
        }
      `}
    `;
  }
);

/**
 * Adds a div with `flex-grow: 1` into a flex layout.
 */
export const FlexLayoutSpacer = styled.div<{ flexBasis?: Properties['flexBasis'] }>(
  ({ flexBasis }) => css`
    flex-grow: 1;
    ${cssProp('flex-basis', flexBasis)}
  `
);
