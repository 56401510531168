import { InfoTooltip } from 'Components/Common/InfoTooltip';
import { Loading } from 'Components/Common/Loading';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Asset } from 'assets';
import {
  Insight_SYS0009Data,
  Insight_SYS0010Data
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import React, { useMemo } from 'react';
import { FIPSCardStyles as Styles } from './FIPSCard.style';

function compactNumber(num: number) {
  return Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(
    num
  );
}

const useReducedInsightData = (dataList: Insight_SYS0009Data[] | Insight_SYS0010Data[] | undefined | 'loading') => {
  return useMemo<Insight_SYS0009Data | undefined>(
    () =>
      typeof dataList === 'object' && dataList.length > 0
        ? dataList.reduce(
          (comb, data) => {
            comb.fipsCompliantIstioWorkloads += data.fipsCompliantIstioWorkloads;
            comb.totalIstioWorkloads += data.totalIstioWorkloads;
            comb.uniqueFipsVersions.push(...data.uniqueFipsVersions);
            return comb;
          },
          { fipsCompliantIstioWorkloads: 0, uniqueFipsVersions: [], totalIstioWorkloads: 0 }
        )
        : undefined,
    [dataList]
  );
};

interface FipsInsightBoxProps {
  title: string;
  icon: React.ReactNode;
  countDesc: React.ReactNode;

  compliant: number;
  total: number;
  versionList: string[];
}
const FipsInsightBox = ({ compliant, total, versionList, title, icon, countDesc }: FipsInsightBoxProps) => {
  const state = compliant < total ? State.WARNING : State.ACCEPTED;
  return (
    <Styles.InsightBox state={state}>
      <Styles.InsightBoxHeader>
        {title}{' '}
        {!!versionList.length && (
          <InfoTooltip
            placement='bottom'
            size={18}
            tooltip={
              <Styles.Tooltip>
                <Styles.TooltipTitle>Image Versions</Styles.TooltipTitle>
                <ul>
                  {versionList.map(v => (
                    <li key={v}>{v}</li>
                  ))}
                </ul>
              </Styles.Tooltip>
            }
          />
        )}
      </Styles.InsightBoxHeader>
      <Styles.InsightBody hideIcon={!icon}>
        <div>{icon}</div>
        <div>
          <Styles.InsightBoxCount state={state}>
            {compactNumber(compliant)}/{compactNumber(total)}
          </Styles.InsightBoxCount>
          <Styles.InsightBoxCountDesc>{countDesc}</Styles.InsightBoxCountDesc>
        </div>
      </Styles.InsightBody>
    </Styles.InsightBox>
  );
};

export const FIPSCardContent = ({
  controlPlane,
  dataPlane
}: {
  controlPlane: Insight_SYS0009Data[] | undefined | 'loading';
  dataPlane: Insight_SYS0010Data[] | undefined | 'loading';
}) => {
  const controlPlaneCombined = useReducedInsightData(controlPlane);
  const dataPlaneCombined = useReducedInsightData(dataPlane);

  const hideIcons = useMemo(() => {
    // To avoid long numbers causing issues, it was decided if either of the number lines become to long (a total of
    // 5 for either card (minus the `/`) seeming to be the sweet spot), then we choose the icon for both cards to
    // disappear, as per a discussion (I assume for design consistency).
    // We use `compactNumber` here, as that value is what will be used in the output
    return (
      (
        compactNumber(controlPlaneCombined?.fipsCompliantIstioWorkloads ?? 0) +
        compactNumber(controlPlaneCombined?.totalIstioWorkloads ?? 0)
      ).length > 5 ||
      (
        compactNumber(dataPlaneCombined?.fipsCompliantIstioWorkloads ?? 0) +
        compactNumber(dataPlaneCombined?.totalIstioWorkloads ?? 0)
      ).length > 5
    );
  }, [controlPlaneCombined, dataPlaneCombined]);

  return !controlPlane && !dataPlane ? (
    <FlexLayout horizontal vertical mt={3}>
      <SoloEmptySimple description='No Data' icon={<Asset.FipsIcon />} iconSize={75} greyscale />
    </FlexLayout>
  ) : (
    <Styles.Grid>
      {controlPlane === 'loading' ? (
        <Loading center small />
      ) : !controlPlaneCombined ? (
        <SoloEmptySimple description='No Data' icon={<Asset.FipsIcon />} iconSize={75} greyscale />
      ) : (
        <FipsInsightBox
          title='Control Plane'
          icon={hideIcons ? undefined : <Asset.ControlPlaneIcon />}
          countDesc={<>Control Planes Using FIPS</>}
          compliant={controlPlaneCombined.fipsCompliantIstioWorkloads}
          total={controlPlaneCombined.totalIstioWorkloads}
          versionList={controlPlaneCombined.uniqueFipsVersions}
        />
      )}
      {dataPlane === 'loading' ? (
        <Loading center small />
      ) : !dataPlaneCombined ? (
        <SoloEmptySimple description='No Data' icon={<Asset.FipsIcon />} iconSize={75} greyscale />
      ) : (
        <FipsInsightBox
          title='Data Plane'
          icon={hideIcons ? undefined : <Asset.DataPlaneIcon />}
          countDesc={<>Workloads Using FIPS</>}
          compliant={dataPlaneCombined.fipsCompliantIstioWorkloads}
          total={dataPlaneCombined.totalIstioWorkloads}
          versionList={dataPlaneCombined.uniqueFipsVersions}
        />
      )}
    </Styles.Grid>
  );
};
