import { overviewApi } from 'Api/overview';
import { PortalsIcon } from 'assets/assets';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloGridTableToggle, { isTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { OverviewTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { PortalsLandingBody } from './PortalsLandingBody';

const { useListPortals } = overviewApi;

const PortalsLanding = () => {
  //
  // State
  //
  di(useListPortals, usePermissionGateRedirect);
  const {
    pagingData,
    useSetPagingTotal,
    apiPaginationObject: portalsPaginationObject
  } = useSoloPaging(OverviewTablePageSize);
  usePermissionGateRedirect(Permission.PortalEnabled);
  const [isTable, setIsTable] = useState(isTableViewPreferred());
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const filtersOn = !!nameFilter.length || !!statusFilter.length;

  const { data: listPortalsResponse, error: listPortalsError } = useListPortals(
    portalsPaginationObject,
    nameFilter,
    statusFilter
  );
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(listPortalsResponse);

  useSetPagingTotal(listPortalsResponse?.filteredTotalPortals);

  //
  // Render
  //
  const isLoading = listPortalsResponse === undefined;
  return (
    <OverviewStyles.Container data-testid='portals-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <PortalsIcon />}
          Portals
        </OverviewStyles.Header.Title>
        {!listPortalsError && (
          <OverviewStyles.Header.FiltersWrapper>
            <HealthCountBox
              onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
              onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
              isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
              isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
              totalCount={listPortalsResponse?.totalPortals}
              errorCount={listPortalsResponse?.portalErrors}
            />
            <OverviewStyles.Header.Filters filters={1}>
              <SoloInput
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label={'Search by name'}
                onChange={evt => setNameFilter(evt.target.value)}
              />
              <SoloGridTableToggle isTable={isTable} onChange={newIsTable => setIsTable(newIsTable)} />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <PortalsLandingBody
        filtersOn={filtersOn}
        items={listPortalsResponse?.portals ?? []}
        isLoading={isLoading}
        pagingData={pagingData}
        itemsError={listPortalsError}
        isTable={isTable}
        data-testid='portals-landing-body-empty'
        icon={<PortalsIcon />}
        // TODO: add in docs. https://github.com/solo-io/gloo-mesh-enterprise/issues/8361
        docsLink={false}
        resourceNamePlural='Portals'
      />
    </OverviewStyles.Container>
  );
};

export default PortalsLanding;
