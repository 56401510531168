import { RpcError } from "@protobuf-ts/runtime-rpc";
import { DataError } from 'Components/Common/DataError';
import { Loading } from 'Components/Common/Loading';
import { BaseLandingBodyProps } from 'Components/Common/Overview/LandingBody';
import { SoloLink } from 'Components/Common/SoloLink';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn, createStatusColumn } from 'Components/Common/SoloTableCells';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListWorkspacesResponse_CoreWorkspaceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { buildWorkspaceDetailsUrl } from 'utils/url-builders';
import { WorkspacesLandingStyles as Styles } from './WorkspacesLanding.style';
import { WorkspacesLandingEmpty } from './WorkspacesLandingEmpty';

//
// Table variant
//
interface TableFields {
  key: string;
  status: Status | undefined;
  name: string;
  numClusters: number;
  numNamespaces: number;
  numServices: number;
  details?: string;
}
const columns: SoloColumnsType<TableFields> = [
  createStatusColumn({ dataIndex: 'status' }),
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  { title: 'Number of Clusters', dataIndex: 'numClusters' },
  { title: 'Number of Namespaces', dataIndex: 'numNamespaces' },
  { title: 'Number of Destinations', dataIndex: 'numServices' },
  {
    title: 'Details',
    dataIndex: 'details',
    render(details) {
      return details ? (
        <SoloLink link={buildWorkspaceDetailsUrl(details)} aria-label='Go to details' withArrow>
          Details
        </SoloLink>
      ) : undefined;
    }
  }
];

const WorkspacesLandingBodyTable = ({
  items: workspaces,
  pagingData
}: BaseLandingBodyProps<ListWorkspacesResponse_CoreWorkspaceSummary>) => {
  const tableData = useMemo<TableFields[]>(
    () =>
      workspaces.map((workspace, ind) => ({
        key: workspace.workspaceRef ? buildIdFromRef(workspace.workspaceRef) : ind + '',
        status: workspace.status,
        name: workspace.workspaceRef?.name ?? '',
        numClusters: workspace.numClusters,
        numNamespaces: workspace.numNamespaces,
        numServices: workspace.numServices,
        details: workspace.workspaceRef?.name
      })),
    [workspaces]
  );

  //
  // Render
  //
  return (
    <SoloTable data-testid='workspaces-landing-body' dataSource={tableData} columns={columns} paging={pagingData} />
  );
};

interface Props {
  items: ListWorkspacesResponse_CoreWorkspaceSummary[];
  pagingData: PagingProps;
  isLoading: boolean;
  itemsError: Partial<RpcError> | undefined;
}
export const WorkspacesLandingBody = (props: Props) => {
  const { items, isLoading, itemsError, pagingData } = props;
  //
  // Check for errors, empty, and loading states
  if (!!itemsError) {
    return <DataError error={itemsError} />;
  }
  if (isLoading) {
    return <Loading center={true} message='Loading workspaces data...' />;
  }
  // if (!!workspaces && !workspaces.workspaces.length) {
  if (pagingData.total === 0 || !items) {
    return <WorkspacesLandingEmpty />;
  }

  return (
    <Styles.ContentBody>
      <WorkspacesLandingBodyTable items={items} pagingData={pagingData} />
    </Styles.ContentBody>
  );
};
