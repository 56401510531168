import { GetUIFeatureFlagsResponse_License_LicenseState } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb';
import { useEffect, useRef, useState } from 'react';
import { di } from 'react-magnetic-di';
import { NoticeUrgencyLevel, useNotifications } from 'utils/notificationsystem';
import { useLicenses } from 'utils/permissions';

const getUpcomingExpirationWarningData = (subject: string) => ({
  label: 'Upcoming License Expiration',
  message: `Your ${subject} license will expire within the next 30 days, please contact your Solo account executive for
  assistance.`,
  level: NoticeUrgencyLevel.Warning,
  dismissable: false,
  id: `license-checker-warning-${subject.toLowerCase().replaceAll(' ', '-')}`,
  'data-testid': 'license-checker-warning'
});

const getLoadErrorData = (error: string) => ({
  label: 'Error Loading License Status',
  message: error,
  level: NoticeUrgencyLevel.Error,
  dismissable: false,
  id: 'license-checker-load-error'
});

const getErrorListErrorData = (licenseErrors: string[] | undefined) => ({
  label: 'License Status',
  message: !!licenseErrors ? licenseErrors : 'No valid licenses found.',
  level: NoticeUrgencyLevel.Error,
  dismissable: false,
  id: 'license-checker-error-list'
});

/* This component's entire purpose is to have a life where the check for licenses lives
     as a single instance in the app, as the permissions hook instatiates individually for all cases. */
// Unfortunately, this needs to be checked from *somewhere*
export const LicenseChecker = () => {
  di(useNotifications, useLicenses);
  const { notifyUser, dismissNotification } = useNotifications();
  const { licenses, errors } = useLicenses();

  const [warningNotificationId, setWarningNotificationId] = useState<string>();

  // License errorsList - this includes expirations (but not upcoming expirations)
  const noLicensesNotificationIdRef = useRef<string>();
  useEffect(() => {
    let errorsList: string[] = Object.values(licenses).flatMap(license => license?.errors ?? []);

    if (errorsList.length > 0) {
      // If notification already exists notifyUser will replace it with this one
      noLicensesNotificationIdRef.current = notifyUser(getErrorListErrorData(errorsList));
    }
    // Remove notifications if things are in a better state
    else if (noLicensesNotificationIdRef.current) {
      dismissNotification(noLicensesNotificationIdRef.current);
      noLicensesNotificationIdRef.current = undefined;
    }
  }, [licenses]);

  // Upcoming license expiration warning
  useEffect(() => {
    let comingExpirationWarningSeen = false;

    const listOfLicenses = [
      { license: licenses.gateway, subject: 'Gloo Mesh Gateway' },
      { license: licenses.mesh, subject: 'Gloo Mesh' },
      { license: licenses.network, subject: 'Gloo Network' },
      { license: licenses.core, subject: 'Gloo Mesh Core' }
    ];

    listOfLicenses.forEach(({ license, subject }) => {
      if (license?.state === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRES_SOON) {
        if (!warningNotificationId) {
          setWarningNotificationId(notifyUser(getUpcomingExpirationWarningData(subject)));
        }
        comingExpirationWarningSeen = true;
      }
    });

    // Remove notifications if things are in a better state
    if (!!warningNotificationId && !comingExpirationWarningSeen) {
      dismissNotification(warningNotificationId);
      setWarningNotificationId(undefined);
    }
  }, [licenses]);

  // License loading error
  const loadErrorNotificationIdRef = useRef<string>();
  useEffect(() => {
    if (errors.length > 0) {
      // If notification already exists notifyUser will replace it with this one
      loadErrorNotificationIdRef.current = notifyUser(getLoadErrorData(errors[0].message ?? ''));
    }
    // Remove notifications if things are in a better state
    else if (loadErrorNotificationIdRef.current) {
      dismissNotification(loadErrorNotificationIdRef.current);
      loadErrorNotificationIdRef.current = undefined;
    }
  }, [errors.length]);
  return <></>;
};
