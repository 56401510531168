import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloModal } from 'Components/Common/SoloModal';
import { SoloModalStyles } from 'Components/Common/SoloModal.style';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { BasicDestinationsTable } from 'Components/Features/Destinations/BasicDestinationsTable';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetRateLimitPolicyDetails, useGetRateLimitClientConfig } = policiesApi;

const policyType = PolicyType.RATE_LIMIT;

const ConfigModalBody = ({ clusterObjRef }: Props) => {
  di(useGetRateLimitClientConfig);
  const { data: config, error } = useGetRateLimitClientConfig(clusterObjRef);

  if (!!error) {
    return (
      <CardStyles.Card>
        <DataError error={error} />
      </CardStyles.Card>
    );
  } else if (!config) {
    return <Loading message={`Retrieving ${clusterObjRef.name} config details...`} />;
  }

  return (
    <CardStyles.Card>
      <FancyCodeDisplayer type='yaml' contentString={config.yaml} />
    </CardStyles.Card>
  );
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const RateLimitDetails = ({ clusterObjRef }: Props) => {
  di(useGetRateLimitPolicyDetails);
  const routesPaging = useSoloPaging();
  const destinationsPaging = useSoloPaging();
  const [configYamlOpen, setConfigYamlOpen] = useState(false);

  const { data: policyData, error: policyError } = useGetRateLimitPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject,
    destinationsPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);
  destinationsPaging.useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.ApiAccessPlanIcon />,
              title: 'Rate Limit Config',
              data: <SecondaryInfo small items={[{ label: 'Type', data: 'Advanced' }]} />,
              right: <SoloButton onClick={() => setConfigYamlOpen(true)}>View Rate Limit Config</SoloButton>
            },
            {
              icon: <Asset.ServerIcon />,
              title: 'Rate Limit Server',
              data: !!policyData.serverSettingsDestinationServer ? (
                <SecondaryInfo
                  small
                  items={[
                    { label: 'Name', data: policyData.serverSettingsDestinationServer.name },
                    {
                      label: 'Namespace',
                      data: policyData.serverSettingsDestinationServer.namespace
                    },
                    {
                      label: 'Cluster',
                      data: policyData.serverSettingsDestinationServer.clusterName
                    }
                  ]}
                />
              ) : (
                'No auth server configured'
              )
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable
          destinations={policyData.destinations?.destinations}
          paging={destinationsPaging.pagingData}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>

      {configYamlOpen && (
        <SoloModal visible width={960} onClose={() => setConfigYamlOpen(false)}>
          <SoloModalStyles.SimpleTitle>Rate Limit Config</SoloModalStyles.SimpleTitle>
          <ConfigModalBody clusterObjRef={clusterObjRef} />
        </SoloModal>
      )}
    </CardStyles.CardList>
  );
};
