import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { GetClusterDetailsCardsResponse_IstioSummaryOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';

export const ClusterIstioOverviewListCard = ({
  istioSummaryOverview
}: {
  istioSummaryOverview: GetClusterDetailsCardsResponse_IstioSummaryOverview | undefined;
}) => {
  return (
    <SoloListCard
      data-testid='istio-overview-card'
      title={
        <FlexLayout gap='10px' vertical>
          Overview <SecondaryInfoItem small label='Version' data={istioSummaryOverview?.version ?? 'unknown'} />
        </FlexLayout>
      }
      dataSource={[
        { data: 'Root Namespace', right: istioSummaryOverview?.rootNamespace },
        { data: 'Trust Domain', right: istioSummaryOverview?.trustDomain },
        { data: 'Managed By Gloo', right: istioSummaryOverview?.managedByGlooMesh ? 'Yes' : 'No' }
      ]}
      hideDottedLine
    />
  );
};
