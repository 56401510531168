import { css } from '@emotion/react';
import styled from '@emotion/styled';

export namespace SoloCheckboxStyles {
  export const OnlyCheckbox = styled.span(
    ({ theme }) => css`
      color: ${theme.septemberGrey};

      .ant-checkbox-wrapper {
        align-items: center;
      }

      .ant-checkbox {
        top: unset;
        .ant-checkbox-inner {
          background: ${theme.januaryGrey};
          border: 1px solid ${theme.juneGrey};
          border-radius: 5px;
          width: 18px;
          height: 18px;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background: ${theme.puddleBlue};
            border-color: ${theme.seaBlue};

            &:after {
              border-color: ${theme.seaBlue};
              border-width: 1px;
              transform: rotate(45deg) scale(1) translate(-37%, -66%);
              height: 9px;
            }
          }
        }
      }
    `
  );

  type CheckboxWrapperProps = { checked?: boolean };
  export const CheckboxWrapper = styled.div<CheckboxWrapperProps>(
    ({ theme, checked }) => css`
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-radius: 10px;
      width: 100%;
      padding: 10px 16px;
      background: white;
      border: 1px solid ${theme.juneGrey};

      padding: 7px 7px 7px 16px;
      color: ${theme.septemberGrey};
      transition: background 0.6s ease-in, border 0.3s;

      ${!!checked
        ? css`
            background: ${theme.dropBlue};
            border-color: ${theme.seaBlue};
          `
        : ``}

      label {
        margin-bottom: 0 !important;
      }

      .ant-checkbox {
        .ant-checkbox-inner {
          background: ${theme.januaryGrey};
          border: 1px solid ${theme.juneGrey};
          border-radius: 5px;
          width: 18px;
          height: 18px;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background: ${theme.puddleBlue};
            border-color: ${theme.seaBlue};

            &:after {
              border-color: ${theme.seaBlue};
              border-width: 1px;
              transform: rotate(45deg) scale(1) translate(-37%, -66%);
              height: 9px;
            }
          }
        }
      }
    `
  );
}
