import { Asset } from 'assets';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { useState } from 'react';
import { SoloButton, SoloButtonSolid } from 'Styles/CommonEmotions/button';
import { docLinks } from 'utils/url-external-links-map';
import { AddWorkspaceModalBody } from './AddWorkspaceModalBody';
import { WorkspacesLandingEmptyStyles as Styles } from './WorkspacesLanding.style';

export const WorkspacesLandingEmpty = () => {
  const [addWorkspaceModalOpen, setAddWorkspaceModalOpen] = useState(false);

  return (
    <Styles.WorkspaceContent>
      <Styles.Top>
        <Styles.RegisteringIconHolder>
          <Asset.WorkspaceIcon />
        </Styles.RegisteringIconHolder>

        <Styles.RegisteringTitle>No Workspaces Found</Styles.RegisteringTitle>
        <Styles.RegisteringBody>
          Group the Kubernetes namespaces and clusters for each of your teams into Gloo Platform workspaces. Gloo
          Platform automatically discovers and shares service mesh resources with the workspace. You can even share
          certain resources with other workspaces by defining import and export settings for the workspaces
        </Styles.RegisteringBody>
      </Styles.Top>

      <Styles.RegisterButtons>
        <SoloButtonSolid onClick={() => setAddWorkspaceModalOpen(true)}>ADD WORKSPACE</SoloButtonSolid>
        <ExternalSoloLink href={docLinks.enterprise.multi_tenancy} newTab>
          <SoloButton>VIEW DOCS</SoloButton>
        </ExternalSoloLink>
      </Styles.RegisterButtons>
      {addWorkspaceModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddWorkspaceModalOpen(false)}>
          <AddWorkspaceModalBody />
        </SoloModal>
      )}
    </Styles.WorkspaceContent>
  );
};
