import { insightsApi } from 'Api/insights';
import { Loading } from 'Components/Common/Loading';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import { OverviewTablePageSize, SoloPaginationJustArrows } from 'Components/Common/SoloPagination';
import { Asset } from 'assets';
import {
  Insight,
  Insight_Severity
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import {
  Code,
  ListInsightsRequest_InsightsFilter,
  ListInsightsRequest_InsightsFilter_Target
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { useCallback, useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useLocation, useNavigate } from 'react-router-dom';
import { InsightCodeGroup } from 'utils/dashboard/dashboard-types';
import { buildIdFromRef } from 'utils/helpers';
import { useDebouncedRefreshIndicator, useEventListener, useTimeout } from 'utils/hooks';
import { NoticeUrgencyLevel, useNotifications } from 'utils/notificationsystem';
import { encodeUrlSearchParamValue, getUrlSearchVariable } from 'utils/url-builder-helpers';
import { insightsModalParamsKeys, insightsSearchParamsKeys } from 'utils/url-builders';
import { InsightsLandingBody } from './InsightsLandingComponents/InsightsLandingBody';
import { InsightsLandingFilters } from './InsightsLandingComponents/InsightsLandingFilters';
import InsightsLandingTagsList from './InsightsLandingComponents/InsightsLandingTagsList';
import { docLinks } from 'utils/url-external-links-map';
const { useListInsights } = insightsApi;

export interface InsightsLandingFiltersObject {
  // These are used to filter by code.
  searchText: string;
  codeGroups: InsightCodeGroup[];
  // These are used to filter by severity and cluster.
  severities: Insight_Severity[];
  clusters: string[];
}

const getUrlSearchFilters = (): InsightsLandingFiltersObject => {
  return {
    clusters: getUrlSearchVariable(insightsSearchParamsKeys.clusters, []),
    codeGroups: getUrlSearchVariable(insightsSearchParamsKeys.codeGroups, []),
    searchText: getUrlSearchVariable(insightsSearchParamsKeys.searchText, ''),
    severities: getUrlSearchVariable(insightsSearchParamsKeys.severities, [])
  };
};

export interface InsightsLandingDetailsModalInfo {
  modalCode?: {
    key: string;
    group?: string;
  };
  modalTarget?: {
    type: string;
    target: string;
  };
}

const getModalInfoFromURL = (): InsightsLandingDetailsModalInfo | undefined => {
  const modalCode = getUrlSearchVariable(insightsModalParamsKeys.modalCode, undefined);
  const modalTarget = getUrlSearchVariable(insightsModalParamsKeys.modalTarget, undefined);
  if (!modalCode || !modalTarget) {
    return undefined;
  }
  return { modalCode, modalTarget };
};

export const insightAndModalInfoMatches = (insight: Insight, modalInfo: InsightsLandingDetailsModalInfo) => {
  const { modalCode, modalTarget } = modalInfo;
  const modalCodeGroup = modalCode?.group ?? '';
  const modalCodeKey = modalCode?.key ?? '';
  const insightCodeGroup = insight.code?.group ?? '';
  const insightCodeKey = insight.code?.key ?? '';
  // If this isn't the insight, return
  if (insightCodeKey !== modalCodeKey || insightCodeGroup !== modalCodeGroup) {
    return;
  }
  if (modalTarget?.type === 'global') {
    return insight.target?.target.oneofKind === 'global' && !!insight.target.target.global;
  }
  if (modalTarget?.type === 'cluster') {
    return insight.target?.target.oneofKind === 'cluster' && modalTarget?.target === insight.target.target.cluster.name;
  }
  if (insight.target?.target.oneofKind !== 'resource') {
    return false;
  }
  return buildIdFromRef(insight.target?.target.resource) === modalTarget?.target;
};

export const InsightsLanding = ({ isSecurity = false }: { isSecurity?: boolean }) => {
  di(useListInsights);
  const location = useLocation();
  const navigate = useNavigate();
  const notificationsSystem = useNotifications();

  //
  // Pagination
  //
  // This pagination works differently from other landing pages
  // because we can only move forward/backward through the data
  // one page at a time.
  //
  // The curRequestCursor is the cursor passed to the current request.
  // If it is changed, the data is refetched for the new cursor.
  //
  const [curRequestCursor, setCurRequestCursor] = useState<string>('');
  //
  // prevCursors is a stack of previous cursors, which we can pop off
  // in order to return to the previous page.
  const [prevCursors, setPrevCursors] = useState<string[]>([]);

  const [searchFilters, setSearchFilters] = useState<InsightsLandingFiltersObject>(getUrlSearchFilters());
  const [shouldPushNextFilterChangeToHistory, setShouldPushNextFilterChangeToHistory] = useState(false);
  const filtersOn = Object.entries(searchFilters)
    .filter(([k, _]) => k !== 'modalCode' && k !== 'modalTarget')
    .some(([_, v]) => (Array.isArray(v) ? !!v.length : !!v));

  //
  // This transforms the search filters into the filter object the api uses for the request.
  //
  const [filterForRequest, setFilterForRequest] = useState<ListInsightsRequest_InsightsFilter>();
  useEffect(() => {
    let codesList: Code[] = [];
    const system = { value: false };
    let global: { value: boolean } | undefined = undefined;
    // For each search word
    const searchPieces = searchFilters.searchText.trim().replaceAll(/\s+/g, ' ').split(' ');
    for (const word of searchPieces) {
      const upperWord = word.toLocaleUpperCase();
      //
      // SYSTEM
      //
      if (upperWord === 'SYSTEM') {
        system.value = true;
        continue;
      }
      //
      // GLOBAL TARGET
      //
      if (upperWord === 'GLOBAL') {
        global = { value: true };
        continue;
      }
      //
      // CODES
      //
      // Try to get the code group and key out of it.
      let group = '';
      const groupMatch = /^[A-Z]+/.exec(upperWord);
      if (!!groupMatch?.[0]) {
        group = groupMatch[0];
      }
      let key = '';
      const keyMatch = /\d+$/.exec(word);
      if (!!keyMatch?.[0]) {
        key = keyMatch[0];
      }
      //
      // If we have something, add it to the list.
      if (!!group || !!key) {
        codesList.push({ group, key });
      }
    }

    // For each group filter value
    for (const group of searchFilters.codeGroups) {
      //
      // Check if we added the code group already.
      const codeWithGroup = codesList.find(c => c.group === group);
      //
      // If not, then push a group with an empty key to match this group.
      if (!codeWithGroup) {
        codesList.push({ group, key: '' });
      }
    }
    //
    // De-dupe the codes list and return it.
    // This could be made more efficient with a dictionary above, but this list won't get too big
    // since it should just be a few user-inputted codes.
    codesList = codesList.reduce(
      (prev, cur) => (prev.find(p => p.group === cur.group && p.key === cur.key) ? prev : [...prev, cur]),
      codesList
    );
    //
    // Set the target property
    let target: ListInsightsRequest_InsightsFilter_Target | undefined = undefined;
    if (!!global || !!searchFilters.clusters.length) {
      target = {
        global,
        clusters: searchFilters.clusters
      };
    }

    const newFilterForRequest: ListInsightsRequest_InsightsFilter = {
      system,
      codes: !isSecurity ? codesList : [{ group: 'SEC', key: '' }],
      target,
      severities: searchFilters.severities
    };
    // If the filter is different, reset the pagination.
    if (JSON.stringify(newFilterForRequest) !== JSON.stringify(filterForRequest)) {
      setCurRequestCursor('');
      setPrevCursors([]);
    }
    setFilterForRequest(newFilterForRequest);
  }, [searchFilters, isSecurity]);

  //
  // Fetch data
  //
  const limit = OverviewTablePageSize;
  const { data: insightsResponse, error: insightsError } = useListInsights(filterForRequest, curRequestCursor, limit);
  const isLoading = insightsResponse === undefined;
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(insightsResponse);

  //
  // Search Filters
  //

  // Update the URL after a delay, when the searchFilter changes.
  const searchParamsTimeout = useTimeout(
    () => {
      const newSearchParams = new URLSearchParams(window.location.search);
      // Set/Delete each value
      if (!!searchFilters.clusters.length) {
        newSearchParams.set(insightsSearchParamsKeys.clusters, encodeUrlSearchParamValue(searchFilters.clusters));
      } else {
        newSearchParams.delete(insightsSearchParamsKeys.clusters);
      }
      if (!!searchFilters.codeGroups.length) {
        newSearchParams.set(insightsSearchParamsKeys.codeGroups, encodeUrlSearchParamValue(searchFilters.codeGroups));
      } else {
        newSearchParams.delete(insightsSearchParamsKeys.codeGroups);
      }
      if (!!searchFilters.searchText.trim()) {
        newSearchParams.set(insightsSearchParamsKeys.searchText, encodeUrlSearchParamValue(searchFilters.searchText));
      } else {
        newSearchParams.delete(insightsSearchParamsKeys.searchText);
      }
      if (!!searchFilters.severities.length) {
        newSearchParams.set(insightsSearchParamsKeys.severities, encodeUrlSearchParamValue(searchFilters.severities));
      } else {
        newSearchParams.delete(insightsSearchParamsKeys.severities);
      }

      const newSearchParamsString = `?${newSearchParams.toString()}`;
      if (window.location.search === newSearchParamsString) {
        // Stop here if there were no changes to the search params.
        return;
      }
      // Otherwise update the URL.
      const newHistoryState = {
        pathname: location.pathname,
        search: newSearchParamsString
      };

      if (shouldPushNextFilterChangeToHistory) {
        // Push this URL on to the history stack, so it is saved in browser history.
        navigate(newHistoryState);
      } else {
        // Keep the URL updated by replacing it. This doesn't push to it.
        navigate(newHistoryState, { replace: true });
      }
    },
    300,
    [
      searchFilters.clusters,
      searchFilters.codeGroups,
      searchFilters.searchText,
      searchFilters.severities,
      shouldPushNextFilterChangeToHistory
    ]
  );

  // When navigating, update the searchFilter object accordingly.
  useEventListener(
    window,
    'popstate',
    () => {
      if (searchParamsTimeout !== null) {
        clearTimeout(searchParamsTimeout);
      }
      const newSearchFilter = getUrlSearchFilters();
      setSearchFilters(newSearchFilter);
      // Do not push this next filter change to history.
      setShouldPushNextFilterChangeToHistory(false);
    },
    [searchParamsTimeout]
  );

  //
  // Insight Details Modal
  //

  // Gets the deep-linked modal info when page loads.
  const [modalInfo, setModalInfo] = useState(getModalInfoFromURL());

  // Updates URL parameters when opening/closing the modal.
  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);
    if (!!modalInfo?.modalCode?.key) {
      newSearchParams.set(insightsModalParamsKeys.modalCode, encodeUrlSearchParamValue(modalInfo?.modalCode));
    } else {
      newSearchParams.delete(insightsModalParamsKeys.modalCode);
    }
    // Below, if target or type exist the other should as well, but no real reason to
    //   be so conservative about checks.
    if (!!modalInfo?.modalTarget?.target || !!modalInfo?.modalTarget?.type) {
      newSearchParams.set(insightsModalParamsKeys.modalTarget, encodeUrlSearchParamValue(modalInfo?.modalTarget));
    } else {
      newSearchParams.delete(insightsModalParamsKeys.modalTarget);
    }
    // Keep the URL updated by replacing it. This doesn't push to it.
    navigate({ pathname: location.pathname, search: `?${newSearchParams.toString()}` }, { replace: true });
  }, [modalInfo]);

  // Notifies the user if the insight isn't found.
  useEffect(() => {
    if (!!isLoading || !modalInfo?.modalCode || !modalInfo.modalTarget) {
      return;
    }
    const modalCodeGroup = modalInfo.modalCode?.group ?? '';
    const modalCodeKey = modalInfo.modalCode?.group ?? '';
    // Check if there are matching insights in the response
    // (note that this just checks the page so it should be filtered at this point)
    const matchingInsightsExist = !!insightsResponse.insights.some(insight =>
      insightAndModalInfoMatches(insight, modalInfo)
    );
    if (!!matchingInsightsExist) {
      return;
    }

    // If there is no matching insight, show a notification.
    notificationsSystem.notifyUser({
      message: `Insight with group: ${modalCodeGroup} and code: ${modalCodeKey} wasn't found.`,
      level: NoticeUrgencyLevel.Info,
      dismissable: true
    });
    setModalInfo(undefined);
  }, [modalInfo]);

  const onDetailsModalInfoChange = useCallback(
    (newModalInfo: InsightsLandingDetailsModalInfo) => setModalInfo(newModalInfo),
    [setModalInfo]
  );

  //
  // Render
  //
  return (
    <OverviewStyles.Container data-testid='insights-landing'>
      <OverviewStyles.Header.Header>
        {!isSecurity ? (
          <OverviewStyles.Header.Title>
            {showRefreshIndicator ? <Loading small /> : <Asset.InsightAnalysisIcon />}
            Insights
          </OverviewStyles.Header.Title>
        ) : (
          <OverviewStyles.Header.Title>
            {showRefreshIndicator ? <Loading small /> : <Asset.InsightAnalysisIcon />}
            Security Insights
          </OverviewStyles.Header.Title>
        )}
        {!insightsError && (
          <InsightsLandingFilters
            filters={searchFilters}
            onFiltersChange={(newFilters, shouldPushToHistory) => {
              setShouldPushNextFilterChangeToHistory(shouldPushToHistory);
              setSearchFilters(newFilters);
            }}
            healthCounts={{
              info: insightsResponse?.infoInsights,
              warning: insightsResponse?.warningInsights,
              error: insightsResponse?.errorInsights
            }}
            isSecurity={isSecurity}
          />
        )}
      </OverviewStyles.Header.Header>
      {filtersOn && (
        <InsightsLandingTagsList
          filters={searchFilters}
          onFiltersChange={newFilters => {
            // Do push this next filter change to history.
            setShouldPushNextFilterChangeToHistory(true);
            setSearchFilters(newFilters);
          }}
        />
      )}
      <InsightsLandingBody
        filtersOn={filtersOn}
        isLoading={isLoading}
        itemsError={insightsError}
        isTable={true}
        items={insightsResponse?.insights ?? []}
        pagingData={{
          // The unique paging for this page means this data is mostly
          //   fake. However, we need to pass the `total` so the landing
          //   page knows if it should display the empty state.
          currentPage: 1,
          pageSize: 1,
          total: insightsResponse?.totalInsights ?? 0,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange: () => {}
        }}
        data-testid='insights-landing-body-empty'
        icon={<Asset.InsightAnalysisIcon />}
        docsLink={docLinks.core.insights}
        resourceNamePlural='Insights'
        extraProps={{
          detailsModalInfo: modalInfo,
          onDetailsModalInfoChange
        }}
      />
      {!!insightsResponse?.totalInsights && (
        <SoloPaginationJustArrows
          total={insightsResponse?.totalInsights ?? 0}
          prevDisabled={prevCursors.length === 0}
          onPrevClick={function (): void {
            setCurRequestCursor(prevCursors.pop() ?? '');
          }}
          nextDisabled={!insightsResponse?.cursor}
          onNextClick={function (): void {
            if (!insightsResponse) {
              return;
            }
            prevCursors.push(curRequestCursor);
            setCurRequestCursor(insightsResponse.cursor);
          }}
        />
      )}
    </OverviewStyles.Container>
  );
};
