import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';

export function convertRefToUrl(ref?: Partial<ClusterObjectRef>) {
  return [
    ref?.name ?? '',
    ref?.namespace ?? '',
    ref?.clusterName // no default in case it's a normal ObjectRef
  ]
    .filter(s => !!s)
    .join('/');
}

/** Gets URL search parameter values that have been added with
 * ``encodeUrlSearchParamValue()`.
 * @param searchParamString The URL search parameter variable name.
 * @param defaultValue The value to return if the variable is not in the URL.
 * @returns Either the decoded, parsed URL search parameter value, or `defaultValue`.
 */
export function getUrlSearchVariable<T>(searchParamString: string, defaultValue: T): T {
  // Try to get the search filters from the URL.
  // Using window.location.search since react-router's location.search isn't always the latest.
  const searchParams = new URLSearchParams(window.location.search);
  const searchFilterString = searchParams.get(searchParamString);
  if (searchFilterString) {
    // If the search filter was in the URL, then try to get the filter.
    const decodedValue = decodeUrlSearchParamValue<T>(searchFilterString);
    if (decodedValue != null) {
      return decodedValue;
    }
  }
  // Otherwise return the empty/default value
  return defaultValue;
}

/**
 * @param value The value to set as a URL search parameter.
 * @returns The stringified, encoded value. Ready to use as a URL search parameter.
 */
export function encodeUrlSearchParamValue(value: any) {
  return encodeURIComponent(JSON.stringify(value));
}

/**
 * @param value The value to decode from a URL search parameter.
 * @returns The decoded value.
 */
export function decodeUrlSearchParamValue<T>(searchParamValue: any): T | null {
  try {
    return JSON.parse(decodeURIComponent(searchParamValue));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse search variable from URL.', searchParamValue);
  }
  return null;
}

/**
 * Gets several search param values (of the same type) from the URL,
 * decodes them, and returns them in order.
 */
export function getAndDecodeUrlSearchParams<T>(...paramKeys: string[]) {
  const searchParams = new URLSearchParams(window.location.search);
  const values: (T | null)[] = [];
  for (const k of paramKeys) {
    values.push(decodeUrlSearchParamValue<T>(searchParams.get(k)));
  }
  return values;
}
