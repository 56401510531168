import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { SoloDropdownStyles } from './SoloDropdown.style';
import { SoloLabelStyles } from './SoloLabel';
import { SoloCheckboxListStyles } from './SoloCheckboxList.style';

export namespace FindOrHideSearchBoxStyles {
  export const MultiSearchBoxStyled = styled.div(
    ({ theme }) => css`
      display: flex;
      flex-grow: 1;
      position: relative;
      color: ${theme.juneGrey};

      ${SoloCheckboxListStyles.InputContainerStyle} {
        padding: 2px 8px;
        line-height: 27px;
        color: ${theme.juneGrey};
      }

      ${svgBasics({ height: 20 }, theme.juneGrey)}
      svg {
        vertical-align: middle;
        position: absolute;
        top: 7px;
        right: 7px;
      }

      input {
        color: ${theme.novemberGrey};
      }

      ${SoloDropdownStyles.SoloDropdownBlock}.ant-select {
        .ant-select-selector {
          border: none !important; // this !important is need to override an !important in SoloDropdown
          outline: none;
        }

        .ant-select-arrow {
          position: absolute;
          right: 2px;
          top: 4px;
        }
      }

      .ant-select-single .ant-select-selector {
        padding-left: 0;
        padding-right: 5px;
      }
    `
  );

  export const MultiSearchBoxDropdownWrapper = styled.div`
    display: inline-block;
    position: relative;
    min-width: 55px;

    border-right: 1px solid currentColor;
    margin-right: 5px;
  `;

  export const SearchText = styled.span`
    margin-right: 30px;
  `;

  export const Label = styled(SoloLabelStyles.Label)`
    margin-bottom: 0;
  `;

  export const MultiSearchBoxInput = styled.input`
    padding: 0 5px;
    margin: -5px 0 -5px;
    border: 0;
  `;
}
