import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { graphApi } from 'Api/graphs';
import { CardStyles } from 'Components/Common/Card';
import { DataError, DataErrorStringList } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { CardFolderTab, CardFolderTabList } from 'Components/Common/Tabs';
import { useGraphFetchSettingsContext } from 'Components/Features/Graph/Context/GraphFetchSettingsContext';
import { Asset } from 'assets';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { makeClusterObjectRef, sortObjectRefs, splitRefFromId } from 'utils/helpers';
import { ServiceDetailsLandingStyles } from './ServiceDetailsLanding.style';
import { ServiceDetailsSignalsTable } from './ServiceDetailsSignalsTable';
import { ServiceDetailsGraph } from './Tabs/ServiceDetailsGraph';
import { ServiceDetailsLogViewer } from './Tabs/ServiceDetailsLogViewer';
import { ServiceDetailsTracing } from './Tabs/ServiceDetailsTracing';

const { useGetServiceDetails } = graphApi;

export type ServiceDetailsLandingParams = {
  serviceName: string;
  serviceNamespace: string;
  serviceCluster: string;
};

export const ServiceDetailsLanding = () => {
  const [tabIndex, setTabIndex] = useState(0);
  di(useGetServiceDetails, useParams);
  const { serviceName, serviceNamespace, serviceCluster } = useParams<ServiceDetailsLandingParams>();
  const serviceRef = makeClusterObjectRef(serviceName, serviceNamespace, serviceCluster)!;

  const { fetchSettings } = useGraphFetchSettingsContext();

  const {
    data: serviceDataFresh,
    error: serviceError,
    mutate: manualMutate,
    isValidating: dataIsValidating
  } = useGetServiceDetails(
    serviceRef,
    fetchSettings.endTime,
    fetchSettings.window,
    fetchSettings.step,
    fetchSettings.istioMetrics,
    fetchSettings.ciliumMetrics,
    fetchSettings.tcpMetrics
  );

  const [serviceData, setCachedData] = useState(serviceDataFresh);
  useMemo(() => {
    if (serviceDataFresh) setCachedData(serviceDataFresh);
  }, [serviceDataFresh]);

  const workloadRef = useMemo(
    () => (serviceData?.workloadId ? splitRefFromId(serviceData.workloadId) : undefined),
    [serviceData?.workloadId]
  );

  const workloadMetrics = useMemo(
    // compare the refs instead of IDs since IDs have slightly different format
    () => serviceData?.nodeMetrics.find(n => sortObjectRefs(splitRefFromId(n.workload?.id), workloadRef) === 0),
    [serviceData?.nodeMetrics, workloadRef]
  );

  const graphData = useMemo(
    () => ({
      nodeMetrics: serviceData?.nodeMetrics ?? [],
      edgeMetrics: serviceData?.incomingEdgeMetrics.concat(serviceData.outgoingEdgeMetrics) ?? []
    }),
    [serviceData?.nodeMetrics, serviceData?.incomingEdgeMetrics, serviceData?.outgoingEdgeMetrics]
  );

  if (!!serviceError || !serviceData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={<Asset.KubernetesServiceIconBlack />} objRef={serviceRef} />
        {!!serviceError ? (
          <DataError error={serviceError} />
        ) : (
          <Loading message={`Retrieving ${serviceRef.name} service details...`} />
        )}
      </>
    );
  }

  return (
    <ServiceDetailsLandingStyles.Container>
      <DetailsHeaderCard
        usedName={serviceRef?.name}
        icon={<Asset.KubernetesServiceIconBlack />}
        objRef={serviceRef}
        resourceType={ResourceType.SERVICE}
        workspaceRef={undefined}
        status={serviceData.status}
      />

      {!serviceError && !!serviceData?.apiserverErrors?.length && (
        <DataErrorStringList errorsList={serviceData.apiserverErrors} />
      )}

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Service Signals
          <CardStyles.CardHeaderRightIcon>
            <Asset.LatencyIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>
        <ServiceDetailsSignalsTable
          metrics={workloadMetrics}
          edgesIn={serviceData.incomingEdgeMetrics}
          edgesOut={serviceData.outgoingEdgeMetrics}
        />
      </CardStyles.Card>

      <Tabs id='tabs' index={tabIndex} onChange={setTabIndex}>
        <CardFolderTabList>
          <CardFolderTab data-testid='graph-tab-button' width='80px'>
            Graph
          </CardFolderTab>
          <CardFolderTab data-testid='tracing-tab-button' width='80px'>
            Tracing
          </CardFolderTab>
          {/* <CardFolderTab data-testid='yaml-tab-button' width='80px'>
            Logs
          </CardFolderTab> */}
        </CardFolderTabList>
        <TabPanels>
          <TabPanel>
            <ServiceDetailsGraph
              graphData={graphData}
              manualGraphMutate={() => manualMutate()}
              isGraphRefreshing={!graphData || dataIsValidating}
              isLoading={!serviceError && !graphData}
            />
          </TabPanel>
          <TabPanel>
            <ServiceDetailsTracing serviceRef={serviceRef} />
          </TabPanel>
          <TabPanel>
            <CardStyles.Card>
              <ServiceDetailsLogViewer cluster={workloadRef?.clusterName ?? ''} component={workloadRef?.name ?? ''} />
            </CardStyles.Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ServiceDetailsLandingStyles.Container>
  );
};
