/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/tracing/v3/custom_tag.proto" (package "envoy.type.tracing.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { MetadataKey } from "../../metadata/v3/metadata_pb";
import { MetadataKind } from "../../metadata/v3/metadata_pb";
// [#protodoc-title: Custom Tag]

/**
 * Describes custom tags for the active span.
 * [#next-free-field: 6]
 *
 * @generated from protobuf message envoy.type.tracing.v3.CustomTag
 */
export interface CustomTag {
    /**
     * Used to populate the tag name.
     *
     * @generated from protobuf field: string tag = 1;
     */
    tag: string;
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "literal";
        /**
         * A literal custom tag.
         *
         * @generated from protobuf field: envoy.type.tracing.v3.CustomTag.Literal literal = 2;
         */
        literal: CustomTag_Literal;
    } | {
        oneofKind: "environment";
        /**
         * An environment custom tag.
         *
         * @generated from protobuf field: envoy.type.tracing.v3.CustomTag.Environment environment = 3;
         */
        environment: CustomTag_Environment;
    } | {
        oneofKind: "requestHeader";
        /**
         * A request header custom tag.
         *
         * @generated from protobuf field: envoy.type.tracing.v3.CustomTag.Header request_header = 4;
         */
        requestHeader: CustomTag_Header;
    } | {
        oneofKind: "metadata";
        /**
         * A custom tag to obtain tag value from the metadata.
         *
         * @generated from protobuf field: envoy.type.tracing.v3.CustomTag.Metadata metadata = 5;
         */
        metadata: CustomTag_Metadata;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Literal type custom tag with static value for the tag value.
 *
 * @generated from protobuf message envoy.type.tracing.v3.CustomTag.Literal
 */
export interface CustomTag_Literal {
    /**
     * Static literal value to populate the tag value.
     *
     * @generated from protobuf field: string value = 1;
     */
    value: string;
}
/**
 * Environment type custom tag with environment name and default value.
 *
 * @generated from protobuf message envoy.type.tracing.v3.CustomTag.Environment
 */
export interface CustomTag_Environment {
    /**
     * Environment variable name to obtain the value to populate the tag value.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * When the environment variable is not found,
     * the tag value will be populated with this default value if specified,
     * otherwise no tag will be populated.
     *
     * @generated from protobuf field: string default_value = 2;
     */
    defaultValue: string;
}
/**
 * Header type custom tag with header name and default value.
 *
 * @generated from protobuf message envoy.type.tracing.v3.CustomTag.Header
 */
export interface CustomTag_Header {
    /**
     * Header name to obtain the value to populate the tag value.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * When the header does not exist,
     * the tag value will be populated with this default value if specified,
     * otherwise no tag will be populated.
     *
     * @generated from protobuf field: string default_value = 2;
     */
    defaultValue: string;
}
/**
 * Metadata type custom tag using
 * :ref:`MetadataKey <envoy_api_msg_type.metadata.v3.MetadataKey>` to retrieve the protobuf value
 * from :ref:`Metadata <envoy_api_msg_config.core.v3.Metadata>`, and populate the tag value with
 * `the canonical JSON <https://developers.google.com/protocol-buffers/docs/proto3#json>`_
 * representation of it.
 *
 * @generated from protobuf message envoy.type.tracing.v3.CustomTag.Metadata
 */
export interface CustomTag_Metadata {
    /**
     * Specify what kind of metadata to obtain tag value from.
     *
     * @generated from protobuf field: envoy.type.metadata.v3.MetadataKind kind = 1;
     */
    kind?: MetadataKind;
    /**
     * Metadata key to define the path to retrieve the tag value.
     *
     * @generated from protobuf field: envoy.type.metadata.v3.MetadataKey metadata_key = 2;
     */
    metadataKey?: MetadataKey;
    /**
     * When no valid metadata is found,
     * the tag value would be populated with this default value if specified,
     * otherwise no tag would be populated.
     *
     * @generated from protobuf field: string default_value = 3;
     */
    defaultValue: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class CustomTag$Type extends MessageType<CustomTag> {
    constructor() {
        super("envoy.type.tracing.v3.CustomTag", [
            { no: 1, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "literal", kind: "message", oneof: "type", T: () => CustomTag_Literal },
            { no: 3, name: "environment", kind: "message", oneof: "type", T: () => CustomTag_Environment },
            { no: 4, name: "request_header", kind: "message", oneof: "type", T: () => CustomTag_Header },
            { no: 5, name: "metadata", kind: "message", oneof: "type", T: () => CustomTag_Metadata }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.tracing.v2.CustomTag" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.tracing.v3.CustomTag
 */
export const CustomTag = new CustomTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomTag_Literal$Type extends MessageType<CustomTag_Literal> {
    constructor() {
        super("envoy.type.tracing.v3.CustomTag.Literal", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.tracing.v2.CustomTag.Literal" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.tracing.v3.CustomTag.Literal
 */
export const CustomTag_Literal = new CustomTag_Literal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomTag_Environment$Type extends MessageType<CustomTag_Environment> {
    constructor() {
        super("envoy.type.tracing.v3.CustomTag.Environment", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "default_value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.tracing.v2.CustomTag.Environment" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.tracing.v3.CustomTag.Environment
 */
export const CustomTag_Environment = new CustomTag_Environment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomTag_Header$Type extends MessageType<CustomTag_Header> {
    constructor() {
        super("envoy.type.tracing.v3.CustomTag.Header", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1", wellKnownRegex: "HTTP_HEADER_NAME", strict: false } } } },
            { no: 2, name: "default_value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.tracing.v2.CustomTag.Header" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.tracing.v3.CustomTag.Header
 */
export const CustomTag_Header = new CustomTag_Header$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomTag_Metadata$Type extends MessageType<CustomTag_Metadata> {
    constructor() {
        super("envoy.type.tracing.v3.CustomTag.Metadata", [
            { no: 1, name: "kind", kind: "message", T: () => MetadataKind },
            { no: 2, name: "metadata_key", kind: "message", T: () => MetadataKey },
            { no: 3, name: "default_value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.tracing.v2.CustomTag.Metadata" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.tracing.v3.CustomTag.Metadata
 */
export const CustomTag_Metadata = new CustomTag_Metadata$Type();
