import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloLink } from 'Components/Common/SoloLink';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { Svg } from 'Components/Common/Svg';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { IconNextToText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getRefFromDestinationRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { getDestinationKindIcon, getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { buildWorkspaceDestinationDetailsUrl } from 'utils/url-builders';

const { useGetMirrorPolicyDetails } = policiesApi;

const policyType = PolicyType.MIRROR;

namespace Styles {
  export const DestinationDataHorizontalList = styled.div`
    display: flex;
    gap: 10px;
  `;
}

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const MirrorDetails = ({ clusterObjRef }: Props) => {
  di(useGetMirrorPolicyDetails, useNamespaceClusterToWorkspaceMap);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetMirrorPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  const { lookupWorkspaceName, error: workspaceMapError } = useNamespaceClusterToWorkspaceMap();

  if (!!(policyError ?? workspaceMapError) || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError ?? workspaceMapError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  const destination = policyData.mirrorDestination;
  const destinationRef =
    destination?.refKind.oneofKind === 'ref'
      ? { ...destination.refKind.ref, clusterName: destination.refKind.ref.cluster }
      : undefined;

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.MirrorDestinationIcon />,
              title: 'Mirror Destination',
              data: !!destination ? (
                <Styles.DestinationDataHorizontalList>
                  <SoloLink
                    link={buildWorkspaceDestinationDetailsUrl(
                      lookupWorkspaceName(destinationRef),
                      destination.kind,
                      destinationRef
                    )}>
                    <IconNextToText>
                      <Svg size={19} color='currentColor' asset={getDestinationKindIcon(destination.kind)} />
                      {getRefFromDestinationRef(destination)?.name ?? ''}
                    </IconNextToText>
                  </SoloLink>
                  <SecondaryInfo
                    small
                    items={[
                      ...(policyData.mirrorDestination?.refKind.oneofKind === 'ref'
                        ? [
                            {
                              label: 'Namespace',
                              data: policyData.mirrorDestination?.refKind.ref.namespace ?? 'unknown'
                            },
                            {
                              label: 'Cluster',
                              data: policyData.mirrorDestination?.refKind.ref.cluster ?? 'unknown'
                            }
                          ]
                        : []),

                      !!policyData.mirrorDestination?.port && {
                        label: 'Port',
                        data:
                          policyData.mirrorDestination?.port?.specifier.oneofKind === 'name'
                            ? `${policyData.mirrorDestination.port.specifier.name}`
                            : policyData.mirrorDestination?.port?.specifier.oneofKind === 'number'
                            ? `:${policyData.mirrorDestination.port.specifier.number}`
                            : ''
                      },

                      Object.keys(policyData.mirrorDestination?.subset ?? {}).length > 0 && {
                        label: 'Subset',
                        data: Object.entries(policyData.mirrorDestination?.subset ?? {})
                          .map(([a, b]) => `${a}: ${b}`)
                          .join(', ')
                      }
                    ]}
                  />
                </Styles.DestinationDataHorizontalList>
              ) : (
                'Destination not configured'
              )
            },
            {
              icon: <Asset.PercentageIcon />,
              title: 'Percentage',
              data:
                policyData.percentage !== undefined ? policyData.percentage.value + '%' : 'Percentage not configured'
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
