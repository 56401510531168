import { RpcError } from "@protobuf-ts/runtime-rpc";
import { DataError } from 'Components/Common/DataError';
import { Loading } from 'Components/Common/Loading';
import { BaseLandingBodyProps } from 'Components/Common/Overview/LandingBody';
import { SoloLink } from 'Components/Common/SoloLink';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { RenderCellStringList, createEllipseTextColumn, createStatusColumn } from 'Components/Common/SoloTableCells';
import { StringListProps } from 'Components/Common/StringList';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { CoreClusterSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useMemo } from 'react';
import { buildClusterDetailsUrl } from 'utils/url-builders';
import { ClustersLandingStyles as Styles } from './ClustersLanding.style';
import { ClustersLandingEmpty } from './ClustersLandingEmpty';

//
// Table variant
//
interface TableFields {
  key: string;
  status: Status | undefined;
  name: string;
  k8sVersion: string;
  istioVersions: StringListProps;
  numberOfInsights: number;
  details?: string;
}
const columns: SoloColumnsType<TableFields> = [
  createStatusColumn({ dataIndex: 'status' }),
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  { title: 'K8s Version', dataIndex: 'k8sVersion' },
  { title: 'Istio Versions', dataIndex: 'istioVersions', render: RenderCellStringList },
  { title: 'Number of Insights', dataIndex: 'numberOfInsights' },
  {
    title: 'Details',
    dataIndex: 'details',
    render(details) {
      return details ? (
        <SoloLink link={buildClusterDetailsUrl(details)} aria-label='Go to details' withArrow>
          Details
        </SoloLink>
      ) : undefined;
    }
  }
];

const ClustersLandingBodyTable = ({
  items: clusters,
  pagingData
}: BaseLandingBodyProps<CoreClusterSummary>) => {
  const tableData = useMemo<TableFields[]>(
    () =>
      clusters.map((cluster, ind) => ({
        key: cluster.clusterRef ? cluster.clusterRef.name + cluster.clusterRef.namespace : ind + '',
        status: cluster.status,
        name: cluster.clusterRef?.name ?? '',
        k8sVersion: cluster.kubernetesVersion,
        istioVersions: { list: cluster.istioVersions },
        numberOfInsights: cluster.numInsights,
        details: cluster.clusterRef?.name
      })),
    [clusters]
  );

  //
  // Render
  //
  return <SoloTable data-testid='clusters-landing-body' dataSource={tableData} columns={columns} paging={pagingData} />;
};

interface Props {
  items: CoreClusterSummary[];
  pagingData: PagingProps;
  isLoading: boolean;
  itemsError: Partial<RpcError> | undefined;
}
export const ClustersLandingBody = (props: Props) => {
  const { items, isLoading, itemsError, pagingData } = props;
  //
  // Check for errors, empty, and loading states
  if (!!itemsError) {
    return <DataError error={itemsError} />;
  }
  if (isLoading) {
    return <Loading center={true} message='Loading clusters data...' />;
  }
  // if (!!clusters && !clusters.clusters.length) {
  if (pagingData.total === 0 || !items) {
    return <ClustersLandingEmpty />;
  }

  return (
    <Styles.ContentBody>
      <ClustersLandingBodyTable items={items} pagingData={pagingData} />
    </Styles.ContentBody>
  );
};
