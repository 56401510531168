import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { useMemo } from 'react';
import { getInsightData } from 'utils/dashboard/dashboard-helpers';
import { CertificateExpiryKey, certificatesExpiryCodesMap } from './insight-codes';

const rootExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.rootCAExpiry].dataProp;

export function useFilterDuplicateCertificates(rootCertInsights: Insight[] | undefined) {
  const rootCertInsightsFiltered = useMemo(
    () =>
      rootCertInsights?.reduce((arr, cert) => {
        if (
          !arr.find(
            c =>
              getInsightData(c, rootExpiryDataProp)?.subjectKeyIdentifier ===
              getInsightData(cert, rootExpiryDataProp)?.subjectKeyIdentifier
          )
        )
          arr.push(cert);
        return arr;
      }, [] as typeof rootCertInsights),
    [rootCertInsights]
  );

  return { rootCertInsightsFiltered };
}
