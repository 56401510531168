import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics, svgColorReplace } from 'Styles/CommonEmotions/utils';
import { Empty } from 'antd';

export namespace SoloEmptyStyles {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 5px;
  `;

  interface IconHolderProps {
    circled?: boolean;
  }
  export const IconHolder = styled.span<IconHolderProps>(
    ({ theme, circled = false }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 80px;
      border: 2px solid ${circled ? theme.marchGrey : 'transparent'};
      border-radius: 50%;

      ${svgColorReplace(theme.marchGrey)}
      svg {
        ${circled
          ? css`
              min-width: 60%;
              height: 60%;
            `
          : css`
              min-width: 100%;
              height: 100%;
            `}
      }
    `
  );

  export const Body = styled.div`
    margin-left: 16px;

    p {
      font-size: 14px;
      margin-bottom: 6px;
    }
    a {
      font-size: 12px;
    }
  `;

  export const Title = styled.strong`
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
  `;

  export const SimpleEmpty = styled(Empty)<{ greyscale?: boolean; iconSize?: number }>(
    ({ theme, greyscale, iconSize }) => {
      return css`
        .ant-empty-description {
          // This is the default color unless you use a custom icon for some reason; so force it to always be this
          color: rgba(0, 0, 0, 0.25);
        }

        ${iconSize &&
        css`
          .ant-empty-image {
            height: ${iconSize}px;
          }
        `}

        ${svgBasics(iconSize, greyscale ? theme.marchGrey : undefined)}
      `;
    }
  );
}
