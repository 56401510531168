import { CardStyles } from 'Components/Common/Card';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { Svg } from 'Components/Common/Svg';
import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { GetClusterDetailsCardsResponse_ResourceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { di } from 'react-magnetic-di';
import { useInsightEnablementChecks } from 'utils/dashboard/dashboard-hooks';
import { GvkType } from 'utils/types';
import { buildResourcesUrl } from 'utils/url-builders';

export const ClusterResourcesCard = ({
  resourceSummary,
  cluster
}: {
  resourceSummary: GetClusterDetailsCardsResponse_ResourceSummary | undefined;
  cluster: string;
}) => {
  di(useInsightEnablementChecks);
  const { isIstioEnabledAndLicenced, isCiliumEnabled } = useInsightEnablementChecks({ clusters: [cluster] });

  const makeLink = (type: GvkType) => buildResourcesUrl(type, { cluster });
  return (
    <CardStyles.Card data-testid='cluster-resources-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.ResourcesIcon />} color={theme => theme.neptuneBlue} size={27} />
        Resources
      </DashboardStyles.Header>
      <Spacer mt='5px'>
        <SoloListCard
          title='Type'
          icon='Amount'
          dataSource={[
            {
              data: 'Solo',
              right: (
                <SoloLink link={makeLink(GvkType.GLOO)} data-testid='solo-resources-count'>
                  {resourceSummary?.soloResources}
                </SoloLink>
              )
            },
            isIstioEnabledAndLicenced && {
              data: 'Istio',
              right: (
                <SoloLink link={makeLink(GvkType.ISTIO)} data-testid='istio-resources-count'>
                  {resourceSummary?.istioResources}
                </SoloLink>
              )
            },
            isCiliumEnabled && {
              data: 'Cilium',
              right: (
                <SoloLink link={makeLink(GvkType.CILIUM)} data-testid='cilium-resources-count'>
                  {resourceSummary?.ciliumResources}
                </SoloLink>
              )
            },
            isIstioEnabledAndLicenced && {
              data: 'Gateway API',
              right: (
                <SoloLink link={makeLink(GvkType.KUBE_GATEWAY_API)} data-testid='gateway-resources-count'>
                  {resourceSummary?.gatewayResources}
                </SoloLink>
              )
            },
            {
              data: 'Kubernetes',
              right: (
                <SoloLink link={makeLink(GvkType.KUBE_NON_GATEWAY)} data-testid='kubernetes-resources-count'>
                  {resourceSummary?.k8SResources}
                </SoloLink>
              )
            }
          ]}
        />
      </Spacer>
    </CardStyles.Card>
  );
};
