import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { CollapseToggle } from 'Components/Common/CollapseToggle';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { FancyCodeDisplayerStyles } from 'Components/Common/FancyCodeDisplayer.style';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloEmptySimple, SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloListCardWithColumns } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetWasmDeploymentPolicyDetailsResponse_WasmFilter,
  GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { BasicWorkloadsTableCard } from './BasicWorkloadsTable';

const { useGetWasmDeploymentPolicyDetails } = policiesApi;

const policyType = PolicyType.WASM_DEPLOYMENT;

const sourceTypeMap: Record<GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType, string> = {
  [GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType.OCI_IMAGE]: 'OCI Image',
  [GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType.LOCAL_PATH]: 'Local Path',
  [GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType.HTTP_URI]: 'HTTP URI'
};

const FilterGridValue = styled.span(
  ({ theme }) =>
    css`
      color: ${theme.augustGrey};
    `
);

const FancyCodeDisplayerContainer = styled.div`
  overflow: auto;
  width: 100%;
  margin-left: -0.5em;
  line-height: normal;

  ${FancyCodeDisplayerStyles.FancyCodeContainer} {
    margin: 0;
  }
`;

const FilterSection = ({
  filter,
  openByDefault = false
}: {
  filter: GetWasmDeploymentPolicyDetailsResponse_WasmFilter;
  openByDefault?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(!openByDefault);
  return (
    <CardStyles.Card onClick={collapsed ? () => setCollapsed(c => !c) : undefined}>
      <CardStyles.CardHeader style={collapsed ? { paddingBottom: 0 } : undefined}>
        {filter.rootId}
        <CardStyles.CardHeaderSecondaryInfoHolder>
          <SecondaryInfo
            small
            items={[
              { label: 'source', data: filter.source },
              { label: 'source type', data: sourceTypeMap[filter.sourceType] }
            ]}
          />
        </CardStyles.CardHeaderSecondaryInfoHolder>
        <CardStyles.CardHeaderRightIcon>
          <CollapseToggle collapsed={collapsed} onClick={!collapsed ? () => setCollapsed(c => !c) : undefined} />
        </CardStyles.CardHeaderRightIcon>
      </CardStyles.CardHeader>

      {!collapsed && (
        <SoloListCardStyles.HorizontalListCardsContainer>
          <SoloListCardWithColumns
            columns={[{ title: 'Name' }, { title: 'Value' }]}
            rows={[
              { key: 'vmid', cells: [{ data: 'VM ID' }, { data: <FilterGridValue>{filter.vmId}</FilterGridValue> }] },
              {
                key: 'fpc',
                cells: [
                  { data: 'Filter Patch Context' },
                  { data: <FilterGridValue>{filter.filterPatchContext}</FilterGridValue> }
                ]
              },
              {
                key: 'ibf',
                cells: [
                  { data: 'Insert Before Filter' },
                  { data: <FilterGridValue>{filter.insertBeforeFilter}</FilterGridValue> }
                ]
              }
            ]}
          />
          <SoloListCardStyles.Container>
            <SoloListCardStyles.Title>FilterConfig</SoloListCardStyles.Title>
            {filter.filterConfig ? (
              <FancyCodeDisplayerContainer>
                <FancyCodeDisplayer
                  padLineNumbersWithZeros={2}
                  includeBorderBackground={false}
                  contentString={JSON.stringify(JSON.parse(filter.filterConfig), null, 2)}
                  type={'json'}
                />
              </FancyCodeDisplayerContainer>
            ) : (
              <SoloEmptyWithDocs
                icon={<Asset.FilterIcon />}
                iconCircled
                title='No FilterConfig configured'
                description=''
                href='' // WASM_DISABLED - Disabled as part of https://github.com/solo-io/gloo-mesh-enterprise/issues/18579
                // href={docLinks.enterprise.wasm_policy_HASH.apply_filter}
              />
            )}
          </SoloListCardStyles.Container>
        </SoloListCardStyles.HorizontalListCardsContainer>
      )}
    </CardStyles.Card>
  );
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const WasmDeploymentDetails = ({ clusterObjRef }: Props) => {
  di(useGetWasmDeploymentPolicyDetails);
  const workloadsPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetWasmDeploymentPolicyDetails(
    clusterObjRef,
    workloadsPaging.apiPaginationObject
  );

  workloadsPaging.useSetPagingTotal(policyData?.workloads?.totalWorkloads);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
        secondaryInfo={[{ label: 'Weight', data: policyData.weight }]}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Filters
          <CardStyles.CardHeaderRightIcon>
            <Asset.FilterIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        {policyData.filters.length ? (
          <CardStyles.CardList>
            {policyData.filters.map((filter, i) => (
              <FilterSection key={filter.rootId} filter={filter} openByDefault={i === 0} />
            ))}
          </CardStyles.CardList>
        ) : (
          <SoloEmptySimple description='No filters attached to this policy.' />
        )}
      </CardStyles.Card>

      <BasicWorkloadsTableCard workloads={policyData.workloads?.workloads} paging={workloadsPaging.pagingData} />
    </CardStyles.CardList>
  );
};
