import { SearchIcon } from 'assets/assets';
import * as React from 'react';
import { useSoloId } from 'utils/hooks';
import { SoloInputStyles } from './SoloInput.style';
import { SoloLabel } from './SoloLabel';

export interface InputProps {
  label?: React.ReactNode;
  infoTooltip?: React.ReactNode;
  value: string | number;
  password?: boolean;
  onChangeValue?: (value: string) => void;
  forceCase?: 'lower' | 'upper';
  searchIcon?: boolean;
  innerRef?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>['ref'];

  // Styled values - passed via ...rest
  borderless?: boolean;
  error?: boolean;

  // Input default - most passed via ...rest
  id?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  autoComplete?: string;
}

export const SoloInput = ({
  id,
  title,
  label = title,
  infoTooltip,
  searchIcon,
  password,
  forceCase,
  innerRef,
  onChange,
  onChangeValue,
  autoComplete,
  ...otherProps // Includes data-testid and `aria-` attributes
}: InputProps) => {
  const inputId = useSoloId(id);

  return (
    <SoloInputStyles.InputContainer data-testid='solo-input'>
      {!!label && (
        <SoloLabel htmlFor={inputId} infoTooltip={infoTooltip}>
          {label}
        </SoloLabel>
      )}
      <SoloInputStyles.Input
        id={inputId}
        className='solo-input'
        autoComplete={autoComplete}
        type={!!password ? 'password' : 'text'}
        title={title ?? (typeof label === 'string' ? label : undefined)}
        onChange={e => {
          if (forceCase === 'lower') e.target.value = e.target.value.toLowerCase();
          else if (forceCase === 'upper') e.target.value = e.target.value.toUpperCase();
          onChange?.(e);
          onChangeValue?.(e.target.value);
        }}
        searchIcon={searchIcon}
        ref={innerRef}
        aria-label={otherProps.placeholder?.replace('...', '')} // can be overwritten by including it in ...otherProps
        {...otherProps}
      />
      {searchIcon && (
        <SoloInputStyles.SearchIconContainer>
          <SearchIcon />
        </SoloInputStyles.SearchIconContainer>
      )}
    </SoloInputStyles.InputContainer>
  );
};
