import styled from '@emotion/styled';
import { rangeType } from './Input/SoloDateTimePicker';

export function getTimeDisplayProps(
  currentdate: Date,
  range: rangeType
): {
  day: string;
  time: string;
  day2: string | undefined;
  time2: string | undefined;
} {
  const startDate = range?.[0] ? new Date(range[0].toISOString()) : new Date();
  const endDate = range?.[1] && new Date(range[1].toISOString());
  return {
    day: `${trimmedMonthNames[startDate.getMonth()]} ${startDate.getDate()}`,
    time: `${cleanTimeDigits(startDate.getHours() % 12 || 12)}:${cleanTimeDigits(
      startDate.getMinutes()
    )}:${cleanTimeDigits(startDate.getSeconds())}${startDate.getHours() > 11 ? 'pm' : 'am'}`,
    day2: endDate && `${trimmedMonthNames[endDate.getMonth()]} ${endDate.getDate()}`,
    time2:
      endDate &&
      `${cleanTimeDigits(endDate.getHours() % 12 || 12)}:${cleanTimeDigits(new Date().getMinutes())}:${cleanTimeDigits(
        endDate.getSeconds()
      )}${endDate.getHours() > 11 ? 'pm' : 'am'}`
  };
}

/* TIME DISPLAY AIDS - COMPONENT AT BOTTOM */
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const trimmedMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

export const cleanTimeDigits = (digits: number): string => {
  return `${digits < 10 ? '0' : ''}${digits}`;
};

const TimeDisplayContainer = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 17px;
`;
const Day = styled.div`
  font-weight: 600;
  margin-right: 4px;
`;

export type TimeDisplayProps = {
  day: string;
  time?: string;
  day2?: string;
  time2?: string;
  extraClass?: string;
};
export const TimeDisplay = ({ day, time, time2, day2, extraClass }: TimeDisplayProps) => {
  return (
    <TimeDisplayContainer data-testid='time-display' className={extraClass}>
      {!day2 || day2 === day ? (
        <>
          <Day>{day}:</Day> {time} {time2 && time2 !== time ? ` - ${time2}` : ''}
        </>
      ) : (
        <>
          <Day>{day}:</Day> {time}
          <span> &nbsp; - &nbsp; </span> <Day>{day2}:</Day> {time2}
        </>
      )}
    </TimeDisplayContainer>
  );
};
