/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { GraphqlApi } from "./graphql_pb";
import type { GetGraphqlApiExplorerSettingsResponse } from "./graphql_pb";
import type { GetGraphqlApiExplorerSettingsRequest } from "./graphql_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetGraphqlApiResponse } from "./graphql_pb";
import type { GetGraphqlApiRequest } from "./graphql_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Apis for the Gloo Mesh UI Graphql APIs page
 *
 * @generated from protobuf service rpc.gloo.solo.io.GraphqlApi
 */
export interface IGraphqlApiClient {
    /**
     * @generated from protobuf rpc: GetGraphqlApi(rpc.gloo.solo.io.GetGraphqlApiRequest) returns (rpc.gloo.solo.io.GetGraphqlApiResponse);
     */
    getGraphqlApi(input: GetGraphqlApiRequest, options?: RpcOptions): UnaryCall<GetGraphqlApiRequest, GetGraphqlApiResponse>;
    /**
     * @generated from protobuf rpc: GetGraphqlApiExplorerSettings(rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsRequest) returns (rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse);
     */
    getGraphqlApiExplorerSettings(input: GetGraphqlApiExplorerSettingsRequest, options?: RpcOptions): UnaryCall<GetGraphqlApiExplorerSettingsRequest, GetGraphqlApiExplorerSettingsResponse>;
}
/**
 * Apis for the Gloo Mesh UI Graphql APIs page
 *
 * @generated from protobuf service rpc.gloo.solo.io.GraphqlApi
 */
export class GraphqlApiClient implements IGraphqlApiClient, ServiceInfo {
    typeName = GraphqlApi.typeName;
    methods = GraphqlApi.methods;
    options = GraphqlApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetGraphqlApi(rpc.gloo.solo.io.GetGraphqlApiRequest) returns (rpc.gloo.solo.io.GetGraphqlApiResponse);
     */
    getGraphqlApi(input: GetGraphqlApiRequest, options?: RpcOptions): UnaryCall<GetGraphqlApiRequest, GetGraphqlApiResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphqlApiRequest, GetGraphqlApiResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGraphqlApiExplorerSettings(rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsRequest) returns (rpc.gloo.solo.io.GetGraphqlApiExplorerSettingsResponse);
     */
    getGraphqlApiExplorerSettings(input: GetGraphqlApiExplorerSettingsRequest, options?: RpcOptions): UnaryCall<GetGraphqlApiExplorerSettingsRequest, GetGraphqlApiExplorerSettingsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphqlApiExplorerSettingsRequest, GetGraphqlApiExplorerSettingsResponse>("unary", this._transport, method, opt, input);
    }
}
