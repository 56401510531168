import { Asset } from 'assets';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloModal } from 'Components/Common/SoloModal';
import { useState } from 'react';
import { SoloButton, SoloButtonSolid } from 'Styles/CommonEmotions/button';
import { AddClusterModalBody } from './AddClusterModalBody';
import { ClustersLandingEmptyStyles as Styles } from './ClustersLanding.style';
import { docLinks } from 'utils/url-external-links-map';

export const ClustersLandingEmpty = () => {
  const [addClusterModalOpen, setAddClusterModalOpen] = useState(false);

  return (
    <Styles.ClusterContent>
      <Styles.Top>
        <Styles.RegisteringIconHolder>
          <Asset.ClusterIcon />
        </Styles.RegisteringIconHolder>

        <Styles.RegisteringTitle>No Clusters Found</Styles.RegisteringTitle>
        <Styles.RegisteringBody>
          To add workload clusters, create KubernetesCluster resources in your Gloo Platform management cluster. Then,
          you can use Gloo Platform custom resources to manage Istio and control service mesh traffic.
        </Styles.RegisteringBody>
      </Styles.Top>

      <Styles.RegisterButtons>
        <SoloButtonSolid onClick={() => setAddClusterModalOpen(true)}>REGISTER CLUSTER</SoloButtonSolid>
        <ExternalSoloLink href={docLinks.enterprise.meshctl_cluster} newTab>
          <SoloButton>VIEW DOCS</SoloButton>
        </ExternalSoloLink>
      </Styles.RegisterButtons>
      {addClusterModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddClusterModalOpen(false)}>
          <AddClusterModalBody />
        </SoloModal>
      )}
    </Styles.ClusterContent>
  );
};
