/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "xds/core/v3/authority.proto" (package "xds.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * xDS authority information.
 *
 * @generated from protobuf message xds.core.v3.Authority
 */
export interface Authority {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Authority$Type extends MessageType<Authority> {
    constructor() {
        super("xds.core.v3.Authority", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.core.v3.Authority
 */
export const Authority = new Authority$Type();
