import {
  Pagination,
  PolicyType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetAccessPolicyDetailsRequest,
  GetActiveHealthCheckPolicyDetailsRequest,
  GetExtAuthPolicyDetailsRequest,
  GetGraphQLAllowedQueryPolicyDetailsRequest,
  GetPolicyDetailsRequest,
  GetPolicyRoutesRequest,
  GetRateLimitClientConfigRequest,
  GetRateLimitPolicyDetailsRequest
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { PoliciesApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb.client';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import { grpcWebFetchTransport, soloGrpcCall, useRequestSkipRef } from './helpers';

export namespace policiesApi {
  const client = new PoliciesApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.PoliciesApi/' + capitalizeFirstLetters(fn.name);

  //////////////////////////////////////////////
  // Adaptive Request Concurrency Policy Details
  //////////////////////////////////////////////
  export async function getAdaptiveRequestConcurrencyPolicyDetails(
    policyRef?: ClusterObjectRef,
    pagination?: Pagination
  ) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getAdaptiveRequestConcurrencyPolicyDetails(request));
  }
  export function useGetAdaptiveRequestConcurrencyPolicyDetails(
    ...args: Parameters<typeof getAdaptiveRequestConcurrencyPolicyDetails>
  ) {
    return useRequestSkipRef(
      getAdaptiveRequestConcurrencyPolicyDetails,
      args,
      getMethodDescriptorName(client.getAdaptiveRequestConcurrencyPolicyDetails)
    );
  }

  ///////////////////////////////////////
  // Graphql Allowed Query Policy Details
  ///////////////////////////////////////
  export async function getGraphQLAllowedQueryPolicyDetails(
    policyRef?: ClusterObjectRef,
    allowedQueryPagination?: Pagination,
    routePagination?: Pagination
  ) {
    const request: GetGraphQLAllowedQueryPolicyDetailsRequest = {
      policyRef,
      allowedQueryPagination,
      routePagination
    };
    return soloGrpcCall(client.getGraphQLAllowedQueryPolicyDetails(request));
  }
  export function useGetGraphQLAllowedQueryPolicyDetails(
    ...args: Parameters<typeof getGraphQLAllowedQueryPolicyDetails>
  ) {
    return useRequestSkipRef(
      getGraphQLAllowedQueryPolicyDetails,
      args,
      getMethodDescriptorName(client.getGraphQLAllowedQueryPolicyDetails)
    );
  }

  ///////////////////////////////////////////////
  // Graphql Persisted Query Cache Policy Details
  ///////////////////////////////////////////////
  export async function getGraphQLPersistedQueryCachePolicyDetails(
    policyRef?: ClusterObjectRef,
    pagination?: Pagination
  ) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getGraphQLPersistedQueryCachePolicyDetails(request));
  }
  export function useGetGraphQLPersistedQueryCachePolicyDetails(
    ...args: Parameters<typeof getGraphQLPersistedQueryCachePolicyDetails>
  ) {
    return useRequestSkipRef(
      getGraphQLPersistedQueryCachePolicyDetails,
      args,
      getMethodDescriptorName(client.getGraphQLPersistedQueryCachePolicyDetails)
    );
  }

  ////////////////////////////////
  // Get Policy Routes
  ////////////////////////////////
  export async function getPolicyRoutes(
    policyRef: ClusterObjectRef | undefined,
    policyType: PolicyType,
    routeTableRef: ClusterObjectRef | undefined,
    pagination?: Pagination
  ) {
    const request: GetPolicyRoutesRequest = {
      policyType,
      pagination,
      policyRef,
      routeTableRef
    };
    return soloGrpcCall(client.getPolicyRoutes(request));
  }
  export function useGetPolicyRoutes(...args: Parameters<typeof getPolicyRoutes>) {
    return useRequestSkipRef(getPolicyRoutes, args, getMethodDescriptorName(client.getPolicyRoutes));
  }

  ////////////////////////////////
  // Access Policy Details
  ////////////////////////////////
  export async function getAccessPolicyDetails(
    policyRef?: ClusterObjectRef,
    clientPagination?: Pagination,
    destinationPagination?: Pagination
  ) {
    const request: GetAccessPolicyDetailsRequest = { policyRef, clientPagination, destinationPagination };
    return soloGrpcCall(client.getAccessPolicyDetails(request));
  }
  export function useGetAccessPolicyDetails(...args: Parameters<typeof getAccessPolicyDetails>) {
    return useRequestSkipRef(
      getAccessPolicyDetails,
      args,
      getMethodDescriptorName(client.getAccessPolicyDetails)
    );
  }

  ////////////////////////////////
  // Failover Policy Details
  ////////////////////////////////
  export async function getFailoverPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getFailoverPolicyDetails(request));
  }
  export function useGetFailoverPolicyDetails(...args: Parameters<typeof getFailoverPolicyDetails>) {
    return useRequestSkipRef(
      getFailoverPolicyDetails,
      args,
      getMethodDescriptorName(client.getFailoverPolicyDetails)
    );
  }

  ////////////////////////////////
  // Fault Injection Policy Details
  ////////////////////////////////
  export async function getFaultInjectionPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getFaultInjectionPolicyDetails(request));
  }
  export function useGetFaultInjectionPolicyDetails(...args: Parameters<typeof getFaultInjectionPolicyDetails>) {
    return useRequestSkipRef(
      getFaultInjectionPolicyDetails,
      args,
      getMethodDescriptorName(client.getFaultInjectionPolicyDetails)
    );
  }

  ////////////////////////////////
  // Outlier Detection Policy Details
  ////////////////////////////////
  export async function getOutlierDetectionPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getOutlierDetectionPolicyDetails(request));
  }
  export function useGetOutlierDetectionPolicyDetails(...args: Parameters<typeof getOutlierDetectionPolicyDetails>) {
    return useRequestSkipRef(
      getOutlierDetectionPolicyDetails,
      args,
      getMethodDescriptorName(client.getOutlierDetectionPolicyDetails)
    );
  }

  ////////////////////////////////
  // Retry Policy Details
  ////////////////////////////////
  export async function getRetryPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getRetryPolicyDetails(request));
  }
  export function useGetRetryPolicyDetails(...args: Parameters<typeof getRetryPolicyDetails>) {
    return useRequestSkipRef(
      getRetryPolicyDetails,
      args,
      getMethodDescriptorName(client.getRetryPolicyDetails)
    );
  }

  ////////////////////////////////
  // Mirror Policy Details
  ////////////////////////////////
  export async function getMirrorPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getMirrorPolicyDetails(request));
  }
  export function useGetMirrorPolicyDetails(...args: Parameters<typeof getMirrorPolicyDetails>) {
    return useRequestSkipRef(
      getMirrorPolicyDetails,
      args,
      getMethodDescriptorName(client.getMirrorPolicyDetails)
    );
  }

  ////////////////////////////////
  // CORS Policy Details
  ////////////////////////////////
  export async function getCorsPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getCorsPolicyDetails(request));
  }
  export function useGetCorsPolicyDetails(...args: Parameters<typeof getCorsPolicyDetails>) {
    return useRequestSkipRef(
      getCorsPolicyDetails,
      args,
      getMethodDescriptorName(client.getCorsPolicyDetails)
    );
  }

  ////////////////////////////////
  // CSRF Policy Details
  ////////////////////////////////
  export async function getCsrfPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getCsrfPolicyDetails(request));
  }
  export function useGetCsrfPolicyDetails(...args: Parameters<typeof getCsrfPolicyDetails>) {
    return useRequestSkipRef(
      getCsrfPolicyDetails,
      args,
      getMethodDescriptorName(client.getCsrfPolicyDetails)
    );
  }

  ////////////////////////////////
  // Ext Auth Policy Details
  ////////////////////////////////
  export async function getExtAuthPolicyDetails(
    policyRef?: ClusterObjectRef,
    routeTablePagination?: Pagination,
    destinationPagination?: Pagination
  ) {
    const request: GetExtAuthPolicyDetailsRequest = { policyRef, routeTablePagination, destinationPagination };
    return soloGrpcCall(client.getExtAuthPolicyDetails(request));
  }
  export function useGetExtAuthPolicyDetails(...args: Parameters<typeof getExtAuthPolicyDetails>) {
    return useRequestSkipRef(
      getExtAuthPolicyDetails,
      args,
      getMethodDescriptorName(client.getExtAuthPolicyDetails)
    );
  }

  ////////////////////////////////
  // Rate Limit Policy Details
  ////////////////////////////////
  export async function getRateLimitPolicyDetails(
    policyRef?: ClusterObjectRef,
    routeTablePagination?: Pagination,
    destinationPagination?: Pagination
  ) {
    const request: GetRateLimitPolicyDetailsRequest = { policyRef, routeTablePagination, destinationPagination };
    return soloGrpcCall(client.getRateLimitPolicyDetails(request));
  }
  export function useGetRateLimitPolicyDetails(...args: Parameters<typeof getRateLimitPolicyDetails>) {
    return useRequestSkipRef(
      getRateLimitPolicyDetails,
      args,
      getMethodDescriptorName(client.getRateLimitPolicyDetails)
    );
  }

  ////////////////////////////////
  // Rate Limit Client Config
  ////////////////////////////////
  export async function getRateLimitClientConfig(policyRef?: ClusterObjectRef) {
    const request: GetRateLimitClientConfigRequest = { policyRef };
    return soloGrpcCall(client.getRateLimitClientConfig(request));
  }
  export function useGetRateLimitClientConfig(...args: Parameters<typeof getRateLimitClientConfig>) {
    return useRequestSkipRef(
      getRateLimitClientConfig,
      args,
      getMethodDescriptorName(client.getRateLimitClientConfig)
    );
  }

  ////////////////////////////////
  // Header Manipulation Policy Details
  ////////////////////////////////
  export async function getHeaderManipulationPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getHeaderManipulationPolicyDetails(request));
  }
  export function useGetHeaderManipulationPolicyDetails(
    ...args: Parameters<typeof getHeaderManipulationPolicyDetails>
  ) {
    return useRequestSkipRef(
      getHeaderManipulationPolicyDetails,
      args,
      getMethodDescriptorName(client.getHeaderManipulationPolicyDetails)
    );
  }

  ////////////////////////////////
  // Access Log Policy Details
  ////////////////////////////////
  export async function getAccessLogPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getAccessLogPolicyDetails(request));
  }
  export function useGetAccessLogPolicyDetails(...args: Parameters<typeof getAccessLogPolicyDetails>) {
    return useRequestSkipRef(
      getAccessLogPolicyDetails,
      args,
      getMethodDescriptorName(client.getAccessLogPolicyDetails)
    );
  }

  ////////////////////////////////
  // JWT Policy Details
  ////////////////////////////////
  export async function getJwtPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getJwtPolicyDetails(request));
  }
  export function useGetJwtPolicyDetails(...args: Parameters<typeof getJwtPolicyDetails>) {
    return useRequestSkipRef(getJwtPolicyDetails, args, getMethodDescriptorName(client.getJwtPolicyDetails));
  }

  ////////////////////////////////
  // WAF Policy Details
  ////////////////////////////////
  export async function getWafPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getWafPolicyDetails(request));
  }
  export function useGetWafPolicyDetails(...args: Parameters<typeof getWafPolicyDetails>) {
    return useRequestSkipRef(getWafPolicyDetails, args, getMethodDescriptorName(client.getWafPolicyDetails));
  }

  ////////////////////////////////
  // DLP Policy Details
  ////////////////////////////////
  export async function getDlpPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getDlpPolicyDetails(request));
  }
  export function useGetDlpPolicyDetails(...args: Parameters<typeof getDlpPolicyDetails>) {
    return useRequestSkipRef(getDlpPolicyDetails, args, getMethodDescriptorName(client.getDlpPolicyDetails));
  }

  ////////////////////////////////
  // WASM Policy Details
  ////////////////////////////////
  export async function getWasmDeploymentPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getWasmDeploymentPolicyDetails(request));
  }
  export function useGetWasmDeploymentPolicyDetails(...args: Parameters<typeof getWasmDeploymentPolicyDetails>) {
    return useRequestSkipRef(
      getWasmDeploymentPolicyDetails,
      args,
      getMethodDescriptorName(client.getWasmDeploymentPolicyDetails)
    );
  }

  ////////////////////////////////
  // Transformation Policy Details
  ////////////////////////////////
  export async function getTransformationPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getTransformationPolicyDetails(request));
  }
  export function useGetTransformationPolicyDetails(...args: Parameters<typeof getTransformationPolicyDetails>) {
    return useRequestSkipRef(
      getTransformationPolicyDetails,
      args,
      getMethodDescriptorName(client.getTransformationPolicyDetails)
    );
  }

  ////////////////////////////////
  // Proxy Protocol Details
  ////////////////////////////////
  export async function getProxyProtocolPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getProxyProtocolPolicyDetails(request));
  }
  export function useGetProxyProtocolPolicyDetails(...args: Parameters<typeof getProxyProtocolPolicyDetails>) {
    return useRequestSkipRef(
      getProxyProtocolPolicyDetails,
      args,
      getMethodDescriptorName(client.getProxyProtocolPolicyDetails)
    );
  }

  ////////////////////////////////
  // Connection Details
  ////////////////////////////////
  export async function getConnectionPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getConnectionPolicyDetails(request));
  }
  export function useGetConnectionPolicyDetails(...args: Parameters<typeof getConnectionPolicyDetails>) {
    return useRequestSkipRef(
      getConnectionPolicyDetails,
      args,
      getMethodDescriptorName(client.getConnectionPolicyDetails)
    );
  }
  ////////////////////////////////
  // Client TLS Details
  ////////////////////////////////
  export async function getClientTlsPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getClientTlsPolicyDetails(request));
  }
  export function useGetClientTlsPolicyDetails(...args: Parameters<typeof getClientTlsPolicyDetails>) {
    return useRequestSkipRef(
      getClientTlsPolicyDetails,
      args,
      getMethodDescriptorName(client.getCorsPolicyDetails)
    );
  }

  ////////////////////////////////
  // Http Buffer Policy Details
  ////////////////////////////////
  export async function getHttpBufferPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getHttpBufferPolicyDetails(request));
  }
  export function useGetHttpBufferPolicyDetails(...args: Parameters<typeof getHttpBufferPolicyDetails>) {
    return useRequestSkipRef(
      getHttpBufferPolicyDetails,
      args,
      getMethodDescriptorName(client.getHttpBufferPolicyDetails)
    );
  }

  ////////////////////////////////
  // Active Health Check Details
  ////////////////////////////////
  export async function getActiveHealthCheckPolicyDetails(
    policyRef?: ClusterObjectRef,
    destinationPagination?: Pagination,
    virtualGatewayPagination?: Pagination
  ) {
    const request: GetActiveHealthCheckPolicyDetailsRequest = {
      policyRef,
      destinationPagination,
      virtualGatewayPagination
    };
    return soloGrpcCall(client.getActiveHealthCheckPolicyDetails(request));
  }
  export function useGetActiveHealthCheckPolicyDetails(...args: Parameters<typeof getActiveHealthCheckPolicyDetails>) {
    return useRequestSkipRef(
      getActiveHealthCheckPolicyDetails,
      args,
      getMethodDescriptorName(client.getActiveHealthCheckPolicyDetails)
    );
  }

  ////////////////////////////////
  // Trim Proxy Config Policy Details
  ////////////////////////////////
  export async function getTrimProxyConfigPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getTrimProxyConfigPolicyDetails(request));
  }
  export function useGetTrimProxyConfigPolicyDetails(...args: Parameters<typeof getTrimProxyConfigPolicyDetails>) {
    return useRequestSkipRef(
      getTrimProxyConfigPolicyDetails,
      args,
      getMethodDescriptorName(client.getTrimProxyConfigPolicyDetails)
    );
  }

  ////////////////////////////////
  // Load Balancer Policy Details
  ////////////////////////////////
  export async function getLoadBalancerPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getLoadBalancerPolicyDetails(request));
  }
  export function useGetLoadBalancerPolicyDetails(...args: Parameters<typeof getLoadBalancerPolicyDetails>) {
    return useRequestSkipRef(
      getLoadBalancerPolicyDetails,
      args,
      getMethodDescriptorName(client.getCorsPolicyDetails)
    );
  }

  ////////////////////////////////
  // Listener Connection Policy Details
  ////////////////////////////////
  export async function getListenerConnectionPolicyDetails(policyRef?: ClusterObjectRef, pagination?: Pagination) {
    const request: GetPolicyDetailsRequest = { policyRef, pagination };
    return soloGrpcCall(client.getListenerConnectionPolicyDetails(request));
  }
  export function useGetListenerConnectionPolicyDetails(
    ...args: Parameters<typeof getListenerConnectionPolicyDetails>
  ) {
    return useRequestSkipRef(
      getListenerConnectionPolicyDetails,
      args,
      getMethodDescriptorName(client.getListenerConnectionPolicyDetails)
    );
  }
}
