import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { insightsApi } from 'Api/insights';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { DataError, DataErrorStringList } from 'Components/Common/DataError';
import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { Loading } from 'Components/Common/Loading';
import { MarkdownRenderer } from 'Components/Common/MarkdownRenderer';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { CardFolderTab, CardFolderTabListWithBottomBorder } from 'Components/Common/Tabs';
import { YamlModalBody } from 'Components/Common/YamlModal';
import { YamlModalStyles } from 'Components/Common/YamlModal.style';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { convertInsightSeverityToState, insightSeverityMap } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeGroup, insightCodeGroupMap } from 'utils/dashboard/dashboard-types';
import { groupKindResourceTypeMap, groupKindtoString } from 'utils/generated/gvk-mapping';
import { getStatusColors } from 'utils/health-status';
import { resourceTypesMap } from 'utils/types';
import { buildClusterDetailsUrl } from 'utils/url-builders';
const { useGetInsightResolutionSteps } = insightsApi;

const IdContainer = styled.span(({ theme }) => {
  return css`
    font-size: 80%;
    color: ${theme.augustGrey};
  `;
});

const SummarySection = ({ insight }: { insight: Insight }) => {
  return (
    <CardStyles.CardList>
      <CardStyles.Card>
        <CardStyles.CardHeader>
          Summary
          <CardStyles.CardHeaderSecondaryInfoHolder>
            <SecondaryInfo
              items={[
                !!insight.code?.group && {
                  label: 'Type',
                  data: insightCodeGroupMap[insight.code.group as InsightCodeGroup] ?? 'unknown'
                },
                !!insight.code && { label: 'Code', data: `${insight.code?.group}${insight.code?.key}` }
              ]}
              small
            />
          </CardStyles.CardHeaderSecondaryInfoHolder>
        </CardStyles.CardHeader>
        <p>{insight.summary}</p>
      </CardStyles.Card>
      <CardStyles.Card>
        <CardStyles.CardHeader>Details</CardStyles.CardHeader>
        <MarkdownRenderer text={insight.details} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};

const ResolutionStepsSection = ({ code }: { code: Code | undefined }) => {
  di(useGetInsightResolutionSteps);
  const { data, error } = useGetInsightResolutionSteps(code);
  if (!!error) {
    return <DataError error={error} />;
  } else if (!data) {
    return <Loading center={true} message='Loading resolutions steps...' />;
  }
  return (
    <div>
      {!error && !!data?.apiserverErrors?.length && <DataErrorStringList errorsList={data.apiserverErrors} />}
      <Spacer px={5} py={2}>
        {!!data.resolutionSteps ? (
          <MarkdownRenderer text={data.resolutionSteps} />
        ) : (
          <SoloEmptySimple description='No resolutions steps available for this insight' />
        )}
      </Spacer>
    </div>
  );
};

interface Props {
  insight: Insight;
  onClose: () => void;
}
export const InsightsLandingDetailsModal = ({ insight, onClose }: Props) => {
  const status = { state: convertInsightSeverityToState(insight.severity), validationErrors: [] };
  const statusColors = getStatusColors(status.state);
  const resourceType = useMemo(
    () =>
      insight.target?.target.oneofKind === 'resource' &&
      !!insight.target.target.resource.apiGroup &&
      !!insight.target.target.resource.kind &&
      groupKindResourceTypeMap[
        groupKindtoString({
          group: insight.target.target.resource.apiGroup.value,
          kind: insight.target.target.resource.kind.value
        })
      ],
    [insight.target]
  );
  return (
    <SoloModal width={1000} onClose={onClose}>
      <YamlModalStyles.Body>
        <SoloModalHeader
          icon={<Asset.InsightAnalysisIcon />}
          title={
            <FlexLayout vertical gap='0.5em'>
              Insight Details {!!insight.id && <IdContainer>({insight.id})</IdContainer>}
            </FlexLayout>
          }
          status={status}
          subtitle={
            <SecondaryInfo
              items={[
                {
                  label: 'Level',
                  data: insightSeverityMap[insight.severity].displayValue,
                  highlighted: {
                    primaryColor: statusColors.border,
                    textColor: statusColors.text,
                    backgroundColor: statusColors.background
                  }
                },
                insight.target?.target.oneofKind === 'resource' && !!insight.target?.target.resource.clusterName
                  ? {
                      label: 'Cluster',
                      data: insight.target.target.resource.clusterName
                    }
                  : insight.target?.target.oneofKind === 'cluster' && !!insight.target?.target.cluster.name
                  ? {
                      label: 'Cluster',
                      data: insight.target.target.cluster.name
                    }
                  : {
                      label: 'Cluster',
                      data: ''
                    },
                {
                  label: 'Target',
                  data:
                    insight.target?.target.oneofKind === 'resource' ? (
                      resourceType ? (
                        resourceTypesMap[resourceType].name
                      ) : (
                        'Unknown Resource Type'
                      )
                    ) : insight.target?.target.oneofKind === 'cluster' ? (
                      <SoloLink link={buildClusterDetailsUrl(insight.target.target.cluster.name)}>Cluster</SoloLink>
                    ) : insight.target?.target.oneofKind === 'global' ? (
                      'Global'
                    ) : (
                      'Unknown'
                    )
                },
                !!insight.lastObserved && {
                  label: 'Last Observed',
                  data: new Date(insight.lastObserved.seconds * 1000).toLocaleString()
                },
                !!insight.lastProcessedTime && {
                  label: 'Last Scanned',
                  data: new Date(insight.lastProcessedTime.seconds * 1000).toLocaleString()
                }
              ]}
              small
            />
          }
        />
        <YamlModalStyles.Content>
          <Tabs id='multiYamlTabs'>
            <CardFolderTabListWithBottomBorder>
              <CardFolderTab width='auto'>Summary</CardFolderTab>
              {insight.target?.target.oneofKind === 'resource' && !!resourceType && (
                <CardFolderTab width='auto'>Target YAML</CardFolderTab>
              )}
              <CardFolderTab width='auto'>View Resolution Steps</CardFolderTab>
            </CardFolderTabListWithBottomBorder>
            <TabPanels>
              <TabPanel>
                <Spacer pt={3} px={1}>
                  <SummarySection insight={insight} />
                </Spacer>
              </TabPanel>
              {insight.target?.target.oneofKind === 'resource' && !!resourceType && (
                <TabPanel>
                  <Spacer pt={3} px={1}>
                    <ErrorBoundary fallback='Could not get YAML...'>
                      <YamlModalBody
                        resourceType={resourceType}
                        clusterObjectRef={{
                          ...insight.target.target.resource,
                          clusterName: insight.target.target.resource.clusterName
                        }}
                      />
                    </ErrorBoundary>
                  </Spacer>
                </TabPanel>
              )}
              <TabPanel>
                <Spacer pt={3} px={1}>
                  <ErrorBoundary fallback='Could not get resolution steps...'>
                    <ResolutionStepsSection code={insight.code} />
                  </ErrorBoundary>
                </Spacer>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </YamlModalStyles.Content>
      </YamlModalStyles.Body>
    </SoloModal>
  );
};
