import { SoloListCard } from 'Components/Common/SoloListCard';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { GetDashboardCardsResponse_HealthOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { DashboardStyles } from '../../../DashboardLanding.style';
import { DashboardHealthCardStyles } from '../DashboardHealthCard.style';
import { useGlooVersionListCardEntries } from '../hooks';

export const GlooHealthEnvironmentCheckSection = ({
  healthOverview
}: {
  healthOverview: GetDashboardCardsResponse_HealthOverview | undefined;
}) => {
  const versionListCardEntries = useGlooVersionListCardEntries(healthOverview?.glooVersions);

  return (
    <GridLayout templateRows='auto 1fr auto' data-testid='gloo-environment-check-section'>
      <DashboardStyles.SubHeader>Environment Check</DashboardStyles.SubHeader>
      <DashboardHealthCardStyles.NoMarginEmptyContainer>
        <SoloListCard
          data-testid='gloo-versions-card'
          title='Version'
          maxHeight='140px'
          dataSource={versionListCardEntries}
          hideDottedLine
        />
      </DashboardHealthCardStyles.NoMarginEmptyContainer>
      <FlexLayout pt={3} justifyContent='space-between'>
        <DashboardStyles.VersionsCountCircleAndTextContainer>
          Clusters{' '}
          <DashboardStyles.VersionsCountCircle data-testid='gloo-clusters-count'>
            {healthOverview?.totalClusters ?? 0}
          </DashboardStyles.VersionsCountCircle>
        </DashboardStyles.VersionsCountCircleAndTextContainer>
        {/* <FlexLayout vertical>
          <DashboardStyles.CardBottomLink>
            <SoloLink small link='/TODO' withArrow>
              DETAILS
            </SoloLink>
          </DashboardStyles.CardBottomLink>
        </FlexLayout> */}
      </FlexLayout>
    </GridLayout>
  );
};
