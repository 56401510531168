import { dashboardApi } from 'Api/dashboard';
import { di } from 'react-magnetic-di';
import { FullPageIframe } from 'Styles/CommonEmotions/iframe';
import TracingLandingEmpty from './TracingLandingEmpty';

const { useGetTracingBasePath } = dashboardApi;

export const TracingLanding = () => {
  di(useGetTracingBasePath);
  const { data } = useGetTracingBasePath();

  // This is to make the Jaeger UI work when developing the UI locally.
  const tracingPageOrigin =
    window.location.origin === 'http://localhost:3000' ? 'http://localhost:8090' : window.location.origin;

  //
  // Render
  //
  return !!data?.tracingBasePath ? (
    <FullPageIframe
      data-testid='tracing-iframe'
      title='Tracing page'
      src={tracingPageOrigin + data.tracingBasePath}
      ignoreBottomPadding
    />
  ) : (
    <div data-testid='tracing-container'>
      <TracingLandingEmpty />
    </div>
  );
};
