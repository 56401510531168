import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { getCiliumAgentLogsLinks, getCiliumOperatorLogsLinks } from 'utils/log-viewer-helpers';
import { DashboardHealthCheckRow } from '../DashboardHealthCheckRow';
import { useCombinedInsightStatusOrLoading } from '../hooks';

export const CiliumHealthCheckSectionContent = ({
  ciliumAgentInsights,
  ciliumOperatorInsights,
  ciliumHubbleInsights
}: {
  ciliumAgentInsights: Insight[] | undefined;
  ciliumOperatorInsights: Insight[] | undefined;
  ciliumHubbleInsights: Insight[] | undefined;
}) => {
  const ciliumAgentStatus = useCombinedInsightStatusOrLoading(ciliumAgentInsights);
  const ciliumOperatorStatus = useCombinedInsightStatusOrLoading(ciliumOperatorInsights);
  const ciliumHubbleStatus = useCombinedInsightStatusOrLoading(ciliumHubbleInsights);

  return (
    <div>
      <GridLayout templateColumns='20px 1fr auto' gap='20px 6px' data-testid='cilium-health-section-content'>
        <DashboardHealthCheckRow
          label='Agent'
          status={ciliumAgentStatus}
          logsLinks={getCiliumAgentLogsLinks(ciliumAgentInsights)}
        />
        <DashboardHealthCheckRow
          label='Operator'
          status={ciliumOperatorStatus}
          logsLinks={getCiliumOperatorLogsLinks(ciliumOperatorInsights)}
        />
        <DashboardHealthCheckRow label='Hubble' status={ciliumHubbleStatus} />
      </GridLayout>
    </div>
  );
};
