import { SoloLinkStylesOnly } from 'Components/Common/SoloLink';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Tooltip } from 'antd';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { getInsightData } from 'utils/dashboard/dashboard-helpers';
import { formatDateToMMDDYYYY } from 'utils/helpers';
import { useEventListener } from 'utils/hooks';
import { CertExpiryStyles } from '../CertificatesExpiryCard.style';
import { CertificateExpiryKey, certificatesExpiryCodesMap } from '../insight-codes';
import { useGetExpiryLineTimeData } from './hooks';

interface DataType {
  hostname: string;
  expiresOn?: Timestamp;
  issuedOn?: Timestamp;
}

interface TooltipContentProps {
  data: DataType;
  onDetailsClicked(): void;
}
export const ExpiryVizualizationTooltipContent = ({ data, onDetailsClicked }: TooltipContentProps) => {
  // Get issued-on, expiration time.
  const hasNoExpiration = data?.expiresOn === undefined;
  const expirationDate = new Date((data.expiresOn?.seconds ?? 0) * 1000);
  const expirationDateFormatted = formatDateToMMDDYYYY(expirationDate);
  const issuedDate = new Date((data.issuedOn?.seconds ?? 0) * 1000);
  const issuedDateFormatted = formatDateToMMDDYYYY(issuedDate);
  return (
    <Spacer padding={1}>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <CertExpiryStyles.TooltipKeyText>Name</CertExpiryStyles.TooltipKeyText>
        <CertExpiryStyles.TooltipValueText>{data.hostname}</CertExpiryStyles.TooltipValueText>
      </FlexLayout>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <CertExpiryStyles.TooltipKeyText>Issued Date</CertExpiryStyles.TooltipKeyText>
        <CertExpiryStyles.TooltipValueText>{issuedDateFormatted}</CertExpiryStyles.TooltipValueText>
      </FlexLayout>
      <FlexLayout alignItems='center' justifyContent='space-between'>
        <CertExpiryStyles.TooltipKeyText>Expiration Date</CertExpiryStyles.TooltipKeyText>
        <CertExpiryStyles.TooltipValueText>
          {hasNoExpiration ? 'No Expiration' : expirationDateFormatted}
        </CertExpiryStyles.TooltipValueText>
      </FlexLayout>
      <CertExpiryStyles.TooltipLinkContainer>
        <SoloLinkStylesOnly onClick={() => onDetailsClicked()} withArrow>
          MORE DETAILS
        </SoloLinkStylesOnly>
      </CertExpiryStyles.TooltipLinkContainer>
    </Spacer>
  );
};

interface ExpiryVizualizationLineProps {
  insights: Insight[];
  mapKey: CertificateExpiryKey.rootCAExpiry | CertificateExpiryKey.intermediateCAExpiry; // gateway type no longer combines nicely
  warningPercent?: number;
  dangerPercent?: number;
  onDetailsClicked(insight: Insight): void;
}
export const ExpiryVizualizationLine = ({
  insights,
  mapKey,
  warningPercent = 60,
  dangerPercent = 90,
  onDetailsClicked
}: ExpiryVizualizationLineProps) => {
  const { dataProp } = certificatesExpiryCodesMap[mapKey];
  const curSeconds = Math.floor(Date.now().valueOf() / 1000);
  const { totalSeconds, startTime } = useGetExpiryLineTimeData(insights, mapKey);
  const nowPercent = Math.max(((curSeconds - startTime) / totalSeconds) * 100, 0);

  const lineRef = useRef<ElementRef<'div'>>(null);
  // set line width on first ref load + when screen resizes
  const [lineWidth, setLineWidth] = useState(0);
  useEffect(() => setLineWidth(lineRef.current?.scrollWidth ?? 0), [lineRef.current]);
  useEventListener(window, 'resize', () => setLineWidth(lineRef.current?.scrollWidth ?? 0), [lineRef.current]);
  const linePercentAsPx = lineWidth / 100;
  // 22px used at to calc room both to prevent normal dot and also the wider "+#" dots from doing overlapping
  const pointOverlapPercent = 22 / linePercentAsPx;

  const getHealthType = (perc: number) =>
    perc < warningPercent ? 'normal' : perc < dangerPercent ? 'warning' : 'error';

  // Render
  return (
    <>
      <CertExpiryStyles.MultiCertExpiryVisualizationLine
        ref={lineRef}
        addMarkerSpace={nowPercent <= 30}
        warningPercent={warningPercent}
        dangerPercent={dangerPercent}>
        <CertExpiryStyles.TextMarker
          percent={nowPercent}
          textOnRight={nowPercent <= 90}
          health={getHealthType(nowPercent)}>
          Today{' '}
        </CertExpiryStyles.TextMarker>
        {insights
          .filter(ins => ins.data?.data.oneofKind === dataProp)
          .sort(
            (a, b) =>
              (getInsightData(b, dataProp)?.expiresOn?.seconds ?? 0) -
              (getInsightData(a, dataProp)?.expiresOn?.seconds ?? 0)
          )
          .reduce<{ insight: Insight; percent: number }[][]>((mergedInsights, insight) => {
            // Calculate the percent complete.
            const data = getInsightData(insight, dataProp);
            const secondsLeft = (data?.expiresOn?.seconds ?? 0) - curSeconds;
            const percentComplete = Math.max((1 - secondsLeft / totalSeconds) * 100, 0);

            // Detect if this point on the line would intercept a previous node before it
            if (!mergedInsights.length || mergedInsights.at(-1)![0].percent <= percentComplete - pointOverlapPercent) {
              mergedInsights.push([]);
            }
            mergedInsights[mergedInsights.length - 1].push({ insight, percent: percentComplete });
            return mergedInsights;
          }, [])
          .map((merged, idx) => {
            const percentComplete = merged[0].percent;

            // Insert the data point at the correct position.
            return (
              <Tooltip
                key={merged[0].percent}
                title={() => (
                  <CertExpiryStyles.DataPointTooltipContentScroller>
                    {merged.map(({ insight }, idx) => {
                      const data = getInsightData(insight, dataProp);
                      if (!data) {
                        return <React.Fragment key={idx} />;
                      }
                      return (
                        <>
                          {idx > 0 && <hr />}
                          <ExpiryVizualizationTooltipContent
                            key={`${data.hostname}_${idx}`}
                            data={data}
                            onDetailsClicked={() => onDetailsClicked(insight)}
                          />
                        </>
                      );
                    })}
                  </CertExpiryStyles.DataPointTooltipContentScroller>
                )}>
                <CertExpiryStyles.CertExpiryDataPoint
                  leftPercent={percentComplete}
                  inLineSection={getHealthType(percentComplete)}>
                  {merged.length === 1 ? '+' : `+${merged.length}`}
                </CertExpiryStyles.CertExpiryDataPoint>
              </Tooltip>
            );
          })}
      </CertExpiryStyles.MultiCertExpiryVisualizationLine>
    </>
  );
};
