import { SerializedStyles } from '@emotion/react';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import React from 'react';
import { Link } from 'react-router-dom';
import { SoloLinkStyles as Styles } from './SoloLink.style';

export type SimpleLinkProps = {
  children: React.ReactNode;
  link: string;
  inline?: boolean;
  stylingOverrides?: SerializedStyles;
  darkText?: boolean;
  health?: State;
  withArrow?: boolean;
  small?: boolean;
  'data-testid'?: string;
  /**
   * The title can be used to add a11y information to the link element
   * when there is no descriptive inner text available.
   * */
  title?: string;
};

export const SoloLink = (props: SimpleLinkProps) => {
  return (
    <Styles.SoloLinkWrapper data-testid={props['data-testid'] ?? 'solo-link'} displayInline={props.inline}>
      <Link to={props.link} title={props.title}>
        <Styles.SoloLinkLooks
          displayInline={props.inline}
          stylingOverrides={props.stylingOverrides}
          darkText={props.darkText}
          health={props.health}
          withArrow={props.withArrow}
          small={props.small}>
          {props.children}
        </Styles.SoloLinkLooks>
      </Link>
    </Styles.SoloLinkWrapper>
  );
};

/** The SoloLink but without the anchor element and link property. */
export const SoloLinkStylesOnly = (
  props: Omit<SimpleLinkProps, 'link'> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => {
  return (
    <Styles.SoloLinkWrapper data-testid={props['data-testid'] ?? 'solo-link'} displayInline={props.inline} {...props}>
      <Styles.SoloLinkLooks
        displayInline={props.inline}
        stylingOverrides={props.stylingOverrides}
        darkText={props.darkText}
        health={props.health}
        withArrow={props.withArrow}
        small={props.small}>
        {props.children}
      </Styles.SoloLinkLooks>
    </Styles.SoloLinkWrapper>
  );
};

// For tables' readability
export const RenderSimpleLink = (props?: SimpleLinkProps) => {
  if (!props) return null;

  return <SoloLink {...props} />;
};

export type ExternalLinkProps = {
  href: string;
  children: React.ReactNode;
  newTab?: boolean;
  inline?: boolean;
  stylingOverrides?: SerializedStyles;
  withArrow?: boolean;
  small?: boolean;
  'data-testid'?: string;
};
export const ExternalSoloLink = (props: ExternalLinkProps) => {
  return (
    <Styles.SoloLinkWrapper data-testid={props['data-testid'] ?? 'solo-link'} displayInline={props.inline}>
      <a href={props.href} target={props.newTab ? '_blank' : ''} rel={'noopener noreferrer'}>
        <Styles.SoloLinkLooks
          displayInline={props.inline}
          stylingOverrides={props.stylingOverrides}
          withArrow={props.withArrow}
          small={props.small}>
          {props.children}
        </Styles.SoloLinkLooks>
      </a>
    </Styles.SoloLinkWrapper>
  );
};
