import { SoloListCardDynamic } from 'Components/Common/SoloListCard';
import { DashboardHealthCheckRow } from 'Components/Features/Dashboard/Cards/Health/DashboardHealthCheckRow';
import { useCombinedInsightStatusOrLoading } from 'Components/Features/Dashboard/Cards/Health/hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { ListInsightsRequest_InsightsFilter_Target } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { getCiliumAgentLogsLinks, getCiliumOperatorLogsLinks } from 'utils/log-viewer-helpers';

export const ClusterCiliumHealthListCard = ({ cluster }: { cluster: string }) => {
  di(useFilteredDashboardInsights);
  const target: ListInsightsRequest_InsightsFilter_Target = { clusters: [cluster] };

  const ciliumAgentInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.ciliumAgent,
    target
  );
  const ciliumAgentStatus = useCombinedInsightStatusOrLoading(ciliumAgentInsights);

  const ciliumOperatorInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.ciliumOperator,
    target
  );
  const ciliumOperatorStatus = useCombinedInsightStatusOrLoading(ciliumOperatorInsights);

  const ciliumHubbleInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.ciliumHubble,
    target
  );
  const ciliumHubbleStatus = useCombinedInsightStatusOrLoading(ciliumHubbleInsights);

  return (
    <SoloListCardDynamic title='Health' data-testid='cilium-health-card'>
      <GridLayout templateColumns='20px 1fr auto' gap='20px 6px'>
        {!!ciliumAgentInsights?.length && (
          <DashboardHealthCheckRow
            label={'Agent'}
            status={ciliumAgentStatus}
            logsLinks={getCiliumAgentLogsLinks(ciliumAgentInsights)}
          />
        )}
        {!!ciliumOperatorInsights?.length && (
          <DashboardHealthCheckRow
            label={'Operator'}
            status={ciliumOperatorStatus}
            logsLinks={getCiliumOperatorLogsLinks(ciliumOperatorInsights)}
          />
        )}
        {!!ciliumHubbleInsights?.length && (
          // TODO: update link to actual page
          <DashboardHealthCheckRow label={'Hubble'} status={ciliumHubbleStatus} />
        )}
      </GridLayout>
    </SoloListCardDynamic>
  );
};
