/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights.proto" (package "insights.internal.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { TypedClusterObjectRef } from "../../../../../../../skv2/api/core/v1/core_pb";
import { Timestamp } from "../../../../../../../../../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight
 */
export interface Insight {
    /**
     * Numerical value of the insight severity.
     *
     * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.Severity severity = 1;
     */
    severity: Insight_Severity;
    /**
     * Time when the situation that caused the insight was last observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp last_observed = 2;
     */
    lastObserved?: Timestamp;
    /**
     * Time when the insight was generated.
     *
     * @generated from protobuf field: google.protobuf.Timestamp created = 3;
     */
    created?: Timestamp;
    /**
     * A code that identifies the situation that caused the insight.
     * For example - IST0102
     *
     * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.Code code = 4;
     */
    code?: Insight_Code;
    /**
     * A unique identifier for the insight.
     *
     * @generated from protobuf field: string id = 5;
     */
    id: string;
    /**
     * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.Target target = 7;
     */
    target?: Insight_Target;
    /**
     * An identifier of the instance of the analytics job that last produced the inisght.
     * Used for garbage collection.
     *
     * @generated from protobuf field: string last_source_exec_id = 9;
     */
    lastSourceExecId: string;
    /**
     * A short dynamic summary of this specific insight, derived by the insights engine.
     *
     * @generated from protobuf field: string summary = 10;
     */
    summary: string;
    /**
     * A longer dynamic details of this specific insight with more context, derived by the insights engine.
     *
     * @generated from protobuf field: string details = 11;
     */
    details: string;
    /**
     * A flag for whether this is an internal system insight.
     *
     * @generated from protobuf field: bool system = 12;
     */
    system: boolean;
    /**
     * used for TTL based garbage collection
     *
     * @generated from protobuf field: google.protobuf.Timestamp expirey_time = 13;
     */
    expireyTime?: Timestamp;
    /**
     * Time used internally to determine if an insight is potentially stale, this should be updated everytime the engine processes the insight.
     *
     * @generated from protobuf field: google.protobuf.Timestamp last_processed_time = 14;
     */
    lastProcessedTime?: Timestamp;
    /**
     * Storage for extra custom data that is output by the insights engine and consumed directly by the UI for custom components.
     *
     * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.Data data = 15;
     */
    data?: Insight_Data;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.Code
 */
export interface Insight_Code {
    /**
     * The group of the code -- e.g. IST
     *
     * @generated from protobuf field: string group = 1;
     */
    group: string;
    /**
     * The key of the code -- e.g. 0102
     *
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
// Static description of insght, intended for documentation purposes.
// Commented out for now, as we don't have a use case for it yet. If the use case is for docs this could be a docURL instead. (josh)
// string description = 6;

/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.ClusterRef
 */
export interface Insight_ClusterRef {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.Target
 */
export interface Insight_Target {
    /**
     * @generated from protobuf oneof: target
     */
    target: {
        oneofKind: "cluster";
        /**
         * The cluster that the insight was generated for.
         *
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.ClusterRef cluster = 7;
         */
        cluster: Insight_ClusterRef;
    } | {
        oneofKind: "resource";
        /**
         * @generated from protobuf field: core.skv2.solo.io.TypedClusterObjectRef resource = 8;
         */
        resource: TypedClusterObjectRef;
    } | {
        oneofKind: "global";
        /**
         * @generated from protobuf field: bool global = 16;
         */
        global: boolean;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.Data
 */
export interface Insight_Data {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "sYS0003";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0003Data SYS0003 = 18 [json_name = "SYS0003"];
         */
        sYS0003: Insight_SYS0003Data;
    } | {
        oneofKind: "sYS0006";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0006Data SYS0006 = 19 [json_name = "SYS0006"];
         */
        sYS0006: Insight_SYS0006Data;
    } | {
        oneofKind: "sYS0007";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0007Data SYS0007 = 20 [json_name = "SYS0007"];
         */
        sYS0007: Insight_SYS0007Data;
    } | {
        oneofKind: "sYS0008";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0008Data SYS0008 = 21 [json_name = "SYS0008"];
         */
        sYS0008: Insight_SYS0008Data;
    } | {
        oneofKind: "sYS0009";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0009Data SYS0009 = 22 [json_name = "SYS0009"];
         */
        sYS0009: Insight_SYS0009Data;
    } | {
        oneofKind: "sYS0010";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0010Data SYS0010 = 23 [json_name = "SYS0010"];
         */
        sYS0010: Insight_SYS0010Data;
    } | {
        oneofKind: "sYS0011";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0011Data SYS0011 = 24 [json_name = "SYS0011"];
         */
        sYS0011: Insight_SYS0011Data;
    } | {
        oneofKind: "sYS0012";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0012Data SYS0012 = 25 [json_name = "SYS0012"];
         */
        sYS0012: Insight_SYS0012Data;
    } | {
        oneofKind: "sYS0014";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0014Data SYS0014 = 27 [json_name = "SYS0014"];
         */
        sYS0014: Insight_SYS0014Data;
    } | {
        oneofKind: "sYS0015";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0015Data SYS0015 = 28 [json_name = "SYS0015"];
         */
        sYS0015: Insight_SYS0015Data;
    } | {
        oneofKind: "sYS0019";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0019Data SYS0019 = 29 [json_name = "SYS0019"];
         */
        sYS0019: Insight_SYS0019Data; // Adding so UI works for mock - might change later
    } | {
        oneofKind: "sYS0020";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0020Data SYS0020 = 30 [json_name = "SYS0020"];
         */
        sYS0020: Insight_SYS0020Data;
    } | {
        oneofKind: "sYS0025";
        /**
         * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0025Data SYS0025 = 31 [json_name = "SYS0025"];
         */
        sYS0025: Insight_SYS0025Data;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Agent Deployent Reference
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0003Data
 */
export interface Insight_SYS0003Data {
    /**
     * @generated from protobuf field: core.skv2.solo.io.TypedClusterObjectRef deployment_ref = 1;
     */
    deploymentRef?: TypedClusterObjectRef;
}
/**
 * CRDs
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0006Data
 */
export interface Insight_SYS0006Data {
    /**
     * @generated from protobuf field: string istio_version = 1;
     */
    istioVersion: string;
}
/**
 * root certificate
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0007Data
 */
export interface Insight_SYS0007Data {
    /**
     * @generated from protobuf field: string hostname = 1;
     */
    hostname: string;
    /**
     * @generated from protobuf field: string issued_to_cn = 2;
     */
    issuedToCn: string;
    /**
     * @generated from protobuf field: repeated string issued_to_organization = 3;
     */
    issuedToOrganization: string[];
    /**
     * @generated from protobuf field: repeated string issued_to_organization_unit = 4;
     */
    issuedToOrganizationUnit: string[];
    /**
     * @generated from protobuf field: string issued_from_cn = 5;
     */
    issuedFromCn: string;
    /**
     * @generated from protobuf field: repeated string issued_from_organization = 6;
     */
    issuedFromOrganization: string[];
    /**
     * @generated from protobuf field: repeated string issued_from_organization_unit = 7;
     */
    issuedFromOrganizationUnit: string[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp issued_on = 8;
     */
    issuedOn?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_on = 9;
     */
    expiresOn?: Timestamp;
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight.SYS0007Data.Fingerprints fingerprints = 10;
     */
    fingerprints: Insight_SYS0007Data_Fingerprints[];
    /**
     * @generated from protobuf field: string subject_key_identifier = 11;
     */
    subjectKeyIdentifier: string;
    /**
     * @generated from protobuf field: string authority_key_identifier = 12;
     */
    authorityKeyIdentifier: string;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0007Data.Fingerprints
 */
export interface Insight_SYS0007Data_Fingerprints {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * intermediate certificate
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0008Data
 */
export interface Insight_SYS0008Data {
    /**
     * @generated from protobuf field: string hostname = 1;
     */
    hostname: string;
    /**
     * @generated from protobuf field: string issued_to_cn = 2;
     */
    issuedToCn: string;
    /**
     * @generated from protobuf field: repeated string issued_to_organization = 3;
     */
    issuedToOrganization: string[];
    /**
     * @generated from protobuf field: repeated string issued_to_organization_unit = 4;
     */
    issuedToOrganizationUnit: string[];
    /**
     * @generated from protobuf field: string issued_from_cn = 5;
     */
    issuedFromCn: string;
    /**
     * @generated from protobuf field: repeated string issued_from_organization = 6;
     */
    issuedFromOrganization: string[];
    /**
     * @generated from protobuf field: repeated string issued_from_organization_unit = 7;
     */
    issuedFromOrganizationUnit: string[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp issued_on = 8;
     */
    issuedOn?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_on = 9;
     */
    expiresOn?: Timestamp;
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight.SYS0008Data.Fingerprints fingerprints = 10;
     */
    fingerprints: Insight_SYS0008Data_Fingerprints[];
    /**
     * @generated from protobuf field: string subject_key_identifier = 11;
     */
    subjectKeyIdentifier: string;
    /**
     * @generated from protobuf field: string authority_key_identifier = 12;
     */
    authorityKeyIdentifier: string;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0008Data.Fingerprints
 */
export interface Insight_SYS0008Data_Fingerprints {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * Istio Gateway certificates
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0015Data
 */
export interface Insight_SYS0015Data {
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight.ReferencedCertificateSecret certificates = 1;
     */
    certificates: Insight_ReferencedCertificateSecret[];
}
/**
 * Kubernetes Gateway certificates
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0025Data
 */
export interface Insight_SYS0025Data {
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight.ReferencedCertificateSecret certificates = 1;
     */
    certificates: Insight_ReferencedCertificateSecret[];
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.ReferencedCertificateSecret
 */
export interface Insight_ReferencedCertificateSecret {
    /**
     * @generated from protobuf field: core.skv2.solo.io.TypedClusterObjectRef ref = 1;
     */
    ref?: TypedClusterObjectRef;
    /**
     * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.CertificateInformation certificate = 2;
     */
    certificate?: Insight_CertificateInformation;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.CertificateInformation
 */
export interface Insight_CertificateInformation {
    /**
     * @generated from protobuf field: string hostname = 1;
     */
    hostname: string;
    /**
     * @generated from protobuf field: string issued_to_cn = 2;
     */
    issuedToCn: string;
    /**
     * @generated from protobuf field: repeated string issued_to_organization = 3;
     */
    issuedToOrganization: string[];
    /**
     * @generated from protobuf field: repeated string issued_to_organization_unit = 4;
     */
    issuedToOrganizationUnit: string[];
    /**
     * @generated from protobuf field: string issued_from_cn = 5;
     */
    issuedFromCn: string;
    /**
     * @generated from protobuf field: repeated string issued_from_organization = 6;
     */
    issuedFromOrganization: string[];
    /**
     * @generated from protobuf field: repeated string issued_from_organization_unit = 7;
     */
    issuedFromOrganizationUnit: string[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp issued_on = 8;
     */
    issuedOn?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_on = 9;
     */
    expiresOn?: Timestamp;
    /**
     * @generated from protobuf field: repeated insights.internal.gloo.solo.io.Insight.CertificateInformation.Fingerprints fingerprints = 10;
     */
    fingerprints: Insight_CertificateInformation_Fingerprints[];
    /**
     * @generated from protobuf field: string subject_key_identifier = 11;
     */
    subjectKeyIdentifier: string;
    /**
     * @generated from protobuf field: string authority_key_identifier = 12;
     */
    authorityKeyIdentifier: string;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.CertificateInformation.Fingerprints
 */
export interface Insight_CertificateInformation_Fingerprints {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * Control plane FIPs
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0009Data
 */
export interface Insight_SYS0009Data {
    /**
     * @generated from protobuf field: int32 fips_compliant_istio_workloads = 1;
     */
    fipsCompliantIstioWorkloads: number;
    /**
     * @generated from protobuf field: int32 total_istio_workloads = 2;
     */
    totalIstioWorkloads: number;
    /**
     * @generated from protobuf field: repeated string unique_fips_versions = 4;
     */
    uniqueFipsVersions: string[];
}
/**
 * Data plane FIPs
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0010Data
 */
export interface Insight_SYS0010Data {
    /**
     * @generated from protobuf field: int32 fips_compliant_istio_workloads = 1;
     */
    fipsCompliantIstioWorkloads: number;
    /**
     * @generated from protobuf field: int32 total_istio_workloads = 2;
     */
    totalIstioWorkloads: number;
    /**
     * @generated from protobuf field: repeated string unique_fips_versions = 4;
     */
    uniqueFipsVersions: string[];
}
/**
 * Cluster services
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0011Data
 */
export interface Insight_SYS0011Data {
    /**
     * @generated from protobuf field: int32 in_mesh_services = 1;
     */
    inMeshServices: number;
    /**
     * @generated from protobuf field: int32 out_of_mesh_services = 2;
     */
    outOfMeshServices: number;
    /**
     * @generated from protobuf field: int32 sidecar_services = 3;
     */
    sidecarServices: number;
    /**
     * @generated from protobuf field: int32 ambient_services = 4;
     */
    ambientServices: number;
    /**
     * @generated from protobuf field: int32 gateway_services = 5;
     */
    gatewayServices: number;
    /**
     * @generated from protobuf field: int32 total_services = 6;
     */
    totalServices: number;
}
/**
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0012Data
 */
export interface Insight_SYS0012Data {
    /**
     * @generated from protobuf field: bool in_mesh = 1;
     */
    inMesh: boolean;
}
/**
 * zero trust - kubernetes external services accessed
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0013Data
 */
export interface Insight_SYS0013Data {
    /**
     * @generated from protobuf field: int32 value = 1;
     */
    value: number;
}
/**
 * zero trust - access policy violations
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0014Data
 */
export interface Insight_SYS0014Data {
    /**
     * @generated from protobuf field: int32 value = 1;
     */
    value: number;
}
/**
 * Adding so UI works for mock - might change later
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0019Data
 */
export interface Insight_SYS0019Data {
    /**
     * @generated from protobuf field: string cilium_version = 1;
     */
    ciliumVersion: string;
}
/**
 * resource counts
 *
 * @generated from protobuf message insights.internal.gloo.solo.io.Insight.SYS0020Data
 */
export interface Insight_SYS0020Data {
    /**
     * @generated from protobuf field: int32 istio_resources = 1;
     */
    istioResources: number;
    /**
     * @generated from protobuf field: int32 cilium_resources = 2;
     */
    ciliumResources: number;
    /**
     * @generated from protobuf field: int32 k8s_resources = 3 [json_name = "k8sResources"];
     */
    k8SResources: number;
    /**
     * @generated from protobuf field: int32 gateway_resources = 4;
     */
    gatewayResources: number;
    /**
     * @generated from protobuf field: int32 solo_resources = 5;
     */
    soloResources: number;
}
/**
 * @generated from protobuf enum insights.internal.gloo.solo.io.Insight.Severity
 */
export enum Insight_Severity {
    /**
     * SEVERITY_UNSPECIFIED is the default SeverityNumber. It should not be used
     * as all insights should have a severity.
     *
     * @generated from protobuf enum value: SEVERITY_UNSPECIFIED = 0;
     */
    SEVERITY_UNSPECIFIED = 0,
    /**
     * guidance, suggestions, and best practices
     *
     * @generated from protobuf enum value: INFO = 1;
     */
    INFO = 1,
    /**
     * something may not work as expected
     *
     * @generated from protobuf enum value: WARNING = 2;
     */
    WARNING = 2,
    /**
     * something is broken, will not work as expected
     *
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Insight$Type extends MessageType<Insight> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight", [
            { no: 1, name: "severity", kind: "enum", T: () => ["insights.internal.gloo.solo.io.Insight.Severity", Insight_Severity] },
            { no: 2, name: "last_observed", kind: "message", T: () => Timestamp },
            { no: 3, name: "created", kind: "message", T: () => Timestamp },
            { no: 4, name: "code", kind: "message", T: () => Insight_Code },
            { no: 5, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "target", kind: "message", T: () => Insight_Target },
            { no: 9, name: "last_source_exec_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "summary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "system", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "expirey_time", kind: "message", T: () => Timestamp },
            { no: 14, name: "last_processed_time", kind: "message", T: () => Timestamp },
            { no: 15, name: "data", kind: "message", T: () => Insight_Data }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight
 */
export const Insight = new Insight$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_Code$Type extends MessageType<Insight_Code> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.Code", [
            { no: 1, name: "group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.Code
 */
export const Insight_Code = new Insight_Code$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_ClusterRef$Type extends MessageType<Insight_ClusterRef> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.ClusterRef", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.ClusterRef
 */
export const Insight_ClusterRef = new Insight_ClusterRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_Target$Type extends MessageType<Insight_Target> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.Target", [
            { no: 7, name: "cluster", kind: "message", oneof: "target", T: () => Insight_ClusterRef },
            { no: 8, name: "resource", kind: "message", oneof: "target", T: () => TypedClusterObjectRef },
            { no: 16, name: "global", kind: "scalar", oneof: "target", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.Target
 */
export const Insight_Target = new Insight_Target$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_Data$Type extends MessageType<Insight_Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.Data", [
            { no: 18, name: "SYS0003", kind: "message", jsonName: "SYS0003", oneof: "data", T: () => Insight_SYS0003Data },
            { no: 19, name: "SYS0006", kind: "message", jsonName: "SYS0006", oneof: "data", T: () => Insight_SYS0006Data },
            { no: 20, name: "SYS0007", kind: "message", jsonName: "SYS0007", oneof: "data", T: () => Insight_SYS0007Data },
            { no: 21, name: "SYS0008", kind: "message", jsonName: "SYS0008", oneof: "data", T: () => Insight_SYS0008Data },
            { no: 22, name: "SYS0009", kind: "message", jsonName: "SYS0009", oneof: "data", T: () => Insight_SYS0009Data },
            { no: 23, name: "SYS0010", kind: "message", jsonName: "SYS0010", oneof: "data", T: () => Insight_SYS0010Data },
            { no: 24, name: "SYS0011", kind: "message", jsonName: "SYS0011", oneof: "data", T: () => Insight_SYS0011Data },
            { no: 25, name: "SYS0012", kind: "message", jsonName: "SYS0012", oneof: "data", T: () => Insight_SYS0012Data },
            { no: 27, name: "SYS0014", kind: "message", jsonName: "SYS0014", oneof: "data", T: () => Insight_SYS0014Data },
            { no: 28, name: "SYS0015", kind: "message", jsonName: "SYS0015", oneof: "data", T: () => Insight_SYS0015Data },
            { no: 29, name: "SYS0019", kind: "message", jsonName: "SYS0019", oneof: "data", T: () => Insight_SYS0019Data },
            { no: 30, name: "SYS0020", kind: "message", jsonName: "SYS0020", oneof: "data", T: () => Insight_SYS0020Data },
            { no: 31, name: "SYS0025", kind: "message", jsonName: "SYS0025", oneof: "data", T: () => Insight_SYS0025Data }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.Data
 */
export const Insight_Data = new Insight_Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0003Data$Type extends MessageType<Insight_SYS0003Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0003Data", [
            { no: 1, name: "deployment_ref", kind: "message", T: () => TypedClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0003Data
 */
export const Insight_SYS0003Data = new Insight_SYS0003Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0006Data$Type extends MessageType<Insight_SYS0006Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0006Data", [
            { no: 1, name: "istio_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0006Data
 */
export const Insight_SYS0006Data = new Insight_SYS0006Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0007Data$Type extends MessageType<Insight_SYS0007Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0007Data", [
            { no: 1, name: "hostname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "issued_to_cn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "issued_to_organization", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "issued_to_organization_unit", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "issued_from_cn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "issued_from_organization", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "issued_from_organization_unit", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "issued_on", kind: "message", T: () => Timestamp },
            { no: 9, name: "expires_on", kind: "message", T: () => Timestamp },
            { no: 10, name: "fingerprints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Insight_SYS0007Data_Fingerprints },
            { no: 11, name: "subject_key_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "authority_key_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0007Data
 */
export const Insight_SYS0007Data = new Insight_SYS0007Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0007Data_Fingerprints$Type extends MessageType<Insight_SYS0007Data_Fingerprints> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0007Data.Fingerprints", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0007Data.Fingerprints
 */
export const Insight_SYS0007Data_Fingerprints = new Insight_SYS0007Data_Fingerprints$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0008Data$Type extends MessageType<Insight_SYS0008Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0008Data", [
            { no: 1, name: "hostname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "issued_to_cn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "issued_to_organization", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "issued_to_organization_unit", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "issued_from_cn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "issued_from_organization", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "issued_from_organization_unit", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "issued_on", kind: "message", T: () => Timestamp },
            { no: 9, name: "expires_on", kind: "message", T: () => Timestamp },
            { no: 10, name: "fingerprints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Insight_SYS0008Data_Fingerprints },
            { no: 11, name: "subject_key_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "authority_key_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0008Data
 */
export const Insight_SYS0008Data = new Insight_SYS0008Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0008Data_Fingerprints$Type extends MessageType<Insight_SYS0008Data_Fingerprints> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0008Data.Fingerprints", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0008Data.Fingerprints
 */
export const Insight_SYS0008Data_Fingerprints = new Insight_SYS0008Data_Fingerprints$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0015Data$Type extends MessageType<Insight_SYS0015Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0015Data", [
            { no: 1, name: "certificates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Insight_ReferencedCertificateSecret }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0015Data
 */
export const Insight_SYS0015Data = new Insight_SYS0015Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0025Data$Type extends MessageType<Insight_SYS0025Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0025Data", [
            { no: 1, name: "certificates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Insight_ReferencedCertificateSecret }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0025Data
 */
export const Insight_SYS0025Data = new Insight_SYS0025Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_ReferencedCertificateSecret$Type extends MessageType<Insight_ReferencedCertificateSecret> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.ReferencedCertificateSecret", [
            { no: 1, name: "ref", kind: "message", T: () => TypedClusterObjectRef },
            { no: 2, name: "certificate", kind: "message", T: () => Insight_CertificateInformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.ReferencedCertificateSecret
 */
export const Insight_ReferencedCertificateSecret = new Insight_ReferencedCertificateSecret$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_CertificateInformation$Type extends MessageType<Insight_CertificateInformation> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.CertificateInformation", [
            { no: 1, name: "hostname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "issued_to_cn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "issued_to_organization", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "issued_to_organization_unit", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "issued_from_cn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "issued_from_organization", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "issued_from_organization_unit", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "issued_on", kind: "message", T: () => Timestamp },
            { no: 9, name: "expires_on", kind: "message", T: () => Timestamp },
            { no: 10, name: "fingerprints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Insight_CertificateInformation_Fingerprints },
            { no: 11, name: "subject_key_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "authority_key_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.CertificateInformation
 */
export const Insight_CertificateInformation = new Insight_CertificateInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_CertificateInformation_Fingerprints$Type extends MessageType<Insight_CertificateInformation_Fingerprints> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.CertificateInformation.Fingerprints", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.CertificateInformation.Fingerprints
 */
export const Insight_CertificateInformation_Fingerprints = new Insight_CertificateInformation_Fingerprints$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0009Data$Type extends MessageType<Insight_SYS0009Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0009Data", [
            { no: 1, name: "fips_compliant_istio_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "total_istio_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "unique_fips_versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0009Data
 */
export const Insight_SYS0009Data = new Insight_SYS0009Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0010Data$Type extends MessageType<Insight_SYS0010Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0010Data", [
            { no: 1, name: "fips_compliant_istio_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "total_istio_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "unique_fips_versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0010Data
 */
export const Insight_SYS0010Data = new Insight_SYS0010Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0011Data$Type extends MessageType<Insight_SYS0011Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0011Data", [
            { no: 1, name: "in_mesh_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "out_of_mesh_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sidecar_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "ambient_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "gateway_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "total_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0011Data
 */
export const Insight_SYS0011Data = new Insight_SYS0011Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0012Data$Type extends MessageType<Insight_SYS0012Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0012Data", [
            { no: 1, name: "in_mesh", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0012Data
 */
export const Insight_SYS0012Data = new Insight_SYS0012Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0013Data$Type extends MessageType<Insight_SYS0013Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0013Data", [
            { no: 1, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0013Data
 */
export const Insight_SYS0013Data = new Insight_SYS0013Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0014Data$Type extends MessageType<Insight_SYS0014Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0014Data", [
            { no: 1, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0014Data
 */
export const Insight_SYS0014Data = new Insight_SYS0014Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0019Data$Type extends MessageType<Insight_SYS0019Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0019Data", [
            { no: 1, name: "cilium_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0019Data
 */
export const Insight_SYS0019Data = new Insight_SYS0019Data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Insight_SYS0020Data$Type extends MessageType<Insight_SYS0020Data> {
    constructor() {
        super("insights.internal.gloo.solo.io.Insight.SYS0020Data", [
            { no: 1, name: "istio_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "cilium_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "k8s_resources", kind: "scalar", jsonName: "k8sResources", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "gateway_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "solo_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message insights.internal.gloo.solo.io.Insight.SYS0020Data
 */
export const Insight_SYS0020Data = new Insight_SYS0020Data$Type();
