/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/config/core/v3/proxy_protocol.proto" (package "envoy.config.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Proxy Protocol]

/**
 * @generated from protobuf message envoy.config.core.v3.ProxyProtocolConfig
 */
export interface ProxyProtocolConfig {
    /**
     * The PROXY protocol version to use. See https://www.haproxy.org/download/2.1/doc/proxy-protocol.txt for details
     *
     * @generated from protobuf field: envoy.config.core.v3.ProxyProtocolConfig.Version version = 1;
     */
    version: ProxyProtocolConfig_Version;
}
/**
 * @generated from protobuf enum envoy.config.core.v3.ProxyProtocolConfig.Version
 */
export enum ProxyProtocolConfig_Version {
    /**
     * PROXY protocol version 1. Human readable format.
     *
     * @generated from protobuf enum value: V1 = 0;
     */
    V1 = 0,
    /**
     * PROXY protocol version 2. Binary format.
     *
     * @generated from protobuf enum value: V2 = 1;
     */
    V2 = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class ProxyProtocolConfig$Type extends MessageType<ProxyProtocolConfig> {
    constructor() {
        super("envoy.config.core.v3.ProxyProtocolConfig", [
            { no: 1, name: "version", kind: "enum", T: () => ["envoy.config.core.v3.ProxyProtocolConfig.Version", ProxyProtocolConfig_Version] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.ProxyProtocolConfig
 */
export const ProxyProtocolConfig = new ProxyProtocolConfig$Type();
