import styled from '@emotion/styled';
import { SoloToggleSwitch } from 'Components/Common/Input/SoloToggleSwitch';
import { Permission, usePermissions } from 'utils/permissions';
import { useGetGraphCache } from '../graph-cache';
import { useGraphWorkspacesUsable } from '../graph-filter-utils';

const MainTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
`;

const Subtitle = styled.div`
  margin-bottom: 22px;
  font-weight: 300;
  font-size: 14px;
`;

const ToggleControls = styled.div`
  font-size: 14;
  margin-bottom: 10px;

  > div {
    margin-bottom: 16px;
  }
`;

const ToggleTitle = styled.strong`
  font-weight: 500;
`;

interface Props {
  toggleGroupBy: (groupBy: 'workspace' | 'infra') => void;
}
export const LayoutSettings = ({ toggleGroupBy }: Props) => {
  const { hasPerm } = usePermissions();
  const { cache, updateCache } = useGetGraphCache();
  const workspacesUsable = useGraphWorkspacesUsable();
  const {
    animationsOn,
    workspacesOn,
    mTLSOn,
    ciliumOn,
    tcpOn,
    idlesOn,
    kubernetesServicesDisplayed,
    externalServicesDisplayed,
    gatewaysDisplayed
  } = cache;

  // Hacking due to how embeded this is for now. Will revisit and rip out full guts with graph data overhaul.
  const toggleBoxes = (workspacesOnOthersOff: boolean): void => {
    toggleGroupBy(workspacesOnOthersOff ? 'workspace' : 'infra');
  };

  return (
    <>
      <MainTitle>Layout Settings</MainTitle>

      <Subtitle>
        Get a better sense of the different aspects of your data by toggling surrounding information on or off. Changes
        will show in real-time.
      </Subtitle>

      <ToggleControls>
        <SoloToggleSwitch
          checked={animationsOn}
          onChange={checked => updateCache({ animationsOn: checked })}
          aria-label='Animations'
          checkedLabel={
            <>
              <ToggleTitle>Animations:</ToggleTitle> ON
            </>
          }
          uncheckedLabel={
            <>
              <ToggleTitle>Animations:</ToggleTitle> OFF
            </>
          }
          labelOnRight={true}
        />

        {workspacesUsable && (
          <SoloToggleSwitch
            checked={workspacesOn}
            onChange={toggleBoxes}
            data-testid='graph-groupby-toggle'
            aria-label='Group By Workspace versus Infra'
            checkedLabel={
              <>
                <ToggleTitle>Group By:</ToggleTitle> WORKSPACE
              </>
            }
            uncheckedLabel={
              <>
                <ToggleTitle>Group By:</ToggleTitle> INFRA
              </>
            }
            labelOnRight={true}
          />
        )}

        <SoloToggleSwitch
          checked={mTLSOn}
          onChange={checked => updateCache({ mTLSOn: checked })}
          aria-label='Mutual TLS'
          checkedLabel={
            <>
              <ToggleTitle>Mutual TLS:</ToggleTitle> SHOW
            </>
          }
          uncheckedLabel={
            <>
              <ToggleTitle>Mutual TLS:</ToggleTitle> HIDE
            </>
          }
          labelOnRight={true}
        />

        {hasPerm(Permission.TcpEnabled) && (
          <SoloToggleSwitch
            checked={tcpOn}
            onChange={checked => updateCache({ tcpOn: checked })}
            data-testid='graph-tcp-toggle'
            aria-label='TCP'
            checkedLabel={
              <>
                <ToggleTitle>TCP:</ToggleTitle> SHOW
              </>
            }
            uncheckedLabel={
              <>
                <ToggleTitle>TCP:</ToggleTitle> HIDE
              </>
            }
            labelOnRight={true}
          />
        )}

        {hasPerm(Permission.CiliumEnabled) && (
          <SoloToggleSwitch
            checked={ciliumOn}
            onChange={checked => updateCache({ ciliumOn: checked })}
            data-testid='graph-cilium-toggle'
            aria-label='Cilium'
            checkedLabel={
              <>
                <ToggleTitle>Cilium:</ToggleTitle> SHOW
              </>
            }
            uncheckedLabel={
              <>
                <ToggleTitle>Cilium:</ToggleTitle> HIDE
              </>
            }
            labelOnRight={true}
          />
        )}
      </ToggleControls>

      <MainTitle>Node Types Displayed</MainTitle>

      <ToggleControls>
        <SoloToggleSwitch
          checked={kubernetesServicesDisplayed}
          onChange={checked => updateCache({ kubernetesServicesDisplayed: checked })}
          aria-label='Kubernetes Services'
          checkedLabel={
            <>
              <ToggleTitle>Kubernetes Services:</ToggleTitle> ON
            </>
          }
          uncheckedLabel={
            <>
              <ToggleTitle>Kubernetes Services:</ToggleTitle> OFF
            </>
          }
          labelOnRight={true}
        />

        <SoloToggleSwitch
          checked={externalServicesDisplayed}
          onChange={checked => updateCache({ externalServicesDisplayed: checked })}
          aria-label='External Services'
          checkedLabel={
            <>
              <ToggleTitle>External Services:</ToggleTitle> ON
            </>
          }
          uncheckedLabel={
            <>
              <ToggleTitle>External Services:</ToggleTitle> OFF
            </>
          }
          labelOnRight={true}
        />

        <SoloToggleSwitch
          checked={gatewaysDisplayed}
          onChange={checked => updateCache({ gatewaysDisplayed: checked })}
          aria-label='Gateways'
          checkedLabel={
            <>
              <ToggleTitle>Gateways:</ToggleTitle> ON
            </>
          }
          uncheckedLabel={
            <>
              <ToggleTitle>Gateways:</ToggleTitle> OFF
            </>
          }
          labelOnRight={true}
        />
      </ToggleControls>

      <MainTitle>Node States Displayed</MainTitle>

      <ToggleControls>
        <SoloToggleSwitch
          checked={idlesOn}
          onChange={checked => updateCache({ idlesOn: checked })}
          data-testid='graph-idle-toggle'
          aria-label='Idle Nodes'
          checkedLabel={
            <>
              <ToggleTitle>Idle Nodes:</ToggleTitle> SHOW
            </>
          }
          uncheckedLabel={
            <>
              <ToggleTitle>Idle Nodes:</ToggleTitle> HIDE
            </>
          }
          labelOnRight={true}
        />
      </ToggleControls>
    </>
  );
};
