/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Status } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { State } from "./common_pb";
import { ResourceType } from "./resources/resources_pb";
import { Pagination } from "./common_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListResourcesRequest
 */
export interface ListResourcesRequest {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 1;
     */
    pagination?: Pagination;
    /**
     * If set, only include resources modified by this parent.
     * Otherwise, include all resources.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.ListResourcesRequest.ParentResource parent_resource = 2;
     */
    parentResource?: ListResourcesRequest_ParentResource;
    /**
     * Filter based on case-insensitive substring of name, namespace, or cluster name
     *
     * @generated from protobuf field: string search_filter = 3;
     */
    searchFilter: string;
    /**
     * If set, only return resources with this exact name
     *
     * @generated from protobuf field: string name_filter = 5;
     */
    nameFilter: string;
    /**
     * If set, only return resources with this exact namespace
     *
     * @generated from protobuf field: string namespace_filter = 6;
     */
    namespaceFilter: string;
    /**
     * If set, return resources with this exact cluster name
     *
     * @generated from protobuf field: string cluster_filter = 7;
     */
    clusterFilter: string;
    /**
     * If set, return resources with in this exact workspace name
     *
     * @generated from protobuf field: string workspace_filter = 8;
     */
    workspaceFilter: string;
    /**
     * If set, only return resources of the specified types
     *
     * @generated from protobuf field: repeated resources.rpc.gloo.solo.io.ResourceType type_filter = 9;
     */
    typeFilter: ResourceType[];
    /**
     * Filter based on case-insensitive substring of label keys
     *
     * @generated from protobuf field: string label_key_filter = 10;
     */
    labelKeyFilter: string;
    /**
     * Filter based on case-insensitive substring of label values
     *
     * @generated from protobuf field: string label_value_filter = 11;
     */
    labelValueFilter: string;
    /**
     * If set, only return resources in the specified states
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 12;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListResourcesRequest.ParentResource
 */
export interface ListResourcesRequest_ParentResource {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: resources.rpc.gloo.solo.io.ResourceType type = 2;
     */
    type: ResourceType;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListResourcesResponse
 */
export interface ListResourcesResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ResourceSummary resources = 1;
     */
    resources: ResourceSummary[];
    /**
     * The total after filtering by non-state conditions.
     *
     * @generated from protobuf field: int32 total_resources = 2;
     */
    totalResources: number;
    /**
     * The number of errors after filtering by non-state conditions.
     *
     * @generated from protobuf field: int32 errors = 3;
     */
    errors: number;
    /**
     * The total after filtering by all conditions including state.
     *
     * @generated from protobuf field: int32 filtered_total = 4;
     */
    filteredTotal: number;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 5;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ResourceSummary
 */
export interface ResourceSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: resources.rpc.gloo.solo.io.ResourceType type = 2;
     */
    type: ResourceType;
    /**
     * The name of the workspace that contains this resource (if any)
     *
     * @generated from protobuf field: string workspace_name = 3;
     */
    workspaceName: string;
    /**
     * @generated from protobuf field: map<string, string> labels = 4;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetYamlRequest
 */
export interface GetYamlRequest {
    /**
     * @generated from protobuf field: resources.rpc.gloo.solo.io.ResourceType type = 1;
     */
    type: ResourceType;
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 2;
     */
    ref?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetYamlResponse
 */
export interface GetYamlResponse {
    /**
     * @generated from protobuf field: string yaml = 1;
     */
    yaml: string;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 2;
     */
    apiserverErrors: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListResourcesRequest$Type extends MessageType<ListResourcesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListResourcesRequest", [
            { no: 1, name: "pagination", kind: "message", T: () => Pagination },
            { no: 2, name: "parent_resource", kind: "message", T: () => ListResourcesRequest_ParentResource },
            { no: 3, name: "search_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "namespace_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "cluster_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "workspace_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["resources.rpc.gloo.solo.io.ResourceType", ResourceType] },
            { no: 10, name: "label_key_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "label_value_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListResourcesRequest
 */
export const ListResourcesRequest = new ListResourcesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResourcesRequest_ParentResource$Type extends MessageType<ListResourcesRequest_ParentResource> {
    constructor() {
        super("rpc.gloo.solo.io.ListResourcesRequest.ParentResource", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["resources.rpc.gloo.solo.io.ResourceType", ResourceType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListResourcesRequest.ParentResource
 */
export const ListResourcesRequest_ParentResource = new ListResourcesRequest_ParentResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResourcesResponse$Type extends MessageType<ListResourcesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListResourcesResponse", [
            { no: 1, name: "resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ResourceSummary },
            { no: 2, name: "total_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListResourcesResponse
 */
export const ListResourcesResponse = new ListResourcesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceSummary$Type extends MessageType<ResourceSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ResourceSummary", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["resources.rpc.gloo.solo.io.ResourceType", ResourceType] },
            { no: 3, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ResourceSummary
 */
export const ResourceSummary = new ResourceSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetYamlRequest$Type extends MessageType<GetYamlRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetYamlRequest", [
            { no: 1, name: "type", kind: "enum", T: () => ["resources.rpc.gloo.solo.io.ResourceType", ResourceType] },
            { no: 2, name: "ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetYamlRequest
 */
export const GetYamlRequest = new GetYamlRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetYamlResponse$Type extends MessageType<GetYamlResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetYamlResponse", [
            { no: 1, name: "yaml", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetYamlResponse
 */
export const GetYamlResponse = new GetYamlResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.DebugApi
 */
export const DebugApi = new ServiceType("rpc.gloo.solo.io.DebugApi", [
    { name: "ListResources", options: {}, I: ListResourcesRequest, O: ListResourcesResponse },
    { name: "GetYaml", options: {}, I: GetYamlRequest, O: GetYamlResponse }
]);
