import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import CertExpiryVizualizationEmpty from './CertExpiryVizualizations/CertExpiryVizualizationEmpty';
import IntermediateCertsExpiryVizualization from './CertExpiryVizualizations/IntermediateCertExpiryVizualization';
import RootCertExpiryVizualization from './CertExpiryVizualizations/RootCertExpiryVizualization';
import { CertExpiryStyles } from './CertificatesExpiryCard.style';

const CertificatesExpiryCardContent = ({
  rootCertInsights,
  intermediateCertInsights
}: {
  rootCertInsights: Insight[];
  intermediateCertInsights: Insight[];
}) => {
  //
  // Render
  //
  return (
    <CertExpiryStyles.CardContentContainer>
      {rootCertInsights.length === 0 ? (
        <CertExpiryVizualizationEmpty title='Root Certificate' type='root' />
      ) : (
        <RootCertExpiryVizualization insights={rootCertInsights} />
      )}
      {intermediateCertInsights.length === 0 ? (
        <CertExpiryVizualizationEmpty title='Intermediate Certificates' type='intermediate' />
      ) : (
        <IntermediateCertsExpiryVizualization insights={intermediateCertInsights} />
      )}
    </CertExpiryStyles.CardContentContainer>
  );
};

export default CertificatesExpiryCardContent;
