import { SoloListCardDynamic } from 'Components/Common/SoloListCard';
import { DashboardHealthCheckRow } from 'Components/Features/Dashboard/Cards/Health/DashboardHealthCheckRow';
import { useCombinedInsightStatusOrLoading } from 'Components/Features/Dashboard/Cards/Health/hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListInsightsRequest_InsightsFilter_Target } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { GetIstioControlPlaneLogsLinks } from 'utils/log-viewer-helpers';
import { buildInsightsUrl } from 'utils/url-builders';

export const ClusterIstioHealthListCard = ({
  cluster,
  lifecycleManagementStatus
}: {
  cluster: string;
  lifecycleManagementStatus: Status | undefined;
}) => {
  di(useFilteredDashboardInsights);
  const target: ListInsightsRequest_InsightsFilter_Target = { clusters: [cluster] };

  const istioControlPlaneInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.istioControlPlane,
    target
  );
  const istioControlPlaneStatus = useCombinedInsightStatusOrLoading(istioControlPlaneInsights);

  const istioDataPlaneInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.istioDataPlane,
    target
  );
  const istioDataPlaneStatus = useCombinedInsightStatusOrLoading(istioDataPlaneInsights);

  return (
    <SoloListCardDynamic title='Health' data-testid='istio-health-card'>
      <GridLayout templateColumns='20px 1fr auto' gap='20px 6px'>
        {!!lifecycleManagementStatus && (
          // TODO: update link to actual page
          <DashboardHealthCheckRow label={'Lifecycle Management'} status={lifecycleManagementStatus} />
        )}
        <DashboardHealthCheckRow
          label={'Control Plane'}
          status={istioControlPlaneStatus}
          logsLinks={GetIstioControlPlaneLogsLinks(istioControlPlaneInsights)}
        />
        <DashboardHealthCheckRow
          label={'Data Plane'}
          status={istioDataPlaneStatus}
          insightsLinks={[
            {
              label: 'Data plane',
              link: buildInsightsUrl({ codes: ['HLT0001'], clusters: [cluster] })
            }
          ]}
        />
      </GridLayout>
    </SoloListCardDynamic>
  );
};
