import { capitalize } from '@patternfly/react-core';
import React, { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import useSWR from 'swr';

export enum NoticeUrgencyLevel {
  Info = 1,
  Warning = 100,
  Error = 1000
}

// https://stackoverflow.com/a/61108377/1411473
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type Notification = {
  label?: React.ReactNode;
  message: React.ReactNode | React.ReactNode[];
  dismissable: boolean;
  id: string;
  level: NoticeUrgencyLevel;
  'data-testid'?: string;
};

export type NotificationInformation = Optional<Notification, 'id' | 'dismissable'>;

let sloppyIdCount = 0;
const generateId = () => `notification#${sloppyIdCount++}`;

export function useNotifications() {
  const { data: notifications = [], mutate: updateNotifications } = useSWR<Notification[]>('notificationsystem');

  /* Returns an id which can be used to programmatically remove notifications, especially those
      which are not dismissable by user */
  const notifyUser = ({ dismissable = false, id: customId, ...rest }: NotificationInformation): string => {
    const id = customId ?? generateId();
    updateNotifications((list = []) => [...list.filter(n => n.id !== id), { ...rest, dismissable, id }]);
    return id;
  };

  const dismissNotification = (id: string) => {
    updateNotifications((list = []) => list.filter(notification => notification.id !== id));
  };

  return {
    notifyUser,
    dismissNotification,
    notifications
  };
}

export type DisplayNotificationProps = Omit<Optional<Notification, 'dismissable'>, 'id'>;
/**
 * Used to display a single notification, as well as control when to remove it
 */
export function useDisplayNotification(notification?: DisplayNotificationProps) {
  di(useNotifications);
  const { notifyUser, dismissNotification } = useNotifications();
  const notificationId = useMemo(() => generateId(), []);
  useEffect(() => {
    if (!notification) return;
    notifyUser({ ...notification, id: notificationId });
    return () => {
      // Dismissing here clears the notification once the hook is no longer rendered.
      // It also clears previous one used by this hook between updates
      dismissNotification(notificationId);
    };
  }, [notificationId, notification]);
}

/**
 * This adds a notification to the UI if there is an apiserverErrors with errors in the response.
 * The notification element has a data-testid property that can be used to check if there was an error:
 * `data-testid=requestName.toLowerCase().replaceAll(' ','-')`.
 */
export function useApiserverErrorsNotification<T extends { apiserverErrors: string[] } | undefined>(
  requestName: string,
  data: T
) {
  const [notification, setNotification] = useState<DisplayNotificationProps>();
  useDisplayNotification(notification);

  useEffect(() => {
    let notification: DisplayNotificationProps | undefined = undefined;
    if (!!data?.apiserverErrors.length) {
      notification = {
        label: `${capitalize(requestName)} error${data.apiserverErrors.length > 1 ? 's' : ''}`,
        message: data.apiserverErrors,
        level: NoticeUrgencyLevel.Error,
        'data-testid': `${requestName.toLowerCase().replaceAll(' ', '-')}-error`
      };
    }
    setNotification(notification);
  }, [data]);
}
