import { useMemo, useState } from 'react';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { OpenAPIV3 } from 'openapi-types';
import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SecondaryInfo, SecondaryInfoItemProps } from 'Components/Common/SecondaryInfo';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloModal } from 'Components/Common/SoloModal';
import { SoloModalStyles } from 'Components/Common/SoloModal.style';

const TypeText = styled.span`
  font-size: 1rem;
`;

const DottedTooltip = styled(Tooltip)`
  text-decoration: underline dotted;
`;

interface ModalPropertiesProps {
  name: string;
  list: SecondaryInfoItemProps[];
}

type TableFields = {
  key: string;
  name: string;
  properties:
    | {
        list: SecondaryInfoItemProps[];
        openPropertiesModal: (props: ModalPropertiesProps) => void;
      }
    | string;
};

const propertiesOverflowCount = 5;
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Type',
    dataIndex: 'name',
    render(path) {
      return <TypeText>{path}</TypeText>;
    }
  },
  {
    title: 'Properties',
    dataIndex: 'properties',
    render(properties, record) {
      if (typeof properties === 'string') {
        return properties;
      }
      const { list, openPropertiesModal } = properties;
      return (
        <Spacer py='5px'>
          <SecondaryInfo items={list.slice(0, propertiesOverflowCount)} inline />

          {list.length > propertiesOverflowCount && (
            <Spacer ml='6px' display='inline'>
              <SoloLinkStyles.SoloLinkLooks
                displayInline
                onClick={() => openPropertiesModal({ name: record.name, list })}>
                and {list.length - propertiesOverflowCount} more...
              </SoloLinkStyles.SoloLinkLooks>
            </Spacer>
          )}
        </Spacer>
      );
    }
  }
];

export const TypesTab = ({ types, filter }: { types: OpenAPIV3.ComponentsObject['schemas']; filter: string }) => {
  const [modalProperties, setModalProperties] = useState<ModalPropertiesProps>();
  const tableData = useMemo<TableFields[]>(
    () =>
      Object.entries(types ?? {})
        .filter(([path]) => path.toLowerCase().includes(filter.toLowerCase()))
        .map(([name, data]) => {
          return {
            key: name,
            name,
            properties:
              '$ref' in data
                ? data.$ref
                : data.type === 'object'
                ? {
                    list: Object.entries(data.properties ?? {}).map(([key, prop]) => ({
                      label: key,
                      data:
                        '$ref' in prop ? (
                          <DottedTooltip title={prop.$ref}>ref</DottedTooltip>
                        ) : prop.type ? (
                          prop.format ?? prop.type
                        ) : (
                          'complex type'
                        )
                    })),
                    openPropertiesModal: setModalProperties
                  }
                : 'Too Complex'
          };
        }),
    [types, filter]
  );
  const { paginatedData, pagingData } = useSoloPagingWithDataFiltering(tableData, 5);

  return (
    <Spacer padding={5}>
      <SoloTable columns={columns} dataSource={paginatedData} paging={pagingData} />
      {!!modalProperties && (
        <SoloModal width={1000} withPadding onClose={() => setModalProperties(undefined)}>
          <SoloModalStyles.SimpleTitle>All properties for: {modalProperties.name}</SoloModalStyles.SimpleTitle>
          <SecondaryInfo items={modalProperties.list} />
        </SoloModal>
      )}
    </Spacer>
  );
};
