import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GetClusterDetailsCardsResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useState } from 'react';
import { ClusterIstioHealthListCard } from './ClusterIstioHealthListCard';
import { ClusterIstioOverviewListCard } from './ClusterIstioOverviewListCard';
import { ClusterIstioVersionsListCard } from './ClusterIstioVersionsListCard';

export const ClusterIstioSummaryTabContent = ({
  data,
  cluster
}: {
  data: GetClusterDetailsCardsResponse;
  cluster: string;
}) => {
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>();

  return (
    <>
      <Spacer pt={5}>
        <GridLayout templateColumns={'1fr 1fr 1fr'} gap='16px'>
          <ClusterIstioHealthListCard
            cluster={cluster}
            lifecycleManagementStatus={data.healthOverview?.lifecycleManagementStatus}
          />
          <ClusterIstioVersionsListCard
            cluster={cluster}
            selectVersion={setSelectedVersion}
            selectedVersion={selectedVersion}
          />
          <ClusterIstioOverviewListCard
            istioSummaryOverview={data.istioSummaryOverview.find(
              istioSummary => istioSummary.version === selectedVersion
            )}
          />
        </GridLayout>
      </Spacer>
    </>
  );
};
