import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgColorReplace } from 'Styles/CommonEmotions/utils';
import { SoloLinkStyles } from './SoloLink.style';

export namespace StringListStyles {
  type ListCellHolderProps = {
    multiple?: boolean;
    smallIcons?: boolean;
    iconColorUnaltered?: boolean;
  };
  export const ListCellHolder = styled.div<ListCellHolderProps>(
    ({ theme, multiple, smallIcons, iconColorUnaltered }) => css`
      display: flex;
      ${multiple
        ? css`
            cursor: pointer;
          `
        : ''}

      ${!iconColorUnaltered && svgColorReplace(theme.seaBlue)}
      svg {
        ${smallIcons
          ? css`
              width: 11px;
              margin-right: 5px;
            `
          : css`
              width: 18px;
              margin-right: 8px;
            `}
      }
    `
  );

  export const OverflowDisplay = styled.div`
    padding: 4px 0;
  `;
  export const OverflowDisplayLine = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      white-space: normal;
      line-height: 16px;

      padding-bottom: 6px;
      border-bottom: 1px solid ${theme.septemberGrey};
      margin-bottom: 6px;
      &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
      }

      ${SoloLinkStyles.SoloLinkLooks} {
        color: ${theme.lakeBlue};
        :hover {
          color: ${theme.puddleBlue};
        }
      }
    `
  );

  export const ListExtraCount = styled.div(
    ({ theme }) => css`
      margin-left: 4px;
      display: inline-block;
      font-size: 14px;
      line-height: 17px;
      border-radius: 8px;
      border: 1px solid ${theme.seaBlue};
      color: ${theme.seaBlue};
      text-transform: lowercase;
      padding: 0 4px;
    `
  );

  export const ExpandPromptCount = styled.div(
    ({ theme }) => css`
      cursor: pointer;
      color: ${theme.seaBlue};
    `
  );

  export const ExpandableList = styled.div`
    display: flex;
    align-items: center;
  `;
}
