import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloTable } from 'Components/Common/SoloTable';
import { renderMonospaceText } from 'Components/Common/SoloTableCells';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { MonospaceText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import { DlpAction_PredefinedAction } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/security/dlp_policy_pb';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GetDlpPolicyDetailsResponse_Sanitize } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetDlpPolicyDetails } = policiesApi;

const policyType = PolicyType.DLP;

const sanitizeMap: Record<GetDlpPolicyDetailsResponse_Sanitize, string> = {
  [GetDlpPolicyDetailsResponse_Sanitize.ALL]: 'Access Logs & Response Body',
  [GetDlpPolicyDetailsResponse_Sanitize.ACCESS_LOGS]: 'Access Logs',
  [GetDlpPolicyDetailsResponse_Sanitize.RESPONSE_BODY]: 'Response Body'
};

const predefinedActionMap: Record<DlpAction_PredefinedAction, string> = {
  [DlpAction_PredefinedAction.ALL_CREDIT_CARDS]: 'All Cards',
  [DlpAction_PredefinedAction.CREDIT_CARD_TRACKERS]: 'Trackers',
  [DlpAction_PredefinedAction.AMEX]: 'Amex',
  [DlpAction_PredefinedAction.DINERS_CLUB]: 'Diners Club',
  [DlpAction_PredefinedAction.DISCOVER]: 'Discover',
  [DlpAction_PredefinedAction.JCB]: 'JCB',
  [DlpAction_PredefinedAction.MASTERCARD]: 'Mastercard',
  [DlpAction_PredefinedAction.VISA]: 'Visa',
  [DlpAction_PredefinedAction.SSN]: 'SSN'
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const DlpDetails = ({ clusterObjRef }: Props) => {
  di(useGetDlpPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetDlpPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.TlsPolicyDisabled />,
              title: 'Enabled For',
              data: sanitizeMap[policyData.sanitize]
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>DLP Actions</CardStyles.CardHeader>

        <CardStyles.CardList>
          {policyData.actions.map(({ action, shadow }, idx) => (
            <CardStyles.Card
              key={
                action.oneofKind === 'customAction'
                  ? action.customAction.name
                  : action.oneofKind === 'keyValueAction'
                  ? action.keyValueAction.name
                  : action.oneofKind === 'predefinedAction'
                  ? action.predefinedAction
                  : idx
              }>
              {action.oneofKind === 'customAction' ? (
                <>
                  <CardStyles.CardHeader>
                    {action.customAction.name}
                    <CardStyles.CardHeaderSecondaryInfoHolder>
                      <SecondaryInfo
                        items={[
                          { data: 'CustomAction', highlighted: true },
                          {
                            label: 'mask character',
                            data: <MonospaceText>{action.customAction.maskChar?.value}</MonospaceText>
                          },
                          {
                            label: 'masking perecent',
                            data: `${action.customAction.percent?.value}%`
                          },
                          { label: 'shadow', data: shadow?.value ? 'true' : 'false' }
                        ]}
                      />
                    </CardStyles.CardHeaderSecondaryInfoHolder>
                  </CardStyles.CardHeader>

                  <Spacer mb='10px'>
                    <strong>Regex Actions:</strong>
                  </Spacer>
                  <SoloTable
                    columns={[
                      { title: 'Regex', dataIndex: 'regex', render: renderMonospaceText },
                      { title: 'Subgroup', dataIndex: 'subgroup' }
                    ]}
                    dataSource={action.customAction.regexActions.map(o => ({
                      ...o,
                      key: `${o.regex}/${o.subgroup}}`
                    }))}
                  />
                </>
              ) : action.oneofKind === 'keyValueAction' ? (
                <>
                  <CardStyles.CardHeader>
                    {action.keyValueAction.name}
                    <CardStyles.CardHeaderSecondaryInfoHolder>
                      <SecondaryInfo
                        items={[
                          { data: 'KeyValueAction', highlighted: true },
                          {
                            label: 'mask character',
                            data: <MonospaceText>{action.keyValueAction.maskChar?.value}</MonospaceText>
                          },
                          {
                            label: 'masking perecent',
                            data: `${action.keyValueAction.percent?.value}%`
                          },
                          { label: 'shadow', data: shadow?.value ? 'true' : 'false' }
                        ]}
                      />
                    </CardStyles.CardHeaderSecondaryInfoHolder>
                  </CardStyles.CardHeader>

                  <Spacer mb='10px'>
                    <strong>Keys to mask:</strong>
                  </Spacer>
                  <SecondaryInfo items={action.keyValueAction.keysToMask.map(k => ({ data: k }))} />
                </>
              ) : action.oneofKind === 'predefinedAction' ? (
                <>
                  <CardStyles.CardHeader>
                    {predefinedActionMap[action.predefinedAction]}
                    <CardStyles.CardHeaderSecondaryInfoHolder>
                      <SecondaryInfo items={[{ label: 'shadow', data: shadow?.value ? 'true' : 'false' }]} />
                    </CardStyles.CardHeaderSecondaryInfoHolder>
                  </CardStyles.CardHeader>
                </>
              ) : (
                <></>
              )}
            </CardStyles.Card>
          ))}
        </CardStyles.CardList>
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
