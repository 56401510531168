import { TcpMetrics } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { reduceListAverage } from '../get-graph-items-data';
import { getMetricsListLineData, isTimestampsListOverHourLong } from './DetailsTab';
import { DetailsTabStyles } from './DetailsTab.style';
import { TimelineGraph } from './TimelineGraph';

const legendData = [
  { name: 'Sent', symbol: { type: 'dash' } },
  { name: 'Received', symbol: { type: 'dash' } }
];

const lineDataIds = [
  { id: 'sent', key: 'bytesSent' as const },
  { id: 'received', key: 'bytesReceived' as const }
];

function parseTcpTimelineGraphData(metric: TcpMetrics) {
  const multipleHours = isTimestampsListOverHourLong(metric.timestamps);

  const { dataset, maxY } = getMetricsListLineData([metric], lineDataIds, multipleHours);
  const graphDataExists = !!dataset.length && maxY > 0;
  return { dataset, graphDataExists };
}
export function tcpMetricsListToCounts(metricsList?: TcpMetrics[]) {
  return (metricsList ?? []).reduce(
    (sums, metrics) => {
      sums.bytesSentCount += reduceListAverage(metrics.bytesSent);
      sums.bytesReceivedCount += reduceListAverage(metrics.bytesReceived);

      sums.startingTime = Math.min(sums.startingTime, metrics.timestamps[0].seconds);
      sums.endingTime = Math.max(sums.endingTime, metrics.timestamps[metrics.timestamps.length - 1].seconds);
      return sums;
    },
    { bytesSentCount: 0, bytesReceivedCount: 0, startingTime: Number.MAX_VALUE, endingTime: 0 }
  );
}

interface Props {
  metric: TcpMetrics;
}
export const TcpSection = ({ metric }: Props) => {
  const { bytesSentCount, bytesReceivedCount, startingTime, endingTime } = tcpMetricsListToCounts([metric]);
  const totalTime = endingTime - startingTime || 1;

  const { dataset, graphDataExists } = parseTcpTimelineGraphData(metric);

  return (
    <>
      {graphDataExists && (
        <>
          <DetailsTabStyles.SectionTitle>Bytes</DetailsTabStyles.SectionTitle>
          <DetailsTabStyles.SubsectionTitle>
            Bytes Transferred({(totalTime / 60).toFixed(0)}min)
          </DetailsTabStyles.SubsectionTitle>
          <DetailsTabStyles.SubsectionDetails>
            BPS Sent: {bytesSentCount.toFixed(2)}, BPS Recieved: {bytesReceivedCount.toFixed(2)}
          </DetailsTabStyles.SubsectionDetails>

          <TimelineGraph fixedHeight={'175px'} dataset={dataset} legendData={legendData} />
        </>
      )}
    </>
  );
};
