import { SoloLink } from 'Components/Common/SoloLink';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn } from 'Components/Common/SoloTableCells';
import { StringList } from 'Components/Common/StringList';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  UsagePlan,
  UsagePlan_Unit
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/portal_pb';
import { useMemo } from 'react';
import { TruncateText } from 'Styles/CommonEmotions/text';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { buildWorkspacePolicyDetailsUrl } from 'utils/url-builders';

const unitMap: Record<UsagePlan_Unit, string> = {
  [UsagePlan_Unit.UNKNOWN]: '?',
  [UsagePlan_Unit.SECOND]: 's',
  [UsagePlan_Unit.MINUTE]: 'm',
  [UsagePlan_Unit.HOUR]: 'h',
  [UsagePlan_Unit.DAY]: 'd'
};

interface TableFields {
  key: string;
  name?: string;
  description?: string;
  limits: { requests: number; unit: string };
  rateLimitPolicy: { text: string; link: string } | undefined;
  policies: { text: string; link: string }[];
}
const columns: SoloColumnsType<TableFields> = [
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Limits in',
    dataIndex: 'limits',
    render({ requests, unit }) {
      return `${requests} req/${unit}`;
    }
  },
  {
    title: 'Policy',
    dataIndex: 'rateLimitPolicy',
    render(data) {
      if (!data) return '';
      const { text, link } = data;
      return (
        <TruncateText title={text}>
          <SoloLink link={link}>{text}</SoloLink>
        </TruncateText>
      );
    }
  },
  {
    title: 'Auth',
    dataIndex: 'policies',
    render(policies) {
      return (
        <StringList
          list={policies.map(({ text, link }) => (
            <SoloLink key={link} link={link}>
              {text}
            </SoloLink>
          ))}
        />
      );
    }
  }
];

export const UsagePlanTable = ({ plans }: { plans: UsagePlan[] }) => {
  const { lookupWorkspaceName } = useNamespaceClusterToWorkspaceMap();

  const tableData = useMemo(
    () =>
      plans.map(plan => ({
        key: plan.name,
        name: plan.name,
        description: plan.description,
        limits: { requests: plan.requestsPerUnit, unit: unitMap[plan.timeUnit] },
        rateLimitPolicy: !!plan.rateLimitPolicy
          ? {
              text: plan.rateLimitPolicy.name,
              link: buildWorkspacePolicyDetailsUrl(
                lookupWorkspaceName(plan.rateLimitPolicy),
                PolicyType.RATE_LIMIT,
                plan.rateLimitPolicy
              )
            }
          : undefined,
        policies: plan.extAuthPolicies.map(ref => ({
          text: ref.name,
          link: buildWorkspacePolicyDetailsUrl(lookupWorkspaceName(ref), PolicyType.EXT_AUTH, ref)
        }))
      })),
    [plans, lookupWorkspaceName]
  );

  return <SoloTable columns={columns} dataSource={tableData} />;
};
