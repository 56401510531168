import { CertificateDetailsModal } from 'Components/Features/Certificates/Modals/CertificateDetailsModal';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { useMemo, useState } from 'react';
import { transformCertDataToModalProps } from 'utils/certificate-helpers';
import { formatDateToMMDDYYYY } from 'utils/helpers';
import { CertExpiryStyles } from '../CertificatesExpiryCard.style';
import { CertificateExpiryKey, certificatesExpiryCodesMap } from '../insight-codes';
import { ExpiryVizualizationLine } from './ExpiryVizualizationLine';
import { useGetExpiryLineTimeData } from './hooks';

const intermediateExpiryDataProp = certificatesExpiryCodesMap[CertificateExpiryKey.intermediateCAExpiry].dataProp;

//
// This renders a line with all intermediate certs specifically placed
// to visualize their expiration time.
//
const IntermediateCertsExpiryVizualization = ({ insights }: { insights: Insight[] }) => {
  const [insightForModal, setInsightForModal] = useState<Insight>();
  const detailsModalProps = useMemo(() => {
    const certData =
      insightForModal?.data?.data.oneofKind === intermediateExpiryDataProp
        ? insightForModal?.data.data[intermediateExpiryDataProp]
        : undefined;
    if (!certData) {
      return undefined;
    }
    return transformCertDataToModalProps('intermediate', certData);
  }, [insightForModal]);

  const { startTime, endTime } = useGetExpiryLineTimeData(insights, CertificateExpiryKey.intermediateCAExpiry);

  //
  // Format the start/end dates to display
  //
  const lineStartTimeFormattedDate = useMemo(() => formatDateToMMDDYYYY(new Date(startTime * 1000)), [startTime]);
  const lineEndTimeFormattedDate = useMemo(() => formatDateToMMDDYYYY(new Date(endTime * 1000)), [endTime]);

  //
  // Render
  //
  if (insights.length === 0) return null;
  return (
    <CertExpiryStyles.CertExpirySubSection data-testid='intermediate-certs-vizualization'>
      <CertExpiryStyles.CertExpiryTitleText>Intermediate Certificates</CertExpiryStyles.CertExpiryTitleText>
      <ExpiryVizualizationLine
        insights={insights}
        mapKey={CertificateExpiryKey.intermediateCAExpiry}
        onDetailsClicked={setInsightForModal}
      />
      <FlexLayout justifyContent='space-between'>
        <CertExpiryStyles.ExpiryDateText>{lineStartTimeFormattedDate}</CertExpiryStyles.ExpiryDateText>
        <CertExpiryStyles.ExpiryDateText>{lineEndTimeFormattedDate}</CertExpiryStyles.ExpiryDateText>
      </FlexLayout>
      {detailsModalProps && (
        <CertificateDetailsModal {...detailsModalProps} onClose={() => setInsightForModal(undefined)} />
      )}
    </CertExpiryStyles.CertExpirySubSection>
  );
};

export default IntermediateCertsExpiryVizualization;
