import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetHeaderManipulationPolicyDetails } = policiesApi;

const policyType = PolicyType.HEADER_MANIPULATION;

function headersToList(headers: (string | [string, string])[]) {
  return typeof headers[0] === 'string'
    ? headers.map(data => ({ data }))
    : headers.map(([label, data]) => ({ label, data }));
}

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const HeaderManipulationDetails = ({ clusterObjRef }: Props) => {
  di(useGetHeaderManipulationPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetHeaderManipulationPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.RequestHeaderRemove />,
              title: 'Remove Request Headers',
              data: !!policyData.removeRequestHeaders.length ? (
                <SecondaryInfo small items={headersToList(policyData.removeRequestHeaders)} />
              ) : (
                'No request headers configured to remove'
              )
            },
            {
              icon: <Asset.RequestHeaderAppend />,
              title: 'Append Request Headers',
              data: !!Object.entries(policyData.appendRequestHeaders).length ? (
                <SecondaryInfo small items={headersToList(Object.entries(policyData.appendRequestHeaders))} />
              ) : (
                'No request headers configured to append'
              )
            },
            {
              icon: <Asset.ResponseHeaderRemove />,
              title: 'Remove Response Headers',
              data: !!policyData.removeResponseHeaders.length ? (
                <SecondaryInfo small items={headersToList(policyData.removeResponseHeaders)} />
              ) : (
                'No response headers configured to remove'
              )
            },
            {
              icon: <Asset.ResponseHeaderAppend />,
              title: 'Append Response Headers',
              data: !!Object.entries(policyData.appendResponseHeaders).length ? (
                <SecondaryInfo small items={headersToList(Object.entries(policyData.appendResponseHeaders))} />
              ) : (
                'No response headers configured to append'
              )
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card data-testid='policy-routes-card'>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
