import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { healthColors } from 'Styles/colors';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace TopLevelNotificationDisplayStyles {
  type ContainerProps = {
    health: keyof typeof healthColors;
  };
  export const Container = styled.div<ContainerProps>(
    ({ health }) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 20px 0;
      padding: 20px;
      border-radius: 8px;
      background: ${healthColors[health].light};
      border: 1px solid ${healthColors[health].dark};
      color: ${health === 'warning' ? healthColors[health].text : healthColors[health].dark};
    `
  );

  export const StorybookWrapper = styled.div`
    ${Container} {
      margin: 0px 0px 20px 0px;
    }
  `;

  export const IndividualNotification = styled.div<{ dismissable?: boolean }>(
    ({ dismissable }) => css`
      line-height: ${dismissable ? '28px' : '20px'};
      display: flex;
    `
  );

  export const StatusIcon = styled.div`
    ${svgBasics(17)}
    svg {
      margin-right: 8px;

      &.warning-icon .bg {
        opacity: 0.5;
      }
    }
  `;

  export const Label = styled.b`
    white-space: nowrap;
    margin-right: 0.35em;
  `;

  export const MessageList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `;

  export const DismissalX = styled(UnstyledButton)`
    display: flex;
    cursor: pointer;
    opacity: 0.8;
    margin-left: 8px;
    margin-top: 2px;
    ${svgBasics(15, 'currentColor')}

    :hover {
      opacity: 0.5;
    }
  `;
}
