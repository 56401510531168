import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { UnaryCall } from '@protobuf-ts/runtime-rpc';
import {
  ClusterObjectRef,
  ObjectRef
} from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useRef } from 'react';
import useSWR, { Key } from 'swr';

export const host = `${process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:8090'}`;

const refreshTimes = {
  short: 5000,
  normal: 10000,
  long: 15000,
  veryLong: 30000,
  day: 24 * 60 * 60 * 1000
};

// The idea behind this, which isn't needed yet, is to allow us to short-circuit this
//  for different settings. Example, some times we may need to cut down refresh times
//  or other times we may want to delay them indefinitely.
export function getNormalApiRefreshTime() {
  return refreshTimes.normal;
}
export function getLongApiRefreshTime() {
  return refreshTimes.long;
}
export function getVeryLongApiRefreshTime() {
  return refreshTimes.veryLong;
}
export function getDayApiRefreshTime() {
  return refreshTimes.day;
}
export function getPermissionsRefreshTime() {
  // Only refresh every 12 hours
  return 12 * 60 * 60 * 1000;
}

// Used for the new protobuf-ts calls
/**
 * Handles the promise of a grpc call, logging any errors and return an object response
 * @param grpcPromise Promise of the actual call
 * @returns The call's response
 */
export async function soloGrpcCall<T extends object, K extends object>(
  grpcPromise: UnaryCall<T, K>
): Promise<K> {
  try {
    const response = await grpcPromise;
    return response.response;
  } catch (error) {
    logServiceError(error);
    throw error;
  }
}

export function logServiceError(error: any) {
  // eslint-disable-next-line no-console
  console.error('Error:', error.message);
  // eslint-disable-next-line no-console
  console.error('Code:', error.code);
  // eslint-disable-next-line no-console
  console.error('Metadata:', error.metadata);
  return error;
}

export function objectRefsAreEqual(resRef1?: ObjectRef, resRef2?: ObjectRef) {
  if (!resRef1 || !resRef2) {
    return false;
  }

  return resRef1.name === resRef2.name && resRef1.namespace === resRef2.namespace;
}

export function clusterRefsAreEqual(cRef1?: ClusterObjectRef, cRef2?: ClusterObjectRef) {
  if (!cRef1 || !cRef2) {
    return false;
  }

  return cRef1.name === cRef2.name && cRef1.namespace === cRef2.namespace && cRef1.clusterName === cRef2.clusterName;
}

type useRequestOptions =
  | {
      key: Key;
      methodDescriptorName: string;
      skip?: boolean;
    }
  | {
      methodDescriptorName: string;
      skip?: boolean;
    };

/**
 * Calls and returns `useSWR(...)` for the given function + arguments.
 * Generates a cached key by default from the function call.
 * @param fn The function to call.
 * @param fnArgs The function arguments for `fn`.
 * @param options
 * ```
 * {
 *  key?: 'custom-key' // Overrides the generated key.
 *  skip?: true // Sets key=null to skip the API request.
 * }
 * ```
 * @param swrConfig This is the same config that is passed into `useSWR`.
 * @returns `useSWR(...)`
 */
export function useRequest<T extends (...args: any) => Promise<any>>(
  fn: T,
  fnArgs: Parameters<T>,
  options: useRequestOptions,
  swrConfig?: Parameters<typeof useSWR>[2]
) {
  //
  // Set the key and return useSWR(...).
  let key: Key;
  if (options?.skip === true) key = null;
  else if ('key' in options) key = options.key;
  else {
    // Generates the key from the function + arguments.
    // Removes undefined/optional arguments from the end of the key.
    key = [options.methodDescriptorName, ...fnArgs] as any[];
    while (key.length > 1 && key[key.length - 1] === undefined) key.pop();
  }
  return useSWR<Awaited<ReturnType<T>>>(key, () => fn(...(fnArgs as any[])), {
    refreshInterval: getNormalApiRefreshTime(),
    ...(swrConfig as any)
  });
}

export function useRequestSkipRef<T extends (ref?: ClusterObjectRef, ...args: any) => any>(
  fn: T,
  args: Parameters<T>,
  methodDescriptorName: string
) {
  const ref = args[0];
  return useRequest(fn, args, {
    skip: !ref,
    methodDescriptorName
  });
}

/*
 *
 * ABORTABLES
 */
export function useSWRAbort<T extends (...args: any) => Promise<any>>(
  key: Key,
  fn: T,
  fnArgs: Parameters<T>,
  swrConfig?: Parameters<typeof useSWR>[2]
) {
  const aborter = useRef<AbortController>();
  const abort = () => aborter.current?.abort();

  const res = useSWR<Awaited<ReturnType<T>>>(
    key,
    () => {
      aborter.current = new AbortController();
      return fn(...(fnArgs as any[]), aborter.current.signal);
    },
    {
      refreshInterval: getNormalApiRefreshTime(),
      ...(swrConfig as any)
    }
  );

  return { ...res, abort };
}

export function useAbortableRequest<T extends (...args: any) => Promise<any>>(
  fn: T,
  fnArgs: Parameters<T>,
  options: useRequestOptions,
  swrConfig?: Parameters<typeof useSWR>[2]
) {
  //
  // Set the key and return useSWR(...).
  let key: Key;
  if (options?.skip === true) key = null;
  else if ('key' in options) key = options.key;
  else {
    // Generates the key from the function + arguments.
    // Removes undefined/optional arguments from the end of the key.
    key = [options.methodDescriptorName, ...fnArgs] as any[];
    while (key.length > 1 && key[key.length - 1] === undefined) key.pop();
  }
  return useSWRAbort<T>(key, fn, fnArgs, swrConfig);
}

export const grpcWebFetchTransport = new GrpcWebFetchTransport({ baseUrl: host });
