import { Insight_SYS0007Data } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { insightCodeMapToArray } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeMap, InsightCodeWithDataProp } from 'utils/dashboard/dashboard-types';

export enum CertificateExpiryKey {
  rootCAExpiry = 'rootCAExpiry',
  intermediateCAExpiry = 'intermediateCAExpiry',
  istioGatewayCAExpiry = 'istioGatewayCAExpiry',
  k8GatewayCAExpiry = 'k8GatewayCAExpiry'
}

export interface CertificateExpiryType extends Record<CertificateExpiryKey, Record<string, any>> {
  [CertificateExpiryKey.rootCAExpiry]: Insight_SYS0007Data;
}

interface CertificateExpiryCodesMap extends InsightCodeMap, Record<CertificateExpiryKey, Code> {
  [CertificateExpiryKey.rootCAExpiry]: InsightCodeWithDataProp<'sYS0007'>;
  [CertificateExpiryKey.intermediateCAExpiry]: InsightCodeWithDataProp<'sYS0008'>;
  [CertificateExpiryKey.istioGatewayCAExpiry]: InsightCodeWithDataProp<'sYS0015'>;
  [CertificateExpiryKey.k8GatewayCAExpiry]: InsightCodeWithDataProp<'sYS0025'>;
}

export const certificatesExpiryCodesMap: CertificateExpiryCodesMap = {
  [CertificateExpiryKey.rootCAExpiry]: {
    // Root CA Expiry
    // Data: &insights_v2alpha1.Insight_Data_SYS0007{
    // 	SYS0007: &insights_v2alpha1.Insight_SYS0007Data{
    // 		Hostname:                   "solo.test",
    // 		IssuedToCn:                 "*.google.com",
    // 		IssuedToOrganization:       "",
    // 		IssuedToOrganizationUnit:   "",
    // 		IssuedFromCn:               "GTS CA 1C3",
    // 		IssuedFromOrganization:     "Google Trust Services LLC",
    // 		IssuedFromOrganizationUnit: "",
    // 		IssuedOn:                   mustParseTime(time.Now()),
    // 		ExpiresOn:                  mustParseTime(time.Now().Add(21 * time.Hour)),
    // 		Fingerprints: []*insights_v2alpha1.Insight_SYS0007Data_Fingerprints{
    // 			{
    // 				Name:  "SHA256",
    // 				Value: "65:2c:c4:03:03:2f:6d:c0:c0:09:79:e7:33:c4:f1:78",
    // 			},
    // 		},
    // 	},
    // },
    group: 'SYS',
    key: '0007',
    dataProp: 'sYS0007'
  },
  [CertificateExpiryKey.intermediateCAExpiry]: {
    // Intermediate CA Expiry
    // Data: &insights_v2alpha1.Insight_Data_SYS0008{
    // 	SYS0008: &insights_v2alpha1.Insight_SYS0008Data{
    // 		Hostname:                   "cluster1.solo.test",
    // 		IssuedToCn:                 "*.google.com",
    // 		IssuedToOrganization:       "",
    // 		IssuedToOrganizationUnit:   "",
    // 		IssuedFromCn:               "GTS CA 1C3",
    // 		IssuedFromOrganization:     "Google Trust Services LLC",
    // 		IssuedFromOrganizationUnit: "",
    // 		IssuedOn:                   mustParseTime(time.Now()),
    // 		ExpiresOn:                  mustParseTime(time.Now().Add(-27 * time.Hour)),
    // 		Fingerprints: []*insights_v2alpha1.Insight_SYS0008Data_Fingerprints{
    // 			{
    // 				Name:  "SHA256",
    // 				Value: "65:2c:c4:03:03:2f:6d:c0:c0:09:79:e7:33:c4:f1:78",
    // 			},
    // 		},
    // 	},
    // },
    group: 'SYS',
    key: '0008',
    dataProp: 'sYS0008'
  },
  [CertificateExpiryKey.istioGatewayCAExpiry]: {
    // Gateway CA Expiry
    // Data: &insights_v2alpha1.Insight_Data_SYS0015{
    //   SYS0015: &insights_v2alpha1.Insight_SYS0015Data{
    //     Hostname:                   "cluster1.solo.test",
    //     IssuedToCn:                 "*.google.com",
    //     IssuedToOrganization:       "",
    //     IssuedToOrganizationUnit:   "",
    //     IssuedFromCn:               "GTS CA 1C3",
    //     IssuedFromOrganization:     "Google Trust Services LLC",
    //     IssuedFromOrganizationUnit: "",
    //     IssuedOn:                   mustParseTime(time.Now()),
    //     ExpiresOn:                  mustParseTime(time.Now().Add(-27 * time.Hour)),
    //     Fingerprints: []*insights_v2alpha1.Insight_SYS0015Data_Fingerprints{
    //       {
    //         Name:  "SHA256",
    //         Value: "65:2c:c4:03:03:2f:6d:c0:c0:09:79:e7:33:c4:f1:78",
    //       },
    //     },
    //   },
    // },
    group: 'SYS',
    key: '0015',
    dataProp: 'sYS0015'
  },
  [CertificateExpiryKey.k8GatewayCAExpiry]: {
    // Kube Gateway CA Expiry
    // Data: &insights_v2alpha1.Insight_Data{
    //   Data: &insights_v2alpha1.Insight_Data_SYS0025{
    //     SYS0025: &insights_v2alpha1.Insight_SYS0025Data{
    //       Certificates: []*insights_v2alpha1.Insight_ReferencedCertificateSecret{{
    //         Ref: &insights_v2alpha1.Insight_ResourceRef{
    //           Name:      "tls-secret",
    //           Namespace: "httpbin",
    //           Gvk: &insights_v2alpha1.Insight_ResourceRef_GVK{
    //             Group:   gvk.Secret.Group,
    //             Version: gvk.Secret.Version,
    //             Kind:    gvk.Secret.Kind,
    //           },
    //           Cluster: "cluster-1",
    //         },
    //         Certificate: &insights_v2alpha1.Insight_CertificateInformation{
    //           Hostname:                   "cluster1.solo.test",
    //           IssuedToCn:                 "*.google.com",
    //           IssuedToOrganization:       []string{""},
    //           IssuedToOrganizationUnit:   []string{""},
    //           IssuedFromCn:               "GTS CA 1C3",
    //           IssuedFromOrganization:     []string{"Google Trust Services LLC"},
    //           IssuedFromOrganizationUnit: []string{""},
    //           SubjectKeyIdentifier:       "B1:37:44:1B:58:AA:1A:0C:7F:79:8D:B5:A1:F2:AA:68:A1:F9:7F:4A",
    //           AuthorityKeyIdentifier:     "B1:37:44:1B:58:AA:1A:0C:7F:79:8D:B5:A1:F2:AA:68:A1:F9:7F:4A",
    //           IssuedOn:                   mustParseTime(time.Now()),
    //           ExpiresOn:                  mustParseTime(time.Now().Add(-27 * time.Hour)),
    //           Fingerprints: []*insights_v2alpha1.Insight_CertificateInformation_Fingerprints{
    //             {
    //               Name:  "SHA256",
    //               Value: "65:2c:c4:03:03:2f:6d:c0:c0:09:79:e7:33:c4:f1:78",
    //             },
    //           },
    //         },
    //       }},
    //     },
    //   },
    // },
    group: 'SYS',
    key: '0025',
    dataProp: 'sYS0025'
  }
};

export const certificatesExpiryCodesArray = insightCodeMapToArray(certificatesExpiryCodesMap);
