import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Select } from 'antd';

export namespace SoloDropdownStyles {
  export const Container = styled.div`
    width: 100%;
  `;

  export const SoloDropdownBlock = styled(Select)(
    ({ theme }) => css`
      position: relative;
      width: inherit;
      line-height: 16px;
      border-radius: 3px;
      font-size: 16px;

      &.ant-select .ant-select-selector {
        border-radius: 3px;
        border: 1px solid ${theme.aprilGrey} !important;
        line-height: 16px;
        outline: none !important;
        height: auto;
        cursor: pointer;

        .ant-select-selection-search,
        .ant-select-selection-search input {
          padding: 0;
        }
        .ant-select-selection-placeholder {
          color: ${theme.juneGrey};
        }

        .ant-select-selection__rendered {
          line-height: inherit;
          margin: 0;

          .ant-select-selection-selected-value {
            color: ${theme.septemberGrey};
          }
        }

        &:disabled {
          background: ${theme.aprilGrey};
        }
      }

      .ant-select-arrow {
        position: absolute;
        right: 10px;
        top: 17px;
      }
    `
  );
}
