import { CardStyles } from 'Components/Common/Card';
import { Loading } from 'Components/Common/Loading';
import { SoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import { di } from 'react-magnetic-di';
import {
  useFilteredDashboardInsights,
  useDataPropOfFirstInsight,
  useInsightEnablementChecks
} from 'utils/dashboard/dashboard-hooks';
import { DashboardStyles } from '../../DashboardLanding.style';
import ClusterServicesCardContent from './ClusterServicesCardContent';
import { ClusterServicesKey, clusterServicesCodesMap } from './insight-codes';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { buildServicesUrl } from 'utils/url-builders';

const clusterServicesDataProp = clusterServicesCodesMap[ClusterServicesKey.clusterServicesSummary].dataProp;

const ClusterServicesCard = () => {
  di(useFilteredDashboardInsights, useInsightEnablementChecks);
  const { isIstioEnabledAndLicenced } = useInsightEnablementChecks();
  //
  // Gets Cluster Services Summary Data
  //
  const insights = useFilteredDashboardInsights(clusterServicesCodesMap, ClusterServicesKey.clusterServicesSummary);
  const summaryData = useDataPropOfFirstInsight(clusterServicesDataProp, insights);

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='cluster-services-card'>
      <DashboardStyles.CardColumn>
        <DashboardStyles.Header>
          <Svg asset={<Asset.ClusterServicesIcon />} color={theme => theme.neptuneBlue} />
          Cluster Services
        </DashboardStyles.Header>
        {insights === undefined ? (
          <Loading />
        ) : !summaryData ? (
          <SoloEmptySimple description='No Data' icon={<Asset.ClusterServicesIcon />} iconSize={75} greyscale />
        ) : (
          <ClusterServicesCardContent data={summaryData} />
        )}
        {isIstioEnabledAndLicenced && (
          <DashboardStyles.CardBottomLink>
            <SoloLink small link={buildServicesUrl()} withArrow>
              DETAILS
            </SoloLink>
          </DashboardStyles.CardBottomLink>
        )}
      </DashboardStyles.CardColumn>
    </CardStyles.Card>
  );
};

export default ClusterServicesCard;
