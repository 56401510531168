import { CardStyles } from 'Components/Common/Card';
import { Svg } from 'Components/Common/Svg';
import { AnalysisAndInsightsStyles } from 'Components/Features/Dashboard/Cards/AnalysisAndInsights/AnalysisAndInsights.style';
import { InsightStatusCard } from 'Components/Features/Dashboard/Cards/AnalysisAndInsights/InsightStatusCard/InsightStatusCard';
import InsightsChart from 'Components/Features/Dashboard/Cards/AnalysisAndInsights/InsightsChart';
import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { Asset } from 'assets';
import { InsightsOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';

export const ClusterInsightsCard = ({
  insightsOverview,
  cluster
}: {
  insightsOverview: InsightsOverview | undefined;
  cluster: string;
}) => {
  return (
    <CardStyles.Card data-testid='cluster-insights-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.InsightAnalysisIcon />} color={theme => theme.neptuneBlue} size={27} />
        Insights
      </DashboardStyles.Header>
      <AnalysisAndInsightsStyles.CardContentContainer>
        <InsightStatusCard statusType='error' value={insightsOverview?.errorCount ?? 0} clusterFilter={cluster} />
        <InsightStatusCard statusType='warning' value={insightsOverview?.warningCount ?? 0} clusterFilter={cluster} />
        <InsightStatusCard statusType='info' value={insightsOverview?.infoCount ?? 0} clusterFilter={cluster} />
        <InsightsChart
          categoryCounts={insightsOverview?.categoryCounts ?? {}}
          total={insightsOverview?.totalCount ?? 0}
          hideRightLine
        />
      </AnalysisAndInsightsStyles.CardContentContainer>
    </CardStyles.Card>
  );
};
