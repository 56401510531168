import { DashboardContext } from 'Components/Features/Dashboard/DashboardContext';
import { useContext } from 'react';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { useCombinedInsightStatusOrLoading } from '../hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from '../insight-codes';
import { GlooHealthCheckSectionContent } from './GlooHealthCheckSectionContent';

export const GlooHealthCheckSection = () => {
  di(useFilteredDashboardInsights);

  // Gloo mgmt plane status comes from the dashboard cards.
  const { dashboardCardsData } = useContext(DashboardContext);
  const glooManagementPlaneStatus =
    dashboardCardsData === undefined ? 'loading' : dashboardCardsData.healthOverview?.glooManagementPlaneStatus;
  const glooManagementPlaneInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.glooManagementPlane
  );

  const glooAgentInsights = useFilteredDashboardInsights(dashboardHealthCodesMap, DashboardHealthKey.glooAgent);
  const glooAgentStatus = useCombinedInsightStatusOrLoading(glooAgentInsights);

  return (
    <GlooHealthCheckSectionContent
      glooManagementPlaneInsights={glooManagementPlaneInsights}
      glooManagementPlaneStatus={glooManagementPlaneStatus}
      glooAgentInsights={glooAgentInsights}
      glooAgentStatus={glooAgentStatus}
    />
  );
};
