import { Tooltip } from 'antd';
import GraphiQL from 'graphiql';
import { OperationDefinitionNode } from 'graphql';
import React, { useMemo, useState } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlExplorerStyles } from './GqlExplorer.style';

const GqlExplorerRunButton = ({
  graphiqlRef,
  onRunOperation
}: {
  graphiqlRef: React.MutableRefObject<GraphiQL | null>;
  onRunOperation: (operationName?: string) => void;
}) => {
  //
  // STATE
  //
  const [showRunDropdown, setShowRunDropdown] = useState(false);
  // These are the parsed GraphQL operations that the user types into the editor.
  // Note: These operations were not initializing properly when passed in as a prop.
  //       Getting the operations here (with the `graphiqlRef` prop) fixes that.
  const operations: OperationDefinitionNode[] = useMemo(() => {
    const queryEditor = graphiqlRef.current?.ref?.props.editorContext.queryEditor;
    if (!queryEditor) return [];
    return queryEditor.operations ?? [];
  }, [graphiqlRef.current?.ref?.props.editorContext.queryEditor?.operations]);

  //
  // Render
  //
  return (
    <GqlExplorerStyles.Toolbar.ButtonDropdownContainer
      numDropdownItems={operations.length}
      onMouseEnter={() => setShowRunDropdown(true)}
      onMouseLeave={() => setShowRunDropdown(false)}>
      <Tooltip title='Run Operation (Ctrl-Enter)' trigger='hover'>
        <GqlExplorerStyles.Toolbar.Button
          variant='primary'
          padded
          onClick={() => {
            if (operations.length === 0) return;
            else onRunOperation(operations[0].name?.value);
          }}>
          <Spacer mt='1px'>RUN</Spacer>
        </GqlExplorerStyles.Toolbar.Button>
      </Tooltip>
      {showRunDropdown && operations.length > 1 && (
        <GqlExplorerStyles.Toolbar.ButtonDropdownList>
          {operations.map((op, idx) => (
            <GqlExplorerStyles.Toolbar.ButtonDropdownItem
              key={idx}
              disabled={!op.name?.value}
              onClick={() => onRunOperation(op.name?.value)}>
              {op.operation}&nbsp;{op.name?.value}
            </GqlExplorerStyles.Toolbar.ButtonDropdownItem>
          ))}
        </GqlExplorerStyles.Toolbar.ButtonDropdownList>
      )}
    </GqlExplorerStyles.Toolbar.ButtonDropdownContainer>
  );
};

export default GqlExplorerRunButton;
