import { FlexLayout, FlexLayoutSpacer } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { DashboardStyles } from '../../DashboardLanding.style';
import { ClusterServicesStyles } from './ClusterServices.style';
import ClusterServicesSummaryChart, { clusterServicesChartColors } from './ClusterServicesSummaryChart';
import { ClusterServicesCodesDataTypeMap, ClusterServicesKey } from './insight-codes';
import { buildServicesUrl } from 'utils/url-builders';
import { useInsightEnablementChecks } from 'utils/dashboard/dashboard-hooks';
import { di } from 'react-magnetic-di';
import React from 'react';

type clusterServicesSummaryDataType = ClusterServicesCodesDataTypeMap[ClusterServicesKey.clusterServicesSummary];

const OptionalIstioLink = ({ children, to, isIstio }: { children: React.ReactNode; to: string; isIstio: boolean }) => {
  return isIstio ? (
    <DashboardStyles.ChartLegendRowAsLink to={to}>{children}</DashboardStyles.ChartLegendRowAsLink>
  ) : (
    <DashboardStyles.ChartLegendRow>{children}</DashboardStyles.ChartLegendRow>
  );
};

const ClusterServicesCardContent = ({ data }: { data: clusterServicesSummaryDataType }) => {
  di(useInsightEnablementChecks);
  const { isIstioEnabledAndLicenced } = useInsightEnablementChecks();
  return (
    <ClusterServicesStyles.CardContentContainer>
      <ClusterServicesStyles.CardContent>
        <Spacer pb={4} pt={2} ml={4}>
          <DashboardStyles.SubHeader>Total Services</DashboardStyles.SubHeader>
          <OptionalIstioLink to={buildServicesUrl({ type: 'out-of-mesh' })} isIstio={isIstioEnabledAndLicenced}>
            <DashboardStyles.ChartLegendColorSample datasetColor={clusterServicesChartColors.outOfMeshServicesColor} />
            <DashboardStyles.ChartLegendCountText
              datasetColor={clusterServicesChartColors.outOfMeshServicesColor}
              data-testid='services-out-of-mesh-count'>
              {data.outOfMeshServices}
            </DashboardStyles.ChartLegendCountText>
            Out of Mesh
          </OptionalIstioLink>
          <OptionalIstioLink to={buildServicesUrl({ type: 'in-mesh' })} isIstio={isIstioEnabledAndLicenced}>
            <DashboardStyles.ChartLegendColorSample datasetColor={clusterServicesChartColors.inMeshServicesColor} />
            <DashboardStyles.ChartLegendCountText
              datasetColor={clusterServicesChartColors.inMeshServicesColor}
              data-testid='services-in-mesh-count'>
              {data.inMeshServices}
            </DashboardStyles.ChartLegendCountText>
            In Mesh
          </OptionalIstioLink>
        </Spacer>
        <FlexLayoutSpacer />
        <FlexLayout justifyContent='center' alignItems='center'>
          <Spacer pb={4}>
            <ClusterServicesSummaryChart data={data} />
          </Spacer>
        </FlexLayout>
        <FlexLayoutSpacer />
        <Spacer pb={4} pt={2} ml={4}>
          <DashboardStyles.SubHeader>In Mesh Services</DashboardStyles.SubHeader>
          <DashboardStyles.ChartLegendRow>
            <DashboardStyles.ChartLegendColorSample datasetColor={clusterServicesChartColors.ambientServicesColor} />
            <DashboardStyles.ChartLegendCountText
              datasetColor={clusterServicesChartColors.ambientServicesColor}
              data-testid='services-ambient-count'>
              {data.ambientServices}
            </DashboardStyles.ChartLegendCountText>
            Ambient Services
          </DashboardStyles.ChartLegendRow>
          <DashboardStyles.ChartLegendRow>
            <DashboardStyles.ChartLegendColorSample datasetColor={clusterServicesChartColors.sidecarServicesColor} />
            <DashboardStyles.ChartLegendCountText
              datasetColor={clusterServicesChartColors.sidecarServicesColor}
              data-testid='services-sidecar-count'>
              {data.sidecarServices}
            </DashboardStyles.ChartLegendCountText>
            Sidecar Services
          </DashboardStyles.ChartLegendRow>
        </Spacer>
        <FlexLayoutSpacer />
      </ClusterServicesStyles.CardContent>
    </ClusterServicesStyles.CardContentContainer>
  );
};

export default ClusterServicesCardContent;
