import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { svgBasics, svgColorReplace } from 'Styles/CommonEmotions/utils';
import { CardStyles } from './Card';

export namespace SoloModalStyles {
  interface ModalWindowProps {
    // modal takes up max screen height without ever going beyond it and requiring a scrollbar
    // to prevent content inside overflowing, a container inside the modal must have `overflow: auto;`
    noScrollMaxHeight?: boolean;
  }
  export const ModalWindow = styled.div<ModalWindowProps>(({ noScrollMaxHeight }) => {
    return css`
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: grid;
      justify-content: center;
      background: rgba(0, 0, 0, 0.1);
      padding-top: ${noScrollMaxHeight ? '50px' : '100px'};
      padding-bottom: 50px;
      overflow: auto;
      line-height: 19px;
      z-index: 100;
      border-radius: 10px;

      ${noScrollMaxHeight &&
      css`
        max-height: calc(100vh - 100px - 50px);
        height: calc(100vh - 100px - 50px);
        box-sizing: content-box;

        ${BlockHolder}, ${ModalBlock} {
          height: 100%;
          min-height: 100%;
        }
      `}
    `;
  });

  export const Body = styled(CardStyles.Card)``;

  export const BlockHolder = styled.div`
    height: fit-content;
    border-radius: 10px;
  `;

  export interface ModalBlockProps {
    width: CSSProperties['width'];
    minHeight?: CSSProperties['minHeight'];
  }
  export const ModalBlock = styled.div<ModalBlockProps>(
    ({ minHeight, width }) => css`
      position: relative;
      width: ${width === 'auto' ? width : `${width}px`};
      max-width: calc(100vw - 50px);
      min-height: ${minHeight ?? '400px'};
      border-radius: 10px;
      background: white;

      ${Body} {
        min-height: inherit;
        box-sizing: border-box;
      }
    `
  );

  export const CloseXContainer = styled.button(
    ({ theme }) => css`
      all: unset;
      position: absolute;
      display: flex;
      right: 20px;
      top: 20px;
      z-index: 2;
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 2px;
      padding: 5px;
      ${svgBasics(20)}

      &:hover,
      &:focus-visible {
        background-color: ${theme.aprilGrey};
        ${svgColorReplace('white')}
        &:active {
          background-color: ${theme.juneGrey};
        }
        &:focus-visible {
          outline: 2px solid ${theme.aprilGrey} !important;
        }
      }
    `
  );

  export const SimpleTitle = styled.div(({ theme }) => {
    return css`
      font-size: 22px;
      font-weight: bold;
      color: ${theme.novemberGrey};
      line-height: normal;
      padding: 23px 18px 13px;

      ${Body} & {
        padding: 5px 0 13px;
      }
    `;
  });

  export namespace Header {
    export const Container = styled.div`
      display: flex;
      gap: 15px;
      padding-bottom: 15px;
      padding-right: 40px;
    `;

    export const TextContainer = styled.div``;

    export const Title = styled.div`
      font-weight: 400;
      font-size: 22px;
      margin: 5px 0;
    `;

    export const Subtitle = styled.div(
      ({ theme }) => css`
        font-size: 18px;
        color: ${theme.augustGrey};
      `
    );

    export const RightContent = styled.div`
      flex: 1;
      display: flex;
    `;
  }
}
