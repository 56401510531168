/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "xds/type/matcher/v3/regex.proto" (package "xds.type.matcher.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Regex matcher]

/**
 * A regex matcher designed for safety when used with untrusted input.
 *
 * @generated from protobuf message xds.type.matcher.v3.RegexMatcher
 */
export interface RegexMatcher {
    /**
     * @generated from protobuf oneof: engine_type
     */
    engineType: {
        oneofKind: "googleRe2";
        /**
         * Google's RE2 regex engine.
         *
         * @generated from protobuf field: xds.type.matcher.v3.RegexMatcher.GoogleRE2 google_re2 = 1;
         */
        googleRe2: RegexMatcher_GoogleRE2;
    } | {
        oneofKind: undefined;
    };
    /**
     * The regex match string. The string must be supported by the configured
     * engine.
     *
     * @generated from protobuf field: string regex = 2;
     */
    regex: string;
}
/**
 * Google's `RE2 <https://github.com/google/re2>`_ regex engine. The regex
 * string must adhere to the documented `syntax
 * <https://github.com/google/re2/wiki/Syntax>`_. The engine is designed to
 * complete execution in linear time as well as limit the amount of memory
 * used.
 *
 * Envoy supports program size checking via runtime. The runtime keys
 * `re2.max_program_size.error_level` and `re2.max_program_size.warn_level`
 * can be set to integers as the maximum program size or complexity that a
 * compiled regex can have before an exception is thrown or a warning is
 * logged, respectively. `re2.max_program_size.error_level` defaults to 100,
 * and `re2.max_program_size.warn_level` has no default if unset (will not
 * check/log a warning).
 *
 * Envoy emits two stats for tracking the program size of regexes: the
 * histogram `re2.program_size`, which records the program size, and the
 * counter `re2.exceeded_warn_level`, which is incremented each time the
 * program size exceeds the warn level threshold.
 *
 * @generated from protobuf message xds.type.matcher.v3.RegexMatcher.GoogleRE2
 */
export interface RegexMatcher_GoogleRE2 {
}
// @generated message type with reflection information, may provide speed optimized methods
class RegexMatcher$Type extends MessageType<RegexMatcher> {
    constructor() {
        super("xds.type.matcher.v3.RegexMatcher", [
            { no: 1, name: "google_re2", kind: "message", oneof: "engineType", T: () => RegexMatcher_GoogleRE2, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "regex", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.RegexMatcher
 */
export const RegexMatcher = new RegexMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegexMatcher_GoogleRE2$Type extends MessageType<RegexMatcher_GoogleRE2> {
    constructor() {
        super("xds.type.matcher.v3.RegexMatcher.GoogleRE2", []);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.RegexMatcher.GoogleRE2
 */
export const RegexMatcher_GoogleRE2 = new RegexMatcher_GoogleRE2$Type();
