import * as Cy from 'cytoscape';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';

export * from './graph-types';

type ClickedTargetPossibilities = Cy.NodeSingular | Cy.EdgeSingular | Cy.Core;

export const isGraphCore = (target: ClickedTargetPossibilities): target is Cy.Core => {
  return !('cy' in target);
};

export const isNode = (target: ClickedTargetPossibilities): target is Cy.NodeSingular => {
  return !isGraphCore(target) && target.isNode();
};

export const isEdge = (target: ClickedTargetPossibilities): target is Cy.EdgeSingular => {
  return !isGraphCore(target) && target.isEdge();
};

export function splitNodeIdIntoData(id: string): ClusterObjectRef {
  const [name, namespace, clusterName] = id.split('.');
  return {
    name,
    namespace,
    clusterName
  };
}

export function clusterObjRefToNodeId(ref: ClusterObjectRef): string {
  return [ref.name, ref.namespace, ref.clusterName].join('.');
}

export function getEdgeIdFromData(srcId?: string, tgtId?: string): string {
  return `${srcId}--${tgtId}`;
}

export const unhighlightNodes = (cyRef: Cy.Core) => {
  cyRef.elements(`.selected`).removeClass('selected');
  unselectAll(cyRef);
};

export const resetGraph = (cyRef: Cy.Core) => {
  cyRef.nodes().removeClass('unselected').removeClass('selected');

  cyRef.edges().removeClass('unselected').removeClass('selected');
};

export const unselectAll = (cyRef: Cy.Core) => {
  cyRef.elements(`.selected`).removeClass('selected');
  cyRef.elements().addClass('unselected');

  cyRef.edges(`.selected`).removeClass('selected');
  cyRef.edges().addClass('unselected');
};
export const selectElements = (elements: Cy.CollectionArgument[]) => {
  elements.forEach(e => e.removeClass('unselected').addClass('selected'));
};

let hoveredNode: Cy.NodeSingular | undefined = undefined;
export const graphOnMouseIn = (target: Cy.NodeSingular) => {
  // only highlight on hover when the graph is currently selected, otherwise leave the
  // selected element highlighted
  if (isNode(target)) {
    target.addClass('hovered');
    hoveredNode = target;
  }
};

export const graphOnMouseOut = (target: Cy.NodeSingular) => {
  if (hoveredNode === target) {
    target.removeClass('hovered');
    hoveredNode = undefined;
  }
};

export function graphIsNodeHovered(target: Cy.NodeSingular, { x, y }: Cy.Position) {
  const r = (target.cy() as any)._private?.renderer;
  if (r) {
    // https://github.com/cytoscape/cytoscape.js/blob/03864cf70c36b327d67b96e2e36d9b83ed8ccaa0/src/extensions/renderer/base/load-listeners.js#L565
    let near = r.findNearestElement(x, y, true, false);
    return near?.id() === target.id();
  }
}
