import { useState } from 'react';
import { Asset } from 'assets';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { AddOverviewResourceModalBodyStyles as Styles } from './AddOverviewResourceModalBody.style';
import { FancyCodeDisplayer } from 'Components/Common/FancyCodeDisplayer';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import workspaceYaml from 'assets/yamls/workspace_gloo-mesh_anything.yaml';
import settingsYaml from 'assets/yamls/workspace-settings_federation_anything.yaml';
import { YamlModalStyles } from 'Components/Common/YamlModal.style';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { docLinks } from 'utils/url-external-links-map';

export const AddWorkspaceModalBody = () => {
  const [yamlString, setYamlString] = useState<string>();
  if (yamlString) {
    return (
      <Styles.Container data-testid='add-overview-resource-modal'>
        <div>
          <Styles.Title>
            Creating a Workspace <Asset.WorkspaceIcon />
          </Styles.Title>
          <Styles.Hint>Organize your registered clusters and namespaces into a Gloo Platform workspace.</Styles.Hint>
        </div>
        <YamlModalStyles.Content>
          <FancyCodeDisplayer contentString={yamlString} type='yaml' maxHeight='none' />
          <Spacer mt='12px'>
            <SoloButton onClick={() => setYamlString(undefined)}>
              <FlexLayout horizontal flexGrow='1'>
                BACK
              </FlexLayout>
            </SoloButton>
          </Spacer>
        </YamlModalStyles.Content>
      </Styles.Container>
    );
  }
  return (
    <Styles.Container data-testid='add-overview-resource-modal'>
      <Styles.Title>
        Creating a Workspace <Asset.WorkspaceIcon />
      </Styles.Title>
      <Styles.Hint>Organize your registered clusters and namespaces into a Gloo Platform workspace.</Styles.Hint>

      <Styles.InstructionsArea>
        <Styles.InstructionHint>
          <Styles.InstructionNumber>1</Styles.InstructionNumber>
          Pick a unique name for your workspace.
        </Styles.InstructionHint>

        <Styles.InstructionHint>
          <Styles.InstructionNumber>2</Styles.InstructionNumber>
          <b>Optional:</b> Label your workspace. Later, you can use this label to import and export resources to your
          workspace.
        </Styles.InstructionHint>

        <Styles.InstructionHint>
          <Styles.InstructionNumber>3</Styles.InstructionNumber>
          Decide on the clusters that you want to include in your workspace.
        </Styles.InstructionHint>

        <Styles.InstructionHint>
          <Styles.InstructionNumber>4</Styles.InstructionNumber>
          Choose the namespaces in each cluster that you want to add to your workspace.
        </Styles.InstructionHint>

        <Styles.InstructionHint>
          <Styles.InstructionNumber>5</Styles.InstructionNumber>
          Create the workspace. You can use{' '}
          <SoloLinkStyles.SoloLinkLooksButton displayInline onClick={() => setYamlString(workspaceYaml)}>
            this example
          </SoloLinkStyles.SoloLinkLooksButton>{' '}
          to create a workspace for all of your clusters and namespaces. For more information,{' '}
          <ExternalSoloLink inline newTab href={docLinks.enterprise.workspace_setup}>
            see the docs
          </ExternalSoloLink>
          .
        </Styles.InstructionHint>

        <Styles.InstructionHint>
          <Styles.InstructionNumber>6</Styles.InstructionNumber>
          Configure the workspace settings. You can use{' '}
          <SoloLinkStyles.SoloLinkLooksButton displayInline onClick={() => setYamlString(settingsYaml)}>
            this example
          </SoloLinkStyles.SoloLinkLooksButton>{' '}
          to enable federation for cross-cluster communication. To enable service isolation, federation, or importing
          and exporting resources across workspaces,{' '}
          <ExternalSoloLink inline newTab href={docLinks.enterprise.workspace_setup_HASH.configure_workspace_settings}>
            see the docs
          </ExternalSoloLink>
          .
        </Styles.InstructionHint>
      </Styles.InstructionsArea>
    </Styles.Container>
  );
};
