import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { SoloDropdownStyles } from 'Components/Common/Input/SoloDropdown.style';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloPaginationStyles } from 'Components/Common/SoloPagination.style';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { healthColors } from 'Styles/colors';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getStatusColors } from 'utils/health-status';

export namespace ClustersLandingStyles {
  export const ContentBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;
  `;

  export const ContentBodyFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    > ${SoloPaginationStyles.PaginationRow} {
      margin-top: 3px;
      padding-bottom: 0;
    }
  `;
  export const FooterCount = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      color: ${theme.augustGrey};
      height: 100%;
      font-size: 14px;
    `
  );
  export const AddItemPrompt = styled(UnstyledButton)(
    ({ theme }) => css`
      ${SoloLinkStyles.SoloLinkLooks} {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        height: 100%;
      }

      ${svgBasics(19)}
      svg {
        margin-left: 10px;
      }
    `
  );

  export const SortContainer = styled.div`
    display: flex;

    ${SoloDropdownStyles.SoloDropdownBlock} {
      &.ant-select .ant-select-selector {
        border-radius: 0 3px 3px 0;
      }
    }
  `;

  interface OrderDirectionToggleProps {
    reversed: boolean;
  }
  export const OrderDirectionToggle = styled.div<OrderDirectionToggleProps>(
    ({ theme, reversed }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      border: 1px solid ${theme.aprilGrey};
      border-right: 0;
      border-radius: 3px 0 0 3px;

      ${svgBasics(16)}
      svg {
        .sort-arrows--up {
          fill: ${reversed ? theme.splashBlue : theme.oceanBlue};
        }
        .sort-arrows--down {
          fill: ${reversed ? theme.oceanBlue : theme.splashBlue};
        }
      }
    `
  );
  export const MoreLinkLine = styled.div`
    width: 100%;

    ${SoloLinkStyles.SoloLinkWrapper} {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      a {
        ${SoloLinkStyles.SoloLinkLooks} {
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;

          ${svgBasics({ width: 15 }, 'currentColor')}
          svg {
            margin-left: 5px;
            transform: translateY(2px);
          }
        }
      }
    }
  `;
}
export namespace ClustersLandingCardStyles {
  type OverviewHealthProps = {
    health?: State;
  };
  type BodyProps = { collapsed: boolean };

  export const Container = styled(CardStyles.ShadowlessCard)<OverviewHealthProps>(
    ({ theme, health, onClick }) => css`
      padding: 0;
      border: 1px solid ${health === State.ACCEPTED ? theme.aprilGrey : getStatusColors(health).border};
      margin-bottom: 20px;
      cursor: ${!!onClick ? 'pointer' : 'initial'};
    `
  );

  export const Header = styled.div<OverviewHealthProps>(
    ({ health, theme }) => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${health === State.ACCEPTED ? theme.marchGrey : getStatusColors(health).background};
      padding: 8px 10px;
      border-radius: 4px 4px 0 0;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
      }
    `
  );
  export const Title = styled.div<OverviewHealthProps>(
    ({ health, theme }) => css`
      color: ${health === State.ACCEPTED ? theme.oceanBlue : getStatusColors(health).text};
    `
  );

  export const HealthHolder = styled.div`
    display: flex;
    align-items: center;

    ${svgBasics(16)}
  `;

  export const Body = styled.div`
    padding: 8px 14px;
  `;
  export const BodyDetails = styled.div`
    padding: 10px 0;
  `;
  interface BodyDetailsRowProps {
    itemCount: number;
  }
  export const BodyDetailsRow = styled.div<BodyDetailsRowProps>(
    ({ itemCount }) => css`
      display: flex;
      align-items: center;
      gap: 30px;
    `
  );

  type HealthColorType = {
    health?: keyof typeof healthColors;
  };
  export const BodyDetailsInstance = styled.div<HealthColorType>(({ health, theme }) => {
    const svgColor = health !== undefined ? healthColors[health].dark : theme.seaBlue;
    return css`
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1;
      min-width: 0; /* Needed for truncated text to work */

      ${health !== undefined
        ? css`
            color: ${svgColor};
          `
        : ''}

      ${svgBasics(21, svgColor)}
      svg {
        margin-right: 6px;
      }
    `;
  });
  export const BodyDetailsInstanceTitle = styled.span`
    font-weight: 600;
    margin-right: 5px;
  `;
  export const ExtraDetailsHolder = styled.div`
    min-height: 100px;
  `;
  export const TooltipErrorText = styled.div(
    ({ theme }) =>
      css`
        color: ${theme.peachOrange};
      `
  );

  export const FurtherDetails = styled.div<BodyProps>(
    ({ collapsed, theme }) => css`
      ${collapsed
        ? css`
            border-top: 1px solid transparent;
            margin-bottom: 0px;
          `
        : css`
            border-top: 1px solid ${theme.aprilGrey};
            margin-bottom: 10px;
          `}

      transition:
      margin .3s,
      border-color .2s;
    `
  );
}
export namespace ClustersLandingEmptyStyles {
  export const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    grid-template-areas:
      'welcome welcome'
      'workspaces clusters';
  `;

  export const AreaBox = styled(CardStyles.Card)``;
  export const WorkspacesBox = styled(AreaBox)`
    grid-area: workspaces;
  `;
  export const ClustersBox = styled(AreaBox)`
    grid-area: clusters;
  `;
  export const WelcomeBox = styled(AreaBox)(
    ({ theme }) => css`
      grid-area: welcome;
      padding: 25px 25px 35px;
      color: ${theme.neptuneBlue};
    `
  );
  export const WelcomeTitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 25px;
  `;
  export const WelcomeItems = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
  `;
  export const WelcomeItemBox = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 20px;
  `;
  export const WelcomeItemCheckbox = styled.div(
    ({ theme }) => css`
      margin-right: 11px;

      ${svgBasics(23, theme.oceanBlue)}
    `
  );

  export const RegisteringPromptContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 48px;
  `;
  export const WorkspaceContent = styled(RegisteringPromptContent)`
    height: 100%;
  `;
  export const ClusterContent = styled(RegisteringPromptContent)`
    height: 100%;
  `;
  export const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const RegisteringIconHolder = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 133px;
      height: 133px;
      border-radius: 133px;
      border: 1px solid ${theme.splashBlue};
      margin-bottom: 30px;

      ${svgBasics(80, theme.splashBlue)}
    `
  );
  export const RegisteringTitle = styled.div`
    font-size: 28px;
    text-align: center;
  `;
  export const RegisteringBody = styled.div`
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding: 25px 0;
  `;
  export const RegisterButtons = styled.div`
    display: flex;
    > * {
      margin-right: 20px;
    }
    > :last-child {
      margin-right: 0;
    }
  `;
}
export namespace AddClusterModalBodyStyles {
  export const Container = styled.div`
    padding: 30px;
  `;
  export const Title = styled.div(() => {
    return css`
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 20px;

      svg {
        height: 28px;
        margin-left: 15px;
      }
    `;
  });
  export const Hint = styled.div(
    ({ theme }) => css`
      background: ${theme.dropBlue};
      border-radius: 8px;
      font-size: 14px;
      line-height: 44px;
      color: ${theme.septemberGrey};
      margin-bottom: 20px;
      padding: 0 11px;
    `
  );

  export const InstructionsArea = styled.div(
    ({ theme }) => css`
      background: ${theme.dropBlue};
      border: 1px solid ${theme.seaBlue};
      border-radius: 8px;
      color: ${theme.seaBlue};
      padding: 15px 11px;
    `
  );

  export const ClusterIconHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${svgBasics(24)}
  `;

  export const InstructionNumber = styled.div(
    ({ theme }) => css`
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      line-height: 23px;
      text-align: center;
      padding-top: 2px;
      width: 25px;
      height: 25px;
      font-weight: 500;
      font-size: 18px;
      border-radius: 100%;
      color: ${theme.seaBlue};
      background: ${theme.splashBlue};
      margin-right: 8px;
    `
  );

  export const InstructionHint = styled.div(
    ({ theme }) => css`
      position: relative;
      padding-left: 40px;
      font-size: 16px;
      line-height: 22px;
      color: ${theme.neptuneBlue};
      margin-bottom: 35px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `
  );
}
