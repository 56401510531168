import { isUIOnlyFeatureFlagOn, UIFEATUREFLAG_SEARCHHIGHLIGHTING } from 'Components/Features/Flags/UIOnlyFlags';
import {
  CiliumMetrics,
  EdgeMetrics,
  GetGraphResponse,
  HttpMetrics,
  NodeMetrics,
  NodeType,
  TcpMetrics,
  WorkloadNode
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { LayoutTypesEnum } from '../Context/GraphUXSettingsContext';
import { getEdgeIdFromData, splitNodeIdIntoData } from './graph-selection-utils';
import {
  ContainerNodeType,
  EdgeType,
  EdgeTypeAnimationProps,
  GraphContainerType,
  GraphMetricsType,
  GraphStatus,
  LabelIcon,
  NodeTypeCiliumTooltipProps,
  NodeTypeHttpTooltipProps,
  NodeTypeTcpTooltipProps,
  RpsBucketType,
  UINodeType
} from './graph-types';

// The GLOOs here are just to be extra sure we will never run into a stupid
//  and very hard to find bug where someone just happened to include "emptyYYY" in
//  their naming schemes
export function EmptyPrefixedString(orig?: string): string {
  return 'GLOOempty' + (orig || '');
}
export function GetContainerBoxName(id: string, containerType: GraphContainerType, suffix?: string) {
  switch (containerType) {
    case GraphContainerType.VPC:
      return (id || EmptyPrefixedString('VPC')) + '.VPC||' + (suffix || '');
    case GraphContainerType.Cluster:
      return (id || EmptyPrefixedString('Cluster')) + '.CL||' + (suffix || '');
    case GraphContainerType.Namespace:
      return (id || EmptyPrefixedString('Namespace')) + '.NS||' + (suffix || '');
    case GraphContainerType.Workspace:
      return (id || EmptyPrefixedString('Workspace')) + '.WS||' + (suffix || '');
    case GraphContainerType.Subnet:
      return (id || EmptyPrefixedString('Subnet')) + '.SN||' + (suffix || '');
    case GraphContainerType.Instance:
      return (id || EmptyPrefixedString('Instance')) + '.IN||' + (suffix || '');
  }
}

export enum EdgeLabelType {
  latency = 0,
  errorRate,
  mtls,
  none
}

function isNodeExternal(nodeType: NodeType | undefined): boolean {
  return (
    nodeType === NodeType.EXTERNAL_WORKLOAD ||
    nodeType === NodeType.LAMBDA_WORKLOAD ||
    nodeType === NodeType.VM_WORKLOAD
  );
}

export function isHttpMetricsDefined(metrics?: HttpMetrics): metrics is HttpMetrics {
  // Http metrics aren't actually undefined when they don't exist, and instead have empty data.
  // as seen in the link below, "undefined" http metrics (those with no timestamps) are given dummy data
  // that we can then check to determine if metrics are "defined" or not
  // https://github.com/solo-io/gloo-mesh-enterprise/pull/2940/commits/ac2d8ce61c9cbf6f260b8fab09680c7bf341ce68#diff-2ed3b30247414af4be970cd176ee0774713d1a30e005e2db843e0a1a8b31dd35R324

  // Nodes with no requests now also count as undefined as per https://github.com/solo-io/gloo-mesh-enterprise/issues/5170
  if (reduceListAdding(metrics?.requestCount) === 0) {
    return false;
  }

  // If timestamps list has no items or contains only a single item of 0 seconds, it is not defined
  const matchesDummydata = (metrics?.timestamps?.length ?? 1) === 1 && (metrics?.timestamps?.[0]?.seconds ?? 0) === 0;
  return !matchesDummydata;
}
export function isTcpMetricsDefined(metrics?: TcpMetrics): metrics is TcpMetrics {
  // Empty results count as undefined
  if (reduceListAdding(metrics?.bytesSent) === 0 && reduceListAdding(metrics?.bytesReceived) === 0) {
    return false;
  }
  // If there is no metrics or timestamps list is empty then that means the metrics are undefined
  if (!metrics?.timestamps.length) {
    return false;
  }
  return true;
}
export function isCiliumMetricsDefined(metrics?: CiliumMetrics): metrics is CiliumMetrics {
  // Empty results count as undefined
  if (reduceListAdding(metrics?.forwardedSent) === 0 && reduceListAdding(metrics?.forwardedReceived) === 0) {
    return false;
  }
  // If there is no metrics or timestamps list is empty then that means the metrics are undefined
  if (!metrics?.timestamps.length) {
    return false;
  }
  return true;
}

export function getHttpItemStatus(httpMetrics: HttpMetrics | undefined): GraphStatus {
  if (!httpMetrics) {
    return GraphStatus.Idle;
  }

  // If the request count during the most recent "step" is 0, then it's considered idle
  if ((httpMetrics?.requestCount.at(-1) ?? 0) === 0) {
    return GraphStatus.Idle;
  }

  const healthVal = reduceListAdding(httpMetrics?.successCount) / reduceListAdding(httpMetrics?.requestCount);
  if (healthVal > 0.99) {
    return GraphStatus.Healthy;
  } else if (healthVal >= 0.9) {
    return GraphStatus.Warning;
  } else {
    return GraphStatus.Error;
  }
}

function getTcpItemStatus(tcpMetrics: TcpMetrics | undefined): GraphStatus {
  if (!tcpMetrics) {
    return GraphStatus.Idle;
  }

  // If the most recent populated data is all 0, then we consider it idle
  const bytesSent = tcpMetrics?.bytesSent.at(-1) ?? 0;
  const bytesReceived = tcpMetrics?.bytesReceived.at(-1) ?? 0;
  if (bytesSent + bytesReceived === 0) {
    return GraphStatus.Idle;
  }

  return GraphStatus.Healthy;
}

function getCiliumItemStatus(ciliumMetrics: CiliumMetrics | undefined): GraphStatus {
  if (!ciliumMetrics) {
    return GraphStatus.Idle;
  }

  if (ciliumMetrics?.policyDrops.some(num => num > 0)) {
    return GraphStatus.Error;
  }

  // If the most recent populated data is all 0, then we consider it idle
  const sentRecent = ciliumMetrics?.forwardedSent.at(-1) ?? 0;
  const receivedRecent = ciliumMetrics?.forwardedReceived.at(-1) ?? 0;
  if (sentRecent + receivedRecent) {
    return GraphStatus.Idle;
  }

  return GraphStatus.Healthy;
}

export function getNodeStatus(edge: NodeMetrics): GraphStatus {
  const metricsType = getEdgeMetricsType(edge);
  switch (metricsType) {
    case GraphMetricsType.Http:
    default:
      return getHttpItemStatus(
        isHttpMetricsDefined(edge.outgoingMetrics) ? edge.outgoingMetrics : edge.incomingMetrics
      );
    case GraphMetricsType.Cilium:
      return getCiliumItemStatus(edge.ciliumMetrics);
    case GraphMetricsType.Tcp:
      return getTcpItemStatus(edge.tcpMetrics);
  }
}

export function getEdgeStatus(edge: EdgeMetrics): GraphStatus {
  const metricsType = getEdgeMetricsType(edge);
  switch (metricsType) {
    case GraphMetricsType.Http:
    default:
      return getHttpItemStatus(edge.httpMetrics);
    case GraphMetricsType.Cilium:
      return getCiliumItemStatus(edge.ciliumMetrics);
    case GraphMetricsType.Tcp:
      return getTcpItemStatus(edge.tcpMetrics);
  }
}

/** Returns boolean if security is supported, or undefined if metrics type doesn't use security */
function doesEdgeSecurityExist(edge: EdgeMetrics): boolean | undefined {
  const metricsType = getEdgeMetricsType(edge);
  switch (metricsType) {
    // In most cases this is undefined, because we are unable to get the information
    // Sometimes the istio_tcp_sent_bytes_total metric will have the connection_security_policy security label,
    //  which is what is checked below
    case GraphMetricsType.Http:
    case GraphMetricsType.Tcp: {
      const securityPolicy = edge.httpMetrics?.security?.securityPolicy ?? edge.tcpMetrics?.security?.securityPolicy;

      return securityPolicy === 'mutual_tls' //the only true case
        ? true
        : securityPolicy === 'none' // the only false case
        ? false
        : undefined; // only thing else should be 'unknown', but treat all others as undefined;
    }
    case GraphMetricsType.Cilium:
    default:
      return undefined;
  }
}

export function reduceListAdding(list?: number[]) {
  return list ? list.reduce((acc, currItem) => acc + currItem, 0) : 0;
}

export function reduceListAverage(list?: number[]) {
  return list ? list.reduce((acc, currItem) => acc + currItem, 0) / list.length : 0;
}

function getLastNumber(list: number[] | undefined): number {
  if (!list || !list.length) return 0;
  return list[list.length - 1];
}

export function getMetricsType(
  httpMetricsList: (HttpMetrics | undefined)[],
  tcpMetrics: TcpMetrics | undefined,
  ciliumMetrics: CiliumMetrics | undefined
) {
  //  The rule is: HTTP > TCP > Cilium

  //  Http comes first. If it exists, this is an Http item and its
  //     health should be based on the Http data
  if (httpMetricsList.some(httpMetric => isHttpMetricsDefined(httpMetric))) {
    return GraphMetricsType.Http;
  }

  if (isTcpMetricsDefined(tcpMetrics)) {
    return GraphMetricsType.Tcp;
  } else if (isCiliumMetricsDefined(ciliumMetrics)) {
    return GraphMetricsType.Cilium;
  }

  // Finally fallback to Http, but also throw out a warning...
  // eslint-disable-next-line no-console
  console.warn('Graph item found without proper typing:');
  // eslint-disable-next-line no-console
  console.warn(httpMetricsList, tcpMetrics, ciliumMetrics);
  return GraphMetricsType.Http;
}

export function getNodeMetricsType(node: NodeMetrics) {
  return getMetricsType([node.incomingMetrics, node.outgoingMetrics], node.tcpMetrics, node.ciliumMetrics);
}

export function getEdgeMetricsType(edge: EdgeMetrics) {
  return getMetricsType([edge.httpMetrics], edge.tcpMetrics, edge.ciliumMetrics);
}

/** Convert nodeMetrics to a map for faster id lookup */
export function nodeMetricsToMap(nodeMetrics: NodeMetrics[]) {
  return nodeMetrics.reduce<Record<string, NodeMetrics>>((map, metrics) => {
    map[metrics.workload?.id ?? ''] = metrics;
    return map;
  }, {});
}

const healthRanking: Record<GraphStatus, number> = {
  // While idle is "worse" than healthy, when comparing nodes we want healthy ones to take priority
  [GraphStatus.Idle]: 0,
  [GraphStatus.Healthy]: 1,
  [GraphStatus.Warning]: 2,
  [GraphStatus.Error]: 3
};
/** Used when trying to compare the severity of multiple statuses */
export function findWorstHealth(healthList: GraphStatus[]): GraphStatus {
  let worstHealth = GraphStatus.Idle;
  for (const health of healthList) {
    // If worst health found return early
    if (health === GraphStatus.Error) {
      return GraphStatus.Error;
    } else {
      worstHealth = healthRanking[health] > healthRanking[worstHealth] ? health : worstHealth;
    }
  }
  return worstHealth;
}

/*** Edge data building utility functions - START ***/
export const calculateRPS = (edgeMetrics?: HttpMetrics) => {
  if (!edgeMetrics || !edgeMetrics.timestamps.length) {
    return 0;
  }
  return getLastNumber(edgeMetrics?.requestCount);
};

function getEdgeIdFromMetrics(edge: EdgeMetrics, reverse = false): string {
  return !reverse
    ? getEdgeIdFromData(edge.sourceWorkload?.id, edge.targetWorkload?.id)
    : getEdgeIdFromData(edge.targetWorkload?.id, edge.sourceWorkload?.id);
}

/*** Edge data building utility functions - END ***/
function getUsedEdgesData(
  edgeList: EdgeMetrics[],
  searchedSpace: {
    namespaces: string[];
    clusters: string[];
    workspaces: string[];
    instances: string[];
    subnets: string[];
    vpcs: string[];
  },
  animationsOn: boolean,
  showIdles: boolean,
  kubernetesServicesDisplayed: boolean,
  externalServicesDisplayed: boolean,
  gatewaysDisplayed: boolean,
  findName: string,
  hideName: string
): EdgeType[] {
  // list of all edge RPS, sorted least to greatest
  const edgeRpsList = edgeList
    .map(edge => calculateRPS(edge.httpMetrics))
    .filter(rps => !!rps)
    .sort((a, b) => a - b);

  // The buckets we split edge rps into for graph animation
  // current split: 20/30/30/20 for edge rps as compared to each other
  const rpsBuckets = [
    edgeRpsList[Math.floor(edgeRpsList.length * 0.2)], // 0-0.2
    edgeRpsList[Math.floor(edgeRpsList.length * (0.2 + 0.3))], // 0.2-0.5
    edgeRpsList[Math.floor(edgeRpsList.length * (0.2 + 0.3 + 0.3))], // 0.5-0.8
    edgeRpsList[edgeRpsList.length - 1] // 0.8-1
  ];

  // Key stored in the format of `sourceid-targetid` of the first found node.
  // When an edge is first found it stores false - if an edge going in opposite
  // direction is found it will be stored as the value of the first one (and the
  // key of the second will remain undefined)
  const twoWayEdgeData: Record<string, EdgeMetrics | false> = {};

  return edgeList
    .filter(pEdge => {
      const workLoadExists = !!pEdge.sourceWorkload && !!pEdge.targetWorkload;
      if (!workLoadExists) return false;

      // Filter out any edges connecting the same nodes in opposite direction

      // check if the partner edge already exists
      if (twoWayEdgeData[getEdgeIdFromMetrics(pEdge, true)] !== undefined) {
        // if it does set this edge's data into the map under the id of it's partner
        twoWayEdgeData[getEdgeIdFromMetrics(pEdge, true)] = pEdge;
        // now that we stored it where it's partner can find it, filter it out of edges list
        return false;
      } else {
        // else first time any edge for these two nodes was seen
        twoWayEdgeData[getEdgeIdFromMetrics(pEdge)] ??= false;
      }

      return true;
    })
    .map(pEdge => {
      // If data exists, then this edge goes in two directions
      let parallelEdge = twoWayEdgeData[getEdgeIdFromMetrics(pEdge)];
      if (parallelEdge) {
        // Currently if one edge has L7 metrics but the other doesn't, we only want this to be a one-way L7 edge
        if (isHttpMetricsDefined(pEdge.httpMetrics) && !isHttpMetricsDefined(parallelEdge.httpMetrics)) {
          parallelEdge = false;
        } else if (isHttpMetricsDefined(parallelEdge.httpMetrics) && !isHttpMetricsDefined(pEdge.httpMetrics)) {
          pEdge = parallelEdge;
          parallelEdge = false;
        } else {
          // Alphabetize edges just so the order is always consistent (to help avoid flickering)
          // this is needed because edge list order isn't always consistent
          [pEdge, parallelEdge] = [parallelEdge, pEdge].sort((a, b) =>
            a.sourceWorkload!.id.localeCompare(b.sourceWorkload!.id)
          );
        }
      }

      // Avoid names like source and target as they are sometimes global keywords...
      let src = pEdge.sourceWorkload!.id;
      let tgt = pEdge.targetWorkload!.id;

      let health = getEdgeStatus(pEdge);
      let classes: string[] = [];
      if (parallelEdge) {
        classes.push('twoWayEdge');

        // for health, show whichever health is worst
        health = findWorstHealth([health, getEdgeStatus(parallelEdge)]);
      }

      let isMtlsEnabled = doesEdgeSecurityExist(pEdge);
      if (parallelEdge) {
        isMtlsEnabled =
          isMtlsEnabled !== undefined
            ? // Only show mTLS as enabled on the combined edge if neither is insecure
              isMtlsEnabled && doesEdgeSecurityExist(parallelEdge) !== false
              ? true
              : false // If either edge is defined as insecure, show the insecurity
            : // If security isn't supported on the original edge, default to security for this one
              doesEdgeSecurityExist(parallelEdge);
      }
      if (!!isMtlsEnabled) {
        classes.push('mtlsEnabledEdge');
      } else if (isMtlsEnabled === false) {
        classes.push('mtlsDisabledEdge');
      }

      const metricsType = getEdgeMetricsType(pEdge);

      let httpEdgeAnimation: EdgeTypeAnimationProps | undefined;
      if (metricsType === GraphMetricsType.Http) {
        let metrics = pEdge.httpMetrics;

        if (parallelEdge && parallelEdge.httpMetrics) {
          const revMetrics = parallelEdge.httpMetrics;
          // for animation and tooltip, use whichever metrics has the highest RPS
          if (calculateRPS(revMetrics) > calculateRPS(metrics)) {
            metrics = revMetrics;

            // also switch which edge is considered the primary (this not only
            // effects sidebar opening but also change the animation direction)
            [src, tgt] = [tgt, src];
          }
        }

        // Used by marching ants animation to indicate their speed
        const latency = metrics?.requestLatencies?.p99 ? reduceListAverage(metrics?.requestLatencies?.p99) : undefined;

        // What bucket this edge falls into for rps - used for graph animation
        const rps = calculateRPS(metrics);
        const rpsBucket = rps === 0 ? 0 : rpsBuckets.findIndex(b => rps <= b) + 1;

        if (animationsOn) {
          classes.push('animated');
        }

        httpEdgeAnimation = {
          latency,
          rpsBucket: rpsBucket as RpsBucketType
        };
      }

      const requestCount = reduceListAverage(pEdge.httpMetrics?.requestCount);
      const successCount = reduceListAverage(pEdge.httpMetrics?.successCount);

      const srcData = splitNodeIdIntoData(src);
      const tgtData = splitNodeIdIntoData(tgt);
      const srcDisplayName = pEdge.sourceWorkload!.displayName;
      const tgtDisplayName = pEdge.targetWorkload!.displayName;
      const nameHidden =
        !srcDisplayName.toLocaleLowerCase().includes(findName.toLocaleLowerCase()) ||
        !tgtDisplayName.toLocaleLowerCase().includes(findName.toLocaleLowerCase()) ||
        (!!hideName && (srcDisplayName.includes(hideName) || tgtDisplayName.includes(hideName)))
          ? 'hide'
          : 'show';
      const sourceType = pEdge.sourceWorkload?.nodeType,
        targetType = pEdge.targetWorkload?.nodeType;

      // TODO :: Determine whether this is truly meaningful AND make sure the logic matches to
      //         the Node lists' logic on this topic
      const sourceIsExternal = isNodeExternal(sourceType),
        targetIsExternal = isNodeExternal(targetType);

      const typeHidden =
        (!kubernetesServicesDisplayed &&
          (sourceType === NodeType.KUBERNETES_WORKLOAD || targetType === NodeType.KUBERNETES_WORKLOAD)) ||
        (!externalServicesDisplayed && (sourceIsExternal || targetIsExternal)) ||
        (!gatewaysDisplayed && (sourceType === NodeType.GATEWAY || targetType === NodeType.GATEWAY))
          ? 'hide'
          : 'show';

      const idleHidden = health === GraphStatus.Idle && !showIdles;

      let internalToSearch = true;
      if (isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING)) {
        // If the flag to search this is on,
        //  then an edge is "internal" only if both nodes are internal to the search
        //  but nodeA and nodeB may be internal for different search term reasons.
        //  (eg, searchBy:{namespaces: ['NS1','NS2']}, nodeA:{...,namespace: NS1}, nodeB:{...NS2} )
        //   So, we need to search for any matching, but that's fairly heavy breaking for
        //   all the times it is internal, so that's what we're catching below
        internalToSearch = false;
        let srcInternal = false;
        let tgtInternal = false;

        internalToSearch = searchedSpace.clusters.some(searchedCluster => {
          if (!srcInternal && searchedCluster === srcData.clusterName) {
            srcInternal = true;
          }
          if (!tgtInternal && searchedCluster === tgtData.clusterName) {
            tgtInternal = true;
          }

          return srcInternal && tgtInternal;
        });
        if (!internalToSearch) {
          internalToSearch = searchedSpace.namespaces.some(searchedNamespace => {
            if (!srcInternal && searchedNamespace === srcData.namespace) {
              srcInternal = true;
            }
            if (!tgtInternal && searchedNamespace === tgtData.namespace) {
              tgtInternal = true;
            }

            return srcInternal && tgtInternal;
          });
        }
        if (!internalToSearch) {
          internalToSearch = searchedSpace.workspaces.some(searchedWorkspace => {
            if (!srcInternal && searchedWorkspace === pEdge.sourceWorkload?.workspace) {
              srcInternal = true;
            }
            if (!tgtInternal && searchedWorkspace === pEdge.targetWorkload?.workspace) {
              tgtInternal = true;
            }

            return srcInternal && tgtInternal;
          });
        }
      }

      return {
        data: {
          metricsType,
          source: src,
          target: tgt,
          id: getEdgeIdFromData(src, tgt),
          name: getEdgeIdFromData(src, tgt),
          // kind: edge.kind,
          timestamps: isHttpMetricsDefined(pEdge.httpMetrics) ? pEdge.httpMetrics.timestamps[0] : undefined,
          label: '',
          requestCount,
          successCount,
          health,
          httpEdgeAnimation,
          idleHidden: idleHidden ? 'hide' : 'show',
          nameHidden,
          typeHidden,
          externalFromSearch: !internalToSearch
        },
        label: getEdgeIdFromData(src, tgt),
        classes: classes.join(' ')
      };
    });
}

export enum BoxTypeDisplay {
  SubnetWithInstances,
  ClusterWithInstances,
  ClusterWithNamespaces,
  Workspaces
}

function getUsedUINodes(
  serverNodeList: NodeMetrics[],
  searchedSpace: {
    namespaces: string[];
    clusters: string[];
    workspaces: string[];
    instances: string[];
    subnets: string[];
    vpcs: string[];
  },
  nonPresetLayoutUsed: boolean,
  boxTypesShown: BoxTypeDisplay,
  showIdles: boolean,
  kubernetesServicesDisplayed: boolean,
  externalServicesDisplayed: boolean,
  gatewaysDisplayed: boolean,
  findName: string,
  hideName: string
): UINodeType[] {
  return serverNodeList
    .filter(
      (serverNode): serverNode is Omit<NodeMetrics, 'workload'> & { workload: WorkloadNode } => !!serverNode.workload
    )
    .map(serverNode => {
      const name = serverNode.workload.displayName ?? 'Undefined';
      const workloadInfo = serverNode.workload;
      const metricsType = getNodeMetricsType(serverNode);
      const nodeType = workloadInfo?.nodeType;
      const isExternal = isNodeExternal(nodeType); // TODO: Determine if this is always about
      //externality, or used as UI shorthand for another meaning to avoid certain cases

      let classes: string[] = [];
      let labelIcons: LabelIcon[] = [];
      let health: GraphStatus,
        httpTooltipProps: NodeTypeHttpTooltipProps | undefined,
        tcpTooltipProps: NodeTypeTcpTooltipProps | undefined,
        ciliumTooltipProps: NodeTypeCiliumTooltipProps | undefined;

      /* SETUP NodeMetric Type Info - BEGIN */
      // TCP info
      if (metricsType === GraphMetricsType.Tcp) {
        labelIcons.push(LabelIcon.Istio);

        const tcpMetrics = serverNode.tcpMetrics;

        const bytesReceived = reduceListAverage(tcpMetrics?.bytesReceived);
        const bytesSent = reduceListAverage(tcpMetrics?.bytesSent);

        health = getTcpItemStatus(tcpMetrics);
        tcpTooltipProps = {
          bytesReceived,
          bytesSent
        };
      }
      // Cilium info
      else if (metricsType === GraphMetricsType.Cilium) {
        labelIcons.push(LabelIcon.Cilium);

        const ciliumMetrics = serverNode.ciliumMetrics;

        const forwardedReceived = reduceListAverage(ciliumMetrics?.forwardedReceived);
        const forwardedSent = reduceListAverage(ciliumMetrics?.forwardedSent);
        const policyDrops = reduceListAverage(ciliumMetrics?.policyDrops);

        health = getCiliumItemStatus(ciliumMetrics);
        ciliumTooltipProps = {
          forwardedReceived,
          forwardedSent,
          policyDrops
        };
      }
      // HTTP info
      else {
        labelIcons.push(LabelIcon.Istio);

        const inMetrics = serverNode.incomingMetrics;
        const outMetrics = serverNode.outgoingMetrics;

        let latencyIN = !!inMetrics?.requestLatencies ? reduceListAverage(inMetrics.requestLatencies.p90) : -1;
        let latencyOUT = !!outMetrics?.requestLatencies ? reduceListAverage(outMetrics.requestLatencies.p90) : -1;

        const requestCountIN = reduceListAverage(inMetrics?.requestCount);
        const requestCountOUT = reduceListAverage(outMetrics?.requestCount);
        const successCountIN = reduceListAverage(inMetrics?.successCount);
        const successCountOUT = reduceListAverage(outMetrics?.successCount);

        health = getHttpItemStatus(serverNode.outgoingMetrics ?? serverNode.incomingMetrics);
        httpTooltipProps = {
          errorsPercentIN: requestCountIN === 0 ? -1 : (requestCountIN - successCountIN) / requestCountIN,
          rpsIN: calculateRPS(serverNode.incomingMetrics),
          latencyIN,
          errorsPercentOUT: requestCountOUT === 0 ? -1 : (requestCountOUT - successCountOUT) / requestCountOUT,
          rpsOUT: calculateRPS(serverNode.outgoingMetrics),
          latencyOUT,
          // Requires an API update to actually set values
          policyIcons: [], // [GraphPolicyIcon.Traffic, GraphPolicyIcon.Access],
          name
        };
      }
      /* SETUP NodeMetric Type Info - END */

      /* UI Node is HIDDEN checks -  BEGIN */
      const typeHidden =
        (!kubernetesServicesDisplayed && workloadInfo?.nodeType === NodeType.KUBERNETES_WORKLOAD) ||
        (!externalServicesDisplayed && isExternal) ||
        (!gatewaysDisplayed && workloadInfo?.nodeType === NodeType.GATEWAY)
          ? 'hide'
          : 'show';
      const idleHidden = health === GraphStatus.Idle && !showIdles ? 'hide' : 'show';
      const nameHidden =
        !name.toLocaleLowerCase().includes(findName.toLocaleLowerCase()) ||
        (!!hideName && name.toLocaleLowerCase().includes(hideName.toLocaleLowerCase()))
          ? 'hide'
          : 'show';
      /* UI Node is HIDDEN checks -  END */

      // This seems heavy at a glance, but these will usually be empty or 2-3 item lists
      const internalToSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        searchedSpace.clusters.some(searchedCluster => searchedCluster === workloadInfo?.cluster) ||
        searchedSpace.namespaces.some(searchedNamespace => searchedNamespace === workloadInfo?.namespace) ||
        searchedSpace.workspaces.some(searchedWorkspace => searchedWorkspace === workloadInfo?.workspace) ||
        searchedSpace.instances.some(searchedInstance => searchedInstance === workloadInfo?.instance) ||
        searchedSpace.subnets.some(searchedSubnet => searchedSubnet === workloadInfo?.subnet) ||
        searchedSpace.vpcs.some(searchedVPC => searchedVPC === workloadInfo?.vpc);

      return {
        data: {
          nodeType,
          metricsType,
          parent: undefined,
          id: serverNode.workload.id,
          name,
          namespace: serverNode.workload.namespace,
          cluster: serverNode.workload.cluster,
          workspace: serverNode.workload.workspace,
          instance: serverNode.workload.instance,
          subnet: serverNode.workload.subnet,
          vpc: serverNode.workload.vpc,
          health,
          tooltipProps: {
            headers: {
              vpcCluster: nonPresetLayoutUsed || (boxTypesShown === BoxTypeDisplay.Workspaces && !isExternal),
              workspace: nonPresetLayoutUsed || (boxTypesShown !== BoxTypeDisplay.Workspaces && !isExternal)
            }
          },
          httpTooltipProps,
          tcpTooltipProps,
          ciliumTooltipProps,
          label: name,
          labelIcons,
          idleHidden,
          nameHidden,
          typeHidden,
          externalFromSearch: !internalToSearch
        },
        locked: false,
        grabbable: true,
        classes: classes.join(' ')
      };
    });
}

function getUsedNodesData(
  serverNodeList: NodeMetrics[],
  searchedSpace: {
    namespaces: string[];
    clusters: string[];
    workspaces: string[];
    instances: string[];
    subnets: string[];
    vpcs: string[];
  },
  nonPresetLayoutUsed: boolean,
  boxTypesShown: BoxTypeDisplay,
  showIdles: boolean,
  kubernetesServicesDisplayed: boolean,
  externalServicesDisplayed: boolean,
  gatewaysDisplayed: boolean,
  findName: string,
  hideName: string
): {
  UINodes: UINodeType[];
  namespaceNodes: ContainerNodeType[];
  clusterNodes: ContainerNodeType[];
  workspaceNodes: ContainerNodeType[];
  instanceNodes: ContainerNodeType[];
  subnetNodes: ContainerNodeType[];
  VPCNodes: ContainerNodeType[];
} {
  let UINodes = getUsedUINodes(
    serverNodeList,
    searchedSpace,
    nonPresetLayoutUsed,
    boxTypesShown,
    showIdles,
    kubernetesServicesDisplayed,
    externalServicesDisplayed,
    gatewaysDisplayed,
    findName,
    hideName
  );

  // IF using nonPresentLayouts, then we want to just return back the list of
  //   UI nodes and empty lists for the rest
  if (nonPresetLayoutUsed) {
    return {
      UINodes,
      namespaceNodes: [],
      clusterNodes: [],
      workspaceNodes: [],
      instanceNodes: [],
      subnetNodes: [],
      VPCNodes: []
    };
  }

  // OTHERWISE, build container node lists and connect UINodes to their parents
  //  Need to build ALL nodes, to avoid breaking cyto on change
  //   SO what changes is the parentage
  let namespaceNodes: ContainerNodeType[] = [];
  let clusterNodes: ContainerNodeType[] = [];
  let workspaceNodes: ContainerNodeType[] = [];
  let instanceNodes: ContainerNodeType[] = [];
  let subnetNodes: ContainerNodeType[] = [];
  let VPCNodes: ContainerNodeType[] = [];

  const boundariesWithChildren = new Set();

  UINodes.forEach(uiNode => {
    const uiNodeHidden =
      uiNode.data.idleHidden === 'hide' || uiNode.data.typeHidden === 'hide' || uiNode.data.nameHidden === 'hide';

    const vpcId = GetContainerBoxName(uiNode.data.vpc, GraphContainerType.VPC);
    const vpcHidden =
      uiNodeHidden || boxTypesShown === BoxTypeDisplay.Workspaces || vpcId.includes(EmptyPrefixedString('VPC'));
    const clusterNodeId = GetContainerBoxName(uiNode.data.cluster, GraphContainerType.Cluster, vpcId);
    const clusterHidden =
      uiNodeHidden ||
      (boxTypesShown !== BoxTypeDisplay.ClusterWithNamespaces &&
        boxTypesShown !== BoxTypeDisplay.ClusterWithInstances) ||
      clusterNodeId.includes(EmptyPrefixedString('Cluster'));
    const namespaceNodeId = GetContainerBoxName(uiNode.data.namespace, GraphContainerType.Namespace, clusterNodeId);
    const namespaceHidden =
      uiNodeHidden ||
      boxTypesShown !== BoxTypeDisplay.ClusterWithNamespaces ||
      namespaceNodeId.includes(EmptyPrefixedString('Namespace'));
    const workspaceNodeId = GetContainerBoxName(uiNode.data.workspace, GraphContainerType.Workspace, vpcId);
    const workspaceHidden =
      uiNodeHidden ||
      boxTypesShown !== BoxTypeDisplay.Workspaces ||
      workspaceNodeId.includes(EmptyPrefixedString('Workspace'));
    const subnetNodeId = GetContainerBoxName(uiNode.data.subnet, GraphContainerType.Subnet, vpcId);
    const subnetHidden =
      uiNodeHidden ||
      boxTypesShown !== BoxTypeDisplay.SubnetWithInstances ||
      subnetNodeId.includes(EmptyPrefixedString('Subnet'));
    const instanceNodeId = GetContainerBoxName(uiNode.data.instance, GraphContainerType.Instance, subnetNodeId);
    const instanceHidden =
      uiNodeHidden ||
      (boxTypesShown !== BoxTypeDisplay.ClusterWithInstances && boxTypesShown !== BoxTypeDisplay.SubnetWithInstances) ||
      instanceNodeId.includes(EmptyPrefixedString('Instance'));

    // External nodes are added outside of namespaces
    //  This prevents us from drawing false namespace boxes ('<unknown>.<unknown.')
    //  and, handely, prevents drawing the false cluster or workspaces either.
    // IF THIS IS THE CASE THEN THE NODE ITSELF SHOULD NOT HAVE A PARENT, regardless of other info
    // TODO: Does external really still mean the same thing?
    const isExternal = isNodeExternal(uiNode.data.nodeType);
    if (!isExternal) {
      // we want all nodes to "register" their parent boundaries here
      // except for VMs, those only register the VPC and Workspace as we want them to display outside other boundaries
      let parentBoundaries = [vpcId, workspaceNodeId];
      if (uiNode.data.nodeType !== NodeType.VM_WORKLOAD) {
        parentBoundaries.push(clusterNodeId, namespaceNodeId, subnetNodeId, instanceNodeId);
      }
      parentBoundaries.forEach(boundariesWithChildren.add, boundariesWithChildren);

      if (boxTypesShown === BoxTypeDisplay.ClusterWithNamespaces) {
        uiNode.data.parent = namespaceHidden ? undefined : namespaceNodeId;
      } else if (
        boxTypesShown === BoxTypeDisplay.ClusterWithInstances ||
        boxTypesShown === BoxTypeDisplay.SubnetWithInstances
      ) {
        uiNode.data.parent = instanceHidden ? undefined : instanceNodeId;
      } else if (boxTypesShown === BoxTypeDisplay.Workspaces) {
        uiNode.data.parent = workspaceHidden ? undefined : workspaceNodeId;
      }

      // VMs should not belong to a cluster/namespace, just a VPC
      if (uiNode.data.nodeType === NodeType.VM_WORKLOAD) {
        uiNode.data.parent = vpcHidden ? undefined : vpcId;
      }
    }

    // Check for namespace, add or alter
    let namespaceNode = namespaceNodes.find(nsNode => nsNode.data.id === namespaceNodeId);

    if (namespaceNode) {
      // Add any new label icons
      !!uiNode.data.labelIcons &&
        uiNode.data.labelIcons.forEach(labelIcon => {
          if (
            labelIcon === LabelIcon.AmbientMesh &&
            !!namespaceNode &&
            !namespaceNode.data.labelIcons.includes(labelIcon)
          ) {
            namespaceNode.data.labelIcons.push(labelIcon);
          }
        });

      // Check any other information, such as parent, as
      //  these may have been misrepresented by past nodes, such as ghosts
      namespaceNode.data.parent =
        namespaceNode.data.parent ||
        (boxTypesShown === BoxTypeDisplay.ClusterWithNamespaces && !clusterHidden ? clusterNodeId : undefined);

      // Flip hidden status if needed
      if (namespaceNode.data.hidden) {
        namespaceNode.data.hidden = namespaceHidden;
      }
    } else {
      const internalToSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        searchedSpace.namespaces.some(searchedNamespace => searchedNamespace === namespaceNodeId) ||
        searchedSpace.clusters.some(searchedCluster => searchedCluster === clusterNodeId);

      namespaceNodes.push({
        data: {
          containerType: GraphContainerType.Namespace,
          parent: boxTypesShown === BoxTypeDisplay.ClusterWithNamespaces && !clusterHidden ? clusterNodeId : undefined,
          id: namespaceNodeId,
          label: uiNode.data.namespace,
          labelIcons: uiNode.data.labelIcons?.filter(labelIcon => labelIcon === LabelIcon.AmbientMesh) ?? [],
          hidden: namespaceHidden,
          externalFromSearch: !internalToSearch
        },
        locked: false,
        grabbable: true
      });
    }

    // Check for cluster, add or alter
    let clusterNode = clusterNodes.find(clNode => clNode.data.id === clusterNodeId);
    if (clusterNode) {
      // Add any new label icons
      !!uiNode.data.labelIcons &&
        uiNode.data.labelIcons.forEach(labelIcon => {
          if (
            (labelIcon === LabelIcon.Istio || labelIcon === LabelIcon.Cilium) &&
            !!clusterNode &&
            !clusterNode.data.labelIcons.includes(labelIcon)
          ) {
            clusterNode.data.labelIcons.push(labelIcon);
          }
        });

      // Check any other information, such as parent, as
      //  these may have been misrepresented by past nodes, such as ghosts
      clusterNode.data.parent =
        clusterNode.data.parent ||
        ((boxTypesShown === BoxTypeDisplay.ClusterWithNamespaces ||
          boxTypesShown === BoxTypeDisplay.ClusterWithInstances) &&
        !vpcHidden
          ? vpcId
          : undefined);

      // Flip hidden status if needed
      if (clusterNode.data.hidden) {
        clusterNode.data.hidden = clusterHidden;
      }
    } else {
      const internalToSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        searchedSpace.clusters.some(searchedCluster => searchedCluster === clusterNodeId);

      clusterNodes.push({
        data: {
          containerType: GraphContainerType.Cluster,
          parent:
            (boxTypesShown === BoxTypeDisplay.ClusterWithNamespaces ||
              boxTypesShown === BoxTypeDisplay.ClusterWithInstances) &&
            !vpcHidden
              ? vpcId
              : undefined,
          id: clusterNodeId,
          label: uiNode.data.cluster,
          labelIcons:
            uiNode.data.labelIcons?.filter(
              labelIcon => labelIcon === LabelIcon.Istio || labelIcon === LabelIcon.Cilium
            ) ?? [],
          hidden: clusterHidden,
          externalFromSearch: !internalToSearch
        },
        locked: false,
        grabbable: true
      });
    }

    // Check for workspace, add or alter
    let workspaceNode = workspaceNodes.find(wsNode => wsNode.data.id === workspaceNodeId);
    if (workspaceNode) {
      // No need to add label icons, workspaces don't display them

      // No need to check parent, workspaces will never have them

      // Flip hidden status if needed
      if (workspaceNode.data.hidden) {
        workspaceNode.data.hidden = workspaceHidden;
      }
    } else {
      const internalToSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        searchedSpace.workspaces.some(searchedWorkspace => searchedWorkspace === workspaceNodeId);

      workspaceNodes.push({
        data: {
          containerType: GraphContainerType.Workspace,
          parent: undefined,
          id: workspaceNodeId,
          label: uiNode.data.workspace,
          labelIcons: [],
          hidden: workspaceHidden,
          externalFromSearch: !internalToSearch
        },
        locked: false,
        grabbable: true
      });
    }

    // Check for instance, add or alter
    let instanceNode = instanceNodes.find(instNode => instNode.data.id === instanceNodeId);
    if (instanceNode) {
      // Check any other information, such as parent, as
      //  these may have been misrepresented by past nodes, such as ghosts
      instanceNode.data.parent =
        instanceNode.data.parent ||
        (boxTypesShown === BoxTypeDisplay.ClusterWithInstances && !clusterHidden
          ? clusterNodeId
          : boxTypesShown === BoxTypeDisplay.SubnetWithInstances && !subnetHidden
          ? subnetNodeId
          : undefined);

      // Flip hidden status if needed
      if (instanceNode.data.hidden) {
        instanceNode.data.hidden = instanceHidden;
      }
    } else {
      const externalFromSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        searchedSpace.instances.some(searchedInstance => searchedInstance === instanceNodeId) ||
        (boxTypesShown === BoxTypeDisplay.ClusterWithInstances &&
          searchedSpace.clusters.some(searchedCluster => searchedCluster === clusterNodeId)) ||
        (boxTypesShown === BoxTypeDisplay.SubnetWithInstances &&
          searchedSpace.subnets.some(searchedSubnet => searchedSubnet === subnetNodeId));

      instanceNodes.push({
        data: {
          containerType: GraphContainerType.Instance,
          parent:
            boxTypesShown === BoxTypeDisplay.ClusterWithInstances && !clusterHidden
              ? clusterNodeId
              : boxTypesShown === BoxTypeDisplay.SubnetWithInstances && !subnetHidden
              ? subnetNodeId
              : undefined,
          id: instanceNodeId,
          label: uiNode.data.instance,
          labelIcons: [],
          hidden: instanceHidden,
          externalFromSearch
        },
        locked: false,
        grabbable: true
      });
    }

    // Check for subnet, add or alter
    let subnetNode = subnetNodes.find(snNode => snNode.data.id === subnetNodeId);
    if (subnetNode) {
      // Check any other information, such as parent, as
      //  these may have been misrepresented by past nodes, such as ghosts
      subnetNode.data.parent =
        subnetNode.data.parent ||
        (boxTypesShown === BoxTypeDisplay.SubnetWithInstances && !vpcHidden ? vpcId : undefined);

      // Flip hidden status if needed
      if (subnetNode.data.hidden) {
        subnetNode.data.hidden = subnetHidden;
      }
    } else {
      const externalFromSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        (boxTypesShown === BoxTypeDisplay.SubnetWithInstances &&
          searchedSpace.subnets.some(searchedSubnet => searchedSubnet === subnetNodeId));

      subnetNodes.push({
        data: {
          containerType: GraphContainerType.Subnet,
          parent: boxTypesShown === BoxTypeDisplay.SubnetWithInstances && !vpcHidden ? vpcId : undefined,
          id: subnetNodeId,
          label: uiNode.data.subnet,
          labelIcons: [],
          hidden: subnetHidden,
          externalFromSearch
        },
        locked: false,
        grabbable: true
      });
    }

    // Check for VPC, add or alter
    let VPCNode = VPCNodes.find(vpcNode => vpcNode.data.id === vpcId);
    if (VPCNode) {
      // No need to check parent or labels. VPCs will never have them.

      // Flip hidden status if needed
      if (VPCNode.data.hidden) {
        VPCNode.data.hidden = vpcHidden;
      }
    } else {
      const internalToSearch =
        !isUIOnlyFeatureFlagOn(UIFEATUREFLAG_SEARCHHIGHLIGHTING) ||
        searchedSpace.workspaces.some(searchedWorkspace => searchedWorkspace === uiNode.data.workspace) ||
        searchedSpace.vpcs.some(searchedVPC => searchedVPC === uiNode.data.vpc);

      VPCNodes.push({
        data: {
          containerType: GraphContainerType.VPC,
          parent: undefined,
          id: vpcId,
          label: uiNode.data.vpc,
          labelIcons: [],
          hidden: vpcHidden,
          externalFromSearch: !internalToSearch
        },
        locked: false,
        grabbable: true
      });
    }
  });

  // Sort the nodes and make sure we're being thorough about hiding boundaries
  UINodes = UINodes.sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));
  namespaceNodes = namespaceNodes
    .map(namespaceNode => {
      // hide empty workspaces
      namespaceNode.data.hidden = namespaceNode.data.hidden || !boundariesWithChildren.has(namespaceNode.data.id);
      return namespaceNode;
    })
    .sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));
  clusterNodes = clusterNodes
    .map(cNode => {
      // hide empty clusters
      cNode.data.hidden = cNode.data.hidden || !boundariesWithChildren.has(cNode.data.id);
      return cNode;
    })
    .sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));
  workspaceNodes = workspaceNodes
    .map(wsNode => {
      // hide empty workspaces
      wsNode.data.hidden = wsNode.data.hidden || !boundariesWithChildren.has(wsNode.data.id);
      return wsNode;
    })
    .sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));
  instanceNodes = instanceNodes.sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));
  subnetNodes = subnetNodes.sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));
  VPCNodes = VPCNodes.map(vpcNode => {
    // hide empty workspaces
    vpcNode.data.hidden = vpcNode.data.hidden || !boundariesWithChildren.has(vpcNode.data.id);
    return vpcNode;
  }).sort((A, B) => A.data.id.toLowerCase().localeCompare(B.data.id.toLowerCase()));

  return {
    UINodes,
    namespaceNodes,
    clusterNodes,
    workspaceNodes,
    instanceNodes,
    subnetNodes,
    VPCNodes
  };
}

/*** GATEWAY TO OUTSIDE ***/
export function getUsedData(
  searchedSpace: {
    namespaces: string[];
    clusters: string[];
    workspaces: string[];
    instances: string[];
    subnets: string[];
    vpcs: string[];
  },
  edgeLabelType: EdgeLabelType,
  layoutUsed: LayoutTypesEnum,
  boxTypesShown: BoxTypeDisplay,
  animationsOn: boolean,
  showIdles: boolean,
  kubernetesServicesDisplayed: boolean,
  externalServicesDisplayed: boolean,
  gatewaysDisplayed: boolean,
  findName: string,
  hideName: string,
  data?: GetGraphResponse
): {
  nodesList: {
    UINodes: UINodeType[];
    namespaceNodes: ContainerNodeType[];
    clusterNodes: ContainerNodeType[];
    workspaceNodes: ContainerNodeType[];
    instanceNodes: ContainerNodeType[];
    subnetNodes: ContainerNodeType[];
    VPCNodes: ContainerNodeType[];
  };
  edgesList: EdgeType[];
} {
  const nonPresetLayoutUsed = layoutUsed !== LayoutTypesEnum.Preset && layoutUsed !== LayoutTypesEnum.Dot;

  if (!data) {
    return {
      nodesList: {
        UINodes: [],
        namespaceNodes: [],
        clusterNodes: [],
        workspaceNodes: [],
        instanceNodes: [],
        subnetNodes: [],
        VPCNodes: []
      },
      edgesList: []
    };
  }

  // Throw to edge determiner function
  const edgesList = getUsedEdgesData(
    data.edgeMetrics,
    searchedSpace,
    animationsOn,
    showIdles,
    kubernetesServicesDisplayed,
    externalServicesDisplayed,
    gatewaysDisplayed,
    findName,
    hideName
  );

  // Throw node builder function
  const nodesList = getUsedNodesData(
    data.nodeMetrics,
    searchedSpace,
    nonPresetLayoutUsed,
    boxTypesShown,
    showIdles,
    kubernetesServicesDisplayed,
    externalServicesDisplayed,
    gatewaysDisplayed,
    findName,
    hideName
  );

  return { nodesList, edgesList };
}
