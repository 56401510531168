import { workspacesApi } from 'Api/workspaces';
import { ApiserverNoContent } from 'Components/Common/ApiserverContent';
import { CardStyles } from 'Components/Common/Card';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { SoloMultiselect } from 'Components/Common/Input/SoloMultiselect';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ImportStatus } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayoutSpacer } from 'Styles/CommonEmotions/flexLayout';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { importStatusMap } from 'utils/types';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';
import { AvailableDestinationsToImportTable } from './AvailableDestinationsToImportTable';
import { WorkspaceDestinationsTable } from './WorkspaceDestinationsTable';
const { useListDestinations } = workspacesApi;

export const VirtualDestinationsTab = ({ workspaceName }: { workspaceName: string }) => {
  di(useListDestinations);
  const workspaceDestinationsPaging = useSoloPaging();
  const availableDestinationsPaging = useSoloPaging();

  const [workspaceNameFilter, setWorkspaceNameFilter] = useState<string>('');
  const [workspaceImportStatusFilter, setWorkspaceImportStatusFilter] = useState<ImportStatus[]>([]);

  const [availableNameFilter, setAvailableNameFilter] = useState<string>('');

  const { data: destinationsDataUnbounced, error: destinationsError } = useListDestinations(
    workspaceName,
    workspaceNameFilter,
    [DestinationKind.VIRTUAL_DESTINATION],
    workspaceImportStatusFilter,
    workspaceDestinationsPaging.apiPaginationObject,
    availableNameFilter,
    [DestinationKind.VIRTUAL_DESTINATION],
    availableDestinationsPaging.apiPaginationObject
  );

  const {
    data: destinationsData,
    initialLoad,
    showRefreshIndicator
  } = useDebouncedRefreshIndicator(destinationsDataUnbounced);

  workspaceDestinationsPaging.useSetPagingTotal(destinationsData?.totalWorkspaceDestinations);
  availableDestinationsPaging.useSetPagingTotal(destinationsData?.totalAvailableDestinations);

  return (
    <CardStyles.Card data-testid='virtual-destinations-tab'>
      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Workspace Virtual Destinations</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={workspaceNameFilter}
            placeholder={'Search by name...'}
            onChangeValue={setWorkspaceNameFilter}
          />
          <SoloMultiselect
            values={workspaceImportStatusFilter}
            options={[ImportStatus.IMPORTED, ImportStatus.EXPORTED, ImportStatus.PRIVATE].map(type => ({
              value: type,
              title: importStatusMap[type].name
            }))}
            placeholder={'Filter by Import Status'}
            onChange={setWorkspaceImportStatusFilter}
            multiple
          />
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!destinationsError || initialLoad ? (
        <ApiserverNoContent error={destinationsError} loading={`Retrieving ${workspaceName} destination details...`} />
      ) : (
        <WorkspaceDestinationsTable
          workspaceName={workspaceName}
          workspaceDestinations={destinationsData?.workspaceDestinations ?? []}
          pagingData={workspaceDestinationsPaging.pagingData}
          hideType
        />
      )}

      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Available Virtual Destinations to Import</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={availableNameFilter}
            placeholder={'Search by name...'}
            onChangeValue={setAvailableNameFilter}
          />
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!destinationsError || initialLoad ? (
        <ApiserverNoContent error={destinationsError} loading={`Retrieving ${workspaceName} destination details...`} />
      ) : (
        <AvailableDestinationsToImportTable
          availableDestinations={destinationsData?.availableDestinations ?? []}
          pagingData={availableDestinationsPaging.pagingData}
          hideType
        />
      )}
    </CardStyles.Card>
  );
};
