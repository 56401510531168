import { InsightsLandingFiltersObject } from 'Components/Features/Insights/Overview/InsightsLanding';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import {
  ApiType,
  PolicyType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { InsightCodeGroup } from './dashboard/dashboard-types';
import {
  destinationKindMap,
  getPolicyTypeId,
  getResourceTypeFromId,
  GvkType,
  gvkTypeMap,
  resourceTypesMap,
  routeTypeMap
} from './types';
import { convertRefToUrl, encodeUrlSearchParamValue } from './url-builder-helpers';
import { urlStrings, workspaceUrlStrings } from './url-string-constants';

type ClusterRef = ClusterObjectRef | undefined;

// this lets us check a string matches one of the values in a record.
type RecordValueType<A> = A extends Record<any, infer V> ? V : never;
function buildUrl(
  // Make sure the first part is required and matches one of the urlStrings
  rootPart: RecordValueType<typeof urlStrings>,
  ...parts: (string | undefined | ClusterObjectRef)[]
) {
  const allParts = [rootPart, ...parts];
  return `/${allParts.map(p => (typeof p === 'object' ? convertRefToUrl(p) : p)).join('/')}/`;
}

function formatSearchParams(params: URLSearchParams): string {
  const size = Array.from(params).length; // `params.size` can't be used since cypress currently uses chrome 109, and size was added in 113
  return size > 0 ? `?${params.toString()}` : '';
}

//
// Login related urls
//
export const buildLoginUrl = () => '/login'; // This must match `LoginPath` in `pkg/dashboard/translator.go`

//
// Overview (platform)
//
export function buildOverviewUrl() {
  buildUrl('overview');
}

//
// Clusters
//
export function buildClustersUrl() {
  return buildUrl('clusters');
}
export function buildClusterDetailsUrl(cluster: string | undefined) {
  return buildUrl('clusters', cluster || 'unknown');
}

//
// Insights
//
/** These each correspond to the filter state variables
 * of the InsightsLanding component (at `/insights`),
 * and can be set to link to that page.
 */
export const insightsSearchParamsKeys = {
  severities: 'severities',
  codeGroups: 'groups',
  clusters: 'clusters',
  searchText: 'search'
} as const;
/** These each correspond to the variables required for
 * the Insights modal on InsightsLanding component (at `/insights`),
 * and can be set to link to that page.
 */
export const insightsModalParamsKeys = {
  modalCode: 'modalCode',
  modalTarget: 'modalTarget'
} as const;

export function buildInsightsUrl(
  props?: Partial<InsightsLandingFiltersObject> & { codes?: Array<`${InsightCodeGroup}${string}`> }
) {
  const params = new URLSearchParams();
  if (props) {
    const { clusters, codeGroups, severities, codes } = props;

    // Because some fields are passed via "searchText", manually handle them here
    let searchText = props.searchText?.trim();
    let searchTextList = searchText ? searchText.split(' ') : [];
    if (codes?.length) searchTextList.push(...codes);
    searchText = searchTextList.join(' ');

    // Set/Delete each value
    if (clusters?.length) params.set(insightsSearchParamsKeys.clusters, encodeUrlSearchParamValue(clusters));
    if (codeGroups?.length) params.set(insightsSearchParamsKeys.codeGroups, encodeUrlSearchParamValue(codeGroups));
    if (searchText?.trim()) params.set(insightsSearchParamsKeys.searchText, encodeUrlSearchParamValue(searchText));
    if (severities?.length) params.set(insightsSearchParamsKeys.severities, encodeUrlSearchParamValue(severities));
  }

  return buildUrl('insights') + formatSearchParams(params);
}

export function buildSecurityInsightsUrl() {
  return buildUrl('security-insights');
}

//
// Certificates
//
export function buildCertificatesUrl() {
  return buildUrl('certificates');
}

//
// Graph
//
export function buildGraphUrl() {
  return buildUrl('graph');
}

//
// Gateways
//
export function buildGatewaysUrl() {
  return buildUrl('gateways');
}

//
// Apis / GraphQL
//
export function buildApisUrl() {
  return buildUrl('apis');
}

interface BuildApiDetailsUrlProps {
  routeTableRef?: ClusterObjectRef;
  istioRouteName: string;
  apiType?: ApiType;
}
export const buildApiDetailsUrl = <T extends BuildApiDetailsUrlProps>(api: T) => {
  const { routeTableRef, istioRouteName, apiType } = api;
  if (!!routeTableRef?.name && !!routeTableRef?.namespace && !!routeTableRef?.clusterName) {
    if (apiType === ApiType.PORTAL_OPENAPI) {
      return buildUrl('apis', 'openapi', routeTableRef);
    }
    // This creates the "name/namespace/clusterName" route for GraphQL API details pages.
    // If workspace data is added to the GraphqlApiSummary object, the format may need to be updated.
    return buildUrl('apis', routeTableRef, istioRouteName);
  }
  return '#';
};

export const buildGraphqlApiExplorerUrl = <
  T extends {
    routeTableRef?: ClusterObjectRef;
    istioRouteName: string;
    gatewayRef?: ClusterObjectRef;
  }
>(
  api: T
) => {
  const { routeTableRef, gatewayRef } = api;
  if (!!routeTableRef?.name && !!routeTableRef?.namespace && !!routeTableRef?.clusterName) {
    let url = `${buildApiDetailsUrl(api)}explorer`;
    if (!!gatewayRef?.name && !!gatewayRef?.namespace && !!gatewayRef?.clusterName) {
      url += `?gwName=${gatewayRef.name}&gwNamespace=${gatewayRef.namespace}&gwCluster=${gatewayRef.clusterName}`;
    }

    return url;
  }
  return '#';
};

//
// Workspace
//
export function buildWorkspacesUrl() {
  return buildUrl('workspaces');
}

export function buildWorkspaceDetailsUrl(workspace: string | undefined) {
  return buildUrl('workspaces', workspace ?? 'unknown');
}

//
// Workspace Subpages
//
function buildWorkspaceSubpageUrl(
  workspace: string | undefined,
  resourceName: keyof typeof workspaceUrlStrings,
  resourceType: string,
  resourceRef: ClusterRef
) {
  return buildUrl('workspaces', workspace ?? 'unknown', resourceName, resourceType, resourceRef);
}

export function buildWorkspacePolicyDetailsUrl(workspace: string | undefined, type: PolicyType, ref: ClusterRef) {
  return buildWorkspaceSubpageUrl(workspace, workspaceUrlStrings.policies, getPolicyTypeId(type), ref);
}

export function buildWorkspaceRouteDetailsUrl(workspace: string | undefined, type: RouteType, ref: ClusterRef) {
  return buildWorkspaceSubpageUrl(workspace, workspaceUrlStrings.routing, routeTypeMap[type].id, ref);
}

export function buildWorkspaceDestinationDetailsUrl(
  workspace: string | undefined,
  kind: DestinationKind,
  ref: ClusterRef
) {
  return buildWorkspaceSubpageUrl(workspace, workspaceUrlStrings.destinations, destinationKindMap[kind].id, ref);
}

export function buildWorkspaceGraphUrl(workspace: string | undefined) {
  return buildUrl('workspaces', workspace ?? 'unknown', workspaceUrlStrings.graph);
}

//
// Resources
//
const resourcesSearchParamsKeys = {
  cluster: 'cluster',
  types: 'types'
} as const;
interface ResourceParamsProps {
  cluster?: string;
  types?: ResourceType[];
}

export function buildResourcesUrl(category: GvkType | undefined, props?: ResourceParamsProps) {
  const params = new URLSearchParams();
  if (props) {
    const { cluster, types } = props;
    if (cluster) params.set(resourcesSearchParamsKeys.cluster, encodeURIComponent(cluster));
    if (types?.length) params.set(resourcesSearchParamsKeys.types, types.map(t => resourceTypesMap[t].id).join(','));
  }
  return buildUrl('resources', gvkTypeMap[category ?? GvkType.GLOO].id) + formatSearchParams(params);
}
export function parseResourceSearchParams(search: string) {
  const params = new URLSearchParams(search);

  const props: ResourceParamsProps = {};
  if (params.has(resourcesSearchParamsKeys.cluster)) {
    props.cluster = decodeURIComponent(params.get(resourcesSearchParamsKeys.cluster) ?? '');
  }
  if (params.has(resourcesSearchParamsKeys.types)) {
    props.types = decodeURIComponent(params.get(resourcesSearchParamsKeys.types) ?? '')
      .split(',')
      .filter(Boolean)
      .map(id => getResourceTypeFromId(id));
  }

  return props;
}

//
// Tracing
//
export function buildTracingUrl() {
  return buildUrl('tracing');
}

//
// Flags
//
export function buildFlagsUrl() {
  return buildUrl('flags');
}

//
// Portals
//
export function buildPortalsUrl() {
  return buildUrl('portals');
}
export function buildPortalDetailsUrl(ref: ClusterRef) {
  return buildUrl('portals', ref);
}

//
// Services
//
const serviceSearchParamsKeys = {
  type: 'type'
} as const;
type ServiceParamsProps = { type?: 'in-mesh' | 'out-of-mesh' };

export function buildServicesUrl(props?: ServiceParamsProps) {
  const params = new URLSearchParams();
  if (props) {
    const { type } = props;
    if (type) params.set(serviceSearchParamsKeys.type, type);
  }

  return buildUrl('services') + formatSearchParams(params);
}

export function parseServicesSearchParams(search: string) {
  const params = new URLSearchParams(search);

  const props: ServiceParamsProps = {};
  if (params.has(serviceSearchParamsKeys.type)) {
    props.type = params.get(serviceSearchParamsKeys.type) as any;
  }

  return props;
}

export function buildServiceDetailsUrl(ref: ClusterRef) {
  return buildUrl('services', ref);
}

//
// Ingress
//
export function buildIngressUrl() {
  return buildUrl('ingress');
}

//
// Egress
//
export function buildEgressUrl() {
  return buildUrl('egress');
}

//
// Logs
//
export const logsSearchParamKeys = {
  cluster: 'cluster',
  component: 'component',
  pod: 'pod',
  container: 'container'
};

export function buildLogsUrl(props?: { cluster?: string; component?: string; pod?: string; container?: string }) {
  const params = new URLSearchParams();
  if (props) {
    const { cluster, component, pod, container } = props;
    if (cluster) params.set(logsSearchParamKeys.cluster, encodeUrlSearchParamValue(cluster));
    if (component) params.set(logsSearchParamKeys.component, encodeUrlSearchParamValue(component));
    if (pod) params.set(logsSearchParamKeys.pod, encodeUrlSearchParamValue(pod));
    if (container) params.set(logsSearchParamKeys.container, encodeUrlSearchParamValue(container));
  }

  return buildUrl('logs') + formatSearchParams(params);
}
