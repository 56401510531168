import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { GqlTypeMergeModal } from 'Components/Features/Apis/Graphql/Modals/TypeMergeModal/GqlTypeMergeModal';
import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { Centered } from 'Styles/CommonEmotions/centered';
import { ObjectTypeDefinitionNode } from 'graphql';
import { GraphqlApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext, useMemo, useState } from 'react';
import { GqlSchemaTabTableProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import {
  RenderDefinitionNameLinkProps,
  renderCenteredTableCell,
  renderDefinitionNameLink
} from '../../../GqlSoloTableCells';
import GqlTypeMergeButton from '../../buttons/GqlTypeMergeButton';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';

type TableFields = {
  key: string;
  nameLink: RenderDefinitionNameLinkProps;
  description: string;
  fields?: number;
  objectName: string;
};

const GqlObjectTabTable = ({
  definitions,
  onTypeClick,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<ObjectTypeDefinitionNode>) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { apiType } = gqlCtx;
  //
  // Pagination, Table Data
  //
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(definitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(d => ({
      key: d.name.value,
      nameLink: { name: d.name.value, tabHeader, onTypeClick },
      description: getDescriptionColumnValue(d),
      fields: d.fields?.length ?? 0,
      objectName: d.name.value
    }));
  }, [paginatedData, tabHeader, onTypeClick]);

  const [selectedTypeMergeObjectName, setSelectedTypeMergeObjectName] = useState('');
  const columns = useMemo(() => {
    const cols: SoloColumnsType<TableFields> = [
      {
        title: 'Name',
        dataIndex: 'nameLink',
        render: renderDefinitionNameLink
      },
      {
        title: 'Description',
        dataIndex: 'description',
        render: renderReactMarkdown
      },
      {
        title: () => <Centered horizontal>Fields</Centered>,
        dataIndex: 'fields',
        render: renderCenteredTableCell
      }
    ];
    if (apiType === GraphqlApiType.STITCHED) {
      cols.push({
        title: () => <Centered horizontal>Type Merge</Centered>,
        dataIndex: 'objectName',
        render: objectName => {
          return (
            <GqlSchemaTabStyles.CenteredTableCell>
              <GqlTypeMergeButton
                onViewDetails={() => setSelectedTypeMergeObjectName(objectName)}
                objectName={objectName}
              />
            </GqlSchemaTabStyles.CenteredTableCell>
          );
        }
      });
    }
    return cols;
  }, [apiType, setSelectedTypeMergeObjectName]);

  //
  // Render
  //
  if (hidden) return null;
  return (
    <>
      <SoloTable
        removeHorizontalPadding
        columns={columns}
        dataSource={tableData}
        paging={pagingData}
        data-testid='gql-objects-table'
      />
      <GqlTypeMergeModal objectName={selectedTypeMergeObjectName} onClose={() => setSelectedTypeMergeObjectName('')} />
    </>
  );
};

export default GqlObjectTabTable;
