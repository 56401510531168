import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { healthColors, hexAddAlpha } from 'Styles/colors';

export namespace CertExpiryStyles {
  export const CardContentAndFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 15px 0px 30px 0px;
  `;

  export const CardContentContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    margin-bottom: -2em;
  `;

  export const CardFooterContainer = styled.div`
    display: flex;
    gap: 1ex;
    align-items: center;
    width: 100%;
  `;

  //
  // For Certificate Expiration Vizualization
  //

  export const CertExpirySubSection = styled.div`
    margin-bottom: 1.25em;
  `;

  export const CertExpiryVizualizationLine = styled.div<{ addMarkerSpace?: boolean }>(({ addMarkerSpace = false }) => {
    return css`
      // Makes room for TextMarker when it's to close to the title text
      margin: ${addMarkerSpace ? 'calc(1ex + 25px)' : '1ex'} 0px 1ex;
      width: 100%;
      height: 10px;
      border-radius: 10px;
      position: relative;
    `;
  });

  export const RootCertExpiryVisualizationLine = styled(CertExpiryVizualizationLine)<{ percentFull: number }>(
    ({ theme, percentFull }) => css`
      background: linear-gradient(90deg, ${theme.oceanBlue} ${percentFull}%, ${theme.splashBlue} ${percentFull}%);
    `
  );

  export const MultiCertExpiryVisualizationLine = styled(CertExpiryVizualizationLine)<{
    warningPercent: number;
    dangerPercent: number;
  }>(
    ({ theme, warningPercent, dangerPercent }) => css`
      background: linear-gradient(
        90deg,
        ${theme.oceanBlue} ${warningPercent}%,
        ${theme.sunGold} ${warningPercent}%,
        ${theme.sunGold} ${dangerPercent}%,
        ${theme.grapefruitOrange} ${dangerPercent}%
      );
    `
  );

  export const CertExpiryDataPoint = styled.div<{
    leftPercent: number;
    inLineSection: keyof typeof healthColors;
  }>(
    ({ theme, leftPercent, inLineSection }) => css`
      position: absolute;
      top: -3px;
      left: calc(${leftPercent}% - 7.5px);
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 15px;
      height: 15px;
      line-height: 15px;
      padding: 1px 2px 0;
      font-size: 12px;
      background-color: ${theme.white};
      border-radius: 7.5px;
      border: 0.5px solid ${theme.healthColors[inLineSection].dark};
      outline: ${hexAddAlpha(theme.healthColors[inLineSection].dark, 0.5)} solid 2px;
      cursor: pointer;

      :hover {
        outline-width: 4px;
      }
    `
  );

  export const CertExpiryTitleText = styled.div(
    ({ theme }) => css`
      color: ${theme.neptuneBlue};
      font-size: 1.15em;
      margin-bottom: 1ex;

      max-width: 30%; // Needed so we know when it's safe not to add padding for the TextMarker
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `
  );

  export const RootCertLineInfoText = styled.div(
    ({ theme }) => css`
      color: ${theme.oceanBlue};
      display: flex;
      align-items: center;
      gap: 0.75ex;
      font-size: 0.9em;
    `
  );

  export const TextMarker = styled.div<{ percent: number; textOnRight: boolean; health: keyof typeof healthColors }>(
    ({ theme, percent, textOnRight, health }) => {
      const myHealthColors = theme.healthColors[health];
      return css`
        position: absolute;
        ${textOnRight ? `left: ${percent}%;` : `right: ${100 - percent}%;`}
        bottom: 10px; // the line's height
        display: flex;
        padding: 0 5px;
        font-size: 0.9em;
        white-space: nowrap;
        color: ${'text' in myHealthColors ? myHealthColors.text : myHealthColors.dark};
      
        :${textOnRight ? 'before' : 'after'} {
          content: '';
          position: relative;
          display: block;
          height: 30px;
          border-left: 1px solid ${myHealthColors.dark};
          // should match the 'padding' above (with 'left' being 1 greater)
          ${textOnRight ? `left: -6px;` : `right: -5px;`}
          top: 0;
        }
      `;
    }
  );

  export const ExpiryDateText = styled.div(
    ({ theme }) => css`
      color: ${theme.oceanBlue};
      font-weight: 500;
      font-size: 0.9em;
    `
  );

  export const DataPointTooltipContentScroller = styled.div`
    max-height: 310px;
    overflow-y: auto;
  `;

  export const TooltipLinkContainer = styled.div(
    ({ theme }) => css`
      display: inline-block;
      padding-top: 5px;
      ${SoloLinkStyles.SoloLinkLooks} {
        color: ${theme.white};
      }
    `
  );

  export const TooltipKeyText = styled.div`
    margin-right: 20px;
  `;

  export const TooltipValueText = styled.div`
    font-weight: 500;
  `;

  export const LimitWarning = styled.div(({ theme }) => {
    const myHealthColors = theme.healthColors.warning;
    return css`
      padding: 0.5em 1em;
      margin-bottom: 0.5em;
      border: 1px solid ${myHealthColors.dark};
      color: ${myHealthColors.text};
    `;
  });
}
