/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { RouteTableDetails } from "./routes_common_pb";
import { PolicyType } from "./common_pb";
import { AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams } from "../../../../api/gloo.solo.io/policy/v2/resilience/adaptive_request_concurrency_policy_pb";
import { LoadBalancerPolicySpec_Config_SimpleLB } from "../../../../api/gloo.solo.io/policy/v2/trafficcontrol/load_balancer_policy_pb";
import { LoadBalancerPolicySpec_Config_ConsistentHashLB } from "../../../../api/gloo.solo.io/policy/v2/trafficcontrol/load_balancer_policy_pb";
import { Empty } from "../../../../../../../../google/protobuf/empty_pb";
import { StringValue } from "../../../../../../../../google/protobuf/wrappers_pb";
import { TransformationPolicySpec_Config_ResponseTransformation } from "../../../../api/gloo.solo.io/policy/v2/trafficcontrol/transformation_policy_pb";
import { TransformationPolicySpec_Config_RequestTransformation } from "../../../../api/gloo.solo.io/policy/v2/trafficcontrol/transformation_policy_pb";
import { AccessLogPolicySpec_Config_Filter } from "../../../../api/gloo.solo.io/policy/v2/observability/access_log_policy_pb";
import { JWTPolicySpec_Config_Provider_ClaimsToHeader } from "../../../../api/gloo.solo.io/policy/v2/security/jwt_policy_pb";
import { Int32Value } from "../../../../../../../../google/protobuf/wrappers_pb";
import { DlpAction } from "../../../../api/gloo.solo.io/policy/v2/security/dlp_policy_pb";
import { DestinationReference } from "../../../../api/gloo.solo.io/common/v2/references_pb";
import { BoolValue } from "../../../../../../../../google/protobuf/wrappers_pb";
import { UInt32Value } from "../../../../../../../../google/protobuf/wrappers_pb";
import { FailoverPolicySpec_Config_LocalityMappings } from "../../../../api/gloo.solo.io/policy/v2/resilience/failover_policy_pb";
import { DoubleValue } from "../../../../../../../../google/protobuf/wrappers_pb";
import { AccessPolicySpec_Config_Authentication_TLSmode } from "../../../../api/gloo.solo.io/policy/v2/security/access_policy_pb";
import { WorkspaceStatus } from "./common_pb";
import { Pagination } from "./common_pb";
import { Duration } from "../../../../../../../../google/protobuf/duration_pb";
import { Status } from "./common_pb";
import { DestinationKind } from "../../../../api/gloo.solo.io/common/v2/selectors_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyDestination
 */
export interface PolicyDestination {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyDestinations
 */
export interface PolicyDestinations {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.PolicyDestination destinations = 1;
     */
    destinations: PolicyDestination[];
    /**
     * @generated from protobuf field: int32 total_policy_destinations = 2;
     */
    totalPolicyDestinations: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyRouteTable
 */
export interface PolicyRouteTable {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string owner_workspace_name = 2;
     */
    ownerWorkspaceName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyRoutes
 */
export interface PolicyRoutes {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.PolicyRouteTable route_tables = 1;
     */
    routeTables: PolicyRouteTable[];
    /**
     * @generated from protobuf field: int32 total_policy_routes = 2;
     */
    totalPolicyRoutes: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyWorkload
 */
export interface PolicyWorkload {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyWorkload.WorkloadKind kind = 2;
     */
    kind: PolicyWorkload_WorkloadKind;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.PolicyWorkload.WorkloadKind
 */
export enum PolicyWorkload_WorkloadKind {
    /**
     * @generated from protobuf enum value: KUBE = 0;
     */
    KUBE = 0,
    /**
     * @generated from protobuf enum value: VM = 1;
     */
    VM = 1
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyWorkloads
 */
export interface PolicyWorkloads {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.PolicyWorkload workloads = 1;
     */
    workloads: PolicyWorkload[];
    /**
     * @generated from protobuf field: int32 total_workloads = 2;
     */
    totalWorkloads: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyVirtualGatewayListener
 */
export interface PolicyVirtualGatewayListener {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: uint32 port = 2;
     */
    port: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyVirtualGatewayListeners
 */
export interface PolicyVirtualGatewayListeners {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.PolicyVirtualGatewayListener virtual_gateway_listeners = 1;
     */
    virtualGatewayListeners: PolicyVirtualGatewayListener[];
    /**
     * @generated from protobuf field: int32 total_virtual_gateway_listeners = 2;
     */
    totalVirtualGatewayListeners: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyVirtualGateway
 */
export interface PolicyVirtualGateway {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.PolicyVirtualGateways
 */
export interface PolicyVirtualGateways {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.PolicyVirtualGateway virtual_gateways = 1;
     */
    virtualGateways: PolicyVirtualGateway[];
    /**
     * @generated from protobuf field: int32 total_virtual_gateways = 2;
     */
    totalVirtualGateways: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.TcpKeepalive
 */
export interface TcpKeepalive {
    /**
     * @generated from protobuf field: uint32 probes = 1;
     */
    probes: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration time = 2;
     */
    time?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration interval = 3;
     */
    interval?: Duration;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.StringMatch
 */
export interface StringMatch {
    /**
     * @generated from protobuf field: string matcher = 1;
     */
    matcher: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.StringMatchType type = 2;
     */
    type: StringMatchType;
    /**
     * @generated from protobuf field: bool case_insensitive = 3;
     */
    caseInsensitive: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPolicyDetailsRequest
 */
export interface GetPolicyDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsRequest
 */
export interface GetAccessPolicyDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination client_pagination = 2;
     */
    clientPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination destination_pagination = 3;
     */
    destinationPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetExtAuthPolicyDetailsRequest
 */
export interface GetExtAuthPolicyDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination destination_pagination = 2;
     */
    destinationPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination route_table_pagination = 3;
     */
    routeTablePagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRateLimitPolicyDetailsRequest
 */
export interface GetRateLimitPolicyDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination destination_pagination = 2;
     */
    destinationPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination route_table_pagination = 3;
     */
    routeTablePagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsRequest
 */
export interface GetActiveHealthCheckPolicyDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination destination_pagination = 2;
     */
    destinationPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination virtual_gateway_pagination = 3;
     */
    virtualGatewayPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsRequest
 */
export interface GetGraphQLAllowedQueryPolicyDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination allowed_query_pagination = 2;
     */
    allowedQueryPagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination route_pagination = 3;
     */
    routePagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsResponse
 */
export interface GetAccessPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication.TLSmode tls_mode = 3;
     */
    tlsMode: AccessPolicySpec_Config_Authentication_TLSmode;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Authorization authorization = 4;
     */
    authorization?: GetAccessPolicyDetailsResponse_Authorization;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 5;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Authorization
 */
export interface GetAccessPolicyDetailsResponse_Authorization {
    /**
     * @generated from protobuf field: repeated string allowed_paths = 1;
     */
    allowedPaths: string[];
    /**
     * @generated from protobuf field: repeated string allowed_methods = 2;
     */
    allowedMethods: string[];
    /**
     * @generated from protobuf field: repeated string allowed_ip_blocks = 3;
     */
    allowedIpBlocks: string[];
    /**
     * @generated from protobuf field: repeated string allowed_remote_ip_blocks = 4;
     */
    allowedRemoteIpBlocks: string[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Client allowed_clients = 5;
     */
    allowedClients: GetAccessPolicyDetailsResponse_Client[];
    /**
     * @generated from protobuf field: int32 total_clients = 6;
     */
    totalClients: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Client
 */
export interface GetAccessPolicyDetailsResponse_Client {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string request_principals = 2;
     */
    requestPrincipals: string[];
    /**
     * @generated from protobuf field: repeated string request_not_principals = 3;
     */
    requestNotPrincipals: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetFaultInjectionPolicyDetailsResponse
 */
export interface GetFaultInjectionPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: int32 abort_http_status = 3;
     */
    abortHttpStatus: number;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue abort_percentage = 4;
     */
    abortPercentage?: DoubleValue;
    /**
     * @generated from protobuf field: google.protobuf.Duration fixed_delay = 5;
     */
    fixedDelay?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue delay_percentage = 6;
     */
    delayPercentage?: DoubleValue;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 7;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 8;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRetryPolicyDetailsResponse
 */
export interface GetRetryPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: int32 attempts = 3;
     */
    attempts: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration per_try_timeout = 4;
     */
    perTryTimeout?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration request_timeout = 5;
     */
    requestTimeout?: Duration;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 6;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetFailoverPolicyDetailsResponse
 */
export interface GetFailoverPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings locality_mappings = 3;
     */
    localityMappings: FailoverPolicySpec_Config_LocalityMappings[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 5;
     */
    destinations?: PolicyDestinations;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetOutlierDetectionPolicyDetailsResponse
 */
export interface GetOutlierDetectionPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value consecutive_errors = 3;
     */
    consecutiveErrors?: UInt32Value;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value consecutive_gateway_errors = 4;
     */
    consecutiveGatewayErrors?: UInt32Value;
    /**
     * @generated from protobuf field: google.protobuf.Duration interval = 5;
     */
    interval?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration base_ejection_time = 6;
     */
    baseEjectionTime?: Duration;
    /**
     * @generated from protobuf field: uint32 max_ejection_percent = 7;
     */
    maxEjectionPercent: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 8;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 9;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetCorsPolicyDetailsResponse
 */
export interface GetCorsPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.StringMatch allowed_origins = 3;
     */
    allowedOrigins: StringMatch[];
    /**
     * @generated from protobuf field: repeated string allowed_methods = 4;
     */
    allowedMethods: string[];
    /**
     * @generated from protobuf field: repeated string allowed_headers = 5;
     */
    allowedHeaders: string[];
    /**
     * @generated from protobuf field: google.protobuf.Duration max_age = 6;
     */
    maxAge?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue allow_credentials = 7;
     */
    allowCredentials?: BoolValue;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 8;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 9;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse
 */
export interface GetCsrfPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse.CsrfMode mode = 3;
     */
    mode: GetCsrfPolicyDetailsResponse_CsrfMode;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.StringMatch additional_origins = 4;
     */
    additionalOrigins: StringMatch[];
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue percentage = 5;
     */
    percentage?: DoubleValue;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 6;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse.CsrfMode
 */
export enum GetCsrfPolicyDetailsResponse_CsrfMode {
    /**
     * @generated from protobuf enum value: UNKNOWN_MODE = 0;
     */
    UNKNOWN_MODE = 0,
    /**
     * @generated from protobuf enum value: ENABLED = 1;
     */
    ENABLED = 1,
    /**
     * @generated from protobuf enum value: DISABLED = 2;
     */
    DISABLED = 2,
    /**
     * @generated from protobuf enum value: SHADOW = 3;
     */
    SHADOW = 3
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetMirrorPolicyDetailsResponse
 */
export interface GetMirrorPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue percentage = 3;
     */
    percentage?: DoubleValue;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationReference mirror_destination = 4;
     */
    mirrorDestination?: DestinationReference;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 5;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse
 */
export interface GetExtAuthPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthUsed auth_used = 2;
     */
    authUsed: GetExtAuthPolicyDetailsResponse_AuthUsed;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthCheck auth_checks = 3;
     */
    authChecks: GetExtAuthPolicyDetailsResponse_AuthCheck[];
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef server_ref = 4;
     */
    serverRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 5;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 6;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthCheck
 */
export interface GetExtAuthPolicyDetailsResponse_AuthCheck {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthType type = 2;
     */
    type: GetExtAuthPolicyDetailsResponse_AuthType;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthUsed
 */
export enum GetExtAuthPolicyDetailsResponse_AuthUsed {
    /**
     * @generated from protobuf enum value: AUTH_DISABLED = 0;
     */
    AUTH_DISABLED = 0,
    /**
     * @generated from protobuf enum value: GLOO_AUTH = 1;
     */
    GLOO_AUTH = 1,
    /**
     * @generated from protobuf enum value: CUSTOM_AUTH = 2;
     */
    CUSTOM_AUTH = 2
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthType
 */
export enum GetExtAuthPolicyDetailsResponse_AuthType {
    /**
     * @generated from protobuf enum value: UNKNOWN_AUTH_METHOD = 0;
     */
    UNKNOWN_AUTH_METHOD = 0,
    /**
     * @generated from protobuf enum value: BASIC = 1;
     */
    BASIC = 1,
    /**
     * @generated from protobuf enum value: OAUTH = 2;
     */
    OAUTH = 2,
    /**
     * @generated from protobuf enum value: OAUTH2 = 3;
     */
    OAUTH2 = 3,
    /**
     * @generated from protobuf enum value: API_KEY = 4;
     */
    API_KEY = 4,
    /**
     * @generated from protobuf enum value: PLUGIN = 5;
     */
    PLUGIN = 5,
    /**
     * @generated from protobuf enum value: OPA = 6;
     */
    OPA = 6,
    /**
     * @generated from protobuf enum value: LDAP = 7;
     */
    LDAP = 7,
    /**
     * @generated from protobuf enum value: JWT = 8;
     */
    JWT = 8,
    /**
     * @generated from protobuf enum value: PASS_THROUGH = 9;
     */
    PASS_THROUGH = 9,
    /**
     * @generated from protobuf enum value: HMAC = 10;
     */
    HMAC = 10,
    /**
     * @generated from protobuf enum value: OPA_SERVER = 11;
     */
    OPA_SERVER = 11
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRateLimitPolicyDetailsResponse
 */
export interface GetRateLimitPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * The destination_server on the RateLimitServerSettings referenced by the policy
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef server_settings_destination_server = 2;
     */
    serverSettingsDestinationServer?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 3;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 4;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWafPolicyDetailsResponse
 */
export interface GetWafPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: bool enable_core_rule_set = 2;
     */
    enableCoreRuleSet: boolean;
    /**
     * @generated from protobuf field: bool request_headers_only = 3;
     */
    requestHeadersOnly: boolean;
    /**
     * @generated from protobuf field: bool response_headers_only = 4;
     */
    responseHeadersOnly: boolean;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetWafPolicyDetailsResponse.AuditLogAction action = 5;
     */
    action: GetWafPolicyDetailsResponse_AuditLogAction;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetWafPolicyDetailsResponse.AuditLogLocation location = 6;
     */
    location: GetWafPolicyDetailsResponse_AuditLogLocation;
    /**
     * @generated from protobuf field: string message = 7;
     */
    message: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 8;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 9;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetWafPolicyDetailsResponse.AuditLogAction
 */
export enum GetWafPolicyDetailsResponse_AuditLogAction {
    /**
     * @generated from protobuf enum value: NEVER = 0;
     */
    NEVER = 0,
    /**
     * @generated from protobuf enum value: RELEVANT_ONLY = 1;
     */
    RELEVANT_ONLY = 1,
    /**
     * @generated from protobuf enum value: ALWAYS = 2;
     */
    ALWAYS = 2
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetWafPolicyDetailsResponse.AuditLogLocation
 */
export enum GetWafPolicyDetailsResponse_AuditLogLocation {
    /**
     * @generated from protobuf enum value: FILTER_STATE = 0;
     */
    FILTER_STATE = 0,
    /**
     * @generated from protobuf enum value: DYNAMIC_METADATA = 1;
     */
    DYNAMIC_METADATA = 1
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetDlpPolicyDetailsResponse
 */
export interface GetDlpPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.DlpAction actions = 2;
     */
    actions: DlpAction[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetDlpPolicyDetailsResponse.Sanitize sanitize = 3;
     */
    sanitize: GetDlpPolicyDetailsResponse_Sanitize;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 4;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetDlpPolicyDetailsResponse.Sanitize
 */
export enum GetDlpPolicyDetailsResponse_Sanitize {
    /**
     * @generated from protobuf enum value: ALL = 0;
     */
    ALL = 0,
    /**
     * @generated from protobuf enum value: ACCESS_LOGS = 1;
     */
    ACCESS_LOGS = 1,
    /**
     * @generated from protobuf enum value: RESPONSE_BODY = 2;
     */
    RESPONSE_BODY = 2
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetHeaderManipulationPolicyDetailsResponse
 */
export interface GetHeaderManipulationPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated string remove_response_headers = 2;
     */
    removeResponseHeaders: string[];
    /**
     * @generated from protobuf field: map<string, string> append_response_headers = 3;
     */
    appendResponseHeaders: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: repeated string remove_request_headers = 4;
     */
    removeRequestHeaders: string[];
    /**
     * @generated from protobuf field: map<string, string> append_request_headers = 5;
     */
    appendRequestHeaders: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 6;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetJwtPolicyDetailsResponse
 */
export interface GetJwtPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Phase phase = 2;
     */
    phase: GetJwtPolicyDetailsResponse_Phase;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value priority = 3;
     */
    priority?: Int32Value;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider providers = 4;
     */
    providers: GetJwtPolicyDetailsResponse_Provider[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 5;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider
 */
export interface GetJwtPolicyDetailsResponse_Provider {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string issuer = 2;
     */
    issuer: string;
    /**
     * @generated from protobuf field: repeated string audiences = 3;
     */
    audiences: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider.Jwks jwks_source = 4;
     */
    jwksSource: GetJwtPolicyDetailsResponse_Provider_Jwks;
    /**
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.JWTPolicySpec.Config.Provider.ClaimsToHeader claims_to_headers = 5;
     */
    claimsToHeaders: JWTPolicySpec_Config_Provider_ClaimsToHeader[];
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider.Jwks
 */
export enum GetJwtPolicyDetailsResponse_Provider_Jwks {
    /**
     * @generated from protobuf enum value: LOCAL = 0;
     */
    LOCAL = 0,
    /**
     * @generated from protobuf enum value: REMOTE = 1;
     */
    REMOTE = 1
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Phase
 */
export enum GetJwtPolicyDetailsResponse_Phase {
    /**
     * @generated from protobuf enum value: PRE_AUTH_Z = 0;
     */
    PRE_AUTH_Z = 0,
    /**
     * @generated from protobuf enum value: POST_AUTH_Z = 1;
     */
    POST_AUTH_Z = 1
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAccessLogPolicyDetailsResponse
 */
export interface GetAccessLogPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated observability.policy.gloo.solo.io.AccessLogPolicySpec.Config.Filter filters = 2;
     */
    filters: AccessLogPolicySpec_Config_Filter[];
    /**
     * @generated from protobuf field: repeated string request_headers = 3;
     */
    requestHeaders: string[];
    /**
     * @generated from protobuf field: repeated string response_headers = 4;
     */
    responseHeaders: string[];
    /**
     * @generated from protobuf field: repeated string response_trailers = 5;
     */
    responseTrailers: string[];
    /**
     * @generated from protobuf field: repeated string filter_state_objects = 6;
     */
    filterStateObjects: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyWorkloads workloads = 7;
     */
    workloads?: PolicyWorkloads;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 8;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse
 */
export interface GetTransformationPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 2;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.RequestTransformation request_transformation = 3;
     */
    requestTransformation?: TransformationPolicySpec_Config_RequestTransformation;
    /**
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.TransformationPolicySpec.Config.ResponseTransformation response_transformation = 4;
     */
    responseTransformation?: TransformationPolicySpec_Config_ResponseTransformation;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse.Phase phase = 5;
     */
    phase: GetTransformationPolicyDetailsResponse_Phase;
    /**
     * @generated from protobuf field: int32 timing_priority = 6;
     */
    timingPriority: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse.Phase
 */
export enum GetTransformationPolicyDetailsResponse_Phase {
    /**
     * @generated from protobuf enum value: PRE_AUTHZ = 0;
     */
    PRE_AUTHZ = 0,
    /**
     * @generated from protobuf enum value: POST_AUTHZ = 1;
     */
    POST_AUTHZ = 1
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse
 */
export interface GetWasmDeploymentPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter filters = 2;
     */
    filters: GetWasmDeploymentPolicyDetailsResponse_WasmFilter[];
    /**
     * @generated from protobuf field: uint32 weight = 3;
     */
    weight: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyWorkloads workloads = 4;
     */
    workloads?: PolicyWorkloads;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter
 */
export interface GetWasmDeploymentPolicyDetailsResponse_WasmFilter {
    /**
     * @generated from protobuf field: string source = 1;
     */
    source: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter.SourceType source_type = 2;
     */
    sourceType: GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType;
    /**
     * @generated from protobuf field: string root_id = 3;
     */
    rootId: string;
    /**
     * @generated from protobuf field: string vm_id = 4;
     */
    vmId: string;
    /**
     * @generated from protobuf field: string filter_patch_context = 5;
     */
    filterPatchContext: string;
    /**
     * @generated from protobuf field: string insert_before_filter = 6;
     */
    insertBeforeFilter: string;
    /**
     * @generated from protobuf field: string filter_config = 7;
     */
    filterConfig: string; // JSON formatted
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter.SourceType
 */
export enum GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType {
    /**
     * @generated from protobuf enum value: OCI_IMAGE = 0;
     */
    OCI_IMAGE = 0,
    /**
     * @generated from protobuf enum value: LOCAL_PATH = 1;
     */
    LOCAL_PATH = 1,
    /**
     * @generated from protobuf enum value: HTTP_URI = 2;
     */
    HTTP_URI = 2
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse
 */
export interface GetConnectionPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.TcpKeepalive keepalive = 2;
     */
    keepalive?: TcpKeepalive;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse.HTTPSettings http_settings = 3;
     */
    httpSettings?: GetConnectionPolicyDetailsResponse_HTTPSettings;
    /**
     * @generated from protobuf field: int32 tcp_max_connections = 4;
     */
    tcpMaxConnections: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration timeout = 5;
     */
    timeout?: Duration;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 6;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse.HTTPSettings
 */
export interface GetConnectionPolicyDetailsResponse_HTTPSettings {
    /**
     * @generated from protobuf field: int32 max_requests_per_connection = 1;
     */
    maxRequestsPerConnection: number;
    /**
     * @generated from protobuf field: int32 max_retries = 2;
     */
    maxRetries: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration idle_timeout = 3;
     */
    idleTimeout?: Duration;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetProxyProtocolPolicyDetailsResponse
 */
export interface GetProxyProtocolPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: bool enable_proxy_protocol = 2;
     */
    enableProxyProtocol: boolean;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyVirtualGatewayListeners virtual_gateway_listeners = 3;
     */
    virtualGatewayListeners?: PolicyVirtualGatewayListeners;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetHttpBufferPolicyDetailsResponse
 */
export interface GetHttpBufferPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value max_request_bytes = 2;
     */
    maxRequestBytes?: UInt32Value;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 3;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse
 */
export interface GetClientTlsPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.ClientTlsMode mode = 2;
     */
    mode: GetClientTlsPolicyDetailsResponse_ClientTlsMode;
    /**
     * Will be populated for modes that have associated config (MUTUAL, SIMPLE)
     *
     * @generated from protobuf field: rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.TlsConfig tls_config = 3;
     */
    tlsConfig?: GetClientTlsPolicyDetailsResponse_TlsConfig;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 4;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.TlsConfig
 */
export interface GetClientTlsPolicyDetailsResponse_TlsConfig {
    /**
     * @generated from protobuf field: google.protobuf.StringValue sni = 1;
     */
    sni?: StringValue;
    /**
     * @generated from protobuf field: string credentialName = 2;
     */
    credentialName: string;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.ClientTlsMode
 */
export enum GetClientTlsPolicyDetailsResponse_ClientTlsMode {
    /**
     * @generated from protobuf enum value: DISABLE = 0;
     */
    DISABLE = 0,
    /**
     * @generated from protobuf enum value: ISTIO_MUTUAL = 1;
     */
    ISTIO_MUTUAL = 1,
    /**
     * @generated from protobuf enum value: MUTUAL = 2;
     */
    MUTUAL = 2,
    /**
     * @generated from protobuf enum value: SIMPLE = 3;
     */
    SIMPLE = 3
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsResponse
 */
export interface GetActiveHealthCheckPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyVirtualGateways virtual_gateways = 2;
     */
    virtualGateways?: PolicyVirtualGateways;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 3;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * @generated from protobuf oneof: health_check_type
     */
    healthCheckType: {
        oneofKind: "httpHealthCheck";
        /**
         * @generated from protobuf field: google.protobuf.Empty http_health_check = 5;
         */
        httpHealthCheck: Empty;
    } | {
        oneofKind: "tcpHealthCheck";
        /**
         * @generated from protobuf field: google.protobuf.Empty tcp_health_check = 6;
         */
        tcpHealthCheck: Empty;
    } | {
        oneofKind: "grpcHealthCheck";
        /**
         * @generated from protobuf field: google.protobuf.Empty grpc_health_check = 7;
         */
        grpcHealthCheck: Empty;
    } | {
        oneofKind: "customHealthCheck";
        /**
         * @generated from protobuf field: google.protobuf.Empty custom_health_check = 8;
         */
        customHealthCheck: Empty;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetTrimProxyConfigPolicyDetailsResponse
 */
export interface GetTrimProxyConfigPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated string included_hosts = 2;
     */
    includedHosts: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyWorkloads workloads = 3;
     */
    workloads?: PolicyWorkloads;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse
 */
export interface GetLoadBalancerPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf oneof: lb_policy_data
     */
    lbPolicyData: {
        oneofKind: "simple";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse.SimpleLoadBalancer simple = 2;
         */
        simple: GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer;
    } | {
        oneofKind: "consistentHash";
        /**
         * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB consistent_hash = 3;
         */
        consistentHash: LoadBalancerPolicySpec_Config_ConsistentHashLB;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: google.protobuf.Duration warmup = 4;
     */
    warmup?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue healthy_panic_threshold = 5;
     */
    healthyPanicThreshold?: DoubleValue;
    /**
     * @generated from protobuf field: google.protobuf.Duration update_merge_window = 6;
     */
    updateMergeWindow?: Duration;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 7;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 8;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse.SimpleLoadBalancer
 */
export interface GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer {
    /**
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.SimpleLB type = 1;
     */
    type: LoadBalancerPolicySpec_Config_SimpleLB;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetListenerConnectionPolicyDetailsResponse
 */
export interface GetListenerConnectionPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value per_connection_buffer_limit_bytes = 2;
     */
    perConnectionBufferLimitBytes?: UInt32Value;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyVirtualGatewayListeners virtual_gateway_listeners = 3;
     */
    virtualGatewayListeners?: PolicyVirtualGatewayListeners;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.TcpKeepalive tcp_keepalive = 4;
     */
    tcpKeepalive?: TcpKeepalive;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphQLPersistedQueryCachePolicyDetailsResponse
 */
export interface GetGraphQLPersistedQueryCachePolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: uint32 cache_size = 2;
     */
    cacheSize: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 3;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsResponse
 */
export interface GetGraphQLAllowedQueryPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated string allowed_query_hashes = 2;
     */
    allowedQueryHashes: string[];
    /**
     * @generated from protobuf field: int32 total_allowed_query_hashes = 3;
     */
    totalAllowedQueryHashes: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyRoutes routes = 4;
     */
    routes?: PolicyRoutes;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse
 */
export interface GetAdaptiveRequestConcurrencyPolicyDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse.RequestConcurrencySettings request_concurrency_settings = 2;
     */
    requestConcurrencySettings?: GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings;
    /**
     * @generated from protobuf field: resilience.policy.gloo.solo.io.AdaptiveRequestConcurrencyPolicySpec.Config.MinRoundtripTimeCalculationParams min_rtt_calc_settings = 3;
     */
    minRttCalcSettings?: AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyDestinations destinations = 4;
     */
    destinations?: PolicyDestinations;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse.RequestConcurrencySettings
 */
export interface GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings {
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue sample_aggregate_percentile = 1;
     */
    sampleAggregatePercentile?: DoubleValue;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value max_concurrency_limit = 2;
     */
    maxConcurrencyLimit?: UInt32Value;
    /**
     * @generated from protobuf field: uint32 concurrency_update_interval_millis = 3;
     */
    concurrencyUpdateIntervalMillis: number;
    /**
     * @generated from protobuf field: uint32 concurrency_limit_exceeded_status = 4;
     */
    concurrencyLimitExceededStatus: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRateLimitClientConfigRequest
 */
export interface GetRateLimitClientConfigRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRateLimitClientConfigResponse
 */
export interface GetRateLimitClientConfigResponse {
    /**
     * @generated from protobuf field: string yaml = 1;
     */
    yaml: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPolicyRoutesRequest
 */
export interface GetPolicyRoutesRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef policy_ref = 1;
     */
    policyRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyType policy_type = 2;
     */
    policyType: PolicyType;
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 3;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 4;
     */
    pagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPolicyRoutesResponse
 */
export interface GetPolicyRoutesResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.RouteTableDetails route_table_details = 1;
     */
    routeTableDetails?: RouteTableDetails;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.StringMatchType
 */
export enum StringMatchType {
    /**
     * @generated from protobuf enum value: UNKNOWN_MATCH_TYPE = 0;
     */
    UNKNOWN_MATCH_TYPE = 0,
    /**
     * @generated from protobuf enum value: EXACT = 1;
     */
    EXACT = 1,
    /**
     * @generated from protobuf enum value: PREFIX = 2;
     */
    PREFIX = 2,
    /**
     * @generated from protobuf enum value: REGEX = 3;
     */
    REGEX = 3,
    /**
     * @generated from protobuf enum value: SUFFIX = 4;
     */
    SUFFIX = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class PolicyDestination$Type extends MessageType<PolicyDestination> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyDestination", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyDestination
 */
export const PolicyDestination = new PolicyDestination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyDestinations$Type extends MessageType<PolicyDestinations> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyDestinations", [
            { no: 1, name: "destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyDestination },
            { no: 2, name: "total_policy_destinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyDestinations
 */
export const PolicyDestinations = new PolicyDestinations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyRouteTable$Type extends MessageType<PolicyRouteTable> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyRouteTable", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "owner_workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyRouteTable
 */
export const PolicyRouteTable = new PolicyRouteTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyRoutes$Type extends MessageType<PolicyRoutes> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyRoutes", [
            { no: 1, name: "route_tables", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyRouteTable },
            { no: 2, name: "total_policy_routes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyRoutes
 */
export const PolicyRoutes = new PolicyRoutes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyWorkload$Type extends MessageType<PolicyWorkload> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyWorkload", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "kind", kind: "enum", T: () => ["rpc.gloo.solo.io.PolicyWorkload.WorkloadKind", PolicyWorkload_WorkloadKind] },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyWorkload
 */
export const PolicyWorkload = new PolicyWorkload$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyWorkloads$Type extends MessageType<PolicyWorkloads> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyWorkloads", [
            { no: 1, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyWorkload },
            { no: 2, name: "total_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyWorkloads
 */
export const PolicyWorkloads = new PolicyWorkloads$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyVirtualGatewayListener$Type extends MessageType<PolicyVirtualGatewayListener> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyVirtualGatewayListener", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyVirtualGatewayListener
 */
export const PolicyVirtualGatewayListener = new PolicyVirtualGatewayListener$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyVirtualGatewayListeners$Type extends MessageType<PolicyVirtualGatewayListeners> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyVirtualGatewayListeners", [
            { no: 1, name: "virtual_gateway_listeners", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyVirtualGatewayListener },
            { no: 2, name: "total_virtual_gateway_listeners", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyVirtualGatewayListeners
 */
export const PolicyVirtualGatewayListeners = new PolicyVirtualGatewayListeners$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyVirtualGateway$Type extends MessageType<PolicyVirtualGateway> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyVirtualGateway", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyVirtualGateway
 */
export const PolicyVirtualGateway = new PolicyVirtualGateway$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyVirtualGateways$Type extends MessageType<PolicyVirtualGateways> {
    constructor() {
        super("rpc.gloo.solo.io.PolicyVirtualGateways", [
            { no: 1, name: "virtual_gateways", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyVirtualGateway },
            { no: 2, name: "total_virtual_gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.PolicyVirtualGateways
 */
export const PolicyVirtualGateways = new PolicyVirtualGateways$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TcpKeepalive$Type extends MessageType<TcpKeepalive> {
    constructor() {
        super("rpc.gloo.solo.io.TcpKeepalive", [
            { no: 1, name: "probes", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "time", kind: "message", T: () => Duration },
            { no: 3, name: "interval", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.TcpKeepalive
 */
export const TcpKeepalive = new TcpKeepalive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringMatch$Type extends MessageType<StringMatch> {
    constructor() {
        super("rpc.gloo.solo.io.StringMatch", [
            { no: 1, name: "matcher", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.StringMatchType", StringMatchType] },
            { no: 3, name: "case_insensitive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.StringMatch
 */
export const StringMatch = new StringMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPolicyDetailsRequest$Type extends MessageType<GetPolicyDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetPolicyDetailsRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPolicyDetailsRequest
 */
export const GetPolicyDetailsRequest = new GetPolicyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccessPolicyDetailsRequest$Type extends MessageType<GetAccessPolicyDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetAccessPolicyDetailsRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "client_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "destination_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsRequest
 */
export const GetAccessPolicyDetailsRequest = new GetAccessPolicyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetExtAuthPolicyDetailsRequest$Type extends MessageType<GetExtAuthPolicyDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetExtAuthPolicyDetailsRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "destination_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "route_table_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetExtAuthPolicyDetailsRequest
 */
export const GetExtAuthPolicyDetailsRequest = new GetExtAuthPolicyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRateLimitPolicyDetailsRequest$Type extends MessageType<GetRateLimitPolicyDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetRateLimitPolicyDetailsRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "destination_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "route_table_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRateLimitPolicyDetailsRequest
 */
export const GetRateLimitPolicyDetailsRequest = new GetRateLimitPolicyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveHealthCheckPolicyDetailsRequest$Type extends MessageType<GetActiveHealthCheckPolicyDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "destination_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "virtual_gateway_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsRequest
 */
export const GetActiveHealthCheckPolicyDetailsRequest = new GetActiveHealthCheckPolicyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphQLAllowedQueryPolicyDetailsRequest$Type extends MessageType<GetGraphQLAllowedQueryPolicyDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "allowed_query_pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "route_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsRequest
 */
export const GetGraphQLAllowedQueryPolicyDetailsRequest = new GetGraphQLAllowedQueryPolicyDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccessPolicyDetailsResponse$Type extends MessageType<GetAccessPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetAccessPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "tls_mode", kind: "enum", T: () => ["security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication.TLSmode", AccessPolicySpec_Config_Authentication_TLSmode] },
            { no: 4, name: "authorization", kind: "message", T: () => GetAccessPolicyDetailsResponse_Authorization },
            { no: 5, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsResponse
 */
export const GetAccessPolicyDetailsResponse = new GetAccessPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccessPolicyDetailsResponse_Authorization$Type extends MessageType<GetAccessPolicyDetailsResponse_Authorization> {
    constructor() {
        super("rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Authorization", [
            { no: 1, name: "allowed_paths", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "allowed_methods", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "allowed_ip_blocks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "allowed_remote_ip_blocks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "allowed_clients", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetAccessPolicyDetailsResponse_Client },
            { no: 6, name: "total_clients", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Authorization
 */
export const GetAccessPolicyDetailsResponse_Authorization = new GetAccessPolicyDetailsResponse_Authorization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccessPolicyDetailsResponse_Client$Type extends MessageType<GetAccessPolicyDetailsResponse_Client> {
    constructor() {
        super("rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Client", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "request_principals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "request_not_principals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAccessPolicyDetailsResponse.Client
 */
export const GetAccessPolicyDetailsResponse_Client = new GetAccessPolicyDetailsResponse_Client$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFaultInjectionPolicyDetailsResponse$Type extends MessageType<GetFaultInjectionPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetFaultInjectionPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "abort_http_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "abort_percentage", kind: "message", T: () => DoubleValue },
            { no: 5, name: "fixed_delay", kind: "message", T: () => Duration },
            { no: 6, name: "delay_percentage", kind: "message", T: () => DoubleValue },
            { no: 7, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 8, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetFaultInjectionPolicyDetailsResponse
 */
export const GetFaultInjectionPolicyDetailsResponse = new GetFaultInjectionPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRetryPolicyDetailsResponse$Type extends MessageType<GetRetryPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetRetryPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "attempts", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "per_try_timeout", kind: "message", T: () => Duration },
            { no: 5, name: "request_timeout", kind: "message", T: () => Duration },
            { no: 6, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRetryPolicyDetailsResponse
 */
export const GetRetryPolicyDetailsResponse = new GetRetryPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFailoverPolicyDetailsResponse$Type extends MessageType<GetFailoverPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetFailoverPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "locality_mappings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FailoverPolicySpec_Config_LocalityMappings },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "destinations", kind: "message", T: () => PolicyDestinations }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetFailoverPolicyDetailsResponse
 */
export const GetFailoverPolicyDetailsResponse = new GetFailoverPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOutlierDetectionPolicyDetailsResponse$Type extends MessageType<GetOutlierDetectionPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetOutlierDetectionPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "consecutive_errors", kind: "message", T: () => UInt32Value },
            { no: 4, name: "consecutive_gateway_errors", kind: "message", T: () => UInt32Value },
            { no: 5, name: "interval", kind: "message", T: () => Duration },
            { no: 6, name: "base_ejection_time", kind: "message", T: () => Duration },
            { no: 7, name: "max_ejection_percent", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 9, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetOutlierDetectionPolicyDetailsResponse
 */
export const GetOutlierDetectionPolicyDetailsResponse = new GetOutlierDetectionPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCorsPolicyDetailsResponse$Type extends MessageType<GetCorsPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetCorsPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "allowed_origins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StringMatch },
            { no: 4, name: "allowed_methods", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "allowed_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "max_age", kind: "message", T: () => Duration },
            { no: 7, name: "allow_credentials", kind: "message", T: () => BoolValue },
            { no: 8, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 9, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetCorsPolicyDetailsResponse
 */
export const GetCorsPolicyDetailsResponse = new GetCorsPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCsrfPolicyDetailsResponse$Type extends MessageType<GetCsrfPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "mode", kind: "enum", T: () => ["rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse.CsrfMode", GetCsrfPolicyDetailsResponse_CsrfMode] },
            { no: 4, name: "additional_origins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StringMatch },
            { no: 5, name: "percentage", kind: "message", T: () => DoubleValue },
            { no: 6, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetCsrfPolicyDetailsResponse
 */
export const GetCsrfPolicyDetailsResponse = new GetCsrfPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMirrorPolicyDetailsResponse$Type extends MessageType<GetMirrorPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetMirrorPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "percentage", kind: "message", T: () => DoubleValue },
            { no: 4, name: "mirror_destination", kind: "message", T: () => DestinationReference },
            { no: 5, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetMirrorPolicyDetailsResponse
 */
export const GetMirrorPolicyDetailsResponse = new GetMirrorPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetExtAuthPolicyDetailsResponse$Type extends MessageType<GetExtAuthPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "auth_used", kind: "enum", T: () => ["rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthUsed", GetExtAuthPolicyDetailsResponse_AuthUsed] },
            { no: 3, name: "auth_checks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetExtAuthPolicyDetailsResponse_AuthCheck },
            { no: 4, name: "server_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 5, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 6, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse
 */
export const GetExtAuthPolicyDetailsResponse = new GetExtAuthPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetExtAuthPolicyDetailsResponse_AuthCheck$Type extends MessageType<GetExtAuthPolicyDetailsResponse_AuthCheck> {
    constructor() {
        super("rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthCheck", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthType", GetExtAuthPolicyDetailsResponse_AuthType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetExtAuthPolicyDetailsResponse.AuthCheck
 */
export const GetExtAuthPolicyDetailsResponse_AuthCheck = new GetExtAuthPolicyDetailsResponse_AuthCheck$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRateLimitPolicyDetailsResponse$Type extends MessageType<GetRateLimitPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetRateLimitPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "server_settings_destination_server", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 4, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRateLimitPolicyDetailsResponse
 */
export const GetRateLimitPolicyDetailsResponse = new GetRateLimitPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWafPolicyDetailsResponse$Type extends MessageType<GetWafPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetWafPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "enable_core_rule_set", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "request_headers_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "response_headers_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "action", kind: "enum", T: () => ["rpc.gloo.solo.io.GetWafPolicyDetailsResponse.AuditLogAction", GetWafPolicyDetailsResponse_AuditLogAction] },
            { no: 6, name: "location", kind: "enum", T: () => ["rpc.gloo.solo.io.GetWafPolicyDetailsResponse.AuditLogLocation", GetWafPolicyDetailsResponse_AuditLogLocation] },
            { no: 7, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 9, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWafPolicyDetailsResponse
 */
export const GetWafPolicyDetailsResponse = new GetWafPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDlpPolicyDetailsResponse$Type extends MessageType<GetDlpPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetDlpPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DlpAction },
            { no: 3, name: "sanitize", kind: "enum", T: () => ["rpc.gloo.solo.io.GetDlpPolicyDetailsResponse.Sanitize", GetDlpPolicyDetailsResponse_Sanitize] },
            { no: 4, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetDlpPolicyDetailsResponse
 */
export const GetDlpPolicyDetailsResponse = new GetDlpPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHeaderManipulationPolicyDetailsResponse$Type extends MessageType<GetHeaderManipulationPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetHeaderManipulationPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "remove_response_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "append_response_headers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "remove_request_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "append_request_headers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetHeaderManipulationPolicyDetailsResponse
 */
export const GetHeaderManipulationPolicyDetailsResponse = new GetHeaderManipulationPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetJwtPolicyDetailsResponse$Type extends MessageType<GetJwtPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetJwtPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "phase", kind: "enum", T: () => ["rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Phase", GetJwtPolicyDetailsResponse_Phase] },
            { no: 3, name: "priority", kind: "message", T: () => Int32Value },
            { no: 4, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetJwtPolicyDetailsResponse_Provider },
            { no: 5, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetJwtPolicyDetailsResponse
 */
export const GetJwtPolicyDetailsResponse = new GetJwtPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetJwtPolicyDetailsResponse_Provider$Type extends MessageType<GetJwtPolicyDetailsResponse_Provider> {
    constructor() {
        super("rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "issuer", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "audiences", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "jwks_source", kind: "enum", T: () => ["rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider.Jwks", GetJwtPolicyDetailsResponse_Provider_Jwks] },
            { no: 5, name: "claims_to_headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JWTPolicySpec_Config_Provider_ClaimsToHeader }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetJwtPolicyDetailsResponse.Provider
 */
export const GetJwtPolicyDetailsResponse_Provider = new GetJwtPolicyDetailsResponse_Provider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccessLogPolicyDetailsResponse$Type extends MessageType<GetAccessLogPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetAccessLogPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "filters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccessLogPolicySpec_Config_Filter },
            { no: 3, name: "request_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "response_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "response_trailers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "filter_state_objects", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "workloads", kind: "message", T: () => PolicyWorkloads },
            { no: 8, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAccessLogPolicyDetailsResponse
 */
export const GetAccessLogPolicyDetailsResponse = new GetAccessLogPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransformationPolicyDetailsResponse$Type extends MessageType<GetTransformationPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 3, name: "request_transformation", kind: "message", T: () => TransformationPolicySpec_Config_RequestTransformation },
            { no: 4, name: "response_transformation", kind: "message", T: () => TransformationPolicySpec_Config_ResponseTransformation },
            { no: 5, name: "phase", kind: "enum", T: () => ["rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse.Phase", GetTransformationPolicyDetailsResponse_Phase] },
            { no: 6, name: "timing_priority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetTransformationPolicyDetailsResponse
 */
export const GetTransformationPolicyDetailsResponse = new GetTransformationPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWasmDeploymentPolicyDetailsResponse$Type extends MessageType<GetWasmDeploymentPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "filters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetWasmDeploymentPolicyDetailsResponse_WasmFilter },
            { no: 3, name: "weight", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "workloads", kind: "message", T: () => PolicyWorkloads },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse
 */
export const GetWasmDeploymentPolicyDetailsResponse = new GetWasmDeploymentPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWasmDeploymentPolicyDetailsResponse_WasmFilter$Type extends MessageType<GetWasmDeploymentPolicyDetailsResponse_WasmFilter> {
    constructor() {
        super("rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter", [
            { no: 1, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_type", kind: "enum", T: () => ["rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter.SourceType", GetWasmDeploymentPolicyDetailsResponse_WasmFilter_SourceType] },
            { no: 3, name: "root_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "vm_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filter_patch_context", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "insert_before_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "filter_config", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetWasmDeploymentPolicyDetailsResponse.WasmFilter
 */
export const GetWasmDeploymentPolicyDetailsResponse_WasmFilter = new GetWasmDeploymentPolicyDetailsResponse_WasmFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConnectionPolicyDetailsResponse$Type extends MessageType<GetConnectionPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "keepalive", kind: "message", T: () => TcpKeepalive },
            { no: 3, name: "http_settings", kind: "message", T: () => GetConnectionPolicyDetailsResponse_HTTPSettings },
            { no: 4, name: "tcp_max_connections", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "timeout", kind: "message", T: () => Duration },
            { no: 6, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse
 */
export const GetConnectionPolicyDetailsResponse = new GetConnectionPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConnectionPolicyDetailsResponse_HTTPSettings$Type extends MessageType<GetConnectionPolicyDetailsResponse_HTTPSettings> {
    constructor() {
        super("rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse.HTTPSettings", [
            { no: 1, name: "max_requests_per_connection", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "max_retries", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "idle_timeout", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetConnectionPolicyDetailsResponse.HTTPSettings
 */
export const GetConnectionPolicyDetailsResponse_HTTPSettings = new GetConnectionPolicyDetailsResponse_HTTPSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProxyProtocolPolicyDetailsResponse$Type extends MessageType<GetProxyProtocolPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetProxyProtocolPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "enable_proxy_protocol", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "virtual_gateway_listeners", kind: "message", T: () => PolicyVirtualGatewayListeners },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetProxyProtocolPolicyDetailsResponse
 */
export const GetProxyProtocolPolicyDetailsResponse = new GetProxyProtocolPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHttpBufferPolicyDetailsResponse$Type extends MessageType<GetHttpBufferPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetHttpBufferPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "max_request_bytes", kind: "message", T: () => UInt32Value },
            { no: 3, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetHttpBufferPolicyDetailsResponse
 */
export const GetHttpBufferPolicyDetailsResponse = new GetHttpBufferPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClientTlsPolicyDetailsResponse$Type extends MessageType<GetClientTlsPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "mode", kind: "enum", T: () => ["rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.ClientTlsMode", GetClientTlsPolicyDetailsResponse_ClientTlsMode] },
            { no: 3, name: "tls_config", kind: "message", T: () => GetClientTlsPolicyDetailsResponse_TlsConfig },
            { no: 4, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse
 */
export const GetClientTlsPolicyDetailsResponse = new GetClientTlsPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClientTlsPolicyDetailsResponse_TlsConfig$Type extends MessageType<GetClientTlsPolicyDetailsResponse_TlsConfig> {
    constructor() {
        super("rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.TlsConfig", [
            { no: 1, name: "sni", kind: "message", T: () => StringValue },
            { no: 2, name: "credentialName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClientTlsPolicyDetailsResponse.TlsConfig
 */
export const GetClientTlsPolicyDetailsResponse_TlsConfig = new GetClientTlsPolicyDetailsResponse_TlsConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveHealthCheckPolicyDetailsResponse$Type extends MessageType<GetActiveHealthCheckPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "virtual_gateways", kind: "message", T: () => PolicyVirtualGateways },
            { no: 3, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "http_health_check", kind: "message", oneof: "healthCheckType", T: () => Empty },
            { no: 6, name: "tcp_health_check", kind: "message", oneof: "healthCheckType", T: () => Empty },
            { no: 7, name: "grpc_health_check", kind: "message", oneof: "healthCheckType", T: () => Empty },
            { no: 8, name: "custom_health_check", kind: "message", oneof: "healthCheckType", T: () => Empty }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetActiveHealthCheckPolicyDetailsResponse
 */
export const GetActiveHealthCheckPolicyDetailsResponse = new GetActiveHealthCheckPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrimProxyConfigPolicyDetailsResponse$Type extends MessageType<GetTrimProxyConfigPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetTrimProxyConfigPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "included_hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "workloads", kind: "message", T: () => PolicyWorkloads },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetTrimProxyConfigPolicyDetailsResponse
 */
export const GetTrimProxyConfigPolicyDetailsResponse = new GetTrimProxyConfigPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLoadBalancerPolicyDetailsResponse$Type extends MessageType<GetLoadBalancerPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "simple", kind: "message", oneof: "lbPolicyData", T: () => GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer },
            { no: 3, name: "consistent_hash", kind: "message", oneof: "lbPolicyData", T: () => LoadBalancerPolicySpec_Config_ConsistentHashLB },
            { no: 4, name: "warmup", kind: "message", T: () => Duration },
            { no: 5, name: "healthy_panic_threshold", kind: "message", T: () => DoubleValue },
            { no: 6, name: "update_merge_window", kind: "message", T: () => Duration },
            { no: 7, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 8, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse
 */
export const GetLoadBalancerPolicyDetailsResponse = new GetLoadBalancerPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer$Type extends MessageType<GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer> {
    constructor() {
        super("rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse.SimpleLoadBalancer", [
            { no: 1, name: "type", kind: "enum", T: () => ["trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.SimpleLB", LoadBalancerPolicySpec_Config_SimpleLB] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetLoadBalancerPolicyDetailsResponse.SimpleLoadBalancer
 */
export const GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer = new GetLoadBalancerPolicyDetailsResponse_SimpleLoadBalancer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetListenerConnectionPolicyDetailsResponse$Type extends MessageType<GetListenerConnectionPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetListenerConnectionPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "per_connection_buffer_limit_bytes", kind: "message", T: () => UInt32Value },
            { no: 3, name: "virtual_gateway_listeners", kind: "message", T: () => PolicyVirtualGatewayListeners },
            { no: 4, name: "tcp_keepalive", kind: "message", T: () => TcpKeepalive },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetListenerConnectionPolicyDetailsResponse
 */
export const GetListenerConnectionPolicyDetailsResponse = new GetListenerConnectionPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphQLPersistedQueryCachePolicyDetailsResponse$Type extends MessageType<GetGraphQLPersistedQueryCachePolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphQLPersistedQueryCachePolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "cache_size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphQLPersistedQueryCachePolicyDetailsResponse
 */
export const GetGraphQLPersistedQueryCachePolicyDetailsResponse = new GetGraphQLPersistedQueryCachePolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphQLAllowedQueryPolicyDetailsResponse$Type extends MessageType<GetGraphQLAllowedQueryPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "allowed_query_hashes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "total_allowed_query_hashes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "routes", kind: "message", T: () => PolicyRoutes },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetGraphQLAllowedQueryPolicyDetailsResponse
 */
export const GetGraphQLAllowedQueryPolicyDetailsResponse = new GetGraphQLAllowedQueryPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdaptiveRequestConcurrencyPolicyDetailsResponse$Type extends MessageType<GetAdaptiveRequestConcurrencyPolicyDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "request_concurrency_settings", kind: "message", T: () => GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings },
            { no: 3, name: "min_rtt_calc_settings", kind: "message", T: () => AdaptiveRequestConcurrencyPolicySpec_Config_MinRoundtripTimeCalculationParams },
            { no: 4, name: "destinations", kind: "message", T: () => PolicyDestinations },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse
 */
export const GetAdaptiveRequestConcurrencyPolicyDetailsResponse = new GetAdaptiveRequestConcurrencyPolicyDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings$Type extends MessageType<GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings> {
    constructor() {
        super("rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse.RequestConcurrencySettings", [
            { no: 1, name: "sample_aggregate_percentile", kind: "message", T: () => DoubleValue },
            { no: 2, name: "max_concurrency_limit", kind: "message", T: () => UInt32Value },
            { no: 3, name: "concurrency_update_interval_millis", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "concurrency_limit_exceeded_status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetAdaptiveRequestConcurrencyPolicyDetailsResponse.RequestConcurrencySettings
 */
export const GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings = new GetAdaptiveRequestConcurrencyPolicyDetailsResponse_RequestConcurrencySettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRateLimitClientConfigRequest$Type extends MessageType<GetRateLimitClientConfigRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetRateLimitClientConfigRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRateLimitClientConfigRequest
 */
export const GetRateLimitClientConfigRequest = new GetRateLimitClientConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRateLimitClientConfigResponse$Type extends MessageType<GetRateLimitClientConfigResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetRateLimitClientConfigResponse", [
            { no: 1, name: "yaml", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRateLimitClientConfigResponse
 */
export const GetRateLimitClientConfigResponse = new GetRateLimitClientConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPolicyRoutesRequest$Type extends MessageType<GetPolicyRoutesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetPolicyRoutesRequest", [
            { no: 1, name: "policy_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "policy_type", kind: "enum", T: () => ["rpc.gloo.solo.io.PolicyType", PolicyType] },
            { no: 3, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 4, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPolicyRoutesRequest
 */
export const GetPolicyRoutesRequest = new GetPolicyRoutesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPolicyRoutesResponse$Type extends MessageType<GetPolicyRoutesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetPolicyRoutesResponse", [
            { no: 1, name: "route_table_details", kind: "message", T: () => RouteTableDetails }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPolicyRoutesResponse
 */
export const GetPolicyRoutesResponse = new GetPolicyRoutesResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.PoliciesApi
 */
export const PoliciesApi = new ServiceType("rpc.gloo.solo.io.PoliciesApi", [
    { name: "GetAccessPolicyDetails", options: {}, I: GetAccessPolicyDetailsRequest, O: GetAccessPolicyDetailsResponse },
    { name: "GetFaultInjectionPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetFaultInjectionPolicyDetailsResponse },
    { name: "GetRetryPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetRetryPolicyDetailsResponse },
    { name: "GetFailoverPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetFailoverPolicyDetailsResponse },
    { name: "GetOutlierDetectionPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetOutlierDetectionPolicyDetailsResponse },
    { name: "GetCorsPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetCorsPolicyDetailsResponse },
    { name: "GetCsrfPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetCsrfPolicyDetailsResponse },
    { name: "GetMirrorPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetMirrorPolicyDetailsResponse },
    { name: "GetExtAuthPolicyDetails", options: {}, I: GetExtAuthPolicyDetailsRequest, O: GetExtAuthPolicyDetailsResponse },
    { name: "GetRateLimitPolicyDetails", options: {}, I: GetRateLimitPolicyDetailsRequest, O: GetRateLimitPolicyDetailsResponse },
    { name: "GetWafPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetWafPolicyDetailsResponse },
    { name: "GetDlpPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetDlpPolicyDetailsResponse },
    { name: "GetHeaderManipulationPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetHeaderManipulationPolicyDetailsResponse },
    { name: "GetJwtPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetJwtPolicyDetailsResponse },
    { name: "GetAccessLogPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetAccessLogPolicyDetailsResponse },
    { name: "GetTransformationPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetTransformationPolicyDetailsResponse },
    { name: "GetWasmDeploymentPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetWasmDeploymentPolicyDetailsResponse },
    { name: "GetConnectionPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetConnectionPolicyDetailsResponse },
    { name: "GetProxyProtocolPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetProxyProtocolPolicyDetailsResponse },
    { name: "GetHttpBufferPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetHttpBufferPolicyDetailsResponse },
    { name: "GetClientTlsPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetClientTlsPolicyDetailsResponse },
    { name: "GetActiveHealthCheckPolicyDetails", options: {}, I: GetActiveHealthCheckPolicyDetailsRequest, O: GetActiveHealthCheckPolicyDetailsResponse },
    { name: "GetTrimProxyConfigPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetTrimProxyConfigPolicyDetailsResponse },
    { name: "GetLoadBalancerPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetLoadBalancerPolicyDetailsResponse },
    { name: "GetListenerConnectionPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetListenerConnectionPolicyDetailsResponse },
    { name: "GetGraphQLPersistedQueryCachePolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetGraphQLPersistedQueryCachePolicyDetailsResponse },
    { name: "GetGraphQLAllowedQueryPolicyDetails", options: {}, I: GetGraphQLAllowedQueryPolicyDetailsRequest, O: GetGraphQLAllowedQueryPolicyDetailsResponse },
    { name: "GetAdaptiveRequestConcurrencyPolicyDetails", options: {}, I: GetPolicyDetailsRequest, O: GetAdaptiveRequestConcurrencyPolicyDetailsResponse },
    { name: "GetRateLimitClientConfig", options: {}, I: GetRateLimitClientConfigRequest, O: GetRateLimitClientConfigResponse },
    { name: "GetPolicyRoutes", options: {}, I: GetPolicyRoutesRequest, O: GetPolicyRoutesResponse }
]);
