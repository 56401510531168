import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace StatMiniCardStyles {
  export const List = styled.div`
    display: flex;
    gap: 20px;

    & + & {
      margin-top: 20px;
    }
  `;
  interface ItemProps {
    hasRight?: boolean;
  }
  export const Item = styled.div<ItemProps>(
    ({ theme, hasRight }) => css`
      flex: 1;
      display: grid;
      grid-template-columns: auto 1fr ${hasRight ? 'auto' : ''};
      grid-template-rows: auto 1fr;
      gap: 4px 10px;
      padding: 12px;
      font-size: 14px;
      border: 1px solid ${theme.aprilGrey};
      border-radius: 3px;
    `
  );

  export const Icon = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: baseline;
      grid-row: span 2;
      ${svgBasics({ width: 30, height: 'auto' }, theme.septemberGrey)}
    `
  );

  export const RightData = styled.div`
    display: flex;
    align-items: baseline;
    grid-row: span 2;

    > div {
      display: flex;
      align-items: center;
      height: 40px;
    }
  `;

  export const Title = styled.strong``;
}
