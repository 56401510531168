import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { ScalarTypeDefinitionNode } from 'graphql';
import { useMemo } from 'react';
import { getDescriptionColumnValue, GqlSchemaTabTableProps } from 'utils/graphql-schema-search-helpers';
import { renderDefinitionNameLink, RenderDefinitionNameLinkProps } from '../../../GqlSoloTableCells';

type TableFields = {
  key: string;
  nameLink: RenderDefinitionNameLinkProps;
  description: string;
};
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Name',
    dataIndex: 'nameLink',
    render: renderDefinitionNameLink
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: renderReactMarkdown
  }
];

const GqlScalarTabTable = ({
  onTypeClick,
  definitions,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<ScalarTypeDefinitionNode>) => {
  //
  // Pagination, Table Data
  //
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(definitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(d => ({
      key: d.name.value,
      nameLink: { name: d.name.value, tabHeader, onTypeClick },
      description: getDescriptionColumnValue(d)
    }));
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  //  Render
  //
  if (hidden) return null;
  return (
    <SoloTable
      removeHorizontalPadding
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='gql-scalars-table'
    />
  );
};

export default GqlScalarTabTable;
