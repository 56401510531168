import { EmptyAsterisk } from 'Components/Common/EmptyAsterisk';
import { HttpMethodBubble } from 'Components/Common/HttpMethodBubble';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { HTTPRequestMatcher } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/http_matchers_pb';
import { StringMatchType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { MonospaceText } from 'Styles/CommonEmotions/text';
import { convertToPolicyStringMatch } from 'utils/helpers';
import { stringMatchTypeMap } from 'utils/types';
import { RoutesListDisplayStyles as Styles } from '../../RoutesListDisplay.style';

export const HttpRequestMatcherGridRow = ({ matcher }: { matcher: HTTPRequestMatcher }) => {
  const uri = convertToPolicyStringMatch(matcher.uri);
  return (
    <>
      <strong>
        URI:
        {uri.type === StringMatchType.UNKNOWN_MATCH_TYPE ? (
          <EmptyAsterisk />
        ) : (
          ` ${stringMatchTypeMap[uri.type].name} : ${uri.matcher}`
        )}
      </strong>
      <Styles.RouteHorizontalList>
        {matcher.name && <Styles.MatchersItem>Name: {matcher.name}</Styles.MatchersItem>}
        {!!matcher.headers && (
          <Styles.MatchersItem>
            Headers:{' '}
            {!!matcher.headers.length ? (
              <SecondaryInfo
                items={matcher.headers.map(h => ({
                  label: h.name,
                  data: h.regex ? <MonospaceText>{h.value}</MonospaceText> : h.value
                }))}
                overflowAfter={1}
                small
              />
            ) : (
              <Styles.RouteItemsNone>None</Styles.RouteItemsNone>
            )}
          </Styles.MatchersItem>
        )}
        {matcher.queryParameters && (
          <Styles.MatchersItem>
            Query Params:{' '}
            {!!matcher.queryParameters.length ? (
              <SecondaryInfo
                items={matcher.queryParameters.map(p => ({
                  label: p.name,
                  data: p.regex ? <MonospaceText>{p.value}</MonospaceText> : p.value
                }))}
                overflowAfter={1}
                small
              />
            ) : (
              <Styles.RouteItemsNone>None</Styles.RouteItemsNone>
            )}
          </Styles.MatchersItem>
        )}
        {matcher.method && (
          <Styles.MatchersItem>
            Method: <HttpMethodBubble method={matcher.method} small />
          </Styles.MatchersItem>
        )}
      </Styles.RouteHorizontalList>
    </>
  );
};
