/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/api/v2/route/route_components.proto" (package "envoy.api.v2.route", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Int64Range } from "../../../type/range_pb";
import { RequestMethod } from "../core/base_pb";
import { CustomTag } from "../../../type/tracing/v2/custom_tag_pb";
import { DataSource } from "../core/base_pb";
import { FractionalPercent } from "../../../type/percent_pb";
import { RoutingPriority } from "../core/base_pb";
import { Duration } from "../../../../google/protobuf/duration_pb";
import { RegexMatchAndSubstitute } from "../../../type/matcher/regex_pb";
import { StringMatcher } from "../../../type/matcher/string_pb";
import { RuntimeFractionalPercent } from "../core/base_pb";
import { BoolValue } from "../../../../google/protobuf/wrappers_pb";
import { RegexMatcher } from "../../../type/matcher/regex_pb";
import { Metadata } from "../core/base_pb";
import { UInt32Value } from "../../../../google/protobuf/wrappers_pb";
import { Any } from "../../../../google/protobuf/any_pb";
import { Struct } from "../../../../google/protobuf/struct_pb";
import { HeaderValueOption } from "../core/base_pb";
// [#protodoc-title: HTTP route components]
// * Routing :ref:`architecture overview <arch_overview_http_routing>`
// * HTTP :ref:`router filter <config_http_filters_router>`

/**
 * The top level element in the routing configuration is a virtual host. Each virtual host has
 * a logical name as well as a set of domains that get routed to it based on the incoming request's
 * host header. This allows a single listener to service multiple top level domain path trees. Once
 * a virtual host is selected based on the domain, the routes are processed in order to see which
 * upstream cluster to route to or whether to perform a redirect.
 * [#next-free-field: 21]
 *
 * @generated from protobuf message envoy.api.v2.route.VirtualHost
 */
export interface VirtualHost {
    /**
     * The logical name of the virtual host. This is used when emitting certain
     * statistics but is not relevant for routing.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * A list of domains (host/authority header) that will be matched to this
     * virtual host. Wildcard hosts are supported in the suffix or prefix form.
     *
     * Domain search order:
     *  1. Exact domain names: ``www.foo.com``.
     *  2. Suffix domain wildcards: ``*.foo.com`` or ``*-bar.foo.com``.
     *  3. Prefix domain wildcards: ``foo.*`` or ``foo-*``.
     *  4. Special wildcard ``*`` matching any domain.
     *
     * .. note::
     *
     *   The wildcard will not match the empty string.
     *   e.g. ``*-bar.foo.com`` will match ``baz-bar.foo.com`` but not ``-bar.foo.com``.
     *   The longest wildcards match first.
     *   Only a single virtual host in the entire route configuration can match on ``*``. A domain
     *   must be unique across all virtual hosts or the config will fail to load.
     *
     * Domains cannot contain control characters. This is validated by the well_known_regex HTTP_HEADER_VALUE.
     *
     * @generated from protobuf field: repeated string domains = 2;
     */
    domains: string[];
    /**
     * The list of routes that will be matched, in order, for incoming requests.
     * The first route that matches will be used.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.Route routes = 3;
     */
    routes: Route[];
    /**
     * Specifies the type of TLS enforcement the virtual host expects. If this option is not
     * specified, there is no TLS requirement for the virtual host.
     *
     * @generated from protobuf field: envoy.api.v2.route.VirtualHost.TlsRequirementType require_tls = 4;
     */
    requireTls: VirtualHost_TlsRequirementType;
    /**
     * A list of virtual clusters defined for this virtual host. Virtual clusters
     * are used for additional statistics gathering.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.VirtualCluster virtual_clusters = 5;
     */
    virtualClusters: VirtualCluster[];
    /**
     * Specifies a set of rate limit configurations that will be applied to the
     * virtual host.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.RateLimit rate_limits = 6;
     */
    rateLimits: RateLimit[];
    /**
     * Specifies a list of HTTP headers that should be added to each request
     * handled by this virtual host. Headers specified at this level are applied
     * after headers from enclosed :ref:`envoy_api_msg_route.Route` and before headers from the
     * enclosing :ref:`envoy_api_msg_RouteConfiguration`. For more information, including
     * details on header value syntax, see the documentation on :ref:`custom request headers
     * <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValueOption request_headers_to_add = 7;
     */
    requestHeadersToAdd: HeaderValueOption[];
    /**
     * Specifies a list of HTTP headers that should be removed from each request
     * handled by this virtual host.
     *
     * @generated from protobuf field: repeated string request_headers_to_remove = 13;
     */
    requestHeadersToRemove: string[];
    /**
     * Specifies a list of HTTP headers that should be added to each response
     * handled by this virtual host. Headers specified at this level are applied
     * after headers from enclosed :ref:`envoy_api_msg_route.Route` and before headers from the
     * enclosing :ref:`envoy_api_msg_RouteConfiguration`. For more information, including
     * details on header value syntax, see the documentation on :ref:`custom request headers
     * <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValueOption response_headers_to_add = 10;
     */
    responseHeadersToAdd: HeaderValueOption[];
    /**
     * Specifies a list of HTTP headers that should be removed from each response
     * handled by this virtual host.
     *
     * @generated from protobuf field: repeated string response_headers_to_remove = 11;
     */
    responseHeadersToRemove: string[];
    /**
     * Indicates that the virtual host has a CORS policy.
     *
     * @generated from protobuf field: envoy.api.v2.route.CorsPolicy cors = 8;
     */
    cors?: CorsPolicy;
    /**
     * The per_filter_config field can be used to provide virtual host-specific
     * configurations for filters. The key should match the filter name, such as
     * *envoy.filters.http.buffer* for the HTTP buffer filter. Use of this field is filter
     * specific; see the :ref:`HTTP filter documentation <config_http_filters>`
     * for if and how it is utilized.
     *
     * @deprecated
     * @generated from protobuf field: map<string, google.protobuf.Struct> per_filter_config = 12 [deprecated = true];
     */
    perFilterConfig: {
        [key: string]: Struct;
    };
    /**
     * The per_filter_config field can be used to provide virtual host-specific
     * configurations for filters. The key should match the filter name, such as
     * *envoy.filters.http.buffer* for the HTTP buffer filter. Use of this field is filter
     * specific; see the :ref:`HTTP filter documentation <config_http_filters>`
     * for if and how it is utilized.
     *
     * @generated from protobuf field: map<string, google.protobuf.Any> typed_per_filter_config = 15;
     */
    typedPerFilterConfig: {
        [key: string]: Any;
    };
    /**
     * Decides whether the :ref:`x-envoy-attempt-count
     * <config_http_filters_router_x-envoy-attempt-count>` header should be included
     * in the upstream request. Setting this option will cause it to override any existing header
     * value, so in the case of two Envoys on the request path with this option enabled, the upstream
     * will see the attempt count as perceived by the second Envoy. Defaults to false.
     * This header is unaffected by the
     * :ref:`suppress_envoy_headers
     * <envoy_api_field_config.filter.http.router.v2.Router.suppress_envoy_headers>` flag.
     *
     * [#next-major-version: rename to include_attempt_count_in_request.]
     *
     * @generated from protobuf field: bool include_request_attempt_count = 14;
     */
    includeRequestAttemptCount: boolean;
    /**
     * Decides whether the :ref:`x-envoy-attempt-count
     * <config_http_filters_router_x-envoy-attempt-count>` header should be included
     * in the downstream response. Setting this option will cause the router to override any existing header
     * value, so in the case of two Envoys on the request path with this option enabled, the downstream
     * will see the attempt count as perceived by the Envoy closest upstream from itself. Defaults to false.
     * This header is unaffected by the
     * :ref:`suppress_envoy_headers
     * <envoy_api_field_config.filter.http.router.v2.Router.suppress_envoy_headers>` flag.
     *
     * @generated from protobuf field: bool include_attempt_count_in_response = 19;
     */
    includeAttemptCountInResponse: boolean;
    /**
     * Indicates the retry policy for all routes in this virtual host. Note that setting a
     * route level entry will take precedence over this config and it'll be treated
     * independently (e.g.: values are not inherited).
     *
     * @generated from protobuf field: envoy.api.v2.route.RetryPolicy retry_policy = 16;
     */
    retryPolicy?: RetryPolicy;
    /**
     * [#not-implemented-hide:]
     * Specifies the configuration for retry policy extension. Note that setting a route level entry
     * will take precedence over this config and it'll be treated independently (e.g.: values are not
     * inherited). :ref:`Retry policy <envoy_api_field_route.VirtualHost.retry_policy>` should not be
     * set if this field is used.
     *
     * @generated from protobuf field: google.protobuf.Any retry_policy_typed_config = 20;
     */
    retryPolicyTypedConfig?: Any;
    /**
     * Indicates the hedge policy for all routes in this virtual host. Note that setting a
     * route level entry will take precedence over this config and it'll be treated
     * independently (e.g.: values are not inherited).
     *
     * @generated from protobuf field: envoy.api.v2.route.HedgePolicy hedge_policy = 17;
     */
    hedgePolicy?: HedgePolicy;
    /**
     * The maximum bytes which will be buffered for retries and shadowing.
     * If set and a route-specific limit is not set, the bytes actually buffered will be the minimum
     * value of this and the listener per_connection_buffer_limit_bytes.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value per_request_buffer_limit_bytes = 18;
     */
    perRequestBufferLimitBytes?: UInt32Value;
}
/**
 * @generated from protobuf enum envoy.api.v2.route.VirtualHost.TlsRequirementType
 */
export enum VirtualHost_TlsRequirementType {
    /**
     * No TLS requirement for the virtual host.
     *
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * External requests must use TLS. If a request is external and it is not
     * using TLS, a 301 redirect will be sent telling the client to use HTTPS.
     *
     * @generated from protobuf enum value: EXTERNAL_ONLY = 1;
     */
    EXTERNAL_ONLY = 1,
    /**
     * All requests must use TLS. If a request is not using TLS, a 301 redirect
     * will be sent telling the client to use HTTPS.
     *
     * @generated from protobuf enum value: ALL = 2;
     */
    ALL = 2
}
/**
 * A filter-defined action type.
 *
 * @generated from protobuf message envoy.api.v2.route.FilterAction
 */
export interface FilterAction {
    /**
     * @generated from protobuf field: google.protobuf.Any action = 1;
     */
    action?: Any;
}
/**
 * A route is both a specification of how to match a request as well as an indication of what to do
 * next (e.g., redirect, forward, rewrite, etc.).
 *
 * .. attention::
 *
 *   Envoy supports routing on HTTP method via :ref:`header matching
 *   <envoy_api_msg_route.HeaderMatcher>`.
 * [#next-free-field: 18]
 *
 * @generated from protobuf message envoy.api.v2.route.Route
 */
export interface Route {
    /**
     * Name for the route.
     *
     * @generated from protobuf field: string name = 14;
     */
    name: string;
    /**
     * Route matching parameters.
     *
     * @generated from protobuf field: envoy.api.v2.route.RouteMatch match = 1;
     */
    match?: RouteMatch;
    /**
     * @generated from protobuf oneof: action
     */
    action: {
        oneofKind: "route";
        /**
         * Route request to some upstream cluster.
         *
         * @generated from protobuf field: envoy.api.v2.route.RouteAction route = 2;
         */
        route: RouteAction;
    } | {
        oneofKind: "redirect";
        /**
         * Return a redirect.
         *
         * @generated from protobuf field: envoy.api.v2.route.RedirectAction redirect = 3;
         */
        redirect: RedirectAction;
    } | {
        oneofKind: "directResponse";
        /**
         * Return an arbitrary HTTP response directly, without proxying.
         *
         * @generated from protobuf field: envoy.api.v2.route.DirectResponseAction direct_response = 7;
         */
        directResponse: DirectResponseAction;
    } | {
        oneofKind: "filterAction";
        /**
         * [#not-implemented-hide:]
         * If true, a filter will define the action (e.g., it could dynamically generate the
         * RouteAction).
         *
         * @generated from protobuf field: envoy.api.v2.route.FilterAction filter_action = 17;
         */
        filterAction: FilterAction;
    } | {
        oneofKind: undefined;
    };
    /**
     * The Metadata field can be used to provide additional information
     * about the route. It can be used for configuration, stats, and logging.
     * The metadata should go under the filter namespace that will need it.
     * For instance, if the metadata is intended for the Router filter,
     * the filter name should be specified as *envoy.filters.http.router*.
     *
     * @generated from protobuf field: envoy.api.v2.core.Metadata metadata = 4;
     */
    metadata?: Metadata;
    /**
     * Decorator for the matched route.
     *
     * @generated from protobuf field: envoy.api.v2.route.Decorator decorator = 5;
     */
    decorator?: Decorator;
    /**
     * The per_filter_config field can be used to provide route-specific
     * configurations for filters. The key should match the filter name, such as
     * *envoy.filters.http.buffer* for the HTTP buffer filter. Use of this field is filter
     * specific; see the :ref:`HTTP filter documentation <config_http_filters>` for
     * if and how it is utilized.
     *
     * @deprecated
     * @generated from protobuf field: map<string, google.protobuf.Struct> per_filter_config = 8 [deprecated = true];
     */
    perFilterConfig: {
        [key: string]: Struct;
    };
    /**
     * The typed_per_filter_config field can be used to provide route-specific
     * configurations for filters. The key should match the filter name, such as
     * *envoy.filters.http.buffer* for the HTTP buffer filter. Use of this field is filter
     * specific; see the :ref:`HTTP filter documentation <config_http_filters>` for
     * if and how it is utilized.
     *
     * @generated from protobuf field: map<string, google.protobuf.Any> typed_per_filter_config = 13;
     */
    typedPerFilterConfig: {
        [key: string]: Any;
    };
    /**
     * Specifies a set of headers that will be added to requests matching this
     * route. Headers specified at this level are applied before headers from the
     * enclosing :ref:`envoy_api_msg_route.VirtualHost` and
     * :ref:`envoy_api_msg_RouteConfiguration`. For more information, including details on
     * header value syntax, see the documentation on :ref:`custom request headers
     * <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValueOption request_headers_to_add = 9;
     */
    requestHeadersToAdd: HeaderValueOption[];
    /**
     * Specifies a list of HTTP headers that should be removed from each request
     * matching this route.
     *
     * @generated from protobuf field: repeated string request_headers_to_remove = 12;
     */
    requestHeadersToRemove: string[];
    /**
     * Specifies a set of headers that will be added to responses to requests
     * matching this route. Headers specified at this level are applied before
     * headers from the enclosing :ref:`envoy_api_msg_route.VirtualHost` and
     * :ref:`envoy_api_msg_RouteConfiguration`. For more information, including
     * details on header value syntax, see the documentation on
     * :ref:`custom request headers <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValueOption response_headers_to_add = 10;
     */
    responseHeadersToAdd: HeaderValueOption[];
    /**
     * Specifies a list of HTTP headers that should be removed from each response
     * to requests matching this route.
     *
     * @generated from protobuf field: repeated string response_headers_to_remove = 11;
     */
    responseHeadersToRemove: string[];
    /**
     * Presence of the object defines whether the connection manager's tracing configuration
     * is overridden by this route specific instance.
     *
     * @generated from protobuf field: envoy.api.v2.route.Tracing tracing = 15;
     */
    tracing?: Tracing;
    /**
     * The maximum bytes which will be buffered for retries and shadowing.
     * If set, the bytes actually buffered will be the minimum value of this and the
     * listener per_connection_buffer_limit_bytes.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value per_request_buffer_limit_bytes = 16;
     */
    perRequestBufferLimitBytes?: UInt32Value;
}
/**
 * Compared to the :ref:`cluster <envoy_api_field_route.RouteAction.cluster>` field that specifies a
 * single upstream cluster as the target of a request, the :ref:`weighted_clusters
 * <envoy_api_field_route.RouteAction.weighted_clusters>` option allows for specification of
 * multiple upstream clusters along with weights that indicate the percentage of
 * traffic to be forwarded to each cluster. The router selects an upstream cluster based on the
 * weights.
 *
 * @generated from protobuf message envoy.api.v2.route.WeightedCluster
 */
export interface WeightedCluster {
    /**
     * Specifies one or more upstream clusters associated with the route.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.WeightedCluster.ClusterWeight clusters = 1;
     */
    clusters: WeightedCluster_ClusterWeight[];
    /**
     * Specifies the total weight across all clusters. The sum of all cluster weights must equal this
     * value, which must be greater than 0. Defaults to 100.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value total_weight = 3;
     */
    totalWeight?: UInt32Value;
    /**
     * Specifies the runtime key prefix that should be used to construct the
     * runtime keys associated with each cluster. When the *runtime_key_prefix* is
     * specified, the router will look for weights associated with each upstream
     * cluster under the key *runtime_key_prefix* + "." + *cluster[i].name* where
     * *cluster[i]* denotes an entry in the clusters array field. If the runtime
     * key for the cluster does not exist, the value specified in the
     * configuration file will be used as the default weight. See the :ref:`runtime documentation
     * <operations_runtime>` for how key names map to the underlying implementation.
     *
     * @generated from protobuf field: string runtime_key_prefix = 2;
     */
    runtimeKeyPrefix: string;
}
/**
 * [#next-free-field: 11]
 *
 * @generated from protobuf message envoy.api.v2.route.WeightedCluster.ClusterWeight
 */
export interface WeightedCluster_ClusterWeight {
    /**
     * Name of the upstream cluster. The cluster must exist in the
     * :ref:`cluster manager configuration <config_cluster_manager>`.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * An integer between 0 and :ref:`total_weight
     * <envoy_api_field_route.WeightedCluster.total_weight>`. When a request matches the route,
     * the choice of an upstream cluster is determined by its weight. The sum of weights across all
     * entries in the clusters array must add up to the total_weight, which defaults to 100.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value weight = 2;
     */
    weight?: UInt32Value;
    /**
     * Optional endpoint metadata match criteria used by the subset load balancer. Only endpoints in
     * the upstream cluster with metadata matching what is set in this field will be considered for
     * load balancing. Note that this will be merged with what's provided in
     * :ref:`RouteAction.metadata_match <envoy_api_field_route.RouteAction.metadata_match>`, with
     * values here taking precedence. The filter name should be specified as *envoy.lb*.
     *
     * @generated from protobuf field: envoy.api.v2.core.Metadata metadata_match = 3;
     */
    metadataMatch?: Metadata;
    /**
     * Specifies a list of headers to be added to requests when this cluster is selected
     * through the enclosing :ref:`envoy_api_msg_route.RouteAction`.
     * Headers specified at this level are applied before headers from the enclosing
     * :ref:`envoy_api_msg_route.Route`, :ref:`envoy_api_msg_route.VirtualHost`, and
     * :ref:`envoy_api_msg_RouteConfiguration`. For more information, including details on
     * header value syntax, see the documentation on :ref:`custom request headers
     * <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValueOption request_headers_to_add = 4;
     */
    requestHeadersToAdd: HeaderValueOption[];
    /**
     * Specifies a list of HTTP headers that should be removed from each request when
     * this cluster is selected through the enclosing :ref:`envoy_api_msg_route.RouteAction`.
     *
     * @generated from protobuf field: repeated string request_headers_to_remove = 9;
     */
    requestHeadersToRemove: string[];
    /**
     * Specifies a list of headers to be added to responses when this cluster is selected
     * through the enclosing :ref:`envoy_api_msg_route.RouteAction`.
     * Headers specified at this level are applied before headers from the enclosing
     * :ref:`envoy_api_msg_route.Route`, :ref:`envoy_api_msg_route.VirtualHost`, and
     * :ref:`envoy_api_msg_RouteConfiguration`. For more information, including details on
     * header value syntax, see the documentation on :ref:`custom request headers
     * <config_http_conn_man_headers_custom_request_headers>`.
     *
     * @generated from protobuf field: repeated envoy.api.v2.core.HeaderValueOption response_headers_to_add = 5;
     */
    responseHeadersToAdd: HeaderValueOption[];
    /**
     * Specifies a list of headers to be removed from responses when this cluster is selected
     * through the enclosing :ref:`envoy_api_msg_route.RouteAction`.
     *
     * @generated from protobuf field: repeated string response_headers_to_remove = 6;
     */
    responseHeadersToRemove: string[];
    /**
     * The per_filter_config field can be used to provide weighted cluster-specific
     * configurations for filters. The key should match the filter name, such as
     * *envoy.filters.http.buffer* for the HTTP buffer filter. Use of this field is filter
     * specific; see the :ref:`HTTP filter documentation <config_http_filters>`
     * for if and how it is utilized.
     *
     * @deprecated
     * @generated from protobuf field: map<string, google.protobuf.Struct> per_filter_config = 8 [deprecated = true];
     */
    perFilterConfig: {
        [key: string]: Struct;
    };
    /**
     * The per_filter_config field can be used to provide weighted cluster-specific
     * configurations for filters. The key should match the filter name, such as
     * *envoy.filters.http.buffer* for the HTTP buffer filter. Use of this field is filter
     * specific; see the :ref:`HTTP filter documentation <config_http_filters>`
     * for if and how it is utilized.
     *
     * @generated from protobuf field: map<string, google.protobuf.Any> typed_per_filter_config = 10;
     */
    typedPerFilterConfig: {
        [key: string]: Any;
    };
}
/**
 * [#next-free-field: 12]
 *
 * @generated from protobuf message envoy.api.v2.route.RouteMatch
 */
export interface RouteMatch {
    /**
     * @generated from protobuf oneof: path_specifier
     */
    pathSpecifier: {
        oneofKind: "prefix";
        /**
         * If specified, the route is a prefix rule meaning that the prefix must
         * match the beginning of the *:path* header.
         *
         * @generated from protobuf field: string prefix = 1;
         */
        prefix: string;
    } | {
        oneofKind: "path";
        /**
         * If specified, the route is an exact path rule meaning that the path must
         * exactly match the *:path* header once the query string is removed.
         *
         * @generated from protobuf field: string path = 2;
         */
        path: string;
    } | {
        oneofKind: "regex";
        /**
         * If specified, the route is a regular expression rule meaning that the
         * regex must match the *:path* header once the query string is removed. The entire path
         * (without the query string) must match the regex. The rule will not match if only a
         * subsequence of the *:path* header matches the regex. The regex grammar is defined `here
         * <https://en.cppreference.com/w/cpp/regex/ecmascript>`_.
         *
         * Examples:
         *
         * * The regex ``/b[io]t`` matches the path *\/bit*
         * * The regex ``/b[io]t`` matches the path *\/bot*
         * * The regex ``/b[io]t`` does not match the path *\/bite*
         * * The regex ``/b[io]t`` does not match the path *\/bit/bot*
         *
         * .. attention::
         *   This field has been deprecated in favor of `safe_regex` as it is not safe for use with
         *   untrusted input in all cases.
         *
         * @deprecated
         * @generated from protobuf field: string regex = 3 [deprecated = true];
         */
        regex: string;
    } | {
        oneofKind: "safeRegex";
        /**
         * If specified, the route is a regular expression rule meaning that the
         * regex must match the *:path* header once the query string is removed. The entire path
         * (without the query string) must match the regex. The rule will not match if only a
         * subsequence of the *:path* header matches the regex.
         *
         * [#next-major-version: In the v3 API we should redo how path specification works such
         * that we utilize StringMatcher, and additionally have consistent options around whether we
         * strip query strings, do a case sensitive match, etc. In the interim it will be too disruptive
         * to deprecate the existing options. We should even consider whether we want to do away with
         * path_specifier entirely and just rely on a set of header matchers which can already match
         * on :path, etc. The issue with that is it is unclear how to generically deal with query string
         * stripping. This needs more thought.]
         *
         * @generated from protobuf field: envoy.type.matcher.RegexMatcher safe_regex = 10;
         */
        safeRegex: RegexMatcher;
    } | {
        oneofKind: undefined;
    };
    /**
     * Indicates that prefix/path matching should be case sensitive. The default
     * is true.
     *
     * @generated from protobuf field: google.protobuf.BoolValue case_sensitive = 4;
     */
    caseSensitive?: BoolValue;
    /**
     * Indicates that the route should additionally match on a runtime key. Every time the route
     * is considered for a match, it must also fall under the percentage of matches indicated by
     * this field. For some fraction N/D, a random number in the range [0,D) is selected. If the
     * number is <= the value of the numerator N, or if the key is not present, the default
     * value, the router continues to evaluate the remaining match criteria. A runtime_fraction
     * route configuration can be used to roll out route changes in a gradual manner without full
     * code/config deploys. Refer to the :ref:`traffic shifting
     * <config_http_conn_man_route_table_traffic_splitting_shift>` docs for additional documentation.
     *
     * .. note::
     *
     *    Parsing this field is implemented such that the runtime key's data may be represented
     *    as a FractionalPercent proto represented as JSON/YAML and may also be represented as an
     *    integer with the assumption that the value is an integral percentage out of 100. For
     *    instance, a runtime key lookup returning the value "42" would parse as a FractionalPercent
     *    whose numerator is 42 and denominator is HUNDRED. This preserves legacy semantics.
     *
     * @generated from protobuf field: envoy.api.v2.core.RuntimeFractionalPercent runtime_fraction = 9;
     */
    runtimeFraction?: RuntimeFractionalPercent;
    /**
     * Specifies a set of headers that the route should match on. The router will
     * check the request’s headers against all the specified headers in the route
     * config. A match will happen if all the headers in the route are present in
     * the request with the same values (or based on presence if the value field
     * is not in the config).
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.HeaderMatcher headers = 6;
     */
    headers: HeaderMatcher[];
    /**
     * Specifies a set of URL query parameters on which the route should
     * match. The router will check the query string from the *path* header
     * against all the specified query parameters. If the number of specified
     * query parameters is nonzero, they all must match the *path* header's
     * query string for a match to occur.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.QueryParameterMatcher query_parameters = 7;
     */
    queryParameters: QueryParameterMatcher[];
    /**
     * If specified, only gRPC requests will be matched. The router will check
     * that the content-type header has a application/grpc or one of the various
     * application/grpc+ values.
     *
     * @generated from protobuf field: envoy.api.v2.route.RouteMatch.GrpcRouteMatchOptions grpc = 8;
     */
    grpc?: RouteMatch_GrpcRouteMatchOptions;
    /**
     * If specified, the client tls context will be matched against the defined
     * match options.
     *
     * [#next-major-version: unify with RBAC]
     *
     * @generated from protobuf field: envoy.api.v2.route.RouteMatch.TlsContextMatchOptions tls_context = 11;
     */
    tlsContext?: RouteMatch_TlsContextMatchOptions;
}
/**
 * @generated from protobuf message envoy.api.v2.route.RouteMatch.GrpcRouteMatchOptions
 */
export interface RouteMatch_GrpcRouteMatchOptions {
}
/**
 * @generated from protobuf message envoy.api.v2.route.RouteMatch.TlsContextMatchOptions
 */
export interface RouteMatch_TlsContextMatchOptions {
    /**
     * If specified, the route will match against whether or not a certificate is presented.
     * If not specified, certificate presentation status (true or false) will not be considered when route matching.
     *
     * @generated from protobuf field: google.protobuf.BoolValue presented = 1;
     */
    presented?: BoolValue;
    /**
     * If specified, the route will match against whether or not a certificate is validated.
     * If not specified, certificate validation status (true or false) will not be considered when route matching.
     *
     * @generated from protobuf field: google.protobuf.BoolValue validated = 2;
     */
    validated?: BoolValue;
}
/**
 * [#next-free-field: 12]
 *
 * @generated from protobuf message envoy.api.v2.route.CorsPolicy
 */
export interface CorsPolicy {
    /**
     * Specifies the origins that will be allowed to do CORS requests.
     *
     * An origin is allowed if either allow_origin or allow_origin_regex match.
     *
     * .. attention::
     *  This field has been deprecated in favor of `allow_origin_string_match`.
     *
     * @deprecated
     * @generated from protobuf field: repeated string allow_origin = 1 [deprecated = true];
     */
    allowOrigin: string[];
    /**
     * Specifies regex patterns that match allowed origins.
     *
     * An origin is allowed if either allow_origin or allow_origin_regex match.
     *
     * .. attention::
     *   This field has been deprecated in favor of `allow_origin_string_match` as it is not safe for
     *   use with untrusted input in all cases.
     *
     * @deprecated
     * @generated from protobuf field: repeated string allow_origin_regex = 8 [deprecated = true];
     */
    allowOriginRegex: string[];
    /**
     * Specifies string patterns that match allowed origins. An origin is allowed if any of the
     * string matchers match.
     *
     * @generated from protobuf field: repeated envoy.type.matcher.StringMatcher allow_origin_string_match = 11;
     */
    allowOriginStringMatch: StringMatcher[];
    /**
     * Specifies the content for the *access-control-allow-methods* header.
     *
     * @generated from protobuf field: string allow_methods = 2;
     */
    allowMethods: string;
    /**
     * Specifies the content for the *access-control-allow-headers* header.
     *
     * @generated from protobuf field: string allow_headers = 3;
     */
    allowHeaders: string;
    /**
     * Specifies the content for the *access-control-expose-headers* header.
     *
     * @generated from protobuf field: string expose_headers = 4;
     */
    exposeHeaders: string;
    /**
     * Specifies the content for the *access-control-max-age* header.
     *
     * @generated from protobuf field: string max_age = 5;
     */
    maxAge: string;
    /**
     * Specifies whether the resource allows credentials.
     *
     * @generated from protobuf field: google.protobuf.BoolValue allow_credentials = 6;
     */
    allowCredentials?: BoolValue;
    /**
     * @generated from protobuf oneof: enabled_specifier
     */
    enabledSpecifier: {
        oneofKind: "enabled";
        /**
         * Specifies if the CORS filter is enabled. Defaults to true. Only effective on route.
         *
         * .. attention::
         *
         *   **This field is deprecated**. Set the
         *   :ref:`filter_enabled<envoy_api_field_route.CorsPolicy.filter_enabled>` field instead.
         *
         * @deprecated
         * @generated from protobuf field: google.protobuf.BoolValue enabled = 7 [deprecated = true];
         */
        enabled: BoolValue;
    } | {
        oneofKind: "filterEnabled";
        /**
         * Specifies the % of requests for which the CORS filter is enabled.
         *
         * If neither ``enabled``, ``filter_enabled``, nor ``shadow_enabled`` are specified, the CORS
         * filter will be enabled for 100% of the requests.
         *
         * If :ref:`runtime_key <envoy_api_field_core.RuntimeFractionalPercent.runtime_key>` is
         * specified, Envoy will lookup the runtime key to get the percentage of requests to filter.
         *
         * @generated from protobuf field: envoy.api.v2.core.RuntimeFractionalPercent filter_enabled = 9;
         */
        filterEnabled: RuntimeFractionalPercent;
    } | {
        oneofKind: undefined;
    };
    /**
     * Specifies the % of requests for which the CORS policies will be evaluated and tracked, but not
     * enforced.
     *
     * This field is intended to be used when ``filter_enabled`` and ``enabled`` are off. One of those
     * fields have to explicitly disable the filter in order for this setting to take effect.
     *
     * If :ref:`runtime_key <envoy_api_field_core.RuntimeFractionalPercent.runtime_key>` is specified,
     * Envoy will lookup the runtime key to get the percentage of requests for which it will evaluate
     * and track the request's *Origin* to determine if it's valid but will not enforce any policies.
     *
     * @generated from protobuf field: envoy.api.v2.core.RuntimeFractionalPercent shadow_enabled = 10;
     */
    shadowEnabled?: RuntimeFractionalPercent;
}
/**
 * [#next-free-field: 34]
 *
 * @generated from protobuf message envoy.api.v2.route.RouteAction
 */
export interface RouteAction {
    /**
     * @generated from protobuf oneof: cluster_specifier
     */
    clusterSpecifier: {
        oneofKind: "cluster";
        /**
         * Indicates the upstream cluster to which the request should be routed
         * to.
         *
         * @generated from protobuf field: string cluster = 1;
         */
        cluster: string;
    } | {
        oneofKind: "clusterHeader";
        /**
         * Envoy will determine the cluster to route to by reading the value of the
         * HTTP header named by cluster_header from the request headers. If the
         * header is not found or the referenced cluster does not exist, Envoy will
         * return a 404 response.
         *
         * .. attention::
         *
         *   Internally, Envoy always uses the HTTP/2 *:authority* header to represent the HTTP/1
         *   *Host* header. Thus, if attempting to match on *Host*, match on *:authority* instead.
         *
         * .. note::
         *
         *   If the header appears multiple times only the first value is used.
         *
         * @generated from protobuf field: string cluster_header = 2;
         */
        clusterHeader: string;
    } | {
        oneofKind: "weightedClusters";
        /**
         * Multiple upstream clusters can be specified for a given route. The
         * request is routed to one of the upstream clusters based on weights
         * assigned to each cluster. See
         * :ref:`traffic splitting <config_http_conn_man_route_table_traffic_splitting_split>`
         * for additional documentation.
         *
         * @generated from protobuf field: envoy.api.v2.route.WeightedCluster weighted_clusters = 3;
         */
        weightedClusters: WeightedCluster;
    } | {
        oneofKind: undefined;
    };
    /**
     * The HTTP status code to use when configured cluster is not found.
     * The default response code is 503 Service Unavailable.
     *
     * @generated from protobuf field: envoy.api.v2.route.RouteAction.ClusterNotFoundResponseCode cluster_not_found_response_code = 20;
     */
    clusterNotFoundResponseCode: RouteAction_ClusterNotFoundResponseCode;
    /**
     * Optional endpoint metadata match criteria used by the subset load balancer. Only endpoints
     * in the upstream cluster with metadata matching what's set in this field will be considered
     * for load balancing. If using :ref:`weighted_clusters
     * <envoy_api_field_route.RouteAction.weighted_clusters>`, metadata will be merged, with values
     * provided there taking precedence. The filter name should be specified as *envoy.lb*.
     *
     * @generated from protobuf field: envoy.api.v2.core.Metadata metadata_match = 4;
     */
    metadataMatch?: Metadata;
    /**
     * Indicates that during forwarding, the matched prefix (or path) should be
     * swapped with this value. This option allows application URLs to be rooted
     * at a different path from those exposed at the reverse proxy layer. The router filter will
     * place the original path before rewrite into the :ref:`x-envoy-original-path
     * <config_http_filters_router_x-envoy-original-path>` header.
     *
     * Only one of *prefix_rewrite* or
     * :ref:`regex_rewrite <envoy_api_field_route.RouteAction.regex_rewrite>`
     * may be specified.
     *
     * .. attention::
     *
     *   Pay careful attention to the use of trailing slashes in the
     *   :ref:`route's match <envoy_api_field_route.Route.match>` prefix value.
     *   Stripping a prefix from a path requires multiple Routes to handle all cases. For example,
     *   rewriting *\/prefix* to *\/* and *\/prefix/etc* to *\/etc* cannot be done in a single
     *   :ref:`Route <envoy_api_msg_route.Route>`, as shown by the below config entries:
     *
     *   .. code-block:: yaml
     *
     *     - match:
     *         prefix: "/prefix/"
     *       route:
     *         prefix_rewrite: "/"
     *     - match:
     *         prefix: "/prefix"
     *       route:
     *         prefix_rewrite: "/"
     *
     *   Having above entries in the config, requests to *\/prefix* will be stripped to *\/*, while
     *   requests to *\/prefix/etc* will be stripped to *\/etc*.
     *
     * @generated from protobuf field: string prefix_rewrite = 5;
     */
    prefixRewrite: string;
    /**
     * Indicates that during forwarding, portions of the path that match the
     * pattern should be rewritten, even allowing the substitution of capture
     * groups from the pattern into the new path as specified by the rewrite
     * substitution string. This is useful to allow application paths to be
     * rewritten in a way that is aware of segments with variable content like
     * identifiers. The router filter will place the original path as it was
     * before the rewrite into the :ref:`x-envoy-original-path
     * <config_http_filters_router_x-envoy-original-path>` header.
     *
     * Only one of :ref:`prefix_rewrite <envoy_api_field_route.RouteAction.prefix_rewrite>`
     * or *regex_rewrite* may be specified.
     *
     * Examples using Google's `RE2 <https://github.com/google/re2>`_ engine:
     *
     * * The path pattern ``^/service/([^/]+)(/.*)$`` paired with a substitution
     *   string of ``\2/instance/\1`` would transform ``/service/foo/v1/api``
     *   into ``/v1/api/instance/foo``.
     *
     * * The pattern ``one`` paired with a substitution string of ``two`` would
     *   transform ``/xxx/one/yyy/one/zzz`` into ``/xxx/two/yyy/two/zzz``.
     *
     * * The pattern ``^(.*?)one(.*)$`` paired with a substitution string of
     *   ``\1two\2`` would replace only the first occurrence of ``one``,
     *   transforming path ``/xxx/one/yyy/one/zzz`` into ``/xxx/two/yyy/one/zzz``.
     *
     * * The pattern ``(?i)/xxx/`` paired with a substitution string of ``/yyy/``
     *   would do a case-insensitive match and transform path ``/aaa/XxX/bbb`` to
     *   ``/aaa/yyy/bbb``.
     *
     * @generated from protobuf field: envoy.type.matcher.RegexMatchAndSubstitute regex_rewrite = 32;
     */
    regexRewrite?: RegexMatchAndSubstitute;
    /**
     * @generated from protobuf oneof: host_rewrite_specifier
     */
    hostRewriteSpecifier: {
        oneofKind: "hostRewrite";
        /**
         * Indicates that during forwarding, the host header will be swapped with
         * this value.
         *
         * @generated from protobuf field: string host_rewrite = 6;
         */
        hostRewrite: string;
    } | {
        oneofKind: "autoHostRewrite";
        /**
         * Indicates that during forwarding, the host header will be swapped with
         * the hostname of the upstream host chosen by the cluster manager. This
         * option is applicable only when the destination cluster for a route is of
         * type *strict_dns* or *logical_dns*. Setting this to true with other cluster
         * types has no effect.
         *
         * @generated from protobuf field: google.protobuf.BoolValue auto_host_rewrite = 7;
         */
        autoHostRewrite: BoolValue;
    } | {
        oneofKind: "autoHostRewriteHeader";
        /**
         * Indicates that during forwarding, the host header will be swapped with the content of given
         * downstream or :ref:`custom <config_http_conn_man_headers_custom_request_headers>` header.
         * If header value is empty, host header is left intact.
         *
         * .. attention::
         *
         *   Pay attention to the potential security implications of using this option. Provided header
         *   must come from trusted source.
         *
         * .. note::
         *
         *   If the header appears multiple times only the first value is used.
         *
         * @generated from protobuf field: string auto_host_rewrite_header = 29;
         */
        autoHostRewriteHeader: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * Specifies the upstream timeout for the route. If not specified, the default is 15s. This
     * spans between the point at which the entire downstream request (i.e. end-of-stream) has been
     * processed and when the upstream response has been completely processed. A value of 0 will
     * disable the route's timeout.
     *
     * .. note::
     *
     *   This timeout includes all retries. See also
     *   :ref:`config_http_filters_router_x-envoy-upstream-rq-timeout-ms`,
     *   :ref:`config_http_filters_router_x-envoy-upstream-rq-per-try-timeout-ms`, and the
     *   :ref:`retry overview <arch_overview_http_routing_retry>`.
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 8;
     */
    timeout?: Duration;
    /**
     * Specifies the idle timeout for the route. If not specified, there is no per-route idle timeout,
     * although the connection manager wide :ref:`stream_idle_timeout
     * <envoy_api_field_config.filter.network.http_connection_manager.v2.HttpConnectionManager.stream_idle_timeout>`
     * will still apply. A value of 0 will completely disable the route's idle timeout, even if a
     * connection manager stream idle timeout is configured.
     *
     * The idle timeout is distinct to :ref:`timeout
     * <envoy_api_field_route.RouteAction.timeout>`, which provides an upper bound
     * on the upstream response time; :ref:`idle_timeout
     * <envoy_api_field_route.RouteAction.idle_timeout>` instead bounds the amount
     * of time the request's stream may be idle.
     *
     * After header decoding, the idle timeout will apply on downstream and
     * upstream request events. Each time an encode/decode event for headers or
     * data is processed for the stream, the timer will be reset. If the timeout
     * fires, the stream is terminated with a 408 Request Timeout error code if no
     * upstream response header has been received, otherwise a stream reset
     * occurs.
     *
     * @generated from protobuf field: google.protobuf.Duration idle_timeout = 24;
     */
    idleTimeout?: Duration;
    /**
     * Indicates that the route has a retry policy. Note that if this is set,
     * it'll take precedence over the virtual host level retry policy entirely
     * (e.g.: policies are not merged, most internal one becomes the enforced policy).
     *
     * @generated from protobuf field: envoy.api.v2.route.RetryPolicy retry_policy = 9;
     */
    retryPolicy?: RetryPolicy;
    /**
     * [#not-implemented-hide:]
     * Specifies the configuration for retry policy extension. Note that if this is set, it'll take
     * precedence over the virtual host level retry policy entirely (e.g.: policies are not merged,
     * most internal one becomes the enforced policy). :ref:`Retry policy <envoy_api_field_route.VirtualHost.retry_policy>`
     * should not be set if this field is used.
     *
     * @generated from protobuf field: google.protobuf.Any retry_policy_typed_config = 33;
     */
    retryPolicyTypedConfig?: Any;
    /**
     * Indicates that the route has a request mirroring policy.
     *
     * .. attention::
     *   This field has been deprecated in favor of `request_mirror_policies` which supports one or
     *   more mirroring policies.
     *
     * @deprecated
     * @generated from protobuf field: envoy.api.v2.route.RouteAction.RequestMirrorPolicy request_mirror_policy = 10 [deprecated = true];
     */
    requestMirrorPolicy?: RouteAction_RequestMirrorPolicy;
    /**
     * Indicates that the route has request mirroring policies.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.RouteAction.RequestMirrorPolicy request_mirror_policies = 30;
     */
    requestMirrorPolicies: RouteAction_RequestMirrorPolicy[];
    /**
     * Optionally specifies the :ref:`routing priority <arch_overview_http_routing_priority>`.
     *
     * @generated from protobuf field: envoy.api.v2.core.RoutingPriority priority = 11;
     */
    priority: RoutingPriority;
    /**
     * Specifies a set of rate limit configurations that could be applied to the
     * route.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.RateLimit rate_limits = 13;
     */
    rateLimits: RateLimit[];
    /**
     * Specifies if the rate limit filter should include the virtual host rate
     * limits. By default, if the route configured rate limits, the virtual host
     * :ref:`rate_limits <envoy_api_field_route.VirtualHost.rate_limits>` are not applied to the
     * request.
     *
     * @generated from protobuf field: google.protobuf.BoolValue include_vh_rate_limits = 14;
     */
    includeVhRateLimits?: BoolValue;
    /**
     * Specifies a list of hash policies to use for ring hash load balancing. Each
     * hash policy is evaluated individually and the combined result is used to
     * route the request. The method of combination is deterministic such that
     * identical lists of hash policies will produce the same hash. Since a hash
     * policy examines specific parts of a request, it can fail to produce a hash
     * (i.e. if the hashed header is not present). If (and only if) all configured
     * hash policies fail to generate a hash, no hash will be produced for
     * the route. In this case, the behavior is the same as if no hash policies
     * were specified (i.e. the ring hash load balancer will choose a random
     * backend). If a hash policy has the "terminal" attribute set to true, and
     * there is already a hash generated, the hash is returned immediately,
     * ignoring the rest of the hash policy list.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.RouteAction.HashPolicy hash_policy = 15;
     */
    hashPolicy: RouteAction_HashPolicy[];
    /**
     * Indicates that the route has a CORS policy.
     *
     * @generated from protobuf field: envoy.api.v2.route.CorsPolicy cors = 17;
     */
    cors?: CorsPolicy;
    /**
     * If present, and the request is a gRPC request, use the
     * `grpc-timeout header <https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-HTTP2.md>`_,
     * or its default value (infinity) instead of
     * :ref:`timeout <envoy_api_field_route.RouteAction.timeout>`, but limit the applied timeout
     * to the maximum value specified here. If configured as 0, the maximum allowed timeout for
     * gRPC requests is infinity. If not configured at all, the `grpc-timeout` header is not used
     * and gRPC requests time out like any other requests using
     * :ref:`timeout <envoy_api_field_route.RouteAction.timeout>` or its default.
     * This can be used to prevent unexpected upstream request timeouts due to potentially long
     * time gaps between gRPC request and response in gRPC streaming mode.
     *
     * .. note::
     *
     *    If a timeout is specified using :ref:`config_http_filters_router_x-envoy-upstream-rq-timeout-ms`, it takes
     *    precedence over `grpc-timeout header <https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-HTTP2.md>`_, when
     *    both are present. See also
     *    :ref:`config_http_filters_router_x-envoy-upstream-rq-timeout-ms`,
     *    :ref:`config_http_filters_router_x-envoy-upstream-rq-per-try-timeout-ms`, and the
     *    :ref:`retry overview <arch_overview_http_routing_retry>`.
     *
     * @generated from protobuf field: google.protobuf.Duration max_grpc_timeout = 23;
     */
    maxGrpcTimeout?: Duration;
    /**
     * If present, Envoy will adjust the timeout provided by the `grpc-timeout` header by subtracting
     * the provided duration from the header. This is useful in allowing Envoy to set its global
     * timeout to be less than that of the deadline imposed by the calling client, which makes it more
     * likely that Envoy will handle the timeout instead of having the call canceled by the client.
     * The offset will only be applied if the provided grpc_timeout is greater than the offset. This
     * ensures that the offset will only ever decrease the timeout and never set it to 0 (meaning
     * infinity).
     *
     * @generated from protobuf field: google.protobuf.Duration grpc_timeout_offset = 28;
     */
    grpcTimeoutOffset?: Duration;
    /**
     * @generated from protobuf field: repeated envoy.api.v2.route.RouteAction.UpgradeConfig upgrade_configs = 25;
     */
    upgradeConfigs: RouteAction_UpgradeConfig[];
    /**
     * @generated from protobuf field: envoy.api.v2.route.RouteAction.InternalRedirectAction internal_redirect_action = 26;
     */
    internalRedirectAction: RouteAction_InternalRedirectAction;
    /**
     * An internal redirect is handled, iff the number of previous internal redirects that a
     * downstream request has encountered is lower than this value, and
     * :ref:`internal_redirect_action <envoy_api_field_route.RouteAction.internal_redirect_action>`
     * is set to :ref:`HANDLE_INTERNAL_REDIRECT
     * <envoy_api_enum_value_route.RouteAction.InternalRedirectAction.HANDLE_INTERNAL_REDIRECT>`
     * In the case where a downstream request is bounced among multiple routes by internal redirect,
     * the first route that hits this threshold, or has
     * :ref:`internal_redirect_action <envoy_api_field_route.RouteAction.internal_redirect_action>`
     * set to
     * :ref:`PASS_THROUGH_INTERNAL_REDIRECT
     * <envoy_api_enum_value_route.RouteAction.InternalRedirectAction.PASS_THROUGH_INTERNAL_REDIRECT>`
     * will pass the redirect back to downstream.
     *
     * If not specified, at most one redirect will be followed.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value max_internal_redirects = 31;
     */
    maxInternalRedirects?: UInt32Value;
    /**
     * Indicates that the route has a hedge policy. Note that if this is set,
     * it'll take precedence over the virtual host level hedge policy entirely
     * (e.g.: policies are not merged, most internal one becomes the enforced policy).
     *
     * @generated from protobuf field: envoy.api.v2.route.HedgePolicy hedge_policy = 27;
     */
    hedgePolicy?: HedgePolicy;
}
/**
 * The router is capable of shadowing traffic from one cluster to another. The current
 * implementation is "fire and forget," meaning Envoy will not wait for the shadow cluster to
 * respond before returning the response from the primary cluster. All normal statistics are
 * collected for the shadow cluster making this feature useful for testing.
 *
 * During shadowing, the host/authority header is altered such that *-shadow* is appended. This is
 * useful for logging. For example, *cluster1* becomes *cluster1-shadow*.
 *
 * .. note::
 *
 *   Shadowing will not be triggered if the primary cluster does not exist.
 *
 * @generated from protobuf message envoy.api.v2.route.RouteAction.RequestMirrorPolicy
 */
export interface RouteAction_RequestMirrorPolicy {
    /**
     * Specifies the cluster that requests will be mirrored to. The cluster must
     * exist in the cluster manager configuration.
     *
     * @generated from protobuf field: string cluster = 1;
     */
    cluster: string;
    /**
     * If not specified, all requests to the target cluster will be mirrored. If
     * specified, Envoy will lookup the runtime key to get the % of requests to
     * mirror. Valid values are from 0 to 10000, allowing for increments of
     * 0.01% of requests to be mirrored. If the runtime key is specified in the
     * configuration but not present in runtime, 0 is the default and thus 0% of
     * requests will be mirrored.
     *
     * .. attention::
     *
     *   **This field is deprecated**. Set the
     *   :ref:`runtime_fraction
     *   <envoy_api_field_route.RouteAction.RequestMirrorPolicy.runtime_fraction>`
     *   field instead. Mirroring occurs if both this and
     *   <envoy_api_field_route.RouteAction.RequestMirrorPolicy.runtime_fraction>`
     *   are not set.
     *
     * @deprecated
     * @generated from protobuf field: string runtime_key = 2 [deprecated = true];
     */
    runtimeKey: string;
    /**
     * If not specified, all requests to the target cluster will be mirrored.
     *
     * If specified, this field takes precedence over the `runtime_key` field and requests must also
     * fall under the percentage of matches indicated by this field.
     *
     * For some fraction N/D, a random number in the range [0,D) is selected. If the
     * number is <= the value of the numerator N, or if the key is not present, the default
     * value, the request will be mirrored.
     *
     * @generated from protobuf field: envoy.api.v2.core.RuntimeFractionalPercent runtime_fraction = 3;
     */
    runtimeFraction?: RuntimeFractionalPercent;
    /**
     * Determines if the trace span should be sampled. Defaults to true.
     *
     * @generated from protobuf field: google.protobuf.BoolValue trace_sampled = 4;
     */
    traceSampled?: BoolValue;
}
/**
 * Specifies the route's hashing policy if the upstream cluster uses a hashing :ref:`load balancer
 * <arch_overview_load_balancing_types>`.
 * [#next-free-field: 7]
 *
 * @generated from protobuf message envoy.api.v2.route.RouteAction.HashPolicy
 */
export interface RouteAction_HashPolicy {
    /**
     * @generated from protobuf oneof: policy_specifier
     */
    policySpecifier: {
        oneofKind: "header";
        /**
         * Header hash policy.
         *
         * @generated from protobuf field: envoy.api.v2.route.RouteAction.HashPolicy.Header header = 1;
         */
        header: RouteAction_HashPolicy_Header;
    } | {
        oneofKind: "cookie";
        /**
         * Cookie hash policy.
         *
         * @generated from protobuf field: envoy.api.v2.route.RouteAction.HashPolicy.Cookie cookie = 2;
         */
        cookie: RouteAction_HashPolicy_Cookie;
    } | {
        oneofKind: "connectionProperties";
        /**
         * Connection properties hash policy.
         *
         * @generated from protobuf field: envoy.api.v2.route.RouteAction.HashPolicy.ConnectionProperties connection_properties = 3;
         */
        connectionProperties: RouteAction_HashPolicy_ConnectionProperties;
    } | {
        oneofKind: "queryParameter";
        /**
         * Query parameter hash policy.
         *
         * @generated from protobuf field: envoy.api.v2.route.RouteAction.HashPolicy.QueryParameter query_parameter = 5;
         */
        queryParameter: RouteAction_HashPolicy_QueryParameter;
    } | {
        oneofKind: "filterState";
        /**
         * Filter state hash policy.
         *
         * @generated from protobuf field: envoy.api.v2.route.RouteAction.HashPolicy.FilterState filter_state = 6;
         */
        filterState: RouteAction_HashPolicy_FilterState;
    } | {
        oneofKind: undefined;
    };
    /**
     * The flag that short-circuits the hash computing. This field provides a
     * 'fallback' style of configuration: "if a terminal policy doesn't work,
     * fallback to rest of the policy list", it saves time when the terminal
     * policy works.
     *
     * If true, and there is already a hash computed, ignore rest of the
     * list of hash polices.
     * For example, if the following hash methods are configured:
     *
     *  ========= ========
     *  specifier terminal
     *  ========= ========
     *  Header A  true
     *  Header B  false
     *  Header C  false
     *  ========= ========
     *
     * The generateHash process ends if policy "header A" generates a hash, as
     * it's a terminal policy.
     *
     * @generated from protobuf field: bool terminal = 4;
     */
    terminal: boolean;
}
/**
 * @generated from protobuf message envoy.api.v2.route.RouteAction.HashPolicy.Header
 */
export interface RouteAction_HashPolicy_Header {
    /**
     * The name of the request header that will be used to obtain the hash
     * key. If the request header is not present, no hash will be produced.
     *
     * @generated from protobuf field: string header_name = 1;
     */
    headerName: string;
}
/**
 * Envoy supports two types of cookie affinity:
 *
 * 1. Passive. Envoy takes a cookie that's present in the cookies header and
 *    hashes on its value.
 *
 * 2. Generated. Envoy generates and sets a cookie with an expiration (TTL)
 *    on the first request from the client in its response to the client,
 *    based on the endpoint the request gets sent to. The client then
 *    presents this on the next and all subsequent requests. The hash of
 *    this is sufficient to ensure these requests get sent to the same
 *    endpoint. The cookie is generated by hashing the source and
 *    destination ports and addresses so that multiple independent HTTP2
 *    streams on the same connection will independently receive the same
 *    cookie, even if they arrive at the Envoy simultaneously.
 *
 * @generated from protobuf message envoy.api.v2.route.RouteAction.HashPolicy.Cookie
 */
export interface RouteAction_HashPolicy_Cookie {
    /**
     * The name of the cookie that will be used to obtain the hash key. If the
     * cookie is not present and ttl below is not set, no hash will be
     * produced.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * If specified, a cookie with the TTL will be generated if the cookie is
     * not present. If the TTL is present and zero, the generated cookie will
     * be a session cookie.
     *
     * @generated from protobuf field: google.protobuf.Duration ttl = 2;
     */
    ttl?: Duration;
    /**
     * The name of the path for the cookie. If no path is specified here, no path
     * will be set for the cookie.
     *
     * @generated from protobuf field: string path = 3;
     */
    path: string;
}
/**
 * @generated from protobuf message envoy.api.v2.route.RouteAction.HashPolicy.ConnectionProperties
 */
export interface RouteAction_HashPolicy_ConnectionProperties {
    /**
     * Hash on source IP address.
     *
     * @generated from protobuf field: bool source_ip = 1;
     */
    sourceIp: boolean;
}
/**
 * @generated from protobuf message envoy.api.v2.route.RouteAction.HashPolicy.QueryParameter
 */
export interface RouteAction_HashPolicy_QueryParameter {
    /**
     * The name of the URL query parameter that will be used to obtain the hash
     * key. If the parameter is not present, no hash will be produced. Query
     * parameter names are case-sensitive.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message envoy.api.v2.route.RouteAction.HashPolicy.FilterState
 */
export interface RouteAction_HashPolicy_FilterState {
    /**
     * The name of the Object in the per-request filterState, which is an
     * Envoy::Http::Hashable object. If there is no data associated with the key,
     * or the stored object is not Envoy::Http::Hashable, no hash will be produced.
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
}
/**
 * Allows enabling and disabling upgrades on a per-route basis.
 * This overrides any enabled/disabled upgrade filter chain specified in the
 * HttpConnectionManager
 * :ref:`upgrade_configs
 * <envoy_api_field_config.filter.network.http_connection_manager.v2.HttpConnectionManager.upgrade_configs>`
 * but does not affect any custom filter chain specified there.
 *
 * @generated from protobuf message envoy.api.v2.route.RouteAction.UpgradeConfig
 */
export interface RouteAction_UpgradeConfig {
    /**
     * The case-insensitive name of this upgrade, e.g. "websocket".
     * For each upgrade type present in upgrade_configs, requests with
     * Upgrade: [upgrade_type] will be proxied upstream.
     *
     * @generated from protobuf field: string upgrade_type = 1;
     */
    upgradeType: string;
    /**
     * Determines if upgrades are available on this route. Defaults to true.
     *
     * @generated from protobuf field: google.protobuf.BoolValue enabled = 2;
     */
    enabled?: BoolValue;
}
/**
 * @generated from protobuf enum envoy.api.v2.route.RouteAction.ClusterNotFoundResponseCode
 */
export enum RouteAction_ClusterNotFoundResponseCode {
    /**
     * HTTP status code - 503 Service Unavailable.
     *
     * @generated from protobuf enum value: SERVICE_UNAVAILABLE = 0;
     */
    SERVICE_UNAVAILABLE = 0,
    /**
     * HTTP status code - 404 Not Found.
     *
     * @generated from protobuf enum value: NOT_FOUND = 1;
     */
    NOT_FOUND = 1
}
/**
 * Configures :ref:`internal redirect <arch_overview_internal_redirects>` behavior.
 *
 * @generated from protobuf enum envoy.api.v2.route.RouteAction.InternalRedirectAction
 */
export enum RouteAction_InternalRedirectAction {
    /**
     * @generated from protobuf enum value: PASS_THROUGH_INTERNAL_REDIRECT = 0;
     */
    PASS_THROUGH_INTERNAL_REDIRECT = 0,
    /**
     * @generated from protobuf enum value: HANDLE_INTERNAL_REDIRECT = 1;
     */
    HANDLE_INTERNAL_REDIRECT = 1
}
/**
 * HTTP retry :ref:`architecture overview <arch_overview_http_routing_retry>`.
 * [#next-free-field: 11]
 *
 * @generated from protobuf message envoy.api.v2.route.RetryPolicy
 */
export interface RetryPolicy {
    /**
     * Specifies the conditions under which retry takes place. These are the same
     * conditions documented for :ref:`config_http_filters_router_x-envoy-retry-on` and
     * :ref:`config_http_filters_router_x-envoy-retry-grpc-on`.
     *
     * @generated from protobuf field: string retry_on = 1;
     */
    retryOn: string;
    /**
     * Specifies the allowed number of retries. This parameter is optional and
     * defaults to 1. These are the same conditions documented for
     * :ref:`config_http_filters_router_x-envoy-max-retries`.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value num_retries = 2;
     */
    numRetries?: UInt32Value;
    /**
     * Specifies a non-zero upstream timeout per retry attempt. This parameter is optional. The
     * same conditions documented for
     * :ref:`config_http_filters_router_x-envoy-upstream-rq-per-try-timeout-ms` apply.
     *
     * .. note::
     *
     *   If left unspecified, Envoy will use the global
     *   :ref:`route timeout <envoy_api_field_route.RouteAction.timeout>` for the request.
     *   Consequently, when using a :ref:`5xx <config_http_filters_router_x-envoy-retry-on>` based
     *   retry policy, a request that times out will not be retried as the total timeout budget
     *   would have been exhausted.
     *
     * @generated from protobuf field: google.protobuf.Duration per_try_timeout = 3;
     */
    perTryTimeout?: Duration;
    /**
     * Specifies an implementation of a RetryPriority which is used to determine the
     * distribution of load across priorities used for retries. Refer to
     * :ref:`retry plugin configuration <arch_overview_http_retry_plugins>` for more details.
     *
     * @generated from protobuf field: envoy.api.v2.route.RetryPolicy.RetryPriority retry_priority = 4;
     */
    retryPriority?: RetryPolicy_RetryPriority;
    /**
     * Specifies a collection of RetryHostPredicates that will be consulted when selecting a host
     * for retries. If any of the predicates reject the host, host selection will be reattempted.
     * Refer to :ref:`retry plugin configuration <arch_overview_http_retry_plugins>` for more
     * details.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.RetryPolicy.RetryHostPredicate retry_host_predicate = 5;
     */
    retryHostPredicate: RetryPolicy_RetryHostPredicate[];
    /**
     * The maximum number of times host selection will be reattempted before giving up, at which
     * point the host that was last selected will be routed to. If unspecified, this will default to
     * retrying once.
     *
     * @generated from protobuf field: int64 host_selection_retry_max_attempts = 6;
     */
    hostSelectionRetryMaxAttempts: number;
    /**
     * HTTP status codes that should trigger a retry in addition to those specified by retry_on.
     *
     * @generated from protobuf field: repeated uint32 retriable_status_codes = 7;
     */
    retriableStatusCodes: number[];
    /**
     * Specifies parameters that control retry back off. This parameter is optional, in which case the
     * default base interval is 25 milliseconds or, if set, the current value of the
     * `upstream.base_retry_backoff_ms` runtime parameter. The default maximum interval is 10 times
     * the base interval. The documentation for :ref:`config_http_filters_router_x-envoy-max-retries`
     * describes Envoy's back-off algorithm.
     *
     * @generated from protobuf field: envoy.api.v2.route.RetryPolicy.RetryBackOff retry_back_off = 8;
     */
    retryBackOff?: RetryPolicy_RetryBackOff;
    /**
     * HTTP response headers that trigger a retry if present in the response. A retry will be
     * triggered if any of the header matches match the upstream response headers.
     * The field is only consulted if 'retriable-headers' retry policy is active.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.HeaderMatcher retriable_headers = 9;
     */
    retriableHeaders: HeaderMatcher[];
    /**
     * HTTP headers which must be present in the request for retries to be attempted.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.HeaderMatcher retriable_request_headers = 10;
     */
    retriableRequestHeaders: HeaderMatcher[];
}
/**
 * @generated from protobuf message envoy.api.v2.route.RetryPolicy.RetryPriority
 */
export interface RetryPolicy_RetryPriority {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf oneof: config_type
     */
    configType: {
        oneofKind: "config";
        /**
         * @deprecated
         * @generated from protobuf field: google.protobuf.Struct config = 2 [deprecated = true];
         */
        config: Struct;
    } | {
        oneofKind: "typedConfig";
        /**
         * @generated from protobuf field: google.protobuf.Any typed_config = 3;
         */
        typedConfig: Any;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message envoy.api.v2.route.RetryPolicy.RetryHostPredicate
 */
export interface RetryPolicy_RetryHostPredicate {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf oneof: config_type
     */
    configType: {
        oneofKind: "config";
        /**
         * @deprecated
         * @generated from protobuf field: google.protobuf.Struct config = 2 [deprecated = true];
         */
        config: Struct;
    } | {
        oneofKind: "typedConfig";
        /**
         * @generated from protobuf field: google.protobuf.Any typed_config = 3;
         */
        typedConfig: Any;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message envoy.api.v2.route.RetryPolicy.RetryBackOff
 */
export interface RetryPolicy_RetryBackOff {
    /**
     * Specifies the base interval between retries. This parameter is required and must be greater
     * than zero. Values less than 1 ms are rounded up to 1 ms.
     * See :ref:`config_http_filters_router_x-envoy-max-retries` for a discussion of Envoy's
     * back-off algorithm.
     *
     * @generated from protobuf field: google.protobuf.Duration base_interval = 1;
     */
    baseInterval?: Duration;
    /**
     * Specifies the maximum interval between retries. This parameter is optional, but must be
     * greater than or equal to the `base_interval` if set. The default is 10 times the
     * `base_interval`. See :ref:`config_http_filters_router_x-envoy-max-retries` for a discussion
     * of Envoy's back-off algorithm.
     *
     * @generated from protobuf field: google.protobuf.Duration max_interval = 2;
     */
    maxInterval?: Duration;
}
/**
 * HTTP request hedging :ref:`architecture overview <arch_overview_http_routing_hedging>`.
 *
 * @generated from protobuf message envoy.api.v2.route.HedgePolicy
 */
export interface HedgePolicy {
    /**
     * Specifies the number of initial requests that should be sent upstream.
     * Must be at least 1.
     * Defaults to 1.
     * [#not-implemented-hide:]
     *
     * @generated from protobuf field: google.protobuf.UInt32Value initial_requests = 1;
     */
    initialRequests?: UInt32Value;
    /**
     * Specifies a probability that an additional upstream request should be sent
     * on top of what is specified by initial_requests.
     * Defaults to 0.
     * [#not-implemented-hide:]
     *
     * @generated from protobuf field: envoy.type.FractionalPercent additional_request_chance = 2;
     */
    additionalRequestChance?: FractionalPercent;
    /**
     * Indicates that a hedged request should be sent when the per-try timeout is hit.
     * This means that a retry will be issued without resetting the original request, leaving multiple upstream requests in flight.
     * The first request to complete successfully will be the one returned to the caller.
     *
     * * At any time, a successful response (i.e. not triggering any of the retry-on conditions) would be returned to the client.
     * * Before per-try timeout, an error response (per retry-on conditions) would be retried immediately or returned ot the client
     *   if there are no more retries left.
     * * After per-try timeout, an error response would be discarded, as a retry in the form of a hedged request is already in progress.
     *
     * Note: For this to have effect, you must have a :ref:`RetryPolicy <envoy_api_msg_route.RetryPolicy>` that retries at least
     * one error code and specifies a maximum number of retries.
     *
     * Defaults to false.
     *
     * @generated from protobuf field: bool hedge_on_per_try_timeout = 3;
     */
    hedgeOnPerTryTimeout: boolean;
}
/**
 * [#next-free-field: 9]
 *
 * @generated from protobuf message envoy.api.v2.route.RedirectAction
 */
export interface RedirectAction {
    /**
     * @generated from protobuf oneof: scheme_rewrite_specifier
     */
    schemeRewriteSpecifier: {
        oneofKind: "httpsRedirect";
        /**
         * The scheme portion of the URL will be swapped with "https".
         *
         * @generated from protobuf field: bool https_redirect = 4;
         */
        httpsRedirect: boolean;
    } | {
        oneofKind: "schemeRedirect";
        /**
         * The scheme portion of the URL will be swapped with this value.
         *
         * @generated from protobuf field: string scheme_redirect = 7;
         */
        schemeRedirect: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * The host portion of the URL will be swapped with this value.
     *
     * @generated from protobuf field: string host_redirect = 1;
     */
    hostRedirect: string;
    /**
     * The port value of the URL will be swapped with this value.
     *
     * @generated from protobuf field: uint32 port_redirect = 8;
     */
    portRedirect: number;
    /**
     * @generated from protobuf oneof: path_rewrite_specifier
     */
    pathRewriteSpecifier: {
        oneofKind: "pathRedirect";
        /**
         * The path portion of the URL will be swapped with this value.
         * Please note that query string in path_redirect will override the
         * request's query string and will not be stripped.
         *
         * For example, let's say we have the following routes:
         *
         * - match: { path: "/old-path-1" }
         *   redirect: { path_redirect: "/new-path-1" }
         * - match: { path: "/old-path-2" }
         *   redirect: { path_redirect: "/new-path-2", strip-query: "true" }
         * - match: { path: "/old-path-3" }
         *   redirect: { path_redirect: "/new-path-3?foo=1", strip_query: "true" }
         *
         * 1. if request uri is "/old-path-1?bar=1", users will be redirected to "/new-path-1?bar=1"
         * 2. if request uri is "/old-path-2?bar=1", users will be redirected to "/new-path-2"
         * 3. if request uri is "/old-path-3?bar=1", users will be redirected to "/new-path-3?foo=1"
         *
         * @generated from protobuf field: string path_redirect = 2;
         */
        pathRedirect: string;
    } | {
        oneofKind: "prefixRewrite";
        /**
         * Indicates that during redirection, the matched prefix (or path)
         * should be swapped with this value. This option allows redirect URLs be dynamically created
         * based on the request.
         *
         * .. attention::
         *
         *   Pay attention to the use of trailing slashes as mentioned in
         *   :ref:`RouteAction's prefix_rewrite <envoy_api_field_route.RouteAction.prefix_rewrite>`.
         *
         * @generated from protobuf field: string prefix_rewrite = 5;
         */
        prefixRewrite: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * The HTTP status code to use in the redirect response. The default response
     * code is MOVED_PERMANENTLY (301).
     *
     * @generated from protobuf field: envoy.api.v2.route.RedirectAction.RedirectResponseCode response_code = 3;
     */
    responseCode: RedirectAction_RedirectResponseCode;
    /**
     * Indicates that during redirection, the query portion of the URL will
     * be removed. Default value is false.
     *
     * @generated from protobuf field: bool strip_query = 6;
     */
    stripQuery: boolean;
}
/**
 * @generated from protobuf enum envoy.api.v2.route.RedirectAction.RedirectResponseCode
 */
export enum RedirectAction_RedirectResponseCode {
    /**
     * Moved Permanently HTTP Status Code - 301.
     *
     * @generated from protobuf enum value: MOVED_PERMANENTLY = 0;
     */
    MOVED_PERMANENTLY = 0,
    /**
     * Found HTTP Status Code - 302.
     *
     * @generated from protobuf enum value: FOUND = 1;
     */
    FOUND = 1,
    /**
     * See Other HTTP Status Code - 303.
     *
     * @generated from protobuf enum value: SEE_OTHER = 2;
     */
    SEE_OTHER = 2,
    /**
     * Temporary Redirect HTTP Status Code - 307.
     *
     * @generated from protobuf enum value: TEMPORARY_REDIRECT = 3;
     */
    TEMPORARY_REDIRECT = 3,
    /**
     * Permanent Redirect HTTP Status Code - 308.
     *
     * @generated from protobuf enum value: PERMANENT_REDIRECT = 4;
     */
    PERMANENT_REDIRECT = 4
}
/**
 * @generated from protobuf message envoy.api.v2.route.DirectResponseAction
 */
export interface DirectResponseAction {
    /**
     * Specifies the HTTP response status to be returned.
     *
     * @generated from protobuf field: uint32 status = 1;
     */
    status: number;
    /**
     * Specifies the content of the response body. If this setting is omitted,
     * no body is included in the generated response.
     *
     * .. note::
     *
     *   Headers can be specified using *response_headers_to_add* in the enclosing
     *   :ref:`envoy_api_msg_route.Route`, :ref:`envoy_api_msg_RouteConfiguration` or
     *   :ref:`envoy_api_msg_route.VirtualHost`.
     *
     * @generated from protobuf field: envoy.api.v2.core.DataSource body = 2;
     */
    body?: DataSource;
}
/**
 * @generated from protobuf message envoy.api.v2.route.Decorator
 */
export interface Decorator {
    /**
     * The operation name associated with the request matched to this route. If tracing is
     * enabled, this information will be used as the span name reported for this request.
     *
     * .. note::
     *
     *   For ingress (inbound) requests, or egress (outbound) responses, this value may be overridden
     *   by the :ref:`x-envoy-decorator-operation
     *   <config_http_filters_router_x-envoy-decorator-operation>` header.
     *
     * @generated from protobuf field: string operation = 1;
     */
    operation: string;
    /**
     * Whether the decorated details should be propagated to the other party. The default is true.
     *
     * @generated from protobuf field: google.protobuf.BoolValue propagate = 2;
     */
    propagate?: BoolValue;
}
/**
 * @generated from protobuf message envoy.api.v2.route.Tracing
 */
export interface Tracing {
    /**
     * Target percentage of requests managed by this HTTP connection manager that will be force
     * traced if the :ref:`x-client-trace-id <config_http_conn_man_headers_x-client-trace-id>`
     * header is set. This field is a direct analog for the runtime variable
     * 'tracing.client_sampling' in the :ref:`HTTP Connection Manager
     * <config_http_conn_man_runtime>`.
     * Default: 100%
     *
     * @generated from protobuf field: envoy.type.FractionalPercent client_sampling = 1;
     */
    clientSampling?: FractionalPercent;
    /**
     * Target percentage of requests managed by this HTTP connection manager that will be randomly
     * selected for trace generation, if not requested by the client or not forced. This field is
     * a direct analog for the runtime variable 'tracing.random_sampling' in the
     * :ref:`HTTP Connection Manager <config_http_conn_man_runtime>`.
     * Default: 100%
     *
     * @generated from protobuf field: envoy.type.FractionalPercent random_sampling = 2;
     */
    randomSampling?: FractionalPercent;
    /**
     * Target percentage of requests managed by this HTTP connection manager that will be traced
     * after all other sampling checks have been applied (client-directed, force tracing, random
     * sampling). This field functions as an upper limit on the total configured sampling rate. For
     * instance, setting client_sampling to 100% but overall_sampling to 1% will result in only 1%
     * of client requests with the appropriate headers to be force traced. This field is a direct
     * analog for the runtime variable 'tracing.global_enabled' in the
     * :ref:`HTTP Connection Manager <config_http_conn_man_runtime>`.
     * Default: 100%
     *
     * @generated from protobuf field: envoy.type.FractionalPercent overall_sampling = 3;
     */
    overallSampling?: FractionalPercent;
    /**
     * A list of custom tags with unique tag name to create tags for the active span.
     * It will take effect after merging with the :ref:`corresponding configuration
     * <envoy_api_field_config.filter.network.http_connection_manager.v2.HttpConnectionManager.Tracing.custom_tags>`
     * configured in the HTTP connection manager. If two tags with the same name are configured
     * each in the HTTP connection manager and the route level, the one configured here takes
     * priority.
     *
     * @generated from protobuf field: repeated envoy.type.tracing.v2.CustomTag custom_tags = 4;
     */
    customTags: CustomTag[];
}
/**
 * A virtual cluster is a way of specifying a regex matching rule against
 * certain important endpoints such that statistics are generated explicitly for
 * the matched requests. The reason this is useful is that when doing
 * prefix/path matching Envoy does not always know what the application
 * considers to be an endpoint. Thus, it’s impossible for Envoy to generically
 * emit per endpoint statistics. However, often systems have highly critical
 * endpoints that they wish to get “perfect” statistics on. Virtual cluster
 * statistics are perfect in the sense that they are emitted on the downstream
 * side such that they include network level failures.
 *
 * Documentation for :ref:`virtual cluster statistics <config_http_filters_router_vcluster_stats>`.
 *
 * .. note::
 *
 *    Virtual clusters are a useful tool, but we do not recommend setting up a virtual cluster for
 *    every application endpoint. This is both not easily maintainable and as well the matching and
 *    statistics output are not free.
 *
 * @generated from protobuf message envoy.api.v2.route.VirtualCluster
 */
export interface VirtualCluster {
    /**
     * Specifies a regex pattern to use for matching requests. The entire path of the request
     * must match the regex. The regex grammar used is defined `here
     * <https://en.cppreference.com/w/cpp/regex/ecmascript>`_.
     *
     * Examples:
     *
     * * The regex ``/rides/\d+`` matches the path *\/rides/0*
     * * The regex ``/rides/\d+`` matches the path *\/rides/123*
     * * The regex ``/rides/\d+`` does not match the path *\/rides/123/456*
     *
     * .. attention::
     *   This field has been deprecated in favor of `headers` as it is not safe for use with
     *   untrusted input in all cases.
     *
     * @deprecated
     * @generated from protobuf field: string pattern = 1 [deprecated = true];
     */
    pattern: string;
    /**
     * Specifies a list of header matchers to use for matching requests. Each specified header must
     * match. The pseudo-headers `:path` and `:method` can be used to match the request path and
     * method, respectively.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.HeaderMatcher headers = 4;
     */
    headers: HeaderMatcher[];
    /**
     * Specifies the name of the virtual cluster. The virtual cluster name as well
     * as the virtual host name are used when emitting statistics. The statistics are emitted by the
     * router filter and are documented :ref:`here <config_http_filters_router_stats>`.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Optionally specifies the HTTP method to match on. For example GET, PUT,
     * etc.
     *
     * .. attention::
     *   This field has been deprecated in favor of `headers`.
     *
     * @deprecated
     * @generated from protobuf field: envoy.api.v2.core.RequestMethod method = 3 [deprecated = true];
     */
    method: RequestMethod;
}
/**
 * Global rate limiting :ref:`architecture overview <arch_overview_global_rate_limit>`.
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit
 */
export interface RateLimit {
    /**
     * Refers to the stage set in the filter. The rate limit configuration only
     * applies to filters with the same stage number. The default stage number is
     * 0.
     *
     * .. note::
     *
     *   The filter supports a range of 0 - 10 inclusively for stage numbers.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value stage = 1;
     */
    stage?: UInt32Value;
    /**
     * The key to be set in runtime to disable this rate limit configuration.
     *
     * @generated from protobuf field: string disable_key = 2;
     */
    disableKey: string;
    /**
     * A list of actions that are to be applied for this rate limit configuration.
     * Order matters as the actions are processed sequentially and the descriptor
     * is composed by appending descriptor entries in that sequence. If an action
     * cannot append a descriptor entry, no descriptor is generated for the
     * configuration. See :ref:`composing actions
     * <config_http_filters_rate_limit_composing_actions>` for additional documentation.
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.RateLimit.Action actions = 3;
     */
    actions: RateLimit_Action[];
}
/**
 * [#next-free-field: 7]
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action
 */
export interface RateLimit_Action {
    /**
     * @generated from protobuf oneof: action_specifier
     */
    actionSpecifier: {
        oneofKind: "sourceCluster";
        /**
         * Rate limit on source cluster.
         *
         * @generated from protobuf field: envoy.api.v2.route.RateLimit.Action.SourceCluster source_cluster = 1;
         */
        sourceCluster: RateLimit_Action_SourceCluster;
    } | {
        oneofKind: "destinationCluster";
        /**
         * Rate limit on destination cluster.
         *
         * @generated from protobuf field: envoy.api.v2.route.RateLimit.Action.DestinationCluster destination_cluster = 2;
         */
        destinationCluster: RateLimit_Action_DestinationCluster;
    } | {
        oneofKind: "requestHeaders";
        /**
         * Rate limit on request headers.
         *
         * @generated from protobuf field: envoy.api.v2.route.RateLimit.Action.RequestHeaders request_headers = 3;
         */
        requestHeaders: RateLimit_Action_RequestHeaders;
    } | {
        oneofKind: "remoteAddress";
        /**
         * Rate limit on remote address.
         *
         * @generated from protobuf field: envoy.api.v2.route.RateLimit.Action.RemoteAddress remote_address = 4;
         */
        remoteAddress: RateLimit_Action_RemoteAddress;
    } | {
        oneofKind: "genericKey";
        /**
         * Rate limit on a generic key.
         *
         * @generated from protobuf field: envoy.api.v2.route.RateLimit.Action.GenericKey generic_key = 5;
         */
        genericKey: RateLimit_Action_GenericKey;
    } | {
        oneofKind: "headerValueMatch";
        /**
         * Rate limit on the existence of request headers.
         *
         * @generated from protobuf field: envoy.api.v2.route.RateLimit.Action.HeaderValueMatch header_value_match = 6;
         */
        headerValueMatch: RateLimit_Action_HeaderValueMatch;
    } | {
        oneofKind: undefined;
    };
}
/**
 * The following descriptor entry is appended to the descriptor:
 *
 * .. code-block:: cpp
 *
 *   ("source_cluster", "<local service cluster>")
 *
 * <local service cluster> is derived from the :option:`--service-cluster` option.
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action.SourceCluster
 */
export interface RateLimit_Action_SourceCluster {
}
/**
 * The following descriptor entry is appended to the descriptor:
 *
 * .. code-block:: cpp
 *
 *   ("destination_cluster", "<routed target cluster>")
 *
 * Once a request matches against a route table rule, a routed cluster is determined by one of
 * the following :ref:`route table configuration <envoy_api_msg_RouteConfiguration>`
 * settings:
 *
 * * :ref:`cluster <envoy_api_field_route.RouteAction.cluster>` indicates the upstream cluster
 *   to route to.
 * * :ref:`weighted_clusters <envoy_api_field_route.RouteAction.weighted_clusters>`
 *   chooses a cluster randomly from a set of clusters with attributed weight.
 * * :ref:`cluster_header <envoy_api_field_route.RouteAction.cluster_header>` indicates which
 *   header in the request contains the target cluster.
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action.DestinationCluster
 */
export interface RateLimit_Action_DestinationCluster {
}
/**
 * The following descriptor entry is appended when a header contains a key that matches the
 * *header_name*:
 *
 * .. code-block:: cpp
 *
 *   ("<descriptor_key>", "<header_value_queried_from_header>")
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action.RequestHeaders
 */
export interface RateLimit_Action_RequestHeaders {
    /**
     * The header name to be queried from the request headers. The header’s
     * value is used to populate the value of the descriptor entry for the
     * descriptor_key.
     *
     * @generated from protobuf field: string header_name = 1;
     */
    headerName: string;
    /**
     * The key to use in the descriptor entry.
     *
     * @generated from protobuf field: string descriptor_key = 2;
     */
    descriptorKey: string;
}
/**
 * The following descriptor entry is appended to the descriptor and is populated using the
 * trusted address from :ref:`x-forwarded-for <config_http_conn_man_headers_x-forwarded-for>`:
 *
 * .. code-block:: cpp
 *
 *   ("remote_address", "<trusted address from x-forwarded-for>")
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action.RemoteAddress
 */
export interface RateLimit_Action_RemoteAddress {
}
/**
 * The following descriptor entry is appended to the descriptor:
 *
 * .. code-block:: cpp
 *
 *   ("generic_key", "<descriptor_value>")
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action.GenericKey
 */
export interface RateLimit_Action_GenericKey {
    /**
     * The value to use in the descriptor entry.
     *
     * @generated from protobuf field: string descriptor_value = 1;
     */
    descriptorValue: string;
}
/**
 * The following descriptor entry is appended to the descriptor:
 *
 * .. code-block:: cpp
 *
 *   ("header_match", "<descriptor_value>")
 *
 * @generated from protobuf message envoy.api.v2.route.RateLimit.Action.HeaderValueMatch
 */
export interface RateLimit_Action_HeaderValueMatch {
    /**
     * The value to use in the descriptor entry.
     *
     * @generated from protobuf field: string descriptor_value = 1;
     */
    descriptorValue: string;
    /**
     * If set to true, the action will append a descriptor entry when the
     * request matches the headers. If set to false, the action will append a
     * descriptor entry when the request does not match the headers. The
     * default value is true.
     *
     * @generated from protobuf field: google.protobuf.BoolValue expect_match = 2;
     */
    expectMatch?: BoolValue;
    /**
     * Specifies a set of headers that the rate limit action should match
     * on. The action will check the request’s headers against all the
     * specified headers in the config. A match will happen if all the
     * headers in the config are present in the request with the same values
     * (or based on presence if the value field is not in the config).
     *
     * @generated from protobuf field: repeated envoy.api.v2.route.HeaderMatcher headers = 3;
     */
    headers: HeaderMatcher[];
}
/**
 * .. attention::
 *
 *   Internally, Envoy always uses the HTTP/2 *:authority* header to represent the HTTP/1 *Host*
 *   header. Thus, if attempting to match on *Host*, match on *:authority* instead.
 *
 * .. attention::
 *
 *   To route on HTTP method, use the special HTTP/2 *:method* header. This works for both
 *   HTTP/1 and HTTP/2 as Envoy normalizes headers. E.g.,
 *
 *   .. code-block:: json
 *
 *     {
 *       "name": ":method",
 *       "exact_match": "POST"
 *     }
 *
 * .. attention::
 *   In the absence of any header match specifier, match will default to :ref:`present_match
 *   <envoy_api_field_route.HeaderMatcher.present_match>`. i.e, a request that has the :ref:`name
 *   <envoy_api_field_route.HeaderMatcher.name>` header will match, regardless of the header's
 *   value.
 *
 *  [#next-major-version: HeaderMatcher should be refactored to use StringMatcher.]
 * [#next-free-field: 12]
 *
 * @generated from protobuf message envoy.api.v2.route.HeaderMatcher
 */
export interface HeaderMatcher {
    /**
     * Specifies the name of the header in the request.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf oneof: header_match_specifier
     */
    headerMatchSpecifier: {
        oneofKind: "exactMatch";
        /**
         * If specified, header match will be performed based on the value of the header.
         *
         * @generated from protobuf field: string exact_match = 4;
         */
        exactMatch: string;
    } | {
        oneofKind: "regexMatch";
        /**
         * If specified, this regex string is a regular expression rule which implies the entire request
         * header value must match the regex. The rule will not match if only a subsequence of the
         * request header value matches the regex. The regex grammar used in the value field is defined
         * `here <https://en.cppreference.com/w/cpp/regex/ecmascript>`_.
         *
         * Examples:
         *
         * * The regex ``\d{3}`` matches the value *123*
         * * The regex ``\d{3}`` does not match the value *1234*
         * * The regex ``\d{3}`` does not match the value *123.456*
         *
         * .. attention::
         *   This field has been deprecated in favor of `safe_regex_match` as it is not safe for use
         *   with untrusted input in all cases.
         *
         * @deprecated
         * @generated from protobuf field: string regex_match = 5 [deprecated = true];
         */
        regexMatch: string;
    } | {
        oneofKind: "safeRegexMatch";
        /**
         * If specified, this regex string is a regular expression rule which implies the entire request
         * header value must match the regex. The rule will not match if only a subsequence of the
         * request header value matches the regex.
         *
         * @generated from protobuf field: envoy.type.matcher.RegexMatcher safe_regex_match = 11;
         */
        safeRegexMatch: RegexMatcher;
    } | {
        oneofKind: "rangeMatch";
        /**
         * If specified, header match will be performed based on range.
         * The rule will match if the request header value is within this range.
         * The entire request header value must represent an integer in base 10 notation: consisting of
         * an optional plus or minus sign followed by a sequence of digits. The rule will not match if
         * the header value does not represent an integer. Match will fail for empty values, floating
         * point numbers or if only a subsequence of the header value is an integer.
         *
         * Examples:
         *
         * * For range [-10,0), route will match for header value -1, but not for 0, "somestring", 10.9,
         *   "-1somestring"
         *
         * @generated from protobuf field: envoy.type.Int64Range range_match = 6;
         */
        rangeMatch: Int64Range;
    } | {
        oneofKind: "presentMatch";
        /**
         * If specified, header match will be performed based on whether the header is in the
         * request.
         *
         * @generated from protobuf field: bool present_match = 7;
         */
        presentMatch: boolean;
    } | {
        oneofKind: "prefixMatch";
        /**
         * If specified, header match will be performed based on the prefix of the header value.
         * Note: empty prefix is not allowed, please use present_match instead.
         *
         * Examples:
         *
         * * The prefix *abcd* matches the value *abcdxyz*, but not for *abcxyz*.
         *
         * @generated from protobuf field: string prefix_match = 9;
         */
        prefixMatch: string;
    } | {
        oneofKind: "suffixMatch";
        /**
         * If specified, header match will be performed based on the suffix of the header value.
         * Note: empty suffix is not allowed, please use present_match instead.
         *
         * Examples:
         *
         * * The suffix *abcd* matches the value *xyzabcd*, but not for *xyzbcd*.
         *
         * @generated from protobuf field: string suffix_match = 10;
         */
        suffixMatch: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * If specified, the match result will be inverted before checking. Defaults to false.
     *
     * Examples:
     *
     * * The regex ``\d{3}`` does not match the value *1234*, so it will match when inverted.
     * * The range [-10,0) will match the value -1, so it will not match when inverted.
     *
     * @generated from protobuf field: bool invert_match = 8;
     */
    invertMatch: boolean;
}
/**
 * Query parameter matching treats the query string of a request's :path header
 * as an ampersand-separated list of keys and/or key=value elements.
 * [#next-free-field: 7]
 *
 * @generated from protobuf message envoy.api.v2.route.QueryParameterMatcher
 */
export interface QueryParameterMatcher {
    /**
     * Specifies the name of a key that must be present in the requested
     * *path*'s query string.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Specifies the value of the key. If the value is absent, a request
     * that contains the key in its query string will match, whether the
     * key appears with a value (e.g., "?debug=true") or not (e.g., "?debug")
     *
     * ..attention::
     *   This field is deprecated. Use an `exact` match inside the `string_match` field.
     *
     * @deprecated
     * @generated from protobuf field: string value = 3 [deprecated = true];
     */
    value: string;
    /**
     * Specifies whether the query parameter value is a regular expression.
     * Defaults to false. The entire query parameter value (i.e., the part to
     * the right of the equals sign in "key=value") must match the regex.
     * E.g., the regex ``\d+$`` will match *123* but not *a123* or *123a*.
     *
     * ..attention::
     *   This field is deprecated. Use a `safe_regex` match inside the `string_match` field.
     *
     * @deprecated
     * @generated from protobuf field: google.protobuf.BoolValue regex = 4 [deprecated = true];
     */
    regex?: BoolValue;
    /**
     * @generated from protobuf oneof: query_parameter_match_specifier
     */
    queryParameterMatchSpecifier: {
        oneofKind: "stringMatch";
        /**
         * Specifies whether a query parameter value should match against a string.
         *
         * @generated from protobuf field: envoy.type.matcher.StringMatcher string_match = 5;
         */
        stringMatch: StringMatcher;
    } | {
        oneofKind: "presentMatch";
        /**
         * Specifies whether a query parameter should be present.
         *
         * @generated from protobuf field: bool present_match = 6;
         */
        presentMatch: boolean;
    } | {
        oneofKind: undefined;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class VirtualHost$Type extends MessageType<VirtualHost> {
    constructor() {
        super("envoy.api.v2.route.VirtualHost", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "domains", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { repeated: { minItems: "1", items: { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } } } },
            { no: 3, name: "routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Route },
            { no: 4, name: "require_tls", kind: "enum", T: () => ["envoy.api.v2.route.VirtualHost.TlsRequirementType", VirtualHost_TlsRequirementType], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 5, name: "virtual_clusters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VirtualCluster },
            { no: 6, name: "rate_limits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RateLimit },
            { no: 7, name: "request_headers_to_add", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValueOption, options: { "validate.rules": { repeated: { maxItems: "1000" } } } },
            { no: 13, name: "request_headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "response_headers_to_add", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValueOption, options: { "validate.rules": { repeated: { maxItems: "1000" } } } },
            { no: 11, name: "response_headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "cors", kind: "message", T: () => CorsPolicy },
            { no: 12, name: "per_filter_config", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Struct } },
            { no: 15, name: "typed_per_filter_config", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } },
            { no: 14, name: "include_request_attempt_count", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "include_attempt_count_in_response", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "retry_policy", kind: "message", T: () => RetryPolicy },
            { no: 20, name: "retry_policy_typed_config", kind: "message", T: () => Any },
            { no: 17, name: "hedge_policy", kind: "message", T: () => HedgePolicy },
            { no: 18, name: "per_request_buffer_limit_bytes", kind: "message", T: () => UInt32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.VirtualHost
 */
export const VirtualHost = new VirtualHost$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterAction$Type extends MessageType<FilterAction> {
    constructor() {
        super("envoy.api.v2.route.FilterAction", [
            { no: 1, name: "action", kind: "message", T: () => Any }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.FilterAction
 */
export const FilterAction = new FilterAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Route$Type extends MessageType<Route> {
    constructor() {
        super("envoy.api.v2.route.Route", [
            { no: 14, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "match", kind: "message", T: () => RouteMatch, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "route", kind: "message", oneof: "action", T: () => RouteAction },
            { no: 3, name: "redirect", kind: "message", oneof: "action", T: () => RedirectAction },
            { no: 7, name: "direct_response", kind: "message", oneof: "action", T: () => DirectResponseAction },
            { no: 17, name: "filter_action", kind: "message", oneof: "action", T: () => FilterAction },
            { no: 4, name: "metadata", kind: "message", T: () => Metadata },
            { no: 5, name: "decorator", kind: "message", T: () => Decorator },
            { no: 8, name: "per_filter_config", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Struct } },
            { no: 13, name: "typed_per_filter_config", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } },
            { no: 9, name: "request_headers_to_add", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValueOption, options: { "validate.rules": { repeated: { maxItems: "1000" } } } },
            { no: 12, name: "request_headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "response_headers_to_add", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValueOption, options: { "validate.rules": { repeated: { maxItems: "1000" } } } },
            { no: 11, name: "response_headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "tracing", kind: "message", T: () => Tracing },
            { no: 16, name: "per_request_buffer_limit_bytes", kind: "message", T: () => UInt32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.Route
 */
export const Route = new Route$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WeightedCluster$Type extends MessageType<WeightedCluster> {
    constructor() {
        super("envoy.api.v2.route.WeightedCluster", [
            { no: 1, name: "clusters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WeightedCluster_ClusterWeight, options: { "validate.rules": { repeated: { minItems: "1" } } } },
            { no: 3, name: "total_weight", kind: "message", T: () => UInt32Value, options: { "validate.rules": { uint32: { gte: 1 } } } },
            { no: 2, name: "runtime_key_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.WeightedCluster
 */
export const WeightedCluster = new WeightedCluster$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WeightedCluster_ClusterWeight$Type extends MessageType<WeightedCluster_ClusterWeight> {
    constructor() {
        super("envoy.api.v2.route.WeightedCluster.ClusterWeight", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "weight", kind: "message", T: () => UInt32Value },
            { no: 3, name: "metadata_match", kind: "message", T: () => Metadata },
            { no: 4, name: "request_headers_to_add", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValueOption, options: { "validate.rules": { repeated: { maxItems: "1000" } } } },
            { no: 9, name: "request_headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "response_headers_to_add", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderValueOption, options: { "validate.rules": { repeated: { maxItems: "1000" } } } },
            { no: 6, name: "response_headers_to_remove", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "per_filter_config", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Struct } },
            { no: 10, name: "typed_per_filter_config", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.WeightedCluster.ClusterWeight
 */
export const WeightedCluster_ClusterWeight = new WeightedCluster_ClusterWeight$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteMatch$Type extends MessageType<RouteMatch> {
    constructor() {
        super("envoy.api.v2.route.RouteMatch", [
            { no: 1, name: "prefix", kind: "scalar", oneof: "pathSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", oneof: "pathSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "regex", kind: "scalar", oneof: "pathSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024" } }, "envoy.annotations.disallowed_by_default": true } },
            { no: 10, name: "safe_regex", kind: "message", oneof: "pathSpecifier", T: () => RegexMatcher, options: { "validate.rules": { message: { required: true } } } },
            { no: 4, name: "case_sensitive", kind: "message", T: () => BoolValue },
            { no: 9, name: "runtime_fraction", kind: "message", T: () => RuntimeFractionalPercent },
            { no: 6, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher },
            { no: 7, name: "query_parameters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QueryParameterMatcher },
            { no: 8, name: "grpc", kind: "message", T: () => RouteMatch_GrpcRouteMatchOptions },
            { no: 11, name: "tls_context", kind: "message", T: () => RouteMatch_TlsContextMatchOptions }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteMatch
 */
export const RouteMatch = new RouteMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteMatch_GrpcRouteMatchOptions$Type extends MessageType<RouteMatch_GrpcRouteMatchOptions> {
    constructor() {
        super("envoy.api.v2.route.RouteMatch.GrpcRouteMatchOptions", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteMatch.GrpcRouteMatchOptions
 */
export const RouteMatch_GrpcRouteMatchOptions = new RouteMatch_GrpcRouteMatchOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteMatch_TlsContextMatchOptions$Type extends MessageType<RouteMatch_TlsContextMatchOptions> {
    constructor() {
        super("envoy.api.v2.route.RouteMatch.TlsContextMatchOptions", [
            { no: 1, name: "presented", kind: "message", T: () => BoolValue },
            { no: 2, name: "validated", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteMatch.TlsContextMatchOptions
 */
export const RouteMatch_TlsContextMatchOptions = new RouteMatch_TlsContextMatchOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CorsPolicy$Type extends MessageType<CorsPolicy> {
    constructor() {
        super("envoy.api.v2.route.CorsPolicy", [
            { no: 1, name: "allow_origin", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "envoy.annotations.disallowed_by_default": true } },
            { no: 8, name: "allow_origin_regex", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { repeated: { items: { string: { maxBytes: "1024" } } } } } },
            { no: 11, name: "allow_origin_string_match", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StringMatcher },
            { no: 2, name: "allow_methods", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "allow_headers", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expose_headers", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "max_age", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "allow_credentials", kind: "message", T: () => BoolValue },
            { no: 7, name: "enabled", kind: "message", oneof: "enabledSpecifier", T: () => BoolValue, options: { "envoy.annotations.disallowed_by_default": true } },
            { no: 9, name: "filter_enabled", kind: "message", oneof: "enabledSpecifier", T: () => RuntimeFractionalPercent },
            { no: 10, name: "shadow_enabled", kind: "message", T: () => RuntimeFractionalPercent }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.CorsPolicy
 */
export const CorsPolicy = new CorsPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction$Type extends MessageType<RouteAction> {
    constructor() {
        super("envoy.api.v2.route.RouteAction", [
            { no: 1, name: "cluster", kind: "scalar", oneof: "clusterSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "cluster_header", kind: "scalar", oneof: "clusterSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1", wellKnownRegex: "HTTP_HEADER_NAME", strict: false } } } },
            { no: 3, name: "weighted_clusters", kind: "message", oneof: "clusterSpecifier", T: () => WeightedCluster },
            { no: 20, name: "cluster_not_found_response_code", kind: "enum", T: () => ["envoy.api.v2.route.RouteAction.ClusterNotFoundResponseCode", RouteAction_ClusterNotFoundResponseCode], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 4, name: "metadata_match", kind: "message", T: () => Metadata },
            { no: 5, name: "prefix_rewrite", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } },
            { no: 32, name: "regex_rewrite", kind: "message", T: () => RegexMatchAndSubstitute },
            { no: 6, name: "host_rewrite", kind: "scalar", oneof: "hostRewriteSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } }, "udpa.annotations.field_migrate": { rename: "host_rewrite_literal" } } },
            { no: 7, name: "auto_host_rewrite", kind: "message", oneof: "hostRewriteSpecifier", T: () => BoolValue },
            { no: 29, name: "auto_host_rewrite_header", kind: "scalar", oneof: "hostRewriteSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_NAME", strict: false } }, "udpa.annotations.field_migrate": { rename: "host_rewrite_header" } } },
            { no: 8, name: "timeout", kind: "message", T: () => Duration },
            { no: 24, name: "idle_timeout", kind: "message", T: () => Duration },
            { no: 9, name: "retry_policy", kind: "message", T: () => RetryPolicy },
            { no: 33, name: "retry_policy_typed_config", kind: "message", T: () => Any },
            { no: 10, name: "request_mirror_policy", kind: "message", T: () => RouteAction_RequestMirrorPolicy },
            { no: 30, name: "request_mirror_policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteAction_RequestMirrorPolicy },
            { no: 11, name: "priority", kind: "enum", T: () => ["envoy.api.v2.core.RoutingPriority", RoutingPriority], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 13, name: "rate_limits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RateLimit },
            { no: 14, name: "include_vh_rate_limits", kind: "message", T: () => BoolValue },
            { no: 15, name: "hash_policy", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteAction_HashPolicy },
            { no: 17, name: "cors", kind: "message", T: () => CorsPolicy },
            { no: 23, name: "max_grpc_timeout", kind: "message", T: () => Duration },
            { no: 28, name: "grpc_timeout_offset", kind: "message", T: () => Duration },
            { no: 25, name: "upgrade_configs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RouteAction_UpgradeConfig },
            { no: 26, name: "internal_redirect_action", kind: "enum", T: () => ["envoy.api.v2.route.RouteAction.InternalRedirectAction", RouteAction_InternalRedirectAction] },
            { no: 31, name: "max_internal_redirects", kind: "message", T: () => UInt32Value },
            { no: 27, name: "hedge_policy", kind: "message", T: () => HedgePolicy }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction
 */
export const RouteAction = new RouteAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_RequestMirrorPolicy$Type extends MessageType<RouteAction_RequestMirrorPolicy> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.RequestMirrorPolicy", [
            { no: 1, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "runtime_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "envoy.annotations.disallowed_by_default": true } },
            { no: 3, name: "runtime_fraction", kind: "message", T: () => RuntimeFractionalPercent },
            { no: 4, name: "trace_sampled", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.RequestMirrorPolicy
 */
export const RouteAction_RequestMirrorPolicy = new RouteAction_RequestMirrorPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_HashPolicy$Type extends MessageType<RouteAction_HashPolicy> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.HashPolicy", [
            { no: 1, name: "header", kind: "message", oneof: "policySpecifier", T: () => RouteAction_HashPolicy_Header },
            { no: 2, name: "cookie", kind: "message", oneof: "policySpecifier", T: () => RouteAction_HashPolicy_Cookie },
            { no: 3, name: "connection_properties", kind: "message", oneof: "policySpecifier", T: () => RouteAction_HashPolicy_ConnectionProperties },
            { no: 5, name: "query_parameter", kind: "message", oneof: "policySpecifier", T: () => RouteAction_HashPolicy_QueryParameter },
            { no: 6, name: "filter_state", kind: "message", oneof: "policySpecifier", T: () => RouteAction_HashPolicy_FilterState },
            { no: 4, name: "terminal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.HashPolicy
 */
export const RouteAction_HashPolicy = new RouteAction_HashPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_HashPolicy_Header$Type extends MessageType<RouteAction_HashPolicy_Header> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.HashPolicy.Header", [
            { no: 1, name: "header_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1", wellKnownRegex: "HTTP_HEADER_NAME", strict: false } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.HashPolicy.Header
 */
export const RouteAction_HashPolicy_Header = new RouteAction_HashPolicy_Header$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_HashPolicy_Cookie$Type extends MessageType<RouteAction_HashPolicy_Cookie> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.HashPolicy.Cookie", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "ttl", kind: "message", T: () => Duration },
            { no: 3, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.HashPolicy.Cookie
 */
export const RouteAction_HashPolicy_Cookie = new RouteAction_HashPolicy_Cookie$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_HashPolicy_ConnectionProperties$Type extends MessageType<RouteAction_HashPolicy_ConnectionProperties> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.HashPolicy.ConnectionProperties", [
            { no: 1, name: "source_ip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.HashPolicy.ConnectionProperties
 */
export const RouteAction_HashPolicy_ConnectionProperties = new RouteAction_HashPolicy_ConnectionProperties$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_HashPolicy_QueryParameter$Type extends MessageType<RouteAction_HashPolicy_QueryParameter> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.HashPolicy.QueryParameter", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.HashPolicy.QueryParameter
 */
export const RouteAction_HashPolicy_QueryParameter = new RouteAction_HashPolicy_QueryParameter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_HashPolicy_FilterState$Type extends MessageType<RouteAction_HashPolicy_FilterState> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.HashPolicy.FilterState", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.HashPolicy.FilterState
 */
export const RouteAction_HashPolicy_FilterState = new RouteAction_HashPolicy_FilterState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction_UpgradeConfig$Type extends MessageType<RouteAction_UpgradeConfig> {
    constructor() {
        super("envoy.api.v2.route.RouteAction.UpgradeConfig", [
            { no: 1, name: "upgrade_type", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } },
            { no: 2, name: "enabled", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RouteAction.UpgradeConfig
 */
export const RouteAction_UpgradeConfig = new RouteAction_UpgradeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetryPolicy$Type extends MessageType<RetryPolicy> {
    constructor() {
        super("envoy.api.v2.route.RetryPolicy", [
            { no: 1, name: "retry_on", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "num_retries", kind: "message", T: () => UInt32Value },
            { no: 3, name: "per_try_timeout", kind: "message", T: () => Duration },
            { no: 4, name: "retry_priority", kind: "message", T: () => RetryPolicy_RetryPriority },
            { no: 5, name: "retry_host_predicate", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RetryPolicy_RetryHostPredicate },
            { no: 6, name: "host_selection_retry_max_attempts", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "retriable_status_codes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "retry_back_off", kind: "message", T: () => RetryPolicy_RetryBackOff },
            { no: 9, name: "retriable_headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher },
            { no: 10, name: "retriable_request_headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RetryPolicy
 */
export const RetryPolicy = new RetryPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetryPolicy_RetryPriority$Type extends MessageType<RetryPolicy_RetryPriority> {
    constructor() {
        super("envoy.api.v2.route.RetryPolicy.RetryPriority", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "config", kind: "message", oneof: "configType", T: () => Struct },
            { no: 3, name: "typed_config", kind: "message", oneof: "configType", T: () => Any }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RetryPolicy.RetryPriority
 */
export const RetryPolicy_RetryPriority = new RetryPolicy_RetryPriority$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetryPolicy_RetryHostPredicate$Type extends MessageType<RetryPolicy_RetryHostPredicate> {
    constructor() {
        super("envoy.api.v2.route.RetryPolicy.RetryHostPredicate", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "config", kind: "message", oneof: "configType", T: () => Struct },
            { no: 3, name: "typed_config", kind: "message", oneof: "configType", T: () => Any }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RetryPolicy.RetryHostPredicate
 */
export const RetryPolicy_RetryHostPredicate = new RetryPolicy_RetryHostPredicate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetryPolicy_RetryBackOff$Type extends MessageType<RetryPolicy_RetryBackOff> {
    constructor() {
        super("envoy.api.v2.route.RetryPolicy.RetryBackOff", [
            { no: 1, name: "base_interval", kind: "message", T: () => Duration, options: { "validate.rules": { duration: { required: true, gt: {} } } } },
            { no: 2, name: "max_interval", kind: "message", T: () => Duration, options: { "validate.rules": { duration: { gt: {} } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RetryPolicy.RetryBackOff
 */
export const RetryPolicy_RetryBackOff = new RetryPolicy_RetryBackOff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HedgePolicy$Type extends MessageType<HedgePolicy> {
    constructor() {
        super("envoy.api.v2.route.HedgePolicy", [
            { no: 1, name: "initial_requests", kind: "message", T: () => UInt32Value, options: { "validate.rules": { uint32: { gte: 1 } } } },
            { no: 2, name: "additional_request_chance", kind: "message", T: () => FractionalPercent },
            { no: 3, name: "hedge_on_per_try_timeout", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.HedgePolicy
 */
export const HedgePolicy = new HedgePolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedirectAction$Type extends MessageType<RedirectAction> {
    constructor() {
        super("envoy.api.v2.route.RedirectAction", [
            { no: 4, name: "https_redirect", kind: "scalar", oneof: "schemeRewriteSpecifier", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "scheme_redirect", kind: "scalar", oneof: "schemeRewriteSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "host_redirect", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } },
            { no: 8, name: "port_redirect", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "path_redirect", kind: "scalar", oneof: "pathRewriteSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } },
            { no: 5, name: "prefix_rewrite", kind: "scalar", oneof: "pathRewriteSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { wellKnownRegex: "HTTP_HEADER_VALUE", strict: false } } } },
            { no: 3, name: "response_code", kind: "enum", T: () => ["envoy.api.v2.route.RedirectAction.RedirectResponseCode", RedirectAction_RedirectResponseCode], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 6, name: "strip_query", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RedirectAction
 */
export const RedirectAction = new RedirectAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirectResponseAction$Type extends MessageType<DirectResponseAction> {
    constructor() {
        super("envoy.api.v2.route.DirectResponseAction", [
            { no: 1, name: "status", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { lt: 600, gte: 100 } } } },
            { no: 2, name: "body", kind: "message", T: () => DataSource }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.DirectResponseAction
 */
export const DirectResponseAction = new DirectResponseAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Decorator$Type extends MessageType<Decorator> {
    constructor() {
        super("envoy.api.v2.route.Decorator", [
            { no: 1, name: "operation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "propagate", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.Decorator
 */
export const Decorator = new Decorator$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tracing$Type extends MessageType<Tracing> {
    constructor() {
        super("envoy.api.v2.route.Tracing", [
            { no: 1, name: "client_sampling", kind: "message", T: () => FractionalPercent },
            { no: 2, name: "random_sampling", kind: "message", T: () => FractionalPercent },
            { no: 3, name: "overall_sampling", kind: "message", T: () => FractionalPercent },
            { no: 4, name: "custom_tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomTag }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.Tracing
 */
export const Tracing = new Tracing$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VirtualCluster$Type extends MessageType<VirtualCluster> {
    constructor() {
        super("envoy.api.v2.route.VirtualCluster", [
            { no: 1, name: "pattern", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024" } }, "envoy.annotations.disallowed_by_default": true } },
            { no: 4, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 3, name: "method", kind: "enum", T: () => ["envoy.api.v2.core.RequestMethod", RequestMethod], options: { "envoy.annotations.disallowed_by_default": true } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.VirtualCluster
 */
export const VirtualCluster = new VirtualCluster$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit$Type extends MessageType<RateLimit> {
    constructor() {
        super("envoy.api.v2.route.RateLimit", [
            { no: 1, name: "stage", kind: "message", T: () => UInt32Value, options: { "validate.rules": { uint32: { lte: 10 } } } },
            { no: 2, name: "disable_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RateLimit_Action, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit
 */
export const RateLimit = new RateLimit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action$Type extends MessageType<RateLimit_Action> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action", [
            { no: 1, name: "source_cluster", kind: "message", oneof: "actionSpecifier", T: () => RateLimit_Action_SourceCluster },
            { no: 2, name: "destination_cluster", kind: "message", oneof: "actionSpecifier", T: () => RateLimit_Action_DestinationCluster },
            { no: 3, name: "request_headers", kind: "message", oneof: "actionSpecifier", T: () => RateLimit_Action_RequestHeaders },
            { no: 4, name: "remote_address", kind: "message", oneof: "actionSpecifier", T: () => RateLimit_Action_RemoteAddress },
            { no: 5, name: "generic_key", kind: "message", oneof: "actionSpecifier", T: () => RateLimit_Action_GenericKey },
            { no: 6, name: "header_value_match", kind: "message", oneof: "actionSpecifier", T: () => RateLimit_Action_HeaderValueMatch }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action
 */
export const RateLimit_Action = new RateLimit_Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action_SourceCluster$Type extends MessageType<RateLimit_Action_SourceCluster> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action.SourceCluster", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action.SourceCluster
 */
export const RateLimit_Action_SourceCluster = new RateLimit_Action_SourceCluster$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action_DestinationCluster$Type extends MessageType<RateLimit_Action_DestinationCluster> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action.DestinationCluster", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action.DestinationCluster
 */
export const RateLimit_Action_DestinationCluster = new RateLimit_Action_DestinationCluster$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action_RequestHeaders$Type extends MessageType<RateLimit_Action_RequestHeaders> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action.RequestHeaders", [
            { no: 1, name: "header_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1", wellKnownRegex: "HTTP_HEADER_NAME", strict: false } } } },
            { no: 2, name: "descriptor_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action.RequestHeaders
 */
export const RateLimit_Action_RequestHeaders = new RateLimit_Action_RequestHeaders$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action_RemoteAddress$Type extends MessageType<RateLimit_Action_RemoteAddress> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action.RemoteAddress", []);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action.RemoteAddress
 */
export const RateLimit_Action_RemoteAddress = new RateLimit_Action_RemoteAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action_GenericKey$Type extends MessageType<RateLimit_Action_GenericKey> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action.GenericKey", [
            { no: 1, name: "descriptor_value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action.GenericKey
 */
export const RateLimit_Action_GenericKey = new RateLimit_Action_GenericKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimit_Action_HeaderValueMatch$Type extends MessageType<RateLimit_Action_HeaderValueMatch> {
    constructor() {
        super("envoy.api.v2.route.RateLimit.Action.HeaderValueMatch", [
            { no: 1, name: "descriptor_value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "expect_match", kind: "message", T: () => BoolValue },
            { no: 3, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeaderMatcher, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.RateLimit.Action.HeaderValueMatch
 */
export const RateLimit_Action_HeaderValueMatch = new RateLimit_Action_HeaderValueMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeaderMatcher$Type extends MessageType<HeaderMatcher> {
    constructor() {
        super("envoy.api.v2.route.HeaderMatcher", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1", wellKnownRegex: "HTTP_HEADER_NAME", strict: false } } } },
            { no: 4, name: "exact_match", kind: "scalar", oneof: "headerMatchSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "regex_match", kind: "scalar", oneof: "headerMatchSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024" } }, "envoy.annotations.disallowed_by_default": true } },
            { no: 11, name: "safe_regex_match", kind: "message", oneof: "headerMatchSpecifier", T: () => RegexMatcher },
            { no: 6, name: "range_match", kind: "message", oneof: "headerMatchSpecifier", T: () => Int64Range },
            { no: 7, name: "present_match", kind: "scalar", oneof: "headerMatchSpecifier", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "prefix_match", kind: "scalar", oneof: "headerMatchSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 10, name: "suffix_match", kind: "scalar", oneof: "headerMatchSpecifier", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 8, name: "invert_match", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.HeaderMatcher
 */
export const HeaderMatcher = new HeaderMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryParameterMatcher$Type extends MessageType<QueryParameterMatcher> {
    constructor() {
        super("envoy.api.v2.route.QueryParameterMatcher", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1", maxBytes: "1024" } } } },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "envoy.annotations.disallowed_by_default": true } },
            { no: 4, name: "regex", kind: "message", T: () => BoolValue, options: { "envoy.annotations.disallowed_by_default": true } },
            { no: 5, name: "string_match", kind: "message", oneof: "queryParameterMatchSpecifier", T: () => StringMatcher, options: { "validate.rules": { message: { required: true } } } },
            { no: 6, name: "present_match", kind: "scalar", oneof: "queryParameterMatchSpecifier", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.api.v2.route.QueryParameterMatcher
 */
export const QueryParameterMatcher = new QueryParameterMatcher$Type();
