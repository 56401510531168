import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { CollapseToggle } from 'Components/Common/CollapseToggle';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { colors } from 'Styles';
import { TruncateText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetJwtPolicyDetailsResponse_Phase,
  GetJwtPolicyDetailsResponse_Provider,
  GetJwtPolicyDetailsResponse_Provider_Jwks
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useState } from 'react';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetJwtPolicyDetails } = policiesApi;

const policyType = PolicyType.JWT;

const phaseMap: Record<GetJwtPolicyDetailsResponse_Phase, string> = {
  [GetJwtPolicyDetailsResponse_Phase.PRE_AUTH_Z]: 'preAuthZ',
  [GetJwtPolicyDetailsResponse_Phase.POST_AUTH_Z]: 'postAuthZ'
};

const jwkMap: Record<GetJwtPolicyDetailsResponse_Provider_Jwks, string> = {
  [GetJwtPolicyDetailsResponse_Provider_Jwks.LOCAL]: 'local',
  [GetJwtPolicyDetailsResponse_Provider_Jwks.REMOTE]: 'remote'
};

const SecondaryInfoTitleHolder = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const ClaimsGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(180px, 1fr);
  gap: 5px 20px;

  > ${TruncateText} {
    font-weight: 500;
  }
`;

const ProviderSection = ({
  provider,
  openByDefault = false
}: {
  provider: GetJwtPolicyDetailsResponse_Provider;
  openByDefault?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(!openByDefault);
  return (
    <CardStyles.Card onClick={collapsed ? () => setCollapsed(c => !c) : undefined}>
      <CardStyles.CardHeader style={collapsed ? { paddingBottom: 0 } : undefined}>
        {provider.name}

        <SecondaryInfoTitleHolder>
          <SecondaryInfo
            small
            items={[
              { label: 'Issuer', data: provider.issuer },
              {
                label: 'JWKs Type',
                data: jwkMap[provider.jwksSource],
                highlighted: {
                  primaryColor:
                    provider.jwksSource === GetJwtPolicyDetailsResponse_Provider_Jwks.LOCAL
                      ? colors.seaBlue
                      : colors.oceanBlue
                }
              }
            ]}
          />
        </SecondaryInfoTitleHolder>
        <CardStyles.CardHeaderRightIcon>
          <CollapseToggle collapsed={collapsed} onClick={!collapsed ? () => setCollapsed(c => !c) : undefined} />
        </CardStyles.CardHeaderRightIcon>
      </CardStyles.CardHeader>

      {!collapsed && (
        <StatMiniCardList
          items={[
            {
              title: 'Audience',
              icon: <Asset.HttpMethodIcon />,
              data: provider.audiences.map(a => (
                <SoloListCardStyles.Cell key={a}>
                  <div>{a}</div>
                  <SoloListCardStyles.DottedLined />
                </SoloListCardStyles.Cell>
              ))
            },
            {
              title: provider.claimsToHeaders.length ? 'ClaimsToHeaders' : 'There are no ClaimsToHeaders',
              icon: <Asset.HeaderManipulationPolicyIcon />,
              data: provider.claimsToHeaders.length ? (
                <ClaimsGrid>
                  {provider.claimsToHeaders.map(data => (
                    <React.Fragment key={`${data.claim}-${data.header}`}>
                      <TruncateText title={`claim: ${data.claim}`} maxWidth='auto'>
                        claim: {data.claim}
                      </TruncateText>
                      <div>
                        <SecondaryInfo
                          small
                          items={[
                            { label: 'Header', data: data.header },
                            {
                              label: 'Append',
                              data: data.append ? 'true' : 'false',
                              background: data.append ? colors.forestGreen : colors.augustGrey
                            }
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </ClaimsGrid>
              ) : (
                'No ClaimsToHeaders configured'
              )
            }
          ]}
        />
      )}
    </CardStyles.Card>
  );
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const JwtDetails = ({ clusterObjRef }: Props) => {
  di(useGetJwtPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetJwtPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
        secondaryInfo={[
          { label: 'Phase', data: phaseMap[policyData.phase] },
          !!policyData.priority && {
            label: 'Priority',
            data: policyData.priority.value
          }
        ]}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Providers
          <CardStyles.CardHeaderRightIcon>
            <Asset.ProviderIconWithLock />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        {policyData.providers.length ? (
          <CardStyles.CardList>
            {policyData.providers.map((provider, i) => (
              <ProviderSection
                key={`${provider.name}-${provider.issuer}`}
                provider={provider}
                openByDefault={i === 0}
              />
            ))}
          </CardStyles.CardList>
        ) : (
          <SoloEmptySimple description='No providers attached to this policy.' />
        )}
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
