import * as React from 'react';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { CardStyles } from './Card';

type State = {
  hasError: boolean;
  error: Error | null;
};

type ErrorBoundProps = React.PropsWithChildren<{
  fallback: React.ReactNode;
}>;

// Error boundaries currently have to be classes.
export class ErrorBoundary extends React.Component<ErrorBoundProps, State> {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error
    };
  }
  componentDidCatch(error: Error, info: object) {
    // eslint-disable-next-line no-console
    console.error('Error Boundary caught an error', error, info);
  }

  componentDidUpdate(prevProps: ErrorBoundProps) {
    if (prevProps.fallback !== this.props.fallback) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

const errorActionMessage = (thereWasAnErrorWith: string) => `There was an error ${thereWasAnErrorWith}.`;

type ErrorBoundaryBasicProps = Omit<ErrorBoundProps, 'fallback'> & { thereWasAnErrorWith: string };
export const ErrorBoundaryBasic = ({ thereWasAnErrorWith, children }: ErrorBoundaryBasicProps) => {
  return <ErrorBoundary fallback={errorActionMessage(thereWasAnErrorWith)}>{children}</ErrorBoundary>;
};

export const ErrorBoundaryCard = ({ fallback, children }: ErrorBoundProps) => {
  return (
    <ErrorBoundary
      fallback={
        <CardStyles.Card>
          <FlexLayout vertical horizontal>
            {{ fallback }}
          </FlexLayout>
        </CardStyles.Card>
      }>
      {children}
    </ErrorBoundary>
  );
};
