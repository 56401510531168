import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Properties } from 'csstype';
import { cssProp } from 'utils/helpers';
import { Spacer } from './spacer';

/**
 * Can be used to quickly create a grid layout.
 */
export const GridLayout = styled(Spacer)<{
  templateColumns?: Properties['gridTemplateColumns'];
  templateRows?: Properties['gridTemplateRows'];
  gap?: Properties['gap'] | number;
  justifyContent?: Properties['justifyContent'];
  alignItems?: Properties['alignItems'];
  height?: Properties['height'];
}>(({ templateColumns, templateRows, gap, justifyContent, alignItems, height }) => {
  if (typeof gap === 'number') gap = gap * 0.25 + 'em';
  return css`
    display: grid;
    ${cssProp('grid-template-columns', templateColumns)}
    ${cssProp('grid-template-rows', templateRows)}
    ${cssProp('justify-content', justifyContent)}
    ${cssProp('align-items', alignItems)}
    ${cssProp('gap', gap)}
    ${cssProp('height', height)}
    max-width: 100%;
    max-height: 100%;
  `;
});
