import { routingApi } from 'Api/routing';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { Asset } from 'assets';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import {
  Listener,
  ListenerType,
  Listener_TlsState
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routing_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getRouteTypeIcon } from 'utils/types';
import { RouteTablesListDisplay } from '../RoutesDisplay/RouteTablesListDisplay';
import { BasicWorkloadsTable } from './BasicWorkloadsTable';
import { VirtualGatewayDetailsStyles as Styles } from './VirtualGatewayDetails.style';

const listenerTypeMap = {
  [ListenerType.UNKNOWN_LISTENER_TYPE]: {
    name: 'Unknown listener type!'
  },
  [ListenerType.HTTP_LISTENER]: {
    name: 'HTTP'
  },
  [ListenerType.TCP_LISTENER]: {
    name: 'TCP'
  }
};

const listenerTlsStateMap = {
  [Listener_TlsState.UNKNOWN_TLS_STATE]: {
    desc: 'Unknown TLS type!'
  },
  [Listener_TlsState.NONE]: {
    desc: 'None' // Should be hidden in this case
  },
  [Listener_TlsState.HTTPS_REDIRECT]: {
    desc: 'HTTP Redirect Enabled'
  },
  [Listener_TlsState.TLS_ENABLED]: {
    desc: 'Enabled'
  }
};

const ListenerSection = ({ listener }: { listener: Listener }) => {
  return (
    <Styles.ListenerCard>
      <Styles.ListenerCardHeader>
        <strong>{listenerTypeMap[listener.type].name} Listener</strong>
        <Styles.ListenerCardHeaderDetails>
          <div>
            <strong>Port:</strong>{' '}
            {!!listener.port &&
              (listener.port.specifier.oneofKind === 'number' && listener.port.specifier.number > 0
                ? listener.port.specifier.number
                : listener.port.specifier.oneofKind === 'name'
                  ? listener.port.specifier.name
                  : '')}
          </div>
          {!!listener.tls && listener.tls !== Listener_TlsState.NONE && (
            <div>
              <strong>TLS:</strong> {listenerTlsStateMap[listener.tls].desc}
            </div>
          )}
        </Styles.ListenerCardHeaderDetails>
      </Styles.ListenerCardHeader>
      <Styles.ListenerCardBody>
        <Styles.ListenerCardSubheader>Attached Route Tables</Styles.ListenerCardSubheader>
        <RouteTablesListDisplay routeTables={listener.routeTables} parentRef='routetable' />
      </Styles.ListenerCardBody>
    </Styles.ListenerCard>
  );
};

const { useGetVirtualGatewayDetails } = routingApi;

export const VirtualGatewayDetails = ({ clusterObjRef }: { clusterObjRef: ClusterObjectRef }) => {
  di(useGetVirtualGatewayDetails);

  const workloadsPaging = useSoloPaging();

  const { data: vgData, error: vgError } = useGetVirtualGatewayDetails(clusterObjRef);

  workloadsPaging.useSetPagingTotal(vgData?.totalGatewayWorkloads);

  if (!!vgError || !vgData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getRouteTypeIcon(RouteType.VIRTUAL_GATEWAY)} objRef={clusterObjRef} />
        {!!vgError ? (
          <DataError error={vgError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} virtual gateway details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList data-testid='virtual-gateway-details'>
      <DetailsHeaderCard
        icon={getRouteTypeIcon(RouteType.VIRTUAL_GATEWAY)}
        status={vgData?.status}
        objRef={clusterObjRef}
        workspaceRef={vgData?.ownerWorkspace?.ref}
        resourceType={ResourceType.VIRTUAL_GATEWAY}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Listeners
          <CardStyles.CardHeaderRightIcon>
            <Asset.ListenerIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>
        <div>
          {vgData.listeners
            .sort((a, b) => a.type - b.type)
            .map(listener => (
              <ListenerSection key={`${listener.port}-${listener.type}`} listener={listener} />
            ))}
        </div>
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Gateway Workloads
          <CardStyles.CardHeaderRightIcon>
            <Asset.WorkloadIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicWorkloadsTable workloads={vgData?.workloads} paging={workloadsPaging.pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
