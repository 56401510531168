import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

export namespace SoloTextareaStyles {
  export const Container = styled.div``;

  interface TextareaStyledProps {
    error?: boolean;
    borderless?: boolean;
    resize?: React.CSSProperties['resize'];
  }
  export const Textarea = styled.textarea<TextareaStyledProps>(
    ({ theme, error, borderless, resize = 'none' }) => css`
      width: 100%;
      padding: 5px 10px;
      border-radius: 3px;
      line-height: 16px;
      outline: none;

      border: 1px solid ${error ? theme.grapefruitOrange : borderless ? 'none' : theme.aprilGrey};

      resize: ${resize};

      &:disabled {
        background: ${theme.marchGrey};
        color: ${theme.septemberGrey};
        border: 1px solid ${theme.juneGrey};
      }

      &::placeholder {
        color: ${theme.juneGrey};
      }
    `
  );
}
