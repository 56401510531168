import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloListCardWithColumns } from 'Components/Common/SoloListCard';
import { renderHealthIndicatorWithName, StateAndNameType } from 'Components/Common/SoloTableCells';
import { GetWorkspaceDetailsResponse_ClusterNamespaces } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';

interface ClusterTableFields {
  key: string;
  cluster: StateAndNameType;
  namespaces: { data: string; highlighted: boolean }[];
}
export const ClusterNamespacesSection = ({
  clusterNamespaces,
  workspaceSettingsRef
}: {
  clusterNamespaces: GetWorkspaceDetailsResponse_ClusterNamespaces[] | undefined;
  workspaceSettingsRef: ClusterObjectRef | undefined;
}) => {
  const tableData = useMemo<ClusterTableFields[]>(() => {
    return (
      clusterNamespaces?.map(({ cluster, namespaces, status }) => ({
        key: cluster,
        cluster: { name: cluster, status: status },
        namespaces: namespaces.map(ns => {
          const highlighted = ns === workspaceSettingsRef?.namespace && cluster === workspaceSettingsRef?.clusterName;
          return {
            data: ns,
            highlighted,
            tooltipText: highlighted ? 'Settings Namespace' : undefined
          };
        }),
        status: status
      })) ?? []
    );
  }, [clusterNamespaces]);
  return (
    <SoloListCardWithColumns
      columns={[{ title: 'Cluster in Workspace', width: '30%' }, { title: 'Namespaces' }]}
      rows={tableData.map(c => ({
        key: c.key,
        cells: [
          { data: renderHealthIndicatorWithName(c.cluster) },
          { data: <SecondaryInfo items={c.namespaces} small overflowAfter={20} /> }
        ]
      }))}
    />
  );
};
