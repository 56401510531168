import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace FlagsLandingStyles {
  export const Container = styled(CardStyles.Card)`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
  `;

  export const TitleLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }
  `;
  export const Title = styled.div`
    font-size: 28px;
  `;
  export const TitleIconHolder = styled.div`
    margin-right: 10px;

    ${svgBasics(24)}
  `;
  export const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 10px;
    align-items: center;
    margin: 15px 0 20px;

    ${svgBasics({ width: 20, height: 'auto' }, 'black')}
  `;
  export const ToggleLabel = styled.strong`
    margin-left: 10px;
  `;
}
