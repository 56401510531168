import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { Asset } from 'assets';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useEffect, useState } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { docLinks } from 'utils/url-external-links-map';

type TableFields = {
  key: string;
  name: StateAndNameType;
  clusterName?: string;
  namespace?: string;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' })
];

interface Props {
  workloads:
    | {
        workloadRef?: ClusterObjectRef;
        status?: Status;
      }[]
    | undefined;
  paging: PagingProps;
}
export const BasicWorkloadsTable = ({ workloads, paging }: Props) => {
  const [tableData, setTableData] = useState<TableFields[]>([]);

  useEffect(() => {
    if (!!workloads) {
      setTableData(
        workloads.map(data => ({
          ...data,
          ...data.workloadRef,
          key: buildIdFromRef(data.workloadRef),
          name: { name: data.workloadRef?.name ?? '', status: data.status }
        }))
      );
    } else {
      setTableData([]);
    }
  }, [workloads]);

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={paging}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.WorkloadIcon />}
          title='There are no Workloads to display'
          description=''
          href={docLinks.enterprise.root}
        />
      }
    />
  );
};
