import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Asset } from 'assets';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { CardStyles } from 'Components/Common/Card';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { HorizontalRule } from 'Styles/CommonEmotions/dividers';
import { FancyCodeDisplayerStyles } from 'Components/Common/FancyCodeDisplayer.style';

export namespace TransformationDetailsStyles {
  export const InfoIconSI = styled(Asset.InfoIcon)`
    width: 17.75px;
    height: auto;
    position: relative;
    left: 5px;
  `;

  export const WhiteLink = styled.a`
    color: white;
    :hover {
      color: white;
      text-decoration: underline;
    }
  `;

  interface ColonListGridProps {
    labelWidth?: number;
  }
  export const ColonListGrid = styled.div<ColonListGridProps>(({ labelWidth = 95 }) => {
    return css`
      display: grid;
      grid-template-columns: ${labelWidth}px minmax(0, 1fr);
      gap: 20px;
    `;
  });

  export const HorizontalRuleLight = styled(HorizontalRule)(
    ({ theme }) => `background: ${theme.splashBlue}; margin:15px 0;`
  );

  export const CardSubheader = styled(CardStyles.CardHeader)`
    font-size: 18px;
    margin-top: 0;
    line-height: 1;
  `;
  interface CodeExpanderWrapperProps {
    expanded: boolean;
  }
  export const CodeExpanderWrapper = styled.div<CodeExpanderWrapperProps>(
    ({ expanded }) => css`
      ${expanded
        ? ''
        : css`
            ${FancyCodeDisplayerStyles.Container} {
              position: relative;
              height: 112px;
              overflow-y: hidden;
              :after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 40px;
                bottom: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
                pointer-events: none;
              }
            }
            ${SlimCodeContainer} {
              margin-bottom: 0;
            }
          `}
      ${SoloLinkStyles.SoloLinkLooks} {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        > div {
          margin-left: 5px;
          ${svgBasics(14)}
        }
      }
    `
  );

  export const SlimCodeContainer = styled.div`
    overflow: auto;
    width: 100%;
    margin-left: -0.5em;
    line-height: normal;

    ${FancyCodeDisplayerStyles.FancyCodeContainer} {
      margin: 0;
    }
  `;

  export const NumberlessCodeContainer = styled.div`
    ${FancyCodeDisplayerStyles.Container} {
      padding-left: 0;
    }
    ${FancyCodeDisplayerStyles.FancyCodeContainer} {
      margin: 0;
    }
    ${FancyCodeDisplayerStyles.LineNo} {
      display: none;
    }
  `;
}
