import React from 'react';
import moment from 'moment';
import { SoloDateTimePickerStyles as Styles } from './SoloDateTimePicker.style';
import { useSoloId } from 'utils/hooks';
import { SoloLabel } from './SoloLabel';

const getDisabledHours = function (isSameDay: boolean) {
  if (!isSameDay) {
    return [];
  }
  const hours = [];
  for (let i = 23; i > moment().hour(); i--) {
    hours.push(i);
  }
  return hours;
};

const getDisabledMinutes = (selectedHour: number, isSameDay: boolean) => {
  if (!isSameDay) {
    return [];
  }
  const minutes = [];
  if (selectedHour === moment().hour()) {
    for (let i = 59; i > moment().minute(); i--) {
      minutes.push(i);
    }
  }
  return minutes;
};

const getDisabledSeconds = (selectedMinute: number, isSameDay: boolean) => {
  if (!isSameDay) {
    return [];
  }
  const seconds = [];
  if (selectedMinute === moment().minute()) {
    for (let i = 59; i > moment().second(); i--) {
      seconds.push(i);
    }
  }
  return seconds;
};

export const disableFutureRange = (currentDate: any) => {
  const isSameDay = moment().isSame(currentDate, 'day');
  return {
    disabledHours: () => getDisabledHours(isSameDay),
    disabledMinutes: (selectedHour: number) => getDisabledMinutes(selectedHour, isSameDay),
    disabledSeconds: (x: any, selectedMinute: number) => getDisabledSeconds(selectedMinute, isSameDay)
  };
};

export type rangeType = null | undefined | [Date | moment.Moment | undefined, Date | moment.Moment | undefined];

export interface DateTimeProps {
  value: rangeType;
  setDates?: (newValue?: rangeType) => any;
  label?: string;
  disableFutureTime?: boolean;
  placeholder?: [string, string];
  defaultValue?: rangeType;
  onBlur?: (evt: React.FocusEvent) => any;
  disabled?: boolean;
  disabledDate?: (value: moment.Moment) => boolean;
  id?: string;
  testId?: string;
  error?: any;
}

export const SoloDateTimePicker = (props: DateTimeProps) => {
  const { value, label, setDates, disableFutureTime, testId, error, defaultValue, id, ...rest } = props;
  const inputId = useSoloId(id);

  return (
    <div data-testid='solo-date-time-picker-wrapper' style={{ width: '100%' }}>
      {label && <SoloLabel htmlFor={inputId}>{label}</SoloLabel>}
      <Styles.SoloDateTimeBlock
        id={inputId}
        data-testid={testId}
        value={value as any}
        disabledTime={disableFutureTime ? disableFutureRange : undefined}
        showTime={true}
        onCalendarChange={setDates as any}
        defaultValue={defaultValue as any}
        {...rest}
      />
    </div>
  );
};
