import { dashboardApi } from 'Api/dashboard';
import { ExternalSoloLink, SoloLink } from 'Components/Common/SoloLink';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { DisplayNotificationProps, NoticeUrgencyLevel, useDisplayNotification } from 'utils/notificationsystem';
import { Permission, usePermissions } from 'utils/permissions';
import { buildClusterDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
const { useGetSafeModeStatus } = dashboardApi;

export const useSafeModeNotificationChecker = () => {
  di(useDisplayNotification, useGetSafeModeStatus, usePermissions);
  const { hasPerm } = usePermissions();
  const [notification, setNotification] = useState<DisplayNotificationProps>();

  const { data } = useGetSafeModeStatus();

  useDisplayNotification(notification);

  useEffect(() => {
    let notification: DisplayNotificationProps | undefined = undefined;
    if (data?.safeModeActive) {
      const clusters = data?.clustersCausingSafeMode ?? []; // list should never be returned empty if safe mode is active
      notification = {
        label: 'Safe Mode',
        message: (
          <>
            The following cluster{clusters.length === 1 ? '' : 's'} triggered the Gloo management server to be in safe
            mode:{' '}
            {hasPerm(Permission.CoreLicense) ? (
              clusters.map((c, i, arr) => [
                <SoloLink key={c} link={buildClusterDetailsUrl(c)} inline>
                  {c}
                </SoloLink>,
                i < arr.length - 1 ? ', ' : ''
              ])
            ) : (
              <SoloLink link='/' inline>
                {clusters.join(', ')}
              </SoloLink>
            )}
            . Safe mode ensures that translation resumes only when the input snapshots of all registered Gloo agents are
            present either in Redis or the Gloo management server's local memory. For more info, see the{' '}
            <ExternalSoloLink href={docLinks.enterprise.recommended_setup_HASH.redis_safemode} newTab inline>
              docs
            </ExternalSoloLink>
            .
          </>
        ),
        dismissable: true,
        level: NoticeUrgencyLevel.Warning,
        'data-testid': `safe-mode-notification`
      };
    }
    setNotification(notification);
  }, [data?.safeModeActive, data?.clustersCausingSafeMode]);
};
