import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics, svgColorReplace } from 'Styles/CommonEmotions/utils';
import { getGraphItemColors } from 'Styles/graph';
import { GraphMetricsType, GraphStatus } from '../graph-selection-utils';

export namespace GraphLegendStyles {
  export const Subsection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-bottom: 26px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  `;

  export const SubTitle = styled.div`
    line-height: 19px;
    margin-bottom: 10px;
    font-size: 18px;
    grid-column: 1 / span 2;
  `;
  export const SubsubTitle = styled(SubTitle)`
    line-height: 18px;
    margin: 5px 0;
    font-size: 16px;
  `;

  export const IconRow = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;

    > div:first-of-type {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: 100%;
      border: 2px solid ${getGraphItemColors(GraphMetricsType.Http, GraphStatus.Healthy).node};

      ${svgBasics(24)}
    }

    > div:last-of-type {
      line-height: 16px;
    }
  `;

  export const SmallIconRow = styled(IconRow)`
    > div:first-of-type {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 6px;
      border: none;

      ${svgBasics(16)}
      &.black-fill {
        ${svgColorReplace('black')}
      }
    }
  `;

  interface StatusIconRowProps {
    metricsType?: GraphMetricsType;
    health: GraphStatus;
  }
  export const StatusIconRow = styled(IconRow)<StatusIconRowProps>(
    ({ metricsType = GraphMetricsType.Http, health, theme }) => css`
      margin-bottom: 10px;

      > div:first-of-type {
        border-color: ${getGraphItemColors(metricsType, health).node};

        &:after {
          content: '';
          position: absolute;

          ${health === GraphStatus.Error || health === GraphStatus.Warning
            ? css`
                height: 48px;
                width: 48px;
                top: -6px;
                left: -6px;
                border-radius: 100%;
                border: 2px solid ${health === GraphStatus.Error ? theme.tangerineOrange : theme.lampGold};
              `
            : ''}
        }
      }
    `
  );

  export const ExtraInfo = styled.div`
    display: none; /* for now */
    font-style: italic;
    font-size: 10px;
  `;

  interface EdgeCellProps {
    forceNewRow?: boolean;
  }
  export const EdgeCell = styled.div<EdgeCellProps>(
    ({ forceNewRow }) => css`
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 10px;
      ${forceNewRow
        ? css`
            grid-column-start: 1;
          `
        : ''}
    `
  );

  type EdgeLineProps = {
    metricsType?: GraphMetricsType;
    health: GraphStatus | 'black';
    edgeStyle?: 'dashed' | 'solid';
    edgeLength?: number;
  };
  export const EdgeLine = styled.div<EdgeLineProps>(
    ({ metricsType = GraphMetricsType.Http, health, edgeStyle = 'dashed', edgeLength = 0 }) => css`
      position: relative;
      width: ${edgeLength}px;
      min-width: ${edgeLength}px;
      border-bottom: 2px ${edgeStyle} transparent;
      border-bottom-color: ${health === 'black' ? health : getGraphItemColors(metricsType, health).edge};
      padding-right: 7px;
      margin-right: 3px;

      // Create the arrow head
      &:after {
        content: '';
        position: absolute;
        top: -5px;
        right: -3px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid ${health === 'black' ? health : getGraphItemColors(metricsType, health).edge};
      }
    `
  );
}
