import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

// Note: For info about Vite SVG imports, see https://vitejs.dev/guide/assets#importing-asset-as-url
import BreadcrumbSeperator from 'assets/breadcrumb-seperator.svg';

export namespace BreadcrumbStyles {
  export const List = styled.ol(
    ({ theme }) => css`
      display: flex;
      flex-wrap: wrap;
      gap: 0 5px;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 18px;
      color: ${theme.oceanBlue};

      height: 50px; // needed for overflow-y to work
      overflow-y: auto; // creates scrollbar on the insanely rare case we get 3+ lines of breadcrumbs
    `
  );

  export const ListItem = styled.li(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      gap: 5px;
      line-height: 1.2;

      &:not(:last-child):after {
        content: '';
        // Note: When passing a URL of SVG to a manually constructed url() by JS,
        // the variable should be wrapped within double quotes.
        // Source: https://vitejs.dev/guide/assets#importing-asset-as-url
        background: url(${'"' + BreadcrumbSeperator + '"'}) center no-repeat;
        background-size: 16px;
        height: 16px;
        width: 16px;
        pointer-events: none;
      }

      &:last-of-type,
      &:last-of-type a {
        cursor: default;
        color: ${theme.oceanBlue};
        pointer-events: none;
        font-weight: 400;
      }
    `
  );

  export const CrumbLink = styled(Link)(
    ({ theme }) => css`
      color: ${theme.augustGrey};
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: ${theme.novemberGrey};
      }
    `
  );
}
