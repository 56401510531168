/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "xds/type/matcher/v3/matcher.proto" (package "xds.type.matcher.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { StringMatcher } from "./string_pb";
import { TypedExtensionConfig } from "../../../core/v3/extension_pb";
// [#protodoc-title: Unified Matcher API]

/**
 * A matcher, which may traverse a matching tree in order to result in a match action.
 * During matching, the tree will be traversed until a match is found, or if no match
 * is found the action specified by the most specific on_no_match will be evaluated.
 * As an on_no_match might result in another matching tree being evaluated, this process
 * might repeat several times until the final OnMatch (or no match) is decided.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher
 */
export interface Matcher {
    /**
     * @generated from protobuf oneof: matcher_type
     */
    matcherType: {
        oneofKind: "matcherList";
        /**
         * A linear list of matchers to evaluate.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherList matcher_list = 1;
         */
        matcherList: Matcher_MatcherList;
    } | {
        oneofKind: "matcherTree";
        /**
         * A match tree to evaluate.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherTree matcher_tree = 2;
         */
        matcherTree: Matcher_MatcherTree;
    } | {
        oneofKind: undefined;
    };
    /**
     * Optional OnMatch to use if no matcher above matched (e.g., if there are no matchers specified
     * above, or if none of the matches specified above succeeded).
     * If no matcher above matched and this field is not populated, the match will be considered unsuccessful.
     *
     * @generated from protobuf field: xds.type.matcher.v3.Matcher.OnMatch on_no_match = 3;
     */
    onNoMatch?: Matcher_OnMatch;
}
/**
 * What to do if a match is successful.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.OnMatch
 */
export interface Matcher_OnMatch {
    /**
     * @generated from protobuf oneof: on_match
     */
    onMatch: {
        oneofKind: "matcher";
        /**
         * Nested matcher to evaluate.
         * If the nested matcher does not match and does not specify
         * on_no_match, then this matcher is considered not to have
         * matched, even if a predicate at this level or above returned
         * true.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher matcher = 1;
         */
        matcher: Matcher;
    } | {
        oneofKind: "action";
        /**
         * Protocol-specific action to take.
         *
         * @generated from protobuf field: xds.core.v3.TypedExtensionConfig action = 2;
         */
        action: TypedExtensionConfig;
    } | {
        oneofKind: undefined;
    };
}
/**
 * A linear list of field matchers.
 * The field matchers are evaluated in order, and the first match
 * wins.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherList
 */
export interface Matcher_MatcherList {
    /**
     * A list of matchers. First match wins.
     *
     * @generated from protobuf field: repeated xds.type.matcher.v3.Matcher.MatcherList.FieldMatcher matchers = 1;
     */
    matchers: Matcher_MatcherList_FieldMatcher[];
}
/**
 * Predicate to determine if a match is successful.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherList.Predicate
 */
export interface Matcher_MatcherList_Predicate {
    /**
     * @generated from protobuf oneof: match_type
     */
    matchType: {
        oneofKind: "singlePredicate";
        /**
         * A single predicate to evaluate.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherList.Predicate.SinglePredicate single_predicate = 1;
         */
        singlePredicate: Matcher_MatcherList_Predicate_SinglePredicate;
    } | {
        oneofKind: "orMatcher";
        /**
         * A list of predicates to be OR-ed together.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherList.Predicate.PredicateList or_matcher = 2;
         */
        orMatcher: Matcher_MatcherList_Predicate_PredicateList;
    } | {
        oneofKind: "andMatcher";
        /**
         * A list of predicates to be AND-ed together.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherList.Predicate.PredicateList and_matcher = 3;
         */
        andMatcher: Matcher_MatcherList_Predicate_PredicateList;
    } | {
        oneofKind: "notMatcher";
        /**
         * The invert of a predicate
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherList.Predicate not_matcher = 4;
         */
        notMatcher: Matcher_MatcherList_Predicate;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Predicate for a single input field.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherList.Predicate.SinglePredicate
 */
export interface Matcher_MatcherList_Predicate_SinglePredicate {
    /**
     * Protocol-specific specification of input field to match on.
     * [#extension-category: envoy.matching.common_inputs]
     *
     * @generated from protobuf field: xds.core.v3.TypedExtensionConfig input = 1;
     */
    input?: TypedExtensionConfig;
    /**
     * @generated from protobuf oneof: matcher
     */
    matcher: {
        oneofKind: "valueMatch";
        /**
         * Built-in string matcher.
         *
         * @generated from protobuf field: xds.type.matcher.v3.StringMatcher value_match = 2;
         */
        valueMatch: StringMatcher;
    } | {
        oneofKind: "customMatch";
        /**
         * Extension for custom matching logic.
         * [#extension-category: envoy.matching.input_matchers]
         *
         * @generated from protobuf field: xds.core.v3.TypedExtensionConfig custom_match = 3;
         */
        customMatch: TypedExtensionConfig;
    } | {
        oneofKind: undefined;
    };
}
/**
 * A list of two or more matchers. Used to allow using a list within a oneof.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherList.Predicate.PredicateList
 */
export interface Matcher_MatcherList_Predicate_PredicateList {
    /**
     * @generated from protobuf field: repeated xds.type.matcher.v3.Matcher.MatcherList.Predicate predicate = 1;
     */
    predicate: Matcher_MatcherList_Predicate[];
}
/**
 * An individual matcher.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherList.FieldMatcher
 */
export interface Matcher_MatcherList_FieldMatcher {
    /**
     * Determines if the match succeeds.
     *
     * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherList.Predicate predicate = 1;
     */
    predicate?: Matcher_MatcherList_Predicate;
    /**
     * What to do if the match succeeds.
     *
     * @generated from protobuf field: xds.type.matcher.v3.Matcher.OnMatch on_match = 2;
     */
    onMatch?: Matcher_OnMatch;
}
/**
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherTree
 */
export interface Matcher_MatcherTree {
    /**
     * Protocol-specific specification of input field to match on.
     *
     * @generated from protobuf field: xds.core.v3.TypedExtensionConfig input = 1;
     */
    input?: TypedExtensionConfig;
    /**
     * @generated from protobuf oneof: tree_type
     */
    treeType: {
        oneofKind: "exactMatchMap";
        /**
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherTree.MatchMap exact_match_map = 2;
         */
        exactMatchMap: Matcher_MatcherTree_MatchMap;
    } | {
        oneofKind: "prefixMatchMap";
        /**
         * Longest matching prefix wins.
         *
         * @generated from protobuf field: xds.type.matcher.v3.Matcher.MatcherTree.MatchMap prefix_match_map = 3;
         */
        prefixMatchMap: Matcher_MatcherTree_MatchMap;
    } | {
        oneofKind: "customMatch";
        /**
         * Extension for custom matching logic.
         *
         * @generated from protobuf field: xds.core.v3.TypedExtensionConfig custom_match = 4;
         */
        customMatch: TypedExtensionConfig;
    } | {
        oneofKind: undefined;
    };
}
/**
 * A map of configured matchers. Used to allow using a map within a oneof.
 *
 * @generated from protobuf message xds.type.matcher.v3.Matcher.MatcherTree.MatchMap
 */
export interface Matcher_MatcherTree_MatchMap {
    /**
     * @generated from protobuf field: map<string, xds.type.matcher.v3.Matcher.OnMatch> map = 1;
     */
    map: {
        [key: string]: Matcher_OnMatch;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class Matcher$Type extends MessageType<Matcher> {
    constructor() {
        super("xds.type.matcher.v3.Matcher", [
            { no: 1, name: "matcher_list", kind: "message", oneof: "matcherType", T: () => Matcher_MatcherList },
            { no: 2, name: "matcher_tree", kind: "message", oneof: "matcherType", T: () => Matcher_MatcherTree },
            { no: 3, name: "on_no_match", kind: "message", T: () => Matcher_OnMatch }
        ], { "xds.annotations.v3.message_status": { workInProgress: true } });
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher
 */
export const Matcher = new Matcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_OnMatch$Type extends MessageType<Matcher_OnMatch> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.OnMatch", [
            { no: 1, name: "matcher", kind: "message", oneof: "onMatch", T: () => Matcher },
            { no: 2, name: "action", kind: "message", oneof: "onMatch", T: () => TypedExtensionConfig }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.OnMatch
 */
export const Matcher_OnMatch = new Matcher_OnMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherList$Type extends MessageType<Matcher_MatcherList> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherList", [
            { no: 1, name: "matchers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Matcher_MatcherList_FieldMatcher, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherList
 */
export const Matcher_MatcherList = new Matcher_MatcherList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherList_Predicate$Type extends MessageType<Matcher_MatcherList_Predicate> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherList.Predicate", [
            { no: 1, name: "single_predicate", kind: "message", oneof: "matchType", T: () => Matcher_MatcherList_Predicate_SinglePredicate },
            { no: 2, name: "or_matcher", kind: "message", oneof: "matchType", T: () => Matcher_MatcherList_Predicate_PredicateList },
            { no: 3, name: "and_matcher", kind: "message", oneof: "matchType", T: () => Matcher_MatcherList_Predicate_PredicateList },
            { no: 4, name: "not_matcher", kind: "message", oneof: "matchType", T: () => Matcher_MatcherList_Predicate }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherList.Predicate
 */
export const Matcher_MatcherList_Predicate = new Matcher_MatcherList_Predicate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherList_Predicate_SinglePredicate$Type extends MessageType<Matcher_MatcherList_Predicate_SinglePredicate> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherList.Predicate.SinglePredicate", [
            { no: 1, name: "input", kind: "message", T: () => TypedExtensionConfig, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "value_match", kind: "message", oneof: "matcher", T: () => StringMatcher },
            { no: 3, name: "custom_match", kind: "message", oneof: "matcher", T: () => TypedExtensionConfig }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherList.Predicate.SinglePredicate
 */
export const Matcher_MatcherList_Predicate_SinglePredicate = new Matcher_MatcherList_Predicate_SinglePredicate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherList_Predicate_PredicateList$Type extends MessageType<Matcher_MatcherList_Predicate_PredicateList> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherList.Predicate.PredicateList", [
            { no: 1, name: "predicate", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Matcher_MatcherList_Predicate, options: { "validate.rules": { repeated: { minItems: "2" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherList.Predicate.PredicateList
 */
export const Matcher_MatcherList_Predicate_PredicateList = new Matcher_MatcherList_Predicate_PredicateList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherList_FieldMatcher$Type extends MessageType<Matcher_MatcherList_FieldMatcher> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherList.FieldMatcher", [
            { no: 1, name: "predicate", kind: "message", T: () => Matcher_MatcherList_Predicate, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "on_match", kind: "message", T: () => Matcher_OnMatch, options: { "validate.rules": { message: { required: true } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherList.FieldMatcher
 */
export const Matcher_MatcherList_FieldMatcher = new Matcher_MatcherList_FieldMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherTree$Type extends MessageType<Matcher_MatcherTree> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherTree", [
            { no: 1, name: "input", kind: "message", T: () => TypedExtensionConfig, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "exact_match_map", kind: "message", oneof: "treeType", T: () => Matcher_MatcherTree_MatchMap },
            { no: 3, name: "prefix_match_map", kind: "message", oneof: "treeType", T: () => Matcher_MatcherTree_MatchMap },
            { no: 4, name: "custom_match", kind: "message", oneof: "treeType", T: () => TypedExtensionConfig }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherTree
 */
export const Matcher_MatcherTree = new Matcher_MatcherTree$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Matcher_MatcherTree_MatchMap$Type extends MessageType<Matcher_MatcherTree_MatchMap> {
    constructor() {
        super("xds.type.matcher.v3.Matcher.MatcherTree.MatchMap", [
            { no: 1, name: "map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Matcher_OnMatch }, options: { "validate.rules": { map: { minPairs: "1" } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.type.matcher.v3.Matcher.MatcherTree.MatchMap
 */
export const Matcher_MatcherTree_MatchMap = new Matcher_MatcherTree_MatchMap$Type();
