import React from 'react';
import { GqlSchemaTabStyles } from './Schema/Tabs/GqlSchemaTabStyles.style';

export interface RenderDefinitionNameLinkProps {
  name: string;
  tabHeader: string;
  onTypeClick: (itemName: string, searchForTheTab: boolean) => void;
}
export function renderDefinitionNameLink({ name, tabHeader, onTypeClick }: RenderDefinitionNameLinkProps) {
  return (
    <GqlSchemaTabStyles.NameLink
      onClick={() => onTypeClick(`${tabHeader}/${name}`, false)}
      data-testid='definition-name-link'>
      {name}
    </GqlSchemaTabStyles.NameLink>
  );
}

export function renderCenteredTableCell(text: React.ReactNode) {
  return <GqlSchemaTabStyles.CenteredTableCell>{text}</GqlSchemaTabStyles.CenteredTableCell>;
}
