import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn,
  RenderCellStringList,
  RenderCellStringListProps,
  RenderImportStatus,
  StateAndNameType
} from 'Components/Common/SoloTableCells';
import { ImportStatus } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  ListWorkspaceRoutesResponse_WorkspaceRoute,
  RouteType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { getRouteTypeIcon, getRouteTypeReadableName } from 'utils/types';
import { buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';

type TableFields = {
  key: string;
  name: StateAndNameType;
  namespace?: string;
  clusterName?: string;
  type: RouteType;
  importStatus: ImportStatus;
  hosts: RenderCellStringListProps;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render(type) {
      return (
        <Styles.CellIconText>
          {getRouteTypeIcon(type)} {getRouteTypeReadableName(type)}
        </Styles.CellIconText>
      );
    }
  },
  {
    title: 'Import Status',
    dataIndex: 'importStatus',
    render: RenderImportStatus
  },
  {
    title: 'Hosts',
    dataIndex: 'hosts',
    render: RenderCellStringList
  }
];

interface Props {
  workspaceName: string;
  workspaceRoutes: ListWorkspaceRoutesResponse_WorkspaceRoute[];
  pagingData: PagingProps;
}
export const WorkspaceRoutesTable = ({ workspaceName, workspaceRoutes, pagingData }: Props) => {
  const tableData = useMemo<TableFields[]>(() => {
    return workspaceRoutes.map(data => ({
      key: buildIdFromRef(data.routeRef),
      name: {
        name: data.routeRef?.name ?? '',
        status: data.status,
        link: buildWorkspaceRouteDetailsUrl(workspaceName, data.type, data.routeRef)
      },
      namespace: data.routeRef?.namespace,
      clusterName: data.routeRef?.clusterName,
      type: data.type,
      importStatus: data.importStatus,
      hosts: { list: data.hosts }
    }));
  }, [workspaceRoutes, workspaceName]);

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='routing-tab-table'
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.RouteGroupIcon />}
          title='There are no Routes to display'
          description=''
          href={docLinks.enterprise.routing}
        />
      }
    />
  );
};
