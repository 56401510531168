import { Asset } from 'assets';
import { DetailsHeaderCardWithoutDefaults } from 'Components/Common/DetailsHeaderCard';
import { DetailsHeaderCardStyles } from 'Components/Common/DetailsHeaderCard.style';
import { HealthIndicatorIcon } from 'Components/Common/HealthIndicator';
import { SvgWithTextContainer } from 'Components/Common/Svg';
import { GetClusterDetailsCardsResponse_ClusterVersions } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import React from 'react';

export const ClusterDetailsLandingHeaderPreLoaded = ({ clusterName }: { clusterName: string | undefined }) => {
  return (
    <DetailsHeaderCardStyles.HeaderInfo>
      <HealthIndicatorIcon status={undefined} size={60} icon={<Asset.ClusterIcon />} />
      <DetailsHeaderCardStyles.NameContainer>
        <DetailsHeaderCardStyles.Name>{clusterName ?? 'unknown'}</DetailsHeaderCardStyles.Name>
      </DetailsHeaderCardStyles.NameContainer>
    </DetailsHeaderCardStyles.HeaderInfo>
  );
};

export const ClusterDetailsLandingHeader = ({
  clusterName,
  clusterVersions
}: {
  clusterName: string | undefined;
  clusterVersions: GetClusterDetailsCardsResponse_ClusterVersions | undefined;
}) => {
  const headerSecondaryLabel = (icon: React.ReactNode, text: string) => (
    <SvgWithTextContainer>
      {icon} {text}
    </SvgWithTextContainer>
  );

  return (
    <DetailsHeaderCardWithoutDefaults
      title={clusterName ?? 'unknown'}
      icon={<Asset.ClusterIcon />}
      status={clusterVersions?.status}
      secondaryInfo={[
        !!clusterVersions?.region && {
          label: headerSecondaryLabel(<Asset.RegionIcon />, 'Region'),
          data: clusterVersions.region,
          key: `cluster-region_${clusterVersions.region}`
        },
        !!clusterVersions?.glooMeshAgentVersion && {
          label: headerSecondaryLabel(<Asset.GlooOutlineIcon />, 'Agent'),
          data: clusterVersions.glooMeshAgentVersion,
          key: `cluster-version_${clusterVersions.glooMeshAgentVersion}`
        },
        !!clusterVersions?.kubernetesVersion && {
          label: headerSecondaryLabel(<Asset.KubernetesTypeIcon />, 'K8s'),
          data: clusterVersions.kubernetesVersion,
          key: `kubenetes-version_${clusterVersions.kubernetesVersion}`
        },
        /*!!clusterVersions?.istioVersionsList && {
                label: headerSecondaryLabel(<Asset.IstioLogo />, 'Istio'),
                data: clusterVersions.istioVersions,
                key: `istio-version_${clusterVersions.istioVersions}`
              },*/
        !!clusterVersions?.ciliumVersion && {
          label: headerSecondaryLabel(<Asset.CiliumIcon />, 'Cilium'),
          data: clusterVersions.ciliumVersion,
          key: `cilium-version_${clusterVersions.ciliumVersion}`
        }
      ]}
      yaml={
        !!clusterVersions?.clusterRef
          ? {
              resourceType: ResourceType.KUBERNETES_CLUSTER,
              clusterObjectRef: clusterVersions?.clusterRef
            }
          : undefined
      }
    />
  );
};
