import { rangeType } from 'Components/Common/Input/SoloDateTimePicker';
import { Duration } from 'proto/google/protobuf/duration_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Permission, usePermissions } from 'utils/permissions';
import { useGetGraphCache } from '../General/graph-cache';
import { DISABLE_REFRESH } from '../MainGraph/GraphFiltersHelpers';

interface FetchSettings {
  timeInterval: number;
  refreshRate: number;
  pullTime: Date;

  endTime: Timestamp;
  window: Duration;
  step: Duration;
  istioMetrics: boolean;
  ciliumMetrics: boolean;
  tcpMetrics: boolean;
}

export interface GraphFetchSettingsContextType {
  range: rangeType;
  setRange: Dispatch<SetStateAction<rangeType>>;
  timeInterval: number;
  setTimeInterval: Dispatch<SetStateAction<number>>;
  refreshRate: number;
  setRefreshRate: Dispatch<SetStateAction<number>>;
  pullTime: Date;
  setPullTime: Dispatch<SetStateAction<Date>>;

  fetchSettings: FetchSettings;

  refreshGraphData(graphMutate: () => void): void;
  toggleRefreshPause(pause: boolean, graphMutate: () => void): void;
}

export const GraphFetchSettingsContext = createContext<GraphFetchSettingsContextType | null>(null);

interface ProviderProps {
  children: ReactNode;
}
export const GraphFetchSettingsContextProvider = ({ children }: ProviderProps) => {
  const { cache, updateCache } = useGetGraphCache();
  const { hasPerm } = usePermissions();

  const tcpOn = cache.tcpOn && hasPerm(Permission.TcpEnabled);
  const ciliumOn = cache.ciliumOn && hasPerm(Permission.CiliumEnabled);

  const [range, setRange] = useState<rangeType>();
  const [timeInterval, setTimeInterval] = useState<number>(cache.timeInterval);
  const [refreshRate, setRefreshRate] = useState<number>(cache.refreshRate);
  const [pullTime, setPullTime] = useState<Date>(new Date());
  const pauseRefresh = useRef(false);

  const fetchSettings = useMemo<FetchSettings>(
    () => ({
      timeInterval,
      refreshRate,
      pullTime,

      endTime: {
        seconds: Math.floor(pullTime.getTime() / 1000),
        nanos: 0
      },
      window: {
        seconds: timeInterval,
        nanos: 0
      },
      step: {
        seconds: Math.max(Math.floor(timeInterval / 10), 60), // less than 1 min defaults to 0 which causes issues
        nanos: 0
      },

      istioMetrics: true,
      ciliumMetrics: ciliumOn,
      tcpMetrics: tcpOn
    }),
    [timeInterval, refreshRate, pullTime, ciliumOn, tcpOn]
  );

  useEffect(() => {
    // If refresh is off we're in custom range mode, and shouldn't update the cache with the dummy refresh/interval data
    if (refreshRate === DISABLE_REFRESH) {
      return;
    }

    updateCache({ timeInterval, refreshRate });
  }, [timeInterval, refreshRate]);

  // This function is called both for automatic updates and when the refresh button is clicked
  const refreshGraphData = useCallback(
    (graphMutate: () => void) => {
      // `DISABLE_REFRESH` is used when traffic interval is set to a range to
      // prevent pull time from being updated
      // `pauseRefresh.current` is set when something is being dragged on the graph
      // to prevent graph shape from changing while user is interacting with it
      if (refreshRate !== DISABLE_REFRESH && !pauseRefresh.current) {
        setPullTime(new Date());
        graphMutate();
      }
    },
    [refreshRate]
  );

  const toggleRefreshPause = useCallback(
    (pause: boolean, graphMutate: () => void) => {
      pauseRefresh.current = pause;
      if (!pause) {
        refreshGraphData(graphMutate);
      }
    },
    [refreshGraphData]
  );

  return (
    <GraphFetchSettingsContext.Provider
      value={{
        range,
        setRange,
        timeInterval,
        setTimeInterval,
        refreshRate,
        setRefreshRate,
        pullTime,
        setPullTime,
        fetchSettings,
        refreshGraphData,
        toggleRefreshPause
      }}>
      {children}
    </GraphFetchSettingsContext.Provider>
  );
};

export const useGraphFetchSettingsContext = () => {
  const graphFetchSettingsContext = useContext(GraphFetchSettingsContext);

  if (!graphFetchSettingsContext) {
    throw new Error('useGraphFetchSettingsContext has to be used within <GraphFetchSettingsContext.Provider>');
  }

  return graphFetchSettingsContext;
};
