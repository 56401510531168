import { HealthNotificationBox } from 'Components/Common/HealthNotificationBox';
import { SoloListCardWithColumns } from 'Components/Common/SoloListCard';
import { SoloModal, SoloModalHeader } from 'Components/Common/SoloModal';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Col, Row } from 'antd';
import { Asset } from 'assets';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { SECONDS_IN_DAY, capitalizeFirstLetters } from 'utils/helpers';
import { CertModalDetailsStyles } from './CertificateDetailsModal.style';

// Using this interface instead of a Record<string,string> type
// allows the arrays to be in order if desired.
export interface NameValue {
  name: string;
  value: string;
}

const NameValuesTable = ({ nameValues }: { nameValues: NameValue[] }) => {
  return (
    <SoloListCardWithColumns
      columns={[{ title: 'Name' }, { title: 'Value' }]}
      rows={nameValues.map(({ name, value }, idx) => ({
        key: idx.toString(),
        cells: [
          { data: <div>{name}</div> },
          { data: <CertModalDetailsStyles.GreyText>{value}</CertModalDetailsStyles.GreyText> }
        ]
      }))}
    />
  );
};

export const CertificateDetailsModal = ({
  hostname,
  certType,
  issuedTo,
  issuedBy,
  issuedAtSeconds,
  expiresAtSeconds,
  fingerprints,
  onClose
}: {
  hostname: string;
  certType: string;
  issuedTo: NameValue[];
  issuedBy: NameValue[];
  /** The (timestamp) seconds that this cert was issued on. */
  issuedAtSeconds: number | undefined;
  /** The (timestamp) seconds that this cert expires at. */
  expiresAtSeconds: number | undefined;
  fingerprints: NameValue[];
  onClose?: () => void;
}) => {
  const hasNoExpiration = expiresAtSeconds === undefined;
  const lineStartTime = issuedAtSeconds ?? 0;
  const lineEndTime = expiresAtSeconds ?? 1;
  const issuedDate = new Date((issuedAtSeconds ?? 0) * 1000);
  const expirationDate = new Date((expiresAtSeconds ?? 0) * 1000);
  //
  // Calculate the days left and percent complete.
  const totalSeconds = lineEndTime - lineStartTime;
  const curSeconds = Math.floor(Date.now().valueOf() / 1000);
  const secondsLeft = lineEndTime - curSeconds;
  const daysLeft = Math.floor(secondsLeft / SECONDS_IN_DAY);
  const percentComplete = (1 - secondsLeft / totalSeconds) * 100;
  const isExpired = secondsLeft < 0;

  //
  // Render
  //
  return (
    <SoloModal
      width={1000}
      visible={true}
      onClose={onClose}
      minHeight='unset'
      withPadding
      data-testid='certificate-details-modal'>
      <SoloModalHeader
        title={hostname}
        subtitle={`${capitalizeFirstLetters(certType)} Certificate`}
        icon={isExpired ? <Asset.CertExpiryErrorIcon /> : <Asset.CertExpiryIcon />}
        status={{
          state: isExpired ? State.FAILED : State.ACCEPTED,
          validationErrors: []
        }}
      />
      {isExpired && (
        <Spacer pb={3}>
          <HealthNotificationBox
            justMessage
            status={{
              state: State.FAILED,
              validationErrors: ['This certificate has expired']
            }}
          />
        </Spacer>
      )}
      <CertModalDetailsStyles.CertModalCard>
        {/*
        
        //
        // Issued To/By
        //
        */}
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12} xl={12}>
            <CertModalDetailsStyles.SubSectionTitle>Issued To</CertModalDetailsStyles.SubSectionTitle>
            <NameValuesTable nameValues={issuedTo} />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <CertModalDetailsStyles.SubSectionTitle>Issued By</CertModalDetailsStyles.SubSectionTitle>
            <NameValuesTable nameValues={issuedBy} />
          </Col>
          {/*
        
          //
          // Validity Period
          //
          */}
          <Col xs={24} xl={24}>
            <CertModalDetailsStyles.SubSectionTitle>Validity Period</CertModalDetailsStyles.SubSectionTitle>
            <CertModalDetailsStyles.CertModalCard>
              <CertModalDetailsStyles.BoldText>Issued On</CertModalDetailsStyles.BoldText>
              <CertModalDetailsStyles.GreyText>{issuedDate.toString()}</CertModalDetailsStyles.GreyText>
              <FlexLayout>
                <CertModalDetailsStyles.CertExpiryVisualizationLine
                  isExpired={isExpired}
                  percentFull={percentComplete}
                />
              </FlexLayout>
              <FlexLayout flexDirection='column' alignItems='flex-end'>
                {hasNoExpiration ? (
                  <CertModalDetailsStyles.GreyText>No Expiration</CertModalDetailsStyles.GreyText>
                ) : isExpired ? (
                  <>
                    <CertModalDetailsStyles.BoldText>
                      <CertModalDetailsStyles.RedText>Expired On</CertModalDetailsStyles.RedText>
                    </CertModalDetailsStyles.BoldText>
                    <CertModalDetailsStyles.RedText>{expirationDate.toString()}</CertModalDetailsStyles.RedText>
                  </>
                ) : (
                  <>
                    <CertModalDetailsStyles.BoldText>
                      Expires{' '}
                      {daysLeft === 0 ? (
                        'within a day'
                      ) : (
                        <>
                          in {daysLeft} day{daysLeft > 1 ? 's' : ''}
                        </>
                      )}
                    </CertModalDetailsStyles.BoldText>
                    <CertModalDetailsStyles.GreyText>{expirationDate.toString()}</CertModalDetailsStyles.GreyText>
                  </>
                )}
              </FlexLayout>
            </CertModalDetailsStyles.CertModalCard>
          </Col>
          {/*
        
          //
          // Fingerprints
          //
          */}
          <Col xs={24} xl={24}>
            <CertModalDetailsStyles.SubSectionTitle>Fingerprints</CertModalDetailsStyles.SubSectionTitle>
            <NameValuesTable nameValues={fingerprints} />
          </Col>
        </Row>
      </CertModalDetailsStyles.CertModalCard>
    </SoloModal>
  );
};
