import { ObjectTypeDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlObjectTabItem from './GqlObjectTabItem';
import GqlObjectTabTable from './GqlObjectTabTable';

const GqlObjectTab = ({
  definitions,
  onTypeClick,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<ObjectTypeDefinitionNode>) => {
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={5}>
        <GqlDetailsStyles.LightText>
          Represent a kind of object the client can fetch from your service, and what fields it has.
        </GqlDetailsStyles.LightText>
      </Spacer>

      {!!focusedItem && (
        <GqlObjectTabItem
          focusedPath={focusedPath}
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlObjectTabTable
        tabHeader={tabHeader}
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
      />
    </>
  );
};

export default GqlObjectTab;
