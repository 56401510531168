import cytoscape from 'cytoscape';
import {
  GraphContainerType,
  GraphMetricsType,
  GraphPolicyIcon,
  LabelIcon,
  NodeTypeCiliumTooltipProps,
  NodeTypeHttpTooltipProps,
  NodeTypeTcpTooltipProps
} from '../General/graph-selection-utils';
import { CyCanvasData, getNodeOpacity, truncateText } from './canvas-utils';
import { colors } from 'Styles';
import { cleanNumberDisplay, drawImage, ImageSource } from './canvas-image-draw-util';

// Note: For info about Vite SVG imports, see https://vitejs.dev/guide/assets#importing-asset-as-url
import namespaceIcon from 'assets/graphing-images/canvas/label-icons/namespace-icon-white.svg';
import clusterIcon from 'assets/graphing-images/canvas/label-icons/cluster-icon-white.svg';
import workspaceIcon from 'assets/graphing-images/canvas/label-icons/workspace-icon-white.svg';
import subnetIcon from 'assets/graphing-images/canvas/label-icons/subnet-icon-white.svg';
import platformIcon from 'assets/graphing-images/canvas/label-icons/platform-icon-white.svg';
import vpcIcon from 'assets/graphing-images/canvas/label-icons/vpc-icon-white.svg';
// Label icons
import istioIcon from 'assets/graphing-images/canvas/label-icons/istio-icon-white.svg';
import ciliumIcon from 'assets/graphing-images/canvas/label-icons/cilium-icon-white.svg';
import pepIcon from 'assets/graphing-images/canvas/label-icons/pep-icon-white.svg';
import sidecarIcon from 'assets/graphing-images/canvas/label-icons/sidecar-envoy-icon-white.svg';
import ambientMeshIcon from 'assets/graphing-images/canvas/label-icons/ambient-mesh-icon-white.svg';
// Label icons - unselected
import istioIconUnselected from 'assets/graphing-images/canvas/unselected-label-icons/istio-icon-grey.svg';
import ciliumIconUnselected from 'assets/graphing-images/canvas/unselected-label-icons/cilium-icon-grey.svg';
import pepIconUnselected from 'assets/graphing-images/canvas/unselected-label-icons/pep-icon-grey.svg';
import sidecarIconUnselected from 'assets/graphing-images/canvas/unselected-label-icons/sidecar-envoy-icon-grey.svg';
import ambientMeshIconUnselected from 'assets/graphing-images/canvas/unselected-label-icons/ambient-mesh-icon-grey.svg';

import accessPolicyIcon from 'assets/graphing-images/canvas/label-icons/access-policy-icon-white.svg';
import trafficPolicyIcon from 'assets/graphing-images/canvas/label-icons/traffic-shift-policy-icon-white.svg';

const labelIconMap: Record<LabelIcon, ImageSource> = {
  [LabelIcon.Istio]: istioIcon,
  [LabelIcon.Cilium]: ciliumIcon,
  [LabelIcon.AmbientMesh]: ambientMeshIcon,
  [LabelIcon.Sidecar]: sidecarIcon,
  [LabelIcon.Pep]: pepIcon
};
const unselectedLabelIconMap: Record<LabelIcon, ImageSource> = {
  [LabelIcon.Istio]: istioIconUnselected,
  [LabelIcon.Cilium]: ciliumIconUnselected,
  [LabelIcon.AmbientMesh]: ambientMeshIconUnselected,
  [LabelIcon.Sidecar]: sidecarIconUnselected,
  [LabelIcon.Pep]: pepIconUnselected
};

const policyIconMap: Record<GraphPolicyIcon, ImageSource> = {
  [GraphPolicyIcon.Access]: accessPolicyIcon,
  [GraphPolicyIcon.Traffic]: trafficPolicyIcon
};

export function renderLabels(cy: cytoscape.Core, { layer, ctx }: CyCanvasData) {
  layer.resetTransform(ctx);
  layer.clear(ctx);
  layer.setTransform(ctx);

  // [ALL_ITEMS] Save default values so we don't overwrite them
  ctx.save();
  ctx.textAlign = 'center';

  cy.edges().forEach(edge => {
    if (!edge.visible()) return;
    renderEdgeLabel(ctx, edge);
  });

  cy.nodes()
    // Force hovered node labels on top
    .sort(n => (n.hasClass('hovered') ? 1 : -1))
    .forEach(node => {
      if (
        node.data('idleHidden') === 'hide' ||
        node.data('nameHidden') === 'hide' ||
        node.data('typeHidden') === 'hide'
      ) {
        // In this case, rather than even just 'hiding' the node, we need to not draw it at all. The equivalent
        // of 'display none'.
      } else {
        const containerType: GraphContainerType | undefined = node.data('containerType');
        const label = node.data('label') ?? node.data('name');

        // Start drawing
        ctx.save();
        ctx.globalAlpha = getNodeOpacity(node);
        const getFont = (props?: FontProps) => buildFontString(node, props);
        ctx.font = getFont();

        if (containerType) {
          if (!node.visible()) {
            ctx.restore();
            return;
          }
          renderContainerLabel(ctx, node, label);
        } else if (node.hasClass('hovered')) {
          renderExpandedNodeTooltip(ctx, node, label);
        } else {
          renderNodeLabel(ctx, node, label);
        }

        ctx.restore();
        // end drawing
      }
    });

  // [/ALL_ITEMS] restore ctx to previous `save()`
  ctx.restore();
}

////////////////////////////////
// Main Render functions
////////////////////////////////
function renderEdgeLabel(ctx: CanvasRenderingContext2D, edge: cytoscape.EdgeSingular) {
  const label = edge.hasClass('mtlsEnabledEdge') ? '\ue90c' : edge.hasClass('mtlsDisabledEdge') ? '\ue90f' : '';
  if (label === '') {
    return; // only do custom edge labels for mtls icons
  }

  const { x: containerX, y: containerY } = edge.midpoint();

  // Start drawing
  ctx.save();

  ctx.globalAlpha = edge.effectiveOpacity();
  const getFont = (props?: FontProps) => buildFontString(edge, props);
  ctx.font = getFont();

  const lineHeight = getLineHeight(ctx);
  const x = containerX,
    y = containerY;

  // Text
  ctx.fillStyle = edge.style('color');
  ctx.strokeStyle = edge.style('text-outline-color');
  ctx.lineWidth = edge.style('text-outline-width').replace('px', '') * 2;
  ctx.globalAlpha = edge.effectiveOpacity() * edge.style('text-opacity');
  ctx.lineJoin = 'round';
  ctx.strokeText(label, x, y + lineHeight * 0.5);
  ctx.fillText(label, x, y + lineHeight * 0.5);

  ctx.restore();
  // end drawing
}

function renderNodeLabel(ctx: CanvasRenderingContext2D, node: cytoscape.SingularElementReturnValue, label: string) {
  const { x: containerX, y: containerY } = node.position();
  const nodeHeight = node.height();

  const paddingX = 7,
    paddingY = 7,
    borderRadius = 4,
    offsetY = 10;

  const { width: textWidth } = ctx.measureText(label);
  const lineHeight = getLineHeight(ctx);

  const width = textWidth + paddingX * 2,
    height = lineHeight + paddingY * 2;

  const x = containerX - width * 0.5,
    y = containerY - (nodeHeight * 0.5 + height + offsetY),
    centerY = y + height * 0.5;

  // Box
  ctx.fillStyle = node.style('text-background-color');
  roundRect(ctx, x, y, width, height, borderRadius, {
    drawBottomArrow: offsetY * 0.5
  }).fill();

  // Text
  ctx.fillStyle = 'white';
  ctx.fillText(label, x + paddingX + textWidth * 0.5, centerY + lineHeight * 0.5 - 1);
}

function renderContainerLabel(
  ctx: CanvasRenderingContext2D,
  node: cytoscape.SingularElementReturnValue,
  label: string
) {
  const { x: containerX, y: containerY } = node.position();
  const nodeHeight = node.height();
  const labelIconIDs: LabelIcon[] = node.data('labelIcons') ?? [];

  const paddingX = 20,
    paddingY = 7,
    borderRadius = 8,
    textIconGap = 6;

  const labelIconList = labelIconIDs.map(id =>
    node.hasClass('unselected') ? unselectedLabelIconMap[id] : labelIconMap[id]
  );
  const labelIconsCont = createIconListElement(labelIconList, 16, 3);

  const { width: textWidth } = ctx.measureText(label);
  const lineHeight = getLineHeight(ctx);

  const labelAreaWidth = textIconGap + labelIconsCont.width;
  const width = textWidth + paddingX * 2 + (labelIconIDs.length > 0 ? labelAreaWidth : 0),
    height = lineHeight + paddingY * 2;

  const x = containerX - width * 0.5,
    y = containerY + nodeHeight * 0.5 + (lineHeight + paddingY * 2) + 4.9,
    centerY = y + height * 0.5;

  // Box
  ctx.fillStyle = node.style('border-color');
  roundRect(ctx, x, y, width, height, {
    bl: borderRadius,
    br: borderRadius
  }).fill();

  // Text
  ctx.fillStyle = node.style('color');
  ctx.fillText(label, x + width - paddingX - textWidth * 0.5, centerY + lineHeight * 0.5 - 1);

  // Label Icons
  labelIconsCont.draw(ctx, { x: x + paddingX, y: centerY, originY: 0.5 });
}

function renderExpandedNodeTooltip(
  ctx: CanvasRenderingContext2D,
  node: cytoscape.SingularElementReturnValue,
  label: string
) {
  const metricsType = node.data('metricsType') as GraphMetricsType;
  const showHeaders = node.data('tooltipProps')?.headers ?? { vpcCluster: false, workspace: false };

  const { x: containerX, y: containerY } = node.position();
  const nodeHeight = node.height();

  const paddingX = 7,
    paddingY = 7,
    borderRadius = 4,
    borderWidth = 1,
    offsetY = 10;

  const width = 300,
    widthFull = width + paddingX * 2;

  // Create elements so we can get their height
  const headerElem = createNodeTooltipTitleHeaderElement(label, widthFull, borderRadius, metricsType, node);
  const clusterHeaderElem = showHeaders.vpcCluster ? createNodeVPCClusterHeaderElement(widthFull, node) : null;
  const workspaceHeaderElem = showHeaders.workspace ? createNodeWorkspaceHeaderElement(widthFull, node) : null;
  const innerContentHeight = 65 + (metricsType === GraphMetricsType.Http ? 27 : 0);

  // Calculate height based on heights of inner content
  const height =
      headerElem.height + (clusterHeaderElem?.height ?? 0) + (workspaceHeaderElem?.height ?? 0) + innerContentHeight,
    heightFull = height + paddingY * 2;

  const x = containerX,
    // 6.5 accounts for node outline growing on hover
    y = containerY - (nodeHeight * 0.5 + heightFull + offsetY) - 6.5;
  const xLeft = x - widthFull * 0.5;

  // Background
  ctx.globalAlpha = 1; // Override for hovered node, we always want it fully visible
  ctx.fillStyle = node.style('text-background-color');
  ctx.strokeStyle = '#6a6a6a';
  ctx.lineWidth = borderWidth;
  ctx.shadowColor = '#6a6a6a';
  ctx.shadowBlur = 3;
  // We add the borderWidth to the size to account for the stroke covering some of the fill
  roundRect(
    ctx,
    xLeft - borderWidth * 0.5,
    y - borderWidth * 0.5,
    widthFull + ctx.lineWidth,
    heightFull + ctx.lineWidth,
    borderRadius,
    {
      drawBottomArrow: offsetY * 0.5
    }
  );
  ctx.fill();
  ctx.stroke();
  ctx.shadowBlur = 0;

  let innerContentY = y;
  ////////////////////
  // Tooltip header
  ////////////////////
  headerElem.draw(ctx, xLeft, innerContentY);
  innerContentY += headerElem.height;

  ////////////////////////////////////////
  // namespace/cluster sub header
  ////////////////////////////////////////
  if (clusterHeaderElem) {
    clusterHeaderElem.draw(ctx, xLeft, innerContentY);
    innerContentY += clusterHeaderElem.height;
  }

  ////////////////////////////////////////
  // workspace sub header
  ////////////////////////////////////////
  if (workspaceHeaderElem) {
    workspaceHeaderElem.draw(ctx, xLeft, innerContentY);
    innerContentY += workspaceHeaderElem.height;
  }

  ////////////////////
  // Inner content
  ////////////////////
  ctx.textAlign = 'left';
  if (metricsType === GraphMetricsType.Http) {
    renderNodeHttpTooltipContent(ctx, node, x, innerContentY, width);
  } else if (metricsType === GraphMetricsType.Tcp) {
    renderNodeTcpTooltipContent(ctx, node, x, innerContentY, width);
  } else if (metricsType === GraphMetricsType.Cilium) {
    renderNodeCiliumTooltipContent(ctx, node, x, innerContentY, width);
  }
}

function createNodeTooltipTitleHeaderElement(
  label: string,
  widthOuter: number,
  borderRadius: number,
  metricsType: GraphMetricsType,
  node: cytoscape.SingularElementReturnValue
) {
  const headerHeight = 24;

  return {
    width: widthOuter,
    height: headerHeight,
    draw(ctx: CanvasRenderingContext2D, x: number, y: number) {
      ctx.save();

      const paddingX = 7,
        textIconGap = 6,
        width = widthOuter - paddingX * 2;

      // Header box
      ctx.fillStyle = '#1d1d1d';
      roundRect(ctx, x, y, widthOuter, headerHeight, { tl: borderRadius, tr: borderRadius }).fill();

      // Set origin x/y to inner coordinates - we shift content up 1 due to parent container's top border
      ctx.translate(x + paddingX, y);

      // Text
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      const titleLineHeight = getLineHeight(ctx);
      let headerTextMaxWidth = width;

      let rightSideIconsAdded = false;

      if (metricsType === GraphMetricsType.Http) {
        let { policyIcons } = node.data('httpTooltipProps') as NodeTypeHttpTooltipProps;
        if (policyIcons.length > 0) {
          rightSideIconsAdded = true;
          const policyIconList = policyIcons.map(id => policyIconMap[id]);
          const policyIconsCont = createIconListElement(policyIconList, 16, 3);
          headerTextMaxWidth -= policyIconsCont.width;
          policyIconsCont.draw(ctx, { x: headerTextMaxWidth, y: headerHeight * 0.5, originY: 0.5 });
        }
      }

      const labelIconIDs: LabelIcon[] = node.data('labelIcons') ?? [];
      if (labelIconIDs.length > 0) {
        if (rightSideIconsAdded) {
          headerTextMaxWidth -= 18;
          const lineX = headerTextMaxWidth + 10;
          drawLine(ctx, lineX, 6, lineX, headerHeight - 6, 'white', 1);
        }
        rightSideIconsAdded = true;
        const labelIconList = labelIconIDs.map(id => labelIconMap[id]);
        const labelIconsCont = createIconListElement(labelIconList, 16, 3);
        headerTextMaxWidth -= labelIconsCont.width;
        labelIconsCont.draw(ctx, { x: headerTextMaxWidth, y: headerHeight * 0.5, originY: 0.5 });
      }

      if (rightSideIconsAdded) {
        headerTextMaxWidth -= textIconGap;
      }

      const labelTrunc = truncateText(ctx, label, headerTextMaxWidth);
      ctx.fillText(labelTrunc, 0, headerHeight * 0.5 + titleLineHeight * 0.5 - 1);

      ctx.restore();
    }
  };
}

function createNodeVPCClusterHeaderElement(widthOuter: number, node: cytoscape.SingularElementReturnValue) {
  let namespace = node.data('namespace'),
    //instance = node.data('instance'), not yet
    cluster = node.data('cluster'),
    subnet = node.data('subnet'),
    platform = node.data('platform'),
    vpc = node.data('vpc');

  const subHeaderHeight =
    27 +
    27 *
      Math.ceil(
        (!!namespace ? 0.5 : 0) +
          //(!!instance ? 0.5 : 0) + not yet
          (!!cluster ? 0.5 : 0) +
          (!!subnet ? 0.5 : 0) +
          (!!platform ? 0.5 : 0) +
          (!!vpc ? 1 : 0)
      );

  return {
    width: widthOuter,
    height: subHeaderHeight,
    draw(ctx: CanvasRenderingContext2D, x: number, y: number) {
      ctx.save();

      const paddingX = 7,
        iconSize = 15,
        iconTextGap = 5,
        columnGap = 15;
      const width = widthOuter - paddingX * 2;
      const maxTextWidth = (widthOuter - iconSize * 2 - iconTextGap * 2 - columnGap) / 2;

      ctx.font = buildFontString(node, { weight: 500, size: '14px' });
      const titleLineHeight = getLineHeight(ctx);

      // Draw background
      ctx.fillStyle = '#138BC2';
      roundRect(ctx, x, y, widthOuter, subHeaderHeight, { tl: 0, tr: 0 }).fill();

      // Set origin x/y to inner coordinates of first line
      ctx.translate(x + paddingX, y);

      // Draw cluster and namespace icons / text
      const centerY = subHeaderHeight * 0.5;
      const iconYs = [
        centerY - iconSize * 0.5,
        centerY + titleLineHeight - iconSize * 0.5,
        centerY + titleLineHeight * 2 - iconSize * 0.5,
        centerY + titleLineHeight * 3 - iconSize * 0.5
      ];
      const textRowYs = [
        centerY + titleLineHeight * 0.5 - 1,
        centerY + titleLineHeight * 1.5 - 1,
        centerY + titleLineHeight * 2.5 - 1,
        centerY + titleLineHeight * 3.5 - 1
      ];

      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';

      // Display these side-by-side, as many as exist
      const infoBoxes = [
        { text: namespace, icon: namespaceIcon },
        //{ text: instance }, not yet
        { text: cluster, icon: clusterIcon },
        { text: subnet, icon: subnetIcon },
        { text: platform, icon: platformIcon }
      ];
      let displayGridSlot = 0;
      infoBoxes.forEach(infoBox => {
        if (!!infoBox.text) {
          if (!!infoBox.icon) {
            drawImage(
              ctx,
              infoBox.icon,
              displayGridSlot % 2 === 0 ? 0 : width / 2,
              iconYs[Math.floor(displayGridSlot / 2)],
              iconSize,
              iconSize
            );
          }

          const text = truncateText(ctx, infoBox.text, maxTextWidth);
          ctx.fillText(
            text,
            (displayGridSlot % 2 === 0 ? 0 : width / 2) + iconSize + iconTextGap,
            textRowYs[Math.floor(displayGridSlot / 2)]
          );

          displayGridSlot++;
        }
      });

      if (!!vpc) {
        // This gets its own row, the next full row available
        displayGridSlot++;
        drawImage(ctx, vpcIcon, 0, iconYs[Math.floor(displayGridSlot / 2)], iconSize, iconSize);
        vpc = truncateText(ctx, vpc, maxTextWidth);
        ctx.fillText(vpc, 0, textRowYs[Math.floor(displayGridSlot / 2)]);
      }

      ctx.restore();
    }
  };
}

function createNodeWorkspaceHeaderElement(widthOuter: number, node: cytoscape.SingularElementReturnValue) {
  const subHeaderHeight = 54;
  return {
    width: widthOuter,
    height: subHeaderHeight,
    draw(ctx: CanvasRenderingContext2D, x: number, y: number) {
      ctx.save();

      let workspace = node.data('workspace'),
        subnetName = node.data('subnet'),
        vpcName = node.data('vpc');

      const paddingX = 7,
        iconSize = 15,
        iconTextGap = 5;
      const width = widthOuter - paddingX * 2;
      const maxTextWidth = width - iconTextGap - iconSize;

      ctx.font = buildFontString(node, { weight: 500, size: '14px' });
      const titleLineHeight = getLineHeight(ctx);

      // Draw background
      ctx.fillStyle = colors.neptuneBlue;
      roundRect(ctx, x, y, widthOuter, subHeaderHeight, { tl: 0, tr: 0 }).fill();

      // Set origin x/y to inner coordinates of 1st line
      ctx.translate(x + paddingX, y);

      // Draw cluster and namespace icons / text
      const centerY = subHeaderHeight * 0.5;
      const iconY = centerY - iconSize * 0.5,
        textY = centerY + titleLineHeight * 0.5 - 1;

      drawImage(ctx, workspaceIcon, width - iconSize, iconY, iconSize, iconSize);
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      workspace = truncateText(ctx, workspace, maxTextWidth);
      ctx.fillText(workspace, 0, textY);

      // Set origin x/y to inner coordinates of 2nd line
      ctx.translate(x + paddingX, y + 27);

      subnetName = truncateText(ctx, subnetName, maxTextWidth);
      ctx.fillText(subnetName, 0, textY);
      vpcName = truncateText(ctx, vpcName, maxTextWidth);
      ctx.fillText(vpcName, width / 2, textY);

      ctx.restore();
    }
  };
}

function renderNodeHttpTooltipContent(
  ctx: CanvasRenderingContext2D,
  node: cytoscape.SingularElementReturnValue,
  x: number,
  y: number,
  width: number
) {
  let { rpsIN, latencyIN, errorsPercentIN, rpsOUT, latencyOUT, errorsPercentOUT } = node.data(
    'httpTooltipProps'
  ) as NodeTypeHttpTooltipProps;

  // Generate Table Data
  const errorsPercentFormattedIN = cleanNumberDisplay(errorsPercentIN, true);
  const errorsPercentFormattedOUT = cleanNumberDisplay(errorsPercentOUT, true);

  let rpsFormattedIN = intToString(rpsIN);
  let rpsFormattedOUT = intToString(rpsOUT);

  const latencyFormattedIN = cleanNumberDisplay(latencyIN);
  const latencyFormattedOUT = cleanNumberDisplay(latencyOUT);

  // Data Table
  const tableWidth = width - 12 * 2; // 100% width - margins
  const tableX = x - tableWidth * 0.5; // since x is center, set to left side of width
  let tableTop = y + 30; // 30 = margin

  const columnData = drawTooltipTable(
    ctx,
    node,
    tableX,
    tableTop,
    tableWidth,
    [
      {
        xPerc: 0,
        label: 'Requests',
        unit: 'req/s'
      },
      {
        xPerc: 0.4,
        label: 'Latency',
        unit: 'ms'
      },
      {
        xPerc: 0.82,
        label: 'Errors',
        unit: '%'
      }
    ],
    {
      inbound: [rpsFormattedIN, latencyFormattedIN, errorsPercentFormattedIN],
      outbound: [rpsFormattedOUT, latencyFormattedOUT, errorsPercentFormattedOUT]
    }
  );

  // Extras (using small text)
  ctx.fillStyle = '#DDD';
  ctx.font = buildFontString(node, { weight: 300, size: '12px' });
  ctx.fillText('(p90)', columnData[1].x + columnData[1].labelWidth + 3, tableTop);
}

function renderNodeTcpTooltipContent(
  ctx: CanvasRenderingContext2D,
  node: cytoscape.SingularElementReturnValue,
  x: number,
  y: number,
  width: number
) {
  const { bytesReceived, bytesSent } = node.data('tcpTooltipProps') as NodeTypeTcpTooltipProps;

  // Generate Table Data
  let bytesReceivedFormatted = intToStringOrDash(bytesReceived);
  let bytesSentFormatted = intToStringOrDash(bytesSent);

  // Data Table
  const tableWidth = width - 12 * 2; // 100% width - margins
  const tableX = x - tableWidth * 0.5; // since x is center, set to left side of width
  const tableTop = y + 30; // 30 = margin

  drawTooltipTable(
    ctx,
    node,
    tableX,
    tableTop,
    tableWidth,
    [
      {
        xPerc: 0,
        label: 'Bytes Received',
        unit: 'bytes/s'
      },
      {
        xPerc: 0.58,
        label: 'Bytes Sent',
        unit: 'bytes/s'
      }
    ],
    { inbound: [bytesReceivedFormatted, bytesSentFormatted] }
  );
}

function renderNodeCiliumTooltipContent(
  ctx: CanvasRenderingContext2D,
  node: cytoscape.SingularElementReturnValue,
  x: number,
  y: number,
  width: number
) {
  const { forwardedReceived, forwardedSent, policyDrops } = node.data(
    'ciliumTooltipProps'
  ) as NodeTypeCiliumTooltipProps;

  // Generate Table Data
  let forwardedReceivedFormatted = intToStringOrDash(forwardedReceived);
  let forwardedSentFormatted = intToStringOrDash(forwardedSent);
  let policyDropsFormatted = intToStringOrDash(policyDrops);

  // Data Table
  const tableWidth = width - 12 * 2; // 100% width - margins
  const tableX = x - tableWidth * 0.5; // since x is center, set to left side of width
  const tableTop = y + 30; // 30 = margin
  drawTooltipTable(
    ctx,
    node,
    tableX,
    tableTop,
    tableWidth,
    [
      {
        xPerc: 0,
        label: 'Fwds Rcvd',
        unit: 'flws/s' // flows / second
      },
      {
        xPerc: 0.38,
        label: 'Fwds Sent',
        unit: 'flws/s' // flows / second
      },
      {
        xPerc: 0.76,
        label: 'Drops',
        unit: 'drops/s'
      }
    ],
    { inbound: [forwardedReceivedFormatted, forwardedSentFormatted, policyDropsFormatted] }
  );
}

////////////////////////////////
// Canvas Helper Functions
////////////////////////////////

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * Stroke/fill needs to be called manually after to actually draw something on screen
 */
function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  pRadius: { tl?: number; tr?: number; br?: number; bl?: number } | number,
  options?: { drawBottomArrow?: number }
) {
  const r = typeof pRadius === 'number' ? pRadius : 0;
  const radiusOverwrite = typeof pRadius === 'number' ? {} : pRadius;
  const radius = { ...{ tl: r, tr: r, br: r, bl: r }, ...radiusOverwrite };
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  if (!!options?.drawBottomArrow && options.drawBottomArrow > 0) {
    const centerX = x + width / 2,
      bY = y + height,
      size = options.drawBottomArrow;
    ctx.lineTo(centerX + size, bY);
    ctx.lineTo(centerX, bY + size);
    ctx.lineTo(centerX - size, bY);
    ctx.lineTo(x + radius.bl, bY);
  } else {
    ctx.lineTo(x + radius.bl, y + height);
  }
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  return ctx;
}

function drawLine(
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  color: string,
  thickness: number
) {
  ctx.save();
  ctx.lineWidth = thickness;
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
}

function getLineHeight(ctx: CanvasRenderingContext2D) {
  // Easiest way to find close to the line height, according to random
  // internet person: https://stackoverflow.com/a/13318387/1411473
  return ctx.measureText('M').width;
}

interface FontProps {
  weight?: number | string;
  size?: string;
  family?: string;
}
function buildFontString(style: cytoscape.CollectionStyle, props?: FontProps) {
  return [
    `normal ${props?.weight ?? style.style('font-weight')}`, // "normal" needed in front for numbered font weight to be recognized
    props?.size ?? style.style('font-size'),
    props?.family ?? style.style('font-family')
  ].join(' ');
}

interface TooltipTableColumnProps {
  xPerc: number;
  label: string;
  unit: string;
}
interface TooltipTableRowProps {
  inbound: (string | number)[];
  outbound?: (string | number)[];
}
function drawTooltipTable(
  ctx: CanvasRenderingContext2D,
  style: cytoscape.CollectionStyle,
  leftX: number,
  topY: number,
  width: number,
  columnsInfo: TooltipTableColumnProps[],
  rowData: TooltipTableRowProps
) {
  const xTable = leftX + 20,
    yRow1 = topY,
    yRow2 = topY + 27,
    yRow3 = topY + 54;

  const columns = columnsInfo.map((c, ind) => ({
    x: xTable + width * c.xPerc,
    label: c.label,
    unit: rowData.inbound[ind] === '-' && rowData.outbound?.[ind] === '-' ? '' : c.unit,
    // We'll replace these with actual values when we draw them, and then overwrite again
    dataWidth: 0,
    labelWidth: 0
  }));
  const getFont = (props?: FontProps) => buildFontString(style, props);

  // Table headers
  ctx.fillStyle = '#DDD';
  ctx.font = getFont({ weight: 300, size: '16px' });
  columns.forEach(c => {
    ctx.fillText(c.label, c.x, yRow1);
    c.labelWidth = ctx.measureText(c.label).width;
  });

  // INBOUND or ALL
  // Table Data
  ctx.fillStyle = 'white';
  ctx.font = getFont({ weight: 800, size: '15px' });
  columns.forEach((c, ind) => {
    ctx.fillText(rowData.inbound[ind].toString(), c.x, yRow2);
    c.dataWidth = ctx.measureText(rowData.inbound[ind].toString()).width;
  });
  // Table Data Units
  ctx.fillStyle = '#DDD';
  ctx.font = getFont({ weight: 300, size: '12px' });
  columns.forEach(({ x, dataWidth, unit }) => {
    ctx.fillText(unit, x + dataWidth + 3, yRow2);
  });

  // 2 rows
  if (!!rowData.outbound) {
    ctx.font = getFont({ weight: 800, size: '13px' });
    ctx.fillText('IN', leftX - 15, yRow2);

    // OUTBOUND
    // Table Data
    ctx.fillStyle = 'white';
    ctx.font = getFont({ weight: 800, size: '15px' });
    columns.forEach((c, ind) => {
      ctx.fillText(rowData.outbound![ind].toString(), c.x, yRow3);
      c.dataWidth = ctx.measureText(rowData.outbound![ind].toString()).width;
    });
    // Table Data Units
    ctx.fillStyle = '#DDD';
    ctx.font = getFont({ weight: 300, size: '12px' });
    columns.forEach(({ x, dataWidth, unit }) => {
      ctx.fillText(unit, x + dataWidth + 3, yRow3);
    });

    ctx.font = getFont({ weight: 800, size: '13px' });
    ctx.fillText('OUT', leftX - 15, yRow3);
  }

  return columns;
}

// https://www.html-code-generator.com/javascript/shorten-long-numbers
function intToString(num: number) {
  let si = [
    { v: 1, s: '' },
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(num < 100 ? 2 : 1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
}

function intToStringOrDash(num?: number) {
  // We are fine with 0 being set to -
  return !num ? '-' : intToString(num);
}

interface DrawProps {
  x: number;
  y: number;
  originX?: number;
  originY?: number;
}
function createIconListElement(sources: ImageSource[], iconSize: number, spacing: number) {
  const width = sources.length * (iconSize + spacing) - spacing;
  const height = iconSize;
  return {
    width,
    height,
    draw(ctx: CanvasRenderingContext2D, { x, y, originX = 0, originY = 0 }: DrawProps) {
      ctx.save();
      ctx.translate(x - width * originX, y - height * originY);
      sources.forEach((src, i) => {
        drawImage(ctx, src, i * (iconSize + spacing), 0, iconSize, iconSize);
      });
      ctx.restore();
    }
  };
}
