import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import {
  ApiType,
  ImportStatus,
  Pagination,
  PolicyType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetWorkspaceDetailsRequest,
  ListWorkspaceApisRequest,
  ListWorkspaceDestinationsRequest,
  ListWorkspacePoliciesRequest,
  ListWorkspaceRoutesRequest,
  RouteType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { WorkspacesApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb.client';
import { capitalizeFirstLetters } from 'utils/helpers';
import { grpcWebFetchTransport, soloGrpcCall, useRequest } from './helpers';

function useRequestSkipWorkspaceName<T extends (workspaceName: string, ...args: any) => any>(
  fn: T,
  args: Parameters<T>,
  methodDescriptorName: string
) {
  const workspaceName = args[0];
  return useRequest(fn, args, {
    skip: !workspaceName,
    methodDescriptorName
  });
}

export namespace workspacesApi {
  const client = new WorkspacesApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.WorkspacesApi/' + capitalizeFirstLetters(fn.name);

  export async function getWorkspaceDetails(workspaceName: string) {
    let request: GetWorkspaceDetailsRequest = { workspaceName };
    return soloGrpcCall(client.getWorkspaceDetails(request));
  }
  export function useGetWorkspaceDetails(...args: Parameters<typeof getWorkspaceDetails>) {
    return useRequestSkipWorkspaceName(getWorkspaceDetails, args, getMethodDescriptorName(client.getWorkspaceDetails));
  }

  //
  // List Destinations
  //
  export async function listDestinations(
    workspaceName: string,
    workspaceNameFilter: string,
    workspaceTypeFilter: DestinationKind[],
    workspaceImportStatusFilter: ImportStatus[],
    workspaceDestinationPagination: Pagination,
    availableNameFilter: string,
    availableTypeFilter: DestinationKind[],
    availableDestinationPagination: Pagination
  ) {
    const request: ListWorkspaceDestinationsRequest = {
      workspaceName,
      workspaceNameFilter,
      workspaceTypeFilter,
      workspaceImportStatusFilter,
      workspaceDestinationPagination,
      availableNameFilter,
      availableTypeFilter,
      availableDestinationPagination
    };
    return soloGrpcCall(client.listWorkspaceDestinations(request));
  }
  export function useListDestinations(...args: Parameters<typeof listDestinations>) {
    return useRequestSkipWorkspaceName(
      listDestinations,
      args,
      getMethodDescriptorName(client.listWorkspaceDestinations)
    );
  }

  //
  // List Policies
  //
  export async function listPolicies(
    workspaceName: string,
    workspaceNameFilter: string,
    workspaceTypeFilter: PolicyType[],
    workspacePolicyPagination?: Pagination
  ) {
    const request: ListWorkspacePoliciesRequest = {
      workspaceName,
      workspaceNameFilter,
      workspaceTypeFilter,
      workspacePolicyPagination
    };
    return soloGrpcCall(client.listWorkspacePolicies(request));
  }
  export function useListPolicies(...args: Parameters<typeof listPolicies>) {
    return useRequestSkipWorkspaceName(listPolicies, args, getMethodDescriptorName(client.listWorkspacePolicies));
  }

  //
  // List Routes
  //
  export async function listRoutes(
    workspaceName: string,
    workspaceNameFilter: string,
    workspaceTypeFilter: RouteType[],
    workspaceImportStatusFilter: ImportStatus[],
    workspaceRoutesPagination: Pagination,
    availableNameFilter: string,
    availableRoutesPagination: Pagination
  ) {
    const request: ListWorkspaceRoutesRequest = {
      workspaceName,
      workspaceNameFilter,
      workspaceTypeFilter,
      workspaceImportStatusFilter,
      workspaceRoutesPagination,
      availableNameFilter,
      availableRoutesPagination
    };
    return soloGrpcCall(client.listWorkspaceRoutes(request));
  }
  export function useListRoutes(...args: Parameters<typeof listRoutes>) {
    return useRequestSkipWorkspaceName(listRoutes, args, getMethodDescriptorName(client.listWorkspaceRoutes));
  }

  //
  // List Graphql APIs
  //
  export async function listApis(
    workspaceName: string,
    workspaceNameFilter: string,
    workspaceTypeFilter: ApiType[],
    workspaceImportStatusFilter: ImportStatus[],
    workspaceApisPagination: Pagination,
    availableNameFilter: string,
    availableTypeFilter: ApiType[],
    availableApisPagination: Pagination
  ) {
    const request: ListWorkspaceApisRequest = {
      workspaceName,
      workspaceNameFilter,
      workspaceTypeFilter,
      workspaceImportStatusFilter,
      workspaceApisPagination,
      availableApisPagination,
      availableNameFilter,
      availableTypeFilter
    };
    return soloGrpcCall(client.listWorkspaceApis(request));
  }
  export function useListApis(...args: Parameters<typeof listApis>) {
    return useRequestSkipWorkspaceName(listApis, args, getMethodDescriptorName(client.listWorkspaceApis));
  }
}
