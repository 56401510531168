import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Properties } from 'csstype';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { cssProp } from 'utils/helpers';

export namespace SoloListCardStyles {
  export const Cell = styled.div<{ isSelectable?: boolean; isSelected?: boolean }>(
    ({ theme, isSelectable, isSelected }) => css`
      display: flex;
      align-items: baseline;
      padding: 0 3px;
      line-height: 24px;

      ${isSelectable
        ? `
        cursor: pointer;
        
          &:hover,
          &:focus,&:active {
            background: ${theme.lakeBlue};
            color: white;

            span {
              color: white;
            }
          }
          
          ${isSelected ? `background: ${theme.puddleBlue};` : ''}
    `
        : ''}
    `
  );

  export const CellContent = styled.div`
    max-width: 100%; // Allows children that are truncated text to have a max width of 100%
  `;

  interface ContainerProps {
    columns?: { width?: string }[];
    maxHeight?: string;
  }
  export const Container = styled.div<ContainerProps>(
    ({ theme, columns, maxHeight }) => css`
      display: grid;
      border: 1px solid ${theme.splashBlue};
      border-radius: 4px;
      width: 100%;
      align-content: start;
      grid-template-columns: ${columns?.map(c => c.width ?? 'minmax(0, 1fr)').join(' ') ?? 'minmax(0, 1fr)'};
      row-gap: 6px;
      padding-bottom: 12px;
      font-size: 14px;

      ${maxHeight
        ? css`
            max-height: ${maxHeight};
            overflow-y: auto;
          `
        : ''}

      .ant-empty {
        margin: 32px 0;
        flex-direction: column;
        text-align: center;
        justify-content: center;
      }

      ${Cell} {
        :nth-of-type(${columns?.length ?? 1}n+1) {
          padding-left: 10px;
        }
        :nth-of-type(${columns?.length ?? 1}n+${columns?.length ?? 1}) {
          padding-right: 10px;
        }
      }
    `
  );

  interface TitleProps {
    isFirst?: boolean;
    isLast?: boolean;
  }
  export const Title = styled.div<TitleProps>(
    ({ theme, isFirst, isLast }) => css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      padding: 7px 7px 6px 8px;
      font-weight: 500;
      background: ${theme.januaryGrey};
      margin-bottom: 3px;
      font-size: 14px;

      ${isFirst &&
      css`
        border-top-left-radius: 3px;
      `}
      ${isLast &&
      css`
        border-top-right-radius: 3px;
      `}
        
      ${svgBasics({ height: 19 }, theme.seaBlue)}
    `
  );

  export const Body = styled.div<{ colspan?: number }>(({ colspan }) => {
    return css`
      padding: 0 10px;
      ${!!colspan &&
      css`
        grid-column: span ${colspan};
      `}
    `;
  });
  export const DottedLined = styled.div<{ visibility?: Properties['visibility'] }>(
    ({ theme, visibility }) => css`
      flex: 1;
      margin-left: 5px;
      line-height: 17px;
      border-bottom: 1px dashed ${theme.splashBlue};
      ${cssProp('visibility', visibility)};
    `
  );
  export const RightValue = styled.div`
    font-weight: 500;
    padding-left: 5px;
  `;

  export const HorizontalListCardsContainer = styled.div`
    display: flex;
    gap: 20px;

    /* This allows for an empty column */
    > div:empty {
      width: 100%;
    }
  `;

  interface GridListCardsContainerProps {
    columns: number;
  }
  export const GridListCardsContainer = styled.div<GridListCardsContainerProps>(
    ({ columns }) => css`
      display: grid;
      grid-template-columns: repeat(${columns}, calc(${100 / columns}% - ${20 / columns}px));
      gap: 20px;
    `
  );
}
