import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { FindOrHideSearchBox } from 'Components/Common/Input/FindOrHideSearchBox';
import { useGraphFetchSettingsContext } from 'Components/Features/Graph/Context/GraphFetchSettingsContext';
import { useGraphUXSettingsContext } from 'Components/Features/Graph/Context/GraphUXSettingsContext';
import { GraphFetchSettings } from 'Components/Features/Graph/MainGraph/GraphFetchSettings';
import { NetworkGraphContainer } from 'Components/Features/Graph/MainGraph/NetworkGraphContainer';
import { NetworkGraphContainerStyles } from 'Components/Features/Graph/MainGraph/NetworkGraphContainer.style';
import { FlexLayout, FlexLayoutSpacer } from 'Styles/CommonEmotions/flexLayout';
import { GetGraphResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { useMemo, useState } from 'react';
import { useInterval } from 'utils/hooks';

const Wrapper = styled.div`
  ${CardStyles.Card} {
    padding: 0;
  }
  ${NetworkGraphContainerStyles.InjectedHeaderActions} {
    display: none;
  }
`;
const HeaderWrapper = styled(FlexLayout)(
  ({ theme }) => css`
    max-width: auto;
    border-bottom: 1px solid ${theme.aprilGrey};
    padding: 18px 18px 12px;
  `
);
const GraphWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const ServiceDetailsGraph = ({
  graphData,
  manualGraphMutate,
  isGraphRefreshing,
  isLoading
}: {
  graphData: GetGraphResponse;
  manualGraphMutate: () => void;
  isGraphRefreshing: boolean;
  isLoading: boolean;
}) => {
  const { refreshRate, refreshGraphData, toggleRefreshPause } = useGraphFetchSettingsContext();

  const { findName, setFindName, hideName, setHideName } = useGraphUXSettingsContext();

  const [cachedGraphDataError] = useState<any>();

  useInterval(() => refreshGraphData(manualGraphMutate), refreshRate);
  const doRefresh = () => refreshGraphData(manualGraphMutate);
  const doPauseRefresh = (on: boolean) => toggleRefreshPause(on, manualGraphMutate);

  const filters = useMemo(() => {
    return {
      findName,
      hideName,
      workspaces: [],
      clusters: Array.from(new Set(graphData.nodeMetrics.map(n => n.workload?.cluster ?? ''))),
      namespaces: Array.from(new Set(graphData.nodeMetrics.map(n => n.workload?.namespace ?? ''))),
      hideEmptyGraphFiltersMessage: true
    };
  }, [graphData, findName, hideName]);

  return (
    <Wrapper>
      <CardStyles.Card>
        <HeaderWrapper>
          <FlexLayoutSpacer data-testid='graph-filter-by-name-block'>
            <FindOrHideSearchBox
              findName={findName}
              setFindName={setFindName}
              hideName={hideName}
              setHideName={setHideName}
            />
          </FlexLayoutSpacer>
          <FlexLayoutSpacer />
          <GraphFetchSettings hasEnoughFilters={true} isGraphRefreshing={isGraphRefreshing} doRefresh={doRefresh} />
        </HeaderWrapper>
        <GraphWrapper>
          <NetworkGraphContainer
            graphData={graphData}
            filters={filters}
            doPauseRefresh={doPauseRefresh}
            graphDataError={cachedGraphDataError}
            isLoading={isLoading}
          />
        </GraphWrapper>
      </CardStyles.Card>
    </Wrapper>
  );
};
