import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { IstioHealthCheckSectionContent } from './IstioHealthCheckSectionContent';

export const IstioHealthCheckSectionFakeData = () => {
  return (
    <IstioHealthCheckSectionContent
      istioControlPlaneInsights={[]}
      lifecycleManagementStatus={{ state: State.ACCEPTED, validationErrors: [] }}
      istioControlPlaneStatus={{ state: State.ACCEPTED, validationErrors: [] }}
      istioDataPlaneStatus={{ state: State.ACCEPTED, validationErrors: [] }}
    />
  );
};
