import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn } from 'Components/Common/SoloTableCells';
import {
  ListWorkspaceRoutesResponse_AvailableRoute,
  RouteType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { getRouteTypeIcon, getRouteTypeReadableName } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';

type TableFields = {
  key: string;
  name: string;
  type: RouteType;
};

const columns: SoloColumnsType<TableFields> = [
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render(type) {
      return (
        <Styles.CellIconText>
          {getRouteTypeIcon(type)} {getRouteTypeReadableName(type)}
        </Styles.CellIconText>
      );
    }
  }
];

interface Props {
  availableRoutes: ListWorkspaceRoutesResponse_AvailableRoute[];
  pagingData: PagingProps;
}
export const AvailableRoutesToImportTable = ({ availableRoutes, pagingData }: Props) => {
  const tableData = useMemo<TableFields[]>(() => {
    return availableRoutes.map(data => ({
      key: buildIdFromRef(data.routeRef),
      name: data.routeRef?.name ?? '',
      type: data.type
    }));
  }, [availableRoutes]);

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='available-routing-table'
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.RouteGroupIcon />}
          title='There are no importable Routes'
          description=''
          href={docLinks.enterprise.routing}
        />
      }
    />
  );
};
