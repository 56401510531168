import { CardStyles } from 'Components/Common/Card';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { VariableTransformation } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { stringifyVariableTransformationValue } from 'utils/graphql-resolver-helpers';

const getHttpMethodFromHeaders = (
  headersMap: [string, VariableTransformation][] | undefined,
  defaultMethod: 'GET' | 'POST'
) => {
  // For Rest Resolvers, the default is:
  //   - If there is a request body, POST.
  //   - If no request body, GET
  // For Proxied API configs:
  //   - The default is POST.
  // Anytime there is a header override named ":method", then that is used.
  let httpMethod: string = defaultMethod;
  if (headersMap) {
    const methodIdx = headersMap.findIndex(h => h[0] === ':method');
    if (methodIdx !== -1) httpMethod = stringifyVariableTransformationValue(headersMap[methodIdx][1], false);
  }
  return httpMethod;
};

const getPathFromHeaders = (headersMap: [string, VariableTransformation][] | undefined) => {
  // Defaults to '/'.
  let thePath = '/';
  if (headersMap) {
    const pathIdx = headersMap.findIndex(h => h[0] === ':path');
    if (pathIdx !== -1) thePath = stringifyVariableTransformationValue(headersMap[pathIdx][1], false);
  }
  return thePath;
};

const GqlInfoFromHeadersMap = ({
  headersMap,
  defaultHttpMethod,
  httpMethodTooltipText
}: {
  headersMap: [string, VariableTransformation][] | undefined;
  defaultHttpMethod: 'GET' | 'POST';
  httpMethodTooltipText: string;
}) => {
  // -- The HTTP request method and path may come from the headers.
  const httpMethod = getHttpMethodFromHeaders(headersMap, defaultHttpMethod);
  const reqPath = getPathFromHeaders(headersMap);

  //
  // Render
  //
  return (
    <CardStyles.CardHeaderSecondaryInfoHolder>
      <SecondaryInfo
        items={[
          {
            label: 'HTTP method',
            data: httpMethod,
            tooltipText: httpMethodTooltipText
          },
          {
            label: 'Path',
            data: reqPath,
            tooltipText:
              'The default path is "/". This can be changed by adding a ":path" header with a value for the new path.'
          }
        ]}
      />
    </CardStyles.CardHeaderSecondaryInfoHolder>
  );
};

export default GqlInfoFromHeadersMap;
