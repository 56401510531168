import { SoloLink } from 'Components/Common/SoloLink';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Tooltip } from 'antd';
import { GatewayIcon, GqlApiExplorerIcon } from 'assets/assets';
import { ApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListOverviewApisResponse_ApiSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { useNavigate } from 'react-router-dom';
import { buildGraphqlApiExplorerUrl } from 'utils/url-builders';
import { ApisLandingStyles } from './ApisLanding.style';
import { GqlDetailsStyles } from './Graphql/Details/GqlDetails.style';

const ApisLandingApiExplorerButton = ({ api }: { api: ListOverviewApisResponse_ApiSummary }) => {
  const gatewayRefs = api.gatewayRefs ?? [];
  const numGateways = gatewayRefs.length;
  const navigate = useNavigate();

  //
  // Render
  //
  const isGraphqlApi = [ApiType.GRAPHQL_STITCHED, ApiType.GRAPHQL_PROXIED, ApiType.GRAPHQL_RESOLVED].includes(
    api.apiType
  );
  if (!isGraphqlApi) return null;
  return (
    <ApisLandingStyles.ApiExplorerButtonContainer onClick={e => e.preventDefault()} disabled={numGateways === 0}>
      {numGateways > 0 ? (
        <Tooltip
          placement='topLeft'
          trigger='hover'
          title={
            numGateways === 1 ? (
              <Spacer px={5} py={2}>
                <GqlDetailsStyles.ExplorerLinkTooltipContent>
                  <div
                    onClick={e => {
                      // Needs to stopPropagation because when this is in a <SoloTable>, clicking on a row
                      // navigates the user to that details page through the onRow onClick callback.
                      // Adding stopPropagation() here prevents that callback from happening so that
                      // this interaction can be used.
                      e.stopPropagation();
                      navigate(
                        buildGraphqlApiExplorerUrl({
                          routeTableRef: api.routeTableRef,
                          istioRouteName: api.routeName,
                          gatewayRef: gatewayRefs[0]
                        })
                      );
                    }}>
                    <SoloLink withArrow inline link={'#'}>
                      Explore this API
                    </SoloLink>
                  </div>
                </GqlDetailsStyles.ExplorerLinkTooltipContent>
              </Spacer>
            ) : (
              <>
                <ApisLandingStyles.TooltipTitleTop>Explore this API via</ApisLandingStyles.TooltipTitleTop>
                <Spacer pt={2}>
                  <GqlDetailsStyles.ExplorerLinkTooltipContent withLessPadding>
                    {gatewayRefs.map((gwRef, idx) => (
                      <Spacer key={idx} py={1}>
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            navigate(
                              buildGraphqlApiExplorerUrl({
                                routeTableRef: api.routeTableRef,
                                istioRouteName: api.routeName,
                                gatewayRef: gwRef
                              })
                            );
                          }}>
                          <SoloLink withArrow inline link={'#'}>
                            <ApisLandingStyles.ApiExplorerTooltipGatewayIconContainer>
                              <GatewayIcon />
                            </ApisLandingStyles.ApiExplorerTooltipGatewayIconContainer>
                            &nbsp;Virtual Gateway {idx}
                          </SoloLink>
                        </div>
                      </Spacer>
                    ))}
                  </GqlDetailsStyles.ExplorerLinkTooltipContent>
                </Spacer>
              </>
            )
          }>
          <GqlApiExplorerIcon
            onClick={e =>
              // Needs to stopPropagation because when this is in a <SoloTable>, clicking on a row
              // navigates the user to that details page through the onRow onClick callback.
              // Adding stopPropagation() here prevents that callback from happening so that
              // this interaction can be used.
              e.stopPropagation()
            }
          />
        </Tooltip>
      ) : (
        <Tooltip placement='topLeft' title={<Spacer padding={2}>No gateway configured</Spacer>} trigger='hover'>
          <GqlApiExplorerIcon
            onClick={e =>
              // Needs to stopPropagation because when this is in a <SoloTable>, clicking on a row
              // navigates the user to that details page through the onRow onClick callback.
              // Adding stopPropagation() here prevents that callback from happening so that
              // this interaction can be used.
              e.stopPropagation()
            }
          />
        </Tooltip>
      )}
    </ApisLandingStyles.ApiExplorerButtonContainer>
  );
};

export default ApisLandingApiExplorerButton;
