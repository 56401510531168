import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createStatusColumn } from 'Components/Common/SoloTableCells';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import {
  Insight,
  Insight_Target
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useMemo } from 'react';
import { convertInsightSeverityToState } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeGroup, insightCodeGroupMap } from 'utils/dashboard/dashboard-types';
import { buildIdFromRef } from 'utils/helpers';
import SoloTableTextEllipsesCell from '../../../../Common/SoloTableTextEllipsesCell';
import { InsightsLandingDetailsModalInfo, insightAndModalInfoMatches } from '../InsightsLanding';
import { InsightsLandingDetailsModal } from './InsightsLandingDetailsModal';

type ExtraPropsType = {
  onDetailsModalInfoChange(newModalInfo: InsightsLandingDetailsModalInfo | undefined): void;
  detailsModalInfo: InsightsLandingDetailsModalInfo | undefined;
};

//
// Currently this is a table that can move forward/backwards by one page at a time.
// It does not use normal paging behavior.
//
export const InsightsLandingBody = (props: CustomLandingBodyProps<Insight, ExtraPropsType>) => {
  return (
    <LandingBody
      {...props}
      GridComponent={undefined}
      TableComponent={InsightsLandingBodyTable}
      extraProps={props.extraProps}
    />
  );
};

interface TableFields {
  key: string;
  status: Status | undefined;
  groupName: string;
  summary: string;
  target: Insight_Target | undefined;
  details: { onClick?: () => void };
}

const columns: SoloColumnsType<TableFields> = [
  // Hiding the error list because the errors don't return information.
  createStatusColumn({ title: 'Level', dataIndex: 'status', hideErrorList: true }),
  {
    title: 'Category',
    dataIndex: 'groupName',
    width: 100,
    ellipsis: true,
    render: value => <SoloTableTextEllipsesCell value={value} />
  },
  {
    title: 'Summary',
    dataIndex: 'summary',
    width: '50%',
    ellipsis: true,
    render: value => <SoloTableTextEllipsesCell value={value} />
  },
  {
    title: 'Resource',
    dataIndex: 'target',
    width: '20%',
    ellipsis: true,
    render(target) {
      if (!target) {
        return null;
      }
      let resourceLabel = '';
      if (target.target.oneofKind === 'global') {
        resourceLabel = 'Global';
      } else if (target.target.oneofKind === 'cluster') {
        resourceLabel = target.target.cluster.name;
      } else if (target.target.oneofKind === 'resource') {
        const { name, namespace, clusterName } = target.target.resource;
        resourceLabel = `${name}${!!namespace.length ? `.${namespace}` : ''}${!!clusterName.length ? `.${clusterName}` : ''
          }`;
      }
      return <SoloTableTextEllipsesCell value={resourceLabel} />;
    }
  },
  {
    title: 'Details',
    dataIndex: 'details',
    width: 100,
    render({ onClick }) {
      return (
        !!onClick && (
          <SoloLinkStyles.SoloLinkLooksButton onClick={onClick} withArrow>
            Details
          </SoloLinkStyles.SoloLinkLooksButton>
        )
      );
    }
  }
];

const InsightsLandingBodyTable = ({ items: insights, extraProps }: BaseLandingBodyProps<Insight, ExtraPropsType>) => {
  const detailsModalInfo = extraProps?.detailsModalInfo;
  const onDetailsModalInfoChange = extraProps?.onDetailsModalInfoChange;

  const tableData = useMemo<TableFields[]>(
    () =>
      insights.map((data, idx) => ({
        ...data,
        key: idx.toString(),
        status: { state: convertInsightSeverityToState(data.severity), validationErrors: [] },
        groupName: insightCodeGroupMap[data.code?.group as InsightCodeGroup] ?? 'Unknown',
        target: data.target,
        details: {
          onClick: () => {
            onDetailsModalInfoChange?.({
              modalCode: {
                key: data.code?.key ?? '',
                group: data.target?.target.oneofKind === 'global' ? '' : data.code?.group ?? ''
              },
              modalTarget: {
                type: data.target?.target.oneofKind ?? '',
                target:
                  data.target?.target.oneofKind === 'global'
                    ? 'global'
                    : data.target?.target.oneofKind === 'cluster'
                      ? data.target?.target.cluster.name ?? ''
                      : data.target?.target.oneofKind === 'resource'
                        ? buildIdFromRef(data.target?.target.resource)
                        : ''
              }
            });
          }
        }
      })),
    [insights, onDetailsModalInfoChange]
  );

  const modalInsight = useMemo<Insight | undefined>(() => {
    if (!detailsModalInfo) {
      return undefined;
    }
    return insights.find(insight => insightAndModalInfoMatches(insight, detailsModalInfo));
  }, [detailsModalInfo?.modalCode, detailsModalInfo?.modalTarget]);

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable dataSource={tableData} columns={columns} />
      {!!modalInsight && (
        <InsightsLandingDetailsModal insight={modalInsight} onClose={() => onDetailsModalInfoChange?.(undefined)} />
      )}
    </Spacer>
  );
};
