import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getStatusColors } from 'utils/health-status';

export namespace FIPSCardStyles {
  export const Grid = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    min-height: 167px; // Min height added so it will usually be the same height as the zero trust card
    margin: 15px 0;
  `;

  export const InsightBox = styled.div<{ state: State }>(({ state, theme }) => {
    const statusColors = getStatusColors(state);
    return css`
      padding: 10px;
      background: ${state === State.ACCEPTED ? theme.dropBlue : statusColors.background};
      border: 1px solid ${statusColors.border};
      border-radius: 4px;
      ${svgBasics({ width: '100%' }, statusColors.text)}
    `;
  });
  export const InsightBoxHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    font-size: 18px;
    padding: 10px 0;
  `;
  export const InsightBody = styled.div<{ hideIcon: boolean }>(({ hideIcon }) => {
    return css`
      display: grid;
      gap: 5px;
      grid-template-columns: ${!hideIcon ? '40%' : ''} 1fr;
      padding: 10px 0;
      overflow: hidden;
      text-align: center;
    `;
  });
  export const InsightBoxCount = styled.div<{ state: State }>(({ state }) => {
    return css`
      font-size: 24px;
      color: ${getStatusColors(state).text};
    `;
  });
  export const InsightBoxCountDesc = styled.div`
    font-size: 12px;
    margin-top: 10px;
  `;

  export const Tooltip = styled.div`
    font-size: 12px;
  `;
  export const TooltipTitle = styled.div`
    font-size: 14px;
    text-align: center;
  `;
}
