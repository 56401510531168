import { Asset } from 'assets';
import {
  HttpMetrics,
  NodeType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import React from 'react';
import { calculateRPS, reduceListAverage } from '../get-graph-items-data';
import { GraphMetricsType } from '../graph-selection-utils';
import { DetailsTabStyles, DetailsTabStyles as Styles } from './DetailsTab.style';
import { dateToTimelineGraphX, TimelineGraphProps } from './TimelineGraph';

export function getNodeIcon(type?: NodeType) {
  switch (type) {
    case NodeType.GATEWAY:
      return <Asset.GatewayIconBlack />;
    case NodeType.KUBERNETES_WORKLOAD:
      return <Asset.KubernetesServiceIconBlack />;
    case NodeType.MESH_WORKLOAD:
      return <Asset.KubernetesServiceIconBlack />;
    case NodeType.VM_WORKLOAD:
      return <Asset.VirtualMachineIconBlack />;
    case NodeType.EXTERNAL_WORKLOAD:
      return <Asset.ExternalServiceIcon />;
    case NodeType.UNKNOWN_NODE_TYPE:
    default:
      return <Asset.HelpIcon title='Unknown Node Type' />;
  }
}

export function httpMetricsListToCounts(metricsList?: HttpMetrics[]) {
  return (metricsList ?? []).reduce(
    (sums, metrics) => {
      sums.requestCount += reduceListAverage(metrics.requestCount);
      sums.successCount += reduceListAverage(metrics.successCount);
      sums.failureCount += reduceListAverage(metrics.failureCount);
      sums.rps += calculateRPS(metrics);

      sums.startingTime = Math.min(sums.startingTime, metrics.timestamps[0].seconds);
      sums.endingTime = Math.max(sums.endingTime, metrics.timestamps[metrics.timestamps.length - 1].seconds);
      return sums;
    },
    { requestCount: 0, successCount: 0, failureCount: 0, rps: 0, startingTime: Number.MAX_VALUE, endingTime: 0 }
  );
}

export function isTimestampsListOverHourLong(timestamps: Timestamp[]) {
  if (timestamps.length) {
    const startingSecond = timestamps[0]?.seconds;
    const endingSecond = timestamps[timestamps.length - 1]?.seconds;
    return (startingSecond - endingSecond) / 3600 > 1;
  } else {
    return false;
  }
}

const lineDataDashStyle = { data: { strokeDasharray: '3,3' } };
function addLineData(lineDataMap: Record<string, DatasetData[]>, id: string, newData: Omit<DatasetData, 'name'>) {
  lineDataMap[id] ??= [];
  let lineData = lineDataMap[id].find(prevData => prevData.x === newData.x);
  if (!!lineData) {
    lineData.y += newData.y;
  } else {
    lineData = { name: id, ...newData };
    lineDataMap[id].push(lineData);
  }
  return lineData;
}
interface DatasetData {
  name: string;
  x: string;
  y: number;
}
type FakeMetrics = { timestamps: Timestamp[] };
export function getMetricsListLineData<T extends FakeMetrics>(
  metricsList: T[],
  metricParams: { id: string; key: keyof T }[],
  multipleHours: boolean
) {
  const dataset: TimelineGraphProps['dataset'] = [];
  const lineDataMap: Record<string, DatasetData[]> = {};
  let minY = Number.MAX_VALUE,
    maxY = 0;

  if (metricsList[0]?.timestamps.length) {
    metricsList.forEach(metrics => {
      metrics.timestamps.forEach((timestamp, ind) => {
        const prettyTime = new Date(timestamp.seconds * 1000);
        const x = dateToTimelineGraphX(prettyTime, multipleHours);

        metricParams.forEach(({ id, key }) => {
          const lineData = addLineData(lineDataMap, id, { x, y: (metrics[key] as unknown as number[])[ind] ?? 0 });
          minY = Math.min(lineData.y, minY);
          maxY = Math.max(lineData.y, maxY);
        });
      });
    });

    dataset.push(...metricParams.map(({ id }) => ({ data: lineDataMap[id], style: lineDataDashStyle })));
  }

  return { dataset, minY, maxY };
}

export const EnforcedByInfoListBlock = ({ metricsType }: { metricsType: GraphMetricsType }) => {
  if (metricsType === GraphMetricsType.Http || metricsType === GraphMetricsType.Tcp) {
    return (
      <DetailsTabStyles.InfoListBlock>
        <div>
          <Asset.IstioLogo />
        </div>
        <div data-testid='enforced-by-text'>Enforced by Istio</div>
      </DetailsTabStyles.InfoListBlock>
    );
  } else if (metricsType === GraphMetricsType.Cilium) {
    return (
      <DetailsTabStyles.InfoListBlock>
        <div>
          <Asset.CiliumIcon />
        </div>
        <div data-testid='enforced-by-text'>Enforced by Cilium</div>
      </DetailsTabStyles.InfoListBlock>
    );
  }
  return <></>;
};

export type DetailsTabProps = {
  tabHidden: boolean;
  toggleIn: (a: any) => any;
  canBeDisplayed: boolean;
  children?: React.ReactNode;
  extraTabs?: React.ReactNode;
  splitView?: boolean;
};
export const DetailsTab = ({
  tabHidden,
  toggleIn,
  canBeDisplayed,
  children,
  extraTabs,
  splitView
}: DetailsTabProps) => {
  return (
    <>
      <Styles.GraphDetailsTabContainer
        data-testid='graph-details-tab-container'
        hideAway={tabHidden}
        canBeDisplayed={canBeDisplayed}
        splitView={splitView ?? false}>
        <Styles.Slider>
          <Styles.TabTray>
            <Styles.CollapseTab data-testid='graph-details-tab-collapse-button' onClick={toggleIn} open={!tabHidden}>
              <Asset.ArrowToggle />
            </Styles.CollapseTab>
            {extraTabs}
          </Styles.TabTray>
          {children}
        </Styles.Slider>
      </Styles.GraphDetailsTabContainer>
    </>
  );
};
