import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
  containerPadding?: string;
  ignoreBottomPadding?: boolean;
}
export const FullPageIframe = styled.iframe<Props>(({ containerPadding: pad = '20px', ignoreBottomPadding }) => {
  const pb = ignoreBottomPadding ? '0px' : pad;
  return css`
    // This method of covering whole page chosen over "position:absolute" so that notification can properly push it down
    min-width: calc(100% + ${pad} + ${pad}); // account for negative margins
    height: calc(100% + ${pad} + ${pb}); // account for negative margins
    // we want the iframe to cover the whole page, including the padding set inside "Content" - leave bottom alone
    margin: -${pad} -${pad} -${pb};
  `;
});
