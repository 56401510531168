/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { DashboardApi } from "./dashboard_pb";
import type { GetSafeModeStatusResponse } from "./dashboard_pb";
import type { GetSafeModeStatusRequest } from "./dashboard_pb";
import type { GetUIFeatureFlagsResponse } from "./dashboard_pb";
import type { GetUIFeatureFlagsRequest } from "./dashboard_pb";
import type { GetTracingBasePathResponse } from "./dashboard_pb";
import type { GetTracingBasePathRequest } from "./dashboard_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetLogoutPathResponse } from "./dashboard_pb";
import type { GetLogoutPathRequest } from "./dashboard_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * App level APIs
 *
 * @generated from protobuf service rpc.gloo.solo.io.DashboardApi
 */
export interface IDashboardApiClient {
    /**
     * Get the logout path for the dashboard.
     *
     * @generated from protobuf rpc: GetLogoutPath(rpc.gloo.solo.io.GetLogoutPathRequest) returns (rpc.gloo.solo.io.GetLogoutPathResponse);
     */
    getLogoutPath(input: GetLogoutPathRequest, options?: RpcOptions): UnaryCall<GetLogoutPathRequest, GetLogoutPathResponse>;
    /**
     * Gets the value of the tracing base path flag.
     *
     * @generated from protobuf rpc: GetTracingBasePath(rpc.gloo.solo.io.GetTracingBasePathRequest) returns (rpc.gloo.solo.io.GetTracingBasePathResponse);
     */
    getTracingBasePath(input: GetTracingBasePathRequest, options?: RpcOptions): UnaryCall<GetTracingBasePathRequest, GetTracingBasePathResponse>;
    /**
     * Get the license access level of various feature flags
     *
     * @generated from protobuf rpc: GetUIFeatureFlags(rpc.gloo.solo.io.GetUIFeatureFlagsRequest) returns (rpc.gloo.solo.io.GetUIFeatureFlagsResponse);
     */
    getUIFeatureFlags(input: GetUIFeatureFlagsRequest, options?: RpcOptions): UnaryCall<GetUIFeatureFlagsRequest, GetUIFeatureFlagsResponse>;
    /**
     * @generated from protobuf rpc: GetSafeModeStatus(rpc.gloo.solo.io.GetSafeModeStatusRequest) returns (rpc.gloo.solo.io.GetSafeModeStatusResponse);
     */
    getSafeModeStatus(input: GetSafeModeStatusRequest, options?: RpcOptions): UnaryCall<GetSafeModeStatusRequest, GetSafeModeStatusResponse>;
}
/**
 * App level APIs
 *
 * @generated from protobuf service rpc.gloo.solo.io.DashboardApi
 */
export class DashboardApiClient implements IDashboardApiClient, ServiceInfo {
    typeName = DashboardApi.typeName;
    methods = DashboardApi.methods;
    options = DashboardApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Get the logout path for the dashboard.
     *
     * @generated from protobuf rpc: GetLogoutPath(rpc.gloo.solo.io.GetLogoutPathRequest) returns (rpc.gloo.solo.io.GetLogoutPathResponse);
     */
    getLogoutPath(input: GetLogoutPathRequest, options?: RpcOptions): UnaryCall<GetLogoutPathRequest, GetLogoutPathResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLogoutPathRequest, GetLogoutPathResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Gets the value of the tracing base path flag.
     *
     * @generated from protobuf rpc: GetTracingBasePath(rpc.gloo.solo.io.GetTracingBasePathRequest) returns (rpc.gloo.solo.io.GetTracingBasePathResponse);
     */
    getTracingBasePath(input: GetTracingBasePathRequest, options?: RpcOptions): UnaryCall<GetTracingBasePathRequest, GetTracingBasePathResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTracingBasePathRequest, GetTracingBasePathResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get the license access level of various feature flags
     *
     * @generated from protobuf rpc: GetUIFeatureFlags(rpc.gloo.solo.io.GetUIFeatureFlagsRequest) returns (rpc.gloo.solo.io.GetUIFeatureFlagsResponse);
     */
    getUIFeatureFlags(input: GetUIFeatureFlagsRequest, options?: RpcOptions): UnaryCall<GetUIFeatureFlagsRequest, GetUIFeatureFlagsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUIFeatureFlagsRequest, GetUIFeatureFlagsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSafeModeStatus(rpc.gloo.solo.io.GetSafeModeStatusRequest) returns (rpc.gloo.solo.io.GetSafeModeStatusResponse);
     */
    getSafeModeStatus(input: GetSafeModeStatusRequest, options?: RpcOptions): UnaryCall<GetSafeModeStatusRequest, GetSafeModeStatusResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSafeModeStatusRequest, GetSafeModeStatusResponse>("unary", this._transport, method, opt, input);
    }
}
