import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Line } from 'react-chartjs-2';

// Reference: https://stackoverflow.com/a/71429921/9796169
// import 'chart.js/auto';
import { CategoryScale, Chart, LinearScale, LineElement, PointElement } from 'chart.js';
Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

// 10 unique color options.
const chartColorOptions = [
  '#69ABD2',
  '#0FAC7C',
  '#E6BA64',
  '#325476',
  '#E99E5E',
  '#D75B1D',
  '#536985',
  '#8575CB',
  '#C46BAB',
  '#000000'
];

const TimelineGraphContainer = styled.div<{ fixedWidth?: string; fixedHeight?: string }>(
  ({ theme, fixedWidth, fixedHeight }) => css`
    position: relative;
    max-width: 100%;
    width: ${fixedWidth ?? 'auto'};
    height: ${fixedHeight ?? 'auto'};
  `
);

export function dateToTimelineGraphX(date: Date, multipleHours: boolean) {
  return [
    ...(multipleHours ? [date.getDay(), date.getHours()] : []),
    date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
    date.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  ].join(':');
}

type DatumFromSet = {
  data: { name: string; x: number | string; y: number }[];
  style?: {
    data: {
      [key: string]: string;
    };
  };
};
export interface TimelineGraphProps {
  dataset: DatumFromSet[];
  fixedHeight?: string;
  fixedWidth?: string;
  title?: string;
  description?: string;
  legendData?: {
    name: string;
    symbol?: { type: string };
  }[];
  yValueMeaning?: string;
}
export const TimelineGraph = ({
  dataset,
  fixedHeight,
  fixedWidth,
  title,
  description,
  legendData,
  yValueMeaning
}: TimelineGraphProps) => {
  // add the labels for each x of each line next to each other based on index then remove any duplicates.
  // In almost all cases they should match, but encase they don't this prevents the data from being lost
  const removeDuplicates = (arr: string[]) => [...new Set(arr)];
  const labels: string[] = removeDuplicates(
    Array.from({ length: Math.max(...dataset.map(d => d.data.length)) }).flatMap((_, i) =>
      dataset.map((datum: DatumFromSet) => datum.data[i].x + '')
    )
  );

  return (
    <TimelineGraphContainer data-testid='details-timeline' fixedHeight={fixedHeight} fixedWidth={fixedWidth}>
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              text: title,
              display: !!title
            }
          }
        }}
        data={{
          labels,
          datasets: dataset.map((datum, i) => {
            return {
              // If there is legend data, take the label provided. Sometimes we only provide a single label
              // If there is no legend data, fallback to the data's defaults
              label: legendData ? (legendData[i] ? legendData[i].name : legendData[0].name) : datum.data[0].name,
              backgroundColor: chartColorOptions[i % chartColorOptions.length],
              borderColor: chartColorOptions[i % chartColorOptions.length],
              fill: false,
              data: datum.data.map(d => {
                return { x: d.x, y: d.y };
              })
            };
          })
        }}
      />
    </TimelineGraphContainer>
  );
};
