import { dashboardHealthCodesMap, DashboardHealthKey } from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { getInsightData } from './dashboard/dashboard-helpers';
import { buildLogsUrl } from './url-builders';

export const getLogTooltipLabel = (insights: Insight[] | undefined, cluster: string, identifier: string) => {
  if (!!insights && insights.length > 1) {
    return `${cluster} / ${identifier}`;
  }
  return identifier;
};

//
// Gloo Logs Links
//

export const getGlooAgentLogsLinks = (insights: Insight[] | undefined) => {
  return (
    insights?.map(insight => {
      // The gloo agent insights are a bit different since they target the cluster, and not
      // the deployment. The deployment (aka component) info is in the data prop.
      const cluster = insight.target?.target.oneofKind === 'resource' ? insight.target?.target.resource.name ?? '' : '';
      const glooAgentData = getInsightData(insight, dashboardHealthCodesMap[DashboardHealthKey.glooAgent].dataProp);
      const component = glooAgentData?.deploymentRef?.name ?? '';
      const container = 'gloo-mesh-agent';
      return {
        label: getLogTooltipLabel(insights, cluster, container),
        link: buildLogsUrl({ cluster, component, container })
      };
    }) ?? []
  );
};

export const getGlooManagementPlaneLogsLinks = (insights: Insight[] | undefined) => {
  return (
    insights?.map(insight => {
      let cluster = '';
      let component = '';
      if (insight.target?.target.oneofKind === 'resource') {
        cluster = insight.target?.target.resource.clusterName ?? '';
        component = insight.target?.target.resource.name ?? '';
      }
      const container = 'gloo-mesh-mgmt-server';
      return {
        label: getLogTooltipLabel(insights, cluster, container),
        link: buildLogsUrl({ cluster, component, container })
      };
    }) ?? []
  );
};

//
// Cilium Logs Links
//

export const getCiliumAgentLogsLinks = (insights: Insight[] | undefined) => {
  return (
    insights?.map(insight => {
      let cluster = '';
      let component = '';
      if (insight.target?.target.oneofKind === 'resource') {
        cluster = insight.target?.target.resource.clusterName ?? '';
        component = insight.target?.target.resource.name ?? '';
      }
      const container = 'cilium-agent';
      return {
        label: getLogTooltipLabel(insights, cluster, container),
        link: buildLogsUrl({ cluster, component, container })
      };
    }) ?? []
  );
};

export const getCiliumOperatorLogsLinks = (insights: Insight[] | undefined) => {
  return (
    insights?.map(insight => {
      let cluster = '';
      let component = '';
      if (insight.target?.target.oneofKind === 'resource') {
        cluster = insight.target?.target.resource.clusterName ?? '';
        component = insight.target?.target.resource.name ?? '';
      }
      const container = 'cilium-operator';
      return {
        label: getLogTooltipLabel(insights, cluster, container),
        link: buildLogsUrl({ cluster, component, container })
      };
    }) ?? []
  );
};

//
// Istio Logs Links
//

export const GetIstioControlPlaneLogsLinks = (insights: Insight[] | undefined) => {
  return (
    insights?.map(insight => {
      let cluster = '';
      let component = '';
      if (insight.target?.target.oneofKind === 'resource') {
        cluster = insight.target?.target.resource.clusterName ?? '';
        component = insight.target?.target.resource.name ?? '';
      }
      const container = 'discovery';
      return {
        label: getLogTooltipLabel(insights, cluster, component),
        link: buildLogsUrl({ cluster, component, container })
      };
    }) ?? []
  );
};
