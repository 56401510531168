import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloDropdownStyles } from 'Components/Common/Input/SoloDropdown.style';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace WorkspacesLandingStyles {
  export const ContentBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;
  `;

  export const SortContainer = styled.div`
    display: flex;

    ${SoloDropdownStyles.SoloDropdownBlock} {
      &.ant-select .ant-select-selector {
        border-radius: 0 3px 3px 0;
      }
    }
  `;

  interface OrderDirectionToggleProps {
    reversed: boolean;
  }
  export const OrderDirectionToggle = styled.div<OrderDirectionToggleProps>(
    ({ theme, reversed }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      border: 1px solid ${theme.aprilGrey};
      border-right: 0;
      border-radius: 3px 0 0 3px;

      ${svgBasics(16)}
      svg {
        .sort-arrows--up {
          fill: ${reversed ? theme.splashBlue : theme.oceanBlue};
        }
        .sort-arrows--down {
          fill: ${reversed ? theme.oceanBlue : theme.splashBlue};
        }
      }
    `
  );
}
export namespace WorkspacesLandingEmptyStyles {
  export const RegisteringPromptContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 48px;
  `;
  export const WorkspaceContent = styled(RegisteringPromptContent)`
    height: 100%;
  `;
  export const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const RegisteringIconHolder = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 133px;
      height: 133px;
      border-radius: 133px;
      border: 1px solid ${theme.splashBlue};
      margin-bottom: 30px;

      ${svgBasics(80, theme.splashBlue)}
    `
  );
  export const RegisteringTitle = styled.div`
    font-size: 28px;
    text-align: center;
  `;
  export const RegisteringBody = styled.div`
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding: 25px 0;
  `;
  export const RegisterButtons = styled.div`
    display: flex;
    > * {
      margin-right: 20px;
    }
    > :last-child {
      margin-right: 0;
    }
  `;
}
export namespace AddWorkspaceModalBodyStyles {
  export const Container = styled.div`
    padding: 30px;
  `;
  export const Title = styled.div(() => {
    return css`
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 20px;

      svg {
        height: 28px;
        margin-left: 15px;
      }
    `;
  });
  export const Hint = styled.div(
    ({ theme }) => css`
      background: ${theme.dropBlue};
      border-radius: 8px;
      font-size: 14px;
      line-height: 44px;
      color: ${theme.septemberGrey};
      margin-bottom: 20px;
      padding: 0 11px;
    `
  );

  export const InstructionsArea = styled.div(
    ({ theme }) => css`
      background: ${theme.dropBlue};
      border: 1px solid ${theme.seaBlue};
      border-radius: 8px;
      color: ${theme.seaBlue};
      padding: 15px 11px;
    `
  );

  export const InstructionNumber = styled.div(
    ({ theme }) => css`
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      line-height: 23px;
      text-align: center;
      padding-top: 2px;
      width: 25px;
      height: 25px;
      font-weight: 500;
      font-size: 18px;
      border-radius: 100%;
      color: ${theme.seaBlue};
      background: ${theme.splashBlue};
      margin-right: 8px;
    `
  );

  export const InstructionHint = styled.div(
    ({ theme }) => css`
      position: relative;
      padding-left: 40px;
      font-size: 16px;
      line-height: 22px;
      color: ${theme.neptuneBlue};
      margin-bottom: 35px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `
  );
}
