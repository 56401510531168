import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn
} from 'Components/Common/SoloTableCells';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { BasicDestinationsTable } from '../../Destinations/BasicDestinationsTable';

const { useGetActiveHealthCheckPolicyDetails } = policiesApi;

const policyType = PolicyType.ACTIVE_HEALTH_CHECK;

type VGTableFields = {
  key: string;
  name: StateAndNameType;
  namespace?: string;
  clusterName?: string;
};

const vgColumns: SoloColumnsType<VGTableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' })
];

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ActiveHealthCheckDetails = ({ clusterObjRef }: Props) => {
  di(useGetActiveHealthCheckPolicyDetails);
  const destPagingData = useSoloPaging();
  const virtGatewayPagingData = useSoloPaging();

  const { data: policyData, error: policyError } = useGetActiveHealthCheckPolicyDetails(
    clusterObjRef,
    destPagingData.apiPaginationObject,
    virtGatewayPagingData.apiPaginationObject
  );

  destPagingData.useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);
  virtGatewayPagingData.useSetPagingTotal(policyData?.virtualGateways?.totalVirtualGateways);

  const vgTableData = useMemo<VGTableFields[]>(
    () =>
      policyData?.virtualGateways?.virtualGateways.map(vg => ({
        ...vg.ref,
        key: buildIdFromRef(vg.ref),
        name: { name: vg.ref?.name ?? '', status: vg.status }
      })) ?? [],
    [policyData?.virtualGateways?.virtualGateways]
  );

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
        secondaryInfo={[
          {
            label: 'Health Check Type',
            // Detects type based on oneof
            data:
              policyData.healthCheckType.oneofKind === 'httpHealthCheck'
                ? 'HTTP'
                : policyData.healthCheckType.oneofKind === 'tcpHealthCheck'
                ? 'TCP'
                : policyData.healthCheckType.oneofKind === 'grpcHealthCheck'
                ? 'gRPC'
                : 'Custom'
          }
        ]}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Virtual Gateways</CardStyles.CardHeader>
        <SoloTable columns={vgColumns} dataSource={vgTableData} paging={virtGatewayPagingData.pagingData} />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable
          destinations={policyData.destinations?.destinations}
          paging={destPagingData.pagingData}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
