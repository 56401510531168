import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { useState } from 'react';
import { capitalizeFirstLetters } from 'utils/helpers';
import { CertificateDetailsModal } from '../Modals/CertificateDetailsModal';

type DetailsModalInfo = Parameters<typeof CertificateDetailsModal>[0];
export interface SharedCertData {
  key: string;
  issuedToCn: string;
  hostname: string;
  certType: string;
  cluster: string;
  expiryDays: number;
  expiration: string;
  detailsModalProps: Omit<DetailsModalInfo, 'onClose'>;
}
export const CertificatesLandingBody = (props: CustomLandingBodyProps<SharedCertData>) => {
  return <LandingBody {...props} GridComponent={undefined} TableComponent={CertificatesLandingBodyTable} />;
};

//
// Table variant
//
const CertificatesLandingBodyTable = ({ items: tableData, pagingData }: BaseLandingBodyProps<SharedCertData>) => {
  const [detailsModalInfo, setDetailsModalInfo] = useState<DetailsModalInfo>();

  const columns: SoloColumnsType<SharedCertData> = [
    { title: 'Hostname', dataIndex: 'hostname' },
    { title: 'Issued to', dataIndex: 'issuedToCn' },
    { title: 'Type', dataIndex: 'certType', render: certType => <>{capitalizeFirstLetters(certType)}</> },
    { title: 'Cluster', dataIndex: 'cluster' },
    { title: 'Expiration', dataIndex: 'expiration' },
    {
      title: 'Expires (Days)',
      dataIndex: 'expiryDays',
      render: expiryDays => <>{expiryDays < 0 ? 'Expired' : expiryDays}</>
    },
    {
      title: 'Details',
      dataIndex: 'detailsModalProps',
      render: curDetailsModalProps => (
        <FlexLayout alignItems='center' justifyContent='center'>
          <SoloLinkStyles.SoloLinkLooks onClick={() => setDetailsModalInfo(curDetailsModalProps)} withArrow>
            DETAILS
          </SoloLinkStyles.SoloLinkLooks>
        </FlexLayout>
      )
    }
  ];

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable data-testid='certificates-landing-body' dataSource={tableData} columns={columns} paging={pagingData} />
      {detailsModalInfo && (
        <CertificateDetailsModal {...detailsModalInfo} onClose={() => setDetailsModalInfo(undefined)} />
      )}
    </Spacer>
  );
};
