import { CardStyles } from 'Components/Common/Card';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import {
  Insight_SYS0009Data,
  Insight_SYS0010Data
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { DashboardStyles } from '../../DashboardLanding.style';
import { FIPSCardContent } from './FIPSCardContent';
import { docLinks } from 'utils/url-external-links-map';

const controlPlane: Insight_SYS0009Data[] = [
  {
    fipsCompliantIstioWorkloads: 14,
    totalIstioWorkloads: 14,
    uniqueFipsVersions: []
  }
];
const dataPlane: Insight_SYS0010Data[] = [
  {
    fipsCompliantIstioWorkloads: 99000,
    totalIstioWorkloads: 110000,
    uniqueFipsVersions: []
  }
];

export const FIPSCardFakeData = () => {
  return (
    <CardStyles.Card data-testid='fips-card'>
      <DashboardStyles.CardColumn>
        <DashboardStyles.Header>
          <Svg asset={<Asset.FipsIcon />} color={theme => theme.neptuneBlue} />
          Istio FIPS
        </DashboardStyles.Header>
        <FIPSCardContent controlPlane={controlPlane} dataPlane={dataPlane} />
        <DashboardStyles.CardBottomLink>
          <ExternalSoloLink small href={docLinks.core.insights_HASH.review_fips_compliance} newTab withArrow>
            DOCS
          </ExternalSoloLink>
        </DashboardStyles.CardBottomLink>
      </DashboardStyles.CardColumn>
    </CardStyles.Card>
  );
};
