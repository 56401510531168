import * as React from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SoloCheckboxStyles as Styles } from './SoloCheckbox.style';
import { SoloLabel } from './SoloLabel';
import { useSoloId } from 'utils/hooks';

export interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (e: CheckboxChangeEvent) => any;
  id?: string;
  title?: React.ReactNode;
  label?: boolean;
  withWrapper?: boolean;
}

export const SoloCheckbox: React.FC<CheckboxProps> = props => {
  const { title, checked, onChange, withWrapper, label, disabled, id } = props;
  const inputId = useSoloId(id);

  if (!!withWrapper) {
    return (
      <Styles.CheckboxWrapper data-testid='solo-checkbox-wrapper' checked={checked}>
        {!title ? null : label ? <SoloLabel htmlFor={inputId}>{title}</SoloLabel> : title}
        <Checkbox id={label ? inputId : undefined} disabled={disabled} checked={checked} onChange={onChange} />
      </Styles.CheckboxWrapper>
    );
  }

  return (
    <Styles.OnlyCheckbox data-testid='solo-checkbox-no-wrapper'>
      <Checkbox disabled={disabled} checked={checked} onChange={onChange}>
        {label ? title : undefined}
      </Checkbox>
    </Styles.OnlyCheckbox>
  );
};
