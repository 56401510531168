import { Asset } from 'assets';
import { DataError } from 'Components/Common/DataError';
import { SoloEmptySimple } from 'Components/Common/SoloEmpty';
import { PagingProps, SoloPagination } from 'Components/Common/SoloPagination';
import { Svg } from 'Components/Common/Svg';
import { Route } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { HTTPRouteDelegatedItem } from './RouteItems/HTTPRouteDelegatedItem';
import { HttpRouteItem } from './RouteItems/HttpRouteItem';
import { TcpRouteItem } from './RouteItems/TcpRouteItem';
import { TlsRouteItem } from './RouteItems/TlsRouteItem';

interface RoutesListDisplayProps {
  routes?: Route[];
  pagingData: PagingProps;
  routeTableRef: ClusterObjectRef | undefined;
  parentRef: ClusterObjectRef;
}
export const RoutesListDisplay = ({ routes, pagingData, routeTableRef, parentRef }: RoutesListDisplayProps) => {
  di(useNamespaceClusterToWorkspaceMap);
  const { error: workspaceMapError } = useNamespaceClusterToWorkspaceMap();

  if (!!workspaceMapError) {
    return <DataError error={workspaceMapError} />;
  }

  return (
    <div>
      {!!routes?.length ? (
        <>
          {routes.map((route, i) =>
            route.routeType.oneofKind === 'httpRoute' ? (
              <HttpRouteItem
                key={route.routeType.httpRoute.name}
                route={route.routeType.httpRoute}
                routeTableRef={routeTableRef}
                parentRef={parentRef}
              />
            ) : route.routeType.oneofKind === 'httpRouteDelegated' ? (
              <HTTPRouteDelegatedItem
                key={route.routeType.httpRouteDelegated.routeNames
                  .map(r => `${r.routeTable?.name ?? '<u>'}.${r.routeName}`)
                  .join('--')}
                route={route.routeType.httpRouteDelegated}
                routeTableRef={routeTableRef}
                parentRef={parentRef}
              />
            ) : route.routeType.oneofKind === 'tcpRoute' ? (
              <TcpRouteItem key={`tcp${i}`} route={route.routeType.tcpRoute} parentRef={parentRef} />
            ) : route.routeType.oneofKind === 'tlsRoute' ? (
              <TlsRouteItem key={`tls${i}`} route={route.routeType.tlsRoute} parentRef={parentRef} />
            ) : undefined
          )}
          <SoloPagination {...pagingData} pageSizeOptions={[5, 10, 20, 50]} />
        </>
      ) : (
        <SoloEmptySimple
          description='No routes in route table'
          icon={<Svg color={thm => thm.aprilGrey} asset={<Asset.RouteGroupIcon />} />}
        />
      )}
    </div>
  );
};
