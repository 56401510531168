import { overviewApi } from 'Api/overview';
import { Loading } from 'Components/Common/Loading';
import { SoloLink } from 'Components/Common/SoloLink';
import { Asset } from 'assets';
import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { Permission, usePermissions } from 'utils/permissions';
import { buildWorkspaceDetailsUrl } from 'utils/url-builders';
import { DataError } from '../../Common/DataError';
import { OverviewDetailsStyles } from './OverviewDetails.style';

const { useGetWorkspace } = overviewApi;

type OverviewWorkspaceDetailsProps = {
  workspaceRef?: ObjectRef;
};
export const OverviewWorkspaceDetails = ({ workspaceRef }: OverviewWorkspaceDetailsProps) => {
  di(useGetWorkspace);
  const { data: workspaceDetails, error: workspaceDetailsError } = useGetWorkspace(workspaceRef);
  const { hasPerm } = usePermissions();

  return (
    <OverviewDetailsStyles.Container data-testid='overview-workspace-details-container'>
      {!!workspaceDetailsError ? (
        <div>
          <DataError error={workspaceDetailsError} center={true} />
        </div>
      ) : !workspaceDetails ? (
        <Loading message={`Retrieving workspace ${workspaceRef?.name ?? ''}...`} />
      ) : (
        <OverviewDetailsStyles.SubDetails>
          <OverviewDetailsStyles.SubDetailsRow>
            <OverviewDetailsStyles.SubDetail>
              <OverviewDetailsStyles.SubDetailsTitle>
                <div>Imports / Exports</div>
                <Asset.ImportExportIcon />
              </OverviewDetailsStyles.SubDetailsTitle>
              <OverviewDetailsStyles.SubDetailsBody>
                <div>
                  <div>Resources Exported</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.exportedResources}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Workspaces Imported</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.importedWorkspaces}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Resources Imported</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.importedResources}</OverviewDetailsStyles.Number>
                </div>
              </OverviewDetailsStyles.SubDetailsBody>
            </OverviewDetailsStyles.SubDetail>
            <OverviewDetailsStyles.SubDetail>
              <OverviewDetailsStyles.SubDetailsTitle>
                <div>Destinations</div> <Asset.ServiceIcon />
              </OverviewDetailsStyles.SubDetailsTitle>
              <OverviewDetailsStyles.SubDetailsBody>
                <div>
                  <div>K8s Services</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.services}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Virtual Destinations</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.virtualDestinations}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>External Services</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.externalServices}</OverviewDetailsStyles.Number>
                </div>
              </OverviewDetailsStyles.SubDetailsBody>
            </OverviewDetailsStyles.SubDetail>
          </OverviewDetailsStyles.SubDetailsRow>
          <OverviewDetailsStyles.SubDetailsRow>
            <OverviewDetailsStyles.SubDetail>
              <OverviewDetailsStyles.SubDetailsTitle>
                <div>Policies</div>
                <Asset.PolicyRuleIcon />
              </OverviewDetailsStyles.SubDetailsTitle>
              <OverviewDetailsStyles.SubDetailsBody>
                <div>
                  <div>Security</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.securityPolicies}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Resiliency</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.resiliencyPolicies}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Traffic Control</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.trafficControlPolicies}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Observability</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.observabilityPolicies}</OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Extension</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{workspaceDetails.extensionPolicies}</OverviewDetailsStyles.Number>
                </div>
              </OverviewDetailsStyles.SubDetailsBody>
            </OverviewDetailsStyles.SubDetail>
            {hasPerm(Permission.GraphQLEnabled) && (
              <OverviewDetailsStyles.SubDetail data-testid='overview-workspace-details-graphql'>
                <OverviewDetailsStyles.SubDetailsTitle>
                  <div>APIs</div>
                  <Asset.ApiIcon />
                </OverviewDetailsStyles.SubDetailsTitle>
                <OverviewDetailsStyles.SubDetailsBody>
                  <div>
                    <div>GraphQL - Resolved</div>
                    <OverviewDetailsStyles.DottedLined />
                    <OverviewDetailsStyles.Number>{workspaceDetails.resolvedGraphqlApis}</OverviewDetailsStyles.Number>
                  </div>
                  <div>
                    <div>GraphQL - Proxied</div>
                    <OverviewDetailsStyles.DottedLined />
                    <OverviewDetailsStyles.Number>{workspaceDetails.proxiedGraphqlApis}</OverviewDetailsStyles.Number>
                  </div>
                  <div>
                    <div>GraphQL - Stitched</div>
                    <OverviewDetailsStyles.DottedLined />
                    <OverviewDetailsStyles.Number>{workspaceDetails.stitchedGraphqlApis}</OverviewDetailsStyles.Number>
                  </div>
                  <div>
                    <div>OpenAPI</div>
                    <OverviewDetailsStyles.DottedLined />
                    <OverviewDetailsStyles.Number>{workspaceDetails.openapiApis}</OverviewDetailsStyles.Number>
                  </div>
                </OverviewDetailsStyles.SubDetailsBody>
              </OverviewDetailsStyles.SubDetail>
            )}
          </OverviewDetailsStyles.SubDetailsRow>

          <OverviewDetailsStyles.MoreLinkLine>
            <SoloLink link={buildWorkspaceDetailsUrl(workspaceRef?.name)} data-testid='overview-moreworkspacedetails'>
              MORE DETAILS <Asset.ArrowToggle />
            </SoloLink>
          </OverviewDetailsStyles.MoreLinkLine>
        </OverviewDetailsStyles.SubDetails>
      )}
    </OverviewDetailsStyles.Container>
  );
};
