import { css } from '@emotion/react';
import styled from '@emotion/styled';

namespace Styles {
  export const HttpMethodBubbleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  `;

  export const HttpMethodBubbleItem = styled.span<HttpMethodBubbleProps>(
    ({ theme, method, small }) => css`
      display: inline-flex;
      color: white;
      background: ${(function () {
        method = method.toLowerCase();
        switch (method) {
          case 'get':
            return theme.seaBlue;
          case 'post':
            return theme.forestGreen;
          case 'put':
            return theme.sunGold;
          case 'delete':
            return theme.pumpkinOrange;
          case 'options':
            return theme.peachOrange;
          case 'head':
            return theme.lakeBlue;
        }
        return theme.augustGrey;
      })()};
      ${!small
        ? css`
            font-size: 12px;
            border-radius: 12px;
            padding: 3px 12px;
          `
        : css`
            font-size: 10px;
            line-height: 18px;
            border-radius: 9px;
            padding: 0 12px;
          `}
    `
  );
}

export const HttpMethodBubbleContainer = Styles.HttpMethodBubbleContainer;

interface HttpMethodBubbleProps {
  method: string;
  small?: boolean;
}
export const HttpMethodBubble = ({ method, small }: HttpMethodBubbleProps) => {
  return (
    <Styles.HttpMethodBubbleItem data-testid='http-method-bubble' method={method} small={small}>
      {method.toUpperCase()}
    </Styles.HttpMethodBubbleItem>
  );
};

interface HttpMethodBubbleListProps {
  methods: string[];
  small?: boolean;
}
export const HttpMethodBubbleList = ({ methods, small }: HttpMethodBubbleListProps) => {
  return (
    <HttpMethodBubbleContainer>
      {methods.map((method: string) => (
        <HttpMethodBubble key={method} method={method} small={small} />
      ))}
    </HttpMethodBubbleContainer>
  );
};
