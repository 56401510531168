import cytoscape from 'cytoscape';
import { GraphStatus } from '../General/graph-selection-utils';

export interface CyCanvasData {
  layer: any;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
}

// Node opacity is put here rather than the graph.ts styling file because
//   we need to hide the real ones for drawing
export function getNodeOpacity(node: cytoscape.SingularElementReturnValue): number {
  let opacityMultiplier = 1;

  if (node.hasClass('unselected')) {
    opacityMultiplier *= 0.5;
  } else if (node.hasClass('selected')) {
    // Keep it 1
  } else if (node.hasClass('onlyErrorsHighlight')) {
    const nodeHealth = node.data('health');
    if (nodeHealth === GraphStatus.Error || nodeHealth === GraphStatus.Warning) {
      // Keep it 1
    } else {
      opacityMultiplier *= 0.5;
    }
  }

  if (node.data('externalFromSearch') === true) {
    opacityMultiplier *= 0.5;
  }

  return opacityMultiplier;
}

export function drawShape(ctx: CanvasRenderingContext2D, points: number[]) {
  ctx.beginPath();
  ctx.moveTo(points[0], points[1]);
  for (let i = 2; i + 1 < points.length; i += 2) {
    ctx.lineTo(points[i], points[i + 1]);
  }
  ctx.lineTo(points[0], points[1]);
  ctx.closePath();
}

/**
 * https://github.com/cytoscape/cytoscape.js/blob/e6541de603f5714f572238573ea06ef17503282b/src/extensions/renderer/base/coord-ele-math/labels.js#L413
 * Returns text whose display width does not exceed the given maxWidth.
 * If the given text is too wide, it will be truncated so that text + ellipsis
 * is still less than maxWidth wide.
 */
export function truncateText(ctx: CanvasRenderingContext2D, text: string, maxW: number, ellipsis = '\u2026') {
  let ellipsized = '';
  let incLastCh = false;

  if (ctx.measureText(text).width < maxW) {
    // the label already fits
    return text;
  }

  for (let i = 0; i < text.length; i++) {
    let widthWithNextCh = ctx.measureText(ellipsized + text[i] + ellipsis).width;

    if (widthWithNextCh > maxW) {
      break;
    }

    ellipsized += text[i];

    if (i === text.length - 1) {
      incLastCh = true;
    }
  }

  if (!incLastCh) {
    ellipsized += ellipsis;
  }

  return ellipsized;
}
