import { logsApi } from 'Api/logs';
import { Loading } from 'Components/Common/Loading';
import SoloLogViewer from 'Components/Common/SoloLogViewer/SoloLogViewer';
import { SoloLogViewerToolbarWithServiceSelection } from 'Components/Common/SoloLogViewer/SoloLogViewerToolbar';
import { GetContainerLogsStreamResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/logs_pb';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { encodeUrlSearchParamValue, getAndDecodeUrlSearchParams } from 'utils/url-builder-helpers';
import { logsSearchParamKeys } from 'utils/url-builders';
const { useGetAvailableComponentInfo, getContainerLogsStream: createListLogsStream } = logsApi;

const MAX_LOG_LINES_TO_SHOW = 50000;

const SoloLogsContainer = () => {
  const { data: availableComponentInfoRaw } = useGetAvailableComponentInfo();
  const isLoading = availableComponentInfoRaw === undefined;

  //
  // State for the SoloLogViewer.
  //
  const [cluster, setCluster] = useState('');
  const [component, setComponent] = useState('');
  const [pod, setPod] = useState('');
  const [container, setContainer] = useState('');

  const readyToStream = !!cluster && !!component && !!pod && !!container;

  const [clusterIsInitialized, setClusterIsInitialized] = useState(false);
  const [componentIsInitialized, setComponentIsInitialized] = useState(false);
  const [podIsInitialized, setPodIsInitialized] = useState(false);
  const [containerIsInitialized, setContainerIsInitialized] = useState(false);

  //
  // Gets the initial URL search parameters
  //
  const [initialClusterValue, initialComponentValue, initialPodValue, initialContainerValue] = useMemo(() => {
    const decodedValues = getAndDecodeUrlSearchParams<string>(
      logsSearchParamKeys.cluster,
      logsSearchParamKeys.component,
      logsSearchParamKeys.pod,
      logsSearchParamKeys.container
    );
    return decodedValues;
  }, []);

  //
  // Updates the search parameters when the values change.
  //
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);
    // Set each value
    if (clusterIsInitialized) {
      newSearchParams.set(logsSearchParamKeys.cluster, encodeUrlSearchParamValue(cluster));
    } else {
      newSearchParams.delete(logsSearchParamKeys.cluster);
    }
    if (componentIsInitialized) {
      newSearchParams.set(logsSearchParamKeys.component, encodeUrlSearchParamValue(component));
    } else {
      newSearchParams.delete(logsSearchParamKeys.component);
    }
    if (podIsInitialized) {
      newSearchParams.set(logsSearchParamKeys.pod, encodeUrlSearchParamValue(pod));
    } else {
      newSearchParams.delete(logsSearchParamKeys.pod);
    }
    if (containerIsInitialized) {
      newSearchParams.set(logsSearchParamKeys.container, encodeUrlSearchParamValue(container));
    } else {
      newSearchParams.delete(logsSearchParamKeys.container);
    }
    const newSearchParamsString = `?${newSearchParams.toString()}`;
    if (window.location.search === newSearchParamsString) {
      // Stop here if there were no changes to the search params.
      return;
    }
    const newHistoryState = {
      pathname: location.pathname,
      search: newSearchParamsString
    };
    navigate(newHistoryState, { replace: true });
  }, [cluster, component, pod, container]);

  //
  // This gets the clusters/components/etc out of the
  // response and initializes state if needed.
  //
  // Repeated for each item.
  // It's not exactly the same though since each mapping
  // is nested and depends on the parent.
  //
  const [availableClusterMap, clusterOptions] = useMemo(() => {
    if (!availableComponentInfoRaw) {
      setCluster('');
      return [{}, []];
    }
    const newMap = availableComponentInfoRaw.attributes;
    const newMapKeys = Object.keys(newMap);
    if (!newMap[cluster ?? '']) {
      // If it exists, set the initial value if this is the first time.
      if (!clusterIsInitialized && !!initialClusterValue && newMap[initialClusterValue]) {
        setCluster(initialClusterValue);
        setClusterIsInitialized(true);
      } else if (newMapKeys.length === 1) {
        setCluster(newMapKeys[0]);
        setClusterIsInitialized(true);
      } else {
        setCluster('');
      }
    }
    return [newMap, newMapKeys];
  }, [availableComponentInfoRaw]);

  const [availableComponentMap, componentOptions] = useMemo(() => {
    if (!availableClusterMap[cluster ?? '']) {
      setComponent('');
      return [{}, []];
    }
    const newMap = availableClusterMap[cluster ?? ''].components;
    const newMapKeys = Object.keys(newMap);
    if (!newMap[component ?? '']) {
      // Set the initial value if this is the first time and it is valid.
      if (!componentIsInitialized && !!initialComponentValue && newMap[initialComponentValue]) {
        setComponent(initialComponentValue);
        setComponentIsInitialized(true);
      } else if (newMapKeys.length === 1) {
        setComponent(newMapKeys[0]);
        setComponentIsInitialized(true);
      } else {
        setComponent('');
      }
    }
    return [newMap, newMapKeys];
  }, [availableClusterMap, cluster]);

  const [availablePodsMap, podOptions] = useMemo(() => {
    if (!availableComponentMap[component ?? '']) {
      setPod('');
      return [{}, []];
    }
    const newMap = availableComponentMap[component ?? ''].pods;
    const newMapKeys = Object.keys(newMap);
    if (!newMap[pod ?? '']) {
      // Set the initial value if this is the first time and it is valid.
      if (!podIsInitialized && !!initialPodValue && newMap[initialPodValue]) {
        setPod(initialPodValue);
        setPodIsInitialized(true);
      } else if (newMapKeys.length === 1) {
        setPod(newMapKeys[0]);
        setPodIsInitialized(true);
      } else {
        setPod('');
      }
    }
    return [newMap, newMapKeys];
  }, [availableComponentMap, component]);

  const containerOptions = useMemo(() => {
    if (!availablePodsMap[pod ?? '']) {
      setContainer('');
      return [];
    }
    const newContainerOptions = availablePodsMap[pod ?? ''].containers;
    if (!newContainerOptions.includes(container ?? '')) {
      // Set the initial value if this is the first time and it is valid.
      if (!containerIsInitialized && !!initialContainerValue && newContainerOptions.includes(initialContainerValue)) {
        setContainer(initialContainerValue);
        setContainerIsInitialized(true);
      } else if (newContainerOptions.length === 1) {
        setContainer(newContainerOptions[0]);
        setContainerIsInitialized(true);
      } else {
        setContainer('');
      }
    }
    return newContainerOptions;
  }, [availablePodsMap, pod]);

  //
  // Set up the log stream based on the selections.
  //
  const logsRef = useRef<string[]>([]);
  const updateTimeoutRef = useRef<NodeJS.Timeout>();
  const [, setLogsLastUpdateTime] = useState<number>();

  const apiserverErrors = useRef<string[]>([]);
  useEffect(() => {
    if (!cluster || !component || !pod || !container) {
      return;
    }
    const newLogStream = createListLogsStream(cluster, component, pod, container);
    const dataCallback = (data: GetContainerLogsStreamResponse) => {
      const handleCallback = () => {
        // Adding randomness can simulate change in the data. Uncomment for testing.
        // let newLogs = [...logsRef.current, ...data.logs.map(l => Math.random() + l)];
        let newLogs = [...logsRef.current, ...data.logs];
        //
        // This slices the logs so no more than MAX_LOG_LINES_TO_SHOW are kept.
        newLogs = newLogs.slice(Math.max(newLogs.length - MAX_LOG_LINES_TO_SHOW, 0));
        logsRef.current = newLogs;
        const newApiserverErrors = data.apiserverErrors;
        apiserverErrors.current = newApiserverErrors;
        if (!!newApiserverErrors.length) {
          // eslint-disable-next-line no-console
          console.error('logged errors', [...newApiserverErrors]);
        }
        //
        // We need to setLogsLastUpdateTime here to queue a re-render when logsRef.current changes.
        if (updateTimeoutRef.current) {
          clearTimeout(updateTimeoutRef.current);
        }
        updateTimeoutRef.current = setTimeout(() => {
          setLogsLastUpdateTime(Date.now);
        }, 500);
      };
      // Setting a timeout prevents this from being UI blocking.
      setTimeout(() => {
        handleCallback();
      }, 0);
      // This can be uncommented for stress-testing.
      // setInterval(() => {
      //   handleCallback();
      // }, 1000);
    };
    newLogStream.onMessage(dataCallback);
    const errorCallback = (err: Error) => {
      // These log stream errors come from the browser and don't necessarily affect the stream.
      // So we can log them here for reference, and not show them on the page.
      // eslint-disable-next-line no-console
      console.error('stream error event', err);
    };
    newLogStream.onError(errorCallback);
    return () => {
      logsRef.current = [];
    };
  }, [cluster, component, pod, container]);

  //
  // Render
  //
  if (isLoading) {
    return <Loading />;
  }
  return (
    <SoloLogViewer
      isWaitingForSelection={!container}
      errorsList={apiserverErrors.current}
      dataSources={{
        initialDataSource: {
          data: logsRef.current,
          isStreaming: readyToStream,
          label: `logs:${cluster}.${component}.${pod}.${container}`
        }
      }}
      ToolbarComponent={SoloLogViewerToolbarWithServiceSelection}
      cluster={cluster}
      clusterOptions={clusterOptions}
      onClusterSelected={c => {
        setCluster(c);
        setClusterIsInitialized(true);
      }}
      component={component}
      componentOptions={componentOptions}
      onComponentSelected={c => {
        setComponent(c);
        setComponentIsInitialized(true);
      }}
      pod={pod}
      podOptions={podOptions}
      onPodSelected={c => {
        setPod(c);
        setPodIsInitialized(true);
      }}
      container={container}
      containerOptions={containerOptions}
      onContainerSelected={c => {
        setContainer(c);
        setContainerIsInitialized(true);
      }}
    />
  );
};

export default SoloLogsContainer;
