import React from 'react';
import { LoadingStyles as Styles } from './Loading.style';

interface LoadingProps {
  message?: string;
  center?: boolean;
  small?: boolean;
  tiny?: boolean;
  floating?: boolean;
}

export const Loading = ({ center = true, message, small, tiny, floating }: LoadingProps) => {
  const [degrees, setDegrees] = React.useState(0);

  React.useEffect(() => {
    const spinterval = setInterval(() => {
      setDegrees(oldDegree => (oldDegree + 60) % 360);
    }, 200);

    return () => clearInterval(spinterval);
  }, []);

  return (
    <Styles.Container tiny={tiny} small={small} center={center} floating={floating} data-testid='loading-container'>
      <div>
        <Styles.Rotater degrees={degrees} size={tiny ? 20 : small ? 41 : 141}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 141.735 161.207'>
            <g opacity='.695'>
              <path className='skip-fill-replace' d='M124.544 109.2l17.191 9.96v-76.8l-17.191 9.861z' fill='#b1e7fe' />
              <path
                className='skip-fill-replace'
                fill='#b1e7ff'
                d='M19.108 112.701l-17.243 9.893 66.88 38.613v-19.85z'
              />
              <path className='skip-fill-replace' d='M17.19 51.918l-17.191-9.96v76.8l17.191-9.861z' fill='#6ac7f0' />
              <path className='skip-fill-replace' d='M68.742 19.85V0L2.289 38.367l17.161 9.942z' fill='#54b7e3' />
              <path className='skip-fill-replace' d='M122.55 48.461l17.246-9.893-66.8-38.569v19.85z' fill='#2196c9' />
              <path
                className='skip-fill-replace'
                fill='#b1e7ff'
                d='M72.993 141.356v19.85l66.536-38.413-17.161-9.942z'
              />
            </g>
          </svg>
        </Styles.Rotater>
      </div>
      {!!message && <Styles.Message>{message}</Styles.Message>}
    </Styles.Container>
  );
};
