import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { DataErrorStringList } from 'Components/Common/DataError';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloGridTableToggle, { isTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { DefaultTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { Svg } from 'Components/Common/Svg';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { TrafficIngressLandingBody } from './TrafficIngressLandingBody';

const { useListIngressGateways } = overviewApi;

// const ingressTypeOptions = [
//   {
//     key: 'k8s-gateway',
//     value: 'k8s-gateway',
//     label: 'K8s Gateway'
//   },
//   {
//     key: 'istio-gw',
//     value: 'istio-gw',
//     label: 'Istio Gateway'
//   }
// ];

export const TrafficIngressLanding = () => {
  di(useListIngressGateways, usePermissionGateRedirect);
  usePermissionGateRedirect(Permission.ViewIngress);
  //
  // Paginate data
  const { pagingData, useSetPagingTotal, apiPaginationObject } = useSoloPaging(DefaultTablePageSize);
  //
  // Table/grid
  const [isTable, setIsTable] = useState(isTableViewPreferred());
  //
  // Filters
  const [nameFilter, setNameFilter] = useState('');
  // const [typeFilter, setTypeFilter] = useState(ingressTypeOptions.map(o => o.value));
  // const filtersOn = !!nameFilter.length || typeFilter.length < ingressTypeOptions.length;
  const filtersOn = !!nameFilter.length;
  //
  // Fetch data
  const { data: ingressGWs, error: ingressGWsError } = useListIngressGateways(nameFilter, apiPaginationObject);
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(ingressGWs);
  useSetPagingTotal(ingressGWs?.filteredTotalIngressGateways);
  const isLoading = ingressGWs === undefined;
  const errorsList = ingressGWs?.apiserverErrors;

  //
  // Render
  //
  return (
    <OverviewStyles.Container data-testid='traffic-ingress-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.IngressIcon />}
          Ingress
        </OverviewStyles.Header.Title>
        {ingressGWs && (
          <FlexLayout flexGrow={1} justifyContent='flex-start' ml={4}>
            <SecondaryInfo
              items={[
                {
                  key: 'istio-gw-proxies',
                  data: (
                    <>
                      <Svg height={15} asset={<Asset.GatewayIcon />} color={theme => theme.seaBlue} />
                      <Spacer mx={1}>
                        <b>{ingressGWs.numIstioGatewayProxies}</b>
                      </Spacer>
                      Istio Gateway Prox
                      {ingressGWs.numIstioGatewayProxies !== 1 ? 'ies' : 'y'}
                    </>
                  )
                },
                {
                  key: 'istio-gw-crs',
                  data: (
                    <>
                      <Svg height={15} asset={<Asset.IstioLogo />} color={theme => theme.seaBlue} />
                      <Spacer mx={1}>
                        <b>{ingressGWs.numIstioGatewayCrs}</b>
                      </Spacer>
                      Istio Gateway CR
                      {ingressGWs.numIstioGatewayCrs !== 1 && 's'}
                    </>
                  )
                }
              ]}
            />
          </FlexLayout>
        )}
        {!ingressGWsError && (
          <OverviewStyles.Header.FiltersWrapper>
            <OverviewStyles.Header.Filters filters={1} hasTableToggle={true}>
              <SoloInput
                searchIcon
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label={'Search by name'}
                onChange={evt => setNameFilter(evt.target.value)}
              />
              <SoloGridTableToggle isTable={isTable} onChange={newIsTable => setIsTable(newIsTable)} />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <div>
        {!!errorsList?.length && <DataErrorStringList errorsList={errorsList} />}
        <TrafficIngressLandingBody
          filtersOn={filtersOn}
          isLoading={isLoading}
          itemsError={ingressGWsError}
          isTable={isTable}
          items={ingressGWs?.ingressGatewaySummaries ?? []}
          pagingData={pagingData}
          data-testid='traffic-ingress-landing-body-empty'
          icon={<Asset.GatewayIcon />}
          docsLink={false}
          resourceNamePlural='Ingress Gateways'
        />
      </div>
    </OverviewStyles.Container>
  );
};
