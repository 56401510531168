import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace ApisLandingStyles {
  export const CardFooterRight = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
  `;
  export const ApiExplorerButtonContainer = styled.div<{ disabled: boolean }>(
    ({ theme, disabled }) => css`
      position: absolute;
      top: 0;
      left: 0;
      ${svgBasics(20, disabled ? theme.juneGrey : theme.seaBlue)}
      pointer-events: all;
      cursor: default;
    `
  );
  export const TooltipTitleTop = styled.div(
    ({ theme }) => css`
      font-size: 90%;
      padding: 2px 8px 0 8px;
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      color: white;
      margin-top: -5px;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -8px;
        bottom: -3px;
        right: -8px;
        z-index: -1;
        background-color: ${theme.neptuneBlue};
      }
    `
  );
  export const ApiExplorerTooltipGatewayIconContainer = styled.div(
    ({ theme }) => css`
      display: inline-block;
      padding-right: 5px;
      ${svgBasics(12, theme.white)}
    `
  );
}
