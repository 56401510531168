import { NodeType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';

export enum GraphStatus {
  Error = 'Error',
  Warning = 'Warning',
  Idle = 'Idle',
  Healthy = 'Healthy'
}

export enum GraphContainerType {
  Namespace = 'Namespace',
  Cluster = 'Cluster',
  Workspace = 'Workspace',
  Instance = 'Instance',
  Subnet = 'Subnet',
  VPC = 'VPC'
}

export enum GraphMetricsType {
  Http = 'Http',
  Tcp = 'Tcp',
  Cilium = 'Cilium'
}

export type NodePosition = {
  position: {
    x: number;
    y: number;
  };
};
export type NodePositions = {
  [nodeId: string]: NodePosition;
};
export type ContainerNodePosition = {
  position: {
    x: number;
    y: number;
  };
  width: number;
  height: number;
};
export type ContainerNodePositions = {
  [nodeId: string]: ContainerNodePosition;
};

export type AllNodeTypePositions = {
  [nodeId: string]: NodePosition | ContainerNodePosition;
};

export enum LabelIcon {
  Istio,
  Cilium,
  Sidecar,
  Pep,
  AmbientMesh
}

export enum GraphPolicyIcon {
  Access,
  Traffic
}

export type ContainerNodeType = {
  data: {
    containerType: GraphContainerType;
    parent?: string;
    id: string;
    label: string;
    labelIcons: LabelIcon[];
    hidden?: boolean;
    externalFromSearch?: boolean;
  };
  locked: boolean;
  grabbable: boolean;
  classes?: string;
};
export interface NodeTypeHttpTooltipProps {
  errorsPercentIN: number;
  rpsIN: number;
  latencyIN: number;
  errorsPercentOUT: number;
  rpsOUT: number;
  latencyOUT: number;
  name: string;
  policyIcons: GraphPolicyIcon[];
}
export interface NodeTypeTcpTooltipProps {
  bytesReceived?: number;
  bytesSent?: number;
}
export interface NodeTypeCiliumTooltipProps {
  forwardedReceived?: number;
  forwardedSent?: number;
  policyDrops?: number;
}

export type HiddenStatusType = 'hide' | 'show';
export type UINodeType = {
  data: {
    nodeType?: NodeType;
    metricsType: GraphMetricsType;
    parent?: string;
    id: string;
    name: string;
    namespace: string;
    cluster: string;
    workspace: string;
    instance: string;
    subnet: string;
    vpc: string;
    health: GraphStatus;
    labelIcons?: LabelIcon[];
    tooltipProps?: { headers: { vpcCluster: boolean; workspace: boolean } };
    httpTooltipProps?: NodeTypeHttpTooltipProps;
    tcpTooltipProps?: NodeTypeTcpTooltipProps;
    ciliumTooltipProps?: NodeTypeCiliumTooltipProps;
    externalFromSearch?: boolean;
    idleHidden: HiddenStatusType;
    nameHidden: HiddenStatusType;
    typeHidden: HiddenStatusType;
  };
  locked: boolean;
  grabbable: boolean;
  classes?: string;
};
export type RpsBucketType = 0 | 1 | 2 | 3 | 4;
export interface EdgeTypeAnimationProps {
  latency: number | undefined;
  rpsBucket: RpsBucketType;
}
export type EdgeType = {
  data: {
    metricsType?: GraphMetricsType;
    source: string;
    target: string;
    name: string;
    id: string;
    timestamps?: Timestamp;
    requestCount?: number;
    successCount?: number;
    requestLatencies?: {
      p99: string;
      p90: string;
      p50: string;
    };
    containerTie?: boolean;
    kind?: string;
    health: GraphStatus;
    label?: string | number;
    httpEdgeAnimation?: EdgeTypeAnimationProps;
    externalFromSearch?: boolean;
  };
  label: string;
  classes?: string;
};
