import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SecondaryInfoStyles } from 'Components/Common/SecondaryInfo.style';

export namespace FooterStyles {
  export const Container = styled.div`
    grid-area: footer;

    display: flex;
    min-height: 42px;
    width: 100%;
    position: sticky;
    top: 100vh;
    padding: 0 20px 20px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;

  export const Copyright = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      gap: 10px;
      color: ${theme.juneGrey};
      font-size: 16px;
      margin-right: 20px;

      ${SecondaryInfoStyles.Item} {
        color: ${theme.juneGrey};
      }
    `
  );

  export const Tagline = styled.div`
    align-content: flex-start;
    align-self: center;
    margin-right: 10px;
    font-size: 10px;
    text-align: left;
  `;

  export const IconContainer = styled.a`
    display: flex;
    flex-direction: row;
    filter: grayscale(100%) opacity(50%);
  `;

  export const PrivacyPolicyContainer = styled.a(
    ({ theme }) => css`
      color: ${theme.juneGrey};
      font-weight: bold;
    `
  );
}
