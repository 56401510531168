/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/envoy-gloo-ee/api/envoy/config/filter/http/transformation_ee/v2/transformation_ee_filter.proto" (package "envoy.config.filter.http.transformation_ee.v2", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Percent } from "../../../../../../../../../../envoy/type/percent_pb";
import { RouteMatch as RouteMatch$ } from "../../../../../../../../../../envoy/config/route/v3/route_components_pb";
import { RouteMatch } from "../../../../../../../../../../envoy/api/v2/route/route_components_pb";
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.FilterTransformations
 */
export interface FilterTransformations {
    /**
     * Specifies transformations based on the route matches. The first matched
     * transformation will be applied. If there are overlapped match conditions,
     * please put the most specific match first.
     *
     * @generated from protobuf field: repeated envoy.config.filter.http.transformation_ee.v2.TransformationRule transformations = 1;
     */
    transformations: TransformationRule[];
    /**
     * @generated from protobuf field: bool log_request_response_info = 2;
     */
    logRequestResponseInfo: boolean;
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.TransformationRule
 */
export interface TransformationRule {
    /**
     * The route matching parameter. Only when the match is satisfied, the
     * "requires" field will apply.
     *
     * For example: following match will match all requests.
     *
     * .. code-block:: yaml
     *
     *    match:
     *      prefix: /
     *
     *
     * @generated from protobuf field: envoy.api.v2.route.RouteMatch match = 1;
     */
    match?: RouteMatch;
    /**
     * @generated from protobuf field: envoy.config.route.v3.RouteMatch match_v3 = 3;
     */
    matchV3?: RouteMatch$;
    /**
     * transformation to perform
     *
     * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.RouteTransformations route_transformations = 2;
     */
    routeTransformations?: RouteTransformations;
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.RouteTransformations
 */
export interface RouteTransformations {
    /**
     * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.Transformation request_transformation = 1;
     */
    requestTransformation?: Transformation;
    /**
     * clear the route cache if the request transformation was applied
     *
     * @generated from protobuf field: bool clear_route_cache = 3;
     */
    clearRouteCache: boolean;
    /**
     * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.Transformation response_transformation = 2;
     */
    responseTransformation?: Transformation;
    /**
     * Apply a transformation in the onStreamComplete callback
     *
     * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.Transformation on_stream_completion_transformation = 4;
     */
    onStreamCompletionTransformation?: Transformation;
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.Transformation
 */
export interface Transformation {
    /**
     * @generated from protobuf oneof: transformation_type
     */
    transformationType: {
        oneofKind: "dlpTransformation";
        /**
         * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.DlpTransformation dlp_transformation = 1;
         */
        dlpTransformation: DlpTransformation;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.DlpTransformation
 */
export interface DlpTransformation {
    /**
     * list of actions to apply
     *
     * @generated from protobuf field: repeated envoy.config.filter.http.transformation_ee.v2.Action actions = 1;
     */
    actions: Action[];
    /**
     * If true, headers will be transformed. Should only be used for the
     * on_stream_complete_transformation route transformation type.
     *
     * @generated from protobuf field: bool enable_header_transformation = 2;
     */
    enableHeaderTransformation: boolean;
    /**
     * If true, dynamic metadata will be transformed. Should only be used for the
     * on_stream_complete_transformation route transformation type.
     *
     * @generated from protobuf field: bool enable_dynamic_metadata_transformation = 3;
     */
    enableDynamicMetadataTransformation: boolean;
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.Action
 */
export interface Action {
    /**
     * Identifier for this action.
     * Used mostly to help ID specific actions in logs.
     * If left null will default to unknown
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Deprecated in favor of DlpMatcher
     * List of regexes to apply to the response body to match data which should be
     * masked They will be applied iteratively in the order which they are
     * specified.
     *
     * @deprecated
     * @generated from protobuf field: repeated string regex = 2 [deprecated = true];
     */
    regex: string[];
    /**
     * Deprecated in favor of DlpMatcher
     * List of regexes to apply to the response body to match data which should be
     * masked They will be applied iteratively in the order which they are
     * specified.
     *
     * @deprecated
     * @generated from protobuf field: repeated envoy.config.filter.http.transformation_ee.v2.RegexAction regex_actions = 6 [deprecated = true];
     */
    regexActions: RegexAction[];
    /**
     * If specified, this rule will not actually be applied, but only logged.
     *
     * @generated from protobuf field: bool shadow = 3;
     */
    shadow: boolean;
    /**
     * The percent of the string which should be masked.
     * If not set, defaults to 75%
     *
     * @generated from protobuf field: envoy.type.Percent percent = 4;
     */
    percent?: Percent;
    /**
     * The character which should overwrite the masked data
     * If left empty, defaults to "X"
     *
     * @generated from protobuf field: string mask_char = 5;
     */
    maskChar: string;
    /**
     * The matcher used to determine which values will be masked by this action.
     *
     * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.Action.DlpMatcher matcher = 7;
     */
    matcher?: Action_DlpMatcher;
}
/**
 * List of regexes to apply to the response body to match data which should be
 * masked. They will be applied iteratively in the order which they are
 * specified.
 *
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.Action.RegexMatcher
 */
export interface Action_RegexMatcher {
    /**
     * @generated from protobuf field: repeated envoy.config.filter.http.transformation_ee.v2.RegexAction regex_actions = 1;
     */
    regexActions: RegexAction[];
}
/**
 * List of headers for which associated values will be masked.
 * Note that enable_header_transformation must be set for this to take effect.
 * Note that if enable_dynamic_metadata_transformation is set, proto struct dynamic metadata
 * (i.e., the values matching any JSON keys specified in `keys`; primarily for json-formatted WAF audit logs) will also be masked accordingly.
 *
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.Action.KeyValueMatcher
 */
export interface Action_KeyValueMatcher {
    /**
     * @generated from protobuf field: repeated string keys = 1;
     */
    keys: string[];
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.Action.DlpMatcher
 */
export interface Action_DlpMatcher {
    /**
     * @generated from protobuf oneof: matcher
     */
    matcher: {
        oneofKind: "regexMatcher";
        /**
         * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.Action.RegexMatcher regex_matcher = 1;
         */
        regexMatcher: Action_RegexMatcher;
    } | {
        oneofKind: "keyValueMatcher";
        /**
         * @generated from protobuf field: envoy.config.filter.http.transformation_ee.v2.Action.KeyValueMatcher key_value_matcher = 2;
         */
        keyValueMatcher: Action_KeyValueMatcher;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message envoy.config.filter.http.transformation_ee.v2.RegexAction
 */
export interface RegexAction {
    /**
     * The regex to match for masking.
     *
     * @generated from protobuf field: string regex = 1;
     */
    regex: string;
    /**
     * If provided and not 0, only this specific subgroup of the regex will be
     * masked.
     *
     * @generated from protobuf field: uint32 subgroup = 2;
     */
    subgroup: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class FilterTransformations$Type extends MessageType<FilterTransformations> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.FilterTransformations", [
            { no: 1, name: "transformations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransformationRule },
            { no: 2, name: "log_request_response_info", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.FilterTransformations
 */
export const FilterTransformations = new FilterTransformations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformationRule$Type extends MessageType<TransformationRule> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.TransformationRule", [
            { no: 1, name: "match", kind: "message", T: () => RouteMatch },
            { no: 3, name: "match_v3", kind: "message", T: () => RouteMatch$ },
            { no: 2, name: "route_transformations", kind: "message", T: () => RouteTransformations }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.TransformationRule
 */
export const TransformationRule = new TransformationRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteTransformations$Type extends MessageType<RouteTransformations> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.RouteTransformations", [
            { no: 1, name: "request_transformation", kind: "message", T: () => Transformation },
            { no: 3, name: "clear_route_cache", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "response_transformation", kind: "message", T: () => Transformation },
            { no: 4, name: "on_stream_completion_transformation", kind: "message", T: () => Transformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.RouteTransformations
 */
export const RouteTransformations = new RouteTransformations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transformation$Type extends MessageType<Transformation> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.Transformation", [
            { no: 1, name: "dlp_transformation", kind: "message", oneof: "transformationType", T: () => DlpTransformation }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.Transformation
 */
export const Transformation = new Transformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DlpTransformation$Type extends MessageType<DlpTransformation> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.DlpTransformation", [
            { no: 1, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Action },
            { no: 2, name: "enable_header_transformation", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "enable_dynamic_metadata_transformation", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.DlpTransformation
 */
export const DlpTransformation = new DlpTransformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.Action", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "regex", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { repeated: { items: { string: { minBytes: "1" } } } } } },
            { no: 6, name: "regex_actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegexAction },
            { no: 3, name: "shadow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "percent", kind: "message", T: () => Percent },
            { no: 5, name: "mask_char", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1" } } } },
            { no: 7, name: "matcher", kind: "message", T: () => Action_DlpMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action_RegexMatcher$Type extends MessageType<Action_RegexMatcher> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.Action.RegexMatcher", [
            { no: 1, name: "regex_actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegexAction }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.Action.RegexMatcher
 */
export const Action_RegexMatcher = new Action_RegexMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action_KeyValueMatcher$Type extends MessageType<Action_KeyValueMatcher> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.Action.KeyValueMatcher", [
            { no: 1, name: "keys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.Action.KeyValueMatcher
 */
export const Action_KeyValueMatcher = new Action_KeyValueMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action_DlpMatcher$Type extends MessageType<Action_DlpMatcher> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.Action.DlpMatcher", [
            { no: 1, name: "regex_matcher", kind: "message", oneof: "matcher", T: () => Action_RegexMatcher },
            { no: 2, name: "key_value_matcher", kind: "message", oneof: "matcher", T: () => Action_KeyValueMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.Action.DlpMatcher
 */
export const Action_DlpMatcher = new Action_DlpMatcher$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegexAction$Type extends MessageType<RegexAction> {
    constructor() {
        super("envoy.config.filter.http.transformation_ee.v2.RegexAction", [
            { no: 1, name: "regex", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } },
            { no: 2, name: "subgroup", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.filter.http.transformation_ee.v2.RegexAction
 */
export const RegexAction = new RegexAction$Type();
