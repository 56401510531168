import { overviewApi } from 'Api/overview';
import { Loading } from 'Components/Common/Loading';
import { Asset } from 'assets';
import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { DataError } from '../../Common/DataError';
import { OverviewClusterCiliumDetails } from './OverviewClusterCiliumDetails';
import { OverviewClusterIstioDetails } from './OverviewClusterIstioDetails';
import { OverviewDetailsStyles } from './OverviewDetails.style';

const { useGetCluster } = overviewApi;

type OverviewClusterDetailsProps = {
  clusterRef?: ObjectRef;
};
export const OverviewClusterDetails = ({ clusterRef }: OverviewClusterDetailsProps) => {
  di(useGetCluster);
  const { data: clusterDetails, error: clusterDetailsError } = useGetCluster(clusterRef);

  const clusterName = clusterRef?.name ?? '';
  return (
    <OverviewDetailsStyles.Container data-testid='overview-cluster-details'>
      {!!clusterDetailsError ? (
        <div>
          <DataError error={clusterDetailsError} center={true} />
        </div>
      ) : !clusterDetails ? (
        <Loading message={`Retrieving cluster ${clusterName}...`} />
      ) : (
        <OverviewDetailsStyles.SubDetails data-testid={`${clusterName}-sub-details`}>
          <OverviewDetailsStyles.SubDetailsRow>
            <OverviewDetailsStyles.SubDetail>
              <OverviewDetailsStyles.SubDetailsTitle>
                <div>Configuration</div>
                <Asset.ConfigIcon />
              </OverviewDetailsStyles.SubDetailsTitle>
              <OverviewDetailsStyles.SubDetailsBody>
                <div data-testid={`${clusterName}-namespaces`}>
                  <div>Namespaces</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{clusterDetails.namespaces}</OverviewDetailsStyles.Number>
                </div>
                <div data-testid={`${clusterName}-services`}>
                  <div>Services</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{clusterDetails.services}</OverviewDetailsStyles.Number>
                </div>
                <div data-testid={`${clusterName}-gateways`}>
                  <div>Gateways</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{clusterDetails.gateways}</OverviewDetailsStyles.Number>
                </div>
              </OverviewDetailsStyles.SubDetailsBody>
            </OverviewDetailsStyles.SubDetail>
            <OverviewDetailsStyles.SubDetail>
              <OverviewDetailsStyles.SubDetailsTitle>
                <div>Hardware</div> <Asset.HardwareIcon />
              </OverviewDetailsStyles.SubDetailsTitle>
              <OverviewDetailsStyles.SubDetailsBody>
                <div data-testid={`${clusterName}-nodes`}>
                  <div>Nodes</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>{clusterDetails.nodes}</OverviewDetailsStyles.Number>
                </div>
                {/* COMING SOON
                <div>
                  <div>CPU</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>
                    {clusterDetails.cpuPercent}%
                  </OverviewDetailsStyles.Number>
                </div>
                <div>
                  <div>Memory</div>
                  <OverviewDetailsStyles.DottedLined />
                  <OverviewDetailsStyles.Number>
                    {clusterDetails.memoryPercent}%
                  </OverviewDetailsStyles.Number>
      </div>*/}
              </OverviewDetailsStyles.SubDetailsBody>
            </OverviewDetailsStyles.SubDetail>
          </OverviewDetailsStyles.SubDetailsRow>

          {clusterDetails.istioOverviews?.map(c => {
            return <OverviewClusterIstioDetails key={JSON.stringify(c)} overview={c} clusterName={clusterName} />;
          })}

          {clusterDetails?.ciliumOverview && (
            <OverviewClusterCiliumDetails cilium={clusterDetails?.ciliumOverview} clusterName={clusterName} />
          )}

          {/* SOMEDAY WE'LL BE TOGETHER. OH YEAH OH YEAH

          <OverviewDetailsStyles.MoreLinkLine>
            <SoloLink
              link={buildClusterDetailsUrl(clusterRef?.namespace}/${clusterRef?.name)}
              data-testid='overview-moreclusterdetails'>
              MORE DETAILS <ArrowToggle />
            </SoloLink>
    </OverviewDetailsStyles.MoreLinkLine>*/}
        </OverviewDetailsStyles.SubDetails>
      )}
    </OverviewDetailsStyles.Container>
  );
};
