import { Toolbar, ToolbarContent } from '@patternfly/react-core';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import { LogViewerSearch } from '@patternfly/react-log-viewer';
import { Svg } from 'Components/Common/Svg';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Asset } from 'assets';

import { Select as SelectDeprecated, SelectOption as SelectOptionDeprecated } from '@patternfly/react-core/deprecated';
import { ElementRef, RefObject, useRef, useState } from 'react';
import { doDownload } from 'utils/helpers';
import { useEventListener } from 'utils/hooks';
import { SoloDropdown } from '../Input/SoloDropdown';
import { SoloInputStyles } from '../Input/SoloInput.style';
import SoloTooltip from '../SoloTooltip';
import { SoloLogViewerDataSources } from './SoloLogViewer';
import { SoloLogViewerStyles } from './SoloLogViewer.style';

export interface SoloLogViewerClusterComponentPodSelectionProps {
  // For cluster/component/pod selection
  clusterOptions?: string[];
  cluster?: string;
  clusterDropdownDisabled?: boolean;
  onClusterSelected?(newValue: string): void;
  componentOptions?: string[];
  component?: string;
  componentDropdownDisabled?: boolean;
  onComponentSelected?(newValue: string): void;
  podOptions?: string[];
  pod?: string;
  podDropdownDisabled?: boolean;
  onPodSelected?(newValue: string): void;
  containerOptions?: string[];
  container?: string;
  containerDropdownDisabled?: boolean;
  onContainerSelected?(newValue: string): void;
}
export interface SoloLogViewerToolbarProps extends SoloLogViewerClusterComponentPodSelectionProps {
  isFullscreen: boolean;
  onIsFullscreenChanged: (newIsFullscreen: boolean) => void;
  dataSources: SoloLogViewerDataSources;
  selectedDataSourceKey: keyof SoloLogViewerDataSources;
  onSelectedDataSourceKeyChanged: (newDataSourceKey: keyof SoloLogViewerDataSources) => void;
  logViewerContainerRef: RefObject<HTMLDivElement>;
  isPaused: boolean;
  onIsPausedChanged: (newValue: boolean) => void;
  searchText: string;
  onSearchTextChanged: (newValue: string) => void;
  lineNumbersEnabled: boolean;
  onLineNumbersEnabledChanged: (newValue: boolean) => void;
  lineWrapEnabled: boolean;
  onLineWrapEnabledChanged: (newValue: boolean) => void;
}

//
//
//
//
// Toolbar Components
//
//
//
//

const SoloLogViewerToolbarTitle = () => (
  <FlexLayout flexGrow={1} alignItems='center' gap={'10px'}>
    <Svg asset={<Asset.LogViewerIcon />} height={50} color={theme => theme.seaBlue} />
    <SoloLogViewerStyles.ToolbarTitle>Logs</SoloLogViewerStyles.ToolbarTitle>
  </FlexLayout>
);

const SoloLogViewerDataSourceDropdown = ({
  dataSources,
  selectedDataSourceKey,
  onSelectedDataSourceKeyChanged
}: SoloLogViewerToolbarProps) => {
  const showDataSourceDropdown = Object.keys(dataSources).length > 1;
  const [selectDataSourceOpen, setSelectDataSourceOpen] = useState(false);
  if (!showDataSourceDropdown) {
    return null;
  }
  return (
    <SelectDeprecated
      onToggle={(_event, isOpen) => setSelectDataSourceOpen(isOpen)}
      onSelect={(_event, selection) => {
        setSelectDataSourceOpen(false);
        onSelectedDataSourceKeyChanged(selection as any);
      }}
      selections={selectedDataSourceKey}
      isOpen={selectDataSourceOpen}>
      {Object.entries(dataSources).map(([value, { label }]: any) => (
        <SelectOptionDeprecated
          key={value}
          value={value}
          isSelected={selectedDataSourceKey === value}
          isChecked={selectedDataSourceKey === value}>
          {label}
        </SelectOptionDeprecated>
      ))}
    </SelectDeprecated>
  );
};

const SoloLogViewerToolbarSearchbar = ({
  searchText,
  onSearchTextChanged,
  onIsPausedChanged
}: SoloLogViewerToolbarProps) => {
  return (
    <SoloLogViewerStyles.SearchContainer>
      <LogViewerSearch
        id='log-viewer-searchbar'
        value={searchText}
        onChange={e => onSearchTextChanged((e.target as HTMLInputElement).value)}
        onClear={() => onSearchTextChanged('')}
        onFocus={() => onIsPausedChanged(true)}
        placeholder='Search log...'
        minSearchChars={0}
      />
      {searchText.length === 0 && (
        <SoloInputStyles.SearchIconContainer>
          <Asset.SearchIcon />
        </SoloInputStyles.SearchIconContainer>
      )}
    </SoloLogViewerStyles.SearchContainer>
  );
};

const SoloLogViewerStreamingButton = ({
  isPaused,
  onIsPausedChanged,
  dataSources,
  selectedDataSourceKey
}: SoloLogViewerToolbarProps) => {
  const selectedDataSource = dataSources[selectedDataSourceKey];
  const dataSourceIsStreaming = selectedDataSource.isStreaming;
  const toolbarButtonRef = useRef<ElementRef<'button'>>(null);

  if (!dataSourceIsStreaming) {
    return null;
  }
  return (
    <SoloTooltip includePopupInContainerElement placement='bottom' title={isPaused ? 'Resume log' : 'Pause log'}>
      <SoloLogViewerStyles.ToolbarButton
        ref={toolbarButtonRef}
        onClick={() => {
          onIsPausedChanged(!isPaused);
        }}>
        <FlexLayout alignItems='center' gap={3}>
          {isPaused ? (
            <>
              <Svg width={20} color={theme => theme.seaBlue} asset={<Asset.RouteThenIcon />} />
              <div>Resume Log</div>
            </>
          ) : (
            <>
              <Svg width={20} color={theme => theme.seaBlue} asset={<Asset.PauseOutlinedIcon />} />
              <div>Pause Log</div>
            </>
          )}
        </FlexLayout>
      </SoloLogViewerStyles.ToolbarButton>
    </SoloTooltip>
  );
};

const SoloLogViewerDownloadButton = ({ dataSources, selectedDataSourceKey }: SoloLogViewerToolbarProps) => {
  const selectedDataSource = dataSources[selectedDataSourceKey];

  const onDownloadClick = () => {
    const dataToDownload = selectedDataSource.data.join('\n');
    const filename = `${selectedDataSource.label.toLocaleLowerCase().replaceAll(' ', '-')}.txt`;
    doDownload(dataToDownload, filename);
  };

  return (
    <SoloTooltip includePopupInContainerElement placement='bottom' title={'Download'}>
      <SoloLogViewerStyles.ToolbarButton onClick={onDownloadClick} aria-label='Download current logs'>
        <Svg mt={'-2px'} height={16} asset={<Asset.DownloadIcon />} color={theme => theme.seaBlue} />
      </SoloLogViewerStyles.ToolbarButton>
    </SoloTooltip>
  );
};

const SoloLogViewerLineNumbersToggleButton = ({
  lineNumbersEnabled,
  onLineNumbersEnabledChanged
}: SoloLogViewerToolbarProps) => {
  const title = lineNumbersEnabled ? 'Disable line numbers' : 'Enable line numbers';
  return (
    <SoloTooltip includePopupInContainerElement placement='bottom' title={title}>
      <SoloLogViewerStyles.ToolbarButton
        onClick={() => onLineNumbersEnabledChanged(!lineNumbersEnabled)}
        aria-label={title}>
        <Svg mt={'-2px'} height={16} asset={<Asset.RawLogViewIcon />} color={theme => theme.seaBlue} />
      </SoloLogViewerStyles.ToolbarButton>
    </SoloTooltip>
  );
};

// This can be used to experiment with toggling line-wrapping.
const SoloLogViewerLineWrapToggleButton = ({
  lineWrapEnabled,
  onLineWrapEnabledChanged
}: SoloLogViewerToolbarProps) => {
  const title = lineWrapEnabled ? 'Disable line wrap' : 'Enable line wrap';
  return (
    <SoloTooltip includePopupInContainerElement placement='top' title={title}>
      <SoloLogViewerStyles.ToolbarButton onClick={() => onLineWrapEnabledChanged(!lineWrapEnabled)} aria-label={title}>
        <Svg mt={'-2px'} height={16} asset={<SoloLogViewerStyles.LineWrapIcon />} color={theme => theme.seaBlue} />
      </SoloLogViewerStyles.ToolbarButton>
    </SoloTooltip>
  );
};

const SoloLogViewerFullscreenToggle = ({
  logViewerContainerRef,
  isFullscreen,
  onIsFullscreenChanged
}: SoloLogViewerToolbarProps) => {
  const onExpandClick = (_event: any) => {
    const element = logViewerContainerRef.current;
    if (element === null) {
      return;
    }
    try {
      if (!isFullscreen) {
        element.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  //
  // Update fullscreen state on change.
  useEventListener(
    document,
    'fullscreenchange',
    () => {
      const newIsFullscreen = document.fullscreenElement === logViewerContainerRef.current;
      onIsFullscreenChanged(newIsFullscreen);
    },
    [onIsFullscreenChanged]
  );
  return (
    <SoloTooltip includePopupInContainerElement placement='bottom' title={'Fullscreen'}>
      <SoloLogViewerStyles.ToolbarButton onClick={onExpandClick} aria-label='View log viewer in full screen'>
        <Svg height={20} asset={<Asset.Fullscreen />} color={theme => theme.seaBlue} />
      </SoloLogViewerStyles.ToolbarButton>
    </SoloTooltip>
  );
};

const EmptyPromptBox = ({
  value,
  itemsNeedingSelection
}: {
  value: string | undefined;
  itemsNeedingSelection: string;
}) => {
  if (!!value) {
    return null;
  }
  return (
    <SoloLogViewerStyles.EmptyPromptBox>
      <SoloLogViewerStyles.EmptyPromptBoxContent>
        Please select a {itemsNeedingSelection} using the filters to view the logs.
      </SoloLogViewerStyles.EmptyPromptBoxContent>
    </SoloLogViewerStyles.EmptyPromptBox>
  );
};

const SoloLogViewerToolbarServiceSelection = ({
  cluster,
  clusterOptions,
  clusterDropdownDisabled,
  onClusterSelected,
  component,
  componentOptions,
  componentDropdownDisabled,
  onComponentSelected,
  pod,
  podOptions,
  podDropdownDisabled,
  onPodSelected,
  container,
  containerOptions,
  containerDropdownDisabled,
  onContainerSelected
}: SoloLogViewerToolbarProps) => {
  const emptyValue = { displayValue: 'Select', value: '' };

  return (
    <FlexLayout alignItems='center' justifyContent='flex-end' gap={'1em'}>
      <SoloLogViewerStyles.SoloDropdownContainer>
        <EmptyPromptBox value={cluster} itemsNeedingSelection={'cluster, component, pod, and container'} />
        <label htmlFor='cluster-dropdown'>Cluster:</label>
        <SoloDropdown
          includePopupInContainerElement
          id='cluster-dropdown'
          value={cluster ?? ''}
          disabled={!!clusterDropdownDisabled}
          options={[emptyValue, ...(clusterOptions?.sort().map(o => ({ displayValue: o, value: o, key: o })) ?? [])]}
          onChange={onClusterSelected}
        />
      </SoloLogViewerStyles.SoloDropdownContainer>
      <SoloLogViewerStyles.SoloDropdownContainer>
        {!!cluster && <EmptyPromptBox value={component} itemsNeedingSelection={'component, pod, and container'} />}
        <label htmlFor='component-dropdown'>Component:</label>
        <SoloDropdown
          includePopupInContainerElement
          id='component-dropdown'
          value={component ?? ''}
          disabled={!!componentDropdownDisabled}
          options={[emptyValue, ...(componentOptions?.sort().map(o => ({ displayValue: o, value: o, key: o })) ?? [])]}
          onChange={onComponentSelected}
        />
      </SoloLogViewerStyles.SoloDropdownContainer>
      <SoloLogViewerStyles.SoloDropdownContainer>
        {!!component && <EmptyPromptBox value={pod} itemsNeedingSelection={'pod and container'} />}
        <label htmlFor='pod-dropdown'>Pod:</label>
        <SoloDropdown
          includePopupInContainerElement
          id='pod-dropdown'
          value={pod ?? ''}
          disabled={!!podDropdownDisabled}
          options={[emptyValue, ...(podOptions?.sort().map(o => ({ displayValue: o, value: o, key: o })) ?? [])]}
          onChange={onPodSelected}
        />
      </SoloLogViewerStyles.SoloDropdownContainer>
      <SoloLogViewerStyles.SoloDropdownContainer>
        {!!pod && <EmptyPromptBox value={container} itemsNeedingSelection={'container'} />}
        <label htmlFor='container-dropdown'>Container:</label>
        <SoloDropdown
          includePopupInContainerElement
          id='container-dropdown'
          value={container ?? ''}
          disabled={!!containerDropdownDisabled}
          options={[emptyValue, ...(containerOptions?.sort().map(o => ({ displayValue: o, value: o, key: o })) ?? [])]}
          onChange={onContainerSelected}
        />
      </SoloLogViewerStyles.SoloDropdownContainer>
    </FlexLayout>
  );
};

//
//
//
//
// Toolbar Variants
//
//
//
//

/**
 * A customized toolbar that includes dropdowns for cluster, component, and pod,
 * a searchbar, and a raw view, matching the design.
 */
export const SoloLogViewerToolbarWithServiceSelection = (props: SoloLogViewerToolbarProps) => {
  return (
    <Toolbar>
      <ToolbarContent>
        <SoloLogViewerStyles.ToolbarRow gap={3}>
          <SoloLogViewerToolbarTitle />
          <SoloLogViewerToolbarServiceSelection {...props} />
          <FlexLayout gap={3} alignItems='center' justifyContent='flex-end' flexGrow={1}>
            <SoloLogViewerToolbarSearchbar {...props} />
            <SoloLogViewerStreamingButton {...props} />
            <SoloLogViewerLineWrapToggleButton {...props} />
            <SoloLogViewerLineNumbersToggleButton {...props} />
            <SoloLogViewerFullscreenToggle {...props} />
            <SoloLogViewerDownloadButton {...props} />
          </FlexLayout>
        </SoloLogViewerStyles.ToolbarRow>
      </ToolbarContent>
    </Toolbar>
  );
};

/**
 * A default toolbar that can show a dropdown with multiple data sources.
 */
export const SoloLogViewerToolbarDefault = (props: SoloLogViewerToolbarProps) => {
  return (
    <Toolbar>
      <ToolbarContent>
        <SoloLogViewerStyles.ToolbarRow gap={3}>
          <SoloLogViewerToolbarTitle />
          <SoloLogViewerStyles.ToolbarToggleGroup toggleIcon={<EllipsisVIcon />} breakpoint='md'>
            <SoloLogViewerDataSourceDropdown {...props} />
          </SoloLogViewerStyles.ToolbarToggleGroup>
          <FlexLayout gap={3} alignItems='center' justifyContent='flex-end' flexGrow={1}>
            <SoloLogViewerToolbarSearchbar {...props} />
            <SoloLogViewerStreamingButton {...props} />
            <SoloLogViewerLineWrapToggleButton {...props} />
            <SoloLogViewerLineNumbersToggleButton {...props} />
            <SoloLogViewerFullscreenToggle {...props} />
            <SoloLogViewerDownloadButton {...props} />
          </FlexLayout>
        </SoloLogViewerStyles.ToolbarRow>
      </ToolbarContent>
    </Toolbar>
  );
};
