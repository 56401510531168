import { InsightsOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { AnalysisAndInsightsStyles } from './AnalysisAndInsights.style';
import InsightStatusCard from './InsightStatusCard/InsightStatusCard';
import InsightsChart from './InsightsChart';
import { ResourcesScannedChart } from './ResourcesScanned/ResourcesScannedChart';

const AnalysisAndInsightsCardContent = ({ data }: { data: InsightsOverview }) => {
  //
  // Render
  //
  return (
    <AnalysisAndInsightsStyles.CardContentContainer>
      <InsightStatusCard statusType='error' value={data.errorCount} />
      <InsightStatusCard statusType='warning' value={data.warningCount} />
      <InsightStatusCard statusType='info' value={data.infoCount} />
      <InsightsChart categoryCounts={data.categoryCounts} total={data.totalCount} />
      <ResourcesScannedChart data={data} />
    </AnalysisAndInsightsStyles.CardContentContainer>
  );
};

export default AnalysisAndInsightsCardContent;
