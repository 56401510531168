import { CardStyles } from 'Components/Common/Card';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights, useDataPropForInsights } from 'utils/dashboard/dashboard-hooks';
import { DashboardStyles } from '../../DashboardLanding.style';
import { FIPSHealthKey, fipsCodesMap } from './insight-codes';
import { FIPSCardContent } from './FIPSCardContent';
import { docLinks } from 'utils/url-external-links-map';

const controlPlaneDataProp = fipsCodesMap[FIPSHealthKey.fipsControlPlaneComplianceSummary].dataProp;
const dataPlaneDataProp = fipsCodesMap[FIPSHealthKey.fipsDataPlaneComplianceSummary].dataProp;

const FIPSCard = () => {
  di(useFilteredDashboardInsights);
  const controlPlaneInsights = useFilteredDashboardInsights(
    fipsCodesMap,
    FIPSHealthKey.fipsControlPlaneComplianceSummary
  );
  const controlPlane = useDataPropForInsights(controlPlaneDataProp, controlPlaneInsights);

  const dataPlaneInsights = useFilteredDashboardInsights(fipsCodesMap, FIPSHealthKey.fipsDataPlaneComplianceSummary);
  const dataPlane = useDataPropForInsights(dataPlaneDataProp, dataPlaneInsights);
  return (
    <CardStyles.Card data-testid='fips-card'>
      <DashboardStyles.CardColumn>
        <DashboardStyles.Header>
          <Svg asset={<Asset.FipsIcon />} color={theme => theme.neptuneBlue} />
          Istio FIPS
        </DashboardStyles.Header>
        <FIPSCardContent
          controlPlane={controlPlaneInsights === undefined ? 'loading' : controlPlane}
          dataPlane={dataPlaneInsights === undefined ? 'loading' : dataPlane}
        />
        <DashboardStyles.CardBottomLink>
          <ExternalSoloLink small href={docLinks.core.insights_HASH.review_fips_compliance} newTab withArrow>
            DOCS
          </ExternalSoloLink>
        </DashboardStyles.CardBottomLink>
      </DashboardStyles.CardColumn>
    </CardStyles.Card>
  );
};

export default FIPSCard;
