import { RpcError } from "@protobuf-ts/runtime-rpc";
import { Loading } from 'Components/Common/Loading';
import { Kind } from 'graphql';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  GetGraphqlApiResponse,
  GraphqlApiType,
  StitchedSchema_Subschema,
  StitchedSchema_Subschema_TypeMergeConfig
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { createContext, useMemo, useState } from 'react';
import {
  ResolverMap,
  SupportedDocumentNode,
  SupportedExtensionNode,
  parseSchemaString,
  supportedExtensionTypes
} from 'utils/graphql-helpers';

//
// TYPES
//
export interface TypeMergeAndSubschema {
  typeMerge: StitchedSchema_Subschema_TypeMergeConfig;
  subschema: StitchedSchema_Subschema;
}
interface FullTypeMergeMapType {
  [typeName: string]: TypeMergeAndSubschema[];
}
interface GqlLandingProviderProps {
  api: GetGraphqlApiResponse | undefined;
  /** The route table containing the GraphQL API route. */
  routeTableRef: ClusterObjectRef | undefined;
  /** The name of the GraphQL API route in the route table. */
  istioRouteName: string;
  apiType: GraphqlApiType;
}
interface IGqlLandingContext extends GqlLandingProviderProps {
  api: GetGraphqlApiResponse;
  apiTypeCanHaveResolvers: boolean;
  resolverMap: ResolverMap;
  schema: SupportedDocumentNode;
  schemaError: Partial<RpcError> | undefined;
  includedSubscriptions: string[];
  fullTypeMergeMap: FullTypeMergeMapType;
  getExtensionInfo: <T extends SupportedExtensionNode['kind']>(
    kindType: T,
    definitionName: string
  ) => (SupportedExtensionNode & { kind: T }) | undefined;
}

//
// CONTEXT
//
export const GqlLandingContext = createContext({} as IGqlLandingContext);

//
// PROVIDER
//
export const GqlLandingProvider = ({
  api,
  apiType,
  routeTableRef,
  istioRouteName,
  children
}: GqlLandingProviderProps & {
  children: React.ReactNode;
}) => {
  // Parse the schema (which will be passed to child-components).
  const [schemaError, setSchemaError] = useState<Partial<RpcError>>();
  const { schema, includedSubscriptions } = useMemo(() => {
    const emptyResponse = {
      schema: {
        kind: Kind.DOCUMENT,
        definitions: []
      } as SupportedDocumentNode,
      includedSubscriptions: [] as string[]
    };
    if (!api?.schemaDefinition) return emptyResponse;
    try {
      const parsedResponse = parseSchemaString(api.schemaDefinition);
      setSchemaError(undefined);
      return parsedResponse;
    } catch (e: any) {
      setSchemaError(e);
      return emptyResponse;
    }
  }, [api]);

  // Parses the resolver map.
  const resolverMap: ResolverMap = useMemo(() => {
    if (
      api?.graphql.oneofKind !== 'executableSchema' ||
      api.graphql.executableSchema.executableSchema.oneofKind !== 'resolved' ||
      !api.graphql.executableSchema.executableSchema.resolved.types
    ) {
      return {};
    }
    const resolverMap = {} as ResolverMap;
    Object.entries(api.graphql.executableSchema.executableSchema.resolved.types).forEach(([objectName, { fields }]) => {
      resolverMap[objectName] = {};
      Object.entries(fields).forEach(([fieldName, resolvers]) => (resolverMap[objectName][fieldName] = resolvers));
    });
    return resolverMap;
  }, [api]);

  //
  // Maps the type merge returned from the apiserver into a more useful data format.
  // This creates some circular references though, which is why it's better to do
  // this transformation here in the UI.
  //
  const fullTypeMergeMap: FullTypeMergeMapType = useMemo(() => {
    if (api?.graphql.oneofKind !== 'stitchedSchema' || !api.graphql.stitchedSchema.subschemas.length) {
      return {};
    }
    const subschemas = api.graphql.stitchedSchema.subschemas;
    const fullMap: FullTypeMergeMapType = {};
    subschemas.forEach(s => {
      Object.entries(s.typeMerge).forEach(([typeName, value]) => {
        fullMap[typeName] ??= [];
        fullMap[typeName].push({
          typeMerge: value,
          subschema: s
        });
      });
    });
    return fullMap;
  }, [api]);

  //
  // These are the schema extensions for each type.
  // This transforms the extensions so that they are in an easier to consume format:
  // { [Kind.SomeType]: { [definitionName]: { ...combinedExtensionInfo } } }
  //
  const definitionExtensions = useMemo(() => {
    //
    // For each Kind of supported definition:
    return supportedExtensionTypes.reduce(
      (prevExtensionTypes, curExtensionType) => ({
        ...prevExtensionTypes,
        //
        // Get the distinct extensions and aggregate their array data:
        [curExtensionType]: schema.definitions.reduce((distinctPrevExtensions, curExtension) => {
          if (!('name' in curExtension) || curExtension.kind !== curExtensionType) {
            return distinctPrevExtensions;
          }
          const curName = curExtension.name.value;
          //
          if (distinctPrevExtensions[curName] !== undefined) {
            // If this definition was already added, aggregate its array data.
            const combinedExtension = { ...distinctPrevExtensions[curName] };
            Object.keys(curExtension)
              .filter(k => Array.isArray(curExtension[k as keyof typeof curExtension]))
              .forEach(k => (combinedExtension[k] = [...combinedExtension[k], ...(curExtension as any)[k]]));
            return {
              ...distinctPrevExtensions,
              [curName]: combinedExtension
            };
          }
          // Else, set it to a new entry
          return {
            ...distinctPrevExtensions,
            [curName]: curExtension
          };
        }, {} as any)
      }),
      {} as Record<SupportedExtensionNode['kind'], Record<string, SupportedExtensionNode>>
    );
  }, [schema]);

  // This is a helper function to get information out of `definitionExtensions`,
  // and to return the correct type.
  function getExtensionInfo<T extends SupportedExtensionNode['kind']>(kindType: T, definitionName: string) {
    return definitionExtensions[kindType][definitionName] as (SupportedExtensionNode & { kind: T }) | undefined;
  }

  // We can show the resolvers column if:
  const apiTypeCanHaveResolvers =
    // This is a resolved api, AND
    apiType === GraphqlApiType.RESOLVED &&
    // It is accepted, OR (if it is not accepted) it has at least one ResolverMap.
    // - This check is necessary, because both resolved and proxied apis fall back
    //   to the GraphqlApiType.RESOLVED type if they have errors. Only resolved apis
    //   would have resolver map references.
    (api?.status?.state === State.ACCEPTED || !!api?.graphqlResolverMapRefs?.length);

  if (!api) {
    return <Loading />;
  }
  return (
    <GqlLandingContext.Provider
      value={{
        api,
        apiType,
        apiTypeCanHaveResolvers,
        resolverMap,
        routeTableRef,
        istioRouteName,
        schema,
        schemaError,
        includedSubscriptions,
        fullTypeMergeMap,
        getExtensionInfo
      }}>
      {children}
    </GqlLandingContext.Provider>
  );
};
