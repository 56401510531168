/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/port.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * selects an individual port by number or name
 *
 * @generated from protobuf message common.gloo.solo.io.PortSelector
 */
export interface PortSelector {
    /**
     * @generated from protobuf oneof: specifier
     */
    specifier: {
        oneofKind: "number";
        /**
         * the number of the port on the destination objects being targeted.
         *
         * +kubebuilder:validation:Minimum=1
         * +kubebuilder:validation:Maximum=65535
         *
         * @generated from protobuf field: uint32 number = 4;
         */
        number: number;
    } | {
        oneofKind: "name";
        /**
         * the name of the port on the destination objects being targeted.
         *
         * @generated from protobuf field: string name = 5;
         */
        name: string;
    } | {
        oneofKind: undefined;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class PortSelector$Type extends MessageType<PortSelector> {
    constructor() {
        super("common.gloo.solo.io.PortSelector", [
            { no: 4, name: "number", kind: "scalar", oneof: "specifier", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "name", kind: "scalar", oneof: "specifier", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.PortSelector
 */
export const PortSelector = new PortSelector$Type();
