import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorOrAllowed } from 'Styles/colors';
import { cssProp } from 'utils/helpers';

export namespace SecondaryInfoStyles {
  export const List = styled.div<{ inline?: boolean }>(({ inline }) => {
    return css`
      display: ${inline ? 'inline-flex' : 'flex'};
      flex-wrap: wrap;
      gap: 8px;
    `;
  });
  export interface HighlightProps {
    primaryColor?: ColorOrAllowed; // Border and text color
    textColor?: ColorOrAllowed; // Needed in cases where `primaryColor` is hard to read on the background
    backgroundColor?: ColorOrAllowed; // Overrides `background` in parent prop if set
  }
  interface ItemProps {
    highlighted?: boolean | HighlightProps;
    background?: string;
    small?: boolean;
  }
  export const Item = styled.div<ItemProps>(({ highlighted, background, small, theme }) => {
    const highlight = highlighted === true ? { primaryColor: theme.seaBlue } : highlighted;
    return css`
      display: inline-flex;
      width: fit-content;
      max-width: 100%;
      font-size: ${small ? '12' : '14'}px;
      line-height: ${small ? '20' : '26'}px;
      padding: 0 ${small ? '10' : '12'}px;
      background: ${background ?? theme.white};
      color: ${background ? theme.white : theme.oceanBlue};
      border: 1px solid ${background ?? theme.aprilGrey};
      border-radius: 13px;
      word-break: break-word;

      ${highlight &&
      css`
        color: ${highlight.textColor ?? highlight.primaryColor ?? theme.seaBlue};
        border: 1px solid ${highlight.primaryColor ?? theme.seaBlue};
        ${cssProp('background', highlight.backgroundColor)}
      `}
    `;
  });
  export const Label = styled.div`
    display: flex;
    font-weight: bold;
    margin-right: 4px;
    word-break: normal;
  `;

  interface ExtraCountPillProps {
    small?: boolean;
  }
  export const ExtraCountPill = styled.div<ExtraCountPillProps>(({ small, theme }) => {
    return css`
      display: inline-flex;
      width: fit-content;
      max-width: 400px;
      font-size: ${small ? '12' : '14'}px;
      line-height: ${small ? '20' : '26'}px;
      padding: 0 ${small ? '10' : '12'}px;
      border-radius: ${small ? '6' : '8'}px;
      cursor: pointer;

      border: 1px solid ${theme.seaBlue};
      color: ${theme.seaBlue};
      /* padding: 0 4px; */
    `;
  });
}
