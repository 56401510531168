import styled from '@emotion/styled';

const Holder = styled.div`
  font-size: 40px;
  line-height: 40px;
  height: 22px;
  overflow: hidden;
`;

export const EmptyAsterisk = () => {
  return <Holder data-testid='empty-asterisk'>*</Holder>;
};
