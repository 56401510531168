import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { Tooltip } from 'antd';
import { GqlResolverInfoIcon, GrpcIcon, RestIcon } from 'assets/assets';
import { FieldDefinitionNode } from 'graphql/language';
import { Resolution_Resolvers } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext, useMemo } from 'react';
import { getRefFromDestinationRef } from 'utils/helpers';
import { GqlLandingContext } from '../../../context/GqlLandingContext';
import { GqlSchemaTabStyles } from '../Tabs/GqlSchemaTabStyles.style';

const GqlResolverButton = ({
  objectName,
  fieldNode,
  onResolverSelected
}: {
  objectName: string;
  fieldNode: FieldDefinitionNode | null | undefined;
  onResolverSelected: (resolver: Resolution_Resolvers) => void;
}) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { resolverMap } = gqlCtx;
  //
  // Gets Resolver from ResolverMap
  //
  const fieldName = fieldNode?.name.value ?? '';
  const theResolver = useMemo(() => {
    if (!fieldName) return undefined;
    let fieldMap = resolverMap[objectName];
    if (!fieldMap) {
      // These are special cases.
      if (objectName === 'query') fieldMap = resolverMap['Query'];
      else if (objectName === 'mutation') fieldMap = resolverMap['Mutation'];
    }
    if (!fieldMap) return undefined;
    return fieldMap[fieldName];
  }, [fieldNode]);
  const hasResolver = !!theResolver;

  const restResolver =
    theResolver?.resolver?.resolver.oneofKind === 'restResolver'
      ? theResolver.resolver.resolver.restResolver
      : undefined;
  const isRest = !!restResolver;

  const destName = getRefFromDestinationRef(restResolver?.destination?.destinationRef)?.name ?? 'unknown';

  const onResolverButtonClick = () => {
    if (!theResolver) return;
    onResolverSelected(theResolver);
  };

  //
  // Render
  //
  if (!hasResolver)
    return (
      <Spacer display='inline-block'>
        <Tooltip title={<Spacer padding={2}>No resolver defined</Spacer>} trigger='hover'>
          <GqlSchemaTabStyles.GqlNoCheckedIcon />
        </Tooltip>
      </Spacer>
    );
  return (
    <Spacer display='inline-block'>
      <GqlSchemaTabStyles.GqlCheckedIconContainer data-testid='gql-resolver-icon'>
        <Tooltip
          placement='topLeft'
          trigger='hover'
          title={
            <Spacer px={5} py={2}>
              <GqlSchemaTabStyles.TableCellTooltip>
                <Spacer mb={3}>
                  {isRest ? (
                    <>
                      <Spacer display='inline' mr={2}>
                        <RestIcon />
                      </Spacer>
                      REST Resolver
                    </>
                  ) : (
                    <>
                      <Spacer display='inline' mr={2}>
                        <GrpcIcon />
                      </Spacer>
                      gRPC Resolver
                    </>
                  )}
                </Spacer>
                <Spacer mb={2}>
                  <SecondaryInfoItem label='Field Name' data={fieldName} />
                </Spacer>
                <Spacer mb={3}>
                  {isRest ? (
                    <SecondaryInfoItem
                      label='Destination'
                      // Only the first destination is used.
                      data={destName}
                    />
                  ) : (
                    <SecondaryInfoItem label='Destination' data={'My-gRPC-api'} />
                  )}
                </Spacer>
                <Spacer display='inline-block'>
                  <UnstyledButton onClick={onResolverButtonClick}>
                    <SoloLinkStyles.SoloLinkWrapper>
                      <SoloLinkStyles.SoloLinkLooks withArrow data-testid='gql-resolver-details-link'>
                        VIEW DETAILS
                      </SoloLinkStyles.SoloLinkLooks>
                    </SoloLinkStyles.SoloLinkWrapper>
                  </UnstyledButton>
                </Spacer>
              </GqlSchemaTabStyles.TableCellTooltip>
            </Spacer>
          }>
          <GqlResolverInfoIcon />
        </Tooltip>
      </GqlSchemaTabStyles.GqlCheckedIconContainer>
    </Spacer>
  );
};

export default GqlResolverButton;
