/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routing.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { RouteTableDetails } from "./routes_common_pb";
import { WorkspaceDependencies } from "./common_pb";
import { WorkspaceStatus } from "./common_pb";
import { Pagination } from "./common_pb";
import { Status } from "./common_pb";
import { ImportStatus } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { PortSelector } from "../../../../api/gloo.solo.io/common/v2/port_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.Listener
 */
export interface Listener {
    /**
     * @generated from protobuf field: common.gloo.solo.io.PortSelector port = 1;
     */
    port?: PortSelector;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListenerType type = 2;
     */
    type: ListenerType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Listener.TlsState tls = 3;
     */
    tls: Listener_TlsState;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.Listener.RouteTable route_tables = 4;
     */
    routeTables: Listener_RouteTable[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.Listener.RouteTable
 */
export interface Listener_RouteTable {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: repeated string hosts = 2;
     */
    hosts: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ImportStatus import_status = 3;
     */
    importStatus: ImportStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * @generated from protobuf field: string owner_workspace_name = 5;
     */
    ownerWorkspaceName: string;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.Listener.TlsState
 */
export enum Listener_TlsState {
    /**
     * @generated from protobuf enum value: UNKNOWN_TLS_STATE = 0;
     */
    UNKNOWN_TLS_STATE = 0,
    /**
     * @generated from protobuf enum value: NONE = 1;
     */
    NONE = 1,
    /**
     * @generated from protobuf enum value: HTTPS_REDIRECT = 2;
     */
    HTTPS_REDIRECT = 2,
    /**
     * @generated from protobuf enum value: TLS_ENABLED = 3;
     */
    TLS_ENABLED = 3
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GatewayWorkload
 */
export interface GatewayWorkload {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef workload_ref = 1;
     */
    workloadRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetVirtualGatewayDetailsRequest
 */
export interface GetVirtualGatewayDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef gateway_ref = 1;
     */
    gatewayRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination workloads_pagination = 2;
     */
    workloadsPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetVirtualGatewayDetailsResponse
 */
export interface GetVirtualGatewayDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.Listener listeners = 3;
     */
    listeners: Listener[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GatewayWorkload workloads = 4;
     */
    workloads: GatewayWorkload[];
    /**
     * @generated from protobuf field: int32 total_gateway_workloads = 5;
     */
    totalGatewayWorkloads: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRouteTableDetailsRequest
 */
export interface GetRouteTableDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination routes_pagination = 2;
     */
    routesPagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRouteTableDetailsResponse
 */
export interface GetRouteTableDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceDependencies dependencies = 2;
     */
    dependencies?: WorkspaceDependencies;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.RouteTableDetails route_table_details = 3;
     */
    routeTableDetails?: RouteTableDetails;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRouteTableSchemaRequest
 */
export interface GetRouteTableSchemaRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetRouteTableSchemaResponse
 */
export interface GetRouteTableSchemaResponse {
    /**
     * @generated from protobuf field: string schema = 1;
     */
    schema: string;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.ListenerType
 */
export enum ListenerType {
    /**
     * @generated from protobuf enum value: UNKNOWN_LISTENER_TYPE = 0;
     */
    UNKNOWN_LISTENER_TYPE = 0,
    /**
     * @generated from protobuf enum value: HTTP_LISTENER = 1;
     */
    HTTP_LISTENER = 1,
    /**
     * @generated from protobuf enum value: TCP_LISTENER = 2;
     */
    TCP_LISTENER = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Listener$Type extends MessageType<Listener> {
    constructor() {
        super("rpc.gloo.solo.io.Listener", [
            { no: 1, name: "port", kind: "message", T: () => PortSelector },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.ListenerType", ListenerType] },
            { no: 3, name: "tls", kind: "enum", T: () => ["rpc.gloo.solo.io.Listener.TlsState", Listener_TlsState] },
            { no: 4, name: "route_tables", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Listener_RouteTable }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Listener
 */
export const Listener = new Listener$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Listener_RouteTable$Type extends MessageType<Listener_RouteTable> {
    constructor() {
        super("rpc.gloo.solo.io.Listener.RouteTable", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "import_status", kind: "enum", T: () => ["rpc.gloo.solo.io.ImportStatus", ImportStatus] },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "owner_workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Listener.RouteTable
 */
export const Listener_RouteTable = new Listener_RouteTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GatewayWorkload$Type extends MessageType<GatewayWorkload> {
    constructor() {
        super("rpc.gloo.solo.io.GatewayWorkload", [
            { no: 1, name: "workload_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GatewayWorkload
 */
export const GatewayWorkload = new GatewayWorkload$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVirtualGatewayDetailsRequest$Type extends MessageType<GetVirtualGatewayDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetVirtualGatewayDetailsRequest", [
            { no: 1, name: "gateway_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "workloads_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetVirtualGatewayDetailsRequest
 */
export const GetVirtualGatewayDetailsRequest = new GetVirtualGatewayDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVirtualGatewayDetailsResponse$Type extends MessageType<GetVirtualGatewayDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetVirtualGatewayDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "listeners", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Listener },
            { no: 4, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GatewayWorkload },
            { no: 5, name: "total_gateway_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetVirtualGatewayDetailsResponse
 */
export const GetVirtualGatewayDetailsResponse = new GetVirtualGatewayDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRouteTableDetailsRequest$Type extends MessageType<GetRouteTableDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetRouteTableDetailsRequest", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "routes_pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRouteTableDetailsRequest
 */
export const GetRouteTableDetailsRequest = new GetRouteTableDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRouteTableDetailsResponse$Type extends MessageType<GetRouteTableDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetRouteTableDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "dependencies", kind: "message", T: () => WorkspaceDependencies },
            { no: 3, name: "route_table_details", kind: "message", T: () => RouteTableDetails },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRouteTableDetailsResponse
 */
export const GetRouteTableDetailsResponse = new GetRouteTableDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRouteTableSchemaRequest$Type extends MessageType<GetRouteTableSchemaRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetRouteTableSchemaRequest", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRouteTableSchemaRequest
 */
export const GetRouteTableSchemaRequest = new GetRouteTableSchemaRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRouteTableSchemaResponse$Type extends MessageType<GetRouteTableSchemaResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetRouteTableSchemaResponse", [
            { no: 1, name: "schema", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetRouteTableSchemaResponse
 */
export const GetRouteTableSchemaResponse = new GetRouteTableSchemaResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.RoutingApi
 */
export const RoutingApi = new ServiceType("rpc.gloo.solo.io.RoutingApi", [
    { name: "GetVirtualGatewayDetails", options: {}, I: GetVirtualGatewayDetailsRequest, O: GetVirtualGatewayDetailsResponse },
    { name: "GetRouteTableDetails", options: {}, I: GetRouteTableDetailsRequest, O: GetRouteTableDetailsResponse },
    { name: "GetRouteTableSchema", options: {}, I: GetRouteTableSchemaRequest, O: GetRouteTableSchemaResponse }
]);
