import { CardStyles } from 'Components/Common/Card';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloTable } from 'Components/Common/SoloTable';
import { OverviewDetailsStyles } from 'Components/Features/Overview/OverviewDetails.style';
import { Tooltip } from 'antd';
import { GatewayIconBlue, GqlApiExplorerIcon } from 'assets/assets';
import { GraphqlApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useContext, useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { buildGraphqlApiExplorerUrl, buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlDetailsStyles } from './GqlDetails.style';

const GqlGatewaysCard = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api, routeTableRef, istioRouteName, apiType } = gqlCtx;
  const tableData = useMemo(
    () =>
      api.gatewayRefs.map(g => ({
        key: buildIdFromRef(g),
        name: g.name,
        gatewayRef: g,
        link: buildWorkspaceRouteDetailsUrl(api.ownerWorkspace?.ref?.name, RouteType.VIRTUAL_GATEWAY, g)
      })),
    [api.gatewayRefs]
  );
  return (
    <CardStyles.Card data-testid='gql-gateways-card'>
      <CardStyles.CardHeader>
        Gateways
        <CardStyles.CardHeaderRightIcon>
          <GatewayIconBlue />
        </CardStyles.CardHeaderRightIcon>
      </CardStyles.CardHeader>
      <SoloTable
        removeHorizontalPadding
        rowClassName={
          // Proxied API's can't be invoked.
          // So we show a standard table for them
          apiType === GraphqlApiType.PROXIED ? undefined : (_rowData, _idx) => 'force-borderless'
        }
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            render: (value, record) => {
              // Proxied API's can't be invoked.
              if (apiType === GraphqlApiType.PROXIED)
                return (
                  <SoloLink inline link={record.link as string}>
                    {value}
                  </SoloLink>
                );
              return (
                <GqlDetailsStyles.GatewayAndExplorerLinks>
                  <SoloLink inline link={record.link as string}>
                    {value}
                  </SoloLink>
                  <OverviewDetailsStyles.DottedLined />
                  <Tooltip
                    placement='topLeft'
                    trigger='hover'
                    title={() => (
                      <GqlDetailsStyles.ExplorerLinkTooltipContent>
                        <SoloLink
                          withArrow
                          link={`${buildGraphqlApiExplorerUrl({
                            routeTableRef,
                            istioRouteName,
                            gatewayRef: record.gatewayRef
                          })}`}>
                          Explore this API
                        </SoloLink>
                      </GqlDetailsStyles.ExplorerLinkTooltipContent>
                    )}>
                    <GqlApiExplorerIcon />
                  </Tooltip>
                </GqlDetailsStyles.GatewayAndExplorerLinks>
              );
            }
          }
        ]}
        dataSource={tableData}
        data-testid='gql-gateways-table'
        renderEmpty={
          <SoloEmptyWithDocs
            icon={<GatewayIconBlue />}
            title='There are no gateways to display'
            description=''
            href={docLinks.gateway.graphql}
          />
        }
      />
    </CardStyles.Card>
  );
};

export default GqlGatewaysCard;
