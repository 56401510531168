/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OverviewApi } from "./overview_pb";
import type { ListOverviewPortalsResponse } from "./overview_pb";
import type { ListOverviewPortalsRequest } from "./overview_pb";
import type { ListOverviewApisResponse } from "./overview_pb";
import type { ListOverviewApisRequest } from "./overview_pb";
import type { ListOverviewGatewaysResponse } from "./overview_pb";
import type { ListOverviewGatewaysRequest } from "./overview_pb";
import type { GetOverviewClusterResponse } from "./overview_pb";
import type { GetOverviewClusterRequest } from "./overview_pb";
import type { ListOverviewClustersResponse } from "./overview_pb";
import type { ListOverviewClustersRequest } from "./overview_pb";
import type { ListNodesResponse } from "./overview_pb";
import type { ListNodesRequest } from "./overview_pb";
import type { ListEgressSourcesResponse } from "./overview_pb";
import type { ListEgressSourcesRequest } from "./overview_pb";
import type { ListIngressGatewaysResponse } from "./overview_pb";
import type { ListIngressGatewaysRequest } from "./overview_pb";
import type { ListServicesResponse } from "./overview_pb";
import type { ListServicesRequest } from "./overview_pb";
import type { GetClusterDetailsCardsResponse } from "./overview_pb";
import type { GetClusterDetailsCardsRequest } from "./overview_pb";
import type { GetDashboardCardsResponse } from "./overview_pb";
import type { GetDashboardCardsRequest } from "./overview_pb";
import type { ListWorkspacesResponse } from "./overview_pb";
import type { ListWorkspacesRequest } from "./overview_pb";
import type { ListClustersResponse } from "./overview_pb";
import type { ListClustersRequest } from "./overview_pb";
import type { GetOverviewWorkspaceResponse } from "./overview_pb";
import type { GetOverviewWorkspaceRequest } from "./overview_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListOverviewWorkspacesResponse } from "./overview_pb";
import type { ListOverviewWorkspacesRequest } from "./overview_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rpc.gloo.solo.io.OverviewApi
 */
export interface IOverviewApiClient {
    /**
     * @generated from protobuf rpc: ListOverviewWorkspaces(rpc.gloo.solo.io.ListOverviewWorkspacesRequest) returns (rpc.gloo.solo.io.ListOverviewWorkspacesResponse);
     */
    listOverviewWorkspaces(input: ListOverviewWorkspacesRequest, options?: RpcOptions): UnaryCall<ListOverviewWorkspacesRequest, ListOverviewWorkspacesResponse>;
    /**
     * @generated from protobuf rpc: GetOverviewWorkspace(rpc.gloo.solo.io.GetOverviewWorkspaceRequest) returns (rpc.gloo.solo.io.GetOverviewWorkspaceResponse);
     */
    getOverviewWorkspace(input: GetOverviewWorkspaceRequest, options?: RpcOptions): UnaryCall<GetOverviewWorkspaceRequest, GetOverviewWorkspaceResponse>;
    /**
     * @generated from protobuf rpc: ListClusters(rpc.gloo.solo.io.ListClustersRequest) returns (rpc.gloo.solo.io.ListClustersResponse);
     */
    listClusters(input: ListClustersRequest, options?: RpcOptions): UnaryCall<ListClustersRequest, ListClustersResponse>;
    /**
     * @generated from protobuf rpc: ListWorkspaces(rpc.gloo.solo.io.ListWorkspacesRequest) returns (rpc.gloo.solo.io.ListWorkspacesResponse);
     */
    listWorkspaces(input: ListWorkspacesRequest, options?: RpcOptions): UnaryCall<ListWorkspacesRequest, ListWorkspacesResponse>;
    /**
     * These are new calls
     *
     * @generated from protobuf rpc: GetDashboardCards(rpc.gloo.solo.io.GetDashboardCardsRequest) returns (rpc.gloo.solo.io.GetDashboardCardsResponse);
     */
    getDashboardCards(input: GetDashboardCardsRequest, options?: RpcOptions): UnaryCall<GetDashboardCardsRequest, GetDashboardCardsResponse>;
    /**
     * @generated from protobuf rpc: GetClusterDetailsCards(rpc.gloo.solo.io.GetClusterDetailsCardsRequest) returns (rpc.gloo.solo.io.GetClusterDetailsCardsResponse);
     */
    getClusterDetailsCards(input: GetClusterDetailsCardsRequest, options?: RpcOptions): UnaryCall<GetClusterDetailsCardsRequest, GetClusterDetailsCardsResponse>;
    /**
     * @generated from protobuf rpc: ListServices(rpc.gloo.solo.io.ListServicesRequest) returns (rpc.gloo.solo.io.ListServicesResponse);
     */
    listServices(input: ListServicesRequest, options?: RpcOptions): UnaryCall<ListServicesRequest, ListServicesResponse>;
    /**
     * @generated from protobuf rpc: ListIngressGateways(rpc.gloo.solo.io.ListIngressGatewaysRequest) returns (rpc.gloo.solo.io.ListIngressGatewaysResponse);
     */
    listIngressGateways(input: ListIngressGatewaysRequest, options?: RpcOptions): UnaryCall<ListIngressGatewaysRequest, ListIngressGatewaysResponse>;
    /**
     * @generated from protobuf rpc: ListEgressSources(rpc.gloo.solo.io.ListEgressSourcesRequest) returns (rpc.gloo.solo.io.ListEgressSourcesResponse);
     */
    listEgressSources(input: ListEgressSourcesRequest, options?: RpcOptions): UnaryCall<ListEgressSourcesRequest, ListEgressSourcesResponse>;
    /**
     * @generated from protobuf rpc: ListNodes(rpc.gloo.solo.io.ListNodesRequest) returns (rpc.gloo.solo.io.ListNodesResponse);
     */
    listNodes(input: ListNodesRequest, options?: RpcOptions): UnaryCall<ListNodesRequest, ListNodesResponse>;
    /**
     * These calls are being deprecated
     *
     * @generated from protobuf rpc: ListOverviewClusters(rpc.gloo.solo.io.ListOverviewClustersRequest) returns (rpc.gloo.solo.io.ListOverviewClustersResponse);
     */
    listOverviewClusters(input: ListOverviewClustersRequest, options?: RpcOptions): UnaryCall<ListOverviewClustersRequest, ListOverviewClustersResponse>;
    /**
     * @generated from protobuf rpc: GetOverviewCluster(rpc.gloo.solo.io.GetOverviewClusterRequest) returns (rpc.gloo.solo.io.GetOverviewClusterResponse);
     */
    getOverviewCluster(input: GetOverviewClusterRequest, options?: RpcOptions): UnaryCall<GetOverviewClusterRequest, GetOverviewClusterResponse>;
    /**
     * @generated from protobuf rpc: ListOverviewGateways(rpc.gloo.solo.io.ListOverviewGatewaysRequest) returns (rpc.gloo.solo.io.ListOverviewGatewaysResponse);
     */
    listOverviewGateways(input: ListOverviewGatewaysRequest, options?: RpcOptions): UnaryCall<ListOverviewGatewaysRequest, ListOverviewGatewaysResponse>;
    /**
     * @generated from protobuf rpc: ListOverviewApis(rpc.gloo.solo.io.ListOverviewApisRequest) returns (rpc.gloo.solo.io.ListOverviewApisResponse);
     */
    listOverviewApis(input: ListOverviewApisRequest, options?: RpcOptions): UnaryCall<ListOverviewApisRequest, ListOverviewApisResponse>;
    /**
     * @generated from protobuf rpc: ListOverviewPortals(rpc.gloo.solo.io.ListOverviewPortalsRequest) returns (rpc.gloo.solo.io.ListOverviewPortalsResponse);
     */
    listOverviewPortals(input: ListOverviewPortalsRequest, options?: RpcOptions): UnaryCall<ListOverviewPortalsRequest, ListOverviewPortalsResponse>;
}
/**
 * @generated from protobuf service rpc.gloo.solo.io.OverviewApi
 */
export class OverviewApiClient implements IOverviewApiClient, ServiceInfo {
    typeName = OverviewApi.typeName;
    methods = OverviewApi.methods;
    options = OverviewApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListOverviewWorkspaces(rpc.gloo.solo.io.ListOverviewWorkspacesRequest) returns (rpc.gloo.solo.io.ListOverviewWorkspacesResponse);
     */
    listOverviewWorkspaces(input: ListOverviewWorkspacesRequest, options?: RpcOptions): UnaryCall<ListOverviewWorkspacesRequest, ListOverviewWorkspacesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOverviewWorkspacesRequest, ListOverviewWorkspacesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOverviewWorkspace(rpc.gloo.solo.io.GetOverviewWorkspaceRequest) returns (rpc.gloo.solo.io.GetOverviewWorkspaceResponse);
     */
    getOverviewWorkspace(input: GetOverviewWorkspaceRequest, options?: RpcOptions): UnaryCall<GetOverviewWorkspaceRequest, GetOverviewWorkspaceResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOverviewWorkspaceRequest, GetOverviewWorkspaceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListClusters(rpc.gloo.solo.io.ListClustersRequest) returns (rpc.gloo.solo.io.ListClustersResponse);
     */
    listClusters(input: ListClustersRequest, options?: RpcOptions): UnaryCall<ListClustersRequest, ListClustersResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClustersRequest, ListClustersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListWorkspaces(rpc.gloo.solo.io.ListWorkspacesRequest) returns (rpc.gloo.solo.io.ListWorkspacesResponse);
     */
    listWorkspaces(input: ListWorkspacesRequest, options?: RpcOptions): UnaryCall<ListWorkspacesRequest, ListWorkspacesResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListWorkspacesRequest, ListWorkspacesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * These are new calls
     *
     * @generated from protobuf rpc: GetDashboardCards(rpc.gloo.solo.io.GetDashboardCardsRequest) returns (rpc.gloo.solo.io.GetDashboardCardsResponse);
     */
    getDashboardCards(input: GetDashboardCardsRequest, options?: RpcOptions): UnaryCall<GetDashboardCardsRequest, GetDashboardCardsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDashboardCardsRequest, GetDashboardCardsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetClusterDetailsCards(rpc.gloo.solo.io.GetClusterDetailsCardsRequest) returns (rpc.gloo.solo.io.GetClusterDetailsCardsResponse);
     */
    getClusterDetailsCards(input: GetClusterDetailsCardsRequest, options?: RpcOptions): UnaryCall<GetClusterDetailsCardsRequest, GetClusterDetailsCardsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetClusterDetailsCardsRequest, GetClusterDetailsCardsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListServices(rpc.gloo.solo.io.ListServicesRequest) returns (rpc.gloo.solo.io.ListServicesResponse);
     */
    listServices(input: ListServicesRequest, options?: RpcOptions): UnaryCall<ListServicesRequest, ListServicesResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListServicesRequest, ListServicesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListIngressGateways(rpc.gloo.solo.io.ListIngressGatewaysRequest) returns (rpc.gloo.solo.io.ListIngressGatewaysResponse);
     */
    listIngressGateways(input: ListIngressGatewaysRequest, options?: RpcOptions): UnaryCall<ListIngressGatewaysRequest, ListIngressGatewaysResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListIngressGatewaysRequest, ListIngressGatewaysResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListEgressSources(rpc.gloo.solo.io.ListEgressSourcesRequest) returns (rpc.gloo.solo.io.ListEgressSourcesResponse);
     */
    listEgressSources(input: ListEgressSourcesRequest, options?: RpcOptions): UnaryCall<ListEgressSourcesRequest, ListEgressSourcesResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListEgressSourcesRequest, ListEgressSourcesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListNodes(rpc.gloo.solo.io.ListNodesRequest) returns (rpc.gloo.solo.io.ListNodesResponse);
     */
    listNodes(input: ListNodesRequest, options?: RpcOptions): UnaryCall<ListNodesRequest, ListNodesResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListNodesRequest, ListNodesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * These calls are being deprecated
     *
     * @generated from protobuf rpc: ListOverviewClusters(rpc.gloo.solo.io.ListOverviewClustersRequest) returns (rpc.gloo.solo.io.ListOverviewClustersResponse);
     */
    listOverviewClusters(input: ListOverviewClustersRequest, options?: RpcOptions): UnaryCall<ListOverviewClustersRequest, ListOverviewClustersResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOverviewClustersRequest, ListOverviewClustersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOverviewCluster(rpc.gloo.solo.io.GetOverviewClusterRequest) returns (rpc.gloo.solo.io.GetOverviewClusterResponse);
     */
    getOverviewCluster(input: GetOverviewClusterRequest, options?: RpcOptions): UnaryCall<GetOverviewClusterRequest, GetOverviewClusterResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOverviewClusterRequest, GetOverviewClusterResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListOverviewGateways(rpc.gloo.solo.io.ListOverviewGatewaysRequest) returns (rpc.gloo.solo.io.ListOverviewGatewaysResponse);
     */
    listOverviewGateways(input: ListOverviewGatewaysRequest, options?: RpcOptions): UnaryCall<ListOverviewGatewaysRequest, ListOverviewGatewaysResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOverviewGatewaysRequest, ListOverviewGatewaysResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListOverviewApis(rpc.gloo.solo.io.ListOverviewApisRequest) returns (rpc.gloo.solo.io.ListOverviewApisResponse);
     */
    listOverviewApis(input: ListOverviewApisRequest, options?: RpcOptions): UnaryCall<ListOverviewApisRequest, ListOverviewApisResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOverviewApisRequest, ListOverviewApisResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListOverviewPortals(rpc.gloo.solo.io.ListOverviewPortalsRequest) returns (rpc.gloo.solo.io.ListOverviewPortalsResponse);
     */
    listOverviewPortals(input: ListOverviewPortalsRequest, options?: RpcOptions): UnaryCall<ListOverviewPortalsRequest, ListOverviewPortalsResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOverviewPortalsRequest, ListOverviewPortalsResponse>("unary", this._transport, method, opt, input);
    }
}
