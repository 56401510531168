import { CardStyles } from 'Components/Common/Card';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  RenderImportStatus,
  StateAndNameType,
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn,
  renderDestinationKind
} from 'Components/Common/SoloTableCells';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ImportStatus } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useContext, useMemo } from 'react';
import { getExecutableSchema } from 'utils/graphql-helpers';
import { buildIdFromRef, getRefFromDestinationRef } from 'utils/helpers';
import { docLinks } from 'utils/url-external-links-map';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlDetailsStyles as Styles } from './GqlDetails.style';

interface TableFields {
  key: string;
  name: StateAndNameType;
  namespace?: string;
  cluster?: string;
  kind?: DestinationKind;
  importStatus: ImportStatus;
}
const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'cluster' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  {
    title: 'Type',
    dataIndex: 'kind',
    render: renderDestinationKind
  },
  {
    title: 'Import Status',
    dataIndex: 'importStatus',
    render: RenderImportStatus
  }
];

const GqlAppliedToDestinationsCard = () => {
  const gqlCtx = useContext(GqlLandingContext);
  const executableSchema = getExecutableSchema(gqlCtx.api);
  const appliedDestinationsList = executableSchema?.appliedDestinations;

  //
  // Pagination, Table Data
  //
  const allAppliedToDestinations = useMemo<TableFields[]>(
    () =>
      appliedDestinationsList?.map(d => {
        const ref = getRefFromDestinationRef(d.destinationRef);
        return {
          ...ref,
          key: buildIdFromRef(ref),
          name: { name: ref?.name ?? '', status: d.status },
          kind: d.destinationRef?.kind,
          importStatus: d.importStatus
        };
      }) ?? [],
    [appliedDestinationsList]
  );
  const { pagingData, paginatedData: tableData } = useSoloPagingWithDataFiltering(allAppliedToDestinations, 5);

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='gql-applied-to-destinations-card'>
      <Styles.StyledCardHeader>
        <Spacer mr={5}>Applied to Destinations</Spacer>
        <CardStyles.CardHeaderRightIcon>
          <Asset.ServiceIcon />
        </CardStyles.CardHeaderRightIcon>
      </Styles.StyledCardHeader>
      <SoloTable
        removeHorizontalPadding
        dataSource={tableData}
        columns={columns}
        data-testid='gql-applied-to-destinations-table'
        paging={pagingData}
        renderEmpty={
          <SoloEmptyWithDocs
            icon={<Asset.SettingsGear />}
            title='There are no destinations to display'
            description=''
            href={docLinks.gateway.graphql}
          />
        }
      />
    </CardStyles.Card>
  );
};

export default GqlAppliedToDestinationsCard;
