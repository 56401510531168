import { CardStyles } from 'Components/Common/Card';
import { Loading } from 'Components/Common/Loading';
import { Svg, SvgWithTextContainer } from 'Components/Common/Svg';
import { CertExpiryStyles } from 'Components/Features/Dashboard/Cards/CertificatesExpiry/CertificatesExpiryCard.style';
import CertificatesExpiryCardContent from 'Components/Features/Dashboard/Cards/CertificatesExpiry/CertificatesExpiryCardContent';
import { useFilterDuplicateCertificates } from 'Components/Features/Dashboard/Cards/CertificatesExpiry/hooks';
import {
  CertificateExpiryKey,
  certificatesExpiryCodesMap
} from 'Components/Features/Dashboard/Cards/CertificatesExpiry/insight-codes';
import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { Asset } from 'assets';
import { ListInsightsRequest_InsightsFilter_Target } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { di } from 'react-magnetic-di';
import { useCheckIfDashboardInsightsPassedLimit, useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';

export const ClusterCertificatesExpiryCard = ({ cluster }: { cluster: string }) => {
  di(useFilteredDashboardInsights);
  const target: ListInsightsRequest_InsightsFilter_Target = { clusters: [cluster] };
  //
  // Get cert insights data
  //
  const insightsLimitPassedData = useCheckIfDashboardInsightsPassedLimit('certs', target);
  const rootCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.rootCAExpiry,
    target
  );
  const intermediateCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.intermediateCAExpiry,
    target
  );

  const { rootCertInsightsFiltered } = useFilterDuplicateCertificates(rootCertInsights);

  const isLoading = rootCertInsights === undefined || intermediateCertInsights === undefined;

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='certs-expiry-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.CertExpiryIcon />} color={theme => theme.neptuneBlue} />
        Certificates Expiry
      </DashboardStyles.Header>
      {isLoading ? (
        <Loading />
      ) : (
        <CertExpiryStyles.CardContentAndFooterContainer>
          <CertificatesExpiryCardContent
            rootCertInsights={rootCertInsightsFiltered ?? []}
            intermediateCertInsights={intermediateCertInsights}
          />
          {!!insightsLimitPassedData?.limitPassed && (
            <CertExpiryStyles.LimitWarning>
              <SvgWithTextContainer>
                <Svg asset={<Asset.WarningCircleIcon />} size={17} /> Some certificates could not be rendered.
              </SvgWithTextContainer>
            </CertExpiryStyles.LimitWarning>
          )}
        </CertExpiryStyles.CardContentAndFooterContainer>
      )}
    </CardStyles.Card>
  );
};
