import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { useMemo } from 'react';
import { getInsightData } from 'utils/dashboard/dashboard-helpers';
import { isNonNullableAndNonFalse } from 'utils/helpers';
import { CertificateExpiryKey, certificatesExpiryCodesMap } from '../insight-codes';

/**
 * Get info about the line, based on the insight data.
 */
export const useGetExpiryLineTimeData = (
  insights: Insight[],
  key: CertificateExpiryKey.rootCAExpiry | CertificateExpiryKey.intermediateCAExpiry // gateway type no longer combines nicely
) => {
  const { dataProp } = certificatesExpiryCodesMap[key];
  const curSeconds = Math.floor(Date.now().valueOf() / 1000);

  const datas = useMemo(
    () => insights.map(insight => getInsightData(insight, dataProp)).filter(isNonNullableAndNonFalse),
    [insights]
  );

  const startTime = useMemo(() => Math.min(curSeconds, ...datas.map(d => d.issuedOn?.seconds ?? 0)), [datas]);
  const endTime = useMemo(() => Math.max(1, ...datas.map(d => d.expiresOn?.seconds ?? 0)), [datas]);

  const totalSeconds = endTime - startTime;

  return {
    startTime,
    endTime,
    totalSeconds
  };
};
