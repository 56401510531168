import { Asset } from 'assets';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import {
  ImportStatus,
  Status
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React from 'react';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { MonospaceText } from 'Styles/CommonEmotions/text';
import { doDownload } from 'utils/helpers';
import {
  getDestinationKindIcon,
  getDestinationKindReadableName,
  getImportStatusIcon,
  getImportStatusReadableName
} from 'utils/types';
import { getFileName } from 'utils/yaml-helpers';
import { HealthIndicator, HealthIndicatorWithName } from './HealthIndicator';
import { MarkdownRenderer } from './MarkdownRenderer';
import { SoloLink } from './SoloLink';
import { SoloColumnType } from './SoloTable';
import { SoloTableStyles as Styles } from './SoloTable.style';
import SoloTableTextEllipsesCell from './SoloTableTextEllipsesCell';
import { StringList, StringListProps } from './StringList';

export function createStatusColumn<RecordType>(column: SoloColumnType<RecordType> & { hideErrorList?: boolean }) {
  return {
    width: 55, // cell padding (20*2) + indicator size (15)
    ...column,
    // Because a cell width of 15 isn't enough for the header text to fit,
    // this forces it to overflow in a more pleasant way, centering over the indicator
    title: <FlexLayout horizontal>{column.title ?? 'Status'}</FlexLayout>,
    render: (status: Status | undefined) => (
      // Also center the indicator encase something forces the column to grow / the width is manually increased
      <FlexLayout horizontal>
        <HealthIndicator status={status} hideErrorList={column.hideErrorList} />
      </FlexLayout>
    )
  };
}

export type StateAndNameType = {
  status: Status | undefined;
  name: string | undefined; // name is required, but allow it to be undefined due to api
  link?: string;
  'data-testid'?: string;
};
// must be used with `ellipsis: true` on column
export function renderHealthIndicatorWithName(props: StateAndNameType) {
  return <HealthIndicatorWithName {...props} maxWidth='200px' />;
}

export function createHealthIndicatorWithNameColumn<RecordType>(column: SoloColumnType<RecordType>) {
  return { ...column, ellipsis: true, render: (props: StateAndNameType) => <HealthIndicatorWithName {...props} /> };
}

export function createEllipseTextColumn<RecordType>(column: SoloColumnType<RecordType> & { 'data-testid'?: string }) {
  return {
    ...column,
    ellipsis: true,
    render: (text: string) => <SoloTableTextEllipsesCell value={text ?? ''} data-testid={column['data-testid']} />
  };
}

export function createEllipseLinkColumn<RecordType>(column: SoloColumnType<RecordType>) {
  return {
    ...column,
    ellipsis: true,
    render: ({ text, url }: { text: string; url: string }) => (
      <SoloLink link={url}>
        <SoloTableTextEllipsesCell value={text} />
      </SoloLink>
    )
  };
}

export type RenderCellStringListProps = StringListProps;
export const RenderCellStringList = (props: StringListProps) => {
  return <StringList {...props} />;
};

// Since download requires a custom getYaml function, return the actual function after we pass that in
export const RenderDownloadYamlWithGetFunction = (getYaml: (objectRef: ObjectRef) => Promise<string>) => {
  const onDownloadYaml = (objectRef: ObjectRef) => {
    getYaml(objectRef).then(yaml => {
      doDownload(yaml, getFileName(objectRef));
    });
  };

  return (objectRef: ObjectRef) => {
    return (
      <Styles.TableActions>
        <Styles.TableActionCircle
          clickable={!!objectRef}
          onClick={() => {
            onDownloadYaml(objectRef!);
          }}>
          <Asset.DownloadIcon />
        </Styles.TableActionCircle>
      </Styles.TableActions>
    );
  };
};

export function RenderImportStatus(status: ImportStatus) {
  return (
    <Styles.CellIconText>
      {getImportStatusIcon(status)} {getImportStatusReadableName(status)}
    </Styles.CellIconText>
  );
}

export function renderDestinationKind(kind?: DestinationKind) {
  return (
    <Styles.CellIconText>
      {getDestinationKindIcon(kind)} {getDestinationKindReadableName(kind)}
    </Styles.CellIconText>
  );
}

export function renderReactMarkdown(str: string) {
  return (
    <Spacer py={1}>
      <MarkdownRenderer text={str} />
    </Spacer>
  );
}

export function renderMonospaceText(str: string) {
  return <MonospaceText>{str}</MonospaceText>;
}

export const LabelsCell = ({ labelsMap }: { labelsMap: string[][] }) => {
  return labelsMap.length === 0 ? (
    <></>
  ) : labelsMap.length === 1 ? (
    <>
      <b>{labelsMap[0][0]}</b>: {labelsMap[0][1]}
    </>
  ) : (
    <StringList
      useOnlyCount
      list={labelsMap
        .sort(([label1], [label2]) => label1.localeCompare(label2))
        .map(([label, value]) => (
          <React.Fragment key={`${label}:${value}`}>
            <b>{label}</b>: {value}
          </React.Fragment>
        ))}
    />
  );
};
