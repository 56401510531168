import { SoloCheckboxListStyles } from './SoloCheckboxList.style';
import { FindOrHideSearchBoxStyles as Styles } from './FindOrHideSearchBox.style';
import { SoloDropdown } from './SoloDropdown';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Asset } from 'assets';

interface FindOrHideSearchBoxProps {
  findName: string;
  setFindName: Dispatch<SetStateAction<string>>;
  hideName: string;
  setHideName: Dispatch<SetStateAction<string>>;
}
export const FindOrHideSearchBox = (props: FindOrHideSearchBoxProps) => {
  const [opened, setOpened] = useState(false);
  const [state, setState] = useState<'find' | 'hide'>('find');

  return (
    <SoloCheckboxListStyles.SelectWrap data-testid='find-or-hide-search-box-wrapper'>
      <Styles.MultiSearchBoxStyled>
        {opened ? (
          <SoloCheckboxListStyles.InputContainerStyle>
            <div>
              <Styles.MultiSearchBoxDropdownWrapper>
                <SoloDropdown
                  value={state}
                  onChange={val => setState(val as 'find' | 'hide')}
                  options={[
                    { key: 'find', displayValue: 'Find' },
                    { key: 'hide', displayValue: 'Hide' }
                  ]}
                />
              </Styles.MultiSearchBoxDropdownWrapper>
              {state === 'find' ? (
                <Styles.MultiSearchBoxInput
                  type='text'
                  value={props.findName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => props.setFindName(e.target.value)}
                  placeholder='by name...'
                  aria-label='Find by name'
                />
              ) : (
                <Styles.MultiSearchBoxInput
                  type='text'
                  value={props.hideName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => props.setHideName(e.target.value)}
                  placeholder='by name...'
                  aria-label='Hide by name'
                />
              )}
            </div>
            <span onClick={() => setOpened(false)} data-testid='search-close-button'>
              <Asset.MagnifyingGlass />
            </span>
          </SoloCheckboxListStyles.InputContainerStyle>
        ) : (
          <SoloCheckboxListStyles.DropdownTrigger onClick={() => setOpened(o => !o)} data-testid='search-start-button'>
            <Styles.SearchText>Filter by name...</Styles.SearchText> <Asset.MagnifyingGlass />
          </SoloCheckboxListStyles.DropdownTrigger>
        )}
      </Styles.MultiSearchBoxStyled>
    </SoloCheckboxListStyles.SelectWrap>
  );
};
