import React, { useState } from 'react';

///////////////////
// NOTE: If you change the structure of an in-use hook, then you have to still
// support returning the old format and updating it to the new one (unless
// it's minor and loss of old data is allowed)
// ex: `{ name:string } | { name:{ first:string, last:string } }`
///////////////////

// Sidebar state
export const SIDEBAR_KEY = 'site-sidebar';
// Allows `undefined` as a way to delete currently logged in user data
type SidebarProps = {
  closed: boolean;
};
saveInitialValueIfNotSet<SidebarProps>(SIDEBAR_KEY, { closed: false });
export function useSidebarStorage() {
  return useLocalStorage<SidebarProps>(SIDEBAR_KEY);
}

// Main storage hook
// https://usehooks.com/useLocalStorage/
// We don't support initialValue to prevent wierdness with potentially setting
// different initial values different places - any initial values should either
// be set on app start, or handled as undefined by default
type ValueAsFunc<T> = (storedValue: T | undefined) => T;
function useLocalStorage<T>(key: string): [T, React.Dispatch<T>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item !== null ? JSON.parse(item) : undefined; //initialValue;
    } catch (error) {
      // If error also return initialValue
      // eslint-disable-next-line no-console
      console.error(error);
      return undefined; //initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ValueAsFunc<T>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (valueToStore === null || valueToStore === undefined) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return [storedValue, setValue];
}

function saveInitialValueIfNotSet<T>(key: string, initialValue: T) {
  // Only set initial value if it's empty
  if (window.localStorage.getItem(key) === null) {
    window.localStorage.setItem(key, JSON.stringify(initialValue));
  }
}
