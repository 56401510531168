import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import React from 'react';

type LinkProps = {
  asSpan?: boolean;
  inheritedFont?: boolean;
};
export const Link = styled.div<LinkProps>(
  ({ theme, asSpan, inheritedFont }) => css`
    ${asSpan
      ? css`
          display: inline;
          cursor: pointer;
          color: ${theme.seaBlue};
          ${!inheritedFont
            ? css`
                font-size: 14px;
              `
            : ''}
        `
      : css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          color: ${theme.seaBlue};
          ${!inheritedFont
            ? css`
                font-size: 14px;
                font-weight: 500;
              `
            : ''}
        `}

    &:hover,
    &:active {
      text-decoration: underline;
    }
  `
);

export const ItalicText = styled.div`
  display: flex;

  font-style: italic;
  font-weight: 300;
  margin-right: 4px;

  > div {
    overflow: visible;
    font-size: 22px !important;
    line-height: 30px !important;
    margin-right: 5px;
    width: 10px;
  }
`;

export const MonospaceText = styled.span`
  font-family: Courier, monospace;
`;

interface TruncateTextProps {
  maxWidth?: string;
  title: React.ReactNode | undefined;
}
export const TruncateText = styled(Tooltip)<TruncateTextProps>(
  ({ maxWidth }) => css`
    display: inline-block;
    max-width: ${maxWidth ?? '100%'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

export const IconNextToText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
