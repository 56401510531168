import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { useState } from 'react';

export const ListExtraCount = styled.div(
  ({ theme }) => css`
    margin-left: 4px;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
    border: 1px solid ${theme.seaBlue};
    color: ${theme.seaBlue};
    text-transform: lowercase;
    padding: 0 4px;
  `
);

type StringListWithTooltipHolderProps = {
  listLength: number;
};
const StringListWithTooltipHolder = styled.div<StringListWithTooltipHolderProps>`
  ${({ listLength }: StringListWithTooltipHolderProps) =>
    listLength > 1 ? 'cursor: pointer;' : 'pointer-events: none;'}
`;

export const StringListWithTooltip = ({ items }: { items: string[] }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StringListWithTooltipHolder
      data-testid='string-list-with-tooltip'
      listLength={items.length}
      onClick={e => {
        // Needs to stopPropagation because when this is in a <SoloTable>, clicking on a row
        // navigates the user to that details page through the onRow onClick callback.
        // Adding stopPropagation() here prevents that callback from happening so that
        // this interaction can be used.
        e.stopPropagation();
        setIsOpen(true);
      }}>
      {items.length === 0 ? null : items.length > 1 ? (
        <Tooltip
          title={
            <div>
              {items.map(name => (
                <div key={name}>{name}</div>
              ))}
            </div>
          }
          trigger='click'
          open={isOpen}
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}>
          <div>
            {items[0]} {items.length > 1 && <ListExtraCount>+ {items.length - 1}</ListExtraCount>}
          </div>
        </Tooltip>
      ) : (
        items[0]
      )}
    </StringListWithTooltipHolder>
  );
};
