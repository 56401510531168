import { NotificationBox, NotificationType } from 'Components/Common/NotificationBox';
import { State, Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useMemo } from 'react';
import { MarkdownRenderer } from './MarkdownRenderer';

type Props = {
  status: Status | undefined;
  multipleIssuesMessage?: string;
  bottomMargin?: boolean;
  justMessage?: boolean;
};

export const HealthNotificationBox = ({ status, multipleIssuesMessage, bottomMargin, justMessage }: Props) => {
  const errors = useMemo(
    () =>
      status?.validationErrors.map(message => ({
        message: <MarkdownRenderer text={message} inline />
      })),
    [status]
  );
  if (!status || !errors || status.state === State.ACCEPTED || status.state === State.PENDING) {
    return null;
  }
  let notificationType = NotificationType.ERROR;
  if (status.state === State.WARNING) notificationType = NotificationType.WARNING;
  return (
    <NotificationBox
      type={notificationType}
      issues={!!errors.length ? errors : [{ message: 'State is invalid or failed' }]}
      multipleIssuesMessage={multipleIssuesMessage}
      bottomMargin={bottomMargin}
      justMessage={justMessage}
    />
  );
};
