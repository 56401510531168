/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources.proto" (package "resources.rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Code generated by skv2. DO NOT EDIT.
//
/**
 * @generated from protobuf enum resources.rpc.gloo.solo.io.ResourceType
 */
export enum ResourceType {
    /**
     * @generated from protobuf enum value: UNKNOWN_RESOURCE_TYPE = 0;
     */
    UNKNOWN_RESOURCE_TYPE = 0,
    /**
     * @generated from protobuf enum value: WORKSPACE = 1;
     */
    WORKSPACE = 1,
    /**
     * @generated from protobuf enum value: WORKSPACE_SETTINGS = 2;
     */
    WORKSPACE_SETTINGS = 2,
    /**
     * @generated from protobuf enum value: KUBERNETES_CLUSTER = 3;
     */
    KUBERNETES_CLUSTER = 3,
    /**
     * @generated from protobuf enum value: ROOT_TRUST_POLICY = 4;
     */
    ROOT_TRUST_POLICY = 4,
    /**
     * @generated from protobuf enum value: EXT_AUTH_SERVER = 5;
     */
    EXT_AUTH_SERVER = 5,
    /**
     * @generated from protobuf enum value: RATE_LIMIT_SERVER_SETTINGS = 6;
     */
    RATE_LIMIT_SERVER_SETTINGS = 6,
    /**
     * @generated from protobuf enum value: RATE_LIMIT_SERVER_CONFIG = 7;
     */
    RATE_LIMIT_SERVER_CONFIG = 7,
    /**
     * @generated from protobuf enum value: DASHBOARD = 8;
     */
    DASHBOARD = 8,
    /**
     * @generated from protobuf enum value: ISTIO_LIFECYCLE_MANAGER = 9;
     */
    ISTIO_LIFECYCLE_MANAGER = 9,
    /**
     * @generated from protobuf enum value: GATEWAY_LIFECYCLE_MANAGER = 10;
     */
    GATEWAY_LIFECYCLE_MANAGER = 10,
    /**
     * @generated from protobuf enum value: CLOUD_PROVIDER = 11;
     */
    CLOUD_PROVIDER = 11,
    /**
     * @generated from protobuf enum value: CLOUD_RESOURCES = 12;
     */
    CLOUD_RESOURCES = 12,
    /**
     * @generated from protobuf enum value: WAYPOINT_LIFECYCLE_MANAGER = 13;
     */
    WAYPOINT_LIFECYCLE_MANAGER = 13,
    /**
     * @generated from protobuf enum value: INSIGHTS_CONFIG = 14;
     */
    INSIGHTS_CONFIG = 14,
    /**
     * @generated from protobuf enum value: EXTERNAL_SERVICE = 15;
     */
    EXTERNAL_SERVICE = 15,
    /**
     * @generated from protobuf enum value: EXTERNAL_ENDPOINT = 16;
     */
    EXTERNAL_ENDPOINT = 16,
    /**
     * @generated from protobuf enum value: ROUTE_TABLE = 17;
     */
    ROUTE_TABLE = 17,
    /**
     * @generated from protobuf enum value: VIRTUAL_DESTINATION = 18;
     */
    VIRTUAL_DESTINATION = 18,
    /**
     * @generated from protobuf enum value: VIRTUAL_GATEWAY = 19;
     */
    VIRTUAL_GATEWAY = 19,
    /**
     * @generated from protobuf enum value: EXTERNAL_WORKLOAD = 20;
     */
    EXTERNAL_WORKLOAD = 20,
    /**
     * @generated from protobuf enum value: WASM_DEPLOYMENT_POLICY = 21;
     */
    WASM_DEPLOYMENT_POLICY = 21,
    /**
     * @generated from protobuf enum value: ACCESS_POLICY = 22;
     */
    ACCESS_POLICY = 22,
    /**
     * @generated from protobuf enum value: CORS_POLICY = 23;
     */
    CORS_POLICY = 23,
    /**
     * @generated from protobuf enum value: CSRF_POLICY = 24;
     */
    CSRF_POLICY = 24,
    /**
     * @generated from protobuf enum value: EXT_AUTH_POLICY = 25;
     */
    EXT_AUTH_POLICY = 25,
    /**
     * @generated from protobuf enum value: WAF_POLICY = 26;
     */
    WAF_POLICY = 26,
    /**
     * @generated from protobuf enum value: JWT_POLICY = 27;
     */
    JWT_POLICY = 27,
    /**
     * @generated from protobuf enum value: CLIENT_TLS_POLICY = 28;
     */
    CLIENT_TLS_POLICY = 28,
    /**
     * @generated from protobuf enum value: GRAPH_QL_ALLOWED_QUERY_POLICY = 29;
     */
    GRAPH_QL_ALLOWED_QUERY_POLICY = 29,
    /**
     * @generated from protobuf enum value: DLP_POLICY = 30;
     */
    DLP_POLICY = 30,
    /**
     * @generated from protobuf enum value: ACCESS_LOG_POLICY = 31;
     */
    ACCESS_LOG_POLICY = 31,
    /**
     * @generated from protobuf enum value: GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY = 32;
     */
    GRAPH_QL_PERSISTED_QUERY_CACHE_POLICY = 32,
    /**
     * @generated from protobuf enum value: FAILOVER_POLICY = 33;
     */
    FAILOVER_POLICY = 33,
    /**
     * @generated from protobuf enum value: OUTLIER_DETECTION_POLICY = 34;
     */
    OUTLIER_DETECTION_POLICY = 34,
    /**
     * @generated from protobuf enum value: ADAPTIVE_REQUEST_CONCURRENCY_POLICY = 35;
     */
    ADAPTIVE_REQUEST_CONCURRENCY_POLICY = 35,
    /**
     * @generated from protobuf enum value: FAULT_INJECTION_POLICY = 36;
     */
    FAULT_INJECTION_POLICY = 36,
    /**
     * @generated from protobuf enum value: RETRY_TIMEOUT_POLICY = 37;
     */
    RETRY_TIMEOUT_POLICY = 37,
    /**
     * @generated from protobuf enum value: CONNECTION_POLICY = 38;
     */
    CONNECTION_POLICY = 38,
    /**
     * @generated from protobuf enum value: TRIM_PROXY_CONFIG_POLICY = 39;
     */
    TRIM_PROXY_CONFIG_POLICY = 39,
    /**
     * @generated from protobuf enum value: ACTIVE_HEALTH_CHECK_POLICY = 40;
     */
    ACTIVE_HEALTH_CHECK_POLICY = 40,
    /**
     * @generated from protobuf enum value: LISTENER_CONNECTION_POLICY = 41;
     */
    LISTENER_CONNECTION_POLICY = 41,
    /**
     * @generated from protobuf enum value: MIRROR_POLICY = 42;
     */
    MIRROR_POLICY = 42,
    /**
     * @generated from protobuf enum value: RATE_LIMIT_POLICY = 43;
     */
    RATE_LIMIT_POLICY = 43,
    /**
     * @generated from protobuf enum value: RATE_LIMIT_CLIENT_CONFIG = 44;
     */
    RATE_LIMIT_CLIENT_CONFIG = 44,
    /**
     * @generated from protobuf enum value: HEADER_MANIPULATION_POLICY = 45;
     */
    HEADER_MANIPULATION_POLICY = 45,
    /**
     * @generated from protobuf enum value: TRANSFORMATION_POLICY = 46;
     */
    TRANSFORMATION_POLICY = 46,
    /**
     * @generated from protobuf enum value: LOAD_BALANCER_POLICY = 47;
     */
    LOAD_BALANCER_POLICY = 47,
    /**
     * @generated from protobuf enum value: PROXY_PROTOCOL_POLICY = 48;
     */
    PROXY_PROTOCOL_POLICY = 48,
    /**
     * @generated from protobuf enum value: HTTP_BUFFER_POLICY = 49;
     */
    HTTP_BUFFER_POLICY = 49,
    /**
     * @generated from protobuf enum value: GRAPH_QL_STITCHED_SCHEMA = 50;
     */
    GRAPH_QL_STITCHED_SCHEMA = 50,
    /**
     * @generated from protobuf enum value: GRAPH_QL_RESOLVER_MAP = 51;
     */
    GRAPH_QL_RESOLVER_MAP = 51,
    /**
     * @generated from protobuf enum value: GRAPH_QL_SCHEMA = 52;
     */
    GRAPH_QL_SCHEMA = 52,
    /**
     * @generated from protobuf enum value: API_DOC = 53;
     */
    API_DOC = 53,
    /**
     * @generated from protobuf enum value: PORTAL = 54;
     */
    PORTAL = 54,
    /**
     * @generated from protobuf enum value: API_PRODUCT = 55;
     */
    API_PRODUCT = 55,
    /**
     * @generated from protobuf enum value: PORTAL_GROUP = 56;
     */
    PORTAL_GROUP = 56,
    /**
     * @generated from protobuf enum value: API_SCHEMA_DISCOVERY = 57;
     */
    API_SCHEMA_DISCOVERY = 57,
    /**
     * @generated from protobuf enum value: DISCOVERED_GATEWAY = 58;
     */
    DISCOVERED_GATEWAY = 58,
    /**
     * @generated from protobuf enum value: MESH = 59;
     */
    MESH = 59,
    /**
     * @generated from protobuf enum value: DISCOVERED_CNI = 60;
     */
    DISCOVERED_CNI = 60,
    /**
     * @generated from protobuf enum value: PORTAL_CONFIG = 61;
     */
    PORTAL_CONFIG = 61,
    /**
     * @generated from protobuf enum value: SPIRE_REGISTRATION_ENTRY = 62;
     */
    SPIRE_REGISTRATION_ENTRY = 62,
    /**
     * @generated from protobuf enum value: AUTH_CONFIG = 63;
     */
    AUTH_CONFIG = 63,
    /**
     * @generated from protobuf enum value: CILIUM_NETWORK_POLICY = 64;
     */
    CILIUM_NETWORK_POLICY = 64,
    /**
     * @generated from protobuf enum value: DESTINATION_RULE = 65;
     */
    DESTINATION_RULE = 65,
    /**
     * @generated from protobuf enum value: GATEWAY = 66;
     */
    GATEWAY = 66,
    /**
     * @generated from protobuf enum value: SERVICE_ENTRY = 67;
     */
    SERVICE_ENTRY = 67,
    /**
     * @generated from protobuf enum value: WORKLOAD_ENTRY = 68;
     */
    WORKLOAD_ENTRY = 68,
    /**
     * @generated from protobuf enum value: VIRTUAL_SERVICE = 69;
     */
    VIRTUAL_SERVICE = 69,
    /**
     * @generated from protobuf enum value: SIDECAR = 70;
     */
    SIDECAR = 70,
    /**
     * @generated from protobuf enum value: ENVOY_FILTER = 71;
     */
    ENVOY_FILTER = 71,
    /**
     * @generated from protobuf enum value: AUTHORIZATION_POLICY = 72;
     */
    AUTHORIZATION_POLICY = 72,
    /**
     * @generated from protobuf enum value: PEER_AUTHENTICATION = 73;
     */
    PEER_AUTHENTICATION = 73,
    /**
     * @generated from protobuf enum value: ISTIO_OPERATOR = 74;
     */
    ISTIO_OPERATOR = 74,
    /**
     * @generated from protobuf enum value: TELEMETRY = 75;
     */
    TELEMETRY = 75,
    /**
     * @generated from protobuf enum value: SERVICE_ACCOUNT = 76;
     */
    SERVICE_ACCOUNT = 76,
    /**
     * @generated from protobuf enum value: SERVICE = 77;
     */
    SERVICE = 77,
    /**
     * @generated from protobuf enum value: NAMESPACE = 78;
     */
    NAMESPACE = 78,
    /**
     * @generated from protobuf enum value: NODE = 79;
     */
    NODE = 79,
    /**
     * @generated from protobuf enum value: DEPLOYMENT = 80;
     */
    DEPLOYMENT = 80,
    /**
     * @generated from protobuf enum value: DAEMON_SET = 81;
     */
    DAEMON_SET = 81,
    /**
     * @generated from protobuf enum value: STATEFUL_SET = 82;
     */
    STATEFUL_SET = 82,
    /**
     * @generated from protobuf enum value: ROLE = 83;
     */
    ROLE = 83,
    /**
     * @generated from protobuf enum value: ROLE_BINDING = 84;
     */
    ROLE_BINDING = 84,
    /**
     * @generated from protobuf enum value: CLUSTER_ROLE = 85;
     */
    CLUSTER_ROLE = 85,
    /**
     * @generated from protobuf enum value: CLUSTER_ROLE_BINDING = 86;
     */
    CLUSTER_ROLE_BINDING = 86,
    /**
     * @generated from protobuf enum value: NETWORK_POLICY = 87;
     */
    NETWORK_POLICY = 87,
    /**
     * @generated from protobuf enum value: CUSTOM_RESOURCE_DEFINITION = 88;
     */
    CUSTOM_RESOURCE_DEFINITION = 88,
    /**
     * @generated from protobuf enum value: K8S_GATEWAY = 89;
     */
    K8S_GATEWAY = 89,
    /**
     * @generated from protobuf enum value: GATEWAY_CLASS = 90;
     */
    GATEWAY_CLASS = 90,
    /**
     * @generated from protobuf enum value: HTTP_ROUTE = 91;
     */
    HTTP_ROUTE = 91,
    /**
     * @generated from protobuf enum value: RATE_LIMIT_CONFIG = 92;
     */
    RATE_LIMIT_CONFIG = 92
}
