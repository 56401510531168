import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SecondaryInfoStyles } from 'Components/Common/SecondaryInfo.style';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { Properties } from 'csstype';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace GqlSchemaTabStyles {
  export const TabDetailsBreadcrumbs = styled.div(
    ({ theme }) => css`
      font-size: 1.5em;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: ${theme.juneGrey};
      padding-bottom: 0.5em;
    `
  );

  export const TabDetailsBreadcrumbsButton = styled(UnstyledButton)(
    ({ theme }) => css`
      color: ${theme.seaBlue};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: ${theme.pondBlue};
      }
    `
  );

  export const TabDetailsDescription = styled.div(
    ({ theme }) => css`
      color: ${theme.juneGrey};
    `
  );

  export const TabDetailsInlineHeader = styled.h2`
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding-right: 0.75em;
  `;

  export const InfoTooltipContainer = styled.div<{ hidden: boolean }>(
    ({ hidden }) => css`
      // This will be hidden if there is no information to show.
      display: ${hidden ? 'none' : 'inline-block'};
    `
  );
  export const InfoIconContainer = styled.div`
    padding: 1px 5px 2px 5px;
    transform: translate(0, 2px);
    ${svgBasics(14)}
  `;

  export const MonospaceItem = styled.div<{
    display?: Properties['display'];
  }>(
    ({ display = 'block' }) => css`
      display: ${display};
      white-space: break-word;
      font-family: Courier, monospace;
      *:not(:last-child) {
        padding-right: 5px;
      }
    `
  );

  export const CenteredTableCell = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  `;

  export const NameLink = styled(UnstyledButton)(
    ({ theme }) => css`
      cursor: pointer;
      display: inline-block;
      padding: 5px;
      flex-grow: 0;
      white-space: nowrap;
      text-overflow: ellipses;
      color: ${theme.neptuneBlue};
      &:hover {
        color: ${theme.seaBlue};
        text-decoration: underline;
      }
      &:active {
        color: ${theme.pondBlue};
        text-decoration: underline;
      }
    `
  );

  // ---------------------------------------- //
  // Used for the resolver/type-merge buttons
  // ---------------------------------------- //
  export const GqlCheckedIconContainer = styled.div(
    ({ theme }) => css`
      svg {
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 50%;
        // Could uncomment below for active+hover states
        // if the button is clickable.
        // cursor: pointer;
        // &:hover {
        //   g {
        //     stroke: ${theme.lakeBlue};
        //   }
        //   path {
        //     fill: ${theme.lakeBlue};
        //   }
        //   &:active {
        //     outline: 1px solid ${theme.lakeBlue};
        //     outline-offset: 2px;
        //   }
        // }
      }
    `
  );
  export const GqlNoCheckedIcon = styled.div(
    ({ theme }) => css`
      width: 19px;
      height: 19px;
      border-radius: 50%;
      border: 2px solid ${theme.splashBlue};
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: ${theme.splashBlue};
      }
    `
  );
  export const TableCellTooltip = styled.div`
    font-weight: 500;
    white-space: nowrap;

    ${svgBasics('1.75em', 'white')}
    svg {
      margin-bottom: -0.5em;
    }

    ${SecondaryInfoStyles.Item},
    ${SoloLinkStyles.SoloLinkLooks},
    ${SoloLinkStyles.SoloLinkLooks}:after {
      background-color: transparent;
      color: white;
      border-color: white;
    }
  `;
}
