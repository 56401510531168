import { Insight_Data } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';

export interface InsightCodeWithDataProp<T = Insight_Data['data']['oneofKind']> extends Code {
  /**
   * The property on the Insight to find related data.
   */
  dataProp: T;
}

/**
 * This maps the group/key values from the insight code
 * to more readable keys.
 */
export interface InsightCodeMap {
  [key: string]: InsightCodeWithDataProp | Code;
}

export enum InsightCodeGroup {
  SYS = 'SYS',
  HLT = 'HLT',
  BP = 'BP',
  SEC = 'SEC',
  RTE = 'RTE',
  ING = 'ING',
  RES = 'RES',
  CFG = 'CFG'
}

export const insightCodeGroupMap: Record<InsightCodeGroup, string> = {
  SYS: 'System',
  HLT: 'Health',
  BP: 'Best Practice',
  SEC: 'Security',
  RTE: 'Routing',
  ING: 'Ingress',
  RES: 'Resiliency',
  CFG: 'Configuration'
};
