import { graphApi } from 'Api/graphs';
import { di } from 'react-magnetic-di';
import { Permission, usePermissions } from 'utils/permissions';
const { useGetFilters } = graphApi;

export const WORKSPACE_QUERY_PARAM = 'workspace';
export const NAMESPACE_QUERY_PARAM = 'namespace';
export const CLUSTER_QUERY_PARAM = 'cluster';

export type GraphFiltersType = {
  findName: string;
  hideName: string;
  workspaces: string[];
  namespaces?: string[];
  clusters?: string[];
  hideEmptyGraphFiltersMessage?: boolean;
};

// return whether enough filters are selected to display the graph
export function hasEnoughFilters(filters: GraphFiltersType): boolean {
  return !!filters.workspaces.length || (!!filters.clusters?.length && !!filters.namespaces?.length);
}

// return whether enough filters are selected to display the graph
export function useGraphWorkspacesUsable(): boolean {
  di(usePermissions, useGetFilters);
  const { hasPerm } = usePermissions();
  const { data: filters } = useGetFilters();

  return hasPerm(Permission.Workspace) && !!filters?.workspaces?.length;
}
