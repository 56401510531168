/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/type/v3/semantic_version.proto" (package "envoy.type.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
// [#protodoc-title: Semantic Version]

/**
 * Envoy uses SemVer (https://semver.org/). Major/minor versions indicate
 * expected behaviors and APIs, the patch version field is used only
 * for security fixes and can be generally ignored.
 *
 * @generated from protobuf message envoy.type.v3.SemanticVersion
 */
export interface SemanticVersion {
    /**
     * @generated from protobuf field: uint32 major_number = 1;
     */
    majorNumber: number;
    /**
     * @generated from protobuf field: uint32 minor_number = 2;
     */
    minorNumber: number;
    /**
     * @generated from protobuf field: uint32 patch = 3;
     */
    patch: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class SemanticVersion$Type extends MessageType<SemanticVersion> {
    constructor() {
        super("envoy.type.v3.SemanticVersion", [
            { no: 1, name: "major_number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "minor_number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "patch", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.type.SemanticVersion" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.type.v3.SemanticVersion
 */
export const SemanticVersion = new SemanticVersion$Type();
