import { TruncateText } from 'Styles/CommonEmotions/text';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { useState } from 'react';
import { StringListStyles as Styles } from './StringList.style';

const StringListTooltipContent = ({ list, icon }: { list: React.ReactNode[]; icon?: React.ReactNode }) => {
  return (
    <Styles.OverflowDisplay>
      {list.map((item, ind) => (
        <Styles.OverflowDisplayLine key={ind}>
          {icon} {item}
        </Styles.OverflowDisplayLine>
      ))}
    </Styles.OverflowDisplay>
  );
};

export type StringListProps = {
  list?: React.ReactNode[];
  icon?: React.ReactNode;
  smallIcons?: boolean;
  onHover?: boolean;
  iconColorUnaltered?: boolean;
  useOnlyCount?: boolean;
  truncateMaxWidth?: string;
  tooltipPlacement?: TooltipPlacement;
};
export const StringList = ({
  list,
  icon,
  smallIcons = false,
  onHover,
  iconColorUnaltered,
  useOnlyCount,
  truncateMaxWidth,
  tooltipPlacement
}: StringListProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!list?.length) {
    return null;
  }

  return (
    <Styles.ListCellHolder
      data-testid='string-list'
      multiple={list.length > 1}
      onClick={list.length > 1 && !onHover ? () => setIsOpen(true) : undefined}
      onMouseEnter={list.length > 1 && onHover ? () => setIsOpen(true) : undefined}
      onMouseLeave={list.length > 1 && onHover ? () => setIsOpen(false) : undefined}
      smallIcons={smallIcons}
      iconColorUnaltered={iconColorUnaltered}
      tabIndex={0}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}>
      {list.length === 0 ? null : list.length > 1 || useOnlyCount ? (
        <Tooltip
          title={<StringListTooltipContent list={useOnlyCount ? list : list.slice(1)} icon={icon} />}
          trigger='hover'
          placement={tooltipPlacement}
          open={isOpen}
          onOpenChange={setIsOpen}>
          {useOnlyCount ? (
            <Styles.ExpandPromptCount>View ({list.length})</Styles.ExpandPromptCount>
          ) : (
            <Styles.ExpandableList>
              {list[0]}
              {list.length > 1 && <Styles.ListExtraCount>+{list.length - 1}</Styles.ListExtraCount>}
            </Styles.ExpandableList>
          )}
        </Tooltip>
      ) : (
        <TruncateText title={list[0]} maxWidth={truncateMaxWidth ?? '150px'}>
          {list[0]}
        </TruncateText>
      )}
    </Styles.ListCellHolder>
  );
};
