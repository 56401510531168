import { GqlExplorerStyles } from './GqlExplorer.style';
import GraphiQLExplorer from 'graphiql-explorer';
import { useCallback, useEffect, useState } from 'react';
import { useEventListener } from 'utils/hooks';

const openResizeValue = 300;

/**
 * This is the left expandable sidebar of the themed Graphiql explorer card.
 * The `graphiql-explorer` npm package is used here, with style overrides
 * and resizing functionality added
 * */
const GqlExplorerExplorer = ({
  executableSchema,
  query,
  handleQueryUpdate,
  onRunOperation
}: {
  executableSchema: any;
  query: string;
  handleQueryUpdate(query: string): void;
  onRunOperation(operationName?: string): void;
}) => {
  //
  // Saves explorer state
  // This is a graphiql extension, and it isn't saved automatically.
  // Saving this to local storage prevents first render flickering - open state is actually controlled by
  // `graphiql:explorerWidth` in local storage
  //
  const [showExplorer, setShowExplorer] = useState(localStorage.getItem('graphiql:explorerOpen') === 'true');
  useEffect(() => {
    localStorage.setItem('graphiql:explorerOpen', showExplorer.toString());
  }, [showExplorer]);

  //
  // Resize state.
  //
  // If `mouseDownResizeValue === -1`, then the explorer panel is being resized
  // (and the mouse is down).
  const [mouseDownXPos, setMouseDownXPos] = useState(-1);
  const [resizeValue, setResizeValue] = useState(() => {
    const savedResizeValue = localStorage.getItem('graphiql:explorerWidth');
    if (!!savedResizeValue) return Number.parseInt(savedResizeValue);
    else return openResizeValue;
  });
  // `resizeValue` is copied to `resizeValueOnMouseDown` when there is a
  // 'mousedown` event on the resizer (used for calculating mouse movement).
  const [resizeValueOnMouseDown, setResizeValueOnMouseDown] = useState(resizeValue);

  const activelyResizing = mouseDownXPos !== -1;
  //
  // Handles resizing.
  // Saves the resize state in localStorage.
  //
  useEffect(() => {
    localStorage.setItem('graphiql:explorerWidth', resizeValue + '');
  }, [resizeValue]);
  useEventListener(
    window,
    'mousemove',
    e => {
      const mouseMovementX = e.clientX - mouseDownXPos;
      setResizeValue(resizeValueOnMouseDown + mouseMovementX);
    },
    [resizeValue, mouseDownXPos],
    !activelyResizing
  );
  useEffect(() => {
    if (resizeValue < 200) {
      setShowExplorer(false);
    } else if (resizeValue > 200) {
      setShowExplorer(true);
    }
  }, [resizeValue]);

  //
  // Stops resizing on mouseup and on mouseout.
  //
  const unsetIsResizingExplorer = useCallback(
    (_: MouseEvent) => {
      setMouseDownXPos(-1);
    },
    [setResizeValue, resizeValue, setMouseDownXPos]
  );
  const unsetIsResizingOnMouseOut = useCallback(
    (e: MouseEvent) => {
      if (e.clientY <= 0 || e.clientX <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight)
        unsetIsResizingExplorer(e);
    },
    [unsetIsResizingExplorer]
  );

  useEventListener(window, 'mouseup', unsetIsResizingExplorer, [unsetIsResizingExplorer], !activelyResizing);
  useEventListener(document, 'mouseout', unsetIsResizingOnMouseOut, [unsetIsResizingOnMouseOut], !activelyResizing);

  //
  // Render
  //
  return (
    <>
      {!showExplorer && (
        <>
          <GqlExplorerStyles.ShowExplorerButton.Container
            placement='left'
            onClick={() => {
              setShowExplorer(true);
              setResizeValue(openResizeValue);
            }}>
            <GqlExplorerStyles.ShowExplorerButton.Content>
              <GqlExplorerStyles.Arrow direction='right' />
              Explorer
            </GqlExplorerStyles.ShowExplorerButton.Content>
          </GqlExplorerStyles.ShowExplorerButton.Container>
        </>
      )}
      <GqlExplorerStyles.GraphiqlExplorerSidebarContainer resizeValue={resizeValue}>
        <GraphiQLExplorer
          schema={executableSchema}
          query={query}
          onEdit={handleQueryUpdate}
          onRunOperation={onRunOperation}
          explorerIsOpen={showExplorer}
          onToggleExplorer={() => {
            if (showExplorer) {
              setResizeValue(0);
              setShowExplorer(false);
            } else {
              setResizeValue(openResizeValue);
              setShowExplorer(true);
            }
          }}
        />
      </GqlExplorerStyles.GraphiqlExplorerSidebarContainer>
      <GqlExplorerStyles.GraphiqlExplorerSidebarResizer
        onMouseDown={e => {
          setResizeValueOnMouseDown(resizeValue);
          setMouseDownXPos(e.clientX);
        }}
      />
    </>
  );
};

export default GqlExplorerExplorer;
