import { SoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { colors } from 'Styles';
import { Asset } from 'assets';
import { Insight_Severity } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { buildInsightsUrl } from 'utils/url-builders';
import { InsightStatusCardStyles } from './InsightStatusCard.style';

export const insightStatusCardMap = {
  info: {
    title: 'Info',
    severity: Insight_Severity.INFO,
    icon: <Asset.InsightInfoIcon />,
    iconBg: colors.deepPuddleBlue,
    fontColor: colors.seaBlue,
    borderColor: colors.seaBlue,
    bgColor: colors.lightSeaBlue
  },
  warning: {
    title: 'Warning',
    severity: Insight_Severity.WARNING,
    icon: <Asset.InsightWarningIcon />,
    iconBg: colors.lampGold,
    fontColor: colors.darkSunGold,
    borderColor: colors.sunGold,
    bgColor: colors.flashlightGold
  },
  error: {
    title: 'Error',
    severity: Insight_Severity.ERROR,
    icon: <Asset.InsightErrorIcon />,
    iconBg: colors.tangerineOrange,
    fontColor: colors.grapefruitOrange,
    borderColor: colors.grapefruitOrange,
    bgColor: colors.kumquatOrange
  }
};

export const InsightStatusCard = ({
  statusType,
  value,
  clusterFilter
}: {
  statusType: keyof typeof insightStatusCardMap;
  value: number;
  clusterFilter?: string;
}) => {
  const statusCardData = insightStatusCardMap[statusType];
  return (
    <InsightStatusCardStyles.CardContainer statusType={statusType}>
      <InsightStatusCardStyles.TopIcon statusType={statusType}>
        <Svg size={26} asset={statusCardData.icon} />
      </InsightStatusCardStyles.TopIcon>
      <InsightStatusCardStyles.TextBig statusType={statusType}>{value}</InsightStatusCardStyles.TextBig>
      <InsightStatusCardStyles.TextSmall>{statusCardData.title}</InsightStatusCardStyles.TextSmall>
      <SoloLink
        small
        link={buildInsightsUrl({
          severities: [statusCardData.severity],
          clusters: clusterFilter ? [clusterFilter] : undefined
        })}
        withArrow>
        DETAILS
      </SoloLink>
    </InsightStatusCardStyles.CardContainer>
  );
};

export default InsightStatusCard;
