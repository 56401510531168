/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/observability/access_log_policy.proto" (package "observability.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { WorkloadReference } from "../../../common/v2/references_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { HeaderMatcher } from "../../../common/v2/http_matchers_pb";
import { StatusCodeMatcher } from "../../../common/v2/http_matchers_pb";
import { WorkloadSelector } from "../../../common/v2/selectors_pb";
/**
 * Specifications for the policy.
 *
 * @generated from protobuf message observability.policy.gloo.solo.io.AccessLogPolicySpec
 */
export interface AccessLogPolicySpec {
    /**
     * Select the workloads where access logs will be collected.
     * If empty, the policy applies to all workloads in the workspace.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.WorkloadSelector apply_to_workloads = 1;
     */
    applyToWorkloads: WorkloadSelector[];
    /**
     * Details of the policy specifying how to collect access logs from the selected workloads.
     *
     * @generated from protobuf field: observability.policy.gloo.solo.io.AccessLogPolicySpec.Config config = 2;
     */
    config?: AccessLogPolicySpec_Config;
}
/**
 * Details of the policy specifying how to collect access logs from the selected workloads.
 *
 * @generated from protobuf message observability.policy.gloo.solo.io.AccessLogPolicySpec.Config
 */
export interface AccessLogPolicySpec_Config {
    /**
     * Criteria for determining which access logs are recorded for the workload.
     * The list is disjunctive, meaning that a request is logged if it matches any filter.
     * If empty, all access logs are recorded.
     *
     * @generated from protobuf field: repeated observability.policy.gloo.solo.io.AccessLogPolicySpec.Config.Filter filters = 2;
     */
    filters: AccessLogPolicySpec_Config_Filter[];
    /**
     * Request headers to include in access logs.
     *
     * @generated from protobuf field: repeated string included_request_headers = 3;
     */
    includedRequestHeaders: string[];
    /**
     * Response headers to include in access logs.
     *
     * @generated from protobuf field: repeated string included_response_headers = 4;
     */
    includedResponseHeaders: string[];
    /**
     * Response trailers to include in access logs.
     *
     * @generated from protobuf field: repeated string included_response_trailers = 5;
     */
    includedResponseTrailers: string[];
    /**
     * Filter state objects to include in access logs.
     *
     * @generated from protobuf field: repeated string included_filter_state_objects = 6;
     */
    includedFilterStateObjects: string[];
}
/**
 * Criteria for recording access logs. A request must match all specified criteria in the filter to be recorded.
 *
 * @generated from protobuf message observability.policy.gloo.solo.io.AccessLogPolicySpec.Config.Filter
 */
export interface AccessLogPolicySpec_Config_Filter {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "statusCodeMatcher";
        /**
         * Matches against a response HTTP status code. Omit to match any status code.
         *
         * @generated from protobuf field: common.gloo.solo.io.StatusCodeMatcher status_code_matcher = 1;
         */
        statusCodeMatcher: StatusCodeMatcher;
    } | {
        oneofKind: "headerMatcher";
        /**
         * Matches against a request or response HTTP header. Omit to match any headers.
         *
         * @generated from protobuf field: common.gloo.solo.io.HeaderMatcher header_matcher = 2;
         */
        headerMatcher: HeaderMatcher;
    } | {
        oneofKind: undefined;
    };
}
/**
 * The status of the policy after it is applied to your Gloo environment.
 *
 * @generated from protobuf message observability.policy.gloo.solo.io.AccessLogPolicyStatus
 */
export interface AccessLogPolicyStatus {
    /**
     * The state and workspace conditions of the applied policy.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of workloads selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_workloads = 2;
     */
    numSelectedWorkloads: number;
}
/**
 * The report shows the resources that the policy selects after the policy is successfully applied.
 *
 * @generated from protobuf message observability.policy.gloo.solo.io.AccessLogPolicyReport
 */
export interface AccessLogPolicyReport {
    /**
     * A list of workspaces in which the policy can apply to workloads.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of workloads selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.WorkloadReference selected_workloads = 2;
     */
    selectedWorkloads: WorkloadReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AccessLogPolicySpec$Type extends MessageType<AccessLogPolicySpec> {
    constructor() {
        super("observability.policy.gloo.solo.io.AccessLogPolicySpec", [
            { no: 1, name: "apply_to_workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkloadSelector },
            { no: 2, name: "config", kind: "message", T: () => AccessLogPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message observability.policy.gloo.solo.io.AccessLogPolicySpec
 */
export const AccessLogPolicySpec = new AccessLogPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessLogPolicySpec_Config$Type extends MessageType<AccessLogPolicySpec_Config> {
    constructor() {
        super("observability.policy.gloo.solo.io.AccessLogPolicySpec.Config", [
            { no: 2, name: "filters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccessLogPolicySpec_Config_Filter },
            { no: 3, name: "included_request_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "included_response_headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "included_response_trailers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "included_filter_state_objects", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message observability.policy.gloo.solo.io.AccessLogPolicySpec.Config
 */
export const AccessLogPolicySpec_Config = new AccessLogPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessLogPolicySpec_Config_Filter$Type extends MessageType<AccessLogPolicySpec_Config_Filter> {
    constructor() {
        super("observability.policy.gloo.solo.io.AccessLogPolicySpec.Config.Filter", [
            { no: 1, name: "status_code_matcher", kind: "message", oneof: "type", T: () => StatusCodeMatcher },
            { no: 2, name: "header_matcher", kind: "message", oneof: "type", T: () => HeaderMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message observability.policy.gloo.solo.io.AccessLogPolicySpec.Config.Filter
 */
export const AccessLogPolicySpec_Config_Filter = new AccessLogPolicySpec_Config_Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessLogPolicyStatus$Type extends MessageType<AccessLogPolicyStatus> {
    constructor() {
        super("observability.policy.gloo.solo.io.AccessLogPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 2, name: "num_selected_workloads", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message observability.policy.gloo.solo.io.AccessLogPolicyStatus
 */
export const AccessLogPolicyStatus = new AccessLogPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessLogPolicyReport$Type extends MessageType<AccessLogPolicyReport> {
    constructor() {
        super("observability.policy.gloo.solo.io.AccessLogPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkloadReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message observability.policy.gloo.solo.io.AccessLogPolicyReport
 */
export const AccessLogPolicyReport = new AccessLogPolicyReport$Type();
