/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/approval_state.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * the state of a resource across clusters
 *
 * @generated from protobuf message common.gloo.solo.io.ClusterState
 */
export interface ClusterState {
    /**
     * the approval state of the resource in each cluster
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.ApprovalState> states = 1;
     */
    states: {
        [key: string]: ApprovalState;
    };
}
/**
 * State of a Policy resource reflected in the status by Gloo Mesh while processing a resource.
 *
 * @generated from protobuf enum common.gloo.solo.io.ApprovalState
 */
export enum ApprovalState {
    /**
     * Resources are in a Pending state before they have been processed by Gloo Mesh.
     *
     * @generated from protobuf enum value: PENDING = 0;
     */
    PENDING = 0,
    /**
     * Resources are in a Accepted state when they are valid and have been applied successfully to
     * the Gloo Mesh configuration.
     *
     * @generated from protobuf enum value: ACCEPTED = 1;
     */
    ACCEPTED = 1,
    /**
     * Resources are in an Invalid state when they contain incorrect configuration parameters,
     * such as missing required values or invalid resource references.
     * An invalid state can also result when a resource's configuration is valid
     * but conflicts with another resource which was accepted in an earlier point in time.
     *
     * @generated from protobuf enum value: INVALID = 2;
     */
    INVALID = 2,
    /**
     * Resources are in a Warning state when they contain partially incorrect configuration parameters,
     * but has still been processed and applied by the system.
     *
     * @generated from protobuf enum value: WARNING = 3;
     */
    WARNING = 3,
    /**
     * Resources are in a Failed state when they contain correct configuration parameters,
     * but the server encountered an error trying to synchronize the system to
     * the desired state.
     *
     * @generated from protobuf enum value: FAILED = 4;
     */
    FAILED = 4,
    /**
     * The license key(s) in the application do not allow you to use this resource.
     *
     * @generated from protobuf enum value: UNLICENSED = 5;
     */
    UNLICENSED = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class ClusterState$Type extends MessageType<ClusterState> {
    constructor() {
        super("common.gloo.solo.io.ClusterState", [
            { no: 1, name: "states", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "enum", T: () => ["common.gloo.solo.io.ApprovalState", ApprovalState] } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ClusterState
 */
export const ClusterState = new ClusterState$Type();
