/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/config/core/v3/address.proto" (package "envoy.config.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { SocketOption } from "./socket_option_pb";
import { BoolValue } from "../../../../google/protobuf/wrappers_pb";
import { UInt32Value } from "../../../../google/protobuf/wrappers_pb";
// [#protodoc-title: Network addresses]

/**
 * @generated from protobuf message envoy.config.core.v3.Pipe
 */
export interface Pipe {
    /**
     * Unix Domain Socket path. On Linux, paths starting with '@' will use the
     * abstract namespace. The starting '@' is replaced by a null byte by Envoy.
     * Paths starting with '@' will result in an error in environments other than
     * Linux.
     *
     * @generated from protobuf field: string path = 1;
     */
    path: string;
    /**
     * The mode for the Pipe. Not applicable for abstract sockets.
     *
     * @generated from protobuf field: uint32 mode = 2;
     */
    mode: number;
}
/**
 * [#not-implemented-hide:] The address represents an envoy internal listener.
 * TODO(lambdai): Make this address available for listener and endpoint.
 * TODO(asraa): When address available, remove workaround from test/server/server_fuzz_test.cc:30.
 *
 * @generated from protobuf message envoy.config.core.v3.EnvoyInternalAddress
 */
export interface EnvoyInternalAddress {
    /**
     * @generated from protobuf oneof: address_name_specifier
     */
    addressNameSpecifier: {
        oneofKind: "serverListenerName";
        /**
         * [#not-implemented-hide:] The :ref:`listener name <envoy_api_field_config.listener.v3.Listener.name>` of the destination internal listener.
         *
         * @generated from protobuf field: string server_listener_name = 1;
         */
        serverListenerName: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * [#next-free-field: 7]
 *
 * @generated from protobuf message envoy.config.core.v3.SocketAddress
 */
export interface SocketAddress {
    /**
     * @generated from protobuf field: envoy.config.core.v3.SocketAddress.Protocol protocol = 1;
     */
    protocol: SocketAddress_Protocol;
    /**
     * The address for this socket. :ref:`Listeners <config_listeners>` will bind
     * to the address. An empty address is not allowed. Specify ``0.0.0.0`` or ``::``
     * to bind to any address. [#comment:TODO(zuercher) reinstate when implemented:
     * It is possible to distinguish a Listener address via the prefix/suffix matching
     * in :ref:`FilterChainMatch <envoy_api_msg_config.listener.v3.FilterChainMatch>`.] When used
     * within an upstream :ref:`BindConfig <envoy_api_msg_config.core.v3.BindConfig>`, the address
     * controls the source address of outbound connections. For :ref:`clusters
     * <envoy_api_msg_config.cluster.v3.Cluster>`, the cluster type determines whether the
     * address must be an IP (*STATIC* or *EDS* clusters) or a hostname resolved by DNS
     * (*STRICT_DNS* or *LOGICAL_DNS* clusters). Address resolution can be customized
     * via :ref:`resolver_name <envoy_api_field_config.core.v3.SocketAddress.resolver_name>`.
     *
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf oneof: port_specifier
     */
    portSpecifier: {
        oneofKind: "portValue";
        /**
         * @generated from protobuf field: uint32 port_value = 3;
         */
        portValue: number;
    } | {
        oneofKind: "namedPort";
        /**
         * This is only valid if :ref:`resolver_name
         * <envoy_api_field_config.core.v3.SocketAddress.resolver_name>` is specified below and the
         * named resolver is capable of named port resolution.
         *
         * @generated from protobuf field: string named_port = 4;
         */
        namedPort: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * The name of the custom resolver. This must have been registered with Envoy. If
     * this is empty, a context dependent default applies. If the address is a concrete
     * IP address, no resolution will occur. If address is a hostname this
     * should be set for resolution other than DNS. Specifying a custom resolver with
     * *STRICT_DNS* or *LOGICAL_DNS* will generate an error at runtime.
     *
     * @generated from protobuf field: string resolver_name = 5;
     */
    resolverName: string;
    /**
     * When binding to an IPv6 address above, this enables `IPv4 compatibility
     * <https://tools.ietf.org/html/rfc3493#page-11>`_. Binding to ``::`` will
     * allow both IPv4 and IPv6 connections, with peer IPv4 addresses mapped into
     * IPv6 space as ``::FFFF:<IPv4-address>``.
     *
     * @generated from protobuf field: bool ipv4_compat = 6;
     */
    ipv4Compat: boolean;
}
/**
 * @generated from protobuf enum envoy.config.core.v3.SocketAddress.Protocol
 */
export enum SocketAddress_Protocol {
    /**
     * @generated from protobuf enum value: TCP = 0;
     */
    TCP = 0,
    /**
     * @generated from protobuf enum value: UDP = 1;
     */
    UDP = 1
}
/**
 * @generated from protobuf message envoy.config.core.v3.TcpKeepalive
 */
export interface TcpKeepalive {
    /**
     * Maximum number of keepalive probes to send without response before deciding
     * the connection is dead. Default is to use the OS level configuration (unless
     * overridden, Linux defaults to 9.)
     *
     * @generated from protobuf field: google.protobuf.UInt32Value keepalive_probes = 1;
     */
    keepaliveProbes?: UInt32Value;
    /**
     * The number of seconds a connection needs to be idle before keep-alive probes
     * start being sent. Default is to use the OS level configuration (unless
     * overridden, Linux defaults to 7200s (i.e., 2 hours.)
     *
     * @generated from protobuf field: google.protobuf.UInt32Value keepalive_time = 2;
     */
    keepaliveTime?: UInt32Value;
    /**
     * The number of seconds between keep-alive probes. Default is to use the OS
     * level configuration (unless overridden, Linux defaults to 75s.)
     *
     * @generated from protobuf field: google.protobuf.UInt32Value keepalive_interval = 3;
     */
    keepaliveInterval?: UInt32Value;
}
/**
 * @generated from protobuf message envoy.config.core.v3.BindConfig
 */
export interface BindConfig {
    /**
     * The address to bind to when creating a socket.
     *
     * @generated from protobuf field: envoy.config.core.v3.SocketAddress source_address = 1;
     */
    sourceAddress?: SocketAddress;
    /**
     * Whether to set the *IP_FREEBIND* option when creating the socket. When this
     * flag is set to true, allows the :ref:`source_address
     * <envoy_api_field_config.cluster.v3.UpstreamBindConfig.source_address>` to be an IP address
     * that is not configured on the system running Envoy. When this flag is set
     * to false, the option *IP_FREEBIND* is disabled on the socket. When this
     * flag is not set (default), the socket is not modified, i.e. the option is
     * neither enabled nor disabled.
     *
     * @generated from protobuf field: google.protobuf.BoolValue freebind = 2;
     */
    freebind?: BoolValue;
    /**
     * Additional socket options that may not be present in Envoy source code or
     * precompiled binaries.
     *
     * @generated from protobuf field: repeated envoy.config.core.v3.SocketOption socket_options = 3;
     */
    socketOptions: SocketOption[];
}
/**
 * Addresses specify either a logical or physical address and port, which are
 * used to tell Envoy where to bind/listen, connect to upstream and find
 * management servers.
 *
 * @generated from protobuf message envoy.config.core.v3.Address
 */
export interface Address {
    /**
     * @generated from protobuf oneof: address
     */
    address: {
        oneofKind: "socketAddress";
        /**
         * @generated from protobuf field: envoy.config.core.v3.SocketAddress socket_address = 1;
         */
        socketAddress: SocketAddress;
    } | {
        oneofKind: "pipe";
        /**
         * @generated from protobuf field: envoy.config.core.v3.Pipe pipe = 2;
         */
        pipe: Pipe;
    } | {
        oneofKind: "envoyInternalAddress";
        /**
         * [#not-implemented-hide:]
         *
         * @generated from protobuf field: envoy.config.core.v3.EnvoyInternalAddress envoy_internal_address = 3;
         */
        envoyInternalAddress: EnvoyInternalAddress;
    } | {
        oneofKind: undefined;
    };
}
/**
 * CidrRange specifies an IP Address and a prefix length to construct
 * the subnet mask for a `CIDR <https://tools.ietf.org/html/rfc4632>`_ range.
 *
 * @generated from protobuf message envoy.config.core.v3.CidrRange
 */
export interface CidrRange {
    /**
     * IPv4 or IPv6 address, e.g. ``192.0.0.0`` or ``2001:db8::``.
     *
     * @generated from protobuf field: string address_prefix = 1;
     */
    addressPrefix: string;
    /**
     * Length of prefix, e.g. 0, 32.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value prefix_len = 2;
     */
    prefixLen?: UInt32Value;
}
// @generated message type with reflection information, may provide speed optimized methods
class Pipe$Type extends MessageType<Pipe> {
    constructor() {
        super("envoy.config.core.v3.Pipe", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "mode", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { lte: 511 } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.Pipe" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.Pipe
 */
export const Pipe = new Pipe$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnvoyInternalAddress$Type extends MessageType<EnvoyInternalAddress> {
    constructor() {
        super("envoy.config.core.v3.EnvoyInternalAddress", [
            { no: 1, name: "server_listener_name", kind: "scalar", oneof: "addressNameSpecifier", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.EnvoyInternalAddress
 */
export const EnvoyInternalAddress = new EnvoyInternalAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SocketAddress$Type extends MessageType<SocketAddress> {
    constructor() {
        super("envoy.config.core.v3.SocketAddress", [
            { no: 1, name: "protocol", kind: "enum", T: () => ["envoy.config.core.v3.SocketAddress.Protocol", SocketAddress_Protocol], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 3, name: "port_value", kind: "scalar", oneof: "portSpecifier", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { lte: 65535 } } } },
            { no: 4, name: "named_port", kind: "scalar", oneof: "portSpecifier", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "resolver_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "ipv4_compat", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.SocketAddress" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.SocketAddress
 */
export const SocketAddress = new SocketAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TcpKeepalive$Type extends MessageType<TcpKeepalive> {
    constructor() {
        super("envoy.config.core.v3.TcpKeepalive", [
            { no: 1, name: "keepalive_probes", kind: "message", T: () => UInt32Value },
            { no: 2, name: "keepalive_time", kind: "message", T: () => UInt32Value },
            { no: 3, name: "keepalive_interval", kind: "message", T: () => UInt32Value }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.TcpKeepalive" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.TcpKeepalive
 */
export const TcpKeepalive = new TcpKeepalive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BindConfig$Type extends MessageType<BindConfig> {
    constructor() {
        super("envoy.config.core.v3.BindConfig", [
            { no: 1, name: "source_address", kind: "message", T: () => SocketAddress, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "freebind", kind: "message", T: () => BoolValue },
            { no: 3, name: "socket_options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SocketOption }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.BindConfig" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.BindConfig
 */
export const BindConfig = new BindConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Address$Type extends MessageType<Address> {
    constructor() {
        super("envoy.config.core.v3.Address", [
            { no: 1, name: "socket_address", kind: "message", oneof: "address", T: () => SocketAddress },
            { no: 2, name: "pipe", kind: "message", oneof: "address", T: () => Pipe },
            { no: 3, name: "envoy_internal_address", kind: "message", oneof: "address", T: () => EnvoyInternalAddress }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.Address" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.Address
 */
export const Address = new Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CidrRange$Type extends MessageType<CidrRange> {
    constructor() {
        super("envoy.config.core.v3.CidrRange", [
            { no: 1, name: "address_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "prefix_len", kind: "message", T: () => UInt32Value, options: { "validate.rules": { uint32: { lte: 128 } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.CidrRange" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.CidrRange
 */
export const CidrRange = new CidrRange$Type();
