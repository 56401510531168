import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { InsightsOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ElementRef, useEffect, useRef } from 'react';
import { formatLargeNumberNicely } from 'utils/helpers';
import { AnalysisAndInsightsStyles } from '../AnalysisAndInsights.style';
import { renderRadar } from './render-radar';

export const ResourcesScannedChart = ({ data }: { data: InsightsOverview }) => {
  //
  // Set up canvas
  //
  const resourcesScannedChart = useRef<ElementRef<'canvas'>>(null);
  useEffect(() => {
    if (!resourcesScannedChart.current) {
      return;
    }
    const canvas = resourcesScannedChart.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    //
    // Change `isAnimated` to toggle animation on/off.
    const isAnimated = false;
    //
    // Render the radar initially.
    renderRadar(ctx, isAnimated);
    // Then set up an interval if animated.
    if (isAnimated) {
      const framesPerSecond = 60;
      const interval = setInterval(() => {
        renderRadar(ctx, isAnimated);
      }, 1000 / framesPerSecond);
      return () => {
        clearInterval(interval);
      };
    }
  }, [resourcesScannedChart.current]);

  const resourcesScannedTotal = formatLargeNumberNicely(data.resourcesScanned);

  //
  // Render
  //
  return (
    <Spacer ml={'5px'} mb={'15px'}>
      <AnalysisAndInsightsStyles.ChartAndLegendOuterContainer>
        <FlexLayout alignItems='center' justifyContent='center' flexDirection='column' gap={2}>
          <AnalysisAndInsightsStyles.ResourcesScannedContainer>
            <canvas width={100} height={100} id='resources-scanned' ref={resourcesScannedChart} />
            <AnalysisAndInsightsStyles.ChartTextContainer>
              <Spacer mt='-10px' ml='-5px'>
                <AnalysisAndInsightsStyles.ChartBigText data-testid='resources-scanned-count'>
                  {resourcesScannedTotal}
                </AnalysisAndInsightsStyles.ChartBigText>
              </Spacer>
            </AnalysisAndInsightsStyles.ChartTextContainer>
          </AnalysisAndInsightsStyles.ResourcesScannedContainer>
          <AnalysisAndInsightsStyles.ResourcesScannedLabel>
            <span>Resources</span> <span>Scanned</span>
          </AnalysisAndInsightsStyles.ResourcesScannedLabel>
        </FlexLayout>
      </AnalysisAndInsightsStyles.ChartAndLegendOuterContainer>
    </Spacer>
  );
};
