import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { insightStatusCardMap } from './InsightStatusCard';

export namespace InsightStatusCardStyles {
  export const CardContainer = styled.div<{ statusType: keyof typeof insightStatusCardMap }>(
    ({ statusType }) => css`
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3px;
      height: 110px;
      border: 1px solid ${insightStatusCardMap[statusType].borderColor};
      border-radius: 5px;
      background-color: ${insightStatusCardMap[statusType].bgColor};
      margin: 10px;
      padding: 20px;
      margin-top: 20px;
      padding-top: 35px;
      ${SoloLinkStyles.SoloLinkLooks} {
        color: ${insightStatusCardMap[statusType].fontColor};
      }
    `
  );

  export const TopIcon = styled.div<{ statusType: keyof typeof insightStatusCardMap }>(
    ({ statusType }) => css`
      position: absolute;
      top: -18px; // size/2 + total padding/2
      padding: 5px;
      border: 1px solid ${insightStatusCardMap[statusType].borderColor};
      background-color: ${insightStatusCardMap[statusType].iconBg};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    `
  );

  export const TextBig = styled.div<{ statusType: keyof typeof insightStatusCardMap }>(
    ({ statusType }) => css`
      font-size: 34px;
      color: ${insightStatusCardMap[statusType].fontColor};
      margin-bottom: 5px;
    `
  );

  export const TextSmall = styled.div(
    ({ theme }) => css`
      font-size: 18px;
      color: ${theme.novemberGrey};
    `
  );
}
