import { InputValueDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { getArgumentDefaultValue } from 'utils/graphql-helpers';
import { GqlSchemaTabItemProps } from 'utils/graphql-schema-search-helpers';
import FieldTypeValue from '../../FieldTypeValue';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';

const GqlInputValueDefinitionItem = ({
  onTypeClick,
  focusedItem,
  focusedPath,
  tabHeader
}: GqlSchemaTabItemProps<InputValueDefinitionNode>) => {
  const defaultValue = getArgumentDefaultValue(focusedItem);
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectiveList node={focusedItem} onTypeClick={onTypeClick} />
      <GqlTabItemDescription node={focusedItem} />
      <Spacer display='block'>
        <GqlSchemaTabStyles.TabDetailsInlineHeader>Return Type:</GqlSchemaTabStyles.TabDetailsInlineHeader>
        <FieldTypeValue onTypeClick={onTypeClick} field={focusedItem.type} />
      </Spacer>
      {!!defaultValue && (
        <Spacer display='block' pt={2}>
          <GqlSchemaTabStyles.TabDetailsInlineHeader>Default Value:</GqlSchemaTabStyles.TabDetailsInlineHeader>
          {defaultValue}
        </Spacer>
      )}
    </>
  );
};

export default GqlInputValueDefinitionItem;
