import { degreesToRadians, setupCanvasScreenResolutionScaling } from 'utils/dashboard/dashboard-helpers';

//
// Constants
//
const speed = 0.05;
const lineWidth = 2;
const halfLineWidth = lineWidth / 2;
const TWO_PI = Math.PI * 2;

export const renderRadar = (ctx: CanvasRenderingContext2D, isAnimated: boolean) => {
  //
  // 1. Scale canvas so it looks higher resolution
  setupCanvasScreenResolutionScaling(ctx);
  //
  // 2. Set up variables
  const angle = isAnimated ? (new Date().getTime() * speed) % 360 : 315;
  const diameter = ctx.canvas.getBoundingClientRect().width;
  const radius = diameter / 2;
  const centerX = radius - halfLineWidth;
  const centerY = radius - halfLineWidth;
  //
  // 3. Draw inner rings
  ctx.strokeStyle = '#e9edf1';
  ctx.lineWidth = 1;
  const numRings = 4;
  for (let i = 0; i < numRings - 1; i++) {
    ctx.beginPath();
    ctx.arc(radius, radius, (centerX / numRings) * (i + 1), 0, TWO_PI);
    ctx.stroke();
  }
  //
  // 4. Draw target
  ctx.beginPath();
  ctx.moveTo(centerX, lineWidth);
  ctx.lineTo(centerX, diameter - lineWidth);
  ctx.moveTo(lineWidth, centerY);
  ctx.lineTo(diameter - lineWidth, centerY);
  ctx.stroke();
  //
  // 5. Draw the sweep line gradient
  //
  // The gradient goes from the two points [gx1,gx1] to [gx2,gy2].
  // And is translated to the center of the circle with ctx.translate.
  // See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/createLinearGradient
  const gradientDistOut = radius / 2;
  const gx1 = gradientDistOut * Math.cos(degreesToRadians(angle));
  const gy1 = gradientDistOut * Math.sin(degreesToRadians(angle));
  const gx2 = gradientDistOut * Math.cos(degreesToRadians(angle - 45));
  const gy2 = gradientDistOut * Math.sin(degreesToRadians(angle - 45));
  const gradient = ctx.createLinearGradient(gx1, gy1, gx2, gy2);
  gradient.addColorStop(0, '#d3e7f0aa');
  gradient.addColorStop(0.8, '#d3e7f000');
  ctx.save();
  ctx.translate(centerX, centerY);
  // These rectangles can be used to debug the gradient.
  // ctx.fillStyle = '#f03';
  // ctx.fillRect(gx1, gy1, 2, 2);
  // ctx.fillRect(gx2, gy2, 2, 2);
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.arc(0, 0, radius - lineWidth, degreesToRadians(angle), degreesToRadians(angle) - Math.PI / 4, true);
  ctx.closePath();
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
  //
  // 6. Draw a sweep line over the gradient
  ctx.beginPath();
  ctx.moveTo(centerX, centerY);
  ctx.lineTo(
    centerX + (radius - lineWidth) * Math.cos(degreesToRadians(angle)),
    centerY + (radius - lineWidth) * Math.sin(degreesToRadians(angle))
  );
  ctx.stroke();
  //
  // 7. Draw outer ring last
  ctx.strokeStyle = '#325476';
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius - lineWidth, 0, TWO_PI);
  ctx.stroke();
};
