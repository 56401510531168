import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace GraphFetchSettingsStyles {
  export const FetchSettings = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
  `;

  export const FetchLabelDropdownPair = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;

    label {
      margin-right: 8px;
    }
  `;

  export const ManualRefreshButton = styled(SoloButton)(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      padding: 5px 10px;
      min-width: auto;
      margin-left: 10px;

      ${svgBasics(17, 'currentColor')}
      svg {
        margin-right: 4px;
      }
    `
  );
}
