import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { Loading } from 'Components/Common/Loading';
import { Svg } from 'Components/Common/Svg';
import { useGetGraphCache } from 'Components/Features/Graph/General/graph-cache';
import { useContext } from 'react';
import { di } from 'react-magnetic-di';
import { useDataPropOfFirstInsight, useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { DashboardContext } from '../../DashboardContext';
import { DashboardStyles } from '../../DashboardLanding.style';
import { clusterServicesCodesMap, ClusterServicesKey } from '../ClusterServices/insight-codes';
import ZeroTrustCardContent from './ZeroTrustCardContent';

const clusterServicesDataProp = clusterServicesCodesMap[ClusterServicesKey.clusterServicesSummary].dataProp;

const ZeroTrustCard = () => {
  di(useGetGraphCache);
  const { cache } = useGetGraphCache();
  const { dashboardCardsData } = useContext(DashboardContext);

  // Check if there are any ambient workloads
  const insights = useFilteredDashboardInsights(clusterServicesCodesMap, ClusterServicesKey.clusterServicesSummary);
  const clusterServicesSummaryData = useDataPropOfFirstInsight(clusterServicesDataProp, insights);

  //
  // Render
  //
  return (
    <Tooltip
      title={`Polled across your Gloo environment every ${
        cache.timeInterval === 60 ? '1 minute' : `${cache.timeInterval / 60} minutes`
      } You can change this interval on the Service or Observability pages.`}
      placement='top'
      trigger='hover'>
      <CardStyles.Card data-testid='zero-trust-card'>
        <DashboardStyles.CardColumn>
          <DashboardStyles.Header>
            <Svg asset={<Asset.ZeroTrustIcon />} color={theme => theme.neptuneBlue} />
            Zero Trust
          </DashboardStyles.Header>
          {dashboardCardsData === undefined ? (
            <Loading />
          ) : (
            <ZeroTrustCardContent
              zeroTrustOverview={dashboardCardsData?.zeroTrustOverview}
              timeInterval={cache.timeInterval}
              clusterServicesSummary={clusterServicesSummaryData}
            />
          )}
        </DashboardStyles.CardColumn>
      </CardStyles.Card>
    </Tooltip>
  );
};

export default ZeroTrustCard;
