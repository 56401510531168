import { RpcError } from "@protobuf-ts/runtime-rpc";
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { DataError } from '../DataError';
import { Loading } from '../Loading';
import { PagingProps } from '../SoloPagination';
import { LandingBodyStyles } from './LandingBody.style';

//
// Used for Grid+Table LandingBody components.
// Base props are just for what is needed to render them.
//
export interface BaseLandingBodyProps<ItemType, ExtraPropsType = undefined> {
  items: ItemType[];
  pagingData: PagingProps;
  // Optionally passing in extra props like this isn't ideal, since
  // it doesn't infer that they are not undefined when extraProps are passed in).
  // But it's simpler and more readable than trying to do conditional
  // types that work with within the existing LandingBody framework here.
  // This can be replaced if there's a better way to do this though.
  extraProps?: ExtraPropsType;
}

interface LandingBodyEmptyProps {
  filtersOn: boolean;
  resourceNamePlural: string;
  icon: JSX.Element;
  docsLink: string | false; // 'false' instead of making it optional as not having a link should be purposeful
  'data-testid': string;
  overrideTitleString?: string;
  overrideInitialSuggestionString?: string;
}

//
// Used for custom LandingBody components.
//
export interface CustomLandingBodyProps<ItemType, ExtraPropsType = undefined>
  extends BaseLandingBodyProps<ItemType, ExtraPropsType>,
  LandingBodyEmptyProps {
  filtersOn: boolean;
  isLoading: boolean;
  itemsError: Partial<RpcError> | undefined;
  isTable: boolean;
}

interface LandingBodyPropsWithGridAndTable<ItemType, ExtraPropsType = undefined>
  extends CustomLandingBodyProps<ItemType, ExtraPropsType> {
  GridComponent: ((props: BaseLandingBodyProps<ItemType, ExtraPropsType>) => JSX.Element) | undefined;
  TableComponent: (props: BaseLandingBodyProps<ItemType, ExtraPropsType>) => JSX.Element;
  extraProps?: ExtraPropsType;
}

//
// Used to render the empty state
//
const LandingBodyEmpty = ({
  filtersOn,
  resourceNamePlural,
  icon,
  docsLink,
  'data-testid': dataTestId,
  overrideTitleString,
  overrideInitialSuggestionString
}: LandingBodyEmptyProps) => {
  return (
    <LandingBodyStyles.EmptyContainer data-testid={dataTestId}>
      <LandingBodyStyles.EmptyIconContainer>{icon}</LandingBodyStyles.EmptyIconContainer>
      {!filtersOn ? (
        <>
          <LandingBodyStyles.Header>
            {!!overrideTitleString ? overrideTitleString : <>No {resourceNamePlural} Found</>}
          </LandingBodyStyles.Header>
          {!!overrideInitialSuggestionString ? (
            <>
              <div>{overrideInitialSuggestionString}</div>
              {!!docsLink && (
                <div>
                  <ExternalSoloLink href={docsLink} newTab>
                    <SoloButton>VIEW DOCS</SoloButton>
                  </ExternalSoloLink>
                </div>
              )}
            </>
          ) : (
            !!docsLink && (
              <>
                <div>Start creating {resourceNamePlural} by following the instructions in the documentation.</div>
                <div>
                  <ExternalSoloLink href={docsLink} newTab>
                    <SoloButton>VIEW DOCS</SoloButton>
                  </ExternalSoloLink>
                </div>
              </>
            )
          )}
        </>
      ) : (
        <>
          <LandingBodyStyles.Header>No {resourceNamePlural} Found</LandingBodyStyles.Header>
          <div>No {resourceNamePlural} were found that match the search criteria.</div>
        </>
      )}
    </LandingBodyStyles.EmptyContainer>
  );
};

//
// Main component
// Used to render the landing page body.
//
export function LandingBody<T, ExtraPropsType>(props: LandingBodyPropsWithGridAndTable<T, ExtraPropsType>) {
  const { items, isLoading, itemsError, pagingData, isTable, GridComponent, TableComponent } = props;
  //
  // Check for errors, empty, and loading states
  if (!!itemsError) {
    return <DataError error={itemsError} />;
  }
  if (isLoading) {
    return <Loading />;
  }
  if (pagingData.total === 0) {
    return <LandingBodyEmpty {...props} />;
  }
  if (!items) {
    return null;
  }
  //
  // Then either render the table or the grid.
  if (!GridComponent) {
    return <TableComponent {...props} />;
  }
  return isTable ? <TableComponent {...props} /> : <GridComponent {...props} />;
}
