import { InputObjectTypeDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlInputTabItem from './GqlInputTabItem';
import GqlInputTabTable from './GqlInputTabTable';

const GqlInputTab = ({
  definitions,
  onTypeClick,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<InputObjectTypeDefinitionNode>) => {
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={5}>
        <GqlDetailsStyles.LightText>Provide hierarchical data as arguments to fields.</GqlDetailsStyles.LightText>
      </Spacer>

      {!!focusedItem && (
        <GqlInputTabItem
          focusedPath={focusedPath}
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlInputTabTable
        tabHeader={tabHeader}
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
      />
    </>
  );
};

export default GqlInputTab;
