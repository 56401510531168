/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/portal.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PortalApi } from "./portal_pb";
import type { GetPortalDetailsResponse } from "./portal_pb";
import type { GetPortalDetailsRequest } from "./portal_pb";
import type { GetPortalApiDetailsResponse } from "./portal_pb";
import type { GetPortalApiDetailsRequest } from "./portal_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSchemaResponse } from "./portal_pb";
import type { GetSchemaRequest } from "./portal_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Apis for the Portal API details page
 *
 * @generated from protobuf service rpc.gloo.solo.io.PortalApi
 */
export interface IPortalApiClient {
    /**
     * @generated from protobuf rpc: GetSchema(rpc.gloo.solo.io.GetSchemaRequest) returns (rpc.gloo.solo.io.GetSchemaResponse);
     */
    getSchema(input: GetSchemaRequest, options?: RpcOptions): UnaryCall<GetSchemaRequest, GetSchemaResponse>;
    /**
     * @generated from protobuf rpc: GetPortalApiDetails(rpc.gloo.solo.io.GetPortalApiDetailsRequest) returns (rpc.gloo.solo.io.GetPortalApiDetailsResponse);
     */
    getPortalApiDetails(input: GetPortalApiDetailsRequest, options?: RpcOptions): UnaryCall<GetPortalApiDetailsRequest, GetPortalApiDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetPortalDetails(rpc.gloo.solo.io.GetPortalDetailsRequest) returns (rpc.gloo.solo.io.GetPortalDetailsResponse);
     */
    getPortalDetails(input: GetPortalDetailsRequest, options?: RpcOptions): UnaryCall<GetPortalDetailsRequest, GetPortalDetailsResponse>;
}
/**
 * Apis for the Portal API details page
 *
 * @generated from protobuf service rpc.gloo.solo.io.PortalApi
 */
export class PortalApiClient implements IPortalApiClient, ServiceInfo {
    typeName = PortalApi.typeName;
    methods = PortalApi.methods;
    options = PortalApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSchema(rpc.gloo.solo.io.GetSchemaRequest) returns (rpc.gloo.solo.io.GetSchemaResponse);
     */
    getSchema(input: GetSchemaRequest, options?: RpcOptions): UnaryCall<GetSchemaRequest, GetSchemaResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSchemaRequest, GetSchemaResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPortalApiDetails(rpc.gloo.solo.io.GetPortalApiDetailsRequest) returns (rpc.gloo.solo.io.GetPortalApiDetailsResponse);
     */
    getPortalApiDetails(input: GetPortalApiDetailsRequest, options?: RpcOptions): UnaryCall<GetPortalApiDetailsRequest, GetPortalApiDetailsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPortalApiDetailsRequest, GetPortalApiDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPortalDetails(rpc.gloo.solo.io.GetPortalDetailsRequest) returns (rpc.gloo.solo.io.GetPortalDetailsResponse);
     */
    getPortalDetails(input: GetPortalDetailsRequest, options?: RpcOptions): UnaryCall<GetPortalDetailsRequest, GetPortalDetailsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPortalDetailsRequest, GetPortalDetailsResponse>("unary", this._transport, method, opt, input);
    }
}
