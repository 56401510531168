import { ErrorBoundaryCard } from 'Components/Common/ErrorBoundary';
import { Col, Row } from 'antd';
import { AnalysisAndInsightsCardFakeData } from './Cards/AnalysisAndInsights/AnalysisAndInsightsCardFakeData';
import { CertificatesExpiryCardFakeData } from './Cards/CertificatesExpiry/CertificatesExpiryCardFakeData';
import { ClusterServicesCardFakeData } from './Cards/ClusterServices/ClusterServicesCardFakeData';
import { FIPSCardFakeData } from './Cards/FIPS/FIPSCardFakeData';
import { DashboardHealthCardFakeData } from './Cards/Health/DashboardHealthCardFakeData';
import { ZeroTrustCardFakeData } from './Cards/ZeroTrust/ZeroTrustCardFakeData';
import { DashboardStyles } from './DashboardLanding.style';
import { DashboardLandingEmptyModal } from './DashboardLandingEmptyModal';

export const DashboardLandingEmpty = ({ isInsightsEngineRunning }: { isInsightsEngineRunning: boolean }) => {
  return (
    <DashboardStyles.LandingContainerEmptyWrapper>
      <DashboardStyles.LandingContainerEmpty data-testid='dashboard-landing'>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24}>
            <DashboardStyles.LandingCardContainer>
              <ErrorBoundaryCard fallback='There was an error showing analysis and insights information...'>
                <AnalysisAndInsightsCardFakeData />
              </ErrorBoundaryCard>
            </DashboardStyles.LandingCardContainer>
          </Col>
          <Col xs={24} xl={12}>
            <DashboardStyles.LandingCardContainer>
              <ErrorBoundaryCard fallback='There was an error showing Istio and Cilium health...'>
                <DashboardHealthCardFakeData />
              </ErrorBoundaryCard>
            </DashboardStyles.LandingCardContainer>
          </Col>
          <Col xs={24} xl={12}>
            <DashboardStyles.LandingCardContainer>
              <ErrorBoundaryCard fallback='There was an error showing certificates expiration information...'>
                <CertificatesExpiryCardFakeData />
              </ErrorBoundaryCard>
            </DashboardStyles.LandingCardContainer>
          </Col>
          <Col xs={24} lg={24} xl={10}>
            <DashboardStyles.LandingCardContainer>
              <ErrorBoundaryCard fallback='There was an error showing cluster services information...'>
                <ClusterServicesCardFakeData />
              </ErrorBoundaryCard>
            </DashboardStyles.LandingCardContainer>
          </Col>
          <Col xs={24} lg={12} xl={7}>
            <DashboardStyles.LandingCardContainer>
              <ErrorBoundaryCard fallback='There was an error showing FIPS information...'>
                <FIPSCardFakeData />
              </ErrorBoundaryCard>
            </DashboardStyles.LandingCardContainer>
          </Col>
          <Col xs={24} lg={12} xl={7}>
            <DashboardStyles.LandingCardContainer>
              <ErrorBoundaryCard fallback='There was an error showing zero trust information...'>
                <ZeroTrustCardFakeData />
              </ErrorBoundaryCard>
            </DashboardStyles.LandingCardContainer>
          </Col>
        </Row>
      </DashboardStyles.LandingContainerEmpty>
      <DashboardLandingEmptyModal isInsightsEngineRunning={isInsightsEngineRunning} />
    </DashboardStyles.LandingContainerEmptyWrapper>
  );
};
