import { DirectiveDefinitionNode } from 'graphql';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { GqlSchemaTabProps, useGetFocusedItem } from 'utils/graphql-schema-search-helpers';
import { GqlDetailsStyles } from '../../../GqlDetails.style';
import GqlDirectiveTabItem from './GqlDirectiveTabItem';
import GqlDirectiveTabTable from './GqlDirectiveTabTable';

const GqlDirectiveTab = ({
  definitions,
  onTypeClick,
  focusedPath,
  tabHeader
}: GqlSchemaTabProps<DirectiveDefinitionNode>) => {
  const focusedItem = useGetFocusedItem(definitions, focusedPath);

  //
  // Render
  //
  return (
    <>
      <Spacer mb={5}>
        <GqlDetailsStyles.LightText>
          Affect the execution of the query by providing additional configuration to part of a schema or operation.
        </GqlDetailsStyles.LightText>
      </Spacer>

      {!!focusedItem && (
        <GqlDirectiveTabItem
          focusedPath={focusedPath}
          tabHeader={tabHeader}
          focusedItem={focusedItem}
          onTypeClick={onTypeClick}
        />
      )}

      <GqlDirectiveTabTable
        hidden={!!focusedItem}
        definitions={definitions}
        onTypeClick={onTypeClick}
        tabHeader={tabHeader}
      />
    </>
  );
};

export default GqlDirectiveTab;
