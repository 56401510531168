import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn,
  StateAndNameType
} from 'Components/Common/SoloTableCells';
import { isPolicyTypeDetailsPageSupported } from 'Components/Features/Policies/Details/PolicyDetailsLanding';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListWorkspacePoliciesResponse_WorkspacePolicy } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { getPolicyIcon, getPolicyTypeReadableName } from 'utils/types';
import { buildWorkspacePolicyDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';

type TableFields = {
  key: string;
  name: StateAndNameType;
  type?: PolicyType;
  namespace?: string;
  clusterName?: string;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render(type) {
      return (
        <Styles.CellIconText>
          {getPolicyIcon(type)} {getPolicyTypeReadableName(type)}
        </Styles.CellIconText>
      );
    }
  }
];

interface Props {
  workspaceName: string;
  workspacePolicies: ListWorkspacePoliciesResponse_WorkspacePolicy[];
  pagingData: PagingProps;
}
export const WorkspacePoliciesTable = ({ workspaceName, workspacePolicies, pagingData }: Props) => {
  const tableData = useMemo<TableFields[]>(() => {
    return workspacePolicies.map(policy => ({
      key: buildIdFromRef(policy.policyRef),
      name: {
        name: policy.policyRef?.name ?? '',
        status: policy.status,
        link: isPolicyTypeDetailsPageSupported(policy.type)
          ? buildWorkspacePolicyDetailsUrl(workspaceName, policy.type, policy.policyRef)
          : undefined
      },
      type: policy.type,
      namespace: policy.policyRef?.namespace,
      clusterName: policy.policyRef?.clusterName
    }));
  }, [workspacePolicies, workspaceName]);

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='policy-tab-table'
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.PolicyRuleIcon width={100} height={100} />}
          title='There are no Policies to display'
          description=''
          href={docLinks.enterprise.supported_policies}
        />
      }
    />
  );
};
