import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace SoloInputStyles {
  export const InputContainer = styled.div`
    position: relative;
  `;

  export const SearchIconContainer = styled.div(
    ({ theme }) => css`
      position: absolute;
      height: 100%;
      top: 0px;
      right: 0.25em;
      display: flex;
      align-items: center;
      pointer-events: none;

      ${svgBasics('.9em')}
      svg {
        pointer-events: none;
      }
    `
  );

  type InputStyledProps = {
    error?: boolean;
    borderless?: boolean;
    searchIcon?: boolean;
  };
  export const Input = styled.input<InputStyledProps>(
    ({ theme, error, borderless, searchIcon }) => css`
      width: 100%;
      padding: 5px 10px;
      ${searchIcon &&
      css`
        padding-right: 1.5em;
      `}
      border-radius: 3px;
      line-height: 16px;
      outline: none;

      border: 1px solid ${error ? theme.grapefruitOrange : borderless ? 'none' : theme.aprilGrey};

      &:disabled {
        background: ${theme.marchGrey};
        color: ${theme.septemberGrey};
        border: 1px solid ${theme.juneGrey};
      }

      &::placeholder {
        color: ${theme.juneGrey};
      }
    `
  );
}
