import { CardStyles } from 'Components/Common/Card';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { Svg } from 'Components/Common/Svg';
import { DashboardStyles } from 'Components/Features/Dashboard/DashboardLanding.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { GetClusterDetailsCardsResponse_ConfigurationSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';

export const ClusterConfigurationCard = ({
  configurationSummary: config
}: {
  configurationSummary: GetClusterDetailsCardsResponse_ConfigurationSummary | undefined;
}) => {
  return (
    <CardStyles.Card data-testid='cluster-config-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.ConfigIcon />} color={theme => theme.neptuneBlue} size={27} />
        Configuration
      </DashboardStyles.Header>
      <Spacer mt='5px'>
        <SoloListCard
          title='Type'
          icon='Amount'
          dataSource={[
            { data: 'Namespaces', right: config?.namespaces ?? 'unknown' },
            { data: 'Services', right: config?.services ?? 'unknown' },
            { data: 'Gateways', right: config?.gateways ?? 'unknown' }
          ]}
        />
      </Spacer>
    </CardStyles.Card>
  );
};
