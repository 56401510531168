import styled from '@emotion/styled';
import { GraphFetchSettingsContextProvider } from 'Components/Features/Graph/Context/GraphFetchSettingsContext';
import { GraphUXSettingsContextProvider } from 'Components/Features/Graph/Context/GraphUXSettingsContext';
import { Footer } from 'Components/Structure/Footer';
import { pageRouteObjects } from 'Components/Structure/routes';
import { useRoutes } from 'react-router-dom';
import { usePermissions } from 'utils/permissions';
import { LicenseChecker } from './Notifications/license-checker';
import { useSafeModeNotificationChecker } from './Notifications/safe-mode-notification-checker';
import { TopLevelNotificationDisplay } from './Notifications/TopLevelNotificationDisplay';

const ScrollContainer = styled.main`
  grid-area: content;

  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
`;

const Container = styled.div`
  position: relative;
  flex: 1;
  padding: 20px;
`;

export const Content = () => {
  const routeElements = useRoutes(pageRouteObjects);
  // We check for loading before rendering any content to avoid permission issues when
  // redirecting a page, since it can't properly do so before permissions are loaded
  const { loading: permissionsLoading } = usePermissions();
  useSafeModeNotificationChecker();

  return (
    <ScrollContainer data-testid='content' id='siteScrollArea'>
      <TopLevelNotificationDisplay />
      <LicenseChecker />

      {/*High level Context to be shared across locations in the app */}
      <GraphFetchSettingsContextProvider>
        <GraphUXSettingsContextProvider>
          <Container>{!permissionsLoading && routeElements}</Container>
        </GraphUXSettingsContextProvider>
      </GraphFetchSettingsContextProvider>

      <Footer />
    </ScrollContainer>
  );
};
