import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';

import { DataError } from 'Components/Common/DataError';
import { OptionType, SoloDropdown } from 'Components/Common/Input/SoloDropdown';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { SoloModal } from 'Components/Common/SoloModal';
import { DefaultTablePageSize, SoloPagination, useSoloPaging } from 'Components/Common/SoloPagination';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  ListOverviewClustersRequest_ClusterSortOrder,
  ListOverviewWorkspacesRequest_WorkspaceSortOrder
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { AddClusterModalBody } from './AddClusterModalBody';
import { AddWorkspaceModalBody } from './AddWorkspaceModalBody';
import { EmptyClusters, EmptyWorkspaces } from './EmptyOverview';
import { OverviewAreasStyles } from './OverviewAreas.style';
import { OverviewClusterCard, OverviewWorkspaceCard } from './OverviewCards';

const { useListOverviewWorkspaces, useListOverviewClusters } = overviewApi;

/** Shared hooks for pagination, filtering and sorting. */
const useGetPagingFilteringSorting = (initialSortOrder: number, sortOrderOptions: OptionType<number>[]) => {
  di(useSoloPaging);
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const [reversed, setReversed] = useState(false);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging(DefaultTablePageSize);
  return {
    nameFilter,
    setNameFilter,
    statusFilter,
    setStatusFilter,
    sortOrder,
    setSortOrder,
    reversed,
    setReversed,
    pagingData,
    apiPaginationObject,
    useSetPagingTotal,
    sortOrderOptions
  };
};

/** Returns true after one useEffect render. */
const useCheckIfDataHasBeenSeenAtLeastOnce = (data: any, count: number | undefined) => {
  const [dataHasBeenSeenAtLeastOnce, setDataHasBeenSeenAtLeastOnce] = useState(false);
  useEffect(() => {
    if (!!count) {
      setDataHasBeenSeenAtLeastOnce(true);
    }
  }, [data]);
  return dataHasBeenSeenAtLeastOnce;
};

/** Renders the filtered total in the footer. */
const OverviewAreasFooterCount = ({ filteredTotal }: { filteredTotal: number }) => (
  <OverviewAreasStyles.FooterCount>
    Total {filteredTotal} item
    {filteredTotal !== 1 ? 's' : ''}
  </OverviewAreasStyles.FooterCount>
);

const OverviewAreaContainer = ({
  title,
  icon,
  totalCount,
  errorCount,
  pagingFilteringSorting,
  refreshing,
  children
}: {
  title: string;
  icon: React.ReactNode;
  totalCount?: number;
  errorCount?: number;
  pagingFilteringSorting: ReturnType<typeof useGetPagingFilteringSorting>;
  children: React.ReactNode;
  refreshing: boolean;
}) => {
  const {
    nameFilter,
    setNameFilter,
    statusFilter,
    setStatusFilter,
    sortOrder,
    setSortOrder,
    reversed,
    setReversed,
    sortOrderOptions
  } = pagingFilteringSorting;

  return (
    <OverviewAreasStyles.Container data-testid='overview-area'>
      <OverviewAreasStyles.Title>
        <div>
          <OverviewAreasStyles.TitleIconHolder>{icon}</OverviewAreasStyles.TitleIconHolder>
          {title}
        </div>
        <div>
          <HealthCountBox
            onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
            onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
            isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
            isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
            totalCount={totalCount}
            errorCount={errorCount}
          />
        </div>
      </OverviewAreasStyles.Title>
      <OverviewAreasStyles.FilterLine>
        <OverviewAreasStyles.SortContainer>
          <OverviewAreasStyles.OrderDirectionToggle reversed={reversed} onClick={() => setReversed(rev => !rev)}>
            <Asset.SortArrows />
          </OverviewAreasStyles.OrderDirectionToggle>
          <SoloDropdown
            value={sortOrder}
            displayValue={`Sort by ${sortOrderOptions.find(o => o.value === sortOrder)?.displayValue}`}
            options={sortOrderOptions}
            placeholder={'Sort by...'}
            aria-label='Sort by'
            onChange={setSortOrder}
          />
        </OverviewAreasStyles.SortContainer>
        <SoloInput
          value={nameFilter}
          placeholder={'Search by name...'}
          aria-label='Search by name'
          onChange={evt => setNameFilter(evt.target.value)}
        />

        <OverviewAreasStyles.RefreshIndicatorHolder>
          {refreshing ? <Loading tiny floating /> : null}
        </OverviewAreasStyles.RefreshIndicatorHolder>
      </OverviewAreasStyles.FilterLine>
      {children}
    </OverviewAreasStyles.Container>
  );
};

//
// Overview Workspaces Area
//
export const OverviewWorkspacesArea = () => {
  di(useListOverviewWorkspaces);
  const [addWorkspaceModalOpen, setAddWorkspaceModalOpen] = useState(false);
  const pagingFilteringSorting = useGetPagingFilteringSorting(ListOverviewWorkspacesRequest_WorkspaceSortOrder.NAME, [
    {
      value: ListOverviewWorkspacesRequest_WorkspaceSortOrder.NAME,
      displayValue: 'Name'
    },
    {
      value: ListOverviewWorkspacesRequest_WorkspaceSortOrder.CLUSTERS,
      displayValue: 'Cluster'
    },
    {
      value: ListOverviewWorkspacesRequest_WorkspaceSortOrder.NAMESPACES,
      displayValue: 'Namespace'
    },
    {
      value: ListOverviewWorkspacesRequest_WorkspaceSortOrder.SERVICES,
      displayValue: 'Destinations'
    }
  ]);
  const { nameFilter, pagingData, useSetPagingTotal, apiPaginationObject, statusFilter, sortOrder, reversed } =
    pagingFilteringSorting;
  const { data: workspacesData, error: workspacesError } = useListOverviewWorkspaces(
    nameFilter,
    apiPaginationObject,
    statusFilter,
    sortOrder,
    reversed
  );

  useSetPagingTotal(workspacesData?.filteredTotal);
  const { initialLoad, showRefreshIndicator, data: workspaces } = useDebouncedRefreshIndicator(workspacesData);
  const dataHasBeenSeenAtLeastOnce = useCheckIfDataHasBeenSeenAtLeastOnce(workspaces, workspaces?.workspaces.length);

  const overviewAreaProps = {
    title: 'Workspaces',
    icon: <Asset.WorkspaceIcon />,
    errorCount: workspaces?.errors,
    totalCount: workspaces?.total,
    pagingFilteringSorting: pagingFilteringSorting
  };
  if (!workspaces || !workspaces.workspaces.length) {
    return dataHasBeenSeenAtLeastOnce ? (
      <OverviewAreaContainer {...overviewAreaProps} refreshing={false}>
        <div>
          <EmptyWorkspaces />
        </div>
      </OverviewAreaContainer>
    ) : (
      <OverviewAreasStyles.Container>
        <div>
          <EmptyWorkspaces />
        </div>
      </OverviewAreasStyles.Container>
    );
  }

  return (
    <OverviewAreaContainer {...overviewAreaProps} refreshing={showRefreshIndicator}>
      <OverviewAreasStyles.ContentBody>
        {!!workspacesError ? (
          <div>
            <DataError error={workspacesError} />
          </div>
        ) : initialLoad ? (
          <Loading center={true} message='Loading workspaces data...' />
        ) : (
          <div>
            {workspaces?.workspaces.map((workspace, ind) => (
              <OverviewWorkspaceCard
                key={workspace.workspaceRef ? workspace.workspaceRef.name + workspace.workspaceRef.namespace : ind}
                clustersCount={workspace.numClusters}
                namespacesCount={workspace.numNamespaces}
                servicesCount={workspace.numServices}
                status={workspace.status}
                objRef={workspace.workspaceRef}
              />
            ))}
          </div>
        )}
        <OverviewAreasStyles.ContentBodyFooter>
          <OverviewAreasFooterCount filteredTotal={workspaces?.filteredTotal ?? 0} />
          <SoloPagination {...pagingData} pageSizeOptions={[5, 10, 20, 50]} hideExtraTotalDisplay={true} small />
          <OverviewAreasStyles.AddItemPrompt
            onClick={() => setAddWorkspaceModalOpen(true)}
            data-testid='adding-a-workspace'>
            <SoloLinkStyles.SoloLinkLooks>
              ADD WORKSPACE <Asset.SmallGreenPlus />
            </SoloLinkStyles.SoloLinkLooks>
          </OverviewAreasStyles.AddItemPrompt>
        </OverviewAreasStyles.ContentBodyFooter>
      </OverviewAreasStyles.ContentBody>
      {addWorkspaceModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddWorkspaceModalOpen(false)}>
          <AddWorkspaceModalBody />
        </SoloModal>
      )}
    </OverviewAreaContainer>
  );
};

//
// Overview Clusters Area
//
export const OverviewClustersArea = () => {
  di(useListOverviewClusters);
  const [addClusterModalOpen, setAddClusterModalOpen] = useState(false);
  const clusterSortOrderOptionsList: OptionType<ListOverviewClustersRequest_ClusterSortOrder>[] = [
    {
      value: ListOverviewClustersRequest_ClusterSortOrder.NAME,
      displayValue: 'Name'
    },
    {
      value: ListOverviewClustersRequest_ClusterSortOrder.NODES,
      displayValue: 'Node'
    },
    {
      value: ListOverviewClustersRequest_ClusterSortOrder.NAMESPACES,
      displayValue: 'Namespace'
    },
    {
      value: ListOverviewClustersRequest_ClusterSortOrder.SERVICES,
      displayValue: 'Service'
    },
    {
      value: ListOverviewClustersRequest_ClusterSortOrder.GATEWAYS,
      displayValue: 'Gateway'
    }
  ];
  const pagingFilteringSorting = useGetPagingFilteringSorting(
    ListOverviewClustersRequest_ClusterSortOrder.NAME,
    clusterSortOrderOptionsList
  );
  const { nameFilter, pagingData, useSetPagingTotal, apiPaginationObject, statusFilter, sortOrder, reversed } =
    pagingFilteringSorting;
  const { data: clustersData, error: clustersError } = useListOverviewClusters(
    nameFilter,
    apiPaginationObject,
    statusFilter,
    sortOrder,
    reversed
  );

  useSetPagingTotal(clustersData?.filteredTotal);
  const { initialLoad, showRefreshIndicator, data: clusters } = useDebouncedRefreshIndicator(clustersData);
  const dataHasBeenSeenAtLeastOnce = useCheckIfDataHasBeenSeenAtLeastOnce(clusters, clusters?.clusters.length);

  const overviewAreaProps = {
    title: 'Clusters',
    icon: <Asset.ClusterIcon />,
    errorCount: clusters?.errors,
    totalCount: clusters?.total,
    pagingFilteringSorting: pagingFilteringSorting
  };
  if (!!clusters && !clusters.clusters.length) {
    return dataHasBeenSeenAtLeastOnce ? (
      <OverviewAreaContainer {...overviewAreaProps} refreshing={false}>
        <EmptyClusters />
      </OverviewAreaContainer>
    ) : (
      <OverviewAreasStyles.Container>
        <EmptyClusters />
      </OverviewAreasStyles.Container>
    );
  }

  return (
    <OverviewAreaContainer {...overviewAreaProps} refreshing={showRefreshIndicator}>
      <OverviewAreasStyles.ContentBody>
        {!!clustersError ? (
          <div>
            <DataError error={clustersError} />
          </div>
        ) : initialLoad ? (
          <Loading center={true} message='Loading clusters data...' />
        ) : (
          <div>
            {clusters?.clusters.map((cluster, ind) => (
              <OverviewClusterCard
                key={cluster.clusterRef ? cluster.clusterRef.name + cluster.clusterRef.namespace : ind}
                region={cluster.region}
                glooMeshAgentVersion={cluster.glooMeshAgentVersion}
                k8Version={cluster.kubernetesVersion}
                istioVersions={cluster.istioVersions}
                ciliumVersion={cluster.ciliumVersion}
                calicoVersion={cluster.calicoVersion}
                status={cluster.status}
                objRef={cluster.clusterRef}
              />
            ))}
          </div>
        )}
        <OverviewAreasStyles.ContentBodyFooter>
          <OverviewAreasFooterCount filteredTotal={clusters?.filteredTotal ?? 0} />
          <SoloPagination {...pagingData} pageSizeOptions={[5, 10, 20, 50]} hideExtraTotalDisplay={true} small />
          <OverviewAreasStyles.AddItemPrompt
            onClick={() => setAddClusterModalOpen(true)}
            data-testid='adding-a-cluster'>
            <SoloLinkStyles.SoloLinkLooks>
              ADD CLUSTER <Asset.SmallGreenPlus />
            </SoloLinkStyles.SoloLinkLooks>
          </OverviewAreasStyles.AddItemPrompt>
        </OverviewAreasStyles.ContentBodyFooter>
      </OverviewAreasStyles.ContentBody>
      {addClusterModalOpen && (
        <SoloModal visible width={672} onClose={() => setAddClusterModalOpen(false)}>
          <AddClusterModalBody />
        </SoloModal>
      )}
    </OverviewAreaContainer>
  );
};
