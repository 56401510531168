import { colors } from 'Styles';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import Chart, { ChartOptions } from 'chart.js/auto';
import { ElementRef, useEffect, useMemo, useRef } from 'react';
import { InsightCodeGroup, insightCodeGroupMap } from 'utils/dashboard/dashboard-types';
import { getEnumValues } from 'utils/helpers';
import { DashboardStyles } from '../../DashboardLanding.style';
import { AnalysisAndInsightsStyles } from './AnalysisAndInsights.style';

// 10 unique color options.
const chartColorOptions = [
  '#158BC2',
  '#325476',
  '#0FAC7C',
  '#E6BA64',
  '#E99E5E',
  '#D75B1D',
  '#8575CB',
  '#C46BAB',
  '#536985'
];

const InsightsChart = ({
  categoryCounts,
  total,
  hideRightLine = false
}: {
  categoryCounts: Record<string, number>;
  total: number;
  hideRightLine?: boolean;
}) => {
  // This fixes the flickering so that it only animates once when the chart appears.
  const chartRenderedOnceRef = useRef(false);

  //
  // Setup Chart
  //
  const insightsChart = useRef<ElementRef<'canvas'>>(null);
  const codeGroups = getEnumValues(InsightCodeGroup).filter(cg => cg !== InsightCodeGroup.SYS);
  useEffect(() => {
    const canvas = insightsChart.current;
    if (!canvas) {
      return;
    }
    //
    // Set up the data we will use for the chart.
    //
    // We always want to show these labels even if they don't exist in data passed in
    let labels = codeGroups.map(cg => insightCodeGroupMap[cg]);
    // Show all supported counts, or default to 0 if not present.
    // This should work for if the code or full name is passed in (e.g. "SYS" or "System").
    let data = codeGroups.map(cg => categoryCounts[insightCodeGroupMap[cg]] ?? categoryCounts[cg] ?? 0);
    // Chart.js repeats the colors if has run out of options (if categoryCountsMap.length > chartColorOptions.length).
    let backgroundColors = chartColorOptions.slice(0, Math.max(codeGroups.length, chartColorOptions.length));

    const options: ChartOptions = {
      plugins: { legend: { display: false } },
      animation: chartRenderedOnceRef.current ? false : undefined
    };
    // If there is no data, we can show a light circle where the donut would be.
    if (data.reduce((acc, prev) => acc + prev, 0) === 0) {
      backgroundColors = [colors.marchGrey];
      labels = [];
      data = [1];
      options.animation = false;
    }
    //
    const chart = new Chart(canvas, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [
          {
            backgroundColor: backgroundColors,
            data,
            borderWidth: 0,
            // These props are in the chart.js spec, but not in the types.
            // @ts-ignore
            cutout: '80%'
          }
        ]
      },
      options
    });
    chartRenderedOnceRef.current = true;
    return () => {
      chart.destroy();
    };
  }, [insightsChart.current, categoryCounts]);

  // This grouping is used for the legend,
  // rendering at most 4 colors per column.
  const categoriesInGroupsOfFour = useMemo(() => {
    const groupsOfFour: string[][] = [];
    const labels = codeGroups.map(cg => insightCodeGroupMap[cg]);
    for (let startIdx = 0; startIdx < labels.length; startIdx += 4) {
      const endIdx = Math.min(startIdx + 4, labels.length);
      groupsOfFour.push(labels.slice(startIdx, endIdx));
    }
    return groupsOfFour;
  }, []);

  //
  // Setup Chart
  //
  return (
    <AnalysisAndInsightsStyles.ChartAndLegendOuterContainer>
      <FlexLayout alignItems='center'>
        <DashboardStyles.VerticalLine height='120px' mr={'20px'} mb={'-10px'} />
        <AnalysisAndInsightsStyles.InsightsChartContainer>
          <canvas id='analysis-and-insights' ref={insightsChart} />
          <AnalysisAndInsightsStyles.ChartTextContainer>
            <AnalysisAndInsightsStyles.ChartBigText data-testid='total-insights-count'>
              {total}
            </AnalysisAndInsightsStyles.ChartBigText>
            <AnalysisAndInsightsStyles.ChartSmallText>
              Total
              <br />
              Insights
            </AnalysisAndInsightsStyles.ChartSmallText>
          </AnalysisAndInsightsStyles.ChartTextContainer>
        </AnalysisAndInsightsStyles.InsightsChartContainer>
        <FlexLayout alignItems='flex-start'>
          {categoriesInGroupsOfFour.map((group, categoriesInGroupsOfFourIdx) => {
            const categoryCountsMapStartIdx = categoriesInGroupsOfFourIdx * 4;
            //
            // Render the legend for each group of category counts.
            return (
              <Spacer pb={4} pt={2} ml={4} key={categoryCountsMapStartIdx}>
                {group.map((label, groupOffsetIdx) => (
                  <DashboardStyles.ChartLegendRow key={label}>
                    <DashboardStyles.ChartLegendColorSample
                      datasetColor={
                        chartColorOptions[(categoryCountsMapStartIdx + groupOffsetIdx) % chartColorOptions.length]
                      }
                    />
                    {label}
                  </DashboardStyles.ChartLegendRow>
                ))}
              </Spacer>
            );
          })}
        </FlexLayout>
        {!hideRightLine && <DashboardStyles.VerticalLine height='120px' ml={'25px'} mb={'-10px'} />}
      </FlexLayout>
    </AnalysisAndInsightsStyles.ChartAndLegendOuterContainer>
  );
};

export default InsightsChart;
