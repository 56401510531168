import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { FancyCodeDisplayerModal, FancyCodeDisplayerModalContent } from 'Components/Common/FancyCodeDisplayerModal';
import { Loading } from 'Components/Common/Loading';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloEmptyLeftIcon } from 'Components/Common/SoloEmpty';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { InjaTemplate } from 'proto/github.com/solo-io/envoy-gloo/api/envoy/config/filter/http/transformation/v2/transformation_filter_pb';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GetTransformationPolicyDetailsResponse_Phase } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { InjaTemplateCard } from './Transformation/InjaTemplateCard';

const { useGetTransformationPolicyDetails } = policiesApi;

const policyType = PolicyType.TRANSFORMATION;

const filterTimingPhaseMap: Record<GetTransformationPolicyDetailsResponse_Phase, string> = {
  [GetTransformationPolicyDetailsResponse_Phase.POST_AUTHZ]: 'PostAuth',
  [GetTransformationPolicyDetailsResponse_Phase.PRE_AUTHZ]: 'PreAuth'
};

function boolToText(bool: boolean) {
  return bool ? 'True' : 'False';
}
export interface TransformationCodeModalBodyProps {
  title: string;
  subtitle: string;
  code: InjaTemplate;
}
interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const TransformationDetails = ({ clusterObjRef }: Props) => {
  di(useGetTransformationPolicyDetails);
  const routesPaging = useSoloPaging();

  const [codeModalData, setCodeModalData] = useState<TransformationCodeModalBodyProps>();

  const { data: policyData, error: policyError } = useGetTransformationPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  const { requestTransformation, responseTransformation } = policyData;

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.FilterTimingIcon />,
              title: 'Filter Timing Phase',
              data: filterTimingPhaseMap[policyData.phase]
            },
            {
              icon: <Asset.FlagPriority />,
              title: 'Filter Timing Priority',
              data: policyData.timingPriority ?? 'Priority not configured'
            }
          ]}
        />
      </CardStyles.Card>

      {requestTransformation ? (
        requestTransformation.injaTemplate ? (
          <InjaTemplateCard
            inja={requestTransformation.injaTemplate}
            header='Request Transformation'
            headerIcon={<Asset.RequestIcon />}
            setCodeModalData={setCodeModalData}
            recalculateRoutingDestination={requestTransformation.recalculateRoutingDestination}
          />
        ) : (
          <CardStyles.Card>
            <CardStyles.CardHeader>Request Transformation</CardStyles.CardHeader>
            <CardStyles.CardHeaderSecondaryInfoHolder>
              <SecondaryInfo
                items={[
                  {
                    label: 'Recalcuate Routing Destination',
                    data: boolToText(requestTransformation.recalculateRoutingDestination)
                  }
                ]}
              />
            </CardStyles.CardHeaderSecondaryInfoHolder>
            <SoloEmptyLeftIcon icon={<Asset.RequestIcon />} iconCircled title='Unknown transformation template type' />
          </CardStyles.Card>
        )
      ) : (
        <CardStyles.Card>
          <CardStyles.CardHeader>Request Transformation</CardStyles.CardHeader>
          <SoloEmptyLeftIcon
            icon={<Asset.RequestIcon />}
            iconCircled
            title='No request transformation template configured'
          />
        </CardStyles.Card>
      )}

      {responseTransformation ? (
        responseTransformation.injaTemplate ? (
          <InjaTemplateCard
            inja={responseTransformation.injaTemplate}
            header='Response Transformation'
            headerIcon={<Asset.ResponseIcon />}
            setCodeModalData={setCodeModalData}
          />
        ) : (
          <CardStyles.Card>
            <CardStyles.CardHeader>Response Transformation</CardStyles.CardHeader>
            <SoloEmptyLeftIcon icon={<Asset.ResponseIcon />} iconCircled title='Unknown transformation template type' />
          </CardStyles.Card>
        )
      ) : (
        <CardStyles.Card>
          <CardStyles.CardHeader>Response Transformation</CardStyles.CardHeader>
          <SoloEmptyLeftIcon
            icon={<Asset.ResponseIcon />}
            iconCircled
            title='No response transformation template configured'
          />
        </CardStyles.Card>
      )}

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>

      {codeModalData && (
        <FancyCodeDisplayerModal
          title={codeModalData.title}
          subtitle={codeModalData.subtitle}
          icon={<Asset.RequestHeader />}
          onClose={() => setCodeModalData(undefined)}>
          <FancyCodeDisplayerModalContent contentString={codeModalData.code.text} type='jinja2' />
        </FancyCodeDisplayerModal>
      )}
    </CardStyles.CardList>
  );
};
