/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routing.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RoutingApi } from "./routing_pb";
import type { GetRouteTableSchemaResponse } from "./routing_pb";
import type { GetRouteTableSchemaRequest } from "./routing_pb";
import type { GetRouteTableDetailsResponse } from "./routing_pb";
import type { GetRouteTableDetailsRequest } from "./routing_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetVirtualGatewayDetailsResponse } from "./routing_pb";
import type { GetVirtualGatewayDetailsRequest } from "./routing_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Apis for the Gloo Mesh UI Workspace Routing pages
 *
 * @generated from protobuf service rpc.gloo.solo.io.RoutingApi
 */
export interface IRoutingApiClient {
    /**
     * @generated from protobuf rpc: GetVirtualGatewayDetails(rpc.gloo.solo.io.GetVirtualGatewayDetailsRequest) returns (rpc.gloo.solo.io.GetVirtualGatewayDetailsResponse);
     */
    getVirtualGatewayDetails(input: GetVirtualGatewayDetailsRequest, options?: RpcOptions): UnaryCall<GetVirtualGatewayDetailsRequest, GetVirtualGatewayDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetRouteTableDetails(rpc.gloo.solo.io.GetRouteTableDetailsRequest) returns (rpc.gloo.solo.io.GetRouteTableDetailsResponse);
     */
    getRouteTableDetails(input: GetRouteTableDetailsRequest, options?: RpcOptions): UnaryCall<GetRouteTableDetailsRequest, GetRouteTableDetailsResponse>;
    /**
     * @generated from protobuf rpc: GetRouteTableSchema(rpc.gloo.solo.io.GetRouteTableSchemaRequest) returns (rpc.gloo.solo.io.GetRouteTableSchemaResponse);
     */
    getRouteTableSchema(input: GetRouteTableSchemaRequest, options?: RpcOptions): UnaryCall<GetRouteTableSchemaRequest, GetRouteTableSchemaResponse>;
}
/**
 * Apis for the Gloo Mesh UI Workspace Routing pages
 *
 * @generated from protobuf service rpc.gloo.solo.io.RoutingApi
 */
export class RoutingApiClient implements IRoutingApiClient, ServiceInfo {
    typeName = RoutingApi.typeName;
    methods = RoutingApi.methods;
    options = RoutingApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetVirtualGatewayDetails(rpc.gloo.solo.io.GetVirtualGatewayDetailsRequest) returns (rpc.gloo.solo.io.GetVirtualGatewayDetailsResponse);
     */
    getVirtualGatewayDetails(input: GetVirtualGatewayDetailsRequest, options?: RpcOptions): UnaryCall<GetVirtualGatewayDetailsRequest, GetVirtualGatewayDetailsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetVirtualGatewayDetailsRequest, GetVirtualGatewayDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRouteTableDetails(rpc.gloo.solo.io.GetRouteTableDetailsRequest) returns (rpc.gloo.solo.io.GetRouteTableDetailsResponse);
     */
    getRouteTableDetails(input: GetRouteTableDetailsRequest, options?: RpcOptions): UnaryCall<GetRouteTableDetailsRequest, GetRouteTableDetailsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRouteTableDetailsRequest, GetRouteTableDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRouteTableSchema(rpc.gloo.solo.io.GetRouteTableSchemaRequest) returns (rpc.gloo.solo.io.GetRouteTableSchemaResponse);
     */
    getRouteTableSchema(input: GetRouteTableSchemaRequest, options?: RpcOptions): UnaryCall<GetRouteTableSchemaRequest, GetRouteTableSchemaResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRouteTableSchemaRequest, GetRouteTableSchemaResponse>("unary", this._transport, method, opt, input);
    }
}
