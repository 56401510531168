/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "xds/core/v3/extension.proto" (package "xds.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../../../google/protobuf/any_pb";
/**
 * Message type for extension configuration.
 *
 * @generated from protobuf message xds.core.v3.TypedExtensionConfig
 */
export interface TypedExtensionConfig {
    /**
     * The name of an extension. This is not used to select the extension, instead
     * it serves the role of an opaque identifier.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The typed config for the extension. The type URL will be used to identify
     * the extension. In the case that the type URL is *xds.type.v3.TypedStruct*
     * (or, for historical reasons, *udpa.type.v1.TypedStruct*), the inner type
     * URL of *TypedStruct* will be utilized. See the
     * :ref:`extension configuration overview
     * <config_overview_extension_configuration>` for further details.
     *
     * @generated from protobuf field: google.protobuf.Any typed_config = 2;
     */
    typedConfig?: Any;
}
// @generated message type with reflection information, may provide speed optimized methods
class TypedExtensionConfig$Type extends MessageType<TypedExtensionConfig> {
    constructor() {
        super("xds.core.v3.TypedExtensionConfig", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "typed_config", kind: "message", T: () => Any, options: { "validate.rules": { any: { required: true } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message xds.core.v3.TypedExtensionConfig
 */
export const TypedExtensionConfig = new TypedExtensionConfig$Type();
