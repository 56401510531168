import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn,
  RenderImportStatus,
  StateAndNameType
} from 'Components/Common/SoloTableCells';
import {
  ApiType,
  ImportStatus
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListWorkspaceApisResponse_WorkspaceApi } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { apiTypeMap } from 'utils/types';
import { buildApiDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';

type TableFields = {
  key: string;
  name: StateAndNameType;
  namespace?: string;
  clusterName?: string;
  type: ApiType;
  importStatus: ImportStatus;
};
const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render: type => {
      const { icon, name } = apiTypeMap[type];
      return (
        <Styles.CellIconText>
          {icon} {name}
        </Styles.CellIconText>
      );
    }
  },
  {
    title: 'Import Status',
    dataIndex: 'importStatus',
    render: RenderImportStatus
  }
];

interface Props {
  workspaceApis: ListWorkspaceApisResponse_WorkspaceApi[];
  pagingData: PagingProps;
}
export const WorkspaceApisTable = ({ workspaceApis, pagingData }: Props) => {
  const tableData = useMemo<TableFields[]>(
    () =>
      workspaceApis.map(({ routeTableRef: rtRef, ...api }) => ({
        key: `${buildIdFromRef(rtRef)}.${api.routeName}`,
        name: {
          name: api.routeName ?? '',
          status: api.status,
          link: buildApiDetailsUrl({ routeTableRef: rtRef, istioRouteName: api.routeName, apiType: api.apiType })
        },
        namespace: rtRef?.namespace ?? '',
        clusterName: rtRef?.clusterName ?? '',
        type: api.apiType,
        importStatus: api.importStatus
      })),
    [workspaceApis]
  );

  return (
    <SoloTable
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='api-tab-table'
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.ApiIcon width={100} height={100} />}
          title='There are no APIs to display'
          description=''
          href={docLinks.enterprise.api_reference}
        />
      }
    />
  );
};
