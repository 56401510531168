import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace EmptyOverviewStyles {
  export const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    grid-template-areas:
      'welcome welcome'
      'workspaces clusters';
  `;

  export const AreaBox = styled(CardStyles.Card)``;
  export const WorkspacesBox = styled(AreaBox)`
    grid-area: workspaces;
  `;
  export const ClustersBox = styled(AreaBox)`
    grid-area: clusters;
  `;
  export const WelcomeBox = styled(AreaBox)(
    ({ theme }) => css`
      grid-area: welcome;
      padding: 25px 25px 35px;
      color: ${theme.neptuneBlue};
    `
  );
  export const WelcomeTitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 25px;
  `;
  export const WelcomeItems = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
  `;
  export const WelcomeItemBox = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 20px;
  `;
  export const WelcomeItemCheckbox = styled.div(
    ({ theme }) => css`
      margin-right: 11px;

      ${svgBasics(23, theme.oceanBlue)}
    `
  );

  export const RegisteringPromptContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 48px;
  `;
  export const WorkspaceContent = styled(RegisteringPromptContent)`
    height: 100%;
  `;
  export const ClusterContent = styled(RegisteringPromptContent)`
    height: 100%;
  `;
  export const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const RegisteringIconHolder = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 133px;
      height: 133px;
      border-radius: 133px;
      border: 1px solid ${theme.splashBlue};
      margin-bottom: 30px;

      ${svgBasics(80, theme.splashBlue)}
    `
  );
  export const RegisteringTitle = styled.div`
    font-size: 28px;
    text-align: center;
  `;
  export const RegisteringBody = styled.div`
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding: 25px 0;
  `;
  export const RegisterButtons = styled.div`
    display: flex;
    > * {
      margin-right: 20px;
    }
    > :last-child {
      margin-right: 0;
    }
  `;
}
