import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { createEllipseTextColumn, RenderCellStringList, renderDestinationKind } from 'Components/Common/SoloTableCells';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ListWorkspaceDestinationsResponse_AvailableDestination } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { docLinks } from 'utils/url-external-links-map';

type TableFields = {
  key: string;
  name?: string;
  type?: DestinationKind;
  hostsList: { list: string[] };
};

const columns: SoloColumnsType<TableFields> = [
  createEllipseTextColumn({ title: 'Name', dataIndex: 'name' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render: renderDestinationKind
  },
  {
    title: 'Hosts',
    dataIndex: 'hostsList',
    render: RenderCellStringList
  }
];

interface Props {
  availableDestinations: ListWorkspaceDestinationsResponse_AvailableDestination[];
  pagingData: PagingProps;
  hideType?: boolean;
}
export const AvailableDestinationsToImportTable = ({ availableDestinations, pagingData, hideType }: Props) => {
  const tableData = useMemo<TableFields[]>(() => {
    return availableDestinations.map(dest => ({
      key: buildIdFromRef(dest.destinationRef),
      name: dest.destinationRef?.name ?? '',
      type: dest.type,
      hostsList: { list: dest.hosts }
    }));
  }, [availableDestinations]);

  return (
    <SoloTable
      columns={!hideType ? columns : columns.filter(c => c.title !== 'Type')}
      dataSource={tableData}
      paging={pagingData}
      data-testid={hideType ? 'available-virtual-destination-table' : 'available-destination-table'}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.ServiceIcon />}
          title='There are no importable Destinations'
          description=''
          href={docLinks.enterprise.workspace_export}
        />
      }
    />
  );
};
