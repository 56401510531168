import { Asset } from 'assets';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { PagingProps } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import {
  createEllipseTextColumn,
  createHealthIndicatorWithNameColumn,
  RenderCellStringList,
  RenderCellStringListProps,
  renderDestinationKind,
  RenderImportStatus,
  StateAndNameType
} from 'Components/Common/SoloTableCells';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ImportStatus } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListWorkspaceDestinationsResponse_WorkspaceDestination } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { buildIdFromRef } from 'utils/helpers';
import { buildWorkspaceDestinationDetailsUrl } from 'utils/url-builders';
import { docLinks } from 'utils/url-external-links-map';

type TableFields = {
  key: string;
  name: StateAndNameType;
  type?: DestinationKind;
  importStatus: ImportStatus;
  namespace?: string;
  clusterName?: string;
  hostsList: RenderCellStringListProps;
};

const columns: SoloColumnsType<TableFields> = [
  createHealthIndicatorWithNameColumn({ title: 'Name', dataIndex: 'name' }),
  createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' }),
  createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
  {
    title: 'Type',
    dataIndex: 'type',
    render: renderDestinationKind
  },
  {
    title: 'Import Status',
    dataIndex: 'importStatus',
    render: RenderImportStatus
  },
  {
    title: 'Hosts',
    dataIndex: 'hostsList',
    render: RenderCellStringList
  }
];

interface Props {
  workspaceName: string;
  workspaceDestinations: ListWorkspaceDestinationsResponse_WorkspaceDestination[];
  pagingData: PagingProps;
  hideType?: boolean;
}
export const WorkspaceDestinationsTable = ({ workspaceName, workspaceDestinations, pagingData, hideType }: Props) => {
  const tableData = useMemo<TableFields[]>(() => {
    return workspaceDestinations.map(dest => ({
      key: buildIdFromRef(dest.destinationRef),
      name: {
        name: dest.destinationRef?.name ?? '',
        status: dest.status,
        link: buildWorkspaceDestinationDetailsUrl(workspaceName, dest.type, dest.destinationRef)
      },
      type: dest.type,
      importStatus: dest.importStatus,
      namespace: dest.destinationRef?.namespace,
      clusterName: dest.destinationRef?.clusterName,
      hostsList: { list: dest.hosts }
    }));
  }, [workspaceDestinations, workspaceName]);

  return (
    <SoloTable
      columns={!hideType ? columns : columns.filter(c => c.title !== 'Type')}
      dataSource={tableData}
      paging={pagingData}
      data-testid={hideType ? 'virtual-destination-tab-table' : 'destination-tab-table'}
      renderEmpty={
        <SoloEmptyWithDocs
          icon={<Asset.ServiceIcon />}
          title='There are no Destinations to display'
          description=''
          href={docLinks.enterprise.workspace_export}
        />
      }
    />
  );
};
