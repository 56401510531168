import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { CardStyles } from 'Components/Common/Card';
import { Svg } from 'Components/Common/Svg';
import { CardFolderTab, CardFolderTabListWithBottomBorder } from 'Components/Common/Tabs';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useInsightEnablementChecks } from 'utils/dashboard/dashboard-hooks';
import { DashboardStyles } from '../../DashboardLanding.style';
import CiliumHealthTabContent from './Cilium/CiliumHealthTabContent';
import { GlooHealthTabContent } from './Gloo/GlooHealthTabContent';
import IstioHealthTabContent from './Istio/IstioHealthTabContent';

type TabDataItem = {
  key: string;
  title: JSX.Element;
  Component: () => JSX.Element;
};

const DashboardHealthCard = () => {
  di(useInsightEnablementChecks);
  const [tabIndex, setTabIndex] = useState(0);
  const { isCiliumEnabled, isIstioEnabledAndLicenced } = useInsightEnablementChecks();

  const tabData = useMemo(() => {
    const istioTab = {
      key: 'istio-health',
      title: (
        <Spacer my={-1}>
          <DashboardStyles.Header>
            <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.IstioLogo />} />
            Istio
          </DashboardStyles.Header>
        </Spacer>
      ),
      Component: IstioHealthTabContent
    };
    const ciliumTab = {
      key: 'cilium-health',
      title: (
        <Spacer my={-1}>
          <DashboardStyles.Header>
            <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.CiliumIcon />} />
            Cilium
          </DashboardStyles.Header>
        </Spacer>
      ),
      Component: CiliumHealthTabContent
    };
    const glooTab = {
      key: 'gloo-health',
      title: (
        <Spacer my={-1}>
          <DashboardStyles.Header>
            <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.GlooOutlineIcon />} />
            Gloo
          </DashboardStyles.Header>
        </Spacer>
      ),
      Component: GlooHealthTabContent
    };
    const tabs: TabDataItem[] = [];
    if (isIstioEnabledAndLicenced) {
      tabs.push(istioTab);
    }
    if (isCiliumEnabled) {
      tabs.push(ciliumTab);
    }
    // Always show gloo tab
    tabs.push(glooTab);
    return tabs;
  }, [isCiliumEnabled, isIstioEnabledAndLicenced]);

  return (
    <CardStyles.Card data-testid='istio-cilium-health-card'>
      <DashboardStyles.FullHeightTabsWrapper>
        <Tabs id='multiYamlTabs' index={tabIndex} onChange={setTabIndex}>
          <CardFolderTabListWithBottomBorder margin='0px'>
            {tabData.map(({ title, key }) => (
              <CardFolderTab key={key} width='auto' data-testid={`tab-${key}`}>
                {title}
              </CardFolderTab>
            ))}
          </CardFolderTabListWithBottomBorder>
          <TabPanels>
            {tabData.map(({ Component, key }) => (
              <TabPanel key={key}>
                <Component />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </DashboardStyles.FullHeightTabsWrapper>
    </CardStyles.Card>
  );
};

export default DashboardHealthCard;
