/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/portal.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { DestinationKind } from "../../../../api/gloo.solo.io/common/v2/selectors_pb";
import { Status } from "./common_pb";
import { WorkspaceDependencies } from "./common_pb";
import { WorkspaceStatus } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPortalApiDetailsRequest
 */
export interface GetPortalApiDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPortalApiDetailsResponse
 */
export interface GetPortalApiDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceDependencies dependencies = 2;
     */
    dependencies?: WorkspaceDependencies;
    /**
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef portal_refs = 3;
     */
    portalRefs: ClusterObjectRef[];
    /**
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef gateway_refs = 4;
     */
    gatewayRefs: ClusterObjectRef[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ApiDestination destinations = 5;
     */
    destinations: ApiDestination[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ApiDestination
 */
export interface ApiDestination {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetSchemaRequest
 */
export interface GetSchemaRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 1;
     */
    routeTableRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetSchemaResponse
 */
export interface GetSchemaResponse {
    /**
     * @generated from protobuf field: string schema = 1;
     */
    schema: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPortalDetailsRequest
 */
export interface GetPortalDetailsRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef portal_ref = 1;
     */
    portalRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetPortalDetailsResponse
 */
export interface GetPortalDetailsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 1;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef portal_config_ref = 2;
     */
    portalConfigRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: repeated string domains = 3;
     */
    domains: string[];
    /**
     * A list of RouteTable refs that are referenced as part of an API by this Portal
     *
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef published_apis = 4;
     */
    publishedApis: ClusterObjectRef[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.UsagePlan usage_plans = 5;
     */
    usagePlans: UsagePlan[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.UsagePlan
 */
export interface UsagePlan {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * The measurement units of the rate limit value (e.g., minutes, seconds)
     * Together with requests_per_unit, represents a rate limit in the form
     * [requests_per_unit] requests per [time_unit] (e.g. 3 requests per second)
     *
     * @generated from protobuf field: rpc.gloo.solo.io.UsagePlan.Unit time_unit = 4;
     */
    timeUnit: UsagePlan_Unit;
    /**
     * @generated from protobuf field: uint32 requests_per_unit = 5;
     */
    requestsPerUnit: number;
    /**
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef ext_auth_policies = 6;
     */
    extAuthPolicies: ClusterObjectRef[];
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef rate_limit_policy = 7;
     */
    rateLimitPolicy?: ClusterObjectRef;
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.UsagePlan.Unit
 */
export enum UsagePlan_Unit {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: SECOND = 1;
     */
    SECOND = 1,
    /**
     * @generated from protobuf enum value: MINUTE = 2;
     */
    MINUTE = 2,
    /**
     * @generated from protobuf enum value: HOUR = 3;
     */
    HOUR = 3,
    /**
     * @generated from protobuf enum value: DAY = 4;
     */
    DAY = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class GetPortalApiDetailsRequest$Type extends MessageType<GetPortalApiDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetPortalApiDetailsRequest", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPortalApiDetailsRequest
 */
export const GetPortalApiDetailsRequest = new GetPortalApiDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPortalApiDetailsResponse$Type extends MessageType<GetPortalApiDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetPortalApiDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "dependencies", kind: "message", T: () => WorkspaceDependencies },
            { no: 3, name: "portal_refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 4, name: "gateway_refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 5, name: "destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiDestination },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPortalApiDetailsResponse
 */
export const GetPortalApiDetailsResponse = new GetPortalApiDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiDestination$Type extends MessageType<ApiDestination> {
    constructor() {
        super("rpc.gloo.solo.io.ApiDestination", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ApiDestination
 */
export const ApiDestination = new ApiDestination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchemaRequest$Type extends MessageType<GetSchemaRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetSchemaRequest", [
            { no: 1, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetSchemaRequest
 */
export const GetSchemaRequest = new GetSchemaRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchemaResponse$Type extends MessageType<GetSchemaResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetSchemaResponse", [
            { no: 1, name: "schema", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetSchemaResponse
 */
export const GetSchemaResponse = new GetSchemaResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPortalDetailsRequest$Type extends MessageType<GetPortalDetailsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetPortalDetailsRequest", [
            { no: 1, name: "portal_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPortalDetailsRequest
 */
export const GetPortalDetailsRequest = new GetPortalDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPortalDetailsResponse$Type extends MessageType<GetPortalDetailsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetPortalDetailsResponse", [
            { no: 1, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 2, name: "portal_config_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "domains", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "published_apis", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 5, name: "usage_plans", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UsagePlan },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetPortalDetailsResponse
 */
export const GetPortalDetailsResponse = new GetPortalDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsagePlan$Type extends MessageType<UsagePlan> {
    constructor() {
        super("rpc.gloo.solo.io.UsagePlan", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "time_unit", kind: "enum", T: () => ["rpc.gloo.solo.io.UsagePlan.Unit", UsagePlan_Unit] },
            { no: 5, name: "requests_per_unit", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "ext_auth_policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 7, name: "rate_limit_policy", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.UsagePlan
 */
export const UsagePlan = new UsagePlan$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.PortalApi
 */
export const PortalApi = new ServiceType("rpc.gloo.solo.io.PortalApi", [
    { name: "GetSchema", options: {}, I: GetSchemaRequest, O: GetSchemaResponse },
    { name: "GetPortalApiDetails", options: {}, I: GetPortalApiDetailsRequest, O: GetPortalApiDetailsResponse },
    { name: "GetPortalDetails", options: {}, I: GetPortalDetailsRequest, O: GetPortalDetailsResponse }
]);
