import styled from '@emotion/styled';
import { policiesApi } from 'Api/policies';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { HttpMethodBubble, HttpMethodBubbleContainer } from 'Components/Common/HttpMethodBubble';
import { Loading } from 'Components/Common/Loading';
import { SoloEmptyWithDocs } from 'Components/Common/SoloEmpty';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { PagingProps, useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { RenderCellStringList, RenderCellStringListProps } from 'Components/Common/SoloTableCells';
import { AccessPolicySpec_Config_Authentication_TLSmode } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/security/access_policy_pb';
import {
  PolicyType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { GetAccessPolicyDetailsResponse_Client } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { docLinks } from 'utils/url-external-links-map';
import { BasicDestinationsTable } from '../../Destinations/BasicDestinationsTable';

const { useGetAccessPolicyDetails } = policiesApi;

const policyType = PolicyType.ACCESS;

const TLSmode = AccessPolicySpec_Config_Authentication_TLSmode;
const tlsModeMap = {
  [TLSmode.DISABLE]: 'DISABLE',
  [TLSmode.PERMISSIVE]: 'PERMISSIVE',
  [TLSmode.STRICT]: 'STRICT'
};

const TallHttpMethodBubbleContainer = styled(HttpMethodBubbleContainer)`
  height: 50px;
`;

type AllowedClientsTableFields = {
  key: string;
  account: string;
  principals: RenderCellStringListProps;
  nonPrincipals: RenderCellStringListProps;
};
const AllowedClientsTable = ({
  clients,
  paging
}: {
  clients?: GetAccessPolicyDetailsResponse_Client[];
  paging: PagingProps;
}) => {
  const [tableData, setTableData] = useState<AllowedClientsTableFields[]>([]);

  useEffect(() => {
    if (!!clients) {
      setTableData(
        clients.map(client => ({
          key: client.name,
          account: client.name,
          principals: { list: client.requestPrincipals },
          nonPrincipals: { list: client.requestNotPrincipals }
        }))
      );
    } else {
      setTableData([]);
    }
  }, [clients]);

  const columns: SoloColumnsType<AllowedClientsTableFields> = [
    { title: 'Service Account', dataIndex: 'account' },
    {
      title: 'Request principals',
      dataIndex: 'principals',
      render: RenderCellStringList
    },
    {
      title: 'Request not-principals',
      dataIndex: 'nonPrincipals',
      render: RenderCellStringList
    }
  ];
  return <SoloTable columns={columns} dataSource={tableData} paging={paging} />;
};

export const AllowedHttpMethodsList = ({ allowedMethodsList }: { allowedMethodsList?: string[] }) => {
  return (
    <SoloListCardStyles.Container>
      <SoloListCardStyles.Title>Allowed HTTP Methods</SoloListCardStyles.Title>
      <SoloListCardStyles.Body>
        {!!allowedMethodsList?.length ? (
          <TallHttpMethodBubbleContainer>
            {allowedMethodsList.map((method: string) => (
              <HttpMethodBubble key={method} method={method} />
            ))}
          </TallHttpMethodBubbleContainer>
        ) : (
          <SoloEmptyWithDocs
            icon={<Asset.HttpEmptyIcon />}
            title='No allowed HTTP methods configured'
            description=''
            href={docLinks.enterprise.access_policy}
          />
        )}
      </SoloListCardStyles.Body>
    </SoloListCardStyles.Container>
  );
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const AccessDetails = ({ clusterObjRef }: Props) => {
  di(useGetAccessPolicyDetails);
  const {
    pagingData: clientPagingData,
    apiPaginationObject: clientApiPaginationObject,
    useSetPagingTotal: useClientSetPagingTotal
  } = useSoloPaging();
  const {
    pagingData: destPagingData,
    apiPaginationObject: destApiPaginationObject,
    useSetPagingTotal: useDestSetPagingTotal
  } = useSoloPaging();

  const { data: policyData, error: policyError } = useGetAccessPolicyDetails(
    clusterObjRef,
    clientApiPaginationObject,
    destApiPaginationObject
  );

  useClientSetPagingTotal(policyData?.authorization?.totalClients);
  useDestSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  let secondaryInfo: { label: string; data: any; highlightColor?: string }[] = [];
  if (!!policyData?.tlsMode) {
    secondaryInfo = [
      {
        label: 'TLS mode',
        data: tlsModeMap[policyData.tlsMode]
      }
    ];
  }

  const auth = policyData.authorization;
  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
        secondaryInfo={secondaryInfo}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        {!auth?.allowedPaths.length &&
        !auth?.allowedMethods.length &&
        !auth?.allowedIpBlocks.length &&
        !auth?.allowedRemoteIpBlocks.length &&
        !auth?.allowedClients.length ? (
          <SoloEmptyWithDocs
            icon={<Asset.HttpEmptyIcon />}
            title='No authz fields configured'
            description=''
            href={docLinks.enterprise.access_policy}
          />
        ) : (
          <>
            <SoloListCardStyles.HorizontalListCardsContainer>
              {!!auth?.allowedPaths.length && <SoloListCard title='Allowed Paths' dataSource={auth.allowedPaths} />}

              {!!auth?.allowedMethods.length && <AllowedHttpMethodsList allowedMethodsList={auth.allowedMethods} />}

              {!!auth?.allowedIpBlocks.length && (
                <SoloListCard title='Allowed IP Blocks' dataSource={auth.allowedIpBlocks} />
              )}

              {!!auth?.allowedRemoteIpBlocks.length && (
                <SoloListCard title='Allowed Remote IP Blocks' dataSource={auth.allowedRemoteIpBlocks} />
              )}
            </SoloListCardStyles.HorizontalListCardsContainer>

            {!!auth?.allowedClients.length && (
              <>
                <CardStyles.CardHeader>Allowed Clients</CardStyles.CardHeader>
                <AllowedClientsTable clients={auth?.allowedClients} paging={clientPagingData} />
              </>
            )}
          </>
        )}
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable destinations={policyData.destinations?.destinations} paging={destPagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
