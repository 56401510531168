/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { DestinationKind } from "../../../../api/gloo.solo.io/common/v2/selectors_pb";
import { TLSRequestMatcher } from "../../../../api/gloo.solo.io/common/v2/tls_matchers_pb";
import { TCPRequestMatcher } from "../../../../api/gloo.solo.io/common/v2/tcp_matchers_pb";
import { PolicyType } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { Status } from "./common_pb";
import { HTTPRequestMatcher } from "../../../../api/gloo.solo.io/common/v2/http_matchers_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.Route
 */
export interface Route {
    /**
     * @generated from protobuf oneof: route_type
     */
    routeType: {
        oneofKind: "httpRoute";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.HTTPRoute http_route = 1;
         */
        httpRoute: HTTPRoute;
    } | {
        oneofKind: "httpRouteDelegated";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.HTTPRouteDelegated http_route_delegated = 2;
         */
        httpRouteDelegated: HTTPRouteDelegated;
    } | {
        oneofKind: "tcpRoute";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.TCPRoute tcp_route = 3;
         */
        tcpRoute: TCPRoute;
    } | {
        oneofKind: "tlsRoute";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.TLSRoute tls_route = 4;
         */
        tlsRoute: TLSRoute;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.HTTPRoute
 */
export interface HTTPRoute {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated common.gloo.solo.io.HTTPRequestMatcher matchers = 2;
     */
    matchers: HTTPRequestMatcher[];
    /**
     * @generated from protobuf field: map<string, string> labels = 3;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.RouteAction action = 4;
     */
    action?: RouteAction;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.AppliedPolicy applied_policies = 5;
     */
    appliedPolicies: AppliedPolicy[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.RouteAction
 */
export interface RouteAction {
    /**
     * @generated from protobuf oneof: action_type
     */
    actionType: {
        oneofKind: "forwardTo";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.ForwardToAction forward_to = 1;
         */
        forwardTo: ForwardToAction;
    } | {
        oneofKind: "delegate";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.DelegateAction delegate = 2;
         */
        delegate: DelegateAction;
    } | {
        oneofKind: "redirect";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.RedirectAction redirect = 3;
         */
        redirect: RedirectAction;
    } | {
        oneofKind: "directResponse";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.DirectResponseAction direct_response = 4;
         */
        directResponse: DirectResponseAction;
    } | {
        oneofKind: "graphql";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.GraphqlAction graphql = 5;
         */
        graphql: GraphqlAction;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.AppliedPolicy
 */
export interface AppliedPolicy {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.PolicyType type = 2;
     */
    type: PolicyType;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.HTTPRouteDelegated
 */
export interface HTTPRouteDelegated {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.HTTPRouteDelegated.DelegatedRouteRef route_names = 1;
     */
    routeNames: HTTPRouteDelegated_DelegatedRouteRef[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.MatcherList matcherLists = 2;
     */
    matcherLists: MatcherList[];
    /**
     * @generated from protobuf field: map<string, string> labels = 3;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.RouteAction action = 4;
     */
    action?: RouteAction;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.AppliedPolicy applied_policies = 5;
     */
    appliedPolicies: AppliedPolicy[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.HTTPRouteDelegated.DelegatedRouteRef
 */
export interface HTTPRouteDelegated_DelegatedRouteRef {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table = 1;
     */
    routeTable?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string route_name = 2;
     */
    routeName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.MatcherList
 */
export interface MatcherList {
    /**
     * @generated from protobuf field: repeated common.gloo.solo.io.HTTPRequestMatcher matchers = 1;
     */
    matchers: HTTPRequestMatcher[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.TCPRoute
 */
export interface TCPRoute {
    /**
     * @generated from protobuf field: repeated common.gloo.solo.io.TCPRequestMatcher matchers = 1;
     */
    matchers: TCPRequestMatcher[];
    /**
     * @generated from protobuf field: map<string, string> labels = 2;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf oneof: action_type
     */
    actionType: {
        oneofKind: "forwardTo";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.ForwardToAction forward_to = 3;
         */
        forwardTo: ForwardToAction;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.TLSRoute
 */
export interface TLSRoute {
    /**
     * @generated from protobuf field: repeated common.gloo.solo.io.TLSRequestMatcher matchers = 1;
     */
    matchers: TLSRequestMatcher[];
    /**
     * @generated from protobuf field: map<string, string> labels = 2;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf oneof: action_type
     */
    actionType: {
        oneofKind: "forwardTo";
        /**
         * @generated from protobuf field: rpc.gloo.solo.io.TLSForwardToAction forward_to = 3;
         */
        forwardTo: TLSForwardToAction;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ForwardToAction
 */
export interface ForwardToAction {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ForwardToDestination destinations = 1;
     */
    destinations: ForwardToDestination[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ForwardToDestination
 */
export interface ForwardToDestination {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef destination_ref = 1;
     */
    destinationRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind type = 2;
     */
    type: DestinationKind;
    /**
     * @generated from protobuf field: uint32 port = 3;
     */
    port: number;
    /**
     * @generated from protobuf field: uint32 weight = 4;
     */
    weight: number;
    /**
     * true if the referenced service has an associated OpenAPI schema in an ApiDoc resource
     *
     * @generated from protobuf field: bool has_openapi_api = 5;
     */
    hasOpenapiApi: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DelegateAction
 */
export interface DelegateAction {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.DelegateAction.RouteTableStatus route_tables = 1;
     */
    routeTables: DelegateAction_RouteTableStatus[];
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 2;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DelegateAction.RouteTableStatus
 */
export interface DelegateAction_RouteTableStatus {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef ref = 1;
     */
    ref?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 2;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.RedirectAction
 */
export interface RedirectAction {
    /**
     * @generated from protobuf field: string host_redirect = 1;
     */
    hostRedirect: string;
    /**
     * @generated from protobuf field: string path_redirect = 2;
     */
    pathRedirect: string;
    /**
     * @generated from protobuf field: uint32 status_code = 3;
     */
    statusCode: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.DirectResponseAction
 */
export interface DirectResponseAction {
    /**
     * @generated from protobuf field: uint32 status_code = 1;
     */
    statusCode: number;
    /**
     * @generated from protobuf field: string body = 2;
     */
    body: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GraphqlAction
 */
export interface GraphqlAction {
    /**
     * @generated from protobuf field: string istio_route_name = 1;
     */
    istioRouteName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.TLSForwardToAction
 */
export interface TLSForwardToAction {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ForwardToDestination destinations = 1;
     */
    destinations: ForwardToDestination[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.RouteTableDetails
 */
export interface RouteTableDetails {
    /**
     * @generated from protobuf field: repeated string hosts = 1;
     */
    hosts: string[];
    /**
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef virtual_gateways = 2;
     */
    virtualGateways: ClusterObjectRef[];
    /**
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef workloads = 3;
     */
    workloads: ClusterObjectRef[];
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.Route routes = 4;
     */
    routes: Route[];
    /**
     * @generated from protobuf field: int32 total_routes = 5;
     */
    totalRoutes: number;
    /**
     * true if the RouteTable has one or more routes with one or more destinations with an associated schema
     *
     * @generated from protobuf field: bool has_openapi_schema = 6;
     */
    hasOpenapiSchema: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class Route$Type extends MessageType<Route> {
    constructor() {
        super("rpc.gloo.solo.io.Route", [
            { no: 1, name: "http_route", kind: "message", oneof: "routeType", T: () => HTTPRoute },
            { no: 2, name: "http_route_delegated", kind: "message", oneof: "routeType", T: () => HTTPRouteDelegated },
            { no: 3, name: "tcp_route", kind: "message", oneof: "routeType", T: () => TCPRoute },
            { no: 4, name: "tls_route", kind: "message", oneof: "routeType", T: () => TLSRoute }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.Route
 */
export const Route = new Route$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTPRoute$Type extends MessageType<HTTPRoute> {
    constructor() {
        super("rpc.gloo.solo.io.HTTPRoute", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HTTPRequestMatcher },
            { no: 3, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "action", kind: "message", T: () => RouteAction },
            { no: 5, name: "applied_policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppliedPolicy },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.HTTPRoute
 */
export const HTTPRoute = new HTTPRoute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteAction$Type extends MessageType<RouteAction> {
    constructor() {
        super("rpc.gloo.solo.io.RouteAction", [
            { no: 1, name: "forward_to", kind: "message", oneof: "actionType", T: () => ForwardToAction },
            { no: 2, name: "delegate", kind: "message", oneof: "actionType", T: () => DelegateAction },
            { no: 3, name: "redirect", kind: "message", oneof: "actionType", T: () => RedirectAction },
            { no: 4, name: "direct_response", kind: "message", oneof: "actionType", T: () => DirectResponseAction },
            { no: 5, name: "graphql", kind: "message", oneof: "actionType", T: () => GraphqlAction }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.RouteAction
 */
export const RouteAction = new RouteAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppliedPolicy$Type extends MessageType<AppliedPolicy> {
    constructor() {
        super("rpc.gloo.solo.io.AppliedPolicy", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.gloo.solo.io.PolicyType", PolicyType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.AppliedPolicy
 */
export const AppliedPolicy = new AppliedPolicy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTPRouteDelegated$Type extends MessageType<HTTPRouteDelegated> {
    constructor() {
        super("rpc.gloo.solo.io.HTTPRouteDelegated", [
            { no: 1, name: "route_names", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HTTPRouteDelegated_DelegatedRouteRef },
            { no: 2, name: "matcherLists", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatcherList },
            { no: 3, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "action", kind: "message", T: () => RouteAction },
            { no: 5, name: "applied_policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppliedPolicy },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.HTTPRouteDelegated
 */
export const HTTPRouteDelegated = new HTTPRouteDelegated$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTPRouteDelegated_DelegatedRouteRef$Type extends MessageType<HTTPRouteDelegated_DelegatedRouteRef> {
    constructor() {
        super("rpc.gloo.solo.io.HTTPRouteDelegated.DelegatedRouteRef", [
            { no: 1, name: "route_table", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.HTTPRouteDelegated.DelegatedRouteRef
 */
export const HTTPRouteDelegated_DelegatedRouteRef = new HTTPRouteDelegated_DelegatedRouteRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatcherList$Type extends MessageType<MatcherList> {
    constructor() {
        super("rpc.gloo.solo.io.MatcherList", [
            { no: 1, name: "matchers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HTTPRequestMatcher }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.MatcherList
 */
export const MatcherList = new MatcherList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TCPRoute$Type extends MessageType<TCPRoute> {
    constructor() {
        super("rpc.gloo.solo.io.TCPRoute", [
            { no: 1, name: "matchers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TCPRequestMatcher },
            { no: 2, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "forward_to", kind: "message", oneof: "actionType", T: () => ForwardToAction },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.TCPRoute
 */
export const TCPRoute = new TCPRoute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TLSRoute$Type extends MessageType<TLSRoute> {
    constructor() {
        super("rpc.gloo.solo.io.TLSRoute", [
            { no: 1, name: "matchers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TLSRequestMatcher },
            { no: 2, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "forward_to", kind: "message", oneof: "actionType", T: () => TLSForwardToAction },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.TLSRoute
 */
export const TLSRoute = new TLSRoute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForwardToAction$Type extends MessageType<ForwardToAction> {
    constructor() {
        super("rpc.gloo.solo.io.ForwardToAction", [
            { no: 1, name: "destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ForwardToDestination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ForwardToAction
 */
export const ForwardToAction = new ForwardToAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForwardToDestination$Type extends MessageType<ForwardToDestination> {
    constructor() {
        super("rpc.gloo.solo.io.ForwardToDestination", [
            { no: 1, name: "destination_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "weight", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "has_openapi_api", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ForwardToDestination
 */
export const ForwardToDestination = new ForwardToDestination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelegateAction$Type extends MessageType<DelegateAction> {
    constructor() {
        super("rpc.gloo.solo.io.DelegateAction", [
            { no: 1, name: "route_tables", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DelegateAction_RouteTableStatus },
            { no: 2, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DelegateAction
 */
export const DelegateAction = new DelegateAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelegateAction_RouteTableStatus$Type extends MessageType<DelegateAction_RouteTableStatus> {
    constructor() {
        super("rpc.gloo.solo.io.DelegateAction.RouteTableStatus", [
            { no: 1, name: "ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DelegateAction.RouteTableStatus
 */
export const DelegateAction_RouteTableStatus = new DelegateAction_RouteTableStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedirectAction$Type extends MessageType<RedirectAction> {
    constructor() {
        super("rpc.gloo.solo.io.RedirectAction", [
            { no: 1, name: "host_redirect", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path_redirect", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status_code", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.RedirectAction
 */
export const RedirectAction = new RedirectAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirectResponseAction$Type extends MessageType<DirectResponseAction> {
    constructor() {
        super("rpc.gloo.solo.io.DirectResponseAction", [
            { no: 1, name: "status_code", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.DirectResponseAction
 */
export const DirectResponseAction = new DirectResponseAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphqlAction$Type extends MessageType<GraphqlAction> {
    constructor() {
        super("rpc.gloo.solo.io.GraphqlAction", [
            { no: 1, name: "istio_route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GraphqlAction
 */
export const GraphqlAction = new GraphqlAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TLSForwardToAction$Type extends MessageType<TLSForwardToAction> {
    constructor() {
        super("rpc.gloo.solo.io.TLSForwardToAction", [
            { no: 1, name: "destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ForwardToDestination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.TLSForwardToAction
 */
export const TLSForwardToAction = new TLSForwardToAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteTableDetails$Type extends MessageType<RouteTableDetails> {
    constructor() {
        super("rpc.gloo.solo.io.RouteTableDetails", [
            { no: 1, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "virtual_gateways", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 3, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef },
            { no: 4, name: "routes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Route },
            { no: 5, name: "total_routes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "has_openapi_schema", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.RouteTableDetails
 */
export const RouteTableDetails = new RouteTableDetails$Type();
