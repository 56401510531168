import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { Loading } from 'Components/Common/Loading';
import { HealthCountBoxStyles } from 'Components/Common/Overview/HealthCountBox.style';
import { SoloLink } from 'Components/Common/SoloLink';
import { Svg, SvgWithTextContainer } from 'Components/Common/Svg';
import {
  Insight,
  Insight_Severity
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { di } from 'react-magnetic-di';
import { useCheckIfDashboardInsightsPassedLimit, useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { buildCertificatesUrl } from 'utils/url-builders';
import { DashboardStyles } from '../../DashboardLanding.style';
import { CertExpiryStyles } from './CertificatesExpiryCard.style';
import CertificatesExpiryCardContent from './CertificatesExpiryCardContent';
import { useFilterDuplicateCertificates } from './hooks';
import { CertificateExpiryKey, certificatesExpiryCodesMap } from './insight-codes';

const CertificatesExpiryCard = () => {
  di(useFilteredDashboardInsights);
  //
  // Get cert insights data
  //
  const insightsLimitPassedData = useCheckIfDashboardInsightsPassedLimit('certs');
  const rootCertInsights = useFilteredDashboardInsights(certificatesExpiryCodesMap, CertificateExpiryKey.rootCAExpiry);
  const intermediateCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.intermediateCAExpiry
  );
  const istioGatewayCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.istioGatewayCAExpiry
  );
  const k8GatewayCertInsights = useFilteredDashboardInsights(
    certificatesExpiryCodesMap,
    CertificateExpiryKey.k8GatewayCAExpiry
  );

  const { rootCertInsightsFiltered } = useFilterDuplicateCertificates(rootCertInsights);

  const infoFilter = (ins: Insight) => ins.severity === Insight_Severity.INFO;
  const rootCertInsightsAllCount = rootCertInsights?.filter(infoFilter).length ?? 0;
  const rootCertInsightsFilteredCount = rootCertInsightsFiltered?.filter(infoFilter).length ?? 0;
  const intermediateCertCount = intermediateCertInsights?.filter(infoFilter).length ?? 0;
  const istioGatewayCertCount = istioGatewayCertInsights?.filter(infoFilter).length ?? 0;
  const k8GatewayCertCount = k8GatewayCertInsights?.filter(infoFilter).length ?? 0;

  const totalCertsCount = rootCertInsightsAllCount + intermediateCertCount + istioGatewayCertCount + k8GatewayCertCount;
  const totalFilteredCertsCount =
    rootCertInsightsFilteredCount + intermediateCertCount + istioGatewayCertCount + k8GatewayCertCount;

  const isLoading =
    rootCertInsights === undefined ||
    intermediateCertInsights === undefined ||
    istioGatewayCertInsights === undefined ||
    k8GatewayCertInsights === undefined;

  const dupes = totalCertsCount - totalFilteredCertsCount;

  //
  // Render
  //
  return (
    <CardStyles.Card data-testid='certs-expiry-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.CertExpiryIcon />} color={theme => theme.neptuneBlue} />
        Certificates Expiry
      </DashboardStyles.Header>
      {isLoading ? (
        <Loading />
      ) : (
        <CertExpiryStyles.CardContentAndFooterContainer>
          <CertificatesExpiryCardContent
            rootCertInsights={rootCertInsightsFiltered ?? []}
            intermediateCertInsights={intermediateCertInsights}
          />
          {!!insightsLimitPassedData?.limitPassed && (
            <CertExpiryStyles.LimitWarning>
              <SvgWithTextContainer>
                <Svg asset={<Asset.WarningCircleIcon />} size={17} />
                Some certificates could not be rendered. Please click on 'Details' to view all certificates.
              </SvgWithTextContainer>
            </CertExpiryStyles.LimitWarning>
          )}
          <CertExpiryStyles.CardFooterContainer>
            Total Certificates{' '}
            {dupes === 0 ? (
              <HealthCountBoxStyles.CountDisplay data-testid='total-certs-count' data-dupes-count='0'>
                {totalFilteredCertsCount}
              </HealthCountBoxStyles.CountDisplay>
            ) : (
              <Tooltip
                title={
                  <>
                    <span data-testid='dupe-certs-count'>{dupes}</span> duplicate certificates omitted from total count.
                  </>
                }>
                <HealthCountBoxStyles.CountDisplay data-testid='total-certs-count' data-dupes-count={dupes}>
                  {totalFilteredCertsCount}
                </HealthCountBoxStyles.CountDisplay>
              </Tooltip>
            )}
            <DashboardStyles.CardBottomLink>
              <SoloLink small link={buildCertificatesUrl()} withArrow>
                DETAILS
              </SoloLink>
            </DashboardStyles.CardBottomLink>
          </CertExpiryStyles.CardFooterContainer>
        </CertExpiryStyles.CardContentAndFooterContainer>
      )}
    </CardStyles.Card>
  );
};

export default CertificatesExpiryCard;
