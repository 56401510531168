import { getNodeIcon } from 'Components/Features/Graph/Canvas/NodeLayer';
import {
  GraphContainerType,
  GraphMetricsType,
  GraphStatus
} from 'Components/Features/Graph/General/graph-selection-utils';
import cytoscape from 'cytoscape';
import { NodeType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { Color, colors } from './colors';

export const arrowScale = 1.25;

interface GraphItemColors {
  node: Color;
  nodeIcon: string;
  nodeHoverOutline: Color;
  edge: Color;
}

export const httpGraphItemColors: Record<GraphStatus, GraphItemColors> = {
  [GraphStatus.Healthy]: {
    node: colors.seaBlue,
    nodeIcon: colors.seaBlue,
    nodeHoverOutline: colors.puddleBlue,
    edge: colors.forestGreen
  },
  [GraphStatus.Warning]: {
    node: colors.novaGold,
    nodeIcon: colors.novaGold,
    nodeHoverOutline: colors.lampGold,
    edge: colors.sunGold
  },
  [GraphStatus.Error]: {
    node: colors.butternutOrange,
    nodeIcon: colors.butternutOrange,
    nodeHoverOutline: colors.tangerineOrange,
    edge: colors.butternutOrange
  },
  [GraphStatus.Idle]: {
    node: colors.septemberGrey,
    nodeIcon: colors.septemberGrey,
    nodeHoverOutline: colors.aprilGrey,
    edge: colors.septemberGrey
  }
};

export const tcpGraphItemColors: Record<GraphStatus.Idle | 'default', GraphItemColors> = {
  [GraphStatus.Idle]: {
    node: colors.septemberGrey,
    nodeIcon: colors.septemberGrey,
    nodeHoverOutline: colors.aprilGrey,
    edge: colors.oceanBlue
  },
  default: {
    node: colors.oceanBlue,
    nodeIcon: colors.seaBlue,
    nodeHoverOutline: colors.augustGrey,
    edge: colors.oceanBlue
  }
};

export const ciliumGraphItemColors: Record<GraphStatus, GraphItemColors> = {
  [GraphStatus.Healthy]: {
    node: colors.oceanBlue,
    nodeIcon: colors.seaBlue,
    nodeHoverOutline: colors.juneGrey,
    edge: colors.oceanBlue
  },
  [GraphStatus.Warning]: {
    node: colors.novaGold,
    nodeIcon: colors.novaGold,
    nodeHoverOutline: colors.lampGold,
    edge: colors.oceanBlue
  },
  [GraphStatus.Error]: {
    node: colors.butternutOrange,
    nodeIcon: colors.butternutOrange,
    nodeHoverOutline: colors.butternutOrange,
    edge: colors.oceanBlue
  },
  [GraphStatus.Idle]: {
    node: colors.septemberGrey,
    nodeIcon: colors.septemberGrey,
    nodeHoverOutline: colors.aprilGrey,
    edge: colors.oceanBlue
  }
};

export function getGraphItemColors(metricsType: GraphMetricsType, health: GraphStatus): GraphItemColors {
  switch (metricsType) {
    case GraphMetricsType.Http:
      return httpGraphItemColors[health];
    case GraphMetricsType.Tcp:
      return health === GraphStatus.Idle ? tcpGraphItemColors[health] : tcpGraphItemColors.default;
    case GraphMetricsType.Cilium:
      return ciliumGraphItemColors[health];
  }
}

export const graphZIndex = {
  // cytoscapeCanvas: 1, // (controlled by cytoscape)
  canvasOverlay: 3,
  // We want asides to appear over graph and graph overlay (but not over dropdowns)
  detailsTab: 5,
  usageInfoTab: 6, // original css haz this z-index be 1 more than details tab - not sure why, but doesn't hurt to keep it
  emptyPromptBox: 8, // we want it to appear over everything except dropdowns
  // dropdownMenu: 9, // (site wide setting)
  footer: 12 // I think this was only needed from when it used to cover the graph/asides (which it doesn't anymore) - doesn't hurt to keep it though
};

function calcEdgeOpacity(baseOpacity: number, elem: cytoscape.EdgeSingular) {
  return baseOpacity * (elem.data('externalFromSearch') ? 0.6 : 1);
}

/* For use with cytoscape graphs, in the Graph components folder */
export const graphStylesheet: any = [
  {
    selector: 'core',
    style: {
      'z-index-compare': 'manual'
    }
  },

  /////////////
  // LABEL
  /////////////
  {
    selector: '*',
    style: {
      'font-size': '14px',
      'font-weight': '500',
      'text-valign': 'top',
      'text-margin-y': -5,
      'text-background-color': '#35393b',
      color: 'white',
      'text-background-opacity': 1,
      'text-background-padding': '6px',
      'text-background-shape': 'roundrectangle',
      'z-index': 100,
      'z-index-compare': 'manual',
      'font-family': 'Proxima Nova, Open Sans, Helvetica, Arial, sans-serif, pficon'
    }
  },

  /////////
  // NODE
  /////////
  {
    selector: 'node',
    style: {
      width: 45,
      height: 45,
      shape: 'ellipse',
      'background-image': 'none',
      'background-opacity': 0,
      'background-width': '65%',
      'background-height': '65%',
      'border-width': 3,
      // label: 'data(name)',
      label: '', // These nodes use custom label support via canvas layer drawing
      'z-index': 10,
      'z-index-compare': 'manual',
      'z-compound-depth': 'top',
      opacity: 0
    }
  },
  // These cases are handled entirely inside the NetworkGraphCanvas.tsx -> renderNode() now
  //  keeping here as this is where you would think to look and want to pointer
  //   in proper direction
  /*{
    selector: 'node.hovered',
    style: {
      opacity: '.5'
    }
  },
  {
    selector: 'node.unselected',
    style: {
      opacity: 0.5
    }
  },
  {
    selector: 'node.onlyErrorsHighlight',
    style: {
      opacity: 0.5
    }
  },
  {
    selector: `node.onlyErrorsHighlight[health = "${GraphStatus.Error}"],node.onlyErrorsHighlight[health = "${GraphStatus.Warning}"]`,
    style: {
      opacity: 1
    }
  },
  {
    selector: 'node.selected.onlyErrorsHighlight',
    style: {
      opacity: 1
    }
  },
  {
    selector: 'node.unselected.onlyErrorsHighlight',
    style: {
      opacity: 0.5
    }
  },
  
  {
    selector: 'node[?externalFromSearch]',
    style: {
      opacity: 0.7 * other opacity
    }
  },
  */
  // These are not opacity questions. These cases
  //  need to not be click or hover-able
  {
    selector: `node[idleHidden = 'hide']`,
    style: {
      display: 'none'
    }
  },
  {
    selector: `node[nameHidden = 'hide']`,
    style: {
      display: 'none'
    }
  },
  {
    selector: `node[typeHidden = 'hide']`,
    style: {
      display: 'none'
    }
  },

  {
    selector: 'node.selected',
    style: {
      'font-size': '16px',
      'z-index': 1000,
      'background-color': colors.seaBlue
      //label: 'data(namespace)'
    }
  },
  {
    selector: `node[health = "${GraphStatus.Healthy}"]`,
    style: {
      'border-color': colors.seaBlue
    }
  },
  {
    selector: `node[health = "${GraphStatus.Error}"]`,
    style: {
      'border-color': colors.grapefruitOrange
    }
  },
  {
    selector: `node[health = "${GraphStatus.Idle}"]`,
    style: {
      'border-color': colors.septemberGrey
    }
  },
  {
    selector: `node[health = "${GraphStatus.Warning}"]`,
    style: {
      'border-color': colors.sunGold
    }
  },

  // Handle icons for all node types
  // Node drawing is currently handled in `NodeLayer` instead, but behavior left here as a backup
  {
    selector: `node[nodeType]`,
    style: {
      'background-image': (elem: cytoscape.NodeSingular) => {
        return getNodeIcon(
          elem.data('metricsType') ?? GraphMetricsType.Http,
          elem.data('nodeType') ?? NodeType.KUBERNETES_WORKLOAD,
          elem.data('health') ?? GraphStatus.Healthy
        );
      }
    }
  },

  {
    selector: 'node[?containerType][?externalFromSearch]',
    style: {
      'background-color': colors.splashBlue,
      'border-color': colors.splashBlue,
      opacity: 0.3,
      'z-index': 20
    }
  },
  {
    selector: `node[metricsType = "${GraphMetricsType.Tcp}"]`,
    style: {
      'border-color': tcpGraphItemColors.default.node
    }
  },
  {
    selector: `node[metricsType = "${GraphMetricsType.Tcp}"][health = "${GraphStatus.Idle}"]`,
    style: {
      'border-color': tcpGraphItemColors.Idle.node
    }
  },
  {
    selector: `node[metricsType = "${GraphMetricsType.Cilium}"][health = "${GraphStatus.Healthy}"]`,
    style: {
      'border-color': ciliumGraphItemColors.Healthy.edge
    }
  },
  {
    selector: `node[metricsType = "${GraphMetricsType.Cilium}"][health = "${GraphStatus.Error}"]`,
    style: {
      'border-color': ciliumGraphItemColors.Error.edge
    }
  },
  {
    selector: `node[metricsType = "${GraphMetricsType.Cilium}"][health = "${GraphStatus.Warning}"]`,
    style: {
      'border-color': ciliumGraphItemColors.Warning.edge
    }
  },
  {
    selector: `node[metricsType = "${GraphMetricsType.Cilium}"][health = "${GraphStatus.Idle}"]`,
    style: {
      'border-color': ciliumGraphItemColors.Idle.node
    }
  },

  {
    selector: 'node[?containerType]',
    style: {
      'background-image': 'none',
      'background-fill': 'solid',
      'background-opacity': 1,
      'background-color': 'white',
      'border-width': '2px',
      // label: 'data(label)',
      label: '', // These nodes use custom label support via canvas layer drawing
      'text-valign': 'bottom',
      'text-background-padding': '6px',
      shape: 'round-rectangle',
      'z-index': 1,
      'z-compound-depth': 'bottom',
      padding: '30px'
    }
  },
  {
    selector: `node[containerType = "${GraphContainerType.Namespace}"]`,
    style: {
      'border-color': colors.pondBlue,
      'text-background-color': colors.pondBlue,
      opacity: 0.84,
      'z-index': 3
    }
  },
  {
    selector: `node[containerType = "${GraphContainerType.Cluster}"]`,
    style: {
      'border-color': colors.seaBlue,
      'text-background-color': colors.seaBlue,
      opacity: 0.92,
      'z-index': 2
    }
  },
  {
    selector: `node[containerType = "${GraphContainerType.Workspace}"]`,
    style: {
      'border-color': colors.neptuneBlue,
      'text-background-color': colors.neptuneBlue,
      opacity: 0.9,
      'z-index': 1
    }
  },
  {
    selector: `node[containerType = "${GraphContainerType.VPC}"]`,
    style: {
      'border-color': colors.neptuneBlue,
      'text-background-color': colors.neptuneBlue,
      opacity: 0.9,
      'z-index': 1
    }
  },
  {
    selector: 'node[?containerType].selected',
    style: {
      'z-index': 1,
      'font-size': '14px',
      'border-color': colors.lakeBlue
    }
  },
  {
    selector: 'node[?containerType][?externalFromSearch]',
    style: {
      'background-color': colors.splashBlue,
      'border-color': colors.splashBlue,
      opacity: 0.4,
      'z-index': 20
    }
  },
  {
    selector: 'node[?containerType][?externalFromSearch].selected',
    style: {
      'background-color': colors.januaryGrey,
      'border-color': colors.lakeBlue,
      opacity: 0.8
    }
  },
  {
    selector: 'node[?containerType].unselected',
    style: {
      'background-color': colors.marchGrey,
      'border-color': colors.marchGrey,
      color: colors.augustGrey
    }
  },
  {
    selector: 'node[?containerType][?hidden]',
    style: {
      display: 'none'
    }
  },

  ///////
  ///EDGE
  ///////
  {
    selector: 'edge',
    style: {
      width: 3,
      'curve-style': 'bezier',
      'text-rotation': 'autorotate',
      'line-style': 'dashed',
      'line-color': colors.forestGreen,
      'target-arrow-color': colors.forestGreen,
      'source-arrow-color': colors.forestGreen,
      'target-arrow-shape': 'triangle',
      'source-arrow-shape': 'none',
      'arrow-scale': arrowScale,
      'z-index-compare': 'manual',
      'z-index': 2,
      opacity: (elem: cytoscape.EdgeSingular) => calcEdgeOpacity(1, elem),
      'text-opacity': 1,
      'text-margin-y': 1,
      'text-background-opacity': 0,
      'text-outline-color': colors.copseGreen,
      'text-outline-width': 3,
      'target-distance-from-node': 3,
      color: colors.forestGreen
    }
  },
  {
    selector: `edge[health = "${GraphStatus.Error}"]`,
    style: {
      'line-color': colors.butternutOrange,
      'target-arrow-color': colors.butternutOrange,
      'source-arrow-color': colors.butternutOrange,
      'text-outline-color': colors.tangerineOrange,
      color: colors.butternutOrange
    }
  },
  {
    selector: `edge[health = "${GraphStatus.Idle}"]`,
    style: {
      'line-color': colors.septemberGrey,
      'target-arrow-color': colors.septemberGrey,
      'source-arrow-color': colors.septemberGrey,
      'text-outline-color': colors.januaryGrey,
      color: colors.septemberGrey
    }
  },
  {
    selector: `edge[health = "${GraphStatus.Warning}"]`,
    style: {
      'line-color': colors.sunGold,
      'target-arrow-color': colors.sunGold,
      'source-arrow-color': colors.sunGold,
      'text-outline-color': colors.sunGold,
      color: colors.sunGold
    }
  },
  /*{
    selector: 'edge.selected',
    style: {
      width: 5,
      'line-color': colors.seaBlue,
      'target-arrow-color': colors.seaBlue,
      'source-arrow-color': colors.seaBlue,
      'target-arrow-shape': 'triangle',
      'font-size': '18px',
      'z-index': 100,
      label: 'data(requestCount)'
    }
  },*/
  {
    selector: 'edge.twoWayEdge',
    style: {
      'source-arrow-shape': 'triangle',
      'source-distance-from-node': 3
    }
  },
  {
    selector: 'edge.unselected',
    style: {
      opacity: (elem: cytoscape.EdgeSingular) => calcEdgeOpacity(0.5, elem),
      'line-color': colors.marchGrey,
      'target-arrow-shape': 'none',
      'source-arrow-shape': 'none',
      'target-distance-from-node': 0,
      'source-distance-from-node': 0,
      'text-opacity': 0
    }
  },
  {
    selector: 'edge.onlyErrorsHighlight',
    style: {
      opacity: (elem: cytoscape.EdgeSingular) => calcEdgeOpacity(0.2, elem)
    }
  },
  {
    selector: 'edge.selected.onlyErrorsHighlight',
    style: {
      opacity: (elem: cytoscape.EdgeSingular) => calcEdgeOpacity(1, elem)
    }
  },
  {
    selector: `edge.onlyErrorsHighlight[health = "${GraphStatus.Error}"]`,
    style: {
      opacity: (elem: cytoscape.EdgeSingular) => calcEdgeOpacity(1, elem)
    }
  },
  {
    selector: `edge.onlyErrorsHighlight[health = "${GraphStatus.Warning}"]`,
    style: {
      opacity: (elem: cytoscape.EdgeSingular) => calcEdgeOpacity(1, elem)
    }
  },
  {
    selector: 'edge.mtlsEnabledEdge',
    style: {
      'font-family': 'icomoon',
      'text-outline-color': 'white',
      'text-outline-width': 3,
      color: colors.seaBlue,

      // Icon is drawn on LabelLayer to avoid square box when label is small
      label: ''
    }
  },
  {
    selector: 'edge.mtlsDisabledEdge',
    style: {
      'font-family': 'icomoon',
      'text-outline-color': 'white',
      'text-outline-width': 3,
      color: colors.butternutOrange,

      // Icon is drawn on LabelLayer to avoid square box when label is small
      label: ''
    }
  },
  {
    selector: `edge[metricsType = "${GraphMetricsType.Tcp}"]`,
    style: {
      'line-color': tcpGraphItemColors.default.edge,
      'target-arrow-shape': 'none',
      'source-arrow-shape': 'none',
      'line-style': 'solid'
    }
  },
  {
    selector: `edge[metricsType = "${GraphMetricsType.Cilium}"]`,
    style: {
      'line-color': colors.oceanBlue,
      'target-arrow-color': colors.oceanBlue,
      'source-arrow-color': colors.oceanBlue,
      'line-style': 'solid',
      color: 'black'
    }
  },
  {
    selector: 'edge.animated',
    style: {
      // When the line is animated we manually draw it in NetworkGraphCanvas
      // so the cytoscape version of the line should be hidden
      'line-opacity': 0
    }
  },
  {
    selector: 'edge[?containerTie]',
    style: {
      opacity: 0,
      label: ''
    }
  },
  {
    selector: `edge[idleHidden = 'hide']`,
    style: {
      opacity: 0
    }
  },
  {
    selector: `edge[nameHidden = 'hide']`,
    style: {
      opacity: 0
    }
  },
  {
    selector: `edge[typeHidden = 'hide']`,
    style: {
      opacity: 0
    }
  }
];
