import cytoscape from 'cytoscape';
import React, { useEffect, useState } from 'react';
import { CyCanvasData } from './canvas-utils';

import { renderEdgeAnimation } from './EdgeLayer';
import { renderLabels } from './LabelLayer';
import { renderNodes } from './NodeLayer';

let lastTimestamp = 0;

function createCanvasData(cyRef: cytoscape.Core): CyCanvasData {
  // @ts-ignore
  let layer = cyRef.cyCanvas();
  let canvas = layer.getCanvas();
  let ctx = canvas.getContext('2d');
  return { layer, canvas, ctx };
}

interface NetworkGraphCanvasProps {
  cyRef: cytoscape.Core;
  animationOff?: boolean;
}
export const NetworkGraphCanvas = React.memo(
  ({ cyRef, animationOff }: NetworkGraphCanvasProps) => {
    const [edgeCanvasData, setEdgeCanvasData] = useState<CyCanvasData>();
    const [nodeCanvasData, setNodeCanvasData] = useState<CyCanvasData>();
    const [labelCanvasData, setLabelCanvasData] = useState<CyCanvasData>();

    useEffect(() => {
      // @ts-ignore
      let animationData = createCanvasData(cyRef);
      let nodeData = createCanvasData(cyRef);
      let labelData = createCanvasData(cyRef);

      setEdgeCanvasData(animationData);
      setNodeCanvasData(nodeData);
      setLabelCanvasData(labelData);

      return () => {
        if (animationData.layer) {
          animationData.layer.clear(animationData.ctx);
        }
        if (nodeData.layer) {
          nodeData.layer.clear(nodeData.ctx);
        }
        if (labelData.layer) {
          labelData.layer.clear(labelData.ctx);
        }
      };
    }, []);

    useEffect(() => {
      if (!edgeCanvasData) {
        return;
      }

      if (!!animationOff) {
        const { layer, ctx } = edgeCanvasData as CyCanvasData;
        layer.clear(ctx);

        return;
      }

      let requestAnimationFrameId: number;
      // Handle the actual update loop
      const doAnimationUpdateLoop = (timestamp: number) => {
        let dt = (timestamp - lastTimestamp) / 1000;

        renderEdgeAnimation(dt, cyRef, edgeCanvasData);

        lastTimestamp = timestamp;
        requestAnimationFrameId = window.requestAnimationFrame(
          doAnimationUpdateLoop
        );
      };
      requestAnimationFrameId = window.requestAnimationFrame(
        doAnimationUpdateLoop
      );

      // cyRef.on("render cyCanvas.resize", doRender);

      return () => {
        // cyRef.off("render cyCanvas.resize", doRender);
        window.cancelAnimationFrame(requestAnimationFrameId);
      };
    }, [cyRef, edgeCanvasData, animationOff]);

    useEffect(() => {
      if (!nodeCanvasData) {
        return;
      }

      const doRenderNodes = () => {
        renderNodes(cyRef, nodeCanvasData);
      };
      cyRef.on('render cyCanvas.resize', doRenderNodes);

      return () => {
        cyRef.off('render cyCanvas.resize', doRenderNodes);
      };
    }, [cyRef, nodeCanvasData]);

    useEffect(() => {
      if (!labelCanvasData) {
        return;
      }

      const doRender = () => {
        renderLabels(cyRef, labelCanvasData);
      };
      cyRef.on('render cyCanvas.resize', doRender);

      return () => {
        cyRef.off('render cyCanvas.resize', doRender);
      };
    }, [cyRef, labelCanvasData]);

    return <></>;
  }
);
