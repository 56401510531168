import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { DashboardStyles } from '../../../DashboardLanding.style';
import { GlooHealthCheckSectionContent } from '../Gloo/GlooHealthCheckSectionContent';
import { GlooHealthEnvironmentCheckSection } from '../Gloo/GlooHealthEnvironmentCheckSection';

const healthOverview = {
  glooVersions: [{ version: '2.6.0', status: { state: State.ACCEPTED, validationErrors: [] } }],
  totalClusters: 3
};

export const GlooHealthTabContentFakeData = () => {
  return (
    <GridLayout templateColumns='1fr auto 1fr' gap={5} pt={5}>
      <GlooHealthCheckSectionContent
        glooManagementPlaneInsights={undefined}
        glooAgentInsights={undefined}
        glooManagementPlaneStatus={{ state: State.WARNING, validationErrors: [] }}
        glooAgentStatus={{ state: State.FAILED, validationErrors: [] }}
      // telemetryStatus={{ state: State.ACCEPTED, validationErrors: [] }}
      />
      <DashboardStyles.VerticalLine />
      <GlooHealthEnvironmentCheckSection healthOverview={healthOverview} />
    </GridLayout>
  );
};
