import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { DirectiveDefinitionNode, TypeNode } from 'graphql';
import { useMemo } from 'react';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { getDescriptionColumnValue, GqlSchemaTabTableProps } from 'utils/graphql-schema-search-helpers';
import { renderDefinitionNameLink, RenderDefinitionNameLinkProps } from '../../../GqlSoloTableCells';
import NameAndReturnType from '../../NameAndReturnType';

type TableFields = {
  key: string;
  nameLink: RenderDefinitionNameLinkProps;
  type: TypeNode | DirectiveDefinitionNode;
  description: string;
};

const GqlDirectiveTabTable = ({
  definitions,
  onTypeClick,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<DirectiveDefinitionNode>) => {
  const columns: SoloColumnsType<TableFields> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'nameLink',
        render: renderDefinitionNameLink
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => (
          <Spacer py={2}>
            <NameAndReturnType node={type} onTypeClick={itemName => onTypeClick(itemName, true)} />
          </Spacer>
        )
      },
      {
        title: 'Description',
        dataIndex: 'description',
        render: renderReactMarkdown
      }
    ],
    [onTypeClick]
  );

  //
  // Pagination, Table Data
  //
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(definitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(f => ({
      key: f.name.value,
      nameLink: { name: f.name.value, tabHeader, onTypeClick },
      type: f,
      description: getDescriptionColumnValue(f)
    }));
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  // Render
  //
  if (hidden) return null;
  return (
    <SoloTable
      removeHorizontalPadding
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='gql-directives-table'
    />
  );
};

export default GqlDirectiveTabTable;
