import { Select } from 'antd';
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { useSoloId } from 'utils/hooks';
import { SoloDropdownStyles as Styles } from './SoloDropdown.style';
import { SoloLabel } from './SoloLabel';

type ValueType = string | number;

export interface OptionType<T> {
  key?: React.Key;
  disabled?: boolean;
  value?: T;
  displayValue?: React.ReactNode;
  icon?: React.ReactNode;
}
export interface DropdownProps<T> {
  value: T;
  options: OptionType<T>[];
  onChange?: (newValue: T) => void;
  displayValue?: string | number;
  id?: string;
  error?: any;
  // Label
  label?: string;
  infoTooltip?: React.ReactNode;
  /** Rendering the dropdown popup inside the container (with `includePopupInContainerElement={true}`)
   * is necessary for them to work if the container element is in fullscreen mode. */
  includePopupInContainerElement?: boolean;

  // Dropdown component default - passed via ...rest
  defaultValue?: string | number;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (evt: React.FocusEvent) => any;
  'data-testid'?: string;
}

export const SoloDropdown = <T extends ValueType>({
  displayValue,
  value,
  options,
  label,
  infoTooltip,
  onChange,
  onBlur,
  error,
  id,
  includePopupInContainerElement,
  ...rest // Includes data-testid and `aria-` attributes
}: DropdownProps<T>) => {
  const inputId = useSoloId(id);
  rest.defaultValue ??= '';
  const containerRef = useRef<ElementRef<'div'>>(null);
  const { isOpen, setIsOpen } = useAddAriaPropsToAntdDropdown(containerRef);

  return (
    <Styles.Container ref={containerRef}>
      {label && (
        <SoloLabel htmlFor={inputId} infoTooltip={infoTooltip}>
          {label}
        </SoloLabel>
      )}
      <Styles.SoloDropdownBlock
        id={inputId}
        dropdownClassName={rest['data-testid']}
        // `displayValue` is used when we want the value shown on the closed input to be different than
        // the label used on the actual dropdown
        value={displayValue ? { value, label: displayValue } : value}
        optionLabelProp='children' // this is required to silence warning from `label` being used in `value` due to `displayValue`
        dropdownMatchSelectWidth={false}
        open={isOpen}
        onDropdownVisibleChange={newIsOpen => setIsOpen(newIsOpen)}
        onChange={onChange as any}
        getPopupContainer={!!includePopupInContainerElement ? () => containerRef.current ?? document.body : undefined}
        {...rest}>
        {options.map((opt: OptionType<T>) => (
          <Select.Option
            key={opt.key ?? opt.value}
            value={opt.value}
            disabled={opt.disabled}
            aria-selected={opt.value === value ? 'true' : 'false'}
            aria-label={opt.displayValue || opt.value}>
            {opt.icon} {opt.displayValue || opt.value}
          </Select.Option>
        ))}
      </Styles.SoloDropdownBlock>
    </Styles.Container>
  );
};

/**
 * There isn't a way to set the aria properties of the antd select's input element directly
 * with JSX, so this finds the select input element, which should be a child of
 * containerRef.current, and sets the aria properties dynamically.
 */
export const useAddAriaPropsToAntdDropdown = (
  containerRef: React.RefObject<ElementRef<'div'>>,
  includeIsOpenCheck = true
) => {
  const [selectInputEl, setSelectInputEl] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const selectEl: HTMLInputElement | null = containerRef.current.querySelector(
      'input.ant-select-selection-search-input'
    );
    selectEl?.removeAttribute('aria-activedescendant');
    setSelectInputEl(selectEl);
  }, [containerRef.current]);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (!selectInputEl || !includeIsOpenCheck) {
      return;
    }
    selectInputEl.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
  }, [selectInputEl, isOpen]);

  return { isOpen, setIsOpen };
};
