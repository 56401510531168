/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { DebugApi } from "./debug_pb";
import type { GetYamlResponse } from "./debug_pb";
import type { GetYamlRequest } from "./debug_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResourcesResponse } from "./debug_pb";
import type { ListResourcesRequest } from "./debug_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rpc.gloo.solo.io.DebugApi
 */
export interface IDebugApiClient {
    /**
     * @generated from protobuf rpc: ListResources(rpc.gloo.solo.io.ListResourcesRequest) returns (rpc.gloo.solo.io.ListResourcesResponse);
     */
    listResources(input: ListResourcesRequest, options?: RpcOptions): UnaryCall<ListResourcesRequest, ListResourcesResponse>;
    /**
     * @generated from protobuf rpc: GetYaml(rpc.gloo.solo.io.GetYamlRequest) returns (rpc.gloo.solo.io.GetYamlResponse);
     */
    getYaml(input: GetYamlRequest, options?: RpcOptions): UnaryCall<GetYamlRequest, GetYamlResponse>;
}
/**
 * @generated from protobuf service rpc.gloo.solo.io.DebugApi
 */
export class DebugApiClient implements IDebugApiClient, ServiceInfo {
    typeName = DebugApi.typeName;
    methods = DebugApi.methods;
    options = DebugApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListResources(rpc.gloo.solo.io.ListResourcesRequest) returns (rpc.gloo.solo.io.ListResourcesResponse);
     */
    listResources(input: ListResourcesRequest, options?: RpcOptions): UnaryCall<ListResourcesRequest, ListResourcesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListResourcesRequest, ListResourcesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetYaml(rpc.gloo.solo.io.GetYamlRequest) returns (rpc.gloo.solo.io.GetYamlResponse);
     */
    getYaml(input: GetYamlRequest, options?: RpcOptions): UnaryCall<GetYamlRequest, GetYamlResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetYamlRequest, GetYamlResponse>("unary", this._transport, method, opt, input);
    }
}
