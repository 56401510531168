import { Tooltip, TooltipProps } from 'antd';
import React, { useMemo } from 'react';
import { SecondaryInfoStyles as Styles } from './SecondaryInfo.style';
import SoloTooltip from './SoloTooltip';

export interface SecondaryInfoItemProps {
  label?: React.ReactNode | string;
  data: NonNullable<React.ReactNode>;
  highlighted?: boolean | Styles.HighlightProps;
  small?: boolean;
  tooltipText?: string;
  tooltipProps?: TooltipProps;
  background?: string;
  key?: string;
}
export const SecondaryInfoItem = ({
  label,
  data,
  tooltipText,
  tooltipProps,
  // includes highlight, small, background, etc
  ...rest
}: SecondaryInfoItemProps) => {
  return (
    <Tooltip title={tooltipText} {...tooltipProps}>
      <Styles.Item {...rest}>
        {!!label && (
          <Styles.Label>
            <span>{label}</span>:
          </Styles.Label>
        )}
        {data}
      </Styles.Item>
    </Tooltip>
  );
};

interface Props {
  items: (SecondaryInfoItemProps | false | null | undefined)[];
  highlightByDefault?: boolean;
  small?: boolean;
  inline?: boolean;
  overflowAfter?: number;
}
export const SecondaryInfo = ({ items, highlightByDefault, small, inline, overflowAfter }: Props) => {
  const [list, tooltipList] = useMemo(() => {
    const filtered = items.filter((info): info is SecondaryInfoItemProps => !!info);
    return !!overflowAfter ? [filtered.slice(0, overflowAfter), filtered.slice(overflowAfter)] : [filtered, []];
  }, [items.map(o => (!o ? '' : o.key ?? `${o.label}-${o.data}`)).join('-')]);

  return (
    <Styles.List data-testid='secondary-info' inline={inline}>
      {list.map(({ highlighted = highlightByDefault, small: ignored, ...rest }) => (
        <SecondaryInfoItem
          key={rest.key ?? `${rest.label}-${rest.data}`}
          {...rest}
          highlighted={highlighted}
          small={small}
        />
      ))}
      {tooltipList.length > 0 && (
        <SoloTooltip
          title={
            <ul>
              {tooltipList.map(({ highlighted = highlightByDefault, small: ignored, ...rest }) => (
                <li key={rest.key ?? `${rest.label}-${rest.data}`}>
                  <SecondaryInfoItem {...rest} highlighted={highlighted} small={small} />
                </li>
              ))}
            </ul>
          }>
          <Styles.ExtraCountPill small={small}>+{tooltipList.length}</Styles.ExtraCountPill>
        </SoloTooltip>
      )}
    </Styles.List>
  );
};

export function recordToSecondaryInfoItemList(record: Record<string, string>): SecondaryInfoItemProps[] {
  return Object.entries(record)
    .sort()
    .map(([label, data]) => ({ label, data }));
}
