import { TCPRoute } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/routes_common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React from 'react';
import { RoutesListDisplayStyles as Styles } from '../RoutesListDisplay.style';
import { BaseRouteItem } from './BaseRouteItem';
import { RouteForwardToDestinationList } from './Components/RouteForwardToDestinationList';

export const TcpRouteItem = ({ route, parentRef }: { route: TCPRoute; parentRef: ClusterObjectRef }) => {
  const { status, matchers, labels } = route;
  return (
    <BaseRouteItem
      data-testid='tcp-route-item'
      routeType='TCP'
      names={undefined}
      status={status}
      labels={labels}
      ifContent={
        <>
          <Styles.RouteContentHeading>Matchers</Styles.RouteContentHeading>
          <Styles.MatchersList>
            {matchers.map((matcher, idx) => {
              return (
                <React.Fragment key={[idx, matcher.port].join('-')}>
                  <Styles.RouteHorizontalList>
                    {matcher.port !== undefined && <Styles.MatchersItem>Port: {matcher.port}</Styles.MatchersItem>}
                  </Styles.RouteHorizontalList>
                </React.Fragment>
              );
            })}
          </Styles.MatchersList>
        </>
      }
      thenContentColumns={[
        <>
          <Styles.RouteContentHeading>Action</Styles.RouteContentHeading>
          {route.actionType.oneofKind === 'forwardTo' && (
            <>
              <Styles.ActionLabel>Forward to Destination</Styles.ActionLabel>
              <RouteForwardToDestinationList
                destinationsList={route.actionType.forwardTo.destinations}
                parentRef={parentRef}
              />
            </>
          )}
        </>,
        '' // empty right column
      ]}
    />
  );
};
