import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';

export namespace AddOverviewResourceModalBodyStyles {
  export const Container = styled.div`
    padding: 30px;
  `;
  export const Title = styled.div(() => {
    return css`
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 20px;

      svg {
        height: 28px;
        margin-left: 15px;
      }
    `;
  });
  export const Hint = styled.div(
    ({ theme }) => css`
      background: ${theme.dropBlue};
      border-radius: 8px;
      font-size: 14px;
      line-height: 44px;
      color: ${theme.septemberGrey};
      margin-bottom: 20px;
      padding: 0 11px;
    `
  );

  export const InstructionsArea = styled.div(
    ({ theme }) => css`
      background: ${theme.dropBlue};
      border: 1px solid ${theme.seaBlue};
      border-radius: 8px;
      color: ${theme.seaBlue};
      padding: 15px 11px;
    `
  );

  export const ClusterIconHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${svgBasics(24)}
  `;

  export const InstructionNumber = styled.div(
    ({ theme }) => css`
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      line-height: 23px;
      text-align: center;
      padding-top: 2px;
      width: 25px;
      height: 25px;
      font-weight: 500;
      font-size: 18px;
      border-radius: 100%;
      color: ${theme.seaBlue};
      background: ${theme.splashBlue};
      margin-right: 8px;
    `
  );

  export const InstructionHint = styled.div(
    ({ theme }) => css`
      position: relative;
      padding-left: 40px;
      font-size: 16px;
      line-height: 22px;
      color: ${theme.neptuneBlue};
      margin-bottom: 35px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `
  );
}
