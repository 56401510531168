import { css } from '@emotion/react';
import { colors } from '../colors';

export const siteGlobalStyles = css`
  html,
  body {
    width: 100vw;
    height: 100vh;

    line-height: 19px;
  }
  body {
    font-family: 'Proxima Nova', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
    margin: 0;
    padding: 0;
    min-height: 100vh;
    min-width: 100vw;
    background: ${colors.januaryGrey};
    color: ${colors.neptuneBlue};
  }

  .force-borderless,
  .force-borderless td {
    border: none !important;
  }

  a {
    border-radius: 2px;
  }

  a:focus-visible,
  button:focus-visible,
  .ant-pagination li:focus-visible {
    outline: 2px solid ${colors.lakeBlue} !important;
    outline-offset: 2px !important;
    transition: 0s outline;
  }

  input:focus-visible {
    outline: 2px solid ${colors.lakeBlue} !important;
  }

  // Patternfly CSS forces all these to be a specific color for some reason, so this unsets it.
  button,
  input,
  optgroup,
  select,
  textarea {
    color: unset;
  }

  // This allows the reach tabs to scroll when the content overflows the container.
  div[role='tabpanel'] {
    overflow: auto !important;
  }
`;
