import { workspacesApi } from 'Api/workspaces';
import { ApiserverNoContent } from 'Components/Common/ApiserverContent';
import { CardStyles } from 'Components/Common/Card';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { SoloMultiselect } from 'Components/Common/Input/SoloMultiselect';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { ImportStatus } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayoutSpacer } from 'Styles/CommonEmotions/flexLayout';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { getRouteTypeReadableName, importStatusMap } from 'utils/types';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';
import { AvailableRoutesToImportTable } from './AvailableRoutesToImportTable';
import { WorkspaceRoutesTable } from './WorkspaceRoutesTable';
const { useListRoutes } = workspacesApi;

export const RoutingTab = ({ workspaceName }: { workspaceName: string }) => {
  di(useListRoutes);
  const workspaceRoutingPaging = useSoloPaging();
  const availableRoutingPaging = useSoloPaging();

  const [workspaceNameFilter, setWorkspaceNameFilter] = useState<string>('');
  const [workspaceTypeFilter, setWorkspaceTypeFilter] = useState<RouteType[]>([]);
  const [workspaceImportStatusFilter, setWorkspaceImportStatusFilter] = useState<ImportStatus[]>([]);

  const [availableNameFilter, setAvailableNameFilter] = useState<string>('');

  const { data: routesDataUnbounced, error: routesError } = useListRoutes(
    workspaceName,
    workspaceNameFilter,
    workspaceTypeFilter,
    workspaceImportStatusFilter,
    workspaceRoutingPaging.apiPaginationObject,
    availableNameFilter,
    availableRoutingPaging.apiPaginationObject
  );

  const { data: routesData, initialLoad, showRefreshIndicator } = useDebouncedRefreshIndicator(routesDataUnbounced);

  workspaceRoutingPaging.useSetPagingTotal(routesData?.totalWorkspaceRoutes);
  availableRoutingPaging.useSetPagingTotal(routesData?.totalAvailableRoutes);

  return (
    <CardStyles.Card data-testid='routing-tab'>
      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Workspace Routes</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={workspaceNameFilter}
            placeholder='Search by name...'
            onChangeValue={setWorkspaceNameFilter}
          />
          <SoloMultiselect
            values={workspaceImportStatusFilter}
            options={[ImportStatus.IMPORTED, ImportStatus.EXPORTED, ImportStatus.PRIVATE].map(type => ({
              value: type,
              title: importStatusMap[type].name
            }))}
            placeholder={'Filter by Import Status'}
            onChange={setWorkspaceImportStatusFilter}
            multiple
          />
          <SoloMultiselect
            values={workspaceTypeFilter}
            options={[RouteType.VIRTUAL_GATEWAY, RouteType.ROUTE_TABLE].map(type => ({
              value: type,
              title: getRouteTypeReadableName(type)
            }))}
            placeholder='Filter by Type'
            onChange={setWorkspaceTypeFilter}
            multiple
          />
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!routesError || initialLoad ? (
        <ApiserverNoContent error={routesError} loading={`Retrieving ${workspaceName} route details...`} />
      ) : (
        <WorkspaceRoutesTable
          workspaceName={workspaceName}
          workspaceRoutes={routesData?.workspaceRoutes ?? []}
          pagingData={workspaceRoutingPaging.pagingData}
        />
      )}

      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Available Routes to Import</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={availableNameFilter}
            placeholder='Search by name...'
            onChangeValue={setAvailableNameFilter}
          />
          {/* Available routes has no type filter, since only 1 type is returned */}
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!routesError || initialLoad ? (
        <ApiserverNoContent error={routesError} loading={`Retrieving ${workspaceName} route details...`} />
      ) : (
        <AvailableRoutesToImportTable
          availableRoutes={routesData?.availableRoutes ?? []}
          pagingData={availableRoutingPaging.pagingData}
        />
      )}
    </CardStyles.Card>
  );
};
