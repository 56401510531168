import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { EmptyAsterisk } from 'Components/Common/EmptyAsterisk';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { Asset } from 'assets';
import { FailoverPolicySpec_Config_LocalityMappings } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/resilience/failover_policy_pb';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';
import { BasicDestinationsTable } from '../../Destinations/BasicDestinationsTable';

const { useGetFailoverPolicyDetails } = policiesApi;

const policyType = PolicyType.FAILOVER;

type SettingsTableFields = {
  key: string;
  region1: React.ReactNode;
  zone1: React.ReactNode;
  subzone1: React.ReactNode;
  region2: React.ReactNode;
  zone2: React.ReactNode;
  subzone2: React.ReactNode;
  weight?: number;
};
const settingsColumns: SoloColumnsType<SettingsTableFields> = [
  { title: 'From:' },
  { title: 'Region', dataIndex: 'region1' },
  { title: 'Zone', dataIndex: 'zone1' },
  { title: 'Subzone', dataIndex: 'subzone1' },

  { title: 'To:' },
  { title: 'Region', dataIndex: 'region2' },
  { title: 'Zone', dataIndex: 'zone2' },
  { title: 'Subzone', dataIndex: 'subzone2' },
  { title: 'Weight', dataIndex: 'weight' }
];
const LocalityTable = ({
  localityMappingsList
}: {
  localityMappingsList?: FailoverPolicySpec_Config_LocalityMappings[];
}) => {
  const [tableData, setTableData] = useState<SettingsTableFields[]>([]);
  useEffect(() => {
    if (!!localityMappingsList) {
      setTableData(
        localityMappingsList
          // Create a seperate row for each 'to'
          .flatMap(data => data.to.map(to => ({ from: data.from, to })))
          .map(data => ({
            key: [
              data.from?.region ?? '',
              data.from?.zone ?? '',
              data.from?.subZone ?? '',
              data.to?.region ?? '',
              data.to?.zone ?? '',
              data.to?.subZone ?? ''
            ].join('.'),
            region1: data.from?.region ?? <EmptyAsterisk />,
            zone1: data.from?.zone ?? <EmptyAsterisk />,
            subzone1: data.from?.subZone ?? <EmptyAsterisk />,
            region2: data.to.region ?? <EmptyAsterisk />,
            zone2: data.to.zone ?? <EmptyAsterisk />,
            subzone2: data.to.subZone ?? <EmptyAsterisk />,
            weight: data.to.weight?.value
          }))
      );
    } else {
      setTableData([]);
    }
  }, [localityMappingsList]);

  return <SoloTable columns={settingsColumns} dataSource={tableData} />;
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const FailoverDetails = ({ clusterObjRef }: Props) => {
  di(useGetFailoverPolicyDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();

  const { data: policyData, error: policyError } = useGetFailoverPolicyDetails(clusterObjRef, apiPaginationObject);

  useSetPagingTotal(policyData?.destinations?.totalPolicyDestinations);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Failover Settings</CardStyles.CardHeader>

        <LocalityTable localityMappingsList={policyData.localityMappings} />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicDestinationsTable destinations={policyData.destinations?.destinations} paging={pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
