import React from 'react';
import { Asset } from 'assets';
import { GraphLegendStyles as Styles } from './GraphLegend.style';
import { GraphMetricsType, GraphStatus } from '../graph-selection-utils';
import { colors } from 'Styles';

export const GraphLegend = () => {
  return (
    <>
      <Styles.Subsection>
        <Styles.SubTitle>Node Types</Styles.SubTitle>
        <Styles.SubsubTitle>Kubernetes Services</Styles.SubsubTitle>
        <Styles.IconRow>
          <div>
            <Asset.KubernetesServiceHealthy />
          </div>
          <div>Kubernetes Workload</div>
        </Styles.IconRow>
        <Styles.IconRow>
          <div>
            <Asset.GatewayIconBlue />
          </div>
          <div>Gateway</div>
        </Styles.IconRow>
        <Styles.IconRow>
          <div>
            <Asset.KubernetesServiceHealthy />
          </div>
          <div>Mesh</div>
        </Styles.IconRow>
        <Styles.IconRow>
          <div>
            <Asset.KubernetesServiceHealthy />
          </div>
          <div>Ambient</div>
        </Styles.IconRow>
        <Styles.IconRow>
          <div>
            <Asset.GatewayIconBlue />
          </div>
          <div>Waypoint Proxy</div>
        </Styles.IconRow>

        <Styles.SubsubTitle>External Services</Styles.SubsubTitle>
        <Styles.IconRow>
          <div>
            <Asset.ExternalServiceIconBlue />
          </div>
          <div>External Workload</div>
        </Styles.IconRow>
        <Styles.IconRow>
          <div>
            <Asset.ExternalServiceIconBlue />
          </div>
          <div>Lambda</div>
        </Styles.IconRow>
        <Styles.IconRow>
          <div>
            <Asset.VirtualMachineIconBlue />
          </div>
          <div>Virtual Machine</div>
        </Styles.IconRow>

        <Styles.SubsubTitle>Node States</Styles.SubsubTitle>
        <Styles.StatusIconRow health={GraphStatus.Healthy}>
          <div></div>
          <div>
            Normal <Styles.ExtraInfo>&#62; 99% healthy</Styles.ExtraInfo>
          </div>
        </Styles.StatusIconRow>
        <Styles.StatusIconRow health={GraphStatus.Error}>
          <div></div>
          <div>
            Danger <Styles.ExtraInfo>&#60; 90% healthy</Styles.ExtraInfo>
          </div>
        </Styles.StatusIconRow>
        <Styles.StatusIconRow health={GraphStatus.Warning}>
          <div></div>
          <div>
            Warning <Styles.ExtraInfo>&#62;= 90% healthy</Styles.ExtraInfo>
          </div>
        </Styles.StatusIconRow>
        <Styles.StatusIconRow health={GraphStatus.Idle}>
          <div></div>
          <div>
            Idle <Styles.ExtraInfo>no requests</Styles.ExtraInfo>
          </div>
        </Styles.StatusIconRow>
      </Styles.Subsection>

      <Styles.Subsection>
        <Styles.SubTitle>Edges</Styles.SubTitle>
        <Styles.EdgeCell>
          <div>
            <Styles.EdgeLine health='black' edgeLength={60} />
          </div>
          <div>L7</div>
        </Styles.EdgeCell>
        <Styles.EdgeCell>
          <div>
            <Styles.EdgeLine
              metricsType={GraphMetricsType.Cilium}
              health={GraphStatus.Healthy}
              edgeLength={60}
              edgeStyle='solid'
            />
          </div>
          <div>L4</div>
        </Styles.EdgeCell>

        <Styles.EdgeCell>
          <div>
            <Styles.EdgeLine health={GraphStatus.Error} />
          </div>
          <div>Failure</div>
        </Styles.EdgeCell>

        <Styles.EdgeCell>
          <div>
            <Styles.EdgeLine health={GraphStatus.Warning} />
          </div>
          <div>Degraded</div>
        </Styles.EdgeCell>

        <Styles.EdgeCell>
          <div>
            <Styles.EdgeLine health={GraphStatus.Healthy} />
          </div>
          <div>Healthy</div>
        </Styles.EdgeCell>

        <Styles.EdgeCell>
          <div>
            <Styles.EdgeLine health={GraphStatus.Idle} />
          </div>
          <div>Idle</div>
        </Styles.EdgeCell>

        <Styles.EdgeCell forceNewRow={true}>
          <div>
            <span className='icon-secure' style={{ color: colors.seaBlue }}></span>
          </div>
          <div>mTLS Applied</div>
        </Styles.EdgeCell>
      </Styles.Subsection>
      <Styles.Subsection>
        <Styles.SubTitle>Mesh Topology</Styles.SubTitle>

        <Styles.SmallIconRow>
          <div className='black-fill'>
            <Asset.CiliumIcon />
          </div>
          <div>
            Enforced by
            <br />
            Cilium
          </div>
        </Styles.SmallIconRow>

        <Styles.SmallIconRow>
          <div>
            <Asset.IstioLogo />
          </div>
          <div>
            Enforced by
            <br />
            Istio
          </div>
        </Styles.SmallIconRow>

        {/* <Styles.SmallIconRow>
          <div>
            <Asset.PepIconBlack />
          </div>
          <div>Server PEP</div>
        </Styles.SmallIconRow> */}

        {/* <Styles.SmallIconRow>
          <div>
            <Asset.SidecarEnvoyIconBlack />
          </div>
          <div>Sidecar</div>
        </Styles.SmallIconRow> */}

        {/* <Styles.SmallIconRow>
          <div>
            <Asset.AmbientMeshIconBlack />
          </div>
          <div>Uses Ambient Mesh</div>
        </Styles.SmallIconRow> */}
      </Styles.Subsection>
    </>
  );
};
