import { DashboardStyles } from '../../../DashboardLanding.style';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { IstioHealthCheckSection } from './IstioHealthCheckSection';
import { IstioHealthEnvironmentCheckSection } from './IstioHealthEnvironmentCheckSection';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { DashboardHealthKey, dashboardHealthCodesMap } from '../insight-codes';
import { di } from 'react-magnetic-di';

const IstioHealthTabContent = () => {
  di(useFilteredDashboardInsights);
  const istioEnvironment = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.istioEnvironmentCheck
  );

  return (
    <GridLayout templateColumns='1fr auto 1fr' gap={5} pt={5}>
      <IstioHealthCheckSection />
      <DashboardStyles.VerticalLine />
      <IstioHealthEnvironmentCheckSection istioEnvironment={istioEnvironment} />
    </GridLayout>
  );
};

export default IstioHealthTabContent;
