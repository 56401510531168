import { destinationsApi } from 'Api/destinations';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { PagingProps, useSoloPaging } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { RenderCellStringList, RenderCellStringListProps } from 'Components/Common/SoloTableCells';
import { Asset } from 'assets';
import { DestinationKind } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/selectors_pb';
import { ExternalEndpoint } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/destinations_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { getDestinationKindIcon } from 'utils/types';
import { BasicPoliciesTable } from '../../Policies/BasicPoliciesTable';
import { WorkspaceDependenciesCard } from '../../Workspaces/WorkspaceDependenciesCard';

const { useGetExternalServiceDetails } = destinationsApi;

type EndpointTableFields = {
  key: string;
  address: string;
  portNames: RenderCellStringListProps;
  portNumbers: RenderCellStringListProps;
  locality: string;
};
const EndpointsTable = ({ endpoints, paging }: { endpoints: ExternalEndpoint[]; paging: PagingProps }) => {
  const [tableData, setTableData] = useState<EndpointTableFields[]>([]);

  useEffect(() => {
    if (!!endpoints) {
      setTableData(
        endpoints.map(endpoint => ({
          key: endpoint.address,
          address: endpoint.address,
          portNames: { list: endpoint.ports.map(o => o.name) },
          portNumbers: { list: endpoint.ports.map(o => o.number) },
          locality: endpoint.locality
        }))
      );
    } else {
      setTableData([]);
    }
  }, [endpoints]);

  const columns: SoloColumnsType<EndpointTableFields> = [
    { title: 'Address', dataIndex: 'address' },
    {
      title: 'Port Name',
      dataIndex: 'portNames',
      render: RenderCellStringList
    },
    {
      title: 'Port Number',
      dataIndex: 'portNumbers',
      render: RenderCellStringList
    },
    { title: 'Locality', dataIndex: 'locality' }
  ];
  return <SoloTable columns={columns} dataSource={tableData} paging={paging} />;
};

interface Props {
  clusterObjRef: ClusterObjectRef;
}
export const ExternalServiceDetails = ({ clusterObjRef }: Props) => {
  di(useGetExternalServiceDetails);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();
  const endpointPaging = useSoloPaging();

  const { data: destinationData, error: destinationError } = useGetExternalServiceDetails(
    clusterObjRef,
    endpointPaging.apiPaginationObject,
    apiPaginationObject
  );

  useSetPagingTotal(destinationData?.policies?.totalPolicies);
  endpointPaging.useSetPagingTotal(destinationData?.totalExternalEndpoints);

  if (!!destinationError || !destinationData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded
          icon={getDestinationKindIcon(DestinationKind.EXTERNAL_SERVICE)}
          objRef={clusterObjRef}
        />
        {!!destinationError ? (
          <DataError error={destinationError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} destination details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getDestinationKindIcon(DestinationKind.EXTERNAL_SERVICE)}
        status={destinationData?.status}
        objRef={clusterObjRef}
        workspaceRef={destinationData?.ownerWorkspace?.ref}
        resourceType={ResourceType.EXTERNAL_SERVICE}
      />

      <WorkspaceDependenciesCard
        dependencies={destinationData.dependencies}
        importingTypeName='service'
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <SoloListCardStyles.HorizontalListCardsContainer>
          <SoloListCard title='Host(s)' dataSource={destinationData.hosts} />
          <SoloListCard title='SubjectAltName(s)' dataSource={destinationData.subjectAltNames} />
          <SoloListCard title='Port(s)' dataSource={destinationData.ports} />
        </SoloListCardStyles.HorizontalListCardsContainer>
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>External Endpoints</CardStyles.CardHeader>

        <EndpointsTable endpoints={destinationData.externalEndpoints} paging={endpointPaging.pagingData} />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Workspace Policies
          <CardStyles.CardHeaderRightIcon>
            <Asset.PolicyRuleIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <BasicPoliciesTable policies={destinationData.policies?.policies} paging={pagingData} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
