/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/references.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { LambdaOptions } from "./cloud_provider_options_pb";
import { PortSelector } from "./port_pb";
import { DestinationKind } from "./selectors_pb";
/**
 * @generated from protobuf message common.gloo.solo.io.WorkloadReference
 */
export interface WorkloadReference {
    /**
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference ref = 1;
     */
    ref?: ObjectReference;
    /**
     * @generated from protobuf field: common.gloo.solo.io.WorkloadReference.WorkloadKind kind = 2;
     */
    kind: WorkloadReference_WorkloadKind;
}
/**
 * References the supported types for the Workload interface in pkg/utils/workloadutils/workload_types.go
 *
 * @generated from protobuf enum common.gloo.solo.io.WorkloadReference.WorkloadKind
 */
export enum WorkloadReference_WorkloadKind {
    /**
     * @generated from protobuf enum value: DEPLOYMENT = 0;
     */
    DEPLOYMENT = 0,
    /**
     * @generated from protobuf enum value: DAEMON_SET = 1;
     */
    DAEMON_SET = 1,
    /**
     * @generated from protobuf enum value: STATEFUL_SET = 2;
     */
    STATEFUL_SET = 2,
    /**
     * @generated from protobuf enum value: REPLICA_SET = 3;
     */
    REPLICA_SET = 3
}
/**
 * reference to a Kubernetes API object.
 * Kube API objects are referenced explicitly by the namespace and cluster containing them.
 *
 * @generated from protobuf message common.gloo.solo.io.ObjectReference
 */
export interface ObjectReference {
    /**
     * the name of the object
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * the namespace of the object. If omitted, Gloo Mesh will use the same namespace as the parent object containing this reference.
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
    /**
     * the cluster of the object. If omitted, Gloo Mesh will use the same cluster as the parent object containing this reference.
     *
     * @generated from protobuf field: string cluster = 3;
     */
    cluster: string;
}
/**
 * Destinations point to the upstream services that fulfill client requests on matching routes that you set up, such as in a route table. Each destination must resolve to one and only one hostname.
 *
 * Destinations can refer to a variety of resources. The behavior of the route action
 * varies depending on the kind of destination.
 *
 * Currently supported destination types are:
 * - Kubernetes `Service`, the default destination.
 * - Gloo `VirtualDestination` to route traffic to one of the VirtualDestination's backing Kubernetes services.
 * - Gloo `ExternalService` to route traffic to a static set of service endpoints that are external to your mesh setup.
 * - Gloo `ExternalWorkload` to route traffic to external workloads that are part of your mesh.
 * - Gloo `CloudProvider` to route traffic to a cloud provider function such as AWS Lambdas, selected by using the `functionCall` field.
 *
 * HTTP routes support all destinations. TCP routes support only Kubernetes `Service` and Gloo `VirtualDestination` destinations.
 *
 * @generated from protobuf message common.gloo.solo.io.DestinationReference
 */
export interface DestinationReference {
    /**
     * @generated from protobuf oneof: ref_kind
     */
    refKind: {
        oneofKind: "ref";
        /**
         * reference to a Kubernetes destination object by its metadata
         *
         * @generated from protobuf field: common.gloo.solo.io.ObjectReference ref = 1;
         */
        ref: ObjectReference;
    } | {
        oneofKind: "awsLambda";
        /**
         * Reference an AWS Lambda function.
         *
         * @generated from protobuf field: common.gloo.solo.io.AWSLambdaReference aws_lambda = 6;
         */
        awsLambda: AWSLambdaReference;
    } | {
        oneofKind: undefined;
    };
    /**
     * the kind of Kubernetes destination being referenced. defaults to Service.
     * Only applicable for Kubernetes destinations. For non Kubernetes destinations, this field is ignored.
     *
     * @generated from protobuf field: common.gloo.solo.io.DestinationKind kind = 2;
     */
    kind: DestinationKind;
    /**
     * the port on the destination object being targeted. required if the object provides more than one port.
     *
     * @generated from protobuf field: common.gloo.solo.io.PortSelector port = 3;
     */
    port?: PortSelector;
    /**
     * select a subset of the destination's endpoints for routing based on their labels.
     * Only applicable for Kubernetes destinations.
     *
     * @generated from protobuf field: map<string, string> subset = 4;
     */
    subset: {
        [key: string]: string;
    };
    /**
     * Specify the proportion of traffic to be forwarded to this destination.
     * Weights across all of the `destinations` must sum to 100.
     * Weight is only relevant when used in the context of a route with multiple destinations.
     *
     * @generated from protobuf field: uint32 weight = 5;
     */
    weight: number;
}
/**
 * A reference to an AWS Lambda Function to use as a routing destination
 *
 * @generated from protobuf message common.gloo.solo.io.AWSLambdaReference
 */
export interface AWSLambdaReference {
    /**
     * Reference to the AWS Cloud Provider.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference cloud_provider = 1;
     */
    cloudProvider?: ObjectReference;
    /**
     * Name of the function to reference.
     *
     * @generated from protobuf field: string function = 2;
     */
    function: string;
    /**
     * Qualifier of the function to reference. Defaults to $LATEST
     *
     * @generated from protobuf field: string qualifier = 3;
     */
    qualifier: string;
    /**
     * Specify how the destinations should be configured, for configuring lambda functions.
     * If the destination config is required for the destination and not provided by the user, Gloo will invalidate the
     * destination and its parent resources.
     *
     * @generated from protobuf field: common.gloo.solo.io.LambdaOptions options = 4;
     */
    options?: LambdaOptions;
}
/**
 * ListenerPortReference identifies a single listener in a VirtualGateway by port number
 *
 * @generated from protobuf message common.gloo.solo.io.ListenerPortReference
 */
export interface ListenerPortReference {
    /**
     * The gateway containing the listener.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference gateway_ref = 1;
     */
    gatewayRef?: ObjectReference;
    /**
     * The port of the listener on the gateway.
     *
     * @generated from protobuf field: uint32 port = 2;
     */
    port: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class WorkloadReference$Type extends MessageType<WorkloadReference> {
    constructor() {
        super("common.gloo.solo.io.WorkloadReference", [
            { no: 1, name: "ref", kind: "message", T: () => ObjectReference },
            { no: 2, name: "kind", kind: "enum", T: () => ["common.gloo.solo.io.WorkloadReference.WorkloadKind", WorkloadReference_WorkloadKind] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.WorkloadReference
 */
export const WorkloadReference = new WorkloadReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObjectReference$Type extends MessageType<ObjectReference> {
    constructor() {
        super("common.gloo.solo.io.ObjectReference", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ObjectReference
 */
export const ObjectReference = new ObjectReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestinationReference$Type extends MessageType<DestinationReference> {
    constructor() {
        super("common.gloo.solo.io.DestinationReference", [
            { no: 1, name: "ref", kind: "message", oneof: "refKind", T: () => ObjectReference },
            { no: 6, name: "aws_lambda", kind: "message", oneof: "refKind", T: () => AWSLambdaReference },
            { no: 2, name: "kind", kind: "enum", T: () => ["common.gloo.solo.io.DestinationKind", DestinationKind] },
            { no: 3, name: "port", kind: "message", T: () => PortSelector },
            { no: 4, name: "subset", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "weight", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.DestinationReference
 */
export const DestinationReference = new DestinationReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AWSLambdaReference$Type extends MessageType<AWSLambdaReference> {
    constructor() {
        super("common.gloo.solo.io.AWSLambdaReference", [
            { no: 1, name: "cloud_provider", kind: "message", T: () => ObjectReference },
            { no: 2, name: "function", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "qualifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "options", kind: "message", T: () => LambdaOptions }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.AWSLambdaReference
 */
export const AWSLambdaReference = new AWSLambdaReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListenerPortReference$Type extends MessageType<ListenerPortReference> {
    constructor() {
        super("common.gloo.solo.io.ListenerPortReference", [
            { no: 1, name: "gateway_ref", kind: "message", T: () => ObjectReference },
            { no: 2, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.ListenerPortReference
 */
export const ListenerPortReference = new ListenerPortReference$Type();
