import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { Color } from 'Styles/colors';
import { TooltipProps } from 'antd';
import { State, Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import React from 'react';
import { getStatusColors } from 'utils/health-status';
import { SmallErrorXList } from './SmallErrorXList';
import { SoloLink } from './SoloLink';
import { SoloLinkStyles } from './SoloLink.style';
import SoloTableTextEllipsesCell from './SoloTableTextEllipsesCell';
import { Svg } from './Svg';

export namespace HealthIndicatorStyles {
  interface HealthIndicatorRingProps {
    borderColor: Color;
    size: number;
  }
  export const HealthIndicatorRing = styled.div<HealthIndicatorRingProps>(
    ({ borderColor, size }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${size}px;
      width: ${size}px;
      min-width: ${size}px;
      border-radius: 50%;
      border: 2px solid ${borderColor};

      svg {
        // Removes the error list icon's border
        .border {
          display: none;
        }
      }
    `
  );

  export const HealthIndicatorWithNameContainer = styled.span(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      color: ${theme.neptuneBlue};
      gap: 8px;

      /* This allows "SoloTableTextEllipsesCell" to work inside the link */
      ${SoloLinkStyles.SoloLinkWrapper} {
        overflow-x: hidden;
      }
    `
  );

  interface HealthIndicatorIconProps {
    backgroundColor: Color;
    borderColor: Color;
    iconColor: Color;
    size: number;
  }
  export const HealthIndicatorIcon = styled(HealthIndicatorRing)<HealthIndicatorIconProps>(
    ({ backgroundColor, iconColor, size }) => css`
      box-sizing: border-box;
      background: ${backgroundColor};
      border-width: 2px;

      ${svgBasics(`${size}%`, iconColor)}
    `
  );

  export const ShowErrorListOnHover = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 0.3s ease-in;
    :hover {
      opacity: 1;
    }

    > div {
      width: 100%;
      height: 100%;
    }

    svg {
      // "important" required to override size set in SmallErrorXList
      width: 100% !important;
      height: 100% !important;
    }
  `;
}

interface HealthIndicatorProps {
  status: Status | undefined;
  size?: number;
  borderColorOverride?: Color;
  hideErrorList?: boolean;
}
export const HealthIndicator = ({ status, size = 15, borderColorOverride, hideErrorList }: HealthIndicatorProps) => {
  const { border: borderColor } = getStatusColors(status?.state ?? State.ACCEPTED);

  return (
    <HealthIndicatorStyles.HealthIndicatorRing
      data-testid='health-indicator'
      borderColor={borderColorOverride ?? borderColor}
      size={size}>
      {!hideErrorList && (
        <HealthIndicatorStyles.ShowErrorListOnHover>
          <SmallErrorXList status={status} />
        </HealthIndicatorStyles.ShowErrorListOnHover>
      )}
    </HealthIndicatorStyles.HealthIndicatorRing>
  );
};

const OptionalSoloLinkContainer = ({
  soloLinkProps,
  children
}: {
  soloLinkProps: Omit<Parameters<typeof SoloLink>[0], 'children'> | undefined;
  children: React.ReactNode;
}) => {
  if (!!soloLinkProps) {
    return <SoloLink {...soloLinkProps}>{children}</SoloLink>;
  }
  return <>{children}</>;
};

const DisplayContents = styled.div`
  display: contents;
`;

export type HealthIndicatorWithNameProps = HealthIndicatorProps & {
  name: string | undefined; // name is required, but allow it to be undefined due to api
  link?: string;
  tooltipTitleOverride?: TooltipProps['title'];
  maxWidth?: string;
  'data-testid'?: string;
};
export const HealthIndicatorWithName = ({
  name,
  link,
  tooltipTitleOverride,
  maxWidth = '100%',
  'data-testid': dataTestId,
  ...props
}: HealthIndicatorWithNameProps) => {
  const soloLinkProps = !!link ? { link, darkText: true } : undefined;
  return (
    <HealthIndicatorStyles.HealthIndicatorWithNameContainer>
      <HealthIndicator {...props} />
      <OptionalSoloLinkContainer soloLinkProps={soloLinkProps}>
        <DisplayContents data-testid={dataTestId}>
          <SoloTableTextEllipsesCell value={name ?? ''} tooltipTitleOverride={tooltipTitleOverride ?? name} />
        </DisplayContents>
      </OptionalSoloLinkContainer>
    </HealthIndicatorStyles.HealthIndicatorWithNameContainer>
  );
};

export const HealthIndicatorRingWithPermanentIcon = ({ state, icon }: { state: State; icon: React.ReactNode }) => {
  const { border: borderColor } = getStatusColors(state ?? State.ACCEPTED);

  return (
    <HealthIndicatorStyles.HealthIndicatorRing borderColor={borderColor} size={15}>
      <Svg asset={icon} color={borderColor} size='100%' />
    </HealthIndicatorStyles.HealthIndicatorRing>
  );
};

interface HealthIndicatorIconProps {
  icon: React.ReactNode;
  status: Status | undefined;
  size?: number;
}
export const HealthIndicatorIcon = ({ icon, status, size = 60 }: HealthIndicatorIconProps) => {
  const state = status?.state ?? State.ACCEPTED;
  const statusColor = getStatusColors(state);
  // If it's an accepted state we don't want to show a border, so have it match the background
  const borderColor = state === State.ACCEPTED ? statusColor.background : statusColor.border;

  return (
    <HealthIndicatorStyles.HealthIndicatorIcon
      borderColor={borderColor}
      backgroundColor={statusColor.background}
      iconColor={statusColor.icon}
      size={size}>
      {icon}
    </HealthIndicatorStyles.HealthIndicatorIcon>
  );
};
