import { overviewApi } from 'Api/overview';
import { Asset } from 'assets';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloGridTableToggle, { isTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { OverviewTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { docLinks } from 'utils/url-external-links-map';
import { GatewaysLandingBody } from './GatewaysLandingBody';

const { useListGateways } = overviewApi;

export const GatewayLanding = () => {
  di(useListGateways);
  const [isTable, setIsTable] = useState(isTableViewPreferred());
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const filtersOn = !!nameFilter.length || !!statusFilter.length;

  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging(OverviewTablePageSize);

  const { data: gateways, error: gatewaysError } = useListGateways(nameFilter, apiPaginationObject, statusFilter);
  const isLoading = gateways === undefined;

  useSetPagingTotal(gateways?.filteredTotalGateways);
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(gateways);

  return (
    <OverviewStyles.Container data-testid='gateways-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.GatewayIcon />}
          Gateways
        </OverviewStyles.Header.Title>
        {!gatewaysError && (
          <OverviewStyles.Header.FiltersWrapper>
            <HealthCountBox
              onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
              onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
              isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
              isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
              totalCount={gateways?.totalGateways}
              errorCount={gateways?.gatewayErrors}
            />
            <OverviewStyles.Header.Filters filters={1}>
              <SoloInput
                value={nameFilter}
                placeholder={'Search by name...'}
                aria-label={'Search by name'}
                onChange={evt => setNameFilter(evt.target.value)}
              />
              <SoloGridTableToggle isTable={isTable} onChange={newIsTable => setIsTable(newIsTable)} />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      <GatewaysLandingBody
        filtersOn={filtersOn}
        isLoading={isLoading}
        itemsError={gatewaysError}
        isTable={isTable}
        items={gateways?.gateways ?? []}
        pagingData={pagingData}
        data-testid='gateways-landing-body-empty'
        icon={<Asset.GatewayIcon />}
        docsLink={docLinks.gateway.root}
        resourceNamePlural='Gateways'
      />
    </OverviewStyles.Container>
  );
};
