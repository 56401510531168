import { Tooltip } from 'antd';
import { Asset } from 'assets';
import Highlight, { defaultProps, Language as BaseLanguage, Prism } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';
import { ReactNode, useState } from 'react';
import { copyTextToClipboard, doDownload } from 'utils/helpers';
import { FancyCodeDisplayerStyles as Styles } from './FancyCodeDisplayer.style';

// Official way on how to get access to additional languages - https://github.com/FormidableLabs/prism-react-renderer#faq
((global ?? window) as any).Prism = Prism;
require('prismjs/components/prism-django'); // contains both `django` and `jinja2`
// Default react prism adds these to `languages` map as aliases for `markdown` but never added it to thier `.d.ts` file
type DefaultMarkupAliases = 'html' | 'mathml' | 'svg' | 'xml' | 'ssml' | 'atom' | 'rss';
export type Language = BaseLanguage | DefaultMarkupAliases | 'jinja2';

const displayTheme = {
  ...theme,
  backgroundColor: 'transparent',
  overflow: 'initial'
};

export const FancyCodeDisplayer = ({
  contentString,
  description,
  copyable = true,
  type,
  includeBorderBackground = true,
  padLineNumbersWithZeros = false,
  hideLineNumbers = false,
  maxHeight
}: {
  description?: ReactNode;
  contentString: string;
  copyable?: boolean;
  type: Language;
  includeBorderBackground?: boolean;
  padLineNumbersWithZeros?: boolean | number;
  hideLineNumbers?: boolean;
  maxHeight?: number | 'none';
}) => {
  const [attemptedCopy, setAttemptedCopy] = useState<boolean | 'inactive'>('inactive');
  const [attemptedDownload, setAttemptedDownload] = useState<boolean | 'inactive'>('inactive');

  const attemptCopyToClipboard = () => {
    setAttemptedCopy(copyTextToClipboard(contentString));

    setTimeout(() => {
      setAttemptedCopy('inactive');
    }, 500);
  };

  const attemptDownload = () => {
    setAttemptedDownload(true);
    // TODO:  Pull a better name in?  Or convert between data types based on type passed in?
    doDownload(contentString, `${type}-code.${type}`);
    setAttemptedDownload('inactive');
  };

  return (
    <Styles.RelativeHook data-testid='fancy-code-displayer'>
      {copyable && (
        <>
          <Tooltip title='Download' placement='left' trigger='hover'>
            <Styles.DownloadButton
              shiftDown={!!description}
              copySuccessful={attemptedDownload}
              onClick={attemptDownload}
              aria-label='Download Code'>
              <Asset.DownloadIcon />
            </Styles.DownloadButton>
          </Tooltip>
          <Tooltip title='Copy' placement='right' trigger='hover'>
            <Styles.CopyButton
              shiftDown={!!description}
              copySuccessful={attemptedCopy}
              onClick={attemptCopyToClipboard}
              aria-label='Copy Code'>
              <Asset.DocumentIcon />
            </Styles.CopyButton>
          </Tooltip>
        </>
      )}
      <Styles.Container
        removeLineNumberPadding={hideLineNumbers}
        includeBorderBackground={includeBorderBackground}
        maxHeight={maxHeight}
        className='FancyCodeDisplayerContainer'>
        {!!description && <Styles.DescriptionContainer>{description}</Styles.DescriptionContainer>}
        <Styles.FancyCodeContainer>
          <Highlight {...defaultProps} theme={displayTheme} code={contentString} language={type as BaseLanguage}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <Styles.Pre className={className} style={style}>
                {tokens
                  .filter(line => !!line.length)
                  .map((line, i) => (
                    <div key={i} {...getLineProps({ line, key: i })}>
                      {!hideLineNumbers && (
                        <>
                          {padLineNumbersWithZeros ? (
                            <Styles.LineNo>
                              {(i + 1)
                                .toString()
                                .padStart(
                                  typeof padLineNumbersWithZeros === 'number' ? padLineNumbersWithZeros : 2,
                                  '0'
                                )}
                            </Styles.LineNo>
                          ) : (
                            <Styles.LineNo>{i + 1}</Styles.LineNo>
                          )}
                        </>
                      )}
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  ))}
              </Styles.Pre>
            )}
          </Highlight>
        </Styles.FancyCodeContainer>
      </Styles.Container>
    </Styles.RelativeHook>
  );
};
