import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { Loading } from 'Components/Common/Loading';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import {
  GetUIFeatureFlagsResponse_License,
  GetUIFeatureFlagsResponse_License_LicenseState
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import { di } from 'react-magnetic-di';
import { useLicenses } from 'utils/permissions';
import { externalLinks } from 'utils/url-external-links-map';
import { SettingsDropdownStyles as Styles } from './SettingsDropdown.style';

function formatExpirationDate(timestamp?: Timestamp) {
  const date = new Date((timestamp?.seconds ?? 0) * 1000);
  return date.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit' });
}

interface LicenseRowProps {
  name: string;
  license: GetUIFeatureFlagsResponse_License | undefined;
  whyBuy: string;
}
const LicenseRow = ({ name, license, whyBuy }: LicenseRowProps) => {
  const state = license?.state ?? GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_DENIED;
  const hasLicense = state !== GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_DENIED;

  return (
    <Styles.LicenseRow data-testid={`topbar-license-row-${name.toLowerCase()}-${state}`}>
      <Styles.ProductName>
        <div>
          <Styles.Gloo>Gloo</Styles.Gloo> <Styles.AfterGloo>{name}</Styles.AfterGloo>
        </div>
        <Tooltip
          title={
            <>
              <Styles.InfoTooltipHeader>Why buy?</Styles.InfoTooltipHeader>
              <p>{whyBuy}</p>
            </>
          }
          trigger='hover'>
          <Styles.InfoIconHolder>
            <Asset.InfoIcon />
          </Styles.InfoIconHolder>
        </Tooltip>
      </Styles.ProductName>
      <Styles.LicenseStatusCell>
        <Styles.LicenseStatusText>
          {hasLicense ? formatExpirationDate(license?.expirationTime) : 'No License'}
        </Styles.LicenseStatusText>
        {
          {
            [GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_DENIED]: <Styles.NoLicenseXSmall />,
            [GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRED]: <Asset.ErrorCircleIcon />,
            [GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRES_SOON]: <Asset.WarningCircleIcon />,
            [GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_GRANTED]: <Asset.SmallLightGreenCheck />
          }[state]
        }
      </Styles.LicenseStatusCell>
    </Styles.LicenseRow>
  );
};

export const SettingsDropdown = () => {
  di(useLicenses);
  const { licenses, loading } = useLicenses();
  if (loading) {
    return (
      <Styles.LoadingHolder>
        <Loading small center />
      </Styles.LoadingHolder>
    );
  }
  return (
    <Styles.Container>
      <Styles.InnerTop>
        <Styles.Header>Gloo Licenses</Styles.Header>
        <LicenseRow
          name='Mesh'
          license={licenses.mesh}
          whyBuy='Secure, observe, and control service-to-service communication across Kubernetes clusters, clouds, and environments with an Istio-based service mesh that is hardened for production. Get n-4 Istio version support with security patches and CVEs, FIPS builds, and central resource management.'
        />
        <LicenseRow
          name='Mesh Gateway'
          license={licenses.gateway}
          whyBuy='Secure, control, and observe ingress traffic for your cluster with a powerful, feature-rich API Gateway that provides exceptional load balancing, routing, and traffic control capabilities, such as external auth, rate limiting, traffic shifting, outlier detection, failover, fault injection, and more.'
        />
        <LicenseRow
          name='Network'
          license={licenses.network}
          whyBuy='Provide connectivity, security, and observability for containerized workloads with a  Cilium-based container network interface (CNI) plug-in that leverages the Linux kernel technology eBPF.'
        />
        <LicenseRow
          name='Mesh Core'
          license={licenses.core}
          whyBuy='Get 24/7 full Istio version support for n-4 versions with security patches, CVE fixes, FIPS builds, and Istio Lifecycle management. Get production and security insights, metrics, tracing, and more.'
        />
      </Styles.InnerTop>
      <Styles.InnerBottom>
        <ExternalSoloLink href={externalLinks.solo_io.talk_to_an_expert}>CONTACT SOLO &gt;</ExternalSoloLink>
      </Styles.InnerBottom>
    </Styles.Container>
  );
};
