import { Code } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/insight_pb';
import { insightCodeMapToArray } from 'utils/dashboard/dashboard-helpers';
import { InsightCodeMap } from 'utils/dashboard/dashboard-types';

export enum DashboardUtilsKey {
  insightsEngineIsRunning = 'insightsEngineIsRunning'
}

interface DashboardUtilsCodesMap extends InsightCodeMap, Record<DashboardUtilsKey, Code> {}

/**
 * This maps the group/key values from the insight code
 * to be more readable.
 */
export const dashboardUtilsCodesMap: DashboardUtilsCodesMap = {
  [DashboardUtilsKey.insightsEngineIsRunning]: {
    // SYS0001 is a bare bones insight generated to ensure the system is working.
    group: 'SYS',
    key: '0001'
  }
};

export const dashboardUtilsCodesArray = insightCodeMapToArray(dashboardUtilsCodesMap);
