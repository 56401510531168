import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloButtonSolid } from 'Styles/CommonEmotions/button';

export namespace AuthLoginPageStyles {
  export const Container = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
  `;

  export const BackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    svg {
      width: 100vw;
      min-height: 100vh;
    }
  `;

  export const ScreenSplitter = styled.div`
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
  `;

  export const LoginPrompt = styled.div(
    ({ theme }) => css`
      position: relative;
      cursor: default;
      margin: 0 auto;
      padding: 40px 0 50px;
      width: 500px;

      svg {
        width: 264px;
      }
    `
  );

  export const Welcome = styled.div(
    ({ theme }) => css`
      font-size: 48px;
      color: ${theme.neptuneBlue};
      margin: 30px 0 20px;
    `
  );
  export const Subtitle = styled.div`
    margin-bottom: 30px;
    font-size: 18px;
  `;
  export const FauxSoloButton = styled(SoloButtonSolid)`
    padding: 13px 30px;
  `;

  export const Copyright = styled.div(
    ({ theme }) => css`
      color: ${theme.juneGrey};
      font-size: 16px;
      margin-top: 40px;
    `
  );
  export const PrivacyPolicyContainer = styled.a(
    ({ theme }) => css`
      color: ${theme.juneGrey};
      font-weight: bold;
      margin-left: 8px;
    `
  );
}
