import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphZIndex } from 'Styles/graph';

export const usageInfoTabWidth = 275;

export enum UsageInfoTopicEnum {
  Legend = 1,
  Settings = 2
}

export namespace UsageInfoTabStyles {
  type UsageInfoTabSwivelProps = {
    topicShown?: UsageInfoTopicEnum;
  };

  export const Swivel = styled.div<UsageInfoTabSwivelProps>(
    ({ topicShown, theme }) => css`
      position: absolute;
      top: 0;
      bottom: 50px;
      left: ${!topicShown ? `-${usageInfoTabWidth}px` : '0'};
      z-index: ${graphZIndex.usageInfoTab};
      border-top: 1px solid ${theme.aprilGrey};

      transition: left 0.3s ease-out;
    `
  );

  export const Container = styled.div(
    ({ theme }) => css`
      width: ${usageInfoTabWidth}px;
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 18px 12px 0;
      background: white;
      border-right: 1px solid ${theme.aprilGrey};

      font-family: 'Proxima Nova', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif', 'pficon';

      ::-webkit-scrollbar {
        background-color: hsl(0, 0%, 98.5%);
        border-radius: 10px;
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: grey;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:vertical {
        height: 20px !important;
        width: 16px;
      }
    `
  );

  export const CloseXContainer = styled.div`
    position: absolute;
    display: flex;
    right: 18px;
    top: 18px;
    z-index: 2;

    > svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  `;

  export const TabsHolder = styled.div<UsageInfoTabSwivelProps>(
    ({ topicShown }) => css`
      position: absolute;
      right: -186px;
      top: 50%;
      z-index: 2;
      display: ${!topicShown ? `none` : 'block'};
      transform: rotate(90deg);
    `
  );
  export const TabsSpinner = styled.div`
    display: flex;
    gap: 10px;
  `;

  type TabProps = {
    isActive: boolean;
  };
  export const Tab = styled.div<TabProps>(
    ({ isActive, theme }) => css`
      padding: 10px 0;
      font-size: 18px;
      line-height: 22px;
      border-radius: 10px 10px 0 0;
      border: 1px solid ${theme.aprilGrey};
      width: 160px;
      text-align: center;
      cursor: default;

      ${isActive
        ? css`
            color: ${theme.seaBlue};
            background: white;
            border-bottom: 1px solid white;
          `
        : css`
            color: ${theme.septemberGrey};
            background: ${theme.marchGrey};
            cursor: pointer;
          `}
    `
  );
}
