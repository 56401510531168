import { grpcWebFetchTransport, soloGrpcCall, useRequest } from 'Api/helpers';
import {
  GetGraphqlApiExplorerSettingsRequest,
  GetGraphqlApiRequest
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { GraphqlApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb.client';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { buildIdFromRef, capitalizeFirstLetters } from 'utils/helpers';

/**
 * Graphql API functions and hooks.
 */
export namespace gqlApi {
  const client = new GraphqlApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.GraphQlApi/' + capitalizeFirstLetters(fn.name);

  //
  // Get Graphql API
  //
  export function getGraphqlApi(routeTableRef: ClusterObjectRef, istioRouteName: string) {
    const request: GetGraphqlApiRequest = { routeTableRef, istioRouteName };
    return soloGrpcCall(client.getGraphqlApi(request));
  }
  export function useGetGraphqlApi(...args: Parameters<typeof getGraphqlApi>) {
    return useRequest(gqlApi.getGraphqlApi, args, {
      key: 'get-gql-api' + buildIdFromRef(args[0]) + '.' + args[1],
      methodDescriptorName: getMethodDescriptorName(client.getGraphqlApi)
    });
  }

  //
  // Get Graphql Explorer Settings
  //
  export function getGraphqlExplorerSettings(routeTableRef?: ClusterObjectRef) {
    const request: GetGraphqlApiExplorerSettingsRequest = { routeTableRef };
    return soloGrpcCall(client.getGraphqlApiExplorerSettings(request));
  }
  export function useGetGraphqlExplorerSettings(...args: Parameters<typeof getGraphqlExplorerSettings>) {
    return useRequest(gqlApi.getGraphqlExplorerSettings, args, {
      key: 'get-gql-explorer-settings',
      methodDescriptorName: getMethodDescriptorName(client.getGraphqlApiExplorerSettings)
    });
  }
}
