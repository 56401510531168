import React, { useState, useEffect } from 'react';
import { NetworkGraphContainer } from '../MainGraph/NetworkGraphContainer';
import { GraphFilters } from '../MainGraph/GraphFilters';
import { useInterval } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { GraphLandingStyles } from '../MainGraph/GraphLanding.style';
import { graphApi } from 'Api/graphs';
import { CardStyles } from 'Components/Common/Card';
import { FindOrHideSearchBox } from 'Components/Common/Input/FindOrHideSearchBox';
import { GraphFetchSettings } from '../MainGraph/GraphFetchSettings';
import { GraphFiltersType } from '../General/graph-filter-utils';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { Asset } from 'assets';
import { useGraphFetchSettingsContext } from '../Context/GraphFetchSettingsContext';
import { useGraphUXSettingsContext } from '../Context/GraphUXSettingsContext';

export const WorkspaceGraphLanding = () => {
  usePermissionGateRedirect(Permission.GraphEnabled);
  const { workspaceName } = useParams();
  const workspaceId = workspaceName ?? 'unknown';

  const { findName, hideName, setFindName, setHideName } = useGraphUXSettingsContext();

  const { fetchSettings, refreshRate, refreshGraphData, toggleRefreshPause } = useGraphFetchSettingsContext();
  const [cachedGraphDataError, setCachedGraphDataError] = useState<any>();

  const [filters, setFilters] = useState<GraphFiltersType>({
    findName,
    hideName,
    workspaces: [workspaceId]
  });

  const {
    data: graphData,
    error: graphDataError,
    mutate: manualGraphMutate,
    isValidating: graphDataIsValidating,
    abort: graphRequestAbort
  } = graphApi.useGetGraph(
    [],
    [],
    [workspaceId], // This does not rely on fetchSettings and shoult not
    undefined,
    fetchSettings.endTime,
    fetchSettings.window,
    fetchSettings.step,
    fetchSettings.istioMetrics,
    fetchSettings.ciliumMetrics,
    fetchSettings.tcpMetrics
  );

  // Keep track of error between loading retries, and clear once we have graph data
  useEffect(() => {
    if (graphDataError) {
      setCachedGraphDataError(graphDataError);
    } else if (!!graphData) {
      setCachedGraphDataError(undefined);
    }
  }, [graphDataError, graphData]);

  const doRefresh = () => refreshGraphData(manualGraphMutate);
  const doPauseRefresh = (on: boolean) => toggleRefreshPause(on, manualGraphMutate);
  useInterval(() => doRefresh, refreshRate);

  useEffect(() => {
    setFilters({
      findName,
      hideName,
      workspaces: [workspaceId]
    });
  }, [findName, hideName, workspaceId]);

  return (
    <GraphLandingStyles.LandingContainer>
      <CardStyles.TitleContainer>
        <div>
          <CardStyles.TitleIconContainer>
            <Asset.GraphIcon />
          </CardStyles.TitleIconContainer>
          <GraphLandingStyles.TitleWord>Graph</GraphLandingStyles.TitleWord>
          <div data-testid='graph-filter-by-name-block'>
            <FindOrHideSearchBox
              findName={findName}
              setFindName={setFindName}
              hideName={hideName}
              setHideName={setHideName}
            />
          </div>
        </div>

        <GraphFetchSettings
          hasEnoughFilters={true}
          isGraphRefreshing={!graphData || graphDataIsValidating}
          doRefresh={doRefresh}
        />
      </CardStyles.TitleContainer>

      <GraphLandingStyles.GraphContainer>
        <GraphFilters
          presetFilters={{
            workspaces: [workspaceId],
            namespaces: [],
            clusters: []
          }}
          graphRequestAbort={graphRequestAbort}
        />
        <NetworkGraphContainer
          graphData={graphData}
          filters={filters}
          graphDataError={cachedGraphDataError}
          doPauseRefresh={doPauseRefresh}
          isLoading={!graphDataError && !graphData}
        />
      </GraphLandingStyles.GraphContainer>
    </GraphLandingStyles.LandingContainer>
  );
};
