import { CardStyles } from 'Components/Common/Card';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { WorkspaceDependencies } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { WorkspaceListCardType, WorkspacesListCard } from './WorkspacesListCard';

export interface Props {
  dependencies: WorkspaceDependencies | undefined;
  importingTypeName: string;
}
export const WorkspaceDependenciesCard = ({ dependencies, importingTypeName }: Props) => {
  return (
    <CardStyles.Card data-testid='workspace-dependencies-card'>
      <CardStyles.CardHeader>Workspace Dependencies</CardStyles.CardHeader>

      <SoloListCardStyles.HorizontalListCardsContainer>
        <WorkspacesListCard
          cardType={WorkspaceListCardType.Exported}
          workspaces={dependencies?.workspacesExportedTo ?? []}
        />
        <WorkspacesListCard
          cardType={WorkspaceListCardType.Importing}
          importingTypeName={importingTypeName}
          workspaces={dependencies?.workspacesImporting ?? []}
        />
      </SoloListCardStyles.HorizontalListCardsContainer>
    </CardStyles.Card>
  );
};
