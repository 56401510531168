import { overviewApi } from 'Api/overview';
import { DataError } from 'Components/Common/DataError';
import { di } from 'react-magnetic-di';
import { Permission, usePermissions } from 'utils/permissions';
import { EmptyOverview } from './EmptyOverview';
import { OverviewClustersArea, OverviewWorkspacesArea } from './OverviewAreas';
import { OverviewLandingStyles } from './OverviewLanding.style';

const { useListClusters, useListOverviewWorkspaces } = overviewApi;

export const OverviewLanding = () => {
  di(useListClusters, useListOverviewWorkspaces);
  const { data: clusters, error: clustersError } = useListClusters();
  const { data: workspaces, error: workspacesError } = useListOverviewWorkspaces();
  const { hasPerm } = usePermissions();

  if (!!clustersError && !!workspacesError) {
    return <DataError error={workspacesError} />;
  } else if (!!clusters && !clusters.clusters.length && !!workspaces && !workspaces.workspaces.length) {
    return <EmptyOverview />;
  }

  return (
    <OverviewLandingStyles.Container
      data-testid='overview-landing'
      singleArea={!hasPerm(Permission.CanViewClustersArea)}>
      <OverviewWorkspacesArea />
      {hasPerm(Permission.CanViewClustersArea) && <OverviewClustersArea />}
    </OverviewLandingStyles.Container>
  );
};
