import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Asset } from 'assets';
import React from 'react';
import { ColorOrAllowed, ColorThemeFunc, handleColorOrColorThemeFunc } from 'Styles/colors';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { isNonNullableAndNonFalse } from 'utils/helpers';

//////////////////////////
// Tag
//////////////////////////
namespace TagStyles {
  interface TagWrapperProps {
    tagColor?: ColorOrAllowed | ColorThemeFunc;
  }

  export const CloseButton = styled.span<TagWrapperProps>(
    ({ tagColor, theme }) => css`
      position: relative;
      left: 3px;
      margin-left: 4px;

      display: inline-flex;
      width: 12px;
      height: 12px;
      border-radius: 6px;

      color: white;

      background: ${handleColorOrColorThemeFunc(tagColor, theme) ?? theme.aprilGrey};

      ${svgBasics({ height: 8 }, '#FFFFFF')}
      svg {
        align-self: center;
      }
    `
  );

  export const Label = styled.span`
    font-weight: bold;
    margin-right: 4px;
  `;

  export const Wrapper = styled.div<TagWrapperProps>(
    ({ tagColor, theme }) => css`
      display: inline-block;
      font-size: 14px;
      line-height: 22px;
      border-radius: 11px;
      padding: 0 8px;
      cursor: pointer;
      background: white;
      color: ${handleColorOrColorThemeFunc(tagColor, theme) ?? 'inherit'};
      border: 1px solid ${handleColorOrColorThemeFunc(tagColor, theme) ?? theme.aprilGrey};
      white-space: nowrap;
    `
  );
}

interface SoloTagProps {
  label?: string;
  value: React.ReactNode;
  color?: ColorOrAllowed | ColorThemeFunc;
  onClose?: () => void;
}
export const SoloTag = ({ label, value, color, onClose }: SoloTagProps) => {
  const closable = !!onClose;
  return (
    <TagStyles.Wrapper data-testid='solo-tag' tagColor={color} onClick={onClose}>
      {label && <TagStyles.Label>{label}:</TagStyles.Label>}
      {value}
      {closable && (
        <TagStyles.CloseButton tagColor={color}>
          <Asset.CloseX />
        </TagStyles.CloseButton>
      )}
    </TagStyles.Wrapper>
  );
};

//////////////////////////
// TagList
//////////////////////////

const TagListWrapper = styled.div<{ includePadding?: boolean }>(({ includePadding }) => {
  return css`
    display: flex;
    flex-wrap: wrap;
    ${includePadding && `padding: 17px 20px 12px;`}
    gap: 8px;
  `;
});

type LabelValuePair = [string, string];
interface TagListItemProps extends SoloTagProps {
  key?: string;
  render?: (data: TagListItemProps) => React.ReactNode;
}
// If `false` is returned in the array, it is skipped
export const TagList = ({
  tags,
  includePadding
}: {
  tags: (TagListItemProps | LabelValuePair | false)[];
  includePadding?: boolean;
}) => {
  return (
    <TagListWrapper data-testid='solo-tag-list' includePadding={includePadding}>
      {tags.filter(isNonNullableAndNonFalse).map(data => {
        data = Array.isArray(data) ? { label: data[0], value: data[1] } : data;
        const { key: inKey, render, ...tagProps } = data;
        const key = inKey ?? [tagProps.label, tagProps.value].filter(v => !!v).join('~~');
        if (render) {
          return render({ key, ...tagProps });
        }

        return <SoloTag key={key} {...tagProps} />;
      })}
    </TagListWrapper>
  );
};
