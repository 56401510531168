import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { healthColors } from 'Styles/colors';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getStatusColors, HealthStateProps } from 'utils/health-status';

export namespace OverviewDetailsStyles {
  export const Container = styled.div`
    margin-top: 18px;
  `;

  export const SubDetails = styled.div`
    display: grid;
    grid-gap: 17px;
  `;
  export const SubDetailsRow = styled.div`
    display: grid;
    grid-gap: 17px;
    grid-template-columns: 1fr 1fr;
  `;

  export const SubDetail = styled.div<HealthStateProps>(
    ({ state = State.ACCEPTED, theme }) => css`
      border: 1px solid ${state === State.ACCEPTED ? theme.aprilGrey : getStatusColors(state).border};
      border-radius: 3px;
    `
  );

  export const SubDetailsTitle = styled.div<HealthStateProps>(({ theme, state = State.ACCEPTED }) => {
    const statusColors = getStatusColors(state);
    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 7px 6px 8px;
      font-weight: 500;
      background: ${state === State.ACCEPTED ? theme.januaryGrey : statusColors.background};
      color: ${state === State.ACCEPTED ? theme.neptuneBlue : statusColors.text};

      ${svgBasics({ height: 19 }, statusColors.icon)}
    `;
  });

  export const SubDetailsBody = styled.div`
    padding: 10px 10px 12px;

    > div {
      display: flex;
      align-items: baseline;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `;
  interface SubDetailsBodyRowProps {
    health?: keyof typeof healthColors;
  }
  export const SubDetailsBodyRow = styled.div<SubDetailsBodyRowProps>(
    ({ health = 'normal' }) => css`
      color: ${healthColors[health].dark};
    `
  );
  export const DottedLined = styled.div(
    ({ theme }) => css`
      flex: 1;
      margin-left: 5px;
      line-height: 17px;
      border-bottom: 1px dashed ${theme.aprilGrey};
    `
  );
  export const Number = styled.div`
    font-weight: 500;
    padding-left: 5px;
  `;
  export const SubDetailsBodyColumn = styled.div`
    flex: 1;
  `;
  export const SubDetailsBodyColumnHeader = styled.div`
    display: flex;
    gap: 10px;
    font-weight: bold;
    padding-bottom: 10px;
  `;

  export const MoreLinkLine = styled.div`
    width: 100%;

    ${SoloLinkStyles.SoloLinkWrapper} {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      a {
        ${SoloLinkStyles.SoloLinkLooks} {
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;

          ${svgBasics({ width: 15 }, 'currentColor')}
          svg {
            margin-left: 5px;
            transform: translateY(2px);
          }
        }
      }
    }
  `;
}
