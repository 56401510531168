import { portalApi } from 'Api/portal';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { SoloButtonLink } from 'Styles/CommonEmotions/button';
import { Asset } from 'assets';
import { ApiType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { makeClusterObjectRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { getApiTypeIcon } from 'utils/types';
import { buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { WorkspaceDependenciesCard } from '../../Workspaces/WorkspaceDependenciesCard';
import { ApiDestinationTable } from './ApiDestinationTable';
import { OpenApiSchemaCard } from './Schema/OpenApiSchemaCard';

const { useGetPortalApiDetails } = portalApi;

export const OpenApiDetails = () => {
  di(useGetPortalApiDetails, useParams);
  const { routeTableName, routeTableNamespace, routeTableCluster } = useParams();
  const { lookupWorkspaceName } = useNamespaceClusterToWorkspaceMap();
  const routeTableRef = makeClusterObjectRef(routeTableName, routeTableNamespace, routeTableCluster)!;

  const { data: apiData, error: apiError } = useGetPortalApiDetails(routeTableRef);

  const gateways = useMemo(
    () =>
      apiData?.gatewayRefs.map(ref => {
        const workspaceName = lookupWorkspaceName(ref);
        return {
          ref,
          url: buildWorkspaceRouteDetailsUrl(workspaceName, RouteType.VIRTUAL_GATEWAY, ref)
        };
      }),
    [apiData?.gatewayRefs, lookupWorkspaceName]
  );

  if (!!apiError || !apiData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getApiTypeIcon(ApiType.PORTAL_OPENAPI)} objRef={routeTableRef} />
        {!!apiError ? (
          <DataError error={apiError} />
        ) : (
          <Loading message={`Retrieving ${routeTableRef.name} OpenAPI details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getApiTypeIcon(ApiType.PORTAL_OPENAPI)}
        status={apiData?.status}
        objRef={routeTableRef}
        workspaceRef={apiData?.ownerWorkspace?.ref}
        resourceType={ResourceType.ROUTE_TABLE}
        buttons={[
          <SoloButtonLink
            key='rt'
            to={buildWorkspaceRouteDetailsUrl(
              apiData?.ownerWorkspace?.ref?.name,

              RouteType.ROUTE_TABLE,
              routeTableRef
            )}>
            Route Table
          </SoloButtonLink>
        ]}
      />

      <OpenApiSchemaCard routeTableRef={routeTableRef} />

      <CardStyles.HorizontalLayout>
        <CardStyles.Card>
          <CardStyles.CardHeader>
            Portals
            <CardStyles.CardHeaderRightIcon>
              <Asset.PortalsIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>

          <SoloListCard title='Portals' dataSource={apiData.portalRefs.map(ref => ref.name)} />
        </CardStyles.Card>

        <CardStyles.Card>
          <CardStyles.CardHeader>
            Gateways
            <CardStyles.CardHeaderRightIcon>
              <Asset.GatewayIcon />
            </CardStyles.CardHeaderRightIcon>
          </CardStyles.CardHeader>
          <SoloListCard
            title='Gateways'
            dataSource={
              gateways?.map(({ ref, url }) => ({
                key: url,
                data: <SoloLink link={url}>{ref.name}</SoloLink>
              })) ?? []
            }
          />
        </CardStyles.Card>
      </CardStyles.HorizontalLayout>

      <WorkspaceDependenciesCard
        dependencies={apiData.dependencies}
        importingTypeName='Route Table'
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Applied to Destinations
          <CardStyles.CardHeaderRightIcon>
            <Asset.ServiceIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <ApiDestinationTable destinations={apiData.destinations} />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
