import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { IconNextToText } from 'Styles/CommonEmotions/text';
import { Asset } from 'assets';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { CiliumOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { OverviewDetailsStyles as Styles } from './OverviewDetails.style';

export const OverviewClusterCiliumDetails = ({
  cilium,
  clusterName
}: {
  cilium: CiliumOverview;
  clusterName: string;
}) => {
  const state = cilium.status?.state;
  const getDesiredHealth = (current: number, target: number) => {
    if (current >= target) {
      // If fulfilled return normal no matter the container's health
      return 'normal';
    } else {
      // When the text is in a non-fulfilled state, have it's state match the container's
      return state === State.FAILED ? 'error' : 'warning';
    }
  };
  return (
    <Styles.SubDetail data-testid='overview-cluster-cilium-details' state={state}>
      <Styles.SubDetailsTitle state={state}>
        <div>
          <IconNextToText>
            <HealthIndicator status={cilium.status} size={14} />
            Cilium Overview
            <SecondaryInfoItem label='Version' data={cilium.version} small />
          </IconNextToText>
        </div>
        <Asset.CiliumIcon />
      </Styles.SubDetailsTitle>

      <Styles.SubDetailsBody>
        <Styles.SubDetailsRow>
          <Styles.SubDetailsBodyColumn>
            <Styles.SubDetailsBodyColumnHeader>Agent</Styles.SubDetailsBodyColumnHeader>
            <Styles.SubDetail>
              <Styles.SubDetailsBody>
                <Styles.SubDetailsBodyRow data-testid={`${clusterName}-agent-desired`}>
                  <div>Desired</div>
                  <Styles.DottedLined />
                  <Styles.Number>{cilium.agentDesired}</Styles.Number>
                </Styles.SubDetailsBodyRow>

                <Styles.SubDetailsBodyRow
                  health={getDesiredHealth(cilium.agentReady, cilium.agentDesired)}
                  data-testid={`${clusterName}-agent-ready`}>
                  <div>Ready</div>
                  <Styles.DottedLined />
                  <Styles.Number>
                    {cilium.agentReady}/{cilium.agentDesired}
                  </Styles.Number>
                </Styles.SubDetailsBodyRow>

                <Styles.SubDetailsBodyRow
                  health={getDesiredHealth(cilium.agentAvailable, cilium.agentDesired)}
                  data-testid={`${clusterName}-agent-available`}>
                  <div>Available</div>
                  <Styles.DottedLined />
                  <Styles.Number>
                    {cilium.agentAvailable}/{cilium.agentDesired}
                  </Styles.Number>
                </Styles.SubDetailsBodyRow>
              </Styles.SubDetailsBody>
            </Styles.SubDetail>
          </Styles.SubDetailsBodyColumn>

          <Styles.SubDetailsBodyColumn>
            <Styles.SubDetailsBodyColumnHeader>Operator</Styles.SubDetailsBodyColumnHeader>
            <Styles.SubDetail>
              <Styles.SubDetailsBody>
                <Styles.SubDetailsBodyRow data-testid={`${clusterName}-operator-desired`}>
                  <div>Desired</div>
                  <Styles.DottedLined />
                  <Styles.Number>{cilium.operatorDesired}</Styles.Number>
                </Styles.SubDetailsBodyRow>

                <Styles.SubDetailsBodyRow
                  health={getDesiredHealth(cilium.operatorReady, cilium.operatorDesired)}
                  data-testid={`${clusterName}-operator-ready`}>
                  <div>Ready</div>
                  <Styles.DottedLined />
                  <Styles.Number>
                    {cilium.operatorReady}/{cilium.operatorDesired}
                  </Styles.Number>
                </Styles.SubDetailsBodyRow>

                <Styles.SubDetailsBodyRow
                  health={getDesiredHealth(cilium.operatorAvailable, cilium.operatorDesired)}
                  data-testid={`${clusterName}-operator-available`}>
                  <div>Available</div>
                  <Styles.DottedLined />
                  <Styles.Number>
                    {cilium.operatorAvailable}/{cilium.operatorDesired}
                  </Styles.Number>
                </Styles.SubDetailsBodyRow>
              </Styles.SubDetailsBody>
            </Styles.SubDetail>
          </Styles.SubDetailsBodyColumn>
        </Styles.SubDetailsRow>

        <Styles.SubDetailsRow>
          <Styles.SubDetailsBodyColumn>
            <Spacer mt={2}>
              <Styles.SubDetailsBodyColumnHeader>Hubble</Styles.SubDetailsBodyColumnHeader>
            </Spacer>
            <Styles.SubDetail>
              <Styles.SubDetailsBody>
                <Styles.SubDetailsBodyRow data-testid={`${clusterName}-hubble-desired`}>
                  <div>Desired</div>
                  <Styles.DottedLined />
                  <Styles.Number>{cilium.hubbleDesired}</Styles.Number>
                </Styles.SubDetailsBodyRow>

                <Styles.SubDetailsBodyRow
                  health={getDesiredHealth(cilium.hubbleReady, cilium.hubbleDesired)}
                  data-testid={`${clusterName}-hubble-ready`}>
                  <div>Ready</div>
                  <Styles.DottedLined />
                  <Styles.Number>
                    {cilium.hubbleReady}/{cilium.hubbleDesired}
                  </Styles.Number>
                </Styles.SubDetailsBodyRow>

                <Styles.SubDetailsBodyRow
                  health={getDesiredHealth(cilium.hubbleAvailable, cilium.hubbleDesired)}
                  data-testid={`${clusterName}-hubble-available`}>
                  <div>Available</div>
                  <Styles.DottedLined />
                  <Styles.Number>
                    {cilium.hubbleAvailable}/{cilium.hubbleDesired}
                  </Styles.Number>
                </Styles.SubDetailsBodyRow>
              </Styles.SubDetailsBody>
            </Styles.SubDetail>
          </Styles.SubDetailsBodyColumn>
        </Styles.SubDetailsRow>
      </Styles.SubDetailsBody>
    </Styles.SubDetail>
  );
};
