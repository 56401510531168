import {
  DirectiveDefinitionNode,
  DocumentNode,
  EnumTypeDefinitionNode,
  EnumTypeExtensionNode,
  FieldDefinitionNode,
  InputObjectTypeDefinitionNode,
  InputObjectTypeExtensionNode,
  InputValueDefinitionNode,
  InterfaceTypeDefinitionNode,
  InterfaceTypeExtensionNode,
  Kind,
  ObjectTypeDefinitionNode,
  ObjectTypeExtensionNode,
  parse,
  ScalarTypeDefinitionNode,
  ScalarTypeExtensionNode,
  TypeNode,
  UnionTypeDefinitionNode,
  UnionTypeExtensionNode
} from 'graphql';
import {
  ExecutableSchema,
  GetGraphqlApiResponse,
  GraphqlApiType,
  Resolution_Resolvers
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { Value } from 'proto/google/protobuf/struct_pb';

export type SupportedExtensionNode =
  // | SchemaExtensionNode
  | EnumTypeExtensionNode
  | UnionTypeExtensionNode
  | ObjectTypeExtensionNode
  | ScalarTypeExtensionNode
  | InterfaceTypeExtensionNode
  | InputObjectTypeExtensionNode;
export type SupportedDefinitionNode =
  | InterfaceTypeDefinitionNode
  | ObjectTypeDefinitionNode
  | EnumTypeDefinitionNode
  | InputObjectTypeDefinitionNode
  | UnionTypeDefinitionNode
  | ScalarTypeDefinitionNode
  // | OperationDefinitionNode
  | DirectiveDefinitionNode;
export const supportedExtensionTypes = [
  // Kind.SCHEMA_EXTENSION,
  Kind.ENUM_TYPE_EXTENSION,
  Kind.UNION_TYPE_EXTENSION,
  Kind.OBJECT_TYPE_EXTENSION,
  Kind.SCALAR_TYPE_EXTENSION,
  Kind.INTERFACE_TYPE_EXTENSION,
  Kind.INPUT_OBJECT_TYPE_EXTENSION
] as SupportedExtensionNode['kind'][];
export const supportedDefinitionTypes = [
  Kind.INTERFACE_TYPE_DEFINITION,
  Kind.OBJECT_TYPE_DEFINITION,
  Kind.ENUM_TYPE_DEFINITION,
  Kind.INPUT_OBJECT_TYPE_DEFINITION,
  Kind.UNION_TYPE_DEFINITION,
  Kind.SCALAR_TYPE_DEFINITION,
  // Kind.OPERATION_TYPE_DEFINITION,
  Kind.DIRECTIVE_DEFINITION
] as SupportedDefinitionNode['kind'][];
export interface SupportedDocumentNode extends DocumentNode {
  definitions: (SupportedExtensionNode | SupportedDefinitionNode)[];
}

export const getKindTypeReadableName = (definitionNode: SupportedExtensionNode | SupportedDefinitionNode) => {
  switch (definitionNode.kind) {
    case Kind.OBJECT_TYPE_DEFINITION:
    case Kind.OBJECT_TYPE_EXTENSION:
      return 'type';
    case Kind.ENUM_TYPE_DEFINITION:
    case Kind.ENUM_TYPE_EXTENSION:
      return 'enum';
    case Kind.INTERFACE_TYPE_DEFINITION:
    case Kind.INTERFACE_TYPE_EXTENSION:
      return 'interface';
    case Kind.INPUT_OBJECT_TYPE_DEFINITION:
    case Kind.INPUT_OBJECT_TYPE_EXTENSION:
      return 'input';
    case Kind.DIRECTIVE_DEFINITION:
      return 'directive';
    case Kind.UNION_TYPE_DEFINITION:
    case Kind.UNION_TYPE_EXTENSION:
      return 'union';
    case Kind.SCALAR_TYPE_DEFINITION:
    case Kind.SCALAR_TYPE_EXTENSION:
      return 'scalar';
    // case Kind.OPERATION_DEFINITION:
    //   return definitionNode.operation;
    default:
      return '';
  }
};

export const kindTypeSort = (a: SupportedDefinitionNode, b: SupportedDefinitionNode) => {
  if (a.kind === b.kind) {
    if ('name' in a && 'name' in b) return a.name.value.localeCompare(b.name.value);
    else return 0;
  }
  // - Objects
  const isAObjType = a.kind === Kind.OBJECT_TYPE_DEFINITION;
  const isBObjType = b.kind === Kind.OBJECT_TYPE_DEFINITION;
  if (isAObjType && a.name.value === 'Query') return -1;
  if (isBObjType && b.name.value === 'Query') return 1;
  if (isAObjType && a.name.value === 'Mutation') return -1;
  if (isBObjType && b.name.value === 'Mutation') return 1;
  if (isAObjType) return -1;
  if (isBObjType) return 1;
  // - Enums
  if (a.kind === Kind.ENUM_TYPE_DEFINITION) return -1;
  if (b.kind === Kind.ENUM_TYPE_DEFINITION) return 1;
  // - Interfaces
  if (a.kind === Kind.INTERFACE_TYPE_DEFINITION) return -1;
  if (b.kind === Kind.INTERFACE_TYPE_DEFINITION) return 1;
  // - Inputs
  if (a.kind === Kind.INPUT_OBJECT_TYPE_DEFINITION) return -1;
  if (b.kind === Kind.INPUT_OBJECT_TYPE_DEFINITION) return 1;
  // - Directives
  if (a.kind === Kind.DIRECTIVE_DEFINITION) return -1;
  if (b.kind === Kind.DIRECTIVE_DEFINITION) return 1;
  // - Unions
  if (a.kind === Kind.UNION_TYPE_DEFINITION) return -1;
  if (b.kind === Kind.UNION_TYPE_DEFINITION) return 1;
  // - Scalars
  if (a.kind === Kind.SCALAR_TYPE_DEFINITION) return -1;
  if (b.kind === Kind.SCALAR_TYPE_DEFINITION) return 1;
  // - Other Operation (e.g. subscription)
  // if (a.kind === Kind.OPERATION_DEFINITION) return -1;
  // if (b.kind === Kind.OPERATION_DEFINITION) return 1;
  //
  // Sorting extensions ("Kind..._EXTENSION") isn't necessary,
  // since they aren't shown in a list.
  //
  else return 0;
};
/**
 * @returns A boolean representing whether subscriptions were in the schema, and the parsed schema
 * with only the supported object definitions, sorted in the order: query, mutation, everything else.
 */
export const parseSchemaString = (schemaString: string | undefined) => {
  const emptyResponse = {
    schema: {
      kind: Kind.DOCUMENT,
      definitions: []
    } as SupportedDocumentNode,
    includedSubscriptions: [] as string[]
  };
  if (!schemaString) return emptyResponse;
  try {
    // Parse and return it.
    const parsedSchema = parse(schemaString);
    const includedSubscriptions: string[] = parsedSchema.definitions
      .filter(d => d.kind === Kind.OPERATION_DEFINITION && d.operation.toLowerCase() === 'subscription')
      .map(d => (d as any).name?.value ?? '<unnamed subscription>');
    const definitions = JSON.parse(
      JSON.stringify(
        parsedSchema.definitions.filter(
          d =>
            (supportedDefinitionTypes as string[]).includes(d.kind) ||
            (supportedExtensionTypes as string[]).includes(d.kind)
        )
      )
    ) as SupportedDefinitionNode[];
    // We can sort the definitions here, and any filtering will keep it sorted.
    definitions.sort(kindTypeSort);
    return { schema: { ...parsedSchema, definitions } as SupportedDocumentNode, includedSubscriptions };
  } catch (_) {
    return emptyResponse;
  }
};

/**
 * Traverses the field definition node to build the string representation of its return type.
 * @returns [prefix, base-type, suffix]
 */
export const getFieldReturnType = (
  field: FieldDefinitionNode | undefined | null | TypeNode | InputValueDefinitionNode
) => {
  const emptyType = {
    fullType: '',
    parts: {
      prefix: '',
      base: '',
      suffix: ''
    }
  };
  if (!field) return emptyType;
  let typePrefix = '';
  let typeSuffix = '';
  let typeBaseObj = 'type' in field ? field.type : (field as any);
  // The fieldDefinition could be nested.
  while (typeBaseObj?.kind === Kind.LIST_TYPE || typeBaseObj?.kind === Kind.NON_NULL_TYPE) {
    if (typeBaseObj?.kind === Kind.NON_NULL_TYPE) {
      typeSuffix = '!' + typeSuffix;
    } else if (typeBaseObj?.kind === Kind.LIST_TYPE) {
      typePrefix = typePrefix + '[';
      typeSuffix = ']' + typeSuffix;
    } else break;
    typeBaseObj = typeBaseObj.type;
  }
  if (typeBaseObj.kind === Kind.NAMED_TYPE)
    return {
      fullType: typePrefix + typeBaseObj.name.value + typeSuffix,
      parts: {
        prefix: typePrefix,
        base: typeBaseObj.name.value,
        suffix: typeSuffix
      }
    };
  else if (typeBaseObj.kind === Kind.ARGUMENT && typeBaseObj.value.kind === Kind.VARIABLE)
    return {
      fullType: '$' + typeBaseObj.value.name.value,
      parts: {
        prefix: '$',
        base: typeBaseObj.value.name.value,
        suffix: ''
      }
    };
  else return emptyType;
};

// ---------------------------------------------- //
// ---------------------------------------------- //
// ---------------------------------------------- //

export const getGraphqlApiType = (api: GetGraphqlApiResponse | undefined) => {
  if (api?.graphql.oneofKind === 'executableSchema') {
    if (api.graphql.executableSchema.executableSchema.oneofKind === 'proxied') {
      return GraphqlApiType.PROXIED;
    } else if (api.graphql.executableSchema.executableSchema.oneofKind === 'resolved') {
      return GraphqlApiType.RESOLVED;
    }
  } else if (api?.graphql.oneofKind === 'stitchedSchema') {
    return GraphqlApiType.STITCHED;
  }
  // Fallback to resolved.
  return GraphqlApiType.RESOLVED;
};

/**
 *
 */
export interface ResolverMap {
  [objectName: string]: {
    [fieldName: string]: Resolution_Resolvers;
  };
}

/**
 * This returns the actual JSON value from any of the google-protobuf types.
 * Noting that any key-value pairs with null values get removed when they are added to the
 * ResolverMap resource. This may be true for other resources as well.
 */
export function convertFromPBValue(obj: Value | undefined): any {
  if (!obj?.kind?.oneofKind || obj.kind.oneofKind === 'nullValue') {
    return null;
  }
  if (obj.kind.oneofKind === 'stringValue') {
    return obj.kind.stringValue;
  }
  if (obj.kind.oneofKind === 'numberValue') {
    return obj.kind.numberValue;
  }
  if (obj.kind.oneofKind === 'boolValue') {
    return obj.kind.boolValue;
  }
  if (obj.kind.oneofKind === 'listValue') {
    return obj.kind.listValue?.values.map((value: any) => convertFromPBValue(value));
  }
  // Last case: (obj.kind.oneofKind === 'structValue')
  return Object.fromEntries(
    Object.entries(obj.kind.structValue.fields).map(([key, value]) => [key, convertFromPBValue(value)])
  );
}

export const getArgumentDefaultValue = (argument: any) => {
  let defaultValue = '';
  if (!!argument.defaultValue?.value) {
    if (argument.defaultValue.kind === Kind.STRING) defaultValue = `"${argument.defaultValue.value}"`;
    else defaultValue = argument.defaultValue.value;
  }
  return defaultValue;
};

export const getExecutableSchema = (api: GetGraphqlApiResponse | undefined) => {
  return api?.graphql.oneofKind === 'executableSchema' ? api.graphql.executableSchema : undefined;
};

export const getResolved = (executableSchema: ExecutableSchema | undefined) => {
  return executableSchema?.executableSchema.oneofKind === 'resolved'
    ? executableSchema?.executableSchema.resolved
    : undefined;
};

export const getProxied = (executableSchema: ExecutableSchema | undefined) => {
  return executableSchema?.executableSchema.oneofKind === 'proxied'
    ? executableSchema.executableSchema.proxied
    : undefined;
};

export const getStitchedSchema = (api: GetGraphqlApiResponse | undefined) => {
  return api?.graphql.oneofKind === 'stitchedSchema' ? api.graphql.stitchedSchema : undefined;
};
