import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo } from 'Components/Common/SecondaryInfo';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { LabelsCell, createEllipseTextColumn, createStatusColumn } from 'Components/Common/SoloTableCells';
import SoloTableTextEllipsesCell from 'Components/Common/SoloTableTextEllipsesCell';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ListServicesResponse_ServiceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { ReactNode, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useIsOnlyCiliumEnabled } from 'utils/dashboard/dashboard-hooks';
import { buildIdFromRef } from 'utils/helpers';
import { useWindowInnerWidth } from 'utils/hooks';
import { buildServiceDetailsUrl } from 'utils/url-builders';

export const ServicesLandingBody = (props: CustomLandingBodyProps<ListServicesResponse_ServiceSummary>) => {
  return (
    <LandingBody
      {...props}
      GridComponent={undefined /*ServicesLandingBodyGrid -- currently removed as no official mocks*/}
      TableComponent={ServicesLandingBodyTable}
    />
  );
};

function getServiceDetailsUrl(ref?: ClusterObjectRef) {
  return ref ? buildServiceDetailsUrl(ref) : undefined;
}

//
// Grid variant
//
export const ServicesLandingBodyGrid = (props: BaseLandingBodyProps<ListServicesResponse_ServiceSummary>) => {
  di(useIsOnlyCiliumEnabled);
  const isOnlyCiliumEnabled = useIsOnlyCiliumEnabled();
  const { items: services, pagingData } = props;

  return (
    <>
      <OverviewGridStyles.Grid data-testid='services-landing-body'>
        {services.map(({ serviceRef, labels, status, requestsTotal, requestsError }) => {
          let metaInfo: { label: string; data: NonNullable<ReactNode> }[] = [];
          if (!!serviceRef) {
            metaInfo.push({
              label: 'Cluster',
              data: serviceRef.clusterName
            });
            metaInfo.push({
              label: 'Namespace',
              data: serviceRef.namespace
            });
          }
          if (!!labels.length) {
            metaInfo.push({
              label: 'Labels',
              data: <LabelsCell labelsMap={Object.entries(labels)} />
            });
          }
          if (!isOnlyCiliumEnabled) {
            metaInfo.push({
              label: 'Total Incoming Requests',
              data: requestsTotal
            });
            metaInfo.push({
              label: 'Errors',
              data: requestsError
            });
          }
          return (
            <li key={buildIdFromRef(serviceRef)}>
              {/* <a
                href={getServiceDetailsUrl()}
                data-testid={`${serviceRef?.name}-service-card`}
                aria-label={`Service Card: ${serviceRef?.name ?? 'Service Unnamed'}`}> */}
              <OverviewGridStyles.Card title={!serviceRef ? 'Missing data to visit service page.' : undefined}>
                <OverviewGridStyles.CardHeader>
                  {serviceRef?.name ?? 'Service Unnamed'}
                  {!isOnlyCiliumEnabled && <HealthIndicator status={status} size={15} />}
                </OverviewGridStyles.CardHeader>
                <OverviewGridStyles.CardContent>
                  <SecondaryInfo items={metaInfo} small={true} />
                </OverviewGridStyles.CardContent>
                <OverviewGridStyles.CardFooter>
                  <div></div>
                  {!!serviceRef && !isOnlyCiliumEnabled && (
                    <SoloLink link={getServiceDetailsUrl(serviceRef)!} withArrow>
                      Details
                    </SoloLink>
                  )}
                </OverviewGridStyles.CardFooter>
              </OverviewGridStyles.Card>
              {/* </a> */}
            </li>
          );
        })}
      </OverviewGridStyles.Grid>
      {!!pagingData.total && <SoloPagination {...pagingData} pageSizeOptions={[3, 6, 12, 24]} />}
    </>
  );
};

//
// Table variant
//
interface TableFields {
  key: string;
  status?: Status;
  name: string;
  namespace: string;
  clusterName: string;
  type: string;
  labels: Record<string, string>;
  requestsTotal: number;
  requestsError: number;
  detailsLink?: string;
}
const ServicesLandingBodyTable = ({
  items: services,
  pagingData
}: BaseLandingBodyProps<ListServicesResponse_ServiceSummary>) => {
  di(useIsOnlyCiliumEnabled);
  const isOnlyCiliumEnabled = useIsOnlyCiliumEnabled();
  const windowInnerWidth = useWindowInnerWidth();
  const columns = useMemo<SoloColumnsType<TableFields>>(() => {
    // This is where we check the breakpoint for combining
    // the name, namespace, and cluster columns into one, that
    // shows the values in the form: name.namespace.cluster.
    const combineNameNamespaceCluster = windowInnerWidth < 1100;
    const newColumns: SoloColumnsType<TableFields> = [];
    if (!isOnlyCiliumEnabled) {
      newColumns.push(createStatusColumn({ dataIndex: 'status' }));
    }
    if (combineNameNamespaceCluster) {
      newColumns.push({
        title: 'Name.Namespace.Cluster',
        dataIndex: 'name',
        width: '40%',
        ellipsis: true,
        render: (_, record) => (
          <SoloTableTextEllipsesCell value={`${record.name}.${record.namespace}.${record.clusterName}`} />
        )
      });
    } else {
      newColumns.push(
        createEllipseTextColumn({ title: 'Name', dataIndex: 'name', 'data-testid': 'name-cell' }),
        createEllipseTextColumn({ title: 'Namespace', dataIndex: 'namespace' }),
        createEllipseTextColumn({ title: 'Cluster', dataIndex: 'clusterName' })
      );
    }
    if (!isOnlyCiliumEnabled) {
      newColumns.push(
        { title: 'Mesh Status', dataIndex: 'type' },
        {
          title: 'Labels',
          dataIndex: 'labels',
          render: labels => <LabelsCell labelsMap={Object.entries(labels)} />
        },
        { title: 'Total Incoming Requests', dataIndex: 'requestsTotal' },
        { title: 'Errors', dataIndex: 'requestsError' },
        {
          title: 'Details',
          dataIndex: 'detailsLink',
          render(detailsLink) {
            return (
              !!detailsLink && (
                <SoloLink link={detailsLink} withArrow>
                  Details
                </SoloLink>
              )
            );
          }
        }
      );
    }
    return newColumns;
  }, [windowInnerWidth, isOnlyCiliumEnabled]);

  const tableData = useMemo<TableFields[]>(
    () =>
      services.map(({ serviceRef, type, labels, status, requestsError, requestsTotal, }) => ({
        key: buildIdFromRef(serviceRef),
        name: serviceRef?.name ?? '',
        namespace: serviceRef?.namespace ?? '',
        clusterName: serviceRef?.clusterName ?? '',
        type,
        labels,
        detailsLink: getServiceDetailsUrl(serviceRef),
        status: status,
        requestsError,
        requestsTotal,
      })),
    [services]
  );

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable data-testid='services-landing-body' dataSource={tableData} columns={columns} paging={pagingData} />
    </Spacer>
  );
};
