import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics, svgColorReplace } from 'Styles/CommonEmotions/utils';

export namespace GqlExplorerStyles {
  export const GqlExplorerPage = styled.div`
    padding: 1em;
  `;
  export const GqlExplorerPageBackground = styled.div(
    ({ theme }) => css`
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: ${theme.januaryGrey};
    `
  );

  export const GqlExplorerCard = styled.div<{ heightPx: number; topPadding: number; bottomPadding: number }>(
    ({ theme, heightPx, topPadding, bottomPadding }) => css`
      position: relative;
      border-radius: 5px;
      border: 1px solid ${theme.splashBlue};
      background-color: ${theme.white};
      box-shadow: 0 0 10px ${theme.aprilGrey};
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      height: ${heightPx + topPadding + bottomPadding}px;
    `
  );

  export const GraphiqlExplorerSidebarContainer = styled.div<{ resizeValue: number }>(
    ({ theme, resizeValue }) => css`
      position: relative;
      min-width: max-content;
      .docExplorerWrap {
        margin-left: 0px !important;
        width: ${resizeValue}px!important;
        min-width: ${resizeValue}px!important;
        max-width: ${resizeValue}px!important;
        // These are the overrides for the left sidebar border.
        border-radius: 5px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border: 1px solid ${theme.splashBlue}!important;
        border-left: none !important;
        .doc-explorer-rhs .docExplorerHide {
          transform: rotate(180deg);
          margin-right: -5px;
        }
      }
    `
  );
  export const GraphiqlExplorerSidebarResizer = styled.div(
    ({ theme }) => css`
      user-select: none;
      min-width: 15px;
      z-index: 1;
      cursor: col-resize;
      height: 100%;
      // These commented styles could be used for a resize indicator.
      // position: relative;
      // &:hover:after,
      // &:hover:active:after {
      //   content: '';
      //   position: absolute;
      //   top: 50%;
      //   border-radius: 5px;
      //   left: 5px;
      //   width: 5px;
      //   margin-top: -40px;
      //   height: 80px;
      // }
      // &:hover:after {
      //   background-color: ${theme.aprilGrey};
      // }
      // &:active:after {
      //   background-color: ${theme.augustGrey};
      // }
    `
  );

  export const Toolbar = {
    Container: styled.div`
      white-space: nowrap;
      width: 100%;
      padding: 10px 30px;
      margin-bottom: 0.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    `,
    Title: styled.div(
      ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${theme.augustGrey};
        ${svgBasics(25, theme.seaBlue)}
        svg {
          margin-top: -2px;
        }
      `
    ),
    RightButtonsContainer: styled.div`
      display: flex;
    `,
    TooltipNestedInfo: styled.div(
      ({ theme }) => css`
        display: inline-block;
        max-width: 100%;
        color: ${theme.aprilGrey};
        border: 1px solid ${theme.augustGrey};
        font-size: 90%;
        border-radius: 5px;
        padding: 2px 5px;
      `
    ),
    CorsTooltip: styled.div(
      ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        ${svgBasics(18, theme.novaGold)}
      `
    ),
    ButtonDropdownContainer: styled.div<{ numDropdownItems: number }>(
      ({ numDropdownItems }) => css`
        position: relative;
        ${numDropdownItems > 1 &&
        css`
          &:hover button {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        `}
      `
    ),
    Button: styled.button<{ variant: 'primary' | 'primary-outline'; padded?: boolean }>(
      ({ theme, variant, padded }) => css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 1px ${padded ? '25px' : '15px'};
        border: 1px solid ${theme.seaBlue};
        background: ${theme.seaBlue};
        color: white;
        font-size: 0.9em;
        font-weight: bold;
        border-radius: 2px;
        ${svgBasics(16, theme.seaBlue)}
        ${variant === 'primary-outline' &&
        css`
          font-weight: normal;
          background: white;
          color: ${theme.seaBlue};
        `}
        &:hover {
          ${svgColorReplace(theme.white)}
          border: 1px solid ${theme.lakeBlue};
          background: ${theme.lakeBlue};
          color: white;
          &:active {
            opacity: 0.75;
          }
        }
      `
    ),
    ButtonDropdownList: styled.div(
      ({ theme }) => css`
        cursor: pointer !important;
        position: absolute;
        top: 100%;
        right: 0px;
        width: 100%;
        min-width: max-content;
        z-index: 20;
        background-color: ${theme.white};
        box-shadow: 0 4px 5px ${theme.augustGrey};
        border: 1px solid ${theme.splashBlue};
        border-top: none;
        border-radius: 5px;
        border-top-right-radius: 0px;
        overflow: hidden;
      `
    ),
    ButtonDropdownItem: styled.button(
      ({ theme }) => css`
        display: block;
        width: 100%;
        text-align: left;
        padding: 5px;
        border: none;
        background-color: ${theme.white};
        color: ${theme.seaBlue};
        border-top: 1px solid ${theme.splashBlue};
        &:hover {
          background-color: ${theme.splashBlue};
          &:active {
            opacity: 0.5;
          }
        }
      `
    )
  };

  export const Arrow = styled.div<{ direction: 'left' | 'right' }>(
    ({ theme, direction }) => css`
      display: inline-block;
      padding: 6px;
      margin-right: 1em;
      width: 0px;
      height: 0px;
      border-top: 1px solid ${theme.seaBlue};
      border-right: 1px solid ${theme.seaBlue};
      ${direction === 'left' &&
      css`
        transform: rotate(135deg);
      `}
      ${direction === 'right' &&
      css`
        transform: translateY(8px) rotate(-45deg);
      `}
    `
  );

  export const ShowExplorerButton = {
    Container: styled.button<{ placement: 'left' | 'right' }>(
      ({ theme, placement }) => css`
        height: 100%;
        width: 2em;
        background-color: ${theme.dropBlue};
        color: ${theme.neptuneBlue};
        font-weight: bold;
        border: 1px solid ${theme.splashBlue};
        border-radius: 5px;
        user-select: none;
        ${placement === 'left'
          ? css`
              border-left: none;
              border-top-left-radius: 0%;
              border-bottom-left-radius: 0%;
            `
          : css`
              border-right: none;
              border-top-right-radius: 0%;
              border-bottom-right-radius: 0%;
            `}
        &:hover {
          color: white;
          ${Arrow} {
            border-color: white;
            border-width: 2px;
          }
          background-color: ${theme.seaBlue};
          opacity: 0.75;
          &:active {
            opacity: 0.5;
          }
        }
      `
    ),
    Content: styled.div`
      white-space: nowrap;
      transform: rotate(90deg);
      display: flex;
      justify-content: center;
    `
  };

  export const GraphiqlContainer = styled.div<{ heightPx: number; topPadding: number }>(
    ({ theme, heightPx, topPadding }) => css`
      position: absolute;
      top: ${topPadding}px;
      left: 0px;
      width: 100%;
      height: ${heightPx}px;
      padding: 0px;
      margin: 0px;
      overflow-y: visible;
      display: flex;
      flex-direction: row;

      // ========================== //
      // TABS
      .editorWrap .tabs {
        user-select: none;
        border-bottom: 1px solid ${theme.splashBlue};
        position: relative;
        background: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        button.tab,
        button.tab-add {
          padding: 0.25em;
          border-bottom: 3px solid transparent;
        }
        button.tab {
          background: none;
          height: auto;
          display: inline-block;
          border-top: none;
          border-right: none;
          border-left: none;
          padding-top: 0.5em;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          padding-left: 30px;
          text-align: left;
          width: 130px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          position: relative;
          color: ${theme.augustGrey};
          &.active {
            color: ${theme.neptuneBlue};
            cursor: default;
            border-color: ${theme.seaBlue};
          }
          transition: 0.1s background-color;
          &:not(.active) {
            cursor: pointer;
            &:hover {
              background-color: ${theme.dropBlue};
              &:active {
                background-color: ${theme.marchGrey};
              }
            }
          }
          // Close Icon
          div.close {
            border-radius: 50%;
            margin: 0em 0.15em 0em 0em;
            height: 1.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 6px;
            left: 5px;
            &:before {
              color: ${theme.augustGrey};
              margin-top: -3px;
            }
          }
        }
      }

      // ========================= //
      // GREEN ADD BUTTON
      > .graphiql-container .editorWrap .tabs button.tab-add,
      .docExplorerWrap form.variable-editor-title button {
        border-radius: 50% !important;
        width: 18px !important;
        height: 18px !important;
        background: ${theme.forestGreen}!important;
        color: white !important;
        opacity: 0.7 !important;
        border-bottom: 0px !important;
        margin: 4px 0px !important;
        padding: 0px !important;
        span {
          line-height: 20px !important;
          font-size: 22px !important;
          font-weight: 100 !important;
          width: 100% !important;
          height: 100% !important;
          margin-top: 0px !important;
          display: block !important;
        }
        &:hover {
          opacity: 0.8 !important;
          &:active {
            opacity: 1 !important;
          }
        }
      }
      // Overrides for add-tab button
      > .graphiql-container .editorWrap .tabs button.tab-add {
        margin-top: 6px !important;
        margin-left: 5px !important;
        position: absolute;
        top: 3px;
        right: 5px;
        margin: 5px !important;
      }
      > .graphiql-container .editorWrap .tabs {
        max-width: 100%;
        padding-right: 35px;
        flex-wrap: wrap;
      }

      // ========================== //
      // MISC GRAPHIQL FIXES
      > .graphiql-container {
        // Allows for horizontal scrolling when the window is narrow.
        overflow: visible;
        // Removes title bar (the toolbar with the GraphiQL logo).
        .editorWrap .topBarWrap .topBar {
          display: none;
        }
        button.docExplorerShow {
          display: none;
        }
      }
      > .graphiql-container button,
      .docExplorerWrap button,
      .docExplorerWrap button span {
        font-family: 'Proxima Nova', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif' !important;
      }

      // ========================== //
      // SIDEBARS
      //
      // These are shared between sidebars.
      // If there are overrides, the right sidebar styles go here
      // and the left sidebar style overrides go above,
      // in GraphiqlExplorerSidebarContainer.
      //
      // Note:
      //  There were scrolling issues with the GraphiQL sidebar content.
      //  The fix was to add 'heightPx' as prop into the component.
      //  Then the sidebar content height is subtracted from the total
      //  (including top + bottom offsets) to get an exact px value.
      //  The heightPx prop is updated whenever the page is resized.
      //
      .editorWrap,
      .docExplorerWrap {
        overflow: auto;
        border-radius: 5px;
        border: 1px solid ${theme.splashBlue};
        box-shadow: none;
      }
      .docExplorerWrap {
        height: ${heightPx}px;
        margin-left: 15px;
        // These are the border styles for the right sidebar.
        border-right: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      > .graphiql-container,
      ${GraphiqlExplorerSidebarContainer} {
        // Changes the X button on the doc explorers to an arrow (">" or "<").
        .doc-explorer-rhs {
          display: contents;
        }
        .docExplorerHide {
          padding: 6px;
          font-size: 0;
          margin: 0px;
          cursor: pointer;
          color: transparent;
          position: relative;
          width: 10px;
          height: 10px;
          &:after {
            content: '';
            position: absolute;
            top: 0em;
            left: 0em;
            width: 100%;
            height: 100%;
            border-top: 1px solid ${theme.seaBlue};
            border-right: 1px solid ${theme.seaBlue};
            // The GraphiqlExplorerSidebarContainer overrides this
            // rotation for the other sidebar direction.
            transform: rotate(45deg);
          }
        }
        .docExplorerWrap,
        .doc-explorer {
          background-color: ${theme.dropBlue};
          color: ${theme.neptuneBlue};
          .doc-explorer-title-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 15px;
            height: 0px;
            background: inherit;
            font-weight: bold;
            font-family: system, -apple-system, San Francisco, \.SFNSDisplay-Regular, Segoe UI, Segoe, Segoe WP,
              Helvetica Neue, helvetica, Lucida Grande, arial, sans-serif;
            .doc-explorer-title {
              margin: 0px;
              padding: 0px 10px 0px 5px;
              overflow: hidden;
              text-align: left;
            }
          }
          .doc-explorer-contents {
            border-top: 1px solid ${theme.splashBlue};
            // Fixes doc searchbar.
            .search-box {
              margin: -5px 0px 15px 0px;
              padding: 0px 0px 0px 10px;
              border: 1px solid ${theme.splashBlue};
              border-radius: 5px;
              input {
                padding-bottom: 6px;
                padding-left: 8px;
              }
              .search-box-icon {
                cursor: default;
                color: ${theme.juneGrey};
                font-size: 2em;
                margin: -10px 0px -10px 0px;
              }
              button.search-box-clear {
                padding: 0px 5px;
                font-size: 1em;
                opacity: 0.75;
                &:hover:active {
                  opacity: 1;
                }
              }
            }
            // Fixes doc sub-headings.
            .doc-category-title {
              color: ${theme.neptuneBlue};
              margin: 0 -15px 10px -15px;
              padding: 10px 0px 10px 15px;
            }

            // ========================== //
            // EXPLORER FIXES
            //
            // Using !important here because the explorer
            // isn't part of the main GraphiQL library,
            // and it has a lot of inline styles.
            //
            .graphiql-explorer-root {
              font-size: 0.9em !important;
              line-height: 1.3;
              padding: 0px !important;
              input {
                font-family: monospace;
                max-width: 100% !important;
                width: max-content !important;
                border-radius: 5px;
                border: 1px solid ${theme.splashBlue}!important;
                background: #fff;
              }
              > div:first-of-type {
                > div {
                  padding: 10px !important;
                }
                height: max-content !important;
                overflow: auto !important;
                height: ${heightPx - 100}px !important;
                background-color: ${theme.white};
              }
              > div:nth-of-type(2) {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 50px;
                z-index: 1;
                background-color: ${theme.white};
                border-radius: 0px !important;
                border-bottom-right-radius: 5px !important;
                border: 1px solid ${theme.splashBlue} !important;
                border-left: none !important;
                overflow: hidden !important;
                form.variable-editor-title.graphiql-explorer-actions {
                  width: 100% !important;
                  height: 100% !important;
                  display: flex !important;
                  justify-content: center !important;
                  align-items: center !important;
                  gap: 10px !important;
                  padding: 2px 10px !important;
                  margin: 0px !important;
                  border: none !important;
                  > span {
                    display: none !important;
                  }
                  select {
                    border: 1px solid ${theme.splashBlue} !important;
                    border-radius: 5px !important;
                    padding: 2px !important;
                    cursor: pointer !important;
                  }
                }
              }
            }
          }
        }

        // ========================== //
        // EDITOR
        .editorWrap {
          // Fix for the gutters
          .CodeMirror-gutters {
            border-right: none !important;
            .CodeMirror-gutter {
              background-color: white;
            }
          }
          .CodeMirror-linenumber {
            color: ${theme.augustGrey};
          }
          .editor-drag-bar {
            background-color: transparent;
            position: relative;
            border: none;
            // Draws a line down the center of the drag-bar column.
            &:after {
              content: '';
              position: absolute;
              top: 0px;
              bottom: 0px;
              left: 50%;
              width: 1px;
              background-color: ${theme.splashBlue};
            }
          }
          // Fix for the variables/headers editor section (on the bottom)
          .secondary-editor-title.variable-editor-title {
            white-space: nowrap;
            background: none;
            border-top: none;
            border-bottom: 1px solid ${theme.splashBlue};
            margin-right: -6px;
            padding-left: 20px;
            .variable-editor-title-text {
              margin: 0px 20px 0px 0px !important;
              font-variant-caps: initial;
              text-transform: initial;
              font-weight: 400;
              letter-spacing: 0px;
              font-family: 'Proxima Nova', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
              position: relative;
              color: ${theme.augustGrey};
              &.active {
                color: ${theme.neptuneBlue};
                &:before {
                  content: '';
                  position: absolute;
                  bottom: -8px;
                  left: -5px;
                  right: -5px;
                  height: 3px;
                  z-index: 1;
                  background-color: ${theme.seaBlue};
                  pointer-events: none;
                }
              }
              &:not(.active) {
                &:hover {
                  opacity: 0.8;
                  &:active {
                    opacity: 0.6;
                  }
                }
              }
            }
          }
          // Fix for the results section (on the right)
          .result-window .CodeMirror {
            background: white;
          }
        }
      }
    `
  );
}
