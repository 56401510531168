/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "envoy/config/core/v3/backoff.proto" (package "envoy.config.core.v3", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../../../../google/protobuf/duration_pb";
// [#protodoc-title: Backoff Strategy]

/**
 * Configuration defining a jittered exponential back off strategy.
 *
 * @generated from protobuf message envoy.config.core.v3.BackoffStrategy
 */
export interface BackoffStrategy {
    /**
     * The base interval to be used for the next back off computation. It should
     * be greater than zero and less than or equal to :ref:`max_interval
     * <envoy_api_field_config.core.v3.BackoffStrategy.max_interval>`.
     *
     * @generated from protobuf field: google.protobuf.Duration base_interval = 1;
     */
    baseInterval?: Duration;
    /**
     * Specifies the maximum interval between retries. This parameter is optional,
     * but must be greater than or equal to the :ref:`base_interval
     * <envoy_api_field_config.core.v3.BackoffStrategy.base_interval>` if set. The default
     * is 10 times the :ref:`base_interval
     * <envoy_api_field_config.core.v3.BackoffStrategy.base_interval>`.
     *
     * @generated from protobuf field: google.protobuf.Duration max_interval = 2;
     */
    maxInterval?: Duration;
}
// @generated message type with reflection information, may provide speed optimized methods
class BackoffStrategy$Type extends MessageType<BackoffStrategy> {
    constructor() {
        super("envoy.config.core.v3.BackoffStrategy", [
            { no: 1, name: "base_interval", kind: "message", T: () => Duration, options: { "validate.rules": { duration: { required: true, gte: { nanos: 1000000 } } } } },
            { no: 2, name: "max_interval", kind: "message", T: () => Duration, options: { "validate.rules": { duration: { gt: {} } } } }
        ], { "udpa.annotations.versioning": { previousMessageType: "envoy.api.v2.core.BackoffStrategy" } });
    }
}
/**
 * @generated MessageType for protobuf message envoy.config.core.v3.BackoffStrategy
 */
export const BackoffStrategy = new BackoffStrategy$Type();
