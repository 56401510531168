/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../../../../../../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetLogoutPathRequest
 */
export interface GetLogoutPathRequest {
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetLogoutPathResponse
 */
export interface GetLogoutPathResponse {
    /**
     * @generated from protobuf field: string logout_path = 1;
     */
    logoutPath: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetTracingBasePathRequest
 */
export interface GetTracingBasePathRequest {
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetTracingBasePathResponse
 */
export interface GetTracingBasePathResponse {
    /**
     * @generated from protobuf field: string tracing_base_path = 1;
     */
    tracingBasePath: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsRequest
 */
export interface GetUIFeatureFlagsRequest {
    /**
     * If the license expires within this time, return an ACCESS_EXPIRES_SOON state.
     * Defaults to 30 days.
     *
     * @generated from protobuf field: int32 license_expiration_threshold_days = 2;
     */
    licenseExpirationThresholdDays: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsResponse
 */
export interface GetUIFeatureFlagsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.Licenses licenses = 1;
     */
    licenses?: GetUIFeatureFlagsResponse_Licenses;
    /**
     * @generated from protobuf field: bool portal_enabled = 2;
     */
    portalEnabled: boolean;
    /**
     * @generated from protobuf field: bool graph_enabled = 3;
     */
    graphEnabled: boolean;
    /**
     * @generated from protobuf field: bool inights_enabled = 4;
     */
    inightsEnabled: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsResponse.Licenses
 */
export interface GetUIFeatureFlagsResponse_Licenses {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License any = 1;
     */
    any?: GetUIFeatureFlagsResponse_License;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License gloo_gateway = 2;
     */
    glooGateway?: GetUIFeatureFlagsResponse_License;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License gloo_mesh = 3;
     */
    glooMesh?: GetUIFeatureFlagsResponse_License;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License gloo_network = 4;
     */
    glooNetwork?: GetUIFeatureFlagsResponse_License;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License gloo_core = 5;
     */
    glooCore?: GetUIFeatureFlagsResponse_License;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License
 */
export interface GetUIFeatureFlagsResponse_License {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License.LicenseState state = 1;
     */
    state: GetUIFeatureFlagsResponse_License_LicenseState;
    /**
     * UTC time representing expiration date and time in seconds since the epoch.
     *
     * @generated from protobuf field: google.protobuf.Timestamp expiration_time = 2;
     */
    expirationTime?: Timestamp;
    /**
     * @generated from protobuf field: repeated string errors = 3;
     */
    errors: string[];
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License.LicenseState
 */
export enum GetUIFeatureFlagsResponse_License_LicenseState {
    /**
     * @generated from protobuf enum value: ACCESS_DENIED = 0;
     */
    ACCESS_DENIED = 0,
    /**
     * @generated from protobuf enum value: ACCESS_EXPIRED = 1;
     */
    ACCESS_EXPIRED = 1,
    /**
     * @generated from protobuf enum value: ACCESS_EXPIRES_SOON = 2;
     */
    ACCESS_EXPIRES_SOON = 2,
    /**
     * @generated from protobuf enum value: ACCESS_GRANTED = 3;
     */
    ACCESS_GRANTED = 3
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetSafeModeStatusRequest
 */
export interface GetSafeModeStatusRequest {
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetSafeModeStatusResponse
 */
export interface GetSafeModeStatusResponse {
    /**
     * @generated from protobuf field: bool safe_mode_active = 1;
     */
    safeModeActive: boolean;
    /**
     * @generated from protobuf field: repeated string clusters_causing_safe_mode = 2;
     */
    clustersCausingSafeMode: string[];
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 3;
     */
    apiserverErrors: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetLogoutPathRequest$Type extends MessageType<GetLogoutPathRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetLogoutPathRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetLogoutPathRequest
 */
export const GetLogoutPathRequest = new GetLogoutPathRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLogoutPathResponse$Type extends MessageType<GetLogoutPathResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetLogoutPathResponse", [
            { no: 1, name: "logout_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetLogoutPathResponse
 */
export const GetLogoutPathResponse = new GetLogoutPathResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTracingBasePathRequest$Type extends MessageType<GetTracingBasePathRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetTracingBasePathRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetTracingBasePathRequest
 */
export const GetTracingBasePathRequest = new GetTracingBasePathRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTracingBasePathResponse$Type extends MessageType<GetTracingBasePathResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetTracingBasePathResponse", [
            { no: 1, name: "tracing_base_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetTracingBasePathResponse
 */
export const GetTracingBasePathResponse = new GetTracingBasePathResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUIFeatureFlagsRequest$Type extends MessageType<GetUIFeatureFlagsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetUIFeatureFlagsRequest", [
            { no: 2, name: "license_expiration_threshold_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsRequest
 */
export const GetUIFeatureFlagsRequest = new GetUIFeatureFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUIFeatureFlagsResponse$Type extends MessageType<GetUIFeatureFlagsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetUIFeatureFlagsResponse", [
            { no: 1, name: "licenses", kind: "message", T: () => GetUIFeatureFlagsResponse_Licenses },
            { no: 2, name: "portal_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "graph_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "inights_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsResponse
 */
export const GetUIFeatureFlagsResponse = new GetUIFeatureFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUIFeatureFlagsResponse_Licenses$Type extends MessageType<GetUIFeatureFlagsResponse_Licenses> {
    constructor() {
        super("rpc.gloo.solo.io.GetUIFeatureFlagsResponse.Licenses", [
            { no: 1, name: "any", kind: "message", T: () => GetUIFeatureFlagsResponse_License },
            { no: 2, name: "gloo_gateway", kind: "message", T: () => GetUIFeatureFlagsResponse_License },
            { no: 3, name: "gloo_mesh", kind: "message", T: () => GetUIFeatureFlagsResponse_License },
            { no: 4, name: "gloo_network", kind: "message", T: () => GetUIFeatureFlagsResponse_License },
            { no: 5, name: "gloo_core", kind: "message", T: () => GetUIFeatureFlagsResponse_License }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsResponse.Licenses
 */
export const GetUIFeatureFlagsResponse_Licenses = new GetUIFeatureFlagsResponse_Licenses$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUIFeatureFlagsResponse_License$Type extends MessageType<GetUIFeatureFlagsResponse_License> {
    constructor() {
        super("rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License", [
            { no: 1, name: "state", kind: "enum", T: () => ["rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License.LicenseState", GetUIFeatureFlagsResponse_License_LicenseState] },
            { no: 2, name: "expiration_time", kind: "message", T: () => Timestamp },
            { no: 3, name: "errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetUIFeatureFlagsResponse.License
 */
export const GetUIFeatureFlagsResponse_License = new GetUIFeatureFlagsResponse_License$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSafeModeStatusRequest$Type extends MessageType<GetSafeModeStatusRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetSafeModeStatusRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetSafeModeStatusRequest
 */
export const GetSafeModeStatusRequest = new GetSafeModeStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSafeModeStatusResponse$Type extends MessageType<GetSafeModeStatusResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetSafeModeStatusResponse", [
            { no: 1, name: "safe_mode_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "clusters_causing_safe_mode", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetSafeModeStatusResponse
 */
export const GetSafeModeStatusResponse = new GetSafeModeStatusResponse$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.DashboardApi
 */
export const DashboardApi = new ServiceType("rpc.gloo.solo.io.DashboardApi", [
    { name: "GetLogoutPath", options: {}, I: GetLogoutPathRequest, O: GetLogoutPathResponse },
    { name: "GetTracingBasePath", options: {}, I: GetTracingBasePathRequest, O: GetTracingBasePathResponse },
    { name: "GetUIFeatureFlags", options: {}, I: GetUIFeatureFlagsRequest, O: GetUIFeatureFlagsResponse },
    { name: "GetSafeModeStatus", options: {}, I: GetSafeModeStatusRequest, O: GetSafeModeStatusResponse }
]);
