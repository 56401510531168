import { css } from '@emotion/react';
import styled from '@emotion/styled';

export namespace GqlSchemaSearchStyles {
  export const SearchBoxContainer = styled.div`
    position: relative;
    flex-basis: 50%;
  `;

  export const SearchBox = styled.div<{
    heightPx: number;
    splitContent: boolean;
  }>(
    ({ theme, heightPx, splitContent = true }) => css`
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 5;
      background-color: ${theme.white};
      border: 1px solid ${theme.marchGrey};
      box-shadow: 0 2px 10px ${theme.aprilGrey};
      border-radius: 5px;
      display: grid;
      // Shift down, below the search input.
      height: ${heightPx}px;
      transform: translateY(${heightPx}px);
      // Can split the search box to show item list and content.
      ${splitContent &&
      css`
        grid-template-columns: 45% 55%;
      `}
    `
  );

  export const SearchBoxSideContent = styled.div(
    ({ theme }) => css`
      padding: 20px;
      background-color: ${theme.januaryGrey};
      overflow-y: auto;
    `
  );

  export const ContentH1 = styled('h1')<{ fontSize: string }>(
    ({ fontSize, theme }) => css`
      font-size: ${fontSize};
      word-wrap: break-word;
      line-height: 1.7rem;
      padding-bottom: 1rem;
      color: ${theme.neptuneBlue};
    `
  );

  export const ContentH2 = styled('h2')(
    ({ theme }) => css`
      font-size: 1.1rem;
      line-height: 1.2rem;
      padding-bottom: 0.5rem;
      color: ${theme.neptuneBlue};
    `
  );

  export const ContentP = styled('p')(
    ({ theme }) => css`
      font-size: 0.9rem;
      line-height: 1.2rem;
      padding-bottom: 0.2rem;
      color: ${theme.septemberGrey};
    `
  );

  export const SearchBoxList = styled('ul')(
    ({ theme }) => css`
      font-size: 0.9rem;
      line-height: 1.1rem;
      border-right: 1px solid ${theme.marchGrey};
      max-height: 100%;
      overflow-y: auto;
    `
  );

  export const SearchBoxListItem = styled('li')<{
    menuItemHeightPx: number;
    hovered: boolean;
  }>(
    ({ theme, hovered, menuItemHeightPx = 35 }) => css`
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
      align-items: center;
      justify-content: flex-start;
      height: ${menuItemHeightPx}px;
      span {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      cursor: pointer;
      ${hovered &&
      css`
        background-color: ${theme.seaBlue};
        color: ${theme.white};
      `}
    `
  );
}
