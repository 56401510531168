import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { CardFolderTab, CardFolderTabListWithBottomBorder } from 'Components/Common/Tabs';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Resolution_Resolvers } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useState } from 'react';
import VariableTransformationLabel from '../VariableTransformationLabel';
import VariableTransformationMapTable from '../VariableTransformationMapTable';

const GqlRestResolverRequestTabs = ({ resolver }: { resolver: Resolution_Resolvers }) => {
  const headersMap =
    resolver.resolver?.resolver.oneofKind === 'restResolver'
      ? resolver.resolver.resolver.restResolver.request?.headers
      : undefined;
  const queryParamsMap =
    resolver.resolver?.resolver.oneofKind === 'restResolver'
      ? resolver.resolver.resolver.restResolver.request?.queryParams
      : undefined;
  const body =
    resolver.resolver?.resolver.oneofKind === 'restResolver'
      ? resolver.resolver.resolver.restResolver.request?.body
      : undefined;
  const [tabIndex, setTabIndex] = useState(0);

  //
  // Render
  //
  if (!headersMap?.length && !queryParamsMap?.length && !body) return <i>No Transformation</i>;
  return (
    <Tabs id='tabs' index={tabIndex} onChange={setTabIndex}>
      <CardFolderTabListWithBottomBorder>
        <CardFolderTab hidden={!headersMap?.length}>Headers</CardFolderTab>
        <CardFolderTab hidden={!queryParamsMap?.length} width='200px' data-testid='query-parameters-button'>
          Query Parameters
        </CardFolderTab>
        <CardFolderTab hidden={!body} data-testid='body-button'>
          Body
        </CardFolderTab>
      </CardFolderTabListWithBottomBorder>
      <TabPanels>
        <TabPanel>
          <Spacer pt={4}>
            <VariableTransformationMapTable variableTransformations={headersMap} />
          </Spacer>
        </TabPanel>
        <TabPanel>
          <Spacer pt={4}>
            <VariableTransformationMapTable variableTransformations={queryParamsMap} />
          </Spacer>
        </TabPanel>
        <TabPanel>
          <Spacer pt={4}>
            <VariableTransformationLabel variableTransformation={body} />
          </Spacer>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default GqlRestResolverRequestTabs;
