import { css } from '@emotion/react';
import { SoloLink } from 'Components/Common/SoloLink';
import { HorizontalRule, VerticalRule } from 'Styles/CommonEmotions/dividers';
import { healthColors } from 'Styles/colors';
import { Asset } from 'assets';
import React, { useState } from 'react';
import { NotificationBoxStyles } from './NotificationBox.style';
import { Svg } from './Svg';

export enum NotificationType {
  INFO = 0,
  WARNING = 1,
  ERROR = 2
}

// helpers
const getColors = (type: NotificationType): NotificationBoxStyles.ColorProps => {
  switch (type) {
    case NotificationType.ERROR:
      return {
        color: healthColors.error.dark,
        backgroundColor: healthColors.error.light,
        borderColor: healthColors.error.dark
      };
    case NotificationType.WARNING:
      return {
        color: healthColors.warning.text,
        backgroundColor: healthColors.warning.light,
        borderColor: healthColors.warning.dark
      };
    case NotificationType.INFO:
    default:
      return {
        color: healthColors.normal.dark,
        backgroundColor: healthColors.normal.light,
        borderColor: healthColors.normal.dark
      };
  }
};

export const notificationTypeStrings = {
  [NotificationType.INFO]: 'Info',
  [NotificationType.WARNING]: 'Warning',
  [NotificationType.ERROR]: 'Error'
};

export type Issue = {
  message: React.ReactNode;
  linkTitle?: string;
  detailsLink?: string;
};

// main component
type Props = {
  type: NotificationType;
  issues: Issue[];
  multipleIssuesMessage?: string;
  notificationTypeString?: string;
  bottomMargin?: boolean;
  justMessage?: boolean;
};

export const NotificationBox = ({
  type,
  issues,
  notificationTypeString,
  multipleIssuesMessage = 'There are multiple issues.',
  bottomMargin,
  justMessage
}: Props) => {
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(exp => !exp);

  const colors: NotificationBoxStyles.ColorProps = getColors(type);
  const message = issues.length === 0 ? '' : issues.length === 1 ? issues[0].message : multipleIssuesMessage;
  const typeString = notificationTypeString ?? notificationTypeStrings[type];
  const headerLink =
    issues.length === 1 && issues[0].detailsLink ? (
      <SoloLink
        link={issues[0].detailsLink}
        stylingOverrides={css`
          font-weight: bold;
          color: ${colors.color};
        `}>
        {issues[0].linkTitle ?? 'View Now'}
      </SoloLink>
    ) : issues.length > 1 ? (
      <NotificationBoxStyles.ExpandLink onClick={toggleExpanded}>
        {`${isExpanded ? 'Collapse' : 'Expand'} ${typeString} List`}
      </NotificationBoxStyles.ExpandLink>
    ) : null;

  return (
    <NotificationBoxStyles.Container
      data-testid='notification-box'
      color={colors.color}
      backgroundColor={colors.backgroundColor}
      borderColor={colors.borderColor}
      bottomMargin={bottomMargin}>
      <NotificationBoxStyles.IssueHeaderContainer>
        <Svg
          size={21}
          asset={
            type === NotificationType.ERROR ? (
              <Asset.ErrorCircleIcon />
            ) : type === NotificationType.WARNING ? (
              <Asset.WarningCircleIcon />
            ) : (
              <Asset.InfoIconSmall />
            )
          }
        />

        <NotificationBoxStyles.IssueMessageContainer>
          {!!typeString.length && !justMessage && (
            <NotificationBoxStyles.IssueTypeContainer>{`${typeString}: `}</NotificationBoxStyles.IssueTypeContainer>
          )}
          {message}
        </NotificationBoxStyles.IssueMessageContainer>
        {headerLink ? (
          <>
            <VerticalRule color={colors.color} />
            {headerLink}
          </>
        ) : null}
        {issues.length > 1 && (
          <NotificationBoxStyles.Toggler onClick={toggleExpanded}>
            <NotificationBoxStyles.ArrowIconHolder expanded={isExpanded}>
              <Svg size={15} color={colors.color} asset={<Asset.ArrowToggle />} />
            </NotificationBoxStyles.ArrowIconHolder>
          </NotificationBoxStyles.Toggler>
        )}
      </NotificationBoxStyles.IssueHeaderContainer>
      {isExpanded && (
        <>
          <HorizontalRule color={colors.borderColor} />
          <NotificationBoxStyles.IssueDetailsContainer>
            <ul>
              {issues.map((issue, idx) => (
                <li key={`issue-${idx}`}>
                  <NotificationBoxStyles.IssueContainer>
                    {issue.message}
                    {issue.detailsLink ? (
                      <>
                        <VerticalRule color={colors.color} />
                        <SoloLink
                          link={issue.detailsLink}
                          stylingOverrides={css`
                            font-weight: bold;
                            color: ${colors.color};
                          `}>
                          {issue.linkTitle ?? 'View Now'}
                        </SoloLink>
                      </>
                    ) : null}
                  </NotificationBoxStyles.IssueContainer>
                </li>
              ))}
            </ul>
          </NotificationBoxStyles.IssueDetailsContainer>
        </>
      )}
    </NotificationBoxStyles.Container>
  );
};
