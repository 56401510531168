import { routingApi } from 'Api/routing';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { FancyCodeDisplayerModal, FancyCodeDisplayerModalContent } from 'Components/Common/FancyCodeDisplayerModal';
import { Loading } from 'Components/Common/Loading';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloListCard } from 'Components/Common/SoloListCard';
import { SoloListCardStyles } from 'Components/Common/SoloListCard.style';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { Svg } from 'Components/Common/Svg';
import { SoloButton } from 'Styles/CommonEmotions/button';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { RouteType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/workspaces_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { di } from 'react-magnetic-di';
import { buildIdFromRef } from 'utils/helpers';
import { useNamespaceClusterToWorkspaceMap } from 'utils/hooks';
import { getRouteTypeIcon } from 'utils/types';
import { buildWorkspaceRouteDetailsUrl } from 'utils/url-builders';
import { WorkspaceDependenciesCard } from '../../Workspaces/WorkspaceDependenciesCard';
import { RoutesListDisplay } from '../RoutesDisplay/RoutesListDisplay';

const { useGetRouteTableDetails, useGetRouteTableSchema } = routingApi;

const ApiSchemaModalContent = ({ routeRef }: { routeRef: ClusterObjectRef }) => {
  di(useGetRouteTableSchema);
  const { data, error } = useGetRouteTableSchema(routeRef);
  return <FancyCodeDisplayerModalContent contentString={data?.schema} error={error} type='json' />;
};

export const RouteTableDetails = ({ clusterObjRef }: { clusterObjRef: ClusterObjectRef }) => {
  di(useGetRouteTableDetails, useNamespaceClusterToWorkspaceMap);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging();
  const [apiSchemaOpen, setApiSchemaOpen] = useState(false);

  const { data: rtData, error: rtError } = useGetRouteTableDetails(clusterObjRef, apiPaginationObject);
  const { lookupWorkspaceName, error: workspaceMapError } = useNamespaceClusterToWorkspaceMap();

  useSetPagingTotal(rtData?.routeTableDetails?.totalRoutes);

  if (!!(rtError ?? workspaceMapError) || !rtData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getRouteTypeIcon(RouteType.ROUTE_TABLE)} objRef={clusterObjRef} />
        {!!rtError ? (
          <DataError error={rtError ?? workspaceMapError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} route table details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList data-testid='route-table-details'>
      <DetailsHeaderCard
        icon={getRouteTypeIcon(RouteType.ROUTE_TABLE)}
        status={rtData?.status}
        objRef={clusterObjRef}
        workspaceRef={rtData?.ownerWorkspace?.ref}
        resourceType={ResourceType.ROUTE_TABLE}
        buttons={
          rtData.routeTableDetails?.hasOpenapiSchema
            ? [
              <Tooltip key='api-icon' title='This Route Table is a REST API'>
                <Svg size={35} asset={<Asset.OpenApiIcon />} />
              </Tooltip>,
              <SoloButton key='api-schema-button' onClick={() => setApiSchemaOpen(true)}>
                API Schema
              </SoloButton>
            ]
            : undefined
        }
      />

      <WorkspaceDependenciesCard
        dependencies={rtData?.dependencies}
        importingTypeName='Route Table'
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <SoloListCardStyles.HorizontalListCardsContainer>
          <SoloListCard title='Host(s)' dataSource={rtData.routeTableDetails?.hosts ?? []} />
          <SoloListCard
            title='Gateways'
            dataSource={
              rtData.routeTableDetails?.virtualGateways.map(g => ({
                key: buildIdFromRef(g),
                data: (
                  <SoloLink link={buildWorkspaceRouteDetailsUrl(lookupWorkspaceName(g), RouteType.VIRTUAL_GATEWAY, g)}>
                    {g.name}
                  </SoloLink>
                )
              })) ?? []
            }
          />
          {!!rtData.routeTableDetails?.workloads?.length && (
            <SoloListCard title='Workloads' dataSource={rtData.routeTableDetails.workloads.map(w => w.name)} />
          )}
        </SoloListCardStyles.HorizontalListCardsContainer>
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RoutesListDisplay
          routeTableRef={clusterObjRef}
          parentRef={clusterObjRef}
          routes={rtData.routeTableDetails?.routes}
          pagingData={pagingData}
        />
      </CardStyles.Card>

      {apiSchemaOpen && (
        <FancyCodeDisplayerModal
          title={clusterObjRef.name}
          subtitle='API Schema'
          icon={getRouteTypeIcon(RouteType.ROUTE_TABLE)}
          onClose={() => setApiSchemaOpen(false)}>
          <ApiSchemaModalContent routeRef={clusterObjRef} />
        </FancyCodeDisplayerModal>
      )}
    </CardStyles.CardList>
  );
};
