import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getGraphItemColors } from 'Styles/graph';
import { GraphMetricsType, GraphStatus } from '../graph-selection-utils';

export namespace EdgeDetailsStyles {
  export const TitleRow = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr auto;
    gap: 5px 0;
    margin-bottom: 10px;
  `;
  export const NodeTitle = styled.div(
    ({ theme }) => css`
      position: relative;
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      cursor: pointer;
      color: ${theme.neptuneBlue};
      white-space: nowrap;
      overflow: hidden;
    `
  );
  export const NodeTitleIconHolder = styled.span(
    ({ theme }) => css`
      display: flex;
      align-items: center;

      ${svgBasics(18, theme.seaBlue)}
      svg {
        margin-right: 6px;
      }
    `
  );
  // export const ModalPopIconStyled = styled(Asset.ExternalLinkIcon)`
  //   width: 11px !important;
  //   height: 11px !important;
  //   position: relative;
  //   top: -4px;

  //   * {
  //     fill: ${theme.seaBlue};
  //   }
  // `;
  export const NodeTitleStatusHolder = styled.span`
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: flex-end;
  `;

  interface EdgeArrowHolderProps {
    status: GraphStatus;
    metricsType: GraphMetricsType;
  }
  export const EdgeArrowHolder = styled.div<EdgeArrowHolderProps>(
    ({ status, metricsType }) => css`
      grid-row: span 2;
      display: flex;
      align-items: center;

      ${svgBasics({ width: 16 }, getGraphItemColors(metricsType, status).edge)}
    `
  );

  interface MtlsIconProps {
    secure: boolean;
  }
  export const MtlsIcon = styled.span<MtlsIconProps>(
    ({ theme, secure }) => css`
      margin-right: 4px;
      color: ${secure ? theme.seaBlue : theme.butternutOrange};
    `
  );

  export const InfoIcon = styled.span(
    ({ theme }) => css`
      display: inline-flex;
      margin-left: 6px;
      justify-content: center;

      font-size: 13px;
      height: 16px;
      line-height: 16px;
      width: 16px;
      border-radius: 8px;

      color: ${theme.septemberGrey};
      border: 1px solid currentColor;

      &:before {
        content: 'i';
        font-family: math;
      }
    `
  );

  export const MtlsTooltipList = styled.ul`
    list-style: disc inside;
    overflow-wrap: break-word;
  `;
}
