/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview.proto" (package "rpc.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Insight_SYS0015Data } from "../../../../api/gloo.solo.io/internal/insights/v2alpha1/insights_pb";
import { TypedClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { Duration } from "../../../../../../../../google/protobuf/duration_pb";
import { ApiType } from "./common_pb";
import { WorkspaceStatus } from "./common_pb";
import { ClusterObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { Status } from "./common_pb";
import { ObjectRef } from "../../../../../../skv2/api/core/v1/core_pb";
import { State } from "./common_pb";
import { Pagination } from "./common_pb";
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewWorkspacesRequest
 */
export interface ListOverviewWorkspacesRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListOverviewWorkspacesRequest.WorkspaceSortOrder sort_order = 3;
     */
    sortOrder: ListOverviewWorkspacesRequest_WorkspaceSortOrder;
    /**
     * @generated from protobuf field: bool reverse_sort_order = 4;
     */
    reverseSortOrder: boolean;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 5;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.ListOverviewWorkspacesRequest.WorkspaceSortOrder
 */
export enum ListOverviewWorkspacesRequest_WorkspaceSortOrder {
    /**
     * @generated from protobuf enum value: NAME = 0;
     */
    NAME = 0,
    /**
     * @generated from protobuf enum value: CLUSTERS = 1;
     */
    CLUSTERS = 1,
    /**
     * @generated from protobuf enum value: NAMESPACES = 2;
     */
    NAMESPACES = 2,
    /**
     * @generated from protobuf enum value: SERVICES = 3;
     */
    SERVICES = 3
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.WorkspaceSummary
 */
export interface WorkspaceSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * @generated from protobuf field: int32 num_clusters = 2;
     */
    numClusters: number;
    /**
     * @generated from protobuf field: int32 num_namespaces = 3;
     */
    numNamespaces: number;
    /**
     * @generated from protobuf field: int32 num_services = 4;
     */
    numServices: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewWorkspacesResponse
 */
export interface ListOverviewWorkspacesResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.WorkspaceSummary workspaces = 1;
     */
    workspaces: WorkspaceSummary[];
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
    /**
     * The number of errors after filtering by name.
     *
     * @generated from protobuf field: int32 errors = 3;
     */
    errors: number;
    /**
     * The total after filtering by name and status.
     *
     * @generated from protobuf field: int32 filtered_total = 4;
     */
    filteredTotal: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetOverviewWorkspaceRequest
 */
export interface GetOverviewWorkspaceRequest {
    /**
     * @generated from protobuf field: string workspace_name = 1;
     */
    workspaceName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetOverviewWorkspaceResponse
 */
export interface GetOverviewWorkspaceResponse {
    /**
     * workspace resources
     *
     * @generated from protobuf field: int32 imported_resources = 1;
     */
    importedResources: number;
    /**
     * @generated from protobuf field: int32 exported_resources = 2;
     */
    exportedResources: number;
    /**
     * @generated from protobuf field: int32 imported_workspaces = 3;
     */
    importedWorkspaces: number;
    /**
     * destinations
     *
     * @generated from protobuf field: int32 services = 4;
     */
    services: number;
    /**
     * @generated from protobuf field: int32 virtual_destinations = 5;
     */
    virtualDestinations: number;
    /**
     * @generated from protobuf field: int32 external_services = 6;
     */
    externalServices: number;
    /**
     * policies
     *
     * @generated from protobuf field: int32 security_policies = 8;
     */
    securityPolicies: number;
    /**
     * @generated from protobuf field: int32 resiliency_policies = 9;
     */
    resiliencyPolicies: number;
    /**
     * @generated from protobuf field: int32 traffic_control_policies = 10;
     */
    trafficControlPolicies: number;
    /**
     * @generated from protobuf field: int32 observability_policies = 11;
     */
    observabilityPolicies: number;
    /**
     * @generated from protobuf field: int32 extension_policies = 12;
     */
    extensionPolicies: number;
    /**
     * apis
     *
     * @generated from protobuf field: int32 resolved_graphql_apis = 13;
     */
    resolvedGraphqlApis: number;
    /**
     * @generated from protobuf field: int32 proxied_graphql_apis = 14;
     */
    proxiedGraphqlApis: number;
    /**
     * @generated from protobuf field: int32 stitched_graphql_apis = 15;
     */
    stitchedGraphqlApis: number;
    /**
     * @generated from protobuf field: int32 openapi_apis = 16;
     */
    openapiApis: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewClustersRequest
 */
export interface ListOverviewClustersRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListOverviewClustersRequest.ClusterSortOrder sort_order = 3;
     */
    sortOrder: ListOverviewClustersRequest_ClusterSortOrder;
    /**
     * @generated from protobuf field: bool reverse_sort_order = 4;
     */
    reverseSortOrder: boolean;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 5;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.ListOverviewClustersRequest.ClusterSortOrder
 */
export enum ListOverviewClustersRequest_ClusterSortOrder {
    /**
     * @generated from protobuf enum value: NAME = 0;
     */
    NAME = 0,
    /**
     * @generated from protobuf enum value: NODES = 1;
     */
    NODES = 1,
    /**
     * @generated from protobuf enum value: NAMESPACES = 2;
     */
    NAMESPACES = 2,
    /**
     * @generated from protobuf enum value: SERVICES = 3;
     */
    SERVICES = 3,
    /**
     * @generated from protobuf enum value: GATEWAYS = 4;
     */
    GATEWAYS = 4
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ClusterSummary
 */
export interface ClusterSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef cluster_ref = 1;
     */
    clusterRef?: ObjectRef;
    /**
     * @generated from protobuf field: string region = 2;
     */
    region: string;
    /**
     * @generated from protobuf field: string kubernetes_version = 3;
     */
    kubernetesVersion: string;
    /**
     * @generated from protobuf field: repeated string istio_versions = 4;
     */
    istioVersions: string[];
    /**
     * @generated from protobuf field: string cilium_version = 5;
     */
    ciliumVersion: string;
    /**
     * @generated from protobuf field: string calico_version = 6;
     */
    calicoVersion: string;
    /**
     * @generated from protobuf field: string gloo_mesh_agent_version = 7;
     */
    glooMeshAgentVersion: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 8;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewClustersResponse
 */
export interface ListOverviewClustersResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ClusterSummary clusters = 1;
     */
    clusters: ClusterSummary[];
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
    /**
     * The number of errors after filtering by name.
     *
     * @generated from protobuf field: int32 errors = 3;
     */
    errors: number;
    /**
     * The total after filtering by name and status.
     *
     * @generated from protobuf field: int32 filtered_total = 4;
     */
    filteredTotal: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetOverviewClusterRequest
 */
export interface GetOverviewClusterRequest {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef cluster_ref = 1;
     */
    clusterRef?: ObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetOverviewClusterResponse
 */
export interface GetOverviewClusterResponse {
    /**
     * configuration
     *
     * @generated from protobuf field: int32 namespaces = 1;
     */
    namespaces: number;
    /**
     * @generated from protobuf field: int32 services = 2;
     */
    services: number;
    /**
     * @generated from protobuf field: int32 gateways = 3;
     */
    gateways: number;
    /**
     * hardware
     *
     * @generated from protobuf field: int32 nodes = 4;
     */
    nodes: number;
    /**
     * @generated from protobuf field: int32 cpu_percent = 5;
     */
    cpuPercent: number;
    /**
     * @generated from protobuf field: int32 memory_percent = 6;
     */
    memoryPercent: number;
    /**
     * istio
     *
     * @generated from protobuf field: repeated rpc.gloo.solo.io.IstioOverview istio_overviews = 7;
     */
    istioOverviews: IstioOverview[];
    /**
     * cilium
     *
     * @generated from protobuf field: rpc.gloo.solo.io.CiliumOverview cilium_overview = 8;
     */
    ciliumOverview?: CiliumOverview;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.IstioOverview
 */
export interface IstioOverview {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: string revision = 2;
     */
    revision: string;
    /**
     * @generated from protobuf field: string root_namespace = 3;
     */
    rootNamespace: string;
    /**
     * @generated from protobuf field: string trust_domain = 4;
     */
    trustDomain: string;
    /**
     * @generated from protobuf field: bool managed_by_gloo_mesh = 5;
     */
    managedByGlooMesh: boolean;
    /**
     * @generated from protobuf field: string health_state = 6;
     */
    healthState: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.CiliumOverview
 */
export interface CiliumOverview {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: int32 agent_desired = 2;
     */
    agentDesired: number;
    /**
     * @generated from protobuf field: int32 agent_ready = 3;
     */
    agentReady: number;
    /**
     * @generated from protobuf field: int32 agent_available = 4;
     */
    agentAvailable: number;
    /**
     * @generated from protobuf field: int32 operator_desired = 5;
     */
    operatorDesired: number;
    /**
     * @generated from protobuf field: int32 operator_ready = 6;
     */
    operatorReady: number;
    /**
     * @generated from protobuf field: int32 operator_available = 7;
     */
    operatorAvailable: number;
    /**
     * @generated from protobuf field: int32 hubble_desired = 8;
     */
    hubbleDesired: number;
    /**
     * @generated from protobuf field: int32 hubble_ready = 9;
     */
    hubbleReady: number;
    /**
     * @generated from protobuf field: int32 hubble_available = 10;
     */
    hubbleAvailable: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 11;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewGatewaysRequest
 */
export interface ListOverviewGatewaysRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 3;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewGatewaysResponse
 */
export interface ListOverviewGatewaysResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListOverviewGatewaysResponse.GatewaySummary gateways = 1;
     */
    gateways: ListOverviewGatewaysResponse_GatewaySummary[];
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 total_gateways = 2;
     */
    totalGateways: number;
    /**
     * The number of errors after filtering by name.
     *
     * @generated from protobuf field: int32 gateway_errors = 3;
     */
    gatewayErrors: number;
    /**
     * The total after filtering by name and status.
     *
     * @generated from protobuf field: int32 filtered_total_gateways = 4;
     */
    filteredTotalGateways: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewGatewaysResponse.GatewaySummary
 */
export interface ListOverviewGatewaysResponse_GatewaySummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef gateway_ref = 1;
     */
    gatewayRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 2;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: repeated string hosts = 3;
     */
    hosts: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewApisRequest
 */
export interface ListOverviewApisRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ApiType type_filter = 2;
     */
    typeFilter: ApiType[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 4;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewApisResponse
 */
export interface ListOverviewApisResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListOverviewApisResponse.ApiSummary apis = 1;
     */
    apis: ListOverviewApisResponse_ApiSummary[];
    /**
     * The total after filtering by name and type.
     *
     * @generated from protobuf field: int32 total_apis = 2;
     */
    totalApis: number;
    /**
     * The number of errors after filtering by name and type.
     *
     * @generated from protobuf field: int32 api_errors = 3;
     */
    apiErrors: number;
    /**
     * The total after filtering by name and type and status.
     *
     * @generated from protobuf field: int32 filtered_total_apis = 4;
     */
    filteredTotalApis: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewApisResponse.ApiSummary
 */
export interface ListOverviewApisResponse_ApiSummary {
    /**
     * This is the istio_route_name for GraphQL APIs (specifying the route that they are on).
     *
     * @generated from protobuf field: string route_name = 1;
     */
    routeName: string;
    /**
     * the route table that contains this API
     *
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef route_table_ref = 2;
     */
    routeTableRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ApiType api_type = 3;
     */
    apiType: ApiType;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * Gateway refs
     *
     * @generated from protobuf field: repeated core.skv2.solo.io.ClusterObjectRef gateway_refs = 5;
     */
    gatewayRefs: ClusterObjectRef[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewPortalsRequest
 */
export interface ListOverviewPortalsRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 3;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewPortalsResponse
 */
export interface ListOverviewPortalsResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListOverviewPortalsResponse.PortalSummary portals = 1;
     */
    portals: ListOverviewPortalsResponse_PortalSummary[];
    /**
     * The total after filtering by name and type.
     *
     * @generated from protobuf field: int32 total_portals = 2;
     */
    totalPortals: number;
    /**
     * The number of errors after filtering by name and type.
     *
     * @generated from protobuf field: int32 portal_errors = 3;
     */
    portalErrors: number;
    /**
     * The total after filtering by name and type and status.
     *
     * @generated from protobuf field: int32 filtered_total_portals = 4;
     */
    filteredTotalPortals: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListOverviewPortalsResponse.PortalSummary
 */
export interface ListOverviewPortalsResponse_PortalSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef portal_ref = 1;
     */
    portalRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.WorkspaceStatus owner_workspace = 2;
     */
    ownerWorkspace?: WorkspaceStatus;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
    /**
     * @generated from protobuf field: repeated string domains = 4;
     */
    domains: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetDashboardCardsRequest
 */
export interface GetDashboardCardsRequest {
    /**
     * Duration of metrics to be returned. If omitted, will default to 15 minutes.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 7;
     */
    window?: Duration;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetDashboardCardsResponse
 */
export interface GetDashboardCardsResponse {
    /**
     * Provides users with a high-level summary of the health of the key components of their environments.
     *
     * @generated from protobuf field: rpc.gloo.solo.io.GetDashboardCardsResponse.HealthOverview health_overview = 1;
     */
    healthOverview?: GetDashboardCardsResponse_HealthOverview;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.InsightsOverview insights_overview = 2;
     */
    insightsOverview?: InsightsOverview;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetDashboardCardsResponse.ZeroTrustOverview zero_trust_overview = 3;
     */
    zeroTrustOverview?: GetDashboardCardsResponse_ZeroTrustOverview;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 4;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetDashboardCardsResponse.HealthOverview
 */
export interface GetDashboardCardsResponse_HealthOverview {
    /**
     * Gloo Health
     *
     * @generated from protobuf field: rpc.gloo.solo.io.Status lifecycle_management_status = 1;
     */
    lifecycleManagementStatus?: Status;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status gloo_management_plane_status = 2;
     */
    glooManagementPlaneStatus?: Status;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GlooVersionSummary gloo_versions = 3;
     */
    glooVersions: GlooVersionSummary[];
    /**
     * @generated from protobuf field: int32 total_clusters = 4;
     */
    totalClusters: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetDashboardCardsResponse.ZeroTrustOverview
 */
export interface GetDashboardCardsResponse_ZeroTrustOverview {
    /**
     * Card 1
     *
     * @generated from protobuf field: int32 workloads_receiving_insecure_traffic = 1;
     */
    workloadsReceivingInsecureTraffic: number;
    /**
     * Card 2
     *
     * @generated from protobuf field: int32 mtls_encrypted_workloads = 2;
     */
    mtlsEncryptedWorkloads: number;
    /**
     * @generated from protobuf field: int32 total_workloads = 3;
     */
    totalWorkloads: number;
    /**
     * Card 3
     *
     * @generated from protobuf field: int32 external_services_accessed = 4;
     */
    externalServicesAccessed: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GlooVersionSummary
 */
export interface GlooVersionSummary {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 2;
     */
    status?: Status;
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef deployment_ref = 3;
     */
    deploymentRef?: ClusterObjectRef;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.InsightsOverview
 */
export interface InsightsOverview {
    /**
     * @generated from protobuf field: uint32 resources_scanned = 1;
     */
    resourcesScanned: number;
    /**
     * A map from display-friendly category name to the number of insights in that category.
     *
     * @generated from protobuf field: map<string, uint32> category_counts = 2;
     */
    categoryCounts: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: uint32 total_count = 3;
     */
    totalCount: number;
    /**
     * @generated from protobuf field: uint32 error_count = 4;
     */
    errorCount: number;
    /**
     * @generated from protobuf field: uint32 warning_count = 5;
     */
    warningCount: number;
    /**
     * @generated from protobuf field: uint32 info_count = 6;
     */
    infoCount: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsRequest
 */
export interface GetClusterDetailsCardsRequest {
    /**
     * @generated from protobuf field: string cluster_name = 1;
     */
    clusterName: string;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse
 */
export interface GetClusterDetailsCardsResponse {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ClusterVersions cluster_versions = 1;
     */
    clusterVersions?: GetClusterDetailsCardsResponse_ClusterVersions;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GetClusterDetailsCardsResponse.IstioSummaryOverview istio_summary_overview = 2;
     */
    istioSummaryOverview: GetClusterDetailsCardsResponse_IstioSummaryOverview[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ResourceSummary resource_summary = 3;
     */
    resourceSummary?: GetClusterDetailsCardsResponse_ResourceSummary;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ConfigurationSummary configuration_summary = 4;
     */
    configurationSummary?: GetClusterDetailsCardsResponse_ConfigurationSummary;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.GetClusterDetailsCardsResponse.HealthOverview health_overview = 5;
     */
    healthOverview?: GetClusterDetailsCardsResponse_HealthOverview;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.InsightsOverview insights_overview = 6;
     */
    insightsOverview?: InsightsOverview;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 7;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ClusterVersions
 */
export interface GetClusterDetailsCardsResponse_ClusterVersions {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef cluster_ref = 1;
     */
    clusterRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string region = 2;
     */
    region: string;
    /**
     * @generated from protobuf field: string gloo_mesh_agent_version = 3;
     */
    glooMeshAgentVersion: string;
    /**
     * @generated from protobuf field: string kubernetes_version = 4;
     */
    kubernetesVersion: string;
    /**
     * @generated from protobuf field: repeated string istio_versions = 5;
     */
    istioVersions: string[];
    /**
     * @generated from protobuf field: string cilium_version = 6;
     */
    ciliumVersion: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 7;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.IstioSummaryOverview
 */
export interface GetClusterDetailsCardsResponse_IstioSummaryOverview {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: string root_namespace = 2;
     */
    rootNamespace: string;
    /**
     * @generated from protobuf field: string trust_domain = 3;
     */
    trustDomain: string;
    /**
     * @generated from protobuf field: bool managed_by_gloo_mesh = 4;
     */
    managedByGlooMesh: boolean;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ResourceSummary
 */
export interface GetClusterDetailsCardsResponse_ResourceSummary {
    /**
     * @generated from protobuf field: int32 istio_resources = 1;
     */
    istioResources: number;
    /**
     * @generated from protobuf field: int32 cilium_resources = 2;
     */
    ciliumResources: number;
    /**
     * @generated from protobuf field: int32 k8s_resources = 3 [json_name = "k8sResources"];
     */
    k8SResources: number;
    /**
     * @generated from protobuf field: int32 gateway_resources = 4;
     */
    gatewayResources: number;
    /**
     * @generated from protobuf field: int32 solo_resources = 5;
     */
    soloResources: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ConfigurationSummary
 */
export interface GetClusterDetailsCardsResponse_ConfigurationSummary {
    /**
     * @generated from protobuf field: int32 namespaces = 1;
     */
    namespaces: number;
    /**
     * @generated from protobuf field: int32 services = 2;
     */
    services: number;
    /**
     * @generated from protobuf field: int32 gateways = 3;
     */
    gateways: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.HealthOverview
 */
export interface GetClusterDetailsCardsResponse_HealthOverview {
    /**
     * Gloo Health
     *
     * @generated from protobuf field: rpc.gloo.solo.io.Status lifecycle_management_status = 1;
     */
    lifecycleManagementStatus?: Status;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status gloo_management_plane_status = 2;
     */
    glooManagementPlaneStatus?: Status;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status gloo_agent_status = 3;
     */
    glooAgentStatus?: Status;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.GlooVersionSummary gloo_versions = 4;
     */
    glooVersions: GlooVersionSummary[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListServicesRequest
 */
export interface ListServicesRequest {
    /**
     * @generated from protobuf field: string text_filter = 1;
     */
    textFilter: string;
    /**
     * @generated from protobuf field: string type_filter = 2;
     */
    typeFilter: string; // TODO: placeholder, since this will always be 'Service' for now.
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 3;
     */
    statusFilter: State[];
    /**
     * Filter based on case-insensitive substring of label keys
     *
     * @generated from protobuf field: string label_key_filter = 4;
     */
    labelKeyFilter: string;
    /**
     * Filter based on case-insensitive substring of label values
     *
     * @generated from protobuf field: string label_value_filter = 5;
     */
    labelValueFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 6;
     */
    pagination?: Pagination;
    /**
     * Duration of metrics to be returned. If omitted, will default to 15 minutes.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 7;
     */
    window?: Duration;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListServicesResponse
 */
export interface ListServicesResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListServicesResponse.ServiceSummary services = 1;
     */
    services: ListServicesResponse_ServiceSummary[];
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 total_services = 2;
     */
    totalServices: number;
    /**
     * The number of errors after filtering by name.
     *
     * @generated from protobuf field: int32 service_errors = 3;
     */
    serviceErrors: number;
    /**
     * The total after filtering by name and status.
     *
     * @generated from protobuf field: int32 filtered_total_services = 4;
     */
    filteredTotalServices: number;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 5;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListServicesResponse.ServiceSummary
 */
export interface ListServicesResponse_ServiceSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ClusterObjectRef service_ref = 1;
     */
    serviceRef?: ClusterObjectRef;
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string; // TODO: placeholder, since this will always be 'Service' for now.
    /**
     * @generated from protobuf field: int32 requests_total = 3;
     */
    requestsTotal: number;
    /**
     * @generated from protobuf field: int32 requests_error = 4;
     */
    requestsError: number;
    /**
     * @generated from protobuf field: map<string, string> labels = 5;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 6;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListEgressSourcesRequest
 */
export interface ListEgressSourcesRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * Duration of metrics to be returned.
     *
     * @generated from protobuf field: google.protobuf.Duration window = 3;
     */
    window?: Duration;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListEgressSourcesResponse
 */
export interface ListEgressSourcesResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListEgressSourcesResponse.EgressSourceSummary egress_source_summaries = 1;
     */
    egressSourceSummaries: ListEgressSourcesResponse_EgressSourceSummary[];
    /**
     * @generated from protobuf field: int32 total_egress_sources = 2;
     */
    totalEgressSources: number;
    /**
     * @generated from protobuf field: int32 filtered_total_egress_sources = 3;
     */
    filteredTotalEgressSources: number;
    /**
     * @generated from protobuf field: bool registry_only = 4;
     */
    registryOnly: boolean;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 5;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListEgressSourcesResponse.EgressSourceSummary
 */
export interface ListEgressSourcesResponse_EgressSourceSummary {
    /**
     * @generated from protobuf field: string host_name = 1;
     */
    hostName: string;
    /**
     * @generated from protobuf field: string port = 2;
     */
    port: string; // This can be a number or a named port.
    /**
     * tls_state can be "secure" "insecure" or "unknown"
     *
     * @generated from protobuf field: string tls_state = 3;
     */
    tlsState: string;
    /**
     * @generated from protobuf field: repeated string consuming_services = 4;
     */
    consumingServices: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListNodesRequest
 */
export interface ListNodesRequest {
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 1;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: string name_filter = 2;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 3;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListNodesResponse
 */
export interface ListNodesResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListNodesResponse.NodeSummary nodes = 1;
     */
    nodes: ListNodesResponse_NodeSummary[];
    /**
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 filtered_total = 3;
     */
    filteredTotal: number;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 4;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListNodesResponse.NodeSummary
 */
export interface ListNodesResponse_NodeSummary {
    /**
     * @generated from protobuf field: string host_name = 1;
     */
    hostName: string;
    /**
     * @generated from protobuf field: string ip_address = 2;
     */
    ipAddress: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 3;
     */
    status?: Status;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListIngressGatewaysRequest
 */
export interface ListIngressGatewaysRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListIngressGatewaysResponse
 */
export interface ListIngressGatewaysResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListIngressGatewaysResponse.IngressGatewaySummary ingress_gateway_summaries = 1;
     */
    ingressGatewaySummaries: ListIngressGatewaysResponse_IngressGatewaySummary[];
    /**
     * @generated from protobuf field: int32 total_ingress_gateways = 2;
     */
    totalIngressGateways: number;
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 filtered_total_ingress_gateways = 3;
     */
    filteredTotalIngressGateways: number;
    /**
     * @generated from protobuf field: int32 num_istio_gateway_proxies = 4;
     */
    numIstioGatewayProxies: number;
    /**
     * @generated from protobuf field: int32 num_istio_gateway_crs = 5;
     */
    numIstioGatewayCrs: number; // TODO: in the future, add num_k8s_gateway_crs and potentially gloo virtualgateway refs 
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 6;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListIngressGatewaysResponse.IngressGatewaySummary
 */
export interface ListIngressGatewaysResponse_IngressGatewaySummary {
    /**
     * Currently, the only allowed type for the ingress_gateway_ref is the Istio Gateway.
     * In the future, we may add the K8s Gateway API Gateway or the Gloo VirtualGateway.
     *
     * @generated from protobuf field: core.skv2.solo.io.TypedClusterObjectRef ingress_gateway_ref = 1;
     */
    ingressGatewayRef?: TypedClusterObjectRef;
    /**
     * @generated from protobuf field: string port = 2;
     */
    port: string; // This can be a number or a named port.
    /**
     * @generated from protobuf field: string domain = 3;
     */
    domain: string;
    /**
     * @generated from protobuf field: string certificate_name = 4;
     */
    certificateName: string;
    /**
     * @generated from protobuf field: repeated string backing_services = 5;
     */
    backingServices: string[];
    /**
     * @generated from protobuf field: insights.internal.gloo.solo.io.Insight.SYS0015Data certificates = 6;
     */
    certificates?: Insight_SYS0015Data;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListClustersRequest
 */
export interface ListClustersRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListClustersRequest.ClusterSortOrder sort_order = 3;
     */
    sortOrder: ListClustersRequest_ClusterSortOrder;
    /**
     * @generated from protobuf field: bool reverse_sort_order = 4;
     */
    reverseSortOrder: boolean;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 5;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.ListClustersRequest.ClusterSortOrder
 */
export enum ListClustersRequest_ClusterSortOrder {
    /**
     * @generated from protobuf enum value: NAME = 0;
     */
    NAME = 0,
    /**
     * @generated from protobuf enum value: INSIGHTS = 1;
     */
    INSIGHTS = 1
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.CoreClusterSummary
 */
export interface CoreClusterSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef cluster_ref = 1;
     */
    clusterRef?: ObjectRef;
    /**
     * @generated from protobuf field: string kubernetes_version = 2;
     */
    kubernetesVersion: string;
    /**
     * @generated from protobuf field: repeated string istio_versions = 3;
     */
    istioVersions: string[];
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 4;
     */
    status?: Status;
    /**
     * @generated from protobuf field: int32 num_insights = 5;
     */
    numInsights: number;
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListClustersResponse
 */
export interface ListClustersResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.CoreClusterSummary clusters = 1;
     */
    clusters: CoreClusterSummary[];
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
    /**
     * The number of errors after filtering by name.
     *
     * @generated from protobuf field: int32 errors = 3;
     */
    errors: number;
    /**
     * The total after filtering by name and status.
     *
     * @generated from protobuf field: int32 filtered_total = 4;
     */
    filteredTotal: number;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 5;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspacesRequest
 */
export interface ListWorkspacesRequest {
    /**
     * @generated from protobuf field: string name_filter = 1;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Pagination pagination = 2;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.ListWorkspacesRequest.WorkspaceSortOrder sort_order = 3;
     */
    sortOrder: ListWorkspacesRequest_WorkspaceSortOrder;
    /**
     * @generated from protobuf field: bool reverse_sort_order = 4;
     */
    reverseSortOrder: boolean;
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.State status_filter = 5;
     */
    statusFilter: State[];
}
/**
 * @generated from protobuf enum rpc.gloo.solo.io.ListWorkspacesRequest.WorkspaceSortOrder
 */
export enum ListWorkspacesRequest_WorkspaceSortOrder {
    /**
     * @generated from protobuf enum value: NAME = 0;
     */
    NAME = 0,
    /**
     * @generated from protobuf enum value: CLUSTERS = 1;
     */
    CLUSTERS = 1,
    /**
     * @generated from protobuf enum value: NAMESPACES = 2;
     */
    NAMESPACES = 2,
    /**
     * @generated from protobuf enum value: SERVICES = 3;
     */
    SERVICES = 3
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspacesResponse
 */
export interface ListWorkspacesResponse {
    /**
     * @generated from protobuf field: repeated rpc.gloo.solo.io.ListWorkspacesResponse.CoreWorkspaceSummary workspaces = 1;
     */
    workspaces: ListWorkspacesResponse_CoreWorkspaceSummary[];
    /**
     * The total after filtering by name.
     *
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
    /**
     * The number of errors after filtering by name.
     *
     * @generated from protobuf field: int32 errors = 3;
     */
    errors: number;
    /**
     * The total after filtering by name and status.
     *
     * @generated from protobuf field: int32 filtered_total = 4;
     */
    filteredTotal: number;
    /**
     * @generated from protobuf field: repeated string apiserver_errors = 5;
     */
    apiserverErrors: string[];
}
/**
 * @generated from protobuf message rpc.gloo.solo.io.ListWorkspacesResponse.CoreWorkspaceSummary
 */
export interface ListWorkspacesResponse_CoreWorkspaceSummary {
    /**
     * @generated from protobuf field: core.skv2.solo.io.ObjectRef workspace_ref = 1;
     */
    workspaceRef?: ObjectRef;
    /**
     * @generated from protobuf field: int32 num_clusters = 2;
     */
    numClusters: number;
    /**
     * @generated from protobuf field: int32 num_namespaces = 3;
     */
    numNamespaces: number;
    /**
     * @generated from protobuf field: int32 num_services = 4;
     */
    numServices: number;
    /**
     * @generated from protobuf field: rpc.gloo.solo.io.Status status = 5;
     */
    status?: Status;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewWorkspacesRequest$Type extends MessageType<ListOverviewWorkspacesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewWorkspacesRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rpc.gloo.solo.io.ListOverviewWorkspacesRequest.WorkspaceSortOrder", ListOverviewWorkspacesRequest_WorkspaceSortOrder] },
            { no: 4, name: "reverse_sort_order", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewWorkspacesRequest
 */
export const ListOverviewWorkspacesRequest = new ListOverviewWorkspacesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkspaceSummary$Type extends MessageType<WorkspaceSummary> {
    constructor() {
        super("rpc.gloo.solo.io.WorkspaceSummary", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "num_clusters", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "num_namespaces", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "num_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.WorkspaceSummary
 */
export const WorkspaceSummary = new WorkspaceSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewWorkspacesResponse$Type extends MessageType<ListOverviewWorkspacesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewWorkspacesResponse", [
            { no: 1, name: "workspaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkspaceSummary },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewWorkspacesResponse
 */
export const ListOverviewWorkspacesResponse = new ListOverviewWorkspacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOverviewWorkspaceRequest$Type extends MessageType<GetOverviewWorkspaceRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetOverviewWorkspaceRequest", [
            { no: 1, name: "workspace_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetOverviewWorkspaceRequest
 */
export const GetOverviewWorkspaceRequest = new GetOverviewWorkspaceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOverviewWorkspaceResponse$Type extends MessageType<GetOverviewWorkspaceResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetOverviewWorkspaceResponse", [
            { no: 1, name: "imported_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "exported_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "imported_workspaces", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "virtual_destinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "external_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "security_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "resiliency_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "traffic_control_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "observability_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "extension_policies", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "resolved_graphql_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "proxied_graphql_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "stitched_graphql_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "openapi_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetOverviewWorkspaceResponse
 */
export const GetOverviewWorkspaceResponse = new GetOverviewWorkspaceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewClustersRequest$Type extends MessageType<ListOverviewClustersRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewClustersRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rpc.gloo.solo.io.ListOverviewClustersRequest.ClusterSortOrder", ListOverviewClustersRequest_ClusterSortOrder] },
            { no: 4, name: "reverse_sort_order", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewClustersRequest
 */
export const ListOverviewClustersRequest = new ListOverviewClustersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClusterSummary$Type extends MessageType<ClusterSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ClusterSummary", [
            { no: 1, name: "cluster_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kubernetes_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "istio_versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "cilium_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "calico_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "gloo_mesh_agent_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ClusterSummary
 */
export const ClusterSummary = new ClusterSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewClustersResponse$Type extends MessageType<ListOverviewClustersResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewClustersResponse", [
            { no: 1, name: "clusters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterSummary },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewClustersResponse
 */
export const ListOverviewClustersResponse = new ListOverviewClustersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOverviewClusterRequest$Type extends MessageType<GetOverviewClusterRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetOverviewClusterRequest", [
            { no: 1, name: "cluster_ref", kind: "message", T: () => ObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetOverviewClusterRequest
 */
export const GetOverviewClusterRequest = new GetOverviewClusterRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOverviewClusterResponse$Type extends MessageType<GetOverviewClusterResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetOverviewClusterResponse", [
            { no: 1, name: "namespaces", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "nodes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "cpu_percent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "memory_percent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "istio_overviews", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IstioOverview },
            { no: 8, name: "cilium_overview", kind: "message", T: () => CiliumOverview }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetOverviewClusterResponse
 */
export const GetOverviewClusterResponse = new GetOverviewClusterResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IstioOverview$Type extends MessageType<IstioOverview> {
    constructor() {
        super("rpc.gloo.solo.io.IstioOverview", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "revision", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "root_namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "trust_domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "managed_by_gloo_mesh", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "health_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.IstioOverview
 */
export const IstioOverview = new IstioOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CiliumOverview$Type extends MessageType<CiliumOverview> {
    constructor() {
        super("rpc.gloo.solo.io.CiliumOverview", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "agent_desired", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "agent_ready", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "agent_available", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "operator_desired", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "operator_ready", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "operator_available", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "hubble_desired", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "hubble_ready", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "hubble_available", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.CiliumOverview
 */
export const CiliumOverview = new CiliumOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewGatewaysRequest$Type extends MessageType<ListOverviewGatewaysRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewGatewaysRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewGatewaysRequest
 */
export const ListOverviewGatewaysRequest = new ListOverviewGatewaysRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewGatewaysResponse$Type extends MessageType<ListOverviewGatewaysResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewGatewaysResponse", [
            { no: 1, name: "gateways", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListOverviewGatewaysResponse_GatewaySummary },
            { no: 2, name: "total_gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "gateway_errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total_gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewGatewaysResponse
 */
export const ListOverviewGatewaysResponse = new ListOverviewGatewaysResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewGatewaysResponse_GatewaySummary$Type extends MessageType<ListOverviewGatewaysResponse_GatewaySummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewGatewaysResponse.GatewaySummary", [
            { no: 1, name: "gateway_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "hosts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewGatewaysResponse.GatewaySummary
 */
export const ListOverviewGatewaysResponse_GatewaySummary = new ListOverviewGatewaysResponse_GatewaySummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewApisRequest$Type extends MessageType<ListOverviewApisRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewApisRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.ApiType", ApiType] },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewApisRequest
 */
export const ListOverviewApisRequest = new ListOverviewApisRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewApisResponse$Type extends MessageType<ListOverviewApisResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewApisResponse", [
            { no: 1, name: "apis", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListOverviewApisResponse_ApiSummary },
            { no: 2, name: "total_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "api_errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total_apis", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewApisResponse
 */
export const ListOverviewApisResponse = new ListOverviewApisResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewApisResponse_ApiSummary$Type extends MessageType<ListOverviewApisResponse_ApiSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewApisResponse.ApiSummary", [
            { no: 1, name: "route_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "route_table_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 3, name: "api_type", kind: "enum", T: () => ["rpc.gloo.solo.io.ApiType", ApiType] },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "gateway_refs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewApisResponse.ApiSummary
 */
export const ListOverviewApisResponse_ApiSummary = new ListOverviewApisResponse_ApiSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewPortalsRequest$Type extends MessageType<ListOverviewPortalsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewPortalsRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewPortalsRequest
 */
export const ListOverviewPortalsRequest = new ListOverviewPortalsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewPortalsResponse$Type extends MessageType<ListOverviewPortalsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewPortalsResponse", [
            { no: 1, name: "portals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListOverviewPortalsResponse_PortalSummary },
            { no: 2, name: "total_portals", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "portal_errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total_portals", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewPortalsResponse
 */
export const ListOverviewPortalsResponse = new ListOverviewPortalsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOverviewPortalsResponse_PortalSummary$Type extends MessageType<ListOverviewPortalsResponse_PortalSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListOverviewPortalsResponse.PortalSummary", [
            { no: 1, name: "portal_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "owner_workspace", kind: "message", T: () => WorkspaceStatus },
            { no: 3, name: "status", kind: "message", T: () => Status },
            { no: 4, name: "domains", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListOverviewPortalsResponse.PortalSummary
 */
export const ListOverviewPortalsResponse_PortalSummary = new ListOverviewPortalsResponse_PortalSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDashboardCardsRequest$Type extends MessageType<GetDashboardCardsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetDashboardCardsRequest", [
            { no: 7, name: "window", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetDashboardCardsRequest
 */
export const GetDashboardCardsRequest = new GetDashboardCardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDashboardCardsResponse$Type extends MessageType<GetDashboardCardsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetDashboardCardsResponse", [
            { no: 1, name: "health_overview", kind: "message", T: () => GetDashboardCardsResponse_HealthOverview },
            { no: 2, name: "insights_overview", kind: "message", T: () => InsightsOverview },
            { no: 3, name: "zero_trust_overview", kind: "message", T: () => GetDashboardCardsResponse_ZeroTrustOverview },
            { no: 4, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetDashboardCardsResponse
 */
export const GetDashboardCardsResponse = new GetDashboardCardsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDashboardCardsResponse_HealthOverview$Type extends MessageType<GetDashboardCardsResponse_HealthOverview> {
    constructor() {
        super("rpc.gloo.solo.io.GetDashboardCardsResponse.HealthOverview", [
            { no: 1, name: "lifecycle_management_status", kind: "message", T: () => Status },
            { no: 2, name: "gloo_management_plane_status", kind: "message", T: () => Status },
            { no: 3, name: "gloo_versions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GlooVersionSummary },
            { no: 4, name: "total_clusters", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetDashboardCardsResponse.HealthOverview
 */
export const GetDashboardCardsResponse_HealthOverview = new GetDashboardCardsResponse_HealthOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDashboardCardsResponse_ZeroTrustOverview$Type extends MessageType<GetDashboardCardsResponse_ZeroTrustOverview> {
    constructor() {
        super("rpc.gloo.solo.io.GetDashboardCardsResponse.ZeroTrustOverview", [
            { no: 1, name: "workloads_receiving_insecure_traffic", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "mtls_encrypted_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "total_workloads", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "external_services_accessed", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetDashboardCardsResponse.ZeroTrustOverview
 */
export const GetDashboardCardsResponse_ZeroTrustOverview = new GetDashboardCardsResponse_ZeroTrustOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlooVersionSummary$Type extends MessageType<GlooVersionSummary> {
    constructor() {
        super("rpc.gloo.solo.io.GlooVersionSummary", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "message", T: () => Status },
            { no: 3, name: "deployment_ref", kind: "message", T: () => ClusterObjectRef }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GlooVersionSummary
 */
export const GlooVersionSummary = new GlooVersionSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsightsOverview$Type extends MessageType<InsightsOverview> {
    constructor() {
        super("rpc.gloo.solo.io.InsightsOverview", [
            { no: 1, name: "resources_scanned", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "category_counts", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 13 /*ScalarType.UINT32*/ } },
            { no: 3, name: "total_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "error_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "warning_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "info_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.InsightsOverview
 */
export const InsightsOverview = new InsightsOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsRequest$Type extends MessageType<GetClusterDetailsCardsRequest> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsRequest", [
            { no: 1, name: "cluster_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsRequest
 */
export const GetClusterDetailsCardsRequest = new GetClusterDetailsCardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsResponse$Type extends MessageType<GetClusterDetailsCardsResponse> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsResponse", [
            { no: 1, name: "cluster_versions", kind: "message", T: () => GetClusterDetailsCardsResponse_ClusterVersions },
            { no: 2, name: "istio_summary_overview", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetClusterDetailsCardsResponse_IstioSummaryOverview },
            { no: 3, name: "resource_summary", kind: "message", T: () => GetClusterDetailsCardsResponse_ResourceSummary },
            { no: 4, name: "configuration_summary", kind: "message", T: () => GetClusterDetailsCardsResponse_ConfigurationSummary },
            { no: 5, name: "health_overview", kind: "message", T: () => GetClusterDetailsCardsResponse_HealthOverview },
            { no: 6, name: "insights_overview", kind: "message", T: () => InsightsOverview },
            { no: 7, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse
 */
export const GetClusterDetailsCardsResponse = new GetClusterDetailsCardsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsResponse_ClusterVersions$Type extends MessageType<GetClusterDetailsCardsResponse_ClusterVersions> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ClusterVersions", [
            { no: 1, name: "cluster_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "gloo_mesh_agent_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "kubernetes_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "istio_versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "cilium_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ClusterVersions
 */
export const GetClusterDetailsCardsResponse_ClusterVersions = new GetClusterDetailsCardsResponse_ClusterVersions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsResponse_IstioSummaryOverview$Type extends MessageType<GetClusterDetailsCardsResponse_IstioSummaryOverview> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsResponse.IstioSummaryOverview", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "root_namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "trust_domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "managed_by_gloo_mesh", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.IstioSummaryOverview
 */
export const GetClusterDetailsCardsResponse_IstioSummaryOverview = new GetClusterDetailsCardsResponse_IstioSummaryOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsResponse_ResourceSummary$Type extends MessageType<GetClusterDetailsCardsResponse_ResourceSummary> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ResourceSummary", [
            { no: 1, name: "istio_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "cilium_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "k8s_resources", kind: "scalar", jsonName: "k8sResources", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "gateway_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "solo_resources", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ResourceSummary
 */
export const GetClusterDetailsCardsResponse_ResourceSummary = new GetClusterDetailsCardsResponse_ResourceSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsResponse_ConfigurationSummary$Type extends MessageType<GetClusterDetailsCardsResponse_ConfigurationSummary> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ConfigurationSummary", [
            { no: 1, name: "namespaces", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.ConfigurationSummary
 */
export const GetClusterDetailsCardsResponse_ConfigurationSummary = new GetClusterDetailsCardsResponse_ConfigurationSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClusterDetailsCardsResponse_HealthOverview$Type extends MessageType<GetClusterDetailsCardsResponse_HealthOverview> {
    constructor() {
        super("rpc.gloo.solo.io.GetClusterDetailsCardsResponse.HealthOverview", [
            { no: 1, name: "lifecycle_management_status", kind: "message", T: () => Status },
            { no: 2, name: "gloo_management_plane_status", kind: "message", T: () => Status },
            { no: 3, name: "gloo_agent_status", kind: "message", T: () => Status },
            { no: 4, name: "gloo_versions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GlooVersionSummary }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.GetClusterDetailsCardsResponse.HealthOverview
 */
export const GetClusterDetailsCardsResponse_HealthOverview = new GetClusterDetailsCardsResponse_HealthOverview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListServicesRequest$Type extends MessageType<ListServicesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListServicesRequest", [
            { no: 1, name: "text_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] },
            { no: 4, name: "label_key_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "label_value_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pagination", kind: "message", T: () => Pagination },
            { no: 7, name: "window", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListServicesRequest
 */
export const ListServicesRequest = new ListServicesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListServicesResponse$Type extends MessageType<ListServicesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListServicesResponse", [
            { no: 1, name: "services", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListServicesResponse_ServiceSummary },
            { no: 2, name: "total_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "service_errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListServicesResponse
 */
export const ListServicesResponse = new ListServicesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListServicesResponse_ServiceSummary$Type extends MessageType<ListServicesResponse_ServiceSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListServicesResponse.ServiceSummary", [
            { no: 1, name: "service_ref", kind: "message", T: () => ClusterObjectRef },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "requests_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "requests_error", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListServicesResponse.ServiceSummary
 */
export const ListServicesResponse_ServiceSummary = new ListServicesResponse_ServiceSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListEgressSourcesRequest$Type extends MessageType<ListEgressSourcesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListEgressSourcesRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "window", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListEgressSourcesRequest
 */
export const ListEgressSourcesRequest = new ListEgressSourcesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListEgressSourcesResponse$Type extends MessageType<ListEgressSourcesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListEgressSourcesResponse", [
            { no: 1, name: "egress_source_summaries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListEgressSourcesResponse_EgressSourceSummary },
            { no: 2, name: "total_egress_sources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "filtered_total_egress_sources", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "registry_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListEgressSourcesResponse
 */
export const ListEgressSourcesResponse = new ListEgressSourcesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListEgressSourcesResponse_EgressSourceSummary$Type extends MessageType<ListEgressSourcesResponse_EgressSourceSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListEgressSourcesResponse.EgressSourceSummary", [
            { no: 1, name: "host_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "port", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tls_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "consuming_services", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListEgressSourcesResponse.EgressSourceSummary
 */
export const ListEgressSourcesResponse_EgressSourceSummary = new ListEgressSourcesResponse_EgressSourceSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNodesRequest$Type extends MessageType<ListNodesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListNodesRequest", [
            { no: 1, name: "pagination", kind: "message", T: () => Pagination },
            { no: 2, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListNodesRequest
 */
export const ListNodesRequest = new ListNodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNodesResponse$Type extends MessageType<ListNodesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListNodesResponse", [
            { no: 1, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListNodesResponse_NodeSummary },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "filtered_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListNodesResponse
 */
export const ListNodesResponse = new ListNodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNodesResponse_NodeSummary$Type extends MessageType<ListNodesResponse_NodeSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListNodesResponse.NodeSummary", [
            { no: 1, name: "host_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ip_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListNodesResponse.NodeSummary
 */
export const ListNodesResponse_NodeSummary = new ListNodesResponse_NodeSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIngressGatewaysRequest$Type extends MessageType<ListIngressGatewaysRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListIngressGatewaysRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListIngressGatewaysRequest
 */
export const ListIngressGatewaysRequest = new ListIngressGatewaysRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIngressGatewaysResponse$Type extends MessageType<ListIngressGatewaysResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListIngressGatewaysResponse", [
            { no: 1, name: "ingress_gateway_summaries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListIngressGatewaysResponse_IngressGatewaySummary },
            { no: 2, name: "total_ingress_gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "filtered_total_ingress_gateways", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "num_istio_gateway_proxies", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "num_istio_gateway_crs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListIngressGatewaysResponse
 */
export const ListIngressGatewaysResponse = new ListIngressGatewaysResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIngressGatewaysResponse_IngressGatewaySummary$Type extends MessageType<ListIngressGatewaysResponse_IngressGatewaySummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListIngressGatewaysResponse.IngressGatewaySummary", [
            { no: 1, name: "ingress_gateway_ref", kind: "message", T: () => TypedClusterObjectRef },
            { no: 2, name: "port", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "certificate_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "backing_services", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "certificates", kind: "message", T: () => Insight_SYS0015Data }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListIngressGatewaysResponse.IngressGatewaySummary
 */
export const ListIngressGatewaysResponse_IngressGatewaySummary = new ListIngressGatewaysResponse_IngressGatewaySummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClustersRequest$Type extends MessageType<ListClustersRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListClustersRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rpc.gloo.solo.io.ListClustersRequest.ClusterSortOrder", ListClustersRequest_ClusterSortOrder] },
            { no: 4, name: "reverse_sort_order", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListClustersRequest
 */
export const ListClustersRequest = new ListClustersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CoreClusterSummary$Type extends MessageType<CoreClusterSummary> {
    constructor() {
        super("rpc.gloo.solo.io.CoreClusterSummary", [
            { no: 1, name: "cluster_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "kubernetes_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "istio_versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "message", T: () => Status },
            { no: 5, name: "num_insights", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.CoreClusterSummary
 */
export const CoreClusterSummary = new CoreClusterSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClustersResponse$Type extends MessageType<ListClustersResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListClustersResponse", [
            { no: 1, name: "clusters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CoreClusterSummary },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListClustersResponse
 */
export const ListClustersResponse = new ListClustersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspacesRequest$Type extends MessageType<ListWorkspacesRequest> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspacesRequest", [
            { no: 1, name: "name_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pagination", kind: "message", T: () => Pagination },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rpc.gloo.solo.io.ListWorkspacesRequest.WorkspaceSortOrder", ListWorkspacesRequest_WorkspaceSortOrder] },
            { no: 4, name: "reverse_sort_order", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "status_filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rpc.gloo.solo.io.State", State] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspacesRequest
 */
export const ListWorkspacesRequest = new ListWorkspacesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspacesResponse$Type extends MessageType<ListWorkspacesResponse> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspacesResponse", [
            { no: 1, name: "workspaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListWorkspacesResponse_CoreWorkspaceSummary },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "errors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filtered_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "apiserver_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspacesResponse
 */
export const ListWorkspacesResponse = new ListWorkspacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWorkspacesResponse_CoreWorkspaceSummary$Type extends MessageType<ListWorkspacesResponse_CoreWorkspaceSummary> {
    constructor() {
        super("rpc.gloo.solo.io.ListWorkspacesResponse.CoreWorkspaceSummary", [
            { no: 1, name: "workspace_ref", kind: "message", T: () => ObjectRef },
            { no: 2, name: "num_clusters", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "num_namespaces", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "num_services", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "status", kind: "message", T: () => Status }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message rpc.gloo.solo.io.ListWorkspacesResponse.CoreWorkspaceSummary
 */
export const ListWorkspacesResponse_CoreWorkspaceSummary = new ListWorkspacesResponse_CoreWorkspaceSummary$Type();
/**
 * @generated ServiceType for protobuf service rpc.gloo.solo.io.OverviewApi
 */
export const OverviewApi = new ServiceType("rpc.gloo.solo.io.OverviewApi", [
    { name: "ListOverviewWorkspaces", options: {}, I: ListOverviewWorkspacesRequest, O: ListOverviewWorkspacesResponse },
    { name: "GetOverviewWorkspace", options: {}, I: GetOverviewWorkspaceRequest, O: GetOverviewWorkspaceResponse },
    { name: "ListClusters", options: {}, I: ListClustersRequest, O: ListClustersResponse },
    { name: "ListWorkspaces", options: {}, I: ListWorkspacesRequest, O: ListWorkspacesResponse },
    { name: "GetDashboardCards", options: {}, I: GetDashboardCardsRequest, O: GetDashboardCardsResponse },
    { name: "GetClusterDetailsCards", options: {}, I: GetClusterDetailsCardsRequest, O: GetClusterDetailsCardsResponse },
    { name: "ListServices", options: {}, I: ListServicesRequest, O: ListServicesResponse },
    { name: "ListIngressGateways", options: {}, I: ListIngressGatewaysRequest, O: ListIngressGatewaysResponse },
    { name: "ListEgressSources", options: {}, I: ListEgressSourcesRequest, O: ListEgressSourcesResponse },
    { name: "ListNodes", options: {}, I: ListNodesRequest, O: ListNodesResponse },
    { name: "ListOverviewClusters", options: {}, I: ListOverviewClustersRequest, O: ListOverviewClustersResponse },
    { name: "GetOverviewCluster", options: {}, I: GetOverviewClusterRequest, O: GetOverviewClusterResponse },
    { name: "ListOverviewGateways", options: {}, I: ListOverviewGatewaysRequest, O: ListOverviewGatewaysResponse },
    { name: "ListOverviewApis", options: {}, I: ListOverviewApisRequest, O: ListOverviewApisResponse },
    { name: "ListOverviewPortals", options: {}, I: ListOverviewPortalsRequest, O: ListOverviewPortalsResponse }
]);
