import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { DirectiveDefinitionNode, InputValueDefinitionNode } from 'graphql';
import { useMemo } from 'react';
import { GqlSchemaTabItemProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import NameAndReturnType from '../../NameAndReturnType';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';

type TableFields = InputValueDefinitionNode & {
  key: string;
  descriptionMarkdown: string;
};

const GqlDirectiveTabItem = ({
  onTypeClick,
  focusedItem,
  tabHeader,
  focusedPath
}: GqlSchemaTabItemProps<DirectiveDefinitionNode>) => {
  const columns: SoloColumnsType<TableFields> = useMemo(
    () => [
      {
        title: 'Argument',
        dataIndex: 'name',
        render: (_, argument) => (
          <Spacer py={2}>
            <NameAndReturnType node={argument} onTypeClick={t => onTypeClick(t, true)} />
          </Spacer>
        )
      },
      {
        title: 'Description',
        dataIndex: 'descriptionMarkdown',
        render: renderReactMarkdown
      }
    ],
    [onTypeClick]
  );
  const tableData: readonly TableFields[] = useMemo(
    () =>
      focusedItem.arguments?.map(a => ({
        ...a,
        key: a.name.value,
        descriptionMarkdown: getDescriptionColumnValue(a)
      })) ?? [],
    [focusedItem.arguments]
  );

  //
  // Render
  //
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      {!!focusedItem.locations.length && (
        <Spacer pt={1} pb={2}>
          On: {focusedItem.locations.map(l => l.value.toLowerCase()).join(', ')}
        </Spacer>
      )}
      <GqlTabItemDescription node={focusedItem} />
      <SoloTable
        removeHorizontalPadding
        columns={columns}
        dataSource={tableData}
        data-testid='gql-directive-arguments-table'
      />
    </>
  );
};

export default GqlDirectiveTabItem;
