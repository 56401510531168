import { HealthIndicatorWithName } from 'Components/Common/HealthIndicator';
import { OverviewGridStyles as GridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo, SecondaryInfoItemProps } from 'Components/Common/SecondaryInfo';
import { SoloLink } from 'Components/Common/SoloLink';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { LabelsCell } from 'Components/Common/SoloTableCells';
import { SvgWithTextContainer } from 'Components/Common/Svg';
import { YamlModal, YamlResource } from 'Components/Common/YamlModal';
import { ResourceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/debug_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
import { getResourceDetailsUrlBasedOnSummaryType } from 'utils/features/resources/resource-helpers';
import { Permission, usePermissions } from 'utils/permissions';
import { resourceTypesMap } from 'utils/types';

function createYamlResource(resourceType: ResourceType, ref: ClusterObjectRef | undefined) {
  return {
    resourceType,
    clusterObjectRef: ref!,
    icon: resourceTypesMap[resourceType].icon
  };
}

export const ResourcesLandingBodyCard = ({ resource }: { resource: ResourceSummary }) => {
  const { hasPerm } = usePermissions();
  const [yamlResource, setYamlResource] = useState<YamlResource>();

  const usefulResourceTypeInfo = resourceTypesMap[resource.type];

  const showYaml = () => {
    setYamlResource(createYamlResource(resource.type, resource.ref));
  };

  let secondaryInfoList: SecondaryInfoItemProps[] = [];

  if (!!resource.workspaceName.length) {
    secondaryInfoList.push({ label: 'Workspace', data: resource.workspaceName });
  }

  if (!!resource.ref) {
    secondaryInfoList.push({ label: 'Cluster', data: resource.ref.clusterName });
    if (!!resource.ref?.namespace) {
      secondaryInfoList.push({ label: 'Namespace', data: resource.ref?.namespace, highlighted: true });
    }
  }
  if (!!Object.keys(resource.labels).length) {
    secondaryInfoList.push({
      label: 'Labels',
      data: <LabelsCell labelsMap={Object.entries(resource.labels)} />
    });
  }
  const url = getResourceDetailsUrlBasedOnSummaryType(resource);
  const clickable =
    (resource.type === ResourceType.CILIUM_NETWORK_POLICY && hasPerm(Permission.CiliumEnabled)) ||
    hasPerm(Permission.IstioEnabled);
  const showYamlButton = !url && !!resource.ref && clickable,
    showLink = !!url && clickable;

  return (
    <GridStyles.Card
      data-testid='resources-landing-resource-card'
      aria-label={`Resource Card: ${resource.ref?.name ?? 'Unknown Resource Name'}`}>
      <GridStyles.CardHeader>
        <HealthIndicatorWithName
          name={resource.ref?.name ?? 'Unknown Resource Name'}
          status={resource.status}
        />
      </GridStyles.CardHeader>
      <GridStyles.CardContent>
        <SecondaryInfo items={secondaryInfoList} small={true} />
      </GridStyles.CardContent>
      <GridStyles.CardFooter>
        <SvgWithTextContainer>{usefulResourceTypeInfo.name}</SvgWithTextContainer>
        {showYamlButton ? (
          <SoloLinkStyles.SoloLinkLooksButton onClick={showYaml} withArrow>
            {!!yamlResource ? 'Hide' : 'View'} YAML
          </SoloLinkStyles.SoloLinkLooksButton>
        ) : showLink ? (
          <SoloLink link={url} withArrow>
            View Details
          </SoloLink>
        ) : undefined}
      </GridStyles.CardFooter>

      {!!yamlResource && <YamlModal yamlResource={yamlResource} onClose={() => setYamlResource(undefined)} />}
    </GridStyles.Card>
  );
};
