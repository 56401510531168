import { Tab, TabPanelProps } from '@reach/tabs';
import { TabsStyles as Styles } from './Tabs.style';

const { FolderTabList, SubpageTabs, CardFolderTabList, CardFolderTabListWithBottomBorder } = Styles;
export { CardFolderTabList, CardFolderTabListWithBottomBorder, FolderTabList, SubpageTabs };

interface FolderTabProps extends TabPanelProps {
  hidden?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  width?: string;
  onClick?: () => void;
}
export const FolderTab = ({ children, isSelected, disabled, ...rest }: FolderTabProps) => {
  return (
    <Styles.FolderTabHolder isSelected={isSelected}>
      <Tab {...rest} disabled={!!disabled}>
        {children}
      </Tab>
    </Styles.FolderTabHolder>
  );
};

export const SubpageTab = ({ isSelected, children, ...rest }: FolderTabProps) => {
  return (
    <Styles.SubpageTabHolder isSelected={isSelected}>
      <Tab {...rest}>{children}</Tab>
    </Styles.SubpageTabHolder>
  );
};

export const CardFolderTab = ({ isSelected, hidden, children, onClick, width, ...rest }: FolderTabProps) => {
  return (
    <Styles.CardFolderTabHolder width={width} onClick={onClick} hidden={hidden} isSelected={isSelected}>
      <Tab {...rest}>{children}</Tab>
    </Styles.CardFolderTabHolder>
  );
};
