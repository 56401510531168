import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { GetClusterDetailsCardsResponse } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ClusterGlooHealthListCard } from './ClusterGlooHealthListCard';
import { ClusterGlooVersionsListCard } from './ClusterGlooVersionsListCard';

export const ClusterGlooSummaryTabContent = ({
  data,
  cluster
}: {
  data: GetClusterDetailsCardsResponse;
  cluster: string;
}) => {
  return (
    <GridLayout templateColumns='1fr 1fr' gap='16px' pt={5}>
      <ClusterGlooHealthListCard cluster={cluster} />
      <ClusterGlooVersionsListCard cluster={cluster} />
    </GridLayout>
  );
};
