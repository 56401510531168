/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/tcp_matchers.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Specify TCP request matchers. Matchers are criteria such as a port to match with an incoming request.
 *
 * @generated from protobuf message common.gloo.solo.io.TCPRequestMatcher
 */
export interface TCPRequestMatcher {
    /**
     * Specify the port on the host defined in the route table to match with incoming TCP requests.
     *
     * +kubebuilder:validation:Minimum=0
     * +kubebuilder:validation:Maximum=65535
     *
     * @generated from protobuf field: uint32 port = 1;
     */
    port: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class TCPRequestMatcher$Type extends MessageType<TCPRequestMatcher> {
    constructor() {
        super("common.gloo.solo.io.TCPRequestMatcher", [
            { no: 1, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.TCPRequestMatcher
 */
export const TCPRequestMatcher = new TCPRequestMatcher$Type();
