import { CardStyles } from 'Components/Common/Card';
import { Loading } from 'Components/Common/Loading';
import { HealthCountBoxStyles } from 'Components/Common/Overview/HealthCountBox.style';
import { SoloLink } from 'Components/Common/SoloLink';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import {
  Insight,
  Insight_Severity
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { SECONDS_IN_DAY } from 'utils/helpers';
import { buildCertificatesUrl } from 'utils/url-builders';
import { DashboardStyles } from '../../DashboardLanding.style';
import { CertExpiryStyles } from './CertificatesExpiryCard.style';
import CertificatesExpiryCardContent from './CertificatesExpiryCardContent';

const now = Date.now() / 1000;
const rootCertInsights: Insight[] = [
  {
    id: 'R3',
    severity: Insight_Severity.INFO,
    lastSourceExecId: '',
    summary: '',
    details: '',
    system: false,
    data: {
      data: {
        oneofKind: 'sYS0007',
        sYS0007: {
          hostname: 'R3',
          authorityKeyIdentifier: '',
          fingerprints: [],
          issuedFromCn: '',
          issuedFromOrganization: [],
          issuedFromOrganizationUnit: [],
          issuedToCn: '',
          issuedToOrganization: [],
          issuedToOrganizationUnit: [],
          subjectKeyIdentifier: '',
          issuedOn: { seconds: now - 2000 * SECONDS_IN_DAY, nanos: 0 },
          expiresOn: { seconds: now + 532 * SECONDS_IN_DAY, nanos: 0 }
        }
      }
    }
  }
];
const intermediateCertInsights = [
  { perc: 0.05 },
  { perc: 0.2 },
  { perc: 0.34 },
  { perc: 0.38 },
  { perc: 0.4 },
  { perc: 0.52 },
  { perc: 0.58 },
  { perc: 0.66 },
  { perc: 0.78 },
  { perc: 0.85 },
  { perc: 0.88 }
].map<Insight>(({ perc }) => ({
  id: '',
  severity: Insight_Severity.INFO,
  lastSourceExecId: '',
  summary: '',
  details: '',
  system: false,
  data: {
    data: {
      oneofKind: 'sYS0008',
      sYS0008: {
        hostname: 'R3',
        authorityKeyIdentifier: '',
        fingerprints: [],
        issuedFromCn: '',
        issuedFromOrganization: [],
        issuedFromOrganizationUnit: [],
        issuedToCn: '',
        issuedToOrganization: [],
        issuedToOrganizationUnit: [],
        subjectKeyIdentifier: '',
        issuedOn: { seconds: now * SECONDS_IN_DAY, nanos: 0 },
        expiresOn: { seconds: now * perc * 100 * SECONDS_IN_DAY, nanos: 0 }
      }
    }
  }
}));

export const CertificatesExpiryCardFakeData = () => {
  const totalCertsCount = (rootCertInsights?.length ?? 0) + (intermediateCertInsights?.length ?? 0) + 2; // 2 being gateways
  const isLoading = false;
  return (
    <CardStyles.Card data-testid='certs-expiry-card'>
      <DashboardStyles.Header>
        <Svg asset={<Asset.CertExpiryIcon />} color={theme => theme.neptuneBlue} />
        Certificates Expiry
      </DashboardStyles.Header>
      {isLoading ? (
        <Loading />
      ) : (
        <CertExpiryStyles.CardContentAndFooterContainer>
          <CertificatesExpiryCardContent
            rootCertInsights={rootCertInsights}
            intermediateCertInsights={intermediateCertInsights}
          />
          <CertExpiryStyles.CardFooterContainer>
            Total Certificates <HealthCountBoxStyles.CountDisplay>{totalCertsCount}</HealthCountBoxStyles.CountDisplay>
            <DashboardStyles.CardBottomLink>
              <SoloLink small link={buildCertificatesUrl()} withArrow>
                DETAILS
              </SoloLink>
            </DashboardStyles.CardBottomLink>
          </CertExpiryStyles.CardFooterContainer>
        </CertExpiryStyles.CardContentAndFooterContainer>
      )}
    </CardStyles.Card>
  );
};
