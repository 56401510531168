import { CardStyles } from 'Components/Common/Card';
import { Svg } from 'Components/Common/Svg';
import { Asset } from 'assets';
import { GetDashboardCardsResponse_ZeroTrustOverview } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { DashboardStyles } from '../../DashboardLanding.style';
import ZeroTrustCardContent from './ZeroTrustCardContent';

const zeroTrustOverview: GetDashboardCardsResponse_ZeroTrustOverview = {
  workloadsReceivingInsecureTraffic: 101,
  mtlsEncryptedWorkloads: 6,
  totalWorkloads: 6,
  externalServicesAccessed: 23
};

export const ZeroTrustCardFakeData = () => {
  return (
    <CardStyles.Card data-testid='zero-trust-card'>
      <DashboardStyles.CardColumn>
        <DashboardStyles.Header>
          <Svg asset={<Asset.ZeroTrustIcon />} color={theme => theme.neptuneBlue} />
          Zero Trust
        </DashboardStyles.Header>
        <ZeroTrustCardContent zeroTrustOverview={zeroTrustOverview} timeInterval={900} />
      </DashboardStyles.CardColumn>
    </CardStyles.Card>
  );
};
