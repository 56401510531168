import OutlinedPlayCircleIcon from '@patternfly/react-icons/dist/esm/icons/outlined-play-circle-icon';
import { Svg } from 'Components/Common/Svg';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Button } from 'antd';

const SoloLogViewerFooterButton = ({
  isVisible,
  onResume,
  linesBehind
}: {
  isVisible: boolean;
  onResume: () => void;
  linesBehind: number;
}) => {
  //
  // Render
  //
  if (!isVisible) {
    return null;
  }
  return (
    <Button onClick={onResume} block>
      <FlexLayout justifyContent='center' alignItems='center' gap={1}>
        <Svg width={20} color={theme => theme.oceanBlue} asset={<OutlinedPlayCircleIcon />} />
        Resume {linesBehind === 0 ? null : `and show ${linesBehind} lines`}
      </FlexLayout>
    </Button>
  );
};

export default SoloLogViewerFooterButton;
