import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { getGraphItemColors } from 'Styles/graph';
import { GraphMetricsType, GraphStatus } from '../graph-selection-utils';

export namespace NodeDetailsStyles {
  export const TitleRow = styled.div`
    display: grid;
    grid-template-columns: 26px 1fr auto;
    gap: 2px 0;
    margin-bottom: 10px;

    > div:first-of-type {
      grid-column: span 2;
    }
  `;
  export const NodeTitle = styled.div(
    ({ theme }) => css`
      position: relative;
      display: flex;
      align-items: center;
      color: ${theme.neptuneBlue};
      cursor: pointer;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
    `
  );
  export const NodeTitleIconHolder = styled.span(
    ({ theme }) => css`
      display: flex;
      align-items: center;

      ${svgBasics(20, theme.seaBlue)}
      svg {
        margin-right: 6px;
      }
    `
  );
  // export const ModalPopIconStyled = styled(Asset.ExternalLinkIcon)`
  //   width: 11px !important;
  //   height: 11px !important;
  //   position: relative;
  //   top: -4px;

  //   * {
  //     fill: ${theme.seaBlue};
  //   }
  // `;
  export const TruncateText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
  export const NodeTitleStatusHolder = styled.span`
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: flex-end;
  `;

  interface EdgeArrowHolderProps {
    flip?: boolean;
    status: GraphStatus;
    metricsType: GraphMetricsType;
  }
  export const EdgeArrowHolder = styled.div<EdgeArrowHolderProps>(
    ({ status, metricsType, flip }) => css`
      display: flex;
      align-items: center;

      ${svgBasics(20, getGraphItemColors(metricsType, status).edge)}
      svg {
        ${flip
          ? css`
              transform: scaleX(-1);
            `
          : ''}
      }
    `
  );
}
