import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Asset } from 'assets';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { svgBasics } from 'Styles/CommonEmotions/utils';
import { graphZIndex } from 'Styles/graph';
import { usageInfoTabWidth } from '../General/UsageInfoTab/UsageInfoTab.style';

export namespace NetworkGraphContainerStyles {
  interface EnabledProps {
    enabled: boolean;
  }

  export const SizeLimiter = styled.div`
    overflow: hidden;
    max-height: calc(100vh - 330px);
  `;

  export const InnerContainer = styled.div`
    position: relative;
    max-height: calc(100vh - 330px);
    height: 100vh;
  `;

  export const ContainerWrapper = styled.div(
    ({ theme }) => css`
      .fullscreen-enabled {
        .sizeLimiter {
          max-height: 100vh;
          height: 100vh;

          .innerContainer {
            max-height: 100vh;
          }
        }

        .usageInfoTabSwivel {
          border-top: 1px solid ${theme.aprilGrey};

          .tabsHolder {
            top: 200px;
          }
        }
      }
    `
  );

  export const InjectedHeaderActions = styled.div`
    position: absolute;
    top: 0;
    right: 20px;
    height: 59px;
    display: flex;
    align-items: center;
  `;

  export const GraphHolder = styled.div``;

  export const GraphDataWrapper = styled.div`
    position: relative;
    max-height: calc(100% - 50px);
    height: 100%;
  `;

  export const GraphFooter = styled.div(
    ({ theme }) => css`
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 0;
      width: 100%;
      height: 50px;
      padding: 0 10px;
      color: ${theme.septemberGrey};
      background: ${theme.marchGrey};
      border-radius: 0 0 4px 4px;
      gap: 10px;
      z-index: ${graphZIndex.footer};

      .fullscreen-enabled & {
        border-radius: 0;
      }
    `
  );

  export const ControlButton = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: none;
      padding: 4px;
      cursor: pointer;
      font-weight: 400;
      height: 40px;

      &:hover,
      &:focus,
      &:active {
        background: ${theme.marchGrey};
      }
    `
  );

  interface CBttnActivateableProps {
    active: boolean;
  }
  export const ControlButtonActivateable = styled.div<CBttnActivateableProps>(
    ({ active, theme }) => css`
      display: flex;
      align-items: center;
      cursor: pointer;
      ${active
        ? `color: ${theme.seaBlue};`
        : css`
            :hover {
              filter: drop-shadow(0 0 2px ${theme.juneGrey});
            }
          `}
    `
  );

  export const ControlsVr = styled.span`
    display: block;
    align-self: center;
    height: 16px;
    border-left: 1px solid currentColor;
  `;

  export const ControlsBox = styled.div<EnabledProps>(
    ({ enabled, theme }) => css`
      display: flex;
      align-items: flex-end;
      gap: 4px;

      ${!enabled ? `opacity:0.5; pointer-events:none;` : ''}

      ${ControlButton} {
        width: 40px;

        ${svgBasics({ width: 24 }, 'currentColor')}
        :hover svg {
          filter: drop-shadow(0 0 2px ${theme.juneGrey});
        }
      }
    `
  );

  export const LayoutControls = styled.div<EnabledProps>(
    ({ enabled, theme }) => css`
      display: flex;
      align-items: flex-end;
      gap: 20px;
      margin-left: 7px;

      ${!enabled ? `opacity:0.5; pointer-events:none;` : ''}

      ${ControlButtonActivateable} {
        span {
          margin-top: 5px;
        }
        ${svgBasics({ width: 24 }, 'currentColor')}
        svg {
          margin-right: 4px;
        }
      }
    `
  );

  export const RightControls = styled.div(
    ({ theme }) => css`
      flex: 1;
      display: flex;
      justify-content: flex-end;

      gap: 8px;
      z-index: 2;

      ${ControlsVr} {
        color: ${theme.aprilGrey};
      }
    `
  );

  export const ControlButtonLink = styled(ControlButton)(
    ({ theme }) => css`
      ${SoloLinkStyles.SoloLinkLooks} {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        ${svgBasics(22, theme.septemberGrey)}
        &:hover svg {
          opacity: 0.8;
        }
      }
    `
  );

  export const LayoutSettingsIconStyled = styled(Asset.ApplicationsIcon)(
    ({ theme }) => css`
      * {
      }
    `
  );

  export const Arrows = styled.div`
    position: absolute;
    left: 5px;
    bottom: 60px;

    background: transparent !important;
    display: grid !important;
    grid-template-columns: 20px 20px 20px;
    grid-template-rows: 20px 20px 20px;
    grid-template-areas:
      '. up .'
      'left  . right'
      '. down  .';
    padding: 0 !important;
    width: auto !important;
    height: auto !important;
    z-index: 1;
  `;

  interface DirectionArrowProps {
    dir: 'up' | 'down' | 'left' | 'right';
  }
  export const DirectionArrow = styled.span<DirectionArrowProps>(
    ({ dir, theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: ${theme.marchGrey};
      margin-right: 8px;
      padding: 4px;
      cursor: pointer;
      font-weight: bold;
      width: 100%;
      height: 100%;

      grid-area: ${dir};
      transform: rotate(${{ up: 180, left: 90, right: -90, down: 0 }[dir]}deg);

      &:hover {
        background: ${theme.aprilGrey};
      }

      svg {
        width: 12px;
      }
    `
  );

  interface TimeContainerProps {
    movedForInfoTab: boolean;
  }
  export const TimeContainer = styled.div<TimeContainerProps>(
    ({ theme, movedForInfoTab }) => css`
      position: absolute;
      top: 10px;
      left: ${10 + (movedForInfoTab ? usageInfoTabWidth : 0)}px;
      background: ${theme.januaryGrey};
      border-radius: 15px;
      padding: 3px 15px;
      z-index: 2;

      transition: left 0.3s ease-out;
    `
  );

  export const GraphLoadingHolder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: ${graphZIndex.canvasOverlay};
    background: white;
  `;

  export const SmallCornerLoadingHolder = styled.div`
    position: absolute;
    top: 40px;
    left: 15px;
    z-index: ${graphZIndex.canvasOverlay};
  `;

  export const ErrorNotificationIconHolder = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: ${graphZIndex.canvasOverlay};
    animation: jiggle 0.5s; /* Jiggle once when it appears */

    svg {
      width: 20px;
    }

    /* https://www.codespeedy.com/make-an-image-jiggle-using-css/ */
    @keyframes jiggle {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-1deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(1deg);
      }
      30% {
        transform: translate(3px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(3px, 1px) rotate(-1deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(1deg);
      }
      90% {
        transform: translate(1px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }
  `;

  export const ErrorModalBodyContainer = styled.div`
    padding: 20px;

    h2 {
      font-size: 22px;
      margin-bottom: 10px;
    }
  `;
}
