import { graphApi } from 'Api/graphs';
import { Asset } from 'assets';
import { CardStyles } from 'Components/Common/Card';
import { FindOrHideSearchBox } from 'Components/Common/Input/FindOrHideSearchBox';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useInterval } from 'utils/hooks';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { useGraphFetchSettingsContext } from '../Context/GraphFetchSettingsContext';
import { useGraphUXSettingsContext } from '../Context/GraphUXSettingsContext';
import { useGetGraphCache } from '../General/graph-cache';
import { GraphFiltersType, hasEnoughFilters, useGraphWorkspacesUsable } from '../General/graph-filter-utils';
import { GraphFetchSettings } from './GraphFetchSettings';
import { GraphFilters } from './GraphFilters';
import { GraphLandingStyles } from './GraphLanding.style';
import { NetworkGraphContainer } from './NetworkGraphContainer';
import { NoValidGraphContent } from './NoValidGraphContent';

const { useGetFilters, useGetGraph } = graphApi;

export const GraphLanding = () => {
  di(useGetFilters, useGetGraph, hasEnoughFilters);
  usePermissionGateRedirect(Permission.GraphEnabled);
  const workspacesUsable = useGraphWorkspacesUsable();

  const { findName, hideName, setFindName, setHideName } = useGraphUXSettingsContext();
  const { fetchSettings, refreshRate, refreshGraphData, toggleRefreshPause } = useGraphFetchSettingsContext();
  const [cachedGraphDataError, setCachedGraphDataError] = useState<any>();

  const { cache } = useGetGraphCache();
  const { workspaceFilters = [], namespaceFilters = [], clusterFilters = [] } = cache;
  const [filters, setFilters] = useState<GraphFiltersType>({
    findName,
    hideName,
    namespaces: namespaceFilters,
    clusters: clusterFilters,
    workspaces: workspaceFilters
  });

  // This is strictly used to short circuit the display
  //   when there are no workspaces to work with.
  const { data: graphFilters } = useGetFilters();

  const {
    data: graphData,
    error: graphDataError,
    mutate: manualGraphMutate,
    isValidating: graphDataIsValidating,
    abort: graphRequestAbort
  } = useGetGraph(
    namespaceFilters,
    clusterFilters,
    workspacesUsable ? workspaceFilters : [],
    undefined,
    fetchSettings.endTime,
    fetchSettings.window,
    fetchSettings.step,
    fetchSettings.istioMetrics,
    fetchSettings.ciliumMetrics,
    fetchSettings.tcpMetrics
  );

  // Keep track of error between loading retries to avoid flickering, and clear once we have graph data
  useEffect(() => {
    if (graphDataError) {
      setCachedGraphDataError(graphDataError);
    } else if (!!graphData) {
      setCachedGraphDataError(undefined);
    }
  }, [graphDataError, graphData]);

  useInterval(() => doRefresh(), refreshRate);

  const doRefresh = () => refreshGraphData(manualGraphMutate);
  const doPauseRefresh = (on: boolean) => toggleRefreshPause(on, manualGraphMutate);

  useEffect(() => {
    setFilters({
      findName,
      hideName,
      namespaces: namespaceFilters,
      clusters: clusterFilters,
      workspaces: workspaceFilters
    });
  }, [findName, hideName, namespaceFilters, clusterFilters, workspaceFilters]);

  const filterableContentExists = !!graphFilters?.clusters.length && !!graphFilters.namespaces.length;

  return (
    <GraphLandingStyles.LandingContainer data-testid='graph-landing'>
      <CardStyles.TitleContainer>
        <div>
          <CardStyles.TitleIconContainer>
            <Asset.GraphIcon />
          </CardStyles.TitleIconContainer>
          <GraphLandingStyles.TitleWord>Graph</GraphLandingStyles.TitleWord>
          {filterableContentExists && (
            <div data-testid='graph-filter-by-name-block'>
              <FindOrHideSearchBox
                findName={findName}
                setFindName={setFindName}
                hideName={hideName}
                setHideName={setHideName}
              />
            </div>
          )}
        </div>

        {filterableContentExists && (
          <GraphFetchSettings
            hasEnoughFilters={hasEnoughFilters(filters)}
            isGraphRefreshing={!graphData || graphDataIsValidating}
            doRefresh={doRefresh}
          />
        )}
      </CardStyles.TitleContainer>

      {filterableContentExists ? (
        <GraphLandingStyles.GraphContainer>
          <GraphFilters graphRequestAbort={graphRequestAbort} />
          <NetworkGraphContainer
            graphData={graphData}
            filters={filters}
            doPauseRefresh={doPauseRefresh}
            graphDataError={cachedGraphDataError}
            isLoading={!graphDataError && !graphData}
          />
        </GraphLandingStyles.GraphContainer>
      ) : (
        <NoValidGraphContent />
      )}
    </GraphLandingStyles.LandingContainer>
  );
};
