import { css } from '@emotion/react';
import '@reach/tabs/styles.css';
import { colors, healthColors, hexAddAlpha } from '../colors';

export const antdGlobalStyles = css`
  body {
    .ant-modal-content {
      border-radius: 10px;
      box-shadow: hsla(0, 0%, 0%, 0.1) 0 4px 9px;

      .ant-modal-title {
        font-size: 24px;
        line-height: 26px;
      }
    }

    .ant-popover {
      .ant-popover-content {
        min-width: 125px;

        .ant-popover-message-title {
          color: white;
        }
        .ant-popover-inner {
          background: ${colors.novemberGrey};
          border-radius: 2px;

          .ant-popover-inner-content {
            color: white;
          }
        }
        .ant-popover-arrow {
          border-color: ${colors.novemberGrey};
        }
      }
    }

    .ant-tooltip {
      max-width: 400px;
    }

    .ant-select-dropdown {
      width: auto !important;
      z-index: 9;
    }

    .ant-tree-select-dropdown {
      padding: 8px 12px;

      .ant-select-tree-switcher {
        display: none !important;
      }

      .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner,
      .ant-select-tree-checkbox-inner {
        border: 1px solid ${colors.aprilGrey};
        border-radius: 2px;
        width: 18px;
        height: 18px;

        &:after {
          display: block;
          border-radius: 2px;
        }
        &:before {
          position: absolute;
          left: 43%;
          top: 52%;
          display: block;
          height: 5px;
          width: 2px;
          transform: rotate(-45deg) scale(1) translate(-50%, -50%);
          opacity: 0;
          transition: all 1s, opacity 0.1s;
          border: none;
          border-radius: 2px;
          background: white;
          content: ' ';
        }
      }
      .ant-select-tree-checkbox-checked,
      .ant-select-tree-checkbox-indeterminate {
        .ant-select-tree-checkbox-inner {
          border: 1px solid ${colors.seaBlue};
          background-color: ${colors.seaBlue};

          &:after {
            border: none;
            background: white;
            height: 9px;
            width: 2px;
            left: 39%;
            top: 48%;
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
          }
          &:before {
            opacity: 1;
          }
        }
      }

      .ant-select-tree-checkbox-indeterminate {
        .ant-select-tree-checkbox-inner {
          &:after {
            left: 20%;
            top: 30%;
            transform: rotate(90deg) scale(1) translate(-50%, -50%);
          }
          &:before {
            display: none;
          }
        }
      }
    }

    ul.ant-select-tree {
      width: 100%;
      /* margin-bottom: 15px; */
      line-height: 16px;
      padding: 0;

      .anticon-caret-down {
        visibility: hidden;
      }

      .treeIcon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background: ${colors.januaryGrey};

        svg {
          max-height: 14px;
          width: 14px;
        }
      }

      > li > ul {
        /*padding-left: 15px !important;*/
      }

      li {
        span.ant-select-tree-switcher {
          display: none;
        }

        .ant-select-tree-node-content-wrapper {
          width: calc(100%);
        }

        &.ant-select-tree-treenode-switcher-open > span {
          color: ${colors.juneGrey};
          cursor: default;
          background: white;
          padding: 0 5px !important;
          line-height: 18px;

          &:hover {
            background: white;
          }
        }
      }

      .ant-select-tree-node-content-wrapper {
        &.ant-select-tree-node-content-wrapper-open {
          + ul {
            padding: 0;
          }
        }

        &.ant-select-tree-node-content-wrapper-normal {
          padding-left: 20px !important;

          &:hover {
            background: ${colors.splashBlue};
          }
        }

        &.ant-select-tree-node-content-wrapper-normal {
          padding-left: 20px !important;
          color: ${colors.novemberGrey};
          cursor: pointer;

          &:hover {
            background: ${colors.splashBlue};
          }

          &.ant-select-tree-node-selected {
            cursor: default;
            background: ${colors.januaryGrey};

            padding: 9px 15px 9px 11px;
            border: none;
            border-radius: 0;
            height: auto;
            outline: none;

            .ant-select-tree-node-selected__rendered {
              line-height: inherit;
              margin: 0;

              .ant-select-tree-node-selected-selected-value {
                color: ${colors.septemberGrey};
              }
            }

            &:disabled {
              background: ${colors.aprilGrey};
            }
          }
        }
      }
    }

    .soloTooltip {
      border: none;
      background: transparent;

      .ant-tooltip-inner {
        padding: 0;
        box-shadow: none;
        background: transparent;
      }

      &.errorColors {
        color: ${healthColors.error.dark};

        .ant-tooltip-arrow-content {
          background-color: ${healthColors.error.dark};
        }
      }
    }

    // Rotates the ant design dropdown arrow
    // for <Select> and <Dropdown> antd components.
    .ant-select.ant-select-show-arrow,
    .ant-dropdown-trigger {
      &.ant-select-open,
      &.ant-dropdown-open {
        .ant-select-arrow svg,
        .anticon-down svg {
          transform: rotate(180deg);
        }
      }
      .ant-select-arrow svg,
      .anticon-down svg {
        transform: rotate(0);
        transition: transform 0.3s;
      }
    }

    .ant-tooltip {
      max-width: 500px;
    }
  }

  .ant-tooltip-content {
    pre {
      padding: 0.5rem;
    }
    code {
      padding: 0.25rem;
    }
    pre,
    code {
      font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
      margin: 0.5rem 0px;
      border-radius: 2px;
      font-size: 0.8rem;
      background-color: ${hexAddAlpha(colors.juneGrey, 0.1)};
      vertical-align: text-bottom;
    }
  }

  div.ant-dropdown:has(ul.ant-dropdown-menu:focus-visible),
  div.ant-dropdown:has(li.ant-dropdown-menu-item:focus-visible),
  div.ant-dropdown:has(li.ant-dropdown-menu-item input:focus-visible) {
    outline: 2px solid ${colors.lakeBlue};
    border-radius: 6px;
  }
`;
