/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/enforcement_layers.proto" (package "common.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message common.gloo.solo.io.EnforcementLayers
 */
export interface EnforcementLayers {
    /**
     * @generated from protobuf field: bool cni = 1;
     */
    cni: boolean;
    /**
     * @generated from protobuf field: bool mesh = 2;
     */
    mesh: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class EnforcementLayers$Type extends MessageType<EnforcementLayers> {
    constructor() {
        super("common.gloo.solo.io.EnforcementLayers", [
            { no: 1, name: "cni", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "mesh", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message common.gloo.solo.io.EnforcementLayers
 */
export const EnforcementLayers = new EnforcementLayers$Type();
