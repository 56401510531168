import { GqlLandingContext } from 'Components/Features/Apis/Graphql/context/GqlLandingContext';
import { Kind, ScalarTypeDefinitionNode } from 'graphql';
import { useContext } from 'react';
import { GqlSchemaTabItemProps } from 'utils/graphql-schema-search-helpers';
import GqlTabItemBreadcrumb from '../GqlTabItemBreadcrumb';
import GqlTabItemDescription from '../GqlTabItemDescription';
import GqlTabItemDirectiveList from '../GqlTabItemDirectivesList';

const GqlScalarTabItem = ({
  onTypeClick,
  focusedItem,
  tabHeader,
  focusedPath
}: GqlSchemaTabItemProps<ScalarTypeDefinitionNode>) => {
  const gqlctx = useContext(GqlLandingContext);
  const { getExtensionInfo } = gqlctx;
  const focusedItemExtensions = getExtensionInfo(Kind.SCALAR_TYPE_EXTENSION, focusedItem.name.value);
  //
  // Render
  //
  return (
    <>
      <GqlTabItemBreadcrumb tabHeader={tabHeader} focusedPath={focusedPath} onTypeClick={onTypeClick} />
      <GqlTabItemDirectiveList
        node={focusedItem}
        extendedDirectives={focusedItemExtensions?.directives}
        onTypeClick={onTypeClick}
      />
      <GqlTabItemDescription node={focusedItem} />
    </>
  );
};

export default GqlScalarTabItem;
