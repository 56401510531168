import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tag, TreeSelect } from 'antd';
import { SoloButtonSolid } from 'Styles/CommonEmotions/button';

export namespace SoloMultiselectStyles {
  interface MaxWidthProps {
    maxWidth?: string;
  }

  export const SelectWrap = styled.div<MaxWidthProps>(
    ({ maxWidth }) => css`
      /* the selected items */
      div div div div {
        max-width: ${maxWidth || '100%'};
      }
      width: 100%;
    `
  );

  export const ButtonWrap = styled.div`
    padding: 5px 0;
    display: flex;
    justify-content: flex-end;
  `;

  export const StyledTag = styled(Tag)`
    max-width: 95%;
    overflow: hidden;

    margin: 0;

    .anticon-close {
      margin-left: 0 !important;
    }
  `;

  export const SelectedItem = styled.span<MaxWidthProps>(
    ({ maxWidth }) => css`
      max-width: ${maxWidth || '95%'};
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    `
  );

  export const UtilityButton = styled(SoloButtonSolid)`
    border-radius: 8px;
    font-size: 13px;
    padding: 2px 5px;
    min-width: initial;
    margin-right: 10px;
    &:last-of-type {
      margin: 0;
    }
  `;

  type TreeDropdownBlockAdditionalProps = {
    multiplePlaceholder?: string;
  };
  export const TreeDropdownBlock = styled(TreeSelect, {
    shouldForwardProp: prop => !['multiplePlaceholder'].includes(prop)
  })<TreeDropdownBlockAdditionalProps>(
    ({ theme, multiplePlaceholder }) => css`
      position: relative;
      width: inherit;
      line-height: 16px;
      border-radius: 3px;
      font-size: 16px;

      &.ant-select {
        .ant-select-selector {
          border-radius: 3px;
          border: 1px solid ${theme.aprilGrey} !important;
          line-height: 16px;
          outline: none !important;
          height: auto;
          cursor: pointer;
          overflow: hidden;

          .ant-select-selection-search,
          .ant-select-selection-search input {
            outline: none !important;
            padding: 0;
            max-width: 118px;
          }
          .ant-select-selection-search input {
            min-width: 100px;

            ${multiplePlaceholder
              ? css`
                  &:-webkit-input-placeholder,
                  &:-moz-placeholder,
                  &:-ms-input-placeholder,
                  &:-moz-placeholder {
                    position: absolute;
                    top: 50%;
                    right: 11px;
                    left: 11px;
                    width: calc(100% - 22px);
                    transform: translateY(-50%);
                    flex: 1;
                    overflow: hidden;
                    color: ${theme.juneGrey};
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    pointer-events: none;
                    content: '${multiplePlaceholder}';
                  }
                `
              : ''}
          }
          .ant-select-selection-placeholder {
            color: ${theme.juneGrey};
          }

          ${multiplePlaceholder
            ? css`
                .ant-select-selection-overflow {
                  .ant-select-selection-overflow-item {
                    display: block;

                    span {
                      display: none;
                    }

                    &:after {
                      display: block;
                      content: '${multiplePlaceholder}';
                      font-size: 16px;
                      line-height: 22px;
                      padding: 0 10px;
                      margin-right: 8px;
                      background: ${theme.marchGrey};
                    }
                  }

                  .ant-select-selection-overflow-item:nth-of-type(n + 2) {
                    display: none;
                  }

                  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
                    display: block;

                    &:after {
                      display: none;
                    }
                  }
                }
              `
            : ''}

          .ant-select-selection__rendered {
            line-height: inherit;
            margin: 0;

            .ant-select-selection-selected-value {
              color: ${theme.septemberGrey};
            }
          }

          &:disabled {
            background: ${theme.aprilGrey};
          }
        }

        .ant-select-clear {
          display: flex;
          opacity: 1;
          width: auto;

          &:before {
            content: 'clear';
            margin-right: 4px;
          }
        }
      }

      .ant-select-arrow {
        position: absolute;
        right: 10px;
        top: 17px;
      }
    `
  );
}
