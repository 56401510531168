import { gqlApi } from 'Api/graphql';
import { DataError } from 'Components/Common/DataError';
import { ErrorBoundary } from 'Components/Common/ErrorBoundary';
import { HealthNotificationBox } from 'Components/Common/HealthNotificationBox';
import { Loading } from 'Components/Common/Loading';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { getGraphqlApiType } from 'utils/graphql-helpers';
import { makeClusterObjectRef } from 'utils/helpers';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import GqlDetails from './Details/GqlDetails';
import GqlExplorerContainer from './Explorer/GqlExplorerContainer';
import { GqlLandingProvider } from './context/GqlLandingContext';

const { useGetGraphqlApi } = gqlApi;

export const GqlLanding = ({ display = 'details' }: { display?: 'details' | 'explorer' }) => {
  di(useParams, useGetGraphqlApi, usePermissionGateRedirect);
  usePermissionGateRedirect(Permission.GraphQLEnabled);
  // Get route + route table + gateway info from the URL.
  const params = useParams();
  const { routeTableName, routeTableNamespace, routeTableCluster, istioRouteName } = params;
  const routeTableRef = makeClusterObjectRef(routeTableName ?? '', routeTableNamespace ?? '', routeTableCluster ?? '')!;

  // Get API details and type.
  const { data: api, error: apiError } = useGetGraphqlApi(routeTableRef, istioRouteName ?? '');
  const apiType = useMemo(() => getGraphqlApiType(api), [api]);

  //
  // Render
  //
  const isLoading = api === undefined;
  if (!!apiError) return <DataError error={apiError} />;
  if (isLoading) return <Loading />;
  return (
    <>
      <Spacer mb={'20px'}>
        <HealthNotificationBox status={api.status} />
      </Spacer>
      {apiType !== undefined && (
        <ErrorBoundary
          fallback={
            !!api.status ? (
              <></>
            ) : (
              <DataError error={{ message: `There was an error with the GraphQL ${display} page.` }} />
            )
          }>
          <GqlLandingProvider
            api={api}
            apiType={apiType}
            routeTableRef={routeTableRef}
            istioRouteName={istioRouteName || ''}>
            {display === 'explorer' ? <GqlExplorerContainer /> : <GqlDetails />}
          </GqlLandingProvider>
        </ErrorBoundary>
      )}
    </>
  );
};
