import {
  GetEdgeMetricsRequest,
  GetFiltersRequest,
  GetFiltersResponse,
  GetGraphRequest,
  GetGraphResponse,
  GetServiceDetailsRequest,
  GetWorkloadMetricsRequest
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { GraphApiClient } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb.client';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { Duration } from 'proto/google/protobuf/duration_pb';
import { Timestamp } from 'proto/google/protobuf/timestamp_pb';
import { capitalizeFirstLetters } from 'utils/helpers';
import {
  grpcWebFetchTransport,
  soloGrpcCall,
  useAbortableRequest,
  useRequest,
  useRequestSkipRef
} from './helpers';
import { overviewApi } from './overview';
import { workspacesApi } from './workspaces';

// Set these to json graph/filter responses to override api and test custom data
let mockGraphData: GetGraphResponse | null = null; // require('./fake-graph/GetGraph.json') // require('./../test/graph-mock-data/three-trees/GetGraph.json')
let mockFiltersData: GetFiltersResponse | null = null; // require('./fake-graph/GetFilters.json') // require('./../test/graph-mock-data/three-trees/GetFilters.json')

export namespace graphApi {
  const client = new GraphApiClient(grpcWebFetchTransport);

  const getMethodDescriptorName = (fn: { name: string }) =>
    '/rpc.gloo.solo.io.GraphApi/' + capitalizeFirstLetters(fn.name);

  export function useGetFilters() {
    return useRequest(getFilters, [], {
      methodDescriptorName: getMethodDescriptorName(client.getFilters)
    });
  }

  export function useGetWorkloadMetrics(
    workloadId?: string,
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics?: boolean,
    ciliumMetrics?: boolean,
    tcpMetrics?: boolean
  ) {
    const key = !!workloadId
      ? [
          getMethodDescriptorName(client.getWorkloadMetrics),
          workloadId,
          window?.seconds,
          step?.seconds,
          istioMetrics,
          ciliumMetrics,
          tcpMetrics
          // Note: We purposefully DON'T want endTime in this key, as it creates
          // a new key every graph refresh, causing a new cache to form
        ]
      : null;
    return useRequest(
      getWorkloadMetrics,
      [workloadId, endTime, window, step, istioMetrics, ciliumMetrics, tcpMetrics],
      { key, methodDescriptorName: getMethodDescriptorName(client.getWorkloadMetrics) }
    );
  }

  export function useGetEdgeMetrics(
    sourceWorkloadId?: string,
    targetWorkloadId?: string,
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics?: boolean,
    ciliumMetrics?: boolean,
    tcpMetrics?: boolean
  ) {
    const key =
      !!sourceWorkloadId && !!targetWorkloadId
        ? [
            getMethodDescriptorName(client.getEdgeMetrics),
            sourceWorkloadId,
            targetWorkloadId,
            window?.seconds,
            step?.seconds,
            istioMetrics,
            ciliumMetrics,
            tcpMetrics
            // Note: We purposefully DON'T want endTime in this key, as it creates
            // a new key every graph refresh, causing a new cache to form
          ]
        : null;
    return useRequest(
      getEdgeMetrics,
      [sourceWorkloadId, targetWorkloadId, endTime, window, step, istioMetrics, ciliumMetrics, tcpMetrics],
      { key, methodDescriptorName: getMethodDescriptorName(client.getEdgeMetrics) }
    );
  }

  export function useGetGraph(
    namespaces: string[],
    clusters: string[],
    workspaces: string[],
    workloadName?: string,
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics?: boolean,
    ciliumMetrics?: boolean,
    tcpMetrics?: boolean
  ) {
    const key = [
      getMethodDescriptorName(client.getGraph),
      namespaces.sort().join('-'),
      clusters.sort().join('-'),
      workspaces.sort().join('-'),
      workloadName,
      window?.seconds,
      step?.seconds,
      istioMetrics,
      ciliumMetrics,
      tcpMetrics
      // Note: We purposefully DON'T want endTime in this key, as it creates
      // a new key every graph refresh, causing a new cache to form
    ];
    return useAbortableRequest(
      getGraph,
      [namespaces, clusters, workspaces, workloadName, endTime, window, step, istioMetrics, ciliumMetrics, tcpMetrics],
      { key, methodDescriptorName: getMethodDescriptorName(client.getGraph) },
      // We don't want graph refreshing by itself - we manually trigger a new refresh via mutate
      { refreshInterval: 0 }
    );
  }

  export async function getWorkspaceNamespacingMap() {
    try {
      const workspacesResponse = await overviewApi.listOverviewWorkspaces();

      let workspacesRefs = workspacesResponse.workspaces.map(ws => ws.workspaceRef!);

      const detailsArray = await Promise.all(
        workspacesRefs.map(wsRef => workspacesApi.getWorkspaceDetails(wsRef.name))
      );

      let namespaceFullIdsMap: { [key: string]: string } = {};
      detailsArray.forEach(details => {
        if (!!details.workspaceRef) {
          details.clusterNamespaces.forEach(clusterPlus => {
            clusterPlus.namespaces.forEach(ns => {
              namespaceFullIdsMap[`${ns}.${clusterPlus.cluster}`] = details.workspaceRef!.name;
            });
          });
        }
      });

      return namespaceFullIdsMap;
    } catch {
      throw Object.assign(new Error('Could not construct list of namespaces within workspaces'), { code: -3 });
    }
  }

  /******
   * DIRECT CALLS
   ******/

  export async function getFilters() {
    if (mockFiltersData) {
      // slight delay to simulate api load
      await new Promise(res => setTimeout(res, 500));
      return mockFiltersData;
    }
    let request: GetFiltersRequest = {};
    return soloGrpcCall(client.getFilters(request));
  }

  export async function getWorkloadMetrics(
    workloadId = '',
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics = true,
    ciliumMetrics = false,
    tcpMetrics = false
  ) {
    let request: GetWorkloadMetricsRequest = {
      id: workloadId,
      istioMetrics,
      ciliumMetrics,
      tcpMetrics,
      endTime,
      window,
      step
    };
    return soloGrpcCall(client.getWorkloadMetrics(request));
  }

  export async function getEdgeMetrics(
    sourceWorkloadId = '',
    targetWorkloadId = '',
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics = true,
    ciliumMetrics = false,
    tcpMetrics = false
  ) {
    let request: GetEdgeMetricsRequest = {
      sourceId: sourceWorkloadId,
      targetId: targetWorkloadId,
      istioMetrics,
      ciliumMetrics,
      tcpMetrics,
      endTime,
      window,
      step
    };
    return soloGrpcCall(client.getEdgeMetrics(request));
  }

  export async function getGraph(
    namespaces: string[],
    clusters: string[],
    workspaces: string[],
    workloadName = '',
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics = true,
    ciliumMetrics = false,
    tcpMetrics = false
  ) {
    if (mockGraphData) {
      // slight delay to simulate api load
      await new Promise(res => setTimeout(res, 500));
      return mockGraphData;
    }

    let request: GetGraphRequest = {
      namespaces,
      clusters,
      workspaces,
      workloadName,
      istioMetrics,
      ciliumMetrics,
      tcpMetrics,
      endTime,
      window,
      step
    };
    return soloGrpcCall(client.getGraph(request));
  }

  //
  // Get Service
  //
  export async function getServiceDetails(
    serviceRef?: ClusterObjectRef,
    endTime?: Timestamp,
    window?: Duration,
    step?: Duration,
    istioMetrics = true,
    ciliumMetrics = false,
    tcpMetrics = false
  ) {
    const request: GetServiceDetailsRequest = {
      serviceRef,
      istioMetrics,
      ciliumMetrics,
      tcpMetrics,
      endTime,
      window,
      step
    };
    return soloGrpcCall(client.getServiceDetails(request));
  }
  export function useGetServiceDetails(...args: Parameters<typeof getServiceDetails>) {
    return useRequestSkipRef(getServiceDetails, args, getMethodDescriptorName(client.getServiceDetails));
  }
}
