import { OpenAPIV3 } from 'openapi-types';
import { useMemo } from 'react';

interface ErrorProps {
  message: string;
}

function validateOpenApiV3(schema: OpenAPIV3.Document) {
  let supportedVersions = ['3.0.0', '3.0.1', '3.0.2', '3.0.3', '3.1.0'];

  // Verify that the parsed object is a Openapi API
  if (schema.openapi === undefined || schema.info === undefined) {
    throw new Error(`Schema is not a valid Openapi API definition`);
  } else if (schema.paths === undefined) {
    throw new Error(`Schema is not a valid Openapi API definition`);
  } else if (typeof schema.openapi !== 'string') {
    // This is a very common mistake, so give a helpful error message
    throw new Error('Openapi version number must be a string (e.g. "3.0.0").');
  } else if (typeof schema.info.version !== 'string') {
    // This is a very common mistake, so give a helpful error message
    throw new Error('API version number must be a string (e.g. "1.0.0").');
  } else if (supportedVersions.indexOf(schema.openapi) === -1) {
    throw new Error(
      `Unsupported OpenAPI version: ${schema.openapi}. ` +
        `Swagger Parser only supports versions ${supportedVersions.join(', ')}`
    );
  }
}

export const useParseOpenApiSchemaString = (schemaString: string | undefined) => {
  return useMemo(() => {
    if (!schemaString) return {};

    try {
      // While the user may give either a v2 or v3, the apiserver will always return back a OpenAPI v3
      const schema: OpenAPIV3.Document = JSON.parse(schemaString);
      if (schema.openapi) {
        validateOpenApiV3(schema);
      } else {
        throw new Error(`Swagger 2 found, expected OpenAPI 3`);
      }

      const returnObject = { schema };
      return { data: returnObject };
    } catch (err) {
      return { data: undefined, error: { message: (err as any).message } as ErrorProps };
    }
  }, [schemaString]);
};
