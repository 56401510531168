import {
  GetGraphResponse,
  NodeMetrics
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { isNonNullableAndNonFalse } from 'utils/helpers';
import { nodeMetricsToMap } from './get-graph-items-data';

export enum TreeType {
  workspace = 0,
  cluster,
  subnet
}
export type TreeNode = {
  id: string;
  workspace?: string;
  vpc?: string;
  cluster?: string;
  namespace?: string;
  subnet?: string;
  instance?: string;
  level: number;
  exteriorAdjuster: number;
};

type VPCTreeObject = {
  [key: string]: {
    subCategories: {
      [key: string]: {
        subSubcategories: string[];
      };
    };
  };
};
export type InfraBasedTree = {
  nodes: TreeNode[];
  vpcsSeen: VPCTreeObject;
};
export type WorkspaceBasedTree = {
  nodes: TreeNode[];
  workspacesSeen: string[];
};

function getInnerWorkspaceTree(
  data: GetGraphResponse,
  pNode: NodeMetrics,
  level: number,
  priorExteriorAdjuster: number,
  nodeIdsAlreadySeen: string[] // used to track cycles
): WorkspaceBasedTree {
  const id = pNode.workload?.id;
  if (!id) {
    return {
      nodes: [],
      workspacesSeen: []
    };
  }
  const exteriorAdjuster = priorExteriorAdjuster + (level === 0 ? 0.35 : 0);

  let allNodes: TreeNode[] = [
    {
      ...pNode.workload,
      id,
      level,
      exteriorAdjuster
    }
  ];
  let workspacesSeen: string[] = [pNode.workload!.workspace];

  const nodeMetricsMap = nodeMetricsToMap(data.nodeMetrics);
  const childrenEdges = data.edgeMetrics.filter(pEdge => pEdge.sourceWorkload?.id === id && !!pEdge.targetWorkload);
  childrenEdges
    .map((pEdge, ind) => {
      const targetId = pEdge.targetWorkload?.id;
      if (!targetId) {
        return undefined;
      }
      const targetNode = nodeMetricsMap[targetId];

      // If we've already visited that node on this
      //  path, then just give it as leaf child and stop there
      //  or if this is a faulty edge (eg, sometimes the api returns
      //  edges that cross clusters that weren't selected, though the nodes
      //  for the unselected cluster won't be there )
      if (nodeIdsAlreadySeen.includes(targetId) || !targetNode) {
        return undefined;
      } else {
        return targetNode;
      }
    })
    .filter(isNonNullableAndNonFalse)
    .forEach(targetNode => {
      const newWorkspace = targetNode.workload!.workspace !== pNode.workload!.workspace;

      const child = getInnerWorkspaceTree(data, targetNode, newWorkspace ? 0 : level + 1, exteriorAdjuster, [
        ...nodeIdsAlreadySeen,
        id
      ]);

      allNodes.push(...child.nodes);
      child.workspacesSeen.forEach(childWorkspace => {
        if (!workspacesSeen.includes(childWorkspace)) {
          workspacesSeen.push(childWorkspace);
        }
      });
    });

  let nodes: TreeNode[] = [];
  allNodes.forEach(node => {
    const repeatNode = nodes.find(n => n.id === node.id);
    if (!repeatNode) {
      nodes.push(node);
    } else {
      repeatNode.level = Math.max(node.level, repeatNode.level);
      repeatNode.exteriorAdjuster = Math.min(repeatNode.exteriorAdjuster, node.exteriorAdjuster);
    }
  });

  return {
    nodes,
    workspacesSeen
  };
}
export function getFullWorkspaceTree(data: GetGraphResponse): WorkspaceBasedTree {
  const roots = data.nodeMetrics
    // We don't want to assume something without incomingMetrics is a root, because we end up
    //  grabbing things like IDLE Nodes, .
    .filter(pNode => !data.edgeMetrics.some(pEdge => pEdge.targetWorkload?.id === pNode.workload?.id))
    .sort((rootA, rootB) => {
      if (rootA.workload && rootB.workload) {
        const workspaceComparison = rootA.workload.workspace
          .toLocaleLowerCase()
          .localeCompare(rootB.workload.workspace.toLocaleLowerCase());

        if (workspaceComparison !== 0) {
          return workspaceComparison;
        } else {
          return rootA.workload.id.toLocaleLowerCase().localeCompare(rootB.workload.id.toLocaleLowerCase());
        }
      } else {
        return 0;
      }
    });

  let allNodes: TreeNode[] = [];
  let allWorkspaces: string[] = [];
  roots.forEach(root => {
    const id = root.workload?.id;
    if (!!id) {
      const rootWorkload = root.workload!;

      allNodes.push({
        ...root.workload,
        id,
        level: 0,
        exteriorAdjuster: 0
      });
      if (!allWorkspaces.includes(rootWorkload.workspace)) {
        allWorkspaces.push(rootWorkload.workspace);
      }

      const nodeMetricsMap = nodeMetricsToMap(data.nodeMetrics);
      const childrenEdges = data.edgeMetrics.filter(pEdge => pEdge.sourceWorkload?.id === id && !!pEdge.targetWorkload);
      childrenEdges
        .map((pEdge, ind) => {
          const targetId = pEdge.targetWorkload?.id;
          const targetNode = nodeMetricsMap[targetId!];

          // If this is a faulty edge, then just give it as leaf child and stop there
          //  (eg, sometimes the api returns
          //  edges that cross clusters that weren't selected, though the nodes
          //  for the unselected cluster won't be there )
          if (!targetNode) {
            return undefined;
          } else {
            const newWorkspace = targetNode.workload?.workspace !== rootWorkload.workspace;

            const childBranch = getInnerWorkspaceTree(data, targetNode, newWorkspace ? 0 : 1, newWorkspace ? 0.35 : 0, [
              id
            ]);

            return childBranch;
          }
        })
        .filter((child): child is WorkspaceBasedTree => !!child)
        .forEach(innerBranch => {
          allNodes.push(...innerBranch.nodes);

          innerBranch.workspacesSeen.forEach(innerWorkspace => {
            if (!allWorkspaces.includes(innerWorkspace)) {
              allWorkspaces.push(innerWorkspace);
            }
          });
        });
    }
  });

  let nodes: TreeNode[] = [];
  allNodes.forEach(node => {
    const repeatNode = nodes.find(n => n.id === node.id);
    if (!repeatNode) {
      nodes.push(node);
    } else {
      repeatNode.level = Math.max(node.level, repeatNode.level);
      repeatNode.exteriorAdjuster = Math.min(repeatNode.exteriorAdjuster, node.exteriorAdjuster);
    }
  });
  nodes.sort((nodeA, nodeB) => nodeA.id.toLowerCase().localeCompare(nodeB.id.toLowerCase()));

  let workspacesSeen = allWorkspaces.sort();

  return {
    nodes,
    workspacesSeen
  };
}

/***********
 * INFRA SECTION
 ******************/
function getInnerInfraTree(
  data: GetGraphResponse,
  pNode: NodeMetrics,
  level: number,
  priorExteriorAdjuster: number,
  useClusterNS: boolean,
  nodeIdsAlreadySeen: string[] // used to track cycles
): InfraBasedTree {
  const id = pNode.workload?.id;
  if (!id) {
    return {
      nodes: [],
      vpcsSeen: {}
    };
  }
  const exteriorAdjuster = priorExteriorAdjuster + (level === 0 ? 0.7 : 0);

  let allNodes: TreeNode[] = [
    {
      ...pNode.workload,
      id,
      level,
      exteriorAdjuster
    }
  ];
  const subcategory = useClusterNS ? pNode.workload!.cluster : pNode.workload!.subnet;
  const subSubcategory = useClusterNS ? pNode.workload!.namespace : pNode.workload!.instance;

  let vpcsSeen: VPCTreeObject = {
    [pNode.workload!.vpc]: {
      subCategories: {
        [subcategory]: { subSubcategories: [subSubcategory] }
      }
    }
  };

  const nodeMetricsMap = nodeMetricsToMap(data.nodeMetrics);
  const childrenEdges = data.edgeMetrics.filter(pEdge => pEdge.sourceWorkload?.id === id && !!pEdge.targetWorkload);
  childrenEdges
    .map((pEdge, ind) => {
      const targetId = pEdge.targetWorkload?.id;
      if (!targetId) {
        return undefined;
      }
      const targetNode = nodeMetricsMap[targetId];

      // If we've already visited that node on this
      //  path, then just give it as leaf child and stop there
      //  or if this is a faulty edge (eg, sometimes the api returns
      //  edges that cross clusters that weren't selected, though the nodes
      //  for the unselected cluster won't be there )
      if (nodeIdsAlreadySeen.includes(targetId) || !targetNode) {
        return undefined;
      } else {
        return targetNode;
      }
    })
    .filter(isNonNullableAndNonFalse)
    .forEach(targetNode => {
      const newVpc = targetNode.workload!.vpc !== pNode.workload!.vpc;

      const child = getInnerInfraTree(data, targetNode, newVpc ? 0 : level + 1, exteriorAdjuster, useClusterNS, [
        ...nodeIdsAlreadySeen,
        id
      ]);

      allNodes.push(...child.nodes);

      Object.keys(child.vpcsSeen).forEach(vpcKey => {
        if (!!vpcsSeen[vpcKey]) {
          Object.keys(child.vpcsSeen[vpcKey].subCategories).forEach(subCKey => {
            if (!!vpcsSeen[vpcKey].subCategories[subCKey]) {
              child.vpcsSeen[vpcKey].subCategories[subCKey].subSubcategories.forEach(subSubc => {
                if (!vpcsSeen[vpcKey].subCategories[subCKey].subSubcategories.includes(subSubc)) {
                  vpcsSeen[vpcKey].subCategories[subCKey].subSubcategories.push(subSubc);
                }
              });
            } else {
              vpcsSeen[vpcKey].subCategories[subCKey] = {
                subSubcategories: [...child.vpcsSeen[vpcKey].subCategories[subCKey].subSubcategories]
              };
            }
          });
        } else {
          vpcsSeen[vpcKey] = { subCategories: { ...child.vpcsSeen[vpcKey].subCategories } };
        }
      });
    });

  let nodes: TreeNode[] = [];
  allNodes.forEach(node => {
    const repeatNode = nodes.find(n => n.id === node.id);
    if (!repeatNode) {
      nodes.push(node);
    } else {
      repeatNode.level = Math.max(node.level, repeatNode.level);
    }
  });

  return {
    nodes,
    vpcsSeen
  };
}

export function getFullInfraTree(data: GetGraphResponse): InfraBasedTree {
  const roots = data.nodeMetrics
    .filter(pNode => !pNode.incomingMetrics)
    .sort((rootA, rootB) => {
      if (!!rootA.workload && !!rootB.workload) {
        const vpcComparison = rootA.workload.vpc
          .toLocaleLowerCase()
          .localeCompare(rootB.workload.vpc.toLocaleLowerCase());

        if (vpcComparison !== 0) {
          return vpcComparison;
        } else {
          return rootA.workload.id.toLocaleLowerCase().localeCompare(rootB.workload.id.toLocaleLowerCase());
        }
      } else {
        return 0;
      }
    });

  let allNodes: TreeNode[] = [];
  let allVpcs: VPCTreeObject = {};
  const useClusterNS = true;

  roots.forEach(root => {
    const id = root.workload?.id;
    if (!!id) {
      const rootWorkload = root.workload!;

      allNodes.push({
        ...root.workload,
        id,
        level: 0,
        exteriorAdjuster: 0
      });

      const vpcSeen = !!allVpcs[rootWorkload.vpc];
      const subcategory = useClusterNS ? rootWorkload.cluster : rootWorkload.subnet;
      const subcategorySeen = vpcSeen && !!allVpcs[rootWorkload.vpc].subCategories[subcategory];
      const subSubcategory = useClusterNS ? rootWorkload.namespace : rootWorkload.instance;
      const subSubcategorySeen =
        subcategorySeen &&
        !!allVpcs[rootWorkload.vpc].subCategories[subcategory].subSubcategories.includes(subSubcategory);
      if (!vpcSeen) {
        allVpcs[rootWorkload.vpc] = {
          subCategories: { [subcategory]: { subSubcategories: [subSubcategory] } }
        };
      } else if (!subcategorySeen) {
        allVpcs[rootWorkload.vpc] = {
          subCategories: {
            ...allVpcs[rootWorkload.vpc].subCategories,
            [subcategory]: { subSubcategories: [subSubcategory] }
          }
        };
      } else if (!subSubcategorySeen) {
        allVpcs[rootWorkload.vpc].subCategories[subcategory].subSubcategories.push(subSubcategory);
      }

      const nodeMetricsMap = nodeMetricsToMap(data.nodeMetrics);
      const childrenEdges = data.edgeMetrics.filter(pEdge => pEdge.sourceWorkload?.id === id && !!pEdge.targetWorkload);
      childrenEdges
        .map((pEdge, ind) => {
          const targetId = pEdge.targetWorkload!.id;
          const targetNode = nodeMetricsMap[targetId];

          // If this is a faulty edge, then just give it as leaf child and stop there
          //  (eg, sometimes the api returns
          //  edges that cross clusters that weren't selected, though the nodes
          //  for the unselected cluster won't be there )
          if (!targetNode) {
            return undefined;
          } else {
            const newBox = pEdge.targetWorkload!.vpc !== root.workload!.vpc;

            const childBranch = getInnerInfraTree(data, targetNode, newBox ? 0 : 1, newBox ? 1 : 0, useClusterNS, [id]);

            return childBranch;
          }
        })
        .filter((child): child is InfraBasedTree => !!child)
        .forEach(infraBranch => {
          allNodes.push(...infraBranch.nodes);

          Object.keys(infraBranch.vpcsSeen).forEach(vpcKey => {
            if (!!allVpcs[vpcKey]) {
              Object.keys(infraBranch.vpcsSeen[vpcKey].subCategories).forEach(subCKey => {
                if (!!allVpcs[vpcKey].subCategories[subCKey]) {
                  infraBranch.vpcsSeen[vpcKey].subCategories[subCKey].subSubcategories.forEach(subSubc => {
                    if (!allVpcs[vpcKey].subCategories[subCKey].subSubcategories.includes(subSubc)) {
                      allVpcs[vpcKey].subCategories[subCKey].subSubcategories.push(subSubc);
                    }
                  });
                } else {
                  allVpcs[vpcKey].subCategories[subCKey] = {
                    subSubcategories: [...infraBranch.vpcsSeen[vpcKey].subCategories[subCKey].subSubcategories]
                  };
                }
              });
            } else {
              allVpcs[vpcKey] = { subCategories: { ...infraBranch.vpcsSeen[vpcKey].subCategories } };
            }
          });
        });
    }
  });

  // Resolve seeing nodes multiple times via different paths
  let nodes: TreeNode[] = [];
  allNodes.forEach(node => {
    const repeatNode = nodes.find(n => n.id === node.id);
    if (!repeatNode) {
      nodes.push(node);
    } else {
      repeatNode.level = Math.max(node.level, repeatNode.level);
    }
  });
  nodes.sort((nodeA, nodeB) => nodeA.id.toLowerCase().localeCompare(nodeB.id.toLowerCase()));

  return {
    nodes,
    vpcsSeen: allVpcs
  };
}
