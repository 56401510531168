import { Asset } from 'assets';
import LoginPromptImage from 'assets/login-page/login-prompt.png';
import { useDocumentTitle } from 'utils/hooks';
import { buildLoginUrl } from 'utils/url-builders';
import { externalLinks } from 'utils/url-external-links-map';
import { AuthLoginPageStyles } from './AuthLoginPage.style';

const tryToLogin = () => {
  //@ts-ignore
  window.location.replace(buildLoginUrl());
};

export const AuthLoginPage = () => {
  useDocumentTitle('Welcome');
  return (
    <AuthLoginPageStyles.Container data-testid='auth-login-page'>
      <AuthLoginPageStyles.BackgroundContainer>
        <Asset.CloudBackground />
      </AuthLoginPageStyles.BackgroundContainer>
      <AuthLoginPageStyles.ScreenSplitter>
        <AuthLoginPageStyles.LoginPrompt>
          <Asset.GlooPlatformLogo />
          <AuthLoginPageStyles.Welcome>Welcome Back!</AuthLoginPageStyles.Welcome>
          <AuthLoginPageStyles.Subtitle>Get started with Gloo Platform by signing in</AuthLoginPageStyles.Subtitle>
          <AuthLoginPageStyles.FauxSoloButton onClick={tryToLogin}>
            SIGN INTO GLOO PLATFORM
          </AuthLoginPageStyles.FauxSoloButton>

          <AuthLoginPageStyles.Copyright>
            {`Gloo Platform v${process.env.UI_VERSION}`} © {new Date().getFullYear()} solo.io, Inc. All Rights Reserved.
            <AuthLoginPageStyles.PrivacyPolicyContainer href={externalLinks.solo_io.privacy_policy} target='_blank'>
              Privacy Policy
            </AuthLoginPageStyles.PrivacyPolicyContainer>
          </AuthLoginPageStyles.Copyright>
        </AuthLoginPageStyles.LoginPrompt>

        <img src={LoginPromptImage} alt='' />
      </AuthLoginPageStyles.ScreenSplitter>
    </AuthLoginPageStyles.Container>
  );
};
