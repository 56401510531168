import { overviewApi } from 'Api/overview';
import { SoloListCardDynamic } from 'Components/Common/SoloListCard';
import { DashboardHealthCheckRow } from 'Components/Features/Dashboard/Cards/Health/DashboardHealthCheckRow';
import { DashboardHealthKey, dashboardHealthCodesMap } from 'Components/Features/Dashboard/Cards/Health/insight-codes';
import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useFilteredDashboardInsights } from 'utils/dashboard/dashboard-hooks';
import { getGlooAgentLogsLinks, getGlooManagementPlaneLogsLinks } from 'utils/log-viewer-helpers';
const { useGetClusterDetailsCards } = overviewApi;

export const ClusterGlooHealthListCard = ({ cluster }: { cluster: string }) => {
  di(useFilteredDashboardInsights, useGetClusterDetailsCards);
  // Gloo mgmt plane status comes from the dashboard cards.
  const { data: clusterCardsData } = useGetClusterDetailsCards(cluster);
  const glooManagementPlaneStatus = clusterCardsData?.healthOverview?.glooManagementPlaneStatus;
  const glooManagementPlaneInsights = useFilteredDashboardInsights(
    dashboardHealthCodesMap,
    DashboardHealthKey.glooManagementPlane
  );

  const glooAgentInsights = useFilteredDashboardInsights(dashboardHealthCodesMap, DashboardHealthKey.glooAgent);
  // The `glooAgentInsightsForThisCluster` applies a filter after getting all the dashboard insights
  // (without a target filter), because the insight that describes the gloo agent that is on this
  // cluster always targets the management plane, so it wouldn't be returned when filtering by this
  // cluster as a target.
  const glooAgentInsightsForThisCluster = useMemo(() => {
    const newGlooAgentInsightForThisCluster = glooAgentInsights?.find(
      insight => insight.target?.target.oneofKind === 'resource' && insight.target.target.resource?.name === cluster
    );
    if (!newGlooAgentInsightForThisCluster) {
      return undefined;
    }
    return [newGlooAgentInsightForThisCluster];
  }, [glooAgentInsights]);
  const glooAgentStatus = clusterCardsData?.healthOverview?.glooAgentStatus;

  return (
    <SoloListCardDynamic title='Health' data-testid='gloo-health-card'>
      <GridLayout templateColumns='20px 1fr auto' gap='20px 6px'>
        <DashboardHealthCheckRow
          label='Management Plane'
          status={clusterCardsData === undefined ? 'loading' : glooManagementPlaneStatus}
          logsLinks={getGlooManagementPlaneLogsLinks(glooManagementPlaneInsights)}
        />
        <DashboardHealthCheckRow
          label='Agent'
          status={glooAgentInsights === undefined ? 'loading' : glooAgentStatus}
          logsLinks={getGlooAgentLogsLinks(glooAgentInsightsForThisCluster)}
        />
        {/* // TODO: update link to actual page
        makeClusterDetailsHealthRowData('Telemetry', telemetry === undefined ? 'loading' : telemetryStatus, '/') */}
      </GridLayout>
    </SoloListCardDynamic>
  );
};
