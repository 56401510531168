import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { DataError } from 'Components/Common/DataError';
import { HealthIndicatorRingWithPermanentIcon, HealthIndicatorWithName } from 'Components/Common/HealthIndicator';
import { ExternalSoloLink } from 'Components/Common/SoloLink';
import { DataType, SoloListCard } from 'Components/Common/SoloListCard';
import { Insight } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/internal/insights/v2alpha1/insights_pb';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useCallback, useMemo } from 'react';
import { createCombinedInsightStatus } from 'utils/dashboard/dashboard-helpers';
import { compareVersion } from 'utils/helpers';
import { externalLinks } from 'utils/url-external-links-map';
import { useGetIstioCveList, useGetIstioVersionAnnouncementsList } from '../hooks';

const NoMarginEmptyContainer = styled.div`
  display: flex; // Makes sure the ListCard stretches to fit in cases where this container is also in a flexbox (basically fixes the default ListCard behavior)
  .ant-empty {
    margin: 0 !important;
  }
`;

type LatestIstioVersion = { [major: string]: { [minor: string]: string } };
const useGetLatestIstioVersion = () => {
  const { data: versionAnnouncements, error } = useGetIstioVersionAnnouncementsList();
  const latestVersionMap = useMemo(
    () =>
      versionAnnouncements?.reduce((map, { title }) => {
        const [, major, minor, patch] = title.match(/ (\d+).(\d+).(\d+)/) ?? [];
        map[major] ??= {};
        // we can assume the first patch it finds is always the newest as the data comes from an rss feed -
        // as such we only want to set it when it's undefined as that will be the newest patch version
        map[major][minor] ??= patch;
        return map;
      }, {} as LatestIstioVersion),
    [versionAnnouncements]
  );
  const getLatestPatchOfMinor = useCallback(
    (version: string) => {
      const [major, minor] = version.split('.');
      const patch = latestVersionMap?.[major]?.[minor];
      return !!patch ? `${major}.${minor}.${patch}` : undefined;
    },
    [latestVersionMap]
  );
  return { getLatestPatchOfMinor, error };
};

type CveVersion = { title: string; link?: string; versions: { min: string; max: string; message: string } };

export const IstioVersionsSoloListCard = ({
  versionsDict,
  maxHeight = '140px',
  selectVersion,
  selectedVersion
}: {
  versionsDict: Record<string, Insight[]>;
  maxHeight?: string;
  selectVersion?: (version: string) => any;
  selectedVersion?: string;
}) => {
  const { data: istioCveList, error: istioCveListError } = useGetIstioCveList();
  const { getLatestPatchOfMinor } = useGetLatestIstioVersion();

  const cveVersionList = useMemo(() => {
    const list: Array<CveVersion> = [];
    istioCveList?.forEach(cve => {
      const descDom = new window.DOMParser().parseFromString(cve.description, 'text/html');
      const rows = Array.from(descDom.querySelector('table tbody')?.children ?? []);
      // Content is always on the second cell of last row on the table
      const affectedVersionsText = rows[rows.length - 1].children[1].textContent;
      const affectedVersionsLines = affectedVersionsText
        ?.split('\n')
        .map(s => s.trim())
        .filter(s => !!s);
      const message = descDom.querySelector('ul')?.textContent ?? '';
      affectedVersionsLines?.forEach(line => {
        let match: RegExpMatchArray | null;
        if ((match = line.match(/^(\d+\.\d+\.?\d*)$/))) {
          list.push({ ...cve, versions: { min: match[1], max: match[1], message } });
        } else if ((match = line.match(/(\d+\.\d+\.?\d*) to (\d+\.\d+\.?\d*)/))) {
          list.push({ ...cve, versions: { min: match[1], max: match[2], message } });
        } else if ((match = line.match(/All releases prior to (\d+\.\d+\.?\d*)/))) {
          list.push({ ...cve, versions: { min: '0.0', max: match[1], message } });
        } else if ((match = line.match(/All (\d+\.\d+) patch releases/))) {
          list.push({ ...cve, versions: { min: `${match[1]}.1`, max: `${match[1]}.999`, message } });
        } else if ((match = line.match(/All releases (\d+\.\d+\.?\d*) and later/))) {
          return; // We don't use this case
        } else {
          console.warn(`Unhandled cve version match text: "${line}"`); // eslint-disable-line no-console
        }
      });
    });
    return list;
  }, [istioCveList]);

  const versionListCardEntries = useMemo<DataType[]>(() => {
    const getCves = (v: string, cveVersionList: Array<CveVersion>) => {
      const vNoSolo = v.replace('-solo', '');
      return cveVersionList.filter(({ versions: { min, max } }) => {
        const isSameVersion =
          (compareVersion(v, min) >= 0 && compareVersion(v, max) <= 0) ||
          (compareVersion(vNoSolo, min) >= 0 && compareVersion(vNoSolo, max) <= 0);
        return isSameVersion;
      });
    };

    return Object.entries(versionsDict)
      .sort(([a], [b]) => -compareVersion(a, b))
      .map(([version, insights]) => {
        const cves = getCves(version, cveVersionList);
        const latestPatchVersion = getLatestPatchOfMinor(version);
        return {
          key: version,
          data: <HealthIndicatorWithName status={createCombinedInsightStatus(insights)} name={version} />,
          right: !cves.length ? undefined : (
            <Tooltip
              placement='left'
              trigger='hover'
              title={
                <div>
                  <div>{cves.length} Istio vulnerabilities found</div>
                  <ul>
                    {cves.map(cve => (
                      <li key={cve.title}>
                        {cve.link ? (
                          <ExternalSoloLink href={cve.link} newTab>
                            {cve.title}
                          </ExternalSoloLink>
                        ) : (
                          cve.title
                        )}
                      </li>
                    ))}
                  </ul>
                  {!!latestPatchVersion && <div>Please upgrade to Istio version {latestPatchVersion}</div>}
                </div>
              }>
              <span>
                <HealthIndicatorRingWithPermanentIcon state={State.WARNING} icon={<Asset.WarningCircleIcon />} />
              </span>
            </Tooltip>
          ),
          selectRow: selectVersion,
          isSelected: selectedVersion === version
        };
      });
  }, [versionsDict, cveVersionList, selectVersion, selectedVersion, getLatestPatchOfMinor]);

  if (istioCveListError) {
    return (
      <DataError
        error={{ message: `There was an error retriving information from ${externalLinks.istio_io.news_xml}.` }}
      />
    );
  }

  return (
    <NoMarginEmptyContainer>
      <SoloListCard
        data-testid='istio-versions-card'
        title='Version'
        maxHeight={maxHeight}
        dataSource={versionListCardEntries}
        hideDottedLine
      />
    </NoMarginEmptyContainer>
  );
};
