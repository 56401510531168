import { RpcError } from "@protobuf-ts/runtime-rpc";
import React from 'react';
import { DataError } from './DataError';
import { ErrorBoundary } from './ErrorBoundary';
import { FancyCodeDisplayer, Language } from './FancyCodeDisplayer';
import { Loading } from './Loading';
import { SoloModal, SoloModalHeader, SoloModalHeaderProps } from './SoloModal';
import { YamlModalStyles } from './YamlModal.style';

interface WrapperProps {
  contentString: string | undefined;
  error?: Partial<RpcError>;
  type: Language;
}
export const FancyCodeDisplayerModalContent = ({ contentString, error, type }: WrapperProps) => {
  if (!!error) return <DataError error={error} />;
  else if (contentString === undefined) return <Loading message='Retrieving content...' />;

  if (type === 'json') {
    contentString = JSON.stringify(JSON.parse(contentString), null, 2);
  }
  return <FancyCodeDisplayer contentString={contentString} type={type} maxHeight='none' />;
};

interface FancyCodeDisplayerModalProps extends SoloModalHeaderProps {
  children: React.ReactNode;
  onClose: () => void;
}
export const FancyCodeDisplayerModal = ({ children, onClose, ...header }: FancyCodeDisplayerModalProps) => {
  return (
    <SoloModal width={1000} onClose={onClose}>
      {/* Custom body used to enforce code not going offscreen */}
      <YamlModalStyles.Body>
        <SoloModalHeader {...header} />
        <YamlModalStyles.Content>
          <ErrorBoundary fallback='Could not retrieve content...'>{children}</ErrorBoundary>
        </YamlModalStyles.Content>
      </YamlModalStyles.Body>
    </SoloModal>
  );
};
