import { Empty } from 'antd';
import React from 'react';
import { SoloEmptyStyles as Styles } from './SoloEmpty.style';
import { ExternalSoloLink } from './SoloLink';

interface SoloEmptyWithDocsProps {
  icon?: React.ReactNode;
  iconCircled?: boolean;
  title: string;
  description: string;
  href: string;
}
export const SoloEmptyWithDocs = ({ icon, iconCircled, title, description, href }: SoloEmptyWithDocsProps) => {
  return (
    <Styles.Container data-testid='solo-empty-with-docs'>
      <Styles.IconHolder circled={iconCircled}>{icon}</Styles.IconHolder>
      <Styles.Body>
        <Styles.Title>{title}</Styles.Title>
        <p>{description}</p>
        <ExternalSoloLink href={href} newTab>
          VIEW DOCS &gt;
        </ExternalSoloLink>
      </Styles.Body>
    </Styles.Container>
  );
};

interface SoloEmptyLeftIconProps {
  icon?: React.ReactNode;
  iconCircled?: boolean;
  title: string;
  description?: string;
}
export const SoloEmptyLeftIcon = ({ icon, iconCircled, title, description }: SoloEmptyLeftIconProps) => {
  return (
    <Styles.Container data-testid='solo-empty-with-docs'>
      <Styles.IconHolder circled={iconCircled}>{icon}</Styles.IconHolder>
      <Styles.Body>
        <Styles.Title>{title}</Styles.Title>
        {description && <p>{description}</p>}
      </Styles.Body>
    </Styles.Container>
  );
};

interface SoloEmptySimpleProps {
  icon?: React.ReactNode;
  iconSize?: number;
  description?: React.ReactNode;
  greyscale?: boolean;
}
export const SoloEmptySimple = ({ icon, iconSize, description, greyscale }: SoloEmptySimpleProps) => {
  return (
    <Styles.SimpleEmpty
      image={icon ?? Empty.PRESENTED_IMAGE_SIMPLE}
      iconSize={iconSize}
      description={description ?? 'No Matches'}
      greyscale={greyscale}
    />
  );
};
