import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { CardStyles } from 'Components/Common/Card';
import { Svg } from 'Components/Common/Svg';
import { CardFolderTab, CardFolderTabListWithBottomBorder } from 'Components/Common/Tabs';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { useState } from 'react';
import { DashboardStyles } from '../../DashboardLanding.style';
import CiliumHealthTabContent from './Cilium/CiliumHealthTabContent';
import { GlooHealthTabContentFakeData } from './Gloo/GlooHealthTabContentFakeData';
import IstioHealthTabContentFakeData from './Istio/IstioHealthTabContentFakeData';

const istioTab = {
  key: 'istio-health',
  title: (
    <Spacer my={-1}>
      <DashboardStyles.Header>
        <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.IstioLogo />} />
        Istio
      </DashboardStyles.Header>
    </Spacer>
  ),
  Component: IstioHealthTabContentFakeData
};
const ciliumTab = {
  key: 'cilium-health',
  title: (
    <Spacer my={-1}>
      <DashboardStyles.Header>
        <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.CiliumIcon />} />
        Cilium
      </DashboardStyles.Header>
    </Spacer>
  ),
  Component: CiliumHealthTabContent
};
const glooTab = {
  key: 'gloo-health',
  title: (
    <Spacer my={-1}>
      <DashboardStyles.Header>
        <Svg size={20} mt={-1} color={theme => theme.neptuneBlue} asset={<Asset.GlooOutlineIcon />} />
        Gloo
      </DashboardStyles.Header>
    </Spacer>
  ),
  Component: GlooHealthTabContentFakeData
};
const tabData = [istioTab, ciliumTab, glooTab];

export const DashboardHealthCardFakeData = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <CardStyles.Card data-testid='istio-cilium-health-card'>
      <DashboardStyles.FullHeightTabsWrapper>
        <Tabs id='multiYamlTabs' index={tabIndex} onChange={setTabIndex}>
          <CardFolderTabListWithBottomBorder margin='0px'>
            {tabData.map(({ title, key }) => (
              <CardFolderTab key={key} width='auto'>
                {title}
              </CardFolderTab>
            ))}
          </CardFolderTabListWithBottomBorder>
          <TabPanels>
            {tabData.map(({ Component, key }) => (
              <TabPanel key={key}>
                <Component />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </DashboardStyles.FullHeightTabsWrapper>
    </CardStyles.Card>
  );
};
