import React from 'react';
import { useSoloId } from 'utils/hooks';
import { SoloLabel } from './SoloLabel';
import { SoloMultiselectStyles as Styles } from './SoloMultiselect.style';

type ValueType = string | number;

export interface OptionType<T> {
  disabled?: boolean;
  value: T;
  title: React.ReactNode;
  icon?: React.ReactNode;
  children?: OptionType<T>[];
}
export interface MultiselectProps<T> {
  values: T[];
  options: OptionType<T>[];
  onChange?: (newValues: T[]) => any;
  id?: string;
  label?: React.ReactNode;
  infoTooltip?: React.ReactNode;
  placeholder?: string;
  showSearch?: boolean;
  defaultValue?: string | number;
  onBlur?: (evt: React.FocusEvent) => any;
  disabled?: boolean;
  testId?: string;
  selectAll?: boolean;
  clear?: boolean;
  error?: any;
  filterOptions?: boolean;
  multiple?: boolean;
  treeDataSimpleMode?: boolean;
  allowClear?: boolean;
  alwaysShowPlaceholder?: boolean;
}

export interface TagProps {
  onClose: (newValues: string[]) => any;
  value: string;
}

export const SoloMultiselect = <T extends ValueType>({
  id,
  label,
  infoTooltip,
  disabled,
  defaultValue,
  values,
  placeholder,
  alwaysShowPlaceholder = true,
  options,
  onChange,
  selectAll,
  clear,
  onBlur,
  testId,
  error,
  filterOptions,
  multiple,
  ...rest
}: MultiselectProps<T>) => {
  const inputId = useSoloId(id);

  const multiSelectItemMaxWidths = ['100%', '95%', '47%', '31%'];
  const itemMaxWidth = multiSelectItemMaxWidths[multiple ? 0 : values.length];

  const getDefaultValue = (): string | number => {
    if (typeof defaultValue === 'undefined') {
      return '';
    }

    return defaultValue!;
  };

  const filteredOptions = filterOptions ? options.filter(opt => !values.includes(opt.value)) : options;

  const selectAllClick = () => {
    if (onChange) {
      onChange(filteredOptions.map(v => v.value));
    }
  };

  const clearClick = () => {
    if (onChange) {
      onChange([]);
    }
  };

  return (
    <Styles.SelectWrap data-testid='solo-multiselect-wrapper' maxWidth={itemMaxWidth}>
      {!!label && (
        <SoloLabel infoTooltip={infoTooltip} htmlFor={inputId}>
          {label}
        </SoloLabel>
      )}
      <Styles.TreeDropdownBlock
        id={inputId}
        treeData={filteredOptions}
        data-testid={testId}
        dropdownClassName={testId}
        treeNodeFilterProp={'title'}
        value={multiple ? values : values[0]}
        defaultValue={getDefaultValue()}
        onChange={onChange as any}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        aria-label={placeholder} // can be overwritten by including it in ...rest
        {...rest}
        multiple={multiple}
        treeCheckable={multiple}
        showCheckedStrategy={'SHOW_ALL'}
        treeDefaultExpandAll={rest.treeDataSimpleMode}
        multiplePlaceholder={multiple && alwaysShowPlaceholder ? placeholder : undefined}
      />
      {(selectAll || clear) && (
        <Styles.ButtonWrap>
          {selectAll && <Styles.UtilityButton onClick={selectAllClick}>Select all</Styles.UtilityButton>}
          {clear && <Styles.UtilityButton onClick={clearClick}>Clear</Styles.UtilityButton>}
        </Styles.ButtonWrap>
      )}
    </Styles.SelectWrap>
  );
};
