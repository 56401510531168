import styled from '@emotion/styled';
import { colors } from 'Styles';
import { DestinationReference, ObjectReference } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/references_pb';
import { StringMatch } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/common/v2/string_match_pb';
import {
  Status
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import {
  StringMatch as PolicyStringMatch,
  StringMatchType
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/policies_pb';
import {
  ClusterObjectRef,
  ObjectRef,
  TypedClusterObjectRef,
  TypedObjectRef
} from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { Duration } from 'proto/google/protobuf/duration_pb';

/* -------------------------------------------------------------------------- */
/*                                   GENERAL                                  */
/* -------------------------------------------------------------------------- */

export function groupBy<T>(data: T[], getKey: (item: T) => string) {
  const map = new Map<string, T[]>();
  data.forEach(resource => {
    const key = getKey(resource);
    if (!map.get(key)) {
      map.set(key, [resource]);
    } else {
      map.get(key)!.push(resource);
    }
  });
  return map;
}

export function makeObjectRef(name?: string, namespace?: string): ObjectRef | undefined {
  if (!!name && !!namespace) {
    return {
      name,
      namespace
    };
  }

  return undefined;
}
export function makeClusterObjectRef(
  name?: string,
  namespace?: string,
  clusterName?: string
): ClusterObjectRef | undefined {
  if (!!name && !!namespace && !!clusterName) {
    return {
      name,
      namespace,
      clusterName
    };
  }

  return undefined;
}

export function sortObjectRefs(
  aRef?: ObjectRef | ClusterObjectRef | TypedObjectRef,
  bRef?: ObjectRef | ClusterObjectRef | TypedObjectRef
) {
  if (bRef?.name === undefined || bRef?.namespace === undefined) {
    return 1;
  }
  if (aRef?.name === undefined || !aRef?.namespace === undefined) {
    return -1;
  }

  const nameDiff = aRef.name.localeCompare(bRef.name);
  const namespaceDiff = aRef.namespace.localeCompare(bRef.namespace);

  // @ts-ignore
  if (!!aRef.clusterName || !!bRef.clusterName) {
    // @ts-ignore
    if (!bRef?.clusterName) {
      return 1;
    }
    // @ts-ignore
    if (!aRef?.clusterName) {
      return -1;
    }

    return (
      nameDiff ||
      namespaceDiff ||
      // @ts-ignore
      aRef.clusterName.localeCompare(bRef.clusterName)
    );
  }

  return nameDiff || namespaceDiff;
}

// Since enum can't be passed as a generic with type constraints, this is used to
// limit what can be passed into the function
export type StandardEnum<T> = {
  [id: string]: T | string;
  [nu: number]: string;
};
// Pass back all enum keys as an array
export function getEnumKeys<Enum>(pEnum: StandardEnum<Enum>): (keyof Enum)[] {
  return (
    Object.keys(pEnum)
      // filter out any number keys - these only represent values (which are used in enums to easily find key name)
      .filter(key => isNaN(key as any)) as (keyof Enum)[]
  );
}
// Pass back all enum values as an array
export function getEnumValues<Enum>(pEnum: StandardEnum<Enum>): Enum[] {
  return (
    Object.entries(pEnum)
      // filter out any number keys - these only represent values (which are used in enums to easily find key name)
      .filter(([key]) => isNaN(key as any))
      .map(([, val]) => val as Enum)
  );
}
// Pass back Object.entries of enum with only valid enum types
export function getEnumEntries<Enum>(pEnum: StandardEnum<Enum>): [string, Enum][] {
  return (
    Object.entries(pEnum)
      // filter out any number keys - these only represent values (which are used in enums to easily find key name)
      .filter(([key]) => isNaN(key as any))
      .map(([key, val]) => [key, val as Enum])
  );
}

// TODO - This is rough for now. See Issue 1207
export function getTimeAsSecondsString(fullTime?: Duration, undefinedText = '-s'): string {
  if (!fullTime) {
    return undefinedText;
  }

  // If there are partial seconds, round off to miliseconds
  if (fullTime.nanos) {
    if (!fullTime.seconds) {
      return `${fullTime.nanos}ns`;
    } else if (fullTime.nanos / 1000000 >= 0.51) {
      return `${fullTime.seconds}.${(fullTime.nanos / 1000000000).toString().match(/^-?\d+(?:\.\d{0,3})?/)![0]}s`;
    }

    return `${fullTime.seconds}s`;
  }

  return `${fullTime.seconds}s`;
}

type PortListItemType = {
  closed?: boolean;
};
const PortListItem = styled.div<PortListItemType>`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  padding: 0 8px;
  margin-right: 8px;

  svg {
    width: 11px !important;
    margin-right: 8px !important;

    * {
      fill: ${(props: PortListItemType) => (props.closed ? colors.forestGreen : colors.juneGrey)} !important;
    }
  }
`;
export function getPortListing(port: number, protocol?: string, useDefaultFallback?: boolean) {
  return (
    <PortListItem closed={port !== 8080} key={port}>
      {/*port !== 8080 ? <ClosedLockIcon /> : <OpenLockIcon />}{' '*/}
      {!port && useDefaultFallback ? 'default' : port}
      {protocol !== undefined ? `::${protocol}` : ''}
    </PortListItem>
  );
}

/**
 * Converts a string_match_pb StringMatch which is harder to deal with into a
 * policies_pb StringMatch which returns an easier to use single type/matcher field
 * @param match
 * @returns
 */
export function convertToPolicyStringMatch(match?: StringMatch): PolicyStringMatch {
  if (match?.matchType.oneofKind === 'exact') {
    return {
      type: StringMatchType.EXACT,
      matcher: match.matchType.exact,
      caseInsensitive: match.ignoreCase
    };
  } else if (match?.matchType.oneofKind === 'prefix') {
    return {
      type: StringMatchType.PREFIX,
      matcher: match.matchType.prefix,
      caseInsensitive: match.ignoreCase
    };
  } else if (match?.matchType.oneofKind === 'regex') {
    return {
      type: StringMatchType.REGEX,
      matcher: match.matchType.regex,
      caseInsensitive: match.ignoreCase
    };
  } else if (match?.matchType.oneofKind === 'suffix') {
    return {
      type: StringMatchType.SUFFIX,
      matcher: match.matchType.suffix,
      caseInsensitive: match.ignoreCase
    };
  } else {
    return {
      type: StringMatchType.UNKNOWN_MATCH_TYPE,
      matcher: 'UNKNOWN MATCH TYPE!',
      caseInsensitive: match?.ignoreCase ?? false
    };
  }
}

/**
 * @param filename Must include an extension
 */
export function doDownload(downloadContent: string, filename: string, fileType = 'text/plain') {
  const file = new Blob([downloadContent], { type: fileType });
  const templElement = document.createElement('a');
  templElement.href = URL.createObjectURL(file);
  templElement.download = filename;
  templElement.style.display = 'none';
  // simulate link click
  // Required for this to work in FireFox
  document.body.appendChild(templElement);
  templElement.click();
  document.body.removeChild(templElement);
}

export function copyTextToClipboard(copyText: string): boolean {
  let textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = '-999px';
  textArea.style.left = '-999px';

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'rgba(255, 255, 255, 0)';

  textArea.value = copyText;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let success = false;
  try {
    success = document.execCommand('copy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Oops, unable to copy.' + err);
  }

  document.body.removeChild(textArea);

  return success;
}

export async function readFileAsText(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = e => {
      const result = e?.target?.result;
      if (typeof result !== 'string') {
        return reject(new Error("File doesn't contain a valid string"));
      }
      resolve(result);
    };
    fr.onerror = reject;
    fr.readAsText(file);
  });
}

export function sumNumberList(numberList?: number[]): number {
  return numberList ? numberList.reduce((acc, currItem) => acc + currItem, 0) : 0;
}

export function isNonNullableAndNonFalse<T>(item: T): item is Exclude<T, null | undefined | false> {
  return typeof item !== 'undefined' && item !== null && !(typeof item === 'boolean' && item === false);
}

export function buildIdFromRef(
  ref?: ObjectRef | ClusterObjectRef | ObjectReference | TypedClusterObjectRef
): string {
  if (!!ref) {
    const { name, namespace } = ref;
    const clusterName: string | undefined =
      (ref as ClusterObjectRef).clusterName ?? (ref as ObjectReference).cluster;
    return `${name}.${namespace}${clusterName ? `.${clusterName}` : ''}`;
  } else {
    return '';
  }
}

export function splitRefFromId(id?: string): ClusterObjectRef {
  const [name, namespace, clusterName] = (id ?? '').split('.');
  return { name, namespace, clusterName };
}

export function cssProp(prop: string, value: string | number | undefined) {
  return value !== undefined ? `${prop}:${value};` : '';
}

/**
 * Useful for generating mock data. https://stackoverflow.com/a/8809472/9796169.
 * @returns
 */
export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

// https://stackoverflow.com/a/7717160/1411473
// Sorts in descending order
export function compareVersion(a: string | number, b: string | number): number {
  const aParts = (a + '').split('.');
  const bParts = (b + '').split('.');
  const len = Math.max(aParts.length, bParts.length);
  for (let i = 0; i < len; i++) {
    aParts[i] ??= '0';
    bParts[i] ??= '0';
    const cmp = parseInt(aParts[i], 10) - parseInt(bParts[i], 10);
    if (cmp !== 0) {
      return cmp < 0 ? -1 : 1;
    }
  }
  return 0;
}

// 1 day in seconds = 60*60*24
export const SECONDS_IN_DAY = 86400;
// 1 year in seconds = 60*60*24*365
export const SECONDS_IN_YEAR = 31536000;

/**
 * Returns the date formatted like MM/DD/YYYY (or local equivalent)
 */
export const formatDateToMMDDYYYY = (d: Date) => {
  // Locale left out so it defaults to local equivalent
  return d.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });
};

/**
 * Capitalizes the first letter of each word in a string (e.g. "hello world" -> "Hello World").
 */
export const capitalizeFirstLetters = (s: string) => {
  return s
    .split(' ')
    .map(s => (s.at(0)?.toLocaleUpperCase() ?? '') + s.slice(1))
    .join(' ');
};

/** Displays large numbers nicely.
 ```
  123,456,789 -> "123M"
  12,345,678 -> "12.3M"
  123,456,7 -> "1.2M"
  123,456 -> "123K"
  12,345 -> "12.3K"
  1,234 -> "1234"
 ```
 */
export function formatLargeNumberNicely(num: number) {
  return num > 99999999
    ? Math.floor(num / 1000000) + 'M'
    : num > 999999
      ? Math.floor(num / 100000) / 10 + 'M'
      : num > 99999
        ? Math.floor(num / 1000) + 'K'
        : num > 9999
          ? Math.floor(num / 100) / 10 + 'K'
          : num.toString();
}

export function createCombinedStatus(statusesIn: Array<Status | undefined>): Status | undefined {
  if (!statusesIn?.length) return undefined;
  // Sort from worst to best
  const statuses = statusesIn.filter(isNonNullableAndNonFalse).sort((a, b) => b.state - a.state);
  return {
    state: statuses[0].state, // Show worst state
    validationErrors: statuses.flatMap(s => s.validationErrors)
  };
}

export function isContentOverflowing(el: HTMLElement | null) {
  if (el === null) {
    return false;
  }
  return el.offsetWidth < el.scrollWidth;
}

export function getRefFromDestinationRef(destinationReference: DestinationReference | undefined) {
  return destinationReference?.refKind.oneofKind === 'ref' ? destinationReference.refKind.ref : undefined;
}

export function isObjectEmpty(o: Record<any, any> | undefined) {
  if (o === undefined) {
    return true;
  }
  return Object.keys(o).length === 0 && o.constructor === Object;
} 