import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { UnstyledButton } from 'Styles/CommonEmotions/unstyledButton';
import { Kind } from 'graphql';
import { useContext, useMemo } from 'react';
import { getArgumentDefaultValue, getFieldReturnType } from 'utils/graphql-helpers';
import { GqlLandingContext } from '../../context/GqlLandingContext';
import { GqlDetailsStyles } from '../GqlDetails.style';
import FieldTypeValue from './FieldTypeValue';
import { GqlDescriptionInfoTooltip } from './GqlDescriptionInfoTooltip';
import { GqlSchemaTabStyles } from './Tabs/GqlSchemaTabStyles.style';

/**
 * This is a generic component that can show information on these nodes:
 * - fields
 * - arguments
 * - directives
 * - variables
 * ---
 * It displays:
 * - the name value
 * - arguments
 * - default values
 * - directives
 * - locations (e.g. `... ON FIELD`)
 * - return types
 */
const NameAndReturnType = (props: { node: any; onTypeClick(t: string): void }) => {
  const { node, onTypeClick } = props;
  const returnType = getFieldReturnType(node);
  const gqlCtx = useContext(GqlLandingContext);
  const { schema } = gqlCtx;

  const nodeName = useMemo(() => {
    if (!node.name?.value) return null;
    if (node.kind === Kind.DIRECTIVE || node.kind === Kind.DIRECTIVE_DEFINITION) {
      // If this is a directive, determine if it was defined in this schema.
      const directiveDefinedInSchema = schema.definitions.some(
        d => d.kind === Kind.DIRECTIVE_DEFINITION && d.name.value === node.name.value
      );
      // If it was, return a link to it.
      if (directiveDefinedInSchema) {
        return (
          <GqlDetailsStyles.MonospaceCodeStyle>
            <UnstyledButton onClick={() => onTypeClick(node.name.value)} aria-label='Select node'>
              <SoloLinkStyles.SoloLinkLooks displayInline>@{node.name.value}</SoloLinkStyles.SoloLinkLooks>
            </UnstyledButton>
          </GqlDetailsStyles.MonospaceCodeStyle>
        );
      }
      // Otherwise return a normal label.
      else return <GqlDetailsStyles.MonospaceCodeStyle>@{node.name.value}</GqlDetailsStyles.MonospaceCodeStyle>;
    } else return node.name.value;
  }, [node, schema]);

  const description = node.description?.value;

  const defaultValue = getArgumentDefaultValue(node);

  let formattedValue = useMemo(() => {
    let formattedValue = '';
    if (node.value?.value) {
      if (node.value.kind === Kind.STRING) formattedValue = `"${node.value.value}"`;
      else formattedValue = `${node.value.value}`;
    }
    return formattedValue;
  }, [node]);

  //
  // Render
  //
  return (
    <GqlSchemaTabStyles.MonospaceItem>
      {nodeName}
      {!!node.variable?.name?.value && '$' + node.variable?.name?.value}
      {!!node.arguments?.length && (
        <>
          (<br />
          {node.arguments.map((a: any) => (
            <Spacer key={a.name.value} pl={3}>
              <NameAndReturnType node={a} onTypeClick={onTypeClick} />
            </Spacer>
          ))}
          )
        </>
      )}
      {!!returnType?.fullType && (
        // If there is a return type, always show it.
        <>
          <span>:</span>
          <span className='inline-block'>
            <FieldTypeValue field={node} onTypeClick={props.onTypeClick} />
          </span>
        </>
      )}
      {!!formattedValue && (
        // If there is a supplied value, always show it.
        <>
          <span>=</span>
          <span>{formattedValue}</span>
        </>
      )}
      {!formattedValue && !!defaultValue && (
        // If there is a default value (and no supplied value).
        <>
          <span>=</span>
          <span>{defaultValue}</span>
        </>
      )}
      {!!node.locations?.length && <span>&nbsp;on&nbsp;{node.locations.map((l: any) => l.value).join(', ')}</span>}
      <GqlDescriptionInfoTooltip placement='topLeft' description={description} />
    </GqlSchemaTabStyles.MonospaceItem>
  );
};

export default NameAndReturnType;
