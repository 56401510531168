import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CardStyles } from 'Components/Common/Card';
import { SoloInputStyles } from 'Components/Common/Input/SoloInput.style';
import { SoloLinkStyles } from 'Components/Common/SoloLink.style';
import { CardFolderTabList } from 'Components/Common/Tabs';

export namespace GqlDetailsStyles {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 5px;
  `;

  // ======================================================== //
  // ======================================================== //
  // ======================================================== //
  // --------------------- TITLE CARD ----------------------- //
  // ======================================================== //
  export const TitleCardGrid = styled.div`
    display: grid;
    grid-template-columns: min-content auto min-content;
    gap: 15px;
  `;

  export const ApiIconCircleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    padding: 1em 0.5em;
  `;
  export const Title = styled.div`
    width: 100%;
    font-size: 1.8em;
    padding-bottom: 0.5em;
    line-height: 1em;
  `;
  export const TitleSecondary = styled.span(
    ({ theme }) => css`
      font-size: 0.9em;
      margin-left: 0.5em;
      color: ${theme.augustGrey};
    `
  );

  export const ViewYAMLContainer = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
  `;
  // ======================================================== //
  // ======================================================== //
  // ======================================================== //

  export const SchemaSection = styled.div(
    ({ theme }) => css`
      ${CardFolderTabList} {
        border-bottom: 1px solid ${theme.marchGrey};
      }
    `
  );

  export const LightText = styled.span(
    ({ theme }) => css`
      color: ${theme.juneGrey};
    `
  );

  export const StyledCardHeader = styled(CardStyles.CardHeader)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${SoloInputStyles.Input} {
      font-size: 1rem;
    }
  `;

  export const HealthCircleContainer = styled.div`
    display: flex;
    align-items: center;
  `;

  export const StyledSubDetailValue = styled.div(
    ({ theme }) => css`
      color: ${theme.neptuneBlue};
      flex-grow: 0;
      white-space: nowrap;
      text-overflow: ellipses;
    `
  );

  export const GatewayAndExplorerLinks = styled.div`
    display: flex;
    div:nth-of-type(2) {
      flex-grow: 999999;
      margin-left: 10px;
      margin-right: 20px;
    }
    svg {
      width: 18;
      height: 18;
    }
  `;
  export const ExplorerLinkTooltipContent = styled.div<{ withLessPadding?: boolean }>(
    ({ theme, withLessPadding }) => css`
      font-size: 90%;
      ${withLessPadding
        ? css`
            padding-left: 5px;
            padding-right: 5px;
          `
        : css`
            padding-left: 10px;
            padding-right: 10px;
          `};
      ${SoloLinkStyles.SoloLinkLooks} {
        color: ${theme.white};
        &:after {
          border-color: ${theme.white};
        }
      }
    `
  );

  export const MonospaceCodeStyle = styled('span')(css`
    font-family: Courier, monospace;
    white-space: nowrap;
  `);
}
