import { graphApi } from 'Api/graphs';
import { DataError } from 'Components/Common/DataError';
import { SoloCheckboxListDropdown } from 'Components/Common/Input/SoloCheckboxList';
import { SoloLogViewerStyles } from 'Components/Common/SoloLogViewer/SoloLogViewer.style';
import { TagList } from 'Components/Common/SoloTag';
import { Tooltip } from 'antd';
import { GetFiltersResponse_ClusterNamespaces, GetFiltersResponse_WorkspaceClusters } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import React, { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useSearchParams } from 'react-router-dom';
import { isObjectEmpty } from 'utils/helpers';
import { useGraphUXSettingsContext } from '../Context/GraphUXSettingsContext';
import { useGetGraphCache } from '../General/graph-cache';
import {
  CLUSTER_QUERY_PARAM,
  NAMESPACE_QUERY_PARAM,
  WORKSPACE_QUERY_PARAM,
  useGraphWorkspacesUsable
} from '../General/graph-filter-utils';
import { GraphFiltersStyles } from './GraphFilters.style';
import { convertToStringArray, filterOptionsSort } from './GraphFiltersHelpers';

const { useGetFilters } = graphApi;

function convertClusterNamespacesEntriesToDict(entries: [string, GetFiltersResponse_ClusterNamespaces][]) {
  return Object.fromEntries(entries.map(([k, v]) => [k, v.clusterNamespaces]));
}

// Turn the backend object into something front-end usable.
// For those not used to our backend array of loose pairs, it's essentially an object turned
//  into an awkward, non-js-y tuple array with the key in the 0 slot and data in the 1 slot.
function convertWorkspaceClustersEntriesToDict(entries: [string, GetFiltersResponse_WorkspaceClusters][]) {
  return Object.fromEntries(
    entries.map(([k, v]) => [k, convertClusterNamespacesEntriesToDict(Object.entries(v.workspaceClusters))])
  );
}

export const EmptyPromptBox = ({
  show = true,
  value,
  itemsNeedingSelection
}: {
  show?: boolean;
  value: string | undefined;
  itemsNeedingSelection: string;
}) => {
  if (!!value || !show) {
    return null;
  }
  return (
    <GraphFiltersStyles.EmptyPromptBox>
      <SoloLogViewerStyles.EmptyPromptBoxContent>
        Please select a {itemsNeedingSelection} using the filters to view graph.
      </SoloLogViewerStyles.EmptyPromptBoxContent>
    </GraphFiltersStyles.EmptyPromptBox>
  );
};

type GraphHeaderProps = {
  presetFilters?: {
    workspaces: string[];
    namespaces: string[];
    clusters: string[];
  };

  graphRequestAbort: () => any;
};
export const GraphFilters = React.memo(({ presetFilters, graphRequestAbort }: GraphHeaderProps) => {
  di(useGetFilters);
  const { cache, updateCache } = useGetGraphCache();
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspaceFilters = [], namespaceFilters = [], clusterFilters = [] } = cache;
  const workspacesUsable = useGraphWorkspacesUsable();

  const { findName, hideName, setFindName, setHideName, usageInfoTopic } = useGraphUXSettingsContext();

  // This is "on data load", triggering off getting the api's filter list
  const [loadingUp, setLoadingUp] = useState(true);

  const { data: graphFilters, error: graphFiltersError } = useGetFilters();

  //
  // Convert api entries maps to actual map
  //
  const workspaceMapping = useMemo(
    () => convertWorkspaceClustersEntriesToDict(Object.entries(graphFilters?.workspaceMap ?? [])),
    [graphFilters?.workspaceMap]
  );
  const clusterMapping = useMemo(
    () => convertClusterNamespacesEntriesToDict(Object.entries(graphFilters?.clusterNamespaceMap ?? [])),
    [graphFilters?.clusterNamespaceMap]
  );

  // Detect if the user is filtering by cluster/namespace instead of workspaces even when workspaces exist.
  // In this case, don't auto set a workspace, and don't auto unselect clusters/namespaces
  // Still show workspace related stuff however, in case the user wishes to switch back to using them
  const workspacesUsableAndNotIgnored =
    workspacesUsable && (!!workspaceFilters.length || !(!!clusterFilters.length && !!namespaceFilters.length));

  // On getting the first graphFilters result from the api...
  // If no existing filters(preset or in url), set some default ones to prevent empty graph on page open
  useEffect(() => {
    if (loadingUp && !graphFiltersError && graphFilters && !presetFilters) {
      // If they came from a certain URL, scrape it. Then scrap it at the end
      let presetWorkspaces = convertToStringArray(searchParams.get(WORKSPACE_QUERY_PARAM));
      let presetNamespaces = convertToStringArray(searchParams.get(NAMESPACE_QUERY_PARAM));
      let presetClusters = convertToStringArray(searchParams.get(CLUSTER_QUERY_PARAM));

      /* Only read workspaces if they have access... */
      if (workspacesUsableAndNotIgnored) {
        // if anything in search param, set to that. Otherwise, set to first from list
        if (!presetWorkspaces.length) {
          presetWorkspaces = [graphFilters.workspaces[0]];
        }

        presetWorkspaces = !!presetWorkspaces.length
          ? presetWorkspaces.filter(pW => graphFilters.workspaces.includes(pW))
          : workspaceFilters.filter(wF => graphFilters.workspaces.includes(wF));
      } else {
        //blow them away
        presetWorkspaces = [];
      }

      presetNamespaces = !!presetNamespaces.length
        ? presetNamespaces.filter(pN => graphFilters.namespaces.includes(pN))
        : namespaceFilters.filter(nF => graphFilters.namespaces.includes(nF));
      presetClusters = !!presetClusters.length
        ? presetClusters.filter(pC => graphFilters.clusters.includes(pC))
        : clusterFilters.filter(cF => graphFilters.clusters.includes(cF));

      if (!presetWorkspaces && !presetNamespaces.length && !presetClusters.length) {
        const validCluster = graphFilters.clusters.find(c => !!clusterMapping[c] && !isObjectEmpty(clusterMapping[c]));
        if (validCluster) {
          presetNamespaces = clusterMapping[validCluster];
          presetClusters = [validCluster];
        }
      }

      graphRequestAbort();
      updateCache({
        workspaceFilters: presetWorkspaces,
        namespaceFilters: presetNamespaces,
        clusterFilters: presetClusters
      });

      setSearchParams({});
      setLoadingUp(false);
    }
  }, [graphFilters]);

  //
  // Filter lists to only what is currently allowed to be selectable
  //
  // const selectableWorkspaces = useMemo(() => graphFilters?.workspaces ?? [], [graphFilters?.workspaces]);
  const selectableClusters = useMemo(
    () =>
      Array.from(
        new Set(
          (workspacesUsableAndNotIgnored
            ? !workspaceFilters.length
              ? graphFilters?.clusters
              : workspaceFilters.reduce<string[]>((clusters, workspace) => {
                // This memo can run before the filters have properly be trimmed from
                // the previous caching/URL params. So, need to be safer
                if (!!workspaceMapping[workspace]) {
                  clusters.push(...Object.keys(workspaceMapping[workspace]));
                }

                return clusters;
              }, [])
            : graphFilters?.clusters) ?? []
        )
      ),
    [graphFilters?.clusters, workspaceFilters]
  );
  const selectableNamespaces = useMemo(
    () =>
      Array.from(
        new Set(
          (workspacesUsableAndNotIgnored && !!workspaceFilters.length
            ? workspaceFilters.reduce<string[]>((namespaces, workspace) => {
              // This memo can run before the filters have properly be trimmed from
              // the previous caching/URL params. So, need to be safer
              if (!!workspaceMapping[workspace]) {
                Object.keys(workspaceMapping[workspace]).forEach(cluster => {
                  if (!clusterFilters.includes(cluster)) return;
                  namespaces.push(...workspaceMapping[workspace][cluster]);
                });
              }
              return namespaces;
            }, [])
            : // Allow selecting any namespace if no clusters/workspaces selected
            !clusterFilters.length
              ? graphFilters?.namespaces
              : // if no workspace selected then filter based on selected clusters
              clusterFilters.reduce<string[]>((namespaces, cluster) => {
                // This memo can run before the filters have properly be trimmed from
                // the previous caching/URL params. So, need to be safer
                if (!!clusterMapping[cluster]) {
                  namespaces.push(...clusterMapping[cluster]);
                }
                return namespaces;
              }, [])) ?? []
        )
      ),
    [graphFilters?.namespaces, workspaceFilters, clusterFilters]
  );

  // Filter out selected filters that are no longer valid whenever `graphFilters` updates
  useEffect(() => {
    if (!graphFilters) return;

    // Identify the filters that are no longer usable.
    let nowUnusableWorkspaces: string[] = workspaceFilters;
    let nowUnusableClusters: string[] = clusterFilters;
    let nowUnusableNamespaces: string[] = namespaceFilters;
    if (
      !!graphFilters.workspaces.length ||
      (!!graphFilters.clusters.length && !!graphFilters.namespaces.length)
    ) {
      if (workspacesUsableAndNotIgnored) {
        graphFilters.workspaces.forEach(workspace => {
          //this workspace is still usable as a filter
          nowUnusableWorkspaces = nowUnusableWorkspaces.filter(w => w !== workspace);
        });
        // If there are no workspace filters, then all workspaces valid
        // Otherwise, from what is left after above workspace trimming, parse
        //  which clusters are still valid.
        let remaingWorkspaces = graphFilters.workspaces;
        if (!!nowUnusableWorkspaces.length) {
          remaingWorkspaces = workspaceFilters.filter(origWs => nowUnusableWorkspaces.includes(origWs));
        }

        remaingWorkspaces.forEach(workspace => {
          Object.keys(workspaceMapping[workspace] ?? []).forEach(cluster => {
            nowUnusableClusters = nowUnusableClusters.filter(maybeUnusableCluster => maybeUnusableCluster !== cluster);
          });
        });
        // If there are no workspace or cluster filters, then all are valid
        // Otherwise, from what is left after above cluster trimming, parse
        //  which clusters are still valid.
        let remainingClusters: string[] = [];
        Object.keys(workspaceMapping).forEach(wsKey =>
          Object.keys(workspaceMapping[wsKey]).forEach(clusterName => {
            // Nothing that is no longer usable or a dupe
            if (!nowUnusableClusters.includes(clusterName) && !remainingClusters.includes(clusterName)) {
              remainingClusters.push(clusterName);
            }
          })
        );

        remaingWorkspaces.forEach(workspace => {
          Object.keys(workspaceMapping[workspace] ?? {}).forEach(cluster => {
            if (remainingClusters.includes(cluster)) {
              nowUnusableNamespaces = nowUnusableNamespaces.filter(
                ns => !workspaceMapping[workspace][cluster].includes(ns)
              );
            }
          });
        });
      } else {
        graphFilters.clusters.forEach(cluster => {
          nowUnusableClusters = nowUnusableClusters.filter(c => c !== cluster);
        });
        if (clusterFilters.length) {
          let remainingClusters = clusterFilters.filter(c => !nowUnusableClusters.includes(c));
          remainingClusters.forEach(cluster => {
            nowUnusableNamespaces = nowUnusableNamespaces.filter(ns => !clusterMapping[cluster].includes(ns));
          });
        } else {
          graphFilters.namespaces.forEach(namespace => {
            nowUnusableNamespaces = nowUnusableNamespaces.filter(ns => namespace !== ns);
          });
        }
      }
    }

    if (nowUnusableWorkspaces.length)
      updateCache({ workspaceFilters: workspaceFilters.filter(wf => !nowUnusableWorkspaces.includes(wf)) });
    if (nowUnusableClusters.length)
      updateCache({ clusterFilters: clusterFilters.filter(cf => !nowUnusableClusters.includes(cf)) });
    if (nowUnusableNamespaces.length)
      updateCache({ namespaceFilters: namespaceFilters.filter(nf => !nowUnusableNamespaces.includes(nf)) });
  }, [graphFilters]);

  if (!!graphFiltersError) {
    return (
      <GraphFiltersStyles.Container leftSpacingNeeded={!!usageInfoTopic}>
        <DataError error={graphFiltersError} />
      </GraphFiltersStyles.Container>
    );
  }

  if (!graphFilters && !presetFilters) {
    return null;
  }

  const filters = {
    findName,
    hideName,
    ...(presetFilters ?? {
      namespaces: namespaceFilters,
      clusters: clusterFilters,
      workspaces: workspaceFilters
    })
  };

  const showFilters =
    !presetFilters &&
    (!!findName || !!hideName || !!workspaceFilters.length || !!clusterFilters.length || !!namespaceFilters.length);

  return (
    <GraphFiltersStyles.Container leftSpacingNeeded={!!usageInfoTopic} data-testid='graph-filters'>
      <GraphFiltersStyles.HeaderTools>
        {!presetFilters ? (
          <>
            <GraphFiltersStyles.HeaderToolsFilterByText>Filter By:</GraphFiltersStyles.HeaderToolsFilterByText>
            {workspacesUsable && (
              <GraphFiltersStyles.DropdownContainer>
                <EmptyPromptBox
                  // Show so long as a cluster/namespace aren't already selected (since that could mean
                  // they aren't planning to use a workspace)
                  show={!(filters.clusters.length || filters.namespaces.length)}
                  value={filters.workspaces[0]}
                  itemsNeedingSelection={'workspace or cluster and namespace'}
                />

                <SoloCheckboxListDropdown
                  title='Workspace'
                  data-testid='workspace-dropdown'
                  values={filters.workspaces.map(workspace => workspace)}
                  onChange={vals => updateCache({ workspaceFilters: vals })}
                  options={
                    graphFilters
                      ? graphFilters.workspaces
                        .map(workspace => ({
                          value: workspace,
                          label: workspace
                        }))
                        .sort(filterOptionsSort)
                      : []
                  }
                />
              </GraphFiltersStyles.DropdownContainer>
            )}

            <GraphFiltersStyles.DropdownContainer>
              <EmptyPromptBox
                show={!workspacesUsableAndNotIgnored || !!filters.namespaces[0]}
                value={filters.clusters[0]}
                itemsNeedingSelection={'cluster and namespace'}
              />
              <SoloCheckboxListDropdown
                title='Cluster'
                data-testid='cluster-dropdown'
                values={filters.clusters}
                onChange={vals => updateCache({ clusterFilters: vals })}
                options={
                  graphFilters
                    ? graphFilters.clusters
                      .map(cluster => {
                        const disabled = !selectableClusters.includes(cluster);

                        return {
                          value: cluster,
                          label: disabled ? (
                            <Tooltip placement='right' title={'Does not appear in a selected workspace'}>
                              {cluster}
                            </Tooltip>
                          ) : (
                            cluster
                          ),
                          disabled
                        };
                      })
                      .sort(filterOptionsSort)
                    : []
                }
              />
            </GraphFiltersStyles.DropdownContainer>

            <GraphFiltersStyles.DropdownContainer>
              <EmptyPromptBox
                show={!!filters.clusters[0]}
                value={filters.namespaces[0]}
                itemsNeedingSelection={'namespace'}
              />
              <SoloCheckboxListDropdown
                title='Namespace'
                values={filters.namespaces}
                onChange={vals => updateCache({ namespaceFilters: vals })}
                options={
                  graphFilters
                    ? graphFilters.namespaces
                      .map((namespace: any) => {
                        let disabledMessage: string | undefined = undefined;

                        if (!selectableNamespaces.includes(namespace)) {
                          disabledMessage = workspacesUsable
                            ? 'Does not appear in a selected workspace / cluster'
                            : 'Does not appear in a selected cluster';
                        }

                        return {
                          value: namespace,
                          label: disabledMessage ? (
                            <Tooltip placement='right' title={disabledMessage}>
                              {namespace}
                            </Tooltip>
                          ) : (
                            namespace
                          ),
                          disabled: !!disabledMessage
                        };
                      })
                      .sort(filterOptionsSort)
                    : []
                }
              />
            </GraphFiltersStyles.DropdownContainer>
          </>
        ) : (
          (!!findName || !!hideName) && (
            <TagList
              includePadding
              tags={[
                {
                  value: 'Clear all',
                  color: thm => thm.butternutOrange,
                  onClose: () => {
                    setFindName('');
                    setHideName('');
                  }
                },

                !!findName && {
                  label: 'find by name',
                  value: findName,
                  onClose: () => setFindName('')
                },
                !!hideName && {
                  label: 'hide by name',
                  value: hideName,
                  onClose: () => setHideName('')
                }
              ]}
            />
          )
        )}

        <GraphFiltersStyles.RightSideTools />
      </GraphFiltersStyles.HeaderTools>
      {!presetFilters && (
        <GraphFiltersStyles.TagsListHolder>
          <TagList
            includePadding
            tags={[
              showFilters && {
                value: 'Clear all',
                color: thm => thm.butternutOrange,
                onClose: () => {
                  graphRequestAbort();
                  updateCache({
                    workspaceFilters: [],
                    clusterFilters: [],
                    namespaceFilters: []
                  });
                  setFindName('');
                  setHideName('');
                }
              },

              ...filters.workspaces.map(workspace => ({
                label: 'workspace',
                value: workspace,
                onClose: () => {
                  graphRequestAbort();
                  updateCache({ workspaceFilters: workspaceFilters.filter(wf => wf !== workspace) });
                }
              })),

              ...filters.clusters.map(cluster => ({
                label: 'cluster',
                value: cluster,
                onClose: () => {
                  graphRequestAbort();
                  updateCache({ clusterFilters: clusterFilters.filter(cf => cf !== cluster) });
                }
              })),

              ...filters.namespaces.map(ns => ({
                label: 'namespace',
                value: ns,
                onClose: () => {
                  graphRequestAbort();
                  updateCache({ namespaceFilters: namespaceFilters.filter(nf => nf !== ns) });
                }
              })),

              !!findName && {
                label: 'find by name',
                value: findName,
                onClose: () => setFindName('')
              },
              !!hideName && {
                label: 'hide by name',
                value: hideName,
                onClose: () => setHideName('')
              }
            ]}
          />
        </GraphFiltersStyles.TagsListHolder>
      )}
    </GraphFiltersStyles.Container>
  );
});
