import { GridLayout } from 'Styles/CommonEmotions/gridLayout';
import { ClusterCiliumHealthListCard } from './ClusterCiliumHealthListCard';
import { ClusterCiliumVersionsListCard } from './ClusterCiliumVersionsListCard';

const ClusterCiliumSummaryTabContent = ({ cluster }: { cluster: string }) => {
  return (
    <GridLayout templateColumns='1fr 1fr' gap='16px' pt={5}>
      <ClusterCiliumHealthListCard cluster={cluster} />
      <ClusterCiliumVersionsListCard cluster={cluster} />
    </GridLayout>
  );
};

export default ClusterCiliumSummaryTabContent;
