import { createBreadcrumbRoutesFromRoutes, routeObjects } from 'Components/Structure/routes';
import React from 'react';
import { Outlet, Params, useParams, useResolvedPath, useRoutes } from 'react-router-dom';
import { BreadcrumbStyles as Styles } from './Breadcrumb.style';
import { di } from 'react-magnetic-di';

interface BreadcrumbsItemProps {
  text: string | ((params: Readonly<Params<string>>) => string);
  nolink?: boolean;
}
/**
 * This component uses react-router features to get info on the current route
 * This also utilizes `Outlet` which lets us display all the crumbs in a sub route chain
 * Inspired by: https://github.com/remix-run/react-router/issues/7273#issuecomment-626814462
 */
export const BreadcrumbsItem = ({ text, nolink }: BreadcrumbsItemProps) => {
  const params = useParams();
  const { pathname } = useResolvedPath('');
  const breadcrumbText = typeof text === 'string' ? text : text(params);

  return (
    <>
      <Styles.ListItem data-testid='bread-crumbs-item'>
        {nolink ? (
          breadcrumbText
        ) : (
          <Styles.CrumbLink to={pathname.endsWith('/') ? pathname : `${pathname}/`}>{breadcrumbText}</Styles.CrumbLink>
        )}
      </Styles.ListItem>
      <Outlet />
    </>
  );
};

export const Breadcrumb = () => {
  di(useRoutes, createBreadcrumbRoutesFromRoutes);
  const breadcrumbRouteObjects = createBreadcrumbRoutesFromRoutes(routeObjects);
  const breadcrumbItems = useRoutes(breadcrumbRouteObjects);
  return (
    <Styles.List data-testid='breadcrumb' aria-label='Breadcrumb'>
      {breadcrumbItems}
    </Styles.List>
  );
};
