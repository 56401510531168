import { policiesApi } from 'Api/policies';
import { CardStyles } from 'Components/Common/Card';
import { DataError } from 'Components/Common/DataError';
import { DetailsHeaderCard, DetailsHeaderCardPreLoaded } from 'Components/Common/DetailsHeaderCard';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { StatMiniCardList } from 'Components/Common/StatMiniCard';
import { RouteTablesListDisplay } from 'Components/Features/Routing/RoutesDisplay/RouteTablesListDisplay';
import { Asset } from 'assets';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ClusterObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { di } from 'react-magnetic-di';
import { getPolicyIcon, getPolicyResourceType } from 'utils/types';

const { useGetFaultInjectionPolicyDetails } = policiesApi;

const policyType = PolicyType.FAULT_INJECTION;

interface FaultInjectionDetailsProps {
  clusterObjRef: ClusterObjectRef;
}
export const FaultInjectionDetails = ({ clusterObjRef }: FaultInjectionDetailsProps) => {
  di(useGetFaultInjectionPolicyDetails);
  const routesPaging = useSoloPaging();

  const { data: policyData, error: policyError } = useGetFaultInjectionPolicyDetails(
    clusterObjRef,
    routesPaging.apiPaginationObject
  );

  routesPaging.useSetPagingTotal(policyData?.routes?.totalPolicyRoutes);

  if (!!policyError || !policyData) {
    return (
      <>
        <DetailsHeaderCardPreLoaded icon={getPolicyIcon(policyType)} objRef={clusterObjRef} />
        {!!policyError ? (
          <DataError error={policyError} />
        ) : (
          <Loading message={`Retrieving ${clusterObjRef.name} policy details...`} />
        )}
      </>
    );
  }

  return (
    <CardStyles.CardList>
      <DetailsHeaderCard
        icon={getPolicyIcon(policyType)}
        status={policyData?.status}
        objRef={clusterObjRef}
        workspaceRef={policyData?.ownerWorkspace?.ref}
        resourceType={getPolicyResourceType(policyType)}
      />

      <CardStyles.Card>
        <CardStyles.CardHeader>Overview</CardStyles.CardHeader>

        <StatMiniCardList
          items={[
            {
              icon: <Asset.TrafficPolicyIcon />,
              title: 'Delay Percentage Traffic',
              data: policyData.delayPercentage ? policyData.delayPercentage.value + '%' : 'Delay not configured'
            },
            !!policyData.delayPercentage && {
              icon: <Asset.TimeoutPolicyIcon />,
              title: 'Fixed Delay',
              data: policyData.fixedDelay ? policyData.fixedDelay.seconds + 's' : 'No fixed delay configured'
            },
            {
              icon: <Asset.TrafficPolicyIcon />,
              title: 'Abort Percentage Traffic',
              data: policyData.abortPercentage ? policyData.abortPercentage.value + '%' : 'Abort not configured'
            },
            !!policyData.abortPercentage && {
              icon: <Asset.AbortHttpStatusIcon />,
              title: 'Abort HTTP Status',
              data: policyData.abortHttpStatus ?? 'No status configured'
            }
          ]}
        />
      </CardStyles.Card>

      <CardStyles.Card>
        <CardStyles.CardHeader>
          Routes Applied to
          <CardStyles.CardHeaderRightIcon>
            <Asset.RouteGroupIcon />
          </CardStyles.CardHeaderRightIcon>
        </CardStyles.CardHeader>

        <RouteTablesListDisplay
          routeTables={policyData.routes?.routeTables}
          paging={routesPaging.pagingData}
          parentRef={clusterObjRef}
          policyType={policyType}
        />
      </CardStyles.Card>
    </CardStyles.CardList>
  );
};
