import { graphviz } from 'd3-graphviz';
import {
  GetGraphResponse
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import React, { SetStateAction } from 'react';
import { LayoutTypesEnum } from '../Context/GraphUXSettingsContext';
import { GetContainerBoxName } from './get-graph-items-data';
import { generateDot } from './graph-dot-helpers';
import { GraphContainerType, NodePositions } from './graph-selection-utils';
import {
  getFullInfraTree,
  getFullWorkspaceTree,
  InfraBasedTree,
  TreeNode,
  WorkspaceBasedTree
} from './graph-tree-builder-utils';

const fakeLargeNumber = 99999;

type WorkspaceNodeType = {
  workspaceId: string;
  nodes: TreeNode[];
  minX: number;
  maxX: number;
  maxY: number;
  minY?: number;
};

type VPCBoxType = {
  vpcId: string;
  subcategoryBoxes: SubcategoryBoxType[];
  minX: number;
  maxX: number;
  maxY: number;
  minY: number;
};
type SubcategoryBoxType = {
  subId: string;
  subSubcategoryBoxes: SubSubcategoryBoxType[];
  minX: number;
  maxX: number;
  maxY: number;
  minY: number;
};
type SubSubcategoryBoxType = {
  subSubId: string;
  nodes: TreeNode[];
  minX: number;
  maxX: number;
  maxY: number;
  minY: number;
};

const getWorkspaceBasedNodePositions = (
  manuallyMovedNodePosition: {
    [key: string]: { x: number; y: number };
  },
  fullTree: WorkspaceBasedTree
): NodePositions => {
  // Get the lists and begin placing them, without final adjustments, within their
  //  respective containers
  const unadjustedWorkspaceNodes: WorkspaceNodeType[] = fullTree.workspacesSeen
    .sort()
    .map(workspaceKey => {
      // Get all nodes within this workspace
      let nodesList = fullTree.nodes
        .filter(treeNode => treeNode.workspace === workspaceKey)
        .sort((nodeA, nodeB) => nodeA.id.toLocaleLowerCase().localeCompare(nodeB.id.toLocaleLowerCase()));

      let minX = fakeLargeNumber;
      let maxX = 0;
      let maxY = 0;

      // Track the width/height of the workspace box
      // For each node, adjust max/mins as needed
      let levelCounts: { [key: number]: number } = {};
      nodesList.forEach(node => {
        levelCounts[node.level] = 1 + (levelCounts[node.level] ? levelCounts[node.level] : 0);

        maxY = Math.max(maxY, levelCounts[node.level] - 1);
      });
      const seenLevels = Object.keys(levelCounts) as unknown as number[];
      minX = Math.min(...seenLevels);
      maxX = Math.max(...seenLevels);

      return {
        workspaceId: workspaceKey,
        nodes: nodesList,
        minX,
        maxX,
        maxY
      };
    })
    .filter(unadjustedWS => !!unadjustedWS.nodes.length);

  // Take all the workspace boxes and nudge them out of each others' way
  //   with priority based on earlier sorting and which box is smallest
  //
  //  WE MAY WANT TO USE SOME OF WHAT WE DID BEFORE WITH VERTICAL STACKING
  //   PERHAPS WE SHOULD STACK UP TO A MAXY 5 or 6 (more if single box) AND
  //   THEN MOVE BOXES OVER PAST THAT "STACK"
  let workspaceNodes: typeof unadjustedWorkspaceNodes = [];
  unadjustedWorkspaceNodes
    .sort((unadjustedWsA, unadjustedWsB) => unadjustedWsA.minX - unadjustedWsB.minX)
    .forEach((unadjustedWorkspaceCollection, ind) => {
      // We sorted left->to->right, so now we need to find out how far
      //  to nudge each to the right, based on where the previous maxX is
      //  and add just a little more for visual spacing niceness
      const leftEdgeBoundary = !!ind ? workspaceNodes[ind - 1].maxX + 1.3 : 0;

      // We know where the left edge (minX) has to move to at least, now adjust the whole box over by
      //  that amount and store as final
      //  THIS LiNE WAS ALREADY IN, BUT DOESN'T FEEL RIGHT
      const adjustmentAmount = leftEdgeBoundary - unadjustedWorkspaceCollection.minX;
      workspaceNodes.push({
        ...unadjustedWorkspaceCollection,
        minY: unadjustedWorkspaceCollection.minX - (!!ind ? 0.3 : 0), // Why is minY based on minX?!?
        minX: unadjustedWorkspaceCollection.minX + adjustmentAmount,
        maxX: unadjustedWorkspaceCollection.maxX + adjustmentAmount
      });
    });

  // Workspaces adjusted. Adjust inner nodes...
  let finalNodes: NodePositions = {};
  workspaceNodes.forEach(workspaceNode => {
    let levelCounts: { [key: number]: number } = {};
    const wsNodes = workspaceNode.nodes;

    // Nodes that come from a tree that starts externally get lowest priority,
    //  and must be treated separately as their tree levels don't know anything about
    //  other trees (otherwise will overlap or, at best, create messier interlocking junk).
    const internalNodes = wsNodes.filter(n => !n.exteriorAdjuster);
    const externalNodes = wsNodes.filter(n => !!n.exteriorAdjuster);
    internalNodes.forEach(node => {
      // If this x position is already taken within the box, then add the node further down
      levelCounts[node.level] = levelCounts[node.level] ? levelCounts[node.level] + 1 : 1;

      // GO WITH MANUAL IF AVAILABLE
      const nodeXPos = manuallyMovedNodePosition[node.id]
        ? manuallyMovedNodePosition[node.id].x
        : (workspaceNode.minX + node.level) * 160;
      const nodeYPos = manuallyMovedNodePosition[node.id]
        ? manuallyMovedNodePosition[node.id].y
        : (levelCounts[node.level] - 1 + node.exteriorAdjuster + (workspaceNode.minY ?? 0)) * 160;

      finalNodes[node.id] = {
        position: {
          x: nodeXPos,
          y: nodeYPos
        }
      };
    });
    externalNodes.forEach(node => {
      // If this x position is already taken within the box, then add the node further down
      levelCounts[node.level] = levelCounts[node.level] ? levelCounts[node.level] + 1 : 1;

      // GO WITH MANUAL IF AVAILABLE
      const nodeXPos = manuallyMovedNodePosition[node.id]
        ? manuallyMovedNodePosition[node.id].x
        : (workspaceNode.minX + node.level) * 160;
      const nodeYPos = manuallyMovedNodePosition[node.id]
        ? manuallyMovedNodePosition[node.id].y
        : (levelCounts[node.level] - 1 + node.exteriorAdjuster + (workspaceNode.minY ?? 0)) * 160;

      finalNodes[node.id] = {
        position: {
          x: nodeXPos,
          y: nodeYPos
        }
      };
    });
  });

  return {
    ...finalNodes
  };
};

const getInfraBasedNodePositions = (
  manuallyMovedNodePosition: {
    [key: string]: { x: number; y: number };
  },
  fullTree: InfraBasedTree
): NodePositions => {
  const useClusterNS = true;

  let unadjustedVPCBoxes: VPCBoxType[] = Object.keys(fullTree.vpcsSeen)
    .sort() // just to keep some regularity, avoid jumping boxes if possible
    .map(vpcId => {
      let vpcBox: VPCBoxType = {
        vpcId: vpcId,
        subcategoryBoxes: [],
        minX: fakeLargeNumber,
        minY: 0, // We don't allow vertical overlaps of VPC boxes currently
        maxX: 0,
        maxY: 0
      };
      const nodesInThisVpc = fullTree.nodes.filter(treeNode => treeNode.vpc === vpcId);

      const subCategories = fullTree.vpcsSeen[vpcId].subCategories;
      let unadjustedSubcategoryBoxes: SubcategoryBoxType[] = Object.keys(subCategories)
        .sort()
        .map(subCatId => {
          let subcatBox: SubcategoryBoxType = {
            subId: subCatId,
            subSubcategoryBoxes: [],
            minX: fakeLargeNumber,
            minY: fakeLargeNumber,
            maxX: 0,
            maxY: 0
          };
          const nodesInThisSubcat = nodesInThisVpc.filter(
            node => (useClusterNS ? node.cluster : node.subnet) === subCatId
          );

          // Now go through and build-up/add-to the bottom-level box information
          let unadjustedSubSubcategoryBoxes: SubSubcategoryBoxType[] = subCategories[subCatId].subSubcategories
            .sort()
            .map(subSubId => {
              let minX = fakeLargeNumber;
              let minY = fakeLargeNumber;
              let maxX = 0;
              let maxY = 0;
              const nodes = nodesInThisSubcat
                .filter(subCatNode => (useClusterNS ? subCatNode.namespace : subCatNode.instance) === subSubId)
                .sort((nodeA, nodeB) => nodeA.id.toLocaleLowerCase().localeCompare(nodeB.id.toLocaleLowerCase()));

              let levelCounts: { [key: number]: number } = {};
              nodes.forEach(node => {
                const verticalDifferentSubcatAdjuster = node.exteriorAdjuster;

                // Keep vertical stacks from getting too high...
                let usedLevel = node.level;
                while (levelCounts[usedLevel] >= 6) {
                  usedLevel++;
                }
                levelCounts[usedLevel] = 1 + (levelCounts[usedLevel] ? levelCounts[usedLevel] : 0);

                maxY = Math.max(maxY, levelCounts[usedLevel] - 1 + verticalDifferentSubcatAdjuster);
              });
              const seenLevels = Object.keys(levelCounts) as unknown as number[];
              minX = Math.min(...seenLevels);
              maxX = Math.max(...seenLevels);

              return {
                subSubId,
                nodes,
                minX,
                maxX,
                maxY,
                minY
              };
            })
            // Should not happen, but just in case there is a box without nodes,
            //  we should not display it.
            .filter(unadjustedSubSubBox => unadjustedSubSubBox.nodes.length > 0);

          // Adjust the new bottom-level boxes to stack them within the mid-level
          unadjustedSubSubcategoryBoxes.forEach(unadjustedSubSubcatBox => {
            let subSubcatBox: SubSubcategoryBoxType = {
              ...unadjustedSubSubcatBox
            };

            const overlaps = subcatBox.subSubcategoryBoxes.filter(placedBox => {
              return placedBox.minX <= unadjustedSubSubcatBox.maxX && placedBox.maxX >= unadjustedSubSubcatBox.minX;
            });

            // Unfortunately, we set maxY as 0 when there is only 1 node in the innermost box
            //  due to the math for the other cases.
            //  This can lead to cases where a stack of single-node inner-most boxes are all on top of each other.
            const overlapHeights = !!overlaps.length ? Math.max(...overlaps.map(overlap => overlap.maxY)) : 0;
            const unfortunatelyNeededForcedHeight = !!overlaps.length ? subcatBox.subSubcategoryBoxes.length : 0;
            // Adjust previous values, min was a fake placeholder before, but max should also be bumped as needed
            subSubcatBox.minY = !!overlapHeights
              ? overlapHeights + 1 // The previous heights in the "stack" + 1 to offset for some readability space
              : unfortunatelyNeededForcedHeight;
            subSubcatBox.maxY =
              Math.max(unadjustedSubSubcatBox.maxY, 1) +
              (!!overlapHeights
                ? overlapHeights + 1 // adjust old max Y slot to "move" the whole box
                : unfortunatelyNeededForcedHeight);

            subcatBox.minX = Math.min(subcatBox.minX, subSubcatBox.minX);
            subcatBox.maxX = Math.max(subcatBox.maxX, subSubcatBox.maxX);
            subcatBox.maxY = Math.max(subcatBox.maxY, subSubcatBox.maxY);
            subcatBox.subSubcategoryBoxes.push(subSubcatBox);
          });

          return subcatBox;
        });

      // Adjust the mid-level boxes, as we did the inner-most, so they don't
      //  overlap
      unadjustedSubcategoryBoxes.forEach(unadjustedSubcatBox => {
        let subcatBox: SubcategoryBoxType = {
          ...unadjustedSubcatBox
        };

        const overlaps = vpcBox.subcategoryBoxes.filter(placedSubcatBox => {
          return placedSubcatBox.minX <= unadjustedSubcatBox.maxX && placedSubcatBox.maxX >= unadjustedSubcatBox.minX;
        });

        const overlapHeights = !!overlaps.length ? Math.max(...overlaps.map(overlap => overlap.maxY)) : 0;
        const unfortunatelyNeededForcedHeight = !!overlaps.length ? subcatBox.subSubcategoryBoxes.length : 0;
        // Adjust previous values, min was a fake placeholder before, but max should be bumped as needed
        subcatBox.minY = !!overlapHeights
          ? overlapHeights + 1.5 // The previous heights in the "stack" + 2 to offset for some readability space
          : unfortunatelyNeededForcedHeight;
        subcatBox.maxY =
          Math.max(unadjustedSubcatBox.maxY, 1) +
          (!!overlapHeights
            ? overlapHeights + 1.5 // adjust old max Y slot to "move" the whole box
            : unfortunatelyNeededForcedHeight);

        vpcBox.minX = Math.min(vpcBox.minX, subcatBox.minX);
        vpcBox.maxX = Math.max(vpcBox.maxX, subcatBox.maxX);
        vpcBox.maxY = Math.max(vpcBox.maxY, subcatBox.maxY);
        vpcBox.subcategoryBoxes.push(subcatBox);
      });

      return vpcBox;
    });

  // STILL TO DO
  // Adjusting the vpc stacks now that we have their sizes, so they don't overlap,
  // Then, go back inward and fully adjust node placement with all of their boxes' adjustments.
  let overlapWidths = 0;
  let vpcBoxes: VPCBoxType[] = [];
  unadjustedVPCBoxes.forEach((unadjustedVPCBox, ind) => {
    // We don't vertically stack vpcs currently. So, there is no need to worry about the shifting for vertical overlaps.
    // We just shift everything thing rightward.
    const vpcMinX = overlapWidths;
    const vpcMaxX = unadjustedVPCBox.maxX + overlapWidths;

    // if there's just a single column, we still need to move the next vpcs over
    overlapWidths += vpcMaxX + 1;

    // Assign final cluster node placement.
    vpcBoxes.push({
      ...unadjustedVPCBox,
      minX: vpcMinX,
      maxX: vpcMaxX
    });
  });

  // Adjust innards...
  let finalNodes: NodePositions = {};
  vpcBoxes.forEach(vpcBox => {
    vpcBox.subcategoryBoxes.forEach(subcatBox => {
      subcatBox.subSubcategoryBoxes.forEach(subSubcatBox => {
        let levelCounts: { [key: number]: number } = {};

        subSubcatBox.nodes.forEach(node => {
          levelCounts[node.level] = levelCounts[node.level] ? levelCounts[node.level] + 1 : 1;

          // GO WITH MANUAL IF AVAILABLE
          const nodeXPos = manuallyMovedNodePosition[node.id]
            ? manuallyMovedNodePosition[node.id].x
            : (node.level + subSubcatBox.minX + subcatBox.minX + vpcBox.minX) * 160;
          const nodeYPos = manuallyMovedNodePosition[node.id]
            ? manuallyMovedNodePosition[node.id].y
            : (levelCounts[node.level] - 1 + node.exteriorAdjuster + subSubcatBox.minY + subcatBox.minY + vpcBox.minY) *
              160;

          finalNodes[node.id] = {
            position: {
              x: nodeXPos,
              y: nodeYPos
            }
          };
        });
      });
    });
  });

  return {
    ...finalNodes
  };
};

// This is separated because it is never based on the shape. It is just getting the list
//  of containers so that our counts match to the data nodes
// These are all Compound nodes (re: Cytoscape only uses children for position)
//  Cytoscape also needs all cases to already exist, as it will crash when
//  trying to flip types if node parent/edge references disappear between renders
//   without a full rerender.
function getContainerNodes(data?: GetGraphResponse): NodePositions {
  if (!data) {
    return {};
  }

  let namespaceNodes: NodePositions = {};
  let clusterNodes: NodePositions = {};
  let workspaceNodes: NodePositions = {};
  let instanceNodes: NodePositions = {};
  let subnetNodes: NodePositions = {};
  let VPCNodes: NodePositions = {};

  data.nodeMetrics.forEach(nodeMetric => {
    // If no workload data, nothing to really work with, so skip node
    if (!!nodeMetric.workload) {
      // Get list of ids with their empty-string-fallbacks that match to what data uses
      const VPCID = GetContainerBoxName(nodeMetric.workload.vpc, GraphContainerType.VPC);
      const clusterNodeId = GetContainerBoxName(nodeMetric.workload.cluster, GraphContainerType.Cluster, VPCID);
      const namespaceNodeId = GetContainerBoxName(
        nodeMetric.workload.namespace,
        GraphContainerType.Namespace,
        clusterNodeId
      );
      const workspaceNodeId = GetContainerBoxName(nodeMetric.workload.workspace, GraphContainerType.Workspace, VPCID);
      const subnetNodeId = GetContainerBoxName(nodeMetric.workload.subnet, GraphContainerType.Subnet, VPCID);
      const instanceNodeId = GetContainerBoxName(
        nodeMetric.workload.instance,
        GraphContainerType.Instance,
        subnetNodeId
      );

      // Now fill out nodes. Since the data is always the same we don't care about overwriting
      namespaceNodes[namespaceNodeId] = {
        position: { x: 0, y: 0 }
      };
      clusterNodes[clusterNodeId] = {
        position: { x: 0, y: 0 }
      };
      workspaceNodes[workspaceNodeId] = {
        position: { x: 0, y: 0 }
      };
      instanceNodes[instanceNodeId] = {
        position: { x: 0, y: 0 }
      };
      subnetNodes[subnetNodeId] = {
        position: { x: 0, y: 0 }
      };
      VPCNodes[VPCID] = {
        position: { x: 0, y: 0 }
      };
    }
  });

  return { ...namespaceNodes, ...clusterNodes, ...workspaceNodes, ...instanceNodes, ...subnetNodes, ...VPCNodes };
}

export function getUsedPositioning(
  manuallyMovedNodePosition: {
    [key: string]: { x: number; y: number };
  },
  layoutUsed: LayoutTypesEnum,
  workspaceBoxesShown: boolean, // if false, clusters shown
  setGraphedNodePositions: React.Dispatch<SetStateAction<NodePositions>>,
  data?: GetGraphResponse
): NodePositions {
  if (!data) {
    return {};
  }

  // This doesn't have to change very much since we have the 2 paths already. We're just adding another path,
  //  which will mostly mimic one of the others

  let nodes: NodePositions = {};

  if (layoutUsed === LayoutTypesEnum.Dot) {
    const dot = generateDot(data);
    graphviz('canvas', { fit: true, zoom: true }).dot(dot, function (this: { _data: any; _dictionary: any }) {
      let nodes: NodePositions = {};
      const transform = this._dictionary['svg-0.g-0'].attributes.transform;
      const translateX = Number(transform.slice(transform.lastIndexOf('(') + 1, transform.lastIndexOf(' ')));
      const translateY = Number(transform.slice(transform.lastIndexOf(' ') + 1, transform.length - 1));

      data.nodeMetrics.forEach(node => {
        if (!node.workload?.id) return;
        // Here we use the svg generated by graphviz to get the center position of each node.
        // Each time we access a node's= element in the dictionary, we look at the third child as that is the ellipse
        // element generated by graphviz. This element tells us where we should draw the corresponding node.
        const { x, y } = this._dictionary[`svg-0.g-0.${node.workload.id}`].children[3].center;
        nodes[node.workload.id] = {
          position: { x: Number(x) + translateX, y: Number(y) + translateY }
        };
      });

      nodes = { ...nodes, ...getContainerNodes(data) };

      setGraphedNodePositions(nodes);
    });
  } else {
    if (workspaceBoxesShown) {
      const fullTree = getFullWorkspaceTree(data);

      nodes = getWorkspaceBasedNodePositions(manuallyMovedNodePosition, fullTree);
    } else {
      const fullTree = getFullInfraTree(data);

      nodes = getInfraBasedNodePositions(manuallyMovedNodePosition, fullTree);
    }

    nodes = { ...nodes, ...getContainerNodes(data) };

    setGraphedNodePositions(nodes);
  }

  return nodes;
}
