import { Empty } from 'antd';
import Table, { ColumnsType, ColumnType, TableProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import React, { useEffect, useState, useRef } from 'react';
import { Loading } from './Loading';
import { DefaultTablePageSizeOptions, PagingProps, SoloPagination } from './SoloPagination';
import { SoloTableStyles as Styles } from './SoloTable.style';
import { useEventListener } from 'utils/hooks';

// This requires that `dataIndex` by a key inside RecordType
// This also makes the first argument in render() know it's type based on `dataIndex` and `RecordType`
// Taken from https://github.com/react-component/table/issues/499#issuecomment-652497954
export type SoloColumnType<RecordType> = keyof RecordType extends infer DataIndexType
  ? DataIndexType extends keyof RecordType
    ? {
        [k in keyof ColumnType<RecordType>]: k extends 'dataIndex'
          ? DataIndexType
          : // eslint-disable-next-line max-len
          k extends 'render'
          ? (value: RecordType[DataIndexType], record: RecordType, index: number) => React.ReactNode
          : ColumnType<RecordType>[k];
      }
    : never
  : never;
type SoloColumnGroupType<RecordType> = Omit<SoloColumnType<RecordType>, 'dataIndex'> & {
  children: SoloColumnsType<RecordType>;
};
export type SoloColumnsType<RecordType> = (SoloColumnGroupType<RecordType> | SoloColumnType<RecordType>)[];

export interface SoloTableProps<RecordType> {
  columns: SoloColumnsType<RecordType>;
  dataSource: readonly RecordType[];
  formComponent?: React.FC;
  flatTopped?: boolean;
  removeBorder?: boolean;
  hideHeader?: boolean;
  rowHeight?: string;
  rowClassName?: (rowData: any, index: number) => string;
  expandable?: ExpandableConfig<any>;
  darkHeader?: boolean;
  scrollable?: { y?: number | string; x?: number | string };
  loading?: boolean;
  renderEmpty?: React.ReactNode;
  paging?: PagingProps;
  'data-testid'?: string;
  removeHorizontalPadding?: boolean;
  hasBottomRowBorder?: boolean;
  onRow?: TableProps<RecordType>['onRow'];
}

export const SoloTable = <RecordType extends { key: string | number }>({
  columns,
  dataSource,
  formComponent,
  flatTopped,
  removeBorder,
  hideHeader,
  rowHeight,
  rowClassName,
  expandable,
  darkHeader,
  scrollable,
  loading,
  renderEmpty,
  paging,
  'data-testid': testId,
  removeHorizontalPadding,
  onRow
}: SoloTableProps<RecordType>) => {
  const isEmpty = dataSource.length === 0;

  const containerRef = useRef<HTMLDivElement>(null);
  const [tableHeadHeight, setTableHeadHeight] = useState(47);
  const getTableHeadHeight = (elem: HTMLDivElement | null) => elem?.querySelector('thead')?.clientHeight ?? 47;
  const updateTableHeadHeight = () => setTableHeadHeight(getTableHeadHeight(containerRef.current));

  // Check if we should resize the table head background height when the window resizes.
  // (since we don't support cell resizing this should be all that's needed for now)
  useEventListener(window, 'resize', updateTableHeadHeight, [containerRef.current]);
  // Sets the table head height to the correct value when the containerRef.current first loads.
  useEffect(updateTableHeadHeight, [containerRef.current]);

  return (
    <Styles.TableContainer
      rowHeight={rowHeight}
      removeBorder={removeBorder}
      flatTopped={flatTopped}
      darkHeader={darkHeader}
      alignWithPagination={removeHorizontalPadding}
      isEmpty={isEmpty}
      hasBottomRowBorder={!!paging}
      rowsAreClickable={!!onRow}>
      <Styles.FakeTableHeaderBackground flatTopped={flatTopped} darkHeader={darkHeader} height={tableHeadHeight} />
      <Table
        dataSource={dataSource}
        columns={columns as ColumnsType<RecordType>}
        pagination={false}
        showHeader={!hideHeader}
        rowClassName={rowClassName}
        expandable={expandable}
        scroll={scrollable}
        data-testid={testId}
        onRow={onRow}
        ref={containerRef}
      />
      {isEmpty && (
        <Styles.CustomEmpty>
          <div>{renderEmpty ?? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Matches' />}</div>
        </Styles.CustomEmpty>
      )}
      {!!paging && !isEmpty && <SoloPagination {...paging} pageSizeOptions={DefaultTablePageSizeOptions} />}
      {loading && (
        <Styles.LoadingOverlay>
          <Loading />
        </Styles.LoadingOverlay>
      )}
    </Styles.TableContainer>
  );
};
