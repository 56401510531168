import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Color } from 'Styles/colors';

export namespace NotificationBoxStyles {
  // overall container
  export type ColorProps = {
    color: Color;
    backgroundColor: Color;
    borderColor: Color;
  };

  type ContainerProps = ColorProps & {
    bottomMargin?: boolean;
  };
  export const Container = styled.div<ContainerProps & ColorProps>(
    ({ bottomMargin, color, backgroundColor, borderColor }) => css`
      border-radius: 4px;
      padding: 8px 15px;
      font-size: 14px;
      ${bottomMargin ? 'margin-bottom: 15px;' : ''}

      color: ${color};
      background: ${backgroundColor};
      border: 1px solid ${borderColor};
    `
  );

  // header section
  export const IssueHeaderContainer = styled.div`
    display: flex;
    align-items: center;
  `;
  export const IssueMessageContainer = styled.div`
    margin-left: 8px;
  `;
  export const IssueTypeContainer = styled.span`
    font-weight: bold;
  `;
  export const ExpandLink = styled.div`
    cursor: pointer;
    font-weight: bold;
    flex: 1;
  `;
  export const Toggler = styled.div`
    cursor: pointer;
  `;
  type ArrowIconProps = {
    expanded: boolean;
  };
  export const ArrowIconHolder = styled.div<ArrowIconProps>(
    ({ expanded }) => css`
      ${expanded &&
      css`
        transform: rotate(180deg);
      `}
      margin-left: 4px;
    `
  );

  // expanded details section
  export const IssueDetailsContainer = styled.div`
    max-height: 150px;
    overflow-y: auto;
  `;
  export const IssueContainer = styled.div`
    display: flex;
  `;
}
