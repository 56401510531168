import { Resolution_Resolvers } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graphql_pb';
import { useContext } from 'react';
import { GqlLandingContext } from '../context/GqlLandingContext';
import { GqlGrpcResolverModal } from './GrpcResolver/GqlGrpcResolverModal';
import { GqlRestResolverModal } from './RestResolver/GqlRestResolverModal';

const GqlResolverModal = ({
  objectName,
  fieldName,
  resolver,
  onClose
}: {
  objectName: string;
  fieldName: string;
  resolver: null | Resolution_Resolvers;
  onClose: () => void;
}) => {
  const gqlCtx = useContext(GqlLandingContext);
  const { api } = gqlCtx;
  if (resolver?.resolver?.resolver.oneofKind === 'restResolver')
    return (
      <GqlRestResolverModal
        api={api}
        objectName={objectName}
        fieldName={fieldName}
        onClose={onClose}
        resolver={resolver}
      />
    );
  if (resolver?.resolver?.resolver.oneofKind === 'grpcResolver')
    return (
      <GqlGrpcResolverModal
        api={api}
        objectName={objectName}
        fieldName={fieldName}
        onClose={onClose}
        resolver={resolver}
      />
    );
  return null;
};

export default GqlResolverModal;
