import { useSoloPagingWithDataFiltering } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import { renderReactMarkdown } from 'Components/Common/SoloTableCells';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { InputValueDefinitionNode, TypeNode } from 'graphql';
import { useMemo } from 'react';
import { getArgumentDefaultValue } from 'utils/graphql-helpers';
import { GqlSchemaTabTableProps, getDescriptionColumnValue } from 'utils/graphql-schema-search-helpers';
import { RenderDefinitionNameLinkProps, renderDefinitionNameLink } from '../../../GqlSoloTableCells';
import FieldTypeValue from '../../FieldTypeValue';
import { GqlDescriptionInfoTooltip } from '../../GqlDescriptionInfoTooltip';
import { GqlSchemaTabStyles } from '../GqlSchemaTabStyles.style';

type TableFields = {
  key: string;
  isFieldIncludedByExtension: boolean;
  nameLink: RenderDefinitionNameLinkProps;
  type: TypeNode;
  defaultValue?: string;
  description: string;
};

const GqlInputTabItemTable = ({
  definitions,
  extendedDefinitions,
  onTypeClick,
  hidden,
  tabHeader
}: GqlSchemaTabTableProps<InputValueDefinitionNode> & { tabHeader: string }) => {
  const columns: SoloColumnsType<TableFields> = useMemo(() => {
    const cols: SoloColumnsType<TableFields> = [
      {
        title: 'Name',
        dataIndex: 'nameLink',
        render: (props, record) => {
          return (
            <FlexLayout>
              {record.isFieldIncludedByExtension && (
                <Spacer mt={1}>
                  <GqlDescriptionInfoTooltip description='This type was extended to include this input value.' />
                </Spacer>
              )}
              {renderDefinitionNameLink(props)}
            </FlexLayout>
          );
        }
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => (
          <GqlSchemaTabStyles.MonospaceItem>
            <FieldTypeValue field={type} onTypeClick={itemName => onTypeClick(itemName, true)} />
          </GqlSchemaTabStyles.MonospaceItem>
        )
      }
    ];
    if (definitions.some(f => !!f.defaultValue)) {
      // Only add the defaultValue column if there are default values defined.
      cols.push({
        title: 'Default Value',
        dataIndex: 'defaultValue'
      });
    }
    cols.push({
      title: 'Description',
      dataIndex: 'description',
      render: renderReactMarkdown
    });
    return cols;
  }, [definitions]);

  //
  // Pagination, Table Data
  //
  const combinedDefinitions = useMemo(
    () =>
      [...definitions, ...(extendedDefinitions?.map(d => ({ ...d, isFieldIncludedByExtension: true })) ?? [])] as
        | (InputValueDefinitionNode & { isFieldIncludedByExtension?: boolean })[],
    [definitions, extendedDefinitions]
  );
  const { pagingData, paginatedData } = useSoloPagingWithDataFiltering(combinedDefinitions, 5);
  const tableData = useMemo<TableFields[]>(() => {
    return paginatedData.map(f => ({
      key: f.name.value,
      isFieldIncludedByExtension: !!f.isFieldIncludedByExtension,
      nameLink: { name: f.name.value, tabHeader, onTypeClick },
      type: f.type,
      defaultValue: getArgumentDefaultValue(f),
      description: getDescriptionColumnValue(f)
    }));
  }, [paginatedData, tabHeader, onTypeClick]);

  //
  // Render
  //
  if (hidden) return null;
  return (
    <SoloTable
      removeHorizontalPadding
      columns={columns}
      dataSource={tableData}
      paging={pagingData}
      data-testid='gql-input-values-table'
    />
  );
};

export default GqlInputTabItemTable;
